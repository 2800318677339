import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material'
import { Router } from '@angular/router'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Action, Store } from '@ngrx/store'
import { Observable, of, from } from 'rxjs'
import { catchError, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators'
import { MessageDialogComponent } from 'src/app/components/common/dialog/message-dialog/message-dialog.component'
import { getDialogConfig } from 'src/app/models/common/dialog'
import { Response } from 'src/app/models/common/http'
import { MerchantGroupListResponse } from 'src/app/models/param-settings/mer-group/merchant-group-list'
import { MerchantGroupViewResponse } from 'src/app/models/param-settings/mer-group/merchant-group-view'
import { ParameterSettingsService } from 'src/app/services/param-settings/parameter-settings.service'
import * as AppStore from 'src/app/store/'
import * as CommonAction from '../../common/common/common.actions'
import * as MerchantGroupAction from './merchant-group.actions'
import { KeyValue } from '@angular/common'
import { MerchantGroupDuplicateComponent } from 'src/app/components/param-settings/merchant-group/merchant-group-duplicate/merchant-group-duplicate.component'

@Injectable()
export class Effects {

	constructor(
		private action$: Actions,
		private router: Router,
		private dialog: MatDialog,
		private parameterSettingsService: ParameterSettingsService,
		private store: Store<AppStore.State>
	) { }

	InitialState$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(MerchantGroupAction.InitialState),
			tap(() => {
				this.router.navigate(['parameter-settings/merchant-group'])
			})
		), { dispatch: false }
	)

	GoList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(MerchantGroupAction.GoList),
			tap(() => {
				this.router.navigate(['parameter-settings/merchant-group'])
			})
		), { dispatch: false }
	)

	GoView$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(MerchantGroupAction.GoView),
			tap(() => {
				this.router.navigate(['parameter-settings/merchant-group/view'])
			})
		), { dispatch: false }
	)

	GoCreate$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(MerchantGroupAction.GoCreate),
			tap(() => {
				this.router.navigate(['parameter-settings/merchant-group/create'])
			})
		), { dispatch: false }
	)

	GoUpdate$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(MerchantGroupAction.GoUpdate),
			tap(() => {
				this.router.navigate(['parameter-settings/merchant-group/update'])
			})
		), { dispatch: false }
	)

	GoBackDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(MerchantGroupAction.GoBackDialog),
			mergeMap(({ action }) => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.BACK', payload: action }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					if (result.payload === 'LIST') {
						return MerchantGroupAction.GoList()
					}
					if (result.payload === 'VIEW') {
						return MerchantGroupAction.GoView()
					}
				} else {
					return MerchantGroupAction.CloseDialog()
				}
			})
		)
	)

	RestError$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(MerchantGroupAction.RestError),
			map(({ message, manualThrow }) => {
				return CommonAction.RestError({ message, manualThrow })
			})
		)
	)

	ResourceListCreate$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(MerchantGroupAction.ResourceListCreate),
			mergeMap(() => {
				return this.parameterSettingsService.getResourceList({ module: 'merchantGroup', action: 'create' }).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: KeyValue<string, string>[] = response.payload
					if (success) {
						return MerchantGroupAction.ResourceListCreateSuccess({ payload })
					} else { return MerchantGroupAction.RestError(response as any) }
				}), catchError(this.catcher))
			})
		)
	)

	ResourceListUpdate$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(MerchantGroupAction.ResourceListUpdate),
			mergeMap(() => {
				return this.parameterSettingsService.getResourceList({ module: 'merchantGroup', action: 'update' }).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: KeyValue<string, string>[] = response.payload
					if (success) {
						return MerchantGroupAction.ResourceListUpdateSuccess({ payload })
					} else { return MerchantGroupAction.RestError(response as any) }
				}), catchError(this.catcher))
			})
		)
	)

	List$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(MerchantGroupAction.List),
			withLatestFrom(this.store.select(state => state.merchantGroup)),
			mergeMap(([x, merchantGroup]) => {

				return this.parameterSettingsService.getMerchantGroupList().pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: MerchantGroupListResponse = response.payload
					if (success) {
						return MerchantGroupAction.ListSuccess({ payload })
					} else { return MerchantGroupAction.RestError(response as any) }
				}), catchError(this.catcher))
			})
		)
	)

	View$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(MerchantGroupAction.View),
			withLatestFrom(this.store.select(state => state.merchantGroup)),
			mergeMap(([, merchantGroup]) => this.parameterSettingsService.viewMerchantGroup({ code: merchantGroup.code })
				.pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: MerchantGroupViewResponse = response.payload

					if (success) {
						return MerchantGroupAction.ViewSuccess({ payload })
					} else { return MerchantGroupAction.RestError(response as any) }

				}), catchError(this.catcher)
				)
			)
		)
	)

	CreateDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(MerchantGroupAction.CreateDialog),
			mergeMap((x) => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: x.content, payload: x.payload }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return MerchantGroupAction.Create({ payload: result.payload })
				} else {
					return MerchantGroupAction.CloseDialog()
				}
			})
		)
	)

	Create$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(MerchantGroupAction.Create),
			withLatestFrom(this.store.select(state => state.merchantGroup)),
			mergeMap(([x, merchantGroup]) => {
				const form = merchantGroup.merchantGroupDetailForm.controls
				return this.parameterSettingsService.createMerchantGroup({
					code: 'MEG' + form.code.value,
					name: form.name.value,
					merchantId: x.payload.list.map(o => o.replace('\r', '')).toString(),
					description: form.description.value
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: string = response.payload
					if (success) {
						return MerchantGroupAction.CreateSuccess({ payload })
					} else { return MerchantGroupAction.RestError(response as any) }

				}), catchError(this.catcher))
			})
		)
	)

	CreateSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(MerchantGroupAction.CreateSuccess),
			mergeMap(({ payload }) => {
				return [MerchantGroupAction.GoList(), CommonAction.RestError({ message: payload })]
			})
		)
	)

	UpdateDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(MerchantGroupAction.UpdateDialog),
			mergeMap((x) => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.UPDATE', payload: x.payload }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return MerchantGroupAction.Update({ payload: result.payload })
				} else {
					return MerchantGroupAction.CloseDialog()
				}
			})
		)
	)

	Update$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(MerchantGroupAction.Update),
			withLatestFrom(this.store.select(state => state.merchantGroup)),
			mergeMap(([x, merchantGroup]) => {
				const form = merchantGroup.merchantGroupDetailForm.controls
				return this.parameterSettingsService.updateMerchantGroup({
					code: form.code.value,
					name: form.name.value,
					merchantId: x.payload.map(o => o.replace('\r', '')).toString(),
					description: form.description.value,
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: string = response.payload
					if (success) {
						return MerchantGroupAction.UpdateSuccess({ payload })
					} else { return MerchantGroupAction.RestError(response as any) }

				}), catchError(this.catcher))
			})
		)
	)

	UpdateSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(MerchantGroupAction.UpdateSuccess),
			mergeMap(({ payload }) => {
				return [MerchantGroupAction.GoView(), CommonAction.RestError({ message: payload })]
			})
		)
	)

	DeleteDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(MerchantGroupAction.DeleteDialog),
			mergeMap(() => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.DELETE_MERCHANT_GROUP' }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return MerchantGroupAction.Delete()
				} else {
					return MerchantGroupAction.CloseDialog()
				}
			})
		)
	)

	Delete$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(MerchantGroupAction.Delete),
			withLatestFrom(this.store.select(state => state.merchantGroup)),
			mergeMap(([, merchantGroup]) => this.parameterSettingsService.deleteMerchantGroup({
				code: merchantGroup.code
			}).pipe(map((response: Response) => {
				const success: boolean = response.success
				const payload: string = response.payload

				if (success) {
					return MerchantGroupAction.DeleteSuccess({ payload })
				} else { return MerchantGroupAction.RestError(response as any) }

			}),
				catchError(this.catcher)
			))
		)
	)

	DeleteSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(MerchantGroupAction.DeleteSuccess),
			mergeMap(({ payload }) => {
				return [MerchantGroupAction.GoList(), CommonAction.RestError({ message: payload })]
			})
		)
	)

	DuplicateDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(MerchantGroupAction.DuplicateDialog),
			mergeMap(() => {
				const dialogRef = this.dialog.open(MerchantGroupDuplicateComponent, getDialogConfig({}))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return MerchantGroupAction.Duplicate()
				} else {
					return MerchantGroupAction.CloseDialog()
				}
			})
		)
	)

	Duplicate$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(MerchantGroupAction.Duplicate),
			withLatestFrom(this.store.select(state => state.merchantGroup)),
			mergeMap(([, merchantGroup]) => {
				const merchantGroupDuplicateForm = merchantGroup.merchantGroupDuplicateForm.value
				return this.parameterSettingsService.duplicateMerchantGroup(merchantGroup.code, {
					code: 'MEG' + merchantGroupDuplicateForm.code,
					name: merchantGroupDuplicateForm.name
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload = response.payload
					if (success) {
						return MerchantGroupAction.DuplicateSuccess({ payload })
					} else {
						return MerchantGroupAction.RestError(response as any)
					}
				}), catchError(this.catcher))
			})
		)
	)

	DuplicateSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(MerchantGroupAction.DuplicateSuccess),
			mergeMap(({ payload }) => {
				return [
					MerchantGroupAction.List(),
					CommonAction.RestError({ message: payload })
				]
			})
		)
	)

	catcher = (error) => {
		return (of(MerchantGroupAction.RestError({ message: error.message, manualThrow: error.manualThrow })))
	}
}
