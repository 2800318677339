import { createAction, props } from '@ngrx/store'
import { GlProvisionListResponse } from 'src/app/models/param-settings/gl-provision/gl-provision-list'
import { GlProvisionViewResponse } from 'src/app/models/param-settings/gl-provision/gl-provision-view'
import { GlProvisionData } from 'src/app/models/param-settings/gl-provision/gl-provision-data'

export const InitialState = createAction('[GlProvision] InitialState')
export const Reset = createAction('[GlProvision] Reset')
export const SetId = createAction('[GlProvision] SetId', props<{ id: number }>())

export const GoList = createAction('[GlProvision] GoList')
export const GoView = createAction('[GlProvision] GoView')
export const GoCreate = createAction('[GlProvision] GoCreate')
export const GoUpdate = createAction('[GlProvision] GoUpdate')

export const GoBackDialog = createAction('[GlProvision] GoBackDialog', props<{ action: string }>())
export const CreateDialog = createAction('[GlProvision] CreateDialog')
export const UpdateDialog = createAction('[GlProvision] UpdateDialog')
export const DeleteDialog = createAction('[GlProvision] DeleteDialog')
export const CloseDialog = createAction('[GlProvision] CloseDialog')

export const RestError = createAction('[GlProvision] RestError', props<{ message: string, manualThrow: boolean }>())

export const List = createAction('[GlProvision] List')
export const ListSuccess = createAction('[GlProvision] ListSuccess', props<{ payload: GlProvisionListResponse }>())

export const View = createAction('[GlProvision] View')
export const ViewSuccess = createAction('[GlProvision] ViewSuccess', props<{ payload: GlProvisionViewResponse }>())

export const Create = createAction('[GlProvision] Create')
export const CreateSuccess = createAction('[GlProvision] CreateSuccess', props<{ payload: string }>())

export const Update = createAction('[GlProvision] Update')
export const UpdateSuccess = createAction('[GlProvision] UpdateSuccess', props<{ payload: string }>())

export const Delete = createAction('[GlProvision] Delete')
export const DeleteSuccess = createAction('[GlProvision] DeleteSuccess')

export const GetGlProvisionData = createAction('[GlProvision] GetGlProvisionData')
export const GetGlProvisionDataSuccess = createAction('[GlProvision] GetGlProvisionDataSuccess', props<{ payload: GlProvisionData }>())

export const DuplicateDialog = createAction('[GlProvision] DuplicateDialog')
export const Duplicate = createAction('[GlProvision] Duplicate')
export const DuplicateSuccess = createAction('[GlProvision] DuplicateSuccess', props<{ payload: string }>())
