import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material'
import { Router } from '@angular/router'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Action, Store } from '@ngrx/store'
import { Observable, of } from 'rxjs'
import { catchError, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators'
import { MessageDialogComponent } from 'src/app/components/common/dialog/message-dialog/message-dialog.component'
import { getDialogConfig } from 'src/app/models/common/dialog'
import { Response } from 'src/app/models/common/http'
import { FieldMappingViewResponse } from 'src/app/models/param-settings/field-mapping/field-mapping-view'
import { ParameterSettingsService } from 'src/app/services/param-settings/parameter-settings.service'
import * as AppStore from 'src/app/store/'
import * as CommonAction from '../../common/common/common.actions'
import * as FieldMappingAction from './field-mapping.actions'
import { FieldMappingListResponse } from 'src/app/models/param-settings/field-mapping/field-mapping-list'
import { FieldMappingEditComponent } from 'src/app/components/param-settings/field-mapping/field-mapping-edit/field-mapping-edit.component'

@Injectable()
export class Effects {

	constructor(
		private action$: Actions,
		private router: Router,
		private dialog: MatDialog,
		private parameterSettingsService: ParameterSettingsService,
		private store: Store<AppStore.State>
	) { }

	InitialState$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(FieldMappingAction.InitialState),
			tap(() => {
				this.router.navigate(['parameter-settings/field-mapping'])
			})
		), { dispatch: false }
	)

	GoList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(FieldMappingAction.GoList),
			tap(() => {
				this.router.navigate(['parameter-settings/field-mapping'])
			})
		), { dispatch: false }
	)

	GoView$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(FieldMappingAction.GoView),
			tap(() => {
				this.router.navigate(['parameter-settings/field-mapping/view'])
			})
		), { dispatch: false }
	)

	GoBackDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(FieldMappingAction.GoBackDialog),
			mergeMap(({ action }) => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.BACK', payload: action }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					if (result.payload === 'LIST') {
						return FieldMappingAction.GoList()
					}
					if (result.payload === 'VIEW') {
						return FieldMappingAction.GoView()
					}
				} else {
					return FieldMappingAction.closeDialog()
				}
			})
		)
	)

	RestError$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(FieldMappingAction.RestError),
			map(({ message, manualThrow }) => {
				return CommonAction.RestError({ message, manualThrow })
			})
		)
	)

	List$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(FieldMappingAction.List),
			withLatestFrom(this.store.select(state => state.fieldMapping)),
			mergeMap(([x, fieldMapping]) => {
				return this.parameterSettingsService.getFieldMappingList().pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: FieldMappingListResponse = response.payload
					if (success) {
						return FieldMappingAction.ListSuccess({ payload })
					} else { return FieldMappingAction.RestError(response as any) }
				}), catchError(this.catcher))
			})
		)
	)

	View$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(FieldMappingAction.View),
			withLatestFrom(this.store.select(state => state.fieldMapping)),
			mergeMap(([, fieldMapping]) => this.parameterSettingsService.viewFieldMapping({ id: fieldMapping.id })),
			map((response: Response) => {
				const success: boolean = response.success
				const payload: FieldMappingViewResponse = response.payload

				if (success) {
					return FieldMappingAction.ViewSuccess({ payload })
				} else { return FieldMappingAction.RestError(response as any) }

			}), catchError(this.catcher)
		)
	)

	UpdateValueDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(FieldMappingAction.UpdateValueDialog),
			withLatestFrom(this.store.select(state => state.fieldMapping)),
			map(([param, fieldMapping]) => {
				return FieldMappingAction.UpdateValueDialogSuccess({id: param.id, label: param.label, value: param.value})
			})
		)
	)

	UpdateValueDialogSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(FieldMappingAction.UpdateValueDialogSuccess),
			withLatestFrom(this.store.select(state => state.fieldMapping)),
			mergeMap(([param, fieldMapping]) => {
				const dialogRef = this.dialog.open(FieldMappingEditComponent, {
					data: {
						id: param.id,
						label: param.label,
						value: param.value
					}
				})
				return dialogRef.afterClosed()
			})
		), { dispatch: false }
	)

	UpdateValue$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(FieldMappingAction.UpdateValue),
			withLatestFrom(this.store.select(state => state.fieldMapping)),
			mergeMap(([param, fieldMapping]) => {
				return this.parameterSettingsService.updateFieldMappingValue({
					id: param.id,
					value: param.value,
					resourceCode: fieldMapping.id
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: string = response.payload
					if (success) {
						return FieldMappingAction.UpdateValueSuccess({ payload })
					} else { return FieldMappingAction.RestError(response as any) }

				})
					, catchError(this.catcher)
				)
			})
		)
	)

	UpdateValueSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(FieldMappingAction.UpdateValueSuccess),
			mergeMap(() => {
				this.dialog.closeAll()
				return [FieldMappingAction.View()]
			})
		)
	)

	UpdateMasking$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(FieldMappingAction.UpdateMasking),
			withLatestFrom(this.store.select(state => state.fieldMapping)),
			mergeMap(([param, fieldMapping]) => {
				return this.parameterSettingsService.updateFieldMappingMasking({
					id: param.id,
					masking: param.masking,
					resourceCode: fieldMapping.id
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: string = response.payload
					if (success) {
						return FieldMappingAction.UpdateMaskingSuccess({ payload })
					} else { return FieldMappingAction.RestError(response as any) }

				})
					, catchError(this.catcher)
				)
			})
		)
	)

	UpdateMaskingSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(FieldMappingAction.UpdateMaskingSuccess),
			mergeMap(() => {
				this.dialog.closeAll()
				return [FieldMappingAction.View()]
			})
		)
	)

	catcher = (error) => {
		return (of(FieldMappingAction.RestError({ message: error.message, manualThrow: error.manualThrow })))
	}
}
