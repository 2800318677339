import { Action, createReducer, on } from '@ngrx/store'
import { formGroupReducer, reset, setValue } from 'ngrx-forms'
import * as GlProvisionAction from './gl-provision.actions'
import * as GlProvisionState from './gl-provision.state'

export const reducer = createReducer(
	GlProvisionState.initialState,
	on(GlProvisionAction.InitialState, () => GlProvisionState.initialState),
	on(GlProvisionAction.Reset, state => ({
		...state
	})),
	on(GlProvisionAction.SetId, (state, { id }) => ({
		...state,
		id
	})),
	on(GlProvisionAction.GoCreate, state => ({
		...state,
		glProvisionDetailForm: reset(setValue(state.glProvisionDetailForm, GlProvisionState.initialGlProvisionDetailFormValue))
	})),
	on(GlProvisionAction.RestError, state => ({
		...state,
		isLoading: false,
		isDuplicateLoading: false,
		isDuplicateDialog: false
	})),
	on(GlProvisionAction.List, state => ({
		...state,
		isLoading: true,
	})),
	on(GlProvisionAction.ListSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		glProvisionListResponse: payload
	})),
	on(GlProvisionAction.View, state => ({
		...state,
		isLoading: true,
		glProvisionDetailForm: setValue(state.glProvisionDetailForm, GlProvisionState.initialGlProvisionDetailFormValue),
		allowDelete: true
	})),
	on(GlProvisionAction.ViewSuccess, (state, { payload }) => {
		const glProvisionDetailFormView = {
			code: payload.code,
			name: payload.name,
			debitGlCode: payload.debitGlCode,
			debitBranch: payload.debitBranch,
			debitBranchCostCenter: payload.debitBranchCostCenter,
			debitEntityKey: payload.debitEntityKey,
			debitEntityValue: payload.debitEntityValue,
			creditGlCode: payload.creditGlCode,
			creditBranch: payload.creditBranch,
			creditBranchCostCenter: payload.creditBranchCostCenter,
			creditEntityKey: payload.creditEntityKey,
			creditEntityValue: payload.creditEntityValue,
			expCreditGlCode: payload.expCreditGlCode,
			expCreditBranch: payload.expCreditBranch,
			expCreditBranchCostCenter: payload.expCreditBranchCostCenter,
			expEntityKey: payload.expEntityKey,
			expEntityValue: payload.expEntityValue,
			subDebitGlCode: payload.subDebitGlCode,
			subDebitBranch: payload.subDebitBranch,
			subDebitBranchCostCenter: payload.subDebitBranchCostCenter,
			subEntityKey: payload.subEntityKey,
			subEntityValue: payload.subEntityValue,
			point: payload.point.toString(),
			cashValue: payload.cashValue.toFixed(4),
			createdBy: payload.createdBy,
			createdDate: payload.createdDate,
			modifiedBy: payload.modifiedBy,
			modifiedDate: payload.modifiedDate
		}
		return ({
			...state,
			isLoading: false,
			glProvisionDetailForm: GlProvisionState.validateGlProvisionDetailForm(state)(setValue(state.glProvisionDetailForm, glProvisionDetailFormView as GlProvisionState.GlProvisionDetailForm)),
			allowDelete: payload.allowDelete,
			glProvisionDetailFormView
		})
	}),
	on(GlProvisionAction.Create, state => ({
		...state,
		isLoading: true
	})),
	on(GlProvisionAction.CreateSuccess, (state, { payload }) => ({
		...GlProvisionState.initialState
	})),
	on(GlProvisionAction.Update, state => ({
		...state,
		isLoading: true
	})),
	on(GlProvisionAction.UpdateSuccess, (state, { payload }) => ({
		...state
	})),
	on(GlProvisionAction.Delete, state => ({
		...state,
		isLoading: true
	})),
	on(GlProvisionAction.DeleteSuccess, () => GlProvisionState.initialState),
	on(GlProvisionAction.GetGlProvisionData, state => ({
		...state,
		isLoading: true
	})),
	on(GlProvisionAction.GetGlProvisionDataSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		entityTypeList: payload.entityTypeList
	})),
	on(GlProvisionAction.DuplicateDialog, state => ({
		...state,
		glProvisionDuplicateForm: reset(setValue(state.glProvisionDuplicateForm, GlProvisionState.initialGlProvisionDuplicateFormValue)),
		isDuplicateDialog: false
	})),
	on(GlProvisionAction.Duplicate, state => ({
		...state,
		isDuplicateLoading: true
	})),
	on(GlProvisionAction.DuplicateSuccess, state => ({
		...state,
		isDuplicateLoading: false,
		isDuplicateDialog: true
	})),
)

export function Reducer(state: GlProvisionState.State = GlProvisionState.initialState, action: Action) {
	const glProvisionDetailForm = GlProvisionState.validateGlProvisionDetailForm(state)(formGroupReducer(state.glProvisionDetailForm, action))
	const glProvisionDuplicateForm = GlProvisionState.validateGlProvisionDuplicateForm()(formGroupReducer(state.glProvisionDuplicateForm, action))

	if (glProvisionDetailForm !== state.glProvisionDetailForm) {
		state = { ...state, glProvisionDetailForm }
	}

	if (glProvisionDuplicateForm !== state.glProvisionDuplicateForm) {
		state = { ...state, glProvisionDuplicateForm }
	}

	return reducer(state, action)
}