<app-spinner [isLoading]="isLoading"></app-spinner>

<div class="title-div" fxLayout="row" fxLayoutAlign="space-between center">
  <span class="title"> {{ 'REDEEM_PARTNER.POINTS_CONVERSION' | translate }} </span>
  <button class="close-icon" mat-icon-button (click)="cancel()">
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-dialog-content>

  <form novalidate [ngrxFormState]="formState$ | async" fxLayout="column" fxFlex>
    <div *ngIf="isView">
      <div fxLayout="column" fxLayout.lt-md="column" fxLayoutGap="5px" fxLayoutGap.lt-md="0px">
        <mat-form-field>
          <mat-label>{{ 'REDEEM_PARTNER.REWARD_POOL_OR_REWARD_POOL_GROUP' | translate }}</mat-label>
          <input matInput value="{{getRewardValue((formState$ | async).controls.id.value)}}" type="text" maxlength="255"
            readonly>
        </mat-form-field>
      </div>
      <div *ngIf="isRewardPool == true">
        <mat-form-field fxFlex>
          <mat-label>{{ 'REDEEM_PARTNER.GL_REDEMPTION' | translate }}</mat-label>
          <input matInput value="{{getGlRedemptionValue((formState$ | async).controls.glRedemptionId.value)}}" type="text"
            maxlength="255" readonly>
        </mat-form-field>
      </div>
      <div *ngIf="isRewardPool == false">
        <div *ngFor="let redeemPartnerRewardPool of (redeemPartnerRewardPools$ | async);trackBy: trackByIndex;let i=index">
          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="5px" fxLayoutGap.lt-md="0px">
            <app-redeem-partner-reward-pool [redeemPartnerRewardPool]="redeemPartnerRewardPool" fxFlex>
            </app-redeem-partner-reward-pool>
          </div>
        </div>
      </div>

      <div fxLayout="row" fxLayout.lt-md="row" fxLayoutGap="5px" fxLayoutGap.lt-md="0px">
        <mat-form-field class="pts-rate-mx-width" fxFlex="100">
          <mat-label>{{ 'REDEEM_PARTNER.REDEMPTION_RATE' | translate }}</mat-label>
          <input matInput [ngrxFormControlState]="(formState$ | async).controls.redemptionRate" type="number" readonly />
          <mat-error *ngIf="(formState$ | async).errors._redemptionRate">
            <app-error-validation [errors]="(formState$ | async).errors._redemptionRate">
            </app-error-validation>
          </mat-error>
        </mat-form-field>

        <mat-form-field class="pts-rate-mx-width" fxFlex="100">
          <mat-label>{{ 'REDEEM_PARTNER.GL_PRODUCT_CODE' | translate }}</mat-label>
          <input matInput [ngrxFormControlState]="(formState$ | async).controls.glProductCode" type="text" readonly />
          <mat-error *ngIf="(formState$ | async).errors._glProductCode">
            <app-error-validation [errors]="(formState$ | async).errors._glProductCode">
            </app-error-validation>
          </mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayout.lt-md="row" fxLayoutGap="5px" fxLayoutGap.lt-md="0px">
        <mat-form-field class="pts-rate-mx-width">
          <mat-label>{{ 'REDEEM_PARTNER.CONVERSION_RATE' | translate }}</mat-label>
          <input matInput value="{{(formState$ | async).controls.point.value}}" type="number" maxlength="255" readonly>
          <span matSuffix>{{ 'REDEEM_PARTNER.SUFFIX.POINTS' | translate }}</span>
        </mat-form-field>
        <label class="equal">
          <br /> =
        </label>
        <mat-form-field class="pts-rate-mx-width">
          <input matInput value="{{(formState$ | async).controls.value.value}}" type="number" maxlength="255" readonly>
          <span matSuffix>{{ 'REDEEM_PARTNER.SUFFIX.PARTNER_POINTS' | translate }}</span>
        </mat-form-field>
      </div>

      <div *ngIf="(formState$ | async).controls.rounding.value" fxLayout="column">
        <mat-checkbox [ngrxFormControlState]="(formState$ | async).controls.rounding" color="primary"
          (click)="$event.preventDefault()">
          {{ 'REDEEM_PARTNER.ROUNDING' | translate }}
        </mat-checkbox>
      </div>

      <div *ngIf="(formState$ | async).controls.rounding.value" fxLayout="column">
        <mat-form-field fxFlex>
          <input matInput value="{{getRoundingValue((formState$ | async).controls.roundingValue.value)}}"
            type="text" maxlength="255" readonly>
        </mat-form-field>

        <mat-form-field fxFlex>
          <mat-label>{{ 'REDEEM_PARTNER.OPTION' | translate }}</mat-label>
          <input matInput
            value="{{getNumberDecimalValue((formState$ | async).controls.numberDecimal.value)}}" type="text"
            maxlength="255" readonly>
        </mat-form-field>

        <mat-form-field fxFlex>
          <input matInput value="{{(formState$ | async).controls.numberDecimalValue.value}}" type="text"
            maxlength="1" readonly>
        </mat-form-field>
      </div>
    </div>

    <div *ngIf="!isView">
      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="5px" fxLayoutGap.lt-md="0px">
        <mat-form-field class="minw-365px" fxFlex="100">
          <mat-label>{{ 'REDEEM_PARTNER.REWARD_POOL_OR_REWARD_POOL_GROUP' | translate }}</mat-label>
		<mat-select [ngrxFormControlState]="(formState$ | async).controls.id" (closed)="clearSearchRewardPool()"
			(selectionChange)="onChangeRewardPool($event)" required>
			<ngx-mat-select-search [formControl]="dropDownCtrlRewardPool" [placeholderLabel]="'COMMON.SEARCH'| translate"
				[noEntriesFoundLabel]="'COMMON.NOT_MATCHING_FOUND' | translate">
				<mat-icon ngxMatSelectSearchClear (click)="clearSearchRewardPool()">close</mat-icon>
			</ngx-mat-select-search>
			<mat-option *ngFor="let rewardPool of filteredRewardPool | async" [value]="rewardPool.key">
				{{rewardPool.value}}
			</mat-option>
		</mat-select>
          <mat-error *ngIf="(formState$ | async).errors._id">
            <app-error-validation [errors]="(formState$ | async).errors._id">
            </app-error-validation>
          </mat-error>
        </mat-form-field>
      </div>

      <div *ngIf="isRewardPool == true">
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="5px" fxLayoutGap.lt-md="0px">
          <mat-form-field class="minw-365px" fxFlex="100">
            <mat-label>{{ 'REDEEM_PARTNER.GL_REDEMPTION' | translate }}</mat-label>
			<mat-select [ngrxFormControlState]="(formState$ | async).controls.glRedemptionId" (closed)="clearSearchGlRedemption()">
				<ngx-mat-select-search [formControl]="dropDownCtrlGlRedemption" [placeholderLabel]="'COMMON.SEARCH'| translate"
					[noEntriesFoundLabel]="'COMMON.NOT_MATCHING_FOUND' | translate"
					>
					<mat-icon ngxMatSelectSearchClear (click)="clearSearchGlRedemption()">close</mat-icon>
				</ngx-mat-select-search>
				<mat-option *ngFor="let glRedemption of filteredGlRedemption | async" [value]="glRedemption.key">
					{{glRedemption.value}}
				</mat-option>
			</mat-select>
            <mat-error *ngIf="(formState$ | async).errors._glRedemptionId">
              <app-error-validation [errors]="(formState$ | async).errors._glRedemptionId">
              </app-error-validation>
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div *ngIf="isRewardPool == false">
        <div *ngFor="let redeemPartnerRewardPool of (redeemPartnerRewardPools$ | async);trackBy: trackByIndex;let i=index">
          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="5px" fxLayoutGap.lt-md="0px">
            <app-redeem-partner-reward-pool [redeemPartnerRewardPool]="redeemPartnerRewardPool" fxFlex>
            </app-redeem-partner-reward-pool>
          </div>
        </div>
      </div>

      <div fxLayout="row" fxLayout.lt-md="row" fxLayoutGap="5px" fxLayoutGap.lt-md="0px">
        <mat-form-field class="pts-rate-mx-width" fxFlex="100">
          <mat-label>{{ 'REDEEM_PARTNER.REDEMPTION_RATE' | translate }}</mat-label>
          <input matInput [ngrxFormControlState]="(formState$ | async).controls.redemptionRate"
            onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57 || event.charCode == 46"
            type="text" maxlength='7' required />
          <mat-error *ngIf="(formState$ | async).errors._redemptionRate">
            <app-error-validation [errors]="(formState$ | async).errors._redemptionRate">
            </app-error-validation>
          </mat-error>
        </mat-form-field>

        <mat-form-field class="pts-rate-mx-width" fxFlex="100">
          <mat-label>{{ 'REDEEM_PARTNER.GL_PRODUCT_CODE' | translate }}</mat-label>
          <input matInput [ngrxFormControlState]="(formState$ | async).controls.glProductCode" type="text" maxlength="6" required />
          <mat-error *ngIf="(formState$ | async).errors._glProductCode">
            <app-error-validation [errors]="(formState$ | async).errors._glProductCode">
            </app-error-validation>
          </mat-error>
        </mat-form-field>
      </div>

      <div *ngIf="(formState$ | async).errors._redemptionRate && (formState$ | async).controls.redemptionRate.value" style="margin-top: 10px">
      </div>
      <div fxLayout="row" fxLayout.lt-md="row" fxLayoutGap="5px" fxLayoutGap.lt-md="0px">
        <mat-form-field class="pts-rate-mx-width">
          <mat-label>{{ 'REDEEM_PARTNER.CONVERSION_RATE' | translate }}</mat-label>
          <input appTwoDecimalPlaces matInput [ngrxFormControlState]="(formState$ | async).controls.point" type="text" required />
          <span matSuffix>{{ 'REDEEM_PARTNER.SUFFIX.POINTS' | translate }}</span>
          <mat-error *ngIf="(formState$ | async).errors._point">
            <app-error-validation [errors]="(formState$ | async).errors._point">
            </app-error-validation>
          </mat-error>
        </mat-form-field>
        <label class="equal">
          <br /> =
        </label>
        <mat-form-field class="pts-rate-mx-width">
          <mat-label>&nbsp;</mat-label>
          <input appTwoDecimalPlaces matInput [ngrxFormControlState]="(formState$ | async).controls.value" type="text" />
          <span matSuffix>{{ 'REDEEM_PARTNER.SUFFIX.PARTNER_POINTS' | translate }}</span>
          <mat-error *ngIf="(formState$ | async).errors._value">
            <app-error-validation [errors]="(formState$ | async).errors._value">
            </app-error-validation>
          </mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="column">
        <mat-checkbox [ngrxFormControlState]="(formState$ | async).controls.rounding" color="primary"
          (change)="onChangeRounding()">
          {{ 'REDEEM_PARTNER.ROUNDING' | translate }}
        </mat-checkbox>
      </div>

      <div *ngIf="(formState$ | async).controls.rounding.value" fxLayout="column">
        <br>
        <mat-radio-group [ngrxFormControlState]="(formState$ | async).controls.roundingValue"
          color="primary" fxLayoutGap="8px">
          <mat-radio-button *ngFor="let i of roundingList" [value]="i.key">
            {{i.value}}
          </mat-radio-button>
        </mat-radio-group>
        <br>

        <span class="radio-button-label">
          {{ 'CAMPAIGN.OPTION' | translate }}
        </span>
        <mat-radio-group [ngrxFormControlState]="(formState$ | async).controls.numberDecimal"
          color="primary" fxLayoutGap="8px">
          <mat-radio-button *ngFor="let i of numberDecimalList" [value]="i.key">
            {{i.value}}
          </mat-radio-button>
        </mat-radio-group>

        <mat-form-field fxFlex>
          <input matInput [ngrxFormControlState]="(formState$ | async).controls.numberDecimalValue"
            onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 49 && event.charCode <= 57"
            type="text" maxlength="1">
          <mat-icon class="material-icons-outlined" matSuffix
            matTooltip="{{ 'CAMPAIGN.INFO.OPTION' | translate }}">
            info</mat-icon>
          <mat-error *ngIf="(formState$ | async).errors._numberDecimalValue">
            <app-error-validation [errors]="(formState$ | async).errors._numberDecimalValue"
              [digitFrom]="1" [digitTo]="9" [maxlength]="1">
            </app-error-validation>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </form>

</mat-dialog-content>

<div *ngIf="!isView" class="action-button-div" fxLayout="row" fxLayoutAlign="end">
  <button [disabled]="!(formState$ | async).isTouched || (formState$ | async).isInvalid || checkValidation()"
    class="mat-raised-button mat-primary next-button" (click)="submit()"
    fxFlexOffset="8px">{{ 'ACTION.SAVE' | translate }}</button>
</div>
