import { Injectable } from '@angular/core'
import { MatDialog, MatDialogConfig } from '@angular/material'
import { Router } from '@angular/router'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Action, Store } from '@ngrx/store'
import { unbox } from 'ngrx-forms'
import { Observable, of } from 'rxjs'
import { catchError, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators'
import { CampaignDuplicateComponent } from 'src/app/components/campaign-management/campaign/campaign-duplicate/campaign-duplicate.component'
import { RulesetComponent } from 'src/app/components/campaign-management/campaign/rule/ruleset/ruleset.component'
import { MessageDialogComponent } from 'src/app/components/common/dialog/message-dialog/message-dialog.component'
import { SelectDialogComponent } from 'src/app/components/common/dialog/select-dialog/select-dialog.component'
import { CampaignTasklist, RuleAddType, Ruleset } from 'src/app/models/campaign-management/campaign/campaign'
import { CampaignDataResponse } from 'src/app/models/campaign-management/campaign/campaign-data'
import { CampaignDraftListResponse, CampaignPbDraftListResponse, CampaignPbPublishedListResponse, CampaignPbUnpublishedListResponse, CampaignPublishedListResponse, CampaignUnpublishedListResponse } from 'src/app/models/campaign-management/campaign/campaign-list'
import { CampaignViewResponse } from 'src/app/models/campaign-management/campaign/campaign-view'
import { RuleListResponse } from 'src/app/models/campaign-management/campaign/rule-list'
import { RulesetDataResponse } from 'src/app/models/campaign-management/campaign/ruleset-data'
import { POLICY } from 'src/app/models/common/constant'
import { getDialogConfig } from 'src/app/models/common/dialog'
import { Response } from 'src/app/models/common/http'
import { Util } from 'src/app/models/util/util'
import { CampaignManagementService } from 'src/app/services/campaign-management/campaign-management.service'
import * as AppStore from 'src/app/store/'
import * as CommonAction from '../../common/common/common.actions'
import * as CampaignAction from './campaign.actions'
import { State } from './campaign.state'
import { TypedAction } from '@ngrx/store/src/models'
import { RulesetDuplicateComponent } from 'src/app/components/campaign-management/campaign/rule/ruleset-duplicate/ruleset-duplicate.component'

@Injectable()
export class Effects {

	constructor(
		private action$: Actions,
		private router: Router,
		private dialog: MatDialog,
		private campaignManagementService: CampaignManagementService,
		private store: Store<AppStore.State>,
	) { }

	InitialState$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CampaignAction.InitialState),
			tap(() => {
				this.router.navigate(['campaign-management/campaign'])
			})
		), { dispatch: false }
	)

	GoList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CampaignAction.GoList),
			tap(({ tab }) => {
				this.router.navigateByUrl('/', { skipLocationChange: true })
					.then(() => {
						this.router.navigate(['campaign-management/campaign'], { queryParams: { tabIndex: tab } })
					})
			})
		), { dispatch: false }
	)

	GoCreate$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CampaignAction.GoCreate),
			tap(() => {
				this.router.navigate(['campaign-management/campaign/create'])
			})
		), { dispatch: false }
	)

	GoUpdate$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CampaignAction.GoUpdate),
			tap(() => {
				this.router.navigate(['campaign-management/campaign/update'])
			})
		), { dispatch: false }
	)

	GoPublishedView$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CampaignAction.GoPublishedView),
			tap(() => {
				this.router.navigate(['campaign-management/campaign/published-view'])
			})
		), { dispatch: false }
	)

	GoRepublishedView$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CampaignAction.GoRepublishedView),
			tap(() => {
				this.router.navigate(['campaign-management/campaign/republished-view'])
			})
		), { dispatch: false }
	)

	GoTasklistCampaignRepublishedView$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CampaignAction.GoTasklistCampaignRepublishedView),
			tap(() => {
				this.router.navigate(['worklist/tasklist/campaign-republished-view'])
			})
		), { dispatch: false }
	)

	GoMyRequestCampaignRepublishedView$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CampaignAction.GoMyRequestCampaignRepublishedView),
			tap(() => {
				this.router.navigate(['worklist/my-request/campaign-republished-view'])
			})
		), { dispatch: false }
	)

	GoUnpublishedView$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CampaignAction.GoUnpublishedView),
			tap(() => {
				this.router.navigate(['campaign-management/campaign/unpublished-view'])
			})
		), { dispatch: false }
	)

	GoPublishedUpdate$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CampaignAction.GoPublishedUpdate),
			tap(() => {
				this.router.navigate(['campaign-management/campaign/published-update'])
			})
		), { dispatch: false }
	)

	GoCreateProductBundle$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CampaignAction.GoCreateProductBundle),
			tap(() => {
				this.router.navigate(['campaign-management/campaign/create-product-bundle'])
			})
		), { dispatch: false }
	)

	GoUpdateProductBundle$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CampaignAction.GoUpdateProductBundle),
			tap(() => {
				this.router.navigate(['campaign-management/campaign/update-product-bundle'])
			})
		), { dispatch: false }
	)

	GoSimulateProductBundle$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CampaignAction.GoSimulateProductBundle),
			tap(() => {
				this.router.navigate(['campaign-management/campaign/simulate-product-bundle'])
			})
		), { dispatch: false }
	)

	GoPublishedViewProductBundle$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CampaignAction.GoPublishedViewProductBundle),
			tap(() => {
				this.router.navigate(['campaign-management/campaign/published-view-product-bundle'])
			})
		), { dispatch: false }
	)

	GoRepublishedViewProductBundle$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CampaignAction.GoRepublishedViewProductBundle),
			tap(() => {
				this.router.navigate(['campaign-management/campaign/republished-view-product-bundle'])
			})
		), { dispatch: false }
	)

	GoTasklistProductBundleRepublishedView$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CampaignAction.GoTasklistProductBundleRepublishedView),
			tap(() => {
				this.router.navigate(['worklist/tasklist/product-bundle-republished-view'])
			})
		), { dispatch: false }
	)

	GoMyRequestProductBundleRepublishedView$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CampaignAction.GoMyRequestProductBundleRepublishedView),
			tap(() => {
				this.router.navigate(['worklist/my-request/product-bundle-republished-view'])
			})
		), { dispatch: false }
	)

	GoUnpublishedViewProductBundle$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CampaignAction.GoUnpublishedViewProductBundle),
			tap(() => {
				this.router.navigate(['campaign-management/campaign/unpublished-view-product-bundle'])
			})
		), { dispatch: false }
	)

	GoBackDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CampaignAction.GoBackDialog),
			mergeMap(({ action }) => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.BACK', payload: action }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					if (result.payload === 'LIST') {
						return CampaignAction.GoList({ tab: 1 })
					}
				} else {
					return CampaignAction.CloseDialog()
				}
			})
		)
	)

	RestError$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CampaignAction.RestError),
			map(({ message, manualThrow }) => {
				return CommonAction.RestError({ message, manualThrow })
			})
		)
	)

	List$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CampaignAction.List),
			mergeMap(({ params }) => {
				return this.campaignManagementService.getCampaignPublishedList(params).pipe(
					map((response: Response) => {
						const success: boolean = response.success
						const payload: CampaignPublishedListResponse = response.payload

						if (success) {
							return CampaignAction.ListSuccess({ payload })
						} else { return CampaignAction.RestError(response as any) }
					}), catchError(this.catcher)
				)
			})
		)
	)

	DraftList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CampaignAction.DraftList),
			mergeMap(({ params }) => {
				return this.campaignManagementService.getCampaignDraftList(params).pipe(
					map((response: Response) => {
						const success: boolean = response.success
						const payload: CampaignDraftListResponse = response.payload
						if (success) {
							return CampaignAction.DraftListSuccess({ payload })
						} else { return CampaignAction.RestError(response as any) }

					}), catchError(this.catcher)
				)
			})
		)
	)

	UnpublishedList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CampaignAction.UnpublishedList),
			mergeMap(({ params }) => {
				return this.campaignManagementService.getCampaignUnpublishedList(params).pipe(
					map((response: Response) => {
						const success: boolean = response.success
						const payload: CampaignUnpublishedListResponse = response.payload

						if (success) {
							return CampaignAction.UnpublishedListSuccess({ payload })
						} else { return CampaignAction.RestError(response as any) }
					}), catchError(this.catcher)
				)
			})
		)
	)

	PbList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CampaignAction.PbList),
			mergeMap(({ params }) => {
				return this.campaignManagementService.getCampaignPbPublishedList(params).pipe(
					map((response: Response) => {
						const success: boolean = response.success
						const payload: CampaignPbPublishedListResponse = response.payload

						if (success) {
							return CampaignAction.PbListSuccess({ payload })
						} else { return CampaignAction.RestError(response as any) }
					}), catchError(this.catcher)
				)
			})
		)
	)

	PbDraftList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CampaignAction.PbDraftList),
			mergeMap(({ params }) => {
				return this.campaignManagementService.getCampaignPbDraftList(params).pipe(
					map((response: Response) => {
						const success: boolean = response.success
						const payload: CampaignPbDraftListResponse = response.payload
						if (success) {
							return CampaignAction.PbDraftListSuccess({ payload })
						} else { return CampaignAction.RestError(response as any) }

					}), catchError(this.catcher)
				)
			})
		)
	)

	PbUnpublishedList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CampaignAction.PbUnpublishedList),
			mergeMap(({ params }) => {
				return this.campaignManagementService.getCampaignPbUnpublishedList(params).pipe(
					map((response: Response) => {
						const success: boolean = response.success
						const payload: CampaignPbUnpublishedListResponse = response.payload

						if (success) {
							return CampaignAction.PbUnpublishedListSuccess({ payload })
						} else { return CampaignAction.RestError(response as any) }
					}), catchError(this.catcher)
				)
			})
		)
	)

	View$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CampaignAction.View),
			withLatestFrom(this.store.select(state => state.campaign)),
			mergeMap(([x, campaign]) => this.campaignManagementService.viewCampaign({ code: campaign.code, checkerMakerId: campaign.checkerMakerId ? campaign.checkerMakerId.toString() : null, policy: x.payload as POLICY }).pipe(
				map((response: Response) => {
					const success: boolean = response.success
					const payload: CampaignViewResponse = response.payload

					if (success) {
						return CampaignAction.ViewSuccess({ payload })
					} else { return CampaignAction.RestError(response as any) }

				}), catchError(this.catcher)
			))
		)
	)

	CreateDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CampaignAction.CreateDialog),
			mergeMap(() => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.CREATE_CAMPAIGN' }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return CampaignAction.Create()
				} else {
					return CampaignAction.CloseDialog()
				}
			})
		)
	)

	Create$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CampaignAction.Create),
			withLatestFrom(this.store.select(state => state.campaign)),
			mergeMap(([, campaign]) => this.campaignManagementService.createCampaign(this.processRequest(campaign))
				.pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload = response.payload
					if (success) {
						return CampaignAction.CreateSuccess({
							code: campaign.informationDetailForm.controls.code.value, payload
						})
					} else { return CampaignAction.RestError(response as any) }

				}), catchError(this.catcher)))
		)
	)

	CreateSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CampaignAction.CreateSuccess),
			withLatestFrom(this.store.select(state => state.campaign)),
			mergeMap(([{ payload }, campaign]) => {
				let navigate: TypedAction<string>
				if (campaign.kind === 'C') {
					navigate = CampaignAction.GoUpdate()
				}
				if (campaign.kind === 'PB') {
					navigate = CampaignAction.GoUpdateProductBundle()
				}
				return [
					navigate,
					CommonAction.RestError({ message: payload })
				]
			})
		)
	)

	UpdateDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CampaignAction.UpdateDialog),
			mergeMap(() => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.UPDATE' }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return CampaignAction.Update()
				} else {
					return CampaignAction.CloseDialog()
				}
			})
		)
	)

	Update$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CampaignAction.Update),
			withLatestFrom(this.store.select(state => state.campaign)),
			mergeMap(([, campaign]) => this.campaignManagementService.updateCampaign(this.processRequest(campaign))
				.pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload = response.payload
					if (success) {
						return CampaignAction.UpdateSuccess({
							code: campaign.informationDetailForm.controls.code.value, payload
						})
					} else { return CampaignAction.RestError(response as any) }
				}), catchError(this.catcher)))
		)
	)

	UpdateSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CampaignAction.UpdateSuccess),
			withLatestFrom(this.store.select(state => state.campaign)),
			mergeMap(([params, campaign]) => {
				if (campaign.kind === 'PB') {
					return [CampaignAction.View({ payload: POLICY.CAMPAIGN_BUNDLING_CREATE }), CommonAction.RestError({ message: params.payload })]
				} else {
					return [CampaignAction.View({ payload: POLICY.CAMPAIGN_CREATE }), CommonAction.RestError({ message: params.payload })]
				}
			})
		)
	)

	DeleteDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CampaignAction.DeleteDialog),
			mergeMap(({ returnTab }) => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.DELETE_CAMPAIGN', payload: { returnTab } }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return CampaignAction.Delete({ tab: result.payload.returnTab })
				} else {
					return CampaignAction.CloseDialog()
				}
			})
		)
	)

	Delete$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CampaignAction.Delete),
			withLatestFrom(this.store.select(state => state.campaign)),
			mergeMap(([param, campaign]) => {
				return this.campaignManagementService.deleteCampaign({
					code: campaign.code
				}).pipe(
					map((response: Response) => {
						const success: boolean = response.success

						if (success) {
							return CampaignAction.DeleteSuccess({ tab: param.tab })
						} else { return CampaignAction.RestError(response as any) }

					}, catchError(this.catcher))
				)
			})
		)
	)

	DeleteSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CampaignAction.DeleteSuccess),
			map(({ tab }) => {
				return CampaignAction.GoList({ tab })
			})
		)
	)

	EnableConditionalRewardDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CampaignAction.EnableConditionalRewardDialog),
			mergeMap(() => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.ENABLE_CONDITIONAL_REWARD_CAMPAIGN' }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return CampaignAction.OnChangeEnableConditionalRewardSucccess()
				} else {
					return CampaignAction.OnChangeEnableConditionalRewardFail()
				}
			})
		)
	)

	RemoveRulesetDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CampaignAction.RemoveRulesetDialog),
			mergeMap(({ id }) => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({
					content: 'DIALOG.DELETE_RULESET', payload: { id }
				}))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return CampaignAction.RemoveRuleset({
						id: result.payload.id
					})
				} else {
					return CampaignAction.CloseDialog()
				}
			})
		)
	)

	PublishDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CampaignAction.PublishDialog),
			mergeMap(() => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.PUBLISH_CAMPAIGN' }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return CampaignAction.Publish()
				} else {
					return CampaignAction.CloseDialog()
				}
			})
		)
	)

	Publish$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CampaignAction.Publish),
			withLatestFrom(this.store.select(state => state.campaign)),
			mergeMap(([, campaign]) => {
				return this.campaignManagementService.publishCampaign({
					code: campaign.code
				}).pipe(
					map((response: Response) => {
						const success: boolean = response.success

						if (success) {
							return CampaignAction.PublishSuccess()
						} else { return CampaignAction.RestError(response as any) }

					}), catchError(this.catcher)
				)
			})
		)
	)

	PublishSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CampaignAction.PublishSuccess),
			withLatestFrom(this.store.select(state => state.campaign)),
			mergeMap(([, campaign]) => {
				if (campaign.kind === 'C') {
					return [CampaignAction.InitialState(), CampaignAction.DraftList({ params: campaign.paginatorMetadata.DraftList })]
				} else {
					return [CampaignAction.InitialState(), CampaignAction.PbDraftList({ params: campaign.paginatorMetadata.PbDraftList })]
				}
			})
		)
	)

	RepublishDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CampaignAction.RepublishDialog),
			mergeMap(() => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.UPDATE' }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return CampaignAction.Republish()
				} else {
					return CampaignAction.CloseDialog()
				}
			})
		)
	)

	Republish$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CampaignAction.Republish),
			withLatestFrom(this.store.select(state => state.campaign)),
			mergeMap(([, campaign]) => this.campaignManagementService.republishCampaign(this.processRequest(campaign))
				.pipe(map((response: Response) => {
					const success: boolean = response.success
					if (success) {
						return CampaignAction.RepublishSuccess()
					} else { return CampaignAction.RestError(response as any) }
				}), catchError(this.catcher)))
		)
	)

	RepublishSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CampaignAction.RepublishSuccess),
			withLatestFrom(this.store.select(state => state.campaign)),
			mergeMap(([, campaign]) => {
				if (campaign.kind === 'C') {
					return [CampaignAction.GoPublishedView({ action: 'CAMPAIGN_VIEW' })]
				} else if (campaign.kind === 'PB') {
					return [CampaignAction.GoPublishedViewProductBundle({ action: 'PB_CAMPAIGN_VIEW' })]
				}
			})
		)
	)

	TerminateDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CampaignAction.TerminateDialog),
			mergeMap(() => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.TERMINATE_CAMPAIGN' }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return CampaignAction.Terminate()
				} else {
					return CampaignAction.CloseDialog()
				}
			})
		)
	)

	Terminate$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CampaignAction.Terminate),
			withLatestFrom(this.store.select(state => state.campaign)),
			mergeMap(([, campaign]) => {
				return this.campaignManagementService.terminateCampaign({
					code: campaign.code,
					kind: campaign.kind
				}).pipe(
					map((response: Response) => {
						const success: boolean = response.success

						if (success) {
							return CampaignAction.TerminateSuccess()
						} else { return CampaignAction.RestError(response as any) }

					}), catchError(this.catcher)
				)
			})
		)
	)

	TerminateSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CampaignAction.TerminateSuccess),
			withLatestFrom(this.store.select(state => state.campaign)),
			mergeMap(([, campaign]) => {
				return [
					CampaignAction.List({ params: campaign.paginatorMetadata.List }),
					CampaignAction.DraftList({ params: campaign.paginatorMetadata.DraftList }),
					CampaignAction.UnpublishedList({ params: campaign.paginatorMetadata.UnpublishedList }),
					CampaignAction.PbList({ params: campaign.paginatorMetadata.PbList }),
					CampaignAction.PbDraftList({ params: campaign.paginatorMetadata.PbDraftList }),
					CampaignAction.PbUnpublishedList({ params: campaign.paginatorMetadata.PbUnpublishedList })
				]
			})
		)
	)

	DuplicateDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CampaignAction.DuplicateDialog),
			mergeMap(() => {
				const dialogRef = this.dialog.open(CampaignDuplicateComponent, getDialogConfig({}))
				return dialogRef.afterClosed()
			}),
			map(result => {
				return CampaignAction.CloseDialog()
			})
		)
	)

	DuplicateRulesetDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CampaignAction.DuplicateRulesetDialog),
			mergeMap(() => {
				const dialogRef = this.dialog.open(RulesetDuplicateComponent, getDialogConfig({}))
				return dialogRef.afterClosed()
			}),
			map(result => {
				return CampaignAction.CloseDialog()
			})
		)
	)

	Duplicate$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CampaignAction.Duplicate),
			withLatestFrom(this.store.select(state => state.campaign)),
			mergeMap(([x, campaign]) => {
				const campaignDuplicateForm = campaign.campaignDuplicateForm.value

				return this.campaignManagementService.duplicateCampaign(campaign.code, {
					code: campaignDuplicateForm.code,
					name: campaignDuplicateForm.name
				}, { policy: x.payload as POLICY }).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload = response.payload
					if (success) {
						return CampaignAction.DuplicateSuccess({ payload })
					} else {
						return CampaignAction.RestError(response as any)
					}
				}), catchError(this.catcher))
			})
		)
	)

	DuplicateSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CampaignAction.DuplicateSuccess),
			withLatestFrom(this.store.select(state => state.campaign)),
			mergeMap(([{ payload }, campaign]) => {
				return [
					CampaignAction.List({ params: campaign.paginatorMetadata.List }),
					CampaignAction.DraftList({ params: campaign.paginatorMetadata.DraftList }),
					CampaignAction.UnpublishedList({ params: campaign.paginatorMetadata.UnpublishedList }),
					CampaignAction.PbList({ params: campaign.paginatorMetadata.PbList }),
					CampaignAction.PbDraftList({ params: campaign.paginatorMetadata.PbDraftList }),
					CampaignAction.PbUnpublishedList({ params: campaign.paginatorMetadata.PbUnpublishedList }),
					CommonAction.RestError({ message: payload })
				]
			})
		)
	)

	DuplicateRulesetSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CampaignAction.DuplicateRulesetSuccess),
			mergeMap(({ isDuplicate }) => {
				if (isDuplicate) {
					return [CampaignAction.RestError({ message: 'Ruleset code or name existed / utilized', manualThrow: isDuplicate })]
				} else {
					return [
						CampaignAction.CloseDialog()
					]
				}
			})
		)
	)

	ViewRepublishCampaign$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CampaignAction.ViewRepublishCampaign),
			withLatestFrom(this.store.select(state => state.campaign)),
			mergeMap(([x, campaign]) => {
				return this.campaignManagementService.getCampaignRepublishDetail({
					code: campaign.code,
					checkerMakerId: campaign.checkerMakerId ? campaign.checkerMakerId.toString() : null,
					policy: x.policy as POLICY
				}).pipe(
					map((response: Response) => {
						const success: boolean = response.success
						const payload: CampaignTasklist = response.payload

						if (success) {
							return CampaignAction.ViewRepublishCampaignSuccess({ payload })
						} else { return CampaignAction.RestError(response as any) }

					}), catchError(this.catcher)
				)
			})
		)
	)

	OnChangeChannelGetRulesetList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CampaignAction.OnChangeChannelGetRulesetList),
			mergeMap(({ resourceCode }) => {
				return this.campaignManagementService.getRuleList({ resourceCode }).pipe(
					mergeMap((response: Response) => {
						const success: boolean = response.success
						const payload: RuleListResponse[] = response.payload

						if (success) {
							return [CampaignAction.OnChangeChannelGetRulesetListSuccess({ payload }),
							CampaignAction.OnChangeChannelGetRulesetData({ resourceCode })]
						} else { return [CampaignAction.RestError(response as any)] }
					}), catchError(this.catcher)
				)
			})
		)
	)

	OnChangeChannelGetRulesetData$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CampaignAction.OnChangeChannelGetRulesetData),
			withLatestFrom(this.store.select(state => state.campaign)),
			mergeMap(([{ resourceCode }, { kind }]) => {
				const isCampaignBundling = (kind === 'PB' ? true : false)
				return this.campaignManagementService.getRulesetData({ resourceCode, isCampaignBundling }).pipe(
					map((response: Response) => {
						const success: boolean = response.success
						const payload: RulesetDataResponse = response.payload

						if (success) {
							return CampaignAction.OnChangeChannelGetRulesetDataSuccess({ payload })
						} else { return CampaignAction.RestError(response as any) }
					}), catchError(this.catcher)
				)
			})
		)
	)

	OnChangeChannelGetRulesetDataSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CampaignAction.OnChangeChannelGetRulesetDataSuccess),
			map(() => {
				return CampaignAction.OnChangeChannelValue()
			})
		)
	)

	GetCampaignData$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CampaignAction.GetCampaignData),
			mergeMap(({ payload }) => {
				return this.campaignManagementService.getCampaignData({ policy: payload as POLICY }).pipe(
					map((response: Response) => {
						const success: boolean = response.success
						const payload: CampaignDataResponse = response.payload

						if (success) {
							return CampaignAction.GetCampaignDataSuccess({ payload })
						} else { return CampaignAction.RestError(response as any) }

					}), catchError(this.catcher)
				)
			})
		)
	)

	NewRulesetDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CampaignAction.NewRulesetDialog),
			withLatestFrom(this.store.select(state => state.campaign)),
			mergeMap(() => {
				const dialogConfig = new MatDialogConfig()
				dialogConfig.disableClose = true
				dialogConfig.panelClass = 'ruleset-dialog'

				const dialogRef = this.dialog.open(RulesetComponent, dialogConfig)
				return dialogRef.afterClosed()
			}),
			map(() => {
				return CampaignAction.CloseDialog()
			})
		)
	)

	AddRulesetDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CampaignAction.AddRulesetDialog),
			withLatestFrom(this.store.select(state => state.campaign)),
			mergeMap(([, campaign]) => {
				const rulesets = campaign.rulesetDetailForm.controls.rulesets.value
				const idList = []

				rulesets.allIds.forEach((key) => {
					if (rulesets.byId.hasOwnProperty(key)) {
						const rule = rulesets.byId[key].rule
						if (rule) {
							idList.push(rule.keyValue.key)
						}
					}
				})

				const dialogRef = this.dialog.open(SelectDialogComponent, getDialogConfig({
					title: 'CAMPAIGN.ADD_RULE',
					content: 'CAMPAIGN.RULE',
					payload: campaign.ruleList.filter(x => {
						const data = idList.find(
							key => key === x.keyValue.key
						)
						if (!data) { return x }
					})
				}))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return CampaignAction.AddRuleset({ payload: result })
				} else {
					return CampaignAction.CloseDialog()
				}
			})
		)
	)

	AddRulesetChildDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CampaignAction.AddRulesetChildDialog),
			withLatestFrom(this.store.select(state => state.campaign)),
			mergeMap(([, campaign]) => {
				const rulesetChildIds = campaign.ruleCondition.rulesetChildIds
				const rulesetChilds = campaign.rulesetDetailForm.controls.rulesetChilds.value
				const idList = []

				const allIds = rulesetChildIds.filter(x => rulesetChildIds.includes(x))
				allIds.forEach((key) => {
					if (rulesetChilds.byId.hasOwnProperty(key)) {
						const rule = rulesetChilds.byId[key].rule
						if (rule) {
							idList.push(rule.keyValue.key)
						}
					}
				})

				const dialogRef = this.dialog.open(SelectDialogComponent, getDialogConfig({
					title: 'CAMPAIGN.ADD_RULE',
					content: 'CAMPAIGN.RULE',
					payload: campaign.ruleList
						.filter(x => x.keyValue.key !== 'CHOOSE_DATE')
						.filter(x => {
							const data = idList.find(
								key => key === x.keyValue.key
							)

							if (!data) { return x }
						})
				}))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return CampaignAction.AddRulesetChild({ payload: result })
				} else {
					return CampaignAction.CloseDialog()
				}
			})
		)
	)

	GetRuleList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CampaignAction.GetRuleList),
			withLatestFrom(this.store.select(state => state.campaign)),
			mergeMap(([params, campaign]) => {
				return this.campaignManagementService.getRuleList({ resourceCode: '' }).pipe(
					mergeMap((response: Response) => {
						const success: boolean = response.success
						const payload: RuleListResponse[] = response.payload

						if (success) {
							return [CampaignAction.GetRuleListSuccess({ payload }),
							CampaignAction.View({ payload: params.policy })]
						} else { return [CampaignAction.RestError(response as any)] }
					}), catchError(this.catcher)
				)
			})
		)
	)

	SetupRuleset$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CampaignAction.SetupRuleset),
			map(() => {
				return CampaignAction.RulesetGetRuleList()
			})
		)
	)

	RulesetGetRuleList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CampaignAction.RulesetGetRuleList),
			withLatestFrom(this.store.select(state => state.campaign)),
			mergeMap(([, campaign]) => {
				const id = campaign.campaignRulesetId
				const campaignRuleset = campaign.rulesetListForm.controls.campaignRulesetList.value.find(x => x.id === id)
				const resourceCode = campaignRuleset.rulesetDetailForm.channel

				return this.campaignManagementService.getRuleList({ resourceCode }).pipe(
					mergeMap((response: Response) => {
						const success: boolean = response.success
						const payload: RuleListResponse[] = response.payload

						if (success) {
							return [CampaignAction.RulesetGetRuleListSuccess({ payload }),
							CampaignAction.RulesetGetRulesetData({ resourceCode })]
						} else { return [CampaignAction.RestError(response as any)] }
					}), catchError(this.catcher)
				)
			})
		)
	)

	RulesetGetRulesetData$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CampaignAction.RulesetGetRulesetData),
			withLatestFrom(this.store.select(state => state.campaign)),
			mergeMap(([{ resourceCode }, campaign]) => {
				const id = campaign.campaignRulesetId
				const action = campaign.campaignRulesetAction
				const isCampaignBundling = (campaign.kind === 'PB' ? true : false)
				return this.campaignManagementService.getRulesetData({ resourceCode, isCampaignBundling }).pipe(
					mergeMap((response: Response) => {
						const success: boolean = response.success
						const payload: RulesetDataResponse = response.payload

						if (success) {
							if (action === 'VIEW') {
								return [CampaignAction.RulesetGetRulesetDataSuccess({ payload }),
								CampaignAction.ViewRuleset({ id }),
								CampaignAction.RulesetDialog()]
							}
							if (action === 'UPDATE' || action === 'PUBLISHED_UPDATE') {
								return [CampaignAction.RulesetGetRulesetDataSuccess({ payload }),
								CampaignAction.UpdateRuleset({ id }),
								CampaignAction.RulesetDialog()]
							}
						} else { return [CampaignAction.RestError(response as any)] }

					}), catchError(this.catcher)
				)
			})
		)
	)

	RulesetDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CampaignAction.RulesetDialog),
			mergeMap(() => {
				const dialogConfig = new MatDialogConfig()
				dialogConfig.disableClose = true
				dialogConfig.panelClass = 'ruleset-dialog'

				const dialogRef = this.dialog.open(RulesetComponent, dialogConfig)
				return dialogRef.afterClosed()
			}),
			map(() => {
				return CampaignAction.CloseDialog()
			})
		)
	)

	catcher = (error) => {
		return (of(CampaignAction.RestError({ message: error.message, manualThrow: error.manualThrow })))
	}

	processForm(id: string, ruleAddType: RuleAddType, rule: string, form: any) {
		let condition: Ruleset = {
			id,
			ruleAddType,
			rule
		}

		if (!form) { return condition }

		switch (rule) {
			case 'VIP_IND':
			case 'STAFF_IND':
			case 'RECURRING_IND':
			case 'E-COMMERCE_IND':
			case 'SEGMENT':
			case 'STAFF_IND_ACC':
			case 'INDICATOR_1':
			case 'INDICATOR_2':
			case 'INDICATOR_3':
			case 'INDICATOR_4':
			case 'INDICATOR_5':
			case 'INDICATOR_6':
			case 'INDICATOR_7':
				condition = {
					...condition,
					value: form.value
				}
				break
			case 'TRANSACTION_AMOUNT':
			case 'APPROVAL_CODE':
			case 'TRANSACTION_DESCRIPTION':
			case 'POS_ENTRY_MODE':
			case 'TRANSACTION_MODE':
			case 'POSTING_CURRENCY_CODE':
			case 'TRANSACTION_CURRENCY_CODE':
			case 'CUSTOMER_BLOCK_CODE':
			case 'ACCOUNT_BLOCK_CODE':
			case 'CARD_BLOCK_CODE':
			case 'DESCRIPTION_1':
			case 'DESCRIPTION_2':
			case 'DESCRIPTION_3':
			case 'DESCRIPTION_4':
			case 'DESCRIPTION_5':
			case 'DESCRIPTION_6':
			case 'DESCRIPTION_7':
			case 'BALANCE_1':
			case 'BALANCE_2':
			case 'BALANCE_3':
			case 'BALANCE_4':
			case 'BALANCE_5':
			case 'BALANCE_6':
			case 'BALANCE_7':
			case 'AMOUNT_1':
			case 'AMOUNT_2':
			case 'AMOUNT_3':
			case 'AMOUNT_4':
			case 'AMOUNT_5':
			case 'AMOUNT_6':
			case 'AMOUNT_7':
				condition = {
					...condition,
					equation: form.equation,
					value: form.value
				}
				break
			case 'MEMBER_SINCE':
				condition = {
					...condition,
					equation: form.equation,
					value: Util.fromISOStringToDateStr(form.value)
				}
				break
			case 'PRODUCT_TYPE':
				condition = {
					...condition,
					equation: form.equation,
					values: [
						unbox(form.value.value).toString(),
						unbox(form.values.value).toString()
					]
				}
				break
			case 'MCC':
			case 'MERCHANT':
			case 'TARGET_CUSTOMER':
			case 'TARGET_ACCOUNT':
			case 'TARGET_CARD':
				condition = {
					...condition,
					equation: form.equation,
					values: [
						form.value,
						unbox(form.values.value).toString()
					]
				}
				break
			case 'TRANSACTION_CODE':
				condition = {
					...condition,
					equation: form.equation,
					values: [
						form.value,
						unbox(form.values.value).toString(),
						form.isCheck,
						unbox(form.reversalValues.value).toString()
					]
				}
				break
			case 'CARD_STATUS':
			case 'ACCOUNT_STATUS':
			case 'COUNTRY_CODE':
			case 'CUSTOMER_STATUS':
				condition = {
					...condition,
					equation: form.equation,
					value: unbox(form.value.value).toString()
				}
				break
			case 'CHOOSE_DATE':
				condition = {
					...condition,
					values: [form.value, form.time]
				}
				break
			case 'BIRTHDAY':
				condition = {
					...condition,
					values: [form.value, form.xDaysBefore, form.xDaysAfter]
				}
				break
			case 'DATE_1':
			case 'DATE_2':
			case 'DATE_3':
			case 'DATE_4':
			case 'DATE_5':
			case 'DATE_6':
			case 'DATE_7':
			case 'COMPARISON_DATE':
				condition = {
					...condition,
					equation: form.equation,
					values: [form.comparisonDate, form.value]
				}
				break
			case 'SELECTED_DAY':
				const value = form.value
				const daily = form.daily
				const monday = form.weekly.monday
				const tuesday = form.weekly.tuesday
				const wednesday = form.weekly.wednesday
				const thursday = form.weekly.thursday
				const friday = form.weekly.friday
				const saturday = form.weekly.saturday
				const sunday = form.weekly.sunday
				const monthly = form.monthly

				if (value === 'D') {
					condition = {
						...condition,
						values: [
							value,
							`${daily.startTime},${daily.endTime}`
						]
					}
				}
				if (value === 'W') {
					condition = {
						...condition,
						values: [
							value,
							`${monday.isCheck},${monday.startTime},${monday.endTime}`,
							`${tuesday.isCheck},${tuesday.startTime},${tuesday.endTime}`,
							`${wednesday.isCheck},${wednesday.startTime},${wednesday.endTime}`,
							`${thursday.isCheck},${thursday.startTime},${thursday.endTime}`,
							`${friday.isCheck},${friday.startTime},${friday.endTime}`,
							`${saturday.isCheck},${saturday.startTime},${saturday.endTime}`,
							`${sunday.isCheck},${sunday.startTime},${sunday.endTime}`
						]
					}
				}
				if (value === 'M') {
					condition = {
						...condition,
						values: [
							value,
							`${monthly.day},${monthly.startTime},${monthly.endTime}`
						]
					}
				}

				break

		}

		return condition
	}

	processRequest(campaign: State) {
		const kind = campaign.kind
		const informationDetailFormVal = campaign.informationDetailForm.value
		const rulesets = []
		const rulesetListFormVal = campaign.rulesetListForm.value
		const capping = campaign.cappingForm.value

		rulesetListFormVal.campaignRulesetList.forEach(({
			id,
			rulesetDetailForm,
			rulesetRewardForm
		}) => {
			const rulesetsVal = rulesetDetailForm.rulesets
			const ruleConditionsVal = rulesetDetailForm.ruleConditions
			const rulesetChildsVal = rulesetDetailForm.rulesetChilds
			const conditions = []

			if (rulesetsVal) {
				rulesetsVal.allIds.forEach(rulesetId => {
					const conditionGroups = []

					if (rulesetsVal.byId.hasOwnProperty(rulesetId)) {
						const byId = rulesetsVal.byId[rulesetId]
						const ruleAddType = byId.ruleAddType

						if (ruleAddType === 'RULE') {
							conditions.push(
								this.processForm(
									rulesetId,
									ruleAddType,
									byId.rule.keyValue.key,
									byId.form.value
								)
							)
						}

						if (ruleAddType === 'CONDITIONAL_RULES') {
							byId.ruleConditionIds.forEach(ruleConditionId => {
								const rulesetChildList = []

								if (ruleConditionsVal.byId.hasOwnProperty(ruleConditionId)) {
									const ruleConditionsById = ruleConditionsVal.byId[ruleConditionId]

									ruleConditionsById.rulesetChildIds.forEach(rulesetChildId => {
										if (rulesetChildsVal.byId.hasOwnProperty(rulesetChildId)) {
											const rulesetChildsById = rulesetChildsVal.byId[rulesetChildId]

											rulesetChildList.push(
												this.processForm(
													rulesetChildId,
													rulesetChildsById.ruleAddType,
													rulesetChildsById.rule.keyValue.key,
													rulesetChildsById.form.value
												)
											)
										}
									})

									conditionGroups.push({
										id: ruleConditionId,
										conditions: rulesetChildList
									})
								}
							})

							conditions.push({
								id: rulesetId,
								ruleAddType,
								conditionGroups
							})
						}
					}
				})
			}

			const rewardPool = rulesetRewardForm.rewardPool
			const amountField = rulesetRewardForm.amountField
			const type = rulesetRewardForm.type
			const rewardCreditMethod = rulesetRewardForm.rewardCreditMethod
			const transactionMethod = rulesetRewardForm.transactionMethod
			const rewardMethod = rulesetRewardForm.rewardMethod
			const conditionFactor = rulesetRewardForm.conditionFactor
			const valueFactor = rulesetRewardForm.valueFactor
			const pointFactor = rulesetRewardForm.pointFactor
			const accumulationCycleType = rulesetRewardForm.accumulationCycleType === 'B' ? rulesetRewardForm.billingCycleType : rulesetRewardForm.accumulationCycleType
			const accumulationCycleDay = rulesetRewardForm.accumulationCycleType === 'B' ? rulesetRewardForm.numberOfDays : rulesetRewardForm.accumulationCycleDay
			const accumulationCycleMonth = rulesetRewardForm.accumulationCycleMonth
			const accumulationCycleTypeOption = rulesetRewardForm.accumulationCycleTypeOption
			const rounding = rulesetRewardForm.rounding
			const roundingValue = rulesetRewardForm.roundingValue
			const numberDecimal = rulesetRewardForm.numberDecimal
			const numberDecimalValue = rulesetRewardForm.numberDecimalValue
			const campaignTiers = rulesetRewardForm.campaignTiers
			const tierRewardSetting = []
			const rewardLevel = rulesetRewardForm.rewardLevel

			if (type === 'TPT') {
				campaignTiers.allIds.forEach(campaignTierId => {

					if (campaignTiers.byId.hasOwnProperty(campaignTierId)) {
						const byId = campaignTiers.byId[campaignTierId]

						const conditionFactorFrom = byId.form.value.conditionFactorFrom
						const conditionFactorTo = byId.form.value.conditionFactorTo
						const valueFactor = byId.form.value.valueFactor
						const pointFactor = byId.form.value.pointFactor

						if (transactionMethod === 'AMOUNT') {
							if (rewardMethod === 'RATIO') {
								tierRewardSetting.push({
									conditionFactorFrom,
									conditionFactorTo,
									valueFactor,
									pointFactor
								})
							}
							if (rewardMethod === 'FIX') {
								tierRewardSetting.push({
									conditionFactorFrom,
									conditionFactorTo,
									pointFactor
								})
							}
						}
					}
				})
			}

			if (type === 'TPC') {
				campaignTiers.allIds.forEach(campaignTierId => {

					if (campaignTiers.byId.hasOwnProperty(campaignTierId)) {
						const byId = campaignTiers.byId[campaignTierId]

						const conditionFactorFrom = byId.form.value.conditionFactorFrom
						const conditionFactorTo = byId.form.value.conditionFactorTo
						const valueFactor = byId.form.value.valueFactor
						const pointFactor = byId.form.value.pointFactor

						if (transactionMethod === 'AMOUNT') {
							if (rewardMethod === 'RATIO') {
								tierRewardSetting.push({
									conditionFactorFrom,
									conditionFactorTo,
									valueFactor,
									pointFactor
								})
							}
							if (rewardMethod === 'FIX') {
								tierRewardSetting.push({
									conditionFactorFrom,
									conditionFactorTo,
									pointFactor
								})
							}
						}

						if (transactionMethod === 'SWIPE' && rewardMethod === 'FIX') {
							tierRewardSetting.push({
								conditionFactorFrom,
								conditionFactorTo,
								pointFactor
							})
						}
					}
				})
			}

			let reward = {
				rewardPool,
				amountField,
				type,
				rewardCreditMethod,
				transactionMethod,
				rewardMethod,
				conditionFactor,
				valueFactor,
				pointFactor,
				accumulationCycleType: accumulationCycleType === 'Y' && rulesetDetailForm.channel === 'CARDLINK' ? accumulationCycleTypeOption : accumulationCycleType,
				accumulationCycleDay,
				accumulationCycleMonth,
				rounding,
				roundingValue,
				numberDecimal,
				numberDecimalValue,
				tierRewardSetting,
				rewardLevel
			}

			switch (type) {
				case 'X2X':
					reward = {
						...reward,
						conditionFactor: undefined,
						tierRewardSetting: undefined
					}
					break
				case 'OX':
					reward = {
						...reward,
						valueFactor: undefined,
						tierRewardSetting: undefined
					}
					break
				case 'PX':
					reward = {
						...reward,
						conditionFactor: undefined,
						tierRewardSetting: undefined
					}
					break
				case 'TPC':
					reward = {
						...reward,
						conditionFactor: undefined,
						valueFactor: undefined,
						pointFactor: undefined,
						tierRewardSetting
					}
					break
				case 'TPC':
					reward = {
						...reward,
						conditionFactor: undefined,
						valueFactor: undefined,
						pointFactor: undefined,
						tierRewardSetting
					}
					break
				case 'INC':
					reward = {
						...reward,
						tierRewardSetting: undefined
					}
					break
			}

			rulesets.push({
				id,
				rule: {
					code: rulesetDetailForm.code,
					name: rulesetDetailForm.name,
					channel: rulesetDetailForm.channel,
					conditions
				},
				reward
			})
		})

		return {
			kind,
			information: {
				channel: informationDetailFormVal.channel ? informationDetailFormVal.channel : '',
				code: informationDetailFormVal.code,
				name: informationDetailFormVal.name,
				priority: informationDetailFormVal.priority,
				startDate: Util.fromISOStringToDateStr(informationDetailFormVal.startDate),
				endDate: Util.fromISOStringToDateStr(informationDetailFormVal.endDate),
				enableConditionalReward: informationDetailFormVal.enableConditionalReward
			},
			rulesets,
			capping: {
				...capping,
				capCycleType: capping.capCycleType === 'B' ? capping.cycleTypeOption : capping.capCycleType,
				customerCapType: capping.customerCapType ? capping.customerCapType : ''
			}
		}
	}
}
