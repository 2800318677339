import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material'
import { Router } from '@angular/router'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Action, Store } from '@ngrx/store'
import { Observable, of } from 'rxjs'
import { catchError, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators'
import { MessageDialogComponent } from 'src/app/components/common/dialog/message-dialog/message-dialog.component'
import { getDialogConfig } from 'src/app/models/common/dialog'
import { Response } from 'src/app/models/common/http'
import { RedeemRules } from 'src/app/models/redemption-settings/redeem-rules/redeem-rules'
import { RedeemRulesDataResponse } from 'src/app/models/redemption-settings/redeem-rules/redeem-rules-data'
import { RedeemRulesViewResponse } from 'src/app/models/redemption-settings/redeem-rules/redeem-rules-view'
import { RedemptionSettingsService } from 'src/app/services/redemption-settings/redemption-settings.service'
import * as AppStore from 'src/app/store/'
import * as CommonAction from '../../common/common/common.actions'
import * as RedeemRulesAction from './redeem-rules.actions'
import { unbox } from 'ngrx-forms'
import { POLICY } from 'src/app/models/common/constant'
import { SelectDialogComponent } from 'src/app/components/common/dialog/select-dialog/select-dialog.component'
import {initialRPGRule} from './redeem-rules.state'

@Injectable()
export class Effects {

	constructor(
		private action$: Actions,
		private router: Router,
		private dialog: MatDialog,
		private redemptionSettingsService: RedemptionSettingsService,
		private store: Store<AppStore.State>
	) { }


	InitialState$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RedeemRulesAction.InitialState),
			tap(() => {
				this.router.navigate(['redemption-settings/redeem-rules'])
			})
		), { dispatch: false }
	)

	RestError$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RedeemRulesAction.RestError),
			map(({ message, manualThrow }) => {
				return CommonAction.RestError({ message, manualThrow })
			})
		)
	)

	CloseDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RedeemRulesAction.CloseDialog),
			tap(() => {
				this.dialog.closeAll()
			})
		), { dispatch: false }
	)

	GoList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RedeemRulesAction.GoList),
			tap(() => {
				this.router.navigate(['redemption-settings/redeem-rules'])
			})
		), { dispatch: false }
	)

	GoView$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RedeemRulesAction.GoView),
			tap(() => {
				this.router.navigate(['redemption-settings/redeem-rules/view'])
			})
		), { dispatch: false }
	)

	GoCreate$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RedeemRulesAction.GoCreate),
			tap(() => {
				this.router.navigate(['redemption-settings/redeem-rules/create'])
			})
		), { dispatch: false }
	)

	GoUpdate$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RedeemRulesAction.GoUpdate),
			tap(() => {
				this.router.navigate(['redemption-settings/redeem-rules/edit'])
			})
		), { dispatch: false }
	)

	List$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RedeemRulesAction.List),
			withLatestFrom(this.store.select(state => state.redeemRules)),
			mergeMap(() => {
				return this.redemptionSettingsService.getRedeemRulesList().pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: RedeemRules[] = response.payload

					if (success) {
						return RedeemRulesAction.ListSuccess({ payload })
					} else {
						return RedeemRulesAction.RestError(response as any)
					}
				}),
					catchError((error) => {
						return (of(RedeemRulesAction.RestError({ message: error.message, manualThrow: true })))
					})
				)
			})
		)
	)

	View$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RedeemRulesAction.View),
			withLatestFrom(this.store.select(state => state.redeemRules)),
			mergeMap(([, redeemRules]) => this.redemptionSettingsService.viewRedeemRules({ id: redeemRules.id } as RedeemRulesViewResponse)
				.pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: RedeemRulesViewResponse = response.payload
					if (success) {
						return RedeemRulesAction.ViewSuccess({ payload })
					} else {
						return RedeemRulesAction.RestError(response as any)
					}
				}), catchError(this.catcher))
			)
		)
	)

	CreateDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RedeemRulesAction.CreateDialog),
			mergeMap(() => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.CREATE_REDEEM_RULES' }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return RedeemRulesAction.Create()
				} else {
					return RedeemRulesAction.CloseDialog()
				}
			})
		)
	)

	Create$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RedeemRulesAction.Create),
			withLatestFrom(this.store.select(state => state.redeemRules)),
			mergeMap(([, redeemRules]) => {
				const redeemRulesDetailForm = redeemRules.redeemRulesForm.value
				const redeemRuleDetail = []
				const formValue = redeemRules.rewardPoolGroupRewardPoolList
					.concat({key: 'RP', value: 'Reward Pool'})
					.map(x => {
						const rule = redeemRules.redeemRulesForm.value.rules[x.value] || initialRPGRule
						return rule.form.value
				}).filter(x => x.rewardPoolId !== 0)

				formValue.forEach(i => {
					redeemRuleDetail.push({
						redeemRuleId: i.rewardPoolId,
						rewardPoolId: i.rewardPoolId,
						customerBlockCode: '{"includeExcludeFlag":"' + i.customerBlockCodeFlag + '","entity":"' + i.customerBlockCode + '"}',
						customerStatus: '{"includeExcludeFlag":"' + i.customerStatusFlag + '","entity":"' + i.customerStatus + '"}',
						accountBlockCode: '{"includeExcludeFlag":"' + i.accountBlockCodeFlag + '","entity":"' + i.accountBlockCode + '"}',
						accountStatus: '{"includeExcludeFlag":"' + i.accountStatusFlag + '","entity":"' + i.accountStatus + '"}',
						cardBlockCode: '{"includeExcludeFlag":"' + i.cardBlockCodeFlag + '","entity":"' + i.cardBlockCode + '"}',
						cardStatus: '{"includeExcludeFlag":"' + i.cardStatusFlag + '","entity":"' + i.cardStatus + '"}'
					})
				})

				return this.redemptionSettingsService.createRedeemRules({
					code: redeemRulesDetailForm.code,
					name: redeemRulesDetailForm.name,
					rewardPool: unbox(redeemRulesDetailForm.rewardPool) && unbox(redeemRulesDetailForm.rewardPool).toString(),
					channel: redeemRulesDetailForm.channel,
					cardholderTypes: unbox(redeemRulesDetailForm.cardholderTypes) && unbox(redeemRulesDetailForm.cardholderTypes).toString(),
					redeemRuleDetail
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload = response.payload
					if (success) {
						return RedeemRulesAction.CreateSuccess({ payload })
					} else {
						return RedeemRulesAction.RestError(response as any)
					}
				}), catchError(this.catcher))
			})
		)
	)

	CreateSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RedeemRulesAction.CreateSuccess),
			mergeMap(({ payload }) => {
				return [RedeemRulesAction.GoList(), CommonAction.RestError({ message: payload })]
			})
		)
	)
	GoBackDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RedeemRulesAction.GoBackDialog),
			mergeMap(({ action }) => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.BACK', payload: action }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					if (result.payload === 'LIST') {
						return RedeemRulesAction.GoList()
					}
					if (result.payload === 'VIEW') {
						return RedeemRulesAction.GoView()
					}
				} else {
					return RedeemRulesAction.CloseDialog()
				}
			})
		)
	)

	UpdateDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RedeemRulesAction.UpdateDialog),
			mergeMap(() => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.UPDATE' }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return RedeemRulesAction.Update()
				} else {
					return RedeemRulesAction.CloseDialog()
				}
			})
		)
	)

	Update$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RedeemRulesAction.Update),
			withLatestFrom(this.store.select(state => state.redeemRules)),
			mergeMap(([, redeemRules]) => {
				const redeemRulesDetailForm = redeemRules.redeemRulesForm.value
				const redeemRuleDetail = []
				const formValue = redeemRules.rewardPoolGroupRewardPoolList
					.concat({key: 'RP', value: 'Reward Pool'})
					.map(x => {
						const rule = redeemRules.redeemRulesForm.value.rules[x.value] || initialRPGRule
						return rule.form.value
					}).filter(x => x.rewardPoolId !== 0)

				formValue.forEach(i => {
					redeemRuleDetail.push({
						redeemRuleId: i.rewardPoolId,
						rewardPoolId: i.rewardPoolId,
						customerBlockCode: '{"includeExcludeFlag":"' + i.customerBlockCodeFlag + '","entity":"' + i.customerBlockCode + '"}',
						customerStatus: '{"includeExcludeFlag":"' + i.customerStatusFlag + '","entity":"' + i.customerStatus + '"}',
						accountBlockCode: '{"includeExcludeFlag":"' + i.accountBlockCodeFlag + '","entity":"' + i.accountBlockCode + '"}',
						accountStatus: '{"includeExcludeFlag":"' + i.accountStatusFlag + '","entity":"' + i.accountStatus + '"}',
						cardBlockCode: '{"includeExcludeFlag":"' + i.cardBlockCodeFlag + '","entity":"' + i.cardBlockCode + '"}',
						cardStatus: '{"includeExcludeFlag":"' + i.cardStatusFlag + '","entity":"' + i.cardStatus + '"}'
					})
				})

				return this.redemptionSettingsService.editRedeemRules(redeemRules.id, {
					code: redeemRulesDetailForm.code,
					name: redeemRulesDetailForm.name,
					channel: redeemRulesDetailForm.channel,
					rewardPool: unbox(redeemRulesDetailForm.rewardPool) && unbox(redeemRulesDetailForm.rewardPool).toString(),
					cardholderTypes: unbox(redeemRulesDetailForm.cardholderTypes) && unbox(redeemRulesDetailForm.cardholderTypes).toString(),
					redeemRuleDetail
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: string = response.payload
					if (success) {
						return RedeemRulesAction.UpdateSuccess({ payload })
					} else {
						return RedeemRulesAction.RestError(response as any)
					}
				}), catchError(this.catcher))
			})
		)
	)

	UpdateSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RedeemRulesAction.UpdateSuccess),
			mergeMap(({ payload }) => {
				return [RedeemRulesAction.GoView(), CommonAction.RestError({ message: payload })]
			})
		)
	)

	DeleteDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RedeemRulesAction.DeleteDialog),
			mergeMap(() => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.DELETE_REDEEM_RULES' }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return RedeemRulesAction.Delete()
				} else {
					return RedeemRulesAction.CloseDialog()
				}
			})
		)
	)

	Delete$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RedeemRulesAction.Delete),
			withLatestFrom(this.store.select(state => state.redeemRules)),
			mergeMap(([, redeemRules]) => {
				return this.redemptionSettingsService.deleteRedeemRules({
					id: redeemRules.id
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					if (success) {
						return RedeemRulesAction.DeleteSuccess()
					} else {
						return RedeemRulesAction.RestError(response as any)
					}
				}), catchError(this.catcher))
			})
		)
	)

	DeleteSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RedeemRulesAction.DeleteSuccess),
			map(() => {
				return RedeemRulesAction.GoList()
			})
		)
	)

	GetRedeemRulesData$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RedeemRulesAction.GetRedeemRulesData),
			mergeMap(({ payload }) => {
				return this.redemptionSettingsService.getRedeemRulesData({ policy: payload as POLICY }).pipe(
					map((response: Response) => {
						const success: boolean = response.success
						const payload: RedeemRulesDataResponse = response.payload

						if (success) {
							return RedeemRulesAction.GetRedeemRulesSuccess({ payload })
						} else {
							return RedeemRulesAction.RestError(response as any)
						}

					}), catchError(this.catcher)
				)
			})
		)
	)

	SelectRuleDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RedeemRulesAction.SelectRuleDialog),
			withLatestFrom(this.store.select(state => state.redeemRules)),
			mergeMap(([params, redeemRules]) => {
				const rewardPool = params.payload
				const rulesKeysArray = redeemRules.redeemRulesForm.value.rules[rewardPool.value]
				const rulesKey = rulesKeysArray && rulesKeysArray.rules || []
				const channel = redeemRules.redeemRulesForm.controls.channel.value
				let filteredRulesKey

				if (channel === 'Card' || params.payload.resource === 'CARDLINK') {
					filteredRulesKey = redeemRules.ruleList.filter(x => !rulesKey.includes(x.keyValue.key) && x.resourceCode === 'CARDLINK' )
				} else {
					filteredRulesKey = redeemRules.ruleList.filter(x => !rulesKey.includes(x.keyValue.key) && x.resourceCode === 'BANKWIDE' )
				}

				const dialogRef = this.dialog.open(SelectDialogComponent, getDialogConfig({
					title: 'REDEEM_RULES.ACTION.ADD_RULE',
					content: 'REDEEM_RULES.ACTION.SELECT_RULE',
					payload: filteredRulesKey
				}))

				return dialogRef.afterClosed()
					.pipe(
						map(result => {
							if (result) {
								return RedeemRulesAction.SelectRule({ payload: result.keyValue,  params: params.payload, filteredRulesKey })
							} else {
								return RedeemRulesAction.CloseDialog()
							}
						}))
			})
		)
	)

	GetRewardPoolGroupRewardPoolList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RedeemRulesAction.GetRewardPoolGroupRewardPoolList),
			mergeMap(({ params }) => {
				return this.redemptionSettingsService.getRewardPoolGroupRewardPoolList(params).pipe(
					map((response: Response) => {
						const success: boolean = response.success
						const payload = response.payload
						if (success) {
							return RedeemRulesAction.GetRewardPoolGroupRewardPoolListSuccess({ payload })
						} else {
							return RedeemRulesAction.RestError(response as any)
						}
					}),
					catchError(this.catcher)
				)
			})
		)
	)

	catcher = (error) => {
		return (of(RedeemRulesAction.RestError({ message: error.message, manualThrow: error.manualThrow })))
	}

}
