export const environment = {
	production: false,
	name: 'vit', // Environment name
	hmr: false, // Hot module reload
	lang: 'en', // Default language
	mock: false, // Use /assets/mock data
	apiUrl: 'http://wll.rhb.dev.worldline.cc/portal-bff/api/v1', // URL of development API
	loginUrl: 'http://wll.rhb.dev.worldline.cc/portal-bff/api/v1/oauth/login', // URL for Login
	logoutUrl: 'http://wll.rhb.dev.worldline.cc/portal-bff/api/v1/oauth/logout', // URL for Logout
	clearCacheUrl: 'http://wll.rhb.dev.worldline.cc/authorization-service/log_out', // URL for clearing cache
	build: 'V2.0.0.111',
	showStoreLogger: true,
}
