<form novalidate [ngrxFormState]="formState$ | async" fxLayout="column">
  <div *ngIf="isView" fxLayout="column">

    <mat-form-field fxFlex>
      <input matInput value="{{getTimeValue((formState$ | async).controls.value.value)}}" type="text" maxlength="255"
        readonly>
    </mat-form-field>

    <div *ngIf="(formState$ | async).controls.value.value === 'D'" fxLayout="row" fxLayoutGap="5px">
      <mat-form-field fxFlex="50">
        <mat-label>{{ 'COMMON.START_TIME' | translate }}</mat-label>
        <input matInput value="{{(formState$ | async).controls.daily.controls.startTime.value}}" type="time"
          maxlength="255" readonly>
      </mat-form-field>

      <mat-form-field fxFlex="50">
        <mat-label>{{ 'COMMON.END_TIME' | translate }}</mat-label>
        <input matInput value="{{(formState$ | async).controls.daily.controls.endTime.value}}" type="time"
          maxlength="255" readonly>
      </mat-form-field>
    </div>

    <div *ngIf="(formState$ | async).controls.value.value === 'W'" fxLayout="column">
      <div *ngIf="(formState$ | async)?.controls.weekly.controls.monday.controls.isCheck.value" fxLayout="column">
        {{ 'CAMPAIGN.MONDAY' | translate }}
      </div>
      <div *ngIf="(formState$ | async)?.controls.weekly.controls.monday.controls.isCheck.value" fxLayout="row"
        fxLayoutGap="5px">
        <mat-form-field fxFlex="50">
          <mat-label>{{ 'COMMON.START_TIME' | translate }}</mat-label>
          <input matInput value="{{(formState$ | async).controls.weekly.controls.monday.controls.startTime.value}}"
            type="time" maxlength="255" readonly>
        </mat-form-field>

        <mat-form-field fxFlex="50">
          <mat-label>{{ 'COMMON.END_TIME' | translate }}</mat-label>
          <input matInput value="{{(formState$ | async).controls.weekly.controls.monday.controls.endTime.value}}"
            type="time" maxlength="255" readonly>
        </mat-form-field>
      </div>

      <div *ngIf="(formState$ | async)?.controls.weekly.controls.tuesday.controls.isCheck.value" fxLayout="column">
        {{ 'CAMPAIGN.TUESDAY' | translate }}
      </div>
      <div *ngIf="(formState$ | async)?.controls.weekly.controls.tuesday.controls.isCheck.value" fxLayout="row"
        fxLayoutGap="5px">
        <mat-form-field fxFlex="50">
          <mat-label>{{ 'COMMON.START_TIME' | translate }}</mat-label>
          <input matInput value="{{(formState$ | async).controls.weekly.controls.tuesday.controls.startTime.value}}"
            type="time" maxlength="255" readonly>
        </mat-form-field>

        <mat-form-field fxFlex="50">
          <mat-label>{{ 'COMMON.END_TIME' | translate }}</mat-label>
          <input matInput value="{{(formState$ | async).controls.weekly.controls.tuesday.controls.endTime.value}}"
            type="time" maxlength="255" readonly>
        </mat-form-field>
      </div>

      <div *ngIf="(formState$ | async)?.controls.weekly.controls.wednesday.controls.isCheck.value" fxLayout="column">
        {{ 'CAMPAIGN.WEDNESDAY' | translate }}
      </div>
      <div *ngIf="(formState$ | async)?.controls.weekly.controls.wednesday.controls.isCheck.value" fxLayout="row"
        fxLayoutGap="5px">
        <mat-form-field fxFlex="50">
          <mat-label>{{ 'COMMON.START_TIME' | translate }}</mat-label>
          <input matInput value="{{(formState$ | async).controls.weekly.controls.wednesday.controls.startTime.value}}"
            type="time" maxlength="255" readonly>
        </mat-form-field>

        <mat-form-field fxFlex="50">
          <mat-label>{{ 'COMMON.END_TIME' | translate }}</mat-label>
          <input matInput value="{{(formState$ | async).controls.weekly.controls.wednesday.controls.endTime.value}}"
            type="time" maxlength="255" readonly>
        </mat-form-field>
      </div>

      <div *ngIf="(formState$ | async)?.controls.weekly.controls.thursday.controls.isCheck.value" fxLayout="column">
        {{ 'CAMPAIGN.THURSDAY' | translate }}
      </div>
      <div *ngIf="(formState$ | async)?.controls.weekly.controls.thursday.controls.isCheck.value" fxLayout="row"
        fxLayoutGap="5px">
        <mat-form-field fxFlex="50">
          <mat-label>{{ 'COMMON.START_TIME' | translate }}</mat-label>
          <input matInput value="{{(formState$ | async).controls.weekly.controls.thursday.controls.startTime.value}}"
            type="time" maxlength="255" readonly>
        </mat-form-field>

        <mat-form-field fxFlex="50">
          <mat-label>{{ 'COMMON.END_TIME' | translate }}</mat-label>
          <input matInput value="{{(formState$ | async).controls.weekly.controls.thursday.controls.endTime.value}}"
            type="time" maxlength="255" readonly>
        </mat-form-field>
      </div>

      <div *ngIf="(formState$ | async)?.controls.weekly.controls.friday.controls.isCheck.value" fxLayout="column">
        {{ 'CAMPAIGN.FRIDAY' | translate }}
      </div>
      <div *ngIf="(formState$ | async)?.controls.weekly.controls.friday.controls.isCheck.value" fxLayout="row"
        fxLayoutGap="5px">
        <mat-form-field fxFlex="50">
          <mat-label>{{ 'COMMON.START_TIME' | translate }}</mat-label>
          <input matInput value="{{(formState$ | async).controls.weekly.controls.friday.controls.startTime.value}}"
            type="time" maxlength="255" readonly>
        </mat-form-field>

        <mat-form-field fxFlex="50">
          <mat-label>{{ 'COMMON.END_TIME' | translate }}</mat-label>
          <input matInput value="{{(formState$ | async).controls.weekly.controls.friday.controls.endTime.value}}"
            type="time" maxlength="255" readonly>
        </mat-form-field>
      </div>

      <div *ngIf="(formState$ | async)?.controls.weekly.controls.saturday.controls.isCheck.value" fxLayout="column">
        {{ 'CAMPAIGN.SATURDAY' | translate }}
      </div>
      <div *ngIf="(formState$ | async)?.controls.weekly.controls.saturday.controls.isCheck.value" fxLayout="row"
        fxLayoutGap="5px">
        <mat-form-field fxFlex="50">
          <mat-label>{{ 'COMMON.START_TIME' | translate }}</mat-label>
          <input matInput value="{{(formState$ | async).controls.weekly.controls.saturday.controls.startTime.value}}"
            type="time" maxlength="255" readonly>
        </mat-form-field>

        <mat-form-field fxFlex="50">
          <mat-label>{{ 'COMMON.END_TIME' | translate }}</mat-label>
          <input matInput value="{{(formState$ | async).controls.weekly.controls.saturday.controls.endTime.value}}"
            type="time" maxlength="255" readonly>
        </mat-form-field>
      </div>

      <div *ngIf="(formState$ | async)?.controls.weekly.controls.sunday.controls.isCheck.value" fxLayout="column">
        {{ 'CAMPAIGN.SUNDAY' | translate }}
      </div>
      <div *ngIf="(formState$ | async)?.controls.weekly.controls.sunday.controls.isCheck.value" fxLayout="row"
        fxLayoutGap="5px">
        <mat-form-field fxFlex="50">
          <mat-label>{{ 'COMMON.START_TIME' | translate }}</mat-label>
          <input matInput value="{{(formState$ | async).controls.weekly.controls.sunday.controls.startTime.value}}"
            type="time" maxlength="255" readonly>
        </mat-form-field>

        <mat-form-field fxFlex="50">
          <mat-label>{{ 'COMMON.END_TIME' | translate }}</mat-label>
          <input matInput value="{{(formState$ | async).controls.weekly.controls.sunday.controls.endTime.value}}"
            type="time" maxlength="255" readonly>
        </mat-form-field>
      </div>
    </div>

    <div *ngIf="(formState$ | async).controls.value.value === 'M'" fxLayout="column">
      <mat-form-field fxFlex>
        <input matInput value="{{getDayOfMonthValue((formState$ | async).controls.monthly.controls.day.value)}}"
          type="text" maxlength="255" readonly>
      </mat-form-field>

      <div fxLayout="row" fxLayoutGap="5px">
        <mat-form-field fxFlex="50">
          <mat-label>{{ 'COMMON.START_TIME' | translate }}</mat-label>
          <input matInput value="{{(formState$ | async).controls.monthly.controls.startTime.value}}" type="time"
            maxlength="255" readonly>
        </mat-form-field>

        <mat-form-field fxFlex="50">
          <mat-label>{{ 'COMMON.END_TIME' | translate }}</mat-label>
          <input matInput value="{{(formState$ | async).controls.monthly.controls.endTime.value}}" type="time"
            maxlength="255" readonly>
        </mat-form-field>
      </div>
    </div>
  </div>

  <div *ngIf="!isView" fxLayout="column">
    <mat-form-field fxFlex>
      <mat-select [ngrxFormControlState]="(formState$ | async).controls.value"
        (selectionChange)="onChangeSelectedDay($event)">
        <mat-option *ngFor="let i of timeList" [value]="i.key">
          {{i.value}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div *ngIf="(formState$ | async).controls.value.value === 'D'" fxLayout="row" fxLayoutGap="5px">
      <mat-form-field fxFlex="50">
        <mat-label>{{ 'COMMON.START_TIME' | translate }}</mat-label>
        <input matInput [ngrxFormControlState]="(formState$ | async)?.controls.daily.controls.startTime" type="time"
          maxlength="255">
        <mat-error *ngIf="(formState$ | async).errors._daily?._startTime">
          <app-error-validation [errors]="(formState$ | async).errors._daily._startTime" [maxlength]="255">
          </app-error-validation>
        </mat-error>
      </mat-form-field>

      <mat-form-field fxFlex="50">
        <mat-label>{{ 'COMMON.END_TIME' | translate }}</mat-label>
        <input matInput [ngrxFormControlState]="(formState$ | async)?.controls.daily.controls.endTime" type="time"
          maxlength="255">
        <mat-error *ngIf="(formState$ | async).errors._daily?._endTime">
          <app-error-validation [errors]="(formState$ | async).errors._daily._endTime" [maxlength]="255">
          </app-error-validation>
        </mat-error>
      </mat-form-field>
    </div>

    <div *ngIf="(formState$ | async).controls.value.value === 'W'" fxLayout="column">

      <mat-checkbox #monday
        [ngrxFormControlState]="(formState$ | async)?.controls.weekly.controls.monday.controls.isCheck" color="primary"
        (change)="onChangeSelectedDayWeekly('monday', monday.checked)">
        {{ 'CAMPAIGN.MONDAY' | translate }}
      </mat-checkbox>
      <div *ngIf="(formState$ | async)?.controls.weekly.controls.monday.controls.isCheck.value" fxLayout="row"
        fxLayoutGap="5px">
        <mat-form-field fxFlex="50">
          <mat-label>{{ 'COMMON.START_TIME' | translate }}</mat-label>
          <input matInput
            [ngrxFormControlState]="(formState$ | async)?.controls.weekly.controls.monday.controls.startTime"
            type="time" maxlength="255">
          <mat-error *ngIf="(formState$ | async).errors._weekly?._monday?._startTime">
            <app-error-validation [errors]="(formState$ | async).errors._weekly._monday._startTime" [maxlength]="255">
            </app-error-validation>
          </mat-error>
        </mat-form-field>

        <mat-form-field fxFlex="50">
          <mat-label>{{ 'COMMON.END_TIME' | translate }}</mat-label>
          <input matInput
            [ngrxFormControlState]="(formState$ | async)?.controls.weekly.controls.monday.controls.endTime" type="time"
            maxlength="255">
          <mat-error *ngIf="(formState$ | async).errors._weekly?._monday?._endTime">
            <app-error-validation [errors]="(formState$ | async).errors._weekly._monday._endTime" [maxlength]="255">
            </app-error-validation>
          </mat-error>
        </mat-form-field>
      </div>

      <mat-checkbox #tuesday
        [ngrxFormControlState]="(formState$ | async)?.controls.weekly.controls.tuesday.controls.isCheck" color="primary"
        (change)="onChangeSelectedDayWeekly('tuesday', tuesday.checked)">
        {{ 'CAMPAIGN.TUESDAY' | translate }}
      </mat-checkbox>
      <div *ngIf="(formState$ | async)?.controls.weekly.controls.tuesday.controls.isCheck.value" fxLayout="row"
        fxLayoutGap="5px">
        <mat-form-field fxFlex="50">
          <mat-label>{{ 'COMMON.START_TIME' | translate }}</mat-label>
          <input matInput
            [ngrxFormControlState]="(formState$ | async)?.controls.weekly.controls.tuesday.controls.startTime"
            type="time" maxlength="255">
          <mat-error *ngIf="(formState$ | async).errors._weekly?._tuesday?._startTime">
            <app-error-validation [errors]="(formState$ | async).errors._weekly._tuesday._startTime" [maxlength]="255">
            </app-error-validation>
          </mat-error>
        </mat-form-field>

        <mat-form-field fxFlex="50">
          <mat-label>{{ 'COMMON.END_TIME' | translate }}</mat-label>
          <input matInput
            [ngrxFormControlState]="(formState$ | async)?.controls.weekly.controls.tuesday.controls.endTime" type="time"
            maxlength="255">
          <mat-error *ngIf="(formState$ | async).errors._weekly?._tuesday?._endTime">
            <app-error-validation [errors]="(formState$ | async).errors._weekly._tuesday._endTime" [maxlength]="255">
            </app-error-validation>
          </mat-error>
        </mat-form-field>
      </div>

      <mat-checkbox #wednesday
        [ngrxFormControlState]="(formState$ | async)?.controls.weekly.controls.wednesday.controls.isCheck"
        color="primary" (change)="onChangeSelectedDayWeekly('wednesday', wednesday.checked)">
        {{ 'CAMPAIGN.WEDNESDAY' | translate }}
      </mat-checkbox>
      <div *ngIf="(formState$ | async)?.controls.weekly.controls.wednesday.controls.isCheck.value" fxLayout="row"
        fxLayoutGap="5px">
        <mat-form-field fxFlex="50">
          <mat-label>{{ 'COMMON.START_TIME' | translate }}</mat-label>
          <input matInput
            [ngrxFormControlState]="(formState$ | async)?.controls.weekly.controls.wednesday.controls.startTime"
            type="time" maxlength="255">
          <mat-error *ngIf="(formState$ | async).errors._weekly?._wednesday?._startTime">
            <app-error-validation [errors]="(formState$ | async).errors._weekly._wednesday._startTime"
              [maxlength]="255">
            </app-error-validation>
          </mat-error>
        </mat-form-field>

        <mat-form-field fxFlex="50">
          <mat-label>{{ 'COMMON.END_TIME' | translate }}</mat-label>
          <input matInput
            [ngrxFormControlState]="(formState$ | async)?.controls.weekly.controls.wednesday.controls.endTime"
            type="time" maxlength="255">
          <mat-error *ngIf="(formState$ | async).errors._weekly?._wednesday?._endTime">
            <app-error-validation [errors]="(formState$ | async).errors._weekly._wednesday._endTime" [maxlength]="255">
            </app-error-validation>
          </mat-error>
        </mat-form-field>
      </div>

      <mat-checkbox #thursday
        [ngrxFormControlState]="(formState$ | async)?.controls.weekly.controls.thursday.controls.isCheck"
        color="primary" (change)="onChangeSelectedDayWeekly('thursday', thursday.checked)">
        {{ 'CAMPAIGN.THURSDAY' | translate }}
      </mat-checkbox>
      <div *ngIf="(formState$ | async)?.controls.weekly.controls.thursday.controls.isCheck.value" fxLayout="row"
        fxLayoutGap="5px">
        <mat-form-field fxFlex="50">
          <mat-label>{{ 'COMMON.START_TIME' | translate }}</mat-label>
          <input matInput
            [ngrxFormControlState]="(formState$ | async)?.controls.weekly.controls.thursday.controls.startTime"
            type="time" maxlength="255">
          <mat-error *ngIf="(formState$ | async).errors._weekly?._thursday?._startTime">
            <app-error-validation [errors]="(formState$ | async).errors._weekly._thursday._startTime" [maxlength]="255">
            </app-error-validation>
          </mat-error>
        </mat-form-field>

        <mat-form-field fxFlex="50">
          <mat-label>{{ 'COMMON.END_TIME' | translate }}</mat-label>
          <input matInput
            [ngrxFormControlState]="(formState$ | async)?.controls.weekly.controls.thursday.controls.endTime"
            type="time" maxlength="255">
          <mat-error *ngIf="(formState$ | async).errors._weekly?._thursday?._endTime">
            <app-error-validation [errors]="(formState$ | async).errors._weekly._thursday._endTime" [maxlength]="255">
            </app-error-validation>
          </mat-error>
        </mat-form-field>
      </div>

      <mat-checkbox #friday
        [ngrxFormControlState]="(formState$ | async)?.controls.weekly.controls.friday.controls.isCheck" color="primary"
        (change)="onChangeSelectedDayWeekly('friday', friday.checked)">
        {{ 'CAMPAIGN.FRIDAY' | translate }}
      </mat-checkbox>
      <div *ngIf="(formState$ | async)?.controls.weekly.controls.friday.controls.isCheck.value" fxLayout="row"
        fxLayoutGap="5px">
        <mat-form-field fxFlex="50">
          <mat-label>{{ 'COMMON.START_TIME' | translate }}</mat-label>
          <input matInput
            [ngrxFormControlState]="(formState$ | async)?.controls.weekly.controls.friday.controls.startTime"
            type="time" maxlength="255">
          <mat-error *ngIf="(formState$ | async).errors._weekly?._friday?._startTime">
            <app-error-validation [errors]="(formState$ | async).errors._weekly._friday._startTime" [maxlength]="255">
            </app-error-validation>
          </mat-error>
        </mat-form-field>

        <mat-form-field fxFlex="50">
          <mat-label>{{ 'COMMON.END_TIME' | translate }}</mat-label>
          <input matInput
            [ngrxFormControlState]="(formState$ | async)?.controls.weekly.controls.friday.controls.endTime" type="time"
            maxlength="255">
          <mat-error *ngIf="(formState$ | async).errors._weekly?._friday?._endTime">
            <app-error-validation [errors]="(formState$ | async).errors._weekly._friday._endTime" [maxlength]="255">
            </app-error-validation>
          </mat-error>
        </mat-form-field>
      </div>

      <mat-checkbox #saturday
        [ngrxFormControlState]="(formState$ | async)?.controls.weekly.controls.saturday.controls.isCheck"
        color="primary" (change)="onChangeSelectedDayWeekly('saturday', saturday.checked)">
        {{ 'CAMPAIGN.SATURDAY' | translate }}
      </mat-checkbox>
      <div *ngIf="(formState$ | async)?.controls.weekly.controls.saturday.controls.isCheck.value" fxLayout="row"
        fxLayoutGap="5px">
        <mat-form-field fxFlex="50">
          <mat-label>{{ 'COMMON.START_TIME' | translate }}</mat-label>
          <input matInput
            [ngrxFormControlState]="(formState$ | async)?.controls.weekly.controls.saturday.controls.startTime"
            type="time" maxlength="255">
          <mat-error *ngIf="(formState$ | async).errors._weekly?._saturday?._startTime">
            <app-error-validation [errors]="(formState$ | async).errors._weekly._saturday._startTime" [maxlength]="255">
            </app-error-validation>
          </mat-error>
        </mat-form-field>

        <mat-form-field fxFlex="50">
          <mat-label>{{ 'COMMON.END_TIME' | translate }}</mat-label>
          <input matInput
            [ngrxFormControlState]="(formState$ | async)?.controls.weekly.controls.saturday.controls.endTime"
            type="time" maxlength="255">
          <mat-error *ngIf="(formState$ | async).errors._weekly?._saturday?._endTime">
            <app-error-validation [errors]="(formState$ | async).errors._weekly._saturday._endTime" [maxlength]="255">
            </app-error-validation>
          </mat-error>
        </mat-form-field>
      </div>

      <mat-checkbox #sunday
        [ngrxFormControlState]="(formState$ | async)?.controls.weekly.controls.sunday.controls.isCheck" color="primary"
        (change)="onChangeSelectedDayWeekly('sunday', sunday.checked)">
        {{ 'CAMPAIGN.SUNDAY' | translate }}
      </mat-checkbox>
      <div *ngIf="(formState$ | async)?.controls.weekly.controls.sunday.controls.isCheck.value" fxLayout="row"
        fxLayoutGap="5px">
        <mat-form-field fxFlex="50">
          <mat-label>{{ 'COMMON.START_TIME' | translate }}</mat-label>
          <input matInput
            [ngrxFormControlState]="(formState$ | async)?.controls.weekly.controls.sunday.controls.startTime"
            type="time" maxlength="255">
          <mat-error *ngIf="(formState$ | async).errors._weekly?._sunday?._startTime">
            <app-error-validation [errors]="(formState$ | async).errors._weekly._sunday._startTime" [maxlength]="255">
            </app-error-validation>
          </mat-error>
        </mat-form-field>

        <mat-form-field fxFlex="50">
          <mat-label>{{ 'COMMON.END_TIME' | translate }}</mat-label>
          <input matInput
            [ngrxFormControlState]="(formState$ | async)?.controls.weekly.controls.sunday.controls.endTime" type="time"
            maxlength="255">
          <mat-error *ngIf="(formState$ | async).errors._weekly?._sunday?._endTime">
            <app-error-validation [errors]="(formState$ | async).errors._weekly._sunday._endTime" [maxlength]="255">
            </app-error-validation>
          </mat-error>
        </mat-form-field>
      </div>

    </div>

    <div *ngIf="(formState$ | async).controls.value.value === 'M'" fxLayout="column">
      <mat-form-field fxFlex>
        <mat-select [ngrxFormControlState]="(formState$ | async).controls.monthly.controls.day">
          <mat-option *ngFor="let i of dayOfMonthList" [value]="i.key">
            {{i.value}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div fxLayout="row" fxLayoutGap="5px">
        <mat-form-field fxFlex="50">
          <mat-label>{{ 'COMMON.START_TIME' | translate }}</mat-label>
          <input matInput [ngrxFormControlState]="(formState$ | async)?.controls.monthly.controls.startTime" type="time"
            maxlength="255">
          <mat-error *ngIf="(formState$ | async).errors._monthly?._startTime">
            <app-error-validation [errors]="(formState$ | async).errors._monthly._startTime" [maxlength]="255">
            </app-error-validation>
          </mat-error>
        </mat-form-field>

        <mat-form-field fxFlex="50">
          <mat-label>{{ 'COMMON.END_TIME' | translate }}</mat-label>
          <input matInput [ngrxFormControlState]="(formState$ | async)?.controls.monthly.controls.endTime" type="time"
            maxlength="255">
          <mat-error *ngIf="(formState$ | async).errors._monthly?._endTime">
            <app-error-validation [errors]="(formState$ | async).errors._monthly._endTime" [maxlength]="255">
            </app-error-validation>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>
</form>