import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { Request, Response } from 'src/app/models/common/http'
import { RestService } from '../common/rest-client/rest.service'

@Injectable({
	providedIn: 'root'
})
export class UserProfileService {

	constructor(
		private rest: RestService,
	) { }

	getUserProfile(): Observable<Response> {
		const request: Request = {
			liveURL: '/userProfile/view'
		}

		return this.rest.get(request)
	}
}
