import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material'
import { Router } from '@angular/router'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Action, Store } from '@ngrx/store'
import { forkJoin, Observable, of } from 'rxjs'
import { catchError, map, mergeMap, take, tap, withLatestFrom } from 'rxjs/operators'
import { MessageDialogComponent } from 'src/app/components/common/dialog/message-dialog/message-dialog.component'
import { getDialogConfig } from 'src/app/models/common/dialog'
import { Response } from 'src/app/models/common/http'
import * as AppStore from 'src/app/store/'
import * as CommonAction from '../../common/common/common.actions'
import * as ProductCatalogueAction from './product-catalogue.actions'
import { ProductCatalogueListResponse } from 'src/app/models/catalogue-management/product-catalogue/product-catalogue-list'
import { CatalogueManagementService } from 'src/app/services/catalogue-management/catalogue-management.service'
import { StatusListResponse } from 'src/app/models/catalogue-management/product-catalogue/product-catalogue-status-list'
import { RedemptionTypeListResponse } from 'src/app/models/catalogue-management/product-catalogue/product-catalogue-redemption-type-list'
import { RewardPoolAndGroup } from 'src/app/models/catalogue-management/product-catalogue/product-catalogue-reward-list'
import { GlRedemptionListResponse } from 'src/app/models/param-settings/gl-redemption/gl-redemption-list'
import { ProductCatalogueViewResponse } from 'src/app/models/catalogue-management/product-catalogue/product-catalogue-view'
import { Util } from 'src/app/models/util/util'
import { FulFilmentPartnerListDataResponse } from 'src/app/models/catalogue-management/product-catalogue/product-catalogue-fulfilment-partner-list'
import { ProductCatalogueImageUploadResponse } from 'src/app/models/catalogue-management/product-catalogue/product-catalogue-image-upload'
import { ProductCategoryListResponse } from 'src/app/models/catalogue-management/product-catalogue/product-catalogue-product-category-list'
import { ParameterSettingsService } from 'src/app/services/param-settings/parameter-settings.service'
import { RewardPoolGroupViewResponse } from 'src/app/models/param-settings/reward-pool-group/reward-pool-group-view'
import { POLICY } from 'src/app/models/common/constant'
import { ProductCatalogueDuplicateComponent } from '../../../components/catalogue-management/product-catalogue/product-catalogue-duplicate/product-catalogue-duplicate.component'

@Injectable()
export class Effects {

	constructor(
		private action$: Actions,
		private router: Router,
		private dialog: MatDialog,
		private catalogueManagementService: CatalogueManagementService,
		private parameterSettingsService: ParameterSettingsService,
		private store: Store<AppStore.State>
	) { }

	InitialState$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ProductCatalogueAction.InitialState),
			tap(() => {
				this.router.navigate(['catalogue-management/product-catalogue'])
			})
		), { dispatch: false }
	)

	GoList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ProductCatalogueAction.GoList),
			tap(() => {
				this.router.navigate(['catalogue-management/product-catalogue'])
			})
		), { dispatch: false }
	)

	GoView$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ProductCatalogueAction.GoView),
			tap(() => {
				this.router.navigate(['catalogue-management/product-catalogue/view'])
			})
		), { dispatch: false }
	)

	GoCreate$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ProductCatalogueAction.GoCreate),
			tap(() => {
				this.router.navigate(['catalogue-management/product-catalogue/create'])
			})
		), { dispatch: false }
	)

	GoEdit$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ProductCatalogueAction.GoUpdate),
			tap(() => {
				this.router.navigate(['catalogue-management/product-catalogue/edit'])
			})
		), { dispatch: false }
	)

	GoBackDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ProductCatalogueAction.GoBackDialog),
			mergeMap(({ action }) => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.BACK', payload: action }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					if (result.payload === 'LIST') {
						return ProductCatalogueAction.GoList()
					}
					if (result.payload === 'VIEW') {
						return ProductCatalogueAction.GoView()
					}
				} else {
					return ProductCatalogueAction.closeDialog()
				}
			})
		)
	)

	RestError$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ProductCatalogueAction.RestError),
			map(({ message, manualThrow }) => {
				return CommonAction.RestError({ message, manualThrow })
			})
		)
	)

	InitialLoadList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ProductCatalogueAction.InitialLoadList),
			mergeMap(x => forkJoin([
				this.catalogueManagementService
					.getProductCatalogueStatusList()
					.pipe(take(1)),
				this.catalogueManagementService
					.getProductCatalogueRedemptionTypeList()
					.pipe(take(1)),
				this.catalogueManagementService
					.getProductCategoryList()
					.pipe(take(1)),
				this.catalogueManagementService
					.getProductCatalogueRewardPoolAndGroupList({ policy: x.payload as POLICY })
					.pipe(take(1)),
				this.catalogueManagementService
					.getProductCatalogueGlRedemptionList({ policy: x.payload as POLICY })
					.pipe(take(1)),
				this.catalogueManagementService
					.getProductCatalogueFulfilmentPartnerList({ policy: x.payload as POLICY })
					.pipe(take(1)),
				]).pipe(
					mergeMap((value) => {
						const [statusResp, redemptionTypeResp, productCategoryResp, rewardPoolResp, glRedemptionResp, fufilmentPartnerResp ]: Response[] = value
						const responses: Action[] = []
						if (rewardPoolResp.success) {
							responses.push(ProductCatalogueAction.RewardPoolAndGroupListSuccess({ payload: rewardPoolResp.payload }))
						}
						if (productCategoryResp.success) {
							responses.push(ProductCatalogueAction.ProductCategoryListSuccess({ payload: productCategoryResp.payload}))
						}
						if (statusResp.success) {
							responses.push(ProductCatalogueAction.StatusListSuccess({ payload: statusResp.payload }))
						}
						if (redemptionTypeResp.success) {
							responses.push(ProductCatalogueAction.RedemptionTypeListSuccess({ payload: redemptionTypeResp.payload }))
						}
						if (fufilmentPartnerResp.success) {
							responses.push(ProductCatalogueAction.FulfilmentParnterListSuccess({ payload: fufilmentPartnerResp.payload }))
						}
						if (glRedemptionResp.success) {
							responses.push(ProductCatalogueAction.GlRedemptionListSuccess({ payload: glRedemptionResp.payload }))
						}
						const fail = value
							.filter(v => !v.success)
							.map(v => ProductCatalogueAction.RestError(v as any) )

						return [...responses, ...fail]
						}
					),
					catchError(this.catcher))
				)
			),
		)

	StatusList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ProductCatalogueAction.StatusList),
			withLatestFrom(this.store.select(state => state.productCatalogue)),
			mergeMap(([x, productCatalogue]) => {
				return this.catalogueManagementService.getProductCatalogueStatusList().pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: StatusListResponse = response.payload
					if (success) {
						return ProductCatalogueAction.StatusListSuccess({ payload })
					} else { return ProductCatalogueAction.RestError(response as any) }
				}), catchError(this.catcher))
			})
		)
	)

	RedemptionTypeList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ProductCatalogueAction.RedemptionTypeList),
			withLatestFrom(this.store.select(state => state.productCatalogue)),
			mergeMap(([x, productCatalogue]) => {
				return this.catalogueManagementService.getProductCatalogueRedemptionTypeList().pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: RedemptionTypeListResponse = response.payload
					if (success) {
						return ProductCatalogueAction.RedemptionTypeListSuccess({ payload })
					} else { return ProductCatalogueAction.RestError(response as any) }
				}), catchError(this.catcher))
			})
		)
	)

	ProductCategoryList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ProductCatalogueAction.ProductCategoryList),
			withLatestFrom(this.store.select(state => state.productCatalogue)),
			mergeMap(() => {
				return this.catalogueManagementService.getProductCategoryList().pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: ProductCategoryListResponse = response.payload
					if (success) {
						return ProductCatalogueAction.ProductCategoryListSuccess({ payload })
					} else { return ProductCatalogueAction.RestError(response as any) }
				}), catchError(this.catcher))
			})
		)
	)

	RewardPoolAndGroupList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ProductCatalogueAction.RewardPoolAndGroupList),
			withLatestFrom(this.store.select(state => state.productCatalogue)),
			mergeMap(([x, productCatalogue]) => {
				return this.catalogueManagementService.getProductCatalogueRewardPoolAndGroupList({policy: x.payload as POLICY}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: RewardPoolAndGroup[] = response.payload
					if (success) {
						return ProductCatalogueAction.RewardPoolAndGroupListSuccess({ payload })
					} else { return ProductCatalogueAction.RestError(response as any) }
				}), catchError(this.catcher))
			})
		)
	)

	GlRedemptionList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ProductCatalogueAction.GlRedemptionList),
			withLatestFrom(this.store.select(state => state.productCatalogue)),
			mergeMap(([x, productCatalogue]) => {
				return this.catalogueManagementService.getProductCatalogueGlRedemptionList({policy: x.payload as POLICY}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: GlRedemptionListResponse = response.payload
					if (success) {
						return ProductCatalogueAction.GlRedemptionListSuccess({ payload })
					} else { return ProductCatalogueAction.RestError(response as any) }
				}), catchError(this.catcher))
			})
		)
	)

	FulfilmentPartnerList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ProductCatalogueAction.FulfilmentParnterList),
			withLatestFrom(this.store.select(state => state.productCatalogue)),
			mergeMap(([x, productCatalogue]) => {
				return this.catalogueManagementService.getProductCatalogueFulfilmentPartnerList({policy: x.payload as POLICY}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: FulFilmentPartnerListDataResponse = response.payload
					if (success) {
						return ProductCatalogueAction.FulfilmentParnterListSuccess({ payload })
					} else { return ProductCatalogueAction.RestError(response as any) }
				}), catchError(this.catcher))
			})
		)
	)

	List$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ProductCatalogueAction.List),
			withLatestFrom(this.store.select(state => state.productCatalogue)),
			mergeMap(([x, productCatalogue]) => {
				return this.catalogueManagementService.getProductCatalogueList().pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: ProductCatalogueListResponse = response.payload

					if (success) {
						return ProductCatalogueAction.ListSuccess({ payload })
					} else { return ProductCatalogueAction.RestError(response as any) }
				}), catchError(this.catcher)
				)
			})
		)
	)

	View$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ProductCatalogueAction.View),
			withLatestFrom(this.store.select(state => state.productCatalogue)),
			mergeMap(([, productCatalogue]) => this.catalogueManagementService.getProductCatalogueDetails({ id: productCatalogue.id })
				.pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: ProductCatalogueViewResponse = response.payload
					if (success) {
						return ProductCatalogueAction.ViewSuccess({ payload })
					} else { return ProductCatalogueAction.RestError(response as any) }
				}), catchError(this.catcher)
				)
			)
		)
	)

	CreateDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ProductCatalogueAction.CreateDialog),
			mergeMap(() => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.CREATE_PRODUCT_CATALOGUE' }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return ProductCatalogueAction.Create()
				} else {
					return ProductCatalogueAction.closeDialog()
				}
			})
		)
	)

	UploadProductCatalogueImage$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ProductCatalogueAction.UploadProductCatalogueImage),
			withLatestFrom(this.store.select(state => state.productCatalogue)),
			mergeMap(([param, productCatalogue]) => {
				const form = productCatalogue.productCatalogueDetailForm.controls
				return this.catalogueManagementService.uploadProductCatalogueImage(param.request)
					.pipe(map((response: Response) => {
						const success: boolean = response.success
						const payload: ProductCatalogueImageUploadResponse = response.payload
						if (success) {
							return ProductCatalogueAction.UploadProductCatalogueImageSuccess({ payload })
						} else { return ProductCatalogueAction.RestError(response as any) }
					}), catchError(this.catcher))
			})
		)
	)

	Create$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ProductCatalogueAction.Create),
			withLatestFrom(this.store.select(state => state.productCatalogue)),
			mergeMap(([, productCatalogue]) => {
				const form = productCatalogue.productCatalogueDetailForm.controls

				const productCatalogueRewardPoolsVal = form.productCatalogueRewardPools.value
				const rewardPoolAndGlRedemption = []
				productCatalogueRewardPoolsVal.allIds.forEach(id => {
					if (productCatalogueRewardPoolsVal.byId.hasOwnProperty(id)) {
						const byId = productCatalogueRewardPoolsVal.byId[id]
						const formVal = byId.form.value

						rewardPoolAndGlRedemption.push({
							rewardPoolId: formVal.rewardPoolId,
							glRedemptionId: formVal.glRedemptionId
						})
					}
				})

				return this.catalogueManagementService.createProductCatalogue({
					code: form.code.value,
					name: form.name.value,
					description: form.description.value,
					merchantId: form.merchantId.value,
					status: form.status.value,
					rewardPoolId: form.rewardPoolAndGroup.value != null ? Number(form.rewardPoolAndGroup.value.split('_')[1]) : null,
					rewardPoolType: form.rewardPoolAndGroup.value != null ? form.rewardPoolAndGroup.value.split('_')[0] : null,
					rewardPoolAndGlRedemption,
					glRedemptionId: form.glRedemptionId.value,
					fulfilmentPartner: form.fulfilmentPartner.value,
					startDate: Util.fromISOStringToDateStr(form.startDate.value),
					endDate: Util.fromISOStringToDateStr(form.endDate.value),
					redemptionType: form.redemptionType.value,
					point: Number(form.point.value),
					amount: Number(form.amount.value),
					transactionCode: form.transactionCode.value,
					redemptionRate: Number(form.redemptionRate.value),
					image: form.imageFileName.value,
					glProductCode: form.glProductCode.value,
					productCategory: form.productCategory.value
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: string = response.payload
					if (success) {
						return ProductCatalogueAction.CreateSuccess({ payload })
					} else { return ProductCatalogueAction.RestError(response as any) }
				}), catchError(this.catcher))
			})
		)
	)

	CreateSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ProductCatalogueAction.CreateSuccess),
			mergeMap(({ payload }) => {
				return [ProductCatalogueAction.GoList(), CommonAction.RestError({ message: payload })]
			})
		)
	)

	UpdateDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ProductCatalogueAction.UpdateDialog),
			mergeMap(() => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.UPDATE' }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return ProductCatalogueAction.Update()
				} else {
					return ProductCatalogueAction.closeDialog()
				}
			})
		)
	)

	Update$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ProductCatalogueAction.Update),
			withLatestFrom(this.store.select(state => state.productCatalogue)),
			mergeMap(([, productCatalogue]) => {
				const form = productCatalogue.productCatalogueDetailForm.controls

				const productCatalogueRewardPoolsVal = form.productCatalogueRewardPools.value
				const rewardPoolAndGlRedemption = []
				productCatalogueRewardPoolsVal.allIds.forEach(id => {
					if (productCatalogueRewardPoolsVal.byId.hasOwnProperty(id)) {
						const byId = productCatalogueRewardPoolsVal.byId[id]
						const formVal = byId.form.value

						rewardPoolAndGlRedemption.push({
							rewardPoolId: formVal.rewardPoolId,
							glRedemptionId: formVal.glRedemptionId
						})
					}
				})

				return this.catalogueManagementService.editProductCatalogue({
					id: productCatalogue.id,
					name: form.name.value,
					description: form.description.value,
					merchantId: form.merchantId.value,
					status: form.status.value,
					rewardPoolId: form.rewardPoolAndGroup.value != null ? Number(form.rewardPoolAndGroup.value.split('_')[1]) : null,
					rewardPoolType: form.rewardPoolAndGroup.value != null ? form.rewardPoolAndGroup.value.split('_')[0] : null,
					rewardPoolAndGlRedemption,
					glRedemptionId: form.glRedemptionId.value,
					fulfilmentPartner: form.fulfilmentPartner.value,
					startDate: Util.fromISOStringToDateStr(form.startDate.value),
					endDate: Util.fromISOStringToDateStr(form.endDate.value),
					redemptionType: form.redemptionType.value,
					point: Number(form.point.value),
					amount: Number(form.amount.value),
					transactionCode: form.transactionCode.value,
					redemptionRate: Number(form.redemptionRate.value),
					image: form.imageFileName.value,
					glProductCode: form.glProductCode.value,
					productCategory: form.productCategory.value
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: string = response.payload
					if (success) {
						return ProductCatalogueAction.UpdateSuccess({ payload })
					} else { return ProductCatalogueAction.RestError(response as any) }
				}), catchError(this.catcher))
			})
		)
	)

	UpdateSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ProductCatalogueAction.UpdateSuccess),
			mergeMap(({ payload }) => {
				return [ProductCatalogueAction.GoView(), CommonAction.RestError({ message: payload })]
			})
		)
	)

	DeleteDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ProductCatalogueAction.DeleteDialog),
			mergeMap(() => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.DELETE_PRODUCT_CATALOGUE' }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return ProductCatalogueAction.Delete()
				} else {
					return ProductCatalogueAction.closeDialog()
				}
			})
		)
	)

	Delete$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ProductCatalogueAction.Delete),
			withLatestFrom(this.store.select(state => state.productCatalogue)),
			mergeMap(([, productCatalogue]) => this.catalogueManagementService.deleteProductCatalogue({
				id: productCatalogue.id
			}).pipe(map((response: Response) => {
				const success: boolean = response.success
				const payload: string = response.payload

				if (success) {
					return ProductCatalogueAction.DeleteSuccess({ payload })
				} else { return ProductCatalogueAction.RestError(response as any) }
			}), catchError(this.catcher)
			))
		)
	)

	DeleteSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ProductCatalogueAction.DeleteSuccess),
			mergeMap(({ payload }) => {
				return [ProductCatalogueAction.GoList(), CommonAction.RestError({ message: payload })]
			})
		)
	)

	OnChangeRewardPoolGroup$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ProductCatalogueAction.OnChangeRewardPoolGroup),
			withLatestFrom(this.store.select(state => state.productCatalogue)),
			mergeMap(([, productCatalogue]) => {
				return this.parameterSettingsService.viewRewardPoolGroup({ id: productCatalogue.productCatalogueDetailForm.value.rewardPoolId }).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: RewardPoolGroupViewResponse = response.payload
					if (success) {
						return ProductCatalogueAction.OnChangeRewardPoolGroupSuccess({ payload })
					} else { return ProductCatalogueAction.RestError(response as any) }
				}), catchError(this.catcher))
			})
		)
	)

	DuplicateDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ProductCatalogueAction.DuplicateDialog),
			mergeMap(() => {
				const dialogRef = this.dialog.open(ProductCatalogueDuplicateComponent, getDialogConfig({}))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return ProductCatalogueAction.Duplicate()
				} else {
					return ProductCatalogueAction.closeDialog()
				}
			})
		)
	)

	Duplicate$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ProductCatalogueAction.Duplicate),
			withLatestFrom(this.store.select(state => state.productCatalogue)),
			mergeMap(([, productCatalogue]) => {
				const productCatalogueDuplicateForm = productCatalogue.productCatalogueDuplicateForm.value
				return this.catalogueManagementService.duplicateProductCatalogue(productCatalogue.id, {
					code: productCatalogueDuplicateForm.code,
					name: productCatalogueDuplicateForm.name
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload = response.payload

					if (success) {
						return ProductCatalogueAction.DuplicateSuccess({payload})
					} else {
						return ProductCatalogueAction.RestError(response as any)
					}
				}), catchError(this.catcher))
			})
		)
	)

	DuplicateSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ProductCatalogueAction.DuplicateSuccess),
			mergeMap(({payload}) => {
				return [
					ProductCatalogueAction.List(),
					CommonAction.RestError({message: payload})
				]
			})
		)
	)

	catcher = (error) => {
		return (of(ProductCatalogueAction.RestError({ message: error.message, manualThrow: error.manualThrow })))
	}
}
