import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material'
import { Router } from '@angular/router'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Action, Store } from '@ngrx/store'
import { Observable, of } from 'rxjs'
import { catchError, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators'
import { MessageDialogComponent } from 'src/app/components/common/dialog/message-dialog/message-dialog.component'
import { FileFrequencyListResponse } from 'src/app/models/catalogue-management/fulfilment-partner/fulfilment-partner-file-frequency-list'
import { FulfilmentPartnerListResponse } from 'src/app/models/catalogue-management/fulfilment-partner/fulfilment-partner-list'
import { FulfilmentPartnerViewResponse } from 'src/app/models/catalogue-management/fulfilment-partner/fulfilment-partner-view'
import { getDialogConfig } from 'src/app/models/common/dialog'
import { Response } from 'src/app/models/common/http'
import { CatalogueManagementService } from 'src/app/services/catalogue-management/catalogue-management.service'
import * as AppStore from 'src/app/store/'
import * as CommonAction from '../../common/common/common.actions'
import * as FulfilmentPartnerAction from './fulfilment-partner.actions'
import {FulfilmentPartnerDuplicateComponent} from '../../../components/catalogue-management/fulfilment-partner/fulfilment-partner-duplicate/fulfilment-partner-duplicate.component'

@Injectable()
export class Effects {
	constructor(
		private action$: Actions,
		private router: Router,
		private dialog: MatDialog,
		private catalogueManagementService: CatalogueManagementService,
		private store: Store<AppStore.State>
	) { }

	InitialState$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(FulfilmentPartnerAction.InitialState),
			tap(() => {
				this.router.navigate(['catalogue-management/fulfilment-partner'])
			})
		), { dispatch: false }
	)

	GoList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(FulfilmentPartnerAction.GoList),
			tap(() => {
				this.router.navigate(['catalogue-management/fulfilment-partner'])
			})
		), { dispatch: false }
	)

	GoView$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(FulfilmentPartnerAction.GoView),
			tap(() => {
				this.router.navigate(['catalogue-management/fulfilment-partner/view'])
			})
		), { dispatch: false }
	)

	GoCreate$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(FulfilmentPartnerAction.GoCreate),
			tap(() => {
				this.router.navigate(['catalogue-management/fulfilment-partner/create'])
			})
		), { dispatch: false }
	)

	GoEdit$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(FulfilmentPartnerAction.GoUpdate),
			tap(() => {
				this.router.navigate(['catalogue-management/fulfilment-partner/edit'])
			})
		), { dispatch: false }
	)

	GoBackDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(FulfilmentPartnerAction.GoBackDialog),
			mergeMap(({ action }) => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.BACK', payload: action }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					if (result.payload === 'LIST') {
						return FulfilmentPartnerAction.GoList()
					}
					if (result.payload === 'VIEW') {
						return FulfilmentPartnerAction.GoView()
					}
				} else {
					return FulfilmentPartnerAction.closeDialog()
				}
			})
		)
	)

	RestError$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(FulfilmentPartnerAction.RestError),
			map(({ message, manualThrow }) => {
				return CommonAction.RestError({ message, manualThrow })
			})
		)
	)

	FileFrequencyList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(FulfilmentPartnerAction.FileFrequencyList),
			withLatestFrom(this.store.select(state => state.fulfilmentPartner)),
			mergeMap(([x, fulfilmentPartner]) => {
				return this.catalogueManagementService.getFulfilmentPartnerFileFrequencyList().pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: FileFrequencyListResponse = response.payload
					if (success) {
						return FulfilmentPartnerAction.FileFrequencyListSuccess({ payload })
					} else { return FulfilmentPartnerAction.RestError(response as any) }
				}), catchError(this.catcher))
			})
		)
	)

	List$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(FulfilmentPartnerAction.List),
			withLatestFrom(this.store.select(state => state.fulfilmentPartner)),
			mergeMap(([x, fulfilmentPartner]) => {
				return this.catalogueManagementService.getFulfilmentPartnerList().pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: FulfilmentPartnerListResponse = response.payload

					if (success) {
						return FulfilmentPartnerAction.ListSuccess({ payload })
					} else { return FulfilmentPartnerAction.RestError(response as any) }
				}), catchError(this.catcher)
				)
			})
		)
	)

	View$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(FulfilmentPartnerAction.View),
			withLatestFrom(this.store.select(state => state.fulfilmentPartner)),
			mergeMap(([, fulfilmentPartner]) => this.catalogueManagementService.getFulfilmentPartnerDetails({ id: fulfilmentPartner.id })
				.pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: FulfilmentPartnerViewResponse = response.payload
					if (success) {
						return FulfilmentPartnerAction.ViewSuccess({ payload })
					} else { return FulfilmentPartnerAction.RestError(response as any) }
				}), catchError(this.catcher)
				)
			)
		)
	)

	CreateDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(FulfilmentPartnerAction.CreateDialog),
			mergeMap(() => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.CREATE_FULFILMENT_PARTNER' }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return FulfilmentPartnerAction.Create()
				} else {
					return FulfilmentPartnerAction.closeDialog()
				}
			})
		)
	)

	Create$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(FulfilmentPartnerAction.Create),
			withLatestFrom(this.store.select(state => state.fulfilmentPartner)),
			mergeMap(([, fulfilmentPartner]) => {
				const form = fulfilmentPartner.fulfilmentPartnerDetailForm.controls
				return this.catalogueManagementService.createFulfilmentPartner({
					code: form.code.value,
					name: form.name.value,
					fileFrequency: form.fileFrequency.value,
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: string = response.payload
					if (success) {
						return FulfilmentPartnerAction.CreateSuccess({ payload })
					} else { return FulfilmentPartnerAction.RestError(response as any) }
				}), catchError(this.catcher))
			})
		)
	)

	CreateSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(FulfilmentPartnerAction.CreateSuccess),
			mergeMap(({ payload }) => {
				return [FulfilmentPartnerAction.GoList(), CommonAction.RestError({ message: payload })]
			})
		)
	)

	UpdateDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(FulfilmentPartnerAction.UpdateDialog),
			mergeMap(() => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.UPDATE' }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return FulfilmentPartnerAction.Update()
				} else {
					return FulfilmentPartnerAction.closeDialog()
				}
			})
		)
	)

	Update$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(FulfilmentPartnerAction.Update),
			withLatestFrom(this.store.select(state => state.fulfilmentPartner)),
			mergeMap(([, fulfilmentPartner]) => {
				const form = fulfilmentPartner.fulfilmentPartnerDetailForm.controls
				return this.catalogueManagementService.editFulfilmentPartner({
					id: fulfilmentPartner.id,
					name: form.name.value,
					fileFrequency: form.fileFrequency.value
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: string = response.payload
					if (success) {
						return FulfilmentPartnerAction.UpdateSuccess({ payload })
					} else { return FulfilmentPartnerAction.RestError(response as any) }
				}), catchError(this.catcher))
			})
		)
	)

	UpdateSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(FulfilmentPartnerAction.UpdateSuccess),
			mergeMap(({ payload }) => {
				return [FulfilmentPartnerAction.GoView(), CommonAction.RestError({ message: payload })]
			})
		)
	)

	DeleteDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(FulfilmentPartnerAction.DeleteDialog),
			mergeMap(() => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.DELETE_FULFILMENT_PARTNER' }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return FulfilmentPartnerAction.Delete()
				} else {
					return FulfilmentPartnerAction.closeDialog()
				}
			})
		)
	)

	Delete$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(FulfilmentPartnerAction.Delete),
			withLatestFrom(this.store.select(state => state.fulfilmentPartner)),
			mergeMap(([, fulfilmentPartner]) => this.catalogueManagementService.deleteFulfilmentPartner({
				id: fulfilmentPartner.id
			}).pipe(map((response: Response) => {
				const success: boolean = response.success
				const payload: string = response.payload
				if (success) {
					return FulfilmentPartnerAction.DeleteSuccess({ payload })
				} else { return FulfilmentPartnerAction.RestError(response as any) }
			}), catchError(this.catcher)
			))
		)
	)

	DeleteSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(FulfilmentPartnerAction.DeleteSuccess),
			mergeMap(({ payload }) => {
				return [FulfilmentPartnerAction.GoList(), CommonAction.RestError({ message: payload })]
			})
		)
	)

	DuplicateDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(FulfilmentPartnerAction.DuplicateDialog),
			mergeMap(() => {
				const dialogRef = this.dialog.open(FulfilmentPartnerDuplicateComponent, getDialogConfig({}))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return FulfilmentPartnerAction.Duplicate()
				} else {
					return FulfilmentPartnerAction.closeDialog()
				}
			})
		)
	)

	Duplicate$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(FulfilmentPartnerAction.Duplicate),
			withLatestFrom(this.store.select(state => state.fulfilmentPartner)),
			mergeMap(([, fulfilmentPartner]) => {
				const fulfilmentPartnerDuplicateForm = fulfilmentPartner.fulfilmentPartnerDuplicateForm.value
				return this.catalogueManagementService.duplicateFulfilmentPartner(fulfilmentPartner.id, {
					code: fulfilmentPartnerDuplicateForm.code,
					name: fulfilmentPartnerDuplicateForm.name
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload = response.payload

					if (success) {
						return FulfilmentPartnerAction.DuplicateSuccess({payload})
					} else {
						return FulfilmentPartnerAction.RestError(response as any)
					}
				}), catchError(this.catcher))
			})
		)
	)

	DuplicateSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(FulfilmentPartnerAction.DuplicateSuccess),
			mergeMap(({payload}) => {
				return [
					FulfilmentPartnerAction.List(),
					CommonAction.RestError({message: payload})
				]
			})
		)
	)

	catcher = (error) => {
		return (of(FulfilmentPartnerAction.RestError({ message: error.message, manualThrow: error.manualThrow })))
	}

}
