import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material'
import { Router } from '@angular/router'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Action, Store } from '@ngrx/store'
import { Observable, of } from 'rxjs'
import { catchError, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators'
import { MessageDialogComponent } from 'src/app/components/common/dialog/message-dialog/message-dialog.component'
import { getDialogConfig } from 'src/app/models/common/dialog'
import { Response } from 'src/app/models/common/http'
import { RewardPoolAndGroup } from 'src/app/models/redemption-settings/auto-redemption/auto-redemption-reward-list'
import { RedemptionTypeListResponse, CycleTypeListResponse, CycleMonthlyListResponse, CycleWeeklyListResponse, CycleYearlyListResponse, BillingCycleTypeListResponse, YearlyTypeListResponse, CappingLevelListResponse } from 'src/app/models/redemption-settings/auto-redemption/auto-redemption-option-list'
import { AutoRedemption } from 'src/app/models/redemption-settings/auto-redemption/auto-redemption'
import { AutoRedemptionViewResponse } from 'src/app/models/redemption-settings/auto-redemption/auto-redemption-view'
import { RedemptionSettingsService } from 'src/app/services/redemption-settings/redemption-settings.service'
import * as AppStore from 'src/app/store/'
import * as CommonAction from '../../common/common/common.actions'
import * as AutoRedemptionAction from './auto-redemption.actions'
import { POLICY } from 'src/app/models/common/constant'
import { AutoRedemptionDuplicateComponent } from '../../../components/redemption-settings/auto-redemption/auto-redemption-duplicate/auto-redemption-duplicate.component'

@Injectable()
export class Effects {

	constructor(
		private action$: Actions,
		private router: Router,
		private dialog: MatDialog,
		private redemptionSettingsService: RedemptionSettingsService,
		private store: Store<AppStore.State>
	) { }

	InitialState$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(AutoRedemptionAction.InitialState),
			tap(() => {
				this.router.navigate(['redemption-settings/auto-redemption'])
			})
		), { dispatch: false }
	)

	RestError$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(AutoRedemptionAction.RestError),
			map(({ message, manualThrow }) => {
				return CommonAction.RestError({ message, manualThrow })
			})
		)
	)

	CloseDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(AutoRedemptionAction.CloseDialog),
			tap(() => {
				this.dialog.closeAll()
			})
		), { dispatch: false }
	)

	GoList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(AutoRedemptionAction.GoList),
			tap(() => {
				this.router.navigate(['redemption-settings/auto-redemption'])
			})
		), { dispatch: false }
	)

	GoView$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(AutoRedemptionAction.GoView),
			tap(() => {
				this.router.navigate(['redemption-settings/auto-redemption/view'])
			})
		), { dispatch: false }
	)

	GoUpdate$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(AutoRedemptionAction.GoUpdate),
			tap(() => {
				this.router.navigate(['redemption-settings/auto-redemption/edit'])
			})
		), { dispatch: false }
	)

	GoCreate$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(AutoRedemptionAction.GoCreate),
			tap(() => {
				this.router.navigate(['redemption-settings/auto-redemption/create'])
			})
		), { dispatch: false }
	)

	List$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(AutoRedemptionAction.List),
			withLatestFrom(this.store.select(state => state.autoRedemption)),
			mergeMap(() => {
				return this.redemptionSettingsService.getAutoRedemptionList().pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: AutoRedemption[] = response.payload
					if (success) {
						return AutoRedemptionAction.ListSuccess({ payload })
					} else { return AutoRedemptionAction.RestError(response as any) }
				}),
					catchError((error) => {
						return (of(AutoRedemptionAction.RestError({ message: error.message, manualThrow: true })))
					})
				)
			})
		)
	)

	View$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(AutoRedemptionAction.View),
			withLatestFrom(this.store.select(state => state.autoRedemption)),
			mergeMap(([, autoRedemption]) => this.redemptionSettingsService.viewAutoRedemption({ id: autoRedemption.id })
				.pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: AutoRedemptionViewResponse = response.payload
					if (success) {
						return AutoRedemptionAction.ViewSuccess({ payload })
					} else { return AutoRedemptionAction.RestError(response as any) }
				}), catchError(this.catcher))
			)
		)
	)

	CreateDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(AutoRedemptionAction.CreateDialog),
			mergeMap(() => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.CREATE_AUTO_REDEMPTION' }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return AutoRedemptionAction.Create()
				} else {
					return AutoRedemptionAction.CloseDialog()
				}
			})
		)
	)

	Create$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(AutoRedemptionAction.Create),
			withLatestFrom(this.store.select(state => state.autoRedemption)),
			mergeMap(([, autoRedemption]) => {
				const autoRedemptionDetailForm = autoRedemption.autoRedemptionDetailForm.controls

				return this.redemptionSettingsService.createAutoRedemption({
					code: autoRedemptionDetailForm.code.value,
					name: autoRedemptionDetailForm.name.value,
					rewardPoolId: autoRedemptionDetailForm.rewardPoolId.value != null ? Number(autoRedemptionDetailForm.rewardPoolId.value.split('_')[1]) : null,
					rewardPoolType: autoRedemptionDetailForm.rewardPoolId.value != null ? autoRedemptionDetailForm.rewardPoolId.value.split('_')[0] : null,
					redemptionType: autoRedemptionDetailForm.redemptionType.value,
					point: Number(autoRedemptionDetailForm.point.value),
					value: Number(autoRedemptionDetailForm.value.value),
					cycleType: autoRedemptionDetailForm.cycleType.value,
					cycleDay: autoRedemptionDetailForm.cycleDay.value,
					cycleMonth: autoRedemptionDetailForm.cycleMonth.value,
					creditTransactionCode: autoRedemptionDetailForm.creditTransactionCode.value,
					creditTransactionDescription:  autoRedemptionDetailForm.creditTransactionDescription.value,
					debitTransactionCode:  autoRedemptionDetailForm.debitTransactionCode.value,
					debitTransactionDescription:  autoRedemptionDetailForm.debitTransactionDescription.value,
					cycleTypeOption: autoRedemptionDetailForm.cycleTypeOption.value,
					numberOfDays: autoRedemptionDetailForm.numberOfDays.value != null ? Number(autoRedemptionDetailForm.numberOfDays.value) : null,
					capping: autoRedemptionDetailForm.capping.value,
					cappingLevel: autoRedemptionDetailForm.capping.value ? autoRedemptionDetailForm.cappingLevel.value : '',
					cappingValue: Number(autoRedemptionDetailForm.cappingValue.value) || 0
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload = response.payload
					if (success) {
						return AutoRedemptionAction.CreateSuccess({ payload })
					} else {
						return AutoRedemptionAction.RestError(response as any)
					}
				}), catchError(this.catcher))
			})
		)
	)

	CreateSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(AutoRedemptionAction.CreateSuccess),
			mergeMap(({ payload }) => {
				return [AutoRedemptionAction.GoList(), CommonAction.RestError({ message: payload })]
			})
		)
	)

	GoBackDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(AutoRedemptionAction.GoBackDialog),
			mergeMap(({ action }) => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.BACK', payload: action }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					if (result.payload === 'LIST') {
						return AutoRedemptionAction.GoList()
					}
					if (result.payload === 'VIEW') {
						return AutoRedemptionAction.GoView()
					}
				} else {
					return AutoRedemptionAction.CloseDialog()
				}
			})
		)
	)

	UpdateDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(AutoRedemptionAction.UpdateDialog),
			mergeMap(() => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.UPDATE' }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return AutoRedemptionAction.Update()
				} else {
					return AutoRedemptionAction.CloseDialog()
				}
			})
		)
	)

	Update$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(AutoRedemptionAction.Update),
			withLatestFrom(this.store.select(state => state.autoRedemption)),
			mergeMap(([, autoRedemption]) => {

				const autoRedemptionDetailForm = autoRedemption.autoRedemptionDetailForm.controls
				return this.redemptionSettingsService.editAutoRedemption({
					id: autoRedemption.id,
					name: autoRedemptionDetailForm.name.value,
					rewardPoolId: autoRedemptionDetailForm.rewardPoolId.value != null ? Number(autoRedemptionDetailForm.rewardPoolId.value.split('_')[1]) : null,
					rewardPoolType: autoRedemptionDetailForm.rewardPoolId.value != null ? autoRedemptionDetailForm.rewardPoolId.value.split('_')[0] : null,
					redemptionType: autoRedemptionDetailForm.redemptionType.value,
					point: Number(autoRedemptionDetailForm.point.value),
					value: Number(autoRedemptionDetailForm.value.value),
					cycleType: autoRedemptionDetailForm.cycleType.value,
					cycleDay: autoRedemptionDetailForm.cycleDay.value,
					cycleMonth: autoRedemptionDetailForm.cycleMonth.value,
					creditTransactionCode: autoRedemptionDetailForm.creditTransactionCode.value,
					creditTransactionDescription:  autoRedemptionDetailForm.creditTransactionDescription.value,
					debitTransactionCode:  autoRedemptionDetailForm.debitTransactionCode.value,
					debitTransactionDescription: autoRedemptionDetailForm.debitTransactionDescription.value,
					cycleTypeOption: autoRedemptionDetailForm.cycleTypeOption.value,
					numberOfDays: autoRedemptionDetailForm.numberOfDays.value != null ? Number(autoRedemptionDetailForm.numberOfDays.value) : null,
					capping: autoRedemptionDetailForm.capping.value,
					cappingLevel: autoRedemptionDetailForm.capping.value ? autoRedemptionDetailForm.cappingLevel.value : '',
					cappingValue: Number(autoRedemptionDetailForm.cappingValue.value) || 0
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: string = response.payload
					if (success) {
						return AutoRedemptionAction.UpdateSuccess({ payload })
					} else {
						return AutoRedemptionAction.RestError(response as any)
					}
				}), catchError(this.catcher))
			})
		)
	)

	UpdateSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(AutoRedemptionAction.UpdateSuccess),
			mergeMap(({ payload }) => {
				return [AutoRedemptionAction.GoView(), CommonAction.RestError({ message: payload })]
			})
		)
	)

	DeleteDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(AutoRedemptionAction.DeleteDialog),
			mergeMap(() => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.DELETE_AUTO_REDEMPTION' }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return AutoRedemptionAction.Delete()
				} else {
					return AutoRedemptionAction.CloseDialog()
				}
			})
		)
	)

	Delete$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(AutoRedemptionAction.Delete),
			withLatestFrom(this.store.select(state => state.autoRedemption)),
			mergeMap(([, autoRedemption]) => {
				return this.redemptionSettingsService.deleteAutoRedemption({
					id: autoRedemption.id
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: string = response.payload
					if (success) {
						return AutoRedemptionAction.DeleteSuccess({ payload })
					} else {
						return AutoRedemptionAction.RestError(response as any)
					}
				}), catchError(this.catcher))
			})
		)
	)

	DeleteSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(AutoRedemptionAction.DeleteSuccess),
			mergeMap(({ payload }) => {
				return [AutoRedemptionAction.GoList(), CommonAction.RestError({ message: payload })]
			})
		)
	)

	RewardPoolAndGroupList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(AutoRedemptionAction.RewardPoolAndGroupList),
			withLatestFrom(this.store.select(state => state.autoRedemption)),
			mergeMap(([x, autoRedemption]) => {
				return this.redemptionSettingsService.getAutoRedemptionRewardPoolList({policy: x.payload as POLICY}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: RewardPoolAndGroup[] = response.payload
					if (success) {
						return AutoRedemptionAction.RewardPoolAndGroupListSuccess({ payload })
					} else { return AutoRedemptionAction.RestError(response as any) }
				}), catchError(this.catcher))
			})
		)
	)

	RedemptionTypeList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(AutoRedemptionAction.RedemptionTypeList),
			withLatestFrom(this.store.select(state => state.autoRedemption)),
			mergeMap(([x, autoRedemption]) => {
				return this.redemptionSettingsService.getAutoRedemptionRedemptionTypeList().pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: RedemptionTypeListResponse = response.payload
					if (success) {
						return AutoRedemptionAction.RedemptionTypeListSuccess({ payload })
					} else { return AutoRedemptionAction.RestError(response as any) }
				}), catchError(this.catcher))
			})
		)
	)

	CycleTypeList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(AutoRedemptionAction.RedemptionTypeList),
			withLatestFrom(this.store.select(state => state.autoRedemption)),
			mergeMap(([x, autoRedemption]) => {
				return this.redemptionSettingsService.getAutoRedemptionCycleTypeList().pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: CycleTypeListResponse = response.payload
					if (success) {
						return AutoRedemptionAction.CycleTypeListSuccess({ payload })
					} else { return AutoRedemptionAction.RestError(response as any) }
				}), catchError(this.catcher))
			})
		)
	)

	CycleWeeklyList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(AutoRedemptionAction.RedemptionTypeList),
			withLatestFrom(this.store.select(state => state.autoRedemption)),
			mergeMap(([x, autoRedemption]) => {
				return this.redemptionSettingsService.getCycleWeeklyList().pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: CycleWeeklyListResponse = response.payload
					if (success) {
						return AutoRedemptionAction.CycleWeeklyListSuccess({ payload })
					} else { return AutoRedemptionAction.RestError(response as any) }
				}), catchError(this.catcher))
			})
		)
	)

	CycleMonthlyList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(AutoRedemptionAction.RedemptionTypeList),
			withLatestFrom(this.store.select(state => state.autoRedemption)),
			mergeMap(([x, autoRedemption]) => {
				return this.redemptionSettingsService.getCycleMonthlyList().pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: CycleMonthlyListResponse = response.payload
					if (success) {
						return AutoRedemptionAction.CycleMonthlyListSuccess({ payload })
					} else { return AutoRedemptionAction.RestError(response as any) }
				}), catchError(this.catcher))
			})
		)
	)

	CycleYearlyList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(AutoRedemptionAction.RedemptionTypeList),
			withLatestFrom(this.store.select(state => state.autoRedemption)),
			mergeMap(([x, autoRedemption]) => {
				return this.redemptionSettingsService.getCycleYearlyList().pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: CycleYearlyListResponse = response.payload
					if (success) {
						return AutoRedemptionAction.CycleYearlyListSuccess({ payload })
					} else { return AutoRedemptionAction.RestError(response as any) }
				}), catchError(this.catcher))
			})
		)
	)

	BillingCycleTypeList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(AutoRedemptionAction.RedemptionTypeList),
			withLatestFrom(this.store.select(state => state.autoRedemption)),
			mergeMap(([x, autoRedemption]) => {
				return this.redemptionSettingsService.getAutoRedemptionBillingCycleTypeList().pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: BillingCycleTypeListResponse = response.payload
					if (success) {
						return AutoRedemptionAction.BillingCycleTypeListSuccess({ payload })
					} else { return AutoRedemptionAction.RestError(response as any) }
				}), catchError(this.catcher))
			})
		)
	)

	YearlyTypeList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(AutoRedemptionAction.RedemptionTypeList),
			withLatestFrom(this.store.select(state => state.autoRedemption)),
			mergeMap(([x, autoRedemption]) => {
				return this.redemptionSettingsService.getAutoRedemptionYearlyTypeList().pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: YearlyTypeListResponse = response.payload
					if (success) {
						return AutoRedemptionAction.YearlyTypeListSuccess({ payload })
					} else { return AutoRedemptionAction.RestError(response as any) }
				}), catchError(this.catcher))
			})
		)
	)

	CappingLevelList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(AutoRedemptionAction.CappingLevelList),
			withLatestFrom(this.store.select(state => state.autoRedemption)),
			mergeMap(([x, autoRedemption]) => {
				return this.redemptionSettingsService.getAutoRedemptionCappingLevelList().pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: CappingLevelListResponse = response.payload
					if (success) {
						return AutoRedemptionAction.CappingLevelListSuccess({ payload })
					} else { return AutoRedemptionAction.RestError(response as any) }
				}), catchError(this.catcher))
			})
		)
	)

	DuplicateDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(AutoRedemptionAction.DuplicateDialog),
			mergeMap(() => {
				const dialogRef = this.dialog.open(AutoRedemptionDuplicateComponent, getDialogConfig({}))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return AutoRedemptionAction.Duplicate()
				} else {
					return AutoRedemptionAction.CloseDialog()
				}
			})
		)
	)

	Duplicate$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(AutoRedemptionAction.Duplicate),
			withLatestFrom(this.store.select(state => state.autoRedemption)),
			mergeMap(([, autoRedemption]) => {
				const autoRedemptionDuplicateForm = autoRedemption.autoRedemptionDuplicateForm.value
				return this.redemptionSettingsService.duplicateAutoRedemption(autoRedemption.id, {
					code: autoRedemptionDuplicateForm.code,
					name: autoRedemptionDuplicateForm.name,
					// need refactor
					rewardPoolId: autoRedemptionDuplicateForm.rewardPoolId != null ? Number(autoRedemptionDuplicateForm.rewardPoolId.split('_')[1]) : null,
					rewardPoolType: autoRedemptionDuplicateForm.rewardPoolId != null ? autoRedemptionDuplicateForm.rewardPoolId.split('_')[0] : null,
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload = response.payload

					if (success) {
						return AutoRedemptionAction.DuplicateSuccess({payload})
					} else {
						return AutoRedemptionAction.RestError(response as any)
					}
				}), catchError(this.catcher))
			})
		)
	)

	DuplicateSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(AutoRedemptionAction.DuplicateSuccess),
			mergeMap(({payload}) => {
				return [
					AutoRedemptionAction.List(),
					CommonAction.RestError({message: payload})
				]
			})
		)
	)

	DuplicateRewardPoolAndGroupList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(AutoRedemptionAction.DuplicateRewardPoolAndGroupList),
			withLatestFrom(this.store.select(state => state.autoRedemption)),
			mergeMap(([x]) => {
				return this.redemptionSettingsService.getAutoRedemptionRewardPoolList({policy: x.payload as POLICY}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: RewardPoolAndGroup[] = response.payload
					if (success) {
						return AutoRedemptionAction.DuplicateRewardPoolAndGroupListSuccess({payload})
					} else {
						return AutoRedemptionAction.RestError(response as any)
					}
				}), catchError(this.catcher))
			})
		)
	)

	catcher = (error) => {
		return (of(AutoRedemptionAction.RestError({ message: error.message, manualThrow: error.manualThrow })))
	}

}
