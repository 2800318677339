import { KeyValue } from '@angular/common'
import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material'
import { Router } from '@angular/router'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Action, Store } from '@ngrx/store'
import { Observable, of } from 'rxjs'
import { catchError, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators'
import { MessageDialogComponent } from 'src/app/components/common/dialog/message-dialog/message-dialog.component'
import { getDialogConfig } from 'src/app/models/common/dialog'
import { Response } from 'src/app/models/common/http'
import { GlProvision } from 'src/app/models/param-settings/gl-provision/gl-provision'
import { GlProvisionListResponse } from 'src/app/models/param-settings/gl-provision/gl-provision-list'
import { RewardPoolListResponse } from 'src/app/models/param-settings/reward-pool/reward-pool-list'
import { RewardPoolCycleListOptionResponse, RewardPoolTypeListOptionResponse } from 'src/app/models/param-settings/reward-pool/reward-pool-option'
import { RewardPoolViewResponse } from 'src/app/models/param-settings/reward-pool/reward-pool-view'
import { ParameterSettingsService } from 'src/app/services/param-settings/parameter-settings.service'
import * as AppStore from 'src/app/store/'
import * as CommonAction from '../../common/common/common.actions'
import * as RewardPoolAction from './reward-pool.actions'
import { RewardPoolDuplicateComponent } from '../../../components/param-settings/reward-pool/reward-pool-duplicate/reward-pool-duplicate.component'

@Injectable()
export class Effects {

	constructor(
		private action$: Actions,
		private router: Router,
		private dialog: MatDialog,
		private parameterSettingsService: ParameterSettingsService,
		private store: Store<AppStore.State>
	) { }

	InitialState$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RewardPoolAction.InitialState),
			tap(() => {
				this.router.navigate(['parameter-settings/reward-pool'])
			})
		), { dispatch: false }
	)

	RestError$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RewardPoolAction.RestError),
			map(({ message, manualThrow }) => {
				return CommonAction.RestError({ message, manualThrow })
			})
		)
	)

	GoList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RewardPoolAction.GoList),
			tap(() => {
				this.router.navigate(['parameter-settings/reward-pool'])
			})
		), { dispatch: false }
	)

	OptionList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RewardPoolAction.OptionList),
			mergeMap(() => {
				return this.parameterSettingsService.getRewardPoolCycleListOptions().pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: RewardPoolCycleListOptionResponse = response.payload
					if (success) {
						return RewardPoolAction.OptionListSuccess({ payload })
					} else { return RewardPoolAction.RestError(response as any) }
				}), catchError(this.catcher))
			})
		)
	)

	RewardPoolTypeOptionList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RewardPoolAction.RewardPoolTypeOptionList),
			mergeMap(() => {
				return this.parameterSettingsService.getRewardPoolTypeListOptions().pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: RewardPoolTypeListOptionResponse = response.payload
					if (success) {
						return RewardPoolAction.RewardPoolTypeOptionListSuccess({ payload })
					} else { return RewardPoolAction.RestError(response as any) }
				}), catchError(this.catcher))
			})
		)
	)

	GLOptionListCreate$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RewardPoolAction.GLOptionListCreate),
			mergeMap(() => {
				return this.parameterSettingsService.getGLProvisionListFromRewardPoolApi({ action: 'create' }).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: GlProvisionListResponse = response.payload
					if (success) {
						return RewardPoolAction.GLOptionListCreateSuccess({ payload })
					} else { return RewardPoolAction.RestError(response as any) }
				}), catchError(this.catcher))
			})
		)
	)

	GLOptionListUpdate$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RewardPoolAction.GLOptionListUpdate),
			mergeMap(() => {
				return this.parameterSettingsService.getGLProvisionListFromRewardPoolApi({ action: 'update' }).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: GlProvisionListResponse = response.payload
					if (success) {
						return RewardPoolAction.GLOptionListUpdateSuccess({ payload })
					} else { return RewardPoolAction.RestError(response as any) }
				}), catchError(this.catcher))
			})
		)
	)

	ResourceListCreate$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RewardPoolAction.ResourceListCreate),
			mergeMap(() => {
				return this.parameterSettingsService.getResourceList({ module: 'rewardPool', action: 'create' }).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: KeyValue<string, string>[] = response.payload
					if (success) {
						return RewardPoolAction.ResourceListCreateSuccess({ payload })
					} else { return RewardPoolAction.RestError(response as any) }
				}), catchError(this.catcher))
			})
		)
	)

	ResourceListUpdate$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RewardPoolAction.ResourceListUpdate),
			mergeMap(() => {
				return this.parameterSettingsService.getResourceList({ module: 'rewardPool', action: 'update' }).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: KeyValue<string, string>[] = response.payload
					if (success) {
						return RewardPoolAction.ResourceListUpdateSuccess({ payload })
					} else { return RewardPoolAction.RestError(response as any) }
				}), catchError(this.catcher))
			})
		)
	)

	GLDetails$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RewardPoolAction.GLDetails),
			withLatestFrom(this.store.select(state => state.rewardPool)),
			mergeMap(([, rewardPool]) => {
				return this.parameterSettingsService.getGlProvisionByRewardPoolId({ id: `${rewardPool.id}` }).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: GlProvision = response.payload
					if (success) {
						return RewardPoolAction.GLDetailsSuccess({ payload })
					} else { return RewardPoolAction.RestError(response as any) }
				}), catchError(this.catcher))
			})
		)
	)

	GoView$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RewardPoolAction.GoView),
			tap(() => {
				this.router.navigate(['parameter-settings/reward-pool/view'])
			})
		), { dispatch: false }
	)

	GoCreate$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RewardPoolAction.GoCreate),
			tap(() => {
				this.router.navigate(['parameter-settings/reward-pool/create'])
			})
		), { dispatch: false }
	)

	GoUpdate$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RewardPoolAction.GoUpdate),
			tap(() => {
				this.router.navigate(['parameter-settings/reward-pool/update'])
			})
		), { dispatch: false }
	)

	GoBackDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RewardPoolAction.GoBackDialog),
			mergeMap(({ action }) => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.BACK', payload: action }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					if (result.payload === 'LIST') {
						return RewardPoolAction.GoList()
					}
					if (result.payload === 'VIEW') {
						return RewardPoolAction.GoView()
					}
				} else {
					return RewardPoolAction.CloseDialog()
				}
			})
		)
	)

	List$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RewardPoolAction.List),
			withLatestFrom(this.store.select(state => state.rewardPool)),
			mergeMap(([x, rewardPool]) => {

				return this.parameterSettingsService.getRewardPoolList().pipe(map((response: Response) => {

					const success: boolean = response.success
					const payload: RewardPoolListResponse = response.payload
					if (success) {
						return RewardPoolAction.ListSuccess({ payload })
					} else { return RewardPoolAction.RestError(response as any) }
				}),
					catchError((error) => {
						return (of(RewardPoolAction.RestError({ message: error.message, manualThrow: true })))
					})
				)
			})
		)
	)

	View$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RewardPoolAction.View),
			withLatestFrom(this.store.select(state => state.rewardPool)),
			mergeMap(([, rewardPool]) => this.parameterSettingsService.viewRewardPool({ id: `${rewardPool.id}` })
				.pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: RewardPoolViewResponse = response.payload
					if (success) {
						return RewardPoolAction.ViewSuccess({ payload })
					} else { return RewardPoolAction.RestError(response as any) }
				}), catchError(this.catcher))
			)
		)
	)

	CreateDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RewardPoolAction.CreateDialog),
			mergeMap(() => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.CREATE_REWARD_POOL' }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return RewardPoolAction.Create()
				} else {
					return RewardPoolAction.CloseDialog()
				}
			})
		)
	)

	Create$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RewardPoolAction.Create),
			withLatestFrom(this.store.select(state => state.rewardPool)),
			mergeMap(([, rewardPool]) => {
				const form = rewardPool.rewardPoolDetailForm.controls
				return this.parameterSettingsService.createRewardPool({
					code: form.code.value,
					name: form.name.value,
					accumulationPeriod: {
						expCycleType: form.expCycleType.value,
						expCycle: Number(form.expCycle.value),
					},
					glId: Number(form.glId.value),
					resourceCode: form.resourceCode.value,
					rewardPoolType: form.rewardPoolType.value,
					glProductCode: form.glProductCode.value,
					statement: form.statement.value
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: string = response.payload
					if (success) {
						return RewardPoolAction.CreateSuccess({ payload })
					} else { return RewardPoolAction.RestError(response as any) }
				}), catchError(this.catcher))
			})
		)
	)

	CreateSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RewardPoolAction.CreateSuccess),
			mergeMap(({ payload }) => {
				return [RewardPoolAction.GoList(), CommonAction.RestError({ message: payload })]
			})
		)
	)

	UpdateDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RewardPoolAction.UpdateDialog),
			mergeMap(() => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.UPDATE' }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return RewardPoolAction.Update()
				} else {
					return RewardPoolAction.CloseDialog()
				}
			})
		)
	)

	Update$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RewardPoolAction.Update),
			withLatestFrom(this.store.select(state => state.rewardPool)),
			mergeMap(([, rewardPool]) => {
				const form = rewardPool.rewardPoolDetailForm.controls
				return this.parameterSettingsService.updateRewardPool({
					id: Number(form.id.value),
					code: form.code.value,
					name: form.name.value,
					resourceCode: form.resourceCode.value,
					accumulationPeriod: {
						expCycleType: form.expCycleType.value,
						expCycle: (form.expCycleType.value === 'E') ? 0 : Number(form.expCycle.value),
					},
					glId: Number(form.glId.value),
					rewardPoolType: form.rewardPoolType.value,
					glProductCode: form.glProductCode.value,
					statement: form.statement.value
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: string = response.payload
					if (success) {
						return RewardPoolAction.UpdateSuccess({ payload })
					} else { return RewardPoolAction.RestError(response as any) }
				}), catchError(this.catcher))
			})
		)
	)

	UpdateSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RewardPoolAction.UpdateSuccess),
			mergeMap(({ payload }) => {
				return [RewardPoolAction.GoView(), CommonAction.RestError({ message: payload })]
			})
		)
	)

	DeleteDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RewardPoolAction.DeleteDialog),
			mergeMap(() => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.DELETE_REWARD_POOL' }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return RewardPoolAction.Delete()
				} else {
					return RewardPoolAction.CloseDialog()
				}
			})
		)
	)

	Delete$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RewardPoolAction.Delete),
			withLatestFrom(this.store.select(state => state.rewardPool)),
			mergeMap(([, rewardPool]) => this.parameterSettingsService.deleteRewardPool({
				id: `${rewardPool.id}`
			}).pipe(map((response: Response) => {
				const success: boolean = response.success
				const payload: string = response.payload

				if (success) {
					return RewardPoolAction.DeleteSuccess({ payload })
				} else { return RewardPoolAction.RestError(response as any) }
			}), catchError(this.catcher)
			))
		)
	)

	DeleteSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RewardPoolAction.DeleteSuccess),
			mergeMap(({ payload }) => {
				return [RewardPoolAction.GoList(), CommonAction.RestError({ message: payload })]
			})
		)
	)

	DuplicateDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RewardPoolAction.DuplicateDialog),
			mergeMap(() => {
				const dialogRef = this.dialog.open(RewardPoolDuplicateComponent, getDialogConfig({}))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return RewardPoolAction.Duplicate()
				} else {
					return RewardPoolAction.CloseDialog()
				}
			})
		)
	)

	Duplicate$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RewardPoolAction.Duplicate),
			withLatestFrom(this.store.select(state => state.rewardPool)),
			mergeMap(([, rewardPool]) => {
				const rewardPoolDuplicateForm = rewardPool.rewardPoolDuplicateForm.value
				return this.parameterSettingsService.duplicateRewardPool(rewardPool.id, {
					code: rewardPoolDuplicateForm.code,
					name: rewardPoolDuplicateForm.name
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload = response.payload

					if (success) {
						return RewardPoolAction.DuplicateSuccess({payload})
					} else {
						return RewardPoolAction.RestError(response as any)
					}
				}), catchError(this.catcher))
			})
		)
	)

	DuplicateSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RewardPoolAction.DuplicateSuccess),
			mergeMap(({payload}) => {
				return [
					RewardPoolAction.List(),
					CommonAction.RestError({message: payload})
				]
			})
		)
	)

	catcher = (error) => {
		return (of(RewardPoolAction.RestError({ message: error.message, manualThrow: error.manualThrow })))
	}
}
