import { WorklistDetailResponse } from 'src/app/models/worklist/worklist'

export interface State {
	isLoading: boolean
	allTaskListResponse: WorklistDetailResponse
	pendingTaskListResponse: WorklistDetailResponse
	approvedTaskListResponse: WorklistDetailResponse
	rejectedTaskListResponse: WorklistDetailResponse
}

export const initialState: State = {
	isLoading: false,
	allTaskListResponse: {
		pageHeader: {
			itemCount: 0,
			pageNum: 0,
			pageSize: 0,
			totalItem: 0,
			totalPage: 0
		},
		worklist: []
	},
	pendingTaskListResponse: {
		pageHeader: {
			itemCount: 0,
			pageNum: 0,
			pageSize: 0,
			totalItem: 0,
			totalPage: 0
		},
		worklist: []
	},
	approvedTaskListResponse: {
		pageHeader: {
			itemCount: 0,
			pageNum: 0,
			pageSize: 0,
			totalItem: 0,
			totalPage: 0
		},
		worklist: []
	},
	rejectedTaskListResponse: {
		pageHeader: {
			itemCount: 0,
			pageNum: 0,
			pageSize: 0,
			totalItem: 0,
			totalPage: 0
		},
		worklist: []
	}
}
