import { Action, createReducer, on } from '@ngrx/store'
import * as FileDownloadAction from './file-download.actions'
import * as FileDownloadState from './file-download.state'

export const reducer = createReducer(
	FileDownloadState.initialState,
	on(FileDownloadAction.InitialState, () => FileDownloadState.initialState),
	on(FileDownloadAction.RestError, state => ({
		...state,
		isLoading: false
	})),
	on(FileDownloadAction.List, state => ({
		...state,
		isLoading: true
	})),
	on(FileDownloadAction.ListSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		fileDownloadList: payload
	}))
)

export function Reducer(state: FileDownloadState.State = FileDownloadState.initialState, action: Action) {
	return reducer(state, action)
}
