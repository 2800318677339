<app-spinner [isLoading]="isLoading"></app-spinner>

<div *ngIf="!isSimulationQueue" >
<div class="title-div" fxLayout="row" fxLayoutAlign="space-between center">
  <span *ngIf="(formState$ | async).ticketType === 'R'" class="title">
    {{ 'SIMULATOR.RAW_DATA_SIMULATION_RESULT' | translate }} </span>
  <span *ngIf="(formState$ | async).ticketType === 'C'" class="title">
    {{ 'SIMULATOR.CONFLICT_DATA_SIMULATION_RESULT' | translate }} </span>
  <button class="close-icon" mat-icon-button (click)="cancel()">
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-dialog-content style="width: 60vw;">
  <div *ngIf="(formState$ | async).ticketType === 'R' && (
    (formState$ | async).simulatorTarget === 'C' ||
    (formState$ | async).simulatorTarget === 'PB'
  )" fxLayout="column" fxFlex>
    <div fxLayout="row" fxFlex>
      <span class="simulator-label" fxFlex="24">{{ 'SIMULATOR.CAMPAIGN' | translate }}</span>
      <h3 class="simulator-symbol" fxFlex="4">:</h3>
      <span class="simulator-value" fxFlex="64">{{ (formState$ | async).result.name }}</span>
    </div>

    <div fxLayout="row" fxFlex>
      <span class="simulator-label" fxFlex="24">{{ 'SIMULATOR.TRANSACTION_HIT' | translate }}</span>
      <h3 class="simulator-symbol" fxFlex="4">:</h3>
      <span class="simulator-value" fxFlex="64">{{ (formState$ | async).result.transactionHit }}</span>
    </div>

    <div fxLayout="row" fxFlex>
      <span class="simulator-label" fxFlex="24">{{ 'SIMULATOR.TOTAL_AMOUNT_HIT' | translate }}</span>
      <h3 class="simulator-symbol" fxFlex="4">:</h3>
      <span class="simulator-value" fxFlex="64">{{ (formState$ | async).result.totalAmountHit }}</span>
    </div>

    <div fxLayout="row" fxFlex>
      <span class="simulator-label" fxFlex="24">{{ 'SIMULATOR.TOTAL_REWARDED_POINT' | translate }}</span>
      <h3 class="simulator-symbol" fxFlex="4">:</h3>
      <span class="simulator-value" fxFlex="64">{{ (formState$ | async).result.totalRewardedPoint }}</span>
    </div>

    <div fxLayout="column">
      <br>
      <div class="table-container-without-hover">
        <table mat-table [dataSource]="rawDataCampaignDataSource" matSort matSortDisabled>

          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ 'SIMULATOR.RULESET' | translate }}
            </th>
            <td mat-cell *matCellDef="let row">{{row.name}}</td>
          </ng-container>

          <ng-container matColumnDef="rewardPool">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ 'SIMULATOR.REWARD_POOL' | translate }}
            </th>
            <td mat-cell *matCellDef="let row">{{row.rewardPool}}</td>
          </ng-container>

          <ng-container matColumnDef="reward">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ 'SIMULATOR.REWARD' | translate }}
            </th>
            <td mat-cell *matCellDef="let row">{{row.reward}}</td>
          </ng-container>

          <ng-container matColumnDef="transactionHit">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ 'SIMULATOR.TXN_HIT' | translate }}
            </th>
            <td mat-cell *matCellDef="let row">{{row.transactionHit}}</td>
          </ng-container>

          <ng-container matColumnDef="transactionAmount">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ 'SIMULATOR.TXN_AMOUNT' | translate }}
            </th>
            <td mat-cell *matCellDef="let row">{{row.transactionAmount}}</td>
          </ng-container>

          <ng-container matColumnDef="rewardPoint">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ 'SIMULATOR.REWARD_POINT' | translate }}
            </th>
            <td mat-cell *matCellDef="let row">{{row.rewardPoint}}</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="rawDataCampaignDisplayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: rawDataCampaignDisplayedColumns;"></tr>
        </table>
        <div class="table-no-data" *ngIf="rawDataCampaignDataSource.filteredData.length === 0"> {{'ERROR.TABLE_NO_RECORD' | translate}} </div>
      </div>
    </div>
  </div>

  <div *ngIf="(formState$ | async).ticketType === 'R' && (formState$ | async).simulatorTarget === 'CR'"
    fxLayout="column" fxFlex>
    <div fxLayout="row" fxFlex>
      <span class="simulator-label" fxFlex="24">{{ 'SIMULATOR.NAME' | translate }}</span>
      <h3 class="simulator-symbol" fxFlex="4">:</h3>
      <span class="simulator-value" fxFlex="64">{{ (formState$ | async).result.name }}</span>
    </div>
    <div *ngFor="let tier of (formState$ | async).result.tierList;trackBy: trackByIndex;let i=index" fxFlexOffset="8px">
      <app-simulator-tier [tier]="tier" [index]="i"></app-simulator-tier>
    </div>
  </div>

  <div *ngIf="(formState$ | async).ticketType === 'C'" fxLayout="column" fxFlex>
    <div fxLayout="column">
      <br>
      {{ 'SIMULATOR.SELECTED_CAMPAIGN' | translate }}
      <div class="table-container-without-hover">
        <table mat-table [dataSource]="conflictDataCampaignDataSource" matSort matSortDisabled>

          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ 'SIMULATOR.CAMPAIGN' | translate }}
            </th>
            <td mat-cell *matCellDef="let row">{{row.name}}</td>
          </ng-container>

          <ng-container matColumnDef="channel">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ 'SIMULATOR.CHANNEL' | translate }}
            </th>
            <td mat-cell *matCellDef="let row">{{row.channel}}</td>
          </ng-container>

          <ng-container matColumnDef="priority">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ 'SIMULATOR.PRIORITY' | translate }}
            </th>
            <td mat-cell *matCellDef="let row">{{row.priority}}</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="conflictDataCampaignDisplayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: conflictDataCampaignDisplayedColumns;"></tr>
        </table>
        <div class="table-no-data" *ngIf="conflictDataCampaignDataSource.filteredData.length === 0"> {{'ERROR.TABLE_NO_RECORD' | translate}} </div>
      </div>
      <br>
      {{ 'SIMULATOR.CONFLICT_RESULT' | translate }}
      <div class="table-container-without-hover">
        <table mat-table [dataSource]="conflictDataDataSource" matSort matSortDisabled>

          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ 'SIMULATOR.CAMPAIGN' | translate }}
            </th>
            <td mat-cell *matCellDef="let row">{{row.name}}</td>
          </ng-container>

          <ng-container matColumnDef="rule">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ 'SIMULATOR.CAMPAIGN_RULE' | translate }}
            </th>
            <td mat-cell *matCellDef="let row">{{row.rule}}</td>
          </ng-container>

          <ng-container matColumnDef="rewardUnitType">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ 'SIMULATOR.REWARD_UNIT_TYPE' | translate }}
            </th>
            <td mat-cell *matCellDef="let row">{{row.rewardUnitType}}</td>
          </ng-container>

          <ng-container matColumnDef="amount">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ 'SIMULATOR.AMOUNT' | translate }}
            </th>
            <td mat-cell *matCellDef="let row">{{row.amount}}</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="conflictDataDisplayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: conflictDataDisplayedColumns;"></tr>
        </table>
        <div class="table-no-data" *ngIf="conflictDataDataSource.filteredData.length === 0"> {{'ERROR.TABLE_NO_RECORD' | translate}} </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
</div>

<div *ngIf="isSimulationQueue" >
	<div class="title-div" fxLayout="row" fxLayoutAlign="space-between center">
		<span>
		  {{ 'SIMULATOR.SIMULATION_QUEUE_DETAIL' | translate }} </span>
		<button class="close-icon" mat-icon-button (click)="cancel()">
		  <mat-icon>close</mat-icon>
		</button>
	  </div>

	<div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="20px">
		<mat-form-field fxFlex="50">
			<mat-label>{{ 'SIMULATOR.CODE' | translate }}</mat-label>
			<input matInput value="{{ticketSequence.code}}" type="text" readonly>
		</mat-form-field>

		<mat-form-field fxFlex="50">
			<mat-label>{{ 'SIMULATOR.NAME' | translate }}</mat-label>
			<input matInput value="{{ticketSequence.name}}" type="text" readonly>
		</mat-form-field>
	</div>

	<div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="20px">
		<mat-form-field fxFlex="50">
			<mat-label>{{ 'SIMULATOR.CHANNEL' | translate }}</mat-label>
			<input matInput value="{{ticketSequence.simulatorTarget}}" type="text" readonly>
		</mat-form-field>

		<mat-form-field fxFlex="50">
			<mat-label>{{ 'SIMULATOR.SIMULATION_TYPE' | translate }}</mat-label>
			<input matInput value="{{ticketSequence.ticketType}}" type="text" readonly>
		</mat-form-field>
	</div>

	<div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="20px">
		<mat-form-field fxFlex="50">
			<mat-label>{{ 'SIMULATOR.SEQUENCE' | translate }}</mat-label>
			<input matInput value="{{ticketSequence.sequence}}" type="text" readonly>
		</mat-form-field>

		<mat-form-field fxFlex="50">
			<mat-label>{{ 'SIMULATOR.STATUS' | translate }}</mat-label>
			<input matInput value="{{ticketSequence.status}}" type="text" readonly>
		</mat-form-field>
	</div>

	<div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="20px">
		<mat-form-field fxFlex="50">
			<mat-label>{{ 'COMMON.START_DATE' | translate }}</mat-label>
			<input matInput value="{{ticketSequence.startDate}}" type="text" readonly>
		</mat-form-field>

		<mat-form-field fxFlex="50">
			<mat-label>{{ 'COMMON.END_DATE' | translate }}</mat-label>
			<input matInput value="{{ticketSequence.endDate}}" type="text" readonly>
		</mat-form-field>
	</div>
</div>
