import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material'
import { Router } from '@angular/router'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Action, Store } from '@ngrx/store'
import { Observable, of } from 'rxjs'
import { catchError, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators'
import { MessageDialogComponent } from 'src/app/components/common/dialog/message-dialog/message-dialog.component'
import { getDialogConfig } from 'src/app/models/common/dialog'
import { Response } from 'src/app/models/common/http'
import { GlRedemptionViewResponse } from 'src/app/models/param-settings/gl-redemption/gl-redemption-view'
import { ParameterSettingsService } from 'src/app/services/param-settings/parameter-settings.service'
import * as AppStore from 'src/app/store/'
import * as CommonAction from '../../common/common/common.actions'
import * as GlRedemptionAction from './gl-redemption.actions'
import { GlRedemptionListResponse } from 'src/app/models/param-settings/gl-redemption/gl-redemption-list'
import { GlRedemptionData } from 'src/app/models/param-settings/gl-redemption/gl-redemption-data'
import { GlRedemptionDuplicateComponent } from 'src/app/components/param-settings/gl-management/gl-redemption/gl-redemption-duplicate/gl-redemption-duplicate.component'

@Injectable()
export class Effects {

	constructor(
		private action$: Actions,
		private router: Router,
		private dialog: MatDialog,
		private parameterSettingsService: ParameterSettingsService,
		private store: Store<AppStore.State>
	) { }

	InitialState$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(GlRedemptionAction.InitialState),
			tap(() => {
				this.router.navigate(['parameter-settings/gl-management'])
			})
		), { dispatch: false }
	)

	GoList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(GlRedemptionAction.GoList),
			tap(() => {
				this.router.navigate(['parameter-settings/gl-management'])
			})
		), { dispatch: false }
	)

	GoView$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(GlRedemptionAction.GoView),
			tap(() => {
				this.router.navigate(['parameter-settings/gl-redemption/view'])
			})
		), { dispatch: false }
	)

	GoCreate$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(GlRedemptionAction.GoCreate),
			tap(() => {
				this.router.navigate(['parameter-settings/gl-redemption/create'])
			})
		), { dispatch: false }
	)

	GoUpdate$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(GlRedemptionAction.GoUpdate),
			tap(() => {
				this.router.navigate(['parameter-settings/gl-redemption/update'])
			})
		), { dispatch: false }
	)

	GoBackDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(GlRedemptionAction.GoBackDialog),
			mergeMap(({ action }) => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.BACK', payload: action }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					if (result.payload === 'LIST') {
						return GlRedemptionAction.GoList()
					}
					if (result.payload === 'VIEW') {
						return GlRedemptionAction.GoView()
					}
				} else {
					return GlRedemptionAction.CloseDialog()
				}
			})
		)
	)

	RestError$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(GlRedemptionAction.RestError),
			map(({ message, manualThrow }) => {
				return CommonAction.RestError({ message, manualThrow })
			})
		)
	)

	List$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(GlRedemptionAction.List),
			withLatestFrom(this.store.select(state => state.glRedemption)),
			mergeMap(([x, glRedemption]) => {
				return this.parameterSettingsService.getGlRedemptionList().pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: GlRedemptionListResponse = response.payload
					if (success) {
						return GlRedemptionAction.ListSuccess({ payload })
					} else { return GlRedemptionAction.RestError(response as any) }
				}), catchError(this.catcher))
			})
		)
	)

	View$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(GlRedemptionAction.View),
			withLatestFrom(this.store.select(state => state.glRedemption)),
			mergeMap(([, glRedemption]) => this.parameterSettingsService.viewGlRedemption({
				id: glRedemption.id
			}).pipe(map((response: Response) => {
				const success: boolean = response.success
				const payload: GlRedemptionViewResponse = response.payload

				if (success) {
					return GlRedemptionAction.ViewSuccess({ payload })
				} else { return GlRedemptionAction.RestError(response as any) }

			}), catchError(this.catcher)))
		)
	)

	CreateDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(GlRedemptionAction.CreateDialog),
			mergeMap(() => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.CREATE_GL_REDEMPTION' }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return GlRedemptionAction.Create()
				} else {
					return GlRedemptionAction.CloseDialog()
				}
			})
		)
	)

	Create$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(GlRedemptionAction.Create),
			withLatestFrom(this.store.select(state => state.glRedemption)),
			mergeMap(([, glRedemption]) => {
				const form = glRedemption.glRedemptionDetailForm.controls
				return this.parameterSettingsService.createGlRedemption({
					code: form.code.value,
					name: form.name.value,
					creditGlCode: form.creditGlCode.value,
					creditBranch: form.creditBranch.value,
					creditBranchCostCenter: form.creditBranchCostCenter.value,
					creditEntityKey: form.creditEntityKey.value,

				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: string = response.payload
					if (success) {
						return GlRedemptionAction.CreateSuccess({ payload })
					} else { return GlRedemptionAction.RestError(response as any) }

				}), catchError(this.catcher))
			})
		)
	)

	CreateSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(GlRedemptionAction.CreateSuccess),
			map(() => {
				return GlRedemptionAction.GoView()
			})
		)
	)

	UpdateDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(GlRedemptionAction.UpdateDialog),
			mergeMap(() => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.UPDATE' }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return GlRedemptionAction.Update()
				} else {
					return GlRedemptionAction.CloseDialog()
				}
			})
		)
	)

	Update$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(GlRedemptionAction.Update),
			withLatestFrom(this.store.select(state => state.glRedemption)),
			mergeMap(([, glRedemption]) => {
				const form = glRedemption.glRedemptionDetailForm.controls
				return this.parameterSettingsService.updateGlRedemption({
					id: glRedemption.id,
					name: form.name.value,
					creditGlCode: form.creditGlCode.value,
					creditBranch: form.creditBranch.value,
					creditBranchCostCenter: form.creditBranchCostCenter.value,
					creditEntityKey: form.creditEntityKey.value,
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: string = response.payload
					if (success) {
						return GlRedemptionAction.UpdateSuccess({ payload })
					} else { return GlRedemptionAction.RestError(response as any) }

				}), catchError(this.catcher))
			})
		)
	)

	UpdateSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(GlRedemptionAction.UpdateSuccess),
			map(() => {
				return GlRedemptionAction.GoView()
			})
		)
	)

	DeleteDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(GlRedemptionAction.DeleteDialog),
			mergeMap(() => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.DELETE_GL_REDEMPTION' }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return GlRedemptionAction.Delete()
				} else {
					return GlRedemptionAction.CloseDialog()
				}
			})
		)
	)

	Delete$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(GlRedemptionAction.Delete),
			withLatestFrom(this.store.select(state => state.glRedemption)),
			mergeMap(([, glRedemption]) => this.parameterSettingsService.deleteGlRedemption({
				id: glRedemption.id
			}).pipe(map((response: Response) => {
				const success: boolean = response.success

				if (success) {
					return GlRedemptionAction.DeleteSuccess()
				} else { return GlRedemptionAction.RestError(response as any) }

			}), catchError(this.catcher)))
		)
	)

	DeleteSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(GlRedemptionAction.DeleteSuccess),
			map(() => {
				return GlRedemptionAction.GoList()
			})
		)
	)

	GetGlRedemptionData$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(GlRedemptionAction.GetGlRedemptionData),
			mergeMap(() => {
				return this.parameterSettingsService.getGlRedemptionData().pipe(
					map((response: Response) => {
						const success: boolean = response.success
						const payload: GlRedemptionData = response.payload

						if (success) {
							return GlRedemptionAction.GetGlRedemptionDataSuccess({ payload })
						} else {
							return GlRedemptionAction.RestError(response as any)
						}

					}), catchError(this.catcher)
				)
			})
		)
	)

	DuplicateDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(GlRedemptionAction.DuplicateDialog),
			mergeMap(() => {
				const dialogRef = this.dialog.open(GlRedemptionDuplicateComponent, getDialogConfig({}))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return GlRedemptionAction.Duplicate()
				} else {
					return GlRedemptionAction.CloseDialog()
				}
			})
		)
	)

	Duplicate$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(GlRedemptionAction.Duplicate),
			withLatestFrom(this.store.select(state => state.glRedemption)),
			mergeMap(([, glRedemption]) => {
				const glRedemptionDuplicateForm = glRedemption.glRedemptionDuplicateForm.value

				return this.parameterSettingsService.duplicateGlRedemption(glRedemption.id, {
					code: glRedemptionDuplicateForm.code,
					name: glRedemptionDuplicateForm.name
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload = response.payload
					if (success) {
						return GlRedemptionAction.DuplicateSuccess({ payload })
					} else {
						return GlRedemptionAction.RestError(response as any)
					}
				}), catchError(this.catcher))
			})
		)
	)

	DuplicateSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(GlRedemptionAction.DuplicateSuccess),
			mergeMap(({ payload }) => {
				return [
					GlRedemptionAction.List(),
					CommonAction.RestError({ message: payload })
				]
			})
		)
	)


	catcher = (error) => {
		return (of(GlRedemptionAction.RestError({ message: error.message, manualThrow: error.manualThrow })))
	}
}
