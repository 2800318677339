<div>
  <mat-dialog-content>
    <app-spinner [isLoading]="isLoading"></app-spinner>

    <div class="layout-div">
      <div fxFlex>
        <form novalidate [ngrxFormState]="formState$ | async" fxLayout="column" fxFlex="100" fxFlex.lt-md="0">
          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="30px" fxLayoutGap.lt-md="0px">
            <span class="mat-h3">{{ 'CUSTOMER_MANAGEMENT.SELECT_WAIVER' | translate }}</span>
          </div>
          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="30px" fxLayoutGap.lt-md="0px">
            <mat-form-field fxFlex="90">
              <mat-label>{{ 'CUSTOMER_MANAGEMENT.WAIVER_TYPE' | translate }}</mat-label>
              <mat-select [ngrxFormControlState]="(formState$ | async).controls.waiverTypeId" required (selectionChange)="onWaiverTypeChange($event)">
                <mat-option>{{ 'COMMON.PLEASE_SELECT' | translate}}</mat-option>
                <mat-option *ngFor="let waiverType of waiverTypeList" [value]="waiverType.id">
                  {{waiverType.name}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="(formState$ | async).errors._waiverTypeId">
                <app-error-validation [errors]="(formState$ | async).errors._waiverTypeid">
                </app-error-validation>
              </mat-error>
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="30px" fxLayoutGap.lt-md="0px">
            <mat-form-field fxFlex="90">
              <mat-label>{{ 'CUSTOMER_MANAGEMENT.WAIVER' | translate }}</mat-label>
              <mat-select [ngrxFormControlState]="(formState$ | async).controls.waiverId" required>
                <mat-option>{{ 'COMMON.PLEASE_SELECT' | translate}}</mat-option>
                <mat-option *ngFor="let waiver of waiverList" [value]="waiver.id">
                  {{waiver.name}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="(formState$ | async).errors._waiverId">
                <app-error-validation [errors]="(formState$ | async).errors._waiverId">
                </app-error-validation>
              </mat-error>
            </mat-form-field>
          </div>
        </form>
      </div>
    </div>
  </mat-dialog-content>

  <div class="action-button-div" fxLayout="row"  fxLayoutGap="10px" fxLayoutAlign="end">
    <button class="mat-raised-button" (click)="cancel()">{{ 'ACTION.CANCEL' | translate }}</button>
    <button type="button" mat-raised-button [disabled]="(formState$ | async).isInvalid" (click)="create()"
      color="primary">{{ 'ACTION.CONFIRM' | translate }}
    </button>
  </div>
</div>