import { createAction, props } from '@ngrx/store'
import { GlRedemptionListResponse } from 'src/app/models/param-settings/gl-redemption/gl-redemption-list'
import { RewardPoolGroupViewResponse } from 'src/app/models/param-settings/reward-pool-group/reward-pool-group-view'
import { OnTheSpotRedemption } from 'src/app/models/redemption-settings/on-the-spot-redemption/on-the-spot-redemption'
import { ProductTypeAndGroup } from 'src/app/models/redemption-settings/on-the-spot-redemption/on-the-spot-redemption-product-list'
import { MerchantGroup, ProductTypeGroup } from 'src/app/models/redemption-settings/on-the-spot-redemption/on-the-spot-redemption-productGroup-list'
import { RewardPoolAndGroup } from 'src/app/models/redemption-settings/on-the-spot-redemption/on-the-spot-redemption-reward-list'
import { OnTheSpotRedemptionViewResponse } from 'src/app/models/redemption-settings/on-the-spot-redemption/on-the-spot-redemption-view'
import { POLICY } from 'src/app/models/common/constant'

export const InitialState = createAction('[OnTheSpotRedemption] InitialState')
export const RestError = createAction('[OnTheSpotRedemption] RestError', props<{ message: string, manualThrow: boolean }>())

export const GoList = createAction('[OnTheSpotRedemption] GoList')

export const SetId = createAction('[OnTheSpotRedemption] SetId', props<{ id: string }>())

export const GoView = createAction('[OnTheSpotRedemption] GoView')
export const GoUpdate = createAction('[OnTheSpotRedemption] GoUpdate')

export const GoBackDialog = createAction('[OnTheSpotRedemption] GoBackDialog', props<{ action: string }>())
export const closeDialog = createAction('[OnTheSpotRedemption] closeDialog')

export const List = createAction('[OnTheSpotRedemption] List')
export const ListSuccess = createAction('[OnTheSpotRedemption] ListSuccess', props<{ payload: OnTheSpotRedemption[] }>())

export const View = createAction('[OnTheSpotRedemption] View')
export const ViewSuccess = createAction('[OnTheSpotRedemption] ViewSuccess', props<{ payload: OnTheSpotRedemptionViewResponse }>())

export const UpdateDialog = createAction('[OnTheSpotRedemption] UpdateDialog')
export const Update = createAction('[OnTheSpotRedemption] Update')
export const UpdateSuccess = createAction('[OnTheSpotRedemption] UpdateSuccess', props<{ payload: string }>())

export const InitialLoadList = createAction('[OnTheSpotRedemption] InitialLoadList', props<{ payload: POLICY }>())

export const RewardPoolAndGroupList = createAction('[OnTheSpotRedemption] RewardPoolAndGroupList', props<{ payload: POLICY }>())
export const RewardPoolAndGroupListSuccess = createAction('[OnTheSpotRedemption] RewardPoolAndGroupListSuccess', props<{ payload: RewardPoolAndGroup[] }>())

export const ProductTypeAndGroupList = createAction('[OnTheSpotRedemption] ProductTypeAndGroupList', props<{ payload: POLICY }>())
export const ProductTypeAndGroupListSuccess = createAction('[OnTheSpotRedemption] ProductTypeAndGroupListSuccess', props<{ payload: ProductTypeAndGroup[] }>())

export const ProductTypeGroupList = createAction('[OnTheSpotRedemption] ProductTypeGroupList')
export const ProductTypeGroupListSuccess = createAction('[OnTheSpotRedemption] ProductTypeGroupListSuccess', props<{ payload: ProductTypeGroup[] }>())

export const MerchantGroupList = createAction('[OnTheSpotRedemption] MerchantGroupList')
export const MerchantGroupListSuccess = createAction('[OnTheSpotRedemption] MerchantGroupListSuccess', props<{ payload: MerchantGroup[] }>())

export const GlRedemptionList = createAction('[OnTheSpotRedemption] GlRedemptionList', props<{ payload: POLICY }>())
export const GlRedemptionListSuccess = createAction('[OnTheSpotRedemption] GlRedemptionListSuccess', props<{ payload: GlRedemptionListResponse }>())

export const GoCreate = createAction('[OnTheSpotRedemption] GoCreate')
export const CreateDialog = createAction('[OnTheSpotRedemption] CreateDialog')

export const Create = createAction('[OnTheSpotRedemption] Create')
export const CreateSuccess = createAction('[OnTheSpotRedemption] CreateSuccess', props<{ payload: string }>())

export const DeleteDialog = createAction('[OnTheSpotRedemption] DeleteDialog')
export const Delete = createAction('[OnTheSpotRedemption] Delete')
export const DeleteSuccess = createAction('[OnTheSpotRedemption] DeleteSuccess', props<{ payload: string }>())

export const OnChangeRewardPool = createAction('[OnTheSpotRedemption] OnChangeRewardPool')
export const OnChangeRewardPoolGroup = createAction('[OnTheSpotRedemption] OnChangeRewardPoolGroup')
export const OnChangeRewardPoolGroupSuccess = createAction('[OnTheSpotRedemption] OnChangeRewardPoolGroupSuccess', props<{ payload: RewardPoolGroupViewResponse }>())

export const DuplicateDialog = createAction('[OnTheSpotRedemption] DuplicateDialog')
export const Duplicate = createAction('[OnTheSpotRedemption] Duplicate')
export const DuplicateSuccess = createAction('[OnTheSpotRedemption] DuplicateSuccess', props<{ payload: string }>())