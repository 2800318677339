import { HttpClient } from '@angular/common/http'
import { Injectable, Inject, PLATFORM_ID } from '@angular/core'
import { Observable } from 'rxjs'
import { HttpMethod } from 'src/app/models/common/constant'
import { Request, Response } from 'src/app/models/common/http'
import { environment } from 'src/environments/environment'
import { HttpService } from './http.service'
import { isPlatformBrowser } from '@angular/common'
import { ConfigLoader } from 'src/app/models/util/config-loader'

const API_MOCK_ENDPOINT = '/assets/mock'
const API_ENDPOINT = ConfigLoader.loadConfig

@Injectable({
	providedIn: 'root'
})
export class RestService {
	isMock = false
	baseURL = ''
	isBrowser: boolean

	constructor(
		private httpClient: HttpClient,
		@Inject(PLATFORM_ID) private platformId
	) {
		this.isBrowser = isPlatformBrowser(this.platformId)
		this.isMock = environment.mock
		this.resetBaseUrl()
	}

	getAbsoluteDomainUrl(): string {
		if (this.isBrowser && window
			&& 'location' in window
			&& 'protocol' in window.location
			&& 'host' in window.location) {
			return window.location.protocol + '//' + window.location.host
		}
		return null
	}

	async resetBaseUrl() {
		if (this.isMock) {
			this.baseURL = this.getAbsoluteDomainUrl() + API_MOCK_ENDPOINT
		} else {
			const apiUrl = ConfigLoader.environment.apiUrl || (await API_ENDPOINT(environment)).apiUrl
			if (!ConfigLoader.environment.apiUrl) { ConfigLoader.environment.apiUrl = apiUrl }
			this.baseURL = apiUrl
		}
	}

	constructURL(request: Request): Request {
		let serviceUrl = (request.baseURL) ? request.baseURL : this.baseURL
		serviceUrl += this.isMock ? (request.mockURL || '') : (request.liveURL || '')
		this.resetBaseUrl()
		request.serviceUrl = serviceUrl
		return request
	}

	get(request: Request, optional = { globalException: true }): Observable<Response> {
		const httpService = new HttpService(this.httpClient)
		request = this.constructURL(request)
		if (this.isMock) { return this.getMockData(httpService, request, 'GET') }
		return httpService.get(request, optional)
	}

	post(request: Request, optional = { globalException: true }): Observable<Response> {
		const httpService = new HttpService(this.httpClient)
		request = this.constructURL(request)
		if (this.isMock) { return this.getMockData(httpService, request, 'POST') }
		return httpService.post(request, optional)
	}

	put(request: Request, optional = { globalException: true }): Observable<Response> {
		const httpService = new HttpService(this.httpClient)
		request = this.constructURL(request)
		if (this.isMock) { return this.getMockData(httpService, request, 'PUT') }
		return httpService.put(request, optional)
	}

	patch(request: Request, optional = { globalException: true }): Observable<Response> {
		const httpService = new HttpService(this.httpClient)
		request = this.constructURL(request)
		if (this.isMock) { return this.getMockData(httpService, request, 'PATCH') }
		return httpService.patch(request, optional)
	}

	delete(request: Request, optional = { globalException: true }): Observable<Response> {
		const httpService = new HttpService(this.httpClient)
		request = this.constructURL(request)
		if (this.isMock) { return this.getMockData(httpService, request, 'DELETE') }
		return httpService.delete(request, optional)
	}

	urlQueryString(obj: any) {
		if (typeof(obj) === typeof({})) {
			const o = Object.keys(obj).reduce((result, val) => {
				if (obj[val] !== undefined && obj[val] !== null && obj[val] !== '') {
					result[val] = encodeURIComponent(obj[val])
				}
				return result
			}, {})
			return `?${new URLSearchParams({ ...o })}`
		}
		return `?${new URLSearchParams({ ...obj })}`
	}

	private getMockData(httpService: HttpService, request?: Request, method?: HttpMethod) {
		request.method = method
		return httpService.get(request, { globalException: true })
	}
}
