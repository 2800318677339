import { createFormGroupState, FormGroupState, updateGroup, validate } from 'ngrx-forms'
import { maxLength, required } from 'ngrx-forms/validation'
import { AutoRedemption } from 'src/app/models/redemption-settings/auto-redemption/auto-redemption'
import { FieldValidator } from 'src/app/models/util/field.validator'
import { Option } from 'src/app/models/option/option'
import { RewardPoolAndGroup } from 'src/app/models/redemption-settings/auto-redemption/auto-redemption-reward-list'

export interface State {
	isLoading: boolean
	id?: number
	// listing
	autoRedemptionList: AutoRedemption[]
	// detail form
	autoRedemptionDetailForm: FormGroupState<AutoRedemptionDetailForm>
	// option list
	redemptionTypeList: Option[]
	billingCycleTypeList: Option[]
	cycleTypeList: Option[]
	cycleWeeklyList: Option[]
	cycleMonthlyList: Option[]
	cycleYearlyList: Option[]
	yearlyCycleTypeList: Option[]
	rewardPoolAndGroupList: RewardPoolAndGroup[]
	cappingLevelList: Option[]
	autoRedemptionView: AutoRedemptionDetailForm
	autoRedemptionDuplicateForm: FormGroupState<AutoRedemptionDuplicateForm>
	isDuplicateLoading: boolean
	isDuplicateDialog: boolean
}

export interface AutoRedemptionDetailForm {
	code: string
	name: string
	rewardPoolId: string
	redemptionType: string
	point: string
	value: string
	cycleType: string
	cycleDay: string
	cycleMonth: string
	creditTransactionCode: string
	creditTransactionDescription: string
	debitTransactionCode: string
	debitTransactionDescription: string
	cycleTypeOption: string
	numberOfDays: string
	createdBy: string
	createdDate: string
	modifiedBy: string
	modifiedDate: string
	capping: boolean
	cappingLevel: string
	cappingValue: string
}

export const initialAutoRedemptionDetailFormValue: AutoRedemptionDetailForm = {
	code: '',
	name: '',
	rewardPoolId: '',
	redemptionType: '',
	point: '',
	value: '',
	cycleType: '',
	cycleDay: null,
	cycleMonth: null,
	creditTransactionCode: '',
	creditTransactionDescription: '',
	debitTransactionCode: '',
	debitTransactionDescription: '',
	cycleTypeOption: null,
	numberOfDays: null,
	createdBy: '',
	createdDate: '',
	modifiedBy: '',
	modifiedDate: '',
	capping: false,
	cappingLevel: 'CUSTOMER',
	cappingValue: '',
}

export const initialAutoRedemptionDetailForm = createFormGroupState('autoRedemptionDetailForm', initialAutoRedemptionDetailFormValue)

export const validateAutoRedemptionDetailForm = (state: State) => updateGroup<AutoRedemptionDetailForm>({
	code: validate([required, maxLength(10), FieldValidator.alphaNumericWithoutSpaces()]),
	name: validate([required, maxLength(40), FieldValidator.checkValidCharacters()]),
	rewardPoolId: validate([required]),
	redemptionType: validate([required]),
	point: validate([FieldValidator.numberWithoutDecimal(), required, maxLength(10)]),
	value: validate([FieldValidator.integerAndDecimalCheck(10, 2), required, maxLength(13)]),
	cycleType: validate([required]),
	cycleDay: validate([FieldValidator.checkCycleDay(state.autoRedemptionDetailForm.controls.cycleType.value), FieldValidator.checkYearlyCycleType(state.autoRedemptionDetailForm.controls.cycleTypeOption.value)]),
	cycleMonth: validate([FieldValidator.checkYearlyCycleType(state.autoRedemptionDetailForm.controls.cycleTypeOption.value)]),
	creditTransactionCode: validate([required, maxLength(4), FieldValidator.numberWithoutDecimal()]),
	creditTransactionDescription: validate([required, maxLength(25), FieldValidator.checkValidCharacters()]),
	debitTransactionCode: validate([required, maxLength(4), FieldValidator.numberWithoutDecimal()]),
	debitTransactionDescription: validate([required, maxLength(25), FieldValidator.checkValidCharacters()]),
	cycleTypeOption: validate([FieldValidator.checkCycleTypeOption(state.autoRedemptionDetailForm.controls.cycleType.value)]),
	numberOfDays: validate([FieldValidator.digit(), FieldValidator.NotStartWithZero(), FieldValidator.checkBillingCycleType(state.autoRedemptionDetailForm.controls.cycleTypeOption.value)]),
	capping: validate([]),
	cappingLevel: validate([maxLength(20), FieldValidator.requiredIfTrue(state.autoRedemptionDetailForm.controls.capping.value)]),
	cappingValue: validate([maxLength(10), FieldValidator.numberWithoutDecimal(), FieldValidator.NotStartWithZero(), FieldValidator.requiredIfTrue(state.autoRedemptionDetailForm.controls.capping.value)])
})

export interface AutoRedemptionDuplicateForm {
	code: string
	name: string
	rewardPoolId: string
}

export const initialAutoRedemptionDuplicateFormValue: AutoRedemptionDuplicateForm = {
	code: '',
	name: '',
	rewardPoolId: ''
}

export const initialAutoRedemptionDuplicateForm = createFormGroupState('autoRedemptionDuplicateForm', initialAutoRedemptionDetailFormValue)

export const validateAutoRedemptionDuplicateForm = updateGroup<AutoRedemptionDuplicateForm>({
	code: validate([required, maxLength(10), FieldValidator.alphaNumericWithoutSpaces()]),
	name: validate([required, maxLength(40), FieldValidator.checkValidCharacters()]),
	rewardPoolId: validate([required])
})

export const initialState: State = {
	isLoading: false,
	autoRedemptionList: [],
	autoRedemptionDetailForm: initialAutoRedemptionDetailForm,
	rewardPoolAndGroupList: [],
	redemptionTypeList: [],
	billingCycleTypeList: [],
	cycleWeeklyList: [],
	cycleMonthlyList: [],
	cycleYearlyList: [],
	cycleTypeList: [],
	yearlyCycleTypeList: [],
	autoRedemptionView: initialAutoRedemptionDetailFormValue,
	cappingLevelList: [],
	autoRedemptionDuplicateForm: initialAutoRedemptionDuplicateForm,
	isDuplicateDialog: false,
	isDuplicateLoading: false
}
