import { createAction, props } from '@ngrx/store'
import { DashboardRedemptionRewardPoolViewResponse, DashboardRedemptionRewardPoolGroupViewResponse, DashboardRedemptionItemViewResponse, DashboardRedemptionCatalogueListViewResponse, CodeOptionListViewResponse, DashboardWaiverRedemptionItemOptionListResponse } from 'src/app/models/dashboard/dashboard-redemption'
import { DashboardOptionListViewResponse } from 'src/app/models/dashboard/dashboard-overview'

export const InitialState = createAction('[DashboardRedemption] InitialState')

export const Reset = createAction('[DashboardRedemption] Reset')

export const RestError = createAction('[DashboardRedemption] RestError', props<{ message: string, manualThrow: boolean }>())

export const InitialRedemptionItemOptionList = createAction('[DashboardRedemption] InitialRedemptionItemOptionList')
export const InitialRedemptionItemOptionListSuccess = createAction('[DashboardRedemption] InitialRedemptionItemOptionListSuccess', props<{ payload: CodeOptionListViewResponse[] }>())

export const ViewRewardPoolRedemption = createAction('[DashboardRedemption] ViewRewardPoolRedemption')
export const ViewRewardPoolRedemptionSuccess = createAction('[DashboardRedemption] ViewRewardPoolRedemptionSuccess', props<{ payload: DashboardRedemptionRewardPoolViewResponse }>())

export const ViewRewardPoolGroupRedemption = createAction('[DashboardRedemption] ViewRewardPoolGroupRedemption')
export const ViewRewardPoolGroupRedemptionSuccess = createAction('[DashboardRedemption] ViewRewardPoolGroupRedemptionSuccess', props<{ payload: DashboardRedemptionRewardPoolGroupViewResponse }>())

export const ViewRedemptionItemCashback = createAction('[DashboardRedemption] ViewRedemptionItemCashback', props<{ displayValue: boolean }>())
export const ViewRedemptionItemCashbackSuccess = createAction('[DashboardRedemption] ViewRedemptionItemCashbackSuccess', props<{ payload: DashboardRedemptionItemViewResponse }>())

export const ViewRedemptionItemPartner = createAction('[DashboardRedemption] ViewRedemptionItemPartner', props<{ displayValue: boolean }>())
export const ViewRedemptionItemPartnerSuccess = createAction('[DashboardRedemption] ViewRedemptionItemPartnerSuccess', props<{ payload: DashboardRedemptionItemViewResponse }>())

export const ViewRedemptionItemWaiver = createAction('[DashboardRedemption] ViewRedemptionItemWaiver', props<{ displayValue: boolean }>())
export const ViewRedemptionItemWaiverSuccess = createAction('[DashboardRedemption] ViewRedemptionItemWaiverSuccess', props<{ payload: DashboardRedemptionItemViewResponse }>())

export const ViewRedemptionItemOnTheSpot = createAction('[DashboardRedemption] ViewRedemptionItemOnTheSpot', props<{ displayValue: boolean }>())
export const ViewRedemptionItemOnTheSpotSuccess = createAction('[DashboardRedemption] ViewRedemptionItemOnTheSpotSuccess', props<{ payload: DashboardRedemptionItemViewResponse }>())

export const ViewCatalogueList = createAction('[DashboardRedemption] ViewCatalogueList')
export const ViewCatalogueListSuccess = createAction('[DashboardRedemption] ViewCatalogueListSuccess', props<{ payload: DashboardRedemptionCatalogueListViewResponse[] }>())

export const RewardPoolOptionList = createAction('[DashboardRedemption] RewardPoolOptionList')
export const RewardPoolOptionListSuccess = createAction('[DashboardRedemption] RewardPoolOptionListSuccess', props<{ payload: CodeOptionListViewResponse[] }>())

export const RewardPoolGroupOptionList = createAction('[DashboardRedemption] RewardPoolGroupOptionList')
export const RewardPoolGroupOptionListSuccess = createAction('[DashboardRedemption] RewardPoolGroupOptionListSuccess', props<{ payload: CodeOptionListViewResponse[] }>())

export const RedemptionItemCashbackOptionList = createAction('[DashboardRedemption] RedemptionItemCashbackOptionList')
export const RedemptionItemCashbackOptionListSuccess = createAction('[DashboardRedemption] RedemptionItemCashbackOptionListSuccess', props<{ payload: CodeOptionListViewResponse[] }>())

export const RedemptionItemPartnerOptionList = createAction('[DashboardRedemption] RedemptionItemPartnerOptionList')
export const RedemptionItemPartnerOptionListSuccess = createAction('[DashboardRedemption] RedemptionItemPartnerOptionListSuccess', props<{ payload: CodeOptionListViewResponse[] }>())

export const RedemptionItemWaiverOptionList = createAction('[DashboardRedemption] RedemptionItemWaiverOptionList')
export const RedemptionItemWaiverOptionListSuccess = createAction('[DashboardRedemption] RedemptionItemWaiverOptionListSuccess', props<{ payload: DashboardWaiverRedemptionItemOptionListResponse[] }>())

export const RedemptionItemOnTheSpotOptionList = createAction('[DashboardRedemption] RedemptionItemOnTheSpotOptionList')
export const RedemptionItemOnTheSpotOptionListSuccess = createAction('[DashboardRedemption] RedemptionItemOnTheSpotOptionListSuccess', props<{ payload: CodeOptionListViewResponse[] }>())


