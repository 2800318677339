export type HttpMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'
export type Action = 'LIST' | 'VIEW' | 'CREATE' | 'UPDATE' | 'DELETE' | 'PUBLISHED_UPDATE'
export type SpinnerType = 'APP' | 'COMPONENT'
export type InputType = 'DIGIT' | 'DECIMAL'
export type Functions =
	'USER_VIEW'
	| 'GROUP_VIEW'
	| 'FUNCTION_VIEW'
	| 'CAMPAIGN_CREATE'
	| 'CAMPAIGN_PUBLISHED_VIEW'
	| 'CAMPAIGN_ARCHIVED_VIEW'
	| 'CAMPAIGN_TERMINATE'
	| 'REWARD_POOL_CREATE'
	| 'REWARD_POOL_VIEW'
	| 'REWARD_POOL_UPDATE'
	| 'REWARD_POOL_DELETE'
	| 'GL_PROVISION_CREATE'
	| 'GL_PROVISION_VIEW'
	| 'GL_PROVISION_UPDATE'
	| 'GL_PROVISION_DELETE'
	| 'GL_REDEMPTION_CREATE'
	| 'GL_REDEMPTION_VIEW'
	| 'GL_REDEMPTION_UPDATE'
	| 'GL_REDEMPTION_DELETE'
	| 'TC_GROUP_CREATE'
	| 'TC_GROUP_VIEW'
	| 'TC_GROUP_UPDATE'
	| 'TC_GROUP_DELETE'
	| 'MCC_GROUP_CREATE'
	| 'MCC_GROUP_VIEW'
	| 'MCC_GROUP_UPDATE'
	| 'MCC_GROUP_DELETE'
	| 'MERCHANT_GROUP_CREATE'
	| 'MERCHANT_GROUP_VIEW'
	| 'MERCHANT_GROUP_UPDATE'
	| 'MERCHANT_GROUP_DELETE'
	| 'PRODUCT_TYPE_GROUP_CREATE'
	| 'PRODUCT_TYPE_GROUP_VIEW'
	| 'PRODUCT_TYPE_GROUP_UPDATE'
	| 'PRODUCT_TYPE_GROUP_DELETE'
	| 'BASIC_INFO_VIEW'
	| 'REWARD_POOL_GROUP_VIEW'
	| 'REWARD_POOL_GROUP_CREATE'
	| 'REWARD_POOL_GROUP_UPDATE'
	| 'REWARD_POOL_GROUP_DELETE'
	| 'FIELD_MAPPING_VIEW'
	| 'FIELD_MAPPING_UPDATE'
	| 'ADJUSTMENT_ACTION'
	| 'REDEEM_PARTNER_VIEW'
	| 'REDEEM_PARTNER_CREATE'
	| 'REDEEM_PARTNER_UPDATE'
	| 'REDEEM_PARTNER_DELETE'
	| 'AUTO_REDEMPTION_VIEW'
	| 'AUTO_REDEMPTION_CREATE'
	| 'AUTO_REDEMPTION_UPDATE'
	| 'AUTO_REDEMPTION_DELETE'
	| 'PRODUCT_CATALOGUE_VIEW'
	| 'PRODUCT_CATALOGUE_CREATE'
	| 'PRODUCT_CATALOGUE_UPDATE'
	| 'PRODUCT_CATALOGUE_DELETE'
	| 'FULFILMENT_PARTNER_VIEW'
	| 'FULFILMENT_PARTNER_CREATE'
	| 'FULFILMENT_PARTNER_UPDATE'
	| 'FULFILMENT_PARTNER_DELETE'
	| 'WAIVER_MANAGEMENT_VIEW'
	| 'WAIVER_MANAGEMENT_CREATE'
	| 'WAIVER_MANAGEMENT_UPDATE'
	| 'WAIVER_MANAGEMENT_DELETE'
	| 'OTS_PARAMETER_VIEW'
	| 'OTS_PARAMETER_CREATE'
	| 'OTS_PARAMETER_UPDATE'
	| 'OTS_PARAMETER_DELETE'
	| 'REDEEM_RULES_VIEW'
	| 'REDEEM_RULES_CREATE'
	| 'REDEEM_RULES_UPDATE'
	| 'REDEEM_RULES_DELETE'
	| 'CATALOGUE_REDEMPTION_ACTION'
	| 'WAIVER_ACTION'
	| 'CONDITIONAL_REWARD_CREATE'
	| 'CONDITIONAL_REWARD_PUBLISHED_VIEW'
	| 'CONDITIONAL_REWARD_ARCHIVED_VIEW'
	| 'CONDITIONAL_REWARD_TERMINATE'
	| 'CONDITIONAL_REWARD_UPDATE'
	| 'CAMPAIGN_APPROVE'
	| 'ADJUSTMENT_APPROVE'
	| 'CAMPAIGN_BUNDLING_APPROVE'
	| 'CONDITIONAL_REWARD_APPROVE'
	| 'DASHBOARD_REWARD_POOL_CAMPAIGN_OVERVIEW'
	| 'DASHBOARD_REWARD_POOL_GROUP_OVERVIEW'
	| 'DASHBOARD_PRODUCT_BUNDLING_OVERVIEW'
	| 'DASHBOARD_OVERVIEW'
	| 'TARGET_SEGMENT_CUSTOMER_VIEW'
	| 'TARGET_SEGMENT_CUSTOMER_CREATE'
	| 'TARGET_SEGMENT_CUSTOMER_UPDATE'
	| 'TARGET_SEGMENT_CUSTOMER_DELETE'
	| 'TARGET_SEGMENT_CARD_VIEW'
	| 'TARGET_SEGMENT_CARD_CREATE'
	| 'TARGET_SEGMENT_CARD_UPDATE'
	| 'TARGET_SEGMENT_CARD_DELETE'
	| 'TARGET_SEGMENT_ACCOUNT_VIEW'
	| 'TARGET_SEGMENT_ACCOUNT_CREATE'
	| 'TARGET_SEGMENT_ACCOUNT_UPDATE'
	| 'TARGET_SEGMENT_ACCOUNT_DELETE'
	| 'CAMPAIGN_BUNDLING_CREATE'
	| 'CAMPAIGN_BUNDLING_PUBLISHED_VIEW'
	| 'CAMPAIGN_BUNDLING_ARCHIVED_VIEW'
	| 'CAMPAIGN_BUNDLING_TERMINATE'
	| 'DASHBOARD_REDEMPTION'
	| 'SIMULATOR_CREATE'
	| 'SIMULATOR_VIEW'
	| 'SIMULATOR_UPDATE'
	| 'SIMULATOR_DELETE'
	| 'ACCOUNT_ADJUSTMENT_APPROVE'
	| 'ACCOUNT_ADJUSTMENT'
	| 'REDEMPTION_HISTORY_VIEW'
	| 'PARTNER_REDEMPTION_ACTION'
	| 'CAMPAIGN_UPDATE'
	| 'CAMPAIGN_BUNDLING_UPDATE'
	| 'REDEMPTION_CANCELLATION_APPROVE'
	| 'REDEMPTION_CANCELLATION'

export enum POLICY {
	USER_VIEW = 'USER_VIEW',
	GROUP_VIEW = 'GROUP_VIEW',
	FUNCTION_VIEW = 'FUNCTION_VIEW',
	CAMPAIGN_CREATE = 'CAMPAIGN_CREATE',
	CAMPAIGN_PUBLISHED_VIEW = 'CAMPAIGN_PUBLISHED_VIEW',
	CAMPAIGN_ARCHIVED_VIEW = 'CAMPAIGN_ARCHIVED_VIEW',
	CAMPAIGN_TERMINATE = 'CAMPAIGN_TERMINATE',
	REWARD_POOL_CREATE = 'REWARD_POOL_CREATE',
	REWARD_POOL_VIEW = 'REWARD_POOL_VIEW',
	REWARD_POOL_UPDATE = 'REWARD_POOL_UPDATE',
	REWARD_POOL_DELETE = 'REWARD_POOL_DELETE',
	GL_PROVISION_CREATE = 'GL_PROVISION_CREATE',
	GL_PROVISION_VIEW = 'GL_PROVISION_VIEW',
	GL_PROVISION_UPDATE = 'GL_PROVISION_UPDATE',
	GL_PROVISION_DELETE = 'GL_PROVISION_DELETE',
	GL_REDEMPTION_CREATE = 'GL_REDEMPTION_CREATE',
	GL_REDEMPTION_VIEW = 'GL_REDEMPTION_VIEW',
	GL_REDEMPTION_UPDATE = 'GL_REDEMPTION_UPDATE',
	GL_REDEMPTION_DELETE = 'GL_REDEMPTION_DELETE',
	TC_GROUP_CREATE = 'TC_GROUP_CREATE',
	TC_GROUP_VIEW = 'TC_GROUP_VIEW',
	TC_GROUP_UPDATE = 'TC_GROUP_UPDATE',
	TC_GROUP_DELETE = 'TC_GROUP_DELETE',
	MCC_GROUP_CREATE = 'MCC_GROUP_CREATE',
	MCC_GROUP_VIEW = 'MCC_GROUP_VIEW',
	MCC_GROUP_UPDATE = 'MCC_GROUP_UPDATE',
	MCC_GROUP_DELETE = 'MCC_GROUP_DELETE',
	MERCHANT_GROUP_CREATE = 'MERCHANT_GROUP_CREATE',
	MERCHANT_GROUP_VIEW = 'MERCHANT_GROUP_VIEW',
	MERCHANT_GROUP_UPDATE = 'MERCHANT_GROUP_UPDATE',
	MERCHANT_GROUP_DELETE = 'MERCHANT_GROUP_DELETE',
	PRODUCT_TYPE_GROUP_CREATE = 'PRODUCT_TYPE_GROUP_CREATE',
	PRODUCT_TYPE_GROUP_VIEW = 'PRODUCT_TYPE_GROUP_VIEW',
	PRODUCT_TYPE_GROUP_UPDATE = 'PRODUCT_TYPE_GROUP_UPDATE',
	PRODUCT_TYPE_GROUP_DELETE = 'PRODUCT_TYPE_GROUP_DELETE',
	BASIC_INFO_VIEW = 'BASIC_INFO_VIEW',
	REWARD_POOL_GROUP_VIEW = 'REWARD_POOL_GROUP_VIEW',
	REWARD_POOL_GROUP_CREATE = 'REWARD_POOL_GROUP_CREATE',
	REWARD_POOL_GROUP_UPDATE = 'REWARD_POOL_GROUP_UPDATE',
	REWARD_POOL_GROUP_DELETE = 'REWARD_POOL_GROUP_DELETE',
	FIELD_MAPPING_VIEW = 'FIELD_MAPPING_VIEW',
	FIELD_MAPPING_UPDATE = 'FIELD_MAPPING_UPDATE',
	ADJUSTMENT_ACTION = 'ADJUSTMENT_ACTION',
	REDEEM_PARTNER_VIEW = 'REDEEM_PARTNER_VIEW',
	REDEEM_PARTNER_CREATE = 'REDEEM_PARTNER_CREATE',
	REDEEM_PARTNER_UPDATE = 'REDEEM_PARTNER_UPDATE',
	REDEEM_PARTNER_DELETE = 'REDEEM_PARTNER_DELETE',
	AUTO_REDEMPTION_VIEW = 'AUTO_REDEMPTION_VIEW',
	AUTO_REDEMPTION_CREATE = 'AUTO_REDEMPTION_CREATE',
	AUTO_REDEMPTION_UPDATE = 'AUTO_REDEMPTION_UPDATE',
	AUTO_REDEMPTION_DELETE = 'AUTO_REDEMPTION_DELETE',
	PRODUCT_CATALOGUE_VIEW = 'PRODUCT_CATALOGUE_VIEW',
	PRODUCT_CATALOGUE_CREATE = 'PRODUCT_CATALOGUE_CREATE',
	PRODUCT_CATALOGUE_UPDATE = 'PRODUCT_CATALOGUE_UPDATE',
	PRODUCT_CATALOGUE_DELETE = 'PRODUCT_CATALOGUE_DELETE',
	FULFILMENT_PARTNER_VIEW = 'FULFILMENT_PARTNER_VIEW',
	FULFILMENT_PARTNER_CREATE = 'FULFILMENT_PARTNER_CREATE',
	FULFILMENT_PARTNER_UPDATE = 'FULFILMENT_PARTNER_UPDATE',
	FULFILMENT_PARTNER_DELETE = 'FULFILMENT_PARTNER_DELETE',
	WAIVER_MANAGEMENT_VIEW = 'WAIVER_MANAGEMENT_VIEW',
	WAIVER_MANAGEMENT_CREATE = 'WAIVER_MANAGEMENT_CREATE',
	WAIVER_MANAGEMENT_UPDATE = 'WAIVER_MANAGEMENT_UPDATE',
	WAIVER_MANAGEMENT_DELETE = 'WAIVER_MANAGEMENT_DELETE',
	OTS_PARAMETER_VIEW = 'OTS_PARAMETER_VIEW',
	OTS_PARAMETER_CREATE = 'OTS_PARAMETER_CREATE',
	OTS_PARAMETER_UPDATE = 'OTS_PARAMETER_UPDATE',
	OTS_PARAMETER_DELETE = 'OTS_PARAMETER_DELETE',
	REDEEM_RULES_VIEW = 'REDEEM_RULES_VIEW',
	REDEEM_RULES_CREATE = 'REDEEM_RULES_CREATE',
	REDEEM_RULES_UPDATE = 'REDEEM_RULES_UPDATE',
	REDEEM_RULES_DELETE = 'REDEEM_RULES_DELETE',
	CATALOGUE_REDEMPTION_ACTION = 'CATALOGUE_REDEMPTION_ACTION',
	WAIVER_ACTION = 'WAIVER_ACTION',
	CONDITIONAL_REWARD_CREATE = 'CONDITIONAL_REWARD_CREATE',
	CONDITIONAL_REWARD_PUBLISHED_VIEW = 'CONDITIONAL_REWARD_PUBLISHED_VIEW',
	CONDITIONAL_REWARD_ARCHIVED_VIEW = 'CONDITIONAL_REWARD_ARCHIVED_VIEW',
	CONDITIONAL_REWARD_TERMINATE = 'CONDITIONAL_REWARD_TERMINATE',
	CONDITIONAL_REWARD_UPDATE = 'CONDITIONAL_REWARD_UPDATE',
	CAMPAIGN_APPROVE = 'CAMPAIGN_APPROVE',
	ADJUSTMENT_APPROVE = 'ADJUSTMENT_APPROVE',
	CAMPAIGN_BUNDLING_APPROVE = 'CAMPAIGN_BUNDLING_APPROVE',
	CONDITIONAL_REWARD_APPROVE = 'CONDITIONAL_REWARD_APPROVE',
	DASHBOARD_REWARD_POOL_GROUP_OVERVIEW = 'DASHBOARD_REWARD_POOL_GROUP_OVERVIEW',
	DASHBOARD_REWARD_POOL_CAMPAIGN_OVERVIEW = 'DASHBOARD_REWARD_POOL_CAMPAIGN_OVERVIEW',
	DASHBOARD_PRODUCT_BUNDLING_OVERVIEW = 'DASHBOARD_PRODUCT_BUNDLING_OVERVIEW',
	DASHBOARD_OVERVIEW = 'DASHBOARD_OVERVIEW',
	TARGET_SEGMENT_VIEW = 'TARGET_SEGMENT_VIEW',
	TARGET_SEGMENT_CREATE = 'TARGET_SEGMENT_CREATE',
	TARGET_SEGMENT_UPDATE = 'TARGET_SEGMENT_UPDATE',
	TARGET_SEGMENT_DELETE = 'TARGET_SEGMENT_DELETE',
	TARGET_SEGMENT_CUSTOMER_VIEW = 'TARGET_SEGMENT_CUSTOMER_VIEW',
	TARGET_SEGMENT_CUSTOMER_CREATE = 'TARGET_SEGMENT_CUSTOMER_CREATE',
	TARGET_SEGMENT_CUSTOMER_UPDATE = 'TARGET_SEGMENT_CUSTOMER_UPDATE',
	TARGET_SEGMENT_CUSTOMER_DELETE = 'TARGET_SEGMENT_CUSTOMER_DELETE',
	TARGET_SEGMENT_CARD_VIEW = 'TARGET_SEGMENT_CARD_VIEW',
	TARGET_SEGMENT_CARD_CREATE = 'TARGET_SEGMENT_CARD_CREATE',
	TARGET_SEGMENT_CARD_UPDATE = 'TARGET_SEGMENT_CARD_UPDATE',
	TARGET_SEGMENT_CARD_DELETE = 'TARGET_SEGMENT_CARD_DELETE',
	TARGET_SEGMENT_ACCOUNT_VIEW = 'TARGET_SEGMENT_ACCOUNT_VIEW',
	TARGET_SEGMENT_ACCOUNT_CREATE = 'TARGET_SEGMENT_ACCOUNT_CREATE',
	TARGET_SEGMENT_ACCOUNT_UPDATE = 'TARGET_SEGMENT_ACCOUNT_UPDATE',
	TARGET_SEGMENT_ACCOUNT_DELETE = 'TARGET_SEGMENT_ACCOUNT_DELETE',
	CAMPAIGN_BUNDLING_CREATE = 'CAMPAIGN_BUNDLING_CREATE',
	CAMPAIGN_BUNDLING_PUBLISHED_VIEW = 'CAMPAIGN_BUNDLING_PUBLISHED_VIEW',
	CAMPAIGN_BUNDLING_ARCHIVED_VIEW = 'CAMPAIGN_BUNDLING_ARCHIVED_VIEW',
	CAMPAIGN_BUNDLING_TERMINATE = 'CAMPAIGN_BUNDLING_TERMINATE',
	DASHBOARD_REDEMPTION = 'DASHBOARD_REDEMPTION',
	SIMULATOR_CREATE = 'SIMULATOR_CREATE',
	SIMULATOR_VIEW = 'SIMULATOR_VIEW',
	SIMULATOR_UPDATE = 'SIMULATOR_UPDATE',
	SIMULATOR_DELETE = 'SIMULATOR_DELETE',
	ACCOUNT_ADJUSTMENT_APPROVE = 'ACCOUNT_ADJUSTMENT_APPROVE',
	ACCOUNT_ADJUSTMENT = 'ACCOUNT_ADJUSTMENT',
	REDEMPTION_HISTORY_VIEW = 'REDEMPTION_HISTORY_VIEW',
	PARTNER_REDEMPTION_ACTION = 'PARTNER_REDEMPTION_ACTION',
	CAMPAIGN_UPDATE = 'CAMPAIGN_UPDATE',
	CAMPAIGN_BUNDLING_UPDATE = 'CAMPAIGN_BUNDLING_UPDATE',
	REDEMPTION_CANCELLATION_APPROVE = 'REDEMPTION_CANCELLATION_APPROVE',
	REDEMPTION_CANCELLATION = 'REDEMPTION_CANCELLATION'
}

export const dateFormat = 'DD-MM-YYYY'
export const dateFormatWithTime = 'DD-MM-YYYY HH:mm:ss'
export const dateISOFormat = 'YYYY-MM-DDTHH:mm'
export const dateStatementFormat = 'DD MMM YYYY'

export enum CatalogueRedemptionType {
	POINT = 'P',
	POINT_AMOUNT = 'PA'
}

export enum User {
	WLLCORE = 'WLLCORE'
}

export enum RedeemRuleEntity {
	ACCOUNT_BLOCK_CODE = 'ACCOUNT_BLOCK_CODE',
	ACCOUNT_STATUS = 'ACCOUNT_STATUS',
	CARD_BLOCK_CODE = 'CARD_BLOCK_CODE',
	CARD_STATUS = 'CARD_STATUS',
	CUSTOMER_BLOCK_CODE = 'CUSTOMER_BLOCK_CODE',
	CUSTOMER_STATUS = 'CUSTOMER_STATUS',
}
