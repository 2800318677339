<app-spinner [isLoading]="isLoading"></app-spinner>

<div class="title-div" fxLayout="row" fxLayoutAlign="space-between center">
  <span *ngIf="ticketType === 'R'" class="title"> {{ 'SIMULATOR.CREATE_RAW_DATA_SIMULATOR' | translate }} </span>
  <span *ngIf="ticketType === 'C'" class="title"> {{ 'SIMULATOR.CREATE_CONFLICT_DATA_SIMULATOR' | translate }} </span>
</div>
<br>
<mat-dialog-content>
  <form novalidate [ngrxFormState]="formState$ | async" fxLayout="column" fxFlex>
    <mat-form-field>
      <mat-label>{{ 'SIMULATOR.TYPE' | translate }}</mat-label>
      <mat-select [ngrxFormControlState]="(formState$ | async).controls.simulatorTarget"
        (selectionChange)="onChangeSimulatorTarget()" required>
        <mat-option>{{ 'COMMON.PLEASE_SELECT' | translate}}</mat-option>
        <div *ngIf="ticketType === 'R'">
          <mat-option *ngFor="let i of simulatorTargetList" [value]="i.key">
            {{i.value}}
          </mat-option>
        </div>
        <div *ngIf="ticketType === 'C'">
          <mat-option *ngFor="let i of conflictDataSimulatorTargetList" [value]="i.key">
            {{i.value}}
          </mat-option>
        </div>
      </mat-select>
      <mat-error *ngIf="(formState$ | async).errors._simulatorTarget">
        <app-error-validation [errors]="(formState$ | async).errors._simulatorTarget">
        </app-error-validation>
      </mat-error>
    </mat-form-field>

    <div *ngIf="(formState$ | async).controls.simulatorTarget.value === 'C' ||
    (formState$ | async).controls.simulatorTarget.value === 'CR'" fxLayout="column">
      <mat-form-field>
        <mat-label>{{ 'SIMULATOR.CHANNEL' | translate }}</mat-label>
		<mat-select [ngrxFormControlState]="(formState$ | async).controls.resourceCode"
			(closed)="clearSearchResource()" (selectionChange)="onChangeResourceCode()" required>
			<ngx-mat-select-search [formControl]="dropDownCtrlChannelList" [placeholderLabel]="'COMMON.SEARCH'| translate"
				[noEntriesFoundLabel]="'COMMON.NOT_MATCHING_FOUND' | translate">
				<mat-icon ngxMatSelectSearchClear (click)="clearSearchResource()">close</mat-icon>
			</ngx-mat-select-search>
			<mat-option *ngFor="let resource of filteredChannelList | async" [value]="resource.key">
				{{resource.value}}
			</mat-option>
		</mat-select>
        <mat-error *ngIf="(formState$ | async).errors._resourceCode">
          <app-error-validation [errors]="(formState$ | async).errors._resourceCode">
          </app-error-validation>
        </mat-error>
      </mat-form-field>
    </div>


    <div *ngIf="(formState$ | async).controls.resourceCode.value &&
    (formState$ | async).controls.simulatorTarget.value === 'C'" fxLayout="column">
      <mat-form-field>
        <mat-label>{{ 'SIMULATOR.NAME' | translate }}</mat-label>
        <mat-select [ngrxFormControlState]="(formState$ | async).controls.key" required>
          <mat-option>{{ 'COMMON.PLEASE_SELECT' | translate}}</mat-option>
          <mat-option *ngFor="let i of campaignList" [value]="i.key">
            {{i.value}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="(formState$ | async).errors._key">
          <app-error-validation [errors]="(formState$ | async).errors._key">
          </app-error-validation>
        </mat-error>
      </mat-form-field>
    </div>

    <div *ngIf="(formState$ | async).controls.resourceCode.value &&
    (formState$ | async).controls.simulatorTarget.value === 'CR'" fxLayout="column">
      <mat-form-field>
        <mat-label>{{ 'SIMULATOR.NAME' | translate }}</mat-label>
        <mat-select [ngrxFormControlState]="(formState$ | async).controls.key" required>
          <mat-option>{{ 'COMMON.PLEASE_SELECT' | translate}}</mat-option>
          <mat-option *ngFor="let i of conditionalRewardList" [value]="i.key">
            {{i.value}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="(formState$ | async).errors._key">
          <app-error-validation [errors]="(formState$ | async).errors._key">
          </app-error-validation>
        </mat-error>
      </mat-form-field>
    </div>

    <div *ngIf="(formState$ | async).controls.simulatorTarget.value === 'PB' && ticketType === 'R'" fxLayout="column">
      <mat-form-field>
        <mat-label>{{ 'SIMULATOR.NAME' | translate }}</mat-label>
        <mat-select [ngrxFormControlState]="(formState$ | async).controls.key" required>
          <mat-option>{{ 'COMMON.PLEASE_SELECT' | translate}}</mat-option>
          <mat-option *ngFor="let i of productBundlingList" [value]="i.key">
            {{i.value}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="(formState$ | async).errors._key">
          <app-error-validation [errors]="(formState$ | async).errors._key">
          </app-error-validation>
        </mat-error>
      </mat-form-field>
    </div>

    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="5px" fxLayoutGap.lt-md="0px">
      <mat-form-field>
        <mat-label>{{ 'COMMON.START_DATE' | translate }}</mat-label>
        <input matInput [max]="(formState$ | async).controls.endDate.value || endDate" [matDatepicker]="picker"
          [ngrxFormControlState]="(formState$ | async).controls.startDate" [ngrxValueConverter]="dateValueConverter"
          (click)="picker.open()" readonly required>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-error *ngIf="(formState$ | async).errors._startDate">
          <app-error-validation [errors]="(formState$ | async).errors._startDate" [month]="3">
          </app-error-validation>
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ 'COMMON.END_DATE' | translate }}</mat-label>
        <input matInput [min]="(formState$ | async).controls.startDate.value" [max]="endDate" [matDatepicker]="endpicker"
          [ngrxFormControlState]="(formState$ | async).controls.endDate" [ngrxValueConverter]="dateValueConverter"
          (click)="endpicker.open()" readonly required>
        <mat-datepicker-toggle matSuffix [for]="endpicker"></mat-datepicker-toggle>
        <mat-datepicker #endpicker></mat-datepicker>
        <mat-error *ngIf="(formState$ | async).errors._endDate">
          <app-error-validation [errors]="(formState$ | async).errors._endDate">
          </app-error-validation>
        </mat-error>
      </mat-form-field>
    </div>

  </form>
</mat-dialog-content>

<br>
<div class="action-button-div" fxLayout="row" fxLayoutAlign="end">
  <button class="mat-raised-button next-button" (click)="cancel()"
    fxFlexOffset="8px">{{ 'ACTION.CANCEL' | translate }}</button>
  <button [disabled]="!(formState$ | async).isTouched || (formState$ | async).isInvalid"
    class="mat-raised-button mat-primary next-button" (click)="submit()"
    fxFlexOffset="8px">{{ 'ACTION.SUBMIT' | translate }}</button>
</div>
