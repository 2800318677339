import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { Request, Response } from 'src/app/models/common/http'
import { GlProvisionCreateRequest } from 'src/app/models/param-settings/gl-provision/gl-provision-create'
import { GlProvisionDeleteRequest } from 'src/app/models/param-settings/gl-provision/gl-provision-delete'
import { GlProvisionUpdateRequest } from 'src/app/models/param-settings/gl-provision/gl-provision-update'
import { GlProvisionViewRequest } from 'src/app/models/param-settings/gl-provision/gl-provision-view'
import { MccGroupCreateRequest } from 'src/app/models/param-settings/mcc-group/mcc-group-create'
import { MccGroupDeleteRequest } from 'src/app/models/param-settings/mcc-group/mcc-group-delete'
import { MccGroupUpdateRequest } from 'src/app/models/param-settings/mcc-group/mcc-group-update'
import { MccGroupViewRequest } from 'src/app/models/param-settings/mcc-group/mcc-group-view'
import { MerchantGroupCreateRequest } from 'src/app/models/param-settings/mer-group/merchant-group-create'
import { MerchantGroupDeleteRequest } from 'src/app/models/param-settings/mer-group/merchant-group-delete'
import { MerchantGroupUpdateRequest } from 'src/app/models/param-settings/mer-group/merchant-group-update'
import { MerchantGroupDuplicateRequest } from 'src/app/models/param-settings/mer-group/merchant-group-duplicate'
import { MerchantGroupViewRequest } from 'src/app/models/param-settings/mer-group/merchant-group-view'
import { RewardPoolCreateRequest } from 'src/app/models/param-settings/reward-pool/reward-pool-create'
import { RewardPoolDeleteRequest } from 'src/app/models/param-settings/reward-pool/reward-pool-delete'
import { RewardPoolUpdateRequest } from 'src/app/models/param-settings/reward-pool/reward-pool-update'
import { RewardPoolViewRequest } from 'src/app/models/param-settings/reward-pool/reward-pool-view'
import { TcGroupCreateRequest } from 'src/app/models/param-settings/tc-group/tc-group-create'
import { TcGroupDeleteRequest } from 'src/app/models/param-settings/tc-group/tc-group-delete'
import { TcGroupUpdateRequest } from 'src/app/models/param-settings/tc-group/tc-group-update'
import { TcGroupViewRequest } from 'src/app/models/param-settings/tc-group/tc-group-view'
import { RestService } from '../common/rest-client/rest.service'
import { ProductTypeGroupCreateRequest } from 'src/app/models/param-settings/product-type-group/product-type-group-create'
import { ProductTypeGroupViewRequest } from 'src/app/models/param-settings/product-type-group/product-type-group-view'
import { ProductTypeGroupUpdateRequest } from 'src/app/models/param-settings/product-type-group/product-type-group-update'
import { ProductTypeGroupDeleteRequest } from 'src/app/models/param-settings/product-type-group/product-type-group-delete'
import { GlRedemptionCreateRequest } from 'src/app/models/param-settings/gl-redemption/gl-redemption-create'
import { GlRedemptionViewRequest } from 'src/app/models/param-settings/gl-redemption/gl-redemption-view'
import { GlRedemptionUpdateRequest } from 'src/app/models/param-settings/gl-redemption/gl-redemption-update'
import { GlRedemptionDeleteRequest } from 'src/app/models/param-settings/gl-redemption/gl-redemption-delete'
import { RewardPoolGroupCreateRequest } from 'src/app/models/param-settings/reward-pool-group/reward-pool-group-create'
import { RewardPoolGroupDeleteRequest } from 'src/app/models/param-settings/reward-pool-group/reward-pool-group-delete'
import { RewardPoolGroupViewRequest } from 'src/app/models/param-settings/reward-pool-group/reward-pool-group-view'
import { RewardPoolGroupUpdateRequest, RewardPoolGroupUpdateRedemptionSequenceRequest } from 'src/app/models/param-settings/reward-pool-group/reward-pool-group-update'
import { RewardPoolGlMapPostBody, RewardPoolGlPolicy, RewardPoolGroupAssignmentRequest, RewardPoolGroupRequestGlMap } from 'src/app/models/param-settings/reward-pool-group/reward-pool-group'
import { FieldMappingViewRequest } from 'src/app/models/param-settings/field-mapping/field-mapping-view'
import { FieldMappingUpdateValueRequest, FieldMappingUpdateMaskingRequest } from 'src/app/models/param-settings/field-mapping/field-mapping-update'
import { ResourceListParams } from 'src/app/models/common/common'
import { ProductTypeOptionRequest } from 'src/app/models/param-settings/product-type-group/product-type-group-option'
import { RewardPoolGLProvisionOption } from 'src/app/models/param-settings/reward-pool/reward-pool'
import { MccGroupDuplicateRequest } from '../../models/param-settings/mcc-group/mcc-group-duplicate'
import { RewardPoolDuplicateRequest } from 'src/app/models/param-settings/reward-pool/reward-pool-duplicate'
import {TcGroupDuplicateRequest} from '../../models/param-settings/tc-group/tc-group-duplicate'
import { GlProvisionDuplicateRequest } from 'src/app/models/param-settings/gl-provision/gl-provision-duplicate'
import { GlRedemptionDuplicateRequest } from 'src/app/models/param-settings/gl-redemption/gl-redemption-duplicate'
import { ProductTypeGroupDuplicateRequest } from 'src/app/models/param-settings/product-type-group/product-type-group-duplicate'

@Injectable({
	providedIn: 'root'
})
export class ParameterSettingsService {

	constructor(
		private rest: RestService
	) { }

	// Reward Pool
	getRewardPoolList(): Observable<Response> {
		const request: Request = {
			liveURL: '/rewardPool/list',
			mockURL: '/parameter-settings/reward-pool/getRewardPoolList.json'
		}

		return this.rest.get(request, { globalException: false })
	}

	getAvailableRewardPoolList(): Observable<Response> {
		const request: Request = {
			liveURL: '/rewardPool/availableList',
			mockURL: '/parameter-settings/reward-pool/getRewardPoolList.json'
		}

		return this.rest.get(request, { globalException: false })
	}

	getRewardPoolCycleListOptions(): Observable<Response> {
		const request: Request = {
			liveURL: '/rewardPool/cycleOption/list'
		}

		return this.rest.get(request)
	}

	getRewardPoolTypeListOptions(): Observable<Response> {
		const request: Request = {
			liveURL: '/rewardPool/rewardPoolType/list'
		}

		return this.rest.get(request)
	}

	createRewardPool(params: RewardPoolCreateRequest): Observable<Response> {
		const request: Request = {
			body: params,
			liveURL: '/rewardPool/create',
			mockURL: '/parameter-settings/reward-pool/createRewardPool.json'
		}

		return this.rest.post(request)
	}

	viewRewardPool(params: RewardPoolViewRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/rewardPool/view/' + params.id,
			mockURL: '/parameter-settings/reward-pool/viewRewardPool.json'
		}

		return this.rest.get(request)
	}

	updateRewardPool(params: RewardPoolUpdateRequest): Observable<Response> {
		const request: Request = {
			body: params,
			liveURL: '/rewardPool/edit/' + params.id,
			mockURL: '/parameter-settings/reward-pool/updateRewardPool.json'
		}

		return this.rest.post(request)
	}

	getGLProvisionListFromRewardPoolApi(params: RewardPoolGLProvisionOption): Observable<Response> {
		const request: Request = {
			liveURL: '/rewardPool/glProvision/list' + `?${new URLSearchParams({ ...params })}`
		}

		return this.rest.get(request)
	}

	getGlProvisionByRewardPoolId(params: RewardPoolViewRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/rewardPool/' + params.id + '/glprovision',
			mockURL: '/parameter-settings/reward-pool/viewRewardPool.json'
		}

		return this.rest.get(request)
	}

	deleteRewardPool(params: RewardPoolDeleteRequest): Observable<Response> {
		const request: Request = {
			body: params,
			liveURL: '/rewardPool/delete/' + params.id,
			mockURL: '/parameter-settings/reward-pool/deleteRewardPool.json'
		}

		return this.rest.post(request)
	}

	duplicateRewardPool(id: number, params: RewardPoolDuplicateRequest): Observable<Response> {
		const request: Request = {
			body: params,
			liveURL: '/rewardPool/duplicate/' + id,
			mockURL: '/parameter-settings/reward-pool/duplicateRewardPool.json'
		}
		return this.rest.post(request)
	}

	// Reward Pool Group
	getRewardPoolGroupList(): Observable<Response> {
		const request: Request = {
			liveURL: '/rewardPoolGroup/list',
		}

		return this.rest.get(request, { globalException: false })
	}

	createRewardPoolGroup(params: RewardPoolGroupCreateRequest): Observable<Response> {
		const request: Request = {
			body: params,
			liveURL: '/rewardPoolGroup/create',
		}

		return this.rest.post(request)
	}

	viewRewardPoolGroup(params: RewardPoolGroupViewRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/rewardPoolGroup/view/' + params.id,
		}

		return this.rest.get(request)
	}

	assignRewardPoolToRewardPoolGroup(params: RewardPoolGroupAssignmentRequest): Observable<Response> {
		const request: Request = {
			body: { rewardPoolId: params.rewardPoolId },
			liveURL: '/rewardPoolGroup/assignRewardPool/' + params.rewardPoolGroupId,
		}

		return this.rest.post(request)
	}

	removeRewardPoolFromRewardPoolGroup(params: RewardPoolGroupAssignmentRequest): Observable<Response> {
		const request: Request = {
			body: { rewardPoolId: params.rewardPoolId },
			liveURL: '/rewardPoolGroup/removeRewardPool/' + params.rewardPoolGroupId,
		}

		return this.rest.post(request)
	}

	updateRewardPoolGroup(params: RewardPoolGroupUpdateRequest): Observable<Response> {
		const request: Request = {
			body: params,
			liveURL: '/rewardPoolGroup/edit/' + params.id,
		}

		return this.rest.post(request)
	}

	updateRewardPoolGroupRedemptionSequence(params: RewardPoolGroupUpdateRedemptionSequenceRequest): Observable<Response> {
		const request: Request = {
			body: params,
			liveURL: '/rewardPoolGroup/edit/redemptionSequence/' + params.id,
		}

		return this.rest.post(request)
	}

	deleteRewardPoolGroup(params: RewardPoolGroupDeleteRequest): Observable<Response> {
		const request: Request = {
			body: params,
			liveURL: '/rewardPoolGroup/delete/' + params.id,
		}

		return this.rest.post(request)
	}

	revertRewardPoolFromRewardPoolGroup(params: RewardPoolGroupAssignmentRequest): Observable<Response> {
		const request: Request = {
			body: { rewardPoolId: params.rewardPoolId },
			liveURL: '/rewardPoolGroup/revertRewardPool/' + params.rewardPoolGroupId,
		}

		return this.rest.post(request)
	}

	getGlMapByRewardPoolGroupId(params: RewardPoolGroupRequestGlMap): Observable<Response> {
		const request: Request = {
			liveURL: '/rewardPoolGroup/glRedemption/' + params.rewardPoolGroupId + `?${new URLSearchParams({ rewardPoolId: params.rewardPoolId.toString() })}`
		}

		return this.rest.get(request)
	}

	getRewardPoolGlRedemptionList(params: RewardPoolGlPolicy): Observable<Response> {
		const request: Request = {
			liveURL: '/rewardPoolGroup/glRedemption/list' + `?${new URLSearchParams({ ...params })}`
		}

		return this.rest.get(request)
	}

	assignGlMapByRewardPoolId(params: RewardPoolGlMapPostBody): Observable<Response> {
		const request: Request = {
			body: params,
			liveURL: '/rewardPoolGroup/glRedemption'
		}

		return this.rest.post(request)
	}

	// GL Provision
	getGlProvisionList(): Observable<Response> {
		const request: Request = {
			liveURL: '/glProvision/list'
		}

		return this.rest.get(request)
	}

	createGlProvision(params: GlProvisionCreateRequest): Observable<Response> {
		const request: Request = {
			body: params,
			liveURL: '/glProvision/create'
		}

		return this.rest.post(request)
	}

	viewGlProvision(params: GlProvisionViewRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/glProvision/view/' + params.id
		}

		return this.rest.get(request)
	}

	updateGlProvision(params: GlProvisionUpdateRequest): Observable<Response> {
		const request: Request = {
			body: params,
			liveURL: '/glProvision/edit/' + params.id
		}

		return this.rest.post(request)
	}

	deleteGlProvision(params: GlProvisionDeleteRequest): Observable<Response> {
		const request: Request = {
			body: params,
			liveURL: '/glProvision/delete/' + params.id
		}

		return this.rest.post(request)
	}

	duplicateGlProvision(id: number, params: GlProvisionDuplicateRequest): Observable<Response> {
		const request: Request = {
			body: params,
			liveURL: '/glProvision/duplicate/' + id
		}

		return this.rest.post(request)
	}

	getGlProvisionData(): Observable<Response> {
		const request: Request = {
			liveURL: '/glProvision/data'
		}

		return this.rest.get(request)
	}

	// GL Redemption
	getGlRedemptionList(): Observable<Response> {
		const request: Request = {
			liveURL: '/glRedemption/list'
		}

		return this.rest.get(request)
	}

	createGlRedemption(params: GlRedemptionCreateRequest): Observable<Response> {
		const request: Request = {
			body: params,
			liveURL: '/glRedemption/create'
		}

		return this.rest.post(request)
	}

	viewGlRedemption(params: GlRedemptionViewRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/glRedemption/view/' + params.id
		}

		return this.rest.get(request)
	}

	updateGlRedemption(params: GlRedemptionUpdateRequest): Observable<Response> {
		const request: Request = {
			body: params,
			liveURL: '/glRedemption/edit/' + params.id
		}

		return this.rest.post(request)
	}

	deleteGlRedemption(params: GlRedemptionDeleteRequest): Observable<Response> {
		const request: Request = {
			body: params,
			liveURL: '/glRedemption/delete/' + params.id
		}

		return this.rest.post(request)
	}

	duplicateGlRedemption(id: number, params: GlRedemptionDuplicateRequest): Observable<Response> {
		const request: Request = {
			body: params,
			liveURL: '/glRedemption/duplicate/' + id
		}

		return this.rest.post(request)
	}

	getGlRedemptionData(): Observable<Response> {
		const request: Request = {
			liveURL: '/glRedemption/data'
		}

		return this.rest.get(request)
	}

	// TC Group
	getTcGroupList(): Observable<Response> {
		const request: Request = {
			liveURL: '/tcGroup/list',
			mockURL: '/parameter-settings/tc-group/getTcGroupList.json'
		}

		return this.rest.get(request)
	}

	createTcGroup(params: TcGroupCreateRequest): Observable<Response> {
		const request: Request = {
			body: params,
			liveURL: '/tcGroup/create',
			mockURL: '/parameter-settings/tc-group/createTcGroup.json'
		}

		return this.rest.post(request)
	}

	viewTcGroup(params: TcGroupViewRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/tcGroup/view/' + params.code,
			mockURL: '/parameter-settings/tc-group/viewTcGroup.json'
		}

		return this.rest.get(request)
	}

	updateTcGroup(params: TcGroupUpdateRequest): Observable<Response> {
		const request: Request = {
			body: params,
			liveURL: '/tcGroup/edit/' + params.code,
			mockURL: '/parameter-settings/tc-group/updateTcGroup.json'
		}

		return this.rest.post(request)
	}

	duplicateTcGroup(code: string, params: TcGroupDuplicateRequest): Observable<Response> {
		const request: Request = {
			body: params,
			liveURL: '/tcGroup/duplicate/' + code,
			mockURL: '/parameter-settings/tc-group/duplicateTcGroup.json'
		}
		return this.rest.post(request)
	}

	deleteTcGroup(params: TcGroupDeleteRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/tcGroup/delete/' + params.code,
			mockURL: '/parameter-settings/tc-group/deleteTcGroup.json'
		}

		return this.rest.post(request)
	}

	// Mcc Group
	getMccGroupList(): Observable<Response> {
		const request: Request = {
			liveURL: '/mccGroup/list',
			mockURL: '/parameter-settings/mcc-group/getMccGroupList.json'
		}

		return this.rest.get(request)
	}

	createMccGroup(params: MccGroupCreateRequest): Observable<Response> {
		const request: Request = {
			body: params,
			liveURL: '/mccGroup/create',
			mockURL: '/parameter-settings/mcc-group/createMccGroup.json'
		}

		return this.rest.post(request)
	}

	viewMccGroup(params: MccGroupViewRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/mccGroup/view/' + params.code,
			mockURL: '/parameter-settings/mcc-group/viewMccGroup.json'
		}

		return this.rest.get(request)
	}

	updateMccGroup(params: MccGroupUpdateRequest): Observable<Response> {
		const request: Request = {
			body: params,
			liveURL: '/mccGroup/edit/' + params.code,
			mockURL: '/parameter-settings/mcc-group/updateMccGroup.json'
		}

		return this.rest.post(request)
	}

	duplicateMccGroup(code: string, params: MccGroupDuplicateRequest): Observable<Response> {
		const request: Request = {
			body: params,
			liveURL: '/mccGroup/duplicate/' + code,
			mockURL: '/parameter-settings/mcc-group/duplicateMccGroup.json'
		}

		return this.rest.post(request)
	}

	deleteMccGroup(params: MccGroupDeleteRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/mccGroup/delete/' + params.code,
			mockURL: '/parameter-settings/mcc-group/deleteMccGroup.json'
		}

		return this.rest.post(request)
	}

	// Merchant Group
	getMerchantGroupList(): Observable<Response> {
		const request: Request = {
			liveURL: '/merchantGroup/list',
			mockURL: '/parameter-settings/merchant-group/getMerchantGroupList.json'
		}

		return this.rest.get(request)
	}

	createMerchantGroup(params: MerchantGroupCreateRequest): Observable<Response> {
		const request: Request = {
			body: params,
			liveURL: '/merchantGroup/create',
			mockURL: '/parameter-settings/merchant-group/createMerchantGroup.json'
		}

		return this.rest.post(request)
	}

	viewMerchantGroup(params: MerchantGroupViewRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/merchantGroup/view/' + params.code,
			mockURL: '/parameter-settings/merchant-group/viewMerchantGroup.json'
		}

		return this.rest.get(request)
	}

	updateMerchantGroup(params: MerchantGroupUpdateRequest): Observable<Response> {
		const request: Request = {
			body: params,
			liveURL: '/merchantGroup/edit/' + params.code,
			mockURL: '/parameter-settings/merchant-group/updateMerchantGroup.json'
		}

		return this.rest.post(request)
	}

	deleteMerchantGroup(params: MerchantGroupDeleteRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/merchantGroup/delete/' + params.code,
			mockURL: '/parameter-settings/merchant-group/deleteMerchantGroup.json'
		}

		return this.rest.post(request)
	}

	duplicateMerchantGroup(code: string, params: MerchantGroupDuplicateRequest): Observable<Response> {
		const request: Request = {
			body: params,
			liveURL: '/merchantGroup/duplicate/' + code,
			mockURL: '/parameter-settings/merchant-group/duplicateMerchantGroup.json'
		}

		return this.rest.post(request)
	}

	// ProductType Group
	getProductTypeGroupList(): Observable<Response> {
		const request: Request = {
			liveURL: '/productTypeGroup/list'
		}

		return this.rest.get(request)
	}

	getProductTypeOptionsList(params: ProductTypeOptionRequest): Observable<Response> {
		const resourceCode = params.resourceCode
		const request: Request = {
			liveURL: '/productTypeGroup/productType/optionList?' + new URLSearchParams({ resourceCode })
		}

		return this.rest.get(request)
	}

	createProductTypeGroup(params: ProductTypeGroupCreateRequest): Observable<Response> {
		const request: Request = {
			body: params,
			liveURL: '/productTypeGroup/create'
		}

		return this.rest.post(request)
	}

	viewProductTypeGroup(params: ProductTypeGroupViewRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/productTypeGroup/view/' + params.code
		}

		return this.rest.get(request)
	}

	updateProductTypeGroup(params: ProductTypeGroupUpdateRequest): Observable<Response> {
		const request: Request = {
			body: params,
			liveURL: '/productTypeGroup/edit/' + params.code
		}

		return this.rest.post(request)
	}

	deleteProductTypeGroup(params: ProductTypeGroupDeleteRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/productTypeGroup/delete/' + params.code
		}

		return this.rest.post(request)
	}

	duplicateProductTypeGroup(code: string, params: ProductTypeGroupDuplicateRequest): Observable<Response> {
		const request: Request = {
			body: params,
			liveURL: '/productTypeGroup/duplicate/' + code
		}

		return this.rest.post(request)
	}

	// Field Mapping
	getFieldMappingList(): Observable<Response> {
		const request: Request = {
			liveURL: '/fieldMapping/list'
		}

		return this.rest.get(request)
	}

	viewFieldMapping(params: FieldMappingViewRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/fieldMapping/view/' + params.id
		}

		return this.rest.get(request)
	}

	updateFieldMappingValue(params: FieldMappingUpdateValueRequest): Observable<Response> {
		const request: Request = {
			body: params,
			liveURL: '/fieldMapping/editValue/' + params.id
		}

		return this.rest.post(request)
	}

	updateFieldMappingMasking(params: FieldMappingUpdateMaskingRequest): Observable<Response> {
		const request: Request = {
			body: params,
			liveURL: '/fieldMapping/editMasking/' + params.id
		}

		return this.rest.post(request)
	}

	// Resource List
	getResourceList(params: ResourceListParams): Observable<Response> {
		const request: Request = {
			liveURL: `/${params.module}/resourceList/${params.action}`
		}

		return this.rest.get(request)
	}
}
