import { createFormGroupState, FormGroupState, updateGroup, validate } from 'ngrx-forms'
import { maxLength, required } from 'ngrx-forms/validation'
import { FieldValidator } from 'src/app/models/util/field.validator'
import { TargetSegmentOverview, TargetSegmentDetails } from 'src/app/models/target-segment/target-segment'
import { KeyValue } from '@angular/common'

export interface State {
	isLoading: boolean
	id?: number
	segment?: string
	targetSegmentDetails: TargetSegmentDetails
	targetSegmentDetailForm: FormGroupState<TargetSegmentDetailForm>
	targetSegmentListResponse: {
		card: TargetSegmentOverview[]
		account: TargetSegmentOverview[]
		customerId: TargetSegmentOverview[]
	}
	resourceList: KeyValue<string, string>[]
	targetSegmentDetailFormView: TargetSegmentDetailForm
	targetSegmentListView: string[]
}

export interface TargetSegmentDetailForm {
	code: string
	name: string
	resourceCode: string
	description: string
}

export const initialTargetSegmentDetailFormValue: TargetSegmentDetailForm = {
	code: '',
	name: '',
	resourceCode: '',
	description: '',
}

export const initialTargetSegmentDetailForm = createFormGroupState('targetSegmentDetailForm', initialTargetSegmentDetailFormValue)

export const validateTargetSegmentDetailForm = (state: State) => updateGroup<any>({
	code: validate([required, maxLength(10), FieldValidator.alphaNumericWithoutSpaces()]),
	name: validate([required, maxLength(40), FieldValidator.checkValidCharacters()]),
	resourceCode: validate([required]),
	description: validate([maxLength(100), FieldValidator.checkValidCharacters()]),
})

export const initialTargetSegmentDetail = {
	id: 0,
	code: '',
	name: '',
	resourceName: '',
	resourceCode: '',
	document: [],
	description: '',
	createdBy: '',
	createdDate: '',
	modifiedBy: '',
	modifiedDate: '',
	allowDelete: false
}

export const initialState: State = {
	isLoading: false,
	targetSegmentDetails: initialTargetSegmentDetail,
	targetSegmentDetailForm: initialTargetSegmentDetailForm,
	targetSegmentListResponse: {
		card: [],
		account: [],
		customerId: []
	},
	resourceList: [],
	targetSegmentDetailFormView: initialTargetSegmentDetailFormValue,
	targetSegmentListView: []
}
