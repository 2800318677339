interface BrowserStorage {
	storageType: Storage
	storageKey: StorageKey
	getStorageData: any
	setStorageData: any
	replaceStorageData: any
	clearAllStorageData: any
}

type StorageKey = 'KEY_STORAGE_USER' | 'KEY_STORAGE_DATA'


export const BrowserStorage: BrowserStorage = {
	storageType: localStorage,
	storageKey: 'KEY_STORAGE_USER',
	getStorageData: (defaultParentKey = BrowserStorage.storageKey): any =>
		JSON.parse(BrowserStorage.storageType.getItem(defaultParentKey)),
	setStorageData: (key, value, defaultParentKey = BrowserStorage.storageKey): void => {
		const storage = JSON.parse(BrowserStorage.storageType.getItem(defaultParentKey))
		storage[key] = value
		BrowserStorage.storageType.setItem(defaultParentKey, JSON.stringify(storage))
	},
	replaceStorageData: (data: {}, defaultParentKey = BrowserStorage.storageKey): void => {
		const storage = JSON.parse(BrowserStorage.storageType.getItem(defaultParentKey))
		BrowserStorage.storageType.setItem(defaultParentKey, JSON.stringify({ ...storage, ...data }))
	},
	clearAllStorageData: (): void => {
		BrowserStorage.storageType.clear()
	}
}

