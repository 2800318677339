import { createAction, props } from '@ngrx/store'

export const UserProfileView = createAction('[ActionBar] UserProfileView')
export const UserList = createAction('[ActionBar] UserList')
export const UserView = createAction('[ActionBar] UserView')
export const UserUpdate = createAction('[ActionBar] UserUpdate')
export const UserUpdateDisableGroup = createAction('[ActionBar] UserUpdateDisableGroup')
export const UserViewFromGroup = createAction('[ActionBar] UserViewFromGroup')
export const GroupList = createAction('[ActionBar] GroupList')
export const GroupView = createAction('[ActionBar] GroupView')
export const GroupEdit = createAction('[ActionBar] GroupEdit')
export const GroupCreate = createAction('[ActionBar] GroupCreate')
export const PolicyList = createAction('[ActionBar] PolicyList')
export const DashboardOverview = createAction('[ActionBar] DashboardOverview')
export const DashboardOverviewV2 = createAction('[ActionBar] DashboardOverviewV2')
export const DashboardRewardPoolOverview = createAction('[ActionBar] DashboardRewardPoolOverview')
export const DashboardOverviewWithoutChannel = createAction('[ActionBar] DashboardOverviewWithoutChannel')
export const DashboardRedemption = createAction('[ActionBar] DashboardRedemption')
export const CampaignList = createAction('[ActionBar] CampaignList')
export const CampaignUpdate = createAction('[ActionBar] CampaignUpdate')
export const CampaignDisableUpdate = createAction('[ActionBar] CampaignDisableUpdate')
export const CampaignCreate = createAction('[ActionBar] CampaignCreate')
export const CampaignDisableCreate = createAction('[ActionBar] CampaignDisableCreate')
export const CampaignSimulate = createAction('[ActionBar] CampaignSimulate')
export const CampaignView = createAction('[ActionBar] CampaignView')
export const CampaignRepublishView = createAction('[ActionBar] CampaignRepublishView')
export const CampaignRepublished = createAction('[ActionBar] CampaignRepublished')
export const CampaignDisableRepublished = createAction('[ActionBar] CampaignDisableRepublished')
export const PbCampaignRepublished = createAction('[ActionBar] PbCampaignRepublished')
export const PbCampaignDisableRepublished = createAction('[ActionBar] PbCampaignDisableRepublished')
export const CampaignPublishedDisableEdit = createAction('[ActionBar] CampaignPublishedDisableEdit')
export const CampaignUpdateDisableDelete = createAction('[ActionBar] CampaignUpdateDisableDelete')
export const CampaignUpdateDisableDeleteAndUpdate = createAction('[ActionBar] CampaignUpdateDisableDeleteAndUpdate')
export const RewardPoolList = createAction('[ActionBar] RewardPoolList')
export const RewardPoolView = createAction('[ActionBar] RewardPoolView')
export const RewardPoolViewDisableDelete = createAction('[ActionBar] RewardPoolViewDisableDelete')
export const RewardPoolUpdate = createAction('[ActionBar] RewardPoolUpdate')
export const RewardPoolUpdateDisableDelete = createAction('[ActionBar] RewardPoolUpdateDisableDelete')
export const RewardPoolCreate = createAction('[ActionBar] RewardPoolCreate')
export const RewardPoolGroupList = createAction('[ActionBar] RewardPoolGroupList')
export const RewardPoolGroupView = createAction('[ActionBar] RewardPoolGroupView')
export const RewardPoolGroupUpdate = createAction('[ActionBar] RewardPoolGroupUpdate')
export const RewardPoolGroupUpdateGlRedemption = createAction('[ActionBar] RewardPoolGroupUpdateGlRedemption')
export const RewardPoolGroupUpdateGlRedemptionFromView = createAction('[ActionBar] RewardPoolGroupUpdateGlRedemptionFromView')
export const RewardPoolGroupViewGlRedemption = createAction('[ActionBar] RewardPoolGroupViewGlRedemption')
export const RewardPoolGroupDisableUpdateGlRedemption = createAction('[ActionBar] RewardPoolGroupDisableUpdateGlRedemption')
export const RewardPoolGroupDisableUpdateGlRedemptionFromView = createAction('[ActionBar] RewardPoolGroupDisableUpdateGlRedemptionFromView')
export const RewardPoolGroupCreate = createAction('[ActionBar] RewardPoolGroupCreate')
export const GlProvisionList = createAction('[ActionBar] GlProvisionList')
export const GlProvisionView = createAction('[ActionBar] GlProvisionView')
export const GlProvisionEditDisableDelete = createAction('[ActionBar] GlProvisionEditDisableDelete')
export const GlProvisionViewDisableDelete = createAction('[ActionBar] GlProvisionViewDisableDelete')
export const GlProvisionUpdate = createAction('[ActionBar] GlProvisionUpdate')
export const GlProvisionCreate = createAction('[ActionBar] GlProvisionCreate')
export const GlRedemptionView = createAction('[ActionBar] GlRedemptionView')
export const GlRedemptionEditDisableDelete = createAction('[ActionBar] GlRedemptionEditDisableDelete')
export const GlRedemptionViewDisableDelete = createAction('[ActionBar] GlRedemptionViewDisableDelete')
export const GlRedemptionUpdate = createAction('[ActionBar] GlRedemptionUpdate')
export const GlRedemptionCreate = createAction('[ActionBar] GlRedemptionCreate')
export const GlManagementList = createAction('[ActionBar] GlManagementList')
export const GlManagementView = createAction('[ActionBar] GlManagementView')
export const GlManagementUpdate = createAction('[ActionBar] GlManagementUpdate')
export const GlManagementCreate = createAction('[ActionBar] GlManagementCreate')
export const TcGroupList = createAction('[ActionBar] TcGroupList')
export const TcGroupView = createAction('[ActionBar] TcGroupView')
export const TcGroupViewDisableDelete = createAction('[ActionBar] TcGroupViewDisableDelete')
export const TcGroupUpdate = createAction('[ActionBar] TcGroupUpdate')
export const TcGroupUpdateDisableDelete = createAction('[ActionBar] TcGroupUpdateDisableDelete')
export const TcGroupCreate = createAction('[ActionBar] TcGroupCreate')
export const MccGroupList = createAction('[ActionBar] MccGroupList')
export const MccGroupView = createAction('[ActionBar] MccGroupView')
export const MccGroupViewDisableDelete = createAction('[ActionBar] MccGroupViewDisableDelete')
export const MccGroupUpdate = createAction('[ActionBar] MccGroupUpdate')
export const MccGroupUpdateDisableDelete = createAction('[ActionBar] MccGroupUpdateDisableDelete')
export const MccGroupCreate = createAction('[ActionBar] MccGroupCreate')
export const MerchantGroupList = createAction('[ActionBar] MerchantGroupList')
export const MerchantGroupView = createAction('[ActionBar] MerchantGroupView')
export const MerchantGroupViewDisableDelete = createAction('[ActionBar] MerchantGroupViewDisableDelete')
export const MerchantGroupUpdate = createAction('[ActionBar] MerchantGroupUpdate')
export const MerchantGroupUpdateDisableDelete = createAction('[ActionBar] MerchantGroupUpdateDisableDelete')
export const MerchantGroupCreate = createAction('[ActionBar] MerchantGroupCreate')
export const ProductTypeGroupList = createAction('[ActionBar] ProductTypeGroupList')
export const ProductTypeGroupView = createAction('[ActionBar] ProductTypeGroupView')
export const ProductTypeGroupViewDisableDelete = createAction('[ActionBar] ProductTypeGroupViewDisableDelete')
export const ProductTypeGroupUpdate = createAction('[ActionBar] ProductTypeGroupUpdate')
export const ProductTypeGroupUpdateDisableDelete = createAction('[ActionBar] ProductTypeGroupUpdateDisableDelete')
export const ProductTypeGroupCreate = createAction('[ActionBar] ProductTypeGroupCreate')
export const CustomerManagementList = createAction('[ActionBar] CustomerManagementList')
export const CustomerManagementView = createAction('[ActionBar] CustomerManagementView')
export const CustomerManagementCardView = createAction('[ActionBar] CustomerManagementCardView')
export const CustomerManagementTransactionView = createAction('[ActionBar] CustomerManagementTransactionView')
export const CustomerManagementAccountView = createAction('[ActionBar] CustomerManagementAccountView')
export const CustomerManagementRewardPoolView = createAction('[ActionBar] CustomerManagementRewardPoolView')
export const CustomerManagementRewardPoolGroupView = createAction('[ActionBar] CustomerManagementRewardPoolGroupView')
export const CustomerManagementConditionalRewardView = createAction('[ActionBar] CustomerManagementConditionalRewardView')
export const CustomerManagementRewardPoolGroupOverview = createAction('[ActionBar] CustomerManagementRewardPoolGroupOverview')
export const CustomerManagementStatementTransactionView = createAction('[ActionBar] CustomerManagementStatementTransactionView')
export const CustomerManagementAccountStatementTransactionView = createAction('[ActionBar] CustomerManagementAccountStatementTransactionView')
export const CustomerManagementTransactionDetailView = createAction('[ActionBar] CustomerManagementTransactionDetailView')
export const CustomerManagementAccountTransactionDetailView = createAction('[ActionBar] CustomerManagementAccountTransactionDetailView')
export const CustomerManagementRewardPoolStatementTransactionView = createAction('[ActionBar] CustomerManagementRewardPoolStatementTransactionView')
export const CustomerManagementRewardPoolAccumulationBucketView = createAction('[ActionBar] CustomerManagementRewardPoolAccumulationBucketView')
export const CustomerManagementAccountAccumulationBucketView = createAction('[ActionBar] CustomerManagementAccountAccumulationBucketView')
export const CustomerManagementRedemptionHistoryView = createAction('[ActionBar] CustomerManagementRedemptionHistoryView')
export const CustomerManagementRedemptionHistoryOverview = createAction('[ActionBar] CustomerManagementRedemptionHistoryOverview')
export const CustomerManagementPointsAdjustment = createAction('[ActionBar] CustomerManagementPointsAdjustment')
export const CustomerManagementBankwidePointsAdjustment = createAction('[ActionBar] CustomerManagementBankwidePointsAdjustment')
export const CustomerManagementAccountViewWithoutPointsAdjustment = createAction('[ActionBar] CustomerManagementAccountViewWithoutPointsAdjustment')
export const CustomerManagementCardViewWithoutPointsAdjustment = createAction('[ActionBar] CustomerManagementCardViewWithoutPointsAdjustment')
export const CustomerManagementCatagloueRedemptionGridListRewardPool = createAction('[ActionBar] CustomerManagementCatagloueRedemptionGridListRewardPool')
export const CustomerManagementCatagloueRedemptionGridListRewardPoolGroup = createAction('[ActionBar] CustomerManagementCatagloueRedemptionGridListRewardPoolGroup')
export const CustomerManagementCatagloueRedemptionDetails = createAction('[ActionBar] CustomerManagementCatagloueRedemptionDetails')
export const CustomerManagementCatagloueRedemptionDetailsDisable = createAction('[ActionBar] CustomerManagementCatagloueRedemptionDetailsDisable')
export const CustomerManagementCataglouePartnerRedemptionDetails = createAction('[ActionBar] CustomerManagementCataglouePartnerRedemptionDetails')
export const CustomerManagementCataglouePartnerRedemptionDetailsDisable = createAction('[ActionBar] CustomerManagementCataglouePartnerRedemptionDetailsDisable')
export const AccountList = createAction('[ActionBar] AccountList')
export const TransactionList = createAction('[ActionBar] TransactionList')
export const TransactionView = createAction('[ActionBar] TransactionView')
export const FieldMappingList = createAction('[ActionBar] FieldMappingList')
export const FieldMappingView = createAction('[ActionBar] FieldMappingView')
export const PointAdjustmentFileUploadPost = createAction('[ActionBar] PointAdjustmentFileUploadPost')
export const PointAdjustmentFileUploadView = createAction('[ActionBar] PointAdjustmentFileUploadView')
export const RedeemPartnerList = createAction('[ActionBar] RedeemPartnerList')
export const RedeemPartnerView = createAction('[ActionBar] RedeemPartnerView')
export const RedeemWllcorePartnerView = createAction('[ActionBar] RedeemWllcorePartnerView')
export const RedeemPartnerCreate = createAction('[ActionBar] RedeemPartnerCreate')
export const RedeemPartnerUpdate = createAction('[ActionBar] RedeemPartnerUpdate')
export const RedeemWllcorePartnerUpdate = createAction('[ActionBar] RedeemWllcorePartnerUpdate')
export const AutoRedemptionList = createAction('[ActionBar] AutoRedemptionList')
export const AutoRedemptionView = createAction('[ActionBar] AutoRedemptionView')
export const AutoRedemptionUpdate = createAction('[ActionBar] AutoRedemptionUpdate')
export const AutoRedemptionCreate = createAction('[ActionBar] AutoRedemptionCreate')
export const ProductCatalogueList = createAction('[ActionBar] ProductCatalogueList')
export const ProductCatalogueView = createAction('[ActionBar] ProductCatalogueView')
export const ProductCatalogueCreate = createAction('[ActionBar] ProductCatalogueCreate')
export const ProductCatalogueUpdate = createAction('[ActionBar] ProductCatalogueUpdate')
export const ProductPartnerView = createAction('[ActionBar] ProductPartnerView')
export const ProductPartnerCreate = createAction('[ActionBar] ProductPartnerCreate')
export const ProductPartnerUpdate = createAction('[ActionBar] ProductPartnerUpdate')
export const FulfilmentPartnerList = createAction('[ActionBar] FulfilmentPartnerList')
export const FulfilmentPartnerView = createAction('[ActionBar] FulfilmentPartnerView')
export const FulfilmentPartnerCreate = createAction('[ActionBar] FulfilmentPartnerCreate')
export const FulfilmentPartnerUpdate = createAction('[ActionBar] FulfilmentPartnerUpdate')
export const FulfilmentPartnerViewDisableDelete = createAction('[ActionBar] FulfilmentPartnerViewDisableDelete')
export const FulfilmentPartnerDisableDelete = createAction('[ActionBar] FulfilmentPartnerDisableDelete')
export const WaiverManagementList = createAction('[ActionBar] WaiverManagementList')
export const WaiverManagementView = createAction('[ActionBar] WaiverManagementView')
export const WaiverManagementEdit = createAction('[ActionBar] WaiverManagementEdit')
export const WaiverManagementCreate = createAction('[ActionBar] WaiverManagementCreate')
export const OnTheSpotRedemptionList = createAction('[ActionBar] OnTheSpotRedemptionList')
export const OnTheSpotRedemptionView = createAction('[ActionBar] OnTheSpotRedemptionView')
export const OnTheSpotRedemptionUpdate = createAction('[ActionBar] OnTheSpotRedemptionUpdate')
export const OnTheSpotRedemptionCreate = createAction('[ActionBar] OnTheSpotRedemptionCreate')
export const OnTheSpotRedemptionViewDisableDelete = createAction('[ActionBar] OnTheSpotRedemptionViewDisableDelete')
export const RedeemRulesList = createAction('[ActionBar] RedeemRulesList')
export const RedeemRulesView = createAction('[ActionBar] RedeemRulesView')
export const RedeemRulesEdit = createAction('[ActionBar] RedeemRulesEdit')
export const RedeemRulesCreate = createAction('[ActionBar] RedeemRulesCreate')
export const ConditionalRewardList = createAction('[ActionBar] ConditionalRewardList')
export const ConditionalRewardUpdate = createAction('[ActionBar] ConditionalRewardUpdate')
export const ConditionalRewardDisableUpdate = createAction('[ActionBar] ConditionalRewardDisableUpdate')
export const ConditionalRewardCreate = createAction('[ActionBar] ConditionalRewardCreate')
export const ConditionalRewardDisableCreate = createAction('[ActionBar] ConditionalRewardDisableCreate')
export const ConditionalRewardDisableDelete = createAction('[ActionBar] ConditionalRewardDisableDelete')
export const ConditionalRewardDisableDeleteAndUpdate = createAction('[ActionBar] ConditionalRewardDisableDeleteAndUpdate')
export const ConditionalRewardView = createAction('[ActionBar] ConditionalRewardView')
export const ConditionalRewardArchiveView = createAction('[ActionBar] ConditionalRewardArchiveView')
export const ConditionalRewardRepublishDisableEdit = createAction('[ActionBar] ConditionalRewardRepublishDisableEdit')
export const ConditionalRewardRepublish = createAction('[ActionBar] ConditionalRewardRepublish')
export const ConditionalRewardDisableRepublish = createAction('[ActionBar] ConditionalRewardDisableRepublish')
export const TaskListView = createAction('[ActionBar] TaskListView')
export const MyRequestView = createAction('[ActionBar] MyRequestView')
export const FromTaskListView = createAction('[ActionBar] FromTaskListView')
export const FromMyRequestView = createAction('[ActionBar] FromMyRequestView')
export const TargetSegmentList = createAction('[ActionBar] TargetSegmentList')
export const TargetSegmentCustomerCreate = createAction('[ActionBar] TargetSegmentCustomerCreate')
export const TargetSegmentCardCreate = createAction('[ActionBar] TargetSegmentCardCreate')
export const TargetSegmentAccountCreate = createAction('[ActionBar] TargetSegmentAccountCreate')
export const TargetSegmentCustomerView = createAction('[ActionBar] TargetSegmentCustomerView', props<{ allowDelete: boolean }>())
export const TargetSegmentCardView = createAction('[ActionBar] TargetSegmentCardView', props<{ allowDelete: boolean }>())
export const TargetSegmentAccountView = createAction('[ActionBar] TargetSegmentAccountView', props<{ allowDelete: boolean }>())
export const TargetSegmentCustomerEdit = createAction('[ActionBar] TargetSegmentCustomerEdit')
export const TargetSegmentCardEdit = createAction('[ActionBar] TargetSegmentCardEdit')
export const TargetSegmentAccountEdit = createAction('[ActionBar] TargetSegmentAccountEdit')
export const SimulatorList = createAction('[ActionBar] SimulatorList')
export const FileDownloadList = createAction('[ActionBar] FileDownloadList')

