import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { Request, Response } from 'src/app/models/common/http'
import { RestService } from '../common/rest-client/rest.service'
import { PointsAdjustmentCreateRequest, PointsAdjustmentViewRequest } from 'src/app/models/customer-management/customer-points-adjustment'


@Injectable({
	providedIn: 'root'
})
export class PointsAdjustmentService {

	constructor(
		private rest: RestService,
	) { }

	createPointsAdjustment(params: PointsAdjustmentCreateRequest): Observable<Response> {
		const request: Request = {
			body: {
				cardNumber: params.cardNumber,
				accountNumber: params.accountNumber,
				rewardPoolId: params.rewardPoolId,
				adjustmentType: params.adjustmentType,
				adjustmentPoints: params.adjustmentPoints,
				resourceCode: params.resourceCode
			},
			liveURL: '/points-adjustment/create',
		}

		return this.rest.post(request)
	}

	getPointsAdjustmentDetails(params: PointsAdjustmentViewRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/points-adjustment/view/' + params.id,
		}
		return this.rest.get(request)
	}

	getAdjustmentTypeList(): Observable<Response> {
		const request: Request = {
			liveURL: '/points-adjustment/adjustment-type/list'
		}

		return this.rest.get(request)
	}
}
