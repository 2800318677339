import { createFormGroupState, FormGroupState, updateGroup, validate } from 'ngrx-forms'
import { maxLength, required } from 'ngrx-forms/validation'
import { RewardPoolGroupListResponse } from 'src/app/models/param-settings/reward-pool-group/reward-pool-group-list'
import { RewardPool } from 'src/app/models/param-settings/reward-pool/reward-pool'
import { RewardPoolListResponse } from 'src/app/models/param-settings/reward-pool/reward-pool-list'
import { FieldValidator } from 'src/app/models/util/field.validator'
import { GlMapListResult, Resource, RewardPoolGroupGlRedemption } from 'src/app/models/param-settings/reward-pool-group/reward-pool-group'
import { GlRedemption } from 'src/app/models/param-settings/gl-redemption/gl-redemption'

export interface State {
	isLoading: boolean
	isTableLoading: boolean
	rewardPoolAction?: string,
	id?: number,
	rewardPoolId?: number,
	redemptionItemId?: number,
	redemptionItemKind?: string,
	isViewPage?: boolean,
	rewardPoolGroupDetailForm: FormGroupState<RewardPoolGroupDetailForm>
	rewardPoolGroupListResponse: RewardPoolGroupListResponse
	rewardPoolAvailableListResponse: RewardPoolListResponse
	redemptionSequence: Resource[] | []
	rewardPoolGroupDetailFormView: RewardPoolGroupDetailForm
	redemptionSequenceView: Resource[] | []
	rewardPoolGlMapList: RewardPoolGroupGlRedemption[]
	rewardPoolGlMapWaiverDetailForm: RewardPoolGroupGlRedemption[]
	rewardPoolGlMapPartnerDetailForm: RewardPoolGroupGlRedemption[]
	rewardPoolGlMapCatalogueDetailForm: RewardPoolGroupGlRedemption[]
	rewardPoolGlMapOtsDetailForm: RewardPoolGroupGlRedemption[]
	glRedemptionList: GlRedemption[]
	glMapListResult: GlMapListResult[]
}

export interface RewardPoolGroupDetailForm {
	id: number
	code: string
	name: string
	createdBy: string
	createdDate: string
	modifiedBy: string
	modifiedDate: string
	rewardPools?: RewardPool[]
	removedRewardPools?: RewardPool[]
	redemptionLinkage: boolean
}

export const initialRewardPoolGroupDetailFormValue: RewardPoolGroupDetailForm = {
	id: 0,
	code: '',
	name: '',
	createdBy: '',
	createdDate: '',
	modifiedBy: '',
	modifiedDate: '',
	rewardPools: [],
	removedRewardPools: [],
	redemptionLinkage: false
}

export interface RewardPoolGlMapForm {
	redemptionItemId: number
	redemptionItemName: string
	redemptionItemCode: string
	rewardPoolName: string
	glRedemptionId: number
}

export const initialRewardPoolGlMapFormValue: RewardPoolGlMapForm = {
	redemptionItemId: null,
	redemptionItemName: '',
	redemptionItemCode: '',
	rewardPoolName: '',
	glRedemptionId: null
}

export const initialRewardPoolGlMapForm = createFormGroupState('rewardPoolGlMapForm', initialRewardPoolGlMapFormValue)

export const validateRewardPoolGlMapForm = updateGroup<RewardPoolGlMapForm>({
	glRedemptionId: validate([required])
})

export const initialRewardPoolGroupDetailForm = createFormGroupState('rewardPoolGroupDetailForm', initialRewardPoolGroupDetailFormValue)

export const validateRewardPoolGroupDetailForm = updateGroup<RewardPoolGroupDetailForm>({
	id: validate([required]),
	code: validate([required, maxLength(10), FieldValidator.alphaNumericWithoutSpaces()]),
	name: validate([required, maxLength(40), FieldValidator.checkValidCharacters()])
})

export const initialState: State = {
	isLoading: false,
	isTableLoading: false,
	rewardPoolGroupDetailForm: initialRewardPoolGroupDetailForm,
	rewardPoolGroupListResponse: {
		rewardPoolGroups: []
	},
	rewardPoolAvailableListResponse:  {
		rewardPools: []
	},
	redemptionSequence: [],
	rewardPoolGroupDetailFormView: initialRewardPoolGroupDetailFormValue,
	redemptionSequenceView: [],
	rewardPoolGlMapList: [],
	rewardPoolGlMapWaiverDetailForm: [],
	rewardPoolGlMapPartnerDetailForm: [],
	rewardPoolGlMapCatalogueDetailForm: [],
	rewardPoolGlMapOtsDetailForm: [],
	glRedemptionList: [],
	glMapListResult: []
}
