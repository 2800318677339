import { KeyValue } from '@angular/common'
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { MatTableDataSource, MatPaginator } from '@angular/material'
import { Store } from '@ngrx/store'
import { Subscription } from 'rxjs'
import { RulesetTasklist, CampaignInfoDataColumns, RulesetDetailDataColumns, RuleDetails, initialRulesetRewardValue, ColumnField, ExpandedRuleDetails } from 'src/app/models/campaign-management/campaign/campaign'
import { FilterPredicate } from 'src/app/models/util/filter-predicate'
import { CampaignTier } from 'src/app/store/campaign-management/campaign'
import * as AppStore from 'src/app/store'
import * as CampaignSelectors from 'src/app/store/campaign-management/campaign/campaign.selectors'
import { trigger, state, style, transition, animate, animateChild, query } from '@angular/animations'

@Component({
	selector: 'app-campaign-worklist-ruleset-detail',
	templateUrl: './campaign-worklist-ruleset-detail.component.html',
	styleUrls: ['./campaign-worklist-ruleset-detail.component.scss'],
	animations: [
		trigger('detailExpand', [
			state('collapsed', style({ height: '0px', minHeight: '0' })),
			state('expanded', style({ height: '*' })),
			transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
			state('collapsed1', style({ transform: 'rotate(360deg)' })),
			state('expanded1', style({ transform: 'rotate(180deg)' })),
			transition('expanded1 <=> collapsed1',
				animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
			),
			state('collapsed3', style({ textOverflow: 'ellipsis', maxWidth: '300px', overflow: 'hidden', whiteSpace: 'nowrap' })),
			state('expanded3', style({ whiteSpace: 'pre-line' })),
			transition('expanded3 <=> collapsed3',
				animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
			)
		])
	]
})
export class CampaignWorklistRulesetDetailComponent implements OnInit {
	@Input() rulesetName: string
	@Input() ruleset: RulesetTasklist
	@Output() isLoading = new EventEmitter<boolean>()

	subs: Subscription
	displayedColumns: CampaignInfoDataColumns[] = ['columnName', 'from', 'to']
	rulesetInfoDisplayedColumns: RulesetDetailDataColumns[] = ['columnName', 'from', 'to']
	rulesetInfoDataSource: MatTableDataSource<RuleDetails>
	expandedRule: RuleDetails = null

	rewardPool: string
	amountField: string
	type: string
	rewardCreditMethod: string
	transactionMethod: string
	rewardMethod: string
	conditionFactor: string
	valueFactor: string
	pointFactor: string
	accumulationCycleType: string
	accumulationCycleDay: string
	accumulationCycleMonth: string
	rewardChanges = true
	roundingValue: string
	numberDecimal: string
	numberDecimalValue: string
	tierRewardSetting: CampaignTier[]
	rewardLevel: string

	channelList: KeyValue<string, string>[] = []
	rewardPoolList: KeyValue<string, string>[] = []
	cappingTypeList: KeyValue<string, string>[] = []
	campaignCycleTypeList: KeyValue<string, string>[] = []
	dayList: KeyValue<string, string>[] = []
	dayOfMonthList: KeyValue<string, string>[] = []
	monthList: KeyValue<string, string>[] = []
	billingCycleList: KeyValue<string, string>[] = []
	yearlyCycleTypeList: KeyValue<string, string>[] = []
	amountList: KeyValue<string, string>[] = []
	rewardTypeList: KeyValue<string, string>[] = []
	rewardCreditMethodList: KeyValue<string, string>[] = []
	transactionMethodList: KeyValue<string, string>[] = []
	transactionsMethodList: KeyValue<string, string>[] = []
	accumulationCycleTypeList: KeyValue<string, string>[] = []
	roundingList: KeyValue<string, string>[] = []
	numberDecimalList: KeyValue<string, string>[] = []
	calculationMethodList: KeyValue<string, string>[] = []
	rewardLevelList: KeyValue<string, string>[] = []
	billingCycleTypeList: KeyValue<string, string>[] = []

	@ViewChild('matPaginatorRulesetCondition', { static: false }) paginator: MatPaginator

	constructor(
		private store: Store<AppStore.State>
	) {
		this.rulesetInfoDataSource = new MatTableDataSource()
		this.subs = this.store.select(CampaignSelectors.getCampaign).subscribe(x => {

			// set option list
			const campaignData = x.campaignData
			if (this.ruleset && campaignData) {
				this.channelList = campaignData.channelList
				this.rewardPoolList = campaignData.rewardPools
				this.cappingTypeList = campaignData.cappingTypeList
				this.campaignCycleTypeList = campaignData.campaignCycleTypeList
				this.dayList = campaignData.dayList
				this.dayOfMonthList = campaignData.dayOfMonthList
				this.monthList = campaignData.monthList
				this.billingCycleList = campaignData.billingCycleTypeList
				this.yearlyCycleTypeList = campaignData.yearlyCycleTypeList
				this.rewardTypeList = campaignData.rewardTypeList
				if (this.ruleset.newReward.type === 'INC') {
					this.rewardCreditMethodList = campaignData.rewardCreditMethodList.filter(o => o.key === 'I' || o.key === 'CCE')
					if (this.ruleset.newReward.rewardLevel === 'ACCOUNT' && this.ruleset.newReward.rewardCreditMethod === 'I') {
						this.transactionMethodList = campaignData.transactionMethodList.filter(o => o.key === 'AMOUNT')
					} else {
						this.transactionMethodList = campaignData.transactionMethodList
					}
				} else if (this.ruleset.newReward.type === 'TPT') {
					this.rewardCreditMethodList = campaignData.rewardCreditMethodList.filter(o => o.key === 'I')
					this.transactionMethodList = campaignData.transactionMethodList.filter(o => o.key === 'AMOUNT')
				} else if (this.ruleset.newReward.type === 'TPC') {
					this.rewardCreditMethodList = campaignData.rewardCreditMethodList.filter(o => o.key === 'CCE' || o.key === 'CPE')
					this.transactionMethodList = campaignData.transactionMethodList
				} else {
					this.rewardCreditMethodList = campaignData.rewardCreditMethodList
					this.transactionMethodList = campaignData.transactionMethodList
				}

				this.transactionsMethodList = campaignData.transactionsMethodList
				this.accumulationCycleTypeList = campaignData.accumulationCycleTypeList
				this.roundingList = campaignData.roundingList
				this.numberDecimalList = campaignData.numberDecimalList
				this.calculationMethodList = campaignData.calculationMethodList
				this.rewardLevelList = campaignData.rewardLevelList
				this.billingCycleTypeList = campaignData.billingCycleTypeList
			}

			if (this.ruleset && this.ruleset.newReward) {
				if (this.yearlyCycleTypeList.map(o => o.key).includes(this.ruleset.newReward.accumulationCycleType)) {
					this.accumulationCycleType = 'Y'
				} else if (this.billingCycleTypeList.map(o => o.key).includes(this.ruleset.newReward.accumulationCycleType)) {
					this.accumulationCycleType = 'B'
				} else {
					this.accumulationCycleType = this.ruleset.newReward.accumulationCycleType
				}
			}

			if (this.ruleset && this.ruleset.newReward && this.ruleset.oldReward) {
				this.rewardChanges = JSON.stringify(this.ruleset.newReward) === JSON.stringify(this.ruleset.oldReward)
				this.isLoading.emit(false)
			}

			if (this.ruleset && this.ruleset.oldReward === null) {
				this.ruleset.oldReward = initialRulesetRewardValue
			}

			this.rulesetInfoDataSource.data = this.ruleset && this.ruleset.rules && this.ruleset.rules.map((o: ExpandedRuleDetails) => o.ruleDetails ? ({ ...o, expand: false, ruleDetails: o.ruleDetails.map((o: ColumnField) => ({ ...o, expand: false })) }) : o)
			// this.expandedRule = this.rulesetInfoDataSource.data
			this.rulesetInfoDataSource.paginator = this.paginator
			this.rulesetInfoDataSource.filterPredicate = ({ columnName }: ColumnField, filter: string): boolean => {
				return FilterPredicate.isIdenticalTo(filter)([columnName])
			}
		})

	}

	ngOnInit() {
		this.rulesetInfoDataSource.data = this.ruleset && this.ruleset.rules
		// this.expandedRule = this.rulesetInfoDataSource.data
		this.rulesetInfoDataSource.paginator = this.paginator
	}

	getChannelValue(value: string): string {
		const result = this.channelList.find(x => x.key === value)
		return result ? result.value : ''
	}

	getRewardPoolValue(value: string): string {
		const result = this.rewardPoolList.find(x => x.key === value)
		return result ? result.value : '-'
	}

	getCappingTypeValue(value: string): string {
		const result = this.cappingTypeList.find(x => x.key === value)
		return result ? result.value : ''
	}

	getCampaignCycleTypeValue(value: string): string {
		const result = this.campaignCycleTypeList.find(x => x.key === value)
		return result ? result.value : ''
	}

	getDayValue(value: string): string {
		const result = this.dayList.find(x => x.key === value)
		return result ? result.value : ''
	}

	getDayOfMonthValue(value: string): string {
		const result = this.dayOfMonthList.find(x => x.key === value)
		return result ? result.value : ''
	}

	getMonthValue(value: string): string {
		const result = this.monthList.find(x => x.key === value)
		return result ? result.value : ''
	}

	getBillingCycleValue(value: string): string {
		const result = this.billingCycleList.find(x => x.key === value)
		return result ? result.value : ''
	}

	getYearlyCycleValue(value: string): string {
		const result = this.yearlyCycleTypeList.find(x => x.key === value)
		return result ? result.value : ''
	}

	getAmountValue(value: string): string {
		const result = this.amountList.find(x => x.key === value)
		return result ? result.value : ''
	}

	getRewardTypeValue(value: string): string {
		const result = this.rewardTypeList.find(x => x.key === value)
		return result ? result.value : ''
	}

	getRewardCreditMethodValue(value: string): string {
		const result = this.rewardCreditMethodList.find(x => x.key === value)
		return result ? result.value : ''
	}

	getTransactionMethodValue(value: string): string {
		const result = this.transactionMethodList.find(x => x.key === value)
		return result ? result.value : ''
	}

	getAccumulationCycleTypeValue(value: string): string {
		const result = this.accumulationCycleTypeList.find(x => x.key === value)
		return result ? result.value : ''
	}

	getRoundingValue(value: string): string {
		const result = this.roundingList.find(x => x.key === value)
		return result ? result.value : ''
	}

	getNumberDecimalValue(value: string): string {
		const result = this.numberDecimalList.find(x => x.key === value)
		return result ? result.value : ''
	}

	getCalculationMethodValue(value: string): string {
		const result = this.calculationMethodList.find(x => x.key === value)
		return result ? result.value : ''
	}

	getRewardLevelValue(value: string): string {
		const result = this.rewardLevelList.find(x => x.key === value)
		return result ? result.value : ''
	}

	searchRulesetCondition(filterValue: string) {
		this.rulesetInfoDataSource.filter = filterValue.trim().toLowerCase()
	}

	onExpandClick($event: Event, columnName: string, columnName1: string) {
		$event.stopPropagation()
		this.rulesetInfoDataSource.data = this.rulesetInfoDataSource.data.map((o: ExpandedRuleDetails) => o.columnName === columnName1 ? (({ ...o, ruleDetails: o.ruleDetails.map((i: ColumnField) => i.columnName === columnName ? ({ ...i, expand: !i.expand }) : i) })) : o)
	}

	onRulesetExpandClick($event: Event, columnName: string) {
		$event.stopPropagation()
		this.rulesetInfoDataSource.data = this.rulesetInfoDataSource.data.map((o: ExpandedRuleDetails) => o.columnName === columnName ? (({ ...o, expand: !o.expand })) : o)
	}
}
