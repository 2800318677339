import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { Request, Response } from 'src/app/models/common/http'
import { RestService } from '../common/rest-client/rest.service'
import { RewardPoolOverviewViewRequest, RewardPoolGroupOverviewViewRequest, CampaignOverviewViewRequest, EndMonthRequest, CampaignListViewRequest, CampaignCappingUtlizationRequest, PointsRedemptionAnalysisRequest,
PointRedeemUtilizationRequest, MccTransactionRequest, RewardPoolAnalysisRequest, RewardPoolGroupAnalysisRequest, RewardPoolAnalysisListRequest } from 'src/app/models/dashboard/dashboard-overview'
import { DashboardRedemptionRewardPoolViewResquest, DashboardRedemptionRewardPoolGroupViewResquest, DashboardRedemptionItemViewResquest, DashboardRedemptionItemOptionListResquest } from 'src/app/models/dashboard/dashboard-redemption'

@Injectable({
	providedIn: 'root'
})

export class DashboardService {
	view() {
		throw new Error('Method not implemented.')
	}

	constructor(
		private rest: RestService,
	) { }

	// Dashboard Overview

	getRewardPoolOverview(params: RewardPoolOverviewViewRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/dashboard/reward-pools' + this.rest.urlQueryString(params)
		}
		return this.rest.get(request)
	}


	getRewardPoolGroupOverview(params: RewardPoolGroupOverviewViewRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/dashboard/reward-pool-groups' + this.rest.urlQueryString(params)
		}
		return this.rest.get(request)
	}

	getCampaignOverview(params: CampaignOverviewViewRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/dashboard/campaign/' + params.campaignId + this.rest.urlQueryString(params)
		}
		return this.rest.get(request)
	}

	getCampaignListOverview(params: CampaignListViewRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/dashboard/campaigns' + this.rest.urlQueryString(params)
		}
		return this.rest.get(request)
	}

	getCampaignOptionList(params: CampaignListViewRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/dashboard/campaign/option-list' + this.rest.urlQueryString(params)
		}
		return this.rest.get(request)
	}

	getDashboardChannelOptionList(): Observable<Response> {
		const request: Request = {
			liveURL: '/dashboard/channel/option-list'
		}
		return this.rest.get(request)
	}

	getCampaignExpiringList(): Observable<Response> {
		const request: Request = {
			liveURL: '/dashboard/campaign/top-5-expiring'
		}
		return this.rest.get(request)
	}

	getDurationOptionList(): Observable<Response> {
		const request: Request = {
			liveURL: '/dashboard/duration-option-list'
		}
		return this.rest.get(request)
	}

	getRedemptionTypeList(): Observable<Response> {
		const request: Request = {
			liveURL: '/dashboard/redemption-type-list'
		}
		return this.rest.get(request)
	}

	getPendingApprovalTask(): Observable<Response> {
		const request: Request = {
			liveURL: '/dashboard/pending-approval-task'
		}
		return this.rest.get(request)
	}

	getPointsRedemptionAnalysis(params: PointsRedemptionAnalysisRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/dashboard/points-redemption-analysis' + this.rest.urlQueryString(params)
		}
		return this.rest.get(request)
	}

	getRewardPoolAnalysis(params: RewardPoolAnalysisRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/dashboard/reward-pool-analysis' + this.rest.urlQueryString(params)
		}
		return this.rest.get(request)
	}

	getPointRedeemUtilization(params: PointRedeemUtilizationRequest): Observable<Response> {

		const request: Request = {
			liveURL: '/dashboard/point-redeem-utilization' + this.rest.urlQueryString(params)
		}
		return this.rest.get(request)
	}

	getRewardPoolGroupAnalysis(params: RewardPoolGroupAnalysisRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/dashboard/reward-pool-group-analysis' + this.rest.urlQueryString(params)
		}
		return this.rest.get(request)
	}

	getRewardPoolAnalysisOptionList(): Observable<Response> {
		const request: Request = {
			liveURL: '/dashboard/reward-pool-analysis-option-list'
		}
		return this.rest.get(request)
	}

	getRewardPoolAnalysisRewardPoolList(params: RewardPoolAnalysisListRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/dashboard/reward-pool-analysis-reward-pool-list' + this.rest.urlQueryString(params)
		}
		return this.rest.get(request)
	}

	getRewardPoolAnalysisRewardPoolGroupList(params: RewardPoolAnalysisListRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/dashboard/reward-pool-analysis-reward-pool-group-list' + this.rest.urlQueryString(params)
		}
		return this.rest.get(request)
	}

	// Dashboard Redemption

	getDashboardRedemptionRewardPoolByMonth(params: DashboardRedemptionRewardPoolViewResquest): Observable<Response> {
		const request: Request = {
			liveURL: '/dashboard-redemption/reward-pools/' + params.rewardPoolId + this.rest.urlQueryString(params)
		}
		return this.rest.get(request)
	}

	getDashboardRedemptionRewardPoolGroupByMonth(params: DashboardRedemptionRewardPoolGroupViewResquest): Observable<Response> {
		const request: Request = {
			liveURL: '/dashboard-redemption/reward-pool-groups/' + params.rewardPoolGroupId + this.rest.urlQueryString(params)
		}
		return this.rest.get(request)
	}

	getDashboardRedemptionItemByMonth(params: DashboardRedemptionItemViewResquest): Observable<Response> {
		const request: Request = {
			liveURL: '/dashboard-redemption/redemption-item/' + params.redemptionId + this.rest.urlQueryString(params)
		}
		return this.rest.get(request)
	}

	getDashboardRedemptionCatalogueList(): Observable<Response> {
		const request: Request = {
			liveURL: '/dashboard-redemption/catalogue-list'
		}
		return this.rest.get(request)
	}

	getDashboardRedemptionRewardPoolOptionList(): Observable<Response> {
		const request: Request = {
			liveURL: '/dashboard-redemption/reward-pool/option-list'
		}
		return this.rest.get(request)
	}

	getDashboardRedemptionRewardPoolGroupOptionList(): Observable<Response> {
		const request: Request = {
			liveURL: '/dashboard-redemption/reward-pool-group/option-list'
		}
		return this.rest.get(request)
	}

	getDashboardRedemptionItemOptionList(params: DashboardRedemptionItemOptionListResquest): Observable<Response> {
		const request: Request = {
			liveURL: '/dashboard-redemption/redemption-item/option-list' + this.rest.urlQueryString(params)
		}
		return this.rest.get(request)
	}

	getDashboardWaiverRedemptionItemOptionList(): Observable<Response> {
		const request: Request = {
			liveURL: '/dashboard-redemption/waiver/option-list'
		}
		return this.rest.get(request)
	}

	getTop5PerformanceCampaign(): Observable<Response> {
		const request: Request = {
			liveURL: '/dashboard/top-5-performance-campaign'
		}
		return this.rest.get(request)
	}

	getCampaignCappingUtilizationList(params: CampaignCappingUtlizationRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/dashboard/campaign/top-5-capping-utilization' + this.rest.urlQueryString(params)
		}
		return this.rest.get(request)
	}

	getCampaignTotalPointComparison(campaignId: number): Observable<Response> {
		const request: Request = {
			liveURL: '/dashboard/campaign-total-point/' + this.rest.urlQueryString({campaignId})
		}
		return this.rest.get(request)
	}

	getPublishedCampaignList(channel: string): Observable<Response> {
		const request: Request = {
			liveURL: '/dashboard/published-campaign-list' + this.rest.urlQueryString({channel})
		}
		return this.rest.get(request)
	}

	getMaxMinVarList(): Observable<Response> {
		const request: Request = {
			liveURL: '/dashboard/max-min-list'
		}
		return this.rest.get(request)
	}

	getMccTransactionList(params: MccTransactionRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/dashboard/mcc-transaction-list' + this.rest.urlQueryString(params)
		}
		return this.rest.get(request)
	}
}
