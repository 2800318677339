import { Component, Inject, OnDestroy } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material'
import { Store } from '@ngrx/store'
import { FormGroupState } from 'ngrx-forms'
import { Observable, Subscription } from 'rxjs'
import * as AppStore from 'src/app/store'
import { GlRedemptionDuplicateForm } from 'src/app/store/param-settings/gl-redemption/gl-redemption.state'
import * as GlRedemptionAction from 'src/app/store/param-settings/gl-redemption/gl-redemption.actions'
import * as GlRedemptionSelectors from 'src/app/store/param-settings/gl-redemption/gl-redemption.selectors'

@Component({
	selector: 'app-gl-redemption-duplicate',
	templateUrl: './gl-redemption-duplicate.component.html',
	styleUrls: ['./gl-redemption-duplicate.component.scss']
})
export class GlRedemptionDuplicateComponent implements OnDestroy {

	isLoading = false
	formState$: Observable<FormGroupState<GlRedemptionDuplicateForm>>

	subs: Subscription

	constructor(
		private store: Store<AppStore.State>,
		private dialogRef: MatDialogRef<GlRedemptionDuplicateComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		this.subs = this.store.select(GlRedemptionSelectors.getGlRedemption).subscribe(({ isDuplicateLoading, isDuplicateDialog }) => {
			this.isLoading = isDuplicateLoading

			if (isDuplicateDialog) {
				this.cancel()
			}
		})

		this.formState$ = this.store.select(({ glRedemption }) => glRedemption.glRedemptionDuplicateForm)
	}

	ngOnDestroy() {
		this.subs.unsubscribe()
	}

	cancel() {
		this.dialogRef.close()
	}

	submit() {
		this.store.dispatch(GlRedemptionAction.Duplicate())
	}
}
