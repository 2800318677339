import { Action, createReducer, on } from '@ngrx/store'
import { formGroupReducer, markAsTouched, reset, setValue, box, SetValueAction, createFormGroupState } from 'ngrx-forms'
import * as NgrxForms from 'ngrx-forms'
import * as WaiverManagementAction from './waiver-management.actions'
import * as WaiverManagementState from './waiver-management.state'

export const reducer = createReducer(
	WaiverManagementState.initialState,
	NgrxForms.onNgrxFormsAction(SetValueAction, (state, action) => {
		return actionHandler(state, action)
	}),
	NgrxForms.onNgrxFormsAction(NgrxForms.MarkAsTouchedAction, (state, action) => {
		return actionHandler(state, action)
	}),
	on(WaiverManagementAction.InitialState, () => WaiverManagementState.initialState),
	on(WaiverManagementAction.RestError, state => ({
		...state,
		isLoading: false,
		isDuplicateLoading: false,
		isDuplicateDialog: false
	})),
	on(WaiverManagementAction.InitialLoadList, state => ({
		...state,
		isLoading: true
	})),
	on(WaiverManagementAction.List, state => ({
		...state,
		isLoading: true
	})),
	on(WaiverManagementAction.ListSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		waiverManagementList: payload
	})),
	on(WaiverManagementAction.SetId, (state, { id }) => ({
		...state,
		id
	})),
	on(WaiverManagementAction.SetRedemptionId, (state, { redemptionId }) => ({
		...state,
		redemptionId
	})),
	on(WaiverManagementAction.GoCreate, state => ({
		...state,
		waiverManagementDetailForm: reset(setValue(state.waiverManagementDetailForm, WaiverManagementState.initialWaiverManagementDetailFormValue))
	})),
	on(WaiverManagementAction.View, state => ({
		...state,
		isLoading: true,
		waiverMangementViewResponse: WaiverManagementState.initialWaiverMangementViewResponse
	})),
	on(WaiverManagementAction.ViewSuccess, (state, { payload }) => {
		const waiverManagementFormView = {
			code: payload.code,
			name: payload.name,
			description: payload.description
		}
		return ({
			...state,
			isLoading: false,
			waiverMangementViewResponse: payload,
			waiverManagementFormView,
			waiverManagementDetailForm: WaiverManagementState.validateWaiverManagementDetailForm(setValue(state.waiverManagementDetailForm, waiverManagementFormView as WaiverManagementState.WaiverManagementDetailForm))
		})
	}),
	on(WaiverManagementAction.Create, state => ({
		...state,
		isLoading: true
	})),
	on(WaiverManagementAction.CreateSuccess, state => ({
		...state,
		isLoading: false
	})),
	on(WaiverManagementAction.Update, state => ({
		...state,
		isLoading: true
	})),
	on(WaiverManagementAction.UpdateSuccess, state => ({
		...state,
		isLoading: false
	})),
	on(WaiverManagementAction.Delete, state => ({
		...state,
		isLoading: true
	})),
	on(WaiverManagementAction.DeleteSuccess, () => WaiverManagementState.initialState),
	on(WaiverManagementAction.GetWaiverManagementData, state => ({
		...state,
		isLoading: true
	})),
	on(WaiverManagementAction.GetWaiverManagementSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		waiverManagementData: payload
	})),
	on(WaiverManagementAction.WaiverDialog, (state, { action }) => {
		let assignedWaiverForm = reset(setValue(state.assignedWaiverForm, WaiverManagementState.initialAssignedWaiverFormValue))
		let assignedWaiverFormView
		const redemptionId = state.redemptionId
		const assignedWaiver = state.waiverMangementViewResponse.assignedWaiverList.find(x => x.id === redemptionId)

		let waiverRewardPools: WaiverManagementState.WaiverRewardPools = {
			byId: {},
			allIds: []
		}

		if (assignedWaiver) {
			if (assignedWaiver.rewardPoolType === 'G') {
			assignedWaiver.rewardPoolAndGlRedemption.forEach(x => {
				const id = x.rewardPoolId
				waiverRewardPools = {
					byId: {
						...waiverRewardPools.byId,
						[id]: {
							id,
							form: WaiverManagementState.validateWaiverRewardPoolForm(getWaiverRewardPoolForm(id.toString(), id.toString(), x.rewardPoolName, x.glRedemptionId))
						}
					},
					allIds: [...waiverRewardPools.allIds, id.toString()]
				}
			})
			}
			const newAssignedWaiver = {
				id: assignedWaiver.id,
				code: assignedWaiver.code,
				name: assignedWaiver.name,
				rewardPoolType: assignedWaiver.rewardPoolType,
				rewardPoolId: `${assignedWaiver.rewardPoolType}_${assignedWaiver.rewardPoolId}`,
				productTypeId: box(assignedWaiver.productType && assignedWaiver.productType.split(',')),
				productTypeGroupId: box(assignedWaiver.productTypeGroup && assignedWaiver.productTypeGroup.split(',')),
				cardHolder: assignedWaiver.cardholderType,
				points: assignedWaiver.points,
				glRedemptionId: assignedWaiver.rewardPoolType === 'I' ? assignedWaiver.rewardPoolAndGlRedemption.find(x => x.rewardPoolId === assignedWaiver.rewardPoolId).glRedemptionId : null,
				transactionCode: assignedWaiver.transactionCode,
				transactionAmount: Number(assignedWaiver.amount).toFixed(2),
				transactionDescription: assignedWaiver.transactionDescription,
				rewardPoolAndGlRedemption: assignedWaiver.rewardPoolAndGlRedemption,
				waiverRewardPools,
				createdBy: assignedWaiver.createdBy,
				createdDate: assignedWaiver.createdDate,
				modifiedBy: assignedWaiver.modifiedBy,
				modifiedDate: assignedWaiver.modifiedDate,
				feeTc: box(assignedWaiver.feeTc && assignedWaiver.feeTc.split(',')),
				feeRedemptionTc: box(assignedWaiver.feeRedemptionTc && assignedWaiver.feeRedemptionTc.split(','))
			}

			if (action === 'VIEW') {

				assignedWaiverForm = reset(setValue(state.assignedWaiverForm, newAssignedWaiver))
			}

			if (action === 'UPDATE') {
				assignedWaiverForm = WaiverManagementState.validateAssignedWaiverForm(state)(
					markAsTouched(
						setValue(state.assignedWaiverForm, newAssignedWaiver)
					)
				)
			}
			assignedWaiverFormView = assignedWaiverForm.value
		}

		return ({
			...state,
			action,
			assignedWaiverForm,
			assignedWaiverFormView
		})
	}),
	on(WaiverManagementAction.CreateWaiver, state => ({
		...state,
		isLoading: true
	})),
	on(WaiverManagementAction.CreateWaiverSuccess, state => ({
		...state,
		isLoading: false
	})),
	on(WaiverManagementAction.UpdateWaiver, state => ({
		...state,
		isLoading: true
	})),
	on(WaiverManagementAction.UpdateWaiverSuccess, state => ({
		...state,
		isLoading: false
	})),
	on(WaiverManagementAction.DeleteWaiver, state => ({
		...state,
		isLoading: true
	})),
	on(WaiverManagementAction.DeleteWaiverSuccess, state => ({
		...state,
		isLoading: false
	})),
	on(WaiverManagementAction.OnChangeRewardPool, state => {
		const rewardPoolAndGroup = state.assignedWaiverForm.controls
		const rewardPoolId = rewardPoolAndGroup.rewardPoolId.value
		const rewardPoolType = rewardPoolAndGroup.rewardPoolType.value
		const waiverRewardPools: WaiverManagementState.WaiverRewardPools = {
			byId: {},
			allIds: []
		}

		return ({
			...state,
			assignedWaiverForm: WaiverManagementState.validateAssignedWaiverForm(state)(setValue(state.assignedWaiverForm, {
				...state.assignedWaiverForm.value,
				rewardPoolId,
				rewardPoolType,
				glRedemptionId: null,
				waiverRewardPools
			}))
		})
	}),
	on( WaiverManagementAction.OnChangeRewardPoolGroup, state => {
		const rewardPoolAndGroup = state.assignedWaiverForm.controls
		const rewardPoolId = rewardPoolAndGroup.rewardPoolId.value
		const rewardPoolType = rewardPoolAndGroup.rewardPoolType.value
		const waiverRewardPools: WaiverManagementState.WaiverRewardPools = {
			byId: {},
			allIds: []
		}

		return ({
			...state,
			assignedWaiverForm: WaiverManagementState.validateAssignedWaiverForm(state)(setValue(state.assignedWaiverForm, {
				...state.assignedWaiverForm.value,
				rewardPoolId,
				rewardPoolType,
				glRedemptionId: null,
				waiverRewardPools
			}))
		})
	}),
	on( WaiverManagementAction.OnChangeRewardPoolGroupSuccess, (state, { payload }) => {
		const rewardPoolList = payload.rewardPools

		let waiverRewardPools = {
			byId: {},
			allIds: []
		}

		if (rewardPoolList) {
			rewardPoolList.forEach(x => {
				const id = x.id.toString()

				waiverRewardPools = {
					byId: {
						...waiverRewardPools.byId,
						[id]: {
							id,
							form: WaiverManagementState.validateWaiverRewardPoolForm(getWaiverRewardPoolForm(id, id, `${x.code} - ${x.name}`, null))
						}
					},
					allIds: [...waiverRewardPools.allIds, id]
				}
			})
		}

		return {
			...state,
			assignedWaiverForm: setValue(state.assignedWaiverForm, {
				...state.assignedWaiverForm.value,
				waiverRewardPools
			})
		}
	}),
	on(WaiverManagementAction.DuplicateDialog, state => ({
		...state,
		assignedWaiverDuplicateForm: reset(setValue(state.assignedWaiverDuplicateForm, WaiverManagementState.initialAssignedWaiverDuplicateFormValue)),
		isDuplicateDialog: false
	})),
	on(WaiverManagementAction.DuplicateWaiverDetail, state => ({
		...state,
		isDuplicateLoading: true
	})),
	on(WaiverManagementAction.DuplicateSuccess, state => ({
		...state,
		isDuplicateLoading: false,
		isDuplicateDialog: true
	}))
)

export function Reducer(state: WaiverManagementState.State = WaiverManagementState.initialState, action: Action) {
	const waiverManagementDetailForm = WaiverManagementState.validateWaiverManagementDetailForm(formGroupReducer(state.waiverManagementDetailForm, action))
	if (waiverManagementDetailForm !== state.waiverManagementDetailForm) {
		state = { ...state, waiverManagementDetailForm }
	}

	const assignedWaiverForm = WaiverManagementState.validateAssignedWaiverForm(state)(formGroupReducer(state.assignedWaiverForm, action))
	if (assignedWaiverForm !== state.assignedWaiverForm) {
		state = { ...state, assignedWaiverForm }
	}

	const assignedWaiverDuplicateForm = WaiverManagementState.validateAssignedWaiverDuplicateForm(formGroupReducer(state.assignedWaiverDuplicateForm, action))
	if (assignedWaiverDuplicateForm !== state.assignedWaiverDuplicateForm) {
		state = {...state, assignedWaiverDuplicateForm}
	}
	return reducer(state, action)
}


function validateForm(form: any) {
	form = markAsTouched(form)

	return WaiverManagementState.validateAssignedWaiverForm(form)
}

function getWaiverRewardPoolForm(id: string, rewardPoolId?: string, rewardPoolName?: string, glRedemptionId?: number) {
	if (rewardPoolId || glRedemptionId) {
		const formGroup = createFormGroupState<WaiverManagementState.WaiverRewardPoolForm>(id, {
			rewardPoolId,
			rewardPoolName,
			glRedemptionId
		})
		return validateWaiverRewardPoolForm(formGroup)
	}

	return createFormGroupState<WaiverManagementState.WaiverRewardPoolForm>(id,
		WaiverManagementState.initialWaiverRewardPoolFormValue)
}

function validateWaiverRewardPoolForm(form: any, state?: WaiverManagementState.State) {
	return markAsTouched(WaiverManagementState.validateWaiverRewardPoolForm(form))
}

function actionHandler(state: WaiverManagementState.State, action: SetValueAction<unknown> | NgrxForms.MarkAsTouchedAction) {
	const formControls = state.assignedWaiverForm

	let form = formGroupReducer(formControls, action)

	if (action.type === 'ngrx/forms/SET_VALUE') {
		form = formGroupReducer(formControls, new SetValueAction(action.controlId, action.value))
	}

	if (form) {
		form = validateForm(state)(form)

		state = {
			...state,
			assignedWaiverForm: form
		}
	}

	const controlId = action.controlId.split('.')[0]

	let waiverRewardPoolsVal = formControls.value.waiverRewardPools

	const newWaiverRewardPools: WaiverManagementState.WaiverRewardPools = {
		byId: {
			...waiverRewardPoolsVal.byId
		},
		allIds: {
			...waiverRewardPoolsVal.allIds
		}
	}

	const waiverRewardPools = newWaiverRewardPools.byId[controlId]

	if (waiverRewardPools) {
		let form = formGroupReducer(waiverRewardPools.form, action)

		if (action.type === 'ngrx/forms/SET_VALUE') {
			form = formGroupReducer(waiverRewardPools.form, new SetValueAction(action.controlId, action.value))
		}

		waiverRewardPoolsVal = {
			byId: {
				...waiverRewardPoolsVal.byId,
				[controlId]: {
					...waiverRewardPoolsVal.byId[controlId],
					form: validateWaiverRewardPoolForm(form)
				}
			},
			allIds: [...waiverRewardPoolsVal.allIds]
		}

		state = {
			...state,
			assignedWaiverForm: setValue(state.assignedWaiverForm, {
				...state.assignedWaiverForm.value,
				waiverRewardPools: waiverRewardPoolsVal
			})
		}
	}
	return state
}
