import { Component, Input, Output, EventEmitter } from '@angular/core'
import { MatTableDataSource } from '@angular/material'

@Component({
	selector: 'app-paginated-result-table',
	templateUrl: './paginated-result-table.component.html',
	styleUrls: ['./paginated-result-table.component.scss']
})
export class PaginatedResultTableComponent {

	constructor() { }
	Object = Object
	@Input() dataSource: MatTableDataSource<any>
	@Input() headerColumns: Array<string>
	@Input() displayedColumns: Array<any>
	@Output() rowClicked = new EventEmitter()

	triggerRowClickOut(event) {
		this.rowClicked.emit(event)
	}

}
