import { createAction, props } from '@ngrx/store'
import { WaiverManagement } from 'src/app/models/redemption-settings/waiver-management/waiver-management'
import { WaiverManagementDataResponse } from 'src/app/models/redemption-settings/waiver-management/waiver-management-data'
import { WaiverManagementViewResponse } from 'src/app/models/redemption-settings/waiver-management/waiver-management-view'
import { RewardPoolGroupViewResponse } from 'src/app/models/param-settings/reward-pool-group/reward-pool-group-view'

export const InitialState = createAction('[WaiverManagement] InitialState')
export const RestError = createAction('[WaiverManagement] RestError', props<{ message: string, manualThrow: boolean }>())

export const SetId = createAction('[WaiverManagement] SetId', props<{ id: number }>())
export const SetRedemptionId = createAction('[WaiverManagement] SetRedemptionId', props<{ redemptionId: string }>())

export const GoList = createAction('[WaiverManagement] GoList')
export const GoView = createAction('[WaiverManagement] GoView')
export const GoCreate = createAction('[WaiverManagement] GoCreate')
export const GoUpdate = createAction('[WaiverManagement] GoUpdate')

export const GoBackDialog = createAction('[WaiverManagement] GoBackDialog', props<{ action: string }>())
export const CreateDialog = createAction('[WaiverManagement] CreateDialog')
export const UpdateDialog = createAction('[WaiverManagement] UpdateDialog')
export const DeleteDialog = createAction('[WaiverManagement] DeleteDialog')
export const CloseDialog = createAction('[WaiverManagement] closeDialog')
export const WaiverDialog = createAction('[WaiverManagement] WaiverDialog', props<{ action: string }>())
export const DeleteWaiverDialog = createAction('[WaiverManagement] DeleteWaiverDialog')

export const List = createAction('[WaiverManagement] List')
export const ListSuccess = createAction('[WaiverManagement] ListSuccess', props<{ payload: WaiverManagement[] }>())

export const View = createAction('[WaiverManagement] View')
export const ViewSuccess = createAction('[WaiverManagement] ViewSuccess', props<{ payload: WaiverManagementViewResponse }>())

export const Create = createAction('[WaiverManagement] Create')
export const CreateSuccess = createAction('[WaiverManagement] CreateSuccess', props<{ payload: string }>())

export const Update = createAction('[WaiverManagement] Update')
export const UpdateSuccess = createAction('[WaiverManagement] UpdateSuccess', props<{ payload: string }>())

export const Delete = createAction('[WaiverManagement] Delete')
export const DeleteSuccess = createAction('[WaiverManagement] DeleteSuccess')

export const CreateWaiver = createAction('[WaiverManagement] CreateWaiver')
export const CreateWaiverSuccess = createAction('[WaiverManagement] CreateWaiverSuccess', props<{ payload: string }>())

export const UpdateWaiver = createAction('[WaiverManagement] UpdateWaiver')
export const UpdateWaiverSuccess = createAction('[WaiverManagement] UpdateWaiverSuccess', props<{ payload: string }>())

export const DeleteWaiver = createAction('[WaiverManagement] DeleteWaiver')
export const DeleteWaiverSuccess = createAction('[WaiverManagement] DeleteWaiverSuccess')

export const GetWaiverManagementData = createAction('[WaiverManagement] GetWaiverManagementData')
export const GetWaiverManagementSuccess = createAction('[WaiverManagement] GetWaiverManagementSuccess', props<{ payload: WaiverManagementDataResponse }>())

export const OnChangeRewardPool = createAction('[WaiverManagement] OnChangeRewardPool')
export const OnChangeRewardPoolGroup = createAction('[WaiverManagement] OnChangeRewardPoolGroup')
export const OnChangeRewardPoolGroupSuccess = createAction('[WaiverManagement] OnChangeRewardPoolGroupSuccess', props<{ payload: RewardPoolGroupViewResponse }>())

export const InitialLoadList = createAction('[WaiverManagement] InitialLoadList')

export const DuplicateDialog = createAction('[WaiverManagement] DuplicateDialog')
export const DuplicateWaiverDetail = createAction('[WaiverManagement] DuplicateWaiverDetail')
export const DuplicateSuccess = createAction('[WaiverManagement] DuplicateSuccess', props<{ payload: string }>())
