import { KeyValue } from '@angular/common'
import { TopCampaignExpiring, CampaignCappingUtilizationResponse, TopPerformanceCampaign, CampaignTotalPointComparison, PublishedCampaign, DateRange, MccTransactionList, MccTransactionTotalAmount } from '../../../models/dashboard/dashboard-overview'
import { PendingApprovalTask } from '../../../models/dashboard/dashboard-overview'
import { PointsRedemptionAnalysisResponse, PointsRedemptionAnalysisForComparisonResponse, PointRedeemUtilization } from '../../../models/dashboard/dashboard-overview'
import { RewardPoolGroupAnalysis } from '../../../models/dashboard/dashboard-overview'
import { createFormGroupState, FormGroupState, updateGroup } from 'ngrx-forms'
import { Option } from 'src/app/models/option/option'
import { RewardPoolAnalysis} from '../../../models/dashboard/dashboard-overview'

export interface State {
	isLoading: boolean
	rewardPoolAnalysisIsLoading: boolean
	pointRedemptionAnalysisIsLoading: boolean
	cappingUtilizationIsLoading: boolean
	topPerformanceCampaign: TopPerformanceCampaign[]
	topCampaignExpiring: TopCampaignExpiring[]
	pointRedeemUtilization: PointRedeemUtilization[]
	campaignCappingUtilizationResponse: CampaignCappingUtilizationResponse[]
	campaignChannelList: KeyValue<string, string>[]
	durationOptionList: Option[]
	durationOptionListWithoutCustom: Option[]
	campaignComparison: {
		isLoading: boolean
		channelList: KeyValue<string, string>[]
		campaignList: PublishedCampaign[]
		campaignIdTotalPointMap: Record<string, CampaignTotalPointComparison[]>
		selectedForm: DashboardViewRequestForm
	}
	dateRange: DateRange
	redemptionTypeList: Option[]
	pointsRedemptionAnalysisResponse: PointsRedemptionAnalysisResponse[]
	pointsRedemptionAnalysisForComparisonResponse: PointsRedemptionAnalysisForComparisonResponse[]
	mccTransaction: {
		maxMinVarList: Option[]
		maxMinSelectedForm: DashboardViewRequestForm
		mccTransactionList: MccTransactionTotalAmount
	}
	dashboardRequestForm: FormGroupState<DashboardViewRequestForm>
	pendingApprovalTask: PendingApprovalTask[],
	rewardPoolAnalysis: RewardPoolAnalysis[],
	rewardPoolGroupAnalysis: RewardPoolGroupAnalysis[]
	rewardPoolAnalysisOptionList: Option[]
	rewardPoolAnalysisRewardPoolList: Option[]
	rewardPoolAnalysisRewardPoolGroupList: Option[]
	pointAccumulationPerformanceForm: FormGroupState<PointAccumulationPerformanceForm>
}

export interface DashboardViewRequestForm {
	campaignId1: number
	campaignId2: number
	campaignChannel: string
	range: string
	daysForComparison: string
	startDate: string
	endDate: string
	redemptionType: string
	maxMinVar: string
}

export interface State {
	isLoading: boolean
	topPerformanceCampaign: TopPerformanceCampaign[]
	pendingApprovalTask: PendingApprovalTask[]
}

export const initialDashboardRequestFormValue: DashboardViewRequestForm = {
	campaignId1: 0,
	campaignId2: 0,
	campaignChannel: '',
	range: '30',
	daysForComparison: '30',
	startDate: '',
	endDate: '',
	redemptionType: 'P',
	maxMinVar: 'HIGH',
}

export const initialDashboardViewRequestForm = createFormGroupState('dashboardV2RequestForm', initialDashboardRequestFormValue)

export interface PointAccumulationPerformanceForm {
	rewardPool: string
	rewardPoolGroup: number
	rewardPoolType: string
}

export const initialPointAccumulationPerformanceForm = createFormGroupState('PointAccumulationPerformanceForm', {
	rewardPool: '0',
	rewardPoolGroup: 0,
	rewardPoolType: 'Reward Pool',
})

export const validateDashboardViewRequestForm = (state: State) => updateGroup<DashboardViewRequestForm>({
})

export const validatePointAccumulationForm = (state: State) => updateGroup<PointAccumulationPerformanceForm>({
})

export const initialState: State = {
	isLoading: false,
	rewardPoolAnalysisIsLoading: false,
	pointRedemptionAnalysisIsLoading: false,
	cappingUtilizationIsLoading: false,
	topPerformanceCampaign: [],
	topCampaignExpiring: [],
	pointRedeemUtilization: [],
	campaignCappingUtilizationResponse: [],
	campaignChannelList: [],
	campaignComparison: {
		isLoading: false,
		channelList: [],
		campaignList: [],
		campaignIdTotalPointMap: {},
		selectedForm: initialDashboardRequestFormValue
	},
	dateRange: {
		startDate: '',
		endDate: ''
	},
	durationOptionList: [],
	mccTransaction: {
		maxMinVarList: [],
		maxMinSelectedForm: initialDashboardRequestFormValue,
		mccTransactionList: {
			totalAmount: 0,
			mccTransactionList: []
		}
	},
	dashboardRequestForm: initialDashboardViewRequestForm,
	pendingApprovalTask: [],
	durationOptionListWithoutCustom: [],
	redemptionTypeList: [],
	pointsRedemptionAnalysisResponse: [],
	pointsRedemptionAnalysisForComparisonResponse: [],
	rewardPoolAnalysis: [],
	rewardPoolGroupAnalysis: [],
	rewardPoolAnalysisOptionList: [],
	rewardPoolAnalysisRewardPoolList: [],
	rewardPoolAnalysisRewardPoolGroupList: [],
	pointAccumulationPerformanceForm: initialPointAccumulationPerformanceForm
}
