import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material'
import { Router } from '@angular/router'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Action, Store } from '@ngrx/store'
import { Observable, of } from 'rxjs'
import { catchError, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators'
import { MessageDialogComponent } from 'src/app/components/common/dialog/message-dialog/message-dialog.component'
import { PointConversionComponent } from 'src/app/components/redemption-settings/redeem-partner/point-conversion/point-conversion.component'
import { getDialogConfig } from 'src/app/models/common/dialog'
import { Response } from 'src/app/models/common/http'
import { GlRedemptionListResponse } from 'src/app/models/param-settings/gl-redemption/gl-redemption-list'
import { RedeemPartner } from 'src/app/models/redemption-settings/redeem-partner/redeem-partner'
import { RedeemPartnerReward } from 'src/app/models/redemption-settings/redeem-partner/redeem-partner-list'
import { RedeemPartnerUpdatePointsConversionRequest, RedeemPartnerUpdateRequest } from 'src/app/models/redemption-settings/redeem-partner/redeem-partner-update'
import { RedeemPartnerViewResponse } from 'src/app/models/redemption-settings/redeem-partner/redeem-partner-view'
import { ParameterSettingsService } from 'src/app/services/param-settings/parameter-settings.service'
import { RedemptionSettingsService } from 'src/app/services/redemption-settings/redemption-settings.service'
import * as AppStore from 'src/app/store/'
import * as CommonAction from '../../common/common/common.actions'
import * as RedeemPartnerAction from './redeem-partner.actions'
import { Rounding } from 'src/app/models/redemption-settings/redeem-partner/redeem-partner-rounding-list'
import { NumberDecimal } from 'src/app/models/redemption-settings/redeem-partner/redeem-partner-number-decimal-list'
import { RewardPoolGroupViewResponse } from 'src/app/models/param-settings/reward-pool-group/reward-pool-group-view'

@Injectable()
export class Effects {

	constructor(
		private action$: Actions,
		private router: Router,
		private dialog: MatDialog,
		private redemptionSettingsService: RedemptionSettingsService,
		private parameterSettingsService: ParameterSettingsService,
		private store: Store<AppStore.State>
	) { }

	InitialState$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RedeemPartnerAction.InitialState),
			tap(() => {
				this.router.navigate(['redemption-settings/redeem-partner'])
			})
		), { dispatch: false }
	)

	RestError$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RedeemPartnerAction.RestError),
			map(({ message, manualThrow }) => {
				return CommonAction.RestError({ message, manualThrow })
			})
		)
	)

	GoList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RedeemPartnerAction.GoList),
			tap(() => {
				this.router.navigate(['redemption-settings/redeem-partner'])
			})
		), { dispatch: false }
	)

	GoCreate$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RedeemPartnerAction.GoCreate),
			tap(() => {
				this.router.navigate(['redemption-settings/redeem-partner/create'])
			})
		), { dispatch: false }
	)

	GoView$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RedeemPartnerAction.GoView),
			tap(() => {
				this.router.navigate(['redemption-settings/redeem-partner/view'])
			})
		), { dispatch: false }
	)

	GoUpdate$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RedeemPartnerAction.GoUpdate),
			tap(() => {
				this.router.navigate(['redemption-settings/redeem-partner/edit'])
			})
		), { dispatch: false }
	)

	List$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RedeemPartnerAction.List),
			withLatestFrom(this.store.select(state => state.redeemPartner)),
			mergeMap(() => {
				return this.redemptionSettingsService.getRedeemPartnerList().pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: RedeemPartner[] = response.payload
					if (success) {
						return RedeemPartnerAction.ListSuccess({ payload })
					} else { return RedeemPartnerAction.RestError(response as any) }
				}),
					catchError((error) => {
						return (of(RedeemPartnerAction.RestError({ message: error.message, manualThrow: true })))
					})
				)
			})
		)
	)

	CreateDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RedeemPartnerAction.CreateDialog),
			mergeMap(() => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.CREATE_REDEEM_PARTNER' }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return RedeemPartnerAction.Create()
				} else {
					return RedeemPartnerAction.closeDialog()
				}
			})
		)
	)

	Create$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RedeemPartnerAction.Create),
			withLatestFrom(this.store.select(state => state.redeemPartner)),
			mergeMap(([, redeemPartner]) => {
				const redeemPartnerDetailForm = redeemPartner.redeemPartnerDetailForm.value
				return this.redemptionSettingsService.createRedeemPartner({
					code: redeemPartnerDetailForm.code,
					name: redeemPartnerDetailForm.name,
					partnerAuthorizationUrl: redeemPartnerDetailForm.partnerAuthorizationUrl
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: string = response.payload
					if (success) {
						return RedeemPartnerAction.CreateSuccess({ payload })
					} else {
						return RedeemPartnerAction.RestError(response as any)
					}
				}), catchError(this.catcher))
			})
		)
	)

	CreateSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RedeemPartnerAction.CreateSuccess),
			mergeMap(({ payload }) => {
				return [RedeemPartnerAction.GoList(), CommonAction.RestError({ message: payload })]
			})
		)
	)

	View$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RedeemPartnerAction.View),
			withLatestFrom(this.store.select(state => state.redeemPartner)),
			mergeMap(([, redeemPartner]) => this.redemptionSettingsService.viewRedeemPartner({ id: redeemPartner.id } as RedeemPartnerViewResponse)
				.pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: RedeemPartnerViewResponse = response.payload
					if (success) {
						return RedeemPartnerAction.ViewSuccess({ payload })
					} else { return RedeemPartnerAction.RestError(response as any) }
				}), catchError(this.catcher))
			)
		)
	)

	UpdateView$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RedeemPartnerAction.UpdateView),
			withLatestFrom(this.store.select(state => state.redeemPartner)),
			mergeMap(([, redeemPartner]) => this.redemptionSettingsService.viewRedeemPartner({ id: redeemPartner.id } as RedeemPartnerViewResponse)
				.pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: RedeemPartnerViewResponse = response.payload
					if (success) {
						return RedeemPartnerAction.UpdateViewSuccess({ payload })
					} else {
						return RedeemPartnerAction.RestError(response as any)
					}
				}), catchError(this.catcher))
			)
		)
	)

	GoBackDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RedeemPartnerAction.GoBackDialog),
			mergeMap(({ action }) => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.BACK', payload: action }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					if (result.payload === 'LIST') {
						return RedeemPartnerAction.GoList()
					}
					if (result.payload === 'VIEW') {
						return RedeemPartnerAction.GoView()
					}
				} else {
					return RedeemPartnerAction.closeDialog()
				}
			})
		)
	)

	GLOptionListView$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RedeemPartnerAction.GLOptionListView),
			mergeMap(() => {
				return this.redemptionSettingsService.getGlRedemptionFromRedeemPartner({ action: 'view' }).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: GlRedemptionListResponse = response.payload
					if (success) {
						return RedeemPartnerAction.GLOptionListViewSuccess({ payload })
					} else { return RedeemPartnerAction.RestError(response as any) }
				}), catchError(this.catcher))
			})
		)
	)

	GLOptionListUpdate$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RedeemPartnerAction.GLOptionListUpdate),
			mergeMap(() => {
				return this.redemptionSettingsService.getGlRedemptionFromRedeemPartner({ action: 'update' }).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: GlRedemptionListResponse = response.payload
					if (success) {
						return RedeemPartnerAction.GLOptionListUpdateSuccess({ payload })
					} else { return RedeemPartnerAction.RestError(response as any) }
				}), catchError(this.catcher))
			})
		)
	)

	RewardOptionListView$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RedeemPartnerAction.RewardOptionListView),
			mergeMap(() => {
				return this.redemptionSettingsService.getRewardList({ action: 'view' }).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: RedeemPartnerReward[] = response.payload
					if (success) {
						return RedeemPartnerAction.RewardOptionListViewSuccess({ payload })
					} else { return RedeemPartnerAction.RestError(response as any) }
				}), catchError(this.catcher))
			})
		)
	)

	RewardOptionListUpdate$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RedeemPartnerAction.RewardOptionListUpdate),
			mergeMap(() => {
				return this.redemptionSettingsService.getRewardList({ action: 'update' }).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: RedeemPartnerReward[] = response.payload
					if (success) {
						return RedeemPartnerAction.RewardOptionListUpdateSuccess({ payload })
					} else { return RedeemPartnerAction.RestError(response as any) }
				}), catchError(this.catcher))
			})
		)
	)

	UpdateDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RedeemPartnerAction.UpdateDialog),
			mergeMap(() => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.UPDATE' }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return RedeemPartnerAction.Update()
				} else {
					return RedeemPartnerAction.closeDialog()
				}
			})
		)
	)

	Update$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RedeemPartnerAction.Update),
			withLatestFrom(this.store.select(state => state.redeemPartner)),
			mergeMap(([, redeemPartner]) => {
				const redeemPartnerDetailForm = redeemPartner.redeemPartnerDetailForm.value
				return this.redemptionSettingsService.editRedeemPartner(Number(redeemPartner.id), {
					name: redeemPartnerDetailForm.name,
					partnerAuthorizationUrl: redeemPartnerDetailForm.partnerAuthorizationUrl
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: string = response.payload
					if (success) {
						return RedeemPartnerAction.UpdateSuccess({ payload })
					} else {
						return RedeemPartnerAction.RestError(response as any)
					}
				}), catchError(this.catcher))
			})
		)
	)

	UpdateSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RedeemPartnerAction.UpdateSuccess),
			mergeMap(({ payload }) => {
				return [RedeemPartnerAction.GoView(), CommonAction.RestError({ message: payload })]
			})
		)
	)

	UpdatePointsConversionDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RedeemPartnerAction.UpdatePointsConversionDialog),
			mergeMap(() => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.UPDATE' }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return RedeemPartnerAction.UpdatePointsConversion()
				} else {
					return RedeemPartnerAction.closeDialog()
				}
			})
		)
	)

	UpdatePointsConversion$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RedeemPartnerAction.UpdatePointsConversion),
			withLatestFrom(this.store.select(state => state.redeemPartner)),
			mergeMap(([, redeemPartner]) => {

				const form = redeemPartner.redeemPartnerDetailForm.controls
				const payload: RedeemPartnerUpdatePointsConversionRequest = {
					id: form.id.value,
					document: redeemPartner.redeemPartnerDetailList.map(x => ({
						id: Number(x.id),
						name: x.name,
						type: x.type,
						point: Number(x.point),
						value: Number(x.value),
						redemptionRate: Number(x.redemptionRate),
						glProductCode: x.glProductCode,
						rounding: x.rounding,
						roundingValue: x.roundingValue,
						numberDecimal: x.numberDecimal,
						numberDecimalValue: x.numberDecimalValue,
						rewardPoolAndGlRedemption: x.rewardPoolAndGlRedemption,
						used: x.used
					}))
				}

				return this.redemptionSettingsService.editRedeemPartnerPointsConversion(payload)
					.pipe(map((response: Response) => {
						const success: boolean = response.success
						const respPayload: string = response.payload
						if (success) {
							return RedeemPartnerAction.UpdatePointsConversionSuccess({ payload: respPayload })
						} else { return RedeemPartnerAction.RestError(response as any) }
					}), catchError(this.catcher))
			})
		)
	)

	UpdatePointsConversionSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RedeemPartnerAction.UpdatePointsConversionSuccess),
			mergeMap(({ payload }) => {
				return [RedeemPartnerAction.UpdateView(), CommonAction.RestError({ message: payload })]
			})
		)
	)

	ConversionDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RedeemPartnerAction.ConversionDialog),
			mergeMap(() => {
				const dialogRef = this.dialog.open(PointConversionComponent, getDialogConfig({}))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (!result) {
					return RedeemPartnerAction.closeDialog()
				}
			})
		)
	)

	DeleteDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RedeemPartnerAction.DeleteDialog),
			mergeMap(() => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.DELETE_REDEEM_PARTNER' }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return RedeemPartnerAction.Delete()
				} else {
					return RedeemPartnerAction.closeDialog()
				}
			})
		)
	)

	Delete$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RedeemPartnerAction.Delete),
			withLatestFrom(this.store.select(state => state.redeemPartner)),
			mergeMap(([, redeemPartner]) => this.redemptionSettingsService.deleteRedeemPartner({
				id: redeemPartner.id
			}).pipe(map((response: Response) => {
				const success: boolean = response.success
				const payload: string = response.payload

				if (success) {
					return RedeemPartnerAction.DeleteSuccess({ payload })
				} else { return RedeemPartnerAction.RestError(response as any) }
			}), catchError(this.catcher)
			))
		)
	)

	DeleteSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RedeemPartnerAction.DeleteSuccess),
			mergeMap(({ payload }) => {
				return [RedeemPartnerAction.GoList(), CommonAction.RestError({ message: payload })]
			})
		)
	)
	DeleteConversionDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RedeemPartnerAction.DeleteConversionDialog),
			mergeMap(() => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({
					content: 'DIALOG.DELETE_POINT_CONVERSION'
				}))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return RedeemPartnerAction.DeleteConversion()
				} else {
					return RedeemPartnerAction.closeDialog()
				}
			})
		)
	)

	DeleteConversion$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RedeemPartnerAction.DeleteConversion),
			mergeMap(({  }) => {
				return [RedeemPartnerAction.UpdatePointsConversion()]
			})
		)
	)

	RoundingList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RedeemPartnerAction.RoundingList),
			withLatestFrom(this.store.select(state => state.redeemPartner)),
			mergeMap(([x, redeemPartner]) => {
				return this.redemptionSettingsService.getRoundingListFromRedeemPartner().pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: Rounding = response.payload
					if (success) {
						return RedeemPartnerAction.RoundingListSuccess({ payload })
					} else { return RedeemPartnerAction.RestError(response as any) }
				}), catchError(this.catcher))
			})
		)
	)

	NumberDecimalList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RedeemPartnerAction.NumberDecimalList),
			withLatestFrom(this.store.select(state => state.redeemPartner)),
			mergeMap(([x, redeemPartner]) => {
				return this.redemptionSettingsService.getNumberDecimalFromRedeemPartner().pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: NumberDecimal = response.payload
					if (success) {
						return RedeemPartnerAction.NumberDecimalListSuccess({ payload })
					} else { return RedeemPartnerAction.RestError(response as any) }
				}), catchError(this.catcher))
			})
		)
	)

	OnChangeRewardPoolGroup$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RedeemPartnerAction.OnChangeRewardPoolGroup),
			withLatestFrom(this.store.select(state => state.redeemPartner)),
			mergeMap(([x, redeemPartner]) => {
				const rewardPool = redeemPartner.redeemPartnerConversionForm.value.id
				let rewardPoolType = ''
				let rewardPoolId = ''
				if (rewardPool) {
					rewardPoolType = rewardPool.split('_')[0]
					rewardPoolId = rewardPool.split('_')[1]
				}
				return this.parameterSettingsService.viewRewardPoolGroup({ id: rewardPoolId }).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: RewardPoolGroupViewResponse = response.payload
					if (success) {
						return RedeemPartnerAction.OnChangeRewardPoolGroupSuccess({ payload })
					} else { return RedeemPartnerAction.RestError(response as any) }
				}), catchError(this.catcher))
			})
		)
	)

	catcher = (error) => {
		return (of(RedeemPartnerAction.RestError({ message: error.message, manualThrow: error.manualThrow })))
	}

}
