<form novalidate [ngrxFormState]="formState$ | async" fxLayout="column">
  <div *ngIf="isView" fxLayout="column">
    <mat-form-field fxFlex>
      <input matInput value="{{getIncludeExcludeValue((formState$ | async).controls.equation.value)}}" type="text"
        maxlength="255" readonly>
    </mat-form-field>

    <mat-form-field fxFlex>
      <input matInput value="{{(formState$ | async).controls.value.value}}" type="text" maxlength="255" readonly>
    </mat-form-field>

    <mat-form-field fxFlex>
      <mat-label>{{ 'CAMPAIGN.TC_GROUP' | translate }}</mat-label>
      <input matInput value="{{getTcGroupsValue((formState$ | async).controls.values.value)}}" type="text"
        maxlength="255" readonly>
    </mat-form-field>

    <div *ngIf="(formState$ | async).controls.isCheck.value" fxLayout="column">
      <span fxFlex>
        {{ 'CAMPAIGN.REVERSAL' | translate }}
      </span>

      <mat-form-field fxFlex>
        <input matInput value="{{getTcGroupsValue((formState$ | async).controls.reversalValues.value)}}" type="text"
          maxlength="255" readonly>
      </mat-form-field>
    </div>
  </div>

  <div *ngIf="!isView" fxLayout="column">
    <mat-radio-group [ngrxFormControlState]="(formState$ | async).controls.equation" color="primary" fxLayoutGap="8px">
      <mat-radio-button *ngFor="let i of includeExcludeList" [value]="i.key">
        {{i.value}}
      </mat-radio-button>
    </mat-radio-group>

    <mat-form-field fxFlex>
      <input matInput [ngrxFormControlState]="(formState$ | async)?.controls.value" type="text"
        onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57 || event.charCode == 44 || event.charCode == 45"
        maxlength="255">
      <mat-icon class="material-icons-outlined" matSuffix
        matTooltip="{{ 'CAMPAIGN.INFO.TRANSACTION_CODE' | translate }}">info
      </mat-icon>
      <mat-error *ngIf="(formState$ | async).errors._value">
        <app-error-validation [errors]="(formState$ | async).errors._value" [maxlength]="255">
        </app-error-validation>
      </mat-error>
    </mat-form-field>

    <mat-form-field fxFlex>
      <mat-label>{{ 'CAMPAIGN.TC_GROUP' | translate }}</mat-label>
	  <mat-select [ngrxFormControlState]="(formState$ | async).controls.values" multiple (closed)="clearSearchTCGroups()">
		<ngx-mat-select-search [formControl]="dropDownCtrlTCGroups" [placeholderLabel]="'COMMON.SEARCH'| translate"
			[noEntriesFoundLabel]="'COMMON.NOT_MATCHING_FOUND' | translate">
			<mat-icon ngxMatSelectSearchClear (click)="clearSearchTCGroups()">close</mat-icon>
		</ngx-mat-select-search>
		<mat-option *ngFor="let tc of filteredTCGroups | async" [value]="tc.key">
			{{tc.key}}{{' - '}}{{tc.value}}
		</mat-option>
	</mat-select>
      <mat-error *ngIf="(formState$ | async).errors._values">
        <app-error-validation [errors]="(formState$ | async).errors._values" [maxlength]="255">
        </app-error-validation>
      </mat-error>
    </mat-form-field>

    <mat-checkbox #checkbox [ngrxFormControlState]="(formState$ | async)?.controls.isCheck" color="primary"
      (change)="onChangeReversalTCGroup(checkbox.checked)">
      {{ 'CAMPAIGN.REVERSAL' | translate }}
    </mat-checkbox>

    <div *ngIf="(formState$ | async)?.controls.isCheck.value" fxLayout="column">
      <mat-form-field fxFlex>
        <mat-select [ngrxFormControlState]="(formState$ | async).controls.reversalValues" multiple (closed)="clearSearchTCGroupsReversal()">
          <ngx-mat-select-search [formControl]="dropDownCtrlTCGroupsReversal" [placeholderLabel]="'COMMON.SEARCH'| translate"
            [noEntriesFoundLabel]="'COMMON.NOT_MATCHING_FOUND' | translate">
            <mat-icon ngxMatSelectSearchClear (click)="clearSearchTCGroups()">close</mat-icon>
          </ngx-mat-select-search>
          <mat-option *ngFor="let i of filteredTCGroupsReversal | async" [value]="i.key">
            {{i.key}}{{' - '}}{{i.value}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="(formState$ | async).errors._reversalValues">
          <app-error-validation [errors]="(formState$ | async).errors._reversalValues" [maxlength]="255">
          </app-error-validation>
        </mat-error>
      </mat-form-field>
    </div>
  </div>
</form>
