import { KeyValue } from '@angular/common'
import { box, Boxed, createFormGroupState, FormGroupState, unbox, updateGroup, validate } from 'ngrx-forms'
import { maxLength, required } from 'ngrx-forms/validation'
import { GlRedemption } from 'src/app/models/param-settings/gl-redemption/gl-redemption'
import { OnTheSpotRedemption } from 'src/app/models/redemption-settings/on-the-spot-redemption/on-the-spot-redemption'
import { RewardPoolAndGroupOptions } from 'src/app/models/redemption-settings/on-the-spot-redemption/on-the-spot-redemption-reward-list'
import { OnTheSpotRedemptionViewResponse } from 'src/app/models/redemption-settings/on-the-spot-redemption/on-the-spot-redemption-view'
import { FieldValidator } from 'src/app/models/util/field.validator'

export interface State {
	isLoading: boolean
	id?: string
	action?: string
	onTheSpotRedemptionList: OnTheSpotRedemption[]
	onTheSpotRedemptionDetailForm: FormGroupState<OnTheSpotRedemptionDetailForm>
	rewardPoolAndGroupList: RewardPoolAndGroupOptions[]
	productTypeAndGroupList: KeyValue<string, string>[]
	productTypeGroupList: KeyValue<string, string>[]
	merchantGroupList: KeyValue<string, string>[]
	glRedemptionList: GlRedemption[]
	onTheSpotRedemptionView: OnTheSpotRedemptionDetailForm
	onTheSpotRedemptionDuplicateForm: FormGroupState<OnTheSpotRedemptionDuplicateForm>
	isDuplicateLoading: boolean
	isDuplicateDialog: boolean
	isEditing: boolean
}

export interface OTSRewardPools {
	byId: { [key: string]: OTSRewardPool }
	allIds: string[]
}

export interface OTSRewardPool {
	id: string
	form: FormGroupState<OTSRewardPoolForm>
}

export interface OnTheSpotRedemptionDuplicateForm {
	code: string
	name: string
	merchantIdRange: Boxed<string[]>
}

export const initialOnTheSpotRedemptionDuplicateValue: OnTheSpotRedemptionDuplicateForm = {
	code: '',
	name: '',
	merchantIdRange: box([])
}

export const initialOnTheSpotRedemptionDuplicateForm = createFormGroupState('onTheSpotRedemptionDuplicateForm', initialOnTheSpotRedemptionDuplicateValue)

export const validateOnTheSpotRedemptionDuplicateForm = (state: State) => updateGroup<OnTheSpotRedemptionDuplicateForm>({
	code: validate([required, maxLength(10), FieldValidator.alphaNumericWithoutSpaces()]),
	name: validate([required, maxLength(40), FieldValidator.checkValidCharacters()]),
	merchantIdRange: validate([value => { if (!unbox(state.onTheSpotRedemptionDetailForm.value.merchantIdRange.value).toString()) { return required(unbox(value)) } }])
})

export interface OnTheSpotRedemptionDetailForm {
	id: string
	code: string
	name: string
	glRedemptionId: number
	startDate: string
	endDate: string
	rewardPoolAndGroup: string
	rewardPoolId: number
	rewardPoolType: string
	otsRewardPools: OTSRewardPools
	onSpotCode: string
	productType: Boxed<string[]>
	productTypeGroup: Boxed<string[]>
	merchantPayout: string
	merchantIdRange: Boxed<string[]>
	redemptionLimit: string
	redemptionPoint: string
	amount: string
	createdBy: string
	createdDate: string
	modifiedBy: string
	modifiedDate: string
}

export const initialOnTheSpotRedemptionDetail: OnTheSpotRedemptionDetailForm = {
	id: '',
	code: '',
	name: '',
	glRedemptionId: null,
	startDate: '',
	endDate: '',
	rewardPoolAndGroup: '',
	rewardPoolId: null,
	rewardPoolType: '',
	otsRewardPools: {
		byId: {},
		allIds: []
	},
	onSpotCode: '',
	productType: box([]),
	productTypeGroup: box([]),
	merchantPayout: '',
	merchantIdRange: box([]),
	redemptionLimit: '',
	redemptionPoint: '',
	amount: '',
	createdBy: '',
	createdDate: '',
	modifiedBy: '',
	modifiedDate: ''
}

export const initialOnTheSpotRedemptionViewResponse: OnTheSpotRedemptionViewResponse = {
	id: '',
	code: '',
	name: '',
	glRedemptionId: null,
	startDate: '',
	endDate: '',
	rewardPoolId: null,
	rewardPoolType: '',
	rewardPool: [],
	onSpotCode: '',
	productType: '',
	productTypeGroup: '',
	merchantPayout: '',
	merchantIdRange: '',
	redemptionLimit: '',
	redemptionPoint: '',
	amount: '',
	createdBy: '',
	createdDate: '',
	modifiedBy: '',
	modifiedDate: ''
}

export const initialOnTheSpotRedemptionDetailForm = createFormGroupState('onTheSpotRedemptionDetailForm', initialOnTheSpotRedemptionDetail)

export const validateOnTheSpotRedemptionDetailForm = (state: State) => updateGroup<OnTheSpotRedemptionDetailForm>({
	code: validate([required, maxLength(10), FieldValidator.alphaNumericWithoutSpaces()]),
	name: validate([required, maxLength(40), FieldValidator.checkValidCharacters()]),
	startDate: validate([required]),
	endDate: validate([required]),
	rewardPoolAndGroup: validate([required]),
	glRedemptionId: validate([FieldValidator.checkRewardPoolType(state.onTheSpotRedemptionDetailForm.value.rewardPoolAndGroup && state.onTheSpotRedemptionDetailForm.value.rewardPoolAndGroup.split('_')[0])]),
	onSpotCode: validate([required, maxLength(2), FieldValidator.checkNumeric()]),
	productType: validate([value => { if (!unbox(state.onTheSpotRedemptionDetailForm.value.productType.value).toString()) { return required(unbox(value)) } }]),
	productTypeGroup: validate([value => { if (!unbox(state.onTheSpotRedemptionDetailForm.value.productTypeGroup.value).toString()) { return required(unbox(value)) } }]),
	merchantPayout: validate([required, maxLength(6), FieldValidator.percentageRange(), FieldValidator.integerAndDecimalCheck(3, 2)]),
	merchantIdRange: validate([value => { if (!unbox(state.onTheSpotRedemptionDetailForm.value.merchantIdRange.value).toString()) { return required(unbox(value)) } }]),
	redemptionLimit: validate([required, maxLength(6), FieldValidator.percentageRange(), FieldValidator.integerAndDecimalCheck(3, 2)]),
	redemptionPoint: validate([required, maxLength(10), FieldValidator.numberWithoutDecimal(), FieldValidator.NotStartWithZero()]),
	amount: validate([required, maxLength(13), FieldValidator.integerAndDecimalCheck(10, 2, true)])
})

export interface OTSRewardPoolForm {
	rewardPoolId: number
	rewardPoolName: string
	glRedemptionId: number
}

export const initialOTSRewardPoolFormValue: OTSRewardPoolForm = {
	rewardPoolId: null,
	rewardPoolName: '',
	glRedemptionId: null
}

export const initialOTSRewardPoolForm = createFormGroupState('otsRewardPoolForm', initialOTSRewardPoolFormValue)

export const validateOTSRewardPoolForm = updateGroup<OTSRewardPoolForm>({
	glRedemptionId: validate([required])
})

export const initialState: State = {
	isLoading: false,
	onTheSpotRedemptionList: [],
	onTheSpotRedemptionDetailForm: initialOnTheSpotRedemptionDetailForm,
	rewardPoolAndGroupList: [],
	productTypeAndGroupList: [],
	productTypeGroupList: [],
	merchantGroupList: [],
	glRedemptionList: [],
	onTheSpotRedemptionView: initialOnTheSpotRedemptionDetail,
	onTheSpotRedemptionDuplicateForm: initialOnTheSpotRedemptionDuplicateForm,
	isDuplicateLoading: false,
	isDuplicateDialog: false,
	isEditing: false
}
