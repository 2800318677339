import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { ProductCatalogueRewardPool } from 'src/app/store/catalogue-management/product-catalogue/product-catalogue.state'
import { Observable, ReplaySubject, Subscription } from 'rxjs'
import { FormGroupState } from 'ngrx-forms'
import { GlRedemption } from 'src/app/models/param-settings/gl-redemption/gl-redemption'
import { Store } from '@ngrx/store'
import * as AppStore from 'src/app/store'
import * as ProductCatalogueSelectors from 'src/app/store/catalogue-management/product-catalogue/product-catalogue.selectors'
import { ProductCatalogueRewardPoolForm } from 'src/app/store/catalogue-management/product-catalogue'
import { FormControl } from '@angular/forms'
import { FilterPredicate } from 'src/app/models/util/filter-predicate'

@Component({
	selector: 'app-product-catalogue-reward-pool',
	templateUrl: './product-catalogue-reward-pool.component.html',
	styleUrls: ['./product-catalogue-reward-pool.component.scss']
})
export class ProductCatalogueRewardPoolComponent implements OnInit, OnDestroy {

	@Input() productCatalogueRewardPool: ProductCatalogueRewardPool

	action: string
	formState$: Observable<FormGroupState<ProductCatalogueRewardPoolForm>>
	glRedemptionViewList: GlRedemption[]
	subs: Subscription
	glRedemptionList: GlRedemption[]
	selectedValue: any = ''

	public filteredGlRedemption: ReplaySubject<GlRedemption[]> = new ReplaySubject<GlRedemption[]>(1)

	public dropDownCtrlGlRedemptionGroup: FormControl = new FormControl(this.selectedValue)

	constructor(
		private store: Store<AppStore.State>
	) {
		this.subs = this.store.select(ProductCatalogueSelectors.getProductCatalogue).subscribe(x => {
			this.action = x.action
			this.glRedemptionViewList = x.glRedemptionList

			const glRedemptionList = x.glRedemptionList

			if (glRedemptionList) {
				this.glRedemptionList = glRedemptionList
			}

			this.filteredGlRedemption.next(this.glRedemptionList.slice())
		})

	}

	get isView(): boolean { return this.action === 'VIEW' }

	ngOnInit() {
		this.formState$ = this.store.select(ProductCatalogueSelectors.getProductCatalogueRewardPool, this.productCatalogueRewardPool.id)
		this.dropDownCtrlGlRedemptionGroup.valueChanges
			.subscribe(value => {
				const maxChar = 255
				if (value.length > maxChar) {
					const newValue = value.slice(0, maxChar)
					this.dropDownCtrlGlRedemptionGroup.reset('', { emitEvent: false })
					this.dropDownCtrlGlRedemptionGroup.setValue(newValue)

				} else {
					this.searchGlRedemption(value)
				}
			})

	}

	ngOnDestroy() {
		this.subs.unsubscribe()
	}

	getGlRedemption(value: number): string {
		const result = this.glRedemptionViewList.find(x => x.id === value)
		return result ? (result.code + ' - ' + result.name) : ''
	}

	searchGlRedemption(search: string) {
		if (!this.glRedemptionList) {
			return
		}
		if (!search) {
			this.filteredGlRedemption.next(this.glRedemptionList.slice())
			return
		} else {
			search = search.toLowerCase()
		}
		// filter the records
		this.filteredGlRedemption.next(
			this.glRedemptionList.filter(glRedemption => FilterPredicate.isIdenticalTo(search)([glRedemption.code.toLowerCase() + ' - ' + glRedemption.name.toLowerCase()]))
		)
	}
	clearSearchGlRedemption() {
		this.filteredGlRedemption.next(this.glRedemptionList.slice())
	}
}
