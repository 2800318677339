import { Component, Inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core'
import { MAT_DIALOG_DATA, MatPaginator, MatTableDataSource } from '@angular/material'
import { Store } from '@ngrx/store'
import { Subscription } from 'rxjs'
import * as AppStore from 'src/app/store'
import * as CustomerManagementAction from 'src/app/store/customer-management/customer-management.selectors'
import { CustomerTransactionListDataTable } from 'src/app/models/customer-management/customer-account-detail'
import { CardTransactionListDataColumns } from 'src/app/models/customer-management/customer-card-detail'

@Component({
	selector: 'app-point-transaction-breakdown-table',
	templateUrl: './point-transaction-breakdown-table.component.html',
	styleUrls: ['./point-transaction-breakdown-table.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class PointTransactionBreakdownTableComponent implements OnInit, OnDestroy {

	dataSource: MatTableDataSource<CustomerTransactionListDataTable>
	@ViewChild('matPaginatorPointTransaction', { static: true }) paginator: MatPaginator
	subs: Subscription
	headerColumns: string[] = ['CUSTOMER_MANAGEMENT.POSTING_DATE', 'CUSTOMER_MANAGEMENT.DESCRIPTION', 'CUSTOMER_MANAGEMENT.TRANSACTION_DATE', 'CUSTOMER_MANAGEMENT.CAMPAIGN_NAME', 'CUSTOMER_MANAGEMENT.TRANSACTION_CODE', 'CUSTOMER_MANAGEMENT.AMOUNT', 'CUSTOMER_MANAGEMENT.POINTS']
	displayedColumns: CardTransactionListDataColumns[] = ['postingDate', 'description', 'transactionDate', 'campaignName', 'transactionCode', 'amount', 'point']
	isLoading = false
	cardSpendAmount: string
	totalSpendAmount: string

	formatTo2Decimal = new Intl.NumberFormat('en-US', {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2
	})

	constructor(
		private store: Store<AppStore.State>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		this.dataSource = new MatTableDataSource()
		this.subs = this.store.select(CustomerManagementAction.getCustomerManagement).subscribe(x => {
			this.isLoading = x.isLoading
			const response = x.customerTransactionList
			if (response) {
				this.dataSource.data = response.transactionList
					.map(o => ({ ...o, amount: this.formatTo2Decimal.format(o.amount || 0), point: this.formatTo2Decimal.format(o.point || 0) }))
				this.dataSource.paginator = this.paginator

				this.totalSpendAmount = this.formatTo2Decimal.format(response.spendAmountList
					.map(i => i.spendAmount)
					.reduce((a, b) => a + b, 0) || 0)

				this.cardSpendAmount = response.spendAmountList
					.map(i => `${i.cardNumber}: ${this.formatTo2Decimal.format(i.spendAmount || 0)}`)
					.join('\n')
			}
		})
	}

	ngOnInit() {
		this.dataSource.paginator = this.paginator
	}

	ngOnDestroy() {
		this.subs.unsubscribe()
	}
}
