import { KeyValue } from '@angular/common'
import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'
import { Boxed, FormGroupState } from 'ngrx-forms'
import { Observable, Subscription } from 'rxjs'
import * as AppStore from 'src/app/store'
import * as CampaignSelectors from 'src/app/store/campaign-management/campaign/campaign.selectors'
import { Ruleset, TargetCardForm } from 'src/app/store/campaign-management/campaign/campaign.state'

@Component({
	selector: 'app-target-card',
	templateUrl: './target-card.component.html',
	styleUrls: ['./target-card.component.scss']
})
export class TargetCardComponent implements OnInit, OnDestroy {

	@Input() ruleset: Ruleset

	formState$: Observable<FormGroupState<TargetCardForm>>
	action: string
	includeExcludeList: KeyValue<string, string>[] = []
	cardList: KeyValue<string, string>[] = []
	subs: Subscription

	constructor(
		private store: Store<AppStore.State>
	) {
		this.subs = this.store.select(CampaignSelectors.getCampaign).subscribe(({ campaignRulesetAction, rulesetData }) => {
			this.action = campaignRulesetAction
			this.includeExcludeList = rulesetData.includeExcludeList
			this.cardList = rulesetData.cardList
		})
	}

	get isView(): boolean { return this.action === 'VIEW' }

	ngOnInit() {
		this.formState$ = this.store.select(CampaignSelectors.getForm, this.ruleset.id)
	}

	ngOnDestroy() {
		this.subs.unsubscribe()
	}

	getIncludeExcludeValue(value: string): string {
		const result = this.includeExcludeList.find(x => x.key === value)
		return result ? result.value : ''
	}

	getCardValue(value: Boxed<string[]>): string {
		let resp = ''

		if (value && value.value) {
			value.value.forEach(x => {
				const result = this.cardList.find(y => x === y.key)
				if (result) {
					if (resp) { resp += ',' }
					resp += result.value
				}
			})
		}

		return resp
	}
}
