import { createAction, props } from '@ngrx/store'
import { RedeemRules } from 'src/app/models/redemption-settings/redeem-rules/redeem-rules'
import { RedeemRulesDataResponse } from 'src/app/models/redemption-settings/redeem-rules/redeem-rules-data'
import { RedeemRulesViewResponse } from 'src/app/models/redemption-settings/redeem-rules/redeem-rules-view'
import { POLICY } from 'src/app/models/common/constant'
import { Option } from 'src/app/models/option/option'

export const InitialState = createAction('[RedeemRules] InitialState')
export const RestError = createAction('[RedeemRules] RestError', props<{ message: string, manualThrow: boolean }>())

export const SetId = createAction('[RedeemRules] SetId', props<{ id: number }>())

export const GoList = createAction('[RedeemRules] GoList')
export const GoView = createAction('[RedeemRules] GoView')
export const GoCreate = createAction('[RedeemRules] GoCreate')
export const GoUpdate = createAction('[RedeemRules] GoUpdate')

export const GoBackDialog = createAction('[RedeemRules] GoBackDialog', props<{ action: string }>())
export const CreateDialog = createAction('[RedeemRules] CreateDialog')
export const UpdateDialog = createAction('[RedeemRules] UpdateDialog')
export const DeleteDialog = createAction('[RedeemRules] DeleteDialog')
export const CloseDialog = createAction('[RedeemRules] closeDialog')

export const List = createAction('[RedeemRules] List')
export const ListSuccess = createAction('[RedeemRules] ListSuccess', props<{ payload: RedeemRules[] }>())

export const View = createAction('[RedeemRules] View')
export const ViewSuccess = createAction('[RedeemRules] ViewSuccess', props<{ payload: RedeemRulesViewResponse }>())

export const Create = createAction('[RedeemRules] Create')
export const CreateSuccess = createAction('[RedeemRules] CreateSuccess', props<{ payload: string }>())

export const Update = createAction('[RedeemRules] Update')
export const UpdateSuccess = createAction('[RedeemRules] UpdateSuccess', props<{ payload: string }>())

export const Delete = createAction('[RedeemRules] Delete')
export const DeleteSuccess = createAction('[RedeemRules] DeleteSuccess')

export const GetRedeemRulesData = createAction('[RedeemRules] GetRedeemRulesData', props<{ payload: POLICY }>())
export const GetRedeemRulesSuccess = createAction('[RedeemRules] GetRedeemRulesSuccess', props<{ payload: RedeemRulesDataResponse }>())

export const GetRewardPoolGroupRewardPoolList = createAction('[RedeemRules] GetRewardPoolGroupRewardPoolList', props<{ params: string[] }>())
export const GetRewardPoolGroupRewardPoolListSuccess = createAction('[RedeemRules] GetRewardPoolGroupRewardPoolListSuccess', props<{ payload: Option[] }>())

export const SelectRule = createAction('[RedeemRules] SelectRule', props<{ payload: Option, params: Option, filteredRulesKey: string[] }>())
export const SelectRuleDialog = createAction('[RedeemRules] SelectRuleDialog', props<{ payload: Option }>())

export const RemoveRule = createAction('[RedeemRules] RemoveRule', props<{ payload: string, params: string }>())

export const OnChangeRewardPoolTypeRadio = createAction('[RedeemRules] OnChangeRewardPoolTypeRadio', props<{ payload: string }>())

export const OnChangeChannel = createAction('[RedeemRules] OnChangeChannel')
