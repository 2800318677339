import { createAction, props } from '@ngrx/store'
import { CampaignTasklist } from 'src/app/models/campaign-management/campaign/campaign'
import { CampaignDataResponse } from 'src/app/models/campaign-management/campaign/campaign-data'
import { CampaignDraftListResponse, CampaignPbDraftListResponse, CampaignPbPublishedListResponse, CampaignPbUnpublishedListResponse, CampaignPublishedListResponse, CampaignUnpublishedListResponse } from 'src/app/models/campaign-management/campaign/campaign-list'
import { CampaignViewResponse } from 'src/app/models/campaign-management/campaign/campaign-view'
import { RuleListResponse } from 'src/app/models/campaign-management/campaign/rule-list'
import { RulesetDataResponse } from 'src/app/models/campaign-management/campaign/ruleset-data'
import { PageRequest } from 'src/app/models/common/common'
import { POLICY } from 'src/app/models/common/constant'
import { Rule, RuleCondition, Ruleset } from './campaign.state'

export const InitialState = createAction('[Campaign] InitialState')
export const SetCode = createAction('[Campaign] SetCode', props<{ code: string }>())
export const SetKind = createAction('[Campaign] SetKind', props<{ kind: string }>())
export const SetCampaignRulesetId = createAction('[Campaign] SetCampaignRulesetId', props<{ campaignRulesetId: string }>())
export const SetCampaignTierId = createAction('[Campaign] SetCampaignTierId', props<{ campaignTierId: string }>())
export const SetRuleCondition = createAction('[Campaign] SetRuleCondition', props<{ ruleCondition: RuleCondition }>())
export const SetResourceType = createAction('[Campaign] SetResourceType')
export const SetPolicy = createAction('[Campaign] SetPolicy', props<{ policy: POLICY }>())
export const SetCheckerMakerId = createAction('[Campaign] SetCheckerMakerId', props<{ checkerMakerId: number }>())

export const GoList = createAction('[Campaign] GoList', props<{ tab: number }>())
export const GoCreate = createAction('[Campaign] GoCreate')
export const GoUpdate = createAction('[Campaign] GoUpdate')
export const GoRepublishedView = createAction('[Campaign] GoRepublishedView', props<{ action: string }>())
export const GoPublishedView = createAction('[Campaign] GoPublishedView', props<{ action: string }>())
export const GoPublishedUpdate = createAction('[Campaign] GoPublishedUpdate')
export const GoUnpublishedView = createAction('[Campaign] GoUnpublishedView')
export const GoCreateProductBundle = createAction('[Campaign] GoCreateProductBundle')
export const GoUpdateProductBundle = createAction('[Campaign] GoUpdateProductBundle')
export const GoSimulateProductBundle = createAction('[Campaign] GoSimulateProductBundle')
export const GoPublishedViewProductBundle = createAction('[Campaign] GoPublishedViewProductBundle', props<{ action: string }>())
export const GoRepublishedViewProductBundle = createAction('[Campaign] GoRepublishedViewProductBundle', props<{ action: string }>())
export const GoUnpublishedViewProductBundle = createAction('[Campaign] GoUnpublishedViewProductBundle')
export const GoTasklistCampaignRepublishedView = createAction('[Campaign] GoTasklistCampaignRepublishedView', props<{ action: string }>())
export const GoTasklistProductBundleRepublishedView = createAction('[Campaign] GoTasklistProductBundleRepublishedView', props<{ action: string }>())
export const GoMyRequestCampaignRepublishedView = createAction('[Campaign] GoMyRequestCampaignRepublishedView', props<{ action: string }>())
export const GoMyRequestProductBundleRepublishedView = createAction('[Campaign] GoMyRequestProductBundleRepublishedView', props<{ action: string }>())

export const GoBackDialog = createAction('[Campaign] GoBackDialog', props<{ action: string }>())
export const CreateDialog = createAction('[Campaign] CreateDialog')
export const UpdateDialog = createAction('[Campaign] UpdateDialog')
export const DeleteDialog = createAction('[Campaign] DeleteDialog', props<{ returnTab: number }>())
export const PublishDialog = createAction('[Campaign] PublishDialog')
export const RepublishDialog = createAction('[Campaign] RepublishDialog')
export const DuplicateDialog = createAction('[Campaign] DuplicateDialog')
export const DuplicateRulesetDialog = createAction('[Campaign] DuplicateRulesetDialog')
export const TerminateDialog = createAction('[Campaign] TerminateDialog')
export const RulesetDialog = createAction('[Campaign] RulesetDialog')
export const RemoveRulesetDialog = createAction('[Campaign] RemoveRulesetDialog', props<{ id: string }>())
export const NewRulesetDialog = createAction('[Campaign] NewRulesetDialog')
export const AddRulesetDialog = createAction('[Campaign] AddRulesetDialog')
export const AddRulesetChildDialog = createAction('[Campaign] AddRulesetChildDialog')
export const EnableConditionalRewardDialog = createAction('[Campaign] EnableConditionalRewardDialog')
export const CloseDialog = createAction('[Campaign] CloseDialog')

export const RestError = createAction('[Campaign] RestError', props<{ message: string, manualThrow: boolean }>())

export const SetPaginatorMetadata = createAction('[Campaign] SetPaginatorMetadata', props<{ payload: any }>())

export const List = createAction('[Campaign] List', props<{ params: PageRequest }>())
export const ListSuccess = createAction('[Campaign] ListSuccess', props<{ payload: CampaignPublishedListResponse }>())

export const DraftList = createAction('[Campaign] DraftList', props<{ params: PageRequest }>())
export const DraftListSuccess = createAction('[Campaign] DraftListSuccess', props<{ payload: CampaignDraftListResponse }>())

export const UnpublishedList = createAction('[Campaign] UnpublishedList', props<{ params: PageRequest }>())
export const UnpublishedListSuccess = createAction('[Campaign] UnpublishedListSuccess', props<{ payload: CampaignUnpublishedListResponse }>())

export const PbList = createAction('[Campaign] PbList', props<{ params: PageRequest }>())
export const PbListSuccess = createAction('[Campaign] PbListSuccess', props<{ payload: CampaignPbPublishedListResponse }>())

export const PbDraftList = createAction('[Campaign] PbDraftList', props<{ params: PageRequest }>())
export const PbDraftListSuccess = createAction('[Campaign] PbDraftListSuccess', props<{ payload: CampaignPbDraftListResponse }>())

export const PbUnpublishedList = createAction('[Campaign] PbUnpublishedList', props<{ params: PageRequest }>())
export const PbUnpublishedListSuccess = createAction('[Campaign] PbUnpublishedListSuccess', props<{ payload: CampaignPbUnpublishedListResponse }>())

export const View = createAction('[Campaign] View', props<{ payload: POLICY }>())
export const ViewSuccess = createAction('[Campaign] ViewSuccess', props<{ payload: CampaignViewResponse }>())

export const Create = createAction('[Campaign] Create')
export const CreateSuccess = createAction('[Campaign] CreateSuccess', props<{ code: string, payload: string }>())

export const Update = createAction('[Campaign] Update')
export const UpdateSuccess = createAction('[Campaign] UpdateSuccess', props<{ code: string, payload: string }>())

export const Delete = createAction('[Campaign] Delete', props<{ tab: number }>())
export const DeleteSuccess = createAction('[Campaign] DeleteSuccess', props<{ tab: number }>())

export const Publish = createAction('[Campaign] Publish')
export const PublishSuccess = createAction('[Campaign] PublishSuccess')

export const Republish = createAction('[Campaign] Republish')
export const RepublishSuccess = createAction('[Campaign] RepublishSuccess')

export const Terminate = createAction('[Campaign] Terminate')
export const TerminateSuccess = createAction('[Campaign] TerminateSuccess')

export const Duplicate = createAction('[Campaign] Duplicate', props<{ payload: POLICY }>())
export const DuplicateSuccess = createAction('[Campaign] DuplicateSuccess', props<{ payload: string }>())

export const DuplicateRuleset = createAction('[Campaign] DuplicateRuleset')
export const DuplicateRulesetSuccess = createAction('[Campaign] DuplicateRulesetSuccess', props<{ isDuplicate: boolean }>())

export const GetRuleList = createAction('[Campaign] GetRuleList', props<{ policy: POLICY, viewType?: string }>())
export const GetRuleListSuccess = createAction('[Campaign] GetRuleListSuccess', props<{ payload: RuleListResponse[] }>())

export const GetCampaignData = createAction('[Campaign] GetCampaignData', props<{ payload: POLICY }>())
export const GetCampaignDataSuccess = createAction('[Campaign] GetCampaignDataSuccess', props<{ payload: CampaignDataResponse }>())

export const ViewRepublishCampaign = createAction('[Campaign] ViewRepublishCampaign', props<{ policy: POLICY }>())
export const ViewRepublishCampaignSuccess = createAction('[Campaign] ViewRepublishCampaignSuccess', props<{ payload: CampaignTasklist }>())

export const OnChangeEnableConditionalRewardSucccess = createAction('[Campaign] OnChangeEnableConditionalRewardSucccess')
export const OnChangeEnableConditionalRewardFail = createAction('[Campaign] OnChangeEnableConditionalRewardFail')
export const OnChangeChannelGetRulesetList = createAction('[Campaign] OnChangeChannelGetRulesetList', props<{ resourceCode: string }>())
export const OnChangeChannelGetRulesetListSuccess = createAction('[Campaign] OnChangeChannelGetRulesetListSuccess', props<{ payload: RuleListResponse[] }>())
export const OnChangeChannelGetRulesetData = createAction('[Campaign] OnChangeChannelGetRulesetData', props<{ resourceCode: string }>())
export const OnChangeChannelGetRulesetDataSuccess = createAction('[Campaign] OnChangeChannelGetRulesetDataSuccess', props<{ payload: RulesetDataResponse }>())
export const OnChangeChannelValue = createAction('[Campaign] OnChangeChannelValue')
export const OnChangeCustomerCapType = createAction('[Campaign] OnChangeCustomerCapType')
export const OnChangeCapCycleType = createAction('[Campaign] OnChangeCapCycleType')
export const OnChangeRewardPool = createAction('[Campaign] OnChangeRewardPool')
export const OnChangeType = createAction('[Campaign] OnChangeType')
export const OnChangeRewardCreditMethod = createAction('[Campaign] OnChangeRewardCreditMethod')
export const OnChangeTransactionMethod = createAction('[Campaign] OnChangeTransactionMethod')
export const OnChangeRewardMethod = createAction('[Campaign] OnChangeRewardMethod')
export const OnChangeAccumulationCycleType = createAction('[Campaign] OnChangeAccumulationCycleType')
export const OnChangeRounding = createAction('[Campaign] OnChangeRounding')
export const OnChangeRewardLevel = createAction('[Campaign] OnChangeRewardLevelMethod')
export const OnChangeSelectedDay = createAction('[Campaign] OnChangeSelectedDay', props<{ ruleset: Ruleset, value: string }>())
export const OnChangeSelectedDayWeekly = createAction('[Campaign] OnChangeSelectedDayWeekly', props<{ ruleset: Ruleset, day: string, isCheck: boolean }>())
export const OnChangeBirthday = createAction('[Campaign] OnChangeBirthday', props<{ ruleset: Ruleset, value: string }>())
export const OnChangeXDaysBefore = createAction('[Campaign] OnChangeXDaysBefore', props<{ ruleset: Ruleset, value: string }>())
export const OnChangeXDaysAfter = createAction('[Campaign] OnChangeXDaysAfter', props<{ ruleset: Ruleset, value: string }>())
export const OnChangeReversalTCGroup = createAction('[Campaign] OnChangeReversalTCGroup', props<{ ruleset: Ruleset, isCheck: boolean }>())
export const OnChangeBillingCycleType = createAction('[Campaign] OnChangeBillingCycleType')
export const OnChangeAccumulationYearlyCycleType = createAction('[Campaign] OnChangeAccumulationYearlyCycleType')
export const OnChangeRulesetBillingCycleType = createAction('[Campaign] OnChangeRulesetBillingCycleType')
export const OnChangeCycleMonth = createAction('[Campaign] OnChangeCycleMonth')
export const OnChangeCapCycleMonth = createAction('[Campaign] OnChangeCapCycleMonth')

export const NewRuleset = createAction('[Campaign] NewRuleset')
export const AddRuleset = createAction('[Campaign] AddRuleset', props<{ payload: Rule }>())
export const AddGroup = createAction('[Campaign] AddGroup')
export const AddCondition = createAction('[Campaign] AddCondition', props<{ payload: Ruleset }>())
export const AddRulesetChild = createAction('[Campaign] AddRulesetChild', props<{ payload: Rule }>())

export const DeleteRuleset = createAction('[Campaign] DeleteRuleset', props<{ ruleset: Ruleset }>())
export const DeleteCondition = createAction('[Campaign] DeleteCondition', props<{ ruleCondition: RuleCondition }>())

export const SetupRuleset = createAction('[Campaign] SetupRuleset', props<{ id: string, action: string }>())
export const RulesetGetRuleList = createAction('[Campaign] RulesetGetRuleList')
export const RulesetGetRuleListSuccess = createAction('[Campaign] RulesetGetRuleListSuccess', props<{ payload: RuleListResponse[] }>())
export const RulesetGetRulesetData = createAction('[Campaign] RulesetGetRulesetData', props<{ resourceCode: string }>())
export const RulesetGetRulesetDataSuccess = createAction('[Campaign] RulesetGetRulesetDataSuccess', props<{ payload: RulesetDataResponse }>())

export const ViewRuleset = createAction('[Campaign] ViewRuleset', props<{ id: string }>())
export const UpdateRuleset = createAction('[Campaign] UpdateRuleset', props<{ id: string }>())
export const SaveRuleset = createAction('[Campaign] SaveRuleset')
export const RemoveRuleset = createAction('[Campaign] RemoveRuleset', props<{ id: string }>())

export const AddCampaignTier = createAction('[Campaign] AddCampaignTier')
export const DeleteCampaignTier = createAction('[Campaign] DeleteCampaignTier')

