<div>
    <mat-dialog-content class="min-w-410px">
        <app-spinner [isLoading]="isLoading"></app-spinner>

        <div class="layout-div" *ngIf="!isCataloguePartner">
            <div fxFlex>
                <form novalidate [ngrxFormState]="formState$ | async" fxLayout="column" fxFlex.lt-md="0">
                    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="5px" fxLayoutGap.lt-md="0px">
                        <span class="mat-h3">{{ 'CUSTOMER_MANAGEMENT.REDEMPTION_CONFIRMATION' | translate }}</span>
                    </div>

                    <div fxLayout="column" fxLayout.lt-md="column" fxLayoutGap.lt-md="0px">
                        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="5px" fxLayoutGap.lt-md="0px">
                            <mat-form-field class="rwd-mgb-sm-2 rwd-mgb-bg-1" fxFlex="50" *ngIf="redemptionType === eCatalogueRedemptionType.POINT_AMOUNT">
                                <mat-label>{{ 'CUSTOMER_MANAGEMENT.CARD_NUMBER' | translate }}</mat-label>
                                <input matInput value="{{cardNo}}" readonly>
                            </mat-form-field>

                            <mat-form-field class="rwd-mgb-sm-2 rwd-mgb-bg-1" fxFlex>
                                <mat-label>{{ 'CUSTOMER_MANAGEMENT.REWARD_POOL_OR_REWARD_POOL_GROUP' | translate }}
                                </mat-label>
                                <input matInput value="{{rewardPoolName}}" readonly>
                            </mat-form-field>
                        </div>

                        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="5px" fxLayoutGap.lt-md="0px">
                            <mat-form-field class="rwd-mgb-sm-2 rwd-mgb-bg-1" fxFlex="50">
                                <mat-label>{{ 'CUSTOMER_MANAGEMENT.PRODUCT' | translate }}</mat-label>
                                <input matInput value="{{productCatalogue.name}}" readonly>
                            </mat-form-field>

                            <mat-form-field class="rwd-mgb-sm-2 rwd-mgb-bg-1" fxFlex="50">
                                <mat-label>{{ 'CUSTOMER_MANAGEMENT.QUANTITY' | translate }}</mat-label>
                                <input matInput [ngrxFormControlState]="(formState$ | async).controls.quantity"
                                    onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57"
                                    type="text" readonly>
                            </mat-form-field>
                        </div>


                        <div *ngIf="productCatalogue.redemptionType === 'P'" fxLayout="row" fxLayout.lt-md="column"
                            fxLayoutGap="5px" fxLayoutGap.lt-md="0px">
                            <mat-form-field class="rwd-mgb-sm-2 rwd-mgb-bg-1 min-w-365px" fxFlex="100">
                                <mat-label>{{ 'CUSTOMER_MANAGEMENT.TOTAL_POINTS' | translate }}</mat-label>
                                <input matInput value="{{totalPoints}}" readonly>
                            </mat-form-field>
                        </div>

                        <div *ngIf="productCatalogue.redemptionType === 'PA'" fxLayout="row" fxLayout.lt-md="column"
                            fxLayoutGap="5px" fxLayoutGap.lt-md="0px">
                            <mat-form-field class="rwd-mgb-sm-2 rwd-mgb-bg-1" fxFlex="50">
                                <mat-label>{{ 'CUSTOMER_MANAGEMENT.TOTAL_POINTS' | translate }}</mat-label>
                                <input matInput value="{{totalPoints}}" readonly>
                            </mat-form-field>

                            <mat-form-field class="rwd-mgb-sm-2 rwd-mgb-bg-1" fxFlex="50">
                                <mat-label>{{ 'CUSTOMER_MANAGEMENT.TOTAL_AMOUNT' | translate }}</mat-label>
                                <input matInput value="{{totalAmount}}" readonly>
                            </mat-form-field>
                        </div>
                    </div>

                    <div *ngIf="!isVoucher">
                        <span class="mat-font-label">
                            {{ 'CUSTOMER_MANAGEMENT.ADDRESS' | translate }}
                        </span>
                        <div fxLayout="column" fxLayout.lt-md="column" fxLayoutGap.lt-md="0px">
                            <mat-expansion-panel class="mat-box-container" [expanded]="true" [hideToggle]="true"
                                class="max-w-365px">
                                <br />
                                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="5px" fxLayoutGap.lt-md="0px">
                                    <mat-form-field class="rwd-mgb-sm-2 rwd-mgb-bg-1 min-w-318px">
                                        <input matInput [ngrxFormControlState]="(formState$ | async).controls.address1"
                                            type="text" readonly [readonly]="!isOthers">
                                    </mat-form-field>
                                </div>
                                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="5px" fxLayoutGap.lt-md="0px">
                                    <mat-form-field class="rwd-mgb-sm-2 rwd-mgb-bg-1 min-w-318px">
                                        <input matInput [ngrxFormControlState]="(formState$ | async).controls.address2"
                                            type="text" readonly [readonly]="!isOthers">
                                    </mat-form-field>
                                </div>
                                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="5px" fxLayoutGap.lt-md="0px">
                                    <mat-form-field class="rwd-mgb-sm-2 rwd-mgb-bg-1 min-w-318px">
                                        <input matInput [ngrxFormControlState]="(formState$ | async).controls.address3"
                                            type="text" readonly [readonly]="!isOthers">
                                    </mat-form-field>
                                </div>
                                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="5px" fxLayoutGap.lt-md="0px">
                                    <mat-form-field class="rwd-mgb-sm-2 rwd-mgb-bg-1 min-w-156px">
                                        <mat-label>{{ 'CUSTOMER_MANAGEMENT.CITY' | translate }}</mat-label>
                                        <input matInput [ngrxFormControlState]="(formState$ | async).controls.city"
                                            type="text" [readonly]="!isOthers">
                                    </mat-form-field>

                                    <mat-form-field class="rwd-mgb-sm-2 rwd-mgb-bg-1 min-w-156px">
                                        <mat-label>{{ 'CUSTOMER_MANAGEMENT.STATE' | translate }}</mat-label>
                                        <input matInput [ngrxFormControlState]="(formState$ | async).controls.state"
                                            type="text" readonly [readonly]="!isOthers">
                                    </mat-form-field>
                                </div>

                                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="0px" fxLayoutGap.lt-md="0px">
                                    <mat-form-field class="rwd-mgb-sm-2 rwd-mgb-bg-1 min-w-318px">
                                        <mat-label>{{ 'CUSTOMER_MANAGEMENT.POSTCODE' | translate }}</mat-label>
                                        <input matInput [ngrxFormControlState]="(formState$ | async).controls.postcode"
                                            type="text" readonly [readonly]="!isOthers">
                                    </mat-form-field>
                                </div>
                            </mat-expansion-panel>
                        </div>
                    </div>

                    <div *ngIf="isVoucher">
                        <span class="radio-button-label">
                            {{ 'CUSTOMER_MANAGEMENT.EMAIL_ADDRESS' | translate }}
                        </span>
                        <br />
                        <div fxLayout="column" fxLayout.lt-md="column" fxLayoutGap.lt-md="0px">
                            <mat-expansion-panel class="mat-box-container" [expanded]="true" [hideToggle]="true">
                                <mat-form-field class="rwd-mgb-sm-2 rwd-mgb-bg-1" fxFlex="90">
                                    <input matInput [ngrxFormControlState]="(formState$ | async).controls.emailAddress"
                                        type="text" readonly [readonly]="!isOthers">
                                </mat-form-field>
                            </mat-expansion-panel>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <div *ngIf="isCataloguePartner">
            <div fxFlex>
                <form novalidate [ngrxFormState]="formState$ | async" fxLayout="column" fxFlex.lt-md="0">
                    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1%" fxLayoutGap.lt-md="0px">
                        <span class="mat-h3">{{ 'CUSTOMER_MANAGEMENT.REDEMPTION_CONFIRMATION' | translate }}</span>
                    </div>

                    <div fxLayout="column" fxLayout.lt-md="column" fxLayoutGap.lt-md="0px">
                        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1%" fxFlex="100"
                            fxLayoutGap.lt-md="0px">
                            <mat-form-field class="rwd-mgb-sm-2 rwd-mgb-bg-1" fxFlex="50">
                                <mat-label>{{ 'CUSTOMER_MANAGEMENT.CARD_NUMBER' | translate }}</mat-label>
                                <input matInput value="{{cardNo}}" readonly>
                            </mat-form-field>
                            <mat-form-field class="rwd-mgb-sm-2 rwd-mgb-bg-1" fxFlex="50">
                                <mat-label>{{ 'CUSTOMER_MANAGEMENT.REWARD_POOL_OR_REWARD_POOL_GROUP' | translate }}
                                </mat-label>
                                <input matInput value="{{rewardPoolName}}" readonly>
                            </mat-form-field>
                        </div>

                        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1%" fxFlex="100"
                            fxLayoutGap.lt-md="0px">
                            <mat-form-field class="rwd-mgb-sm-2 rwd-mgb-bg-1" fxFlex="50">
                                <mat-label>{{ 'CUSTOMER_MANAGEMENT.SMALL_LETTER_PARTNER' | translate }}</mat-label>
                                <input matInput value="{{productCatalogue.name}}" readonly>
                            </mat-form-field>
                            <mat-form-field class="rwd-mgb-sm-2 rwd-mgb-bg-1" fxFlex="50">
                                <mat-label>{{ 'CUSTOMER_MANAGEMENT.QUANTITY' | translate }}</mat-label>
                                <input matInput [ngrxFormControlState]="(formState$ | async).controls.quantity"
                                    type="text" readonly>
                            </mat-form-field>
                        </div>

                        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1%" fxLayoutGap.lt-md="0px">
                            <mat-form-field class="rwd-mgb-sm-2 rwd-mgb-bg-1" fxFlex="48" style="pointer-events:none;">
                                <mat-label>{{ 'CUSTOMER_MANAGEMENT.TOTAL_POINTS' | translate }}</mat-label>
                                <input appTwoDecimalPlaces matInput
                                    [ngrxFormControlState]="(formState$ | async).controls.totalPoint" readonly>
                                <span matSuffix>{{ 'CUSTOMER_MANAGEMENT.SUFFIX.POINTS' | translate }}</span>
                            </mat-form-field>
                            <label class="equal" fxFlex="4">
                                =
                            </label>
                            <mat-form-field class="rwd-mgb-sm-2 rwd-mgb-bg-1" fxFlex="48" style="pointer-events:none;">
                                <input appTwoDecimalPlaces matInput
                                    [ngrxFormControlState]="(formState$ | async).controls.value" readonly>
                                <span matSuffix>{{ 'CUSTOMER_MANAGEMENT.SUFFIX.PARTNER_POINTS' | translate }}</span>
                            </mat-form-field>
                        </div>

                        <div fxLayout="column" fxLayout.lt-md="column" fxLayoutGap="1%" fxLayoutGap.lt-md="0px">
                            <mat-form-field class="rwd-mgb-sm-2 rwd-mgb-bg-1">
                                <mat-label>{{ 'CUSTOMER_MANAGEMENT.PARTNER_ID' | translate }}</mat-label>
                                <input matInput [ngrxFormControlState]="(formState$ | async).controls.airmileId"
                                    readonly>
                            </mat-form-field>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <br>
        <div class="action-button-div" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="end">
            <button class="mat-raised-button" (click)="cancel()">{{ 'ACTION.CANCEL' | translate }}</button>
            <button type="button" mat-raised-button [disabled]="(formState$ | async).isInvalid" (click)="create()"
                color="primary">{{ 'ACTION.CONFIRM' | translate }}
            </button>
        </div>
    </mat-dialog-content>
</div>
