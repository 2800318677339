import { Action, createReducer, on } from '@ngrx/store'
import * as ActionbarWithSelectionAction from './action-bar-with-selection.actions'
import { initialState, State } from './action-bar-with-selection.state'
import * as DashboardAction from '../../dashboard/dashboard-overview'

export const reducer = createReducer(
	initialState,
	on(ActionbarWithSelectionAction.DashboardOverviewOptionList, (state, { payload }) => ({
		...state,
		selectionList: payload,
		resource: payload[0].key
	})),
	on(ActionbarWithSelectionAction.DashboardRewardPoolOverviewOptionList, (state, { payload }) => ({
		...state,
		selectionList: payload,
		resource: payload[0].key
	})),
)

export function Reducer(state: State = initialState, action: Action) {
	return reducer(state, action)
}
