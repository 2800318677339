import sha1 from 'sha1'
import * as uuid from 'uuid'

export class UUID {
	// create uuid immediate via class name
	// UUID.getUUID()
	static getUUID(): any {
		const id = uuid.v4()
		const uid = (sha1(id) + '').substring(0, 8)
		return uid
	}
}
