import { DatePipe, KeyValue } from '@angular/common'
import { Component, Inject, OnDestroy, OnInit } from '@angular/core'
import { DateAdapter, MAT_DATE_FORMATS, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material'
import { MomentDateAdapter } from '@angular/material-moment-adapter'
import { Store } from '@ngrx/store'
import { FormGroupState, NgrxValueConverters } from 'ngrx-forms'
import { Observable, ReplaySubject, Subscription } from 'rxjs'
import { CUSTOM_DATE_FORMATS } from 'src/app/models/util/date.adapter'
import * as AppStore from 'src/app/store'
import { SimulatorDetailForm } from 'src/app/store/campaign-management/simulator'
import * as SimulatorAction from 'src/app/store/campaign-management/simulator/simulator.actions'
import * as SimulatorSelector from 'src/app/store/campaign-management/simulator/simulator.selectors'
import { FormControl } from '@angular/forms'
import { FilterPredicate } from 'src/app/models/util/filter-predicate'

@Component({
	selector: 'app-simulator-create',
	templateUrl: './simulator-create.component.html',
	styleUrls: ['./simulator-create.component.scss'],
	providers: [
		DatePipe,
		{
			provide: DateAdapter, useClass: MomentDateAdapter
		},
		{
			provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS
		}
	]
})
export class SimulatorCreateComponent implements OnInit, OnDestroy {

	isLoading = false
	ticketType: string
	simulatorTarget: string
	resourceCode: string
	action: string
	formState$: Observable<FormGroupState<SimulatorDetailForm>>

	channelList: KeyValue<string, string>[] = []
	simulatorTargetList: KeyValue<string, string>[] = []
	conflictDataSimulatorTargetList: KeyValue<string, string>[] = []
	campaignList: KeyValue<string, string>[] = []
	conditionalRewardList: KeyValue<string, string>[] = []
	productBundlingList: KeyValue<string, string>[] = []
	subs: Subscription
	endDate = new Date()
	dateValueConverter = NgrxValueConverters.dateToISOString
	selectedValue: any = ''

	public filteredChannelList: ReplaySubject<KeyValue<string, string>[]> = new ReplaySubject<KeyValue<string, string>[]>(1)
	public dropDownCtrlChannelList: FormControl = new FormControl(this.selectedValue)

	constructor(
		private store: Store<AppStore.State>,
		private dialogRef: MatDialogRef<SimulatorCreateComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		this.subs = this.store.select(SimulatorSelector.getSimulator).subscribe(x => {
			this.isLoading = x.isTicketLoading

			if (x.isTicketDialog) {
				this.cancel()
			}

			this.ticketType = x.simulatorDetailForm.value.ticketType

			const keyData = x.simulatorKeyDataResponse.rawDataSimulatorKeyData
			const conflictKeyData = x.simulatorKeyDataResponse.conflictDataSimulatorKeyData
			const data = x.simulatorDataResponse
			if (data) {
				this.channelList = data.channelList
				this.simulatorTargetList = data.simulatorTargetList
				this.conflictDataSimulatorTargetList = data.conflictDataSimulatorTargetList
				this.filteredChannelList.next(this.channelList.slice())
			}

			if (this.ticketType === 'R') {
				if (keyData) {
					this.productBundlingList = keyData.productBundlingList
					this.campaignList = keyData.campaignList
					this.conditionalRewardList = keyData.conditionalRewardList
				}
			} else if (this.ticketType === 'C') {
				if (conflictKeyData) {
					this.campaignList = conflictKeyData.campaignList
					this.conditionalRewardList = conflictKeyData.conditionalRewardList
				}
			}

			this.simulatorTarget = x.simulatorDetailForm.value.simulatorTarget
			this.resourceCode = x.simulatorDetailForm.value.resourceCode

		})

		this.formState$ = this.store.select(({ simulator }) => simulator.simulatorDetailForm)
	}

	ngOnInit() {
		this.endDate.setDate(this.endDate.getDate() - 1)
		this.store.dispatch(SimulatorAction.GetSimulatorData())

		this.dropDownCtrlChannelList.valueChanges
			.subscribe(value => {
				const maxChar = 255
				if (value.length > maxChar) {
					const newValue = value.slice(0, maxChar)
					this.dropDownCtrlChannelList.reset('', { emitEvent: false })
					this.dropDownCtrlChannelList.setValue(newValue)

				} else {
					this.searchResource(value)
				}
			})

	}

	ngOnDestroy() {
		this.subs.unsubscribe()
	}

	cancel() {
		this.dialogRef.close()
	}

	submit() {
		this.store.dispatch(SimulatorAction.CreateDialog())
	}

	onChangeResourceCode() {
		this.store.dispatch(SimulatorAction.OnChangeResourceCode())
		if (this.simulatorTarget && this.resourceCode) {
			this.store.dispatch(SimulatorAction.GetSimulatorKeyData())
		}
	}

	onChangeSimulatorTarget() {
		this.store.dispatch(SimulatorAction.OnChangeSimulatorTarget())
		if (this.simulatorTarget === 'CR' || this.simulatorTarget === 'PB') {
			this.store.dispatch(SimulatorAction.GetSimulatorKeyData())
		}
	}

	searchResource(search: string) {
		if (!this.channelList) {
			return
		}
		if (!search) {
			this.filteredChannelList.next(this.channelList.slice())
			return
		} else {
			search = search.toLowerCase()
		}
		// filter the records
		this.filteredChannelList.next(
			this.channelList.filter(resource => FilterPredicate.isIdenticalTo(search)([resource.value]))
		)
	}
	clearSearchResource() {
		this.filteredChannelList.next(this.channelList.slice())
	}
}
