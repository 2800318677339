import { getGlobalDialogConfig } from '../common/dialog'

export const GlobalDialogBox = {
	matDialog: null,
	component: null,
	snackbarService: null,
	customVariable: {},
	appendVariable: (data = {}) => {
		GlobalDialogBox.customVariable = { ...GlobalDialogBox.customVariable, ...data }
	},
	dialogConfig: (o: {
		title?: string
		content: string
		contentParam?: string
		submitBtn?: string
		cancelBtn?: string
		payload?: any
	}) => getGlobalDialogConfig({
		content: '',
		payload: {},
		...o
	}),
	register: (matDialog, component) => {
		GlobalDialogBox.matDialog = matDialog
		GlobalDialogBox.component = component
	},
	registerSnakebar: (snackbarService) => {
		GlobalDialogBox.snackbarService = snackbarService
	}
}
