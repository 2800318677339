import { KeyValue } from '@angular/common'
import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'
import { Boxed, FormGroupState } from 'ngrx-forms'
import { Observable, ReplaySubject, Subscription } from 'rxjs'
import * as AppStore from 'src/app/store'
import * as CampaignSelectors from 'src/app/store/campaign-management/campaign/campaign.selectors'
import { MccForm, Ruleset } from 'src/app/store/campaign-management/campaign/campaign.state'
import { FormControl } from '@angular/forms'
import { FilterPredicate } from 'src/app/models/util/filter-predicate'

@Component({
	selector: 'app-mcc',
	templateUrl: './mcc.component.html',
	styleUrls: ['./mcc.component.scss']
})
export class MccComponent implements OnInit, OnDestroy {

	@Input() ruleset: Ruleset

	formState$: Observable<FormGroupState<MccForm>>
	action: string
	includeExcludeList: KeyValue<string, string>[] = []
	mccGroups: KeyValue<string, string>[] = []
	subs: Subscription

	public filteredMCC: ReplaySubject<KeyValue<string, string>[]> = new ReplaySubject<KeyValue<string, string>[]>(1)

	public dropDownCtrlMCC: FormControl = new FormControl()

	constructor(
		private store: Store<AppStore.State>
	) {
		this.subs = this.store.select(CampaignSelectors.getCampaign).subscribe(({ campaignRulesetAction, rulesetData }) => {
			this.action = campaignRulesetAction
			this.includeExcludeList = rulesetData.includeExcludeList
			this.mccGroups = rulesetData.mccGroups
		})
	}

	get isView(): boolean { return this.action === 'VIEW' }

	ngOnInit() {
		this.formState$ = this.store.select(CampaignSelectors.getForm, this.ruleset.id)
		this.filteredMCC.next(this.mccGroups.slice())

		this.dropDownCtrlMCC.valueChanges
			.subscribe(value => {
				const maxChar = 255
				if (value.length > maxChar) {
					const newValue = value.slice(0, maxChar)
					this.dropDownCtrlMCC.reset('', { emitEvent: false })
					this.dropDownCtrlMCC.setValue(newValue)

				} else {
					this.searchMCC(value)
				}
			})

	}

	ngOnDestroy() {
		this.subs.unsubscribe()
	}

	getIncludeExcludeValue(value: string): string {
		const result = this.includeExcludeList.find(x => x.key === value)
		return result ? result.value : ''
	}

	getMccGroupsValue(value: Boxed<string[]>): string {
		let resp = ''

		if (value && value.value) {
			value.value.forEach(x => {
				const result = this.mccGroups.find(y => x === y.key)
				if (result) {
					if (resp) { resp += ',' }
					resp += result.key + '-' + result.value
				}
			})
		}

		return resp
	}

	searchMCC(search: string) {
		if (!this.mccGroups) {
			return
		}
		if (!search) {
			this.filteredMCC.next(this.mccGroups.slice())
			return
		} else {
			search = search.toLowerCase()
		}
		// filter the records
		this.filteredMCC.next(
			this.mccGroups.filter(mcc => FilterPredicate.isIdenticalTo(search)([mcc.key.toLowerCase() + ' - ' + mcc.value.toLowerCase()]))
		)
	}

	clearSearchMCC() {
		this.filteredMCC.next(this.mccGroups.slice())
	}

}

