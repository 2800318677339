<mat-dialog-content>
  <div class="layout-div" *ngIf="data.indicatorList.length > 0">
    <span class="mat-h3">{{ 'FIELD_MAPPING.INDICATOR' | translate }}</span>
    <div fxLayout="row wrap" fxLayoutGap.xs="0">
      <div class="item" fxFlex="50%" *ngFor="let field of data.indicatorList">
        <mat-form-field>
          <mat-label>{{field.label}}</mat-label>
          <input matInput [(ngModel)]="field.value" readonly>
        </mat-form-field>
      </div>
    </div>
    <hr/>
  </div>

  <div class="layout-div" *ngIf="data.descriptionList.length > 0">
    <span class="mat-h3">{{ 'FIELD_MAPPING.DESCRIPTION' | translate }}</span>
    <div fxLayout="row wrap" fxLayoutGap.xs="0">
      <div class="item" fxFlex="50%" *ngFor="let field of data.descriptionList">
        <mat-form-field>
          <mat-label>{{field.label}}</mat-label>
          <input matInput [(ngModel)]="field.value" readonly>
        </mat-form-field>
      </div>
    </div>
    <hr/>
  </div>

  <div class="layout-div" *ngIf="data.balanceList.length > 0">
    <span class="mat-h3">{{ 'FIELD_MAPPING.BALANCE' | translate }}</span>
    <div fxLayout="row wrap" fxLayoutGap.xs="0">
      <div class="item" fxFlex="50%" *ngFor="let field of data.balanceList">
        <mat-form-field>
          <mat-label>{{field.label}}</mat-label>
          <input matInput [(ngModel)]="field.value" readonly>
        </mat-form-field>
      </div>
    </div>
    <hr/>
  </div>

  <div class="layout-div" *ngIf="data.dateList.length > 0">
    <span class="mat-h3">{{ 'FIELD_MAPPING.DATE' | translate }}</span>
    <div fxLayout="row wrap" fxLayoutGap.xs="0">
      <div class="item" fxFlex="50%" *ngFor="let field of data.dateList">
        <mat-form-field>
          <mat-label>{{field.label}}</mat-label>
          <input matInput [(ngModel)]="field.value" readonly>
        </mat-form-field>
      </div>
    </div>
    <hr/>
  </div>

  <div class="layout-div" *ngIf="data.timeList.length > 0">
    <span class="mat-h3">{{ 'FIELD_MAPPING.TIME' | translate }}</span>
    <div fxLayout="row wrap" fxLayoutGap.xs="0">
      <div class="item" fxFlex="50%" *ngFor="let field of data.timeList">
        <mat-form-field>
          <mat-label>{{field.label}}</mat-label>
          <input matInput [(ngModel)]="field.value" readonly>
        </mat-form-field>
      </div>
    </div>
    <hr/>
  </div>

  <div class="layout-div" *ngIf="data.amountList.length > 0">
    <span class="mat-h3">{{ 'FIELD_MAPPING.AMOUNT' | translate }}</span>
    <div fxLayout="row wrap" fxLayoutGap.xs="0">
      <div class="item" fxFlex="50%" *ngFor="let field of data.amountList">
        <mat-form-field>
          <mat-label>{{field.label}}</mat-label>
          <input matInput [(ngModel)]="field.value" readonly>
        </mat-form-field>
      </div>
    </div>
    <hr/>
  </div>
</mat-dialog-content>

<div>
  <button class="mat-raised-button" (click)="cancel()">{{ 'ACTION.CANCEL' | translate }}</button>
</div>