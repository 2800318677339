import { createFormGroupState, FormGroupState, updateGroup, validate } from 'ngrx-forms'
import { maxLength, required } from 'ngrx-forms/validation'

export interface State {
	isLoading: boolean
	loginForm: FormGroupState<LoginForm>
}

export interface LoginForm {
	username: string
	password: string
}

export const initialLoginFormValue: LoginForm = {
	username: '',
	password: ''
}

export const initialLoginForm = createFormGroupState('loginForm', initialLoginFormValue)

export const validateLoginForm = updateGroup<LoginForm>({
	username: validate(required, maxLength(255)),
	password: validate(required, maxLength(255))
})

export const initialState = {
	isLoading: false,
	loginForm: initialLoginForm
}
