import { Component, OnInit, Input } from '@angular/core'
import { SpinnerType } from 'src/app/models/common/constant'

@Component({
	selector: 'app-spinner',
	templateUrl: './spinner.component.html',
	styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {

	@Input() isLoading = false
	diameter = 50
	@Input() spinnerType: SpinnerType = 'APP'

	constructor() { }
	ngOnInit() { }
}
