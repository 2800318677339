import { NativeDateAdapter } from '@angular/material'
import { Injectable } from '@angular/core'

@Injectable({
	providedIn: 'root'
})
export class AppDateAdapter extends NativeDateAdapter {

	parse(value: any): Date | null {
		if ((typeof value === 'string') && (value.indexOf('/') > -1)) {
			const str = value.split('/')
			const year = Number(str[2])
			const month = Number(str[1]) - 1
			const date = Number(str[0])
			return new Date(year, month, date)
		}
		const timestamp = typeof value === 'number' ? value : Date.parse(value)
		return isNaN(timestamp) ? null : new Date(timestamp)
	}

	format(date: Date, displayFormat: string): string {
		if (displayFormat === 'input') {
			const day = date.getDate()
			const month = date.getMonth() + 1
			const year = date.getFullYear()
			return this.to2digit(day) + '/' + this.to2digit(month) + '/' + year
		} else if (displayFormat === 'inputMonth') {
			const month = date.getMonth() + 1
			const year = date.getFullYear()
			return this.to2digit(month) + '/' + year
		} else {
			return date.toDateString()
		}
	}

	private to2digit(n: number): string {
		return ('00' + n).slice(-2)
	}
}

export const APP_DATE_FORMATS = {
	parse: {
		dateInput: { month: 'short', year: 'numeric', day: 'numeric' }
	},
	display: {
		// dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
		dateInput: 'input',
		// monthYearLabel: { month: 'short', year: 'numeric', day: 'numeric' },
		monthYearLabel: 'inputMonth',
		dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
		monthYearA11yLabel: { year: 'numeric', month: 'long' },
	}
}

export const CUSTOM_DATE_FORMATS = {
	parse: {
		dateInput: 'DD-MM-YYYY',
	},
	display: {
		dateInput: 'DD-MM-YYYY',
		monthYearLabel: 'MM YYYY',
		dateA11yLabel: 'DD-MM-YYYY',
		monthYearA11yLabel: 'MM YYYY',
	},
}
