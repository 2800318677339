import { createFormGroupState, FormGroupState, updateGroup } from 'ngrx-forms'

export interface State {
	isLoading: boolean
	userProfileDetilForm: FormGroupState<UserProfileDetailForm>
}

export interface UserProfileDetailForm {
	userId: string
}

export const initialUserProfileDetailFormValue: UserProfileDetailForm = {
	userId: ''
}

export const initialUserProfileDetailForm = createFormGroupState('userProfileDetilForm', initialUserProfileDetailFormValue)

export const validateUserProfileDetailForm = updateGroup<UserProfileDetailForm>({})

export const initialState: State = {
	isLoading: false,
	userProfileDetilForm: initialUserProfileDetailForm
}
