import { Action, createReducer, on } from '@ngrx/store'
import * as NgrxForms from 'ngrx-forms'
import { formGroupReducer, markAsTouched, reset, setValue, SetValueAction } from 'ngrx-forms'
import * as SimulatorAction from './simulator.actions'
import * as SimulatorState from './simulator.state'

export const reducer = createReducer(
	SimulatorState.initialState,
	NgrxForms.onNgrxFormsAction(SetValueAction, (state, action) => {
		return actionHandler(state, action)
	}),
	NgrxForms.onNgrxFormsAction(NgrxForms.MarkAsTouchedAction, (state, action) => {
		return actionHandler(state, action)
	}),
	on(SimulatorAction.InitialState, () => SimulatorState.initialState),
	on(SimulatorAction.RestError, state => ({
		...state,
		isLoading: false
	})),
	on(SimulatorAction.SetId, (state, { id }) => ({
		...state,
		id
	})),
	on(SimulatorAction.SetSimulationQueue, (state, { isSimulationQueue }) => ({
		...state,
		isSimulationQueue
	})),
	on(SimulatorAction.RawDataSimulatorDialog, state => ({
		...state,
		isTicketLoading: false,
		isTicketDialog: false,
		simulatorDetailForm: reset(setValue(state.simulatorDetailForm, {
			...SimulatorState.initialSimulatorDetailFormValue,
			ticketType: 'R'
		}))
	})),
	on(SimulatorAction.ConflictDataSimulatorDialog, state => ({
		...state,
		isTicketLoading: false,
		isTicketDialog: false,
		simulatorDetailForm: reset(setValue(state.simulatorDetailForm, {
			...SimulatorState.initialSimulatorDetailFormValue,
			ticketType: 'C'
		}))
	})),
	on(SimulatorAction.List, state => ({
		...state,
		isLoading: true
	})),
	on(SimulatorAction.ListSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		ticketSequences: payload
	})),
	on(SimulatorAction.SimulationResultList, state => ({
		...state,
		isLoading: true
	})),
	on(SimulatorAction.SimulationResultListSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		simulationResults: payload
	})),
	on(SimulatorAction.View, state => ({
		...state,
		isLoading: true,
		simulatorDetailForm: reset(setValue(state.simulatorDetailForm, SimulatorState.initialSimulatorDetailFormValue))
	})),
	on(SimulatorAction.ViewSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		simulatorViewResponse: payload,
	})),
	on(SimulatorAction.Create, state => ({
		...state,
		isTicketLoading: true
	})),
	on(SimulatorAction.CreateSuccess, state => ({
		...state,
		isTicketLoading: false,
		isTicketDialog: true
	})),
	on(SimulatorAction.Delete, state => ({
		...state,
		isLoading: true
	})),
	on(SimulatorAction.DeleteSuccess, () => SimulatorState.initialState),
	on(SimulatorAction.Cancel, state => ({
		...state,
		isLoading: true
	})),
	on(SimulatorAction.CancelSuccess, () => SimulatorState.initialState),
	on(SimulatorAction.GetSimulatorData, state => ({
		...state,
		isTicketLoading: true
	})),
	on(SimulatorAction.GetSimulatorDataSuccess, (state, { payload }) => ({
		...state,
		isTicketLoading: false,
		simulatorDataResponse: payload
	})),
	on(SimulatorAction.GetSimulatorKeyData, state => ({
		...state,
		isTicketLoading: true
	})),
	on(SimulatorAction.GetSimulatorKeyDataSuccess, (state, { payload }) => ({
		...state,
		isTicketLoading: false,
		simulatorKeyDataResponse: payload
	})),
	on(SimulatorAction.OnChangeSimulatorTarget, state => ({
		...state,
		simulatorDetailForm: setValue(state.simulatorDetailForm, {
			...state.simulatorDetailForm.value,
			resourceCode: '',
			key: ''
		})
	})),
	on(SimulatorAction.OnChangeResourceCode, state => ({
		...state,
		simulatorDetailForm: setValue(state.simulatorDetailForm, {
			...state.simulatorDetailForm.value,
			key: ''
		})
	})),
)

export function Reducer(state: SimulatorState.State = SimulatorState.initialState, action: Action) {
	const simulatorDetailForm = SimulatorState.validateSimulatorDetailForm(state)(formGroupReducer(state.simulatorDetailForm, action))
	if (simulatorDetailForm !== state.simulatorDetailForm) {
		state = { ...state, simulatorDetailForm }
	}

	return reducer(state, action)
}

function validateForm(form: any) {
	form = markAsTouched(form)

	return SimulatorState.validateSimulatorDetailForm(form)
}

function actionHandler(state: SimulatorState.State, action: SetValueAction<unknown> | NgrxForms.MarkAsTouchedAction) {
	const formControls = state.simulatorDetailForm

	let form = formGroupReducer(formControls, action)

	if (action.type === 'ngrx/forms/SET_VALUE') {
		form = formGroupReducer(formControls, new SetValueAction(action.controlId, action.value))
	}

	if (form) {
		form = validateForm(state)(form)

		state = {
			...state,
			simulatorDetailForm: form
		}
	}
	return state
}
