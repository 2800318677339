import { createFeatureSelector, createSelector } from '@ngrx/store'
import * as RedeemPartner from './redeem-partner.state'

const redeemPartner = createFeatureSelector<RedeemPartner.State>('redeemPartner')

export const getRedeemPartner = createSelector(redeemPartner, x => x)

export const getRedeemPartnerRewardPools = createSelector(redeemPartner, ({ redeemPartnerConversionForm }): RedeemPartner.RedeemPartnerRewardPool[] => {
	let result = []
	const list = redeemPartnerConversionForm.controls.redeemPartnerRewardPools.value

	if (list && list.allIds) {
		result = list.allIds.map(id => list.byId[id])
	}
	return result
})

export const getRedeemPartnerRewardPool = createSelector(redeemPartner, ({ redeemPartnerConversionForm }, id: string) => {
	const redeemPartnerRewardPools = redeemPartnerConversionForm.controls.redeemPartnerRewardPools.value

	const form: RedeemPartner.RedeemPartnerRewardPools = {
		byId: {
			...redeemPartnerRewardPools.byId
		},
		allIds: {
			...redeemPartnerRewardPools.allIds
		}
	}

	return form && form.byId[id] && form.byId[id].form
})
