import { HttpClient, HttpResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, Subscriber } from 'rxjs'
import { timeout } from 'rxjs/operators'
import { Request, Response, HttpInfo } from 'src/app/models/common/http'
import { environment } from 'src/environments/environment'
import { BrowserStorage } from 'src/app/models/util/browser-storage'
import { LoginResponse } from 'src/app/models/authentication/login'
import { Redirector } from 'src/app/models/util/redirector'
import { MatDialog } from '@angular/material'
import { GlobalDialogBox } from 'src/app/models/util/global-dialog'

@Injectable({
	providedIn: 'root'
})
export class HttpService {

	constructor(
		private httpClient: HttpClient,
	) { }

	private readonly DEFAULT_TIMEOUT: number = 120 * 1000 // 1 second = 1000

	httpStatus = {
		checkStatusAndOkExist: (response) => {
			const keys = Object.keys(response)
			return !!(keys.includes('status') && keys.includes('ok'))
		},
		globalErrorStatusHandler: (response, subscriber, isGlobalThrow = true) => {
			switch (response.status) {
				case 0: // Offline mode
					if (isGlobalThrow) {
						GlobalDialogBox.snackbarService.open(this.httpStatus.offlinePayload().message)
					}
					subscriber.error({ ...this.httpStatus.offlinePayload(), manualThrow: !isGlobalThrow })
					break
				default:
					const errorResponse = response.error
					if (isGlobalThrow && !!errorResponse.error) {
						GlobalDialogBox.snackbarService.open(`${errorResponse.error.message}`)
					} else {
						// 	Currently only firefox will hit this issue
						if (!errorResponse.error && navigator.userAgent.search('Firefox')) {
							setTimeout(() => location.reload(), 100)
						}
					}
					subscriber.error({ ...errorResponse.error, manualThrow: !isGlobalThrow })
			}
		},
		checkIsOffline: (response): boolean => {
			const keys = Object.keys(response)
			if (keys.includes('status') && keys.includes('ok') && response.status === 0) {
				return true
			}
			return false
		},

		offlinePayload: () => (
			this.httpStatus.payload(
				false, '0', '[OFFLINE]', null, {
				code: '0', message: '[OFFLINE]'
			}
			)
		),
		payload: (success, code, message, payload, error): Response => ({
			success, code, message, payload, error
		})
	}

	getDefaultHeader() {
		const httpHeaders: {
			'Content-Type': string
			'Authorization'?: string
		} = {
			'Content-Type': 'application/json; charset=UTF-8',
		}

		const storageData = BrowserStorage.getStorageData()
		if (!!storageData) {
			const token = storageData.payload.accessToken
			httpHeaders.Authorization = `Bearer ${token}`
		}

		return httpHeaders
	}

	getHttpOptions(reqHeaders = {}): object {
		return {
			headers: { ...this.getDefaultHeader(), ...reqHeaders },
			// observe: 'response'
		}
	}

	get(request: Request, optional): Observable<Response> {
		const serviceUrl = request.serviceUrl || ''
		const reqHeaders = request.headers || {}
		const reqBody = request.body || {}
		const httpInfo: HttpInfo = {
			method: request.method || 'GET',
			serviceUrl,
			header: JSON.stringify(this.getHttpOptions(reqHeaders)),
			body: JSON.stringify(reqBody),
		}

		const observable = new Observable<Response>(subscriber => {
			const callback = this.handleHttpResponse(httpInfo, optional, subscriber)
			this.httpClient.get(
				serviceUrl,
				this.getHttpOptions(reqHeaders)
			).pipe(timeout(this.DEFAULT_TIMEOUT))
				.subscribe(callback.onSuccess, callback.onError)
		})

		return observable
	}

	post(request: Request, optional): Observable<Response> {
		const serviceUrl = request.serviceUrl || ''
		const reqHeaders = request.headers || {}
		const reqBody = request.body || {}
		const httpInfo: HttpInfo = {
			method: request.method || 'POST',
			serviceUrl,
			header: JSON.stringify(this.getHttpOptions(reqHeaders)),
			body: JSON.stringify(reqBody),
		}

		const observable = new Observable<Response>(subscriber => {
			const callback = this.handleHttpResponse(httpInfo, optional, subscriber)
			this.httpClient.post(
				serviceUrl,
				reqBody,
				this.getHttpOptions(reqHeaders)
			).pipe(timeout(this.DEFAULT_TIMEOUT))
				.subscribe(callback.onSuccess, callback.onError)
		})
		return observable
	}

	put(request: Request, optional): Observable<Response> {
		const serviceUrl = request.serviceUrl || ''
		const reqHeaders = request.headers || {}
		const reqBody = request.body || {}
		const httpInfo: HttpInfo = {
			method: request.method || 'PUT',
			serviceUrl,
			header: JSON.stringify(this.getHttpOptions(reqHeaders)),
			body: JSON.stringify(reqBody),
		}

		const observable = new Observable<Response>(subscriber => {
			const callback = this.handleHttpResponse(httpInfo, optional, subscriber)
			this.httpClient.put(
				serviceUrl,
				reqBody,
				this.getHttpOptions(reqHeaders)
			).pipe(timeout(this.DEFAULT_TIMEOUT))
				.subscribe(callback.onSuccess, callback.onError)
		})
		return observable
	}

	patch(request: Request, optional): Observable<Response> {
		const serviceUrl = request.serviceUrl || ''
		const reqHeaders = request.headers || {}
		const reqBody = request.body || {}
		const httpInfo: HttpInfo = {
			method: request.method || 'PATCH',
			serviceUrl,
			header: JSON.stringify(this.getHttpOptions(reqHeaders)),
			body: JSON.stringify(reqBody),
		}

		const observable = new Observable<Response>(subscriber => {
			const callback = this.handleHttpResponse(httpInfo, optional, subscriber)
			this.httpClient.patch(
				serviceUrl,
				reqBody,
				this.getHttpOptions(reqHeaders)
			).pipe(timeout(this.DEFAULT_TIMEOUT))
				.subscribe(callback.onSuccess, callback.onError)
		})
		return observable
	}

	delete(request: Request, optional): Observable<Response> {
		const serviceUrl = request.serviceUrl || ''
		const reqHeaders = request.headers || {}
		const reqBody = request.body || {}
		const httpInfo: HttpInfo = {
			method: request.method || 'DELETE',
			serviceUrl,
			header: JSON.stringify(this.getHttpOptions(reqHeaders)),
			body: JSON.stringify(reqBody),
		}

		const observable = new Observable<Response>(subscriber => {
			const callback = this.handleHttpResponse(httpInfo, optional, subscriber)
			this.httpClient.delete(
				serviceUrl,
				this.getHttpOptions(reqHeaders)
			).pipe(timeout(this.DEFAULT_TIMEOUT))
				.subscribe(callback.onSuccess, callback.onError)
		})
		return observable
	}

	private handleHttpResponse(httpInfo: HttpInfo, optional = {}, subscriber: Subscriber<Response>) {
		const params = { globalException: true, ...optional }
		return {
			onSuccess: (response) => {
				const data = response
				if (!!params.globalException && typeof (data.payload) === 'string') {
					GlobalDialogBox.snackbarService.open(`${data.payload}`)
				}
				subscriber.next(data)
			},
			onError: (response) => {
				const data = response
				if (this.tokenChecker(response)) {
					this.tokenRefresher(response, httpInfo, optional, subscriber)
				} else if (this.httpStatus.checkStatusAndOkExist(response)) {
					this.httpStatus.globalErrorStatusHandler(response, subscriber, !!params.globalException)
				} else {
					subscriber.error(data)
				}

			}
		}
	}

	private tokenChecker(response): boolean {
		const body = response.error
		return !!(body.error && (body.error.code === '020001' || body.error.code === '020002'))
	}

	private tokenRefresher(response: Response, httpInfo: HttpInfo, optional, subscriber: Subscriber<Response>) {

		const code = response.error.error.code

		if (code === '020002') {
			const dialog: MatDialog = GlobalDialogBox.matDialog
			GlobalDialogBox.appendVariable({ showNo: false, onOk: Redirector.clearStorageAndRedirect })
			dialog.open(GlobalDialogBox.component, GlobalDialogBox.dialogConfig({ content: 'DIALOG.TOKEN_EXPIRED', submitBtn: 'ACTION.OK' }))
		} else {

			const storageData = BrowserStorage.getStorageData()
			if (!!storageData) {

				const refreshToken = storageData.payload.refreshToken

				const refreshTokenRequest: Request = {
					serviceUrl: `${environment.apiUrl}/oauth/refreshToken` + `?${new URLSearchParams({ refreshToken })}`,
					headers: { ...this.getHttpOptions() }
				}

				const callback = {
					onSuccess: (successResponse) => {
						const payload = successResponse.payload
						const tokens: LoginResponse = {
							accessToken: payload.access_token,
							refreshToken: payload.refresh_token,
							message: 'Successful login.',
							authorities: payload.authorities
						}

						BrowserStorage.replaceStorageData({ payload: tokens })

						this.recallHttpRequest(httpInfo, optional, {
							serviceUrl: httpInfo.serviceUrl,
							body: httpInfo.body,
							method: httpInfo.method
						}, subscriber)

					},
					onError: (errorResponse) => {
						subscriber.error(errorResponse)
					}
				}

				this.post(refreshTokenRequest, optional).subscribe(callback.onSuccess, callback.onError)
			}
		}
	}

	private recallHttpRequest(httpInfo, optional, previousRequest: Request, subscriber: Subscriber<Response>) {
		const callback = this.handleHttpResponse(httpInfo, optional, subscriber)
		const method = previousRequest.method.toLowerCase()
		this[method](previousRequest).subscribe(callback.onSuccess, callback.onError)
	}
}
