<mat-accordion>
  <mat-expansion-panel [ngClass]="'mat-box-container'" [expanded]="true" hideToggle>
    <mat-expansion-panel-header>
      <div fxLayout="row" fxLayoutAlign="space-between center" fxFlex>
        <span>{{ 'CAMPAIGN.TIER' | translate }} {{index+1}}</span>
        <mat-icon *ngIf="!isView" class="mat-icon-pointer material-icons-outlined" (click)="delete()">close</mat-icon>
      </div>
    </mat-expansion-panel-header>

    <form novalidate [ngrxFormState]="formState$ | async">

      <div *ngIf="isView" fxLayout="column">
        <div *ngIf="rewardMethod === 'RATIO'" fxLayout="row" fxFlex>
          <mat-form-field fxFlex="22">
            <span matPrefix>{{ 'CAMPAIGN.FROM_AMOUNT' | translate }} &nbsp;</span>
            <input matInput value="{{(formState$ | async).controls.conditionFactorFrom.value}}" type="text"
              maxlength="10" readonly>
          </mat-form-field>

          <h3 fxFlex="4" style="text-align: center;">-</h3>

          <mat-form-field fxFlex="22">
            <span matPrefix>{{ 'CAMPAIGN.TO_AMOUNT' | translate }} &nbsp;</span>
            <input matInput value="{{(formState$ | async).controls.conditionFactorTo.value}}" type="text" maxlength="10"
              readonly>
          </mat-form-field>

          <h3 fxFlex="4" style="text-align: center;">=</h3>

          <mat-form-field fxFlex="22">
            <span matPrefix>{{ 'CAMPAIGN.AMOUNT' | translate }} &nbsp;</span>
            <input matInput value="{{(formState$ | async).controls.valueFactor.value}}" type="text" maxlength="10"
              readonly>
          </mat-form-field>

          <h3 fxFlex="4" style="text-align: center;">-</h3>

          <mat-form-field fxFlex="22">
            <input matInput style="text-align:right" value="{{(formState$ | async).controls.pointFactor.value}}"
              type="text" maxlength="10" readonly>
            <span matSuffix>&nbsp; {{ 'CAMPAIGN.POINTS' | translate }}</span>
          </mat-form-field>
        </div>

        <div *ngIf="type === 'TPC' && rewardMethod === 'FIX'" fxLayout="row" fxFlex>
          <mat-form-field fxFlex="32">
            <span matPrefix>{{ 'CAMPAIGN.FROM_TRXN' | translate }} &nbsp;</span>
            <input matInput value="{{(formState$ | async).controls.conditionFactorFrom.value}}" type="text"
              maxlength="10" readonly>
          </mat-form-field>

          <h3 fxFlex="2" style="text-align: center;">-</h3>

          <mat-form-field fxFlex="32">
            <span matPrefix>{{ 'CAMPAIGN.TO_TRXN' | translate }} &nbsp;</span>
            <input matInput value="{{(formState$ | async).controls.conditionFactorTo.value}}" type="text" maxlength="10"
              readonly>
          </mat-form-field>

          <h3 fxFlex="2" style="text-align: center;">=</h3>

          <mat-form-field fxFlex="32">
            <input matInput style="text-align:right" value="{{(formState$ | async).controls.pointFactor.value}}"
              type="text" maxlength="10" readonly>
            <span matSuffix>&nbsp; {{ 'CAMPAIGN.POINTS' | translate }}</span>
          </mat-form-field>
        </div>

        <div *ngIf="type !== 'TPC' && rewardMethod === 'FIX'" fxLayout="row" fxFlex>
          <mat-form-field fxFlex="22">
            <span matPrefix>{{ 'CAMPAIGN.FROM_AMOUNT' | translate }} &nbsp;</span>
            <input matInput value="{{(formState$ | async).controls.conditionFactorFrom.value}}" type="text"
              maxlength="10" readonly>
          </mat-form-field>

          <h3 fxFlex="4" style="text-align: center;">-</h3>

          <mat-form-field fxFlex="22">
            <span matPrefix>{{ 'CAMPAIGN.TO_AMOUNT' | translate }} &nbsp;</span>
            <input matInput value="{{(formState$ | async).controls.conditionFactorTo.value}}" type="text" maxlength="10"
              readonly>
          </mat-form-field>

          <h3 fxFlex="4" style="text-align: center;">=</h3>

          <mat-form-field fxFlex="48">
            <input matInput style="text-align:right" value="{{(formState$ | async).controls.pointFactor.value}}"
              type="text" maxlength="10" readonly>
            <span matSuffix>&nbsp; {{ 'CAMPAIGN.POINTS' | translate }}</span>
          </mat-form-field>
        </div>

      </div>

      <div *ngIf="!isView" fxLayout="column">

        <div *ngIf="rewardMethod === 'RATIO'" fxLayout="row" fxFlex>
          <mat-form-field fxFlex="22">
            <span matPrefix>{{ 'CAMPAIGN.FROM_AMOUNT' | translate }} &nbsp;</span>
            <input matInput [ngrxFormControlState]="(formState$ | async)?.controls.conditionFactorFrom" type="text"
              maxlength="10">
            <mat-error *ngIf="(formState$ | async).errors._conditionFactorFrom">
              <app-error-validation [errors]="(formState$ | async).errors._conditionFactorFrom" [maxlength]="10">
              </app-error-validation>
            </mat-error>
          </mat-form-field>

          <h3 fxFlex="4" style="text-align: center;">-</h3>

          <mat-form-field fxFlex="22">
            <span matPrefix>{{ 'CAMPAIGN.TO_AMOUNT' | translate }} &nbsp;</span>
            <input matInput [ngrxFormControlState]="(formState$ | async).controls.conditionFactorTo" type="text"
              maxlength="10">
            <mat-error *ngIf="(formState$ | async).errors._conditionFactorTo">
              <app-error-validation [errors]="(formState$ | async).errors._conditionFactorTo" [maxlength]="10">
              </app-error-validation>
            </mat-error>
          </mat-form-field>

          <h3 fxFlex="4" style="text-align: center;">=</h3>

          <mat-form-field fxFlex="22">
            <span matPrefix>{{ 'CAMPAIGN.AMOUNT' | translate }} &nbsp;</span>
            <input matInput [ngrxFormControlState]="(formState$ | async)?.controls.valueFactor" type="text"
              maxlength="10">
            <mat-error *ngIf="(formState$ | async).errors._valueFactor">
              <app-error-validation [errors]="(formState$ | async).errors._valueFactor" [maxlength]="10">
              </app-error-validation>
            </mat-error>
          </mat-form-field>

          <h3 fxFlex="4" style="text-align: center;">-</h3>

          <mat-form-field fxFlex="22">
            <input matInput style="text-align:right" [ngrxFormControlState]="(formState$ | async).controls.pointFactor"
              type="text" maxlength="15">
            <span matSuffix>&nbsp; {{ 'CAMPAIGN.POINTS' | translate }}</span>
            <mat-error *ngIf="(formState$ | async).errors._pointFactor">
              <app-error-validation [errors]="(formState$ | async).errors._pointFactor" [maxlength]="15">
              </app-error-validation>
            </mat-error>
          </mat-form-field>
        </div>

        <div *ngIf="type === 'TPC' && rewardMethod === 'FIX' && transactionMethod !=='SWIPE'" fxLayout="row" fxFlex>
          <mat-form-field fxFlex="22">
            <span matPrefix>{{ 'CAMPAIGN.FROM_AMOUNT' | translate }} &nbsp;</span>
            <input matInput [ngrxFormControlState]="(formState$ | async)?.controls.conditionFactorFrom" type="text"
              maxlength="10">
            <mat-error *ngIf="(formState$ | async).errors._conditionFactorFrom">
              <app-error-validation [errors]="(formState$ | async).errors._conditionFactorFrom" [maxlength]="10">
              </app-error-validation>
            </mat-error>
          </mat-form-field>

          <h3 fxFlex="4" style="text-align: center;">-</h3>

          <mat-form-field fxFlex="22">
            <span matPrefix>{{ 'CAMPAIGN.TO_AMOUNT' | translate }} &nbsp;</span>
            <input matInput [ngrxFormControlState]="(formState$ | async).controls.conditionFactorTo" type="text"
              maxlength="10">
            <mat-error *ngIf="(formState$ | async).errors._conditionFactorTo">
              <app-error-validation [errors]="(formState$ | async).errors._conditionFactorTo" [maxlength]="10">
              </app-error-validation>
            </mat-error>
          </mat-form-field>

          <h3 fxFlex="4" style="text-align: center;">=</h3>

          <mat-form-field fxFlex="48">
            <input matInput style="text-align:right" [ngrxFormControlState]="(formState$ | async).controls.pointFactor"
              type="text" maxlength="10">
            <span matSuffix>&nbsp; {{ 'CAMPAIGN.POINTS' | translate }}</span>
            <mat-error *ngIf="(formState$ | async).errors._pointFactor">
              <app-error-validation [errors]="(formState$ | async).errors._pointFactor" [maxlength]="10">
              </app-error-validation>
            </mat-error>
          </mat-form-field>
        </div>

        <div *ngIf="type === 'TPC' && rewardMethod === 'FIX' && transactionMethod ==='SWIPE'" fxLayout="row" fxFlex>
          <mat-form-field fxFlex="22">
            <span matPrefix>{{ 'CAMPAIGN.FROM_TRXN' | translate }} &nbsp;</span>
            <input matInput [ngrxFormControlState]="(formState$ | async)?.controls.conditionFactorFrom" type="text"
              maxlength="10">
            <mat-error *ngIf="(formState$ | async).errors._conditionFactorFrom">
              <app-error-validation [errors]="(formState$ | async).errors._conditionFactorFrom" [maxlength]="10">
              </app-error-validation>
            </mat-error>
          </mat-form-field>

          <h3 fxFlex="4" style="text-align: center;">-</h3>

          <mat-form-field fxFlex="22">
            <span matPrefix>{{ 'CAMPAIGN.TO_TRXN' | translate }} &nbsp;</span>
            <input matInput [ngrxFormControlState]="(formState$ | async).controls.conditionFactorTo" type="text"
              maxlength="10">
            <mat-error *ngIf="(formState$ | async).errors._conditionFactorTo">
              <app-error-validation [errors]="(formState$ | async).errors._conditionFactorTo" [maxlength]="10">
              </app-error-validation>
            </mat-error>
          </mat-form-field>

          <h3 fxFlex="4" style="text-align: center;">=</h3>

          <mat-form-field fxFlex="48">
            <input matInput style="text-align:right" [ngrxFormControlState]="(formState$ | async).controls.pointFactor"
              type="text" maxlength="10">
            <span matSuffix>&nbsp; {{ 'CAMPAIGN.POINTS' | translate }}</span>
            <mat-error *ngIf="(formState$ | async).errors._pointFactor">
              <app-error-validation [errors]="(formState$ | async).errors._pointFactor" [maxlength]="10">
              </app-error-validation>
            </mat-error>
          </mat-form-field>
        </div>


        <div *ngIf="type !== 'TPC' && rewardMethod === 'FIX'" fxLayout="row" fxFlex>
          <mat-form-field fxFlex="22">
            <span matPrefix>{{ 'CAMPAIGN.FROM_AMOUNT' | translate }} &nbsp;</span>
            <input matInput [ngrxFormControlState]="(formState$ | async)?.controls.conditionFactorFrom" type="text"
              maxlength="10">
            <mat-error *ngIf="(formState$ | async).errors._conditionFactorFrom">
              <app-error-validation [errors]="(formState$ | async).errors._conditionFactorFrom" [maxlength]="10">
              </app-error-validation>
            </mat-error>
          </mat-form-field>

          <h3 fxFlex="4" style="text-align: center;">-</h3>

          <mat-form-field fxFlex="22">
            <span matPrefix>{{ 'CAMPAIGN.TO_AMOUNT' | translate }} &nbsp;</span>
            <input matInput [ngrxFormControlState]="(formState$ | async).controls.conditionFactorTo" type="text"
              maxlength="10">
            <mat-error *ngIf="(formState$ | async).errors._conditionFactorTo">
              <app-error-validation [errors]="(formState$ | async).errors._conditionFactorTo" [maxlength]="10">
              </app-error-validation>
            </mat-error>
          </mat-form-field>

          <h3 fxFlex="4" style="text-align: center;">=</h3>

          <mat-form-field fxFlex="48">
            <input matInput style="text-align:right" [ngrxFormControlState]="(formState$ | async).controls.pointFactor"
              type="text" maxlength="10">
            <span matSuffix>&nbsp; {{ 'CAMPAIGN.POINTS' | translate }}</span>
            <mat-error *ngIf="(formState$ | async).errors._pointFactor">
              <app-error-validation [errors]="(formState$ | async).errors._pointFactor" [maxlength]="10">
              </app-error-validation>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </form>

  </mat-expansion-panel>
</mat-accordion>
