import { Action, createReducer, on } from '@ngrx/store'
import * as GroupAction from '../../access-management/group'
import * as UserAction from '../../access-management/user'
import * as PointAdjustmentFileAction from '../../adjustment-function/point-adjustment-file-upload'
import * as CampaignAction from '../../campaign-management/campaign'
import * as ConditionalRewardAction from '../../campaign-management/conditional-reward'
import * as SimulatorAction from '../../campaign-management/simulator'
import * as FulfilmentPartnerAction from '../../catalogue-management/fulfilment-partner'
import * as ProductCatalogueAction from '../../catalogue-management/product-catalogue'
import * as BasicInfoAction from '../../customer-management'
import * as FieldMappingAction from '../../param-settings/field-mapping'
import * as GlProvisionAction from '../../param-settings/gl-provision'
import * as GlRedemptionAction from '../../param-settings/gl-redemption'
import * as MccGroupAction from '../../param-settings/mcc-group'
import * as MerchantGroupAction from '../../param-settings/merchant-group'
import * as ProductTypeGroupAction from '../../param-settings/product-type-group'
import * as RewardPoolAction from '../../param-settings/reward-pool'
import * as RewardPoolGroupAction from '../../param-settings/reward-pool-group'
import * as TargetSegmentAction from '../../param-settings/target-segment'
import * as TcGroupAction from '../../param-settings/tc-group'
import * as AutoRedemptionAction from '../../redemption-settings/auto-redemption'
import * as OnTheSpotRedemptionAction from '../../redemption-settings/on-the-spot-redemption'
import * as RedeemPartnerAction from '../../redemption-settings/redeem-partner'
import * as RedeemRulesAction from '../../redemption-settings/redeem-rules'
import * as WaiverManagementAction from '../../redemption-settings/waiver-management'
import * as MyRequestAction from '../../worklist/my-request'
import * as TasklistAction from '../../worklist/tasklist'
import * as ProductPartnerAction from '../../catalogue-management/product-partner'
import * as ActionbarAction from './action-bar.actions'
import { initialState, State } from './action-bar.state'


export const reducer = createReducer(
	initialState,
	on(ActionbarAction.UserProfileView, () => ({
		icon: '',
		title: 'USER_PROFILE.USER_PROFILE'
	})),
	on(ActionbarAction.UserList, () => ({
		icon: '',
		title: 'USER.USER'
	})),
	on(ActionbarAction.UserView, () => ({
		icon: '',
		title: 'USER.USER',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'USER.USER_DETAILS',
				functions: ['USER_VIEW'],
				actionButtonType: 'BACK',
				action: UserAction.GoList()
			}
		]
	})),
	on(ActionbarAction.UserUpdate, () => ({
		icon: '',
		title: 'USER.USER',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'USER.USER_DETAILS',
				functions: ['USER_VIEW'],
				actionButtonType: 'BACK',
				action: UserAction.GoBackDialog({ action: 'VIEW' })
			},
			{
				icon: 'add_box',
				title: 'USER.ACTION.ASSIGN_GORUP',
				functions: ['USER_VIEW'],
				action: UserAction.AssignGroupDialog()
			}
		]
	})),
	on(ActionbarAction.UserUpdateDisableGroup, () => ({
		icon: '',
		title: 'USER.USER',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'USER.USER_DETAILS',
				functions: ['USER_VIEW'],
				actionButtonType: 'BACK',
				action: UserAction.GoBackDialog({ action: 'VIEW' })
			},
			{
				icon: 'add_box',
				title: 'USER.ACTION.ASSIGN_GORUP',
				functions: ['USER_VIEW'],
				isDisable: true,
				action: UserAction.AssignGroupDialog()
			}
		]
	})),
	on(ActionbarAction.UserViewFromGroup, () => ({
		icon: '',
		title: 'USER.USER',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'GROUP.GROUP_DETAILS',
				functions: ['USER_VIEW'],
				actionButtonType: 'BACK',
				action: GroupAction.GoView()
			}
		]
	})),
	on(ActionbarAction.GroupList, () => ({
		icon: '',
		title: 'GROUP.GROUP',
		actionButtons: [
			{
				icon: 'add_box',
				title: 'GROUP.ACTION.CREATE_GROUP',
				functions: ['GROUP_VIEW'],
				action: GroupAction.GoCreate()
			}
		]
	})),
	on(ActionbarAction.GroupView, () => ({
		icon: '',
		title: 'GROUP.GROUP',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'GROUP.GROUP_DETAILS',
				functions: ['GROUP_VIEW'],
				actionButtonType: 'BACK',
				action: GroupAction.GoList()
			},
			{
				icon: 'edit',
				title: 'ACTION.EDIT',
				functions: ['GROUP_VIEW'],
				action: GroupAction.GoEdit()
			},
			{
				icon: 'delete',
				title: 'ACTION.DELETE',
				functions: ['GROUP_VIEW'],
				action: GroupAction.DeleteDialog()
			}
		]
	})),
	on(ActionbarAction.GroupCreate, () => ({
		icon: '',
		title: 'GROUP.GROUP',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'GROUP.CREATE_GROUP',
				functions: ['GROUP_VIEW'],
				actionButtonType: 'BACK',
				action: GroupAction.GoBackDialog({ action: 'LIST' })
			}
		]
	})),
	on(ActionbarAction.GroupEdit, () => ({
		icon: '',
		title: 'GROUP.GROUP',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'GROUP.GROUP_DETAILS',
				functions: ['GROUP_VIEW'],
				actionButtonType: 'BACK',
				action: GroupAction.GoBackDialog({ action: 'VIEW' })
			},
			{
				icon: 'delete',
				title: 'ACTION.DELETE',
				functions: ['GROUP_VIEW'],
				action: GroupAction.DeleteDialog()
			}
		]
	})),
	on(ActionbarAction.PolicyList, () => ({
		icon: '',
		title: 'FUNCTION.FUNCTION'
	})),
	on(ActionbarAction.DashboardOverview, () => ({
		icon: '',
		title: 'DASHBOARD.DASHBOARD',
		dashboardActionBar: [
			{
				icon: '',
				title: 'DASHBOARD.OVERVIEW',
				functions: ['DASHBOARD_REWARD_POOL_CAMPAIGN_OVERVIEW'],
				actionButtonType: 'TITLE',
				selectionType: 'CHANNEL'
			}
		]
	})),
	on(ActionbarAction.DashboardOverviewV2, () => ({
		icon: '',
		title: 'DASHBOARD.DASHBOARD',
		dashboardActionBar: [
			{
				icon: '',
				title: 'DASHBOARD.DASHBOARD',
				functions: ['DASHBOARD_OVERVIEW'],
				actionButtonType: 'TITLE'
			}
		]
	})),
	on(ActionbarAction.DashboardRewardPoolOverview, () => ({
		icon: '',
		title: 'DASHBOARD.DASHBOARD',
		dashboardActionBar: [
			{
				icon: '',
				title: 'DASHBOARD.OVERVIEW',
				functions: ['DASHBOARD_REWARD_POOL_CAMPAIGN_OVERVIEW'],
				actionButtonType: 'TITLE',
				selectionType: 'CHANNEL'
			}
		]
	})),
	on(ActionbarAction.DashboardOverviewWithoutChannel, () => ({
		icon: '',
		title: 'DASHBOARD.DASHBOARD',
		dashboardActionBar: [
			{
				icon: '',
				title: 'DASHBOARD.OVERVIEW',
				functions: ['DASHBOARD_PRODUCT_BUNDLING_OVERVIEW', 'DASHBOARD_REWARD_POOL_GROUP_OVERVIEW'],
				actionButtonType: 'TITLE'
			}
		]
	})),
	on(ActionbarAction.DashboardRedemption, () => ({
		icon: '',
		title: 'DASHBOARD.DASHBOARD',
		dashboardActionBar: [
			{
				icon: '',
				title: 'DASHBOARD_REDEMPTION.REDEMPTION',
				functions: ['DASHBOARD_REDEMPTION'],
				actionButtonType: 'TITLE',
			}
		]
	})),
	on(ActionbarAction.CampaignList, () => ({
		icon: '',
		title: 'CAMPAIGN.CAMPAIGN',
		actionButtons: [
			{
				icon: 'add_box',
				title: 'CAMPAIGN.ACTION.CREATE_CAMPAIGN',
				functions: ['CAMPAIGN_CREATE'],
				action: CampaignAction.GoCreate()
			},
			{
				icon: 'add_box',
				title: 'CAMPAIGN.ACTION.CREATE_PRODUCT_BUNDLING',
				functions: ['CAMPAIGN_BUNDLING_CREATE'],
				action: CampaignAction.GoCreateProductBundle()
			}
		]
	})),
	on(ActionbarAction.CampaignCreate, () => ({
		icon: '',
		title: 'CAMPAIGN.CAMPAIGN',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'CAMPAIGN.CREATE_CAMPAIGN',
				functions: [
					'CAMPAIGN_PUBLISHED_VIEW', 'CAMPAIGN_ARCHIVED_VIEW', 'CAMPAIGN_CREATE',
					'CAMPAIGN_BUNDLING_PUBLISHED_VIEW', 'CAMPAIGN_BUNDLING_ARCHIVED_VIEW', 'CAMPAIGN_BUNDLING_CREATE'
				],
				actionButtonType: 'BACK',
				action: CampaignAction.GoBackDialog({ action: 'LIST' })
			},
			{
				icon: 'save',
				title: 'ACTION.SAVE',
				functions: ['CAMPAIGN_CREATE', 'CAMPAIGN_BUNDLING_CREATE'],
				action: CampaignAction.CreateDialog()
			}
		]
	})),
	on(ActionbarAction.CampaignDisableCreate, () => ({
		icon: '',
		title: 'CAMPAIGN.CAMPAIGN',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'CAMPAIGN.CREATE_CAMPAIGN',
				functions: [
					'CAMPAIGN_PUBLISHED_VIEW', 'CAMPAIGN_ARCHIVED_VIEW', 'CAMPAIGN_CREATE',
					'CAMPAIGN_BUNDLING_PUBLISHED_VIEW', 'CAMPAIGN_BUNDLING_ARCHIVED_VIEW', 'CAMPAIGN_BUNDLING_CREATE'
				],
				actionButtonType: 'BACK',
				action: CampaignAction.GoBackDialog({ action: 'LIST' })
			},
			{
				icon: 'save',
				title: 'ACTION.SAVE',
				functions: ['CAMPAIGN_CREATE', 'CAMPAIGN_BUNDLING_CREATE'],
				isDisable: true,
				action: CampaignAction.CreateDialog()
			}
		]
	})),
	on(ActionbarAction.CampaignUpdateDisableDelete, () => ({
		icon: '',
		title: 'CAMPAIGN.CAMPAIGN',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'CAMPAIGN.CAMPAIGN_DETAILS',
				functions: [
					'CAMPAIGN_PUBLISHED_VIEW', 'CAMPAIGN_ARCHIVED_VIEW', 'CAMPAIGN_CREATE',
					'CAMPAIGN_BUNDLING_PUBLISHED_VIEW', 'CAMPAIGN_BUNDLING_ARCHIVED_VIEW', 'CAMPAIGN_BUNDLING_CREATE'
				],
				actionButtonType: 'BACK',
				action: CampaignAction.GoBackDialog({ action: 'LIST' })
			},
			{
				icon: 'save',
				title: 'ACTION.SAVE',
				functions: ['CAMPAIGN_CREATE', 'CAMPAIGN_BUNDLING_CREATE'],
				action: CampaignAction.UpdateDialog()
			},
			{
				icon: 'delete',
				title: 'ACTION.DELETE',
				isDisable: true,
				functions: ['CAMPAIGN_CREATE', 'CAMPAIGN_BUNDLING_CREATE'],
				action: CampaignAction.DeleteDialog({ returnTab: 1 }),
				disableDescription: 'CAMPAIGN.DELETE_DISABLE_DESCRIPTION'
			}
		]
	})),
	on(ActionbarAction.CampaignUpdateDisableDeleteAndUpdate, () => ({
		icon: '',
		title: 'CAMPAIGN.CAMPAIGN',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'CAMPAIGN.CAMPAIGN_DETAILS',
				functions: [
					'CAMPAIGN_PUBLISHED_VIEW', 'CAMPAIGN_ARCHIVED_VIEW', 'CAMPAIGN_CREATE',
					'CAMPAIGN_BUNDLING_PUBLISHED_VIEW', 'CAMPAIGN_BUNDLING_ARCHIVED_VIEW', 'CAMPAIGN_BUNDLING_CREATE'
				],
				actionButtonType: 'BACK',
				action: CampaignAction.GoBackDialog({ action: 'LIST' })
			},
			{
				icon: 'save',
				title: 'ACTION.SAVE',
				functions: ['CAMPAIGN_CREATE', 'CAMPAIGN_BUNDLING_CREATE'],
				action: CampaignAction.UpdateDialog(),
				isDisable: true,
			},
			{
				icon: 'delete',
				title: 'ACTION.DELETE',
				isDisable: true,
				functions: ['CAMPAIGN_CREATE', 'CAMPAIGN_BUNDLING_CREATE'],
				action: CampaignAction.DeleteDialog({ returnTab: 1 }),
				disableDescription: 'CAMPAIGN.DELETE_DISABLE_DESCRIPTION'
			}
		]
	})),
	on(ActionbarAction.CampaignUpdate, () => ({
		icon: '',
		title: 'CAMPAIGN.CAMPAIGN',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'CAMPAIGN.CAMPAIGN_DETAILS',
				functions: [
					'CAMPAIGN_PUBLISHED_VIEW', 'CAMPAIGN_ARCHIVED_VIEW', 'CAMPAIGN_CREATE',
					'CAMPAIGN_BUNDLING_PUBLISHED_VIEW', 'CAMPAIGN_BUNDLING_ARCHIVED_VIEW', 'CAMPAIGN_BUNDLING_CREATE'
				],
				actionButtonType: 'BACK',
				action: CampaignAction.GoBackDialog({ action: 'LIST' })
			},
			{
				icon: 'save',
				title: 'ACTION.SAVE',
				functions: ['CAMPAIGN_CREATE', 'CAMPAIGN_BUNDLING_CREATE'],
				action: CampaignAction.UpdateDialog()
			},
			{
				icon: 'delete',
				title: 'ACTION.DELETE',
				functions: ['CAMPAIGN_CREATE', 'CAMPAIGN_BUNDLING_CREATE'],
				action: CampaignAction.DeleteDialog({ returnTab: 1 })
			}
		]
	})),
	on(ActionbarAction.CampaignDisableUpdate, () => ({
		icon: '',
		title: 'CAMPAIGN.CAMPAIGN',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'CAMPAIGN.CAMPAIGN_DETAILS',
				functions: [
					'CAMPAIGN_PUBLISHED_VIEW', 'CAMPAIGN_ARCHIVED_VIEW', 'CAMPAIGN_CREATE',
					'CAMPAIGN_BUNDLING_PUBLISHED_VIEW', 'CAMPAIGN_BUNDLING_ARCHIVED_VIEW', 'CAMPAIGN_BUNDLING_CREATE'
				],
				actionButtonType: 'BACK',
				action: CampaignAction.GoBackDialog({ action: 'LIST' })
			},
			{
				icon: 'save',
				title: 'ACTION.SAVE',
				functions: ['CAMPAIGN_CREATE', 'CAMPAIGN_BUNDLING_CREATE'],
				isDisable: true,
				action: CampaignAction.UpdateDialog()
			},
			{
				icon: 'delete',
				title: 'ACTION.DELETE',
				functions: ['CAMPAIGN_CREATE', 'CAMPAIGN_BUNDLING_CREATE'],
				action: CampaignAction.DeleteDialog({ returnTab: 1 })
			}
		]
	})),
	on(ActionbarAction.CampaignSimulate, () => ({
		icon: '',
		title: 'CAMPAIGN.CAMPAIGN',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'CAMPAIGN.SIMULATION',
				functions: [
					'CAMPAIGN_PUBLISHED_VIEW', 'CAMPAIGN_ARCHIVED_VIEW',
					'CAMPAIGN_BUNDLING_PUBLISHED_VIEW', 'CAMPAIGN_BUNDLING_ARCHIVED_VIEW'
				],
				actionButtonType: 'BACK',
				action: CampaignAction.GoList({ tab: 0 })
			},
			{
				icon: 'publish',
				title: 'ACTION.PUBLISH',
				functions: ['CAMPAIGN_CREATE', 'CAMPAIGN_BUNDLING_CREATE'],
				action: CampaignAction.Publish()
			}
		]
	})),
	on(ActionbarAction.CampaignRepublishView, () => ({
		icon: '',
		title: 'CAMPAIGN.CAMPAIGN',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'CAMPAIGN.CAMPAIGN_DETAILS',
				functions: ['CAMPAIGN_CREATE', 'CAMPAIGN_BUNDLING_CREATE',
					'CAMPAIGN_PUBLISHED_VIEW', 'CAMPAIGN_ARCHIVED_VIEW',
					'CAMPAIGN_BUNDLING_PUBLISHED_VIEW', 'CAMPAIGN_BUNDLING_ARCHIVED_VIEW'],
				actionButtonType: 'BACK',
				action: CampaignAction.GoList({ tab: 0 })
			},
			{
				icon: 'edit',
				title: 'ACTION.EDIT',
				functions: ['CAMPAIGN_UPDATE', 'CAMPAIGN_BUNDLING_UPDATE'],
				action: CampaignAction.GoPublishedUpdate()
			}
		]
	})),
	on(ActionbarAction.CampaignView, () => ({
		icon: '',
		title: 'CAMPAIGN.CAMPAIGN',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'CAMPAIGN.CAMPAIGN_DETAILS',
				functions: ['CAMPAIGN_CREATE', 'CAMPAIGN_BUNDLING_CREATE',
					'CAMPAIGN_PUBLISHED_VIEW', 'CAMPAIGN_ARCHIVED_VIEW',
					'CAMPAIGN_BUNDLING_PUBLISHED_VIEW', 'CAMPAIGN_BUNDLING_ARCHIVED_VIEW'],
				actionButtonType: 'BACK',
				action: CampaignAction.GoList({ tab: 0 })
			}
		]
	})),
	on(ActionbarAction.CampaignPublishedDisableEdit, () => ({
		icon: '',
		title: 'CAMPAIGN.CAMPAIGN',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'CAMPAIGN.CAMPAIGN_DETAILS',
				functions: ['CAMPAIGN_CREATE', 'CAMPAIGN_BUNDLING_CREATE',
					'CAMPAIGN_PUBLISHED_VIEW', 'CAMPAIGN_ARCHIVED_VIEW',
					'CAMPAIGN_BUNDLING_PUBLISHED_VIEW', 'CAMPAIGN_BUNDLING_ARCHIVED_VIEW'],
				actionButtonType: 'BACK',
				action: CampaignAction.GoList({ tab: 0 })
			},
			{
				icon: 'edit',
				title: 'ACTION.EDIT',
				functions: ['CAMPAIGN_UPDATE', 'CAMPAIGN_BUNDLING_UPDATE'],
				action: CampaignAction.GoPublishedUpdate(),
				isDisable: true,
				disableDescription: 'CAMPAIGN.REPUBLISH_DISABLE_DESCRIPTION'
			}
		]
	})),
	on(ActionbarAction.CampaignRepublished, () => ({
		icon: '',
		title: 'CAMPAIGN.CAMPAIGN',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'CAMPAIGN.CAMPAIGN_DETAILS',
				functions: ['CAMPAIGN_UPDATE', 'CAMPAIGN_BUNDLING_UPDATE'],
				actionButtonType: 'BACK',
				action: CampaignAction.GoList({ tab: 0 })
			},
			{
				icon: 'publish',
				title: 'ACTION.RE_PUBLISH',
				functions: ['CAMPAIGN_UPDATE', 'CAMPAIGN_BUNDLING_UPDATE'],
				action: CampaignAction.RepublishDialog()
			}
		]
	})),
	on(ActionbarAction.CampaignDisableRepublished, () => ({
		icon: '',
		title: 'CAMPAIGN.CAMPAIGN',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'CAMPAIGN.CAMPAIGN_DETAILS',
				functions: ['CAMPAIGN_UPDATE', 'CAMPAIGN_BUNDLING_UPDATE'],
				actionButtonType: 'BACK',
				action: CampaignAction.GoList({ tab: 0 })
			},
			{
				icon: 'publish',
				title: 'ACTION.RE_PUBLISH',
				functions: ['CAMPAIGN_UPDATE', 'CAMPAIGN_BUNDLING_UPDATE'],
				isDisable: true,
				action: CampaignAction.RepublishDialog()
			}
		]
	})),
	on(ActionbarAction.PbCampaignRepublished, () => ({
		icon: '',
		title: 'CAMPAIGN.CAMPAIGN',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'CAMPAIGN.CAMPAIGN_DETAILS',
				functions: ['CAMPAIGN_UPDATE', 'CAMPAIGN_BUNDLING_UPDATE'],
				actionButtonType: 'BACK',
				action: CampaignAction.GoList({ tab: 0 })
			},
			{
				icon: 'publish',
				title: 'ACTION.RE_PUBLISH',
				functions: ['CAMPAIGN_UPDATE', 'CAMPAIGN_BUNDLING_UPDATE'],
				action: CampaignAction.RepublishDialog()
			}
		]
	})),
	on(ActionbarAction.PbCampaignDisableRepublished, () => ({
		icon: '',
		title: 'CAMPAIGN.CAMPAIGN',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'CAMPAIGN.CAMPAIGN_DETAILS',
				functions: ['CAMPAIGN_UPDATE', 'CAMPAIGN_BUNDLING_UPDATE'],
				actionButtonType: 'BACK',
				action: CampaignAction.GoList({ tab: 0 })
			},
			{
				icon: 'publish',
				title: 'ACTION.RE_PUBLISH',
				functions: ['CAMPAIGN_UPDATE', 'CAMPAIGN_BUNDLING_UPDATE'],
				isDisable: true,
				action: CampaignAction.RepublishDialog()
			}
		]
	})),
	on(ActionbarAction.GlManagementList, () => ({
		icon: '',
		title: 'GL_MANAGEMENT.GL_MANAGEMENT',
		actionButtons: [
			{
				icon: 'add_box',
				title: 'GL_PROVISION.ACTION.CREATE_GL_PROVISION',
				functions: ['GL_PROVISION_CREATE'],
				action: GlProvisionAction.GoCreate()
			},
			{
				icon: 'add_box',
				title: 'GL_REDEMPTION.ACTION.CREATE_GL_REDEMPTION',
				functions: ['GL_REDEMPTION_CREATE'],
				action: GlRedemptionAction.GoCreate()
			}
		]
	})),
	on(ActionbarAction.GlProvisionView, () => ({
		icon: '',
		title: 'GL_MANAGEMENT.GL_MANAGEMENT',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'GL_PROVISION.GL_PROVISION_DETAILS',
				functions: ['GL_PROVISION_VIEW'],
				actionButtonType: 'BACK',
				action: GlProvisionAction.GoList()
			},
			{
				icon: 'edit',
				title: 'ACTION.EDIT',
				functions: ['GL_PROVISION_UPDATE'],
				action: GlProvisionAction.GoUpdate()
			},
			{
				icon: 'delete',
				title: 'ACTION.DELETE',
				functions: ['GL_PROVISION_DELETE'],
				action: GlProvisionAction.DeleteDialog()
			}
		]
	})),
	on(ActionbarAction.GlProvisionViewDisableDelete, () => ({
		icon: '',
		title: 'GL_MANAGEMENT.GL_MANAGEMENT',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'GL_PROVISION.GL_PROVISION_DETAILS',
				functions: ['GL_PROVISION_VIEW'],
				actionButtonType: 'BACK',
				action: GlProvisionAction.GoList()
			},
			{
				icon: 'edit',
				title: 'ACTION.EDIT',
				functions: ['GL_PROVISION_UPDATE'],
				action: GlProvisionAction.GoUpdate()
			},
			{
				icon: 'delete',
				title: 'ACTION.DELETE',
				functions: ['GL_PROVISION_DELETE'],
				action: GlProvisionAction.DeleteDialog(),
				isDisable: true,
				disableDescription: 'GL_MANAGEMENT.GL_PROVISION_DELETE_DISABLE_DESCRIPTION'
			}
		]
	})),
	on(ActionbarAction.GlProvisionEditDisableDelete, () => ({
		icon: '',
		title: 'GL_MANAGEMENT.GL_MANAGEMENT',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'GL_PROVISION.GL_PROVISION_DETAILS',
				functions: ['GL_PROVISION_VIEW'],
				actionButtonType: 'BACK',
				action: GlProvisionAction.GoBackDialog({ action: 'VIEW' })
			},
			{
				icon: 'delete',
				title: 'ACTION.DELETE',
				functions: ['GL_PROVISION_DELETE'],
				action: GlProvisionAction.DeleteDialog(),
				isDisable: true,
				disableDescription: 'GL_MANAGEMENT.GL_PROVISION_DELETE_DISABLE_DESCRIPTION'
			}
		]
	})),
	on(ActionbarAction.GlProvisionCreate, () => ({
		icon: '',
		title: 'GL_MANAGEMENT.GL_MANAGEMENT',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'GL_PROVISION.CREATE_GL_PROVISION',
				functions: ['GL_PROVISION_VIEW'],
				actionButtonType: 'BACK',
				action: GlProvisionAction.GoBackDialog({ action: 'LIST' })
			}
		]
	})),
	on(ActionbarAction.GlProvisionUpdate, () => ({
		icon: '',
		title: 'GL_MANAGEMENT.GL_MANAGEMENT',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'GL_PROVISION.GL_PROVISION_DETAILS',
				functions: ['GL_PROVISION_VIEW'],
				actionButtonType: 'BACK',
				action: GlProvisionAction.GoBackDialog({ action: 'VIEW' })
			},
			{
				icon: 'delete',
				title: 'ACTION.DELETE',
				functions: ['GL_PROVISION_DELETE'],
				action: GlProvisionAction.DeleteDialog()
			}
		]
	})),
	on(ActionbarAction.GlRedemptionView, () => ({
		icon: '',
		title: 'GL_MANAGEMENT.GL_MANAGEMENT',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'GL_REDEMPTION.GL_REDEMPTION_DETAILS',
				functions: ['GL_REDEMPTION_VIEW'],
				actionButtonType: 'BACK',
				action: GlRedemptionAction.GoList()
			},
			{
				icon: 'edit',
				title: 'ACTION.EDIT',
				functions: ['GL_REDEMPTION_UPDATE'],
				action: GlRedemptionAction.GoUpdate()
			},
			{
				icon: 'delete',
				title: 'ACTION.DELETE',
				functions: ['GL_REDEMPTION_DELETE'],
				action: GlRedemptionAction.DeleteDialog()
			}
		]
	})),
	on(ActionbarAction.GlRedemptionViewDisableDelete, () => ({
		icon: '',
		title: 'GL_MANAGEMENT.GL_MANAGEMENT',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'GL_REDEMPTION.GL_REDEMPTION_DETAILS',
				functions: ['GL_REDEMPTION_VIEW'],
				actionButtonType: 'BACK',
				action: GlRedemptionAction.GoList()
			},
			{
				icon: 'edit',
				title: 'ACTION.EDIT',
				functions: ['GL_REDEMPTION_UPDATE'],
				action: GlRedemptionAction.GoUpdate()
			},
			{
				icon: 'delete',
				title: 'ACTION.DELETE',
				functions: ['GL_REDEMPTION_DELETE'],
				action: GlRedemptionAction.DeleteDialog(),
				isDisable: true,
				disableDescription: 'GL_MANAGEMENT.GL_REDEMPTION_DELETE_DISABLE_DESCRIPTION'
			}
		]
	})),
	on(ActionbarAction.GlRedemptionEditDisableDelete, () => ({
		icon: '',
		title: 'GL_MANAGEMENT.GL_MANAGEMENT',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'GL_REDEMPTION.GL_REDEMPTION_DETAILS',
				functions: ['GL_REDEMPTION_VIEW'],
				actionButtonType: 'BACK',
				action: GlRedemptionAction.GoView()
			},
			{
				icon: 'delete',
				title: 'ACTION.DELETE',
				functions: ['GL_REDEMPTION_DELETE'],
				action: GlRedemptionAction.DeleteDialog(),
				isDisable: true,
				disableDescription: 'GL_MANAGEMENT.GL_REDEMPTION_DELETE_DISABLE_DESCRIPTION'
			}
		]
	})),
	on(ActionbarAction.GlRedemptionCreate, () => ({
		icon: '',
		title: 'GL_MANAGEMENT.GL_MANAGEMENT',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'GL_REDEMPTION.CREATE_GL_REDEMPTION',
				functions: ['GL_REDEMPTION_VIEW'],
				actionButtonType: 'BACK',
				action: GlRedemptionAction.GoBackDialog({ action: 'LIST' })
			}
		]
	})),
	on(ActionbarAction.GlRedemptionUpdate, () => ({
		icon: '',
		title: 'GL_MANAGEMENT.GL_MANAGEMENT',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'GL_REDEMPTION.GL_REDEMPTION_DETAILS',
				functions: ['GL_REDEMPTION_VIEW'],
				actionButtonType: 'BACK',
				action: GlRedemptionAction.GoBackDialog({ action: 'VIEW' })
			},
			{
				icon: 'delete',
				title: 'ACTION.DELETE',
				functions: ['GL_REDEMPTION_DELETE'],
				action: GlRedemptionAction.DeleteDialog()
			}
		]
	})),
	on(ActionbarAction.TcGroupList, () => ({
		icon: '',
		title: 'TC_GROUP.TC_GROUP',
		actionButtons: [
			{
				icon: 'add_box',
				title: 'TC_GROUP.ACTION.CREATE_TC_GROUP',
				functions: ['TC_GROUP_CREATE'],
				action: TcGroupAction.GoCreate()
			}
		]
	})),
	on(ActionbarAction.TcGroupView, () => ({
		icon: '',
		title: 'TC_GROUP.TC_GROUP',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'TC_GROUP.TC_GROUP_DETAILS',
				functions: ['TC_GROUP_VIEW'],
				actionButtonType: 'BACK',
				action: TcGroupAction.GoList()
			},
			{
				icon: 'edit',
				title: 'ACTION.EDIT',
				functions: ['TC_GROUP_UPDATE'],
				action: TcGroupAction.GoUpdate()
			},
			{
				icon: 'delete',
				title: 'ACTION.DELETE',
				functions: ['TC_GROUP_DELETE'],
				action: TcGroupAction.DeleteDialog()
			}
		]
	})),
	on(ActionbarAction.TcGroupViewDisableDelete, () => ({
		icon: '',
		title: 'TC_GROUP.TC_GROUP',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'TC_GROUP.TC_GROUP_DETAILS',
				functions: ['TC_GROUP_VIEW'],
				actionButtonType: 'BACK',
				action: TcGroupAction.GoList()
			},
			{
				icon: 'edit',
				title: 'ACTION.EDIT',
				functions: ['TC_GROUP_UPDATE'],
				action: TcGroupAction.GoUpdate()
			},
			{
				icon: 'delete',
				title: 'ACTION.DELETE',
				functions: ['TC_GROUP_DELETE'],
				action: TcGroupAction.DeleteDialog(),
				isDisable: true,
				disableDescription: 'TC_GROUP.DELETE_DISABLE_DESCRIPTION'
			}
		]
	})),
	on(ActionbarAction.TcGroupCreate, () => ({
		icon: '',
		title: 'TC_GROUP.TC_GROUP',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'TC_GROUP.TC_GROUP_DETAILS',
				functions: ['TC_GROUP_VIEW'],
				actionButtonType: 'BACK',
				action: TcGroupAction.GoBackDialog({ action: 'LIST' })
			}
		]
	})),
	on(ActionbarAction.TcGroupUpdate, () => ({
		icon: '',
		title: 'TC_GROUP.TC_GROUP',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'TC_GROUP.TC_GROUP_DETAILS',
				functions: ['TC_GROUP_VIEW'],
				actionButtonType: 'BACK',
				action: TcGroupAction.GoBackDialog({ action: 'VIEW' })
			},
			{
				icon: 'delete',
				title: 'ACTION.DELETE',
				functions: ['TC_GROUP_DELETE'],
				action: TcGroupAction.DeleteDialog()
			}
		]
	})),
	on(ActionbarAction.TcGroupUpdateDisableDelete, () => ({
		icon: '',
		title: 'TC_GROUP.TC_GROUP',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'TC_GROUP.TC_GROUP_DETAILS',
				functions: ['TC_GROUP_VIEW'],
				actionButtonType: 'BACK',
				action: TcGroupAction.GoBackDialog({ action: 'VIEW' })
			},
			{
				icon: 'delete',
				title: 'ACTION.DELETE',
				functions: ['TC_GROUP_DELETE'],
				action: TcGroupAction.DeleteDialog(),
				isDisable: true,
				disableDescription: 'TC_GROUP.DELETE_DISABLE_DESCRIPTION'
			}
		]
	})),
	on(ActionbarAction.MccGroupList, () => ({
		icon: '',
		title: 'MCC_GROUP.MCC_GROUP',
		actionButtons: [
			{
				icon: 'add_box',
				title: 'MCC_GROUP.ACTION.CREATE_MCC_GROUP',
				functions: ['MCC_GROUP_CREATE'],
				action: MccGroupAction.GoCreate()
			}
		]
	})),
	on(ActionbarAction.MccGroupView, () => ({
		icon: '',
		title: 'MCC_GROUP.MCC_GROUP',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'MCC_GROUP.MCC_GROUP_DETAILS',
				functions: ['MCC_GROUP_VIEW'],
				actionButtonType: 'BACK',
				action: MccGroupAction.GoList()
			},
			{
				icon: 'edit',
				title: 'ACTION.EDIT',
				functions: ['MCC_GROUP_UPDATE'],
				action: MccGroupAction.GoUpdate()
			},
			{
				icon: 'delete',
				title: 'ACTION.DELETE',
				functions: ['MCC_GROUP_DELETE'],
				action: MccGroupAction.DeleteDialog()
			}
		]
	})),
	on(ActionbarAction.MccGroupViewDisableDelete, () => ({
		icon: '',
		title: 'MCC_GROUP.MCC_GROUP',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'MCC_GROUP.MCC_GROUP_DETAILS',
				functions: ['MCC_GROUP_VIEW'],
				actionButtonType: 'BACK',
				action: MccGroupAction.GoList()
			},
			{
				icon: 'edit',
				title: 'ACTION.EDIT',
				functions: ['MCC_GROUP_UPDATE'],
				action: MccGroupAction.GoUpdate()
			},
			{
				icon: 'delete',
				title: 'ACTION.DELETE',
				functions: ['MCC_GROUP_DELETE'],
				action: MccGroupAction.DeleteDialog(),
				isDisable: true,
				disableDescription: 'MCC_GROUP.DELETE_DISABLE_DESCRIPTION'
			}
		]
	})),
	on(ActionbarAction.MccGroupCreate, () => ({
		icon: '',
		title: 'MCC_GROUP.MCC_GROUP',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'MCC_GROUP.CREATE_MCC_GROUP',
				functions: ['MCC_GROUP_VIEW'],
				actionButtonType: 'BACK',
				action: MccGroupAction.GoBackDialog({ action: 'LIST' })
			}
		]
	})),
	on(ActionbarAction.MccGroupUpdate, () => ({
		icon: '',
		title: 'MCC_GROUP.MCC_GROUP',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'MCC_GROUP.MCC_GROUP_DETAILS',
				functions: ['MCC_GROUP_VIEW'],
				actionButtonType: 'BACK',
				action: MccGroupAction.GoBackDialog({ action: 'VIEW' })
			},
			{
				icon: 'delete',
				title: 'ACTION.DELETE',
				functions: ['MCC_GROUP_DELETE'],
				action: MccGroupAction.DeleteDialog()
			}
		]
	})),
	on(ActionbarAction.MccGroupUpdateDisableDelete, () => ({
		icon: '',
		title: 'MCC_GROUP.MCC_GROUP',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'MCC_GROUP.MCC_GROUP_DETAILS',
				functions: ['MCC_GROUP_VIEW'],
				actionButtonType: 'BACK',
				action: MccGroupAction.GoBackDialog({ action: 'VIEW' })
			},
			{
				icon: 'delete',
				title: 'ACTION.DELETE',
				functions: ['MCC_GROUP_DELETE'],
				action: MccGroupAction.DeleteDialog(),
				isDisable: true,
				disableDescription: 'MCC_GROUP.DELETE_DISABLE_DESCRIPTION'
			}
		]
	})),
	on(ActionbarAction.MerchantGroupList, () => ({
		icon: '',
		title: 'MERCHANT_GROUP.MERCHANT_GROUP',
		actionButtons: [
			{
				icon: 'add_box',
				title: 'MERCHANT_GROUP.ACTION.CREATE_MERCHANT_GROUP',
				functions: ['MERCHANT_GROUP_CREATE'],
				action: MerchantGroupAction.GoCreate()
			}
		]
	})),
	on(ActionbarAction.MerchantGroupView, () => ({
		icon: '',
		title: 'MERCHANT_GROUP.MERCHANT_GROUP',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'MERCHANT_GROUP.MERCHANT_GROUP_DETAILS',
				functions: ['MERCHANT_GROUP_VIEW'],
				actionButtonType: 'BACK',
				action: MerchantGroupAction.GoList()
			},
			{
				icon: 'edit',
				title: 'ACTION.EDIT',
				functions: ['MERCHANT_GROUP_UPDATE'],
				action: MerchantGroupAction.GoUpdate()
			},
			{
				icon: 'delete',
				title: 'ACTION.DELETE',
				functions: ['MERCHANT_GROUP_DELETE'],
				action: MerchantGroupAction.DeleteDialog()
			}
		]
	})),
	on(ActionbarAction.MerchantGroupViewDisableDelete, () => ({
		icon: '',
		title: 'MERCHANT_GROUP.MERCHANT_GROUP',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'MERCHANT_GROUP.MERCHANT_GROUP_DETAILS',
				functions: ['MERCHANT_GROUP_VIEW'],
				actionButtonType: 'BACK',
				action: MerchantGroupAction.GoList()
			},
			{
				icon: 'edit',
				title: 'ACTION.EDIT',
				functions: ['MERCHANT_GROUP_UPDATE'],
				action: MerchantGroupAction.GoUpdate()
			},
			{
				icon: 'delete',
				title: 'ACTION.DELETE',
				functions: ['MERCHANT_GROUP_DELETE'],
				action: MerchantGroupAction.DeleteDialog(),
				isDisable: true,
				disableDescription: 'MERCHANT_GROUP.DELETE_DISABLE_DESCRIPTION'
			}
		]
	})),
	on(ActionbarAction.MerchantGroupCreate, () => ({
		icon: '',
		title: 'MERCHANT_GROUP.MERCHANT_GROUP',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'MERCHANT_GROUP.MERCHANT_GROUP_DETAILS',
				functions: ['MERCHANT_GROUP_VIEW'],
				actionButtonType: 'BACK',
				action: MerchantGroupAction.GoBackDialog({ action: 'LIST' })
			}
		]
	})),
	on(ActionbarAction.MerchantGroupUpdate, () => ({
		icon: '',
		title: 'MERCHANT_GROUP.MERCHANT_GROUP',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'MERCHANT_GROUP.MERCHANT_GROUP_DETAILS',
				functions: ['MERCHANT_GROUP_VIEW'],
				actionButtonType: 'BACK',
				action: MerchantGroupAction.GoBackDialog({ action: 'VIEW' })
			},
			{
				icon: 'delete',
				title: 'ACTION.DELETE',
				functions: ['MERCHANT_GROUP_DELETE'],
				action: MerchantGroupAction.DeleteDialog()
			}
		]
	})),
	on(ActionbarAction.MerchantGroupUpdateDisableDelete, () => ({
		icon: '',
		title: 'MERCHANT_GROUP.MERCHANT_GROUP',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'MERCHANT_GROUP.MERCHANT_GROUP_DETAILS',
				functions: ['MERCHANT_GROUP_VIEW'],
				actionButtonType: 'BACK',
				action: MerchantGroupAction.GoBackDialog({ action: 'VIEW' })
			},
			{
				icon: 'delete',
				title: 'ACTION.DELETE',
				functions: ['MERCHANT_GROUP_DELETE'],
				action: MerchantGroupAction.DeleteDialog(),
				isDisable: true,
				disableDescription: 'MERCHANT_GROUP.DELETE_DISABLE_DESCRIPTION'
			}
		]
	})),
	on(ActionbarAction.ProductTypeGroupList, () => ({
		icon: '',
		title: 'PRODUCT_TYPE_GROUP.PRODUCT_TYPE_GROUP',
		actionButtons: [
			{
				icon: 'add_box',
				title: 'PRODUCT_TYPE_GROUP.ACTION.CREATE_PRODUCT_TYPE_GROUP',
				functions: ['PRODUCT_TYPE_GROUP_CREATE'],
				action: ProductTypeGroupAction.GoCreate()
			}
		]
	})),
	on(ActionbarAction.ProductTypeGroupView, () => ({
		icon: '',
		title: 'PRODUCT_TYPE_GROUP.PRODUCT_TYPE_GROUP',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'PRODUCT_TYPE_GROUP.PRODUCT_TYPE_GROUP_DETAILS',
				functions: ['PRODUCT_TYPE_GROUP_VIEW'],
				actionButtonType: 'BACK',
				action: ProductTypeGroupAction.GoList()
			},
			{
				icon: 'edit',
				title: 'ACTION.EDIT',
				functions: ['PRODUCT_TYPE_GROUP_UPDATE'],
				action: ProductTypeGroupAction.GoUpdate()
			},
			{
				icon: 'delete',
				title: 'ACTION.DELETE',
				functions: ['PRODUCT_TYPE_GROUP_DELETE'],
				action: ProductTypeGroupAction.DeleteDialog()
			}
		]
	})),
	on(ActionbarAction.ProductTypeGroupViewDisableDelete, () => ({
		icon: '',
		title: 'PRODUCT_TYPE_GROUP.PRODUCT_TYPE_GROUP',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'PRODUCT_TYPE_GROUP.PRODUCT_TYPE_GROUP_DETAILS',
				functions: ['PRODUCT_TYPE_GROUP_VIEW'],
				actionButtonType: 'BACK',
				action: ProductTypeGroupAction.GoList()
			},
			{
				icon: 'edit',
				title: 'ACTION.EDIT',
				functions: ['PRODUCT_TYPE_GROUP_UPDATE'],
				action: ProductTypeGroupAction.GoUpdate()
			},
			{
				icon: 'delete',
				title: 'ACTION.DELETE',
				functions: ['PRODUCT_TYPE_GROUP_DELETE'],
				action: ProductTypeGroupAction.DeleteDialog(),
				isDisable: true,
				disableDescription: 'PRODUCT_TYPE_GROUP.DELETE_DISABLE_DESCRIPTION'
			}
		]
	})),
	on(ActionbarAction.ProductTypeGroupCreate, () => ({
		icon: '',
		title: 'PRODUCT_TYPE_GROUP.PRODUCT_TYPE_GROUP',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'PRODUCT_TYPE_GROUP.CREATE_PRODUCT_TYPE_GROUP',
				functions: ['PRODUCT_TYPE_GROUP_VIEW'],
				actionButtonType: 'BACK',
				action: ProductTypeGroupAction.GoBackDialog({ action: 'LIST' })
			}
		]
	})),
	on(ActionbarAction.ProductTypeGroupUpdate, () => ({
		icon: '',
		title: 'PRODUCT_TYPE_GROUP.PRODUCT_TYPE_GROUP',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'PRODUCT_TYPE_GROUP.PRODUCT_TYPE_GROUP_DETAILS',
				functions: ['PRODUCT_TYPE_GROUP_VIEW'],
				actionButtonType: 'BACK',
				action: ProductTypeGroupAction.GoBackDialog({ action: 'VIEW' })
			},
			{
				icon: 'delete',
				title: 'ACTION.DELETE',
				functions: ['PRODUCT_TYPE_GROUP_DELETE'],
				action: ProductTypeGroupAction.DeleteDialog()
			}
		]
	})),
	on(ActionbarAction.ProductTypeGroupUpdateDisableDelete, () => ({
		icon: '',
		title: 'PRODUCT_TYPE_GROUP.PRODUCT_TYPE_GROUP',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'PRODUCT_TYPE_GROUP.PRODUCT_TYPE_GROUP_DETAILS',
				functions: ['PRODUCT_TYPE_GROUP_VIEW'],
				actionButtonType: 'BACK',
				action: ProductTypeGroupAction.GoBackDialog({ action: 'VIEW' })
			},
			{
				icon: 'delete',
				title: 'ACTION.DELETE',
				functions: ['PRODUCT_TYPE_GROUP_DELETE'],
				action: ProductTypeGroupAction.DeleteDialog(),
				isDisable: true,
				disableDescription: 'PRODUCT_TYPE_GROUP.DELETE_DISABLE_DESCRIPTION'
			}
		]
	})),
	on(ActionbarAction.RewardPoolList, () => ({
		icon: '',
		title: 'REWARD_POOL.REWARD_POOL',
		actionButtons: [
			{
				icon: 'add_box',
				title: 'REWARD_POOL.ACTION.CREATE_REWARD_POOL',
				functions: ['REWARD_POOL_CREATE'],
				action: RewardPoolAction.GoCreate()
			},
			{
				icon: 'add_box',
				title: 'REWARD_POOL.ACTION.CREATE_REWARD_POOL_GROUP',
				functions: ['REWARD_POOL_GROUP_CREATE'],
				action: RewardPoolGroupAction.GoCreate()
			},
		]
	})),
	on(ActionbarAction.RewardPoolView, () => ({
		icon: '',
		title: 'REWARD_POOL.REWARD_POOL',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'REWARD_POOL.REWARD_POOL_DETAILS',
				functions: ['REWARD_POOL_VIEW'],
				actionButtonType: 'BACK',
				action: RewardPoolAction.GoList()
			},
			{
				icon: 'edit',
				title: 'ACTION.EDIT',
				functions: ['REWARD_POOL_UPDATE'],
				action: RewardPoolAction.GoUpdate()
			},
			{
				icon: 'delete',
				title: 'ACTION.DELETE',
				functions: ['REWARD_POOL_DELETE'],
				action: RewardPoolAction.DeleteDialog()
			}
		]
	})),
	on(ActionbarAction.RewardPoolViewDisableDelete, () => ({
		icon: '',
		title: 'REWARD_POOL.REWARD_POOL',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'REWARD_POOL.REWARD_POOL_DETAILS',
				functions: ['REWARD_POOL_VIEW'],
				actionButtonType: 'BACK',
				action: RewardPoolAction.GoList()
			},
			{
				icon: 'edit',
				title: 'ACTION.EDIT',
				functions: ['REWARD_POOL_UPDATE'],
				action: RewardPoolAction.GoUpdate()
			},
			{
				icon: 'delete',
				title: 'ACTION.DELETE',
				functions: ['REWARD_POOL_DELETE'],
				action: RewardPoolAction.DeleteDialog(),
				isDisable: true,
				disableDescription: 'REWARD_POOL.DELETE_DISABLE_DESCRIPTION'
			}
		]
	})),
	on(ActionbarAction.RewardPoolCreate, () => ({
		icon: '',
		title: 'REWARD_POOL.REWARD_POOL',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'REWARD_POOL.CREATE_REWARD_POOL',
				functions: ['REWARD_POOL_VIEW'],
				actionButtonType: 'BACK',
				action: RewardPoolAction.GoBackDialog({ action: 'LIST' })
			}
		]
	})),
	on(ActionbarAction.RewardPoolUpdate, () => ({
		icon: '',
		title: 'REWARD_POOL.REWARD_POOL',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'REWARD_POOL.REWARD_POOL_DETAILS',
				functions: ['REWARD_POOL_VIEW'],
				actionButtonType: 'BACK',
				action: RewardPoolAction.GoBackDialog({ action: 'VIEW' })
			},
			{
				icon: 'delete',
				title: 'ACTION.DELETE',
				functions: ['REWARD_POOL_DELETE'],
				action: RewardPoolAction.DeleteDialog()
			}
		]
	})),
	on(ActionbarAction.RewardPoolUpdateDisableDelete, () => ({
		icon: '',
		title: 'REWARD_POOL.REWARD_POOL',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'REWARD_POOL.REWARD_POOL_DETAILS',
				functions: ['REWARD_POOL_VIEW'],
				actionButtonType: 'BACK',
				action: RewardPoolAction.GoBackDialog({ action: 'VIEW' })
			},
			{
				icon: 'delete',
				title: 'ACTION.DELETE',
				functions: ['REWARD_POOL_DELETE'],
				action: RewardPoolAction.DeleteDialog(),
				isDisable: true,
				disableDescription: 'REWARD_POOL.DELETE_DISABLE_DESCRIPTION'
			}
		]
	})),
	on(ActionbarAction.RewardPoolGroupView, () => ({
		icon: '',
		title: 'REWARD_POOL.REWARD_POOL',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'REWARD_POOL.REWARD_POOL_GROUP_DETAILS',
				functions: ['REWARD_POOL_GROUP_VIEW'],
				actionButtonType: 'BACK',
				action: RewardPoolGroupAction.GoList()
			},
			{
				icon: 'edit',
				title: 'ACTION.EDIT',
				functions: ['REWARD_POOL_GROUP_UPDATE'],
				action: RewardPoolGroupAction.GoUpdate()
			},
			{
				icon: 'delete',
				title: 'ACTION.DELETE',
				functions: ['REWARD_POOL_GROUP_DELETE'],
				action: RewardPoolGroupAction.DeleteDialog()
			}
		]
	})),
	on(ActionbarAction.RewardPoolGroupCreate, () => ({
		icon: '',
		title: 'REWARD_POOL.REWARD_POOL',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'REWARD_POOL.CREATE_REWARD_POOL_GROUP',
				functions: ['REWARD_POOL_GROUP_VIEW'],
				actionButtonType: 'BACK',
				action: RewardPoolGroupAction.GoBackDialog({ action: 'LIST' })
			}
		]
	})),
	on(ActionbarAction.RewardPoolGroupUpdate, () => ({
		icon: '',
		title: 'REWARD_POOL.REWARD_POOL',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'REWARD_POOL.REWARD_POOL_GROUP_DETAILS',
				functions: ['REWARD_POOL_GROUP_VIEW'],
				actionButtonType: 'BACK',
				action: RewardPoolGroupAction.GoBackDialog({ action: 'VIEW' })
			},
			{
				icon: 'delete',
				title: 'ACTION.DELETE',
				functions: ['REWARD_POOL_GROUP_DELETE'],
				action: RewardPoolGroupAction.DeleteDialog()
			}
		]
	})),
	on(ActionbarAction.RewardPoolGroupUpdateGlRedemption, () => ({
		icon: '',
		title: 'REWARD_POOL.REWARD_POOL',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'REWARD_POOL.ASSIGN_GL_REDEMPTION',
				functions: ['REWARD_POOL_GROUP_VIEW'],
				actionButtonType: 'BACK',
				action: RewardPoolGroupAction.GoBackDialog({ action: 'EDIT' })
			},
			{
				icon: 'save',
				title: 'ACTION.SAVE',
				functions: ['REWARD_POOL_GROUP_UPDATE'],
				action: RewardPoolGroupAction.AssignedGlRedemptionToRewardPool()
			},
		]
	})),
	on(ActionbarAction.RewardPoolGroupUpdateGlRedemptionFromView, () => ({
		icon: '',
		title: 'REWARD_POOL.REWARD_POOL',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'REWARD_POOL.ASSIGN_GL_REDEMPTION',
				functions: ['REWARD_POOL_GROUP_VIEW'],
				actionButtonType: 'BACK',
				action: RewardPoolGroupAction.GoBackDialog({ action: 'EDIT_TO_GL_VIEW' })
			},
			{
				icon: 'save',
				title: 'ACTION.SAVE',
				functions: ['REWARD_POOL_GROUP_UPDATE'],
				action: RewardPoolGroupAction.AssignedGlRedemptionToRewardPool()
			},
		]
	})),
	on(ActionbarAction.RewardPoolGroupViewGlRedemption, () => ({
		icon: '',
		title: 'REWARD_POOL.REWARD_POOL',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'REWARD_POOL.ASSIGN_GL_REDEMPTION',
				functions: ['REWARD_POOL_GROUP_VIEW'],
				actionButtonType: 'BACK',
				action: RewardPoolGroupAction.GoView()
			},
			{
				icon: 'edit',
				title: 'ACTION.EDIT',
				functions: ['REWARD_POOL_GROUP_UPDATE'],
				action: RewardPoolGroupAction.GoUpdateGlRedemption()
			},
			{
				icon: 'delete',
				title: 'ACTION.DELETE',
				functions: ['REWARD_POOL_GROUP_DELETE'],
				action: RewardPoolGroupAction.RemoveRewardPoolFromViewDialog()
			},
		]
	})),
	on(ActionbarAction.RewardPoolGroupDisableUpdateGlRedemption, () => ({
		icon: '',
		title: 'REWARD_POOL.REWARD_POOL',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'REWARD_POOL.ASSIGN_GL_REDEMPTION',
				functions: ['REWARD_POOL_GROUP_VIEW'],
				actionButtonType: 'BACK',
				action: RewardPoolGroupAction.GoBackDialog({ action: 'EDIT' })
			},
			{
				icon: 'save',
				title: 'ACTION.SAVE',
				isDisable: true,
				functions: ['REWARD_POOL_GROUP_UPDATE'],
				action: RewardPoolGroupAction.AssignedGlRedemptionToRewardPool()
			},
		]
	})),
	on(ActionbarAction.RewardPoolGroupDisableUpdateGlRedemptionFromView, () => ({
		icon: '',
		title: 'REWARD_POOL.REWARD_POOL',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'REWARD_POOL.ASSIGN_GL_REDEMPTION',
				functions: ['REWARD_POOL_GROUP_VIEW'],
				actionButtonType: 'BACK',
				action: RewardPoolGroupAction.GoBackDialog({ action: 'EDIT_TO_GL_VIEW' })
			},
			{
				icon: 'save',
				title: 'ACTION.SAVE',
				isDisable: true,
				functions: ['REWARD_POOL_GROUP_UPDATE'],
				action: RewardPoolGroupAction.AssignedGlRedemptionToRewardPool()
			},
		]
	})),
	on(ActionbarAction.CustomerManagementList, () => ({
		icon: '',
		title: 'CUSTOMER_MANAGEMENT.CUSTOMER_MANAGEMENT'
	})),
	on(ActionbarAction.CustomerManagementView, () => ({
		icon: '',
		title: 'CUSTOMER_MANAGEMENT.CUSTOMER_MANAGEMENT',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'CUSTOMER_MANAGEMENT.BASIC_INFORMATION',
				functions: ['BASIC_INFO_VIEW'],
				actionButtonType: 'BACK',
				action: BasicInfoAction.GoList()
			}
		]
	})),
	on(ActionbarAction.CustomerManagementCardView, () => ({
		icon: '',
		title: 'CUSTOMER_MANAGEMENT.CUSTOMER_MANAGEMENT',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'CUSTOMER_MANAGEMENT.CARD_INFORMATION_DETAILS',
				functions: ['BASIC_INFO_VIEW'],
				actionButtonType: 'BACK',
				action: BasicInfoAction.GoView()
			},
			{
				icon: 'money_off',
				title: 'CUSTOMER_MANAGEMENT.WAIVER',
				functions: ['WAIVER_ACTION'],
				action: BasicInfoAction.GetWaiverTypeList()
			},
			{
				icon: 'pageview',
				title: 'CUSTOMER_MANAGEMENT.ACCOUNT_OVERVIEW',
				functions: ['BASIC_INFO_VIEW'],
				action: BasicInfoAction.GoCardStatementView()
			},
			{
				icon: 'tune',
				title: 'CUSTOMER_MANAGEMENT.ADJUSTMENT',
				functions: ['ACCOUNT_ADJUSTMENT_APPROVE', 'ACCOUNT_ADJUSTMENT'],
				action: BasicInfoAction.GoPointsAdjustment()
			},
			{
				icon: 'monetization_on',
				title: 'CUSTOMER_MANAGEMENT.TRANSACTION',
				functions: ['BASIC_INFO_VIEW'],
				action: BasicInfoAction.GoTransactionView()
			}
		]
	})),
	on(ActionbarAction.CustomerManagementCardViewWithoutPointsAdjustment, () => ({
		icon: '',
		title: 'CUSTOMER_MANAGEMENT.CUSTOMER_MANAGEMENT',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'CUSTOMER_MANAGEMENT.CARD_INFORMATION_DETAILS',
				functions: ['BASIC_INFO_VIEW'],
				actionButtonType: 'BACK',
				action: BasicInfoAction.GoView()
			},
			{
				icon: 'money_off',
				title: 'CUSTOMER_MANAGEMENT.WAIVER',
				functions: ['WAIVER_ACTION'],
				action: BasicInfoAction.GetWaiverTypeList()
			},
			{
				icon: 'pageview',
				title: 'CUSTOMER_MANAGEMENT.ACCOUNT_OVERVIEW',
				functions: ['BASIC_INFO_VIEW'],
				action: BasicInfoAction.GoCardStatementView()
			},
			{
				icon: 'monetization_on',
				title: 'CUSTOMER_MANAGEMENT.TRANSACTION',
				functions: ['BASIC_INFO_VIEW'],
				action: BasicInfoAction.GoTransactionView()
			}
		]
	})),
	on(ActionbarAction.CustomerManagementTransactionView, () => ({
		icon: '',
		title: 'CUSTOMER_MANAGEMENT.CUSTOMER_MANAGEMENT',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'CUSTOMER_MANAGEMENT.TRANSACTION',
				functions: ['BASIC_INFO_VIEW'],
				actionButtonType: 'BACK',
				action: BasicInfoAction.GoCardView()
			}
		]
	})),
	on(ActionbarAction.CustomerManagementCatagloueRedemptionGridListRewardPool, () => ({
		icon: '',
		title: 'CUSTOMER_MANAGEMENT.CUSTOMER_MANAGEMENT',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'CUSTOMER_MANAGEMENT.CATALOGUE_PRODUCTS',
				functions: ['BASIC_INFO_VIEW'],
				actionButtonType: 'BACK',
				action: BasicInfoAction.GoRewardPoolView()
			}
		]
	})),
	on(ActionbarAction.CustomerManagementCatagloueRedemptionGridListRewardPoolGroup, () => ({
		icon: '',
		title: 'CUSTOMER_MANAGEMENT.CUSTOMER_MANAGEMENT',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'CUSTOMER_MANAGEMENT.CATALOGUE_PRODUCTS',
				functions: ['BASIC_INFO_VIEW'],
				actionButtonType: 'BACK',
				action: BasicInfoAction.GoRewardPoolGroupView()
			}
		]
	})),
	on(ActionbarAction.CustomerManagementCatagloueRedemptionDetails, () => ({
		icon: '',
		title: 'CUSTOMER_MANAGEMENT.CUSTOMER_MANAGEMENT',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'CUSTOMER_MANAGEMENT.REDEMPTION_DETAILS',
				functions: ['BASIC_INFO_VIEW'],
				actionButtonType: 'BACK',
				action: BasicInfoAction.GoCatalogueRedemptionGridList()
			},
			{
				icon: 'add_box',
				title: 'CUSTOMER_MANAGEMENT.SUBMIT_REQUEST',
				functions: ['BASIC_INFO_VIEW'],
				action: BasicInfoAction.CatalogueRedemptionDialog()
			}
		]
	})),
	on(ActionbarAction.CustomerManagementCatagloueRedemptionDetailsDisable, () => ({
		icon: '',
		title: 'CUSTOMER_MANAGEMENT.CUSTOMER_MANAGEMENT',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'CUSTOMER_MANAGEMENT.REDEMPTION_DETAILS',
				functions: ['BASIC_INFO_VIEW'],
				actionButtonType: 'BACK',
				action: BasicInfoAction.GoCatalogueRedemptionGridList()
			},
			{
				icon: 'add_box',
				title: 'CUSTOMER_MANAGEMENT.SUBMIT_REQUEST',
				isDisable: true,
				functions: ['BASIC_INFO_VIEW'],
				action: BasicInfoAction.CatalogueRedemptionDialog()
			}
		]
	})),
	on(ActionbarAction.CustomerManagementCataglouePartnerRedemptionDetails, () => ({
		icon: '',
		title: 'CUSTOMER_MANAGEMENT.CUSTOMER_MANAGEMENT',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'CUSTOMER_MANAGEMENT.REDEMPTION_DETAILS',
				functions: ['BASIC_INFO_VIEW'],
				actionButtonType: 'BACK',
				action: BasicInfoAction.GoCatalogueRedemptionGridList()
			},
			{
				icon: 'add_box',
				title: 'CUSTOMER_MANAGEMENT.SUBMIT_REQUEST',
				functions: ['BASIC_INFO_VIEW'],
				action: BasicInfoAction.CatalogueRedemptionDialog()
			}
		]
	})),
	on(ActionbarAction.CustomerManagementCataglouePartnerRedemptionDetailsDisable, () => ({
		icon: '',
		title: 'CUSTOMER_MANAGEMENT.CUSTOMER_MANAGEMENT',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'CUSTOMER_MANAGEMENT.REDEMPTION_DETAILS',
				functions: ['BASIC_INFO_VIEW'],
				actionButtonType: 'BACK',
				action: BasicInfoAction.GoCatalogueRedemptionGridList()
			},
			{
				icon: 'add_box',
				title: 'CUSTOMER_MANAGEMENT.SUBMIT_REQUEST',
				isDisable: true,
				functions: ['BASIC_INFO_VIEW'],
				action: BasicInfoAction.CatalogueRedemptionDialog()
			}
		]
	})),
	on(ActionbarAction.CustomerManagementPointsAdjustment, () => ({
		icon: '',
		title: 'CUSTOMER_MANAGEMENT.CUSTOMER_MANAGEMENT',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'CUSTOMER_MANAGEMENT.POINTS_ADJUSTMENT_DETAILS',
				functions: ['ACCOUNT_ADJUSTMENT_APPROVE', 'ACCOUNT_ADJUSTMENT'],
				actionButtonType: 'BACK',
				action: BasicInfoAction.GoCardView()
			}
		]
	})),
	on(ActionbarAction.CustomerManagementBankwidePointsAdjustment, () => ({
		icon: '',
		title: 'CUSTOMER_MANAGEMENT.CUSTOMER_MANAGEMENT',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'CUSTOMER_MANAGEMENT.POINTS_ADJUSTMENT_DETAILS',
				functions: ['ACCOUNT_ADJUSTMENT_APPROVE', 'ACCOUNT_ADJUSTMENT'],
				actionButtonType: 'BACK',
				action: BasicInfoAction.GoAccountView()
			}
		]
	})),
	on(ActionbarAction.CustomerManagementStatementTransactionView, () => ({
		icon: '',
		title: 'CUSTOMER_MANAGEMENT.CUSTOMER_MANAGEMENT',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'CUSTOMER_MANAGEMENT.ACCOUNT_OVERVIEW',
				functions: ['BASIC_INFO_VIEW'],
				actionButtonType: 'BACK',
				action: BasicInfoAction.GoCardView()
			}
		]
	})),
	on(ActionbarAction.CustomerManagementAccountStatementTransactionView, () => ({
		icon: '',
		title: 'CUSTOMER_MANAGEMENT.CUSTOMER_MANAGEMENT',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'CUSTOMER_MANAGEMENT.ACCOUNT_OVERVIEW',
				functions: ['BASIC_INFO_VIEW'],
				actionButtonType: 'BACK',
				action: BasicInfoAction.GoAccountView()
			}
		]
	})),
	on(ActionbarAction.CustomerManagementTransactionDetailView, () => ({
		icon: '',
		title: 'CUSTOMER_MANAGEMENT.CUSTOMER_MANAGEMENT',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'CUSTOMER_MANAGEMENT.STATEMENT_TRANSACTIONS.STATEMENT_DETAILS',
				functions: ['BASIC_INFO_VIEW'],
				actionButtonType: 'BACK',
				action: BasicInfoAction.GoCardStatementView()
			}
		]
	})),
	on(ActionbarAction.CustomerManagementAccountTransactionDetailView, () => ({
		icon: '',
		title: 'CUSTOMER_MANAGEMENT.CUSTOMER_MANAGEMENT',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'CUSTOMER_MANAGEMENT.ACCOUNT_STATEMENT',
				functions: ['BASIC_INFO_VIEW'],
				actionButtonType: 'BACK',
				action: BasicInfoAction.GoAccountStatementView()
			}
		]
	})),
	on(ActionbarAction.CustomerManagementRewardPoolStatementTransactionView, () => ({
		icon: '',
		title: 'CUSTOMER_MANAGEMENT.CUSTOMER_MANAGEMENT',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'CUSTOMER_MANAGEMENT.REWARD_POOL_OVERVIEW',
				functions: ['BASIC_INFO_VIEW'],
				actionButtonType: 'BACK',
				action: BasicInfoAction.GoRewardPoolView()
			}
		]
	})),
	on(ActionbarAction.CustomerManagementAccountView, () => ({
		icon: '',
		title: 'CUSTOMER_MANAGEMENT.CUSTOMER_MANAGEMENT',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'CUSTOMER_MANAGEMENT.ACCOUNT_INFORMATION_DETAILS',
				functions: ['BASIC_INFO_VIEW'],
				actionButtonType: 'BACK',
				action: BasicInfoAction.GoView()
			},
			{
				icon: 'pageview',
				title: 'CUSTOMER_MANAGEMENT.ACCOUNT_OVERVIEW',
				functions: ['BASIC_INFO_VIEW'],
				action: BasicInfoAction.GoAccountStatementView()
			},
			{
				icon: 'tune',
				title: 'CUSTOMER_MANAGEMENT.ADJUSTMENT',
				functions: ['ACCOUNT_ADJUSTMENT_APPROVE', 'ACCOUNT_ADJUSTMENT'],
				action: BasicInfoAction.GoPointsAdjustment()
			}
		]
	})),
	on(ActionbarAction.CustomerManagementAccountViewWithoutPointsAdjustment, () => ({
		icon: '',
		title: 'CUSTOMER_MANAGEMENT.CUSTOMER_MANAGEMENT',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'CUSTOMER_MANAGEMENT.ACCOUNT_INFORMATION_DETAILS',
				functions: ['BASIC_INFO_VIEW'],
				actionButtonType: 'BACK',
				action: BasicInfoAction.GoView()
			},
			{
				icon: 'pageview',
				title: 'CUSTOMER_MANAGEMENT.ACCOUNT_OVERVIEW',
				functions: ['BASIC_INFO_VIEW'],
				action: BasicInfoAction.GoAccountStatementView()
			},
		]
	})),
	on(ActionbarAction.CustomerManagementRewardPoolAccumulationBucketView, () => ({
		icon: '',
		title: 'CUSTOMER_MANAGEMENT.CUSTOMER_MANAGEMENT',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'CUSTOMER_MANAGEMENT.LOYALTY_ACCUMULATION_BUCKET',
				functions: ['BASIC_INFO_VIEW'],
				actionButtonType: 'BACK',
				action: BasicInfoAction.GoRewardPoolView()
			}
		]
	})),
	on(ActionbarAction.CustomerManagementAccountAccumulationBucketView, () => ({
		icon: '',
		title: 'CUSTOMER_MANAGEMENT.CUSTOMER_MANAGEMENT',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'CUSTOMER_MANAGEMENT.LOYALTY_ACCUMULATION_BUCKET',
				functions: ['BASIC_INFO_VIEW'],
				actionButtonType: 'BACK',
				action: BasicInfoAction.GoAccountView()
			}
		]
	})),
	on(ActionbarAction.CustomerManagementRedemptionHistoryView, () => ({
		icon: '',
		title: 'CUSTOMER_MANAGEMENT.CUSTOMER_MANAGEMENT',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'CUSTOMER_MANAGEMENT.REDEMPTION_HISTORY',
				functions: ['BASIC_INFO_VIEW'],
				actionButtonType: 'BACK',
				action: BasicInfoAction.GoRewardPoolView()
			}
		]
	})),
	on(ActionbarAction.CustomerManagementRedemptionHistoryOverview, () => ({
		icon: '',
		title: 'CUSTOMER_MANAGEMENT.CUSTOMER_MANAGEMENT',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'CUSTOMER_MANAGEMENT.REDEMPTION_HISTORY',
				functions: ['BASIC_INFO_VIEW'],
				actionButtonType: 'BACK',
				action: BasicInfoAction.GoRewardPoolGroupView()
			}
		]
	})),
	on(ActionbarAction.CustomerManagementRewardPoolView, () => ({
		icon: '',
		title: 'CUSTOMER_MANAGEMENT.CUSTOMER_MANAGEMENT',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'CUSTOMER_MANAGEMENT.REWARD_POOL_INFORMATION',
				functions: ['BASIC_INFO_VIEW'],
				actionButtonType: 'BACK',
				action: BasicInfoAction.GoView()
			},
			{
				icon: 'pageview',
				title: 'CUSTOMER_MANAGEMENT.REWARD_POOL_OVERVIEW',
				functions: ['BASIC_INFO_VIEW'],
				action: BasicInfoAction.GoRewardPoolStatementView()
			},
						{
				icon: 'redeem',
				title: 'SIDE_NAV.DASHBOARD.REDEMPTION',
				functions: ['CATALOGUE_REDEMPTION_ACTION', 'PARTNER_REDEMPTION_ACTION'],
				actionButtonType: 'SELECTION_PARENT',
			},
			{
				icon: 'redeem',
				title: 'CUSTOMER_MANAGEMENT.CATALOGUE_REDEMPTION',
				functions: ['CATALOGUE_REDEMPTION_ACTION'],
				actionButtonType: 'SELECTION',
				action: BasicInfoAction.GetProductCatalogueList()
			},
			{
				icon: 'redeem',
				title: 'CUSTOMER_MANAGEMENT.PARTNER_REDEMPTION',
				functions: ['PARTNER_REDEMPTION_ACTION'],
				actionButtonType: 'SELECTION',
				action: BasicInfoAction.GetPartnerRedemptionList({from: 'Partner'})
			},
			{
				icon: 'history',
				title: 'CUSTOMER_MANAGEMENT.REDEMPTION_HISTORY',
				functions: ['REDEMPTION_HISTORY_VIEW'],
				action: BasicInfoAction.GoRedemptionHistoryView()
			}
		]
	})),
	on(ActionbarAction.CustomerManagementConditionalRewardView, () => ({
		icon: '',
		title: 'CUSTOMER_MANAGEMENT.CUSTOMER_MANAGEMENT',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'CUSTOMER_MANAGEMENT.CONDITIONAL_REWARD_INFORMATIONS',
				functions: ['BASIC_INFO_VIEW'],
				actionButtonType: 'BACK',
				action: BasicInfoAction.GoView()
			}
		]
	})),
	on(ActionbarAction.CustomerManagementRewardPoolGroupView, () => ({
		icon: '',
		title: 'CUSTOMER_MANAGEMENT.CUSTOMER_MANAGEMENT',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'CUSTOMER_MANAGEMENT.REWARD_POOL_GROUP_INFORMATION',
				functions: ['BASIC_INFO_VIEW'],
				actionButtonType: 'BACK',
				action: BasicInfoAction.GoView()
			},
			{
				icon: 'redeem',
				title: 'SIDE_NAV.DASHBOARD.REDEMPTION',
				functions: ['CATALOGUE_REDEMPTION_ACTION', 'PARTNER_REDEMPTION_ACTION'],
				actionButtonType: 'SELECTION_PARENT',
			},
			{
				icon: 'redeem',
				title: 'CUSTOMER_MANAGEMENT.CATALOGUE_REDEMPTION',
				functions: ['CATALOGUE_REDEMPTION_ACTION'],
				actionButtonType: 'SELECTION',
				action: BasicInfoAction.GetProductCatalogueList()
			},
			{
				icon: 'redeem',
				title: 'CUSTOMER_MANAGEMENT.PARTNER_REDEMPTION',
				functions: ['PARTNER_REDEMPTION_ACTION'],
				actionButtonType: 'SELECTION',
				action: BasicInfoAction.GetPartnerRedemptionList({from: 'Partner'})
			},
			{
				icon: 'history',
				title: 'CUSTOMER_MANAGEMENT.REDEMPTION_HISTORY',
				functions: ['REDEMPTION_HISTORY_VIEW'],
				action: BasicInfoAction.GoRedemptionHistoryView()
			}
		]
	})),
	on(ActionbarAction.CustomerManagementRewardPoolGroupOverview, () => ({
		icon: '',
		title: 'CUSTOMER_MANAGEMENT.CUSTOMER_MANAGEMENT',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'CUSTOMER_MANAGEMENT.REWARD_POOL_GROUP_INFORMATION',
				functions: ['BASIC_INFO_VIEW'],
				actionButtonType: 'BACK',
				action: BasicInfoAction.GoRewardPoolGroupView()
			},
			{
				icon: 'pageview',
				title: 'CUSTOMER_MANAGEMENT.REWARD_POOL_OVERVIEW',
				functions: ['BASIC_INFO_VIEW'],
				action: BasicInfoAction.GoRewardPoolStatementView()
			}
		]
	})),
	on(ActionbarAction.FieldMappingList, () => ({
		icon: '',
		title: 'FIELD_MAPPING.FIELD_MAPPING'
	})),
	on(ActionbarAction.FieldMappingView, () => ({
		icon: '',
		title: 'FIELD_MAPPING.FIELD_MAPPING',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'FIELD_MAPPING.FIELD_MAPPING_DETAILS',
				functions: ['FIELD_MAPPING_VIEW'],
				actionButtonType: 'BACK',
				action: FieldMappingAction.GoList()
			}
		]
	})),
	on(ActionbarAction.PointAdjustmentFileUploadPost, () => ({
		icon: '',
		title: 'ADJUSTMENT.POINT_ADJUSTMENT_FILE_UPLOAD'
	})),
	on(ActionbarAction.PointAdjustmentFileUploadView, () => ({
		icon: '',
		title: 'ADJUSTMENT.POINT_ADJUSTMENT_FILE_UPLOAD',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'ADJUSTMENT.POINT_ADJUSTMENT_FILE_UPLOAD_DETAILS',
				functions: ['ADJUSTMENT_ACTION', 'ADJUSTMENT_APPROVE'],
				actionButtonType: 'BACK',
				action: PointAdjustmentFileAction.GoList()
			}
		]
	})),
	on(ActionbarAction.RedeemPartnerList, () => ({
		icon: '',
		title: 'REDEEM_PARTNER.REDEEM_PARTNER',
		actionButtons: [
			{
				icon: 'add_box',
				title: 'REDEEM_PARTNER.ACTION.CREATE_REDEEM_PARTNER',
				functions: ['REDEEM_PARTNER_CREATE'],
				action: RedeemPartnerAction.GoCreate()
			}
		]
	})),
	on(ActionbarAction.RedeemPartnerView, () => ({
		icon: '',
		title: 'REDEEM_PARTNER.REDEEM_PARTNER',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'REDEEM_PARTNER.REDEEM_PARTNER_DETAILS',
				functions: ['REDEEM_PARTNER_VIEW'],
				actionButtonType: 'BACK',
				action: RedeemPartnerAction.GoList()
			},
			{
				icon: 'edit',
				title: 'ACTION.EDIT',
				functions: ['REDEEM_PARTNER_UPDATE'],
				action: RedeemPartnerAction.GoUpdate()
			},
			{
				icon: 'delete',
				title: 'ACTION.DELETE',
				functions: ['REDEEM_PARTNER_DELETE'],
				action: RedeemPartnerAction.DeleteDialog()
			}
		]
	})),
	on(ActionbarAction.RedeemWllcorePartnerView, () => ({
		icon: '',
		title: 'REDEEM_PARTNER.REDEEM_PARTNER',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'REDEEM_PARTNER.REDEEM_PARTNER_DETAILS',
				functions: ['REDEEM_PARTNER_VIEW'],
				actionButtonType: 'BACK',
				action: RedeemPartnerAction.GoList()
			},
			{
				icon: 'edit',
				title: 'ACTION.EDIT',
				functions: ['REDEEM_PARTNER_UPDATE'],
				action: RedeemPartnerAction.GoUpdate()
			}
		]
	})),
	on(ActionbarAction.RedeemPartnerCreate, () => ({
		icon: '',
		title: 'REDEEM_PARTNER.REDEEM_PARTNER',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'REDEEM_PARTNER.REDEEM_PARTNER_DETAILS',
				functions: ['REDEEM_PARTNER_VIEW'],
				actionButtonType: 'BACK',
				action: RedeemPartnerAction.GoList()
			}
		]
	})),
	on(ActionbarAction.RedeemPartnerUpdate, () => ({
		icon: '',
		title: 'REDEEM_PARTNER.REDEEM_PARTNER',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'REDEEM_PARTNER.REDEEM_PARTNER_DETAILS',
				functions: ['REDEEM_PARTNER_VIEW'],
				actionButtonType: 'BACK',
				action: RedeemPartnerAction.GoBackDialog({ action: 'VIEW' })
			},
			{
				icon: 'delete',
				title: 'ACTION.DELETE',
				functions: ['REDEEM_PARTNER_DELETE'],
				action: RedeemPartnerAction.DeleteDialog()
			}
		]
	})),
	on(ActionbarAction.RedeemWllcorePartnerUpdate, () => ({
		icon: '',
		title: 'REDEEM_PARTNER.REDEEM_PARTNER',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'REDEEM_PARTNER.REDEEM_PARTNER_DETAILS',
				functions: ['REDEEM_PARTNER_VIEW'],
				actionButtonType: 'BACK',
				action: RedeemPartnerAction.GoBackDialog({ action: 'VIEW' })
			}
		]
	})),
	on(ActionbarAction.AutoRedemptionList, () => ({
		icon: '',
		title: 'AUTO_REDEMPTION.AUTO_REDEMPTION',
		actionButtons: [
			{
				icon: 'add_box',
				title: 'AUTO_REDEMPTION.ACTION.CREATE_AUTO_REDEMPTION',
				functions: ['AUTO_REDEMPTION_CREATE'],
				action: AutoRedemptionAction.GoCreate()
			}
		]
	})),
	on(ActionbarAction.AutoRedemptionView, () => ({
		icon: '',
		title: 'AUTO_REDEMPTION.AUTO_REDEMPTION',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'AUTO_REDEMPTION.AUTO_REDEMPTION_DETAILS',
				functions: ['AUTO_REDEMPTION_VIEW'],
				actionButtonType: 'BACK',
				action: AutoRedemptionAction.GoList()
			},
			{
				icon: 'edit',
				title: 'ACTION.EDIT',
				functions: ['AUTO_REDEMPTION_UPDATE'],
				action: AutoRedemptionAction.GoUpdate()
			},
			{
				icon: 'delete',
				title: 'ACTION.DELETE',
				functions: ['AUTO_REDEMPTION_DELETE'],
				action: AutoRedemptionAction.DeleteDialog()
			},
		]
	})),
	on(ActionbarAction.AutoRedemptionCreate, () => ({
		icon: '',
		title: 'AUTO_REDEMPTION.AUTO_REDEMPTION',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'AUTO_REDEMPTION.CREATE_AUTO_REDEMPTION',
				functions: ['AUTO_REDEMPTION_VIEW'],
				actionButtonType: 'BACK',
				action: AutoRedemptionAction.GoBackDialog({ action: 'LIST' })
			},
		]
	})),
	on(ActionbarAction.AutoRedemptionUpdate, () => ({
		icon: '',
		title: 'AUTO_REDEMPTION.AUTO_REDEMPTION',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'AUTO_REDEMPTION.AUTO_REDEMPTION_DETAILS',
				functions: ['AUTO_REDEMPTION_VIEW'],
				actionButtonType: 'BACK',
				action: AutoRedemptionAction.GoBackDialog({ action: 'VIEW' })
			},
			{
				icon: 'delete',
				title: 'ACTION.DELETE',
				functions: ['AUTO_REDEMPTION_DELETE'],
				action: AutoRedemptionAction.DeleteDialog()
			}
		]
	})),
	on(ActionbarAction.ProductCatalogueList, () => ({
		icon: '',
		title: 'PRODUCT_CATALOGUE.PRODUCT_CATALOGUE',
		actionButtons: [
			{
				icon: 'add_box',
				title: 'PRODUCT_CATALOGUE.ACTION.CREATE_PRODUCT_CATALOGUE',
				functions: ['PRODUCT_CATALOGUE_CREATE'],
				action: ProductCatalogueAction.GoCreate(),
			},
			{
				icon: 'add_box',
				title: 'PRODUCT_PARTNER.ACTION.CREATE_PRODUCT_PARTNER',
				functions: ['PRODUCT_CATALOGUE_CREATE'],
				action: ProductPartnerAction.GoCreate(),
			},
		]
	})),
	on(ActionbarAction.ProductCatalogueView, () => ({
		icon: '',
		title: 'PRODUCT_CATALOGUE.PRODUCT_CATALOGUE',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'PRODUCT_CATALOGUE.PRODUCT_CATALOGUE_DETAILS',
				functions: ['PRODUCT_CATALOGUE_VIEW'],
				actionButtonType: 'BACK',
				action: ProductCatalogueAction.GoList()
			},
			{
				icon: 'edit',
				title: 'ACTION.EDIT',
				functions: ['PRODUCT_CATALOGUE_UPDATE'],
				action: ProductCatalogueAction.GoUpdate()
			},
			{
				icon: 'delete',
				title: 'ACTION.DELETE',
				functions: ['PRODUCT_CATALOGUE_DELETE'],
				action: ProductCatalogueAction.DeleteDialog()
			}
		]
	})),
	on(ActionbarAction.ProductCatalogueCreate, () => ({
		icon: '',
		title: 'PRODUCT_CATALOGUE.PRODUCT_CATALOGUE',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'PRODUCT_CATALOGUE.CREATE_PRODUCT_CATALOGUE',
				functions: ['PRODUCT_CATALOGUE_VIEW'],
				actionButtonType: 'BACK',
				action: ProductCatalogueAction.GoBackDialog({ action: 'LIST' })
			}
		]
	})),
	on(ActionbarAction.ProductCatalogueUpdate, () => ({
		icon: '',
		title: 'PRODUCT_CATALOGUE.PRODUCT_CATALOGUE',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'PRODUCT_CATALOGUE.PRODUCT_CATALOGUE_DETAILS',
				functions: ['PRODUCT_CATALOGUE_VIEW'],
				actionButtonType: 'BACK',
				action: ProductCatalogueAction.GoBackDialog({ action: 'VIEW' })
			},
			{
				icon: 'delete',
				title: 'ACTION.DELETE',
				functions: ['PRODUCT_CATALOGUE_DELETE'],
				action: ProductCatalogueAction.DeleteDialog()
			}
		]
	})),
	on(ActionbarAction.ProductPartnerView, () => ({
		icon: '',
		title: 'PRODUCT_PARTNER.PRODUCT_PARTNER',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'PRODUCT_PARTNER.PRODUCT_PARTNER_DETAILS',
				functions: ['PRODUCT_CATALOGUE_VIEW'],
				actionButtonType: 'BACK',
				action: ProductPartnerAction.GoList()
			},
			{
				icon: 'edit',
				title: 'ACTION.EDIT',
				functions: ['PRODUCT_CATALOGUE_UPDATE'],
				action: ProductPartnerAction.GoUpdate()
			},
			{
				icon: 'delete',
				title: 'ACTION.DELETE',
				functions: ['PRODUCT_CATALOGUE_DELETE'],
				action: ProductPartnerAction.DeleteDialog()
			}
		]
	})),
	on(ActionbarAction.ProductPartnerCreate, () => ({
		icon: '',
		title: 'PRODUCT_PARTNER.PRODUCT_PARTNER',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'PRODUCT_PARTNER.CREATE_PRODUCT_PARTNER',
				functions: ['PRODUCT_CATALOGUE_VIEW'],
				actionButtonType: 'BACK',
				action: ProductPartnerAction.GoBackDialog({ action: 'LIST' })
			}
		]
	})),
	on(ActionbarAction.ProductPartnerUpdate, () => ({
		icon: '',
		title: 'PRODUCT_PARTNER.PRODUCT_PARTNER',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'PRODUCT_PARTNER.PRODUCT_PARTNER_DETAILS',
				functions: ['PRODUCT_CATALOGUE_VIEW'],
				actionButtonType: 'BACK',
				action: ProductPartnerAction.GoBackDialog({ action: 'VIEW' })
			},
			{
				icon: 'delete',
				title: 'ACTION.DELETE',
				functions: ['PRODUCT_CATALOGUE_DELETE'],
				action: ProductPartnerAction.DeleteDialog()
			}
		]
	})),
	on(ActionbarAction.FulfilmentPartnerList, () => ({
		icon: '',
		title: 'FULFILMENT_PARTNER.FULFILMENT_PARTNER',
		actionButtons: [
			{
				icon: 'add_box',
				title: 'FULFILMENT_PARTNER.ACTION.CREATE_FULFILMENT_PARTNER',
				functions: ['FULFILMENT_PARTNER_CREATE'],
				action: FulfilmentPartnerAction.GoCreate(),
			},
		]
	})),
	on(ActionbarAction.FulfilmentPartnerView, () => ({
		icon: '',
		title: 'FULFILMENT_PARTNER.FULFILMENT_PARTNER',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'FULFILMENT_PARTNER.FULFILMENT_PARTNER_DETAILS',
				functions: ['FULFILMENT_PARTNER_VIEW'],
				actionButtonType: 'BACK',
				action: FulfilmentPartnerAction.GoList()
			},
			{
				icon: 'edit',
				title: 'ACTION.EDIT',
				functions: ['FULFILMENT_PARTNER_UPDATE'],
				action: FulfilmentPartnerAction.GoUpdate()
			},
			{
				icon: 'delete',
				title: 'ACTION.DELETE',
				functions: ['FULFILMENT_PARTNER_DELETE'],
				action: FulfilmentPartnerAction.DeleteDialog()
			}
		]
	})),
	on(ActionbarAction.FulfilmentPartnerViewDisableDelete, () => ({
		icon: '',
		title: 'FULFILMENT_PARTNER.FULFILMENT_PARTNER',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'FULFILMENT_PARTNER.FULFILMENT_PARTNER_DETAILS',
				functions: ['FULFILMENT_PARTNER_VIEW'],
				actionButtonType: 'BACK',
				action: FulfilmentPartnerAction.GoList()
			},
			{
				icon: 'edit',
				title: 'ACTION.EDIT',
				functions: ['FULFILMENT_PARTNER_UPDATE'],
				action: FulfilmentPartnerAction.GoUpdate()
			},
			{
				icon: 'delete',
				title: 'ACTION.DELETE',
				functions: ['FULFILMENT_PARTNER_DELETE'],
				isDisable: true,
				action: FulfilmentPartnerAction.DeleteDialog(),
				disableDescription: 'FULFILMENT_PARTNER.DELETE_DISABLE_DESCRIPTION'
			}
		]
	})),
	on(ActionbarAction.FulfilmentPartnerCreate, () => ({
		icon: '',
		title: 'FULFILMENT_PARTNER.FULFILMENT_PARTNER',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'FULFILMENT_PARTNER.CREATE_FULFILMENT_PARTNER',
				functions: ['FULFILMENT_PARTNER_VIEW'],
				actionButtonType: 'BACK',
				action: FulfilmentPartnerAction.GoBackDialog({ action: 'LIST' })
			}
		]
	})),
	on(ActionbarAction.FulfilmentPartnerUpdate, () => ({
		icon: '',
		title: 'FULFILMENT_PARTNER.FULFILMENT_PARTNER',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'FULFILMENT_PARTNER.FULFILMENT_PARTNER_DETAILS',
				functions: ['FULFILMENT_PARTNER_VIEW'],
				actionButtonType: 'BACK',
				action: FulfilmentPartnerAction.GoBackDialog({ action: 'VIEW' })
			},
			{
				icon: 'delete',
				title: 'ACTION.DELETE',
				functions: ['FULFILMENT_PARTNER_DELETE'],
				action: FulfilmentPartnerAction.DeleteDialog()
			}
		]
	})),
	on(ActionbarAction.FulfilmentPartnerDisableDelete, () => ({
		icon: '',
		title: 'FULFILMENT_PARTNER.FULFILMENT_PARTNER',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'FULFILMENT_PARTNER.FULFILMENT_PARTNER_DETAILS',
				functions: ['FULFILMENT_PARTNER_VIEW'],
				actionButtonType: 'BACK',
				action: FulfilmentPartnerAction.GoBackDialog({ action: 'VIEW' })
			},
			{
				icon: 'delete',
				title: 'ACTION.DELETE',
				functions: ['FULFILMENT_PARTNER_DELETE'],
				isDisable: true,
				action: FulfilmentPartnerAction.DeleteDialog(),
				disableDescription: 'FULFILMENT_PARTNER.DELETE_DISABLE_DESCRIPTION'
			}
		]
	})),
	on(ActionbarAction.WaiverManagementList, () => ({
		icon: '',
		title: 'WAIVER_MANAGEMENT.WAIVER_MANAGEMENT',
		actionButtons: [
			{
				icon: 'add_box',
				title: 'WAIVER_MANAGEMENT.ACTION.CREATE_WAIVER_TYPE',
				functions: ['WAIVER_MANAGEMENT_CREATE'],
				action: WaiverManagementAction.GoCreate()
			}
		]
	})),
	on(ActionbarAction.WaiverManagementView, () => ({
		icon: '',
		title: 'WAIVER_MANAGEMENT.WAIVER_MANAGEMENT',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'WAIVER_MANAGEMENT.WAIVER_TYPE_DETAILS',
				functions: ['WAIVER_MANAGEMENT_VIEW'],
				actionButtonType: 'BACK',
				action: WaiverManagementAction.GoList()
			},
			{
				icon: 'edit',
				title: 'ACTION.EDIT',
				functions: ['WAIVER_MANAGEMENT_UPDATE'],
				action: WaiverManagementAction.GoUpdate()
			},
			{
				icon: 'delete',
				title: 'ACTION.DELETE',
				functions: ['WAIVER_MANAGEMENT_DELETE'],
				action: WaiverManagementAction.DeleteDialog()
			}
		]
	})),
	on(ActionbarAction.WaiverManagementCreate, () => ({
		icon: '',
		title: 'WAIVER_MANAGEMENT.WAIVER_MANAGEMENT',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'WAIVER_MANAGEMENT.CREATE_WAIVER_TYPE',
				functions: ['WAIVER_MANAGEMENT_VIEW'],
				actionButtonType: 'BACK',
				action: WaiverManagementAction.GoBackDialog({ action: 'LIST' })
			}
		]
	})),
	on(ActionbarAction.WaiverManagementEdit, () => ({
		icon: '',
		title: 'WAIVER_MANAGEMENT.WAIVER_MANAGEMENT',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'WAIVER_MANAGEMENT.WAIVER_TYPE_DETAILS',
				functions: ['WAIVER_MANAGEMENT_VIEW'],
				actionButtonType: 'BACK',
				action: WaiverManagementAction.GoBackDialog({ action: 'VIEW' })
			},
			{
				icon: 'delete',
				title: 'ACTION.DELETE',
				functions: ['WAIVER_MANAGEMENT_DELETE'],
				action: WaiverManagementAction.DeleteDialog()
			}
		]
	})),
	on(ActionbarAction.OnTheSpotRedemptionList, () => ({
		icon: '',
		title: 'ON_THE_SPOT_REDEMPTION.ON_THE_SPOT_REDEMPTION',
		actionButtons: [
			{
				icon: 'add_box',
				title: 'ON_THE_SPOT_REDEMPTION.ACTION.CREATE_ON_THE_SPOT_REDEMPTION',
				functions: ['OTS_PARAMETER_CREATE'],
				action: OnTheSpotRedemptionAction.GoCreate()
			}
		]
	})),
	on(ActionbarAction.OnTheSpotRedemptionView, () => ({
		icon: '',
		title: 'ON_THE_SPOT_REDEMPTION.ON_THE_SPOT_REDEMPTION',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'ON_THE_SPOT_REDEMPTION.ON_THE_SPOT_REDEMPTION_DETAILS',
				functions: ['OTS_PARAMETER_VIEW'],
				actionButtonType: 'BACK',
				action: OnTheSpotRedemptionAction.GoList()
			},
			{
				icon: 'edit',
				title: 'ACTION.EDIT',
				functions: ['OTS_PARAMETER_UPDATE'],
				action: OnTheSpotRedemptionAction.GoUpdate()
			},
			{
				icon: 'delete',
				title: 'ACTION.DELETE',
				functions: ['OTS_PARAMETER_DELETE'],
				action: OnTheSpotRedemptionAction.DeleteDialog()
			},
		]
	})),
	on(ActionbarAction.OnTheSpotRedemptionUpdate, () => ({
		icon: '',
		title: 'ON_THE_SPOT_REDEMPTION.ON_THE_SPOT_REDEMPTION',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'ON_THE_SPOT_REDEMPTION.ON_THE_SPOT_REDEMPTION_DETAILS',
				functions: ['OTS_PARAMETER_VIEW'],
				actionButtonType: 'BACK',
				action: OnTheSpotRedemptionAction.GoBackDialog({ action: 'VIEW' })
			},
			{
				icon: 'delete',
				title: 'ACTION.DELETE',
				functions: ['OTS_PARAMETER_DELETE'],
				action: OnTheSpotRedemptionAction.DeleteDialog()
			},
		]
	})),
	on(ActionbarAction.OnTheSpotRedemptionCreate, () => ({
		icon: '',
		title: 'ON_THE_SPOT_REDEMPTION.ON_THE_SPOT_REDEMPTION',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'ON_THE_SPOT_REDEMPTION.CREATE_ON_THE_SPOT_REDEMPTION',
				functions: ['OTS_PARAMETER_VIEW'],
				actionButtonType: 'BACK',
				action: OnTheSpotRedemptionAction.GoBackDialog({ action: 'LIST' })
			},
		]
	})),
	on(ActionbarAction.RedeemRulesList, () => ({
		icon: '',
		title: 'REDEEM_RULES.REDEEM_RULES',
		actionButtons: [
			{
				icon: 'add_box',
				title: 'REDEEM_RULES.ACTION.CREATE_REDEEM_RULES',
				functions: ['REDEEM_RULES_CREATE'],
				action: RedeemRulesAction.GoCreate()
			}
		]
	})),
	on(ActionbarAction.RedeemRulesView, () => ({
		icon: '',
		title: 'REDEEM_RULES.REDEEM_RULES',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'REDEEM_RULES.REDEEM_RULES_DETAILS',
				functions: ['REDEEM_RULES_VIEW'],
				actionButtonType: 'BACK',
				action: RedeemRulesAction.GoList()
			},
			{
				icon: 'edit',
				title: 'ACTION.EDIT',
				functions: ['REDEEM_RULES_UPDATE'],
				action: RedeemRulesAction.GoUpdate()
			},
			{
				icon: 'delete',
				title: 'ACTION.DELETE',
				functions: ['REDEEM_RULES_DELETE'],
				action: RedeemRulesAction.DeleteDialog()
			}
		]
	})),
	on(ActionbarAction.RedeemRulesCreate, () => ({
		icon: '',
		title: 'REDEEM_RULES.REDEEM_RULES',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'REDEEM_RULES.CREATE_REDEEM_RULES',
				functions: ['REDEEM_RULES_VIEW'],
				actionButtonType: 'BACK',
				action: RedeemRulesAction.GoBackDialog({ action: 'LIST' })
			}
		]
	})),
	on(ActionbarAction.RedeemRulesEdit, () => ({
		icon: '',
		title: 'REDEEM_RULES.REDEEM_RULES',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'REDEEM_RULES.REDEEM_RULES_DETAILS',
				functions: ['REDEEM_RULES_VIEW'],
				actionButtonType: 'BACK',
				action: RedeemRulesAction.GoBackDialog({ action: 'VIEW' })
			},
			{
				icon: 'delete',
				title: 'ACTION.DELETE',
				functions: ['REDEEM_RULES_DELETE'],
				action: RedeemRulesAction.DeleteDialog()
			}
		]
	})),
	on(ActionbarAction.ConditionalRewardList, () => ({
		icon: '',
		title: 'CONDITIONAL_REWARD.CONDITIONAL_REWARD',
		actionButtons: [
			{
				icon: 'add_box',
				title: 'CONDITIONAL_REWARD.ACTION.CREATE_CONDITIONAL_REWARD',
				functions: ['CONDITIONAL_REWARD_CREATE'],
				action: ConditionalRewardAction.GoCreate()
			}
		]
	})),
	on(ActionbarAction.ConditionalRewardCreate, () => ({
		icon: '',
		title: 'CONDITIONAL_REWARD.CONDITIONAL_REWARD',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'CONDITIONAL_REWARD.CREATE_CONDITIONAL_REWARD',
				functions: ['CONDITIONAL_REWARD_PUBLISHED_VIEW', 'CONDITIONAL_REWARD_ARCHIVED_VIEW'],
				actionButtonType: 'BACK',
				action: ConditionalRewardAction.GoBackDialog({ action: 'LIST' })
			},
			{
				icon: 'save',
				title: 'ACTION.SAVE',
				functions: ['CONDITIONAL_REWARD_CREATE'],
				action: ConditionalRewardAction.CreateDialog()
			}
		]
	})),
	on(ActionbarAction.ConditionalRewardDisableCreate, () => ({
		icon: '',
		title: 'CONDITIONAL_REWARD.CONDITIONAL_REWARD',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'CONDITIONAL_REWARD.CREATE_CONDITIONAL_REWARD',
				functions: ['CONDITIONAL_REWARD_PUBLISHED_VIEW', 'CONDITIONAL_REWARD_ARCHIVED_VIEW'],
				actionButtonType: 'BACK',
				action: ConditionalRewardAction.GoBackDialog({ action: 'LIST' })
			},
			{
				icon: 'save',
				title: 'ACTION.SAVE',
				functions: ['CONDITIONAL_REWARD_CREATE'],
				isDisable: true,
				action: ConditionalRewardAction.CreateDialog()
			}
		]
	})),
	on(ActionbarAction.ConditionalRewardUpdate, () => ({
		icon: '',
		title: 'CONDITIONAL_REWARD.CONDITIONAL_REWARD',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'CONDITIONAL_REWARD.CONDITIONAL_REWARD_DETAILS',
				functions: ['CONDITIONAL_REWARD_PUBLISHED_VIEW', 'CONDITIONAL_REWARD_ARCHIVED_VIEW', 'CONDITIONAL_REWARD_CREATE', 'CONDITIONAL_REWARD_UPDATE'],
				actionButtonType: 'BACK',
				action: ConditionalRewardAction.GoBackDialog({ action: 'LIST' })
			},
			{
				icon: 'save',
				title: 'ACTION.SAVE',
				functions: ['CONDITIONAL_REWARD_CREATE'],
				action: ConditionalRewardAction.UpdateDialog()
			},
			{
				icon: 'delete',
				title: 'ACTION.DELETE',
				functions: ['CONDITIONAL_REWARD_CREATE'],
				action: ConditionalRewardAction.DeleteDialog()
			}
		]
	})),
	on(ActionbarAction.ConditionalRewardDisableUpdate, () => ({
		icon: '',
		title: 'CONDITIONAL_REWARD.CONDITIONAL_REWARD',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'CONDITIONAL_REWARD.CONDITIONAL_REWARD_DETAILS',
				functions: ['CONDITIONAL_REWARD_PUBLISHED_VIEW', 'CONDITIONAL_REWARD_ARCHIVED_VIEW', 'CONDITIONAL_REWARD_CREATE', 'CONDITIONAL_REWARD_UPDATE'],
				actionButtonType: 'BACK',
				action: ConditionalRewardAction.GoBackDialog({ action: 'LIST' })
			},
			{
				icon: 'save',
				title: 'ACTION.SAVE',
				functions: ['CONDITIONAL_REWARD_CREATE'],
				isDisable: true,
				action: ConditionalRewardAction.UpdateDialog()
			},
			{
				icon: 'delete',
				title: 'ACTION.DELETE',
				functions: ['CONDITIONAL_REWARD_CREATE'],
				action: ConditionalRewardAction.DeleteDialog()
			}
		]
	})),
	on(ActionbarAction.ConditionalRewardDisableRepublish, () => ({
		icon: '',
		title: 'CONDITIONAL_REWARD.CONDITIONAL_REWARD',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'CONDITIONAL_REWARD.CONDITIONAL_REWARD_DETAILS',
				functions: ['CONDITIONAL_REWARD_UPDATE'],
				actionButtonType: 'BACK',
				action: ConditionalRewardAction.GoBackDialog({ action: 'LIST' })
			},
			{
				icon: 'publish',
				title: 'ACTION.RE_PUBLISH',
				functions: ['CONDITIONAL_REWARD_UPDATE'],
				isDisable: true,
				action: ConditionalRewardAction.RepublishDialog()
			}
		]
	})),
	on(ActionbarAction.ConditionalRewardDisableDelete, () => ({
		icon: '',
		title: 'CONDITIONAL_REWARD.CONDITIONAL_REWARD',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'CONDITIONAL_REWARD.CONDITIONAL_REWARD_DETAILS',
				functions: ['CONDITIONAL_REWARD_PUBLISHED_VIEW', 'CONDITIONAL_REWARD_ARCHIVED_VIEW', 'CONDITIONAL_REWARD_CREATE', 'CONDITIONAL_REWARD_UPDATE'],
				actionButtonType: 'BACK',
				action: ConditionalRewardAction.GoBackDialog({ action: 'LIST' })
			},
			{
				icon: 'save',
				title: 'ACTION.SAVE',
				functions: ['CONDITIONAL_REWARD_CREATE'],
				action: ConditionalRewardAction.UpdateDialog()
			},
			{
				icon: 'delete',
				title: 'ACTION.DELETE',
				functions: ['CONDITIONAL_REWARD_CREATE'],
				isDisable: true,
				action: ConditionalRewardAction.DeleteDialog(),
				disableDescription: 'CONDITIONAL_REWARD.DELETE_DISABLE_DESCRIPTION'
			}
		]
	})),
	on(ActionbarAction.ConditionalRewardDisableDeleteAndUpdate, () => ({
		icon: '',
		title: 'CONDITIONAL_REWARD.CONDITIONAL_REWARD',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'CONDITIONAL_REWARD.CONDITIONAL_REWARD_DETAILS',
				functions: ['CONDITIONAL_REWARD_PUBLISHED_VIEW', 'CONDITIONAL_REWARD_ARCHIVED_VIEW', 'CONDITIONAL_REWARD_CREATE', 'CONDITIONAL_REWARD_UPDATE'],
				actionButtonType: 'BACK',
				action: ConditionalRewardAction.GoBackDialog({ action: 'LIST' })
			},
			{
				icon: 'save',
				title: 'ACTION.SAVE',
				functions: ['CONDITIONAL_REWARD_CREATE'],
				isDisable: true,
				action: ConditionalRewardAction.UpdateDialog()
			},
			{
				icon: 'delete',
				title: 'ACTION.DELETE',
				functions: ['CONDITIONAL_REWARD_CREATE'],
				isDisable: true,
				action: ConditionalRewardAction.DeleteDialog(),
				disableDescription: 'CONDITIONAL_REWARD.DELETE_DISABLE_DESCRIPTION'
			}
		]
	})),
	on(ActionbarAction.ConditionalRewardView, () => ({
		icon: '',
		title: 'CONDITIONAL_REWARD.CONDITIONAL_REWARD',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'CONDITIONAL_REWARD.CONDITIONAL_REWARD_DETAILS',
				functions: ['CONDITIONAL_REWARD_CREATE', 'CONDITIONAL_REWARD_UPDATE'],
				actionButtonType: 'BACK',
				action: ConditionalRewardAction.GoList()
			},
			{
				icon: 'edit',
				title: 'ACTION.EDIT',
				functions: ['CONDITIONAL_REWARD_UPDATE'],
				action: ConditionalRewardAction.GoPublishedUpdate({ action: 'PUBLISHED_UPDATE' })
			}
		]
	})),
	on(ActionbarAction.ConditionalRewardArchiveView, () => ({
		icon: '',
		title: 'CONDITIONAL_REWARD.CONDITIONAL_REWARD',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'CONDITIONAL_REWARD.CONDITIONAL_REWARD_DETAILS',
				functions: ['CONDITIONAL_REWARD_CREATE', 'CONDITIONAL_REWARD_UPDATE'],
				actionButtonType: 'BACK',
				action: ConditionalRewardAction.GoList()
			}
		]
	})),
	on(ActionbarAction.ConditionalRewardRepublishDisableEdit, () => ({
		icon: '',
		title: 'CONDITIONAL_REWARD.CONDITIONAL_REWARD',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'CONDITIONAL_REWARD.CONDITIONAL_REWARD_DETAILS',
				functions: ['CONDITIONAL_REWARD_CREATE', 'CONDITIONAL_REWARD_UPDATE'],
				actionButtonType: 'BACK',
				action: ConditionalRewardAction.GoList()
			},
			{
				icon: 'edit',
				title: 'ACTION.EDIT',
				functions: ['CONDITIONAL_REWARD_UPDATE'],
				isDisable: true,
				disableDescription: 'CONDITIONAL_REWARD.REPUBLISH_DISABLE_DESCRIPTION',
				action: ConditionalRewardAction.GoPublishedUpdate({ action: 'PUBLISHED_UPDATE' })
			}
		]
	})),
	on(ActionbarAction.ConditionalRewardRepublish, () => ({
		icon: '',
		title: 'CONDITIONAL_REWARD.CONDITIONAL_REWARD',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'CONDITIONAL_REWARD.CONDITIONAL_REWARD_DETAILS',
				functions: ['CONDITIONAL_REWARD_UPDATE'],
				actionButtonType: 'BACK',
				action: ConditionalRewardAction.GoList()
			},
			{
				icon: 'publish',
				title: 'ACTION.RE_PUBLISH',
				functions: ['CONDITIONAL_REWARD_UPDATE'],
				action: ConditionalRewardAction.RepublishDialog()
			}
		]
	})),
	on(ActionbarAction.TaskListView, () => ({
		icon: '',
		title: 'WORKLIST.TASKLIST'
	})),
	on(ActionbarAction.MyRequestView, () => ({
		icon: '',
		title: 'WORKLIST.MY_REQUEST'
	})),
	on(ActionbarAction.FromTaskListView, () => ({
		icon: '',
		title: 'WORKLIST.TASKLIST',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'WORKLIST.TASKLIST_DETAILS',
				functions: [
					'CAMPAIGN_APPROVE',
					'ADJUSTMENT_APPROVE',
					'CAMPAIGN_BUNDLING_APPROVE',
					'CONDITIONAL_REWARD_APPROVE',
					'REDEMPTION_CANCELLATION_APPROVE'
				],
				actionButtonType: 'BACK',
				action: TasklistAction.GoList()
			}
		]
	})),
	on(ActionbarAction.FromMyRequestView, () => ({
		icon: '',
		title: 'WORKLIST.MY_REQUEST',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'WORKLIST.MY_REQUEST_DETAILS',
				functions: [
					'CAMPAIGN_APPROVE',
					'ADJUSTMENT_APPROVE',
					'CAMPAIGN_BUNDLING_APPROVE',
					'CONDITIONAL_REWARD_APPROVE',
					'CAMPAIGN_CREATE',
					'ADJUSTMENT_ACTION',
					'CAMPAIGN_BUNDLING_CREATE',
					'CONDITIONAL_REWARD_CREATE',
					'REDEMPTION_CANCELLATION_APPROVE',
					'REDEMPTION_CANCELLATION',
					'CAMPAIGN_UPDATE',
					'CAMPAIGN_BUNDLING_UPDATE',
					'CONDITIONAL_REWARD_UPDATE'
				],
				actionButtonType: 'BACK',
				action: MyRequestAction.GoList()
			}
		]
	})),
	on(ActionbarAction.TargetSegmentList, () => ({
		icon: '',
		title: 'TARGET_SEGMENT.TARGET_SEGMENT',
		actionButtons: [
			{
				icon: 'add_box',
				title: 'TARGET_SEGMENT.ACTION.CREATE_CUSTOMER_ID',
				functions: ['TARGET_SEGMENT_CUSTOMER_CREATE'],
				action: TargetSegmentAction.GoCustomerCreate()
			},
			{
				icon: 'add_box',
				title: 'TARGET_SEGMENT.ACTION.CREATE_ACCOUNT_NO',
				functions: ['TARGET_SEGMENT_ACCOUNT_CREATE'],
				action: TargetSegmentAction.GoAccountCreate()
			},
			{
				icon: 'add_box',
				title: 'TARGET_SEGMENT.ACTION.CREATE_CARD_NO',
				functions: ['TARGET_SEGMENT_CARD_CREATE'],
				action: TargetSegmentAction.GoCardCreate()
			},
		]
	})),
	on(ActionbarAction.TargetSegmentAccountCreate, () => ({
		icon: '',
		title: 'TARGET_SEGMENT.TARGET_SEGMENT',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'TARGET_SEGMENT.TARGET_SEGMENT_DETAILS',
				functions: ['TARGET_SEGMENT_CUSTOMER_VIEW', 'TARGET_SEGMENT_CARD_VIEW', 'TARGET_SEGMENT_ACCOUNT_VIEW'],
				actionButtonType: 'BACK',
				action: TargetSegmentAction.GoBackDialog({ action: 'LIST' })
			},
		]
	})),
	on(ActionbarAction.TargetSegmentCardCreate, () => ({
		icon: '',
		title: 'TARGET_SEGMENT.TARGET_SEGMENT',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'TARGET_SEGMENT.TARGET_SEGMENT_DETAILS',
				functions: ['TARGET_SEGMENT_CUSTOMER_VIEW', 'TARGET_SEGMENT_CARD_VIEW', 'TARGET_SEGMENT_ACCOUNT_VIEW'],
				actionButtonType: 'BACK',
				action: TargetSegmentAction.GoBackDialog({ action: 'LIST' })
			},
		]
	})),
	on(ActionbarAction.TargetSegmentCustomerCreate, () => ({
		icon: '',
		title: 'TARGET_SEGMENT.TARGET_SEGMENT',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'TARGET_SEGMENT.TARGET_SEGMENT_DETAILS',
				functions: ['TARGET_SEGMENT_CUSTOMER_VIEW', 'TARGET_SEGMENT_CARD_VIEW', 'TARGET_SEGMENT_ACCOUNT_VIEW'],
				actionButtonType: 'BACK',
				action: TargetSegmentAction.GoBackDialog({ action: 'LIST' })
			},
		]
	})),
	on(ActionbarAction.TargetSegmentAccountView, (action, payload) => ({
		icon: '',
		title: 'TARGET_SEGMENT.TARGET_SEGMENT',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'TARGET_SEGMENT.TARGET_SEGMENT_DETAILS',
				functions: ['TARGET_SEGMENT_CUSTOMER_VIEW', 'TARGET_SEGMENT_CARD_VIEW', 'TARGET_SEGMENT_ACCOUNT_VIEW'],
				actionButtonType: 'BACK',
				action: TargetSegmentAction.GoList()
			},
			{
				icon: 'edit',
				title: 'ACTION.EDIT',
				functions: ['TARGET_SEGMENT_ACCOUNT_UPDATE'],
				action: TargetSegmentAction.GoAccountEdit()
			},
			{
				icon: 'delete',
				title: 'ACTION.DELETE',
				functions: ['TARGET_SEGMENT_ACCOUNT_UPDATE'],
				isDisable: !payload.allowDelete,
				disableDescription: 'TARGET_SEGMENT.DELETE_DISABLE_DESCRIPTION',
				action: TargetSegmentAction.DeleteDialog({ payload: 'DIALOG.DELETE_ACCOUNT_SEGMENT' })
			},
		]
	})),
	on(ActionbarAction.TargetSegmentCardView, (action, payload) => ({
		icon: '',
		title: 'TARGET_SEGMENT.TARGET_SEGMENT',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'TARGET_SEGMENT.TARGET_SEGMENT_DETAILS',
				functions: ['TARGET_SEGMENT_CUSTOMER_VIEW', 'TARGET_SEGMENT_CARD_VIEW', 'TARGET_SEGMENT_ACCOUNT_VIEW'],
				actionButtonType: 'BACK',
				action: TargetSegmentAction.GoList()
			},
			{
				icon: 'edit',
				title: 'ACTION.EDIT',
				functions: ['TARGET_SEGMENT_CARD_UPDATE'],
				action: TargetSegmentAction.GoCardEdit()
			},
			{
				icon: 'delete',
				title: 'ACTION.DELETE',
				functions: ['TARGET_SEGMENT_CARD_UPDATE'],
				isDisable: !payload.allowDelete,
				disableDescription: 'TARGET_SEGMENT.DELETE_DISABLE_DESCRIPTION',
				action: TargetSegmentAction.DeleteDialog({ payload: 'DIALOG.DELETE_CARD_SEGMENT' })
			},
		]
	})),
	on(ActionbarAction.TargetSegmentCustomerView, (action, payload) => ({
		icon: '',
		title: 'TARGET_SEGMENT.TARGET_SEGMENT',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'TARGET_SEGMENT.TARGET_SEGMENT_DETAILS',
				functions: ['TARGET_SEGMENT_CUSTOMER_VIEW', 'TARGET_SEGMENT_CARD_VIEW', 'TARGET_SEGMENT_ACCOUNT_VIEW'],
				actionButtonType: 'BACK',
				action: TargetSegmentAction.GoList()
			},
			{
				icon: 'edit',
				title: 'ACTION.EDIT',
				functions: ['TARGET_SEGMENT_CUSTOMER_UPDATE'],
				action: TargetSegmentAction.GoCustomerEdit()
			},
			{
				icon: 'delete',
				title: 'ACTION.DELETE',
				functions: ['TARGET_SEGMENT_CUSTOMER_UPDATE'],
				isDisable: !payload.allowDelete,
				disableDescription: 'TARGET_SEGMENT.DELETE_DISABLE_DESCRIPTION',
				action: TargetSegmentAction.DeleteDialog({ payload: 'DIALOG.DELETE_CUSTOMER_SEGMENT' })
			},
		]
	})),
	on(ActionbarAction.TargetSegmentAccountEdit, () => ({
		icon: '',
		title: 'TARGET_SEGMENT.TARGET_SEGMENT',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'TARGET_SEGMENT.TARGET_SEGMENT_DETAILS',
				functions: ['TARGET_SEGMENT_CUSTOMER_VIEW', 'TARGET_SEGMENT_CARD_VIEW', 'TARGET_SEGMENT_ACCOUNT_VIEW'],
				actionButtonType: 'BACK',
				action: TargetSegmentAction.GoBackDialog({ action: 'VIEW_ACCOUNT' })
			},
			{
				icon: 'delete',
				title: 'ACTION.DELETE',
				functions: ['TARGET_SEGMENT_ACCOUNT_UPDATE'],
				action: TargetSegmentAction.DeleteDialog({ payload: 'DIALOG.DELETE_ACCOUNT_SEGMENT' })
			},
		]
	})),
	on(ActionbarAction.TargetSegmentCardEdit, () => ({
		icon: '',
		title: 'TARGET_SEGMENT.TARGET_SEGMENT',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'TARGET_SEGMENT.TARGET_SEGMENT_DETAILS',
				functions: ['TARGET_SEGMENT_CUSTOMER_VIEW', 'TARGET_SEGMENT_CARD_VIEW', 'TARGET_SEGMENT_ACCOUNT_VIEW'],
				actionButtonType: 'BACK',
				action: TargetSegmentAction.GoBackDialog({ action: 'VIEW_CARD' })
			},
			{
				icon: 'delete',
				title: 'ACTION.DELETE',
				functions: ['TARGET_SEGMENT_CARD_UPDATE'],
				action: TargetSegmentAction.DeleteDialog({ payload: 'DIALOG.DELETE_CARD_SEGMENT' })
			},
		]
	})),
	on(ActionbarAction.TargetSegmentCustomerEdit, () => ({
		icon: '',
		title: 'TARGET_SEGMENT.TARGET_SEGMENT',
		actionButtons: [
			{
				icon: 'arrow_back',
				title: 'TARGET_SEGMENT.TARGET_SEGMENT_DETAILS',
				functions: ['TARGET_SEGMENT_CUSTOMER_VIEW', 'TARGET_SEGMENT_CARD_VIEW', 'TARGET_SEGMENT_ACCOUNT_VIEW'],
				actionButtonType: 'BACK',
				action: TargetSegmentAction.GoBackDialog({ action: 'VIEW_CUSTOMER' })
			},
			{
				icon: 'delete',
				title: 'ACTION.DELETE',
				functions: ['TARGET_SEGMENT_CUSTOMER_UPDATE'],
				action: TargetSegmentAction.DeleteDialog({ payload: 'DIALOG.DELETE_CUSTOMER_SEGMENT' })
			},
		]
	})),
	on(ActionbarAction.SimulatorList, () => ({
		icon: '',
		title: 'SIMULATOR.SIMULATOR',
		actionButtons: [
			{
				icon: 'confirmation_number',
				title: 'SIMULATOR.ACTION.CREATE_SIMULATION',
				functions: ['SIMULATOR_CREATE'],
				actionButtonType: 'MENU',
				actionButtons: [
					{
						icon: '',
						title: 'SIMULATOR.RAW_DATA_SIMULATOR',
						functions: ['SIMULATOR_CREATE'],
						action: SimulatorAction.RawDataSimulatorDialog()
					},
					{
						icon: '',
						title: 'SIMULATOR.CONFLICT_DATA_SIMULATOR',
						functions: ['SIMULATOR_CREATE'],
						action: SimulatorAction.ConflictDataSimulatorDialog()
					}
				]
			}
		]
	})),
	on(ActionbarAction.FileDownloadList, () => ({
		icon: '',
		title: 'FILE_DOWNLOAD.FILE_DOWNLOAD',
	})),
)

export function Reducer(state: State = initialState, action: Action) {
	return reducer(state, action)
}
