import { Action, createReducer, on } from '@ngrx/store'
import { formGroupReducer } from 'ngrx-forms'
import * as CommonAction from './common.actions'
import * as CommonState from './common.state'

export const reducer = createReducer(
	CommonState.initialState,
	on(CommonAction.LogoutSuccess, () => CommonState.initialState),
	on(CommonAction.RestError, state => ({
		...state,
	})),
	on(CommonAction.SuccessMessage, state => ({
		...state,
	})),
	on(CommonAction.ToggleSidenav, (state, { flag }) => ({
		...state,
		sideNavFlag: !flag
	})),
	on(CommonAction.SetLoginUser, (state, { payload }) => ({
		...state,
		loginUser: payload
	})),
	on(CommonAction.SetPreviousRoute, (state, { previousRoute }) => ({
		...state,
		previousRoute
	})),
	on(CommonAction.SelectInitial, state => ({
		...state,
		selectForm: CommonState.initialSelectForm
	})),
	on(CommonAction.SetCurrentActiveNav, (state, { currentActiveNav }) => ({
		...state,
		currentActiveNav
	})),
)

export function Reducer(state: CommonState.State = CommonState.initialState, action: Action) {
	const selectForm = CommonState.validateSelectForm(formGroupReducer(state.selectForm, action))
	if (selectForm !== state.selectForm) {
		state = { ...state, selectForm }
	}

	return reducer(state, action)
}
