import { createAction, props } from '@ngrx/store'
import { TargetSegmentOverview, TargetSegmentDetails, TargetSegmentValidationResponse } from 'src/app/models/target-segment/target-segment'
import { TargetSegmentViewRequest } from 'src/app/models/target-segment/target-segment-view'
import { KeyValue } from '@angular/common'
import { TargetSegmentPrefixAndList } from 'src/app/models/target-segment/target-segment-create'

export const InitialState = createAction('[TargetSegment] InitialState')
export const RestError = createAction('[TargetSegment] RestError', props<{ message: string, manualThrow: boolean }>())

export const GetCardSegmentList = createAction('[TargetSegment] GetCardSegmentList')
export const GetCardSegmentListSuccess = createAction('[TargetSegment] GetCardSegmentListSuccess', props<{ payload: TargetSegmentOverview[] }>())

export const GetCustomerIdSegmentList = createAction('[TargetSegment] GetCustomerIdSegmentList')
export const GetCustomerIdSegmentListSuccess = createAction('[TargetSegment] GetCustomerIdSegmentListSuccess', props<{ payload: TargetSegmentOverview[] }>())

export const SetSegmentAndId = createAction('[TargetSegment] SetSegmentAndId', props<{ payload: TargetSegmentViewRequest }>())

export const GetAccountSegmentList = createAction('[TargetSegment] GetAccountSegmentList')
export const GetAccountSegmentListSuccess = createAction('[TargetSegment] GetAccountSegmentListSuccess', props<{ payload: TargetSegmentOverview[] }>())

export const GoViewCardSegmentDetail = createAction('[TargetSegment] GoViewCardSegmentDetail')
export const GoViewAccountSegmentDetail = createAction('[TargetSegment] GoViewAccountSegmentDetail')
export const GoViewCustomerIdSegmentDetail = createAction('[TargetSegment] GoViewCustomerIdSegmentDetail')

export const ViewCardSegmentDetail = createAction('[TargetSegment] ViewCardSegmentDetail')
export const ViewCardSegmentDetailSuccess = createAction('[TargetSegment] ViewCardSegmentDetailSuccess', props<{ payload: TargetSegmentDetails }>())

export const ViewCustomerIdSegmentDetail = createAction('[TargetSegment] ViewCustomerIdSegmentDetail')
export const ViewCustomerIdSegmentDetailSuccess = createAction('[TargetSegment] ViewCustomerIdSegmentDetailSuccess', props<{ payload: TargetSegmentDetails }>())

export const ViewAccountSegmentDetail = createAction('[TargetSegment] ViewAccountSegmentDetail')
export const ViewAccountSegmentDetailSuccess = createAction('[TargetSegment] ViewAccountSegmentDetailSuccess', props<{ payload: TargetSegmentDetails }>())

export const ViewTargetSegmentDetailEdit = createAction('[TargetSegment] ViewTargetSegmentDetailEdit')
export const ViewTargetSegmentDetailEditSuccess = createAction('[TargetSegment] ViewTargetSegmentDetailEditSuccess', props<{ payload: TargetSegmentDetails }>())

export const GoList = createAction('[TargetSegment] GoList')
export const GoCustomerCreate = createAction('[TargetSegment] GoCustomerCreate')
export const GoCardCreate = createAction('[TargetSegment] GoCardCreate')
export const GoAccountCreate = createAction('[TargetSegment] GoAccountCreate')
export const GoCustomerEdit = createAction('[TargetSegment] GoCustomerEdit')
export const GoCardEdit = createAction('[TargetSegment] GoCardEdit')
export const GoAccountEdit = createAction('[TargetSegment] GoAccountEdit')

export const GoBackDialog = createAction('[TargetSegment] GoBackDialog', props<{ action: string }>())
export const CloseDialog = createAction('[TargetSegment] CloseDialog')

export const GetResourceList = createAction('[TargetSegment] GetResourceList', props<{ action: 'create' | 'update' }>())
export const GetResourceListSuccess = createAction('[TargetSegment] GetResourceListSuccess', props<{ payload: KeyValue<string, string>[] }>())

export const DeleteDialog = createAction('[TargetSegment] DeleteDialog', props<{ payload: string }>())
export const Delete = createAction('[TargetSegment] Delete')
export const DeleteSuccess = createAction('[TargetSegment] DeleteSuccess', props<{ payload: string }>())

export const UpdateDialog = createAction('[TargetSegment] UpdateDialog', props<{ payload: string[] }>())
export const Update = createAction('[TargetSegment] Update', props<{ payload: string[] }>())
export const UpdateSuccess = createAction('[TargetSegment] UpdateSuccess', props<{ segment: string, payload: TargetSegmentValidationResponse }>())

export const CreateDialog = createAction('[TargetSegment] CreateDialog', props<{ content: string, payload: TargetSegmentPrefixAndList }>())
export const Create = createAction('[TargetSegment] Create', props<{ payload: TargetSegmentPrefixAndList }>())
export const CreateSuccess = createAction('[TargetSegment] CreateSuccess', props<{ segment: string, payload: TargetSegmentValidationResponse }>())

export const OpenSuccessValidationDialogCreate = createAction('[TargetSegment] OpenSuccessValidationDialogCreate', props<{ segment: string, payload: TargetSegmentValidationResponse }>())
export const OpenSuccessValidationDialogUpdate = createAction('[TargetSegment] OpenSuccessValidationDialogUpdate', props<{ segment: string, payload: TargetSegmentValidationResponse }>())
