import { Action, createReducer, on } from '@ngrx/store'
import * as MyRequestAction from './my-request.actions'
import * as MyRequestState from './my-request.state'

export const reducer = createReducer(
	MyRequestState.initialState,
	on(MyRequestAction.InitialState, () => MyRequestState.initialState),
	on(MyRequestAction.List, (state) => ({
		...state,
		isLoading: true
	})),
	on(MyRequestAction.ListSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		allMyRequestResponse: payload.all,
		pendingMyRequestResponse: payload.pending,
		approvedMyRequestResponse: payload.approved,
		rejectedMyRequestResponse: payload.rejected
	})),
	on(MyRequestAction.ListByStatus, (state) => ({
		...state,
		isLoading: true
	})),
	on(MyRequestAction.ListByStatusSuccess, (state, { status, payload }) => ({
		...state,
		isLoading: false,
		allMyRequestResponse: status === 'ALL' ? payload : state.allMyRequestResponse,
		pendingMyRequestResponse: status === 'PENDING' ? payload : state.pendingMyRequestResponse,
		approvedMyRequestResponse: status === 'APPROVED' ? payload : state.approvedMyRequestResponse,
		rejectedMyRequestResponse: status === 'REJECTED' ? payload : state.rejectedMyRequestResponse
	}))
)

export function Reducer(state: MyRequestState.State = MyRequestState.initialState, action: Action) {
	return reducer(state, action)
}
