import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Action, Store } from '@ngrx/store'
import { Observable, of } from 'rxjs'
import { catchError, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators'
import { Response } from 'src/app/models/common/http'
import * as AppStore from 'src/app/store/'
import * as CommonAction from '../../common/common/common.actions'
import * as DashboardRedemptionAction from './dashboard-redemption.actions'
import { MatDialog } from '@angular/material'
import { DashboardService } from 'src/app/services/dashboard/dashboard.service'
import { CodeOptionListViewResponse, DashboardWaiverRedemptionItemOptionListResponse } from 'src/app/models/dashboard/dashboard-redemption'
import { Util } from 'src/app/models/util/util'

@Injectable()
export class Effects {

	constructor(
		private action$: Actions,
		private router: Router,
		private dialog: MatDialog,
		private dashboardService: DashboardService,
		private store: Store<AppStore.State>
	) { }

	InitialState$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(DashboardRedemptionAction.InitialState),
			tap(() => {
				this.router.navigate(['dashboard/redemption'])
			})
		), { dispatch: false }
	)

	RestError$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(DashboardRedemptionAction.RestError),
			map(({ message, manualThrow }) => CommonAction.RestError({ message, manualThrow }))
		)
	)

	InitialRedemptionItemOptionList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(DashboardRedemptionAction.InitialRedemptionItemOptionList),
			withLatestFrom(this.store.select(state => state.dashboardRedemption)),
			mergeMap(([params, dashboard]) => this.dashboardService.getDashboardRedemptionItemOptionList({
				kind: 'CB'
			}).pipe(map((response: Response) => {
				const success: boolean = response.success
				const payload: CodeOptionListViewResponse[] = response.payload
				if (success) {
					return DashboardRedemptionAction.InitialRedemptionItemOptionListSuccess({ payload })
				} else { return DashboardRedemptionAction.RestError(response as any) }
			}), catchError(this.catcher))
			)
		)
	)

	InitialRewardPoolResponse$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(DashboardRedemptionAction.RewardPoolOptionListSuccess),
			withLatestFrom(this.store.select(state => state.dashboardRedemption)),
			mergeMap(([, dashboardRedemption]) => {
				const dashboardRedemptionRequestForm = dashboardRedemption.dashboardRedemptionRequestForm.controls
				return this.dashboardService.getDashboardRedemptionRewardPoolByMonth({
					rewardPoolId: dashboardRedemptionRequestForm.rewardPoolId.value,
					startMonth: dashboardRedemptionRequestForm.rewardPoolStartMonth.value,
					endMonth: dashboardRedemptionRequestForm.rewardPoolEndMonth.value
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload = response.payload
					if (success) {
						return DashboardRedemptionAction.ViewRewardPoolRedemptionSuccess({ payload })
					} else {
						return DashboardRedemptionAction.RestError(response as any)
					}
				}), catchError(this.catcher))
			})
		)
	)

	InitialRewardPoolGroupResponse$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(DashboardRedemptionAction.RewardPoolGroupOptionListSuccess),
			withLatestFrom(this.store.select(state => state.dashboardRedemption)),
			mergeMap(([, dashboardRedemption]) => {
				const dashboardRedemptionRequestForm = dashboardRedemption.dashboardRedemptionRequestForm.controls
				return this.dashboardService.getDashboardRedemptionRewardPoolGroupByMonth({
					rewardPoolGroupId: dashboardRedemptionRequestForm.rewardPoolGroupId.value,
					startMonth: dashboardRedemptionRequestForm.rewardPoolGroupStartMonth.value,
					endMonth: dashboardRedemptionRequestForm.rewardPoolGroupEndMonth.value
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload = response.payload
					if (success) {
						return DashboardRedemptionAction.ViewRewardPoolGroupRedemptionSuccess({ payload })
					} else {
						return DashboardRedemptionAction.RestError(response as any)
					}
				}), catchError(this.catcher))
			})
		)
	)

	InitialRedemptionItemResponse$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(DashboardRedemptionAction.InitialRedemptionItemOptionListSuccess),
			withLatestFrom(this.store.select(state => state.dashboardRedemption)),
			mergeMap(([params, dashboardRedemption]) => {
				const dashboardRedemptionRequestForm = dashboardRedemption.dashboardRedemptionRequestForm.controls
				return this.dashboardService.getDashboardRedemptionItemByMonth({
					redemptionId: dashboardRedemptionRequestForm.redemptionItemId.value,
					kind: 'CB',
					displayValue: false,
					startMonth: dashboardRedemptionRequestForm.redemptionItemStartMonth.value,
					endMonth: dashboardRedemptionRequestForm.redemptionItemEndMonth.value
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload = response.payload
					if (success) {
						return DashboardRedemptionAction.ViewRedemptionItemCashbackSuccess({ payload })
					} else {
						return DashboardRedemptionAction.RestError(response as any)
					}
				}), catchError(this.catcher))
			})
		)
	)

	ViewRewardPoolRedemption$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(DashboardRedemptionAction.ViewRewardPoolRedemption),
			withLatestFrom(this.store.select(state => state.dashboardRedemption)),
			mergeMap(([, dashboardRedemption]) => {
				const dashboardRedemptionRequestForm = dashboardRedemption.dashboardRedemptionRequestForm.controls
				return this.dashboardService.getDashboardRedemptionRewardPoolByMonth({
					rewardPoolId: dashboardRedemptionRequestForm.rewardPoolId.value,
					startMonth: dashboardRedemptionRequestForm.rewardPoolStartMonth.value.length === 10 ?
						dashboardRedemptionRequestForm.rewardPoolStartMonth.value :
						Util.fromISOStringToDynamicDateStr(dashboardRedemptionRequestForm.rewardPoolStartMonth.value, 'YYYY-MM-DD'),
					endMonth: dashboardRedemptionRequestForm.rewardPoolEndMonth.value.length === 10 ?
						dashboardRedemptionRequestForm.rewardPoolEndMonth.value :
						Util.fromISOStringToDynamicDateStr(dashboardRedemptionRequestForm.rewardPoolEndMonth.value, 'YYYY-MM-DD'),
				}).pipe(map((response: Response) => {
					const { success, payload } = response
					if (success) {
						return DashboardRedemptionAction.ViewRewardPoolRedemptionSuccess({ payload })
					} else {
						return DashboardRedemptionAction.RestError(response as any)
					}
				}), catchError(this.catcher))
			})
		)
	)

	ViewRewardPoolGroupRedemption$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(DashboardRedemptionAction.ViewRewardPoolGroupRedemption),
			withLatestFrom(this.store.select(state => state.dashboardRedemption)),
			mergeMap(([, dashboardRedemption]) => {
				const dashboardRedemptionRequestForm = dashboardRedemption.dashboardRedemptionRequestForm.controls
				return this.dashboardService.getDashboardRedemptionRewardPoolGroupByMonth({
					rewardPoolGroupId: dashboardRedemptionRequestForm.rewardPoolGroupId.value,
					startMonth: dashboardRedemptionRequestForm.rewardPoolGroupStartMonth.value.length === 10 ?
						dashboardRedemptionRequestForm.rewardPoolGroupStartMonth.value :
						Util.fromISOStringToDynamicDateStr(dashboardRedemptionRequestForm.rewardPoolGroupStartMonth.value, 'YYYY-MM-DD'),
					endMonth: dashboardRedemptionRequestForm.rewardPoolGroupEndMonth.value.length === 10 ?
						dashboardRedemptionRequestForm.rewardPoolGroupEndMonth.value :
						Util.fromISOStringToDynamicDateStr(dashboardRedemptionRequestForm.rewardPoolGroupEndMonth.value, 'YYYY-MM-DD')
				}).pipe(map((response: Response) => {
					const { success, payload } = response
					if (success) {
						return DashboardRedemptionAction.ViewRewardPoolGroupRedemptionSuccess({ payload })
					} else {
						return DashboardRedemptionAction.RestError(response as any)
					}
				}), catchError(this.catcher))
			})
		)
	)

	ViewRedemptionItemCashback$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(DashboardRedemptionAction.ViewRedemptionItemCashback),
			withLatestFrom(this.store.select(state => state.dashboardRedemption)),
			mergeMap(([params, dashboardRedemption]) => {
				const dashboardRedemptionRequestForm = dashboardRedemption.dashboardRedemptionRequestForm.controls
				return this.dashboardService.getDashboardRedemptionItemByMonth({
					redemptionId: dashboardRedemptionRequestForm.redemptionItemId.value,
					kind: 'CB',
					displayValue: params.displayValue,
					startMonth: dashboardRedemptionRequestForm.redemptionItemStartMonth.value.length === 10 ?
						dashboardRedemptionRequestForm.redemptionItemStartMonth.value :
						Util.fromISOStringToDynamicDateStr(dashboardRedemptionRequestForm.redemptionItemStartMonth.value, 'YYYY-MM-DD'),
					endMonth: dashboardRedemptionRequestForm.redemptionItemEndMonth.value.length === 10 ?
						dashboardRedemptionRequestForm.redemptionItemEndMonth.value :
						Util.fromISOStringToDynamicDateStr(dashboardRedemptionRequestForm.redemptionItemEndMonth.value, 'YYYY-MM-DD'),
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload = response.payload
					if (success) {
						return DashboardRedemptionAction.ViewRedemptionItemCashbackSuccess({ payload })
					} else {
						return DashboardRedemptionAction.RestError(response as any)
					}
				}), catchError(this.catcher))
			})
		)
	)

	ViewRedemptionItemPartner$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(DashboardRedemptionAction.ViewRedemptionItemPartner),
			withLatestFrom(this.store.select(state => state.dashboardRedemption)),
			mergeMap(([params, dashboardRedemption]) => {
				const dashboardRedemptionRequestForm = dashboardRedemption.dashboardRedemptionRequestForm.controls
				return this.dashboardService.getDashboardRedemptionItemByMonth({
					redemptionId: dashboardRedemptionRequestForm.redemptionItemId.value,
					kind: 'P',
					displayValue: params.displayValue,
					startMonth: dashboardRedemptionRequestForm.redemptionItemStartMonth.value.length === 10 ?
						dashboardRedemptionRequestForm.redemptionItemStartMonth.value :
						Util.fromISOStringToDynamicDateStr(dashboardRedemptionRequestForm.redemptionItemStartMonth.value, 'YYYY-MM-DD'),
					endMonth: dashboardRedemptionRequestForm.redemptionItemEndMonth.value.length === 10 ?
						dashboardRedemptionRequestForm.redemptionItemEndMonth.value :
						Util.fromISOStringToDynamicDateStr(dashboardRedemptionRequestForm.redemptionItemEndMonth.value, 'YYYY-MM-DD'),
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload = response.payload
					if (success) {
						return DashboardRedemptionAction.ViewRedemptionItemPartnerSuccess({ payload })
					} else {
						return DashboardRedemptionAction.RestError(response as any)
					}
				}), catchError(this.catcher))
			})
		)
	)

	ViewRedemptionItemWaiver$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(DashboardRedemptionAction.ViewRedemptionItemWaiver),
			withLatestFrom(this.store.select(state => state.dashboardRedemption)),
			mergeMap(([params, dashboardRedemption]) => {
				const dashboardRedemptionRequestForm = dashboardRedemption.dashboardRedemptionRequestForm.controls
				return this.dashboardService.getDashboardRedemptionItemByMonth({
					redemptionId: dashboardRedemptionRequestForm.redemptionItemId.value,
					kind: 'FW',
					displayValue: params.displayValue,
					startMonth: dashboardRedemptionRequestForm.redemptionItemStartMonth.value.length === 10 ?
						dashboardRedemptionRequestForm.redemptionItemStartMonth.value :
						Util.fromISOStringToDynamicDateStr(dashboardRedemptionRequestForm.redemptionItemStartMonth.value, 'YYYY-MM-DD'),
					endMonth: dashboardRedemptionRequestForm.redemptionItemEndMonth.value.length === 10 ?
						dashboardRedemptionRequestForm.redemptionItemEndMonth.value :
						Util.fromISOStringToDynamicDateStr(dashboardRedemptionRequestForm.redemptionItemEndMonth.value, 'YYYY-MM-DD'),
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload = response.payload
					if (success) {
						return DashboardRedemptionAction.ViewRedemptionItemWaiverSuccess({ payload })
					} else {
						return DashboardRedemptionAction.RestError(response as any)
					}
				}), catchError(this.catcher))
			})
		)
	)


	ViewRedemptionItemOnTheSpot$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(DashboardRedemptionAction.ViewRedemptionItemOnTheSpot),
			withLatestFrom(this.store.select(state => state.dashboardRedemption)),
			mergeMap(([params, dashboardRedemption]) => {
				const dashboardRedemptionRequestForm = dashboardRedemption.dashboardRedemptionRequestForm.controls
				return this.dashboardService.getDashboardRedemptionItemByMonth({
					redemptionId: dashboardRedemptionRequestForm.redemptionItemId.value,
					kind: 'OTS',
					displayValue: params.displayValue,
					startMonth: dashboardRedemptionRequestForm.redemptionItemStartMonth.value.length === 10 ?
						dashboardRedemptionRequestForm.redemptionItemStartMonth.value :
						Util.fromISOStringToDynamicDateStr(dashboardRedemptionRequestForm.redemptionItemStartMonth.value, 'YYYY-MM-DD'),
					endMonth: dashboardRedemptionRequestForm.redemptionItemEndMonth.value.length === 10 ?
						dashboardRedemptionRequestForm.redemptionItemEndMonth.value :
						Util.fromISOStringToDynamicDateStr(dashboardRedemptionRequestForm.redemptionItemEndMonth.value, 'YYYY-MM-DD'),
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload = response.payload
					if (success) {
						return DashboardRedemptionAction.ViewRedemptionItemOnTheSpotSuccess({ payload })
					} else {
						return DashboardRedemptionAction.RestError(response as any)
					}
				}), catchError(this.catcher))
			})
		)
	)

	ViewCatalogueList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(DashboardRedemptionAction.ViewCatalogueList),
			withLatestFrom(this.store.select(state => state.dashboardRedemption)),
			mergeMap(([, dashboard]) => this.dashboardService.getDashboardRedemptionCatalogueList().pipe(map((response: Response) => {
				const success: boolean = response.success
				const payload = response.payload
				if (success) {
					return DashboardRedemptionAction.ViewCatalogueListSuccess({ payload })
				} else {
					return DashboardRedemptionAction.RestError(response as any)
				}
			}), catchError(this.catcher)
			))
		)
	)

	RewardPoolOptionList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(DashboardRedemptionAction.RewardPoolOptionList),
			withLatestFrom(this.store.select(state => state.dashboardRedemption)),
			mergeMap(([, dashboard]) => this.dashboardService.getDashboardRedemptionRewardPoolOptionList().pipe(map((response: Response) => {
				const success: boolean = response.success
				const payload: CodeOptionListViewResponse[] = response.payload
				if (success) {
					return DashboardRedemptionAction.RewardPoolOptionListSuccess({ payload })
				} else { return DashboardRedemptionAction.RestError(response as any) }
			}), catchError(this.catcher))
			)
		)
	)

	RewardPoolGroupOptionList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(DashboardRedemptionAction.RewardPoolGroupOptionList),
			withLatestFrom(this.store.select(state => state.dashboardRedemption)),
			mergeMap(([, dashboard]) => this.dashboardService.getDashboardRedemptionRewardPoolGroupOptionList().pipe(map((response: Response) => {
				const success: boolean = response.success
				const payload: CodeOptionListViewResponse[] = response.payload
				if (success) {
					return DashboardRedemptionAction.RewardPoolGroupOptionListSuccess({ payload })
				} else { return DashboardRedemptionAction.RestError(response as any) }
			}), catchError(this.catcher))
			)
		)
	)

	RedemptionItemCashbackOptionList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(DashboardRedemptionAction.RedemptionItemCashbackOptionList),
			withLatestFrom(this.store.select(state => state.dashboardRedemption)),
			mergeMap(([params, dashboard]) => this.dashboardService.getDashboardRedemptionItemOptionList({
				kind: 'CB'
			}).pipe(map((response: Response) => {
				const success: boolean = response.success
				const payload: CodeOptionListViewResponse[] = response.payload
				if (success) {
					return DashboardRedemptionAction.RedemptionItemCashbackOptionListSuccess({ payload })
				} else { return DashboardRedemptionAction.RestError(response as any) }
			}), catchError(this.catcher))
			)
		)
	)

	RedemptionItemCashbackOptionListSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(DashboardRedemptionAction.RedemptionItemCashbackOptionListSuccess),
			withLatestFrom(this.store.select(state => state.dashboardRedemption)),
			mergeMap(([params, dashboardRedemption]) => {
				const dashboardRedemptionRequestForm = dashboardRedemption.dashboardRedemptionRequestForm.controls
				return this.dashboardService.getDashboardRedemptionItemByMonth({
					redemptionId: dashboardRedemptionRequestForm.redemptionItemId.value,
					kind: 'CB',
					displayValue: false,
					startMonth: dashboardRedemptionRequestForm.redemptionItemStartMonth.value.length === 10 ?
						dashboardRedemptionRequestForm.redemptionItemStartMonth.value :
						Util.fromISOStringToDynamicDateStr(dashboardRedemptionRequestForm.redemptionItemStartMonth.value, 'YYYY-MM-DD'),
					endMonth: dashboardRedemptionRequestForm.redemptionItemEndMonth.value.length === 10 ?
						dashboardRedemptionRequestForm.redemptionItemEndMonth.value :
						Util.fromISOStringToDynamicDateStr(dashboardRedemptionRequestForm.redemptionItemEndMonth.value, 'YYYY-MM-DD')
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload = response.payload
					if (success) {
						return DashboardRedemptionAction.ViewRedemptionItemCashbackSuccess({ payload })
					} else {
						return DashboardRedemptionAction.RestError(response as any)
					}
				}), catchError(this.catcher))
			})
		)
	)


	RedemptionItemWaiverOptionList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(DashboardRedemptionAction.RedemptionItemWaiverOptionList),
			withLatestFrom(this.store.select(state => state.dashboardRedemption)),
			mergeMap(([params, dashboard]) => this.dashboardService.getDashboardWaiverRedemptionItemOptionList().pipe(map((response: Response) => {
				const success: boolean = response.success
				const payload: DashboardWaiverRedemptionItemOptionListResponse[] = response.payload
				if (success) {
					return DashboardRedemptionAction.RedemptionItemWaiverOptionListSuccess({ payload })
				} else { return DashboardRedemptionAction.RestError(response as any) }
			}), catchError(this.catcher))
			)
		)
	)

	RedemptionItemWaiverOptionListSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(DashboardRedemptionAction.RedemptionItemWaiverOptionListSuccess),
			withLatestFrom(this.store.select(state => state.dashboardRedemption)),
			mergeMap(([params, dashboardRedemption]) => {
				const dashboardRedemptionRequestForm = dashboardRedemption.dashboardRedemptionRequestForm.controls
				return this.dashboardService.getDashboardRedemptionItemByMonth({
					redemptionId: dashboardRedemptionRequestForm.redemptionItemId.value,
					kind: 'FW',
					displayValue: false,
					startMonth: dashboardRedemptionRequestForm.redemptionItemStartMonth.value.length === 10 ?
						dashboardRedemptionRequestForm.redemptionItemStartMonth.value :
						Util.fromISOStringToDynamicDateStr(dashboardRedemptionRequestForm.redemptionItemStartMonth.value, 'YYYY-MM-DD'),
					endMonth: dashboardRedemptionRequestForm.redemptionItemEndMonth.value.length === 10 ?
						dashboardRedemptionRequestForm.redemptionItemEndMonth.value :
						Util.fromISOStringToDynamicDateStr(dashboardRedemptionRequestForm.redemptionItemEndMonth.value, 'YYYY-MM-DD')
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload = response.payload
					if (success) {
						return DashboardRedemptionAction.ViewRedemptionItemWaiverSuccess({ payload })
					} else {
						return DashboardRedemptionAction.RestError(response as any)
					}
				}), catchError(this.catcher))
			})
		)
	)

	RedemptionItemPartnerOptionList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(DashboardRedemptionAction.RedemptionItemPartnerOptionList),
			withLatestFrom(this.store.select(state => state.dashboardRedemption)),
			mergeMap(([params, dashboard]) => this.dashboardService.getDashboardRedemptionItemOptionList({
				kind: 'P'
			}).pipe(map((response: Response) => {
				const success: boolean = response.success
				const payload: CodeOptionListViewResponse[] = response.payload
				if (success) {
					return DashboardRedemptionAction.RedemptionItemPartnerOptionListSuccess({ payload })
				} else { return DashboardRedemptionAction.RestError(response as any) }
			}), catchError(this.catcher))
			)
		)
	)

	RedemptionItemPartnerOptionListSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(DashboardRedemptionAction.RedemptionItemPartnerOptionListSuccess),
			withLatestFrom(this.store.select(state => state.dashboardRedemption)),
			mergeMap(([params, dashboardRedemption]) => {
				const dashboardRedemptionRequestForm = dashboardRedemption.dashboardRedemptionRequestForm.controls
				return this.dashboardService.getDashboardRedemptionItemByMonth({
					redemptionId: dashboardRedemptionRequestForm.redemptionItemId.value,
					kind: 'P',
					displayValue: false,
					startMonth: dashboardRedemptionRequestForm.redemptionItemStartMonth.value.length === 10 ?
						dashboardRedemptionRequestForm.redemptionItemStartMonth.value :
						Util.fromISOStringToDynamicDateStr(dashboardRedemptionRequestForm.redemptionItemStartMonth.value, 'YYYY-MM-DD'),
					endMonth: dashboardRedemptionRequestForm.redemptionItemEndMonth.value.length === 10 ?
						dashboardRedemptionRequestForm.redemptionItemEndMonth.value :
						Util.fromISOStringToDynamicDateStr(dashboardRedemptionRequestForm.redemptionItemEndMonth.value, 'YYYY-MM-DD')
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload = response.payload
					if (success) {
						return DashboardRedemptionAction.ViewRedemptionItemPartnerSuccess({ payload })
					} else {
						return DashboardRedemptionAction.RestError(response as any)
					}
				}), catchError(this.catcher))
			})
		)
	)

	RedemptionItemOnTheSpotOptionList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(DashboardRedemptionAction.RedemptionItemOnTheSpotOptionList),
			withLatestFrom(this.store.select(state => state.dashboardRedemption)),
			mergeMap(([params, dashboard]) => this.dashboardService.getDashboardRedemptionItemOptionList({
				kind: 'OTS'
			}).pipe(map((response: Response) => {
				const success: boolean = response.success
				const payload: CodeOptionListViewResponse[] = response.payload
				if (success) {
					return DashboardRedemptionAction.RedemptionItemOnTheSpotOptionListSuccess({ payload })
				} else { return DashboardRedemptionAction.RestError(response as any) }
			}), catchError(this.catcher))
			)
		)
	)

	RedemptionItemOnTheSpotOptionListSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(DashboardRedemptionAction.RedemptionItemOnTheSpotOptionListSuccess),
			withLatestFrom(this.store.select(state => state.dashboardRedemption)),
			mergeMap(([params, dashboardRedemption]) => {
				const dashboardRedemptionRequestForm = dashboardRedemption.dashboardRedemptionRequestForm.controls
				return this.dashboardService.getDashboardRedemptionItemByMonth({
					redemptionId: dashboardRedemptionRequestForm.redemptionItemId.value,
					kind: 'OTS',
					displayValue: false,
					startMonth: dashboardRedemptionRequestForm.redemptionItemStartMonth.value.length === 10 ?
						dashboardRedemptionRequestForm.redemptionItemStartMonth.value :
						Util.fromISOStringToDynamicDateStr(dashboardRedemptionRequestForm.redemptionItemStartMonth.value, 'YYYY-MM-DD'),
					endMonth: dashboardRedemptionRequestForm.redemptionItemEndMonth.value.length === 10 ?
						dashboardRedemptionRequestForm.redemptionItemEndMonth.value :
						Util.fromISOStringToDynamicDateStr(dashboardRedemptionRequestForm.redemptionItemEndMonth.value, 'YYYY-MM-DD')
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload = response.payload
					if (success) {
						return DashboardRedemptionAction.ViewRedemptionItemOnTheSpotSuccess({ payload })
					} else {
						return DashboardRedemptionAction.RestError(response as any)
					}
				}), catchError(this.catcher))
			})
		)
	)

	catcher = (error) => (of(DashboardRedemptionAction.RestError({ message: error.message, manualThrow: error.manualThrow })))
}
