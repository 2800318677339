import { AfterViewChecked, Component, OnChanges, OnDestroy, OnInit } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { Subscription } from 'rxjs'
import { NavItem } from 'src/app/models/common/nav-item'
import * as AppStore from 'src/app/store'
import * as CommonAction from 'src/app/store/common/common'

@Component({
	selector: 'app-sidenav',
	templateUrl: './sidenav.component.html',
	styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit, OnDestroy, AfterViewChecked {

	toggleFlag: boolean
	navItems: NavItem[]
	subsSideNavFlag: Subscription
	subsSideNavItemList: Subscription

	constructor(
		private store: Store<AppStore.State>,
		private router: Router
	) {
		this.subsSideNavFlag = this.store.select(CommonAction.getSideNavFlag).subscribe(x => {
			this.toggleFlag = x
		})

		this.subsSideNavItemList = this.store.select(CommonAction.getSideNavItemList).subscribe(x => {
			this.navItems = x
		})
	}

	ngOnInit() { }

	ngAfterViewChecked() {
		this.router.events.subscribe((evt) => {
			if (!(evt instanceof NavigationEnd)) {
				return
			}
			document.querySelector('mat-sidenav-content').scrollTop = 0
		})
	}

	ngOnDestroy() {
		this.subsSideNavFlag.unsubscribe()
		this.subsSideNavItemList.unsubscribe()
	}
}
