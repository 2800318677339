import { Action, createReducer, on } from '@ngrx/store'
import * as NgrxForms from 'ngrx-forms'
import { box, createFormGroupState, formGroupReducer, markAsTouched, reset, setValue, SetValueAction } from 'ngrx-forms'
import { Ruleset } from 'src/app/models/campaign-management/campaign/campaign'
import { Util } from 'src/app/models/util/util'
import { UUID } from 'src/app/models/util/uuid'
import * as CampaignAction from './campaign.actions'
import * as CampaignState from './campaign.state'
import { CampaignTierForm, CampaignTiers, Rulesets, validateCampaignTierForm } from './campaign.state'

export const reducer = createReducer(
	CampaignState.initialState,
	NgrxForms.onNgrxFormsAction(SetValueAction, (state, action) => {
		return actionHandler(state, action)
	}),
	NgrxForms.onNgrxFormsAction(NgrxForms.MarkAsTouchedAction, (state, action) => {
		return actionHandler(state, action)
	}),
	on(CampaignAction.InitialState, () => CampaignState.initialState),
	on(CampaignAction.RestError, state => ({
		...state,
		isLoading: false,
		isDuplicateLoading: false,
		isDuplicateDialog: false
	})),
	on(CampaignAction.SetCode, (state, { code }) => ({
		...state,
		code
	})),
	on(CampaignAction.SetKind, (state, { kind }) => ({
		...state,
		kind
	})),
	on(CampaignAction.SetCampaignTierId, (state, { campaignTierId }) => ({
		...state,
		campaignTierId
	})),
	on(CampaignAction.SetResourceType, state => ({
		...state,
		resourceType: state.campaignData.channelList.find(x => x.key === state.rulesetDetailForm.value.channel).resourceType
	})),
	on(CampaignAction.SetPolicy, (state, { policy }) => ({
		...state,
		policy
	})),
	on(CampaignAction.SetCheckerMakerId, (state, { checkerMakerId }) => ({
		...state,
		checkerMakerId
	})),
	on(CampaignAction.GoPublishedView, (state, { action }) => ({
		...state,
		republishAction: action
	})),
	on(CampaignAction.GoPublishedViewProductBundle, (state, { action }) => ({
		...state,
		republishAction: action
	})),
	on(CampaignAction.GoRepublishedView, (state, { action }) => ({
		...state,
		republishAction: action
	})),
	on(CampaignAction.GoPublishedViewProductBundle, (state, { action }) => ({
		...state,
		republishAction: action
	})),
	on(CampaignAction.GoRepublishedViewProductBundle, (state, { action }) => ({
		...state,
		republishAction: action
	})),
	on(CampaignAction.GoCreate, state => ({
		...state,
		kind: 'C',
		informationDetailForm: reset(setValue(state.informationDetailForm, CampaignState.initialInformationDetailFormValue)),
		rulesetListForm: reset(setValue(state.rulesetListForm, CampaignState.initialRulesetListFormValue)),
		cappingForm: reset(setValue(state.cappingForm, CampaignState.initialCappingFormValue))
	})),
	on(CampaignAction.GoCreateProductBundle, state => ({
		...state,
		kind: 'PB',
		informationDetailForm: reset(setValue(state.informationDetailForm, CampaignState.initialInformationDetailFormValue)),
		rulesetListForm: reset(setValue(state.rulesetListForm, CampaignState.initialRulesetListFormValue)),
		cappingForm: reset(setValue(state.cappingForm, CampaignState.initialCappingFormValue))
	})),
	on(CampaignAction.SetCampaignRulesetId, (state, { campaignRulesetId }) => ({
		...state,
		campaignRulesetId
	})),
	on(CampaignAction.SetRuleCondition, (state, { ruleCondition }) => ({
		...state,
		ruleCondition
	})),
	on(CampaignAction.SetPaginatorMetadata, (state, { payload }) => ({
		...state,
		paginatorMetadata: payload
	})),
	on(CampaignAction.List, state => ({
		...state,
		campaignIsLoading: true,
		isLoading: true,
	})),
	on(CampaignAction.ListSuccess, (state, { payload }) => ({
		...state,
		campaignIsLoading: false,
		isLoading: false,
		campaignPublishedListResponse: payload
	})),
	on(CampaignAction.DraftList, state => ({
		...state,
		campaignIsLoading: true,
		isLoading: true
	})),
	on(CampaignAction.DraftListSuccess, (state, { payload }) => ({
		...state,
		campaignIsLoading: false,
		isLoading: false,
		campaignDraftListResponse: payload
	})),
	on(CampaignAction.UnpublishedList, state => ({
		...state,
		campaignIsLoading: true,
		isLoading: true
	})),
	on(CampaignAction.UnpublishedListSuccess, (state, { payload }) => ({
		...state,
		campaignIsLoading: false,
		isLoading: false,
		campaignUnpublishedListResponse: payload
	})),
	on(CampaignAction.PbList, state => ({
		...state,
		pbIsLoading: true,
		isLoading: true
	})),
	on(CampaignAction.PbListSuccess, (state, { payload }) => ({
		...state,
		pbIsLoading: false,
		isLoading: false,
		campaignPbPublishedListResponse: payload
	})),
	on(CampaignAction.PbDraftList, state => ({
		...state,
		pbIsLoading: true,
		isLoading: true
	})),
	on(CampaignAction.PbDraftListSuccess, (state, { payload }) => ({
		...state,
		pbIsLoading: false,
		isLoading: false,
		campaignPbDraftListResponse: payload
	})),
	on(CampaignAction.PbUnpublishedList, state => ({
		...state,
		pbIsLoading: true,
		isLoading: true
	})),
	on(CampaignAction.PbUnpublishedListSuccess, (state, { payload }) => ({
		...state,
		pbIsLoading: false,
		isLoading: false,
		campaignPbUnpublishedListResponse: payload
	})),
	on(CampaignAction.View, state => ({
		...state,
		isLoading: true,
		informationDetailForm: reset(setValue(state.informationDetailForm, CampaignState.initialInformationDetailFormValue)),
		rulesetListForm: reset(setValue(state.rulesetListForm, CampaignState.initialRulesetListFormValue)),
		cappingForm: reset(setValue(state.cappingForm, CampaignState.initialCappingFormValue)),
		allowDelete: true,
	})),
	on(CampaignAction.ViewSuccess, (state, { payload }) => {
		const kind = payload.kind
		const ruleList = state.ruleList
		const information = payload.information
		const rulesetList = payload.rulesets
		const capping = payload.capping
		const allowDelete = payload.allowDelete
		const allowRepublish = payload.allowRepublish

		const campaignRulesetList = []
		const campaignRulesetListView = []

		rulesetList.forEach(x => {
			const rule = x.rule
			const rulesetReward = x.reward

			const conditions = rule.conditions

			let rulesets = {
				byId: {},
				allIds: []
			}
			let ruleConditions = {
				byId: {},
				allIds: []
			}
			let rulesetChilds = {
				byId: {},
				allIds: []
			}

			if (conditions) {
				conditions.forEach(y => {
					const id = y.id
					const ruleAddType = y.ruleAddType

					if (ruleAddType === 'RULE') {
						const ruleKey = y.rule
						let ruleType: CampaignState.Rule

						if (ruleKey.startsWith('INDICATOR_') ||
							ruleKey.startsWith('DESCRIPTION_') ||
							ruleKey.startsWith('BALANCE_') ||
							ruleKey.startsWith('AMOUNT_') ||
							ruleKey.startsWith('DATE_')) {
							ruleType = ruleList.find(t => t.keyValue.key === ruleKey && t.resourceCode === rule.channel)
						} else {
							ruleType = ruleList.find(t => t.keyValue.key === ruleKey)
						}

						rulesets = {
							byId: {
								...rulesets.byId,
								[id]: {
									id,
									ruleAddType,
									rule: {
										keyValue: {
											key: ruleKey,
											value: ruleType && ruleType.keyValue && ruleType.keyValue.value
										}
									},
									form: markAsTouched(getForm(id, ruleKey, y))
								}
							},
							allIds: [...rulesets.allIds, id]
						}
					}

					if (ruleAddType === 'CONDITIONAL_RULES') {
						const conditionGroups = y.conditionGroups
						const ruleConditionIds = []

						if (conditionGroups) {
							conditionGroups.forEach(z => {
								const ruleConditionId = z.id
								const rulesetChildIds = []

								const childConditions = z.conditions
								if (childConditions) {
									childConditions.forEach(i => {
										const rulesetChildId = i.id
										const rulesetChildAddType = i.ruleAddType
										const rulesetChildRuleKey = i.rule
										let rulesetChildRuleType: CampaignState.Rule

										if (rulesetChildRuleKey.startsWith('INDICATOR_') ||
											rulesetChildRuleKey.startsWith('DESCRIPTION_') ||
											rulesetChildRuleKey.startsWith('BALANCE_') ||
											rulesetChildRuleKey.startsWith('AMOUNT_') ||
											rulesetChildRuleKey.startsWith('DATE_')) {
											rulesetChildRuleType = ruleList.find(t => t.keyValue.key === rulesetChildRuleKey && t.resourceCode === rule.channel)
										} else {
											rulesetChildRuleType = ruleList.find(t => t.keyValue.key === rulesetChildRuleKey)
										}

										rulesetChilds = {
											byId: {
												...rulesetChilds.byId,
												[rulesetChildId]: {
													id: rulesetChildId,
													isChild: true,
													ruleAddType: rulesetChildAddType,
													rule: {
														keyValue: {
															key: rulesetChildRuleKey,
															value: rulesetChildRuleType && rulesetChildRuleType.keyValue && rulesetChildRuleType.keyValue.value
														}
													},
													form: markAsTouched(getForm(rulesetChildId, rulesetChildRuleKey, i))
												}
											},
											allIds: [...rulesetChilds.allIds, rulesetChildId]
										}

										rulesetChildIds.push(rulesetChildId)
									})
								}

								ruleConditions = {
									byId: {
										...ruleConditions.byId,
										[ruleConditionId]: {
											id: ruleConditionId,
											rulesetChildIds
										}
									},
									allIds: [...ruleConditions.allIds, ruleConditionId]
								}

								ruleConditionIds.push(ruleConditionId)
							})
						}

						rulesets = {
							byId: {
								...rulesets.byId,
								[id]: {
									id,
									ruleAddType,
									ruleConditionIds
								}
							},
							allIds: [...rulesets.allIds, id]
						}
					}
				})
			}

			let campaignTiers = {
				byId: {},
				allIds: []
			}

			if (rulesetReward.tierRewardSetting) {
				rulesetReward.tierRewardSetting.forEach(tier => {
					const tierId = UUID.getUUID()

					campaignTiers = {
						byId: {
							...campaignTiers.byId,
							[tierId]: {
								id: tierId,
								form: markAsTouched(getCampaignTierForm(tierId, tier))
							}
						},
						allIds: [...campaignTiers.allIds, tierId]
					}
				})
			}

			campaignRulesetList.push({
				id: x.id,
				rulesetDetailForm: {
					code: rule.code,
					name: rule.name,
					channel: rule.channel,
					modifiedDate: rule.modifiedDate,
					rulesets,
					ruleConditions,
					rulesetChilds
				},
				rulesetRewardForm: {
					rewardPool: rulesetReward.rewardPool,
					amountField: rulesetReward.amountField,
					type: rulesetReward.type,
					rewardCreditMethod: rulesetReward.rewardCreditMethod,
					transactionMethod: rulesetReward.transactionMethod,
					rewardMethod: rulesetReward.rewardMethod,
					conditionFactor: rulesetReward.conditionFactor,
					valueFactor: rulesetReward.valueFactor,
					pointFactor: rulesetReward.pointFactor,
					accumulationCycleType: (rulesetReward.accumulationCycleType === 'OD'
						|| rulesetReward.accumulationCycleType === 'FD'
						|| rulesetReward.accumulationCycleType === 'ID') ? 'Y' :
						(rulesetReward.accumulationCycleType === 'AC'
						|| rulesetReward.accumulationCycleType === 'BC'
						|| rulesetReward.accumulationCycleType === 'OC') ? 'B' : rulesetReward.accumulationCycleType,
					accumulationCycleDay: rulesetReward.accumulationCycleDay,
					accumulationCycleMonth: rulesetReward.accumulationCycleMonth,
					accumulationCycleTypeOption: (rulesetReward.accumulationCycleType === 'OD'
						|| rulesetReward.accumulationCycleType === 'FD'
						|| rulesetReward.accumulationCycleType === 'ID') ? rulesetReward.accumulationCycleType : '',
					billingCycleType: rulesetReward.accumulationCycleType === 'AC' || rulesetReward.accumulationCycleType === 'BC' || rulesetReward.accumulationCycleType === 'OC' ? rulesetReward.accumulationCycleType : '',
					numberOfDays: rulesetReward.accumulationCycleType === 'AC' || rulesetReward.accumulationCycleType === 'BC' || rulesetReward.accumulationCycleType === 'OC' ? rulesetReward.accumulationCycleDay : '',
					rounding: rulesetReward.rounding,
					roundingValue: rulesetReward.roundingValue,
					numberDecimal: rulesetReward.numberDecimal,
					numberDecimalValue: rulesetReward.numberDecimalValue,
					campaignTiers,
					rewardLevel: rulesetReward.rewardLevel
				}
			})
		})

		return ({
			...state,
			isLoading: false,
			code: information.code,
			allowDelete,
			allowRepublish,
			kind,
			informationDetailForm: CampaignState.validateInformationDetailForm(state)(
				markAsTouched(
					setValue(state.informationDetailForm, {
						channel: information.channel,
						code: information.code,
						name: information.name,
						priority: information.priority,
						startDate: Util.dateStrToISOString(information.startDate),
						endDate: Util.dateStrToISOString(information.endDate),
						enableConditionalReward: information.enableConditionalReward,
						createdBy: payload.createdBy,
						createdDate: payload.createdDate,
						modifiedBy: payload.modifiedBy,
						modifiedDate: payload.modifiedDate
					})
				),
			),
			informationDetailFormView: {
				channel: information.channel,
				code: information.code,
				name: information.name,
				priority: information.priority,
				startDate: Util.dateStrToISOString(information.startDate),
				endDate: Util.dateStrToISOString(information.endDate),
				enableConditionalReward: information.enableConditionalReward,
				createdBy: payload.createdBy,
				createdDate: payload.createdDate,
				modifiedBy: payload.modifiedBy,
				modifiedDate: payload.modifiedDate
			},
			rulesetListForm: CampaignState.validateRulesetListForm(
				markAsTouched(
					setValue(state.rulesetListForm, {
						...state.rulesetListForm.value,
						campaignRulesetList
					})
				)
			),
			rulesetListFormView: {
				...state.rulesetListForm.value,
				campaignRulesetList,
			},
			cappingForm: CampaignState.validateCappingForm(state)(
				markAsTouched(
					setValue(state.cappingForm, {
						customerCapType: capping.customerCapType,
						customerCap: capping.customerCapType === 'P' ? Number(capping.customerCap).toFixed(2) : capping.customerCap,
						campaignCapType: capping.campaignCapType,
						campaignCap: capping.campaignCap,
						capCycleType: (capping.capCycleType === 'OC')
							|| (capping.capCycleType === 'AC')
							|| (capping.capCycleType === 'BC') ? 'B' : capping.capCycleType,
						capCycleDay: capping.capCycleDay,
						capCycleMonth: capping.capCycleMonth,
						cycleTypeOption: (capping.capCycleType !== 'OC')
							&& (capping.capCycleType !== 'AC')
							&& (capping.capCycleType !== 'BC') ? '' : capping.capCycleType
					})
				)
			),
			cappingFormView: {
				customerCapType: capping.customerCapType,
				customerCap: capping.customerCapType === 'P' ? Number(capping.customerCap).toFixed(2) : capping.customerCap,
				campaignCapType: capping.campaignCapType,
				campaignCap: capping.campaignCap,
				capCycleType: (capping.capCycleType === 'OC')
					|| (capping.capCycleType === 'AC')
					|| (capping.capCycleType === 'BC') ? 'B' : capping.capCycleType,
				capCycleDay: capping.capCycleDay,
				capCycleMonth: capping.capCycleMonth,
				cycleTypeOption: (capping.capCycleType !== 'OC')
					&& (capping.capCycleType !== 'AC')
					&& (capping.capCycleType !== 'BC') ? '' : capping.capCycleType
			},
		})
	}),
	on(CampaignAction.Create, state => ({
		...state,
		isLoading: true
	})),
	on(CampaignAction.CreateSuccess, (state, { code }) => ({
		...state,
		isLoading: false,
		code
	})),
	on(CampaignAction.Update, state => ({
		...state,
		isLoading: true
	})),
	on(CampaignAction.UpdateSuccess, (state, { code }) => ({
		...state,
		isLoading: false,
		code
	})),
	on(CampaignAction.Republish, state => ({
		...state,
		isLoading: true
	})),
	on(CampaignAction.RepublishSuccess, state => ({
		...state,
		isLoading: false
	})),
	on(CampaignAction.Delete, state => ({
		...state,
		isLoading: true
	})),
	on(CampaignAction.DeleteSuccess, () => CampaignState.initialState),
	on(CampaignAction.Publish, state => ({
		...state,
		isLoading: true
	})),
	on(CampaignAction.GetRuleList, state => ({
		...state,
		isLoading: true
	})),
	on(CampaignAction.GetRuleListSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		ruleList: payload.map(({ key, value, resourceCode }) => (({
			keyValue: { key, value },
			resourceCode
		})))
	})),
	on(CampaignAction.GetCampaignData, state => ({
		...state,
		isLoading: true
	})),
	on(CampaignAction.GetCampaignDataSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		campaignData: payload
	})),
	on(CampaignAction.OnChangeEnableConditionalRewardSucccess, state => ({
		...state,
		rulesetListForm: setValue(state.rulesetListForm, CampaignState.initialRulesetListFormValue)
	})),
	on(CampaignAction.OnChangeEnableConditionalRewardFail, state => ({
		...state,
		informationDetailForm: setValue(state.informationDetailForm, {
			...state.informationDetailForm.value,
			enableConditionalReward: !state.informationDetailForm.value.enableConditionalReward
		})
	})),
	on(CampaignAction.OnChangeChannelGetRulesetList, state => ({
		...state,
		isLoading: true
	})),
	on(CampaignAction.OnChangeChannelGetRulesetListSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		ruleList: payload.map(({ key, value, resourceCode }) => (({
			keyValue: { key, value },
			resourceCode
		}))),
		resourceType: state.campaignData.channelList.find(x => x.key === state.rulesetDetailForm.value.channel).resourceType
	})),
	on(CampaignAction.OnChangeChannelGetRulesetData, state => ({
		...state,
		isLoading: true
	})),
	on(CampaignAction.OnChangeChannelGetRulesetDataSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		rulesetData: payload
	})),
	on(CampaignAction.OnChangeChannelValue, state => {
		const id = UUID.getUUID()
		const rulesetsVal = CampaignState.initialRulesetDetailFormValue

		// set default ruleset
		const chooseDate = state.ruleList.find(x => x.keyValue.key === 'CHOOSE_DATE')

		let ruleset: Ruleset = null
		if (state.rulesetDetailForm.controls.channel.value === 'CARDLINK') {
			ruleset = {
				id,
				rule: chooseDate.keyValue.key,
				ruleAddType: 'RULE',
				values: ['TRANSACTION_DATE']
			}
		}

		return ({
			...state,
			rulesetDetailForm: markAsTouched(reset(setValue(state.rulesetDetailForm, chooseDate ? {
				...state.rulesetDetailForm.value,
				rulesets: {
					byId: {
						...rulesetsVal.rulesets.byId,
						[id]: {
							id,
							ruleAddType: 'RULE',
							rule: chooseDate,
							form: validateForm(id, chooseDate.keyValue.key, getForm(id, chooseDate.keyValue.key, ruleset))
						}
					},
					allIds: [...rulesetsVal && rulesetsVal.rulesets.allIds, id]
				},
				ruleConditions: {
					byId: {},
					allIds: []
				},
				rulesetChilds: {
					byId: {},
					allIds: []
				}
			} : rulesetsVal))),
			rulesetRewardForm: setValue(state.rulesetRewardForm, {
				...state.rulesetRewardForm.value,
				accumulationCycleDay: state.rulesetRewardForm.value.accumulationCycleTypeOption === 'FD' ? '' : state.rulesetRewardForm.value.accumulationCycleDay,
				accumulationCycleMonth: state.rulesetRewardForm.value.accumulationCycleTypeOption === 'FD' ? '' : state.rulesetRewardForm.value.accumulationCycleMonth,
				accumulationCycleTypeOption: '',
				amountField: ''
			})
		})
	}),
	on(CampaignAction.OnChangeCustomerCapType, state => ({
		...state,
		cappingForm: setValue(state.cappingForm, {
			...state.cappingForm.value,
			customerCap: state.cappingForm.controls.customerCapType.value ? state.cappingForm.controls.customerCap.value : ''
		})
	})),
	on(CampaignAction.OnChangeCapCycleType, state => ({
		...state,
		cappingForm: setValue(state.cappingForm, {
			...state.cappingForm.value,
			capCycleDay: '',
			capCycleMonth: state.cappingForm.controls.capCycleType.value === 'Y' ? '' : state.cappingForm.controls.capCycleMonth.value,
			cycleTypeOption: ''
		})
	})),
	on(CampaignAction.OnChangeBillingCycleType, state => ({
		...state,
		cappingForm: setValue(state.cappingForm, {
			...state.cappingForm.value,
			capCycleDay: ''
		})
	})),
	on(CampaignAction.OnChangeRulesetBillingCycleType, state => ({
		...state,
		rulesetRewardForm: setValue(state.rulesetRewardForm, {
			...state.rulesetRewardForm.value,
			numberOfDays: ''
		})
	})),
	on(CampaignAction.OnChangeRewardPool, state => ({
		...state,
		rulesetRewardForm: setValue(state.rulesetRewardForm, {
			...state.rulesetRewardForm.value,
			amountField: '',
			type: '',
			rewardCreditMethod: '',
			transactionMethod: '',
			rewardMethod: '',
			conditionFactor: '',
			valueFactor: '',
			pointFactor: '',
			accumulationCycleType: '',
			accumulationCycleDay: '',
			accumulationCycleMonth: '',
			rounding: false,
			roundingValue: '',
			numberDecimal: '',
			numberDecimalValue: '',
			campaignTiers: {
				byId: {},
				allIds: []
			},
			rewardLevel: ''
		})
	})),
	on(CampaignAction.OnChangeType, state => {
		let rulesetRewardFormVal = state.rulesetRewardForm.value
		const type = rulesetRewardFormVal.type

		rulesetRewardFormVal = {
			...rulesetRewardFormVal,
			rewardCreditMethod: '',
			transactionMethod: '',
			rewardMethod: '',
			conditionFactor: '',
			valueFactor: '',
			pointFactor: '',
			accumulationCycleType: '',
			accumulationCycleDay: '',
			accumulationCycleMonth: '',
			billingCycleType: '',
			numberOfDays: '',
			rounding: false,
			roundingValue: '',
			numberDecimal: '',
			numberDecimalValue: '',
			campaignTiers: {
				byId: {},
				allIds: []
			},
			rewardLevel: ''
		}

		if (type === 'TPT') {
			rulesetRewardFormVal = {
				...rulesetRewardFormVal,
				rewardCreditMethod: 'I',
				transactionMethod: 'AMOUNT'
			}
		}

		if (type === 'INC') {
			rulesetRewardFormVal = {
				...rulesetRewardFormVal,
				rewardCreditMethod: 'CCE'
			}
		}

		return ({
			...state,
			rulesetRewardForm: setValue(state.rulesetRewardForm, rulesetRewardFormVal)
		})
	}),
	on(CampaignAction.OnChangeRewardCreditMethod, state => {
		let rulesetRewardFormVal = state.rulesetRewardForm.value
		const rewardLevel = rulesetRewardFormVal.rewardLevel
		const rewardCreditMethod = rulesetRewardFormVal.rewardCreditMethod

		rulesetRewardFormVal = {
			...rulesetRewardFormVal,
			accumulationCycleType: '',
			accumulationCycleDay: '',
			accumulationCycleMonth: '',
			accumulationCycleTypeOption: '',
			billingCycleType: '',
			numberOfDays: ''
		}

		if (rewardLevel === 'ACCOUNT') {
			if (rewardCreditMethod === 'I') {
				rulesetRewardFormVal = {
					...rulesetRewardFormVal,
					transactionMethod: 'AMOUNT'
				}
			}
		}

		return ({
			...state,
			rulesetRewardForm: setValue(state.rulesetRewardForm, rulesetRewardFormVal)
		})

	}),
	on(CampaignAction.OnChangeAccumulationYearlyCycleType, state => ({
		...state,
		rulesetRewardForm: setValue(state.rulesetRewardForm, {
			...state.rulesetRewardForm.value,
			accumulationCycleDay: '',
			accumulationCycleMonth: ''
		})
	})),
	on(CampaignAction.OnChangeTransactionMethod, state => {
		let rulesetRewardFormVal = state.rulesetRewardForm.value

		const type = rulesetRewardFormVal.type
		const transactionMethod = rulesetRewardFormVal.transactionMethod
		const conditionFactor = rulesetRewardFormVal.conditionFactor
		const rewardMethod = rulesetRewardFormVal.rewardMethod

		rulesetRewardFormVal = {
			...rulesetRewardFormVal,
			rewardMethod: '',
			conditionFactor: '',
			valueFactor: '',
			pointFactor: '',
			campaignTiers: {
				byId: {},
				allIds: []
			},
			rounding: false,
			roundingValue: '',
			numberDecimal: '',
			numberDecimalValue: ''
		}

		switch (type) {
			case 'X2X':
				if (transactionMethod === 'AMOUNT') {
					rulesetRewardFormVal = {
						...rulesetRewardFormVal,
						rewardMethod: 'RATIO'
					}
				}
				if (transactionMethod === 'SWIPE') {
					rulesetRewardFormVal = {
						...rulesetRewardFormVal,
						rewardMethod: 'FIX'
					}
				}
				break
			case 'OX':
			case 'PX':
				if (transactionMethod) {
					rulesetRewardFormVal = {
						...rulesetRewardFormVal,
						rewardMethod: 'FIX'
					}
				}
				break
			case 'TPT':
				break
			case 'TPC':
				if (transactionMethod === 'SWIPE') {
					rulesetRewardFormVal = {
						...rulesetRewardFormVal,
						rewardMethod: 'FIX'
					}
				}
				break
			case 'INC':
				if (transactionMethod) {
					rulesetRewardFormVal = {
						...rulesetRewardFormVal,
						conditionFactor,
						rewardMethod
					}
				}
				break
		}

		return ({
			...state,
			rulesetRewardForm: setValue(state.rulesetRewardForm, rulesetRewardFormVal)
		})
	}),
	on(CampaignAction.OnChangeRewardMethod, state => ({
		...state,
		rulesetRewardForm: setValue(state.rulesetRewardForm, {
			...state.rulesetRewardForm.value,
			campaignTiers: {
				byId: {},
				allIds: []
			},
			rounding: false,
			roundingValue: '',
			numberDecimal: '',
			numberDecimalValue: '',
			valueFactor: '',
			pointFactor: ''
		})
	})),
	on(CampaignAction.OnChangeAccumulationCycleType, state => ({
		...state,
		rulesetRewardForm: setValue(state.rulesetRewardForm, {
			...state.rulesetRewardForm.value,
			accumulationCycleTypeOption: '',
			accumulationCycleDay: '',
			accumulationCycleMonth: state.rulesetRewardForm.controls.accumulationCycleType.value === 'Y' ? '' : state.rulesetRewardForm.controls.accumulationCycleMonth.value,
			billingCycleType: '',
			numberOfDays: ''
		})
	})),

	on(CampaignAction.OnChangeRounding, state => ({
		...state,
		rulesetRewardForm: setValue(state.rulesetRewardForm, {
			...state.rulesetRewardForm.value,
			roundingValue: state.rulesetRewardForm.controls.rounding.value ? state.rulesetRewardForm.controls.roundingValue.value : '',
			numberDecimal: state.rulesetRewardForm.controls.rounding.value ? state.rulesetRewardForm.controls.numberDecimal.value : '',
			numberDecimalValue: state.rulesetRewardForm.controls.rounding.value ? state.rulesetRewardForm.controls.numberDecimalValue.value : ''
		})
	})),
	on(CampaignAction.OnChangeSelectedDay, (state, { ruleset, value }) => {
		const id = ruleset.id

		const formControls = state.rulesetDetailForm.controls
		let rulesetsVal = formControls.rulesets.value
		let rulesetChildsVal = formControls.rulesetChilds.value

		const form = createFormGroupState(id, {
			...CampaignState.initialSelectedDayFormValue,
			value
		})

		const isChild = ruleset.isChild
		if (isChild) {
			rulesetChildsVal = {
				byId: {
					...rulesetChildsVal.byId,
					[id]: {
						...rulesetChildsVal.byId[id],
						form
					}
				},
				allIds: [...rulesetChildsVal.allIds]
			}
		} else {
			rulesetsVal = {
				byId: {
					...rulesetsVal.byId,
					[id]: {
						...rulesetsVal.byId[id],
						form
					}
				},
				allIds: [...rulesetsVal.allIds]
			}
		}

		return ({
			...state, rulesetDetailForm: setValue(state.rulesetDetailForm, {
				...state.rulesetDetailForm.value,
				rulesets: rulesetsVal,
				rulesetChilds: rulesetChildsVal
			})
		})
	}),
	on(CampaignAction.OnChangeBirthday, (state, { ruleset, value }) => {
		const id = ruleset.id

		const formControls = state.rulesetDetailForm.controls
		let rulesetsVal = formControls.rulesets.value
		let rulesetChildsVal = formControls.rulesetChilds.value

		const form = createFormGroupState(id, {
			...CampaignState.initialBirthdayFormValue,
			value
		})

		const isChild = ruleset.isChild
		if (isChild) {
			rulesetChildsVal = {
				byId: {
					...rulesetChildsVal.byId,
					[id]: {
						...rulesetChildsVal.byId[id],
						form
					}
				},
				allIds: [...rulesetChildsVal.allIds]
			}
		} else {
			rulesetsVal = {
				byId: {
					...rulesetsVal.byId,
					[id]: {
						...rulesetsVal.byId[id],
						form
					}
				},
				allIds: [...rulesetsVal.allIds]
			}
		}

		return ({
			...state, rulesetDetailForm: setValue(state.rulesetDetailForm, {
				...state.rulesetDetailForm.value,
				rulesets: rulesetsVal,
				rulesetChilds: rulesetChildsVal
			})
		})
	}),
	on(CampaignAction.OnChangeXDaysBefore, (state, { ruleset, value }) => {
		const id = ruleset.id

		const formControls = state.rulesetDetailForm.controls
		let rulesetsVal = formControls.rulesets.value
		let rulesetChildsVal = formControls.rulesetChilds.value


		const newRulesets: Rulesets = {
			byId: {
				...rulesetsVal.byId,
				...rulesetChildsVal.byId
			},
			allIds: {
				...rulesetsVal.allIds,
				...rulesetChildsVal.allIds
			}
		}
		const currentRuleset = newRulesets.byId[id]

		const form = createFormGroupState(id, {
			...currentRuleset.form.value,
			xDaysBefore: value ? `${+value}` : '0'
		})

		const isChild = ruleset.isChild
		if (isChild) {
			rulesetChildsVal = {
				byId: {
					...rulesetChildsVal.byId,
					[id]: {
						...rulesetChildsVal.byId[id],
						form
					}
				},
				allIds: [...rulesetChildsVal.allIds]
			}
		} else {
			rulesetsVal = {
				byId: {
					...rulesetsVal.byId,
					[id]: {
						...rulesetsVal.byId[id],
						form
					}
				},
				allIds: [...rulesetsVal.allIds]
			}
		}

		return ({
			...state, rulesetDetailForm: setValue(state.rulesetDetailForm, {
				...state.rulesetDetailForm.value,
				rulesets: rulesetsVal,
				rulesetChilds: rulesetChildsVal
			})
		})
	}),
	on(CampaignAction.OnChangeXDaysAfter, (state, { ruleset, value }) => {
		const id = ruleset.id

		const formControls = state.rulesetDetailForm.controls
		let rulesetsVal = formControls.rulesets.value
		let rulesetChildsVal = formControls.rulesetChilds.value


		const newRulesets: Rulesets = {
			byId: {
				...rulesetsVal.byId,
				...rulesetChildsVal.byId
			},
			allIds: {
				...rulesetsVal.allIds,
				...rulesetChildsVal.allIds
			}
		}
		const currentRuleset = newRulesets.byId[id]

		const form = createFormGroupState(id, {
			...currentRuleset.form.value,
			xDaysAfter: value ? `${+value}` : '0'
		})

		const isChild = ruleset.isChild
		if (isChild) {
			rulesetChildsVal = {
				byId: {
					...rulesetChildsVal.byId,
					[id]: {
						...rulesetChildsVal.byId[id],
						form
					}
				},
				allIds: [...rulesetChildsVal.allIds]
			}
		} else {
			rulesetsVal = {
				byId: {
					...rulesetsVal.byId,
					[id]: {
						...rulesetsVal.byId[id],
						form
					}
				},
				allIds: [...rulesetsVal.allIds]
			}
		}

		return ({
			...state, rulesetDetailForm: setValue(state.rulesetDetailForm, {
				...state.rulesetDetailForm.value,
				rulesets: rulesetsVal,
				rulesetChilds: rulesetChildsVal
			})
		})
	}),
	on(CampaignAction.OnChangeReversalTCGroup, (state, { ruleset, isCheck }) => {
		const id = ruleset.id

		const formControls = state.rulesetDetailForm.controls
		let rulesetsVal = formControls.rulesets.value
		let rulesetChildsVal = formControls.rulesetChilds.value

		const newRulesets: Rulesets = {
			byId: {
				...rulesetsVal.byId,
				...rulesetChildsVal.byId
			},
			allIds: {
				...rulesetsVal.allIds,
				...rulesetChildsVal.allIds
			}
		}
		const currentRuleset = newRulesets.byId[id]

		let form = createFormGroupState(id, {
			...currentRuleset.form.value,
			reversalValues: isCheck ? currentRuleset.form.controls.reversalValues.value : box([])
		})

		form = validateForm(id, ruleset.rule.keyValue.key, form, state)

		const isChild = ruleset.isChild
		if (isChild) {
			rulesetChildsVal = {
				byId: {
					...rulesetChildsVal.byId,
					[id]: {
						...rulesetChildsVal.byId[id],
						form
					}
				},
				allIds: [...rulesetChildsVal.allIds]
			}
		} else {
			rulesetsVal = {
				byId: {
					...rulesetsVal.byId,
					[id]: {
						...rulesetsVal.byId[id],
						form
					}
				},
				allIds: [...rulesetsVal.allIds]
			}
		}

		return ({
			...state, rulesetDetailForm: setValue(state.rulesetDetailForm, {
				...state.rulesetDetailForm.value,
				rulesets: rulesetsVal,
				rulesetChilds: rulesetChildsVal
			})
		})
	}),
	on(CampaignAction.OnChangeSelectedDayWeekly, (state, { ruleset, day, isCheck }) => {
		const id = ruleset.id

		const formControls = state.rulesetDetailForm.controls
		let rulesetsVal = formControls.rulesets.value
		let rulesetChildsVal = formControls.rulesetChilds.value

		const newRulesets: Rulesets = {
			byId: {
				...rulesetsVal.byId,
				...rulesetChildsVal.byId
			},
			allIds: {
				...rulesetsVal.allIds,
				...rulesetChildsVal.allIds
			}
		}
		const currentRuleset = newRulesets.byId[id]

		let form = createFormGroupState(id, {
			...currentRuleset.form.value
		})

		switch (day) {
			case 'monday':
				if (!isCheck) {
					form = createFormGroupState(id, {
						...currentRuleset.form.value,
						weekly: {
							...currentRuleset.form.controls.weekly.value,
							monday: {
								isCheck: false,
								startTime: '00:00',
								endTime: '23:59'
							}
						}
					})
				}
				break
			case 'tuesday':
				if (!isCheck) {
					form = createFormGroupState(id, {
						...currentRuleset.form.value,
						weekly: {
							...currentRuleset.form.controls.weekly.value,
							tuesday: {
								isCheck: false,
								startTime: '00:00',
								endTime: '23:59'
							}
						}
					})
				}
				break
			case 'wednesday':
				if (!isCheck) {
					form = createFormGroupState(id, {
						...currentRuleset.form.value,
						weekly: {
							...currentRuleset.form.controls.weekly.value,
							wednesday: {
								isCheck: false,
								startTime: '00:00',
								endTime: '23:59'
							}
						}
					})
				}
				break
			case 'thursday':
				if (!isCheck) {
					form = createFormGroupState(id, {
						...currentRuleset.form.value,
						weekly: {
							...currentRuleset.form.controls.weekly.value,
							thursday: {
								isCheck: false,
								startTime: '00:00',
								endTime: '23:59'
							}
						}
					})
				}
				break
			case 'friday':
				if (!isCheck) {
					form = createFormGroupState(id, {
						...currentRuleset.form.value,
						weekly: {
							...currentRuleset.form.controls.weekly.value,
							friday: {
								isCheck: false,
								startTime: '00:00',
								endTime: '23:59'
							}
						}
					})
				}
				break
			case 'saturday':
				if (!isCheck) {
					form = createFormGroupState(id, {
						...currentRuleset.form.value,
						weekly: {
							...currentRuleset.form.controls.weekly.value,
							saturday: {
								isCheck: false,
								startTime: '00:00',
								endTime: '23:59'
							}
						}
					})
				}
				break
			case 'sunday':
				if (!isCheck) {
					form = createFormGroupState(id, {
						...currentRuleset.form.value,
						weekly: {
							...currentRuleset.form.controls.weekly.value,
							sunday: {
								isCheck: false,
								startTime: '00:00',
								endTime: '23:59'
							}
						}
					})
				}
				break
		}


		const isChild = ruleset.isChild
		if (isChild) {
			rulesetChildsVal = {
				byId: {
					...rulesetChildsVal.byId,
					[id]: {
						...rulesetChildsVal.byId[id],
						form
					}
				},
				allIds: [...rulesetChildsVal.allIds]
			}
		} else {
			rulesetsVal = {
				byId: {
					...rulesetsVal.byId,
					[id]: {
						...rulesetsVal.byId[id],
						form
					}
				},
				allIds: [...rulesetsVal.allIds]
			}
		}

		return ({
			...state, rulesetDetailForm: setValue(state.rulesetDetailForm, {
				...state.rulesetDetailForm.value,
				rulesets: rulesetsVal,
				rulesetChilds: rulesetChildsVal
			})
		})
	}),
	on(CampaignAction.OnChangeRewardLevel, state => {
		let rulesetRewardFormVal = state.rulesetRewardForm.value
		const rewardLevel = rulesetRewardFormVal.rewardLevel

		if (rewardLevel === 'CUSTOMER' || rewardLevel === 'ACCOUNT') {
			rulesetRewardFormVal = {
				...rulesetRewardFormVal,
				rewardCreditMethod: 'CCE'
			}
		}

		return ({
			...state,
			rulesetRewardForm: setValue(state.rulesetRewardForm, rulesetRewardFormVal)
		})
	}),
	on(CampaignAction.OnChangeCycleMonth, state => ({
		...state,
		rulesetRewardForm: setValue(state.rulesetRewardForm, {
			...state.rulesetRewardForm.value,
			accumulationCycleDay: ''
		})
	})),
	on(CampaignAction.OnChangeCapCycleMonth, state => ({
		...state,
		cappingForm: setValue(state.cappingForm, {
			...state.cappingForm.value,
			capCycleDay: ''
		})
	})),
	on(CampaignAction.NewRulesetDialog, state => {
		const id = UUID.getUUID()
		const rulesetsVal = CampaignState.initialRulesetDetailFormValue

		// set default ruleset
		const payload = state.ruleList.find(x => x.keyValue.key === 'CHOOSE_DATE')

		return ({
			...state,
			campaignRulesetId: undefined,
			campaignRulesetAction: 'CREATE',
			rulesetDetailForm: reset(setValue(state.rulesetDetailForm, payload ? {
				...rulesetsVal,
				rulesets: {
					byId: {
						...rulesetsVal.rulesets.byId,
						[id]: {
							id,
							ruleAddType: 'RULE',
							rule: payload,
							form: getForm(id, payload.keyValue.key)
						}
					},
					allIds: [...rulesetsVal && rulesetsVal.rulesets.allIds, id]
				}
			} : rulesetsVal)),
			rulesetRewardForm: reset(setValue(state.rulesetRewardForm, CampaignState.initialRulesetRewardFormValue))
		})
	}),
	on(CampaignAction.AddRuleset, (state, { payload }) => {
		const id = UUID.getUUID()
		const formControls = state.rulesetDetailForm.controls
		const rulesetsVal = formControls.rulesets.value

		state = {
			...state,
			rulesetDetailForm: setValue(state.rulesetDetailForm, {
				...state.rulesetDetailForm.value,
				rulesets: {
					byId: {
						...rulesetsVal.byId,
						[id]: {
							id,
							ruleAddType: 'RULE',
							rule: payload,
							form: getForm(id, payload.keyValue.key)
						}
					},
					allIds: [...rulesetsVal && rulesetsVal.allIds, id]
				}
			})
		}

		state = {
			...state,
			rulesetDetailForm: setValue(state.rulesetDetailForm, {
				...state.rulesetDetailForm.value,
				rulesets: {
					byId: {
						...state.rulesetDetailForm.value.rulesets.byId,
						[id]: {
							...state.rulesetDetailForm.value.rulesets.byId[id],
							form: validateForm(id, payload.keyValue.key, state.rulesetDetailForm.value.rulesets.byId[id].form, state)
						}
					},
					allIds: [...state.rulesetDetailForm.value.rulesets && state.rulesetDetailForm.value.rulesets.allIds]
				}
			})
		}

		return state
	}),
	on(CampaignAction.AddGroup, state => {
		const id = UUID.getUUID()
		const formControls = state.rulesetDetailForm.controls
		const rulesetsVal = formControls.rulesets.value

		return ({
			...state,
			rulesetDetailForm: setValue(state.rulesetDetailForm, {
				...state.rulesetDetailForm.value,
				rulesets: {
					byId: {
						...rulesetsVal.byId,
						[id]: {
							id,
							ruleAddType: 'CONDITIONAL_RULES',
							ruleConditionIds: []
						}
					},
					allIds: [...rulesetsVal.allIds, id]
				}
			})
		})
	}),
	on(CampaignAction.AddCondition, (state, { payload }) => {
		const id = UUID.getUUID()
		const formControls = state.rulesetDetailForm.controls
		const rulesetsVal = formControls.rulesets.value
		const ruleConditionsVal = formControls.ruleConditions.value

		return ({
			...state,
			rulesetDetailForm: setValue(state.rulesetDetailForm, {
				...state.rulesetDetailForm.value,
				rulesets: {
					byId: {
						...rulesetsVal.byId,
						[payload.id]: {
							...rulesetsVal.byId[payload.id],
							ruleConditionIds: [...rulesetsVal.byId[payload.id].ruleConditionIds, id]
						}
					},
					allIds: [...rulesetsVal.allIds]
				},
				ruleConditions: {
					byId: {
						...ruleConditionsVal.byId,
						[id]: {
							id,
							rulesetChildIds: []
						}
					},
					allIds: [...ruleConditionsVal.allIds, id]
				}
			})
		})
	}),
	on(CampaignAction.AddRulesetChild, (state, { payload }) => {
		const id = UUID.getUUID()
		const ruleCondition = state.ruleCondition
		const formControls = state.rulesetDetailForm.controls
		const ruleConditionsVal = formControls.ruleConditions.value
		const rulesetChildsVal = formControls.rulesetChilds.value

		state = {
			...state,
			rulesetDetailForm: setValue(state.rulesetDetailForm, {
				...state.rulesetDetailForm.value,
				ruleConditions: {
					byId: {
						...ruleConditionsVal.byId,
						[ruleCondition.id]: {
							...ruleConditionsVal.byId[ruleCondition.id],
							rulesetChildIds: [...ruleConditionsVal.byId[ruleCondition.id].rulesetChildIds, id]
						}
					},
					allIds: [...ruleConditionsVal.allIds]
				},
				rulesetChilds: {
					byId: {
						...rulesetChildsVal.byId,
						[id]: {
							id,
							isChild: true,
							ruleAddType: 'RULE',
							rule: payload,
							form: getForm(id, payload.keyValue.key)
						}
					},
					allIds: [...rulesetChildsVal.allIds, id]
				}
			})
		}

		state = {
			...state,
			rulesetDetailForm: setValue(state.rulesetDetailForm, {
				...state.rulesetDetailForm.value,
				ruleConditions: {
					byId: {
						...state.rulesetDetailForm.value.ruleConditions.byId,
						[ruleCondition.id]: {
							...state.rulesetDetailForm.value.ruleConditions.byId[ruleCondition.id],
							rulesetChildIds: [...state.rulesetDetailForm.value.ruleConditions.byId[ruleCondition.id].rulesetChildIds]
						}
					},
					allIds: [...state.rulesetDetailForm.value.ruleConditions.allIds]
				},
				rulesetChilds: {
					byId: {
						...state.rulesetDetailForm.value.rulesetChilds.byId,
						[id]: {
							...state.rulesetDetailForm.value.rulesetChilds.byId[id],
							form: validateForm(id, payload.keyValue.key, state.rulesetDetailForm.value.rulesetChilds.byId[id].form, state)
						}
					},
					allIds: [...state.rulesetDetailForm.value.rulesetChilds.allIds]
				}
			})
		}

		return state
	}),
	on(CampaignAction.DeleteRuleset, (state, { ruleset }) => {
		const { id, isChild, ruleConditionIds = [] } = ruleset

		const formControls = state.rulesetDetailForm.controls
		const rulesetGroupById = formControls.rulesets.value.byId
		const ruleConditionGroupById = formControls.ruleConditions.value.byId
		const rulesetChildrenGroupById = formControls.rulesetChilds.value.byId

		if (isChild) {

			const newRuleCondition = Object.values(ruleConditionGroupById)
				.reduce((obj, ruleCondition) => {
					if (ruleCondition.rulesetChildIds) {
						obj[ruleCondition.id] = {
							...ruleCondition,
							rulesetChildIds: ruleCondition.rulesetChildIds.filter(y => y !== id)
						}
					} else {
						obj[ruleCondition.id] = ruleCondition
					}
					return obj
				}, {})

			const newRulesetChildren = Object.values(rulesetChildrenGroupById)
				.filter(child => child.id !== id)
				.reduce((obj, rulesetChild) => ({ ...obj, [rulesetChild.id]: rulesetChild }), {})


			return {
				...state,
				rulesetDetailForm: setValue(state.rulesetDetailForm, {
					...state.rulesetDetailForm.value,
					ruleConditions: {
						byId: newRuleCondition,
						allIds: Object.keys(newRuleCondition)
					}
					,
					rulesetChilds: {
						byId: newRulesetChildren,
						allIds: Object.keys(newRulesetChildren)
					}
				})
			}
		} else {

			const newRuleset = Object.values(rulesetGroupById)
				.filter(value => value.id !== id)
				.reduce((obj, cur) => ({ ...obj, [cur.id]: cur }), {})

			const newRuleConditions = Object.values(ruleConditionGroupById)
				.filter(value => !ruleConditionIds.includes(value.id))
				.reduce((obj, cur) => ({ ...obj, [cur.id]: cur }), {})


			const rulesetChildIdsToRemove: string[] = Object.values(ruleConditionGroupById)
				.filter(value => ruleConditionIds.includes(value.id))
				.reduce((arr, cur) => [...arr, ...(cur.rulesetChildIds || [])], [])

			const newRulesetChildren = Object.values(rulesetChildrenGroupById)
				.filter(value => !rulesetChildIdsToRemove.includes(value.id))
				.reduce((obj, cur) => ({ ...obj, [cur.id]: cur }), {})


			return {
				...state,
				rulesetDetailForm: setValue(state.rulesetDetailForm, {
					...state.rulesetDetailForm.value,
					rulesets: {
						byId: newRuleset,
						allIds: Object.keys(newRuleset)
					},
					ruleConditions: {
						byId: newRuleConditions,
						allIds: Object.keys(newRuleConditions)
					}
					,
					rulesetChilds: {
						byId: newRulesetChildren,
						allIds: Object.keys(newRulesetChildren)
					},
				})
			}
		}
	}),
	on(CampaignAction.DeleteCondition, (state, { ruleCondition }) => {
		const { id, rulesetChildIds } = ruleCondition
		const formControls = state.rulesetDetailForm.controls

		const newRuleset = Object.values(formControls.rulesets.value.byId)
			.reduce((obj, val) => {
				if (val.ruleConditionIds) {
					obj[val.id] = {
						...val,
						ruleConditionIds: val.ruleConditionIds.filter(y => y !== id)
					}
				} else {
					obj[val.id] = val
				}
				return obj
			}, {})

		const newRuleConditions = Object.values(formControls.ruleConditions.value.byId)
			.filter((x) => x.id !== id)
			.reduce((obj, val) => {
				return {
					...obj,
					[val.id]: val
				}
			}, {})

		const newRulesetChildren = Object.values(formControls.rulesetChilds.value.byId)
			.filter((x) => !rulesetChildIds.includes(x.id))
			.reduce((obj, value) => {
				return {
					...obj,
					[value.id]: value
				}
			}, {})

		return ({
			...state,
			rulesetDetailForm: setValue(state.rulesetDetailForm, {
				...state.rulesetDetailForm.value,
				rulesets: {
					byId: newRuleset,
					allIds: Object.keys(newRuleset)
				}
				,
				ruleConditions: {
					byId: newRuleConditions,
					allIds: Object.keys(newRuleConditions)
				}
				,
				rulesetChilds: {
					byId: newRulesetChildren,
					allIds: Object.keys(newRulesetChildren)
				}

			})
		})
	}),
	on(CampaignAction.SaveRuleset, state => {
		const campaignRulesetId = state.campaignRulesetId
		const id = UUID.getUUID()
		const campaignRulesetList: CampaignState.CampaignRuleset[] = [...state.rulesetListForm.controls.campaignRulesetList.value]
		const rulesetDetailForm = setValue(state.rulesetDetailForm, {
			...state.rulesetDetailForm.value
		}).value

		if (campaignRulesetId) {
			const index = campaignRulesetList.findIndex(x => x.id === campaignRulesetId)
			campaignRulesetList[index] = {
				id: campaignRulesetId,
				rulesetDetailForm,
				rulesetRewardForm: state.rulesetRewardForm.value
			}
		} else {
			campaignRulesetList.push({
				id,
				rulesetDetailForm,
				rulesetRewardForm: state.rulesetRewardForm.value
			})
		}

		return ({
			...state,
			rulesetListForm: setValue(state.rulesetListForm, {
				...state.rulesetListForm.value,
				campaignRulesetList,

			}),
			rulesetDetailForm: reset(setValue(state.rulesetDetailForm, CampaignState.initialRulesetDetailFormValue)),
			rulesetRewardForm: reset(setValue(state.rulesetRewardForm, CampaignState.initialRulesetRewardFormValue))
		})
	}),
	on(CampaignAction.SetupRuleset, (state, { id, action }) => ({
		...state,
		campaignRulesetId: id,
		campaignRulesetAction: action
	})),
	on(CampaignAction.RulesetGetRuleList, state => ({
		...state,
		isLoading: true
	})),
	on(CampaignAction.RulesetGetRuleListSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		ruleList: payload.map(({ key, value, resourceCode }) => (({
			keyValue: { key, value },
			resourceCode
		})))
	})),
	on(CampaignAction.RulesetGetRulesetData, state => ({
		...state,
		isLoading: true
	})),
	on(CampaignAction.RulesetGetRulesetDataSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		rulesetData: payload
	})),
	on(CampaignAction.ViewRuleset, (state, { id }) => {
		const campaignRuleset = state.rulesetListForm.controls.campaignRulesetList.value.find(x => x.id === id)

		return ({
			...state,
			rulesetDetailForm: reset(setValue(state.rulesetDetailForm, campaignRuleset.rulesetDetailForm)),
			rulesetRewardForm: reset(setValue(state.rulesetRewardForm, campaignRuleset.rulesetRewardForm))
		})
	}),
	on(CampaignAction.UpdateRuleset, (state, { id }) => {
		const campaignRuleset = state.rulesetListForm.controls.campaignRulesetList.value.find(x => x.id === id)

		return ({
			...state,
			rulesetDetailForm: CampaignState.validateRulesetDetailForm(state)(
				markAsTouched(
					setValue(state.rulesetDetailForm, campaignRuleset.rulesetDetailForm)
				)
			),
			rulesetRewardForm: CampaignState.validateRulesetRewardForm(state)(
				markAsTouched(
					setValue(state.rulesetRewardForm, campaignRuleset.rulesetRewardForm)
				)
			)
		})
	}),
	on(CampaignAction.RemoveRuleset, (state, { id }) => ({
		...state,
		rulesetListForm: setValue(state.rulesetListForm, {
			...state.rulesetListForm.value,
			campaignRulesetList: state.rulesetListForm.controls.campaignRulesetList.value.filter(x => x.id !== id)
		})
	})),
	on(CampaignAction.DuplicateDialog, state => ({
		...state,
		campaignDuplicateForm: reset(setValue(state.campaignDuplicateForm, CampaignState.initialCampaignDuplicateFormValue)),
		isDuplicateDialog: false
	})),
	on(CampaignAction.DuplicateRulesetDialog, state => ({
		...state,
		campaignDuplicateForm: reset(setValue(state.campaignDuplicateForm, CampaignState.initialCampaignDuplicateFormValue)),
		isDuplicateDialog: false
	})),
	on(CampaignAction.Duplicate, state => ({
		...state,
		isDuplicateLoading: true
	})),
	on(CampaignAction.DuplicateSuccess, state => ({
		...state,
		isDuplicateLoading: false,
		isDuplicateDialog: true
	})),
	on(CampaignAction.ViewRepublishCampaign, state => ({
		...state,
		isLoading: true,
		campaignWorklistDetail: {
			taskName: '',
			code: '',
			name: '',
			modifiedBy: '',
			modifiedDate: '',
			campaignInformation: [],
			capping: [],
			ruleset: []
		}
	})),
	on(CampaignAction.ViewRepublishCampaignSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		campaignWorklistDetail: payload
	})),
	on(CampaignAction.DuplicateRuleset, state => {
		const campaignRulesetId = state.campaignRulesetId
		const id = UUID.getUUID()
		const campaignRulesetList: CampaignState.CampaignRuleset[] = [...state.rulesetListForm.controls.campaignRulesetList.value]
		const campaignDuplicateForm = state.campaignDuplicateForm.value
		let isDuplicate = false

		if (campaignRulesetId) {
			const index = campaignRulesetList.findIndex(x => x.id === campaignRulesetId)
			const targetRulesetDetailForm = campaignRulesetList[index].rulesetDetailForm
			const rulesetRewardForm = campaignRulesetList[index].rulesetRewardForm
			const rulesetCode = campaignRulesetList.find(e => e.rulesetDetailForm.code === campaignDuplicateForm.code)
			const rulesetName = campaignRulesetList.find(e => e.rulesetDetailForm.name === campaignDuplicateForm.name)

			const rulesetDetailForm = setValue(state.rulesetDetailForm, {
				...state.rulesetDetailForm.value,
				channel: targetRulesetDetailForm.channel,
				code: campaignDuplicateForm.code,
				name: campaignDuplicateForm.name,
				rulesets: targetRulesetDetailForm.rulesets,
				ruleConditions: targetRulesetDetailForm.ruleConditions,
				rulesetChilds: targetRulesetDetailForm.rulesetChilds
			}).value

			if (!rulesetCode && !rulesetName) {
				campaignRulesetList.push({
					id,
					rulesetDetailForm,
					rulesetRewardForm
				})
			} else {
				isDuplicate = true
			}
		}
		return ({
			...state,
			rulesetListForm: setValue(state.rulesetListForm, {
				...state.rulesetListForm.value,
				campaignRulesetList,

			}),
			rulesetDetailForm: reset(setValue(state.rulesetDetailForm, CampaignState.initialRulesetDetailFormValue)),
			rulesetRewardForm: reset(setValue(state.rulesetRewardForm, CampaignState.initialRulesetRewardFormValue)),
			isDuplicate
		})
	}),
	on(CampaignAction.DuplicateRulesetSuccess, state => ({
		...state,
		isDuplicateLoading: false,
		isDuplicateDialog: true
	})),
	on(CampaignAction.AddCampaignTier, state => {
		const id = UUID.getUUID()
		const formControls = state.rulesetRewardForm.controls
		const campaignTiersVal = formControls.campaignTiers.value

		return ({
			...state,
			rulesetRewardForm: setValue(state.rulesetRewardForm, {
				...state.rulesetRewardForm.value,
				campaignTiers: {
					byId: {
						...campaignTiersVal.byId,
						[id]: {
							id,
							form: validateCampaignTierForm(id, state)(getCampaignTierForm(id, undefined, state))
						}
					},
					allIds: [...campaignTiersVal && campaignTiersVal.allIds, id]
				}
			})
		})
	}),
	on(CampaignAction.DeleteCampaignTier, state => {
		const campaignTierId = state.campaignTierId
		const formControls = state.rulesetRewardForm.controls
		const campaignTiersVal = formControls.campaignTiers.value

		const allIds = campaignTiersVal.allIds.filter(x => x !== campaignTierId)
		const byId = allIds.reduce((obj, key) => {
			if (campaignTiersVal.byId.hasOwnProperty(key)) {
				obj[key] = campaignTiersVal.byId[key]
			}
			return obj
		}, {})

		return ({
			...state,
			rulesetRewardForm: setValue(state.rulesetRewardForm, {
				...state.rulesetRewardForm.value,
				campaignTiers: {
					byId,
					allIds
				}
			})
		})
	})
)

export function Reducer(state: CampaignState.State = CampaignState.initialState, action: Action) {
	const informationDetailForm = CampaignState.validateInformationDetailForm(state)(formGroupReducer(state.informationDetailForm, action))
	if (informationDetailForm !== state.informationDetailForm) {
		state = { ...state, informationDetailForm }
	}

	const cappingForm = CampaignState.validateCappingForm(state)(formGroupReducer(state.cappingForm, action))
	if (cappingForm !== state.cappingForm) {
		state = { ...state, cappingForm }
	}

	const rulesetListForm = CampaignState.validateRulesetListForm(formGroupReducer(state.rulesetListForm, action))
	if (rulesetListForm !== state.rulesetListForm) {
		state = { ...state, rulesetListForm }
	}

	const rulesetDetailForm = CampaignState.validateRulesetDetailForm(state)(formGroupReducer(state.rulesetDetailForm, action))
	if (rulesetDetailForm !== state.rulesetDetailForm) {
		state = { ...state, rulesetDetailForm }
	}

	const rulesetRewardForm = CampaignState.validateRulesetRewardForm(state)(formGroupReducer(state.rulesetRewardForm, action))
	if (rulesetRewardForm !== state.rulesetRewardForm) {
		state = { ...state, rulesetRewardForm }
	}

	const campaignDuplicateForm = CampaignState.validateCampaignDuplicateForm(formGroupReducer(state.campaignDuplicateForm, action))
	if (campaignDuplicateForm !== state.campaignDuplicateForm) {
		state = { ...state, campaignDuplicateForm }
	}

	return reducer(state, action)
}

function getForm(id: string, key: string, form?: Ruleset): any {
	switch (key) {
		case 'CHOOSE_DATE':
			if (form) {
				const formGroup = createFormGroupState<CampaignState.ChooseDateForm>(id, {
					value: form.values[0],
					time: form.values[1]
				})
				return validateForm(id, key, formGroup)
			}

			return createFormGroupState<CampaignState.ChooseDateForm>(id,
				CampaignState.initialChooseDateFormValue)
		case 'TRANSACTION_AMOUNT':
			if (form) {
				const formGroup = createFormGroupState<CampaignState.TransactionAmountForm>(id, {
					equation: form.equation,
					value: form.value
				})
				return validateForm(id, key, formGroup)
			}

			return createFormGroupState<CampaignState.TransactionAmountForm>(id,
				CampaignState.initialTransactionAmountFormValue)
		case 'PRODUCT_TYPE':
			if (form) {
				const value = []
				const values = []

				if (form.values && form.values.length === 2) {
					form.values[0].split(',').forEach(x => {
						value.push(x)
					})
					form.values[1].split(',').forEach(x => {
						values.push(x)
					})
				}

				const formGroup = createFormGroupState<CampaignState.ProductTypeForm>(id, {
					equation: form.equation,
					value: box(value),
					values: box(values)
				})
				return validateForm(id, key, formGroup)
			}

			return createFormGroupState<CampaignState.ProductTypeForm>(id,
				CampaignState.initialProductTypeFormValue)
		case 'MCC':
			if (form) {
				let value = ''
				const values = []

				if (form.values && form.values.length === 2) {
					value = form.values[0]
					form.values[1].split(',').forEach(x => {
						values.push(x)
					})
				}

				const formGroup = createFormGroupState<CampaignState.MccForm>(id, {
					equation: form.equation,
					value,
					values: box(values)
				})
				return validateForm(id, key, formGroup)
			}

			return createFormGroupState<CampaignState.MccForm>(id,
				CampaignState.initialMccFormValue)
		case 'MERCHANT':
			if (form) {
				let value = ''
				const values = []

				if (form.values && form.values.length === 2) {
					value = form.values[0]
					form.values[1].split(',').forEach(x => {
						values.push(x)
					})
				}

				const formGroup = createFormGroupState<CampaignState.MerchantForm>(id, {
					equation: form.equation,
					value,
					values: box(values)
				})
				return validateForm(id, key, formGroup)
			}

			return createFormGroupState<CampaignState.MerchantForm>(id,
				CampaignState.initialMerchantFormValue)
		case 'SELECTED_DAY':
			if (form) {
				const values = form.values
				const value = values && values[0]

				let selectedDayForm = CampaignState.initialSelectedDayFormValue

				if (value === 'D' && values.length === 2) {
					const data = values[1].split(',')
					if (data && data.length === 2) {
						selectedDayForm = {
							...selectedDayForm,
							value,
							daily: {
								startTime: data[0],
								endTime: data[1]
							}
						}
					}
				}

				if (value === 'W' && values.length === 8) {
					const monday = values[1].split(',')
					const tuesday = values[2].split(',')
					const wednesday = values[3].split(',')
					const thursday = values[4].split(',')
					const friday = values[5].split(',')
					const saturday = values[6].split(',')
					const sunday = values[7].split(',')

					selectedDayForm = {
						...selectedDayForm,
						value,
						weekly: {
							monday: {
								isCheck: (monday[0] === 'true'),
								startTime: monday[1],
								endTime: monday[2]
							},
							tuesday: {
								isCheck: (tuesday[0] === 'true'),
								startTime: tuesday[1],
								endTime: tuesday[2]
							},
							wednesday: {
								isCheck: (wednesday[0] === 'true'),
								startTime: wednesday[1],
								endTime: wednesday[2]
							},
							thursday: {
								isCheck: (thursday[0] === 'true'),
								startTime: thursday[1],
								endTime: thursday[2]
							},
							friday: {
								isCheck: (friday[0] === 'true'),
								startTime: friday[1],
								endTime: friday[2]
							},
							saturday: {
								isCheck: (saturday[0] === 'true'),
								startTime: saturday[1],
								endTime: saturday[2]
							},
							sunday: {
								isCheck: (sunday[0] === 'true'),
								startTime: sunday[1],
								endTime: sunday[2]
							}
						}
					}
				}

				if (value === 'M' && values.length === 2) {
					const data = values[1].split(',')
					if (data && data.length === 3) {
						selectedDayForm = {
							...selectedDayForm,
							value,
							monthly: {
								day: data[0],
								startTime: data[1],
								endTime: data[2]
							}
						}
					}
				}

				const formGroup = createFormGroupState<CampaignState.SelectedDayForm>(id, selectedDayForm)
				return validateForm(id, key, formGroup)
			}

			return createFormGroupState<CampaignState.SelectedDayForm>(id,
				CampaignState.initialSelectedDayFormValue)
		case 'TRANSACTION_CODE':
			if (form) {
				let value = ''
				let isCheck = false
				const values = []
				const reversalValues = []

				if (form.values && form.values.length === 4) {
					value = form.values[0]
					form.values[1].split(',').forEach(x => {
						values.push(x)
					})
					isCheck = (form.values[2] === 'true')
					form.values[3].split(',').forEach(x => {
						reversalValues.push(x)
					})
				}

				const formGroup = createFormGroupState<CampaignState.TransactionCodeForm>(id, {
					equation: form.equation,
					value,
					isCheck,
					values: box(values),
					reversalValues: box(reversalValues)
				})
				return validateForm(id, key, formGroup)
			}

			return createFormGroupState<CampaignState.TransactionCodeForm>(id,
				CampaignState.initialTransactionCodeFormValue)
		case 'APPROVAL_CODE':
			if (form) {
				const formGroup = createFormGroupState<CampaignState.ApprovalCodeForm>(id, {
					equation: form.equation,
					value: form.value
				})
				return validateForm(id, key, formGroup)
			}

			return createFormGroupState<CampaignState.ApprovalCodeForm>(id,
				CampaignState.initialApprovalCodeFormValue)
		case 'TRANSACTION_DESCRIPTION':
			if (form) {
				const formGroup = createFormGroupState<CampaignState.TransactionDescriptionForm>(id, {
					equation: form.equation,
					value: form.value
				})
				return validateForm(id, key, formGroup)
			}

			return createFormGroupState<CampaignState.TransactionDescriptionForm>(id,
				CampaignState.initialTransactionDescriptionFormValue)
		case 'POS_ENTRY_MODE':
			if (form) {
				const formGroup = createFormGroupState<CampaignState.PosEntryModeForm>(id, {
					equation: form.equation,
					value: form.value
				})
				return validateForm(id, key, formGroup)
			}

			return createFormGroupState<CampaignState.PosEntryModeForm>(id,
				CampaignState.initialPosEntryModeFormValue)
		case 'TRANSACTION_MODE':
			if (form) {
				const formGroup = createFormGroupState<CampaignState.TransactionModeForm>(id, {
					equation: form.equation,
					value: form.value
				})
				return validateForm(id, key, formGroup)
			}

			return createFormGroupState<CampaignState.TransactionModeForm>(id,
				CampaignState.initialTransactionModeFormValue)
		case 'POSTING_CURRENCY_CODE':
			if (form) {
				const formGroup = createFormGroupState<CampaignState.PostingCurrencyCodeForm>(id, {
					equation: form.equation,
					value: form.value
				})
				return validateForm(id, key, formGroup)
			}

			return createFormGroupState<CampaignState.PostingCurrencyCodeForm>(id,
				CampaignState.initialPostingCurrencyCodeFormValue)
		case 'TRANSACTION_CURRENCY_CODE':
			if (form) {
				const formGroup = createFormGroupState<CampaignState.TransactionCurrencyCodeForm>(id, {
					equation: form.equation,
					value: form.value
				})
				return validateForm(id, key, formGroup)
			}

			return createFormGroupState<CampaignState.TransactionCurrencyCodeForm>(id,
				CampaignState.initialTransactionCurrencyCodeFormValue)
		case 'COUNTRY_CODE':
			if (form) {
				const value = []

				if (form.value) {
					form.value.split(',').forEach(x => {
						value.push(x)
					})
				}

				const formGroup = createFormGroupState<CampaignState.CountryCodeForm>(id, {
					equation: form.equation,
					value: box(value)
				})
				return validateForm(id, key, formGroup)
			}

			return createFormGroupState<CampaignState.CountryCodeForm>(id,
				CampaignState.initialCountryCodeFormValue)
		case 'CUSTOMER_BLOCK_CODE':
			if (form) {
				const formGroup = createFormGroupState<CampaignState.CustomerBlockCodeForm>(id, {
					equation: form.equation,
					value: form.value
				})
				return validateForm(id, key, formGroup)
			}

			return createFormGroupState<CampaignState.CustomerBlockCodeForm>(id,
				CampaignState.initialCustomerBlockCodeFormValue)
		case 'ACCOUNT_BLOCK_CODE':
			if (form) {
				const formGroup = createFormGroupState<CampaignState.AccountBlockCodeForm>(id, {
					equation: form.equation,
					value: form.value
				})
				return validateForm(id, key, formGroup)
			}

			return createFormGroupState<CampaignState.AccountBlockCodeForm>(id,
				CampaignState.initialAccountBlockCodeFormValue)
		case 'CARD_BLOCK_CODE':
			if (form) {
				const formGroup = createFormGroupState<CampaignState.CardBlockCodeForm>(id, {
					equation: form.equation,
					value: form.value
				})
				return validateForm(id, key, formGroup)
			}

			return createFormGroupState<CampaignState.CardBlockCodeForm>(id,
				CampaignState.initialCardBlockCodeFormValue)
		case 'BIRTHDAY':
			if (form) {
				const formGroup = createFormGroupState<CampaignState.BirthdayForm>(id, {
					value: form.values[0],
					xDaysBefore: form.values[1],
					xDaysAfter: form.values[2]
				})
				return validateForm(id, key, formGroup)
			}

			return createFormGroupState<CampaignState.BirthdayForm>(id,
				CampaignState.initialBirthdayFormValue)
		case 'CUSTOMER_STATUS':
			if (form) {
				const value = []

				if (form.value) {
					form.value.split(',').forEach(x => {
						value.push(x)
					})
				}

				const formGroup = createFormGroupState<CampaignState.CustomerStatusForm>(id, {
					equation: form.equation,
					value: box(value)
				})
				return validateForm(id, key, formGroup)
			}

			return createFormGroupState<CampaignState.CustomerStatusForm>(id,
				CampaignState.initialCustomerStatusFormValue)
		case 'MEMBER_SINCE':
			if (form) {
				const formGroup = createFormGroupState<CampaignState.MemberSinceForm>(id, {
					equation: form.equation,
					value: Util.dateStrToISOString(form.value)
				})
				return validateForm(id, key, formGroup)
			}

			return createFormGroupState<CampaignState.MemberSinceForm>(id,
				CampaignState.initialMemberSinceFormValue)
		case 'ACCOUNT_STATUS':
			if (form) {
				const value = []

				if (form.value) {
					form.value.split(',').forEach(x => {
						value.push(x)
					})
				}

				const formGroup = createFormGroupState<CampaignState.AccountStatusForm>(id, {
					equation: form.equation,
					value: box(value)
				})
				return validateForm(id, key, formGroup)
			}

			return createFormGroupState<CampaignState.AccountStatusForm>(id,
				CampaignState.initialAccountStatusFormValue)
		case 'CARD_STATUS':
			if (form) {
				const value = []

				if (form.value) {
					form.value.split(',').forEach(x => {
						value.push(x)
					})
				}

				const formGroup = createFormGroupState<CampaignState.CardStatusForm>(id, {
					equation: form.equation,
					value: box(value)
				})
				return validateForm(id, key, formGroup)
			}

			return createFormGroupState<CampaignState.CardStatusForm>(id,
				CampaignState.initialCardStatusFormValue)
		case 'COMPARISON_DATE':
			if (form) {
				let comparisonDate = ''
				let value = ''

				if (form.values) {
					comparisonDate = form.values[0]
					value = form.values[1]
				}

				const formGroup = createFormGroupState<CampaignState.ComparisonDateForm>(id, {
					comparisonDate,
					equation: form.equation,
					value
				})
				return validateForm(id, key, formGroup)
			}

			return createFormGroupState<CampaignState.ComparisonDateForm>(id,
				CampaignState.initialComparisonDateFormValue)
		case 'VIP_IND':
			if (form) {
				const formGroup = createFormGroupState<CampaignState.VIPIndForm>(id, {
					value: form.value
				})
				return validateForm(id, key, formGroup)
			}

			return createFormGroupState<CampaignState.VIPIndForm>(id,
				CampaignState.initialVIPIndFormValue)
		case 'STAFF_IND':
			if (form) {
				const formGroup = createFormGroupState<CampaignState.StaffIndForm>(id, {
					value: form.value
				})
				return validateForm(id, key, formGroup)
			}

			return createFormGroupState<CampaignState.StaffIndForm>(id,
				CampaignState.initialStaffIndFormValue)
		case 'RECURRING_IND':
			if (form) {
				const formGroup = createFormGroupState<CampaignState.RecurringIndForm>(id, {
					value: form.value
				})
				return validateForm(id, key, formGroup)
			}

			return createFormGroupState<CampaignState.RecurringIndForm>(id,
				CampaignState.initialRecurringIndFormValue)
		case 'E-COMMERCE_IND':
			if (form) {
				const formGroup = createFormGroupState<CampaignState.ECommerceIndForm>(id, {
					value: form.value
				})
				return validateForm(id, key, formGroup)
			}

			return createFormGroupState<CampaignState.ECommerceIndForm>(id,
				CampaignState.initialECommerceIndFormValue)
		case 'TARGET_CUSTOMER':
			if (form) {
				const values = []

				if (form.values && form.values.length === 2) {
					form.values[1].split(',').forEach(x => {
						values.push(x)
					})
				}

				const formGroup = createFormGroupState<CampaignState.TargetCustomerForm>(id, {
					equation: form.equation,
					values: box(values)
				})
				return validateForm(id, key, formGroup)
			}

			return createFormGroupState<CampaignState.TargetCustomerForm>(id,
				CampaignState.initialTargetCustomerFormValue)
		case 'TARGET_ACCOUNT':
			if (form) {
				const values = []

				if (form.values && form.values.length === 2) {
					form.values[1].split(',').forEach(x => {
						values.push(x)
					})
				}

				const formGroup = createFormGroupState<CampaignState.TargetAccountForm>(id, {
					equation: form.equation,
					values: box(values)
				})
				return validateForm(id, key, formGroup)
			}

			return createFormGroupState<CampaignState.TargetAccountForm>(id,
				CampaignState.initialTargetAccountFormValue)
		case 'TARGET_CARD':
			if (form) {
				const values = []

				if (form.values && form.values.length === 2) {
					form.values[1].split(',').forEach(x => {
						values.push(x)
					})
				}

				const formGroup = createFormGroupState<CampaignState.TargetCardForm>(id, {
					equation: form.equation,
					values: box(values)
				})
				return validateForm(id, key, formGroup)
			}

			return createFormGroupState<CampaignState.TargetCardForm>(id,
				CampaignState.initialTargetCardFormValue)
		case 'SEGMENT':
			if (form) {
				const formGroup = createFormGroupState<CampaignState.SegmentForm>(id, {
					value: form.value
				})
				return validateForm(id, key, formGroup)
			}

			return createFormGroupState<CampaignState.SegmentForm>(id,
				CampaignState.initialSegmentFormValue)
		case 'STAFF_IND_ACC':
			if (form) {
				const formGroup = createFormGroupState<CampaignState.StaffIndAccForm>(id, {
					value: form.value
				})
				return validateForm(id, key, formGroup)
			}

			return createFormGroupState<CampaignState.StaffIndAccForm>(id,
				CampaignState.initialStaffIndAccFormValue)
		case 'INDICATOR_1':
		case 'INDICATOR_2':
		case 'INDICATOR_3':
		case 'INDICATOR_4':
		case 'INDICATOR_5':
		case 'INDICATOR_6':
		case 'INDICATOR_7':
			if (form) {
				const formGroup = createFormGroupState<CampaignState.IndicatorForm>(id, {
					value: form.value
				})
				return validateForm(id, key, formGroup)
			}

			return createFormGroupState<CampaignState.IndicatorForm>(id,
				CampaignState.initialIndicatorFormValue)
		case 'DESCRIPTION_1':
		case 'DESCRIPTION_2':
		case 'DESCRIPTION_3':
		case 'DESCRIPTION_4':
		case 'DESCRIPTION_5':
		case 'DESCRIPTION_6':
		case 'DESCRIPTION_7':
			if (form) {
				const formGroup = createFormGroupState<CampaignState.DescriptionForm>(id, {
					equation: form.equation,
					value: form.value
				})
				return validateForm(id, key, formGroup)
			}

			return createFormGroupState<CampaignState.DescriptionForm>(id,
				CampaignState.initialDescriptionFormValue)
		case 'BALANCE_1':
		case 'BALANCE_2':
		case 'BALANCE_3':
		case 'BALANCE_4':
		case 'BALANCE_5':
		case 'BALANCE_6':
		case 'BALANCE_7':
			if (form) {
				const formGroup = createFormGroupState<CampaignState.BalanceForm>(id, {
					equation: form.equation,
					value: form.value
				})
				return validateForm(id, key, formGroup)
			}

			return createFormGroupState<CampaignState.BalanceForm>(id,
				CampaignState.initialBalanceFormValue)
		case 'AMOUNT_1':
		case 'AMOUNT_2':
		case 'AMOUNT_3':
		case 'AMOUNT_4':
		case 'AMOUNT_5':
		case 'AMOUNT_6':
		case 'AMOUNT_7':
			if (form) {
				const formGroup = createFormGroupState<CampaignState.AmountForm>(id, {
					equation: form.equation,
					value: form.value
				})
				return validateForm(id, key, formGroup)
			}

			return createFormGroupState<CampaignState.AmountForm>(id,
				CampaignState.initialAmountFormValue)
		case 'DATE_1':
		case 'DATE_2':
		case 'DATE_3':
		case 'DATE_4':
		case 'DATE_5':
		case 'DATE_6':
		case 'DATE_7':
			if (form) {
				let comparisonDate = ''
				let value = ''

				if (form.values) {
					comparisonDate = form.values[0]
					value = form.values[1]
				}

				const formGroup = createFormGroupState<CampaignState.DateForm>(id, {
					comparisonDate,
					equation: form.equation,
					value
				})
				return validateForm(id, key, formGroup)
			}

			return createFormGroupState<CampaignState.DateForm>(id,
				CampaignState.initialDateFormValue)
		default:
			return undefined
	}
}

function validateForm(id: string, key: string, form: any, state?: CampaignState.State): NgrxForms.FormGroupState<any> {

	switch (key) {
		case 'CHOOSE_DATE':
			return CampaignState.validateChooseDateForm(form)
		case 'TRANSACTION_AMOUNT':
			return CampaignState.validateTransactionAmountForm(form)
		case 'PRODUCT_TYPE':
			return CampaignState.validateProductTypeForm(id, state)(form)
		case 'MCC':
			return CampaignState.validateMccForm(id, state)(form)
		case 'MERCHANT':
			return CampaignState.validateMerchantForm(id, state)(form)
		case 'SELECTED_DAY':
			return CampaignState.validateSelectedDayForm(id, state)(form)
		case 'TRANSACTION_CODE':
			return CampaignState.validateTransactionCodeForm(id, state)(form)
		case 'APPROVAL_CODE':
			return CampaignState.validateApprovalCodeForm(form)
		case 'TRANSACTION_DESCRIPTION':
			return CampaignState.validateTransactionDescriptionForm(form)
		case 'POS_ENTRY_MODE':
			return CampaignState.validatePosEntryModeForm(form)
		case 'TRANSACTION_MODE':
			return CampaignState.validateTransactionModeForm(form)
		case 'POSTING_CURRENCY_CODE':
			return CampaignState.validatePostingCurrencyCodeForm(form)
		case 'TRANSACTION_CURRENCY_CODE':
			return CampaignState.validateTransactionCurrencyCodeForm(form)
		case 'COUNTRY_CODE':
			return CampaignState.validateCountryCodeForm(form)
		case 'CUSTOMER_BLOCK_CODE':
			return CampaignState.validateCustomerBlockCodeForm(form)
		case 'ACCOUNT_BLOCK_CODE':
			return CampaignState.validateAccountBlockCodeForm(form)
		case 'CARD_BLOCK_CODE':
			return CampaignState.validateCardBlockCodeForm(form)
		case 'BIRTHDAY':
			return CampaignState.validateBirthdayForm(id, state)(form)
		case 'CUSTOMER_STATUS':
			return CampaignState.validateCustomerStatusForm(form)
		case 'ACCOUNT_STATUS':
			return CampaignState.validateAccountStatusForm(form)
		case 'CARD_STATUS':
			return CampaignState.validateCardStatusForm(form)
		case 'MEMBER_SINCE':
			return CampaignState.validateMemberSinceForm(form)
		case 'COMPARISON_DATE':
			return CampaignState.validateComparisonDateForm(form)
		case 'VIP_IND':
			return CampaignState.validateVIPIndForm(form)
		case 'STAFF_IND':
			return CampaignState.validateStaffIndForm(form)
		case 'RECURRING_IND':
			return CampaignState.validateRecurringIndForm(form)
		case 'E-COMMERCE_IND':
			return CampaignState.validateECommerceIndForm(form)
		case 'TARGET_CUSTOMER':
			return CampaignState.validateTargetCustomerForm(form)
		case 'TARGET_ACCOUNT':
			return CampaignState.validateTargetAccountForm(form)
		case 'TARGET_CARD':
			return CampaignState.validateTargetCardForm(form)
		case 'SEGMENT':
			return CampaignState.validateSegmentForm(form)
		case 'STAFF_IND_ACC':
			return CampaignState.validateStaffIndAccForm(form)
		case 'INDICATOR_1':
		case 'INDICATOR_2':
		case 'INDICATOR_3':
		case 'INDICATOR_4':
		case 'INDICATOR_5':
		case 'INDICATOR_6':
		case 'INDICATOR_7':
			return CampaignState.validateIndicatorForm(form)
		case 'DESCRIPTION_1':
		case 'DESCRIPTION_2':
		case 'DESCRIPTION_3':
		case 'DESCRIPTION_4':
		case 'DESCRIPTION_5':
		case 'DESCRIPTION_6':
		case 'DESCRIPTION_7':
			return CampaignState.validateDescriptionForm(form)
		case 'BALANCE_1':
		case 'BALANCE_2':
		case 'BALANCE_3':
		case 'BALANCE_4':
		case 'BALANCE_5':
		case 'BALANCE_6':
		case 'BALANCE_7':
			return CampaignState.validateBalanceForm(form)
		case 'AMOUNT_1':
		case 'AMOUNT_2':
		case 'AMOUNT_3':
		case 'AMOUNT_4':
		case 'AMOUNT_5':
		case 'AMOUNT_6':
		case 'AMOUNT_7':
			return CampaignState.validateAmountForm(form)
		case 'DATE_1':
		case 'DATE_2':
		case 'DATE_3':
		case 'DATE_4':
		case 'DATE_5':
		case 'DATE_6':
		case 'DATE_7':
			return CampaignState.validateDateForm(form)
		default:
			return undefined
	}
}

function getCampaignTierForm(id: string, form?: CampaignTierForm, state?: CampaignState.State) {
	if (form) {
		const formGroup = createFormGroupState<CampaignTierForm>(id, {
			conditionFactorFrom: form.conditionFactorFrom,
			conditionFactorTo: form.conditionFactorTo,
			valueFactor: form.valueFactor,
			pointFactor: form.pointFactor
		})
		return validateCampaignTierForm(id, state)(formGroup)
	}

	return createFormGroupState<CampaignTierForm>(id,
		CampaignState.initialCampaignTierFormValue)
}

function validateAllTiers(state: CampaignState.State, action: SetValueAction<unknown> | NgrxForms.MarkAsTouchedAction) {
	const tiersVal = state.rulesetRewardForm.controls.campaignTiers.value

	let campaignTiers = {
		byId: {},
		allIds: []
	}

	tiersVal.allIds.forEach(tierId => {
		if (tiersVal.byId.hasOwnProperty(tierId)) {
			const byId = tiersVal.byId[tierId]
			const tierForm = formGroupReducer(byId.form, action)

			campaignTiers = {
				byId: {
					...campaignTiers.byId,
					[tierId]: {
						...byId,
						form: validateTiersForm(tierId, tierForm, state)
					}
				},
				allIds: [...tiersVal && tiersVal.allIds]
			}
		}
	})
	return {
		...state,
		rulesetRewardForm: setValue(state.rulesetRewardForm, {
			...state.rulesetRewardForm.value,
			campaignTiers
		})
	}
}

function validateTiersForm(id: string, form: any, state?: CampaignState.State) {
	return markAsTouched(CampaignState.validateCampaignTierForm(id, state)(form))
}

function actionHandler(state: CampaignState.State, action: SetValueAction<unknown> | NgrxForms.MarkAsTouchedAction) {
	const controlId = action.controlId.split('.')[0]

	const formControls = state.rulesetDetailForm.controls
	let rulesetsVal = formControls.rulesets.value
	let rulesetChildsVal = formControls.rulesetChilds.value

	const newRulesets: Rulesets = {
		byId: {
			...rulesetsVal.byId,
			...rulesetChildsVal.byId
		},
		allIds: {
			...rulesetsVal.allIds,
			...rulesetChildsVal.allIds
		}
	}
	const ruleset = newRulesets.byId[controlId]

	if (ruleset) {
		const ruleKey = ruleset.rule.keyValue.key
		let form = formGroupReducer(ruleset.form, action)

		if (action.type === 'ngrx/forms/SET_VALUE') {
			form = formGroupReducer(ruleset.form, new SetValueAction(action.controlId, action.value))
		}

		const isChild = ruleset.isChild
		if (isChild) {
			rulesetChildsVal = {
				byId: {
					...rulesetChildsVal.byId,
					[controlId]: {
						...rulesetChildsVal.byId[controlId],
						form
					}
				},
				allIds: [...rulesetChildsVal.allIds]
			}
		} else {
			rulesetsVal = {
				byId: {
					...rulesetsVal.byId,
					[controlId]: {
						...rulesetsVal.byId[controlId],
						form
					}
				},
				allIds: [...rulesetsVal.allIds]
			}
		}

		state = {
			...state, rulesetDetailForm: setValue(state.rulesetDetailForm, {
				...state.rulesetDetailForm.value,
				rulesets: rulesetsVal,
				rulesetChilds: rulesetChildsVal
			})
		}

		form = validateForm(controlId, ruleKey, form, state)

		if (isChild) {
			rulesetChildsVal = {
				byId: {
					...rulesetChildsVal.byId,
					[controlId]: {
						...rulesetChildsVal.byId[controlId],
						form
					}
				},
				allIds: [...rulesetChildsVal.allIds]
			}
		} else {
			rulesetsVal = {
				byId: {
					...rulesetsVal.byId,
					[controlId]: {
						...rulesetsVal.byId[controlId],
						form
					}
				},
				allIds: [...rulesetsVal.allIds]
			}
		}

		state = {
			...state, rulesetDetailForm: setValue(state.rulesetDetailForm, {
				...state.rulesetDetailForm.value,
				rulesets: rulesetsVal,
				rulesetChilds: rulesetChildsVal
			})
		}
	}

	const rewardformControls = state.rulesetRewardForm.controls
	let campaignTiersVal = rewardformControls.campaignTiers.value

	const newCampaignTiers: CampaignTiers = {
		byId: {
			...campaignTiersVal.byId
		},
		allIds: {
			...campaignTiersVal.allIds
		}
	}

	const campaignTier = newCampaignTiers.byId[controlId]

	if (campaignTier) {
		let form = formGroupReducer(campaignTier.form, action)

		if (action.type === 'ngrx/forms/SET_VALUE') {
			form = formGroupReducer(campaignTier.form, new SetValueAction(action.controlId, action.value))
		}

		campaignTiersVal = {
			byId: {
				...campaignTiersVal.byId,
				[controlId]: {
					...campaignTiersVal.byId[controlId],
					form
				}
			},
			allIds: [...campaignTiersVal.allIds]
		}

		state = {
			...state,
			rulesetRewardForm: setValue(state.rulesetRewardForm, {
				...state.rulesetRewardForm.value,
				campaignTiers: campaignTiersVal
			})
		}

		form = validateCampaignTierForm(controlId, state)(form)

		campaignTiersVal = {
			byId: {
				...campaignTiersVal.byId,
				[controlId]: {
					...campaignTiersVal.byId[controlId],
					form
				}
			},
			allIds: [...campaignTiersVal.allIds]
		}

		state = {
			...state,
			rulesetRewardForm: setValue(state.rulesetRewardForm, {
				...state.rulesetRewardForm.value,
				campaignTiers: campaignTiersVal
			})
		}
		state = validateAllTiers(state, action)
	}

	return state
}
