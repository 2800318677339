import { Component, OnInit, OnDestroy, Inject } from '@angular/core'
import { Subscription, Observable } from 'rxjs'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material'
import { Store } from '@ngrx/store'
import * as AppStore from 'src/app/store'
import * as CustomerManagementSelector from 'src/app/store/customer-management/customer-management.selectors'
import * as CustomerManagementAction from 'src/app/store/customer-management/customer-management.actions'
import { FormGroupState } from 'ngrx-forms'
import { CatalogueRedemptionForm } from 'src/app/store/customer-management'
import { CustomerCardListData, CustomerAddressListData, CardProductCatalogueListData } from 'src/app/models/customer-management/catalogue-redemption'
import { CatalogueRedemptionType } from 'src/app/models/common/constant'
import { CustomerPartnerRedemptionListData } from 'src/app/models/customer-management/customer-partner-redemption'

@Component({
	selector: 'app-customer-management-catalogue-redemption-confirmation-dialog',
	templateUrl: './customer-management-catalogue-redemption-confirmation-dialog.component.html',
	styleUrls: ['./customer-management-catalogue-redemption-confirmation-dialog.component.scss']
})
export class CustomerManagementCatalogueRedemptionConfirmationDialogComponent implements OnInit, OnDestroy {

	isLoading = false
	formState$: Observable<FormGroupState<CatalogueRedemptionForm>>
	cardList: CustomerCardListData[]
	addressList: CustomerAddressListData[]
	productCatalogue: CardProductCatalogueListData
	rewardPoolName: string
	totalPoints: string
	totalAmount: string
	subs: Subscription
	isOthers: boolean
	isVoucher: boolean
	isCataloguePartner = false
	partnerCode: string
	customerpartnerRedemption: CustomerPartnerRedemptionListData[]
	redemptionType: string
	cardNo = ''
	eCatalogueRedemptionType = CatalogueRedemptionType

	constructor(
		private store: Store<AppStore.State>,
		private dialogRef: MatDialogRef<CustomerManagementCatalogueRedemptionConfirmationDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any) {

		this.subs = this.store.select(CustomerManagementSelector.getCustomerManagement).subscribe(x => {
			this.isLoading = x.isLoading
			this.cardList = x.customerCardList
			this.addressList = x.customerAddressList
			this.productCatalogue = x.productCatalogue
			if (this.productCatalogue.rewardPoolType === 'I') {
				this.rewardPoolName = x.customerRewardPoolViewResponse.rewardPoolName
			} else {
				this.rewardPoolName = x.customerViewResponse.rewardPoolGroupList.find(e => e.rewardPoolGroupId === x.rewardPoolGroupId).rewardPoolGroupName
			}
			if (this.productCatalogue.kind === 'CP') {
				this.isCataloguePartner = true
				this.partnerCode = this.productCatalogue.partnerCode
			}
			this.customerpartnerRedemption = x.partnerRedemptionList
			this.isVoucher = this.productCatalogue.productCategory === 'V'
			this.totalPoints = x.totalPoints
			this.totalAmount = x.totalAmount
			this.redemptionType = this.productCatalogue.redemptionType
			if (this.cardList.length > 0) {
				const card = this.cardList.find(i => i.id === x.catalogueRedemptionForm.controls.cardId.value)
				this.cardNo = card && card.cardNumber
			}
		})

		this.formState$ = this.store.select(({ customerManagement }) => customerManagement.catalogueRedemptionForm)
	}

	ngOnInit() {
	}

	ngOnDestroy() {
		this.partnerCode = null
		this.subs.unsubscribe()
	}

	cancel() {
		this.dialogRef.close()
	}

	create() {
		this.dialogRef.close()
		if (this.isCataloguePartner) {
			this.store.dispatch(CustomerManagementAction.CreateCataloguePartnerRedemption())
		} else {
			this.store.dispatch(CustomerManagementAction.CreateCatalogueRedemption())
		}
	}
}
