import { Field } from './../../../models/util/field.validator';
import { createFormGroupState, FormGroupState, updateGroup, validate } from 'ngrx-forms'
import { maxLength, required } from 'ngrx-forms/validation'
import { Draft, Published, Unpublished } from 'src/app/models/campaign-management/conditional-reward/conditional-reward'
import { ConditionalRewardCampaignResponse } from 'src/app/models/campaign-management/conditional-reward/conditional-reward-campaign'
import { ConditionalRewardDataResponse } from 'src/app/models/campaign-management/conditional-reward/conditional-reward-data'
import { ConditionalRewardViewResponse } from 'src/app/models/campaign-management/conditional-reward/conditional-reward-view'
import { FieldValidator } from 'src/app/models/util/field.validator'

export interface State {
	isLoading: boolean
	id?: number
	tierId?: string
	tierAction?: string
	checkerMakerId?: number

	// Listing
	publisheds: Published[]
	drafts: Draft[]
	unpublisheds: Unpublished[]
	// Detail
	conditionalRewardDetailForm: FormGroupState<ConditionalRewardDetailForm>
	conditionalRewardDetailFormView: ConditionalRewardDetailForm
	// option list
	conditionalRewardDataResponse: ConditionalRewardDataResponse
	conditionalRewardCampaignResponse: ConditionalRewardCampaignResponse[]
	allowDelete: boolean
	allowRepublish: boolean
}

export interface Tiers {
	byId: { [key: string]: Tier }
	allIds: string[]
}

export interface Tier {
	id: string
	campaigns: number[]
	form: FormGroupState<TierForm>
}

export const initialConditionalRewardViewResponse: ConditionalRewardViewResponse = {
	id: 0,
	resourceCode: '',
	code: '',
	name: '',
	startDate: '',
	endDate: '',
	accumulationCycleType: '',
	accumulationCycleMonth: '',
	accumulationCycleDay: '',
	rewardCreditMethod: '',
	status: '',
	tiers: [],
	createdBy: '',
	createdDate: '',
	modifiedBy: '',
	modifiedDate: '',
	allowDelete: true
}

export interface ConditionalRewardDetailForm {
	resourceCode: string
	code: string
	name: string
	startDate: string
	endDate: string
	accumulationCycleType: string
	accumulationCycleMonth: string
	accumulationCycleDay: string
	accumulationCycleTypeOption: string
	rewardCreditMethod: string
	tiers: Tiers
	createdBy: string
	createdDate: string
	modifiedBy: string
	modifiedDate: string

}

export const initialConditionalRewardDetailFormValue: ConditionalRewardDetailForm = {
	resourceCode: '',
	code: '',
	name: '',
	startDate: '',
	endDate: '',
	accumulationCycleType: '',
	accumulationCycleMonth: '',
	accumulationCycleDay: '',
	accumulationCycleTypeOption: '',
	rewardCreditMethod: '',
	tiers: {
		byId: {},
		allIds: []
	},
	createdBy: '',
	createdDate: '',
	modifiedBy: '',
	modifiedDate: ''
}

export const initialConditionalRewardDetailForm = createFormGroupState('conditionalRewardDetailForm', initialConditionalRewardDetailFormValue)

export const validateConditionalRewardDetailForm = (state: State) => {
	const accumulationCycleType = state.conditionalRewardDetailForm.controls.accumulationCycleType && state.conditionalRewardDetailForm.controls.accumulationCycleType.value
	const resourceCode = state.conditionalRewardDetailForm.controls.resourceCode && state.conditionalRewardDetailForm.controls.resourceCode.value
	const accumulationCycleTypeOption = state.conditionalRewardDetailForm.controls.accumulationCycleTypeOption && state.conditionalRewardDetailForm.controls.accumulationCycleTypeOption.value
	const rewardCreditMethod = state.conditionalRewardDetailForm.controls.rewardCreditMethod && state.conditionalRewardDetailForm.controls.rewardCreditMethod.value
	let accumulationCycleTypeCondition = false
	let accumulationYearlyDayMonthCondition = false
	let billingCycleDayCondition = false
	let rewardCreditMethodCondition = false
	if ((accumulationCycleType === 'Y' && resourceCode === 'CARDLINK') || accumulationCycleType === 'B') {
		accumulationCycleTypeCondition = true
	}

	if ((accumulationCycleType === 'Y' && resourceCode !== 'CARDLINK') || (state.conditionalRewardDetailForm.controls.accumulationCycleTypeOption.value === 'FD' && resourceCode === 'CARDLINK')) {
		accumulationYearlyDayMonthCondition = true
	}

	if (accumulationCycleType === 'B' && (state.conditionalRewardDetailForm.controls.accumulationCycleTypeOption.value === 'BC' || state.conditionalRewardDetailForm.controls.accumulationCycleTypeOption.value === 'AC')) {
		billingCycleDayCondition = true
	}

	if (rewardCreditMethod === 'CCE') {
		rewardCreditMethodCondition = true
	}

	return updateGroup<ConditionalRewardDetailForm>({
		resourceCode: validate([required]),
		code: validate([required, FieldValidator.alphaNumericWithoutSpaces(), maxLength(10)]),
		name: validate([required, maxLength(40), FieldValidator.checkValidCharacters()]),
		startDate: validate([required]),
		endDate: validate([required]),
		accumulationCycleType: validate([FieldValidator.requiredIfTrue(rewardCreditMethodCondition)]),
		accumulationCycleMonth: validate([FieldValidator.requiredIfTrue(accumulationYearlyDayMonthCondition)]),
		accumulationCycleDay: validate([FieldValidator.checkCycleDay(state.conditionalRewardDetailForm.controls.accumulationCycleType.value), FieldValidator.requiredIfTrue(accumulationYearlyDayMonthCondition || billingCycleDayCondition), FieldValidator.NotStartWithZero()]),
		accumulationCycleTypeOption: validate([FieldValidator.requiredIfTrue(accumulationCycleTypeCondition)]),
		rewardCreditMethod: validate([required])
	})
}



export interface TierForm {
	fromValue: string
	toValue: string
}


export const initialTierFormValue: TierForm = {
	fromValue: '',
	toValue: ''
}

export const initialTierForm = createFormGroupState('tierForm', initialTierFormValue)

export const validateTierForm = (id: string, state: State) => {
	const tiers = state && state.conditionalRewardDetailForm && state.conditionalRewardDetailForm.value.tiers
	const tier = tiers && tiers.byId[id] && tiers.byId[id].form && tiers.byId[id].form.value
	const fromValue = tier && tier.fromValue
	const toValue = tier && tier.toValue

	let list = []
	if (tiers) {
		list = [...tiers.allIds.map(key => ({
			id: key,
			fromValue: tiers.byId[key].form.value.fromValue,
			toValue: tiers.byId[key].form.value.toValue
		}))]
	}

	const currentIndex = list.findIndex(x => x.id === id)
	let fromValueRequiredCondition = false
	let toValueRequiredCondition = false
	let fromValueCondition = false
	let toValueCondition = false

	if (currentIndex !== -1) {
		if (currentIndex === 0) {
			if (!fromValue && !toValue) {
				fromValueRequiredCondition = true
				toValueRequiredCondition = true
			}

			if (list.length > 1) {
				toValueRequiredCondition = true

				const next = list[currentIndex + 1]

				if (next) {
					if (!toValue) {
						toValueCondition = true
					} else {
						toValueCondition = +toValue >= +next.fromValue
					}
				}
			}
		} else {
			const previous = list[currentIndex - 1]
			const next = list[currentIndex + 1]

			fromValueRequiredCondition = true

			if (previous) {
				fromValueCondition = +fromValue <= +previous.toValue
			}

			if (next) {
				toValueCondition = +toValue >= +next.fromValue
			}

			if (currentIndex === (list.length - 1)) {
				toValueRequiredCondition = false
			}
		}
	}

	return updateGroup<TierForm>({
		fromValue: validate([
			FieldValidator.requiredIfTrue(fromValueRequiredCondition),
			maxLength(10),
			FieldValidator.numberWithoutDecimal(),
			FieldValidator.lessThanOrEqualTo(toValue),
			FieldValidator.ifTrue(fromValueCondition, 'previousTo')
		]),
		toValue: validate([
			FieldValidator.requiredIfTrue(toValueRequiredCondition),
			maxLength(10),
			FieldValidator.numberWithoutDecimal(),
			FieldValidator.moreThanOrEqualTo(fromValue),
			FieldValidator.ifTrue(toValueCondition, 'nextFrom')
		])
	})
}

export const initialState: State = {
	isLoading: false,
	publisheds: [],
	drafts: [],
	unpublisheds: [],
	conditionalRewardDetailForm: initialConditionalRewardDetailForm,
	conditionalRewardDataResponse: {
		channelList: [],
		accumulationCycleTypeList: [],
		dayList: [],
		dayOfMonthList: [],
		monthList: [],
		rewardCreditMethodList: [],
		comparisonList: [],
		yearlyCycleTypeList: [],
		billingCycleTypeList: []
	},
	conditionalRewardCampaignResponse: [],
	conditionalRewardDetailFormView: initialConditionalRewardDetailFormValue,
	allowDelete: true,
	allowRepublish: true
}
