<span *ngIf="title" mat-dialog-title>{{ title | translate }}</span>

<mat-dialog-content>
  <form novalidate [ngrxFormState]="(formState$ | async)">
    <mat-form-field fxFlex>
      <mat-label>{{ 'COMMON.SELECT' | translate }} {{ labelTitle | translate }}</mat-label>
      <mat-select [ngrxFormControlState]="(formState$ | async).controls.selectionList" [multiple]="true" required (closed)="clearSearch()" #multiSelect>
        <mat-option>
          <ngx-mat-select-search [showToggleAllCheckbox]="true" [formControl]="dropDownCtrl"
            [placeholderLabel]="'COMMON.SEARCH'| translate"
            [noEntriesFoundLabel]="'COMMON.NOT_MATCHING_FOUND' | translate"
            [toggleAllCheckboxChecked]="isToggleAllChecked" (toggleAll)="toggleSelectAll($event)">
          </ngx-mat-select-search>
        </mat-option>
        <mat-option *ngFor="let selection of filteredSelectionList | async" [value]="selection.keyValue.key"
          (click)="checkSelectionAll()">
          {{selection.keyValue.value}}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="(formState$ | async).errors._selectionList">
        <app-error-validation [errors]="(formState$ | async).errors._selectionList">
        </app-error-validation>
      </mat-error>
    </mat-form-field>
  </form>
</mat-dialog-content>

<div fxLayout="row" fxLayoutAlign="end">
  <button class="mat-raised-button" (click)="cancel()">{{ 'ACTION.CANCEL' | translate }}</button>
  <button class="mat-raised-button mat-primary" [disabled]="(formState$ | async).controls.selectionList.isInvalid" (click)="add()"
    fxFlexOffset="8px">{{ 'ACTION.ADD' | translate }}</button>
</div>