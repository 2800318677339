import { Component, OnInit, AfterViewInit, ViewEncapsulation } from '@angular/core'
import { Store, Action } from '@ngrx/store'
import * as AppStore from 'src/app/store'
import * as ActionBarWithSelectionAction from 'src/app/store/common/action-bar-with-selection'
import { KeyValue } from '@angular/common'
import * as DashboardAction from 'src/app/store/dashboard/dashboard-overview'
import { ActivatedRoute } from '@angular/router'

@Component({
	selector: 'app-action-bar-with-selection',
	templateUrl: './action-bar-with-selection.component.html',
	styleUrls: ['./action-bar-with-selection.component.scss']
})
export class ActionBarWithSelectionComponent implements OnInit, AfterViewInit {

	action: Action
	selectionList: KeyValue<string, string>[] = []
	resource: string

	constructor(
		public activedRoute: ActivatedRoute,
		private store: Store<AppStore.State>
	) {
		this.action = activedRoute.snapshot.data.action
	}

	ngAfterViewInit(): void {
		const prefixFormField = document.querySelectorAll(`.custom-dashboard [class*="mat-form-field-infix"]`)
		if (prefixFormField.length > 0) {
			(prefixFormField[0] as HTMLElement).style.borderTop = '0px',
			(prefixFormField[0] as HTMLElement).style.position = 'relative',
			(prefixFormField[0] as HTMLElement).style.bottom = '3px'
		}

		const prefixFormField2 = document.querySelectorAll(`.custom-dashboard [class*="mat-form-field-wrapper"]`)
		if (prefixFormField2.length > 0) {
			(prefixFormField2[0] as HTMLElement).style.paddingBottom = '0px'
		}

		const prefixFormField3 = document.querySelectorAll(`.cdk-overlay-pane .custom-dashboard [class*="mat-form-field-infix"]`)
		if (prefixFormField3.length > 0) {
			(prefixFormField3[0] as HTMLElement).style.borderTop = '0px'
		}

		const prefixFormField4 = document.querySelectorAll(`.cdk-overlay-pane .custom-dashboard [class*="mat-form-field-wrapper"]`)
		if (prefixFormField4.length > 0) {
			(prefixFormField4[0] as HTMLElement).style.paddingBottom = '0px'
		}
	}

	ngOnInit() {
		this.store.select(ActionBarWithSelectionAction.getActionBarWithSelection).subscribe(x => {
			this.selectionList = x.selectionList
			this.resource = x.resource
		})
	}

	selectionAction(selectionValue: string) {
		this.store.dispatch(DashboardAction.SetDashboardResource({
			resource: selectionValue
		}))
		this.store.dispatch(DashboardAction.ViewRewardPoolOverview())
		this.store.dispatch(DashboardAction.CampaignOptionList())
		this.store.dispatch(DashboardAction.ViewCampaignsListOverview())
	}
}
