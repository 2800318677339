import { Component, OnInit, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material'
import { MessageDialogData } from 'src/app/models/common/dialog'
import { Store } from '@ngrx/store'
import * as TargetSegmentAction from 'src/app/store/param-settings/target-segment'
import * as AppStore from 'src/app/store'
import { Subscription } from 'rxjs'
import { AbstractControl, FormControl, Validators } from '@angular/forms'
import { TargetSegmentUpdateInput } from 'src/app/models/target-segment/target-segment-edit'

function onlyNumber(typeOfTargetSegment: string) {
	return(control: AbstractControl): { [key: string]: boolean } | null => {
		let textInput = ''
		if (typeOfTargetSegment === 'customerId') {
			textInput = control.value === null || control.value === '' || control.value.match(/^([a-zA-Z0-9])+$/)
		} else {
			textInput = control.value === null || control.value === '' || control.value.match(/^\d+$/)
		}
		return textInput ? null : { containInvalidCharacters: true }
	}
}
@Component({
	selector: 'app-target-segment-dialog',
	templateUrl: './target-segment-dialog.component.html',
	styleUrls: ['./target-segment-dialog.component.scss']
})
export class TargetSegmentDialogComponent implements OnInit {
	parameters: TargetSegmentUpdateInput
	formState$: FormControl

	type = ''
	txtInput = ''
	title = ''
	content = ''
	pendingList: string[] = []
	subs: Subscription
	isLoading = false
	targetSegmentType = ''

	constructor(
		private dialogRef: MatDialogRef<TargetSegmentDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: MessageDialogData,
		private store: Store<AppStore.State>
	) {
		this.subs = this.store.select(TargetSegmentAction.getTargetSegment).subscribe(x => {
			this.isLoading = x.isLoading
			this.targetSegmentType = x.segment
		})

		this.title = data.title
		this.content = data.content
		this.parameters = {
			txtInput: data.payload.txtInput
		}
		this.type = data.payload.type
		this.pendingList = (data.payload.pendingList || [])
		this.txtInput = data.payload.txtInput

		this.formState$ = new FormControl(this.parameters.txtInput, [onlyNumber(this.targetSegmentType), Validators.maxLength(20)])
	}

	ngOnInit() {
	}

	cancel() {
		this.dialogRef.close()
	}

	submit(action) {
		switch (this.type) {
			case 'ADD_EDIT':
				this.data.payload = this.formState$.value
				break
			case 'APPEND_REPLACE':
				this.data.payload = { action, pendingList: this.pendingList }
				break
		}
		this.dialogRef.close(this.data)
	}

	isInvalidOrEqualToOldValue() {
		return this.formState$.invalid || (this.formState$.value === this.data.payload.txtInput)
	}
}
