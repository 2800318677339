import { createAction, props } from '@ngrx/store'
import { WorklistDetailResponse, WorklistResponse } from 'src/app/models/worklist/worklist'

export const InitialState = createAction('[Tasklist] InitialState')
export const RestError = createAction('[Tasklist] RestError', props<{ message: string, manualThrow: boolean }>())

export const GoList = createAction('[Tasklist] GoList')

export const List = createAction('[Tasklist] List', props<{ pageNumber: number, pageSize: number }>())
export const ListSuccess = createAction('[Tasklist] ListSuccess', props<{ payload: WorklistResponse }>())

export const ListByStatus = createAction('[Tasklist] ListByStatus', props<{ status: string, pageNumber: number, pageSize: number }>())
export const ListByStatusSuccess = createAction('[Tasklist] ListByStatusSuccess', props<{ status: string, payload: WorklistDetailResponse }>())

export const SearchListByKeyword = createAction('[Tasklist] SearchListByKeyword', props<{ keyword: string, status: string, pageNumber: number, pageSize: number }>())
