import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material'
import { Router } from '@angular/router'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Action, Store } from '@ngrx/store'
import { Observable, of } from 'rxjs'
import { catchError, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators'
import { MessageDialogComponent } from 'src/app/components/common/dialog/message-dialog/message-dialog.component'
import { getDialogConfig } from 'src/app/models/common/dialog'
import { Response } from 'src/app/models/common/http'
import { ProductTypeGroupListResponse } from 'src/app/models/param-settings/product-type-group/product-type-group-list'
import { ProductTypeGroupViewResponse } from 'src/app/models/param-settings/product-type-group/product-type-group-view'
import { ParameterSettingsService } from 'src/app/services/param-settings/parameter-settings.service'
import * as AppStore from 'src/app/store/'
import * as CommonAction from '../../common/common/common.actions'
import * as ProductTypeGroupAction from './product-type-group.actions'
import { ProductTypeGroupOptionResponse, ProductTypeOptionRequest } from 'src/app/models/param-settings/product-type-group/product-type-group-option'
import { KeyValue } from '@angular/common'
import { ProductTypeGroupDuplicateComponent } from 'src/app/components/param-settings/product-type-group/product-type-group-duplicate/product-type-group-duplicate.component'

@Injectable()
export class Effects {

	constructor(
		private action$: Actions,
		private router: Router,
		private dialog: MatDialog,
		private parameterSettingsService: ParameterSettingsService,
		private store: Store<AppStore.State>
	) { }

	InitialState$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ProductTypeGroupAction.InitialState),
			tap(() => {
				this.router.navigate(['parameter-settings/product-type-group'])
			})
		), { dispatch: false }
	)

	GoList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ProductTypeGroupAction.GoList),
			tap(() => {
				this.router.navigate(['parameter-settings/product-type-group'])
			})
		), { dispatch: false }
	)

	GoView$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ProductTypeGroupAction.GoView),
			tap(() => {
				this.router.navigate(['parameter-settings/product-type-group/view'])
			})
		), { dispatch: false }
	)

	GoCreate$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ProductTypeGroupAction.GoCreate),
			tap(() => {
				this.router.navigate(['parameter-settings/product-type-group/create'])
			})
		), { dispatch: false }
	)

	GoUpdate$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ProductTypeGroupAction.GoUpdate),
			tap(() => {
				this.router.navigate(['parameter-settings/product-type-group/update'])
			})
		), { dispatch: false }
	)

	GoBackDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ProductTypeGroupAction.GoBackDialog),
			mergeMap(({ action }) => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.BACK', payload: action }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					if (result.payload === 'LIST') {
						return ProductTypeGroupAction.GoList()
					}
					if (result.payload === 'VIEW') {
						return ProductTypeGroupAction.GoView()
					}
				} else {
					return ProductTypeGroupAction.CloseDialog()
				}
			})
		)
	)

	RestError$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ProductTypeGroupAction.RestError),
			map(({ message, manualThrow }) => {
				return CommonAction.RestError({ message, manualThrow })
			})
		)
	)

	OptionList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ProductTypeGroupAction.OptionList),
			withLatestFrom(this.store.select(state => state.productTypeGroup)),
			mergeMap(([x, productTypeGroup]) => {

				const params: ProductTypeOptionRequest = {
					resourceCode: productTypeGroup.productTypeGroupDetailForm.value.resourceCode
				}

				return this.parameterSettingsService.getProductTypeOptionsList(params).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: ProductTypeGroupOptionResponse = response.payload
					if (success) {
						return ProductTypeGroupAction.OptionListSuccess({ payload })
					} else { return ProductTypeGroupAction.RestError(response as any) }
				}), catchError(this.catcher))
			})
		)
	)

	ResourceListCreate$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ProductTypeGroupAction.ResourceListCreate),
			mergeMap(() => {
				return this.parameterSettingsService.getResourceList({ module: 'productTypeGroup', action: 'create' }).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: KeyValue<string, string>[] = response.payload
					if (success) {
						return ProductTypeGroupAction.ResourceListCreateSuccess({ payload })
					} else { return ProductTypeGroupAction.RestError(response as any) }
				}), catchError(this.catcher))
			})
		)
	)

	ResourceListUpdate$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ProductTypeGroupAction.ResourceListUpdate),
			mergeMap(() => {
				return this.parameterSettingsService.getResourceList({ module: 'productTypeGroup', action: 'update' }).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: KeyValue<string, string>[] = response.payload
					if (success) {
						return ProductTypeGroupAction.ResourceListUpdateSuccess({ payload })
					} else { return ProductTypeGroupAction.RestError(response as any) }
				}), catchError(this.catcher))
			})
		)
	)

	List$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ProductTypeGroupAction.List),
			withLatestFrom(this.store.select(state => state.productTypeGroup)),
			mergeMap(([x, productTypeGroup]) => {

				return this.parameterSettingsService.getProductTypeGroupList().pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: ProductTypeGroupListResponse = response.payload
					if (success) {
						return ProductTypeGroupAction.ListSuccess({ payload })
					} else { return ProductTypeGroupAction.RestError(response as any) }
				}),
					catchError(this.catcher)
				)
			})
		)
	)

	View$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ProductTypeGroupAction.View),
			withLatestFrom(this.store.select(state => state.productTypeGroup)),
			mergeMap(([, productTypeGroup]) => this.parameterSettingsService.viewProductTypeGroup({ code: productTypeGroup.code })
				.pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: ProductTypeGroupViewResponse = response.payload
					if (success) {
						return ProductTypeGroupAction.ViewSuccess({ payload })
					} else { return ProductTypeGroupAction.RestError(response as any) }
				}), catchError(this.catcher)
				)
			)
		)
	)

	CreateDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ProductTypeGroupAction.CreateDialog),
			mergeMap(() => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.CREATE_PRODUCT_TYPE_GROUP' }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return ProductTypeGroupAction.Create()
				} else {
					return ProductTypeGroupAction.CloseDialog()
				}
			})
		)
	)

	Create$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ProductTypeGroupAction.Create),
			withLatestFrom(this.store.select(state => state.productTypeGroup)),
			mergeMap(([, productTypeGroup]) => {
				const form = productTypeGroup.productTypeGroupDetailForm.controls
				return this.parameterSettingsService.createProductTypeGroup({
					code: 'PTG' + form.code.value,
					name: form.name.value,
					productTypeList: form.productTypes.value.value,
					description: form.description.value,
					resourceCode: form.resourceCode.value
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: string = response.payload
					if (success) {
						return ProductTypeGroupAction.CreateSuccess({ payload })
					} else { return ProductTypeGroupAction.RestError(response as any) }
				}), catchError(this.catcher))
			})
		)
	)

	CreateSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ProductTypeGroupAction.CreateSuccess),
			mergeMap(({ payload }) => {
				return [ProductTypeGroupAction.GoList(), CommonAction.RestError({ message: payload })]
			})
		)
	)

	UpdateDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ProductTypeGroupAction.UpdateDialog),
			mergeMap(() => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.UPDATE' }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return ProductTypeGroupAction.Update()
				} else {
					return ProductTypeGroupAction.CloseDialog()
				}
			})
		)
	)

	Update$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ProductTypeGroupAction.Update),
			withLatestFrom(this.store.select(state => state.productTypeGroup)),
			mergeMap(([, productTypeGroup]) => {
				const form = productTypeGroup.productTypeGroupDetailForm.controls
				return this.parameterSettingsService.updateProductTypeGroup({
					code: form.code.value,
					name: form.name.value,
					resourceCode: form.resourceCode.value,
					productTypeList: form.productTypes.value.value,
					description: form.description.value,
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: string = response.payload
					if (success) {
						return ProductTypeGroupAction.UpdateSuccess({ payload })
					} else { return ProductTypeGroupAction.RestError(response as any) }
				}), catchError(this.catcher))
			})
		)
	)

	UpdateSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ProductTypeGroupAction.UpdateSuccess),
			mergeMap(({ payload }) => {
				return [ProductTypeGroupAction.GoView(), CommonAction.RestError({ message: payload })]
			})
		)
	)

	DeleteDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ProductTypeGroupAction.DeleteDialog),
			mergeMap(() => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.DELETE_PRODUCT_TYPE_GROUP' }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return ProductTypeGroupAction.Delete()
				} else {
					return ProductTypeGroupAction.CloseDialog()
				}
			})
		)
	)

	Delete$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ProductTypeGroupAction.Delete),
			withLatestFrom(this.store.select(state => state.productTypeGroup)),
			mergeMap(([, productTypeGroup]) => this.parameterSettingsService.deleteProductTypeGroup({
				code: productTypeGroup.code
			}).pipe(map((response: Response) => {
				const success: boolean = response.success
				const payload: string = response.payload

				if (success) {
					return ProductTypeGroupAction.DeleteSuccess({ payload })
				} else { return ProductTypeGroupAction.RestError(response as any) }
			}), catchError(this.catcher)
			))
		)
	)

	DeleteSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ProductTypeGroupAction.DeleteSuccess),
			mergeMap(({ payload }) => {
				return [ProductTypeGroupAction.GoList(), CommonAction.RestError({ message: payload })]
			})
		)
	)

	DuplicateDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ProductTypeGroupAction.DuplicateDialog),
			mergeMap(() => {
				const dialogRef = this.dialog.open(ProductTypeGroupDuplicateComponent, getDialogConfig({}))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return ProductTypeGroupAction.Duplicate()
				} else {
					return ProductTypeGroupAction.CloseDialog()
				}
			})
		)
	)

	Duplicate$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ProductTypeGroupAction.Duplicate),
			withLatestFrom(this.store.select(state => state.productTypeGroup)),
			mergeMap(([, productTypeGroup]) => {
				const productTypeGroupDuplicateForm = productTypeGroup.productTypeGroupDuplicateForm.value
				return this.parameterSettingsService.duplicateProductTypeGroup(productTypeGroup.code, {
					code: "PTG" + productTypeGroupDuplicateForm.code,
					name: productTypeGroupDuplicateForm.name
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload = response.payload
					if (success) {
						return ProductTypeGroupAction.DuplicateSuccess({ payload })
					} else {
						return ProductTypeGroupAction.RestError(response as any)
					}
				}), catchError(this.catcher))
			})
		)
	)

	DuplicateSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ProductTypeGroupAction.DuplicateSuccess),
			mergeMap(({ payload }) => {
				return [
					ProductTypeGroupAction.List(),
					CommonAction.RestError({ message: payload })
				]
			})
		)
	)

	catcher = (error) => {
		return (of(ProductTypeGroupAction.RestError({ message: error.message, manualThrow: error.manualThrow })))
	}
}
