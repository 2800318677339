import { DatePipe } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import { DateAdapter, MatDialog, MAT_DATE_FORMATS } from '@angular/material'
import { MomentDateAdapter } from '@angular/material-moment-adapter'
import { TranslateService } from '@ngx-translate/core'
import { SnackbarService } from 'src/app/services/common/snackbar/snackbar.service'
import { environment } from 'src/environments/environment'
import { GlobalMessageDialogComponent } from './components/common/dialog/global-message-dialog/global-message-dialog.component'
import { CUSTOM_DATE_FORMATS } from './models/util/date.adapter'
import { GlobalDialogBox } from './models/util/global-dialog'

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	providers: [
		DatePipe,
		{
			provide: DateAdapter, useClass: MomentDateAdapter
		},
		{
			provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS
		}
	]
})
export class AppComponent implements OnInit {
	title = 'hephaestus-frontend'

	constructor(
		public translate: TranslateService,
		private dialog: MatDialog,
		private snackbarService: SnackbarService
	) {
		const lang = environment.lang
		translate.addLangs([lang])
		translate.setDefaultLang(lang)
		translate.use(lang)

		const browserLang = translate.getBrowserLang()
		translate.use(translate.getLangs().find(x => x === browserLang) ? browserLang : lang)
	}

	ngOnInit(): void {
		GlobalDialogBox.register(this.dialog, GlobalMessageDialogComponent)
		GlobalDialogBox.registerSnakebar(this.snackbarService)
	}
}
