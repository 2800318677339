import { createAction, props } from '@ngrx/store'
import { PointAdjustmentFileUploadForm } from './point-adjustment-file-upload.state'
import { KeyValue } from '@angular/common'
import { AdjustmentFile } from 'src/app/models/adjustment/adjustment-list'
import { AdjustmentFileInfo, AdjustmentFileContent } from 'src/app/models/adjustment/adjustment'
import { POLICY } from 'src/app/models/common/constant'

export const InitialState = createAction('[PointAdjustmentFileUpload] InitialState')
export const Reset = createAction('[PointAdjustmentFileUpload] Reset')

export const FileList = createAction('[PointAdjustmentFileUpload] FileList', props<{ payload: string }>())
export const FileListSuccess = createAction('[PointAdjustmentFileUpload] FileListSuccess', props<{ payload: AdjustmentFile[] }>())

export const SetId = createAction('[PointAdjustmentFileUpload] SetId', props<{ payload: number }>())
export const SetResourceCode = createAction('[PointAdjustmentFileUpload] SetResourceCode', props<{ payload: string }>())

export const FileContent = createAction('[PointAdjustmentFileUpload] FileContent', props<{ payload: POLICY}>())
export const FileContentSuccess = createAction('[PointAdjustmentFileUpload] FileContentSuccess', props<{ payload: AdjustmentFileContent[] }>())

export const RestError = createAction('[PointAdjustmentFileUpload] RestError', props<{ message: string, manualThrow: boolean }>())
export const CreatePointAdjustmentFileUpload = createAction('[PointAdjustmentFileUpload] CreatePointAdjustmentFileUpload', props<{ payload: PointAdjustmentFileUploadForm }>())
export const CreatePointAdjustmentFileUploadSuccess = createAction('[PointAdjustmentFileUpload] CreatePointAdjustmentFileUploadSuccess')

export const ResourceListCreate = createAction('[PointAdjustmentFileUpload] ResourceListCreate')
export const ResourceListCreateSuccess = createAction('[PointAdjustmentFileUpload] ResourceListCreateSuccess', props<{ payload: KeyValue<string, string>[] }>())

export const DownloadFile = createAction('[PointAdjustmentFileUpload] DownloadFile', props<{ payload: string }>())
export const DownloadFileSuccess = createAction('[PointAdjustmentFileUpload] DownloadFileSuccess', props<{ payload: AdjustmentFileInfo }>())

export const GoView = createAction('[PointAdjustmentFileUpload] GoView')
export const GoList = createAction('[PointAdjustmentFileUpload] GoList')
