import { createAction, props } from '@ngrx/store'
import { CustomerListResponse } from 'src/app/models/customer-management/customer-list'
import { CustomerViewResponse } from 'src/app/models/customer-management/customer-detail'
import { CustomerCardViewResponse, CustomerCardViewTransactionResponse } from 'src/app/models/customer-management/customer-card-detail'
import { AccumulatingTransactionListData, CustomerAccountViewResponse, CustomerAccountViewTransactionDetailResponse, CustomerAccountViewTransactionListResponse, PointBreakdownListData } from 'src/app/models/customer-management/customer-account-detail'
import { CustomerRewardPoolViewResponse, RewardPoolInformationList } from 'src/app/models/customer-management/customer-reward-pool-detail'
import { CardProductCatalogueListData, CardProductCatalogueListResponse, CustomerAddressListResponse, CustomerCardListResponse } from 'src/app/models/customer-management/catalogue-redemption'
import { CustomerStatementTransactionListResponse } from 'src/app/models/customer-management/customer-statement-transaction-detail'
import { WaiverListDataResponse, WaiverTypeListDataResponse } from 'src/app/models/customer-management/waiver'
import { AccumulationBucketList } from 'src/app/models/customer-management/customer-accumulation-bucket-detail'
import { CustomerRedemptionHistoryListResponse } from 'src/app/models/customer-management/customer-redemption-history'
import { CustomerCatalogueRedemptionOrderViewResponse } from 'src/app/models/customer-management/customer-catalogue-redemption-order'
import { CustomerPartnerRedemptionListData, CustomerPartnerRedemptionListResponse } from 'src/app/models/customer-management/customer-partner-redemption'
import { Option } from 'src/app/models/option/option'
import { AdjustmentTypeListResponse, PointsAdjustmentDetails, RewardPoolListResponse } from 'src/app/models/customer-management/customer-points-adjustment'
import { ConditionalRewardViewResponse } from 'src/app/models/customer-management/customer-conditional-reward'
import { CustomerPartnerRedemptionOrderViewResponse } from 'src/app/models/customer-management/customer-partner-redemption-order'

export const InitialState = createAction('[Customer Management] InitialState')

export const Reset = createAction('[Customer Management] Reset')
export const SetId = createAction('[Customer Management] SetId', props<{ id: string }>())
export const SetCardId = createAction('[Customer Management] SetCardId', props<{ cardId: number }>())
export const SetAccountId = createAction('[Customer Management] SetAccountId', props<{ accountId: number }>())
export const SetRewardPoolId = createAction('[Customer Management] SetRewardPoolId', props<{ rewardPoolId: number }>())
export const SetRewardPoolGroupId = createAction('[Customer Management] SetRewardPoolGroupId', props<{ rewardPoolGroupId?: number }>())
export const SetRedemptionTransactionIdAndRedemptionType = createAction('[Custmer Management] SetRedemptionTransactionIdAndRedemptionType', props<{ redemptionTransactionId?: number, redemptionType?: string }>())
export const SetIcNumber = createAction('[Customer Management] SetIcNumber', props<{ icNumber: string }>())
export const SetCustomerName = createAction('[Customer Management] SetCustomerName', props<{ customerName: string }>())
export const SetCardNumber = createAction('[Customer Management] SetCardNumber', props<{ cardNumber: string }>())
export const SetAccountNumber = createAction('[Customer Management] SetAccountNumber', props<{ accountNumber: string }>())
export const SetPartnerRedemption = createAction('[Customer Management] SetPartnerRedemption', props<{ partnerRedemption: CustomerPartnerRedemptionListData }>())
export const SetStatementStartDateAndEndDate = createAction('[Customer Management] SetStatementStartDateAndEndDate', props<{ startDate: string, endDate: string }>())
export const SetConditionalRewardId = createAction('[Customer Management] SetConditionalRewardId', props<{ conditionalRewardId: number }>())
export const SetCatalogueRedemptionId = createAction('[Customer Management] SetCatalogueRedemptionId', props<{ catalogueRedemptionId: number }>())
export const SetSearchIcNumber = createAction('[Customer Management] SetSearchIcNumber', props<{ icNumber: string }>())
export const SetSearchCustomerName = createAction('[Customer Management] SetSearchCustomerName', props<{ customerName: string }>())
export const SetSearchCardNumber = createAction('[Customer Management] SetSearchCardNumber', props<{ cardNumber: string }>())
export const SetSearchAccountNumber = createAction('[Customer Management] SetSearchAccountNumber', props<{ accountNumber: string }>())
export const SetTotalPointsAndTotalAmount = createAction('[Customer Management] SetTotalPointsAndTotalAmount', props<{ totalPoints: string, totalAmount: string }>())
export const SetResource = createAction('[Customer Management] SetResource', props<{ resource: string }>())

export const SetTaskId = createAction('[Customer Management] SetTaskId', props<{ payload: number }>())
export const GoList = createAction('[Customer Management] GoList')
export const GoView = createAction('[Customer Management] GoView')
export const GoCardView = createAction('[Customer Management] GoCardView')
export const GoAccountView = createAction('[Customer Management] GoAccountView')
export const GoRewardPoolView = createAction('[Customer Management] GoRewardPoolView')
export const GoRewardPoolGroupView = createAction('[Customer Management] GoRewardPoolGroupView')
export const GoConditionalRewardView = createAction('[Customer Management] GoConditionalRewardView')
export const GoRewardPoolAccumulationBucket = createAction('[Customer Management] GoRewardPoolAccumulationBucket')
export const GoAccountAccumulationBucket = createAction('[Customer Management] GoAccountAccumulationBucket')
export const GoCardStatementView = createAction('[Customer Management] GoCardStatementView')
export const GoAccountStatementView = createAction('[Customer Management] GoAccountStatementView')
export const GoCardStatementDetailView = createAction('[Customer Management] GoCardStatementDetailView')
export const GoAccountStatementDetailView = createAction('[Customer Management] GoAccountStatementDetailView')
export const GoRewardPoolStatementView = createAction('[Customer Management] GoRewardPoolStatementView')
export const GoRedemptionHistoryView = createAction('[Customer Management] GoRedemptionHistoryView')
export const GoPointsAdjustment = createAction('[Customer Management] GoPointsAdjustment')
export const GoPointsAdjustmentView = createAction('[Customer Management] GoPointsAdjustmentView')
export const GoCatalogueRedemptionGridList = createAction('[Customer Management] GoCatalogueRedemptionGridList')
export const GoCatalogueRedemptionDetail = createAction('[Customer Management] GoCatalogueRedemptionDetail')
export const GoCatalogueRedemptionDialog = createAction('[Customer Management] GoCatalogueRedemptionDialog', props<{ action: string }>())
export const GoCatalogueRedemptionMyRequestView = createAction('[Customer Management] GoCatalogueRedemptionMyRequestView')
export const GoCatalogueRedemptionTasklistView = createAction('[Customer Management] GoCatalogueRedemptionTasklistView')
export const GoTransactionBreakdownDialog = createAction('[Customer Management] GoTransactionBreakdownDialog', props<{ payload: number }>())
export const GoTransactionView = createAction('[Customer Management] GoTransactionView')

export const RestError = createAction('[Customer Management] RestError', props<{ message: string, manualThrow: boolean }>())

export const ListPage = createAction('[Customer Management] ListPage', props <{pageNumber: number, pageSize: number}>())
export const List = createAction('[Customer Management] List', props <{ pageNumber: number, pageSize: number}>())
export const ListSuccess = createAction('[Customer Management] ListSuccess', props<{ payload: CustomerListResponse }>())

export const View = createAction('[Customer Management] View')
export const ViewSuccess = createAction('[Customer Management] ViewSuccess', props<{ payload: CustomerViewResponse }>())

export const CardView = createAction('[Customer Management] CardView')
export const CardViewSuccess = createAction('[Customer Management] CardViewSuccess', props<{ payload: CustomerCardViewResponse }>())

export const CardTransactionList = createAction('[Customer Management] CardTransactionList', props<{ accountNumber: string, cardNumber: string, relationship: string, startDate: string, endDate: string, pageNumber: number, pageSize: number }>())
export const CardTransactionListSuccess = createAction('[Customer Management] CardTransactionListSuccess', props<{ payload: CustomerCardViewTransactionResponse }>())

export const AccountView = createAction('[Customer Management] AccountView')
export const AccountViewSuccess = createAction('[Customer Management] AccountViewSuccess', props<{ payload: CustomerAccountViewResponse }>())

export const AccountTransactionListByCycle = createAction('[Customer Management] AccountTransactionListByCycle', props<{ startDate: string, endDate: string, rewardPoolId: number, pageNumber: number, pageSize: number }>())
export const AccountTransactionListSuccess = createAction('[Customer Management] AccountTransactionListSuccess', props<{ payload: CustomerAccountViewTransactionListResponse }>())

export const AccountTransactionView = createAction('[Customer Management] AccountTransactionView', props<{ id: number }>())
export const AccountTransactionViewSuccess = createAction('[Customer Management] AccountTransactionViewSuccess', props<{ payload: CustomerAccountViewTransactionDetailResponse }>())

export const RewardPoolView = createAction('[Customer Management] RewardPoolView')
export const RewardPoolViewSuccess = createAction('[Customer Management] RewardPoolViewSuccess', props<{ payload: CustomerRewardPoolViewResponse }>())

export const ConditionalRewardView = createAction('[Customer Management] ConditionalRewardView')
export const ConditionalRewardViewSuccess = createAction('[Customer Management] ConditionalRewardViewSuccess', props<{ payload: ConditionalRewardViewResponse[] }>())

export const SelectProductCatalogueDialog = createAction('[Customer Management] SelectProductCatalogueDialog')
export const CatalogueRedemptionDialog = createAction('[Customer Management] CatalogueRedemptionDialog')
export const CatalogueRedemptionList = createAction('[Customer Management] CatalogueRedemptionList')
export const closeDialog = createAction('[Customer Management] closeDialog')

export const GetProductCatalogueList = createAction('[Customer Management] GetProductCatalogueList')
export const GetProductCatalogueListSuccess = createAction('[Customer Management] GetProductCatalogueListSuccess', props<{ payload: CardProductCatalogueListResponse }>())

export const SelectProductCatalogue = createAction('[Customer Management] SelectProductCatalogue', props<{ productCatalogue: CardProductCatalogueListData}>())

export const GetCustomerCardList = createAction('[Customer Management] GetCustomerCardList')
export const GetCustomerCardListSuccess = createAction('[Customer Management] GetCustomerCardListSuccess', props<{ payload: CustomerCardListResponse}>())
export const GetCustomerAddressList = createAction('[Customer Management] GetCustomerAddressList')
export const GetCustomerAddressListSuccess = createAction('[Customer Management] GetCustomerAddressListSuccess', props<{ payload: CustomerAddressListResponse}>())

export const SetCatalogueRedemptionAddress = createAction('[Customer Management] SetCatalogueRedemptionAddress', props<{ address1?: string, address2?: string, address3?: string, city?: string, addressState?: string, postcode?: string, emailAddress?: string}>())

export const CreateCatalogueRedemption = createAction('[Customer Management] CreateCatalogueRedemption')
export const CreateCatalogueRedemptionSuccess = createAction('[Customer Management] CreateCatalogueRedemptionSuccess', props<{ payload: string }>())
export const CardStatmentTransactionList = createAction('[Customer Management] CardStatmentTransactionList', props<{ accountId: number, customerId: string }>())
export const InitialStatmentTransactionList = createAction('[Customer Management] InitialStatmentTransactionList')
export const RewardPoolStatmentTransactionList = createAction('[Customer Management] RewardPoolStatmentTransactionList', props<{ accountId: number, customerId: string, rewardPoolId: number }>())
export const StatementTransactionListSuccess = createAction('[Customer Management] StatementTransactionListSuccess', props<{ payload: CustomerStatementTransactionListResponse }>())

export const GetCardStatementTransactionOptionList = createAction('[Customer Management] GetCardStatementTransactionOptionList')
export const GetCardStatementTransactionOptionListSuccess = createAction('[Customer Management] GetCardStatementTransactionOptionListSuccess', props<{ payload: Option[] }>())
export const GetCardStatementTransactionListByCycle = createAction('[Customer Management] GetCardStatementTransactionListByCycle', props<{
accountNumber: string, cardNumber: string, relationship: string, pageNumber: number, pageSize: number, rewardPoolId?: number, startDate: string, endDate: string
}>())
export const GetCardStatementTransactionListByCycleSuccess = createAction('[Customer Management] GetCardStatementTransactionListByCycleSuccess', props<{ payload: CustomerCardViewTransactionResponse }>())
export const CardStatementTransactionDialog = createAction('[Customer Management] CardStatementTransactionDialog')

export const GetWaiverTypeList = createAction('[Customer Management] GetWaiverTypeList')
export const GetWaiverTypeListSuccess = createAction('[Customer Management] GetWaiverTypeListSuccess', props<{ payload: WaiverTypeListDataResponse }>())

export const ClearWaiverList = createAction('[Customer Management] ClearWaiverList')
export const GetWaiverList = createAction('[Customer Management] GetWaiverList', props<{ waiverTypeId: number }>())
export const GetWaiverListSuccess = createAction('[Customer Management] GetWaiverListSuccess', props<{ payload: WaiverListDataResponse }>())

export const CreateWaiver = createAction('[Customer Management] CreateWaiver')
export const CreateWaiverSuccess = createAction('[Customer Management] CreateWaiverSuccess', props<{ payload: string }>())

export const SelectWaiverDialog = createAction('[Customer Management] SelectWaiverDialog')
export const WaiverConfirmationDialog = createAction('[Customer Management] WaiverConfirmationDialog')

export const RewardPoolInformation = createAction('[Customer Management] RewardPoolInformation')
export const RewardPoolInformationSuccess = createAction('[Customer Management] RewardPoolInformationSuccess', props<{payload: RewardPoolInformationList[]}>())

export const AccumulationBucket = createAction('[Customer Management] AccumulationBucket')
export const AccumulationBucketSuccess = createAction('[Customer Management] AccumulationBucketSuccess', props<{payload: AccumulationBucketList }>())

export const GetRedemptionHistoryList = createAction('[Customer Management] GetRedemptionHistoryList')
export const GetRedemptionHistoryListSuccess = createAction('[Customer Management] GetRedemptionHistoryListSuccess', props<{payload: CustomerRedemptionHistoryListResponse }>())

export const CatalogueRedemptionOrderView = createAction('[Customer Management] CatalogueRedemptionOrderView')
export const CatalogueRedemptionOrderViewSuccess = createAction('[Customer Management] CatalogueRedemptionOrderViewSuccess', props<{payload: CustomerCatalogueRedemptionOrderViewResponse }>())
export const CatalogueRedemptionOrderDialog = createAction('[Customer Management] CatalogueRedemptionOrderDialog')
export const CatalogueRedemptionOrderWithdrawal = createAction('[Customer Management] CatalogueRedemptionOrderWithdrawal')
export const CatalogueRedemptionOrderWithdrawalSuccess = createAction('[Customer Management] CatalogueRedemptionOrderWithdrawalSuccess', props<{ payload: string }>())

export const PartnerRedemptionOrderView = createAction('[Customer Management] PartnerRedemptionOrderView')
export const PartnerRedemptionOrderViewSuccess = createAction('[Customer Management] PartnerRedemptionOrderViewSuccess', props<{payload: CustomerPartnerRedemptionOrderViewResponse }>())
export const PartnerRedemptionOrderDialog = createAction('[Customer Management] PartnerRedemptionOrderDialog')

export const GetPartnerRedemptionList = createAction('[Customer Management] GetPartnerRedemptionList', props<{from: string}>())
export const GetPartnerRedemptionListSuccess = createAction('[Customer Management] GetPartnerRedemptionListSuccess', props<{ payload: CustomerPartnerRedemptionListResponse }>())
export const PartnerRedemptionDialog = createAction('[Customer Management] PartnerRedemptionDialog')
export const PartnerRedemptionConfirmationDialog = createAction('[Customer Management] PartnerRedemptionConfirmationDialog')
export const SetPointAndValueAndTypeAndRewardId = createAction('[Customer Management] SetPointAndValueAndType', props<{ point: number, value: number, redemptionType: string, rewardId: number }>())
export const SetPointResetValueAndAirmileId = createAction('[Customer Management] SetPointResetValueAndAirmileId', props<{ point: number }>())
export const ResetPointAndValueAndNameAndAirmileId = createAction('[Customer Management] ResetPointAndValueAndNameAndAirmileId')
export const SetCustomerPartnerRdemptionListData = createAction('[Customer Management] SetCustomerPartnerRdemptionListData', props<{ customerPartnerRedemptionListData: CustomerPartnerRedemptionListData}>())


export const CreatePartnerRedemption = createAction('[Customer Management] CreatePartnerRedemption')
export const CreatePartnerRedemptionSuccess = createAction('[Customer Management] CreatePartnerRedemptionSuccess', props<{ payload: string }>())

export const GetPoBoxList = createAction('[Customer Management] GetPoBoxList')
export const GetPoBoxListSuccess = createAction('[Customer Management] GetPoBoxListSuccess', props<{ payload: string[] }>())

export const SubmitDialog = createAction('[Customer Management] SubmitDialog')
export const Submit = createAction('[Customer Management] Submit')
export const SubmitSuccess = createAction('[Customer Management] SubmitSuccess', props<{ payload: string }>())
export const CloseDialog = createAction('[Customer Management] CloseDialog')

export const GetPointsAdjustmentDetails = createAction('[Customer Management] GetPointsAdjustmentDetails')
export const GetPointsAdjustmentDetailsSuccess = createAction('[Customer Management] GetPointsAdjustmentDetailsSuccess', props<{ payload: PointsAdjustmentDetails }>())

export const AdjustmentTypeList = createAction('[Customer Management] AdjustmentTypeList')
export const AdjustmentTypeListSuccess = createAction('[Customer Management] AdjustmentTypeListSuccess', props<{ payload: AdjustmentTypeListResponse}>())

export const CreateCataloguePartnerRedemption = createAction('[Customer Management] CreateCataloguePartnerRedemption')
export const CreateCataloguePartnerRedemptionSuccess = createAction('[Customer Management] CreateCataloguePartnerRedemptionSuccess', props<{ payload: string }>())

export const CardTransactionView = createAction('[Customer Management] CardTransactionView', props<{ id: number }>())
export const CardTransactionViewSuccess = createAction('[Customer Management] CardTransactionViewSuccess', props<{ payload: PointBreakdownListData }>())

export const RewardPoolList = createAction('[Customer Management] RewardPoolList')
export const RewardPoolListSuccess = createAction('[Customer Management] RewardPoolListSuccess', props<{ payload: RewardPoolListResponse }>())

export const GetAccumulatingTransactions = createAction('[Customer Management] GetAccumulatingTransactions')
export const GetAccumulatingTransactionsSuccess = createAction('[Customer Management] GetAccumulatingTransactionsSuccess', props<{ payload: AccumulatingTransactionListData[] }>())
