import { Action, createReducer, on } from '@ngrx/store'
import * as ProductPartnerAction from './product-partner.actions'
import * as ProductPartnerState from './product-partner.state'
import * as NgrxForms from 'ngrx-forms'
import { formGroupReducer, setValue, reset, markAsTouched, SetValueAction } from 'ngrx-forms'
import { KeyValue } from '@angular/common'
import { Util } from 'src/app/models/util/util'

export const reducer = createReducer(
	ProductPartnerState.initialState,
	NgrxForms.onNgrxFormsAction(SetValueAction, (state, action) => {
		return actionHandler(state, action)
	}),
	NgrxForms.onNgrxFormsAction(NgrxForms.MarkAsTouchedAction, (state, action) => {
		return actionHandler(state, action)
	}),
	on(ProductPartnerAction.InitialState, () => ProductPartnerState.initialState),
	on(ProductPartnerAction.SetId, (state, { id }) => ({
		...state,
		id
	})),
	on(ProductPartnerAction.SetPartnerCode, (state, { redeemPartner }) => ({
		...state,
		redeemPartner
	})),
	on(ProductPartnerAction.SetRedeemPartnerId, (state, { redeemPartnerId }) => ({
		...state,
		redeemPartnerId
	})),
	on(ProductPartnerAction.RestError, state => ({
		...state,
		isLoading: false,
		isDuplicateDialog: false,
		isDuplicateLoading: false
	})),
	on(ProductPartnerAction.GoCreate, state => ({
		...state,
		productPartnerDetailForm: reset(setValue(state.productPartnerDetailForm, ProductPartnerState.initialProductPartnerDetailFormValue)),
		action: 'CREATE'
	})),
	on(ProductPartnerAction.GoUpdate, state => ({
		...state,
		action: 'UPDATE'
	})),
	on(ProductPartnerAction.GoView, state => ({
		...state,
		action: 'VIEW'
	})),
	on(ProductPartnerAction.StatusList, state => ({
		...state,
		isLoading: true
	})),
	on(ProductPartnerAction.StatusListSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		statusList: payload.statusList
	})),
	on(ProductPartnerAction.OnChangeRedeemPartner, state => ({
		...state,
		isLoading: true
	})),
	on(ProductPartnerAction.OnChangeRedeemPartnerSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		productPartnerDetailForm: ProductPartnerState.validateProductPartnerDetailForm(state)(setValue(state.productPartnerDetailForm, {
			code: state.productPartnerDetailForm.value.code,
			name: state.productPartnerDetailForm.value.name,
			description: state.productPartnerDetailForm.value.description,
			status: state.productPartnerDetailForm.value.status,
			redeemPartnerId: state.productPartnerDetailForm.value.redeemPartnerId,
			redeemPartner: state.productPartnerDetailForm.value.redeemPartner,
			rewardPoolId: '',
			rewardPoolAndGroup: '',
			rewardPoolType: '',
			startDate: state.productPartnerDetailForm.value.startDate,
			endDate: state.productPartnerDetailForm.value.endDate,
			point: '',
			image: state.productPartnerDetailForm.value.image,
			imageFileName: state.productPartnerDetailForm.value.imageFileName,
			createdBy: state.productPartnerDetailForm.value.createdBy,
			createdDate: state.productPartnerDetailForm.value.createdDate,
			modifiedBy: state.productPartnerDetailForm.value.modifiedBy,
			modifiedDate: state.productPartnerDetailForm.value.modifiedDate
		})),
		rewardList: (payload || []),
		rewardPoolAndGroupList: (payload || []).map(i => ({ key: `${i.type}_${i.id}`, value: `${i.code} - ${i.name}` })) as KeyValue<string, string>[]
	})),
	on(ProductPartnerAction.SetRewardPool, state => ({
		...state,
		productPartnerDetailForm: ProductPartnerState.validateProductPartnerDetailForm(state)(setValue(state.productPartnerDetailForm, {
			code: state.productPartnerDetailForm.value.code,
			name: state.productPartnerDetailForm.value.name,
			description: state.productPartnerDetailForm.value.description,
			status: state.productPartnerDetailForm.value.status,
			redeemPartnerId: state.productPartnerDetailForm.value.redeemPartnerId,
			redeemPartner: state.productPartnerDetailForm.value.redeemPartner,
			rewardPoolAndGroup: state.productPartnerDetailForm.value.rewardPoolAndGroup,
			rewardPoolId: state.productPartnerDetailForm.value.rewardPoolAndGroup.split('_')[1],
			rewardPoolType: state.productPartnerDetailForm.value.rewardPoolAndGroup.split('_')[0],
			startDate: state.productPartnerDetailForm.value.startDate,
			endDate: state.productPartnerDetailForm.value.endDate,
			point: state.productPartnerDetailForm.value.point,
			image: state.productPartnerDetailForm.value.image,
			imageFileName: state.productPartnerDetailForm.value.imageFileName,
			createdBy: state.productPartnerDetailForm.value.createdBy,
			createdDate: state.productPartnerDetailForm.value.createdDate,
			modifiedBy: state.productPartnerDetailForm.value.modifiedBy,
			modifiedDate: state.productPartnerDetailForm.value.modifiedDate
		})),
	})),
	on(ProductPartnerAction.RewardPoolAndGroupList, state => ({
		...state,
		isLoading: true
	})),
	on(ProductPartnerAction.RewardPoolAndGroupListSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		rewardList: (payload || []),
		rewardPoolAndGroupList: (payload || []).map(i => ({ key: `${i.type}_${i.id}`, value: `${i.code} - ${i.name}` })) as KeyValue<string, string>[]
	})),
	on(ProductPartnerAction.RedeemParnterList, state => ({
		...state,
		isLoading: true
	})),
	on(ProductPartnerAction.RedeemParnterListSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		redeemPartnerList: payload.redeemPartners
	})),
	on(ProductPartnerAction.List, state => ({
		...state,
		isLoading: true
	})),
	on(ProductPartnerAction.ListSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		productPartnerListResponse: payload
	})),
	on(ProductPartnerAction.View, state => ({
		...state,
		isLoading: true,
		productPartnerDetailForm: setValue(state.productPartnerDetailForm, ProductPartnerState.initialProductPartnerDetailFormValue)
	})),
	on(ProductPartnerAction.ViewSuccess, (state, { payload }) => {
		const productPartnerDetailFormView: ProductPartnerState.ProductPartnerDetailForm = {
			code: payload.code,
			name: payload.name,
			description: payload.description,
			status: payload.status,
			redeemPartnerId: payload.redeemPartnerId,
			redeemPartner: payload.redeemPartner,
			rewardPoolAndGroup: payload.rewardPoolType + '_' + payload.rewardPoolId,
			rewardPoolId: payload.rewardPoolId.toString(),
			rewardPoolType: payload.rewardPoolType,
			startDate: payload.startDate,
			endDate: payload.endDate,
			point: payload.point.toString(),
			image: payload.image,
			imageFileName: payload.imageFileName,
			createdBy: payload.createdBy,
			createdDate: payload.createdDate,
			modifiedBy: payload.modifiedBy,
			modifiedDate: payload.modifiedDate
		}
		return ({
			...state,
			isLoading: false,
			id: payload.id,
			productPartnerDetailForm: ProductPartnerState.validateProductPartnerDetailForm(state)(setValue(state.productPartnerDetailForm, productPartnerDetailFormView)),
			productPartnerDetailFormView,
			redeemPartner: payload.redeemPartner,
			redeemPartnerId: payload.redeemPartnerId
		})
	}),
	on(ProductPartnerAction.UploadProductPartnerImage, state => ({
		...state,
		isLoading: true
	})),
	on(ProductPartnerAction.UploadProductPartnerImageSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		productPartnerDetailForm: ProductPartnerState.validateProductPartnerDetailForm(state)(setValue(state.productPartnerDetailForm, {
			code: state.productPartnerDetailForm.value.code,
			name: state.productPartnerDetailForm.value.name,
			description: state.productPartnerDetailForm.value.description,
			status: state.productPartnerDetailForm.value.status,
			rewardPoolId: state.productPartnerDetailForm.value.rewardPoolId,
			redeemPartnerId: state.productPartnerDetailForm.value.redeemPartnerId,
			redeemPartner: state.productPartnerDetailForm.value.redeemPartner,
			startDate: state.productPartnerDetailForm.value.startDate,
			endDate: state.productPartnerDetailForm.value.endDate,
			point: state.productPartnerDetailForm.value.point,
			image: payload.image,
			imageFileName: payload.imageFileName,
			rewardPoolAndGroup: state.productPartnerDetailForm.value.rewardPoolAndGroup,
			rewardPoolType: state.productPartnerDetailForm.value.rewardPoolType,
			createdBy: state.productPartnerDetailForm.value.createdBy,
			createdDate: state.productPartnerDetailForm.value.createdDate,
			modifiedBy: state.productPartnerDetailForm.value.modifiedBy,
			modifiedDate: state.productPartnerDetailForm.value.modifiedDate
		}))
	})),
	on(ProductPartnerAction.Create, state => ({
		...state,
		isLoading: true
	})),
	on(ProductPartnerAction.CreateSuccess, (state, { payload }) => ({
		...ProductPartnerState.initialState
	})),
	on(ProductPartnerAction.Update, state => ({
		...state,
		isLoading: true
	})),
	on(ProductPartnerAction.UpdateSuccess, (state, { payload }) => ({
		...state
	})),
	on(ProductPartnerAction.Delete, state => ({
		...state,
		isLoading: true
	})),
	on(ProductPartnerAction.DeleteSuccess, () => ProductPartnerState.initialState),
	on(ProductPartnerAction.DuplicateDialog, state => ({
		...state,
		productPartnerDuplicateForm: reset(setValue(state.productPartnerDuplicateForm, ProductPartnerState.initialProductPartnerDuplicateFormValue)),
		isDuplicateDialog: false
	})),
	on(ProductPartnerAction.Duplicate, state => ({
		...state,
		isDuplicateLoading: true
	})),
	on(ProductPartnerAction.DuplicateSuccess, state => ({
		...state,
		isDuplicateLoading: false,
		isDuplicateDialog: true
	})),
)

export function Reducer(state: ProductPartnerState.State = ProductPartnerState.initialState, action: Action) {
	const productPartnerDetailForm = ProductPartnerState.validateProductPartnerDetailForm(state)(formGroupReducer(state.productPartnerDetailForm, action))
	if (productPartnerDetailForm !== state.productPartnerDetailForm) {
		state = { ...state, productPartnerDetailForm }
	}

	const productPartnerDuplicateForm = ProductPartnerState.validateProductPartnerDuplicateForm(formGroupReducer(state.productPartnerDuplicateForm, action))
	if (productPartnerDuplicateForm !== state.productPartnerDuplicateForm) {
		state = { ...state, productPartnerDuplicateForm }
	}

	return reducer(state, action)
}

function validateForm(form: any) {
	form = markAsTouched(form)

	return ProductPartnerState.validateProductPartnerDetailForm(form)
}

function actionHandler(state: ProductPartnerState.State, action: SetValueAction<unknown> | NgrxForms.MarkAsTouchedAction) {
	const formControls = state.productPartnerDetailForm

	let form = formGroupReducer(formControls, action)

	if (action.type === 'ngrx/forms/SET_VALUE') {
		form = formGroupReducer(formControls, new SetValueAction(action.controlId, action.value))
	}

	state = {
		...state,
		productPartnerDetailForm: setValue(state.productPartnerDetailForm, {
			...state.productPartnerDetailForm.value,
			endDate: Util.ISOStringFormat(form.value.endDate),
			startDate: Util.ISOStringFormat(form.value.startDate),
		})
	}

	form = validateForm(state)(form)

	state = {
		...state,
		productPartnerDetailForm: setValue(state.productPartnerDetailForm, {
			...state.productPartnerDetailForm.value,
			endDate: Util.ISOStringFormat(form.value.endDate),
			startDate: Util.ISOStringFormat(form.value.startDate),
		})
	}
	return state
}
