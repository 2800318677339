import { Component, Inject, OnDestroy } from '@angular/core'
import { Observable, Subscription } from 'rxjs'
import { FormGroupState } from 'ngrx-forms'
import { AssignedWaiverDuplicateForm } from '../../../../store/redemption-settings/waiver-management'
import { Store } from '@ngrx/store'
import * as AppStore from '../../../../store'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material'
import * as WaiverManagementSelector from 'src/app/store/redemption-settings/waiver-management/waiver-management.selectors'
import * as WaiverManagementAction from 'src/app/store/redemption-settings/waiver-management/waiver-management.actions'

@Component({
	selector: 'app-assigned-waiver-duplicate',
	templateUrl: './assigned-waiver-duplicate.component.html',
	styleUrls: ['./assigned-waiver-duplicate.component.scss']
})
export class AssignedWaiverDuplicateComponent implements OnDestroy {

	isLoading = false
	formState$: Observable<FormGroupState<AssignedWaiverDuplicateForm>>

	subs: Subscription

	constructor(
		private store: Store<AppStore.State>,
		private dialogRef: MatDialogRef<AssignedWaiverDuplicateComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		this.subs = this.store.select(WaiverManagementSelector.getWaiverManagement).subscribe(({isDuplicateLoading, isDuplicateDialog}) => {
			this.isLoading = isDuplicateLoading
			if (isDuplicateDialog) {
				this.cancel()
			}
		})

		this.formState$ = this.store.select(({waiverManagement}) => waiverManagement.assignedWaiverDuplicateForm)
	}

	ngOnDestroy(): void {
		this.subs.unsubscribe()
	}

	cancel() {
		this.dialogRef.close()
	}

	submit() {
		this.store.dispatch(WaiverManagementAction.DuplicateWaiverDetail())
	}
}
