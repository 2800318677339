import { Action, createReducer, on } from '@ngrx/store'
import { formGroupReducer, reset, setValue } from 'ngrx-forms'
import * as GlRedemptionAction from './gl-redemption.actions'
import * as GlRedemptionState from './gl-redemption.state'

export const reducer = createReducer(
	GlRedemptionState.initialState,
	on(GlRedemptionAction.InitialState, () => GlRedemptionState.initialState),
	on(GlRedemptionAction.Reset, state => ({
		...state
	})),
	on(GlRedemptionAction.SetId, (state, { id }) => ({
		...state,
		id
	})),
	on(GlRedemptionAction.GoCreate, state => ({
		...state,
		glRedemptionDetailForm: reset(setValue(state.glRedemptionDetailForm, GlRedemptionState.initialGlRedemptionDetailFormValue))
	})),
	on(GlRedemptionAction.RestError, state => ({
		...state,
		isLoading: false,
		isDuplicateLoading: false,
		isDuplicateDialog: false
	})),
	on(GlRedemptionAction.List, state => ({
		...state,
		isLoading: true,
	})),
	on(GlRedemptionAction.ListSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		glRedemptionListResponse: payload
	})),
	on(GlRedemptionAction.View, state => ({
		...state,
		isLoading: true,
		glRedemptionDetailForm: setValue(state.glRedemptionDetailForm, GlRedemptionState.initialGlRedemptionDetailFormValue),
		allowDelete: true
	})),
	on(GlRedemptionAction.ViewSuccess, (state, { payload }) => {
		const glRedemptionDetailFormView = {
			code: payload.code,
			name: payload.name,
			creditGlCode: payload.creditGlCode,
			creditBranch: payload.creditBranch,
			creditBranchCostCenter: payload.creditBranchCostCenter,
			creditEntityKey: payload.creditEntityKey,
			creditEntityValue: payload.creditEntityValue,
			createdBy: payload.createdBy,
			createdDate: payload.createdDate,
			modifiedBy: payload.modifiedBy,
			modifiedDate: payload.modifiedDate
		}
		return ({
			...state,
			isLoading: false,
			glRedemptionDetailForm: GlRedemptionState.validateGlRedemptionDetailForm(setValue(state.glRedemptionDetailForm, glRedemptionDetailFormView as GlRedemptionState.GlRedemptionDetailForm)),
			allowDelete: payload.allowDelete,
			glRedemptionDetailFormView
		})
	}),
	on(GlRedemptionAction.Create, state => ({
		...state,
		isLoading: true
	})),
	on(GlRedemptionAction.CreateSuccess, (state, { payload }) => ({
		...GlRedemptionState.initialState
	})),
	on(GlRedemptionAction.Update, state => ({
		...state,
		isLoading: true
	})),
	on(GlRedemptionAction.UpdateSuccess, (state, { payload }) => ({
		...state
	})),
	on(GlRedemptionAction.Delete, state => ({
		...state,
		isLoading: true
	})),
	on(GlRedemptionAction.DeleteSuccess, () => GlRedemptionState.initialState),
	on(GlRedemptionAction.GetGlRedemptionData, state => ({
		...state,
		isLoading: true
	})),
	on(GlRedemptionAction.GetGlRedemptionDataSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		entityTypeList: payload.entityTypeList
	})),
	on(GlRedemptionAction.DuplicateDialog, state => ({
		...state,
		glRedemptionDuplicateForm: reset(setValue(state.glRedemptionDuplicateForm, GlRedemptionState.initialGlRedemptionDuplicateFormValue)),
		isDuplicateDialog: false
	})),
	on(GlRedemptionAction.Duplicate, state => ({
		...state,
		isDuplicateLoading: true
	})),
	on(GlRedemptionAction.DuplicateSuccess, state => ({
		...state,
		isDuplicateLoading: false,
		isDuplicateDialog: true
	})),
)

export function Reducer(state: GlRedemptionState.State = GlRedemptionState.initialState, action: Action) {
	const glRedemptionDetailForm = GlRedemptionState.validateGlRedemptionDetailForm(formGroupReducer(state.glRedemptionDetailForm, action))
	const glRedemptionDuplicateForm = GlRedemptionState.validateGlRedemptionDuplicateForm(formGroupReducer(state.glRedemptionDuplicateForm, action))

	if (glRedemptionDetailForm !== state.glRedemptionDetailForm) {
		state = { ...state, glRedemptionDetailForm }
	}

	if (glRedemptionDuplicateForm !== state.glRedemptionDuplicateForm) {
		state = { ...state, glRedemptionDuplicateForm }
	}

	return reducer(state, action)
}
