import { Action, createReducer, on } from '@ngrx/store'
import * as TasklistAction from './tasklist.actions'
import * as TasklistState from './tasklist.state'

export const reducer = createReducer(
	TasklistState.initialState,
	on(TasklistAction.InitialState, () => TasklistState.initialState),
	on(TasklistAction.List, (state) => ({
		...state,
		isLoading: true
	})),
	on(TasklistAction.ListSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		allTaskListResponse: payload.all,
		pendingTaskListResponse: payload.pending,
		approvedTaskListResponse: payload.approved,
		rejectedTaskListResponse: payload.rejected
	})),
	on(TasklistAction.ListByStatus, (state) => ({
		...state,
		isLoading: true
	})),
	on(TasklistAction.ListByStatusSuccess, (state, { status, payload }) => ({
		...state,
		isLoading: false,
		allTaskListResponse: status === 'ALL' ? payload : state.allTaskListResponse,
		pendingTaskListResponse: status === 'PENDING' ? payload : state.pendingTaskListResponse,
		approvedTaskListResponse: status === 'APPROVED' ? payload : state.approvedTaskListResponse,
		rejectedTaskListResponse: status === 'REJECTED' ? payload : state.rejectedTaskListResponse
	}))
)

export function Reducer(state: TasklistState.State = TasklistState.initialState, action: Action) {
	return reducer(state, action)
}
