import { Component, Inject, OnDestroy, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef, MatSelectChange } from '@angular/material'
import { Store } from '@ngrx/store'
import * as AppStore from 'src/app/store'
import { Observable, Subscription } from 'rxjs'
import * as CustomerManagementSelector from 'src/app/store/customer-management/customer-management.selectors'
import { CustomerPartnerRedemptionListData } from 'src/app/models/customer-management/customer-partner-redemption'
import { PartnerRedemptionForm } from 'src/app/store/customer-management'
import { FormGroupState, SetValueAction } from 'ngrx-forms'
import * as CustomerManagementAction from 'src/app/store/customer-management/customer-management.actions'
import { KeyValue } from '@angular/common'

@Component({
	selector: 'app-customer-management-partner-redemption',
	templateUrl: './customer-management-partner-redemption.component.html',
	styleUrls: ['./customer-management-partner-redemption.component.scss']
})
export class CustomerManagementPartnerRedemptionComponent implements OnInit, OnDestroy {

	customerpartnerRedemption: CustomerPartnerRedemptionListData[]
	partnerRedemptionKeyValueList: KeyValue<string, string>[]
	formState$: Observable<FormGroupState<PartnerRedemptionForm>>
	subs: Subscription
	isLoading = false
	conversionRate: number
	point: number = undefined
	value: number = undefined
	id: number
	type: string
	rewardId: number
	partnerRedemption: CustomerPartnerRedemptionListData
	partnerCode: string
	valueFormId: string
	pointFormValue: number
	rounding: boolean
	roundingValue: string
	numberDecimal: string
	numberDecimalValue: string

	constructor(
		private store: Store<AppStore.State>,
		private dialogRef: MatDialogRef<CustomerManagementPartnerRedemptionComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any) {
		this.subs = this.store.select(CustomerManagementSelector.getCustomerManagement).subscribe(x => {
			this.isLoading = x.isLoading
			this.customerpartnerRedemption = x.partnerRedemptionList
			this.partnerRedemptionKeyValueList = x.partnerRedemptionKeyValueList
			this.partnerRedemption = x.partnerRedemption && x.partnerRedemption
			this.valueFormId = x.partnerRedemptionForm.controls.values.id
			this.pointFormValue = x.partnerRedemptionForm.controls.points.value
		})
		this.formState$ = this.store.select(({ customerManagement }) => customerManagement.partnerRedemptionForm)
	}

	ngOnDestroy() {
		this.partnerCode = null
		this.subs.unsubscribe()
	}

	ngOnInit() {
		this.store.dispatch(CustomerManagementAction.ResetPointAndValueAndNameAndAirmileId())
	}

	selected(event: MatSelectChange) {
		this.id = event.value
		this.point = undefined
		this.value = undefined
		this.customerpartnerRedemption.forEach(e => {
			if (e.id === Number(this.id)) {
				this.point = e.point
				this.value = e.value
				this.type = e.type
				this.rewardId = e.id
				this.rounding = e.rounding
				this.roundingValue = e.roundingValue
				this.numberDecimal = e.numberDecimal
				this.numberDecimalValue = e.numberDecimalValue
				this.conversionRate = this.value / this.point
				this.store.dispatch(CustomerManagementAction.SetCustomerPartnerRdemptionListData({ customerPartnerRedemptionListData: e }))
				this.store.dispatch(CustomerManagementAction.SetPointResetValueAndAirmileId({ point: this.point }))
			}
		})
		this.partnerCode = this.partnerRedemption.partnerCode
	}

	cancel() {
		this.partnerCode = null
		this.dialogRef.close()
		this.subs.unsubscribe()
	}

	create() {
		this.dialogRef.close()
		this.store.dispatch(CustomerManagementAction.PartnerRedemptionConfirmationDialog())
	}

	calculation() {
		let unit = '1'
		for (let i = 0; i < Number(this.numberDecimalValue); i++) {
			unit = unit.concat('0')
		}
		this.value = this.pointFormValue * this.conversionRate

		if (!this.rounding) {
			this.value = this.toFixedWithoutRounding(this.pointFormValue * this.conversionRate, 2)
			this.value = Number((this.pointFormValue * this.conversionRate).toFixed(2))
			this.store.dispatch(new SetValueAction(this.valueFormId, this.value))
		} else {
			if (this.numberDecimal === 'DIGIT') {
				unit = (Number(unit) / 10).toString()
				if (this.roundingValue === 'ROUNDING_UP') {
					// digit and rounding up
					if (this.value.toString().split('.')[0].length === Number(this.numberDecimalValue) - 1) {
						this.value = Math.ceil(this.toFixedWithoutRounding((this.value / Number(unit)), 1)) * Number(unit)
					} else if (this.value.toString().split('.')[0].length < Number(this.numberDecimalValue) - 1) {
						this.value = this.value
					} else {
						this.value = Math.ceil((this.value / Number(unit))) * Number(unit)
					}
					this.store.dispatch(new SetValueAction(this.valueFormId, this.value))
				} else {
					// digit and rounding down
					if (this.value.toString().split('.')[0].length === Number(this.numberDecimalValue)) {
						this.value = Math.floor(this.toFixedWithoutRounding((this.value / Number(unit)), 1)) * Number(unit)
					} else if (this.value.toString().split('.')[0].length < Number(this.numberDecimalValue)) {
						this.value = this.value
					} else {
						this.value = Math.floor(this.value / Number(unit)) * Number(unit)
					}
					this.store.dispatch(new SetValueAction(this.valueFormId, this.value))
				}
			} else {
				if (this.roundingValue === 'ROUNDING_UP') {
					// decimal and rounding up
					this.value = Math.ceil(this.value * Number(unit)) / Number(unit)
					this.store.dispatch(new SetValueAction(this.valueFormId, this.value))
				} else {
					// decimal and rounding down
					this.value = this.toFixedWithoutRounding(this.value, this.numberDecimalValue)
					this.store.dispatch(new SetValueAction(this.valueFormId, this.value))
				}
			}
		}

		this.store.dispatch(CustomerManagementAction.SetPointAndValueAndTypeAndRewardId({ point: this.point, value: this.value, redemptionType: this.type, rewardId: this.rewardId }))
	}

	toFixedWithoutRounding(value, fixed) {
		const re = new RegExp('^-?\\d+(?:\.\\d{0,' + (fixed || -1) + '})?')
		return value.toString().match(re)[0]
	}

	keyboardValidation(keyword: string): boolean {
		return keyword.charCodeAt(0) >= 48 && keyword.charCodeAt(0) <= 57
	}
}
