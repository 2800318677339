import { Injectable } from '@angular/core'
import { MatDialog, MatDialogRef } from '@angular/material'
import { Router } from '@angular/router'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Action, Store } from '@ngrx/store'
import { Observable, of } from 'rxjs'
import { catchError, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators'
import { MessageDialogComponent } from 'src/app/components/common/dialog/message-dialog/message-dialog.component'
import { getDialogConfig } from 'src/app/models/common/dialog'
import { Response } from 'src/app/models/common/http'
import { RewardPoolGroupListResponse } from 'src/app/models/param-settings/reward-pool-group/reward-pool-group-list'
import { RewardPoolGroupViewResponse } from 'src/app/models/param-settings/reward-pool-group/reward-pool-group-view'
import { ParameterSettingsService } from 'src/app/services/param-settings/parameter-settings.service'
import * as AppStore from 'src/app/store/'
import * as CommonAction from '../../common/common/common.actions'
import * as RewardPoolGroupAction from './reward-pool-group.actions'
import { RewardPoolGroupCreateRequest } from 'src/app/models/param-settings/reward-pool-group/reward-pool-group-create'
import { RewardPoolGroupUpdateRequest, RewardPoolGroupUpdateRedemptionSequenceRequest } from 'src/app/models/param-settings/reward-pool-group/reward-pool-group-update'
import { RewardPoolListResponse } from 'src/app/models/param-settings/reward-pool/reward-pool-list'
import { SelectDialogComponent } from 'src/app/components/common/dialog/select-dialog/select-dialog.component'
import { RewardPoolGroupGlRedemptionResponse } from 'src/app/models/param-settings/reward-pool-group/reward-pool-group'
import { POLICY } from 'src/app/models/common/constant'
import { GlRedemptionListResponse } from 'src/app/models/param-settings/gl-redemption/gl-redemption-list'
import { HttpParams } from '@angular/common/http'
import { ErrorMessage } from 'src/app/models/access-management/group/group-view'
import { GlobalMessageDialogComponent } from 'src/app/components/common/dialog/global-message-dialog/global-message-dialog.component'

@Injectable()
export class Effects {

	constructor(
		private action$: Actions,
		private router: Router,
		private dialog: MatDialog,
		private parameterSettingsService: ParameterSettingsService,
		private store: Store<AppStore.State>
	) { }

	InitialState$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RewardPoolGroupAction.InitialState),
			tap(() => {
				this.router.navigate(['parameter-settings/reward-pool'])
			})
		), { dispatch: false }
	)

	GoList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RewardPoolGroupAction.GoList),
			tap(() => {
				this.router.navigate(['parameter-settings/reward-pool'])
			})
		), { dispatch: false }
	)

	GoView$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RewardPoolGroupAction.GoView),
			tap(() => {
				this.router.navigate(['parameter-settings/reward-pool-group/view'])
			})
		), { dispatch: false }
	)

	GoCreate$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RewardPoolGroupAction.GoCreate),
			tap(() => {
				this.router.navigate(['parameter-settings/reward-pool-group/create'])
			})
		), { dispatch: false }
	)

	GoUpdate$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RewardPoolGroupAction.GoUpdate),
			tap(() => {
				this.router.navigate(['parameter-settings/reward-pool-group/update'])
			})
		), { dispatch: false }
	)

	GoUpdateGlRedemption$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RewardPoolGroupAction.GoUpdateGlRedemption),
			tap(() => {
				this.router.navigate(['parameter-settings/reward-pool-group/gl-redemption'])
			})
		), { dispatch: false }
	)

	GoUpdateGlRedemptionView$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RewardPoolGroupAction.GoUpdateGlRedemptionView),
			tap(() => {
				this.router.navigate(['parameter-settings/reward-pool-group/gl-redemption/view'])
			})
		), { dispatch: false }
	)

	GoBackDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RewardPoolGroupAction.GoBackDialog),
			mergeMap(({ action }) => {
				let dialogRef: MatDialogRef<MessageDialogComponent, any>
				if (action === 'EDIT' || action === 'EDIT_TO_GL_VIEW') {
					dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'REWARD_POOL.GO_BACK_MSG', payload: action }))
				} else {
					dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.BACK', payload: action }))
				}
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					if (result.payload === 'LIST') {
						return RewardPoolGroupAction.GoList()
					}
					if (result.payload === 'VIEW' || result.payload === 'EDIT') {
						return RewardPoolGroupAction.GoView()
					}
					if (result.payload === 'EDIT_TO_GL_VIEW') {
						return RewardPoolGroupAction.GoUpdateGlRedemptionView()
					}
				} else {
					return RewardPoolGroupAction.closeDialog()
				}
			})
		)
	)

	RestError$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RewardPoolGroupAction.RestError),
			map(({ message, manualThrow }) => {
				return CommonAction.RestError({ message, manualThrow })
			})
		)
	)

	List$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RewardPoolGroupAction.List),
			withLatestFrom(this.store.select(state => state.rewardPoolGroup)),
			mergeMap(([x, rewardPoolGroup]) => {

				return this.parameterSettingsService.getRewardPoolGroupList().pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: RewardPoolGroupListResponse = response.payload
					if (success) {
						return RewardPoolGroupAction.ListSuccess({ payload })
					} else { return RewardPoolGroupAction.RestError(response as any) }
				}), catchError(this.catcher))
			})
		)
	)

	View$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RewardPoolGroupAction.View),
			withLatestFrom(this.store.select(state => state.rewardPoolGroup)),
			mergeMap(([, rewardPoolGroup]) => this.parameterSettingsService.viewRewardPoolGroup({ id: `${rewardPoolGroup.id}` })
				.pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: RewardPoolGroupViewResponse = response.payload
					if (success) {
						return RewardPoolGroupAction.ViewSuccess({ payload })
					} else { return RewardPoolGroupAction.RestError(response as any) }
				}), catchError(this.catcher)
				)
			)
		)
	)

	CreateDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RewardPoolGroupAction.CreateDialog),
			mergeMap(() => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.CREATE_REWARD_POOL_GROUP' }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return RewardPoolGroupAction.Create()
				} else {
					return RewardPoolGroupAction.closeDialog()
				}
			})
		)
	)

	Create$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RewardPoolGroupAction.Create),
			withLatestFrom(this.store.select(state => state.rewardPoolGroup)),
			mergeMap(([, rewardPoolGroup]) => {
				const form = rewardPoolGroup.rewardPoolGroupDetailForm.controls
				return this.parameterSettingsService.createRewardPoolGroup({
					code: form.code.value,
					name: form.name.value,
				} as RewardPoolGroupCreateRequest).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: string = response.payload
					if (success) {
						return RewardPoolGroupAction.CreateSuccess({ payload })
					} else { return RewardPoolGroupAction.RestError(response as any) }
				}), catchError(this.catcher)
				)
			})
		)
	)

	CreateSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RewardPoolGroupAction.CreateSuccess),
			mergeMap(({ payload }) => {
				return [RewardPoolGroupAction.GoList(), CommonAction.RestError({ message: payload })]
			})
		)
	)

	UpdateDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RewardPoolGroupAction.UpdateDialog),
			mergeMap(() => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.UPDATE' }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return RewardPoolGroupAction.Update()
				} else {
					return RewardPoolGroupAction.closeDialog()
				}
			})
		)
	)

	Update$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RewardPoolGroupAction.Update),
			withLatestFrom(this.store.select(state => state.rewardPoolGroup)),
			mergeMap(([, rewardPoolGroup]) => {
				const form = rewardPoolGroup.rewardPoolGroupDetailForm.controls
				return this.parameterSettingsService.updateRewardPoolGroup({
					id: Number(form.id.value),
					name: form.name.value
				} as RewardPoolGroupUpdateRequest).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: string = response.payload
					if (success) {
						return RewardPoolGroupAction.UpdateSuccess({ payload })
					} else { return RewardPoolGroupAction.RestError(response as any) }
				}), catchError(this.catcher)
				)
			})
		)
	)

	UpdateSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RewardPoolGroupAction.UpdateSuccess),
			mergeMap(({ payload }) => {
				return [RewardPoolGroupAction.GoView(), CommonAction.RestError({ message: payload })]
			})
		)
	)

	UpdateRedemptionSequence$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RewardPoolGroupAction.UpdateRedemptionSequence),
			withLatestFrom(this.store.select(state => state.rewardPoolGroup)),
			mergeMap(([x, rewardPoolGroup]) => {
				const param = x.payload
				const form = rewardPoolGroup.rewardPoolGroupDetailForm.controls
				return this.parameterSettingsService.updateRewardPoolGroupRedemptionSequence({
					id: Number(form.id.value),
					redemptionSequence: param.map(o => o.code)
				} as RewardPoolGroupUpdateRedemptionSequenceRequest).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: string = response.payload
					if (success) {
						return RewardPoolGroupAction.UpdateRedemptionSequenceSuccess({ payload })
					} else { return RewardPoolGroupAction.RestError(response as any) }
				}), catchError(this.catcher)
				)
			})
		)
	)

	UpdateRedemptionSequenceSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RewardPoolGroupAction.UpdateRedemptionSequenceSuccess),
			mergeMap(({ payload }) => {
				return [RewardPoolGroupAction.View(), CommonAction.RestError({ message: payload })]
			})
		)
	)

	DeleteDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RewardPoolGroupAction.DeleteDialog),
			mergeMap(() => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.DELETE_REWARD_POOL_GROUP' }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return RewardPoolGroupAction.Delete()
				} else {
					return RewardPoolGroupAction.closeDialog()
				}
			})
		)
	)

	RemoveRewardPoolDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RewardPoolGroupAction.RemoveRewardPoolDialog),
			mergeMap((params) => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.REMOVE_REWARD_POOL_FROM_REWARD_POOL_GROUP', payload: params.rewardPoolId  }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return RewardPoolGroupAction.RemoveRewardPoolFromRewardPoolGroup({
						rewardPoolId: result.payload
					})
				} else {
					return RewardPoolGroupAction.closeDialog()
				}
			})
		)
	)

	RemoveRewardPoolFromViewDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RewardPoolGroupAction.RemoveRewardPoolFromViewDialog),
			withLatestFrom(this.store.select(state => state.rewardPoolGroup)),
			mergeMap(([params, rewardPoolGroup]) => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.REMOVE_REWARD_POOL_FROM_REWARD_POOL_GROUP', payload: rewardPoolGroup.rewardPoolId }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return RewardPoolGroupAction.RemoveRewardPoolFromRewardPoolGroup({
						rewardPoolId: result.payload
					})
				} else {
					return RewardPoolGroupAction.closeDialog()
				}
			})
		)
	)

	RevertRewardPoolDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RewardPoolGroupAction.RevertRewardPoolDialog),
			mergeMap((params) => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.REVERT_REWARD_POOL_FROM_REWARD_POOL_GROUP', payload: params.rewardPoolId  }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return RewardPoolGroupAction.RevertRemovedRewardPoolFromRewardPoolGroup({
						rewardPoolId: result.payload
					})
				} else {
					return RewardPoolGroupAction.closeDialog()
				}
			})
		)
	)

	Delete$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RewardPoolGroupAction.Delete),
			withLatestFrom(this.store.select(state => state.rewardPoolGroup)),
			mergeMap(([, rewardPoolGroup]) => this.parameterSettingsService.deleteRewardPoolGroup({
				id: `${rewardPoolGroup.id}`
			}).pipe(map((response: Response) => {
				const success: boolean = response.success
				const payload: string = response.payload

				if (success) {
					return RewardPoolGroupAction.DeleteSuccess({ payload })
				} else { return RewardPoolGroupAction.RestError(response as any) }
			}), catchError(this.catcher)
			))
		)
	)

	DeleteSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RewardPoolGroupAction.DeleteSuccess),
			mergeMap(({ payload }) => {
				return [RewardPoolGroupAction.GoList(), CommonAction.RestError({ message: payload })]
			})
		)
	)

	AssignRewardPoolList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RewardPoolGroupAction.AssignRewardPoolList),
			mergeMap(() => {
				return this.parameterSettingsService.getAvailableRewardPoolList()
					.pipe(map((response: Response) => {
						const success: boolean = response.success
						const payload: RewardPoolListResponse = response.payload
						if (success) {
							this.store.dispatch(RewardPoolGroupAction.AssignRewardPoolListSuccess({ payload }))
							return RewardPoolGroupAction.AssignRewardPoolDialog()
						} else { return RewardPoolGroupAction.RestError(response as any) }
					}), catchError(this.catcher))
			})
		)
	)

	AssignRewardPoolDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RewardPoolGroupAction.AssignRewardPoolDialog),
			withLatestFrom(this.store.select(state => ({ rewardPoolGroup: state.rewardPoolGroup }))),
			mergeMap(([, { rewardPoolGroup }]) => {

				const rewardPools = rewardPoolGroup.rewardPoolAvailableListResponse.rewardPools
				const rewardPoolsInGroup = rewardPoolGroup.rewardPoolGroupDetailForm.value.rewardPools

				const payload = rewardPools.filter(x => {
					return !rewardPoolsInGroup.map(e => e.id).includes(x.id)
				}).map(i => ({
					keyValue: { key: `${i.id}`, value: `${i.code} - ${i.name}` },
					linkage: rewardPoolGroup.rewardPoolGroupDetailForm.value.redemptionLinkage,
					...i
				}))

				const dialogRef = this.dialog.open(SelectDialogComponent, getDialogConfig({
					title: 'REWARD_POOL.ASSIGN_REWARD_POOL',
					content: 'REWARD_POOL.REWARD_POOL',
					payload
				}))

				return dialogRef.afterClosed()
			}),
			mergeMap(result => {
				if (result && result.linkage) {
					return [RewardPoolGroupAction.GetRewardPoolGroupGlRedemptionMap({ rewardPoolId: result.id }), RewardPoolGroupAction.closeDialog()]
				} else if (result && !result.linkage) {
					return [RewardPoolGroupAction.AssignRewardPoolToRewardPoolGroup({ rewardPoolId: result.id })]
				} else {
					return [RewardPoolGroupAction.closeDialog()]
				}
			})
		)
	)

	AssignRewardPoolToRewardPoolGroup$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RewardPoolGroupAction.AssignRewardPoolToRewardPoolGroup),
			withLatestFrom(this.store.select(state => state.rewardPoolGroup)),
			mergeMap(([param, rewardPoolGroup]) => {
				const rewardPoolGroupId = rewardPoolGroup.rewardPoolGroupDetailForm.value.id
				const rewardPoolId = param.rewardPoolId
				return this.parameterSettingsService.assignRewardPoolToRewardPoolGroup({ rewardPoolGroupId, rewardPoolId }).pipe(
					map((response: Response) => {
						const success: boolean = response.success
						const payload: string = response.payload

						if (success) {
							return RewardPoolGroupAction.AssignRewardPoolToRewardPoolGroupSuccess({ message: payload })
						} else { return RewardPoolGroupAction.RestError(response as any) }
					}), catchError(this.catcher)
				)
			})
		)
	)


	AssignRewardPoolToRewardPoolGroupSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RewardPoolGroupAction.AssignRewardPoolToRewardPoolGroupSuccess),
			withLatestFrom(this.store.select(state => state.rewardPoolGroup)),
			mergeMap(([ message, rewardPoolGroup ]) => {
				if (rewardPoolGroup.rewardPoolGroupDetailForm.value.redemptionLinkage) {
					return [CommonAction.RestError(message), RewardPoolGroupAction.GetRewardPoolGlRedemptionMap({ isViewPage: false })]
				} else {
					return [CommonAction.RestError(message), RewardPoolGroupAction.View()]
				}
			})
		)
	)

	RemoveRewardPoolFromRewardPoolGroup$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RewardPoolGroupAction.RemoveRewardPoolFromRewardPoolGroup),
			withLatestFrom(this.store.select(state => state.rewardPoolGroup)),
			mergeMap(([param, rewardPoolGroup]) => {
				const rewardPoolGroupId = rewardPoolGroup.rewardPoolGroupDetailForm.value.id
				const rewardPoolId = param.rewardPoolId
				return this.parameterSettingsService.removeRewardPoolFromRewardPoolGroup({ rewardPoolGroupId, rewardPoolId }).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: string = response.payload
					if (success) {
						return RewardPoolGroupAction.RemoveRewardPoolFromRewardPoolGroupSuccess({ message: payload })
					} else { return RewardPoolGroupAction.RestError(response as any) }
				}), catchError(this.catcher))
			})
		)
	)

	RemoveRewardPoolFromRewardPoolGroupSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RewardPoolGroupAction.RemoveRewardPoolFromRewardPoolGroupSuccess),
			mergeMap(({ message }) => {
				return [CommonAction.RestError({ message }), RewardPoolGroupAction.View(), RewardPoolGroupAction.GoView()]
			})
		)
	)

	RevertRemovedRewardPoolFromRewardPoolGroup$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RewardPoolGroupAction.RevertRemovedRewardPoolFromRewardPoolGroup),
			withLatestFrom(this.store.select(state => state.rewardPoolGroup)),
			mergeMap(([param, rewardPoolGroup]) => {
				const rewardPoolGroupId = rewardPoolGroup.rewardPoolGroupDetailForm.value.id
				const rewardPoolId = param.rewardPoolId
				return this.parameterSettingsService.revertRewardPoolFromRewardPoolGroup({ rewardPoolGroupId, rewardPoolId }).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: string = response.payload
					if (success) {
						return RewardPoolGroupAction.RevertRemovedRewardPoolFromRewardPoolGroupSuccess({ message: payload })
					} else { return RewardPoolGroupAction.RestError(response as any) }
				}), catchError(this.catcher))
			})
		)
	)

	RevertRemovedRewardPoolFromRewardPoolGroupSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RewardPoolGroupAction.RevertRemovedRewardPoolFromRewardPoolGroupSuccess),
			mergeMap(({ message }) => {
				return [CommonAction.RestError({ message }), RewardPoolGroupAction.View()]
			})
		)
	)

	GetRewardPoolGlRedemptionMap$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RewardPoolGroupAction.GetRewardPoolGlRedemptionMap),
			withLatestFrom(this.store.select(state => state.rewardPoolGroup)),
			mergeMap(([param, rewardPoolGroup]) => {
				const rewardPoolGroupId = rewardPoolGroup.id
				const rewardPoolId = rewardPoolGroup.rewardPoolId
				return this.parameterSettingsService.getGlMapByRewardPoolGroupId({ rewardPoolGroupId, rewardPoolId }).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: RewardPoolGroupGlRedemptionResponse[] = response.payload
					if (success) {
						if (param.isViewPage) {
							return RewardPoolGroupAction.GetRewardPoolGlRedemptionMapViewSuccess({ payload })
						} else {
							return RewardPoolGroupAction.GetRewardPoolGlRedemptionMapSuccess({ payload })
						}
					} else { return RewardPoolGroupAction.RestError(response as any) }
				}), catchError(this.catcher))
			})
		)
	)

	GetRewardPoolGroupGlRedemptionMap$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RewardPoolGroupAction.GetRewardPoolGroupGlRedemptionMap),
			withLatestFrom(this.store.select(state => state.rewardPoolGroup)),
			mergeMap(([param, rewardPoolGroup]) => {
				const rewardPoolId = param.rewardPoolId
				const rewardPoolGroupId = rewardPoolGroup.id
				return this.parameterSettingsService.getGlMapByRewardPoolGroupId({ rewardPoolGroupId, rewardPoolId }).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: RewardPoolGroupGlRedemptionResponse[] = response.payload
					if (success) {
						this.store.dispatch(RewardPoolGroupAction.SetRewardPoolId({ rewardPoolId }))
						return RewardPoolGroupAction.GetRewardPoolGlRedemptionMapSuccess({ payload })
					} else { return RewardPoolGroupAction.RestError(response as any) }
				}), catchError(this.catcher))
			})
		)
	)

	GlRedemptionDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RewardPoolGroupAction.GlRedemptionDialog),
			withLatestFrom(this.store.select(state => state.rewardPoolGroup)),
			mergeMap(([params, rewardPoolGroup]) => {
				const dialogRef = this.dialog.open(SelectDialogComponent, getDialogConfig({
					title: 'REWARD_POOL.ASSIGN_GL_REDEMPTION',
					payload: rewardPoolGroup.glRedemptionList
						.map(({ id, code, name }) => (({
							keyValue: { key: id.toString(), value: `${code} - ${name}` }
						})))
				}))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return RewardPoolGroupAction.AssignGlRedemptionToRewardPool({ glRedemptionId: Number(result.keyValue.key) })
				} else {
					return RewardPoolGroupAction.closeDialog()
				}
			})
		)
	)

	GetRewardPoolGlRedemptionMapSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RewardPoolGroupAction.GetRewardPoolGlRedemptionMapSuccess),
			tap(() => {
				this.router.navigate(['parameter-settings/reward-pool-group/gl-redemption'])
			})
		), { dispatch: false }
	)

	GetRewardPoolGlRedemptionMapViewSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RewardPoolGroupAction.GetRewardPoolGlRedemptionMapViewSuccess),
			tap(() => {
				this.router.navigate(['parameter-settings/reward-pool-group/gl-redemption/view'])
			})
		), { dispatch: false }
	)

	GetGlRedemptionList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RewardPoolGroupAction.GetGlRedemptionList),
			withLatestFrom(this.store.select(state => state.rewardPoolGroup)),
			mergeMap(([x, ]) => {
				return this.parameterSettingsService.getRewardPoolGlRedemptionList({policy: x.payload as POLICY}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: GlRedemptionListResponse = response.payload
					if (success) {
						return RewardPoolGroupAction.GetGlRedemptionListSuccess({ payload })
					} else { return RewardPoolGroupAction.RestError(response as any) }
				}), catchError(this.catcher))
			})
		)
	)

	OpenPolicyResult$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RewardPoolGroupAction.AssignedGlRedemptionToRewardPool),
			withLatestFrom(this.store.select(state => state.rewardPoolGroup)),
			mergeMap(([, state]) => {
				const dialogRef = this.dialog.open(GlobalMessageDialogComponent, getDialogConfig({
					content: 'REWARD_POOL.ASSIGN_FUNCTION_CONFIRMATION_LIST',
					tableColumnName: 'REWARD_POOL.REDEMPTION_ITEM_NAME',
					table: state.glMapListResult != null ? state.glMapListResult : null,
					submitBtn: 'ACTION.OK',
					module: 'REWARD_POOL_GROUP',
					payload: state.isViewPage
				}))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (!result.payload) {
					return RewardPoolGroupAction.GoView()
				} else {
					return RewardPoolGroupAction.GoUpdateGlRedemptionView()
				}
			})
		)
	)

	AssignedGlRedemptionToRewardPool$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RewardPoolGroupAction.AssignedGlRedemptionToRewardPool),
			withLatestFrom(this.store.select(state => state.rewardPoolGroup)),
			mergeMap(([, rewardPoolGroup]) => {
				const waiverlist = rewardPoolGroup.rewardPoolGlMapWaiverDetailForm.length > 0 ? rewardPoolGroup.rewardPoolGlMapWaiverDetailForm.filter(obj =>
					obj.glRedemptionId !== rewardPoolGroup.rewardPoolGlMapList.find(k => k.redemptionItemId === obj.redemptionItemId).glRedemptionId
				).map(o => `${o.redemptionItemId}-${o.glRedemptionId}`) : []
				const cataloguelist = rewardPoolGroup.rewardPoolGlMapCatalogueDetailForm.length > 0 ? rewardPoolGroup.rewardPoolGlMapCatalogueDetailForm.filter(obj =>
					obj.glRedemptionId !== rewardPoolGroup.rewardPoolGlMapList.find(k => k.redemptionItemId === obj.redemptionItemId).glRedemptionId
				).map(o => `${o.redemptionItemId}-${o.glRedemptionId}`) : []
				const otslist = rewardPoolGroup.rewardPoolGlMapOtsDetailForm.length > 0 ? rewardPoolGroup.rewardPoolGlMapOtsDetailForm.filter(obj =>
					obj.glRedemptionId !== rewardPoolGroup.rewardPoolGlMapList.find(k => k.redemptionItemId === obj.redemptionItemId).glRedemptionId
				).map(o => `${o.redemptionItemId}-${o.glRedemptionId}`) : []
				const partnerlist = rewardPoolGroup.rewardPoolGlMapPartnerDetailForm.length > 0 ? rewardPoolGroup.rewardPoolGlMapPartnerDetailForm.filter(obj =>
					obj.glRedemptionId !== rewardPoolGroup.rewardPoolGlMapList.find(k => k.redemptionItemId === obj.redemptionItemId).glRedemptionId
				).map(o => `${o.redemptionItemId}-${o.glRedemptionId}`) : []
				const updatedList = waiverlist.concat(partnerlist).concat(otslist).concat(cataloguelist)
				if (rewardPoolGroup.rewardPoolAction === 'CREATE') {
					return [RewardPoolGroupAction.PostMultiAssignedGlRedemptionToRewardPool({ rewardPoolGlMapList: updatedList }), RewardPoolGroupAction.AssignRewardPoolToRewardPoolGroup({ rewardPoolId: rewardPoolGroup.rewardPoolId })]
				} else {
					return [RewardPoolGroupAction.PostMultiAssignedGlRedemptionToRewardPool({ rewardPoolGlMapList: updatedList })]
				}
			})
		)
	)

	PostMultiAssignedGlRedemptionToRewardPool$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RewardPoolGroupAction.PostMultiAssignedGlRedemptionToRewardPool),
			mergeMap((param) => {
				const service = (redemptionItemId, glRedemptionId) => RewardPoolGroupAction.PostAssignedGlRedemptionToRewardPool({ redemptionItemId, glRedemptionId })
				const box = param.rewardPoolGlMapList.map(o => service(o.split('-')[0], o.split('-')[1]))
				return [...box]
			})
		)
	)

	PostAssignedGlRedemptionToRewardPool$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(RewardPoolGroupAction.PostAssignedGlRedemptionToRewardPool),
			withLatestFrom(this.store.select(state => state.rewardPoolGroup)),
			mergeMap(([param, group]) => {
				return this.parameterSettingsService.assignGlMapByRewardPoolId({
					redemptionItemId: Number(param.redemptionItemId),
					rewardPoolId: Number(group.rewardPoolId),
					rewardPoolGroupId: Number(group.id),
					glRedemptionId: Number(param.glRedemptionId)
				}).pipe(
					map((response: Response) => {
						const success: boolean = response.success
						const payload: string = response.payload

						if (success) {
							return RewardPoolGroupAction.SuccessGlMapToRewardPool({ redemptionItemId: Number(payload) })
						} else {
							const errorMessage: ErrorMessage = response.error
							return RewardPoolGroupAction.FailedGlMapToRewardPool({ redemptionItemId: Number(payload), message: errorMessage.message })
						}
					})
				)
			})
		)
	)

	catcher = (error) => {
		return (of(RewardPoolGroupAction.RestError({ message: error.message, manualThrow: error.manualThrow })))
	}
}
