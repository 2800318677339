import { createAction, props } from '@ngrx/store'
import { ProductPartnerListResponse } from 'src/app/models/catalogue-management/product-partner/product-partner-list'
import { ProductPartnerViewResponse } from 'src/app/models/catalogue-management/product-partner/product-partner-view'
import { StatusListResponse } from 'src/app/models/catalogue-management/product-catalogue/product-catalogue-status-list'
import { RewardPoolAndGroup } from 'src/app/models/catalogue-management/product-catalogue/product-catalogue-reward-list'
import { POLICY } from 'src/app/models/common/constant'
import { RedeemPartnerListDataResponse } from 'src/app/models/catalogue-management/product-partner/product-partner-redeem-partner-list'
import { ProductPartnerImageUploadRequest, ProductPartnerImageUploadResponse } from 'src/app/models/catalogue-management/product-partner/product-partner-image-upload'

export const InitialState = createAction('[ProductPartner] InitialState')
export const SetId = createAction('[ProductPartner] SetId', props<{ id: number }>())
export const SetPartnerCode =  createAction('[ProductPartner] SetPartnerCode', props<{ redeemPartner: string }>())
export const SetRedeemPartnerId =  createAction('[ProductPartner] SetRedeemPartnerId', props<{ redeemPartnerId: string }>())

export const GoList = createAction('[ProductPartner] GoList')
export const GoView = createAction('[ProductPartner] GoView')
export const GoCreate = createAction('[ProductPartner] GoCreate')
export const GoUpdate = createAction('[ProductPartner] GoUpdate')

export const GoBackDialog = createAction('[ProductPartner] GoBackDialog', props<{ action: string }>())
export const CreateDialog = createAction('[ProductPartner] CreateDialog')
export const UpdateDialog = createAction('[ProductPartner] UpdateDialog')
export const DeleteDialog = createAction('[ProductPartner] DeleteDialog')
export const closeDialog = createAction('[ProductPartner] closeDialog')

export const RestError = createAction('[ProductPartner] RestError', props<{ message: string, manualThrow: boolean }>())

export const StatusList = createAction('[ProductPartner] StatusList')
export const StatusListSuccess = createAction('[ProductPartner] StatusListSuccess', props<{ payload: StatusListResponse }>())

export const RewardPoolAndGroupList = createAction('[ProductPartner] RewardPoolAndGroupList', props<{ payload: POLICY }>())
export const RewardPoolAndGroupListSuccess = createAction('[ProductPartner] RewardPoolAndGroupListSuccess', props<{ payload: RewardPoolAndGroup[] }>())

export const RedeemParnterList = createAction('[ProductPartner] RedeemParnterList', props<{ payload: POLICY }>())
export const RedeemParnterListSuccess = createAction('[ProductPartner] RedeemParnterListSuccess', props<{ payload: RedeemPartnerListDataResponse}>())

export const List = createAction('[ProductPartner] List')
export const ListSuccess = createAction('[ProductPartner] ListSuccess', props<{ payload: ProductPartnerListResponse }>())

export const View = createAction('[ProductPartner] View')
export const ViewSuccess = createAction('[ProductPartner] ViewSuccess', props<{ payload: ProductPartnerViewResponse }>())

export const Create = createAction('[ProductPartner] Create')
export const CreateSuccess = createAction('[ProductPartner] CreateSuccess', props<{ payload: string }>())

export const Update = createAction('[ProductPartner] Update')
export const UpdateSuccess = createAction('[ProductPartner] UpdateSuccess', props<{ payload: string }>())

export const Delete = createAction('[ProductPartner] Delete')
export const DeleteSuccess = createAction('[ProductPartner] DeleteSuccess', props<{ payload: string }>())

export const UploadProductPartnerImage = createAction('[ProductPartner] UploadProductPartnerImage', props<{ request: ProductPartnerImageUploadRequest }>())
export const UploadProductPartnerImageSuccess = createAction('[ProductPartner] UploadProductPartnerImageSuccess', props<{ payload: ProductPartnerImageUploadResponse }>())

export const OnChangeRedeemPartner = createAction('[ProductPartner] OnChangeRedeemPartner', props<{ payload: POLICY, redeemPartnerId: string, clearRewardPool: boolean }>())
export const OnChangeRedeemPartnerSuccess = createAction('[ProductPartner] OnChangeRedeemPartnerSuccess', props<{ payload: RewardPoolAndGroup[] }>())
export const SetRewardPool = createAction('[ProductPartner] SetRewardPool')

export const DuplicateDialog = createAction('[ProductPartner] DuplicateDialog')
export const Duplicate = createAction('[ProductPartner] Duplicate')
export const DuplicateSuccess = createAction('[ProductPartner] DuplicateSuccess', props<{ payload: string}>())
