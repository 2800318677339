import { createAction, props } from '@ngrx/store'
import { KeyValue } from '@angular/common'
import { TcGroupViewResponse } from 'src/app/models/param-settings/tc-group/tc-group-view'
import { TcGroupListResponse } from 'src/app/models/param-settings/tc-group/tc-group-list'
import { TcGroupPrefixAndList } from 'src/app/models/param-settings/tc-group/tc-group-create'

export const InitialState = createAction('[TcGroup] InitialState')
export const Reset = createAction('[TcGroup] Reset')
export const SetId = createAction('[TcGroup] SetId', props<{ code: string }>())

export const GoList = createAction('[TcGroup] GoList')
export const GoView = createAction('[TcGroup] GoView')
export const GoCreate = createAction('[TcGroup] GoCreate')
export const GoUpdate = createAction('[TcGroup] GoUpdate')

export const GoBackDialog = createAction('[TcGroup] GoBackDialog', props<{ action: string }>())
export const CreateDialog = createAction('[TcGroup] CreateDialog', props<{ content: string, payload: TcGroupPrefixAndList }>())
export const UpdateDialog = createAction('[TcGroup] UpdateDialog', props<{ payload: string[] }>())
export const DeleteDialog = createAction('[TcGroup] DeleteDialog')
export const CloseDialog = createAction('[TcGroup] CloseDialog')

export const RestError = createAction('[TcGroup] RestError', props<{ message: string, manualThrow: boolean }>())

export const List = createAction('[TcGroup] List')
export const ListSuccess = createAction('[TcGroup] ListSuccess', props<{ payload: TcGroupListResponse }>())

export const View = createAction('[TcGroup] View')
export const ViewSuccess = createAction('[TcGroup] ViewSuccess', props<{ payload: TcGroupViewResponse }>())

export const Create = createAction('[TcGroup] Create', props<{ payload: TcGroupPrefixAndList }>())
export const CreateSuccess = createAction('[TcGroup] CreateSuccess', props<{ payload: string }>())

export const Update = createAction('[TcGroup] Update', props<{ payload: string[] }>())
export const UpdateSuccess = createAction('[TcGroup] UpdateSuccess', props<{ payload: string }>())

export const Delete = createAction('[TcGroup] Delete')
export const DeleteSuccess = createAction('[TcGroup] DeleteSuccess', props<{ payload: string }>())

export const ResourceListCreate = createAction('[TcGroup] ResourceListCreate')
export const ResourceListCreateSuccess = createAction('[TcGroup] ResourceListCreateSuccess', props<{ payload: KeyValue<string, string>[] }>())

export const ResourceListUpdate = createAction('[TcGroup] ResourceListUpdate')
export const ResourceListUpdateSuccess = createAction('[TcGroup] ResourceListUpdateSuccess', props<{ payload: KeyValue<string, string>[] }>())

export const DuplicateDialog = createAction('[TcGroup] DuplicateDialog')
export const Duplicate = createAction('[TcGroup] Duplicate')
export const DuplicateSuccess = createAction('[TcGroup] DuplicateSuccess', props<{ payload: string }>())
