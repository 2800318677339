import { createFormGroupState, FormGroupState, updateGroup, validate, Boxed, box } from 'ngrx-forms'
import { maxLength, required } from 'ngrx-forms/validation'
import { ProductTypeGroupListResponse } from 'src/app/models/param-settings/product-type-group/product-type-group-list'
import { CardProduct } from 'src/app/models/cardProduct/cardProduct'
import { FieldValidator } from 'src/app/models/util/field.validator'
import { KeyValue } from '@angular/common'

export interface State {
	isLoading: boolean
	code?: string
	productTypeGroupDuplicateForm: FormGroupState<ProductTypeGroupDuplicateForm>
	productTypeGroupDetailForm: FormGroupState<ProductTypeGroupDetailForm>
	productTypeGroupListResponse: ProductTypeGroupListResponse
	productTypes: CardProduct[]
	resourceList: KeyValue<string, string>[]
	allowDelete: boolean
	productTypeGroupDetailFormView: ProductTypeGroupDetailForm
	isDuplicateDialog: boolean
	isDuplicateLoading: boolean
}

export interface ProductTypeGroupDuplicateForm {
	code: string,
	name: string
}

export const initialProductTypeGroupDuplicateFormValue: ProductTypeGroupDuplicateForm = {
	code: '',
	name: ''
}

export const initialProductTypeGroupDuplicateForm = createFormGroupState('productTypeGroupDuplicateForm', initialProductTypeGroupDuplicateFormValue)

export const validateProductTypeGroupDuplicateForm = updateGroup<ProductTypeGroupDuplicateForm>({
	code: validate([required, FieldValidator.alphaNumericWithoutSpaces()]),
	name: validate([required, maxLength(40), FieldValidator.checkValidCharacters()])
})

export interface ProductTypeGroupDetailForm {
	code: string
	name: string
	resourceCode: string
	resourceName: string
	description: string
	productTypeList: CardProduct[]
	productTypes: Boxed<string[]>
	createdBy: string
	createdDate: string
	modifiedBy: string
	modifiedDate: string
}

export const initialProductTypeGroupDetailFormValue: ProductTypeGroupDetailForm = {
	code: '',
	name: '',
	resourceCode: '',
	resourceName: '',
	description: '',
	productTypeList: [],
	productTypes: box([]),
	createdBy: '',
	createdDate: '',
	modifiedBy: '',
	modifiedDate: ''
}

export const initialProductTypeGroupDetailForm = createFormGroupState('productTypeGroupDetailForm', initialProductTypeGroupDetailFormValue)

export const validateProductTypeGroupDetailForm = updateGroup<ProductTypeGroupDetailForm>({
	code: validate([required, FieldValidator.alphaNumericWithoutSpaces()]),
	name: validate([required, maxLength(40), FieldValidator.checkValidCharacters()]),
	resourceCode: validate([required, maxLength(10)]),
	description: validate([maxLength(100), FieldValidator.checkValidCharacters()]),
	productTypes: validate([required])
})

export const initialState: State = {
	isLoading: false,
	productTypeGroupDetailForm: initialProductTypeGroupDetailForm,
	productTypeGroupDuplicateForm: initialProductTypeGroupDuplicateForm,
	productTypeGroupListResponse: {
		productTypeGroups: []
	},
	productTypes: [],
	resourceList: [],
	allowDelete: true,
	productTypeGroupDetailFormView: initialProductTypeGroupDetailFormValue,
	isDuplicateDialog: false,
	isDuplicateLoading: false
}
