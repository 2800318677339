<span *ngIf="title" mat-dialog-title>{{ title | translate }}</span>

<mat-dialog-content *ngIf="content">
  <pre class="error-dialog-box-content">{{ content | translate: contentParam }}</pre>
</mat-dialog-content>

<div fxLayout="row" fxLayoutAlign="end">
  <div>
      <button class="mat-raised-button mat-primary" (click)="submit()"
        fxFlexOffset="8px">{{ submitBtn | translate }}</button>
  </div>
</div>