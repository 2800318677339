import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { Request, Response } from 'src/app/models/common/http'
import { RestService } from '../common/rest-client/rest.service'
import { PointAdjustmentFileUploadForm } from 'src/app/store/adjustment-function/point-adjustment-file-upload'
import { POLICY } from 'src/app/models/common/constant'


@Injectable({
	providedIn: 'root'
})
export class AdjustmentFunctionService {

	constructor(
		private rest: RestService,
	) { }

	getAdjustmentFunctionFileList(params): Observable<Response> {
		const request: Request = {
			liveURL: `/adjustmentFunction/list/filename?${new URLSearchParams(params)}`
		}

		return this.rest.get(request)
	}

	getAdjustmentFunctionFile(params): Observable<Response> {
		const request: Request = {
			liveURL: `/adjustmentFunction/download?${new URLSearchParams(params)}`
		}

		return this.rest.get(request)
	}

	getAdjustmentFunctionFileContent(params: { id: number, policyCode: POLICY }): Observable<Response> {
		const request: Request = {
			liveURL: `/adjustmentFunction/fileContent/${params.id}` + this.rest.urlQueryString({ policyCode: params.policyCode })

		}
		return this.rest.get(request)
	}


	pointAdjustmentFileUpload(params: PointAdjustmentFileUploadForm): Observable<Response> {
		const request: Request = {
			body: params,
			liveURL: '/adjustmentFunction/create'
		}

		return this.rest.post(request)
	}

	getResourceList(): Observable<Response> {
		const request: Request = {
			liveURL: `/adjustmentFunction/resourceList/create`
		}

		return this.rest.get(request)
	}
}
