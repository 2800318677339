import { Component, Inject, OnInit, OnDestroy } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material'
import { MessageDialogData } from 'src/app/models/common/dialog'
import { GlobalDialogBox } from 'src/app/models/util/global-dialog'

@Component({
	selector: 'app-error-message-dialog',
	templateUrl: './error-message-dialog.component.html',
	styleUrls: ['./error-message-dialog.component.scss']
})
export class ErrorMessageDialogComponent implements OnInit, OnDestroy {

	title: string
	content: string
	contentParam: string
	cancelBtn: string
	submitBtn: string
	customVariable: any

	constructor(
		private dialogRef: MatDialogRef<ErrorMessageDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: MessageDialogData
	) {
		this.title = data.title
		this.content = data.content
		this.contentParam = data.contentParam
		this.submitBtn = data.submitBtn || 'ACTION.OK'
	}

	ngOnInit() {
	}

	ngOnDestroy() {
	}

	submit() {
		this.dialogRef.close(this.data)
	}

}
