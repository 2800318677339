<div class="layout-div">

    <form novalidate [ngrxFormState]="formState$ | async" fxLayout="column" fxFlex>

        <div fxLayout="row" fxLayout.lt-md="outline" fxLayoutGap="20px" fxLayoutGap.lt-md="20px">
            <span class="mat-h3" style="width: 100%;">{{redemptionType}}</span>

            <button mat-icon-button class="close-button" [mat-dialog-close]="true">
                <mat-icon class="close-icon">close</mat-icon>
            </button>
        </div>

        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="20px">
            <mat-form-field fxFlex="100">
                <mat-label>{{ 'CUSTOMER_MANAGEMENT.PRODUCT' | translate }}</mat-label>
                <input matInput value="{{(formState$ | async).productCodeAndName}}" type="text" readonly>
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="20px">
            <mat-form-field fxFlex="50">
                <mat-label>{{ 'CUSTOMER_MANAGEMENT.PARTNER_ID' | translate }}</mat-label>
                <input matInput value="{{(formState$ | async).airmileId}}" type="text" readonly>
            </mat-form-field>

            <mat-form-field fxFlex="50">
                <mat-label>{{ 'CUSTOMER_MANAGEMENT.QUANTITY' | translate }}</mat-label>
                <input matInput value="{{(formState$ | async).quantity}}" type="text" readonly>
            </mat-form-field>
		</div>

		<div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="20px">
            <mat-form-field fxFlex="50">
                <mat-label>{{ 'CUSTOMER_MANAGEMENT.TOTAL_POINTS' | translate }}</mat-label>
                <input matInput value="{{(formState$ | async).totalPoint}}" type="text" readonly>
            </mat-form-field>

            <mat-form-field fxFlex="50">
                <mat-label>{{ 'CUSTOMER_MANAGEMENT.TOTAL_VALUE' | translate }}</mat-label>
                <input matInput value="{{(formState$ | async).value}}" type="text" readonly>
            </mat-form-field>
        </div>
    </form>
</div>
