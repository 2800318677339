import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'
import { FormGroupState } from 'ngrx-forms'
import { Observable, Subscription } from 'rxjs'
import * as AppStore from 'src/app/store'
import * as CampaignSelectors from 'src/app/store/campaign-management/campaign/campaign.selectors'
import { Ruleset, StaffIndAccForm } from 'src/app/store/campaign-management/campaign/campaign.state'

@Component({
	selector: 'app-staff-ind-acc',
	templateUrl: './staff-ind-acc.component.html',
	styleUrls: ['./staff-ind-acc.component.scss']
})
export class StaffIndAccComponent implements OnInit, OnDestroy {

	@Input() ruleset: Ruleset

	formState$: Observable<FormGroupState<StaffIndAccForm>>
	action: string
	subs: Subscription

	constructor(
		private store: Store<AppStore.State>
	) {
		this.subs = this.store.select(CampaignSelectors.getCampaign).subscribe(({ campaignRulesetAction }) => {
			this.action = campaignRulesetAction
		})
	}

	get isView(): boolean { return this.action === 'VIEW' }

	ngOnInit() {
		this.formState$ = this.store.select(CampaignSelectors.getForm, this.ruleset.id)
	}

	ngOnDestroy() {
		this.subs.unsubscribe()
	}
}
