import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core'
import { CanAccess } from '../models/authentication/auth'
import { AuthService } from '../services/authentication/auth.service'

@Directive({
	selector: '[appCanAccess]'
})
export class CanAccessDirective implements OnInit {

	@Input() set appCanAccess(canAccess: CanAccess) {
		this.applyPermission(canAccess)
	}

	isVisible = false

	constructor(
		private templateRef: TemplateRef<any>,
		private viewContainer: ViewContainerRef,
		private authService: AuthService
	) { }

	ngOnInit() { }

	private applyPermission(canAccess: CanAccess) {
		if (canAccess.functions.length === 0 || this.authService.checkAuthorization(canAccess)) {
			this.isVisible = true
			this.viewContainer.createEmbeddedView(this.templateRef)
		} else {
			this.isVisible = false
			this.viewContainer.clear()
		}
	}
}
