import { KeyValue } from '@angular/common'
import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { MatSelectChange } from '@angular/material'
import { Store } from '@ngrx/store'
import { FormGroupState } from 'ngrx-forms'
import { Observable, Subscription } from 'rxjs'
import * as AppStore from 'src/app/store'
import * as CampaignAction from 'src/app/store/campaign-management/campaign/campaign.actions'
import * as CampaignSelectors from 'src/app/store/campaign-management/campaign/campaign.selectors'
import { BirthdayForm, Ruleset } from 'src/app/store/campaign-management/campaign/campaign.state'

@Component({
	selector: 'app-birthday',
	templateUrl: './birthday.component.html',
	styleUrls: ['./birthday.component.scss']
})
export class BirthdayComponent implements OnInit, OnDestroy {

	@Input() ruleset: Ruleset

	formState$: Observable<FormGroupState<BirthdayForm>>
	action: string
	birthdayList: KeyValue<string, string>[] = []
	subs: Subscription

	constructor(
		private store: Store<AppStore.State>
	) {
		this.subs = this.store.select(CampaignSelectors.getCampaign).subscribe(({ campaignRulesetAction, rulesetData }) => {
			this.action = campaignRulesetAction
			this.birthdayList = rulesetData.birthdayList
		})
	}

	get isView(): boolean { return this.action === 'VIEW' }

	ngOnInit() {
		this.formState$ = this.store.select(CampaignSelectors.getForm, this.ruleset.id)
	}

	ngOnDestroy() {
		this.subs.unsubscribe()
	}

	onChangeBirthday(matSelectChange: MatSelectChange) {
		this.store.dispatch(CampaignAction.OnChangeBirthday({ ruleset: this.ruleset, value: matSelectChange.value }))
	}

	xDaysBeforeChange(value: string) {
		this.store.dispatch(CampaignAction.OnChangeXDaysBefore({ ruleset: this.ruleset, value }))
	}

	xDaysAfterChange(value: string) {
		this.store.dispatch(CampaignAction.OnChangeXDaysAfter({ ruleset: this.ruleset, value }))
	}

	getBirthdayValue(value: string): string {
		const result = this.birthdayList.find(x => x.key === value)
		return result ? result.value : ''
	}
}
