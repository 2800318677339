import { DownloadFileSampleResponse, FileDownload } from 'src/app/models/file-download/file-download'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Action, Store } from '@ngrx/store'
import { Observable, of } from 'rxjs'
import { catchError, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators'
import { Response } from 'src/app/models/common/http'
import * as AppStore from 'src/app/store/'
import * as CommonAction from '../common/common/common.actions'
import * as FileDownloadAction from './file-download.actions'
import { MatDialog } from '@angular/material'
import { FileDownloadService } from 'src/app/services/file-download/file-download.service'

@Injectable()
export class Effects {

	constructor(
		private action$: Actions,
		private router: Router,
		private dialog: MatDialog,
		private store: Store<AppStore.State>,
		private fileDownloadService: FileDownloadService,
	) { }

	InitialState$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(FileDownloadAction.InitialState),
			tap(() => {
				this.router.navigate(['file-download'])
			})
		), { dispatch: false }
	)

	RestError$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(FileDownloadAction.RestError),
			map(({ message, manualThrow }) => {
				return CommonAction.RestError({ message, manualThrow })
			})
		)
	)

	List$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(FileDownloadAction.List),
			withLatestFrom(this.store.select(state => state.fileDownload)),
			mergeMap(([params, fileDownload]) => {
				return this.fileDownloadService.getFileDownloadList({policyList: params.policyList}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: FileDownload[] = response.payload
					if (success) {
						return FileDownloadAction.ListSuccess({ payload })
					} else { return FileDownloadAction.RestError(response as any) }
				}),
					catchError((error) => {
						return (of(FileDownloadAction.RestError({ message: error.message, manualThrow: true })))
					})
				)
			})
		)
	)

	DownloadFileSample$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(FileDownloadAction.DownloadFileSample),
			mergeMap(({ fileName }) => {
				return this.fileDownloadService.downloadFileSample({ fileName }).pipe(map((response: Response) => {
					const success: boolean = response.success
					const respPayload: DownloadFileSampleResponse = response.payload
					if (success) {
						const  a = document.createElement('a')
						a.href = respPayload.fileSample
						document.body.appendChild(a)
						a.click()
						return FileDownloadAction.DownloadFileSampleSuccess()
					} else { return FileDownloadAction.RestError(response as any) }
				}), catchError(this.catcher)
				)
			})
		)
	)

	DownloadFileSampleSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(FileDownloadAction.DownloadFileSampleSuccess),
			mergeMap(( ) => {
				return []
			})
		)
	)

	catcher = (error) => {
		return (of(FileDownloadAction.RestError({ message: error.message, manualThrow: error.manualThrow })))
	}
}
