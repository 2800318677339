import { createAction, props } from '@ngrx/store'
import { AutoRedemption } from 'src/app/models/redemption-settings/auto-redemption/auto-redemption'
import { AutoRedemptionUpdateView } from 'src/app/models/redemption-settings/auto-redemption/auto-redemption-update'
import { AutoRedemptionViewResponse } from 'src/app/models/redemption-settings/auto-redemption/auto-redemption-view'
import { RewardPoolAndGroup } from 'src/app/models/redemption-settings/auto-redemption/auto-redemption-reward-list'
import { RedemptionTypeListResponse, CycleTypeListResponse, CycleMonthlyListResponse, CycleWeeklyListResponse, CycleYearlyListResponse, BillingCycleTypeListResponse, YearlyTypeListResponse, CappingLevelListResponse } from 'src/app/models/redemption-settings/auto-redemption/auto-redemption-option-list'
import { POLICY } from 'src/app/models/common/constant'

export const InitialState = createAction('[AutoRedemption] InitialState')
export const RestError = createAction('[AutoRedemption] RestError', props<{ message: string, manualThrow: boolean }>())

export const GoList = createAction('[AutoRedemption] GoList')
export const GoView = createAction('[AutoRedemption] GoView')
export const GoUpdate = createAction('[AutoRedemption] GoUpdate')
export const GoCreate = createAction('[AutoRedemption] GoCreate')

export const SetId = createAction('[AutoRedemption] SetId', props<{ id: number }>())
export const SetRedemptionId = createAction('[AutoRedemption] SetRedemptionId', props<{ redemptionId: string }>())

export const GoBackDialog = createAction('[AutoRedemption] GoBackDialog', props<{ action: string }>())
export const CreateDialog = createAction('[AutoRedemption] CreateDialog')
export const UpdateDialog = createAction('[AutoRedemption] UpdateDialog')
export const DeleteDialog = createAction('[AutoRedemption] DeleteDialog')
export const CloseDialog = createAction('[AutoRedemption] CloseDialog')

export const List = createAction('[AutoRedemption] List')
export const ListSuccess = createAction('[AutoRedemption] ListSuccess', props<{ payload: AutoRedemption[] }>())

export const View = createAction('[AutoRedemption] View')
export const ViewSuccess = createAction('[AutoRedemption] ViewSuccess', props<{ payload: AutoRedemptionViewResponse }>())

export const Create = createAction('[AutoRedemption] Create')
export const CreateSuccess = createAction('[AutoRedemption] CreateSuccess', props<{ payload: string }>())

export const Update = createAction('[AutoRedemption] Update')
export const UpdateSuccess = createAction('[AutoRedemption] UpdateSuccess', props<{ payload: string }>())

export const Delete = createAction('[AutoRedemption] Delete')
export const DeleteSuccess = createAction('[AutoRedemption] DeleteSuccess', props<{ payload: string }>())

export const UpdateView = createAction('[AutoRedemption] UpdateView')
export const UpdateViewSuccess = createAction('[AutoRedemption] UpdateViewSuccess', props<{ payload: AutoRedemptionUpdateView }>())

export const SaveConversion = createAction('[AutoRedemption] SaveConversion')
export const DeleteConversion = createAction('[AutoRedemption] DeleteConversion')

export const RedemptionTypeList = createAction('[AutoRedemption] RedemptionTypeList')
export const RedemptionTypeListSuccess = createAction('[AutoRedemption] RedemptionTypeListSuccess', props<{ payload: RedemptionTypeListResponse }>())

export const CappingLevelList = createAction('[AutoRedemption] CappingLevelList')
export const CappingLevelListSuccess = createAction('[AutoRedemption] CappingLevelListSuccess', props<{ payload: CappingLevelListResponse }>())

export const CycleTypeList = createAction('[AutoRedemption] CycleTypeList')
export const CycleTypeListSuccess = createAction('[AutoRedemption] CycleTypeListSuccess', props<{ payload: CycleTypeListResponse }>())

export const RewardPoolAndGroupList = createAction('[AutoRedemption] RewardPoolAndGroupList', props<{ payload: POLICY }>())
export const RewardPoolAndGroupListSuccess = createAction('[AutoRedemption] RewardPoolAndGroupListSuccess', props<{ payload: RewardPoolAndGroup[] }>())

export const CycleWeeklyList = createAction('[AutoRedemption] CycleWeeklyList')
export const CycleWeeklyListSuccess = createAction('[AutoRedemption] CycleWeeklyListSuccess', props<{ payload: CycleWeeklyListResponse }>())

export const CycleMonthlyList = createAction('[AutoRedemption] CycleMonthlyList')
export const CycleMonthlyListSuccess = createAction('[AutoRedemption] CycleMonthlyListSuccess', props<{ payload: CycleMonthlyListResponse }>())

export const CycleYearlyList = createAction('[AutoRedemption] CycleYearlyList')
export const CycleYearlyListSuccess = createAction('[AutoRedemption] CycleYearlyListSuccess', props<{ payload: CycleYearlyListResponse }>())

export const BillingCycleTypeList = createAction('[AutoRedemption] BillingCycleTypeList')
export const BillingCycleTypeListSuccess = createAction('[AutoRedemption] BillingCycleTypeListSuccess', props<{ payload: BillingCycleTypeListResponse }>())

export const YearlyTypeList = createAction('[AutoRedemption] YearlyTypeList')
export const YearlyTypeListSuccess = createAction('[AutoRedemption] YearlyTypeListSuccess', props<{ payload: YearlyTypeListResponse }>())

export const ClearCycleDayMonth = createAction('[AutoRedemption] ClearCycleDayMonth')
export const ClearCycleDay = createAction('[AutoRedemption] ClearCycleDay')
export const ClearBillingCycleType = createAction('[AutoRedemption] ClearBillingCycleType')
export const OnChangeYearlyCycleType = createAction('[AutoRedemption] OnChangeYearlyCycleType')
export const ClearCapping = createAction('[AutoRedemption] ClearCapping', props<{ cappingLevel: string, cappingValue: string }>())

export const DuplicateDialog = createAction('[AutoRedemption] DuplicateDialog')
export const Duplicate = createAction('[AutoRedemption] Duplicate')
export const DuplicateSuccess = createAction('[AutoRedemption] DuplicateSuccess', props<{ payload: string }>())
export const DuplicateRewardPoolAndGroupList = createAction('[AutoRedemption] DuplicateRewardPoolAndGroupList', props<{ payload: POLICY }>())
export const DuplicateRewardPoolAndGroupListSuccess = createAction('[AutoRedemption] DuplicateRewardPoolAndGroupListSuccess', props<{ payload: RewardPoolAndGroup[] }>())
