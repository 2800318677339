import { Action, createReducer, on } from '@ngrx/store'
import { formGroupReducer, setValue } from 'ngrx-forms'
import * as UserProfileAction from './user-profile.actions'
import * as UserProfileState from './user-profile.state'

export const reducer = createReducer(
	UserProfileState.initialState,
	on(UserProfileAction.InitialState, () => UserProfileState.initialState),
	on(UserProfileAction.ViewSuccess, (state, { payload }) => ({
		...state,
		userProfileDetilForm: UserProfileState.validateUserProfileDetailForm(setValue(state.userProfileDetilForm, {
			userId: payload.userId
		}))
	}))
)

export function Reducer(state: UserProfileState.State = UserProfileState.initialState, action: Action) {
	const userProfileDetilForm = UserProfileState.validateUserProfileDetailForm(formGroupReducer(state.userProfileDetilForm, action))
	if (userProfileDetilForm !== state.userProfileDetilForm) {
		state = { ...state, userProfileDetilForm }
	}
	return reducer(state, action)
}
