import { Action, createReducer, on } from '@ngrx/store'
import { formGroupReducer } from 'ngrx-forms'
import * as LoginAction from './login.actions'
import { initialState, State, validateLoginForm } from './login.state'

export const reducer = createReducer(
	initialState,
	on(LoginAction.RestError, state => ({
		...state,
		isLoading: false
	})),
	on(LoginAction.Login, state => ({
		...state,
		isLoading: true
	})),
	on(LoginAction.LoginSuccess, state => ({
		...initialState,
		isLoading: false
	}))
)

export function Reducer(state: State = initialState, action: Action) {
	const loginForm = validateLoginForm(formGroupReducer(state.loginForm, action))
	if (loginForm !== state.loginForm) {
		state = { ...state, loginForm }
	}

	return reducer(state, action)
}
