import { ChangeDetectionStrategy, Component, Input, OnInit, OnDestroy } from '@angular/core'
import { Store } from '@ngrx/store'
import { Observable, Subscription } from 'rxjs'
import * as AppStore from 'src/app/store'
import * as CampaignAction from 'src/app/store/campaign-management/campaign/campaign.actions'
import * as CampaignSelectors from 'src/app/store/campaign-management/campaign/campaign.selectors'
import { RuleCondition, Ruleset } from 'src/app/store/campaign-management/campaign/campaign.state'

@Component({
	selector: 'app-rule-group',
	templateUrl: './rule-group.component.html',
	styleUrls: ['./rule-group.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class RuleGroupComponent implements OnInit, OnDestroy {

	@Input() ruleset: Ruleset
	ruleConditions$: Observable<RuleCondition[]>
	action: string
	subs: Subscription

	constructor(
		private store: Store<AppStore.State>,
	) { }

	get isView(): boolean { return this.action === 'VIEW' }

	ngOnInit() {
		this.ruleConditions$ = this.store.select(CampaignSelectors.getGroups, this.ruleset.id)
		this.subs = this.store.select(CampaignSelectors.getCampaign).subscribe(({ campaignRulesetAction }) => {
			this.action = campaignRulesetAction
		})
	}

	ngOnDestroy() {
		this.subs.unsubscribe()
	}

	trackByIndex(index: number, ruleCondition: RuleCondition) {
		return ruleCondition && ruleCondition.id
	}

	add() {
		this.store.dispatch(CampaignAction.AddCondition({ payload: this.ruleset }))
	}

	delete() {
		this.store.dispatch(CampaignAction.DeleteRuleset({ ruleset: this.ruleset }))
	}
}
