import { createAction, props } from '@ngrx/store'
import { KeyValue } from '@angular/common'
import { ProductTypeGroupListResponse } from 'src/app/models/param-settings/product-type-group/product-type-group-list'
import { ProductTypeGroupViewResponse } from 'src/app/models/param-settings/product-type-group/product-type-group-view'
import { ProductTypeGroupOptionResponse } from 'src/app/models/param-settings/product-type-group/product-type-group-option'

export const InitialState = createAction('[ProductTypeGroup] InitialState')
export const Reset = createAction('[ProductTypeGroup] Reset')
export const SetId = createAction('[ProductTypeGroup] SetId', props<{ code: string }>())

export const GoList = createAction('[ProductTypeGroup] GoList')
export const GoView = createAction('[ProductTypeGroup] GoView')
export const GoCreate = createAction('[ProductTypeGroup] GoCreate')
export const GoUpdate = createAction('[ProductTypeGroup] GoUpdate')

export const GoBackDialog = createAction('[ProductTypeGroup] GoBackDialog', props<{ action: string }>())
export const CreateDialog = createAction('[ProductTypeGroup] CreateDialog')
export const UpdateDialog = createAction('[ProductTypeGroup] UpdateDialog')
export const DeleteDialog = createAction('[ProductTypeGroup] DeleteDialog')
export const CloseDialog = createAction('[ProductTypeGroup] CloseDialog')

export const RestError = createAction('[ProductTypeGroup] RestError', props<{ message: string, manualThrow: boolean }>())

export const OptionList = createAction('[ProductTypeGroup] OptionList')
export const OptionListSuccess = createAction('[ProductTypeGroup] OptionListSuccess', props<{ payload: ProductTypeGroupOptionResponse }>())

export const List = createAction('[ProductTypeGroup] List')
export const ListSuccess = createAction('[ProductTypeGroup] ListSuccess', props<{ payload: ProductTypeGroupListResponse }>())

export const View = createAction('[ProductTypeGroup] View')
export const ViewSuccess = createAction('[ProductTypeGroup] ViewSuccess', props<{ payload: ProductTypeGroupViewResponse }>())

export const Create = createAction('[ProductTypeGroup] Create')
export const CreateSuccess = createAction('[ProductTypeGroup] CreateSuccess', props<{ payload: string }>())

export const Update = createAction('[ProductTypeGroup] Update')
export const UpdateSuccess = createAction('[ProductTypeGroup] UpdateSuccess', props<{ payload: string }>())

export const Delete = createAction('[ProductTypeGroup] Delete')
export const DeleteSuccess = createAction('[ProductTypeGroup] DeleteSuccess', props<{ payload: string }>())

export const ResourceListCreate = createAction('[ProductTypeGroup] ResourceListCreate')
export const ResourceListCreateSuccess = createAction('[ProductTypeGroup] ResourceListCreateSuccess', props<{ payload: KeyValue<string, string>[] }>())

export const ResourceListUpdate = createAction('[ProductTypeGroup] ResourceListUpdate')
export const ResourceListUpdateSuccess = createAction('[ProductTypeGroup] ResourceListUpdateSuccess', props<{ payload: KeyValue<string, string>[] }>())

export const ClearType = createAction('[ProductTypeGroup] ClearType')

export const DuplicateDialog = createAction('[ProductTypeGroup] DuplicateDialog')
export const Duplicate = createAction('[ProductTypeGroup] Duplicate')
export const DuplicateSuccess = createAction('[ProductTypeGroup] DuplicateSuccess', props<{ payload: string }>())

