import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { CanAccess } from 'src/app/models/authentication/auth'
import { LoginRequest, OauthTokenRequest } from 'src/app/models/authentication/login'
import { LoginUser } from 'src/app/models/common/common'
import { Request, Response } from 'src/app/models/common/http'
import { StorageData } from 'src/app/models/common/storage.model'
import * as AppStore from 'src/app/store'
import * as CommonAction from 'src/app/store/common/common'
import { getLoginUser } from 'src/app/store/common/common'
import { LocalStorageService } from '../common/local-storage/local-storage.service'
import { RestService } from '../common/rest-client/rest.service'

@Injectable({
	providedIn: 'root',
})
export class AuthService {

	storageData: StorageData
	loginUser: LoginUser

	constructor(
		private rest: RestService,
		private localStorageService: LocalStorageService,
		private store: Store<AppStore.State>
	) {
		this.storageData = this.localStorageService.get('KEY_STORAGE_USER')
		if (this.storageData) {
			this.store.dispatch(CommonAction.SetLoginUser({
				payload: this.storageData.payload
			}))
		}

		this.store.select(getLoginUser).subscribe(x => {
			this.loginUser = x
		})
	}

	login(params: LoginRequest): Observable<Response> {
		const request: Request = {
			body: params,
			liveURL: '/authentication/login/',
			mockURL: '/authentication/login.json'
		}

		return this.rest.post(request)
	}

	obtainTokenByAuthorizationCode(params: OauthTokenRequest): Observable<Response> {
		const queryString = new URLSearchParams({ ...params })
		const request: Request = {
			liveURL: `/oauth/authCode?${queryString}`,
			mockURL: ''
		}

		return this.rest.post(request)
	}

	checkAuthorization(canAccess: CanAccess): boolean {
		// testing
		// return true

		const authorities = this.loginUser && this.loginUser.authorities
		const functions = canAccess.functions
		if (functions && authorities && functions.some(r => authorities.includes(r))) {
			return true
		}

		return false
	}
}
