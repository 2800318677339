import * as moment from 'moment'
import { dateFormat, dateISOFormat } from '../common/constant'

export class Util {
	static fromISOStringToDateStr(dateStr: string) {
		if (!dateStr) { return '' }
		return moment(dateStr).format(dateFormat)
	}

	static dateStrToISOString(dateStr: string) {
		if (!dateStr) { return '' }
		const split = dateStr.split('-')
		return new Date(Number(split[2]), Number(split[1]) - 1, Number(split[0])).toISOString()
	}

	static diffInMonths(startDate: string, endDate: string) {
		if (!startDate || !endDate) {
			return 0
		}

		const from = new Date(Util.fromISOStringToDateStr(startDate).replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3"))
		const to = new Date(Util.fromISOStringToDateStr(endDate).replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3"))

		let months = to.getMonth() - from.getMonth() + (12 * (to.getFullYear() - from.getFullYear()))

		if (to.getDate() < from.getDate()) {
			let newFrom = new Date(to.getFullYear(), to.getMonth(), from.getDate())
			if (to < newFrom && to.getMonth() === newFrom.getMonth() && to.getFullYear() % 4 !== 0) {
				months--
			}
		}

		return months;
	}

	static ISOStringFormat(str: string) {
		if (!str) { return '' }
		return moment(str).format(dateISOFormat)
	}

	static fromISOStringToDynamicDateStr(str: string, pattern: string) {
		if (!str) { return '' }
		return moment(str).format(pattern)
	}

	static objectComparatorNLayer(a, b) {
		if (JSON.stringify(a) === JSON.stringify(b)) {
			return true
		} else {
			return false
		}
	}

	// Date format yyy-mm-dd
	static dateFormat(day: number, month: number, year: number): string {
		const dd = String(day).padStart(2, '0')
		const mm = String(month + 1).padStart(2, '0')
		const yyyy = year
		return yyyy + '-' + mm + '-' + dd
	}

	static fromISOStringToCustomDateStr(dateStr: string, dateFormat: string) {
		if (!dateStr) { return '' }
		return moment(dateStr).format(dateFormat)
	}

	static minusDayFromToday(minusDay?: number) {
		const date = new Date()
		date.setDate(date.getDate() - (minusDay ? minusDay : 0))
		return moment(date).format('YYYY-MM-DD')
	}
}
