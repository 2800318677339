import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { FormControl } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef, MatSelect } from '@angular/material'
import { Observable, ReplaySubject, Subject, Subscription } from 'rxjs'
import { take, takeUntil } from 'rxjs/operators'
import { box, Boxed, FormGroupState, SetValueAction, unbox } from 'ngrx-forms'
import { Store } from '@ngrx/store'
import { SelectForm } from 'src/app/store/common/common'
import * as AppStore from 'src/app/store'
import * as CommonAction from 'src/app/store/common/common/common.actions'
import * as CommonSelector from 'src/app/store/common/common/common.selectors'
import { SelectDialogData } from 'src/app/models/common/dialog'
import * as GroupAction from 'src/app/store/access-management/group/group.actions'
import * as UserAction from 'src/app/store/access-management/user/user.actions'
import { FilterPredicate } from 'src/app/models/util/filter-predicate'

@Component({
	selector: 'app-multi-select-dialog',
	templateUrl: './multi-select-dialog.component.html',
	styleUrls: ['./multi-select-dialog.component.scss']
})
export class MultiSelectDialogComponent implements OnInit, OnDestroy {

	title: string
	labelTitle: string
	payload: any = []
	value: Boxed<string[]>
	formState$: Observable<FormGroupState<SelectForm>>
	formState: string
	public dropDownCtrl: FormControl = new FormControl()
	public multiCtrl: FormControl = new FormControl()
	public filteredSelectionList: ReplaySubject<any[]> = new ReplaySubject<any[]>(1)
	isToggleAllChecked: boolean
	protected _onDestroy = new Subject<void>()
	subs: Subscription

	@ViewChild('multiSelect', { static: false }) multiSelect: MatSelect

	constructor(
		private store: Store<AppStore.State>,
		private dialogRef: MatDialogRef<MultiSelectDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: SelectDialogData
	) {
		this.title = data.title
		this.labelTitle = data.content
		this.payload = data.payload

		this.store.dispatch(CommonAction.SelectInitial())
		this.subs = this.store.select(CommonSelector.getSelectForm).subscribe(x => { this.value = x.controls.selectionList.value })
		this.formState$ = this.store.select(({ common }) => common.selectForm)
	}

	ngOnInit() {
		this.filteredSelectionList.next(this.payload.slice())

		// listen for search field value changes
		this.dropDownCtrl.valueChanges
			.pipe(takeUntil(this._onDestroy))
			.subscribe(value => {
				const maxChar = 255
				if (value.length > maxChar) {
					const newValue = value.slice(0, maxChar)
					this.dropDownCtrl.reset('', { emitEvent: false })
					this.dropDownCtrl.setValue(newValue)

				} else {
					this.checkSelectionAll()
				}
			})
	}

	ngOnDestroy() {
		this._onDestroy.next()
		this._onDestroy.complete()
		this.subs.unsubscribe()
	}

	toggleSelectAll(selectAllValue: boolean) {
		this.filteredSelectionList.pipe(take(1), takeUntil(this._onDestroy))
			.subscribe(val => {
				this.formState$.pipe(take(1), takeUntil(this._onDestroy)).subscribe(control => { this.formState = control.controls.selectionList.id })

				const value = val.map(o => o.keyValue.key) && val.map(o => o.keyValue.key).toString().split(',')

				if (selectAllValue) {
					this.isToggleAllChecked = true
					if (this.value !== box([])) {
						this.store.dispatch(new SetValueAction(this.formState, box(Array.from(new Set(value.concat(unbox(this.value)))))))
					} else {
						this.store.dispatch(new SetValueAction(this.formState, box(Array.from(new Set(value)))))
					}
				} else {
					this.isToggleAllChecked = false
					this.store.dispatch(new SetValueAction(this.formState, box(Array.from(new Set(unbox(this.value))).filter(item => !value.includes(item)))))
				}
			})
		this.checkSelectionAll()
	}

	protected search(search: string) {
		if (!this.payload) {
			return
		}
		// get the search keyword
		if (!search) {
			this.filteredSelectionList.next(this.payload.slice())
			return
		} else {
			search = search.toLowerCase()
		}
		// filter the records
		this.filteredSelectionList.next(
			this.payload.filter(payload => FilterPredicate.isIdenticalTo(search)([payload.keyValue.value]))
		)
	}

	clearSearch() {
		this.filteredSelectionList.next(this.payload.slice())
		this.checkSelectionAll()
	}

	checkSelectionAll() {
		let key = []
		this.search(this.dropDownCtrl.value)
		this.filteredSelectionList.pipe(take(1), takeUntil(this._onDestroy)).subscribe(o => { key = o.map(i => i.keyValue.key) })

		if (key.every(o => unbox(this.value).includes(o)) && key.length !== 0) {
			this.isToggleAllChecked = true
		} else {
			this.isToggleAllChecked = false
		}
	}

	add() {
		this.dialogRef.close(this.value)
		if (this.title === 'GROUP.ASSIGN_FUNCTION') {
			this.store.dispatch(GroupAction.OpenConfirmationDialog())
		} else if (this.title === 'USER.ASSIGN_GROUP') {
			this.store.dispatch(UserAction.OpenConfirmationDialog())
		}
	}

	cancel() {
		this.dialogRef.close()
	}
}
