import { Worklist } from 'src/app/models/worklist/worklist'

export interface State {
	isLoading: boolean
	from: 'tasklist' | 'my-request' | string
	code: string
	show: boolean
	task: Worklist
}

export const initialState: State = {
	isLoading: false,
	show: false,
	from: '',
	code: '',
	task: {
		id: 0,
		name: '',
		dataId: 0,
		dataCode: '',
		dataName: '',
		type: '',
		status: '',
		createdBy: '',
		modifiedBy: '',
		modifiedDate: '',
		rejectReason: '',
	}
}
