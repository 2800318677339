import { Action, createReducer, on } from '@ngrx/store'
import { formGroupReducer, reset, setValue } from 'ngrx-forms'
import * as TcGroupAction from './tc-group.actions'
import * as TcGroupState from './tc-group.state'

export const reducer = createReducer(
	TcGroupState.initialState,

	on(TcGroupAction.InitialState, () => TcGroupState.initialState),
	on(TcGroupAction.Reset, state => ({
		...state,
		tcGroupListForm: reset(setValue(state.tcGroupListForm, TcGroupState.initialTcGroupListFormValue))
	})),
	on(TcGroupAction.SetId, (state, { code }) => ({
		...state,
		code
	})),
	on(TcGroupAction.GoCreate, state => ({
		...state,
		tcGroupDetailForm: reset(setValue(state.tcGroupDetailForm, TcGroupState.initialTcGroupDetailFormValue))
	})),
	on(TcGroupAction.RestError, state => ({
		...state,
		isLoading: false,
		isDuplicateLoading: false,
		isDuplicateDialog: false
	})),
	on(TcGroupAction.List, state => ({
		...state,
		isLoading: true
	})),
	on(TcGroupAction.ListSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		tcGroupListResponse: payload
	})),
	on(TcGroupAction.ResourceListCreate, state => ({
		...state,
		isLoading: true
	})),
	on(TcGroupAction.ResourceListCreateSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		resourceList: (payload || [])
	})),
	on(TcGroupAction.ResourceListUpdate, state => ({
		...state,
		isLoading: true
	})),
	on(TcGroupAction.ResourceListUpdateSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		resourceList: (payload || [])
	})),
	on(TcGroupAction.View, state => ({
		...state,
		isLoading: true,
		tcGroupDetailForm: setValue(state.tcGroupDetailForm, TcGroupState.initialTcGroupDetailFormValue),
		allowDelete: true,
	})),
	on(TcGroupAction.ViewSuccess, (state, { payload }) => {
		const tcGroupDetailFormView = {
			code: payload.code,
			name: payload.name,
			description: payload.description,
			range: payload.range,
			createdBy: payload.createdBy,
			createdDate: payload.createdDate,
			modifiedBy: payload.modifiedBy,
			modifiedDate: payload.modifiedDate
		}
		return ({
			...state,
			isLoading: false,
			tcGroupDetailForm: TcGroupState.validateTcGroupDetailForm(setValue(state.tcGroupDetailForm, tcGroupDetailFormView as TcGroupState.TcGroupDetailForm)),
			allowDelete: payload.allowDelete,
			tcGroupDetailFormView
		})
	}),
	on(TcGroupAction.Create, state => ({
		...state,
		isLoading: true
	})),
	on(TcGroupAction.CreateSuccess, (state, { payload }) => ({
		...TcGroupState.initialState
	})),
	on(TcGroupAction.Update, state => ({
		...state,
		isLoading: true
	})),
	on(TcGroupAction.UpdateSuccess, (state, { payload }) => ({
		...state
	})),
	on(TcGroupAction.Delete, state => ({
		...state,
		isLoading: true
	})),
	on(TcGroupAction.DeleteSuccess, () => TcGroupState.initialState),
	on(TcGroupAction.DuplicateDialog, state => ({
		...state,
		tcGroupDuplicateForm: reset(setValue(state.tcGroupDuplicateForm, TcGroupState.initialTcGroupDuplicateFormValue)),
		isDuplicateDialog: false
	})),
	on(TcGroupAction.Duplicate, state => ({
		...state,
		isDuplicateLoading: true
	})),
	on(TcGroupAction.DuplicateSuccess, state => ({
		...state,
		isDuplicateLoading: false,
		isDuplicateDialog: true
	}))
)

export function Reducer(state: TcGroupState.State = TcGroupState.initialState, action: Action) {
	const tcGroupListForm = TcGroupState.validateTcGroupListForm(formGroupReducer(state.tcGroupListForm, action))
	if (tcGroupListForm !== state.tcGroupListForm) {
		state = { ...state, tcGroupListForm }
	}

	const tcGroupDetailForm = TcGroupState.validateTcGroupDetailForm(formGroupReducer(state.tcGroupDetailForm, action))
	if (tcGroupDetailForm !== state.tcGroupDetailForm) {
		state = { ...state, tcGroupDetailForm }
	}

	const tcGroupDuplicateForm = TcGroupState.validateTcGroupDuplicateForm(formGroupReducer(state.tcGroupDuplicateForm, action))
	if (tcGroupDuplicateForm !== state.tcGroupDuplicateForm) {
		state = {...state, tcGroupDuplicateForm}
	}

	return reducer(state, action)
}
