import { createAction, props } from '@ngrx/store'
import { GlRedemptionListResponse } from 'src/app/models/param-settings/gl-redemption/gl-redemption-list'
import { GlRedemptionViewResponse } from 'src/app/models/param-settings/gl-redemption/gl-redemption-view'
import { GlRedemptionData } from 'src/app/models/param-settings/gl-redemption/gl-redemption-data'

export const InitialState = createAction('[GlRedemption] InitialState')
export const Reset = createAction('[GlRedemption] Reset')
export const SetId = createAction('[GlRedemption] SetId', props<{ id: number }>())

export const GoList = createAction('[GlRedemption] GoList')
export const GoView = createAction('[GlRedemption] GoView')
export const GoCreate = createAction('[GlRedemption] GoCreate')
export const GoUpdate = createAction('[GlRedemption] GoUpdate')

export const GoBackDialog = createAction('[GlRedemption] GoBackDialog', props<{ action: string }>())
export const CreateDialog = createAction('[GlRedemption] CreateDialog')
export const UpdateDialog = createAction('[GlRedemption] UpdateDialog')
export const DeleteDialog = createAction('[GlRedemption] DeleteDialog')
export const CloseDialog = createAction('[GlRedemption] CloseDialog')

export const RestError = createAction('[GlRedemption] RestError', props<{ message: string, manualThrow: boolean }>())

export const List = createAction('[GlRedemption] List')
export const ListSuccess = createAction('[GlRedemption] ListSuccess', props<{ payload: GlRedemptionListResponse }>())

export const View = createAction('[GlRedemption] View')
export const ViewSuccess = createAction('[GlRedemption] ViewSuccess', props<{ payload: GlRedemptionViewResponse }>())

export const Create = createAction('[GlRedemption] Create')
export const CreateSuccess = createAction('[GlRedemption] CreateSuccess', props<{ payload: string }>())

export const Update = createAction('[GlRedemption] Update')
export const UpdateSuccess = createAction('[GlRedemption] UpdateSuccess', props<{ payload: string }>())

export const Delete = createAction('[GlRedemption] Delete')
export const DeleteSuccess = createAction('[GlRedemption] DeleteSuccess')

export const GetGlRedemptionData = createAction('[GlRedemption] GetGlRedemptionData')
export const GetGlRedemptionDataSuccess = createAction('[GlRedemption] GetGlRedemptionDataSuccess', props<{ payload: GlRedemptionData }>())

export const DuplicateDialog = createAction('[GlRedemption] DuplicateDialog')
export const Duplicate = createAction('[GlRedemption] Duplicate')
export const DuplicateSuccess = createAction('[GlRedemption] DuplicateSuccess', props<{ payload: string }>())