import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { Request, Response } from 'src/app/models/common/http'
import { AutoRedemptionCreateRequest } from 'src/app/models/redemption-settings/auto-redemption/auto-redemption-create'
import { AutoRedemptionDeleteRequest } from 'src/app/models/redemption-settings/auto-redemption/auto-redemption-delete'
import { AutoRedemptionUpdateRequest } from 'src/app/models/redemption-settings/auto-redemption/auto-redemption-update'
import { AutoRedemptionViewRequest } from 'src/app/models/redemption-settings/auto-redemption/auto-redemption-view'
import { OnTheSpotRedemptionCreateRequest } from 'src/app/models/redemption-settings/on-the-spot-redemption/on-the-spot-redemption-create'
import { OnTheSpotRedemptionDeleteRequest } from 'src/app/models/redemption-settings/on-the-spot-redemption/on-the-spot-redemption-delete'
import { OnTheSpotRedemptionUpdateRequest } from 'src/app/models/redemption-settings/on-the-spot-redemption/on-the-spot-redemption-update'
import { OnTheSpotRedemptionViewRequest } from 'src/app/models/redemption-settings/on-the-spot-redemption/on-the-spot-redemption-view'
import { RedeemPartnerUpdatePointsConversionRequest, RedeemPartnerUpdateRequest } from 'src/app/models/redemption-settings/redeem-partner/redeem-partner-update'
import { GLRedemptionForRedeemPartnerRequest, RewardsForRedeemPartnerRequest } from 'src/app/models/redemption-settings/redeem-partner/redeem-partner'
import { RedeemPartnerViewRequest } from 'src/app/models/redemption-settings/redeem-partner/redeem-partner-view'
import { RedeemRulesCreateRequest } from 'src/app/models/redemption-settings/redeem-rules/redeem-rules-create'
import { RedeemRulesDeleteRequest } from 'src/app/models/redemption-settings/redeem-rules/redeem-rules-delete'
import { RedeemRulesEditRequest } from 'src/app/models/redemption-settings/redeem-rules/redeem-rules-edit'
import { RedeemRulesViewRequest } from 'src/app/models/redemption-settings/redeem-rules/redeem-rules-view'
import { WaiverCreateRequest } from 'src/app/models/redemption-settings/waiver-management/waiver-create'
import { WaiverDeleteRequest } from 'src/app/models/redemption-settings/waiver-management/waiver-delete'
import { WaiverManagementCreateRequest } from 'src/app/models/redemption-settings/waiver-management/waiver-management-create'
import { WaiverManagementDeleteRequest } from 'src/app/models/redemption-settings/waiver-management/waiver-management-delete'
import { WaiverManagementEditRequest } from 'src/app/models/redemption-settings/waiver-management/waiver-management-edit'
import { WaiverManagementViewRequest } from 'src/app/models/redemption-settings/waiver-management/waiver-management-view'
import { WaiverUpdateRequest } from 'src/app/models/redemption-settings/waiver-management/waiver-update'
import { RestService } from '../common/rest-client/rest.service'
import { AutoRedemptionPolicy } from 'src/app/models/redemption-settings/auto-redemption/auto-redemption'
import { OnTheSpotPolicy } from 'src/app/models/redemption-settings/on-the-spot-redemption/on-the-spot-redemption'
import { RedeemRulesPolicy } from 'src/app/models/redemption-settings/redeem-rules/redeem-rules'
import { RedeemPartnerCreateRequest } from 'src/app/models/redemption-settings/redeem-partner/redeem-partner-create'
import { RedeemPartnerDeleteRequest } from 'src/app/models/redemption-settings/redeem-partner/redeem-partner-delete'
import { AssignedWaiverDuplicateRequest } from '../../models/redemption-settings/waiver-management/assigned-waiver-duplicate'
import { AutoRedemptionDuplicateRequest } from '../../models/redemption-settings/auto-redemption/auto-redemption-duplicate'
import { OnTheSpotRedemptionDuplicateRequest } from 'src/app/models/redemption-settings/on-the-spot-redemption/on-the-spot-redemption-duplicate'

@Injectable({
	providedIn: 'root'
})
export class RedemptionSettingsService {

	constructor(
		private rest: RestService
	) { }

	// Redeem Partner
	getRedeemPartnerList(): Observable<Response> {
		const request: Request = {
			liveURL: '/redeemPartner/list',
		}

		return this.rest.get(request)
	}

	getRewardList(params: RewardsForRedeemPartnerRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/redeemPartner/reward/list' + `?${new URLSearchParams({ ...params })}`,
		}

		return this.rest.get(request)
	}

	createRedeemPartner(params: RedeemPartnerCreateRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/redeemPartner/create',
			body: params
		}

		return this.rest.post(request)
	}

	viewRedeemPartner(params: RedeemPartnerViewRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/redeemPartner/view/' + params.id,
		}

		return this.rest.get(request)
	}

	editRedeemPartner(id: number, params: RedeemPartnerUpdateRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/redeemPartner/edit/' + id,
			body: params
		}

		return this.rest.post(request)
	}

	deleteRedeemPartner(params: RedeemPartnerDeleteRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/redeemPartner/delete/' + params.id,
		}

		return this.rest.post(request)
	}

	editRedeemPartnerPointsConversion(params: RedeemPartnerUpdatePointsConversionRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/redeemPartner/edit-points-conversion/' + params.id,
			body: params
		}

		return this.rest.post(request)
	}

	getGlRedemptionFromRedeemPartner(params: GLRedemptionForRedeemPartnerRequest) {
		const request: Request = {
			liveURL: '/redeemPartner/glProvision/list' + `?${new URLSearchParams({ ...params })}`
		}
		return this.rest.get(request)
	}

	getRoundingListFromRedeemPartner(): Observable<Response> {
		const request: Request = {
			liveURL: '/redeemPartner/rounding/list'
		}
		return this.rest.get(request)
	}

	getNumberDecimalFromRedeemPartner(): Observable<Response> {
		const request: Request = {
			liveURL: '/redeemPartner/numberDecimal/list'
		}
		return this.rest.get(request)
	}

	getAutoRedemptionList(): Observable<Response> {
		const request: Request = {
			liveURL: '/autoRedemption/list',
		}
		return this.rest.get(request)
	}

	getOnTheSpotRedemptionList(): Observable<Response> {
		const request: Request = {
			liveURL: '/onTheSpotParameter/list',
		}
		return this.rest.get(request)
	}

	// Waiver Management
	getWaiverManagementList(): Observable<Response> {
		const request: Request = {
			liveURL: '/waiver-management/list'
		}
		return this.rest.get(request)

	}

	viewAutoRedemption(params: AutoRedemptionViewRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/autoRedemption/view/' + params.id,
		}

		return this.rest.get(request)
	}

	editAutoRedemption(params: AutoRedemptionUpdateRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/autoRedemption/edit/' + params.id,
			body: params
		}

		return this.rest.post(request)
	}

	deleteAutoRedemption(params: AutoRedemptionDeleteRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/autoRedemption/delete/' + params.id,
			body: params
		}

		return this.rest.post(request)
	}


	viewOnTheSpotRedemption(params: OnTheSpotRedemptionViewRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/onTheSpotParameter/view/' + params.id,
		}
		return this.rest.get(request)
	}

	createOnTheSpotRedemption(params: OnTheSpotRedemptionCreateRequest): Observable<Response> {
		const request: Request = {
			body: params,
			liveURL: '/onTheSpotParameter/create',
		}
		return this.rest.post(request)
	}

	editOnTheSpotRedemption(params: OnTheSpotRedemptionUpdateRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/onTheSpotParameter/edit/' + params.id,
			body: params
		}

		return this.rest.post(request)
	}

	createAutoRedemption(params: AutoRedemptionCreateRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/autoRedemption/create',
			body: params,
		}

		return this.rest.post(request)
	}

	createWaiverManagement(params: WaiverManagementCreateRequest): Observable<Response> {
		const request: Request = {
			body: params,
			liveURL: '/waiver-management/create'
		}
		return this.rest.post(request)
	}

	getAutoRedemptionRewardPoolList(params: AutoRedemptionPolicy): Observable<Response> {
		const request: Request = {
			liveURL: '/autoRedemption/rewardPool/list' + `?${new URLSearchParams({ ...params })}`
		}

		return this.rest.get(request)
	}

	viewWaiverManagement(params: WaiverManagementViewRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/waiver-management/view/' + params.id
		}

		return this.rest.get(request)
	}

	getAutoRedemptionRedemptionTypeList(): Observable<Response> {
		const request: Request = {
			liveURL: '/autoRedemption/redemptionType/list',
		}

		return this.rest.get(request)
	}

	getOnTheSpotRedemptionRewardPoolAndGroupList(params: OnTheSpotPolicy): Observable<Response> {
		const request: Request = {
			liveURL: '/onTheSpotParameter/rewardPoolAndGroup/list' + `?${new URLSearchParams({ ...params })}`
		}

		return this.rest.get(request)
	}


	getAutoRedemptionCycleTypeList(): Observable<Response> {
		const request: Request = {
			liveURL: '/autoRedemption/cycleType/list',
		}
		return this.rest.get(request)
	}

	getAutoRedemptionBillingCycleTypeList(): Observable<Response> {
		const request: Request = {
			liveURL: '/autoRedemption/billingCycleType/list',
		}
		return this.rest.get(request)
	}

	getAutoRedemptionYearlyTypeList(): Observable<Response> {
		const request: Request = {
			liveURL: '/autoRedemption/yearlyType/list',
		}
		return this.rest.get(request)
	}

	getAutoRedemptionCappingLevelList(): Observable<Response> {
		const request: Request = {
			liveURL: '/autoRedemption/cappingLevel/list',
		}
		return this.rest.get(request)
	}

	editWaiverManagement(id: number, params: WaiverManagementEditRequest): Observable<Response> {
		const request: Request = {
			body: params,
			liveURL: '/waiver-management/update/' + id
		}

		return this.rest.post(request)
	}

	deleteWaiverManagement(params: WaiverManagementDeleteRequest): Observable<Response> {
		const request: Request = {
			body: params,
			liveURL: '/waiver-management/delete'
		}

		return this.rest.post(request)
	}

	getWaiverManagementData(): Observable<Response> {
		const request: Request = {
			liveURL: '/waiver-management/waiverManagementData'
		}

		return this.rest.get(request)
	}


	getOnTheSpotRedemptionProductTypeAndGroupList(params: OnTheSpotPolicy): Observable<Response> {
		const request: Request = {
			liveURL: '/onTheSpotParameter/productType/list' + `?${new URLSearchParams({ ...params })}`
		}

		return this.rest.get(request)
	}

	getCycleWeeklyList(): Observable<Response> {
		const request: Request = {
			liveURL: '/autoRedemption/cycleWeekly/list',
		}

		return this.rest.get(request)
	}

	getOnTheSpotRedemptionProductTypeGroupList(): Observable<Response> {
		const request: Request = {
			liveURL: '/onTheSpotParameter/option/list'
		}

		return this.rest.get(request)
	}


	getCycleMonthlyList(): Observable<Response> {
		const request: Request = {
			liveURL: '/autoRedemption/cycleMonthly/list',
		}

		return this.rest.get(request)
	}

	getOnTheSpotRedemptionGlRedemptionList(params: OnTheSpotPolicy): Observable<Response> {
		const request: Request = {
			liveURL: '/onTheSpotParameter/glRedemption/list' + `?${new URLSearchParams({ ...params })}`
		}

		return this.rest.get(request)
	}

	getCycleYearlyList(): Observable<Response> {
		const request: Request = {
			liveURL: '/autoRedemption/cycleYearly/list',
		}

		return this.rest.get(request)
	}

	duplicateAutoRedemption(id: number, params: AutoRedemptionDuplicateRequest): Observable<Response> {
		const request: Request = {
			body: params,
			liveURL: '/autoRedemption/duplicate/' + id
		}
		return this.rest.post(request)
	}

	createWaiver(id: number, params: WaiverCreateRequest): Observable<Response> {
		const request: Request = {
			body: params,
			liveURL: '/waiver-management/createWaiver/' + id
		}

		return this.rest.post(request)
	}

	updateWaiver(id: number, params: WaiverUpdateRequest): Observable<Response> {
		const request: Request = {
			body: params,
			liveURL: '/waiver-management/updateWaiver/' + id
		}

		return this.rest.post(request)
	}

	deleteWaiver(id: number, params: WaiverDeleteRequest): Observable<Response> {
		const request: Request = {
			body: params,
			liveURL: '/waiver-management/deleteWaiver/' + id
		}

		return this.rest.post(request)
	}

	duplicateWaiver(id: number, params: AssignedWaiverDuplicateRequest): Observable<Response> {
		const request: Request = {
			body: params,
			liveURL: '/waiver-management/duplicateWaiver/' + id
		}

		return this.rest.post(request)
	}

	deleteOnTheSpotRedemption(params: OnTheSpotRedemptionDeleteRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/onTheSpotParameter/delete/' + params.id
		}
		return this.rest.post(request)
	}

	// Redeem Rules
	getRedeemRulesList(): Observable<Response> {
		const request: Request = {
			liveURL: '/redeem-rules/list'
		}
		return this.rest.get(request)

	}

	viewRedeemRules(params: RedeemRulesViewRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/redeem-rules/view/' + params.id
		}

		return this.rest.get(request)
	}

	createRedeemRules(params: RedeemRulesCreateRequest): Observable<Response> {
		const request: Request = {
			body: params,
			liveURL: '/redeem-rules/create'
		}
		return this.rest.post(request)
	}

	editRedeemRules(id: number, params: RedeemRulesEditRequest): Observable<Response> {
		const request: Request = {
			body: params,
			liveURL: '/redeem-rules/update/' + id
		}

		return this.rest.post(request)
	}

	deleteRedeemRules(params: RedeemRulesDeleteRequest): Observable<Response> {
		const request: Request = {
			body: params,
			liveURL: '/redeem-rules/delete'
		}

		return this.rest.post(request)
	}

	getRedeemRulesData(params: RedeemRulesPolicy): Observable<Response> {
		const request: Request = {
			liveURL: '/redeem-rules/redeemRulesData' + `?${new URLSearchParams({ ...params })}`
		}

		return this.rest.get(request)
	}

	getRewardPoolGroupRewardPoolList(params: string[]): Observable<Response> {
		const request: Request = {
			liveURL: '/redeem-rules/rewardPoolGroupRewardPoolList?rewardPoolGroupIds=' + params.join('&rewardPoolGroupIds=')
		}

		return this.rest.get(request)
	}

	duplicateOnTheSpotRedemption(id: number, params: OnTheSpotRedemptionDuplicateRequest): Observable<Response> {
		const request: Request = {
			body: params,
			liveURL: '/onTheSpotParameter/duplicate/' + id,
		}
		return this.rest.post(request)
	}
}