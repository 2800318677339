<mat-dialog-content *ngIf="!!type">
    <div *ngIf="type === 'ADD_EDIT'">
      <label class="pd-btm-10px block">
        {{ title | translate }}
        {{ content | translate }}
      </label>
      <br />
      <form novalidate>
        <mat-form-field>
          <mat-label>{{ content | translate }}</mat-label>
          <input matInput name="txtInput" [formControl]="formState$" required maxlength="20" />
          <mat-error>{{ "ERROR.FIELD_CONTAIN_INVALID_CHARACTERS" | translate }}</mat-error>
        </mat-form-field>
      </form>
      <div fxLayout="row" fxLayoutAlign="end">
        <div class="mg-b-5px">
          <button class="mat-raised-button" (click)="cancel()"
            fxFlexOffset="8px">{{ 'ACTION.CANCEL' | translate }}</button>
          <button class="mat-raised-button mat-primary w-80px" (click)="submit(null)" [disabled]="isInvalidOrEqualToOldValue()"
            fxFlexOffset="8px">{{ 'ACTION.SUBMIT' | translate }}</button>
        </div>
      </div>
    </div>
  
    <div *ngIf="type === 'SHOW_ERROR'">
      <div class="block">
        <pre class="error-dialog-box-content">{{ txtInput }}</pre>
      </div>
      <div class="block ta-r of-hidden">
        <button class="mat-raised-button mat-primary w-80px" (click)="submit(null)"
            fxFlexOffset="8px">{{ 'ACTION.CLOSE' | translate }}</button>
      </div>
    </div>
  
    <div *ngIf="type === 'APPEND_REPLACE'">
      <div class="block">
        <pre class="error-dialog-box-content">{{ pendingList.join('\n') }}</pre>
      </div>
      <div class="block ta-r of-hidden">
        <button class="mat-raised-button mat-primary w-90px" (click)="submit('REPLACE')"
            fxFlexOffset="8px">{{ 'MCC_GROUP.DIALOG.REPLACE' | translate }}</button>
        <button class="mat-raised-button mat-primary w-90px" (click)="submit('APPEND')"
            fxFlexOffset="8px">{{ 'MCC_GROUP.DIALOG.APPEND' | translate }}</button>
        <label>&nbsp;</label>
      </div>
    </div>
  </mat-dialog-content>
