import { FieldMappingListResponse } from 'src/app/models/param-settings/field-mapping/field-mapping-list'
import { FieldMappingViewResponse } from 'src/app/models/param-settings/field-mapping/field-mapping-view'
import { updateGroup, validate, FormGroupState, createFormGroupState } from 'ngrx-forms'
import { maxLength, required } from 'ngrx-forms/validation'

export interface State {
	isLoading: boolean
	id?: string,
	fieldMappingListResponse: FieldMappingListResponse
	fieldMappingViewResponse: FieldMappingViewResponse
	fieldDetailForm: FormGroupState<FieldDetailForm>
	fieldDetailFormView: FieldDetailForm
}

export interface FieldDetailForm {
	fieldValue: string
}

export const validateFieldDetailForm = updateGroup<FieldDetailForm>({
	fieldValue: validate([maxLength(30)])
})

export const initialFieldDetailFormValue: FieldDetailForm = {
	fieldValue: ''
}

export const initialFieldDetailForm = createFormGroupState('fieldDetailForm', initialFieldDetailFormValue)

export const initialState: State = {
	isLoading: false,
	fieldMappingListResponse: {
		fieldMappings: []
	},
	fieldMappingViewResponse: {
		channel: '',
		indicators: [],
		descriptions: [],
		balances: [],
		dates: [],
		times: [],
		amounts: [],
		createdBy: '',
		createdDate: '',
		modifiedBy: '',
		modifiedDate: ''
	},
	fieldDetailForm: initialFieldDetailForm,
	fieldDetailFormView: initialFieldDetailFormValue
}
