import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Action, Store } from '@ngrx/store'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/internal/operators/map'
import { catchError, mergeMap, tap, withLatestFrom } from 'rxjs/operators'
import { LoginResponse, OauthTokenResponse } from 'src/app/models/authentication/login'
import { Response } from 'src/app/models/common/http'
import { BrowserStorage } from 'src/app/models/util/browser-storage'
import { AuthService } from 'src/app/services/authentication/auth.service'
import * as AppStore from 'src/app/store'
import * as CommonAction from '../../common/common/common.actions'
import * as LoginAction from './login.actions'

@Injectable()
export class Effects {

	constructor(
		private action$: Actions,
		private authService: AuthService,
		private store: Store<AppStore.State>
	) { }

	RestError$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(LoginAction.RestError),
			map(({ message, manualThrow }) => {
				return CommonAction.RestError({ message, manualThrow })
			})
		)
	)

	Login$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(LoginAction.Login),
			withLatestFrom(this.store.select(state => state.login)),
			mergeMap(([, login]) => this.authService.login(login.loginForm.value)
				.pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: LoginResponse = response.payload

					if (success) {
						return LoginAction.LoginSuccess({ payload })
					} else { return LoginAction.RestError(response as any) }
				})
					, catchError(this.catcher)
				)
			)
		)
	)

	LoginSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(LoginAction.LoginSuccess),
			tap(({ payload }) => {
				this.store.dispatch(CommonAction.SetLoginUser({ payload }))
			})
		), { dispatch: false }
	)

	ObtainTokenByAuthorizationCode$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(LoginAction.ObtainTokenByAuthorizationCode),
			mergeMap(({ payload }) => this.authService.obtainTokenByAuthorizationCode(payload)
				.pipe(map((response: Response) => {

					const success: boolean = response.success
					const respPayload: OauthTokenResponse = response.payload

					if (success) {
						const tokens: LoginResponse = {
							accessToken: respPayload.access_token,
							refreshToken: respPayload.refresh_token,
							message: 'Successful login.',
							authorities: respPayload.authorities
						}

						BrowserStorage.replaceStorageData({ payload: tokens })
						return LoginAction.LoginSuccess({ payload: tokens })
					} else { return LoginAction.RestError(response as any) }


				}), catchError(this.catcher)
				)
			)
		)
	)

	catcher = (error) => {
		return (of(LoginAction.RestError({ message: error.message, manualThrow: error.manualThrow })))
	}
}
