import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material'
import { Router } from '@angular/router'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Action, Store } from '@ngrx/store'
import { unbox } from 'ngrx-forms'
import { forkJoin, Observable, of } from 'rxjs'
import { catchError, map, mergeMap, take, tap, withLatestFrom } from 'rxjs/operators'
import { MessageDialogComponent } from 'src/app/components/common/dialog/message-dialog/message-dialog.component'
import { getDialogConfig } from 'src/app/models/common/dialog'
import { Response } from 'src/app/models/common/http'
import { GlRedemptionListResponse } from 'src/app/models/param-settings/gl-redemption/gl-redemption-list'
import { RewardPoolGroupViewResponse } from 'src/app/models/param-settings/reward-pool-group/reward-pool-group-view'
import { OnTheSpotRedemption } from 'src/app/models/redemption-settings/on-the-spot-redemption/on-the-spot-redemption'
import { OnTheSpotRedemptionCreateRequest } from 'src/app/models/redemption-settings/on-the-spot-redemption/on-the-spot-redemption-create'
import { ProductTypeAndGroup } from 'src/app/models/redemption-settings/on-the-spot-redemption/on-the-spot-redemption-product-list'
import { MerchantGroup, ProductTypeGroup } from 'src/app/models/redemption-settings/on-the-spot-redemption/on-the-spot-redemption-productGroup-list'
import { RewardPoolAndGroup } from 'src/app/models/redemption-settings/on-the-spot-redemption/on-the-spot-redemption-reward-list'
import { OnTheSpotRedemptionUpdateRequest } from 'src/app/models/redemption-settings/on-the-spot-redemption/on-the-spot-redemption-update'
import { OnTheSpotRedemptionViewResponse } from 'src/app/models/redemption-settings/on-the-spot-redemption/on-the-spot-redemption-view'
import { Util } from 'src/app/models/util/util'
import { ParameterSettingsService } from 'src/app/services/param-settings/parameter-settings.service'
import { RedemptionSettingsService } from 'src/app/services/redemption-settings/redemption-settings.service'
import * as AppStore from 'src/app/store/'
import * as CommonAction from '../../common/common/common.actions'
import * as OnTheSpotRedemptionAction from './on-the-spot-redemption.actions'
import { POLICY } from 'src/app/models/common/constant'
import { OnTheSpotRedemptionDuplicateComponent } from 'src/app/components/redemption-settings/on-the-spot-redemption/on-the-spot-redemption-duplicate/on-the-spot-redemption-duplicate.component'

@Injectable()
export class Effects {

	constructor(
		private action$: Actions,
		private router: Router,
		private dialog: MatDialog,
		private parameterSettingsService: ParameterSettingsService,
		private redemptionSettingsService: RedemptionSettingsService,
		private store: Store<AppStore.State>
	) { }

	InitialState$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(OnTheSpotRedemptionAction.InitialState),
			tap(() => {
				this.router.navigate(['redemption-settings/on-the-spot-redemption'])
			})
		), { dispatch: false }
	)

	RestError$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(OnTheSpotRedemptionAction.RestError),
			map(({ message, manualThrow }) => {
				return CommonAction.RestError({ message, manualThrow })
			})
		)
	)

	GoList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(OnTheSpotRedemptionAction.GoList),
			tap(() => {
				this.router.navigate(['redemption-settings/on-the-spot-redemption'])
			})
		), { dispatch: false }
	)

	GoView$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(OnTheSpotRedemptionAction.GoView),
			tap(() => {
				this.router.navigate(['redemption-settings/on-the-spot-redemption/view'])
			})
		), { dispatch: false }
	)

	GoCreate$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(OnTheSpotRedemptionAction.GoCreate),
			tap(() => {
				this.router.navigate(['redemption-settings/on-the-spot-redemption/create'])
			})
		), { dispatch: false }
	)

	GoUpdate$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(OnTheSpotRedemptionAction.GoUpdate),
			tap(() => {
				this.router.navigate(['redemption-settings/on-the-spot-redemption/update'])
			})
		), { dispatch: false }
	)

	List$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(OnTheSpotRedemptionAction.List),
			withLatestFrom(this.store.select(state => state.onTheSpotRedemption)),
			mergeMap(() => {
				return this.redemptionSettingsService.getOnTheSpotRedemptionList().pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: OnTheSpotRedemption[] = response.payload
					if (success) {
						return OnTheSpotRedemptionAction.ListSuccess({ payload })
					} else { return OnTheSpotRedemptionAction.RestError(response as any) }
				}),
					catchError((error) => {
						return (of(OnTheSpotRedemptionAction.RestError({ message: error.message, manualThrow: true })))
					})
				)
			})
		)
	)

	View$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(OnTheSpotRedemptionAction.View),
			withLatestFrom(this.store.select(state => state.onTheSpotRedemption)),
			mergeMap(([, onTheSpotRedemption]) => this.redemptionSettingsService.viewOnTheSpotRedemption({ id: onTheSpotRedemption.id } as OnTheSpotRedemptionViewResponse)
				.pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: OnTheSpotRedemptionViewResponse = response.payload
					if (success) {
						return OnTheSpotRedemptionAction.ViewSuccess({ payload })
					} else { return OnTheSpotRedemptionAction.RestError(response as any) }
				}), catchError(this.catcher))
			)
		)
	)

	OnChangeRewardPoolGroup$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(OnTheSpotRedemptionAction.OnChangeRewardPoolGroup),
			withLatestFrom(this.store.select(state => state.onTheSpotRedemption)),
			mergeMap(([x, onTheSpotRedemption]) => {
				return this.parameterSettingsService.viewRewardPoolGroup({ id: onTheSpotRedemption.onTheSpotRedemptionDetailForm.value.rewardPoolId.toString() }).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: RewardPoolGroupViewResponse = response.payload
					if (success) {
						return OnTheSpotRedemptionAction.OnChangeRewardPoolGroupSuccess({ payload })
					} else { return OnTheSpotRedemptionAction.RestError(response as any) }
				}), catchError(this.catcher))
			})
		)
	)

	InitialLoadList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(OnTheSpotRedemptionAction.InitialLoadList),
			mergeMap(x => forkJoin([
				this.redemptionSettingsService
					.getOnTheSpotRedemptionRewardPoolAndGroupList({ policy: x.payload as POLICY })
					.pipe(take(1)),
				this.redemptionSettingsService
					.getOnTheSpotRedemptionProductTypeAndGroupList({ policy: x.payload as POLICY })
					.pipe(take(1)),
				this.redemptionSettingsService
					.getOnTheSpotRedemptionProductTypeGroupList()
					.pipe(take(1)),
				this.redemptionSettingsService
					.getOnTheSpotRedemptionGlRedemptionList({ policy: x.payload as POLICY })
					.pipe(take(1)),
				]).pipe(
					mergeMap((value) => {
						const [rewardPoolResp, productTypeAndGroupListResp, productTypeGroupListResp, glRedemptionListResp]: Response[] = value
						const responses: Action[] = []
						if (rewardPoolResp.success) {
							responses.push(OnTheSpotRedemptionAction.RewardPoolAndGroupListSuccess({ payload: rewardPoolResp.payload }))
						}
						if (productTypeAndGroupListResp.success) {
							responses.push(OnTheSpotRedemptionAction.ProductTypeAndGroupListSuccess({ payload: productTypeAndGroupListResp.payload.productTypes }))
						}
						if (productTypeGroupListResp.success) {
							responses.push(OnTheSpotRedemptionAction.ProductTypeGroupListSuccess({ payload: productTypeGroupListResp.payload.productTypes }))
							responses.push(OnTheSpotRedemptionAction.MerchantGroupListSuccess({ payload: productTypeGroupListResp.payload.merchants }))
						}
						if (glRedemptionListResp.success) {
							responses.push(OnTheSpotRedemptionAction.GlRedemptionListSuccess({ payload: glRedemptionListResp.payload }))
						}
						const fail = value
							.filter(v => !v.success)
							.map(v => OnTheSpotRedemptionAction.RestError(v as any) )

						return [...responses, ...fail]
						}
					),
					catchError(this.catcher))
				)
			),
	)

	MerchantGroupList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(OnTheSpotRedemptionAction.MerchantGroupList),
			withLatestFrom(this.store.select(state => state.onTheSpotRedemption)),
			mergeMap(() => {
				return this.redemptionSettingsService.getOnTheSpotRedemptionProductTypeGroupList().pipe(map((response: Response) => {
					const success: boolean = response.success

					if (success) {
						const payload: MerchantGroup[] = response.payload.merchants
						return OnTheSpotRedemptionAction.MerchantGroupListSuccess({ payload })
					} else { return OnTheSpotRedemptionAction.RestError(response as any) }
				}), catchError(this.catcher))
			})
		)
	)

	RewardPoolAndGroupList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(OnTheSpotRedemptionAction.RewardPoolAndGroupList),
			withLatestFrom(this.store.select(state => state.onTheSpotRedemption)),
			mergeMap(([x, onTheSpotRedemption]) => {
				return this.redemptionSettingsService.getOnTheSpotRedemptionRewardPoolAndGroupList({policy: x.payload as POLICY}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: RewardPoolAndGroup[] = response.payload
					if (success) {
						return OnTheSpotRedemptionAction.RewardPoolAndGroupListSuccess({ payload })
					} else { return OnTheSpotRedemptionAction.RestError(response as any) }
				}), catchError(this.catcher))
			})
		)
	)

	ProductTypeAndGroupList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(OnTheSpotRedemptionAction.ProductTypeAndGroupList),
			withLatestFrom(this.store.select(state => state.onTheSpotRedemption)),
			mergeMap(([x, onTheSpotRedemption]) => {
				return this.redemptionSettingsService.getOnTheSpotRedemptionProductTypeAndGroupList({policy: x.payload as POLICY}).pipe(map((response: Response) => {
					const success: boolean = response.success

					if (success) {
						const payload: ProductTypeAndGroup[] = response.payload.productTypes
						return OnTheSpotRedemptionAction.ProductTypeAndGroupListSuccess({ payload })
					} else { return OnTheSpotRedemptionAction.RestError(response as any) }
				}), catchError(this.catcher))
			})
		)
	)

	ProductTypeGroupList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(OnTheSpotRedemptionAction.ProductTypeGroupList),
			withLatestFrom(this.store.select(state => state.onTheSpotRedemption)),
			mergeMap(() => {
				return this.redemptionSettingsService.getOnTheSpotRedemptionProductTypeGroupList().pipe(map((response: Response) => {
					const success: boolean = response.success

					if (success) {
						const payload: ProductTypeGroup[] = response.payload.productTypes
						return OnTheSpotRedemptionAction.ProductTypeGroupListSuccess({ payload })
					} else { return OnTheSpotRedemptionAction.RestError(response as any) }
				}), catchError(this.catcher))
			})
		)
	)

	GlRedemptionList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(OnTheSpotRedemptionAction.GlRedemptionList),
			withLatestFrom(this.store.select(state => state.onTheSpotRedemption)),
			mergeMap(([x, onTheSpotRedemption]) => {
				return this.redemptionSettingsService.getOnTheSpotRedemptionGlRedemptionList({policy: x.payload as POLICY}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: GlRedemptionListResponse = response.payload
					if (success) {
						return OnTheSpotRedemptionAction.GlRedemptionListSuccess({ payload })
					} else { return OnTheSpotRedemptionAction.RestError(response as any) }
				}), catchError(this.catcher))
			})
		)
	)

	GoBackDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(OnTheSpotRedemptionAction.GoBackDialog),
			mergeMap(({ action }) => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.BACK', payload: action }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					if (result.payload === 'LIST') {
						return OnTheSpotRedemptionAction.GoList()
					}
					if (result.payload === 'VIEW') {
						return OnTheSpotRedemptionAction.GoView()
					}
				} else {
					return OnTheSpotRedemptionAction.closeDialog()
				}
			})
		)
	)

	CreateDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(OnTheSpotRedemptionAction.CreateDialog),
			mergeMap(() => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.CREATE_ON_THE_SPOT_REDEMPTION' }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return OnTheSpotRedemptionAction.Create()
				} else {
					return OnTheSpotRedemptionAction.closeDialog()
				}
			})
		)
	)

	Create$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(OnTheSpotRedemptionAction.Create),
			withLatestFrom(this.store.select(state => state.onTheSpotRedemption)),
			mergeMap(([, onTheSpotRedemption]) => {
				const form = onTheSpotRedemption.onTheSpotRedemptionDetailForm.controls
				const otsRewardPoolsVal = form.otsRewardPools.value
				const rewardPoolAndGlRedemption = []

				otsRewardPoolsVal.allIds.forEach(id => {
					if (otsRewardPoolsVal.byId.hasOwnProperty(id)) {
						const byId = otsRewardPoolsVal.byId[id]
						const formVal = byId.form.value

						rewardPoolAndGlRedemption.push({
							rewardPoolId: formVal.rewardPoolId,
							glRedemptionId: formVal.glRedemptionId
						})
					}
				})

				const payload: OnTheSpotRedemptionCreateRequest = {
					code: form.code.value,
					name: form.name.value,
					glRedemptionId: form.glRedemptionId.value,
					startDate: Util.fromISOStringToDateStr(form.startDate.value),
					endDate: Util.fromISOStringToDateStr(form.endDate.value),
					rewardPoolId: form.rewardPoolAndGroup.value != null ? Number(form.rewardPoolAndGroup.value.split('_')[1]) : null,
					rewardPoolType: form.rewardPoolAndGroup.value != null ? form.rewardPoolAndGroup.value.split('_')[0] : null,
					rewardPoolAndGlRedemption,
					onSpotCode: form.onSpotCode.value,
					productType: unbox(form.productType.value) && unbox(form.productType.value).toString(),
					productTypeGroup: unbox(form.productTypeGroup.value) && unbox(form.productTypeGroup.value).toString(),
					merchantPayout: Number(form.merchantPayout.value),
					merchantIdRange: unbox(form.merchantIdRange.value) && unbox(form.merchantIdRange.value).toString(),
					redemptionLimit: Number(form.redemptionLimit.value),
					redemptionPoint: Number(form.redemptionPoint.value),
					amount: Number(form.amount.value),
					createdBy: form.createdBy.value,
					createdDate: form.createdDate.value,
					modifiedBy: form.modifiedBy.value,
					modifiedDate: form.modifiedDate.value
				}

				return this.redemptionSettingsService.createOnTheSpotRedemption(payload)
					.pipe(map((response: Response) => {
						const success: boolean = response.success
						const respPayload: string = response.payload
						if (success) {
							return OnTheSpotRedemptionAction.CreateSuccess({ payload: respPayload })
						} else { return OnTheSpotRedemptionAction.RestError(response as any) }
					}), catchError(this.catcher))
			})
		)
	)

	CreateSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(OnTheSpotRedemptionAction.CreateSuccess),
			mergeMap(({ payload }) => {
				return [OnTheSpotRedemptionAction.GoList(), CommonAction.RestError({ message: payload })]
			})
		)
	)

	UpdateDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(OnTheSpotRedemptionAction.UpdateDialog),
			mergeMap(() => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.UPDATE' }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return OnTheSpotRedemptionAction.Update()
				} else {
					return OnTheSpotRedemptionAction.closeDialog()
				}
			})
		)
	)

	Update$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(OnTheSpotRedemptionAction.Update),
			withLatestFrom(this.store.select(state => state.onTheSpotRedemption)),
			mergeMap(([, onTheSpotRedemption]) => {
				const form = onTheSpotRedemption.onTheSpotRedemptionDetailForm.controls
				const otsRewardPoolsVal = form.otsRewardPools.value
				const rewardPoolAndGlRedemption = []

				otsRewardPoolsVal.allIds.forEach(id => {
					if (otsRewardPoolsVal.byId.hasOwnProperty(id)) {
						const byId = otsRewardPoolsVal.byId[id]
						const formVal = byId.form.value

						rewardPoolAndGlRedemption.push({
							rewardPoolId: formVal.rewardPoolId,
							glRedemptionId: formVal.glRedemptionId
						})
					}
				})

				const payload: OnTheSpotRedemptionUpdateRequest = {
					id: Number(form.id.value),
					code: form.code.value,
					name: form.name.value,
					glRedemptionId: form.glRedemptionId.value,
					startDate: Util.fromISOStringToDateStr(form.startDate.value),
					endDate: Util.fromISOStringToDateStr(form.endDate.value),
					rewardPoolId: form.rewardPoolAndGroup.value != null ? Number(form.rewardPoolAndGroup.value.split('_')[1]) : null,
					rewardPoolType: form.rewardPoolAndGroup.value != null ? form.rewardPoolAndGroup.value.split('_')[0] : null,
					rewardPoolAndGlRedemption,
					onSpotCode: form.onSpotCode.value,
					productType: unbox(form.productType.value) && unbox(form.productType.value).toString(),
					productTypeGroup: unbox(form.productTypeGroup.value) && unbox(form.productTypeGroup.value).toString(),
					merchantPayout: Number(form.merchantPayout.value),
					merchantIdRange: unbox(form.merchantIdRange.value) && unbox(form.merchantIdRange.value).toString(),
					redemptionLimit: Number(form.redemptionLimit.value),
					redemptionPoint: Number(form.redemptionPoint.value),
					amount: Number(form.amount.value),
					createdBy: form.createdBy.value,
					createdDate: form.createdDate.value,
					modifiedBy: form.modifiedBy.value,
					modifiedDate: form.modifiedDate.value
				}

				return this.redemptionSettingsService.editOnTheSpotRedemption(payload)
					.pipe(map((response: Response) => {
						const success: boolean = response.success
						const respPayload: string = response.payload
						if (success) {
							return OnTheSpotRedemptionAction.UpdateSuccess({ payload: respPayload })
						} else { return OnTheSpotRedemptionAction.RestError(response as any) }
					}), catchError(this.catcher))
			})
		)
	)

	UpdateSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(OnTheSpotRedemptionAction.UpdateSuccess),
			mergeMap(({ payload }) => {
				return [OnTheSpotRedemptionAction.GoView(), CommonAction.RestError({ message: payload })]
			})
		)
	)

	DeleteDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(OnTheSpotRedemptionAction.DeleteDialog),
			mergeMap(() => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.DELETE_ON_THE_SPOT_REDEMPTION' }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return OnTheSpotRedemptionAction.Delete()
				} else {
					return OnTheSpotRedemptionAction.closeDialog()
				}
			})
		)
	)

	Delete$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(OnTheSpotRedemptionAction.Delete),
			withLatestFrom(this.store.select(state => state.onTheSpotRedemption)),
			mergeMap(([, onTheSpotRedemption]) => this.redemptionSettingsService.deleteOnTheSpotRedemption({
				id: onTheSpotRedemption.id
			}).pipe(map((response: Response) => {
				const success: boolean = response.success
				const payload: string = response.payload

				if (success) {
					return OnTheSpotRedemptionAction.DeleteSuccess({ payload })
				} else { return OnTheSpotRedemptionAction.RestError(response as any) }
			}), catchError(this.catcher)
			))
		)
	)

	DeleteSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(OnTheSpotRedemptionAction.DeleteSuccess),
			mergeMap(({ payload }) => {
				return [OnTheSpotRedemptionAction.GoList(), CommonAction.RestError({ message: payload })]
			})
		)
	)

	DuplicateDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(OnTheSpotRedemptionAction.DuplicateDialog),
			mergeMap(() => {
				const dialogRef = this.dialog.open(OnTheSpotRedemptionDuplicateComponent, getDialogConfig({}))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return OnTheSpotRedemptionAction.Duplicate()
				} else {
					return OnTheSpotRedemptionAction.closeDialog()
				}
			})
		)
	)

	Duplicate$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(OnTheSpotRedemptionAction.Duplicate),
			withLatestFrom(this.store.select(state => state.onTheSpotRedemption)),
			mergeMap(([, onTheSpotRedemption]) => {
				const onTheSpotRedemptionDuplicateForm = onTheSpotRedemption.onTheSpotRedemptionDuplicateForm.value
				return this.redemptionSettingsService.duplicateOnTheSpotRedemption(parseInt(onTheSpotRedemption.id, 10), {
					code: onTheSpotRedemptionDuplicateForm.code,
					name: onTheSpotRedemptionDuplicateForm.name,
					merchantIdRange: unbox(onTheSpotRedemptionDuplicateForm.merchantIdRange.value).toString()
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload = response.payload

					if (success) {
						return OnTheSpotRedemptionAction.DuplicateSuccess({payload})
					} else {
						return OnTheSpotRedemptionAction.RestError(response as any)
					}
				}), catchError(this.catcher))
			})
		)
	)

	DuplicateSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(OnTheSpotRedemptionAction.DuplicateSuccess),
			mergeMap(({ payload }) => {
				return [
					OnTheSpotRedemptionAction.List(),
					CommonAction.RestError({ message: payload })
				]
			})
		)
	)

	catcher = (error) => {
		return (of(OnTheSpotRedemptionAction.RestError({ message: error.message, manualThrow: error.manualThrow })))
	}
}
