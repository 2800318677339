import { Component, Input, OnInit } from '@angular/core'
import { ValidationErrors } from '@angular/forms'

@Component({
	selector: 'app-error-validation',
	templateUrl: './error-validation.component.html',
	styleUrls: ['./error-validation.component.scss']
})
export class ErrorValidationComponent implements OnInit {

	@Input() errors: ValidationErrors
	// parameter
	@Input() min: number
	@Input() max: number
	@Input() minlength: number
	@Input() maxlength: number
	@Input() digitFrom: number
	@Input() digitTo: number
	@Input() month: number

	@Input() integerPoints: number
	@Input() decimalPoints: number

	@Input() sameString: string

	constructor() { }

	ngOnInit() { }

	getErrorMessage(): string {
		if (!this.errors) { return '' }

		if (this.errors.matDatepickerParse) {
			return 'ERROR.FIELD_DATE'
		} else if (this.errors.required) {
			return 'ERROR.FIELD_REQUIRED'
		} else if (this.errors.requiredIf) {
			return 'ERROR.FIELD_REQUIRED'
		} else if (this.errors.min) {
			return 'ERROR.FIELD_MIN'
		} else if (this.errors.max) {
			return 'ERROR.FIELD_MAX'
		} else if (this.errors.minlength) {
			return 'ERROR.FIELD_MINLENGTH'
		} else if (this.errors.minLength) {
			return 'ERROR.FIELD_MINLENGTH'
		} else if (this.errors.maxlength) {
			return 'ERROR.FIELD_MAXLENGTH'
		} else if (this.errors.maxLength) {
			return 'ERROR.FIELD_MAXLENGTH'
		} else if (this.errors.email) {
			return 'ERROR.FIELD_EMAIL'
		} else if (this.errors.phoneNumber) {
			return 'ERROR.FIELD_PHONE'
		} else if (this.errors.date) {
			return 'ERROR.FIELD_DATE'
		} else if (this.errors.dateRange) {
			return 'ERROR.FIELD_DATE_RANGE'
		} else if (this.errors.passwordNotMatch) {
			return 'ERROR.FIELD_PASSWORD_MATCH'
		} else if (this.errors.maxPreAccRequired) {
			return 'ERROR.FIELD_DATE_RANGE'
		} else if (this.errors.number) {
			return 'ERROR.FIELD_DATA_TYPE_INVALID'
		} else if (this.errors.digit) {
			return 'ERROR.FIELD_DATA_TYPE_INVALID'
		} else if (this.errors.digitRange) {
			return 'ERROR.FIELD_DIGIT_RANGE'
		} else if (this.errors.range) {
			return 'ERROR.FIELD_DATA_TYPE_INVALID'
		} else if (this.errors.duplicate) {
			return 'ERROR.FIELD_DUPLICATE_FOUND'
		} else if (this.errors.alphaNumericWithSpaces) {
			return 'ERROR.FIELD_NOT_ALPHANUMERIC_WITH_SPACES'
		} else if (this.errors.alphaNumericWithoutSpaces) {
			return 'ERROR.FIELD_NOT_ALPHANUMERIC_WITH_SPACES'
		} else if (this.errors.numberWithoutDecimal) {
			return 'ERROR.FIELD_NOT_NUMBER_WITHOUT_DECIMAL'
		} else if (this.errors.numberWithDecimal) {
			return 'ERROR.FIELD_NOT_NUMBER_WITH_DECIMAL'
		} else if (this.errors.integerAndDecimal) {
			return 'ERROR.FIELD_INTEGER_AND_DECIMAL_CHECK'
		} else if (this.errors.fieldValueCheck) {
			return 'ERROR.FIELD_FIELD_VALUE_CHECK'
		} else if (this.errors.notEvergreenValidator) {
			return 'ERROR.FIELD_REWARD_POOL_EXPCYCLETYPE_AND_EXPCYCLE_IS_NOT_EVERGREEN'
		} else if (this.errors.NotStartWithZero) {
			return 'ERROR.FIELD_REWARD_POOL_EXPCYCLETYPE_AND_EXPCYCLE_IS_NOT_EVERGREEN'
		} else if (this.errors.percentageRange) {
			return 'ERROR.FIELD_PERCENTAGE_RANGE'
		} else if (this.errors.lessThan) {
			return 'ERROR.FIELD_LESS_THAN'
		} else if (this.errors.lessThanOrEqualTo) {
			return 'ERROR.FIELD_LESS_THAN_OR_EQUAL_TO'
		} else if (this.errors.moreThan) {
			return 'ERROR.FIELD_MORE_THAN'
		} else if (this.errors.moreThanOrEqualTo) {
			return 'ERROR.FIELD_MORE_THAN_OR_EQUAL_TO'
		} else if (this.errors.previousTo) {
			return 'ERROR.FIELD_PREVIOUS_TO'
		} else if (this.errors.nextFrom) {
			return 'ERROR.FIELD_NEXT_FROM'
		} else if (this.errors.pobox) {
			return 'ERROR.FIELD_PO_BOX'
		} else if (this.errors.dateBetween) {
			return 'ERROR.FIELD_DATE_BETWEEN'
		} else if (this.errors.invalidAirmileId) {
			return 'ERROR.FIELD_INVALID_AIRMILE_ID'
		} else if (this.errors.numericMatchLength) {
			return 'ERROR.FIELD_NUMERIC_MATCH_LENGTH'
		} else if (this.errors.numericUpToLength) {
			return 'ERROR.FIELD_NUMERIC_UP_TO_LENGTH'
		} else if (this.errors.alphaNumericMatchLength) {
			return 'ERROR.FIELD_ALPHANUMERIC_MATCH_LENGTH'
		} else if (this.errors.invalidEmail) {
			return 'ERROR.FIELD_EMAIL'
		} else if (this.errors.notAllowedSpace) {
			return 'ERROR.FIELD_NOT_ALLOWED_SPACE'
		} else if (this.errors.sameString) {
			return 'ERROR.FIELD_NOT_SAME'
		} else if (this.errors.invalidInputRange) {
			return 'ERROR.FIELD_INVALID_RANGE'
		} else if (this.errors.firstPositionContainSpace) {
			return 'ERROR.FIELD_FIRST_POSITION_CONTAIN_SPACE'
		} else if (this.errors.containInvalidCharacters) {
			return 'ERROR.FIELD_CONTAIN_INVALID_CHARACTERS'
		} else {
			return ''
		}
	}

	getParam(): string {
		if (!this.errors) { return '' }

		if (this.errors.min) {
			return '{value: ' + this.min + '}'
		} else if (this.errors.max) {
			return '{value: ' + this.max + '}'
		} else if (this.errors.minlength) {
			return '{value: ' + this.minlength + '}'
		} else if (this.errors.maxlength) {
			return '{value: ' + this.maxlength + '}'
		} else if (this.errors.minLength) {
			return '{value: ' + this.minlength + '}'
		} else if (this.errors.maxLength) {
			return '{value: ' + this.maxlength + '}'
		} else if (this.errors.digitRange) {
			return '{from: ' + this.digitFrom + ', to: ' + this.digitTo + '}'
		} else if (this.errors.dateBetween) {
			return '{value: ' + this.month + '}'
		} else if (this.errors.numberWithDecimal) {
			return '{value: ' + this.errors.decimal + '}'
		} else if (this.errors.integerAndDecimal) {
			return JSON.stringify({ integerPoints: this.errors.integer, decimalPoints: this.errors.decimal })
		} else if (this.errors.fieldValueCheck) {
			return JSON.stringify({ fieldA: this.errors.fieldA, fieldB: this.errors.fieldB, match: this.errors.match })
		} else if (this.errors.numericMatchLength) {
			return JSON.stringify({ length: this.errors.numericLength })
		} else if (this.errors.numericUpToLength) {
			return JSON.stringify({ length: this.errors.numericLength })
		} else if (this.errors.alphaNumericMatchLength) {
			return JSON.stringify({ length: this.errors.alphaNumericLength })
		} else if (this.errors.sameString) {
			return JSON.stringify({ sameStringLabel: this.errors.sameStringLabel })
		} else if (this.errors.invalidInputRange) {
			return JSON.stringify({ to: this.errors.to, from: this.errors.from })
		} else {
			return ''
		}
	}

}
