import { createAction, props } from '@ngrx/store'
import { GroupListResponse } from 'src/app/models/access-management/group/group-list'
import { GroupViewResponse } from 'src/app/models/access-management/group/group-view'
import { PolicyListResponse } from 'src/app/models/access-management/policy/policy-list'

export const InitialState = createAction('[Group] InitialState')
export const Reset = createAction('[Group] Reset')
export const SetId = createAction('[Group] SetId', props<{ groupId: number }>())

export const GoList = createAction('[Group] GoList')
export const GoView = createAction('[Group] GoView')
export const GoCreate = createAction('[Group] GoCreate')
export const GoEdit = createAction('[Group] GoEdit')

export const GoBackDialog = createAction('[Group] GoBackDialog', props<{ action: string }>())
export const CreateDialog = createAction('[Group] CreateDialog')
export const UpdateDialog = createAction('[Group] UpdateDialog')
export const DeleteDialog = createAction('[Group] DeleteDialog')
export const AssignPolicyDialog = createAction('[Group] AssignPolicyDialog')
export const closeDialog = createAction('[Group] closeDialog')

export const RestError = createAction('[Group] RestError', props<{ message: string, manualThrow: boolean }>())

export const List = createAction('[Group] List')
export const ListSuccess = createAction('[Group] ListSuccess', props<{ payload: GroupListResponse }>())

export const View = createAction('[Group] View')
export const ViewSuccess = createAction('[Group] ViewSuccess', props<{ payload: GroupViewResponse }>())

export const Create = createAction('[Group] Create')
export const CreateSuccess = createAction('[Group] CreateSuccess', props<{ payload: string }>())

export const Edit = createAction('[Group] Edit')
export const EditSuccess = createAction('[Group] EditSuccess', props<{ payload: string }>())

export const Delete = createAction('[Group] Delete')
export const DeleteSuccess = createAction('[Group] DeleteSuccess')

export const onPagination = createAction('[Group] onPagination', props<{ pageNum: number, pageSize: number }>())

export const AssignPolicyList = createAction('[Group] AssignPolicyList')
export const AssignPolicyListSuccess = createAction('[Group] AssignPolicyListSuccess', props<{ payload: PolicyListResponse }>())

export const RemovePolicyFromGroup = createAction('[Group] RemovePolicyFromGroup', props<{ groupId: number, policyId: number }>())
export const RemovePolicyFromGroupSuccess = createAction('[Group] RemovePolicyFromGroupSuccess', props<{ message: string }>())
export const AssignMultiPolicyToGroup = createAction('[Group] AssignMultiPolicyToGroup', props<{ policyIdList: any }>())
export const AssignPolicyToGroup = createAction('[Group] AssignPolicyToGroup', props<{ policyId: number }>())
export const AssignPolicyToGroupSuccess = createAction('[Group] AssignPolicyToGroupSuccess')
export const SuccessPolicyToGroup = createAction('[Group] SuccessPolicyToGroup', props<{ policyId: number }>())
export const FailedPolicyToGroup = createAction('[Group] FailedPolicyToGroup', props<{ policyId: number, message: string }>())

export const DuplicateDialog = createAction('[Group] DuplicateDialog')
export const Duplicate = createAction('[Group] Duplicate')
export const DuplicateSuccess = createAction('[Group] DuplicateSuccess', props<{ payload: string }>())
export const CloseDialog = createAction('[Group] CloseDialog')
export const OpenConfirmationDialog = createAction('[Group] OpenConfirmationDialog')
