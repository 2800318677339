import {Component, Inject, OnDestroy} from '@angular/core'
import {Store} from '@ngrx/store'
import * as AppStore from 'src/app/store'
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material'
import {Observable, Subscription} from 'rxjs'
import {FormGroupState} from 'ngrx-forms'
import {RewardPoolDuplicateForm} from '../../../../store/param-settings/reward-pool'
import * as RewardPoolSelectors from 'src/app/store/param-settings/reward-pool/reward-pool.selectors'
import * as RewardPoolAction from 'src/app/store/param-settings/reward-pool/reward-pool.actions'

@Component({
	selector: 'app-reward-pool-duplicate',
	templateUrl: './reward-pool-duplicate.component.html',
	styleUrls: ['./reward-pool-duplicate.component.scss']
})
export class RewardPoolDuplicateComponent implements OnDestroy {

	isLoading = false
	formState$: Observable<FormGroupState<RewardPoolDuplicateForm>>

	sub: Subscription

	constructor(
		private store: Store<AppStore.State>,
		private dialogRef: MatDialogRef<RewardPoolDuplicateComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		this.sub = this.store.select(RewardPoolSelectors.getRewardPool).subscribe(({isDuplicateLoading, isDuplicateDialog}) => {
			this.isLoading = isDuplicateLoading

			if (isDuplicateDialog) {
				this.cancel()
			}
		})

		this.formState$ = this.store.select(({rewardPool}) => rewardPool.rewardPoolDuplicateForm)
	}

	ngOnDestroy() {
		this.sub.unsubscribe()
	}

	cancel() {
		this.dialogRef.close()
	}

	submit() {
		this.store.dispatch(RewardPoolAction.Duplicate())
	}
}
