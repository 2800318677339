<form novalidate [ngrxFormState]="formState$ | async">
  <div *ngIf="isView">
    <mat-form-field fxFlex>
      <input matInput value="{{(formState$ | async).controls.value.value}}" type="text" maxlength="255" readonly>
    </mat-form-field>
  </div>

  <div *ngIf="!isView">
    <mat-form-field fxFlex>
      <input matInput [ngrxFormControlState]="(formState$ | async)?.controls.value" type="text" maxlength="255">
      <mat-icon class="material-icons-outlined" matSuffix matTooltip="{{ 'CAMPAIGN.INFO.SEGMENT' | translate }}">info
      </mat-icon>
      <mat-error *ngIf="(formState$ | async).errors._value">
        <app-error-validation [errors]="(formState$ | async).errors._value" [maxlength]="255">
        </app-error-validation>
      </mat-error>
    </mat-form-field>
  </div>
</form>