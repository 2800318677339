import { createFeatureSelector, createSelector } from '@ngrx/store'
import * as ConditionalReward from './conditional-reward.state'

const conditionalReward = createFeatureSelector<ConditionalReward.State>('conditionalReward')

export const getConditionalReward = createSelector(conditionalReward, x => x)

export const getTiers = createSelector(conditionalReward, ({ conditionalRewardDetailForm }): ConditionalReward.Tier[] => {
	let result = []
	const list = conditionalRewardDetailForm.controls.tiers.value

	if (list && list.allIds) {
		result = list.allIds.map(id => list.byId[id])
	}

	return result
})

export const getTier = createSelector(conditionalReward, ({ conditionalRewardDetailForm }, id: string) => {
	const tiers = conditionalRewardDetailForm.controls.tiers.value

	const form: ConditionalReward.Tiers = {
		byId: {
			...tiers.byId
		},
		allIds: {
			...tiers.allIds
		}
	}

	return form && form.byId[id] && form.byId[id].form
})

export const getTierCampaign = createSelector(conditionalReward, ({ conditionalRewardDetailForm }, id: string) => {
	const tiers = conditionalRewardDetailForm.controls.tiers.value

	const form: ConditionalReward.Tiers = {
		byId: {
			...tiers.byId
		},
		allIds: {
			...tiers.allIds
		}
	}

	return form && form.byId[id] && form.byId[id].form && form.byId[id].campaigns
})
