import { Directive, OnDestroy, OnInit } from '@angular/core'
import * as AppStore from 'src/app/store'
import * as WorklistTaskAction from 'src/app/store/worklist/checker-maker-function'
import { Store } from '@ngrx/store'
import * as ActionBarAction from 'src/app/store/common/action-bar/action-bar.actions'


@Directive({
	selector: '[appCheckerMakerModule]'
})
export class CheckerMakerModuleDirective implements OnInit, OnDestroy {

	private fromWhere = ''

	constructor(
		private store: Store<AppStore.State>
	) { }

	ngOnInit() {
		this.store.dispatch(WorklistTaskAction.ShowWorklistFunction())
		this.store.select(WorklistTaskAction.getWorklistTask).subscribe(x => {
			this.fromWhere = x.from
		})
		switch (this.fromWhere) {
			case 'tasklist':
				this.store.dispatch(ActionBarAction.FromTaskListView())
				break
			case 'my-request':
				this.store.dispatch(ActionBarAction.FromMyRequestView())
				break
		}
	}

	ngOnDestroy() {
		this.store.dispatch(WorklistTaskAction.ResetWorklistTask())
	}
}
