<form novalidate [ngrxFormState]="formState$ | async" fxLayout="column">
  <div *ngIf="isView" fxLayout="column">
    <mat-form-field fxFlex>
      <input matInput value="{{getIncludeExcludeValue((formState$ | async).controls.equation.value)}}" type="text"
        maxlength="255" readonly>
    </mat-form-field>

    <mat-form-field fxFlex>
      <input matInput value="{{getAccountValue((formState$ | async).controls.values.value)}}" type="text" maxlength="255"
        readonly>
    </mat-form-field>
  </div>

  <div *ngIf="!isView" fxLayout="column">
    <mat-radio-group [ngrxFormControlState]="(formState$ | async).controls.equation" color="primary" fxLayoutGap="8px">
      <mat-radio-button *ngFor="let i of includeExcludeList" [value]="i.key">
        {{i.value}}
      </mat-radio-button>
    </mat-radio-group>

    <mat-form-field fxFlex>
      <mat-select [ngrxFormControlState]="(formState$ | async).controls.values" multiple>
        <mat-option *ngFor="let i of accountList" [value]="i.key">
          {{i.value}}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="(formState$ | async).errors._values">
        <app-error-validation [errors]="(formState$ | async).errors._values" [maxlength]="255">
        </app-error-validation>
      </mat-error>
    </mat-form-field>
  </div>
</form>