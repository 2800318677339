import { Action, createReducer, on } from '@ngrx/store'
import { createFormGroupState, formGroupReducer, markAsTouched, reset, setValue } from 'ngrx-forms'
import * as RewardPoolGroupAction from './reward-pool-group.actions'
import * as RewardPoolGroupState from './reward-pool-group.state'
import { RewardPoolGroupViewResponse } from 'src/app/models/param-settings/reward-pool-group/reward-pool-group-view'

export const reducer = createReducer(
	RewardPoolGroupState.initialState,
	on(RewardPoolGroupAction.InitialState, () => RewardPoolGroupState.initialState),
	on(RewardPoolGroupAction.Reset, state => ({
		...state,
	})),
	on(RewardPoolGroupAction.SetId, (state, { id }) => ({
		...state,
		id
	})),
	on(RewardPoolGroupAction.SetRewardPoolId, (state, { rewardPoolId }) => ({
		...state,
		rewardPoolId
	})),
	on(RewardPoolGroupAction.SetRedemptionItemId, (state, { redemptionItemId }) => ({
		...state,
		redemptionItemId
	})),
	on(RewardPoolGroupAction.SetRedemptionItemKind, (state, { redemptionItemKind }) => ({
		...state,
		redemptionItemKind
	})),
	on(RewardPoolGroupAction.GoCreate, state => ({
		...state,
		rewardPoolGroupDetailForm: reset(setValue(state.rewardPoolGroupDetailForm, RewardPoolGroupState.initialRewardPoolGroupDetailFormValue))
	})),
	on(RewardPoolGroupAction.RestError, state => ({
		...state,
		isLoading: false
	})),
	on(RewardPoolGroupAction.List, state => ({
		...state,
		isLoading: true
	})),
	on(RewardPoolGroupAction.ListSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		rewardPoolGroupListResponse: payload
	})),
	on(RewardPoolGroupAction.AssignRewardPoolListSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		rewardPoolAvailableListResponse: payload
	})),
	on(RewardPoolGroupAction.View, state => ({
		...state,
		isLoading: true,
		rewardPoolGlMapWaiverDetailForm: [],
		rewardPoolGlMapPartnerDetailForm: [],
		rewardPoolGlMapCatalogueDetailForm: [],
		rewardPoolGlMapOtsDetailForm: [],
		rewardPoolGroupDetailForm: setValue(state.rewardPoolGroupDetailForm, RewardPoolGroupState.initialRewardPoolGroupDetailFormValue)
	})),
	on(RewardPoolGroupAction.ViewSuccess, (state, { payload }: { payload: RewardPoolGroupViewResponse }) => {
		const rewardPoolGroupDetailFormView = {
			id: payload.id,
			code: payload.code,
			name: payload.name,
			createdBy: payload.createdBy,
			createdDate: payload.createdDate,
			modifiedBy: payload.modifiedBy,
			modifiedDate: payload.modifiedDate,
			rewardPools: payload.rewardPools || [],
			removedRewardPools: payload.removedRewardPools || [],
			redemptionLinkage: payload.redemptionLinkage
		}

		const redemptionSequenceView = payload.redemptionSequence

		return ({
			...state,
			isLoading: false,
			rewardPoolGroupDetailForm: RewardPoolGroupState.validateRewardPoolGroupDetailForm(setValue(state.rewardPoolGroupDetailForm, rewardPoolGroupDetailFormView as RewardPoolGroupState.RewardPoolGroupDetailForm)),
			redemptionSequence: payload.redemptionSequence,
			rewardPoolGroupDetailFormView,
			redemptionSequenceView
		})
	}),
	on(RewardPoolGroupAction.Create, state => ({
		...state,
		isLoading: true
	})),
	on(RewardPoolGroupAction.CreateSuccess, (state, { payload }) => ({
		...RewardPoolGroupState.initialState
	})),
	on(RewardPoolGroupAction.Update, state => ({
		...state,
		isLoading: true
	})),
	on(RewardPoolGroupAction.UpdateSuccess, (state, { payload }) => ({
		...state
	})),
	on(RewardPoolGroupAction.Delete, state => ({
		...state,
		isLoading: true
	})),
	on(RewardPoolGroupAction.DeleteSuccess, () => RewardPoolGroupState.initialState),
	on(RewardPoolGroupAction.GetRewardPoolGlRedemptionMap, (state, { isViewPage }) => ({
		...state,
		isLoading: true,
		rewardPoolAction: 'UPDATE',
		isViewPage
	})),
	on(RewardPoolGroupAction.GetRewardPoolGlRedemptionMapSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		rewardPoolGlMapList: payload.map(o => o.listDetails).reduce((acc, con) => [...acc, ...con], []),
		rewardPoolGlMapWaiverDetailForm: payload.find(o => o.redemptionType === 'FW') ? payload.find(o => o.redemptionType === 'FW').listDetails : [],
		rewardPoolGlMapPartnerDetailForm: payload.find(o => o.redemptionType === 'P') ? payload.find(o => o.redemptionType === 'P').listDetails : [],
		rewardPoolGlMapCatalogueDetailForm: payload.find(o => o.redemptionType === 'C') ? payload.find(o => o.redemptionType === 'C').listDetails : [],
		rewardPoolGlMapOtsDetailForm: payload.find(o => o.redemptionType === 'OTS') ? payload.find(o => o.redemptionType === 'OTS').listDetails : []
	})),
	on(RewardPoolGroupAction.GetRewardPoolGlRedemptionMapViewSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		rewardPoolGlMapList: payload.map(o => o.listDetails).reduce((acc, con) => [...acc, ...con], []),
		rewardPoolGlMapWaiverDetailForm: payload.find(o => o.redemptionType === 'FW') ? payload.find(o => o.redemptionType === 'FW').listDetails : [],
		rewardPoolGlMapPartnerDetailForm: payload.find(o => o.redemptionType === 'P') ? payload.find(o => o.redemptionType === 'P').listDetails : [],
		rewardPoolGlMapCatalogueDetailForm: payload.find(o => o.redemptionType === 'C') ? payload.find(o => o.redemptionType === 'C').listDetails : [],
		rewardPoolGlMapOtsDetailForm: payload.find(o => o.redemptionType === 'OTS') ? payload.find(o => o.redemptionType === 'OTS').listDetails : []
	})),
	on(RewardPoolGroupAction.GetRewardPoolGroupGlRedemptionMap, state => ({
		...state,
		isLoading: true,
		rewardPoolAction: 'CREATE'
	})),
	on(RewardPoolGroupAction.AssignGlRedemptionToRewardPool, (state, { glRedemptionId }) => {
		const rewardPoolGlMapWaiverDetailForm = state.rewardPoolGlMapWaiverDetailForm
		const rewardPoolGlMapPartnerDetailForm = state.rewardPoolGlMapPartnerDetailForm
		const rewardPoolGlMapCatalogueDetailForm = state.rewardPoolGlMapCatalogueDetailForm
		const rewardPoolGlMapOtsDetailForm = state.rewardPoolGlMapOtsDetailForm
		const redemptionItemKind = state && state.redemptionItemKind
		const redemptionItemId = state && state.redemptionItemId ? state.redemptionItemId : null

		return ({
			...state,
			isLoading: false,
			rewardPoolGlMapWaiverDetailForm: redemptionItemKind === 'FW' ? (redemptionItemId !== null ? (rewardPoolGlMapWaiverDetailForm.map(o => o.redemptionItemId !== redemptionItemId ? o : {
				...o,
				glRedemptionId
			})) : rewardPoolGlMapWaiverDetailForm.map(o => o.updated === false ? o : {
				...o,
				glRedemptionId,
				updated: !o.updated
			})) : rewardPoolGlMapWaiverDetailForm,
			rewardPoolGlMapPartnerDetailForm: redemptionItemKind === 'P' ? (redemptionItemId !== null ? (rewardPoolGlMapPartnerDetailForm.map(o => o.redemptionItemId !== redemptionItemId ? o : {
				...o,
				glRedemptionId
			})) : rewardPoolGlMapPartnerDetailForm.map(o => o.updated === false ? o : {
				...o,
				glRedemptionId,
				updated: !o.updated
			})) : rewardPoolGlMapPartnerDetailForm,
			rewardPoolGlMapCatalogueDetailForm: redemptionItemKind === 'C' ? (redemptionItemId !== null ? (rewardPoolGlMapCatalogueDetailForm.map(o => o.redemptionItemId !== redemptionItemId ? o : {
				...o,
				glRedemptionId
			})) : rewardPoolGlMapCatalogueDetailForm.map(o => o.updated === false ? o : {
				...o,
				glRedemptionId,
				updated: !o.updated
			})) : rewardPoolGlMapCatalogueDetailForm,
			rewardPoolGlMapOtsDetailForm: redemptionItemKind === 'OTS' ? (redemptionItemId !== null ? (rewardPoolGlMapOtsDetailForm.map(o => o.redemptionItemId !== redemptionItemId ? o : {
				...o,
				glRedemptionId
			})) : rewardPoolGlMapOtsDetailForm.map(o => o.updated === false ? o : {
				...o,
				glRedemptionId,
				updated: !o.updated
			})) : rewardPoolGlMapOtsDetailForm,
		})
	}),
	on(RewardPoolGroupAction.GetGlRedemptionList, state => ({
		...state,
		isLoading: true
	})),
	on(RewardPoolGroupAction.GetGlRedemptionListSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		glRedemptionList: payload.glRedemptions
	})),
	on(RewardPoolGroupAction.AssignedGlRedemptionToRewardPool, state => ({
		...state,
		isLoading: false
	})),
	on(RewardPoolGroupAction.SuccessGlMapToRewardPool, (state, { redemptionItemId }) => {
		const glMapResult = {
			success: true,
			payload: state.rewardPoolGlMapPartnerDetailForm.concat(state.rewardPoolGlMapOtsDetailForm).concat(state.rewardPoolGlMapWaiverDetailForm).concat(state.rewardPoolGlMapCatalogueDetailForm).find(item => redemptionItemId === item.redemptionItemId).redemptionItemName
		}
		return ({
			...state,
			isTableLoading: false,
			glMapListResult: state.glMapListResult.concat(glMapResult)
		})
	}),
	on(RewardPoolGroupAction.FailedGlMapToRewardPool, (state, { redemptionItemId, message }) => {
		const glMapResult = {
			success: false,
			payload: state.rewardPoolGlMapPartnerDetailForm.concat(state.rewardPoolGlMapOtsDetailForm).concat(state.rewardPoolGlMapWaiverDetailForm).concat(state.rewardPoolGlMapCatalogueDetailForm).find(item => redemptionItemId === item.redemptionItemId).redemptionItemName
		}
		return ({
			...state,
			isTableLoading: false,
			glMapListResult: state.glMapListResult.concat(glMapResult)
		})
	}),
	on(RewardPoolGroupAction.SetAllGlMapCheckbox, (state, { redemptionItemKind, updated }) => {
		const rewardPoolGlMapWaiverDetailForm = state.rewardPoolGlMapWaiverDetailForm
		const rewardPoolGlMapPartnerDetailForm = state.rewardPoolGlMapPartnerDetailForm
		const rewardPoolGlMapCatalogueDetailForm = state.rewardPoolGlMapCatalogueDetailForm
		const rewardPoolGlMapOtsDetailForm = state.rewardPoolGlMapOtsDetailForm

		return ({
			...state,
			isLoading: false,
			rewardPoolGlMapWaiverDetailForm: redemptionItemKind === 'waiver' ? rewardPoolGlMapWaiverDetailForm.map(o => ({
				...o,
				updated
			})) :  rewardPoolGlMapWaiverDetailForm,
			rewardPoolGlMapPartnerDetailForm: redemptionItemKind === 'partner' ? rewardPoolGlMapPartnerDetailForm.map(o => ({
				...o,
				updated
			})) : rewardPoolGlMapPartnerDetailForm,
			rewardPoolGlMapCatalogueDetailForm: redemptionItemKind === 'catalogue' ? rewardPoolGlMapCatalogueDetailForm.map(o => ({
				...o,
				updated
			})) : rewardPoolGlMapCatalogueDetailForm,
			rewardPoolGlMapOtsDetailForm: redemptionItemKind === 'ots' ? rewardPoolGlMapOtsDetailForm.map(o => ({
				...o,
				updated
			})) : rewardPoolGlMapOtsDetailForm,
		})
	}),
	on(RewardPoolGroupAction.SetGlMapCheckbox, (state, { redemptionItemKind, redemptionItemId }) => {
		const rewardPoolGlMapWaiverDetailForm = state.rewardPoolGlMapWaiverDetailForm
		const rewardPoolGlMapPartnerDetailForm = state.rewardPoolGlMapPartnerDetailForm
		const rewardPoolGlMapCatalogueDetailForm = state.rewardPoolGlMapCatalogueDetailForm
		const rewardPoolGlMapOtsDetailForm = state.rewardPoolGlMapOtsDetailForm

		return ({
			...state,
			isLoading: false,
			rewardPoolGlMapWaiverDetailForm: redemptionItemKind === 'waiver' ? rewardPoolGlMapWaiverDetailForm.map(o => o.redemptionItemId !== redemptionItemId ? o : {
				...o,
				updated: !o.updated
			}) :  rewardPoolGlMapWaiverDetailForm,
			rewardPoolGlMapPartnerDetailForm: redemptionItemKind === 'partner' ? rewardPoolGlMapPartnerDetailForm.map(o => o.redemptionItemId !== redemptionItemId ? o : {
				...o,
				updated: !o.updated
			}) : rewardPoolGlMapPartnerDetailForm,
			rewardPoolGlMapCatalogueDetailForm: redemptionItemKind === 'catalogue' ? rewardPoolGlMapCatalogueDetailForm.map(o => o.redemptionItemId !== redemptionItemId ? o : {
				...o,
				updated: !o.updated
			}) : rewardPoolGlMapCatalogueDetailForm,
			rewardPoolGlMapOtsDetailForm: redemptionItemKind === 'ots' ? rewardPoolGlMapOtsDetailForm.map(o => o.redemptionItemId !== redemptionItemId ? o : {
				...o,
				updated: !o.updated
			}) : rewardPoolGlMapOtsDetailForm,
		})
	}),
	on(RewardPoolGroupAction.PostMultiAssignedGlRedemptionToRewardPool, state => ({
		...state,
		isTableLoading: true,
		glMapListResult: []
	}))
)

export function Reducer(state: RewardPoolGroupState.State = RewardPoolGroupState.initialState, action: Action) {

	const rewardPoolGroupDetailForm = RewardPoolGroupState.validateRewardPoolGroupDetailForm(formGroupReducer(state.rewardPoolGroupDetailForm, action))
	if (rewardPoolGroupDetailForm !== state.rewardPoolGroupDetailForm) {
		state = { ...state, rewardPoolGroupDetailForm }
	}
	return reducer(state, action)
}
