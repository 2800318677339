import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { CampaignCreateRequest } from 'src/app/models/campaign-management/campaign/campaign-create'
import { CampaignPolicy } from 'src/app/models/campaign-management/campaign/campaign-data'
import { CampaignDeleteRequest } from 'src/app/models/campaign-management/campaign/campaign-delete'
import { CampaignDuplicateRequest } from 'src/app/models/campaign-management/campaign/campaign-duplicate'
import { CampaignUpdateRequest } from 'src/app/models/campaign-management/campaign/campaign-edit'
import { CampaignPublishRequest } from 'src/app/models/campaign-management/campaign/campaign-publish'
import { CampaignTerminateRequest } from 'src/app/models/campaign-management/campaign/campaign-terminate'
import { CampaignViewRequest } from 'src/app/models/campaign-management/campaign/campaign-view'
import { RuleListRequest } from 'src/app/models/campaign-management/campaign/rule-list'
import { RulesetDataRequest } from 'src/app/models/campaign-management/campaign/ruleset-data'
import { ConditionalRewardCampaignRequest } from 'src/app/models/campaign-management/conditional-reward/conditional-reward-campaign'
import { ConditionalRewardCreateRequest } from 'src/app/models/campaign-management/conditional-reward/conditional-reward-create'
import { ConditionalRewardDeleteRequest } from 'src/app/models/campaign-management/conditional-reward/conditional-reward-delete'
import { ConditionalRewardEditRequest } from 'src/app/models/campaign-management/conditional-reward/conditional-reward-edit'
import { ConditionalRewardPublishRequest } from 'src/app/models/campaign-management/conditional-reward/conditional-reward-publish'
import { ConditionalRewardTerminateRequest } from 'src/app/models/campaign-management/conditional-reward/conditional-reward-terminate'
import { ConditionalRewardViewRequest } from 'src/app/models/campaign-management/conditional-reward/conditional-reward-view'
import { SimulatorCancelRequest } from 'src/app/models/campaign-management/simulator/simulator-cancel'
import { SimulatorCreateRequest } from 'src/app/models/campaign-management/simulator/simulator-create'
import { SimulatorKeyDataRequest } from 'src/app/models/campaign-management/simulator/simulator-data'
import { SimulatorDeleteRequest } from 'src/app/models/campaign-management/simulator/simulator-delete'
import { Request, Response } from 'src/app/models/common/http'
import { RestService } from '../common/rest-client/rest.service'
import { SimulatorViewRequest } from 'src/app/models/campaign-management/simulator/simulator-view'
import { ConditionalRewardDataRequest } from 'src/app/models/campaign-management/conditional-reward/conditional-reward-data'
import { ConditionalRewardPolicy } from 'src/app/models/campaign-management/conditional-reward/conditional-reward'
import { ConditionalRewardRepublishRequest } from 'src/app/models/campaign-management/conditional-reward/conditional-reward-republish'
import { PageRequest } from 'src/app/models/common/common'

@Injectable({
	providedIn: 'root'
})
export class CampaignManagementService {

	constructor(
		private rest: RestService
	) { }

	// Campaign
	getCampaignPublishedList(params: PageRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/campaign/published' + this.rest.urlQueryString(params),
			mockURL: '/campaign/getCampaignPublishedList.json'
		}

		return this.rest.get(request)
	}

	getCampaignDraftList(params: PageRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/campaign/draft' + this.rest.urlQueryString(params),
			mockURL: '/campaign/getCampaignDraftList.json'
		}

		return this.rest.get(request)
	}

	getCampaignUnpublishedList(params: PageRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/campaign/unpublished' + this.rest.urlQueryString(params),
			mockURL: '/campaign/getCampaignUnpublishedList.json'
		}

		return this.rest.get(request)
	}

	getCampaignPbPublishedList(params: PageRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/campaign/pb_published' + this.rest.urlQueryString(params),
			mockURL: '/campaign/getCampaignPublishedList.json'
		}

		return this.rest.get(request)
	}

	getCampaignPbDraftList(params: PageRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/campaign/pb_draft' + this.rest.urlQueryString(params),
			mockURL: '/campaign/getCampaignDraftList.json'
		}

		return this.rest.get(request)
	}

	getCampaignPbUnpublishedList(params: PageRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/campaign/pb_unpublished' + this.rest.urlQueryString(params),
			mockURL: '/campaign/getCampaignUnpublishedList.json'
		}

		return this.rest.get(request)
	}

	createCampaign(params: CampaignCreateRequest): Observable<Response> {
		const request: Request = {
			body: params,
			liveURL: '/campaign/create',
			mockURL: '/campaign/createCampaign.json'
		}

		return this.rest.post(request)
	}

	viewCampaign(params: CampaignViewRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/campaign/view/' + params.code + `?${new URLSearchParams({ ...params })}`,
			mockURL: '/campaign/viewCampaign.json'
		}

		return this.rest.get(request)
	}

	updateCampaign(params: CampaignUpdateRequest): Observable<Response> {
		const request: Request = {
			body: params,
			liveURL: '/campaign/update/' + params.information.code,
			mockURL: '/campaign/updateCampaign.json'
		}

		return this.rest.post(request)
	}

	duplicateCampaign(code: string, params: CampaignDuplicateRequest, policy: CampaignPolicy): Observable<Response> {
		const request: Request = {
			body: params,
			liveURL: '/campaign/duplicate/' + code + `?${new URLSearchParams({ ...policy })}`,
			mockURL: '/campaign/duplicateCampaign.json'
		}

		return this.rest.post(request)
	}

	deleteCampaign(params: CampaignDeleteRequest): Observable<Response> {
		const request: Request = {
			body: params,
			liveURL: '/campaign/delete/',
			mockURL: '/campaign/deleteCampaign.json'
		}

		return this.rest.post(request)
	}

	publishCampaign(params: CampaignPublishRequest): Observable<Response> {
		const request: Request = {
			body: params,
			liveURL: '/campaign/publish_campaign/',
			mockURL: '/campaign/publishCampaign.json'
		}

		return this.rest.post(request)
	}

	republishCampaign(params: CampaignUpdateRequest): Observable<Response> {
		const request: Request = {
			body: params,
			liveURL: '/campaign/republish_campaign/' + params.information.code
		}

		return this.rest.post(request)
	}

	terminateCampaign(params: CampaignTerminateRequest): Observable<Response> {
		const request: Request = {
			body: params,
			liveURL: '/campaign/terminate_campaign/',
			mockURL: '/campaign/terminateCampaign.json'
		}

		return this.rest.post(request)
	}

	getRuleList(params: RuleListRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/campaign/campaignRule' + this.rest.urlQueryString(params),
			mockURL: '/campaign/getRuleList.json'
		}

		return this.rest.get(request)
	}

	getCampaignData(params: CampaignPolicy): Observable<Response> {
		const request: Request = {
			liveURL: '/campaign/campaignData' + `?${new URLSearchParams({ ...params })}`,
			mockURL: '/campaign/getCampaignData.json'
		}

		return this.rest.get(request)
	}


	getRulesetData(params: RulesetDataRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/campaign/rulesetData' + this.rest.urlQueryString(params),
			mockURL: '/campaign/getRulesetData.json'
		}

		return this.rest.get(request)
	}

	getCampaignRepublishDetail(params: CampaignViewRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/campaign/republish_campaign/view/' + params.code + `?${new URLSearchParams({ ...params })}`,
		}

		return this.rest.get(request)
	}

	// Conditional Reward
	getConditionalRewardPublishedList(): Observable<Response> {
		const request: Request = {
			liveURL: '/conditionalReward/published'
		}

		return this.rest.get(request)
	}

	getConditionalRewardDraftList(): Observable<Response> {
		const request: Request = {
			liveURL: '/conditionalReward/draft'
		}

		return this.rest.get(request)
	}

	getConditionalRewardUnpublishedList(): Observable<Response> {
		const request: Request = {
			liveURL: '/conditionalReward/unpublished'
		}

		return this.rest.get(request)
	}

	createConditionalReward(params: ConditionalRewardCreateRequest): Observable<Response> {
		const request: Request = {
			body: params,
			liveURL: '/conditionalReward/create'
		}

		return this.rest.post(request)
	}

	viewConditionalReward(params: ConditionalRewardViewRequest, policy: ConditionalRewardPolicy): Observable<Response> {
		const { id , ...requestParam } = params
		const request: Request = {
			liveURL: '/conditionalReward/view/' + params.id + this.rest.urlQueryString(requestParam) + `&${new URLSearchParams({ ...policy })}`
		}

		return this.rest.get(request)
	}

	updateConditionalReward(id: number, params: ConditionalRewardEditRequest): Observable<Response> {
		const request: Request = {
			body: params,
			liveURL: '/conditionalReward/update/' + id
		}

		return this.rest.post(request)
	}

	deleteConditionalReward(params: ConditionalRewardDeleteRequest): Observable<Response> {
		const request: Request = {
			body: params,
			liveURL: '/conditionalReward/delete/'
		}

		return this.rest.post(request)
	}

	publishConditionalReward(params: ConditionalRewardPublishRequest): Observable<Response> {
		const request: Request = {
			body: params,
			liveURL: '/conditionalReward/publishConditionalReward/'
		}

		return this.rest.post(request)
	}

	terminateConditionalReward(params: ConditionalRewardTerminateRequest): Observable<Response> {
		const request: Request = {
			body: params,
			liveURL: '/conditionalReward/terminateConditionalReward/'
		}

		return this.rest.post(request)
	}

	republishConditionalReward(id: number, params: ConditionalRewardRepublishRequest): Observable<Response> {
		const request: Request = {
			body: params,
			liveURL: '/conditionalReward/republish/' + id
		}

		return this.rest.post(request)
	}

	getConditionalRewardData(params: ConditionalRewardDataRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/conditionalReward/conditionalRewardData' + this.rest.urlQueryString(params)
		}

		return this.rest.get(request)
	}

	getCampaignList(params: ConditionalRewardCampaignRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/conditionalReward/getCampaignList' + this.rest.urlQueryString(params)
		}

		return this.rest.get(request)
	}

	// Simulator
	getSimulatorTicketSequenceList(): Observable<Response> {
		const request: Request = {
			liveURL: '/simulator/ticketSequence'
		}

		return this.rest.get(request)
	}

	getSimulatorSimulationResultList(): Observable<Response> {
		const request: Request = {
			liveURL: '/simulator/simulationResult'
		}

		return this.rest.get(request)
	}

	getSimulatorData(): Observable<Response> {
		const request: Request = {
			liveURL: '/simulator/simulatorData'
		}

		return this.rest.get(request)
	}

	getSimulatorKeyData(params: SimulatorKeyDataRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/simulator/keyData' + this.rest.urlQueryString(params)
		}

		return this.rest.get(request)
	}

	createSimulator(params: SimulatorCreateRequest): Observable<Response> {
		const request: Request = {
			body: params,
			liveURL: '/simulator/create'
		}

		return this.rest.post(request)
	}

	viewSimulator(params: SimulatorViewRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/simulator/view/' + params.id
		}

		return this.rest.get(request)
	}

	deleteSimulator(params: SimulatorDeleteRequest): Observable<Response> {
		const request: Request = {
			body: params,
			liveURL: '/simulator/delete/'
		}

		return this.rest.post(request)
	}

	cancelSimulator(params: SimulatorCancelRequest): Observable<Response> {
		const request: Request = {
			body: params,
			liveURL: '/simulator/cancel/'
		}

		return this.rest.post(request)
	}
}
