<mat-accordion>
  <mat-expansion-panel [ngClass]="this.ruleset?.isChild ? 'ruleset-child-container' : 'mat-box-container'"
    [expanded]="!isView" [hideToggle]="!isView">
    <mat-expansion-panel-header>
      <div fxLayout="row" fxLayoutAlign="space-between center" fxFlex>
        <span class="content-title">{{title}}</span>
        <mat-icon *ngIf="!isView && ruleset?.rule?.keyValue?.key !== 'CHOOSE_DATE'"
          class="mat-icon-pointer material-icons-outlined" (click)="delete()">close</mat-icon>
      </div>
    </mat-expansion-panel-header>
    <ng-template #dynamicContainer></ng-template>

  </mat-expansion-panel>
</mat-accordion>