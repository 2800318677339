import { createFeatureSelector, createSelector } from '@ngrx/store'

import * as Common from './common.state'

const common = createFeatureSelector<Common.State>('common')

export const getCommon = createSelector(common, x => x)
export const getSideNavFlag = createSelector(common, x => x.sideNavFlag)
export const getSideNavItemList = createSelector(common, x => x.sidenavItemList)
export const getLoginUser = createSelector(common, x => x.loginUser)
export const getPreviousRoute = createSelector(common, x => x.previousRoute)
export const getSelectForm = createSelector(common, x => x.selectForm)
export const getCurrentActiveNav = createSelector(common, x => x.currentActiveNav)
