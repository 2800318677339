import { BrowserStorage } from './browser-storage'
import { environment } from 'src/environments/environment'
import { ConfigLoader } from './config-loader'

export const Redirector = {
	clearStorageAndRedirect: async () => {
		const newEnv = await ConfigLoader.loadConfig(environment)
		const storageData = BrowserStorage.getStorageData()
		if (!!storageData) {
			const token = new URLSearchParams({ token: storageData.payload.accessToken })
			fetch(`${newEnv.logoutUrl}?${token}`, { method: 'POST' }).then(_ => {
				BrowserStorage.clearAllStorageData()
				Redirector.redirectToLogout()
			})
		} else {
			Redirector.redirectToLogout()
		}
	},
	redirectToLogin: async () => {
		const newEnv = await ConfigLoader.loadConfig(environment)
		const loginUrl = `${newEnv.loginUrl}`
		window.location.assign(loginUrl)
	},
	redirectToLogout: async () => {
		const newEnv = await ConfigLoader.loadConfig(environment)
		const logoutUrl = `${newEnv.clearCacheUrl}`
		window.location.assign(logoutUrl)
	}
}
