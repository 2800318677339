import { Action, createReducer, on } from '@ngrx/store'
import * as NgrxForms from 'ngrx-forms'
import { formGroupReducer, reset, setValue, SetValueAction, markAsTouched } from 'ngrx-forms'
import * as RewardPoolAction from './reward-pool.actions'
import * as RewardPoolState from './reward-pool.state'

export const reducer = createReducer(
	RewardPoolState.initialState,
	NgrxForms.onNgrxFormsAction(SetValueAction, (state, action) => {
		return actionHandler(state, action)
	}),
	NgrxForms.onNgrxFormsAction(NgrxForms.MarkAsTouchedAction, (state, action) => {
		return actionHandler(state, action)
	}),
	on(RewardPoolAction.InitialState, () => RewardPoolState.initialState),
	on(RewardPoolAction.Reset, state => ({
		...state,
	})),
	on(RewardPoolAction.SetId, (state, { id }) => ({
		...state,
		id
	})),
	on(RewardPoolAction.GoCreate, state => ({
		...state,
		rewardPoolDetailForm: reset(setValue(state.rewardPoolDetailForm, RewardPoolState.initialRewardPoolDetailFormValue))
	})),
	on(RewardPoolAction.RestError, state => ({
		...state,
		isLoading: false,
		isDuplicateLoading: false,
		isDuplicateDialog: false
	})),
	on(RewardPoolAction.List, state => ({
		...state,
		isLoading: true
	})),
	on(RewardPoolAction.ListSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		rewardPoolListResponse: payload
	})),
	on(RewardPoolAction.OptionList, state => ({
		...state,
		isLoading: true
	})),
	on(RewardPoolAction.OptionListSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		cycleList: payload.cycleList
	})),
	on(RewardPoolAction.RewardPoolTypeOptionList, state => ({
		...state,
		isLoading: true
	})),
	on(RewardPoolAction.RewardPoolTypeOptionListSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		rewardPoolTypeList: payload.rewardPoolTypeList
	})),
	on(RewardPoolAction.ResourceListCreate, state => ({
		...state,
		isLoading: true
	})),
	on(RewardPoolAction.ResourceListCreateSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		resourceList: (payload || [])
	})),
	on(RewardPoolAction.ResourceListUpdate, state => ({
		...state,
		isLoading: true
	})),
	on(RewardPoolAction.ResourceListUpdateSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		resourceList: (payload || [])
	})),
	on(RewardPoolAction.GLOptionListCreate, state => ({
		...state,
		isLoading: true
	})),
	on(RewardPoolAction.GLOptionListCreateSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		glProvisions: (payload.glProvisions || []).map(i => ({ key: i.id, value: `${i.code} - ${i.name}` }))
	})),
	on(RewardPoolAction.GLOptionListUpdate, state => ({
		...state,
		isLoading: true
	})),
	on(RewardPoolAction.GLOptionListUpdateSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		glProvisions: (payload.glProvisions || []).map(i => ({ key: i.id, value: `${i.code} - ${i.name}` }))
	})),
	on(RewardPoolAction.GLDetails, state => ({
		...state,
		isLoading: true
	})),
	on(RewardPoolAction.GLDetailsSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		glProvision: payload
	})),
	on(RewardPoolAction.View, state => ({
		...state,
		isLoading: true,
		rewardPoolDetailForm: setValue(state.rewardPoolDetailForm, RewardPoolState.initialRewardPoolDetailFormValue),
		allowDelete: true
	})),
	on(RewardPoolAction.ViewSuccess, (state, { payload }) => {
		const rewardPoolDetailFormView = {
			id: payload.id,
			code: payload.code,
			name: payload.name,
			glId: payload.glId,
			resourceCode: payload.resourceCode,
			resourceName: payload.resourceName,
			expCycleType: payload.accumulationPeriod.expCycleType,
			expCycle: payload.accumulationPeriod.expCycle.toString(),
			cycleType: '-',
			cycleMonth: 0,
			cycleDay: 0,
			customerCycleCap: 0,
			cycleCap: 0,
			customerMaxCap: 0,
			maxCap: 0,
			rewardPoolType: payload.rewardPoolType,
			glProductCode: payload.glProductCode,
			statement: payload.statement,
			createdBy: payload.createdBy,
			createdDate: payload.createdDate,
			modifiedBy: payload.modifiedBy,
			modifiedDate: payload.modifiedDate
		}

		let channel

		if (payload.resourceCode === 'CARDLINK') {
			channel = 'CARDLINK'
		} else {
			channel = 'BANKWIDE'
		}
		return ({
			...state,
			isLoading: false,
			rewardPoolDetailForm: RewardPoolState.validateRewardPoolDetailForm(state)(setValue(state.rewardPoolDetailForm, rewardPoolDetailFormView as RewardPoolState.RewardPoolDetailForm)),
			allowDelete: payload.allowDelete,
			allowEditType: payload.rewardPoolType === 'A' ? payload.allowDelete : !payload.rewardPoolGroupLinkage,
			rewardPoolDetailFormView,
			channel
		})
	}),
	on(RewardPoolAction.Create, state => ({
		...state,
		isLoading: true
	})),
	on(RewardPoolAction.CreateSuccess, (state, { payload }) => ({
		...RewardPoolState.initialState
	})),
	on(RewardPoolAction.Update, state => ({
		...state,
		isLoading: true
	})),
	on(RewardPoolAction.UpdateSuccess, (state, { payload }) => ({
		...state
	})),
	on(RewardPoolAction.Delete, state => ({
		...state,
		isLoading: true
	})),
	on(RewardPoolAction.DeleteSuccess, () => RewardPoolState.initialState),
	on(RewardPoolAction.ClearExpCycle, (state, { }) => ({
		...state,
		isLoading: false,
		rewardPoolDetailForm: RewardPoolState.validateRewardPoolDetailForm(state)(setValue(state.rewardPoolDetailForm, {
			id: state.rewardPoolDetailForm.value.id,
			code: state.rewardPoolDetailForm.value.code,
			name: state.rewardPoolDetailForm.value.name,
			glId: state.rewardPoolDetailForm.value.glId,
			resourceCode: state.rewardPoolDetailForm.value.resourceCode,
			resourceName: state.rewardPoolDetailForm.value.resourceName,
			expCycleType: state.rewardPoolDetailForm.value.expCycleType,
			expCycle: '0',
			cycleType: '-',
			cycleMonth: 0,
			cycleDay: 0,
			customerCycleCap: 0,
			cycleCap: 0,
			customerMaxCap: 0,
			maxCap: 0,
			rewardPoolType: state.rewardPoolDetailForm.value.rewardPoolType,
			glProductCode: state.rewardPoolDetailForm.value.glProductCode,
			statement: state.rewardPoolDetailForm.value.statement,
			createdBy: state.rewardPoolDetailForm.value.createdBy,
			createdDate: state.rewardPoolDetailForm.value.createdDate,
			modifiedBy: state.rewardPoolDetailForm.value.modifiedBy,
			modifiedDate: state.rewardPoolDetailForm.value.modifiedDate,
		} as RewardPoolState.RewardPoolDetailForm))
	})),
	on(RewardPoolAction.ClearStatement, (state, { }) => ({
		...state,
		isLoading: false,
		rewardPoolDetailForm: RewardPoolState.validateRewardPoolDetailForm(state)(setValue(state.rewardPoolDetailForm, {
			id: state.rewardPoolDetailForm.value.id,
			code: state.rewardPoolDetailForm.value.code,
			name: state.rewardPoolDetailForm.value.name,
			glId: state.rewardPoolDetailForm.value.glId,
			resourceCode: state.rewardPoolDetailForm.value.resourceCode,
			resourceName: state.rewardPoolDetailForm.value.resourceName,
			expCycleType: state.rewardPoolDetailForm.value.expCycleType,
			expCycle: state.rewardPoolDetailForm.value.expCycle,
			cycleType: '-',
			cycleMonth: 0,
			cycleDay: 0,
			customerCycleCap: 0,
			cycleCap: 0,
			customerMaxCap: 0,
			maxCap: 0,
			rewardPoolType: state.rewardPoolDetailForm.value.rewardPoolType,
			glProductCode: state.rewardPoolDetailForm.value.glProductCode,
			statement: null,
			createdBy: state.rewardPoolDetailForm.value.createdBy,
			createdDate: state.rewardPoolDetailForm.value.createdDate,
			modifiedBy: state.rewardPoolDetailForm.value.modifiedBy,
			modifiedDate: state.rewardPoolDetailForm.value.modifiedDate,
		} as RewardPoolState.RewardPoolDetailForm))
	})),
	on(RewardPoolAction.OnChangeExpCycleChange, (state, { value }) => ({
		...state,
		rewardPoolDetailForm: setValue(state.rewardPoolDetailForm, {
			...state.rewardPoolDetailForm.value,
			expCycle: value ? `${+value}` : '0'
		})
	})),
	on(RewardPoolAction.OnChangeRewardPoolType, state => ({
		...state,
		rewardPoolDetailForm: setValue(state.rewardPoolDetailForm, {
			...state.rewardPoolDetailForm.value,
			glProductCode: state.rewardPoolDetailForm.value.rewardPoolType === 'P' ? state.rewardPoolDetailForm.value.glProductCode : ''
		})
	})),
	on(RewardPoolAction.OnChangeChannel, (state, { payload }) => {
		let rewardPoolType
		let channel

		if (payload === 'CARDLINK') {
			rewardPoolType = ''
			channel = 'CARDLINK'
		} else {
			rewardPoolType = 'P'
			channel = 'BANKWIDE'
		}
		return ({
			...state,
			isLoading: false,
			rewardPoolDetailForm: setValue(state.rewardPoolDetailForm, {
				...state.rewardPoolDetailForm.value,
				glId: null,
				glProductCode: '',
				rewardPoolType
			}),
			channel
		})
	}),
	on(RewardPoolAction.DuplicateDialog, state => ({
		...state,
		rewardPoolDuplicateForm: reset(setValue(state.rewardPoolDuplicateForm, RewardPoolState.initialRewardPoolDuplicateFormValue)),
		isDuplicateDialog: false
	})),
	on(RewardPoolAction.Duplicate, state => ({
		...state,
		isDuplicateLoading: true
	})),
	on(RewardPoolAction.DuplicateSuccess, state => ({
		...state,
		isDuplicateLoading: false,
		isDuplicateDialog: true
	})),
)

export function Reducer(state: RewardPoolState.State = RewardPoolState.initialState, action: Action) {

	const rewardPoolDetailForm = RewardPoolState.validateRewardPoolDetailForm(state)(formGroupReducer(state.rewardPoolDetailForm, action))
	if (rewardPoolDetailForm !== state.rewardPoolDetailForm) {
		state = { ...state, rewardPoolDetailForm }
	}

	const rewardPoolDuplicateForm = RewardPoolState.validateRewardPoolDuplicateForm(formGroupReducer(state.rewardPoolDuplicateForm, action))
	if (rewardPoolDuplicateForm !== state.rewardPoolDuplicateForm) {
		state = {...state, rewardPoolDuplicateForm}
	}

	return reducer(state, action)
}

function validateForm(form: any) {
	form = markAsTouched(form)

	return RewardPoolState.validateRewardPoolDetailForm(form)
}

function actionHandler(state: RewardPoolState.State, action: SetValueAction<unknown> | NgrxForms.MarkAsTouchedAction) {
	const formControls = state.rewardPoolDetailForm

	let form = formGroupReducer(formControls, action)

	if (action.type === 'ngrx/forms/SET_VALUE') {
		form = formGroupReducer(formControls, new SetValueAction(action.controlId, action.value))
	}

	if (form) {
		form = validateForm(state)(form)

		state = {
			...state,
			rewardPoolDetailForm: form
		}
	}
	return state
}
