import { createAction, props } from '@ngrx/store'
import { FieldMappingListResponse } from 'src/app/models/param-settings/field-mapping/field-mapping-list'
import { FieldMappingViewResponse } from 'src/app/models/param-settings/field-mapping/field-mapping-view'

export const InitialState = createAction('[FieldMapping] InitialState')
export const Reset = createAction('[FieldMapping] Reset')
export const SetId = createAction('[FieldMapping] SetId', props<{ id: string }>())

export const GoList = createAction('[FieldMapping] GoList')
export const GoView = createAction('[FieldMapping] GoView')

export const GoBackDialog = createAction('[FieldMapping] GoBackDialog', props<{ action: string }>())
export const UpdateValueDialog = createAction('[FieldMapping] UpdateValueDialog', props<{ id: number, label: string, value: string}>())
export const UpdateValueDialogSuccess = createAction('[FieldMapping] UpdateValueDialogSuccess', props<{ id: number, label: string, value: string}>())
export const closeDialog = createAction('[FieldMapping] closeDialog')

export const RestError = createAction('[FieldMapping] RestError', props<{ message: string, manualThrow: boolean }>())

export const List = createAction('[FieldMapping] List')
export const ListSuccess = createAction('[FieldMapping] ListSuccess', props<{ payload: FieldMappingListResponse }>())

export const View = createAction('[FieldMapping] View')
export const ViewSuccess = createAction('[FieldMapping] ViewSuccess', props<{ payload: FieldMappingViewResponse }>())

export const UpdateValue = createAction('[FieldMapping] UpdateValue', props<{id: number, value: string}>())
export const UpdateValueSuccess = createAction('[FieldMapping] UpdateValueSuccess', props<{ payload: string }>())

export const UpdateMasking = createAction('[FieldMapping] UpdateMasking', props<{id: number, masking: boolean}>())
export const UpdateMaskingSuccess = createAction('[FieldMapping] UpdateMaskingSuccess', props<{ payload: string }>())

