import { KeyValue } from '@angular/common'
import { Type } from '@angular/core'
import { box, Boxed, createFormGroupState, FormGroupState, unbox, updateGroup, validate } from 'ngrx-forms'
import { maxLength, required } from 'ngrx-forms/validation'
import { CampaignTasklist, RuleAddType } from 'src/app/models/campaign-management/campaign/campaign'
import { CampaignDataResponse } from 'src/app/models/campaign-management/campaign/campaign-data'
import { CampaignDraftListResponse, CampaignPublishedListResponse, CampaignUnpublishedListResponse, CampaignPbPublishedListResponse, CampaignPbDraftListResponse, CampaignPbUnpublishedListResponse } from 'src/app/models/campaign-management/campaign/campaign-list'
import { RulesetDataResponse } from 'src/app/models/campaign-management/campaign/ruleset-data'
import { FieldValidator } from 'src/app/models/util/field.validator'
import { POLICY } from 'src/app/models/common/constant'

export interface State {
	campaignIsLoading: boolean
	pbIsLoading: boolean
	isLoading: boolean
	isDuplicateLoading: boolean
	isDuplicateDialog: boolean
	code?: string
	kind?: string
	campaignRulesetId?: string
	campaignRulesetAction?: string
	campaignTierId?: string
	ruleCondition?: RuleCondition
	checkerMakerId?: number
	paginatorMetadata: any
	// Listing
	campaignPublishedListResponse: CampaignPublishedListResponse
	campaignDraftListResponse: CampaignDraftListResponse
	campaignUnpublishedListResponse: CampaignUnpublishedListResponse
	campaignPbPublishedListResponse: CampaignPbPublishedListResponse
	campaignPbDraftListResponse: CampaignPbDraftListResponse
	campaignPbUnpublishedListResponse: CampaignPbUnpublishedListResponse
	// Detail
	informationDetailForm: FormGroupState<InformationDetailForm>
	rulesetListForm: FormGroupState<RulesetListForm>
	cappingForm: FormGroupState<CappingForm>
	informationDetailFormView: InformationDetailForm
	rulesetListFormView: RulesetListForm
	cappingFormView: CappingForm
	// Ruleset
	rulesetDetailForm: FormGroupState<RulesetDetailForm>
	rulesetRewardForm: FormGroupState<RulesetRewardForm>
	// Campaign Duplicate
	campaignDuplicateForm: FormGroupState<CampaignDuplicateForm>
	// Campaign Worklist View
	campaignWorklistDetail: CampaignTasklist

	campaignRulesetView: []

	ruleList: Rule[]
	campaignData: CampaignDataResponse
	rulesetData: RulesetDataResponse
	resourceType: string
	allowDelete: boolean
	allowRepublish: boolean
	republishAction?: string
	policy?: POLICY
	isDuplicate?: boolean
}

export interface CampaignDuplicateForm {
	code: string
	name: string
}

export const initialCampaignDuplicateFormValue: CampaignDuplicateForm = {
	code: '',
	name: ''
}

export const initialCampaignDuplicateForm = createFormGroupState('campaignDuplicateForm', initialCampaignDuplicateFormValue)

export const validateCampaignDuplicateForm = updateGroup<CampaignDuplicateForm>({
	code: validate([required, FieldValidator.alphaNumericWithoutSpaces(), maxLength(10)]),
	name: validate([required, maxLength(40)])
})

export interface InformationDetailForm {
	channel: string
	code: string
	name: string
	priority: string
	startDate: string
	endDate: string
	enableConditionalReward: boolean
	createdBy: string
	createdDate: string
	modifiedBy: string
	modifiedDate: string
}

export const initialInformationDetailFormValue: InformationDetailForm = {
	channel: '',
	code: '',
	name: '',
	priority: '',
	startDate: '',
	endDate: '',
	enableConditionalReward: false,
	createdBy: '',
	createdDate: '',
	modifiedBy: '',
	modifiedDate: ''
}


export const initialInformationDetailForm = createFormGroupState('informationDetailForm', initialInformationDetailFormValue)

export const validateInformationDetailForm = (state: State) => {
	const channelCondition = state.kind === 'C'

	return updateGroup<InformationDetailForm>({
		channel: validate([FieldValidator.requiredIfTrue(channelCondition)]),
		code: validate([required, FieldValidator.alphaNumericWithoutSpaces(), maxLength(10)]),
		name: validate([required, maxLength(40), FieldValidator.checkValidCharacters()]),
		priority: validate([FieldValidator.digit(), FieldValidator.digitRange(1, 999)]),
		startDate: validate([required, maxLength(255)]),
		endDate: validate([required, maxLength(255)])
	})
}

export interface CampaignRulesets {
	byId: { [key: string]: CampaignRuleset }
	allIds: string[]
}

export interface CampaignRuleset {
	id: string
	rulesetDetailForm: RulesetDetailForm
	rulesetRewardForm: RulesetRewardForm
}

export interface RulesetListForm {
	campaignRulesetList: CampaignRuleset[]
}

export const initialRulesetListFormValue: RulesetListForm = {
	campaignRulesetList: []
}

export const initialRulesetListForm = createFormGroupState('rulesetListForm', initialRulesetListFormValue)

export const validateRulesetListForm = updateGroup<RulesetListForm>({})

export interface Rulesets {
	byId: { [key: string]: Ruleset }
	allIds: string[]
}

export interface Ruleset {
	id: string
	ruleConditionIds: string[]
	isChild: boolean
	ruleAddType: RuleAddType
	rule: Rule
	form: FormGroupState<any>
}

export interface RuleConditions {
	byId: { [key: string]: RuleCondition }
	allIds: string[]
}

export interface RuleCondition {
	id: string
	rulesetChildIds: string[]
}

export interface Rule {
	keyValue: KeyValue<string, string>
	resourceCode?: string
	component?: Type<any>
}

export interface RulesetDetailForm {
	channel: string
	code: string
	name: string
	rulesets: Rulesets
	ruleConditions: RuleConditions
	rulesetChilds: Rulesets
}

export const initialRulesetDetailFormValue: RulesetDetailForm = {
	channel: '',
	code: '',
	name: '',
	rulesets: {
		byId: {},
		allIds: []
	},
	ruleConditions: {
		byId: {},
		allIds: []
	},
	rulesetChilds: {
		byId: {},
		allIds: []
	}
}

export const initialRulesetDetailForm = createFormGroupState('rulesetDetailForm', initialRulesetDetailFormValue)

export const validateRulesetDetailForm = (state: State) => updateGroup<RulesetDetailForm>({
	code: validate([
		required,
		FieldValidator.alphaNumericWithoutSpaces(),
		FieldValidator.duplicateCode(state.rulesetListForm.controls.campaignRulesetList.controls
			.filter(x => x.controls.id.value !== state.campaignRulesetId)
			.map(x => x.controls.rulesetDetailForm.controls.code.value)),
		maxLength(10)
	]),
	name: validate([required, maxLength(40), FieldValidator.checkValidCharacters()]),
	channel: validate([required])
})

export interface CappingForm {
	customerCapType: string,
	customerCap: string,
	campaignCapType: string,
	campaignCap: string,
	capCycleType: string,
	capCycleDay: string,
	capCycleMonth: string
	cycleTypeOption: string
}

export const initialCappingFormValue: CappingForm = {
	customerCapType: '',
	customerCap: '',
	campaignCapType: 'U',
	campaignCap: '',
	capCycleType: '',
	capCycleDay: '',
	capCycleMonth: '',
	cycleTypeOption: ''
}

export const initialCappingForm = createFormGroupState('cappingForm', initialCappingFormValue)

export const validateCappingForm = (state: State) => {
	const cappingForm = state && state.cappingForm && state.cappingForm.controls
	const customerCapType = cappingForm && cappingForm.customerCapType.value
	const campaignCap = cappingForm && cappingForm.campaignCap.value
	const capCycleType = cappingForm && cappingForm.capCycleType.value
	const cycleTypeOption = cappingForm && cappingForm.cycleTypeOption.value

	let customerCapCondtion = []
	let campaignCapCondition = [] as any

	switch (customerCapType) {
		case 'U':
			customerCapCondtion = [
				FieldValidator.requiredIf(customerCapType),
				FieldValidator.numberWithoutDecimal(),
				maxLength(255)
			]
			break
		case 'P':
			customerCapCondtion = [
				FieldValidator.requiredIf(customerCapType),
				FieldValidator.numberWithDecimal(2),
				maxLength(255)
			]
			break
	}

	if (campaignCap) {
		campaignCapCondition = [FieldValidator.numberWithoutDecimal()]
	}

	return updateGroup<CappingForm>({
		customerCap: validate(customerCapCondtion),
		campaignCap: validate([...campaignCapCondition, maxLength(255)]),
		capCycleMonth: validate([FieldValidator.checkCycleMonth(capCycleType)]),
		capCycleDay: validate([FieldValidator.checkCapCycleDay(capCycleType), FieldValidator.checkBillingCycleType(cycleTypeOption)]),
		cycleTypeOption: validate([FieldValidator.checkBillingCycle(capCycleType)])
	})
}

export interface RulesetRewardForm {
	rewardPool: string
	amountField: string
	type: string
	rewardCreditMethod: string
	transactionMethod: string
	rewardMethod: string
	conditionFactor: string
	valueFactor: string
	pointFactor: string
	accumulationCycleType: string
	accumulationCycleTypeOption: string
	accumulationCycleDay: string
	accumulationCycleMonth: string
	billingCycleType: string
	numberOfDays: string
	rounding: boolean
	roundingValue: string
	numberDecimal: string
	numberDecimalValue: string
	campaignTiers: CampaignTiers
	rewardLevel: string
}

export const initialRulesetRewardFormValue: RulesetRewardForm = {
	rewardPool: '',
	amountField: '',
	type: '',
	rewardCreditMethod: '',
	transactionMethod: '',
	rewardMethod: '',
	conditionFactor: '',
	valueFactor: '',
	pointFactor: '',
	accumulationCycleType: '',
	accumulationCycleTypeOption: '',
	accumulationCycleDay: '',
	accumulationCycleMonth: '',
	billingCycleType: '',
	numberOfDays: '',
	rounding: false,
	roundingValue: '',
	numberDecimal: '',
	numberDecimalValue: '',
	campaignTiers: {
		byId: {},
		allIds: []
	},
	rewardLevel: ''
}

export const initialRulesetRewardForm = createFormGroupState('rulesetRewardForm', initialRulesetRewardFormValue)

export const validateRulesetRewardForm = (state: State) => {
	const enableConditionalReward = state.informationDetailForm.value.enableConditionalReward
	const rulesetRewardForm = state.rulesetRewardForm.controls
	const rewardPool = rulesetRewardForm.rewardPool.value
	const amountFieldCondition = state.resourceType === 'BANK'
	const type = rulesetRewardForm.type.value
	const rewardCreditMethod = rulesetRewardForm.rewardCreditMethod.value
	const transactionMethod = rulesetRewardForm.transactionMethod.value
	const rewardMethod = rulesetRewardForm.rewardMethod.value
	const accumulationCycleType = rulesetRewardForm.accumulationCycleType.value
	const billingCycleType = rulesetRewardForm.billingCycleType && rulesetRewardForm.billingCycleType.value
	const accumulationCycleTypeOption = rulesetRewardForm.accumulationCycleTypeOption && rulesetRewardForm.accumulationCycleTypeOption.value
	const rounding = rulesetRewardForm.rounding.value

	let rewardCreditMethodCondition = false
	let transactionMethodCondition = false
	let rewardMethodCondition = false
	let conditionFactorCondition = false
	let valueFactorCondition = false
	let pointFactorCondition = false
	let accumulationCycleTypeCondition = false
	let accumulationCycleDayCondition = false
	let accumulationCycleMonthCondition = false
	let billingCycleTypeCondition = false
	let numberOfDaysCondition = false
	let rewardLevelCondition = false

	let conditionValidation = []
	let valueValidation = []
	let integerAndDecimalValidation = []

	conditionValidation = [FieldValidator.check2Decimal()]
	valueValidation = [FieldValidator.check2Decimal()]

	switch (type) {
		case 'X2X':
			rewardCreditMethodCondition = true
			transactionMethodCondition = true
			rewardMethodCondition = true

			if (transactionMethod === 'AMOUNT' && rewardMethod === 'RATIO') {
				valueFactorCondition = true
				pointFactorCondition = true
				valueValidation = [FieldValidator.integerAndDecimalCheck(10, 2)]
				integerAndDecimalValidation = [FieldValidator.integerAndDecimalCheck(10, 4)]
			}
			if (transactionMethod === 'SWIPE' && rewardMethod === 'FIX') {
				pointFactorCondition = true
				integerAndDecimalValidation = [FieldValidator.checkNumericLengthUpTo(10)]
			}

			if (rewardCreditMethod === 'CCE') {
				accumulationCycleTypeCondition = true

				if (accumulationCycleType === 'W' || accumulationCycleType === 'M' || (accumulationCycleType === 'Y' && (accumulationCycleTypeOption === 'FD' || amountFieldCondition))) {
					accumulationCycleDayCondition = true
				}

				if (accumulationCycleType === 'Y' && (accumulationCycleTypeOption === 'FD' || amountFieldCondition)) {
					accumulationCycleMonthCondition = true
				}

				if (accumulationCycleType === 'B') {
					billingCycleTypeCondition = true
					if ((billingCycleType === 'BC' || billingCycleType === 'AC')) {
						numberOfDaysCondition = true
					}
				}
			}
			break
		case 'OX':
			rewardCreditMethodCondition = true
			transactionMethodCondition = true
			rewardMethodCondition = true

			if (transactionMethod === 'AMOUNT' && rewardMethod === 'FIX') {
				conditionFactorCondition = true
				pointFactorCondition = true
				conditionValidation = [FieldValidator.integerAndDecimalCheck(10, 2)]
				integerAndDecimalValidation = [FieldValidator.integerAndDecimalCheck(10, 4)]
			}
			if (transactionMethod === 'SWIPE' && rewardMethod === 'FIX') {
				conditionFactorCondition = true
				pointFactorCondition = true
				integerAndDecimalValidation = [FieldValidator.integerAndDecimalCheck(10, 4)]
				conditionValidation = [FieldValidator.checkNumericLengthUpTo(10)]
			}

			if (rewardCreditMethod === 'I' || rewardCreditMethod === 'CCE') {
				accumulationCycleTypeCondition = true

				if (accumulationCycleType === 'W' || accumulationCycleType === 'M' || (accumulationCycleType === 'Y' && (accumulationCycleTypeOption === 'FD' || amountFieldCondition))) {
					accumulationCycleDayCondition = true
				}

				if (accumulationCycleType === 'Y' && (accumulationCycleTypeOption === 'FD' || amountFieldCondition)) {
					accumulationCycleMonthCondition = true
				}

				if (accumulationCycleType === 'B') {
					billingCycleTypeCondition = true
					if ((billingCycleType === 'BC' || billingCycleType === 'AC')) {
						numberOfDaysCondition = true
					}
				}
			}
			break
		case 'PX':
			rewardCreditMethodCondition = true
			transactionMethodCondition = true
			rewardMethodCondition = true

			if (transactionMethod === 'AMOUNT' && rewardMethod === 'FIX') {
				valueFactorCondition = true
				pointFactorCondition = true
				valueValidation = [FieldValidator.integerAndDecimalCheck(10, 2)]
				integerAndDecimalValidation = [FieldValidator.integerAndDecimalCheck(10, 4)]
			}
			if (transactionMethod === 'SWIPE' && rewardMethod === 'FIX') {
				valueFactorCondition = true
				pointFactorCondition = true
				integerAndDecimalValidation = [FieldValidator.integerAndDecimalCheck(10, 4)]
				valueValidation = [FieldValidator.numberWithoutDecimal()]
			}

			if (rewardCreditMethod === 'I' || rewardCreditMethod === 'CCE') {
				accumulationCycleTypeCondition = true

				if (accumulationCycleType === 'W' || accumulationCycleType === 'M' || (accumulationCycleType === 'Y' && (accumulationCycleTypeOption === 'FD' || amountFieldCondition))) {
					accumulationCycleDayCondition = true
				}

				if (accumulationCycleType === 'Y' && (accumulationCycleTypeOption === 'FD' || amountFieldCondition)) {
					accumulationCycleMonthCondition = true
				}

				if (accumulationCycleType === 'B') {
					billingCycleTypeCondition = true
					if ((billingCycleType === 'BC' || billingCycleType === 'AC')) {
						numberOfDaysCondition = true
					}
				}
			}
			break
		case 'TPT':
			if (!enableConditionalReward) {
				rewardCreditMethodCondition = true
				transactionMethodCondition = true
				rewardMethodCondition = true
			}
			break
		case 'TPC':
			rewardCreditMethodCondition = true
			transactionMethodCondition = true
			rewardMethodCondition = true

			if (rewardCreditMethod === 'CCE') {
				accumulationCycleTypeCondition = true

				if (accumulationCycleType === 'W' || accumulationCycleType === 'M' || (accumulationCycleType === 'Y' && (accumulationCycleTypeOption === 'FD' || amountFieldCondition))) {
					accumulationCycleDayCondition = true
				}

				if (accumulationCycleType === 'Y' && (accumulationCycleTypeOption === 'FD' || amountFieldCondition)) {
					accumulationCycleMonthCondition = true
				}

				if (accumulationCycleType === 'B') {
					billingCycleTypeCondition = true
					if ((billingCycleType === 'BC' || billingCycleType === 'AC')) {
						numberOfDaysCondition = true
					}
				}
			}
			break
		case 'INC' :
			rewardCreditMethodCondition = true
			transactionMethodCondition = true
			rewardLevelCondition = true
			conditionFactorCondition = true

			conditionValidation = [FieldValidator.numberWithoutDecimal()]

			if (transactionMethod === 'AMOUNT' && rewardMethod === 'RATIO') {
				valueFactorCondition = true
				pointFactorCondition = true
				valueValidation = [FieldValidator.integerAndDecimalCheck(10, 2)]
				integerAndDecimalValidation = [FieldValidator.integerAndDecimalCheck(10, 4)]
			}
			if (transactionMethod === 'SWIPE' && rewardMethod === 'RATIO') {
				valueFactorCondition = true
				pointFactorCondition = true
				valueValidation = [FieldValidator.numberWithoutDecimal()]
				integerAndDecimalValidation = [FieldValidator.integerAndDecimalCheck(10, 4)]
			}
			if (transactionMethod === 'AMOUNT' && rewardMethod === 'FIX') {
				pointFactorCondition = true
				integerAndDecimalValidation = [FieldValidator.integerAndDecimalCheck(10, 4)]
			}
			if (transactionMethod === 'SWIPE' && rewardMethod === 'FIX') {
				pointFactorCondition = true
				integerAndDecimalValidation = [FieldValidator.integerAndDecimalCheck(10, 4)]
			}

			if (rewardCreditMethod === 'CCE') {
				accumulationCycleTypeCondition = true

				if (accumulationCycleType === 'W' || accumulationCycleType === 'M' || (accumulationCycleType === 'Y' && (accumulationCycleTypeOption === 'FD' || amountFieldCondition))) {
					accumulationCycleDayCondition = true
				}

				if (accumulationCycleType === 'Y' && (accumulationCycleTypeOption === 'FD' || amountFieldCondition)) {
					accumulationCycleMonthCondition = true
				}

				if (accumulationCycleType === 'B') {
					billingCycleTypeCondition = true
					if ((billingCycleType === 'BC' || billingCycleType === 'AC')) {
						numberOfDaysCondition = true
					}
				}
			}
			break
	}

	return updateGroup<RulesetRewardForm>({
		rewardPool: validate([required]),
		amountField: validate([FieldValidator.requiredIfTrue(amountFieldCondition)]),
		type: validate([FieldValidator.requiredIf(rewardPool)]),
		rewardCreditMethod: validate([FieldValidator.requiredIfTrue(rewardCreditMethodCondition)]),
		transactionMethod: validate([FieldValidator.requiredIfTrue(transactionMethodCondition)]),
		rewardMethod: validate([FieldValidator.requiredIfTrue(rewardMethodCondition)]),
		conditionFactor: validate([...conditionValidation, maxLength(255), FieldValidator.requiredIfTrue(conditionFactorCondition)]),
		valueFactor: validate([...valueValidation, maxLength(255), FieldValidator.requiredIfTrue(valueFactorCondition)]),
		pointFactor: validate([...integerAndDecimalValidation, FieldValidator.requiredIfTrue(pointFactorCondition), maxLength(15)]),
		accumulationCycleType: validate([FieldValidator.requiredIfTrue(accumulationCycleTypeCondition)]),
		accumulationCycleDay: validate([FieldValidator.requiredIfTrue(accumulationCycleDayCondition)]),
		accumulationCycleMonth: validate([FieldValidator.requiredIfTrue(accumulationCycleMonthCondition)]),
		accumulationCycleTypeOption: validate([FieldValidator.requiredIfTrue(!amountFieldCondition && accumulationCycleType === 'Y')]),
		billingCycleType: validate([FieldValidator.requiredIfTrue(billingCycleTypeCondition)]),
		numberOfDays: validate([FieldValidator.requiredIfTrue(numberOfDaysCondition), FieldValidator.digit(), FieldValidator.NotStartWithZero()]),
		roundingValue: validate([FieldValidator.requiredIfTrue(rounding)]),
		numberDecimal: validate([FieldValidator.requiredIfTrue(rounding)]),
		numberDecimalValue: validate([FieldValidator.requiredIfTrue(rounding), FieldValidator.digit(), FieldValidator.digitRange(1, 9)]),
		rewardLevel: validate([FieldValidator.requiredIfTrue(rewardLevelCondition)])
	})
}

// Tier
export interface CampaignTierForm {
	conditionFactorFrom: string
	conditionFactorTo: string
	valueFactor: string
	pointFactor: string
}


export const initialCampaignTierFormValue: CampaignTierForm = {
	conditionFactorFrom: '',
	conditionFactorTo: '',
	valueFactor: '',
	pointFactor: ''
}

export const initialCampaignTierForm = createFormGroupState('campaignTierForm', initialCampaignTierFormValue)
export interface CampaignTiers {
	byId: { [key: string]: CampaignTier }
	allIds: string[]
}

export interface CampaignTier {
	id: string
	form: FormGroupState<CampaignTierForm>
}

export const validateCampaignTierForm = (id: string, state: State) => {

	const tiers = state && state.rulesetRewardForm && state.rulesetRewardForm.value.campaignTiers
	const tier = tiers && tiers.byId[id] && tiers.byId[id].form && tiers.byId[id].form.value
	const fromValue = tier && tier.conditionFactorFrom
	const toValue = tier && tier.conditionFactorTo

	let list = []
	if (tiers) {
		list = [...tiers.allIds.map(key => ({
			id: key,
			fromValue: tiers.byId[key].form.value.conditionFactorFrom,
			toValue: tiers.byId[key].form.value.conditionFactorTo
		}))]
	}

	const currentIndex = list.findIndex(x => x.id === id)
	let fromValueRequiredCondition = false
	let toValueRequiredCondition = false
	let fromValueCondition = false
	let toValueCondition = false

	if (currentIndex !== -1) {
		if (currentIndex === 0) {
			if (!fromValue && !toValue) {
				fromValueRequiredCondition = true
				toValueRequiredCondition = true
			}

			if (list.length > 1) {
				toValueRequiredCondition = true

				const next = list[currentIndex + 1]

				if (next) {
					if (!toValue) {
						toValueCondition = true
					} else {
						toValueCondition = +toValue >= +next.fromValue
					}
				}
			}
		} else {
			const previous = list[currentIndex - 1]
			const next = list[currentIndex + 1]

			fromValueRequiredCondition = true

			if (previous) {
				fromValueCondition = +fromValue <= +previous.toValue
			}

			if (next) {
				toValueCondition = +toValue >= +next.fromValue
			}

			if (currentIndex === (list.length - 1)) {
				toValueRequiredCondition = false
			}
		}
	}

	const campaignTier = state &&
		state.rulesetRewardForm.value.campaignTiers &&
		state.rulesetRewardForm.value.campaignTiers.byId[id]
	const from = campaignTier && campaignTier.form.controls.conditionFactorFrom.value
	const valueFactorCondition = state &&
		(state.rulesetRewardForm.controls.type.value === 'TPT' ||
			state.rulesetRewardForm.controls.type.value === 'TPC') &&
		state.rulesetRewardForm.controls.transactionMethod.value &&
		state.rulesetRewardForm.controls.rewardMethod.value === 'RATIO'

	const rulesetRewardForm = state && state.rulesetRewardForm && state.rulesetRewardForm.controls
	const transactionMethod = rulesetRewardForm && rulesetRewardForm.transactionMethod.value
	const rewardMethod = rulesetRewardForm && rulesetRewardForm.rewardMethod.value

	let pointValidation = []
	const valueValidation = [FieldValidator.integerAndDecimalCheck(10, 2)]

	switch (rulesetRewardForm && rulesetRewardForm.type.value) {
		case 'TPT':
			if (transactionMethod === 'AMOUNT' && rewardMethod === 'RATIO') {
				pointValidation = [FieldValidator.integerAndDecimalCheck(10, 4)]
			}

			if (transactionMethod === 'AMOUNT' && rewardMethod === 'FIX') {
				pointValidation = [FieldValidator.checkNumericLengthUpTo(10)]
			}
			break
		case 'TPC':
			if (transactionMethod === 'RATIO' || rewardMethod === 'RATIO') {
				pointValidation = [FieldValidator.integerAndDecimalCheck(10, 4)]
			}

			if (transactionMethod === 'FIX' || rewardMethod === 'FIX') {
				pointValidation = [FieldValidator.checkNumericLengthUpTo(10)]
			}
			break
	}

	return updateGroup<CampaignTierForm>({
		conditionFactorFrom: validate([
			FieldValidator.requiredIfTrue(fromValueRequiredCondition),
			maxLength(10),
			FieldValidator.numberWithoutDecimal(), // fix https://dni.atlassian.net/browse/LPP2-585
			FieldValidator.lessThanOrEqualTo(toValue),
			FieldValidator.ifTrue(fromValueCondition, 'previousTo')
		]),
		conditionFactorTo: validate([
			FieldValidator.requiredIfTrue(toValueRequiredCondition),
			maxLength(10),
			FieldValidator.numberWithoutDecimal(), // fix https://dni.atlassian.net/browse/LPP2-585
			FieldValidator.moreThanOrEqualTo(fromValue),
			FieldValidator.ifTrue(toValueCondition, 'nextFrom')
		]),
		valueFactor: validate([maxLength(13), ...valueValidation, FieldValidator.requiredIfTrue(valueFactorCondition)]),
		pointFactor: validate([required, ...pointValidation, maxLength(15)])
	})
}

// Rule

// Choose Date
export interface ChooseDateForm {
	value: string
	time: string
}

export const initialChooseDateFormValue: ChooseDateForm = {
	value: '',
	time: ''
}
export const validateChooseDateForm = updateGroup<ChooseDateForm>({
	value: validate([required])
})

// Transaction Amount
export interface TransactionAmountForm {
	equation: string
	value: string
}

export const initialTransactionAmountFormValue: TransactionAmountForm = {
	equation: 'MORE_THAN',
	value: ''
}
export const validateTransactionAmountForm = updateGroup<TransactionAmountForm>({
	equation: validate([required]),
	value: validate([required, FieldValidator.digit(), maxLength(255)])
})

// Product Type/ Group
export interface ProductTypeForm {
	equation: string
	value: Boxed<string[]>
	values: Boxed<string[]>
}

export const initialProductTypeFormValue: ProductTypeForm = {
	equation: 'INCLUDE',
	value: box([]),
	values: box([])
}

export const validateProductTypeForm = (id: string, state: State) => {
	const formValue = getRulesetForm(id, state)
	let valueCondition = true

	if (formValue && (unbox(formValue.value).toString() || unbox(formValue.values).toString())) {
		valueCondition = false
	}

	return updateGroup<ProductTypeForm>({
		equation: validate([required]),
		value: validate([value => { if (valueCondition) { return required(unbox(value)) } }]),
		values: validate([value => { if (valueCondition) { return required(unbox(value)) } }])
	})
}

// MCC/ MCC Group
export interface MccForm {
	equation: string
	value: string
	values: Boxed<string[]>
}

export const initialMccFormValue: MccForm = {
	equation: 'INCLUDE',
	value: '',
	values: box([])
}

export const validateMccForm = (id: string, state: State) => {
	const formValue = getRulesetForm(id, state)
	let valueCondition = false

	if (formValue) {
		valueCondition = !formValue.value && !unbox(formValue.values).toString()
	}

	return updateGroup<MccForm>({
		equation: validate([required]),
		value: validate([FieldValidator.requiredIfTrue(valueCondition), FieldValidator.checkNumeric(4, true, true), maxLength(255)]),
		values: validate([value => { if (valueCondition) { return required(unbox(value)) } }])
	})
}

// Merchant/ Merchant Group
export interface MerchantForm {
	equation: string
	value: string
	values: Boxed<string[]>
}

export const initialMerchantFormValue: MerchantForm = {
	equation: 'INCLUDE',
	value: '',
	values: box([])
}

export const validateMerchantForm = (id: string, state: State) => {
	const formValue = getRulesetForm(id, state)
	let valueCondition = false

	if (formValue) {
		valueCondition = !formValue.value && !unbox(formValue.values).toString()
	}

	return updateGroup<MerchantForm>({
		equation: validate([required]),
		value: validate([FieldValidator.requiredIfTrue(valueCondition), FieldValidator.checkAlphaNumeric(15, true, true), maxLength(255)]),
		values: validate([value => { if (valueCondition) { return required(unbox(value)) } }])
	})
}

// Selected Day
export interface SelectedDayForm {
	value: string
	daily: Daily
	weekly: Weekly
	monthly: Monthly
}

export interface Daily {
	startTime: string
	endTime: string
}

export interface Weekly {
	monday: Day
	tuesday: Day
	wednesday: Day
	thursday: Day
	friday: Day
	saturday: Day
	sunday: Day
}

export interface Day {
	isCheck: boolean
	startTime: string
	endTime: string
}

export interface Monthly {
	day: string
	startTime: string
	endTime: string
}

export const initialSelectedDayFormValue: SelectedDayForm = {
	value: '',
	daily: {
		startTime: '00:00',
		endTime: '23:59'
	},
	weekly: {
		monday: {
			isCheck: false,
			startTime: '00:00',
			endTime: '23:59'
		},
		tuesday: {
			isCheck: false,
			startTime: '00:00',
			endTime: '23:59'
		},
		wednesday: {
			isCheck: false,
			startTime: '00:00',
			endTime: '23:59'
		},
		thursday: {
			isCheck: false,
			startTime: '00:00',
			endTime: '23:59'
		},
		friday: {
			isCheck: false,
			startTime: '00:00',
			endTime: '23:59'
		},
		saturday: {
			isCheck: false,
			startTime: '00:00',
			endTime: '23:59'
		},
		sunday: {
			isCheck: false,
			startTime: '00:00',
			endTime: '23:59'
		}
	},
	monthly: {
		day: '1',
		startTime: '00:00',
		endTime: '23:59'
	}
}

export const validateSelectedDayForm = (id: string, state: State) => {
	const formValue = getRulesetForm(id, state)
	let dailyCondition = false
	let mondayCondition = false
	let tuesdayCondition = false
	let wednesdayCondition = false
	let thursdayCondition = false
	let fridayCondition = false
	let saturdayCondition = false
	let sundayCondition = false
	let monthlyCondition = false

	if (formValue) {
		const value = formValue.value
		const weekly = formValue.weekly
		switch (value) {
			case 'D':
				dailyCondition = true
				break
			case 'W':
				mondayCondition = weekly.monday.isCheck
				tuesdayCondition = weekly.tuesday.isCheck
				wednesdayCondition = weekly.wednesday.isCheck
				thursdayCondition = weekly.thursday.isCheck
				fridayCondition = weekly.friday.isCheck
				saturdayCondition = weekly.saturday.isCheck
				sundayCondition = weekly.sunday.isCheck
				break
			case 'M':
				monthlyCondition = true
				break
		}
	}
	return updateGroup<SelectedDayForm>({
		value: validate([required]),
		daily: updateGroup<Daily>({
			startTime: validate([FieldValidator.requiredIfTrue(dailyCondition)]),
			endTime: validate([FieldValidator.requiredIfTrue(dailyCondition)])
		}),
		weekly: updateGroup<Weekly>({
			monday: updateGroup<Day>({
				startTime: validate([FieldValidator.requiredIfTrue(mondayCondition)]),
				endTime: validate([FieldValidator.requiredIfTrue(mondayCondition)])
			}),
			tuesday: updateGroup<Day>({
				startTime: validate([FieldValidator.requiredIfTrue(tuesdayCondition)]),
				endTime: validate([FieldValidator.requiredIfTrue(tuesdayCondition)])
			}),
			wednesday: updateGroup<Day>({
				startTime: validate([FieldValidator.requiredIfTrue(wednesdayCondition)]),
				endTime: validate([FieldValidator.requiredIfTrue(wednesdayCondition)])
			}),
			thursday: updateGroup<Day>({
				startTime: validate([FieldValidator.requiredIfTrue(thursdayCondition)]),
				endTime: validate([FieldValidator.requiredIfTrue(thursdayCondition)])
			}),
			friday: updateGroup<Day>({
				startTime: validate([FieldValidator.requiredIfTrue(fridayCondition)]),
				endTime: validate([FieldValidator.requiredIfTrue(fridayCondition)])
			}),
			saturday: updateGroup<Day>({
				startTime: validate([FieldValidator.requiredIfTrue(saturdayCondition)]),
				endTime: validate([FieldValidator.requiredIfTrue(saturdayCondition)])
			}),
			sunday: updateGroup<Day>({
				startTime: validate([FieldValidator.requiredIfTrue(sundayCondition)]),
				endTime: validate([FieldValidator.requiredIfTrue(sundayCondition)])
			})
		}),
		monthly: updateGroup<Monthly>({
			day: validate([FieldValidator.requiredIfTrue(monthlyCondition)]),
			startTime: validate([FieldValidator.requiredIfTrue(monthlyCondition)]),
			endTime: validate([FieldValidator.requiredIfTrue(monthlyCondition)])
		})
	})
}

// Transaction Code/ Transaction Code Group
export interface TransactionCodeForm {
	equation: string
	value: string
	values: Boxed<string[]>
	isCheck: boolean
	reversalValues: Boxed<string[]>
}

export const initialTransactionCodeFormValue: TransactionCodeForm = {
	equation: 'INCLUDE',
	value: '',
	values: box([]),
	isCheck: false,
	reversalValues: box([])
}

export const validateTransactionCodeForm = (id: string, state: State) => {
	const formValue = getRulesetForm(id, state)
	let valueCondition = false
	let reversalValuesCondition = false

	if (formValue) {
		valueCondition = !formValue.value && !unbox(formValue.values).toString()
		reversalValuesCondition = formValue.isCheck
	}

	return updateGroup<TransactionCodeForm>({
		equation: validate([required]),
		value: validate([FieldValidator.requiredIfTrue(valueCondition), FieldValidator.checkNumeric(4, true, true), maxLength(255)]),
		values: validate([value => { if (valueCondition) { return required(unbox(value)) } }]),
		reversalValues: validate([value => { if (reversalValuesCondition) { return required(unbox(value)) } }])
	})
}

// Approval Code
export interface ApprovalCodeForm {
	equation: string
	value: string
}

export const initialApprovalCodeFormValue: ApprovalCodeForm = {
	equation: 'EQUAL',
	value: ''
}

export const validateApprovalCodeForm = updateGroup<ApprovalCodeForm>({
	equation: validate([required]),
	value: validate([required, FieldValidator.checkAlphaNumeric(6), maxLength(255)])
})

// Transaction Description
export interface TransactionDescriptionForm {
	equation: string
	value: string
}

export const initialTransactionDescriptionFormValue: TransactionDescriptionForm = {
	equation: 'EQUAL',
	value: ''
}

export const validateTransactionDescriptionForm = updateGroup<TransactionDescriptionForm>({
	equation: validate([required]),
	value: validate([required, maxLength(255), FieldValidator.checkValidCharacters()])
})

// POS Entry Mode
export interface PosEntryModeForm {
	equation: string
	value: string
}

export const initialPosEntryModeFormValue: PosEntryModeForm = {
	equation: 'EQUAL',
	value: ''
}

export const validatePosEntryModeForm = updateGroup<PosEntryModeForm>({
	equation: validate([required]),
	value: validate([required, FieldValidator.checkAlphaNumeric(2), maxLength(255)])
})

// Transaction Mode
export interface TransactionModeForm {
	equation: string
	value: string
}

export const initialTransactionModeFormValue: TransactionModeForm = {
	equation: 'EQUAL',
	value: ''
}

export const validateTransactionModeForm = updateGroup<TransactionModeForm>({
	equation: validate([required]),
	value: validate([required, FieldValidator.checkAlphaNumeric(2), maxLength(255)])
})

// Posting Currency Code
export interface PostingCurrencyCodeForm {
	equation: string
	value: string
}

export const initialPostingCurrencyCodeFormValue: PostingCurrencyCodeForm = {
	equation: 'EQUAL',
	value: ''
}

export const validatePostingCurrencyCodeForm = updateGroup<PostingCurrencyCodeForm>({
	equation: validate([required]),
	value: validate([required, FieldValidator.checkAlphaNumeric(3, true, true, false, true), maxLength(255)])
})

// Transaction Currency Code
export interface TransactionCurrencyCodeForm {
	equation: string
	value: string
}

export const initialTransactionCurrencyCodeFormValue: TransactionCurrencyCodeForm = {
	equation: 'EQUAL',
	value: ''
}

export const validateTransactionCurrencyCodeForm = updateGroup<TransactionCurrencyCodeForm>({
	equation: validate([required]),
	value: validate([required, FieldValidator.checkAlphaNumeric(3, true, true, false, true), maxLength(255)])
})

// Country Code
export interface CountryCodeForm {
	equation: string
	value: Boxed<string[]>
}

export const initialCountryCodeFormValue: CountryCodeForm = {
	equation: 'EQUAL',
	value: box([])
}

export const validateCountryCodeForm = updateGroup<CountryCodeForm>({
	equation: validate([required]),
	value: validate([value => required(unbox(value))])
})

// Customer Block Code
export interface CustomerBlockCodeForm {
	equation: string
	value: string
}

export const initialCustomerBlockCodeFormValue: CustomerBlockCodeForm = {
	equation: 'EQUAL',
	value: ''
}

export const validateCustomerBlockCodeForm = updateGroup<CustomerBlockCodeForm>({
	equation: validate([required]),
	value: validate([required, FieldValidator.checkAlphaNumeric(1, false, false, true), maxLength(255)])
})

// Account Block Code
export interface AccountBlockCodeForm {
	equation: string
	value: string
}

export const initialAccountBlockCodeFormValue: AccountBlockCodeForm = {
	equation: 'EQUAL',
	value: ''
}

export const validateAccountBlockCodeForm = updateGroup<AccountBlockCodeForm>({
	equation: validate([required]),
	value: validate([required, FieldValidator.checkAlphaNumeric(1, false, false, true), maxLength(255)])
})

// Card Block Code
export interface CardBlockCodeForm {
	equation: string
	value: string
}

export const initialCardBlockCodeFormValue: CardBlockCodeForm = {
	equation: 'EQUAL',
	value: ''
}

export const validateCardBlockCodeForm = updateGroup<CardBlockCodeForm>({
	equation: validate([required]),
	value: validate([required, FieldValidator.checkAlphaNumeric(1, false, false, true), maxLength(255)])
})

// Birthday
export interface BirthdayForm {
	value: string
	xDaysBefore: string
	xDaysAfter: string
}

export const initialBirthdayFormValue: BirthdayForm = {
	value: '',
	xDaysBefore: '0',
	xDaysAfter: '0'
}

export const validateBirthdayForm = (id: string, state: State) => {
	let xDaysCondition = false
	const rulesetDetailForm = state && state.rulesetDetailForm.controls
	const rulesets = rulesetDetailForm && rulesetDetailForm.rulesets.value
	const rulesetChilds = rulesetDetailForm && rulesetDetailForm.rulesetChilds.value

	const form: Rulesets = {
		byId: {
			...rulesets && rulesets.byId,
			...rulesetChilds && rulesetChilds.byId
		},
		allIds: {
			...rulesets && rulesets.allIds,
			...rulesetChilds && rulesetChilds.allIds
		}
	}

	const formValue = form && form.byId[id] && form.byId[id].form.value
	xDaysCondition = formValue && formValue.value === 'X_DAYS_BEFORE_X_DAYS_AFTER'

	return updateGroup<BirthdayForm>({
		value: validate([required]),
		xDaysBefore: validate([FieldValidator.requiredIfTrue(xDaysCondition), FieldValidator.digit(), maxLength(255)]),
		xDaysAfter: validate([FieldValidator.requiredIfTrue(xDaysCondition), FieldValidator.digit(), maxLength(255)])
	})
}

// Customer Status
export interface CustomerStatusForm {
	equation: string
	value: Boxed<string[]>
}

export const initialCustomerStatusFormValue: CustomerStatusForm = {
	equation: 'EQUAL',
	value: box([])
}

export const validateCustomerStatusForm = updateGroup<CustomerStatusForm>({
	equation: validate([required]),
	value: validate([value => required(unbox(value))])
})

// Account Status
export interface AccountStatusForm {
	equation: string
	value: Boxed<string[]>
}

export const initialAccountStatusFormValue: AccountStatusForm = {
	equation: 'EQUAL',
	value: box([])
}

export const validateAccountStatusForm = updateGroup<AccountStatusForm>({
	equation: validate([required]),
	value: validate([value => required(unbox(value))])
})

// Card Status
export interface CardStatusForm {
	equation: string
	value: Boxed<string[]>
}

export const initialCardStatusFormValue: CardStatusForm = {
	equation: 'EQUAL',
	value: box([])
}

export const validateCardStatusForm = updateGroup<CardStatusForm>({
	equation: validate([required]),
	value: validate([value => required(unbox(value))])
})

// Member Since
export interface MemberSinceForm {
	equation: string
	value: string
}

export const initialMemberSinceFormValue: MemberSinceForm = {
	equation: 'MORE_THAN',
	value: ''
}

export const validateMemberSinceForm = updateGroup<MemberSinceForm>({
	equation: validate([required]),
	value: validate([required])
})

// Comparison Date
export interface ComparisonDateForm {
	comparisonDate: string
	equation: string
	value: string
}

export const initialComparisonDateFormValue: ComparisonDateForm = {
	comparisonDate: '',
	equation: 'MORE_THAN',
	value: ''
}

export const validateComparisonDateForm = updateGroup<ComparisonDateForm>({
	comparisonDate: validate([required]),
	equation: validate([required]),
	value: validate([required, FieldValidator.checkNumeric(), maxLength(255)])
})

// VIP Ind
export interface VIPIndForm {
	value: string
}

export const initialVIPIndFormValue: VIPIndForm = {
	value: '',
}

export const validateVIPIndForm = updateGroup<VIPIndForm>({
	value: validate([required, FieldValidator.checkAlphaNumeric(), maxLength(255)])
})

// Staff Ind
export interface StaffIndForm {
	value: string
}

export const initialStaffIndFormValue: StaffIndForm = {
	value: '',
}

export const validateStaffIndForm = updateGroup<StaffIndForm>({
	value: validate([required, FieldValidator.checkAlphaNumeric(), maxLength(255)]),
})

// Recurring Ind
export interface RecurringIndForm {
	value: string
}

export const initialRecurringIndFormValue: RecurringIndForm = {
	value: '',
}

export const validateRecurringIndForm = updateGroup<RecurringIndForm>({
	value: validate([required, FieldValidator.checkAlphaNumeric(), maxLength(255)])
})

// E-Commerce Ind
export interface ECommerceIndForm {
	value: string
}

export const initialECommerceIndFormValue: ECommerceIndForm = {
	value: '',
}

export const validateECommerceIndForm = updateGroup<ECommerceIndForm>({
	value: validate([required, FieldValidator.checkAlphaNumeric(1, true, false, false, false), maxLength(255)])
})

// Target Customer
export interface TargetCustomerForm {
	equation: string
	values: Boxed<string[]>
}

export const initialTargetCustomerFormValue: TargetCustomerForm = {
	equation: 'INCLUDE',
	values: box([])
}

export const validateTargetCustomerForm = updateGroup<TargetCustomerForm>({
	equation: validate([required]),
	values: validate([value => required(unbox(value))])
})

// Target Account
export interface TargetAccountForm {
	equation: string
	values: Boxed<string[]>
}

export const initialTargetAccountFormValue: TargetAccountForm = {
	equation: 'INCLUDE',
	values: box([])
}

export const validateTargetAccountForm = updateGroup<TargetAccountForm>({
	equation: validate([required]),
	values: validate([value => required(unbox(value))])
})

// Target Card
export interface TargetCardForm {
	equation: string
	values: Boxed<string[]>
}

export const initialTargetCardFormValue: TargetCardForm = {
	equation: 'INCLUDE',
	values: box([])
}

export const validateTargetCardForm = updateGroup<TargetCardForm>({
	equation: validate([required]),
	values: validate([value => required(unbox(value))])
})

// Segment
export interface SegmentForm {
	value: string
}

export const initialSegmentFormValue: SegmentForm = {
	value: ''
}

export const validateSegmentForm = updateGroup<SegmentForm>({
	value: validate([required, FieldValidator.checkAlphaNumeric(255, false, true), maxLength(500)])
})

// Staff Ind Acc
export interface StaffIndAccForm {
	value: string
}

export const initialStaffIndAccFormValue: StaffIndAccForm = {
	value: '',
}

export const validateStaffIndAccForm = updateGroup<StaffIndAccForm>({
	value: validate([required, FieldValidator.checkAlphaNumeric(), maxLength(255)])
})

// Indicator
export interface IndicatorForm {
	value: string
}

export const initialIndicatorFormValue: IndicatorForm = {
	value: '',
}

export const validateIndicatorForm = updateGroup<IndicatorForm>({
	value: validate([required, FieldValidator.checkAlphaNumeric(), maxLength(255)])
})

// Description
export interface DescriptionForm {
	equation: string
	value: string
}

export const initialDescriptionFormValue: DescriptionForm = {
	equation: 'EQUAL',
	value: ''
}

export const validateDescriptionForm = updateGroup<DescriptionForm>({
	equation: validate([required]),
	value: validate([required, maxLength(50), FieldValidator.checkFirstPositionContainSpace()])
})

// Balance
export interface BalanceForm {
	equation: string
	value: string
}

export const initialBalanceFormValue: BalanceForm = {
	equation: 'MORE_THAN',
	value: ''
}

export const validateBalanceForm = updateGroup<BalanceForm>({
	equation: validate([required]),
	value: validate([required, FieldValidator.check2Decimal(), maxLength(255)])
})

// Amount
export interface AmountForm {
	equation: string
	value: string
}

export const initialAmountFormValue: AmountForm = {
	equation: 'MORE_THAN',
	value: ''
}

export const validateAmountForm = updateGroup<AmountForm>({
	equation: validate([required]),
	value: validate([required, FieldValidator.check2Decimal(), maxLength(255)])
})

// Date
export interface DateForm {
	comparisonDate: string
	equation: string
	value: string
}

export const initialDateFormValue: DateForm = {
	comparisonDate: '',
	equation: 'MORE_THAN',
	value: ''
}

export const validateDateForm = updateGroup<DateForm>({
	equation: validate([required]),
	value: validate([required, FieldValidator.checkNumeric(), maxLength(255)])
})

export const initialState: State = {
	campaignIsLoading: false,
	pbIsLoading: false,
	isLoading: false,
	isDuplicateLoading: false,
	isDuplicateDialog: false,
	informationDetailForm: initialInformationDetailForm,
	rulesetListForm: initialRulesetListForm,
	cappingForm: initialCappingForm,
	rulesetDetailForm: initialRulesetDetailForm,
	rulesetRewardForm: initialRulesetRewardForm,
	campaignDuplicateForm: initialCampaignDuplicateForm,
	paginatorMetadata: {},
	campaignWorklistDetail: {
		taskName: '',
		code: '',
		name: '',
		modifiedBy: '',
		modifiedDate: '',
		campaignInformation: [],
		capping: [],
		ruleset: []
	},
	campaignPublishedListResponse: {
		pageHeader: {
			itemCount: 0,
			pageNum: 0,
			pageSize: 5,
			totalItem: 0,
			totalPage: 0
		},
		resultList: [],
	},
	campaignDraftListResponse: {
		pageHeader: {
			itemCount: 0,
			pageNum: 0,
			pageSize: 5,
			totalItem: 0,
			totalPage: 0
		},
		resultList: [],
	},
	campaignUnpublishedListResponse: {
		pageHeader: {
			itemCount: 0,
			pageNum: 0,
			pageSize: 5,
			totalItem: 0,
			totalPage: 0
		},
		resultList: [],
	},
	campaignPbPublishedListResponse: {
		pageHeader: {
			itemCount: 0,
			pageNum: 0,
			pageSize: 5,
			totalItem: 0,
			totalPage: 0
		},
		resultList: [],
	},
	campaignPbDraftListResponse: {
		pageHeader: {
			itemCount: 0,
			pageNum: 0,
			pageSize: 5,
			totalItem: 0,
			totalPage: 0
		},
		resultList: [],
	},
	campaignPbUnpublishedListResponse: {
		pageHeader: {
			itemCount: 0,
			pageNum: 0,
			pageSize: 5,
			totalItem: 0,
			totalPage: 0
		},
		resultList: [],
	},
	ruleList: [],
	campaignData: {
		accumulationCycleTypeList: [],
		campaignCycleTypeList: [],
		cappingTypeList: [],
		channelList: [],
		dayList: [],
		dayOfMonthList: [],
		monthList: [],
		rewardCreditMethodList: [],
		rewardTypeList: [],
		transactionMethodList: [],
		transactionsMethodList: [],
		rewardPools: [],
		roundingList: [],
		numberDecimalList: [],
		calculationMethodList: [],
		rewardLevelList: [],
		billingCycleTypeList: [],
		yearlyCycleTypeList: []
	},
	rulesetData: {
		birthdayList: [],
		chooseDateList: [],
		comparisonDateList: [],
		comparisonList: [],
		criteriaList: [],
		dayOfMonthList: [],
		equalList: [],
		includeExcludeList: [],
		timeList: [],
		customerList: [],
		accountList: [],
		cardList: [],
		accountStatusList: [],
		countryCodeList: [],
		customerStatusList: [],
		cappingMethods: [],
		productTypes: [],
		productTypeGroups: [],
		mccGroups: [],
		merchantGroups: [],
		tcGroups: [],
		selectedDateTimeList: [],
		amountList: [],
		cardStatusList: []
	},
	resourceType: '',
	informationDetailFormView: initialInformationDetailFormValue,
	rulesetListFormView: initialRulesetListFormValue,
	cappingFormView: initialCappingFormValue,
	campaignRulesetView: [],
	allowDelete: true,
	allowRepublish: true
}

function getRulesetForm(id: string, state: State) {
	const rulesetDetailForm = state && state.rulesetDetailForm.controls
	const rulesets = rulesetDetailForm && rulesetDetailForm.rulesets.value
	const rulesetChilds = rulesetDetailForm && rulesetDetailForm.rulesetChilds.value

	const form: Rulesets = {
		byId: {
			...rulesets && rulesets.byId,
			...rulesetChilds && rulesetChilds.byId
		},
		allIds: {
			...rulesets && rulesets.allIds,
			...rulesetChilds && rulesetChilds.allIds
		}
	}

	return form && form.byId[id] && form.byId[id].form.value
}
