import { Component, OnInit, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material'
import { MessageDialogData } from 'src/app/models/common/dialog'

@Component({
	selector: 'app-textarea-dialog',
	templateUrl: './textarea-dialog.component.html',
	styleUrls: ['./textarea-dialog.component.scss']
})
export class TextareaDialogComponent implements OnInit {

	txtAreaInput = ''
	content: string

	constructor(
		private dialogRef: MatDialogRef<TextareaDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: MessageDialogData
	) {
		this.content = data.content
	}

	ngOnInit() {
	}

	cancel() {
		this.dialogRef.close()
	}

	submit() {
		this.data.payload = this.txtAreaInput
		this.dialogRef.close(this.data)
	}

}
