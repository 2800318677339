import { createFeatureSelector, createSelector } from '@ngrx/store'
import * as WaiverManagement from './waiver-management.state'

const waiverManagement = createFeatureSelector<WaiverManagement.State>('waiverManagement')

export const getWaiverManagement = createSelector(waiverManagement, x => x)

export const getWaiverRewardPools = createSelector(waiverManagement, ({ assignedWaiverForm }): WaiverManagement.WaiverRewardPool[] => {
	let result = []
	const list = assignedWaiverForm.controls.waiverRewardPools.value

	if (list && list.allIds) {
		result = list.allIds.map(id => list.byId[id])
	}

	return result
})

export const getWaiverRewardPool = createSelector(waiverManagement, ({ assignedWaiverForm }, id: string) => {
	const waiverRewardPools = assignedWaiverForm.controls.waiverRewardPools.value

	const form: WaiverManagement.WaiverRewardPools = {
		byId: {
			...waiverRewardPools.byId
		},
		allIds: {
			...waiverRewardPools.allIds
		}
	}

	return form && form.byId[id] && form.byId[id].form
})
