import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { Request, Response } from 'src/app/models/common/http'
import { RestService } from '../common/rest-client/rest.service'
import { TargetSegmentViewRequest } from 'src/app/models/target-segment/target-segment-view'
import { TargetSegmentResourceListRequest, TargetSegmentUpdateRequest } from 'src/app/models/target-segment/target-segment-edit'
import { TargetSegmentDeleteRequest } from 'src/app/models/target-segment/target-segment-delete'
import { TargetSegmentCreateRequest } from 'src/app/models/target-segment/target-segment-create'

@Injectable({
	providedIn: 'root'
})
export class TargetSegmentService {

	constructor(
		private rest: RestService
	) { }

	getCustomerIdSegmentList(): Observable<Response> {
		const request: Request = {
			liveURL: '/targetSegment/customerId/list'
		}

		return this.rest.get(request)
	}

	getAccountSegmentList(): Observable<Response> {
		const request: Request = {
			liveURL: '/targetSegment/account/list'
		}

		return this.rest.get(request)
	}

	getCardSegmentList(): Observable<Response> {
		const request: Request = {
			liveURL: '/targetSegment/card/list'
		}

		return this.rest.get(request)
	}

	getTargetSegmentDetails(params: TargetSegmentViewRequest): Observable<Response> {
		const request: Request = {
			liveURL: `/targetSegment/${params.segment}/view/${params.id}`
		}

		return this.rest.get(request)
	}

	getTargetSegmentResourceList(params: TargetSegmentResourceListRequest): Observable<Response> {
		const request: Request = {
			liveURL: `/targetSegment/${params.segment}/resourceList/${params.action}`
		}

		return this.rest.get(request)
	}

	createTargetSegment(params: { segment: string, body: TargetSegmentCreateRequest }): Observable<Response> {
		const request: Request = {
			liveURL: `/targetSegment/${params.segment}/create`,
			body: params.body
		}

		return this.rest.post(request)
	}

	updateTargetSegment(params: { direction: TargetSegmentViewRequest, body: TargetSegmentUpdateRequest }): Observable<Response> {
		const request: Request = {
			liveURL: `/targetSegment/${params.direction.segment}/edit/${params.direction.id}`,
			body: params.body
		}

		return this.rest.post(request)
	}

	deleteTargetSegment(params: TargetSegmentDeleteRequest): Observable<Response> {
		const request: Request = {
			liveURL: `/targetSegment/${params.segment}/delete/${params.id}`
		}

		return this.rest.post(request)
	}
}
