import { Action, createReducer, on } from '@ngrx/store'
import { formGroupReducer, markAsTouched, reset, setValue, SetValueAction } from 'ngrx-forms'
import * as NgrxForms from 'ngrx-forms'
import * as AutoRedemptionAction from './auto-redemption.actions'
import * as AutoRedemptionState from './auto-redemption.state'

export const reducer = createReducer(
	AutoRedemptionState.initialState,
	NgrxForms.onNgrxFormsAction(SetValueAction, (state, action) => {
		return actionHandler(state, action)
	}),
	NgrxForms.onNgrxFormsAction(NgrxForms.MarkAsTouchedAction, (state, action) => {
		return actionHandler(state, action)
	}),
	on(AutoRedemptionAction.InitialState, () => AutoRedemptionState.initialState),
	on(AutoRedemptionAction.List, state => ({
		...state,
		isLoading: true
	})),
	on(AutoRedemptionAction.ListSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		autoRedemptionList: payload
	})),
	on(AutoRedemptionAction.SetId, (state, { id }) => ({
		...state,
		id
	})),
	on(AutoRedemptionAction.View, state => ({
		...state,
		isLoading: true,
		autoRedemptionDetailForm: setValue(state.autoRedemptionDetailForm, AutoRedemptionState.initialAutoRedemptionDetailFormValue),
		autoRedemptionView: AutoRedemptionState.initialAutoRedemptionDetailFormValue
	})),
	on(AutoRedemptionAction.ViewSuccess, (state, { payload }) => {
		const autoRedemptionView = {
			code: payload.code,
			name: payload.name,
			rewardPoolId: payload.rewardPoolType + '_' + payload.rewardPoolId,
			redemptionType: payload.redemptionType,
			point: payload.point != null ? payload.point.toString() : null,
			value: payload.value != null ? payload.value.toFixed(2) : null,
			cycleType: payload.cycleType,
			cycleDay: payload.cycleDay,
			cycleMonth: payload.cycleMonth,
			creditTransactionCode: payload.creditTransactionCode,
			creditTransactionDescription:  payload.creditTransactionDescription,
			debitTransactionCode:  payload.debitTransactionCode,
			debitTransactionDescription:  payload.debitTransactionDescription,
			cycleTypeOption: payload.cycleTypeOption,
			numberOfDays: payload.numberOfDays != null ? payload.numberOfDays.toString() : null,
			createdBy: payload.createdBy,
			createdDate: payload.createdDate,
			modifiedBy: payload.modifiedBy,
			modifiedDate: payload.modifiedDate,
			capping: payload.capping,
			cappingLevel: payload.cappingLevel || '',
			cappingValue: payload.cappingValue ? `${payload.cappingValue}` : ''
		}
		return ({
			...state,
			isLoading: false,
			id: payload.id,
			autoRedemptionView,
			autoRedemptionDetailForm: AutoRedemptionState.validateAutoRedemptionDetailForm(state)(setValue(state.autoRedemptionDetailForm, autoRedemptionView as AutoRedemptionState.AutoRedemptionDetailForm))
		})
	}),
	on(AutoRedemptionAction.GoCreate, state => ({
		...state,
		autoRedemptionDetailForm: reset(setValue(state.autoRedemptionDetailForm, AutoRedemptionState.initialAutoRedemptionDetailFormValue))
	})),
	on(AutoRedemptionAction.Create, state => ({
		...state,
		isLoading: true
	})),
	on(AutoRedemptionAction.CreateSuccess, (state, { payload }) => ({
		...state,
		isLoading: false
	})),
	on(AutoRedemptionAction.Update, state => ({
		...state,
		isLoading: true
	})),
	on(AutoRedemptionAction.UpdateSuccess, state => ({
		...state,
		isLoading: false
	})),
	on(AutoRedemptionAction.Delete, state => ({
		...state,
		isLoading: true
	})),
	on(AutoRedemptionAction.DeleteSuccess, () => AutoRedemptionState.initialState),
	on(AutoRedemptionAction.RewardPoolAndGroupList, state => ({
		...state,
		isLoading: true
	})),
	on(AutoRedemptionAction.RewardPoolAndGroupListSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		rewardPoolAndGroupList: (payload || [])
	})),
	on(AutoRedemptionAction.RedemptionTypeList, state => ({
		...state,
		isLoading: true
	})),
	on(AutoRedemptionAction.RedemptionTypeListSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		redemptionTypeList: payload.redemptionTypeList
	})),
	on(AutoRedemptionAction.CycleTypeList, state => ({
		...state,
		isLoading: true
	})),
	on(AutoRedemptionAction.CycleTypeListSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		cycleTypeList: payload.cycleTypeList
	})),
	on(AutoRedemptionAction.CycleWeeklyList, state => ({
		...state,
		isLoading: true
	})),
	on(AutoRedemptionAction.CycleWeeklyListSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		cycleWeeklyList: payload.cycleWeeklyList
	})),
	on(AutoRedemptionAction.CycleMonthlyList, state => ({
		...state,
		isLoading: true
	})),
	on(AutoRedemptionAction.CycleMonthlyListSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		cycleMonthlyList: payload.cycleMonthlyList
	})),
	on(AutoRedemptionAction.CycleYearlyList, state => ({
		...state,
		isLoading: true
	})),
	on(AutoRedemptionAction.CycleYearlyListSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		cycleYearlyList: payload.cycleYearlyList
	})),
	on(AutoRedemptionAction.BillingCycleTypeList, state => ({
		...state,
		isLoading: true
	})),
	on(AutoRedemptionAction.BillingCycleTypeListSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		billingCycleTypeList: payload.billingCycleType
	})),
	on(AutoRedemptionAction.YearlyTypeList, state => ({
		...state,
		isLoading: true
	})),
	on(AutoRedemptionAction.YearlyTypeListSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		yearlyCycleTypeList: payload.yearlyType
	})),
	on(AutoRedemptionAction.CappingLevelListSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		cappingLevelList: payload.cappingLevelList
	})),
	on(AutoRedemptionAction.RestError, state => ({
		...state,
		isLoading: false,
		isDuplicateDialog: false,
		isDuplicateLoading: false
	})),
	on(AutoRedemptionAction.ClearCycleDayMonth, (state, { }) => ({
		...state,
		isLoading: false,
		autoRedemptionDetailForm: AutoRedemptionState.validateAutoRedemptionDetailForm(state)(setValue(state.autoRedemptionDetailForm, {
			code: state.autoRedemptionDetailForm.value.code,
			name: state.autoRedemptionDetailForm.value.name,
			rewardPoolId: state.autoRedemptionDetailForm.value.rewardPoolId,
			redemptionType: state.autoRedemptionDetailForm.value.redemptionType,
			point: state.autoRedemptionDetailForm.value.point,
			value: state.autoRedemptionDetailForm.value.value,
			cycleType: state.autoRedemptionDetailForm.value.cycleType,
			cycleDay: null,
			cycleMonth: null,
			creditTransactionCode: state.autoRedemptionDetailForm.value.creditTransactionCode,
			creditTransactionDescription: state.autoRedemptionDetailForm.value.creditTransactionDescription,
			debitTransactionCode: state.autoRedemptionDetailForm.value.debitTransactionCode,
			debitTransactionDescription: state.autoRedemptionDetailForm.value.debitTransactionDescription,
			cycleTypeOption: null,
			numberOfDays: null,
			createdBy: state.autoRedemptionDetailForm.value.createdBy,
			createdDate: state.autoRedemptionDetailForm.value.createdDate,
			modifiedBy: state.autoRedemptionDetailForm.value.modifiedBy,
			modifiedDate: state.autoRedemptionDetailForm.value.modifiedDate,
			capping: state.autoRedemptionDetailForm.value.capping,
			cappingLevel: state.autoRedemptionDetailForm.value.cappingLevel,
			cappingValue: state.autoRedemptionDetailForm.value.cappingValue,
		} as AutoRedemptionState.AutoRedemptionDetailForm))
	})),
	on(AutoRedemptionAction.ClearCycleDay, (state, { }) => ({
		...state,
		isLoading: false,
		autoRedemptionDetailForm: AutoRedemptionState.validateAutoRedemptionDetailForm(state)(setValue(state.autoRedemptionDetailForm, {
			code: state.autoRedemptionDetailForm.value.code,
			name: state.autoRedemptionDetailForm.value.name,
			rewardPoolId: state.autoRedemptionDetailForm.value.rewardPoolId,
			redemptionType: state.autoRedemptionDetailForm.value.redemptionType,
			point: state.autoRedemptionDetailForm.value.point,
			value: state.autoRedemptionDetailForm.value.value,
			cycleType: state.autoRedemptionDetailForm.value.cycleType,
			cycleDay: null,
			cycleMonth: state.autoRedemptionDetailForm.value.cycleMonth,
			creditTransactionCode: state.autoRedemptionDetailForm.value.creditTransactionCode,
			creditTransactionDescription:  state.autoRedemptionDetailForm.value.creditTransactionDescription,
			debitTransactionCode:  state.autoRedemptionDetailForm.value.debitTransactionCode,
			debitTransactionDescription:  state.autoRedemptionDetailForm.value.debitTransactionDescription,
			cycleTypeOption: state.autoRedemptionDetailForm.value.cycleTypeOption,
			numberOfDays: state.autoRedemptionDetailForm.value.numberOfDays,
			createdBy: state.autoRedemptionDetailForm.value.createdBy,
			createdDate: state.autoRedemptionDetailForm.value.createdDate,
			modifiedBy: state.autoRedemptionDetailForm.value.modifiedBy,
			modifiedDate: state.autoRedemptionDetailForm.value.modifiedDate,
			capping: state.autoRedemptionDetailForm.value.capping,
			cappingLevel: state.autoRedemptionDetailForm.value.cappingLevel,
			cappingValue: state.autoRedemptionDetailForm.value.cappingValue,
		} as AutoRedemptionState.AutoRedemptionDetailForm))
	})),
	on(AutoRedemptionAction.ClearBillingCycleType, (state, { }) => ({
		...state,
		isLoading: false,
		autoRedemptionDetailForm: AutoRedemptionState.validateAutoRedemptionDetailForm(state)(setValue(state.autoRedemptionDetailForm, {
			code: state.autoRedemptionDetailForm.value.code,
			name: state.autoRedemptionDetailForm.value.name,
			rewardPoolId: state.autoRedemptionDetailForm.value.rewardPoolId,
			redemptionType: state.autoRedemptionDetailForm.value.redemptionType,
			point: state.autoRedemptionDetailForm.value.point,
			value: state.autoRedemptionDetailForm.value.value,
			cycleType: state.autoRedemptionDetailForm.value.cycleType,
			cycleDay: state.autoRedemptionDetailForm.value.cycleDay,
			cycleMonth: state.autoRedemptionDetailForm.value.cycleMonth,
			creditTransactionCode: state.autoRedemptionDetailForm.value.creditTransactionCode,
			creditTransactionDescription:  state.autoRedemptionDetailForm.value.creditTransactionDescription,
			debitTransactionCode:  state.autoRedemptionDetailForm.value.debitTransactionCode,
			debitTransactionDescription:  state.autoRedemptionDetailForm.value.debitTransactionDescription,
			cycleTypeOption: state.autoRedemptionDetailForm.value.cycleTypeOption,
			numberOfDays: null,
			createdBy: state.autoRedemptionDetailForm.value.createdBy,
			createdDate: state.autoRedemptionDetailForm.value.createdDate,
			modifiedBy: state.autoRedemptionDetailForm.value.modifiedBy,
			modifiedDate: state.autoRedemptionDetailForm.value.modifiedDate,
			capping: state.autoRedemptionDetailForm.value.capping,
			cappingLevel: state.autoRedemptionDetailForm.value.cappingLevel,
			cappingValue: state.autoRedemptionDetailForm.value.cappingValue,
		} as AutoRedemptionState.AutoRedemptionDetailForm))
	})),
	on(AutoRedemptionAction.OnChangeYearlyCycleType, (state, { }) => ({
		...state,
		isLoading: false,
		autoRedemptionDetailForm: AutoRedemptionState.validateAutoRedemptionDetailForm(state)(setValue(state.autoRedemptionDetailForm, {
			code: state.autoRedemptionDetailForm.value.code,
			name: state.autoRedemptionDetailForm.value.name,
			rewardPoolId: state.autoRedemptionDetailForm.value.rewardPoolId,
			redemptionType: state.autoRedemptionDetailForm.value.redemptionType,
			point: state.autoRedemptionDetailForm.value.point,
			value: state.autoRedemptionDetailForm.value.value,
			cycleType: state.autoRedemptionDetailForm.value.cycleType,
			cycleDay: null,
			cycleMonth: null,
			creditTransactionCode: state.autoRedemptionDetailForm.value.creditTransactionCode,
			creditTransactionDescription:  state.autoRedemptionDetailForm.value.creditTransactionDescription,
			debitTransactionCode:  state.autoRedemptionDetailForm.value.debitTransactionCode,
			debitTransactionDescription:  state.autoRedemptionDetailForm.value.debitTransactionDescription,
			cycleTypeOption: state.autoRedemptionDetailForm.value.cycleTypeOption,
			numberOfDays: state.autoRedemptionDetailForm.value.numberOfDays,
			createdBy: state.autoRedemptionDetailForm.value.createdBy,
			createdDate: state.autoRedemptionDetailForm.value.createdDate,
			modifiedBy: state.autoRedemptionDetailForm.value.modifiedBy,
			modifiedDate: state.autoRedemptionDetailForm.value.modifiedDate,
			capping: state.autoRedemptionDetailForm.value.capping,
			cappingLevel: state.autoRedemptionDetailForm.value.cappingLevel,
			cappingValue: state.autoRedemptionDetailForm.value.cappingValue,
		} as AutoRedemptionState.AutoRedemptionDetailForm))
	})),
	on(AutoRedemptionAction.ClearCapping, (state, { cappingLevel, cappingValue }) => ({
		...state,
		isLoading: false,
		autoRedemptionDetailForm: AutoRedemptionState.validateAutoRedemptionDetailForm(state)(setValue(state.autoRedemptionDetailForm, {
			code: state.autoRedemptionDetailForm.value.code,
			name: state.autoRedemptionDetailForm.value.name,
			rewardPoolId: state.autoRedemptionDetailForm.value.rewardPoolId,
			redemptionType: state.autoRedemptionDetailForm.value.redemptionType,
			point: state.autoRedemptionDetailForm.value.point,
			value: state.autoRedemptionDetailForm.value.value,
			cycleType: state.autoRedemptionDetailForm.value.cycleType,
			cycleDay: null,
			cycleMonth: null,
			creditTransactionCode: state.autoRedemptionDetailForm.value.creditTransactionCode,
			creditTransactionDescription:  state.autoRedemptionDetailForm.value.creditTransactionDescription,
			debitTransactionCode:  state.autoRedemptionDetailForm.value.debitTransactionCode,
			debitTransactionDescription:  state.autoRedemptionDetailForm.value.debitTransactionDescription,
			cycleTypeOption: state.autoRedemptionDetailForm.value.cycleTypeOption,
			numberOfDays: state.autoRedemptionDetailForm.value.numberOfDays,
			createdBy: state.autoRedemptionDetailForm.value.createdBy,
			createdDate: state.autoRedemptionDetailForm.value.createdDate,
			modifiedBy: state.autoRedemptionDetailForm.value.modifiedBy,
			modifiedDate: state.autoRedemptionDetailForm.value.modifiedDate,
			capping: state.autoRedemptionDetailForm.value.capping,
			cappingLevel,
			cappingValue,
		} as AutoRedemptionState.AutoRedemptionDetailForm))
	})),
	on(AutoRedemptionAction.DuplicateDialog, state => ({
		...state,
		autoRedemptionDuplicateForm: reset(setValue(state.autoRedemptionDuplicateForm, AutoRedemptionState.initialAutoRedemptionDuplicateFormValue)),
		isDuplicateDialog: false
	})),
	on(AutoRedemptionAction.Duplicate, state => ({
		...state,
		isDuplicateLoading: true
	})),
	on(AutoRedemptionAction.DuplicateSuccess, state => ({
		...state,
		isDuplicateLoading: false,
		isDuplicateDialog: true
	})),
	on(AutoRedemptionAction.DuplicateRewardPoolAndGroupList, state => ({
		...state,
		isDuplicateLoading: true
	})),
	on(AutoRedemptionAction.DuplicateRewardPoolAndGroupListSuccess, (state, {payload}) => ({
		...state,
		isDuplicateLoading: false,
		rewardPoolAndGroupList: (payload || [])
	})),
)

export function Reducer(state: AutoRedemptionState.State = AutoRedemptionState.initialState, action: Action) {
	const autoRedemptionDetailForm = AutoRedemptionState.validateAutoRedemptionDetailForm(state)(formGroupReducer(state.autoRedemptionDetailForm, action))
	if (autoRedemptionDetailForm !== state.autoRedemptionDetailForm) {
		state = { ...state, autoRedemptionDetailForm }
	}

	const autoRedemptionDuplicateForm = AutoRedemptionState.validateAutoRedemptionDuplicateForm(formGroupReducer(state.autoRedemptionDuplicateForm, action))
	if (autoRedemptionDuplicateForm !== state.autoRedemptionDuplicateForm) {
		state = {...state, autoRedemptionDuplicateForm}
	}
	return reducer(state, action)
}

function validateForm(form: any) {
	form = markAsTouched(form)

	return AutoRedemptionState.validateAutoRedemptionDetailForm(form)
}

function actionHandler(state: AutoRedemptionState.State, action: SetValueAction<unknown> | NgrxForms.MarkAsTouchedAction) {

	const formControls = state.autoRedemptionDetailForm

	let form = formGroupReducer(formControls, action)

	if (action.type === 'ngrx/forms/SET_VALUE') {
		form = formGroupReducer(formControls, new SetValueAction(action.controlId, action.value))
	}

	form = validateForm(state)(form)

	state = {
		...state,
		autoRedemptionDetailForm: form
	}

	return state
}
