import { createFormGroupState, FormGroupState, updateGroup, validate } from 'ngrx-forms'
import { maxLength, required } from 'ngrx-forms/validation'
import { GlRedemptionListResponse } from 'src/app/models/param-settings/gl-redemption/gl-redemption-list'
import { FieldValidator } from 'src/app/models/util/field.validator'
import { KeyValue } from '@angular/common'

export interface State {
	isLoading: boolean
	id?: number
	glRedemptionDetailForm: FormGroupState<GlRedemptionDetailForm>
	glRedemptionDuplicateForm: FormGroupState<GlRedemptionDuplicateForm>
	glRedemptionListResponse: GlRedemptionListResponse
	allowDelete: boolean
	glRedemptionDetailFormView: GlRedemptionDetailForm
	entityTypeList: KeyValue<string, string>[]
	isDuplicateDialog: boolean
	isDuplicateLoading: boolean
}

export interface GlRedemptionDuplicateForm {
	code: string
	name: string
}

export const initialGlRedemptionDuplicateFormValue: GlRedemptionDuplicateForm  = {
	code: '',
	name: ''
}

export const initialGlRedemptionDuplicateForm = createFormGroupState('glRedemptionDuplicateForm', initialGlRedemptionDuplicateFormValue)

export const validateGlRedemptionDuplicateForm = updateGroup<GlRedemptionDuplicateForm>({
	code: validate([required, maxLength(10), FieldValidator.alphaNumericWithoutSpaces()]),
	name: validate([required, maxLength(40), FieldValidator.checkValidCharacters()])
})

export interface GlRedemptionDetailForm {
	code: string
	name: string
	creditGlCode: string
	creditBranch: string
	creditBranchCostCenter: string
	creditEntityKey: string
	creditEntityValue: string
	createdBy: string
	createdDate: string
	modifiedBy: string
	modifiedDate: string
}

export const initialGlRedemptionDetailFormValue: GlRedemptionDetailForm = {
	code: '',
	name: '',
	creditGlCode: '',
	creditBranch: '',
	creditBranchCostCenter: '',
	creditEntityKey: '',
	creditEntityValue: '',
	createdBy: '',
	createdDate: '',
	modifiedBy: '',
	modifiedDate: ''
}

export const initialGlRedemptionDetailForm = createFormGroupState('glRedemptionDetailForm', initialGlRedemptionDetailFormValue)

export const validateGlRedemptionDetailForm = updateGroup<GlRedemptionDetailForm>({
	code: validate([required, maxLength(10), FieldValidator.alphaNumericWithoutSpaces()]),
	name: validate([required, maxLength(40), FieldValidator.checkValidCharacters()]),
	creditGlCode: validate([required, FieldValidator.checkNumericMatchLength(8)]),
	creditBranch: validate([required, FieldValidator.alphaNumericWithoutSpaces(), FieldValidator.checkAlphaNumericMatchLength(3)]),
	creditBranchCostCenter: validate([required, FieldValidator.alphaNumericWithoutSpaces(), FieldValidator.checkAlphaNumericMatchLength(8)]),
	creditEntityKey: validate([required]),
})

export const initialState: State = {
	isLoading: false,
	glRedemptionDuplicateForm: initialGlRedemptionDuplicateForm,
	glRedemptionDetailForm: initialGlRedemptionDetailForm,
	glRedemptionListResponse: {
		glRedemptions: []
	},
	allowDelete: true,
	glRedemptionDetailFormView: initialGlRedemptionDetailFormValue,
	entityTypeList: [],
	isDuplicateDialog: false,
	isDuplicateLoading: false
}
