import { environment } from 'src/environments/environment'

export const ConfigLoader = {
	environment: {} as { [key: string]: string },
	loadConfig: async (env) => {
		try {
			console.log('Loading environment from config.')
			ConfigLoader.environment = await (await fetch('/config.json')).json()
			Object.keys(ConfigLoader.environment).forEach(el => {
				environment[el] = ConfigLoader.environment[el]
				env[el] = ConfigLoader.environment[el]
			})
			console.log('Environment loaded successfully.')
			return env
		} catch {
			console.log('Config file not found.')
			return env
		}
	}
}
