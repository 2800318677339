import { Action, createReducer, on } from '@ngrx/store'
import * as WorklistTaskAction from './checker-maker-function.actions'
import * as WorklistTaskState from './checker-maker-function.state'

export const reducer = createReducer(
	WorklistTaskState.initialState,
	on(WorklistTaskAction.InitialState, () => WorklistTaskState.initialState),
	on(WorklistTaskAction.SetWorklistTask, (state, { payload }) => ({
		...state,
		task: payload
	})),
	on(WorklistTaskAction.SetModuleFrom, (state, { payload }) => ({
		...state,
		from: payload
	})),
	on(WorklistTaskAction.ShowWorklistFunction, (state) => ({
		...state,
		show: true
	})),
	on(WorklistTaskAction.ResetWorklistTask, (state) => ({
		...state,
		isLoading: false,
		show: false,
		from: '',
		code: '',
		task: {
			id: 0,
			name: '',
			dataId: 0,
			dataCode: '',
			dataName: '',
			type: '',
			status: '',
			createdBy: '',
			modifiedBy: '',
			modifiedDate: '',
			rejectReason: '',
		}
	})),
	on(WorklistTaskAction.GetCodeFromDataIdCampaignSuccess, (state, { payload }) => ({
		...state,
		code: payload.code
	})),
	on(WorklistTaskAction.GetCodeFromDataIdRepublishCampaignSuccess, (state, { payload }) => ({
		...state,
		code: payload.code
	})),
	on(WorklistTaskAction.GetCodeFromDataIdCampaignProductBundlingSuccess, (state, { payload }) => ({
		...state,
		code: payload.code
	})),
	on(WorklistTaskAction.GetCodeFromDataIdRepublishCampaignProductBundlingSuccess, (state, { payload }) => ({
		...state,
		code: payload.code
	})),
	on(WorklistTaskAction.WorklistTaskPerformSuccess, (state) => ({
		...state,
		isLoading: false
	})),
	on(WorklistTaskAction.ApproveWorklistTask, (state) => ({
		...state,
		isLoading: true
	})),
	on(WorklistTaskAction.RejectWorklistTask, (state) => ({
		...state,
		isLoading: true
	})),
	on(WorklistTaskAction.WithdrawWorklistTask, (state) => ({
		...state,
		isLoading: true
	})),
	on(WorklistTaskAction.RestError, (state) => ({
		...state,
		isLoading: false
	})),
)

export function Reducer(state: WorklistTaskState.State = WorklistTaskState.initialState, action: Action) {
	return reducer(state, action)
}
