import { createAction, props } from '@ngrx/store'
import { GroupListResponse } from 'src/app/models/access-management/group/group-list'
import { UserListRequest, UserListResponse } from 'src/app/models/access-management/user/user-list'
import { UserViewResponse } from 'src/app/models/access-management/user/user-view'

export const InitialState = createAction('[User] InitialState')
export const Reset = createAction('[User] Reset')
export const SetUserId = createAction('[User] SetUserId', props<{ userId: number }>())
export const IsUserPage = createAction('[User] IsUserPage', props<{ isUserPage: boolean }>())

export const GoList = createAction('[User] GoList')
export const GoView = createAction('[User] GoView')
export const GoUpdate = createAction('[User] GoUpdate')

export const GoBackDialog = createAction('[User] GoBackDialog', props<{ action: string }>())
export const UpdateDialog = createAction('[User] UpdateDialog')
export const AssignGroupDialog = createAction('[User] AssignGroupDialog')
export const closeDialog = createAction('[User] closeDialog')

export const RestError = createAction('[User] RestError', props<{ message: string, manualThrow: boolean }>())

export const List = createAction('[User] List')

export const ListSuccess = createAction('[User] ListSuccess', props<{ payload: UserListResponse }>())

export const View = createAction('[User] View')
export const ViewSuccess = createAction('[User] ViewSuccess', props<{ payload: UserViewResponse }>())

export const onPagination = createAction('[User] onPagination', props<{ pageNum: number, pageSize: number }>())

export const AssignGroupList = createAction('[User] AssignGroupList')
export const AssignGroupListSuccess = createAction('[User] AssignGroupListSuccess', props<{ payload: GroupListResponse }>())

export const RemoveGroupFromUser = createAction('[User] RemoveGroupFromUser', props<{ userId: number, groupId: number }>())
export const RemoveGroupFromUserSuccess = createAction('[User] RemoveGroupFromUserSuccess', props<{ message: string }>())
export const AssignMultiGroupToUser = createAction('[User] AssignMultiGroupToUser', props<{ groupIdList: any }>())
export const AssignGroupToUser = createAction('[User] AssignGroupToUser', props<{ groupId: number }>())
export const AssignGroupToUserSuccess = createAction('[User] AssignGroupToUserSuccess', props<{ message: string }>())
export const SuccessGroupToUser = createAction('[User] SuccessGroupToUser', props<{ groupId: number }>())
export const FailedGroupToUser = createAction('[User] FailedGroupToUser', props<{ groupId: number, message: string }>())
export const OpenConfirmationDialog = createAction('[User] OpenConfirmationDialog')
