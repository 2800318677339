import { Action, createReducer, on } from '@ngrx/store'
import { formGroupReducer, reset, setValue } from 'ngrx-forms'
import * as GroupAction from './group.actions'
import * as GroupState from './group.state'

export const reducer = createReducer(
	GroupState.initialState,
	on(GroupAction.InitialState, () => GroupState.initialState),
	on(GroupAction.Reset, state => ({
		...state,
		groupListForm: reset(setValue(state.groupListForm, GroupState.initialGroupListFormValue))
	})),
	on(GroupAction.SetId, (state, { groupId }) => ({
		...state,
		groupId
	})),
	on(GroupAction.GoCreate, state => ({
		...state,
		groupDetailForm: reset(setValue(state.groupDetailForm, GroupState.initialGroupDetailFormValue))
	})),
	on(GroupAction.RestError, state => ({
		...state,
		isLoading: false,
		isDuplicateLoading: false,
		isDuplicateDialog: false
	})),
	on(GroupAction.List, state => ({
		...state,
		isLoading: true,
		groupListReponse: {
			groups: []
		}
	})),
	on(GroupAction.ListSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		groupListReponse: payload
	})),
	on(GroupAction.View, state => ({
		...state,
		isLoading: true,
		policyListResult: [],
		groupDetailForm: setValue(state.groupDetailForm, GroupState.initialGroupDetailFormValue)
	})),
	on(GroupAction.ViewSuccess, (state, { payload }) => {
		const groupDetailFormView = {
			name: payload.name,
			description: payload.description
		}
		return ({
			...state,
			isLoading: false,
			groupViewResponse: {
				groupId: payload.groupId,
				name: payload.name,
				numberOfUsers: payload.numberOfUsers,
				description: payload.description,
				policies: payload.policies,
				assignedUsers: payload.assignedUsers,
				createdBy: payload.createdBy,
				createdDate: payload.createdDate,
				modifiedBy: payload.modifiedBy,
				modifiedDate: payload.modifiedDate
			},
			groupDetailForm: GroupState.validateGroupDetailForm(setValue(state.groupDetailForm, groupDetailFormView as GroupState.GroupDetailForm)),
			groupDetailFormView
		})
	}),
	on(GroupAction.Create, state => ({
		...state,
		isLoading: true
	})),
	on(GroupAction.CreateSuccess, (state, { payload }) => ({
		...GroupState.initialState
	})),
	on(GroupAction.Edit, state => ({
		...state,
		isLoading: true
	})),
	on(GroupAction.EditSuccess, (state, { payload }) => ({
		...GroupState.initialState
	})),
	on(GroupAction.Delete, state => ({
		...state,
		isLoading: true
	})),
	on(GroupAction.DeleteSuccess, () => GroupState.initialState),
	on(GroupAction.AssignPolicyList, state => ({
		...state,
		isLoading: true,
	})),
	on(GroupAction.AssignPolicyListSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		assignPolicyDataList: payload.policies.map(x => ({
			keyValue: { key: x.policyId.toString(), value: x.name },
			policyId: x.policyId,
			name: x.name,
			description: x.description
		}))
	})),
	on(GroupAction.DuplicateDialog, state => ({
		...state,
		groupDetailForm: reset(setValue(state.groupDetailForm, GroupState.initialGroupDetailFormValue)),
		isDuplicateDialog: false
	})),
	on(GroupAction.Duplicate, state => ({
		...state,
		isDuplicateLoading: true
	})),
	on(GroupAction.DuplicateSuccess, state => ({
		...state,
		isDuplicateLoading: false,
		isDuplicateDialog: true
	})),
	on(GroupAction.OpenConfirmationDialog, state => ({
		...state,
		isTableLoading: true
	})),
	on(GroupAction.SuccessPolicyToGroup, (state, { policyId }) => {
		const policyResult = {
			success: true,
			payload: state.assignPolicyDataList.find(item => policyId === item.policyId).name
		}
		return ({
			...state,
			isTableLoading: false,
			policyListResult: state.policyListResult.concat(policyResult)
		})
	}),
	on(GroupAction.FailedPolicyToGroup, (state, { policyId, message }) => {
		const policyResult = {
				success: false,
				payload: state.assignPolicyDataList.find(item => policyId === item.policyId).name,
				error: message
		}
		return ({
			...state,
			isTableLoading: false,
			policyListResult: state.policyListResult.concat(policyResult)
		})
	})
)

export function Reducer(state: GroupState.State = GroupState.initialState, action: Action) {
	const groupListForm = GroupState.validateGroupListForm(formGroupReducer(state.groupListForm, action))
	if (groupListForm !== state.groupListForm) {
		state = { ...state, groupListForm }
	}

	const groupDetailForm = GroupState.validateGroupDetailForm(formGroupReducer(state.groupDetailForm, action))
	if (groupDetailForm !== state.groupDetailForm) {
		state = { ...state, groupDetailForm }
	}
	return reducer(state, action)
}
