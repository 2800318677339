import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material'
import { Router } from '@angular/router'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Action, Store } from '@ngrx/store'
import { Observable, of } from 'rxjs'
import { catchError, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators'
import { MessageDialogComponent } from 'src/app/components/common/dialog/message-dialog/message-dialog.component'
import { getDialogConfig } from 'src/app/models/common/dialog'
import { Response } from 'src/app/models/common/http'
import { MccGroupListResponse } from 'src/app/models/param-settings/mcc-group/mcc-group-list'
import { MccGroupViewResponse } from 'src/app/models/param-settings/mcc-group/mcc-group-view'
import { ParameterSettingsService } from 'src/app/services/param-settings/parameter-settings.service'
import * as AppStore from 'src/app/store/'
import * as CommonAction from '../../common/common/common.actions'
import * as MccGroupAction from './mcc-group.actions'
import { KeyValue } from '@angular/common'
import { MccGroupDuplicateComponent } from '../../../components/param-settings/mcc-group/mcc-group-duplicate/mcc-group-duplicate.component'

@Injectable()
export class Effects {

	constructor(
		private action$: Actions,
		private router: Router,
		private dialog: MatDialog,
		private parameterSettingsService: ParameterSettingsService,
		private store: Store<AppStore.State>
	) { }

	InitialState$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(MccGroupAction.InitialState),
			tap(() => {
				this.router.navigate(['parameter-settings/mcc-group'])
			})
		), { dispatch: false }
	)

	GoList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(MccGroupAction.GoList),
			tap(() => {
				this.router.navigate(['parameter-settings/mcc-group'])
			})
		), { dispatch: false }
	)

	GoView$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(MccGroupAction.GoView),
			tap(() => {
				this.router.navigate(['parameter-settings/mcc-group/view'])
			})
		), { dispatch: false }
	)

	GoCreate$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(MccGroupAction.GoCreate),
			tap(() => {
				this.router.navigate(['parameter-settings/mcc-group/create'])
			})
		), { dispatch: false }
	)

	GoUpdate$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(MccGroupAction.GoUpdate),
			tap(() => {
				this.router.navigate(['parameter-settings/mcc-group/update'])
			})
		), { dispatch: false }
	)

	GoBackDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(MccGroupAction.GoBackDialog),
			mergeMap(({ action }) => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.BACK', payload: action }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					if (result.payload === 'LIST') {
						return MccGroupAction.GoList()
					}
					if (result.payload === 'VIEW') {
						return MccGroupAction.GoView()
					}
				} else {
					return MccGroupAction.CloseDialog()
				}
			})
		)
	)

	RestError$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(MccGroupAction.RestError),
			map(({ message, manualThrow }) => {
				return CommonAction.RestError({ message, manualThrow })
			})
		)
	)

	List$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(MccGroupAction.List),
			withLatestFrom(this.store.select(state => state.mccGroup)),
			mergeMap(([x, mccGroup]) => {
				const form = mccGroup.mccGroupListForm.controls

				return this.parameterSettingsService.getMccGroupList().pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: MccGroupListResponse = response.payload

					if (success) {
						return MccGroupAction.ListSuccess({ payload })
					} else { return MccGroupAction.RestError(response as any) }
				}), catchError(this.catcher)
				)
			})
		)
	)

	ResourceListCreate$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(MccGroupAction.ResourceListCreate),
			mergeMap(() => {
				return this.parameterSettingsService.getResourceList({ module: 'mccGroup', action: 'create' }).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: KeyValue<string, string>[] = response.payload
					if (success) {
						return MccGroupAction.ResourceListCreateSuccess({ payload })
					} else { return MccGroupAction.RestError(response as any) }
				}), catchError(this.catcher))
			})
		)
	)

	ResourceListUpdate$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(MccGroupAction.ResourceListUpdate),
			mergeMap(() => {
				return this.parameterSettingsService.getResourceList({ module: 'mccGroup', action: 'update' }).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: KeyValue<string, string>[] = response.payload
					if (success) {
						return MccGroupAction.ResourceListUpdateSuccess({ payload })
					} else { return MccGroupAction.RestError(response as any) }
				}), catchError(this.catcher))
			})
		)
	)

	View$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(MccGroupAction.View),
			withLatestFrom(this.store.select(state => state.mccGroup)),
			mergeMap(([, mccGroup]) => this.parameterSettingsService.viewMccGroup({ code: mccGroup.code })),
			map((response: Response) => {
				const success: boolean = response.success
				const payload: MccGroupViewResponse = response.payload

				if (success) {
					return MccGroupAction.ViewSuccess({ payload })
				} else { return MccGroupAction.RestError(response as any) }

			}), catchError(this.catcher)
		)
	)

	CreateDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(MccGroupAction.CreateDialog),
			mergeMap((x) => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({content: x.content, payload: x.payload}))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return MccGroupAction.Create({payload: result.payload})
				} else {
					return MccGroupAction.CloseDialog()
				}
			})
		)
	)

	Create$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(MccGroupAction.Create),
			withLatestFrom(this.store.select(state => state.mccGroup)),
			mergeMap(([x, mccGroup]) => {
				const form = mccGroup.mccGroupDetailForm.controls
				return this.parameterSettingsService.createMccGroup({
					code: x.payload.prefix + form.code.value,
					name: form.name.value,
					description: form.description.value,
					range: x.payload.list.map(o => o.replace('\r', '')).toString(),
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: string = response.payload

					if (success) {
						return MccGroupAction.CreateSuccess({ payload })
					} else { return  MccGroupAction.RestError(response as any) }
				}), catchError(this.catcher)
				)
			})
		)
	)

	CreateSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(MccGroupAction.CreateSuccess),
			mergeMap(({payload}) => {
				return [MccGroupAction.GoList(), CommonAction.RestError({ message: payload })]
			})
		)
	)

	UpdateDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(MccGroupAction.UpdateDialog),
			mergeMap((x) => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({content: 'DIALOG.UPDATE', payload: x.payload}))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return MccGroupAction.Update({payload: result.payload})
				} else {
					return MccGroupAction.CloseDialog()
				}
			})
		)
	)

	Update$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(MccGroupAction.Update),
			withLatestFrom(this.store.select(state => state.mccGroup)),
			mergeMap(([x, mccGroup]) => {
				const form = mccGroup.mccGroupDetailForm.controls
				return this.parameterSettingsService.updateMccGroup({
					code: form.code.value,
					name: form.name.value,
					description: form.description.value,
					range: x.payload.map(o => o.replace('\r', '')).toString()
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: string = response.payload

					if (success) {
						return MccGroupAction.UpdateSuccess({ payload })
					} else { return  MccGroupAction.RestError(response as any) }

				}), catchError(this.catcher)
				)
			})
		)
	)

	UpdateSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(MccGroupAction.UpdateSuccess),
			mergeMap(({payload}) => {
				return [MccGroupAction.GoView(), CommonAction.RestError({ message: payload })]
			})
		)
	)

	DeleteDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(MccGroupAction.DeleteDialog),
			mergeMap(() => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.DELETE_MCC_GROUP' }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return MccGroupAction.Delete()
				} else {
					return MccGroupAction.CloseDialog()
				}
			})
		)
	)

	Delete$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(MccGroupAction.Delete),
			withLatestFrom(this.store.select(state => state.mccGroup)),
			mergeMap(([, mccGroup]) => this.parameterSettingsService.deleteMccGroup({
				code: mccGroup.code
			}).pipe(map((response: Response) => {
				const success: boolean = response.success
				const payload: string = response.payload

				if (success) {
					return MccGroupAction.DeleteSuccess({ payload })
				} else { return  MccGroupAction.RestError(response as any) }

			}), catchError(this.catcher))
			)
		)
	)

	DeleteSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(MccGroupAction.DeleteSuccess),
			mergeMap(({payload}) => {
				return [MccGroupAction.GoList(), CommonAction.RestError({ message: payload })]
			})
		)
	)

	DuplicateDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(MccGroupAction.DuplicateDialog),
			mergeMap(() => {
				const dialogRef = this.dialog.open(MccGroupDuplicateComponent, getDialogConfig({}))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return MccGroupAction.Duplicate()
				} else {
					return MccGroupAction.CloseDialog()
				}
			})
		)
	)

	Duplicate$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(MccGroupAction.Duplicate),
			withLatestFrom(this.store.select(state => state.mccGroup)),
			mergeMap(([, mccGroup]) => {
				const mccGroupDuplicateForm = mccGroup.mccGroupDuplicateForm.value
				return this.parameterSettingsService.duplicateMccGroup(mccGroup.code, {
					code: 'MCG' + mccGroupDuplicateForm.code,
					name: mccGroupDuplicateForm.name
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload = response.payload

					if (success) {
						return MccGroupAction.DuplicateSuccess({payload})
					} else {
						return MccGroupAction.RestError(response as any)
					}
				}), catchError(this.catcher))
			})
		)
	)

	DuplicateSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(MccGroupAction.DuplicateSuccess),
			mergeMap(({payload}) => {
				return [
					MccGroupAction.List(),
					CommonAction.RestError({message: payload})
				]
			})
		)
	)

	catcher = (error) => {
		return (of(MccGroupAction.RestError({ message: error.message, manualThrow: error.manualThrow })))
	}
}
