import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material'
import { Router } from '@angular/router'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Action, Store } from '@ngrx/store'
import { Observable, of } from 'rxjs'
import { catchError, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators'
import { MessageDialogComponent } from 'src/app/components/common/dialog/message-dialog/message-dialog.component'
import { getDialogConfig } from 'src/app/models/common/dialog'
import { Response } from 'src/app/models/common/http'
import { GlProvisionViewResponse } from 'src/app/models/param-settings/gl-provision/gl-provision-view'
import { ParameterSettingsService } from 'src/app/services/param-settings/parameter-settings.service'
import * as AppStore from 'src/app/store/'
import * as CommonAction from '../../common/common/common.actions'
import * as GlProvisionAction from './gl-provision.actions'
import { GlProvisionListResponse } from 'src/app/models/param-settings/gl-provision/gl-provision-list'
import { GlProvisionData } from 'src/app/models/param-settings/gl-provision/gl-provision-data'
import { GlProvisionDuplicateComponent } from 'src/app/components/param-settings/gl-management/gl-provision/gl-provision-duplicate/gl-provision-duplicate.component'

@Injectable()
export class Effects {

	constructor(
		private action$: Actions,
		private router: Router,
		private dialog: MatDialog,
		private parameterSettingsService: ParameterSettingsService,
		private store: Store<AppStore.State>
	) { }

	InitialState$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(GlProvisionAction.InitialState),
			tap(() => {
				this.router.navigate(['parameter-settings/gl-management'])
			})
		), { dispatch: false }
	)

	GoList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(GlProvisionAction.GoList),
			tap(() => {
				this.router.navigate(['parameter-settings/gl-management'])
			})
		), { dispatch: false }
	)

	GoView$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(GlProvisionAction.GoView),
			tap(() => {
				this.router.navigate(['parameter-settings/gl-provision/view'])
			})
		), { dispatch: false }
	)

	GoCreate$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(GlProvisionAction.GoCreate),
			tap(() => {
				this.router.navigate(['parameter-settings/gl-provision/create'])
			})
		), { dispatch: false }
	)

	GoUpdate$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(GlProvisionAction.GoUpdate),
			tap(() => {
				this.router.navigate(['parameter-settings/gl-provision/update'])
			})
		), { dispatch: false }
	)

	GoBackDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(GlProvisionAction.GoBackDialog),
			mergeMap(({ action }) => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.BACK', payload: action }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					if (result.payload === 'LIST') {
						return GlProvisionAction.GoList()
					}
					if (result.payload === 'VIEW') {
						return GlProvisionAction.GoView()
					}
				} else {
					return GlProvisionAction.CloseDialog()
				}
			})
		)
	)

	RestError$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(GlProvisionAction.RestError),
			map(({ message, manualThrow }) => {
				return CommonAction.RestError({ message, manualThrow })
			})
		)
	)

	List$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(GlProvisionAction.List),
			withLatestFrom(this.store.select(state => state.glProvision)),
			mergeMap(() => {
				return this.parameterSettingsService.getGlProvisionList().pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: GlProvisionListResponse = response.payload
					if (success) {
						return GlProvisionAction.ListSuccess({ payload })
					} else { return GlProvisionAction.RestError(response as any) }

				}), catchError(this.catcher))
			})
		)
	)

	View$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(GlProvisionAction.View),
			withLatestFrom(this.store.select(state => state.glProvision)),
			mergeMap(([, glProvision]) => this.parameterSettingsService.viewGlProvision({
				id: glProvision.id
			}).pipe(map((response: Response) => {
				const success: boolean = response.success
				const payload: GlProvisionViewResponse = response.payload

				if (success) {
					return GlProvisionAction.ViewSuccess({ payload })
				} else { return GlProvisionAction.RestError(response as any) }

			}), catchError(this.catcher)))
		)
	)

	CreateDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(GlProvisionAction.CreateDialog),
			mergeMap(() => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.CREATE_GL_PROVISION' }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return GlProvisionAction.Create()
				} else {
					return GlProvisionAction.CloseDialog()
				}
			})
		)
	)

	Create$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(GlProvisionAction.Create),
			withLatestFrom(this.store.select(state => state.glProvision)),
			mergeMap(([, glRedemption]) => {
				const form = glRedemption.glProvisionDetailForm.controls
				return this.parameterSettingsService.createGlProvision({
					code: form.code.value,
					name: form.name.value,
					debitGlCode: form.debitGlCode.value,
					debitBranch: form.debitBranch.value,
					debitBranchCostCenter: form.debitBranchCostCenter.value,
					debitEntityKey: form.debitEntityKey.value,
					creditGlCode: form.creditGlCode.value,
					creditBranch: form.creditBranch.value,
					creditBranchCostCenter: form.creditBranchCostCenter.value,
					creditEntityKey: form.creditEntityKey.value,
					expCreditGlCode: form.expCreditGlCode.value,
					expCreditBranch: form.expCreditBranch.value,
					expCreditBranchCostCenter: form.expCreditBranchCostCenter.value,
					expEntityKey: form.expEntityKey.value,
					subDebitGlCode: form.subDebitGlCode.value,
					subDebitBranch: form.subDebitBranch.value,
					subDebitBranchCostCenter: form.subDebitBranchCostCenter.value,
					subEntityKey: form.subEntityKey.value,
					point: Number(form.point.value),
					cashValue: Number(form.cashValue.value)
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: string = response.payload
					if (success) {
						return GlProvisionAction.CreateSuccess({ payload })
					} else { return GlProvisionAction.RestError(response as any) }

				}), catchError(this.catcher))
			})
		)
	)

	CreateSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(GlProvisionAction.CreateSuccess),
			map(() => {
				return GlProvisionAction.GoView()
			})
		)
	)

	UpdateDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(GlProvisionAction.UpdateDialog),
			mergeMap(() => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.UPDATE' }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return GlProvisionAction.Update()
				} else {
					return GlProvisionAction.CloseDialog()
				}
			})
		)
	)

	Update$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(GlProvisionAction.Update),
			withLatestFrom(this.store.select(state => state.glProvision)),
			mergeMap(([, glRedemption]) => {
				const form = glRedemption.glProvisionDetailForm.controls
				return this.parameterSettingsService.updateGlProvision({
					id: glRedemption.id,
					name: form.name.value,
					debitGlCode: form.debitGlCode.value,
					debitBranch: form.debitBranch.value,
					debitBranchCostCenter: form.debitBranchCostCenter.value,
					debitEntityKey: form.debitEntityKey.value,
					creditGlCode: form.creditGlCode.value,
					creditBranch: form.creditBranch.value,
					creditBranchCostCenter: form.creditBranchCostCenter.value,
					creditEntityKey: form.creditEntityKey.value,
					expCreditGlCode: form.expCreditGlCode.value,
					expCreditBranch: form.expCreditBranch.value,
					expCreditBranchCostCenter: form.expCreditBranchCostCenter.value,
					expEntityKey: form.expEntityKey.value,
					subDebitGlCode: form.subDebitGlCode.value,
					subDebitBranch: form.subDebitBranch.value,
					subDebitBranchCostCenter: form.subDebitBranchCostCenter.value,
					subEntityKey: form.subEntityKey.value,
					point: Number(form.point.value),
					cashValue: Number(form.cashValue.value)
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: string = response.payload
					if (success) {
						return GlProvisionAction.UpdateSuccess({ payload })
					} else { return GlProvisionAction.RestError(response as any) }

				}), catchError(this.catcher))
			})
		)
	)

	UpdateSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(GlProvisionAction.UpdateSuccess),
			map(() => {
				return GlProvisionAction.GoView()
			})
		)
	)

	DeleteDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(GlProvisionAction.DeleteDialog),
			mergeMap(() => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.DELETE_GL_PROVISION' }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return GlProvisionAction.Delete()
				} else {
					return GlProvisionAction.CloseDialog()
				}
			})
		)
	)

	Delete$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(GlProvisionAction.Delete),
			withLatestFrom(this.store.select(state => state.glProvision)),
			mergeMap(([, glProvision]) => this.parameterSettingsService.deleteGlProvision({
				id: glProvision.id
			}).pipe(map((response: Response) => {
				const success: boolean = response.success
				if (success) {
					return GlProvisionAction.DeleteSuccess()
				} else { return GlProvisionAction.RestError(response as any) }

			}), catchError(this.catcher)))
		)
	)

	DeleteSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(GlProvisionAction.DeleteSuccess),
			map(() => {
				return GlProvisionAction.GoList()
			})
		)
	)

	GetGlProvisionData$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(GlProvisionAction.GetGlProvisionData),
			mergeMap(() => {
				return this.parameterSettingsService.getGlProvisionData().pipe(
					map((response: Response) => {
						const success: boolean = response.success
						const payload: GlProvisionData = response.payload

						if (success) {
							return GlProvisionAction.GetGlProvisionDataSuccess({ payload })
						} else {
							return GlProvisionAction.RestError(response as any)
						}

					}), catchError(this.catcher)
				)
			})
		)
	)

	DuplicateDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(GlProvisionAction.DuplicateDialog),
			mergeMap(() => {
				const dialogRef = this.dialog.open(GlProvisionDuplicateComponent, getDialogConfig({}))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return GlProvisionAction.Duplicate()
				} else {
					return GlProvisionAction.CloseDialog()
				}
			})
		)
	)

	Duplicate$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(GlProvisionAction.Duplicate),
			withLatestFrom(this.store.select(state => state.glProvision)),
			mergeMap(([, glProvision]) => {
				const glProvisionDuplicateForm = glProvision.glProvisionDuplicateForm.value

				return this.parameterSettingsService.duplicateGlProvision(glProvision.id, {
					code: glProvisionDuplicateForm.code,
					name: glProvisionDuplicateForm.name
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload = response.payload
					if (success) {
						return GlProvisionAction.DuplicateSuccess({ payload })
					} else {
						return GlProvisionAction.RestError(response as any)
					}
				}), catchError(this.catcher))
			})
		)
	)

	DuplicateSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(GlProvisionAction.DuplicateSuccess),
			mergeMap(({ payload }) => {
				return [
					GlProvisionAction.List(),
					CommonAction.RestError({ message: payload })
				]
			})
		)
	)

	catcher = (error) => {
		return (of(GlProvisionAction.RestError({ message: error.message, manualThrow: error.manualThrow })))
	}
}
