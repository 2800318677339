import { FormControl, FormGroupDirective, NgForm } from '@angular/forms'
import { ErrorStateMatcher } from '@angular/material'

export class CustomErrorStateMatcher implements ErrorStateMatcher {
	isErrorState(control: FormControl, form: FormGroupDirective | NgForm | null): boolean {
		const isSubmitted = form && form.submitted
		// return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted))
		return !!(control && control.invalid && (control.dirty || control.touched))
	}
}
