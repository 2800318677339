import { Component, Inject, OnDestroy, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material'
import { Store } from '@ngrx/store'
import { Subscription } from 'rxjs'
import * as AppStore from 'src/app/store'
import * as CustomerManagementAction from 'src/app/store/customer-management/customer-management.actions'
import * as CustomerManagementSelector from 'src/app/store/customer-management/customer-management.selectors'

@Component({
	selector: 'app-customer-management-partner-redemption-confirmation',
	templateUrl: './customer-management-partner-redemption-confirmation.component.html',
	styleUrls: ['./customer-management-partner-redemption-confirmation.component.scss']
})
export class CustomerManagementPartnerRedemptionConfirmationComponent implements OnInit {

	isLoading = false
	points = undefined
	value = undefined
	subs: Subscription
	constructor(
		private store: Store<AppStore.State>,
		private dialogRef: MatDialogRef<CustomerManagementPartnerRedemptionConfirmationComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any) {
			this.subs = this.store.select(CustomerManagementSelector.getCustomerManagement).subscribe(x => {
				this.isLoading = x.isLoading
				this.points = x.partnerRedemptionForm.value.points
				this.value = x.partnerRedemptionForm.value.values
			})
		}

	ngOnInit() {
	}

	cancel() {
		this.dialogRef.close()
	}

	create() {
		this.dialogRef.close()
		this.store.dispatch(CustomerManagementAction.CreatePartnerRedemption())
	}

}
