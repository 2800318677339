import { STEPPER_GLOBAL_OPTIONS, StepperSelectionEvent } from '@angular/cdk/stepper'
import { KeyValue } from '@angular/common'
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Inject, OnDestroy, OnInit } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material'
import { Store } from '@ngrx/store'
import { FormGroupState, MarkAsTouchedAction } from 'ngrx-forms'
import { Observable, ReplaySubject, Subscription } from 'rxjs'
import * as AppStore from 'src/app/store'
import * as CampaignAction from 'src/app/store/campaign-management/campaign/campaign.actions'
import * as CampaignSelectors from 'src/app/store/campaign-management/campaign/campaign.selectors'
import { CampaignTier, InformationDetailForm, Ruleset, RulesetDetailForm, RulesetRewardForm } from 'src/app/store/campaign-management/campaign/campaign.state'
import { take } from 'rxjs/operators'
import { FilterPredicate } from 'src/app/models/util/filter-predicate'
import { ActivatedRoute } from '@angular/router'

@Component({
	selector: 'app-ruleset',
	templateUrl: './ruleset.component.html',
	styleUrls: ['./ruleset.component.scss'],
	providers: [{
		provide: STEPPER_GLOBAL_OPTIONS, useValue: { showError: true }
	}],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class RulesetComponent implements OnInit, AfterViewInit, OnDestroy {

	isLoading = false
	isValid = true
	isFirstStepValid = true
	kind: string
	action: string

	informationDetailFormState$: Observable<FormGroupState<InformationDetailForm>>
	formState$: Observable<FormGroupState<RulesetDetailForm>>
	rewardFormState$: Observable<FormGroupState<RulesetRewardForm>>
	rulesets$: Observable<Ruleset[]>
	campaignTiers$: Observable<CampaignTier[]>
	subs: Subscription

	selectedIndex = 0
	ruleListLength = 0
	campaignTiersLength = 0

	rewardPoolList: KeyValue<string, string>[] = []
	amountList: KeyValue<string, string>[] = []
	rewardTypeList: KeyValue<string, string>[] = []
	rewardCreditMethodList: KeyValue<string, string>[] = []
	channelList: KeyValue<string, string>[] = []
	transactionMethodList: KeyValue<string, string>[] = []
	transactionsMethodList: KeyValue<string, string>[] = []
	accumulationCycleTypeList: KeyValue<string, string>[] = []
	dayList: KeyValue<string, string>[] = []
	dayOfMonthList: KeyValue<string, string>[] = []
	monthList: KeyValue<string, string>[] = []
	roundingList: KeyValue<string, string>[] = []
	numberDecimalList: KeyValue<string, string>[] = []
	calculationMethodList: KeyValue<string, string>[] = []
	rulesetConditionFormGroup: FormGroup
	rewardLevelList: KeyValue<string, string>[] = []
	yearlyCycleTypeList: KeyValue<string, string>[] = []
	billingCycleTypeList: KeyValue<string, string>[] = []

	resourceType: string
	channelValue: string
	selectedValue: any = ''

	public filteredRewardPool: ReplaySubject<KeyValue<string, string>[]> = new ReplaySubject<KeyValue<string, string>[]>(1)
	public filteredChannelList: ReplaySubject<KeyValue<string, string>[]> = new ReplaySubject<KeyValue<string, string>[]>(1)

	public dropDownCtrlRewardPool: FormControl = new FormControl(this.selectedValue)
	public dropDownCtrlChannelList: FormControl = new FormControl(this.selectedValue)
	constructor(
		private store: Store<AppStore.State>,
		private dialogRef: MatDialogRef<RulesetComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private _formBuilder: FormBuilder,
		private elementRef: ElementRef,
		public activedRoute: ActivatedRoute,
	) {
		this.rulesetConditionFormGroup = this._formBuilder.group({
			channelCtrl: ['', Validators.required]
		})
		this.informationDetailFormState$ = this.store.select(({ campaign }) => campaign.informationDetailForm)
		this.formState$ = this.store.select(({ campaign }) => campaign.rulesetDetailForm)
		this.rewardFormState$ = this.store.select(({ campaign }) => campaign.rulesetRewardForm)
		this.rulesets$ = this.store.select(CampaignSelectors.getRulesets)
		this.campaignTiers$ = this.store.select(CampaignSelectors.getCampaignTiers)

		this.subs = this.store.select(CampaignSelectors.getCampaign).subscribe(({ isLoading, kind, informationDetailForm, campaignRulesetAction, ruleList, rulesetData, campaignData, rulesetDetailForm, rulesetRewardForm, resourceType }) => {
			this.isLoading = isLoading
			// set action
			this.action = campaignRulesetAction
			this.kind = kind
			// set option list
			this.ruleListLength = ruleList.length
			this.amountList = rulesetData.amountList
			this.channelList = campaignData.channelList
			this.transactionMethodList = campaignData.transactionMethodList
			this.transactionsMethodList = campaignData.transactionsMethodList
			this.dayList = campaignData.dayList
			this.dayOfMonthList = campaignData.dayOfMonthList
			this.monthList = campaignData.monthList
			this.rewardPoolList = campaignData.rewardPools.filter(x => x.resourceCode === rulesetDetailForm.controls.channel.value)
			this.roundingList = campaignData.roundingList
			this.numberDecimalList = campaignData.numberDecimalList
			this.calculationMethodList = campaignData.calculationMethodList
			this.yearlyCycleTypeList = campaignData.yearlyCycleTypeList
			this.rulesetConditionFormGroup.controls.channelCtrl.setValue(rulesetDetailForm.value.channel)
			this.resourceType = resourceType

			const enableConditionalReward = informationDetailForm.value.enableConditionalReward

			this.filteredRewardPool.next(this.rewardPoolList.slice())
			this.filteredChannelList.next(this.channelList.slice())
			this.rewardLevelList = campaignData.rewardLevelList
			this.accumulationCycleTypeList = campaignData.accumulationCycleTypeList
			.filter(x => {
				const channelResourceType = campaignData.channelList.find(o => o.key === rulesetDetailForm.value.channel)
				if(channelResourceType && channelResourceType.resourceType === 'CARD'){
					return x.key
				} else {
					return x.key !== 'B'
				}
			})
			.filter(x => {
				const rewardLevel = rulesetRewardForm.value.rewardLevel
				if (rewardLevel === 'CUSTOMER') {
					return x.key !== 'B' && x.key !== 'C'
				} else if (rewardLevel === 'ACCOUNT') {
					return x.key !== 'C'
				} else {
					return x.key
				}
			})
			this.billingCycleTypeList = campaignData.billingCycleTypeList

			this.rewardTypeList = campaignData.rewardTypeList.filter(x => {
				if (kind === 'PB') {
					return x.key === 'OX'
				}

				if (enableConditionalReward) {
					return x.key !== 'TPT'
				} else {
					return x
				}
			})

			this.transactionMethodList = campaignData.transactionMethodList.filter(x => {
				const type = rulesetRewardForm.value.type
				const rewardCreditMethod = rulesetRewardForm.value.rewardCreditMethod
				if (type === 'TPT') {
					return x.key === 'AMOUNT'
				} else if (type === 'INC') {
					if (rewardCreditMethod === 'I') {
						return x.key === 'AMOUNT'
					} else {
						return x
					}
				} else {
					return x
				}
			})

			this.rewardCreditMethodList = campaignData.rewardCreditMethodList.filter(x => {
				const type = rulesetRewardForm.value.type
				const rewardLevel = rulesetRewardForm.value.rewardLevel
				if (type === 'TPT') {
					if (!enableConditionalReward) {
						return x.key !== 'CCE' && x.key !== 'CPE'
					}
				} else if (type === 'TPC') {
					return x.key !== 'I'
				} else if (type === 'INC') {
					return x.key === 'CCE'
				} else {
					if (enableConditionalReward) {
						return x.key !== 'I'
					} else {
						return x
					}
				}
			})

			this.dayOfMonthList = campaignData.dayOfMonthList.filter(e => {
				const cycleType = rulesetRewardForm.controls.accumulationCycleType.value
				const cycleMonth = rulesetRewardForm.controls.accumulationCycleMonth.value
				if (cycleType === 'Y') {
					// show 30 days
					if (cycleMonth === '4' || cycleMonth === '6' || cycleMonth === '9' || cycleMonth === '11') {
						return e.key !== '31'
					// show 28 days for feb
					} else if (cycleMonth === '2') {
						return e.key !== '29' && e.key !== '30' && e.key !== '31'
					// show 31 days
					} else {
						return e
					}
				} else {
					return e
				}
			})

			const campaignTiers = rulesetRewardForm.value.campaignTiers
			this.campaignTiersLength = campaignTiers.allIds.length

			const rulesets = rulesetDetailForm.value.rulesets
			const ruleConditions = rulesetDetailForm.value.ruleConditions
			const rulesetChilds = rulesetDetailForm.value.rulesetChilds

			const code = rulesetDetailForm.value.code

			function isInvalidCode() {
				return !code.match(new RegExp(/^([a-zA-Z0-9])+$/))
			}

			function isInvalidName() {
				return !rulesetDetailForm.value.name
			}

			function isInvalidRulesets() {
				return Object.values(rulesets.byId)
					.some(value => {
						const isEmptyConditionalRules = value.ruleConditionIds && value.ruleConditionIds.length === 0
						const isInvalidForm = value.form && value.form.isInvalid
						return isEmptyConditionalRules || isInvalidForm
					})
			}

			function isInvalidRuleConditions() {
				return Object.values(ruleConditions.byId)
					.some(value => value.rulesetChildIds && value.rulesetChildIds.length === 0)
			}

			function isInvalidRulesetChildren() {
				return Object.values(rulesetChilds.byId)
					.some(value => value.form && value.form.isInvalid)
			}

			function isInvalidCampaignTiers() {
				return Object.values(campaignTiers.byId)
					.some(value => value.form && value.form.isInvalid)
			}

			this.isValid = ![
				isInvalidCode,
				isInvalidName,
				isInvalidRulesets,
				isInvalidRuleConditions,
				isInvalidRulesetChildren,
				isInvalidCampaignTiers
			].some(condition => condition())

			this.isFirstStepValid = ![
				isInvalidCode,
				isInvalidName,
				isInvalidRulesets,
				isInvalidRuleConditions,
				isInvalidRulesetChildren
			].some(condition => condition())

		})

		if (this.isUpdate || this.isPublishedUpdate) { this.store.dispatch(CampaignAction.SetResourceType()) }


		this.dropDownCtrlChannelList.valueChanges
			.subscribe(value => {
				const maxChar = 255
				if (value.length > maxChar) {
					const newValue = value.slice(0, maxChar)
					this.dropDownCtrlChannelList.reset('', { emitEvent: false })
					this.dropDownCtrlChannelList.setValue(newValue)

				} else {
					this.searchResource(value)
				}
			})

		this.dropDownCtrlRewardPool.valueChanges
			.subscribe(value => {
				const maxChar = 255
				if (value.length > maxChar) {
					const newValue = value.slice(0, maxChar)
					this.dropDownCtrlRewardPool.reset('', { emitEvent: false })
					this.dropDownCtrlRewardPool.setValue(newValue)

				} else {
					this.searchRewardPool(value)
				}
			})


	}

	get isCreate(): boolean { return this.action === 'CREATE' }
	get isUpdate(): boolean { return this.action === 'UPDATE' }
	get isView(): boolean { return this.action === 'VIEW' }
	get isPublishedUpdate(): boolean { return this.action === 'PUBLISHED_UPDATE' }

	ngOnInit() {
	}

	ngOnDestroy() {
		this.subs.unsubscribe()
	}

	ngAfterViewInit() {
		this.elementRef.nativeElement.querySelectorAll('mat-step-header').forEach(item => {
			item.addEventListener('click', event => {
				this.formState$.pipe(take(1)).subscribe(o => {
					Object.values(o.controls.rulesets.value.byId).forEach(value => {
						if(value.form) {
						this.store.dispatch(new MarkAsTouchedAction(value.form.id))
						}
					})
					Object.values(o.controls.rulesetChilds.value.byId).forEach(value => {
						this.store.dispatch(new MarkAsTouchedAction(value.form.id))
					})
					this.store.dispatch(new MarkAsTouchedAction(o.controls.code.id))
					this.store.dispatch(new MarkAsTouchedAction(o.controls.name.id))
					this.store.dispatch(new MarkAsTouchedAction(o.controls.channel.id))
				})
			})
		})
	}

	trackByIndex(index: number, ruleset: Ruleset) {
		return ruleset.id
	}

	trackByCampaignTierIndex(index: number, campaignTier: CampaignTier) {
		return campaignTier.id
	}

	selectionChange(stepperSelectionEvent?: StepperSelectionEvent) {
		this.selectedIndex = stepperSelectionEvent.selectedIndex
	}

	cancel() {
		this.dialogRef.close()
	}

	submit() {
		this.store.dispatch(CampaignAction.SaveRuleset())
		this.dialogRef.close()
	}

	addRuleset() {
		this.store.dispatch(CampaignAction.AddRulesetDialog())
	}

	addCampaignTier() {
		this.store.dispatch(CampaignAction.AddCampaignTier())
	}

	addGroup() {
		this.store.dispatch(CampaignAction.AddGroup())
	}

	onChangeChannel(resourceCode: string) {
		this.rulesetConditionFormGroup.controls.channelCtrl.setValue(resourceCode)
		if (resourceCode) {
			this.store.dispatch(CampaignAction.OnChangeChannelGetRulesetList({ resourceCode }))
		} else {
			this.store.dispatch(CampaignAction.OnChangeChannelValue())
		}
		this.onChangeRewardCreditMethod()
	}

	onChangeRewardPool() {
		this.store.dispatch(CampaignAction.OnChangeRewardPool())
	}

	onChangeType() {
		this.store.dispatch(CampaignAction.OnChangeType())
	}

	onChangeRewardCreditMethod() {
		this.store.dispatch(CampaignAction.OnChangeRewardCreditMethod())
	}

	onChangeTransactionMethod() {
		this.store.dispatch(CampaignAction.OnChangeTransactionMethod())
	}

	onChangeRewardMethod() {
		this.store.dispatch(CampaignAction.OnChangeRewardMethod())
	}

	onChangeAccumulationCycleType() {
		this.store.dispatch(CampaignAction.OnChangeAccumulationCycleType())
	}

	onChangeRounding() {
		this.store.dispatch(CampaignAction.OnChangeRounding())
	}

	onChangeRewardLevel() {
		this.store.dispatch(CampaignAction.OnChangeRewardLevel())
	}

	onChangeYearlyCycleType() {
		this.store.dispatch(CampaignAction.OnChangeAccumulationYearlyCycleType())
	}

	getGuidelines() {
		switch (this.selectedIndex) {
			case 0:
				return 'CAMPAIGN.GUIDELINES.RULESET_CONDITIONS'
			case 1:
				return 'CAMPAIGN.GUIDELINES.RULESET_REWARD'
		}
	}

	getRewardPoolValue(value: string): string {
		const result = this.rewardPoolList.find(x => x.key === value)
		return result ? result.value : ''
	}

	getAmountValue(value: string): string {
		const result = this.amountList.find(x => x.key === value)
		return result ? result.value : ''
	}

	getRewardTypeValue(value: string): string {
		const result = this.rewardTypeList.find(x => x.key === value)
		return result ? result.value : ''
	}

	getRewardCreditMethodValue(value: string): string {
		const result = this.rewardCreditMethodList.find(x => x.key === value)
		return result ? result.value : ''
	}

	getChannelValue(value: string): string {
		this.channelValue = value
		const result = this.channelList.find(x => x.key === value)
		return result ? result.value : ''
	}

	getTransactionMethodValue(value: string): string {
		const result = this.transactionMethodList.find(x => x.key === value)
		return result ? result.value : ''
	}

	getTransactionsMethodValue(value: string): string {
		const result = this.transactionsMethodList.find(x => x.key === value)
		return result ? result.value : ''
	}

	getAccumulationCycleTypeValue(value: string): string {
		const result = this.accumulationCycleTypeList.find(x => x.key === value)
		return result ? result.value : ''
	}

	getDayValue(value: string): string {
		const result = this.dayList.find(x => x.key === value)
		return result ? result.value : ''
	}

	getDayOfMonthValue(value: string): string {
		const result = this.dayOfMonthList.find(x => x.key === value)
		return result ? result.value : ''
	}

	getMonthValue(value: string): string {
		const result = this.monthList.find(x => x.key === value)
		return result ? result.value : ''
	}

	getRoundingValue(value: string): string {
		const result = this.roundingList.find(x => x.key === value)
		return result ? result.value : ''
	}

	getNumberDecimalValue(value: string): string {
		const result = this.numberDecimalList.find(x => x.key === value)
		return result ? result.value : ''
	}

	getCalculationMethodValue(value: string): string {
		const result = this.calculationMethodList.find(x => x.key === value)
		return result ? result.value : ''
	}

	getRewardLevelValue(value: string): string {
		const result = this.rewardLevelList.find(x => x.key === value)
		return result ? result.value : ''
	}

	searchRewardPool(search: string) {
		if (!this.rewardPoolList) {
			return
		}
		if (!search) {
			this.filteredRewardPool.next(this.rewardPoolList.slice())
			return
		} else {
			search = search.toLowerCase()
		}
		// filter the records
		this.filteredRewardPool.next(
			this.rewardPoolList.filter(rewardPool => FilterPredicate.isIdenticalTo(search)([rewardPool.value]))
		)
	}
	clearSearchRewardPool() {
		this.filteredRewardPool.next(this.rewardPoolList.slice())
	}

	searchResource(search: string) {
		if (!this.channelList) {
			return
		}
		if (!search) {
			this.filteredChannelList.next(this.channelList.slice())
			return
		} else {
			search = search.toLowerCase()
		}
		// filter the records
		this.filteredChannelList.next(
			this.channelList.filter(resource => FilterPredicate.isIdenticalTo(search)([resource.value]))
		)
	}
	clearSearchResource() {
		this.filteredChannelList.next(this.channelList.slice())
	}

	getYearlyCycleValue(value: string): string {
		const result = this.yearlyCycleTypeList.find(x => x.key === value)
		return result ? result.value : ''
	}

	clearBillingCycleType(){
		this.store.dispatch(CampaignAction.OnChangeRulesetBillingCycleType())
	}

	getBillingCycleType(value: string): string {
		const result = this.billingCycleTypeList.find(x => x.key === value)
		return result ? result.value : ''
	}

	clearCycleDay() {
		this.store.dispatch(CampaignAction.OnChangeCycleMonth())
	}
}
