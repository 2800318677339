import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { Request, Response } from 'src/app/models/common/http'
import { RestService } from '../common/rest-client/rest.service'
import { TaskRequest, TaskRejectRequest, TaskCodeRequest, TaskId, TasklistPaginator, TasklistStatusPaginator, TasklistSearch } from 'src/app/models/worklist/worklist'

@Injectable({
	providedIn: 'root'
})
export class WorklistService {

	constructor(
		private rest: RestService,
	) { }

	getTasklist(paginator: TasklistPaginator): Observable<Response> {
		const element = { ownTask: 'false', pageNumber: paginator.pageNumber, pageSize: paginator.pageSize }
		const request: Request = {
			liveURL: '/worklist/list' + this.rest.urlQueryString(element),
		}

		return this.rest.get(request)
	}

	getMyRequest(paginator: TasklistPaginator): Observable<Response> {
		const element = { ownTask: 'true', pageNumber: paginator.pageNumber, pageSize: paginator.pageSize }
		const request: Request = {
			liveURL: '/worklist/list' + this.rest.urlQueryString(element),
		}

		return this.rest.get(request)
	}

	getTasklistByStatus(params: TasklistStatusPaginator): Observable<Response> {
		const element = { ownTask: 'false', pageNumber: params.pageNumber, pageSize: params.pageSize }
		const request: Request = {
			liveURL: '/worklist/list/' + params.status + this.rest.urlQueryString(element),
		}

		return this.rest.get(request)
	}

	getMyRequestByStatus(params: TasklistStatusPaginator): Observable<Response> {
		const element = { ownTask: 'true', pageNumber: params.pageNumber, pageSize: params.pageSize }
		const request: Request = {
			liveURL: '/worklist/list/' + params.status + this.rest.urlQueryString(element),
		}

		return this.rest.get(request)
	}

	searchTasklistByKeyword(params: TasklistSearch): Observable<Response> {
		const element = { ownTask: 'false', keyword: params.keyword, pageNumber: params.pageNumber, pageSize: params.pageSize }
		const request: Request = {
			liveURL: '/worklist/list/' + params.status + this.rest.urlQueryString(element),
		}

		return this.rest.get(request)
	}

	searchMyRequestByKeyword(params: TasklistSearch): Observable<Response> {
		const element = { ownTask: 'true', keyword: params.keyword, pageNumber: params.pageNumber, pageSize: params.pageSize }
		const request: Request = {
			liveURL: '/worklist/list/' + params.status + this.rest.urlQueryString(element),
		}

		return this.rest.get(request)
	}

	getTaskDetail(params: TaskId): Observable<Response> {
		const request: Request = {
			liveURL: '/worklist/' + `${params.id}`,
		}

		return this.rest.get(request)
	}

	getDataCode(params: TaskCodeRequest): Observable<Response> {
		const element = { dataId: `${params.dataId}`, taskType: params.taskType }
		const request: Request = {
			liveURL: '/worklist/getCode' + `?${new URLSearchParams({ ...element })}`,
		}

		return this.rest.get(request)
	}

	approveTask(params: TaskRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/worklist/approve/' + `${params.dataId}`,
		}

		return this.rest.post(request)
	}

	rejectTask(params: TaskRejectRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/worklist/reject/' + `${params.dataId}`,
			body: { rejectReason: params.rejectReason }
		}

		return this.rest.post(request)
	}

	withdrawTask(params: TaskRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/worklist/withdraw/' + `${params.dataId}`,
		}

		return this.rest.post(request)
	}
}
