import { WorklistDetailResponse } from 'src/app/models/worklist/worklist'

export interface State {
	isLoading: boolean
	allMyRequestResponse: WorklistDetailResponse
	pendingMyRequestResponse: WorklistDetailResponse
	approvedMyRequestResponse: WorklistDetailResponse
	rejectedMyRequestResponse: WorklistDetailResponse
}

export const initialState: State = {
	isLoading: false,
	allMyRequestResponse: {
		pageHeader: {
			itemCount: 0,
			pageNum: 0,
			pageSize: 0,
			totalItem: 0,
			totalPage: 0
		},
		worklist: []
	},
	pendingMyRequestResponse: {
		pageHeader: {
			itemCount: 0,
			pageNum: 0,
			pageSize: 0,
			totalItem: 0,
			totalPage: 0
		},
		worklist: []
	},
	approvedMyRequestResponse: {
		pageHeader: {
			itemCount: 0,
			pageNum: 0,
			pageSize: 0,
			totalItem: 0,
			totalPage: 0
		},
		worklist: []
	},
	rejectedMyRequestResponse: {
		pageHeader: {
			itemCount: 0,
			pageNum: 0,
			pageSize: 0,
			totalItem: 0,
			totalPage: 0
		},
		worklist: []
	}
}
