import { FileDownload } from 'src/app/models/file-download/file-download'

export interface State {
	isLoading: boolean
	fileDownloadList: FileDownload[]

}

export const initialState: State = {
	isLoading: false,
	fileDownloadList: []
}
