import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'
import { Observable, Subscription } from 'rxjs'
import * as AppStore from 'src/app/store'
import * as CampaignAction from 'src/app/store/campaign-management/campaign/campaign.actions'
import * as CampaignSelectors from 'src/app/store/campaign-management/campaign/campaign.selectors'
import { RuleCondition, Ruleset } from 'src/app/store/campaign-management/campaign/campaign.state'

@Component({
	selector: 'app-rule-condition',
	templateUrl: './rule-condition.component.html',
	styleUrls: ['./rule-condition.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class RuleConditionComponent implements OnInit, OnDestroy {

	@Input() ruleCondition: RuleCondition
	rulesetChilds$: Observable<Ruleset[]>
	action: string
	subs: Subscription
	ruleListLength = 0

	constructor(
		private store: Store<AppStore.State>
	) { }

	get isView(): boolean { return this.action === 'VIEW' }

	ngOnInit() {
		this.rulesetChilds$ = this.store.select(CampaignSelectors.getConditions, this.ruleCondition.id)
		this.subs = this.store.select(CampaignSelectors.getCampaign).subscribe(({ campaignRulesetAction, ruleList }) => {
			this.action = campaignRulesetAction
			this.ruleListLength = ruleList.length - 1
		})
	}

	ngOnDestroy() {
		this.subs.unsubscribe()
	}

	trackByIndex(index: number, ruleset: Ruleset) {
		return ruleset && ruleset.id
	}

	add() {
		this.store.dispatch(CampaignAction.SetRuleCondition({ ruleCondition: this.ruleCondition }))
		this.store.dispatch(CampaignAction.AddRulesetChildDialog())
	}

	delete() {
		this.store.dispatch(CampaignAction.DeleteCondition({ ruleCondition: this.ruleCondition }))
	}
}
