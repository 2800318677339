import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { FulfilmentPartnerCreateRequest } from 'src/app/models/catalogue-management/fulfilment-partner/fulfilment-partner-create'
import { FulfilmentPartnerDeleteRequest } from 'src/app/models/catalogue-management/fulfilment-partner/fulfilment-partner-delete'
import { FulfilmentPartnerUpdateRequest } from 'src/app/models/catalogue-management/fulfilment-partner/fulfilment-partner-update'
import { FulfilmentPartnerViewRequest } from 'src/app/models/catalogue-management/fulfilment-partner/fulfilment-partner-view'
import { ProductCatalogueCreateRequest } from 'src/app/models/catalogue-management/product-catalogue/product-catalogue-create'
import { ProductCatalogueDeleteRequest } from 'src/app/models/catalogue-management/product-catalogue/product-catalogue-delete'
import { ProductCatalogueUpdateRequest } from 'src/app/models/catalogue-management/product-catalogue/product-catalogue-update'
import { ProductCatalogueViewRequest } from 'src/app/models/catalogue-management/product-catalogue/product-catalogue-view'
import { Request, Response } from 'src/app/models/common/http'
import { RestService } from '../common/rest-client/rest.service'
import { ProductCatalogueImageUploadRequest } from 'src/app/models/catalogue-management/product-catalogue/product-catalogue-image-upload'
import { ProductCataloguePolicy } from 'src/app/models/catalogue-management/product-catalogue/product-catalogue-reward-pool'
import { ProductPartnerViewRequest } from 'src/app/models/catalogue-management/product-partner/product-partner-view'
import { ProductPartnerCreateRequest } from 'src/app/models/catalogue-management/product-partner/product-partner-create'
import { ProductPartnerUpdateRequest } from 'src/app/models/catalogue-management/product-partner/product-partner-update'
import { ProductPartnerImageUploadRequest } from 'src/app/models/catalogue-management/product-partner/product-partner-image-upload'
import { FulfilmentPartnerDuplicateRequest } from '../../models/catalogue-management/fulfilment-partner/fulfilment-partner-duplicate'
import { ProductCatalogueDuplicateRequest } from '../../models/catalogue-management/product-catalogue/product-catalogue-duplicate'
import { ProductPartnerDuplicateRequest } from '../../models/catalogue-management/product-partner/product-partner-duplicate'
@Injectable({
	providedIn: 'root'
})
export class CatalogueManagementService {

	constructor(
		private rest: RestService,
	) { }

	// Product Catalogue
	getProductCatalogueList(): Observable<Response> {
		const request: Request = {
			liveURL: '/product-catalogues/list'
		}

		return this.rest.get(request)
	}

	getProductCatalogueDetails(params: ProductCatalogueViewRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/product-catalogues/view/' + params.id
		}

		return this.rest.get(request)
	}

	uploadProductCatalogueImage(params: ProductCatalogueImageUploadRequest): Observable<Response> {
		const request: Request = {
			body: params,
			liveURL: '/product-catalogues/upload/image'
		}

		return this.rest.post(request)
	}

	createProductCatalogue(params: ProductCatalogueCreateRequest): Observable<Response> {
		const request: Request = {
			body: params,
			liveURL: '/product-catalogues/create'
		}

		return this.rest.post(request)
	}

	editProductCatalogue(params: ProductCatalogueUpdateRequest): Observable<Response> {
		const request: Request = {
			body: params,
			liveURL: '/product-catalogues/edit/' + params.id
		}

		return this.rest.post(request)
	}

	deleteProductCatalogue(params: ProductCatalogueDeleteRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/product-catalogues/delete/' + params.id
		}

		return this.rest.post(request)
	}

	getProductCatalogueStatusList(): Observable<Response> {
		const request: Request = {
			liveURL: '/product-catalogues/status/list'
		}

		return this.rest.get(request)
	}

	getProductCatalogueRedemptionTypeList(): Observable<Response> {
		const request: Request = {
			liveURL: '/product-catalogues/redemptionType/list'
		}

		return this.rest.get(request)
	}

	getProductCatalogueRewardPoolAndGroupList(params: ProductCataloguePolicy): Observable<Response> {
		const request: Request = {
			liveURL: '/product-catalogues/rewardPoolAndGroup/list' + `?${new URLSearchParams({ ...params })}`
		}

		return this.rest.get(request)
	}

	getProductCatalogueGlRedemptionList(params: ProductCataloguePolicy): Observable<Response> {
		const request: Request = {
			liveURL: '/product-catalogues/glRedemption/list' + `?${new URLSearchParams({ ...params })}`
		}

		return this.rest.get(request)
	}

	getProductCatalogueFulfilmentPartnerList(params: ProductCataloguePolicy): Observable<Response> {
		const request: Request = {
			liveURL: '/product-catalogues/fulfilment-partners/list' + `?${new URLSearchParams({ ...params })}`
		}

		return this.rest.get(request)
	}

	getProductCategoryList(): Observable<Response> {
		const request: Request = {
			liveURL: '/product-catalogues/product-category/list'
		}

		return this.rest.get(request)
	}

	duplicateProductCatalogue(id: number, params: ProductCatalogueDuplicateRequest): Observable<Response> {
		const request: Request = {
			body: params,
			liveURL: '/product-catalogues/duplicate/' + id
		}

		return this.rest.post(request)
	}

	// Fulfilment Partner


	getFulfilmentPartnerList(): Observable<Response> {
		const request: Request = {
			liveURL: '/fulfilment-partners/list'
		}
		return this.rest.get(request)
	}

	getFulfilmentPartnerDetails(params: FulfilmentPartnerViewRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/fulfilment-partners/view/' + params.id
		}
		return this.rest.get(request)
	}

	createFulfilmentPartner(params: FulfilmentPartnerCreateRequest): Observable<Response> {
		const request: Request = {
			body: params,
			liveURL: '/fulfilment-partners/create'
		}
		return this.rest.post(request)
	}


	editFulfilmentPartner(params: FulfilmentPartnerUpdateRequest): Observable<Response> {
		const request: Request = {
			body: params,
			liveURL: '/fulfilment-partners/edit/' + params.id
		}
		return this.rest.post(request)
	}

	deleteFulfilmentPartner(params: FulfilmentPartnerDeleteRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/fulfilment-partners/delete/' + params.id
		}
		return this.rest.post(request)
	}

	getFulfilmentPartnerFileFrequencyList(): Observable<Response> {
		const request: Request = {
			liveURL: '/fulfilment-partners/fileFrequency/list'
		}
		return this.rest.get(request)
	}

	duplicateFulfilmentPartner(id: number, params: FulfilmentPartnerDuplicateRequest): Observable<Response> {
		const request: Request = {
			body: params,
			liveURL: '/fulfilment-partners/duplicate/' + id
		}
		return this.rest.post(request)
	}

	// Product Partner
	getProductPartnerList(): Observable<Response> {
		const request: Request = {
			liveURL: '/product-partner/list'
		}

		return this.rest.get(request)
	}

	getProductPartnerDetails(params: ProductPartnerViewRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/product-partner/view/' + params.id
		}

		return this.rest.get(request)
	}

	uploadProductPartnerImage(params: ProductPartnerImageUploadRequest): Observable<Response> {
		const request: Request = {
			body: params,
			liveURL: '/product-partner/upload/image'
		}

		return this.rest.post(request)
	}

	createProductPartner(params: ProductPartnerCreateRequest): Observable<Response> {
		const request: Request = {
			body: params,
			liveURL: '/product-partner/create'
		}

		return this.rest.post(request)
	}

	editProductPartner(params: ProductPartnerUpdateRequest): Observable<Response> {
		const request: Request = {
			body: params,
			liveURL: '/product-partner/edit/' + params.id
		}

		return this.rest.post(request)
	}

	deleteProductPartner(params: ProductCatalogueDeleteRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/product-partner/delete/' + params.id
		}

		return this.rest.post(request)
	}

	getProductPartnerStatusList(): Observable<Response> {
		const request: Request = {
			liveURL: '/product-partner/status/list'
		}

		return this.rest.get(request)
	}

	getProductPartnerFilteredRewardPoolAndGroupList(params: ProductCataloguePolicy, redeemPartnerId: string): Observable<Response> {
		const request: Request = {
			liveURL: '/product-partner/rewardPoolAndGroup/filtered-list/' + redeemPartnerId + `?${new URLSearchParams({ ...params })}`
		}

		return this.rest.get(request)
	}

	getProductPartnerRewardPoolAndGroupList(params: ProductCataloguePolicy): Observable<Response> {
		const request: Request = {
			liveURL: '/product-partner/rewardPoolAndGroup/list/' + `?${new URLSearchParams({ ...params })}`
		}

		return this.rest.get(request)
	}

	getProductPartnerRedeemPartnerList(params: ProductCataloguePolicy): Observable<Response> {
		const request: Request = {
			liveURL: '/product-partner/redeem-partners/list' + `?${new URLSearchParams({ ...params })}`
		}

		return this.rest.get(request)
	}

	duplicateProductPartner(id: number, params: ProductPartnerDuplicateRequest): Observable<Response> {
		const request: Request = {
			body: params,
			liveURL: '/product-partner/duplicate/' + id
		}

		return this.rest.post(request)
	}
}
