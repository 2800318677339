<mat-accordion>
  <mat-expansion-panel class="mat-box-container" [expanded]="!isView" [hideToggle]="!isView">
    <mat-expansion-panel-header>
      <div fxLayout="row" fxLayoutAlign="space-between center" fxFlex>
        <span class="content-title">{{ 'CAMPAIGN.CONDITION' | translate }}</span>
        <mat-icon *ngIf="!isView" class="mat-icon-pointer material-icons-outlined" (click)="delete()">close</mat-icon>
      </div>
    </mat-expansion-panel-header>

    <div fxLayout="column">
      <div *ngFor="let ruleset of (rulesetChilds$ | async);trackBy: trackByIndex;let i=index" fxLayout="column">
        <div *ngIf="ruleset" fxFlexOffset="8px">
          <app-rule-dynamic [ruleset]="ruleset"></app-rule-dynamic>
        </div>
      </div>

      <div *ngIf="!isView" fxLayout="column" fxFlexOffset="8px">
        <div>
          <button mat-button color="primary" class="rule-btn" (click)="add()"
            [disabled]="(rulesetChilds$ | async).length >= ruleListLength" fxLayout="row" fxLayoutAlign="center">
            <mat-icon class="material-icons-outlined">add</mat-icon> {{ 'CAMPAIGN.ACTION.ADD_RULE' | translate }}
          </button>
        </div>
      </div>
    </div>

  </mat-expansion-panel>
</mat-accordion>