import { createFormGroupState, FormGroupState, updateGroup, validate } from 'ngrx-forms'
import { maxLength } from 'ngrx-forms/validation'
import { AssignGroup } from 'src/app/models/access-management/user/user'
import { UserListResponse } from 'src/app/models/access-management/user/user-list'
import { UserViewResponse, GroupListResult} from 'src/app/models/access-management/user/user-view'
import { Group } from 'src/app/models/access-management/group/group'

export interface State {
	isLoading: boolean
	userId?: number
	isUserPage?: boolean
	userListForm: FormGroupState<UserListForm>
	userDetailForm: FormGroupState<UserDetailForm>
	userListResponse: UserListResponse
	userViewResponse: UserViewResponse
	isTableLoading: boolean
	groupListResult: GroupListResult[]
	groupResult: GroupListResult
	groups: Group[]
}

export interface UserListForm {
	username: string
	email: string
	pageNum: number
	pageSize: number
}

export const initialUserListFormValue: UserListForm = {
	username: '',
	email: '',
	pageNum: 0,
	pageSize: 0
}

export const initialUserListForm = createFormGroupState('userListForm', initialUserListFormValue)

export const validateUserListForm = updateGroup<UserListForm>({
	username: validate([maxLength(255)]),
	email: validate([maxLength(255)])
})

export interface UserDetailForm {
	username: string
	email: string
	groups: AssignGroup[]
}

export const initialUserDetailFormValue: UserDetailForm = {
	username: '',
	email: '',
	groups: []
}

export const initialUserDetailForm = createFormGroupState('userDetailForm', initialUserDetailFormValue)

export const validateUserDetailForm = updateGroup<UserDetailForm>({
	username: validate([maxLength(255)]),
	email: validate([maxLength(255)]),
})

export const initialState: State = {
	isLoading: false,
	userListForm: initialUserListForm,
	userDetailForm: initialUserDetailForm,
	userListResponse: {
		users: []
	},
	userViewResponse: {
		userId: 0,
		username: '',
		email: '',
		modifiedBy: '',
		modifiedDate: '',
		groups: []
	},
	groupListResult: [],
	groupResult: {
		success: false,
		payload: ''
	},
	isTableLoading: false,
	groups: []
}
