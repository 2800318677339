import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { MatPaginator, MatTableDataSource } from '@angular/material'
import { AccountData } from 'src/app/models/customer-management/customer-detail'
import { FilterPredicate } from '../../../../models/util/filter-predicate'

@Component({
	selector: 'app-account-deposit-table',
	templateUrl: './account-deposit-table.component.html',
	styleUrls: ['./account-deposit-table.component.scss']
})
export class AccountDepositTableComponent implements OnInit {

	@Input() isLoading: boolean
	@Input() headerColumns: Array<string>
	@Input() accountList: AccountData[]
	@Input() displayedColumns: Array<any>
	@Output() rowClicked = new EventEmitter()

	dataSource: MatTableDataSource<AccountData>
	@ViewChild('matPaginator', { static: true }) paginator: MatPaginator

	constructor() {
		this.dataSource = new MatTableDataSource()
	}

	ngOnInit() {
		this.dataSource.data = this.accountList
		this.dataSource.filterPredicate = ({ accountNumber }: AccountData, filter: string): boolean => {
			return FilterPredicate.isIdenticalTo(filter)([accountNumber])
		}
		this.dataSource.paginator = this.paginator
	}

	triggerRowClickOut(event) {
		this.rowClicked.emit(event)
	}

	search(filterValue: string) {
		this.dataSource.filter = filterValue.trim().toLowerCase()
	}
}
