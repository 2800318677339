import { KeyValue } from '@angular/common'
import { Action, createReducer, on } from '@ngrx/store'
import * as NgrxForms from 'ngrx-forms'
import { box, createFormGroupState, formGroupReducer, markAsTouched, reset, setValue, SetValueAction } from 'ngrx-forms'
import * as OnTheSpotRedemptionAction from './on-the-spot-redemption.actions'
import * as OnTheSpotRedemptionState from './on-the-spot-redemption.state'
import { Util } from 'src/app/models/util/util'
import { RewardPoolAndGroupOptions } from 'src/app/models/redemption-settings/on-the-spot-redemption/on-the-spot-redemption-reward-list'

export const reducer = createReducer(
	OnTheSpotRedemptionState.initialState,
	NgrxForms.onNgrxFormsAction(SetValueAction, (state, action) => {
		return actionHandler(state, action)
	}),
	NgrxForms.onNgrxFormsAction(NgrxForms.MarkAsTouchedAction, (state, action) => {
		return actionHandler(state, action)
	}),
	on(OnTheSpotRedemptionAction.InitialState, () => OnTheSpotRedemptionState.initialState),
	on(OnTheSpotRedemptionAction.List, state => ({
		...state,
		isLoading: true,
		isEditing: false
	})),
	on(OnTheSpotRedemptionAction.ListSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		onTheSpotRedemptionList: payload
	})),
	on(OnTheSpotRedemptionAction.RestError, state => ({
		...state,
		isLoading: false,
		isDuplicateLoading: false,
		isDuplicateDialog: false,
		isEditing: false
	})),
	on(OnTheSpotRedemptionAction.SetId, (state, { id }) => ({
		...state,
		id
	})),
	on(OnTheSpotRedemptionAction.GoCreate, state => ({
		...state,
		action: 'CREATE',
		id: null
	})),
	on(OnTheSpotRedemptionAction.GoView, state => ({
		...state,
		action: 'VIEW',
		isEditing: true //to preload merchant group id range before editing
	})),
	on(OnTheSpotRedemptionAction.GoUpdate, state => ({
		...state,
		action: 'UPDATE',
		isEditing: true
	})),
	on(OnTheSpotRedemptionAction.View, state => ({
		...state,
		isLoading: true,
		onTheSpotRedemptionView: OnTheSpotRedemptionState.initialOnTheSpotRedemptionDetail,
		onTheSpotRedemptionDetailForm: reset(setValue(state.onTheSpotRedemptionDetailForm, OnTheSpotRedemptionState.initialOnTheSpotRedemptionDetail))
	})),
	on(OnTheSpotRedemptionAction.ViewSuccess, (state, { payload }) => {
		const rewardPoolAndGlRedemption = payload.rewardPool
		let otsRewardPools: OnTheSpotRedemptionState.OTSRewardPools = {
			byId: {},
			allIds: []
		}

		if (payload.rewardPoolType === 'G') {
			rewardPoolAndGlRedemption.forEach(x => {
				const id = x.rewardPoolId
				otsRewardPools = {
					byId: {
						...otsRewardPools.byId,
						[id]: {
							id,
							form: OnTheSpotRedemptionState.validateOTSRewardPoolForm(getOTSRewardPoolForm(id.toString(), id, x.rewardPoolName, x.glRedemptionId))
						}
					},
					allIds: [...otsRewardPools.allIds, id.toString()]
				}
			})
		}

		const onTheSpotRedemptionView: OnTheSpotRedemptionState.OnTheSpotRedemptionDetailForm = {
			id: payload.id,
			code: payload.code,
			name: payload.name,
			rewardPoolAndGroup: payload.rewardPoolType + '_' + payload.rewardPoolId,
			rewardPoolId: payload.rewardPoolId,
			rewardPoolType: payload.rewardPoolType,
			otsRewardPools,
			onSpotCode: payload.onSpotCode,
			merchantIdRange: box(payload.merchantIdRange && payload.merchantIdRange.split(',')),
			merchantPayout: payload.merchantPayout,
			productType: box(payload.productType && payload.productType.split(',')),
			productTypeGroup: box(payload.productTypeGroup && payload.productTypeGroup.split(',')),
			amount: Number(payload.amount).toFixed(2),
			redemptionLimit: Number(payload.redemptionLimit).toFixed(2),
			redemptionPoint: payload.redemptionPoint.toString(),
			createdBy: payload.createdBy,
			createdDate: payload.createdDate,
			modifiedBy: payload.modifiedBy,
			modifiedDate: payload.modifiedDate,
			endDate: payload.endDate,
			startDate: payload.startDate,
			glRedemptionId: payload.rewardPoolType === 'I' ? payload.rewardPool.find(x => x.rewardPoolId === Number(payload.rewardPoolId)).glRedemptionId : null,
		}

		return ({
			...state,
			onTheSpotRedemptionView,
			onTheSpotRedemptionDetailForm: OnTheSpotRedemptionState.validateOnTheSpotRedemptionDetailForm(state)(setValue(state.onTheSpotRedemptionDetailForm, onTheSpotRedemptionView as OnTheSpotRedemptionState.OnTheSpotRedemptionDetailForm))
		})
	}),
	on(OnTheSpotRedemptionAction.Create, state => ({
		...state,
		isLoading: true
	})),
	on(OnTheSpotRedemptionAction.InitialLoadList, state => ({
		...state,
		isLoading: true
	})),
	on(OnTheSpotRedemptionAction.MerchantGroupList, state => ({
		...state,
		isLoading: false
	})),
	on(OnTheSpotRedemptionAction.RewardPoolAndGroupList, state => ({
		...state,
		isLoading: true
	})),
	on(OnTheSpotRedemptionAction.RewardPoolAndGroupListSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		rewardPoolAndGroupList: (payload || []).map(i => ({ key: `${i.type}_${i.id}`, value: `${i.code} - ${i.name}`, onSpotCodeList: i.onSpotCodeList, merchantIdRangeList: i.merchantIdRangeList })) as RewardPoolAndGroupOptions[]
	})),
	on(OnTheSpotRedemptionAction.ProductTypeAndGroupList, state => ({
		...state,
		isLoading: true
	})),
	on(OnTheSpotRedemptionAction.ProductTypeAndGroupListSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		productTypeAndGroupList: (payload || []).map(i => ({ key: `${i.key}`, value: `${i.value}` })) as KeyValue<string, string>[]
	})),
	on(OnTheSpotRedemptionAction.ProductTypeGroupList, state => ({
		...state,
		isLoading: true
	})),
	on(OnTheSpotRedemptionAction.ProductTypeGroupListSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		productTypeGroupList: (payload || []).map(i => ({ key: `${i.key}`, value: `${i.value}` })) as KeyValue<string, string>[]
	})),
	on(OnTheSpotRedemptionAction.MerchantGroupListSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		merchantGroupList: (state.id !== null && state.isEditing) 
		? (payload || []).filter(x => x.id === null || x.id === parseInt(state.id, 10)).map(i => ({ key: `${i.merchant.key}`, value: `${i.merchant.value}` })) as KeyValue<string, string>[] 
		: (payload || []).filter(x => x.id === null).map(i => ({ key: `${i.merchant.key}`, value: `${i.merchant.value}` })) as KeyValue<string, string>[]
	})),
	on(OnTheSpotRedemptionAction.GlRedemptionList, state => ({
		...state,
		isLoading: true
	})),
	on(OnTheSpotRedemptionAction.GlRedemptionListSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		glRedemptionList: payload.glRedemptions
	})),
	on(OnTheSpotRedemptionAction.GoCreate, state => ({
		...state,
		onTheSpotRedemptionDetailForm: reset(setValue(state.onTheSpotRedemptionDetailForm, OnTheSpotRedemptionState.initialOnTheSpotRedemptionDetail))
	})),
	on(OnTheSpotRedemptionAction.Update, state => ({
		...state,
		isLoading: true,
		isEditing: true
	})),
	on(OnTheSpotRedemptionAction.UpdateSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		isEditing: false
	})),
	on(OnTheSpotRedemptionAction.Delete, state => ({
		...state,
		isLoading: true
	})),
	on(OnTheSpotRedemptionAction.DeleteSuccess, () => OnTheSpotRedemptionState.initialState),
	on(OnTheSpotRedemptionAction.OnChangeRewardPool, state => {
		const rewardPoolAndGroup = state.onTheSpotRedemptionDetailForm.controls.rewardPoolAndGroup.value
		const rewardPoolId = Number(rewardPoolAndGroup && rewardPoolAndGroup.split('_')[1])
		const rewardPoolType = rewardPoolAndGroup && rewardPoolAndGroup.split('_')[0]
		const otsRewardPools = {
			byId: {},
			allIds: []
		}

		return ({
			...state,
			onTheSpotRedemptionDetailForm: setValue(state.onTheSpotRedemptionDetailForm, {
				...state.onTheSpotRedemptionDetailForm.value,
				rewardPoolId,
				rewardPoolType,
				glRedemptionId: state.onTheSpotRedemptionDetailForm.controls.glRedemptionId.value,
				otsRewardPools
			})
		})
	}),
	on(OnTheSpotRedemptionAction.OnChangeRewardPoolGroup, state => {
		const rewardPoolAndGroup = state.onTheSpotRedemptionDetailForm.controls.rewardPoolAndGroup.value
		const rewardPoolId = Number(rewardPoolAndGroup && rewardPoolAndGroup.split('_')[1])
		const rewardPoolType = rewardPoolAndGroup && rewardPoolAndGroup.split('_')[0]
		const otsRewardPools: OnTheSpotRedemptionState.OTSRewardPools = {
			byId: {},
			allIds: []
		}

		return ({
			...state,
			onTheSpotRedemptionDetailForm: OnTheSpotRedemptionState.validateOnTheSpotRedemptionDetailForm(state)(setValue(state.onTheSpotRedemptionDetailForm, {
				...state.onTheSpotRedemptionDetailForm.value,
				rewardPoolId,
				rewardPoolType,
				glRedemptionId: null,
				otsRewardPools
			}))
		})
	}),
	on(OnTheSpotRedemptionAction.OnChangeRewardPoolGroupSuccess, (state, { payload }) => {
		const rewardPoolList = payload.rewardPools

		let otsRewardPools = {
			byId: {},
			allIds: []
		}

		if (rewardPoolList) {
			rewardPoolList.forEach(x => {
				const id = x.id

				otsRewardPools = {
					byId: {
						...otsRewardPools.byId,
						[id]: {
							id,
							form: OnTheSpotRedemptionState.validateOTSRewardPoolForm(getOTSRewardPoolForm(id.toString(), id, `${x.code} - ${x.name}`, null))
						}
					},
					allIds: [...otsRewardPools.allIds, id.toString()]
				}
			})
		}

		return {
			...state,
			onTheSpotRedemptionDetailForm: setValue(state.onTheSpotRedemptionDetailForm, {
				...state.onTheSpotRedemptionDetailForm.value,
				otsRewardPools
			})
		}
	}),
	on(OnTheSpotRedemptionAction.DuplicateDialog, state => ({
		...state,
		onTheSpotRedemptionDuplicateForm: reset(setValue(state.onTheSpotRedemptionDuplicateForm, OnTheSpotRedemptionState.initialOnTheSpotRedemptionDuplicateValue)),
		isDuplicateDialog: false
	})),
	on(OnTheSpotRedemptionAction.Duplicate, state => ({
		...state,
		isDuplicateLoading: true
	})),
	on(OnTheSpotRedemptionAction.DuplicateSuccess, state => ({
		...state,
		isDuplicateLoading: false,
		isDuplicateDialog: true
	}))
)

export function Reducer(state: OnTheSpotRedemptionState.State = OnTheSpotRedemptionState.initialState, action: Action) {
	const onTheSpotRedemptionDetailForm = OnTheSpotRedemptionState.validateOnTheSpotRedemptionDetailForm(state)(formGroupReducer(state.onTheSpotRedemptionDetailForm, action))
	if (onTheSpotRedemptionDetailForm !== state.onTheSpotRedemptionDetailForm) {
		state = { ...state, onTheSpotRedemptionDetailForm }
	}

	const onTheSpotRedemptionDuplicateForm = OnTheSpotRedemptionState.validateOnTheSpotRedemptionDuplicateForm(state)(formGroupReducer(state.onTheSpotRedemptionDuplicateForm, action))
	if (onTheSpotRedemptionDuplicateForm !== state.onTheSpotRedemptionDuplicateForm) {
		state = { ...state, onTheSpotRedemptionDuplicateForm }
	}

	return reducer(state, action)
}

function validateForm(form: any) {
	form = markAsTouched(form)

	return OnTheSpotRedemptionState.validateOnTheSpotRedemptionDetailForm(form)
}

function getOTSRewardPoolForm(id: string, rewardPoolId?: number, rewardPoolName?: string, glRedemptionId?: number) {
	if (rewardPoolId || glRedemptionId) {
		const formGroup = createFormGroupState<OnTheSpotRedemptionState.OTSRewardPoolForm>(id, {
			rewardPoolId,
			rewardPoolName,
			glRedemptionId
		})
		return validateOTSRewardPoolForm(formGroup)
	}

	return createFormGroupState<OnTheSpotRedemptionState.OTSRewardPoolForm>(id,
		OnTheSpotRedemptionState.initialOTSRewardPoolFormValue)
}

function validateOTSRewardPoolForm(form: any, state?: OnTheSpotRedemptionState.State) {
	return markAsTouched(OnTheSpotRedemptionState.validateOTSRewardPoolForm(form))
}

function actionHandler(state: OnTheSpotRedemptionState.State, action: SetValueAction<unknown> | NgrxForms.MarkAsTouchedAction) {
	const formControls = state.onTheSpotRedemptionDetailForm

	let form = formGroupReducer(formControls, action)

	if (action.type === 'ngrx/forms/SET_VALUE') {
		form = formGroupReducer(formControls, new SetValueAction(action.controlId, action.value))
	}

	state = {
		...state,
		onTheSpotRedemptionDetailForm: setValue(state.onTheSpotRedemptionDetailForm, {
			...state.onTheSpotRedemptionDetailForm.value,
			endDate: Util.ISOStringFormat(form.value.endDate),
			startDate: Util.ISOStringFormat(form.value.startDate),
		})
	}

	form = validateForm(state)(form)

	state = {
		...state,
		onTheSpotRedemptionDetailForm: setValue(state.onTheSpotRedemptionDetailForm, {
			...state.onTheSpotRedemptionDetailForm.value,
			endDate: Util.ISOStringFormat(form.value.endDate),
			startDate: Util.ISOStringFormat(form.value.startDate),
		})
	}

	const controlId = action.controlId.split('.')[0]

	let otsRewardPoolsVal = formControls.value.otsRewardPools

	const newOtsRewardPools: OnTheSpotRedemptionState.OTSRewardPools = {
		byId: {
			...otsRewardPoolsVal.byId
		},
		allIds: {
			...otsRewardPoolsVal.allIds
		}
	}

	const otsRewardPools = newOtsRewardPools.byId[controlId]

	if (otsRewardPools) {
		let form = formGroupReducer(otsRewardPools.form, action)

		if (action.type === 'ngrx/forms/SET_VALUE') {
			form = formGroupReducer(otsRewardPools.form, new SetValueAction(action.controlId, action.value))
		}

		otsRewardPoolsVal = {
			byId: {
				...otsRewardPoolsVal.byId,
				[controlId]: {
					...otsRewardPoolsVal.byId[controlId],
					form: validateOTSRewardPoolForm(form)
				}
			},
			allIds: [...otsRewardPoolsVal.allIds]
		}

		state = {
			...state,
			onTheSpotRedemptionDetailForm: setValue(state.onTheSpotRedemptionDetailForm, {
				...state.onTheSpotRedemptionDetailForm.value,
				otsRewardPools: otsRewardPoolsVal
			})
		}
	}

	return state
}

