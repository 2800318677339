import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Action, Store } from '@ngrx/store'
import { Observable, of } from 'rxjs'
import { map, tap, withLatestFrom } from 'rxjs/operators'
import { LocalStorageService } from 'src/app/services/common/local-storage/local-storage.service'
import { SnackbarService } from 'src/app/services/common/snackbar/snackbar.service'
import * as AppStore from 'src/app/store/'
import * as CommonAction from './common.actions'
import { Redirector } from 'src/app/models/util/redirector'
import { MatDialog } from '@angular/material'
import { GlobalDialogBox } from 'src/app/models/util/global-dialog'

@Injectable()
export class Effects {

	constructor(
		private action$: Actions,
		private router: Router,
		private localStorageService: LocalStorageService,
		private snackbarService: SnackbarService,
		private store: Store<AppStore.State>
	) { }

	SetLoginUser$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CommonAction.SetLoginUser),
			tap(({ payload }) => {
				this.localStorageService.set('KEY_STORAGE_USER', { payload })
			})
		), { dispatch: false }
	)

	RestError$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CommonAction.RestError),
			tap(({ message, manualThrow }) => {
				if (!!manualThrow) { this.snackbarService.open(message) }
			})
		), { dispatch: false }
	)

	SuccessMessage$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CommonAction.SuccessMessage),
			tap(({ message }) => {
				this.snackbarService.open(message)
			})
		), { dispatch: false }
	)

	Logout$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CommonAction.Logout),
			tap(() => {
				const dialog: MatDialog = GlobalDialogBox.matDialog
				GlobalDialogBox.appendVariable({ showNo: true, onOk: Redirector.clearStorageAndRedirect })
				dialog.open(GlobalDialogBox.component, GlobalDialogBox.dialogConfig({ content: 'DIALOG.LOGOUT_CONFIRMATION' }))
			})
		), { dispatch: false }
	)

	catcher = (error) => {
		return (of(CommonAction.RestError({ message: error.message, manualThrow: error.manualThrow })))
	}
}
