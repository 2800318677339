import { Component, OnInit, Inject, OnDestroy } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material'
import { Store } from '@ngrx/store'
import * as AppStore from 'src/app/store'
import { Subscription, Observable } from 'rxjs'
import * as CustomerManagementSelector from 'src/app/store/customer-management/customer-management.selectors'
import * as CustomerManagementAction from 'src/app/store/customer-management/customer-management.actions'
import { CustomerCatalogueRedemptionOrderViewResponse } from 'src/app/models/customer-management/customer-catalogue-redemption-order'
import { KeyValue } from '@angular/common'
import { ActivatedRoute, Router } from '@angular/router'

@Component({
	selector: 'app-customer-management-catalogue-redemption-view',
	templateUrl: './customer-management-catalogue-redemption-view.component.html',
	styleUrls: ['./customer-management-catalogue-redemption-view.component.scss']
})
export class CustomerManagementCatalogueRedemptionViewComponent implements OnInit, OnDestroy {

	redemptionType: string
	formState$: Observable<CustomerCatalogueRedemptionOrderViewResponse>
	subs: Subscription
	rewardPoolAndGroupList: KeyValue<string, string>[]
	isLoading = false
	taskId: number

	constructor(
		public activedRoute: ActivatedRoute,
		private router: Router,
		private store: Store<AppStore.State>
	) {
		this.subs = this.store.select(CustomerManagementSelector.getCustomerManagement).subscribe(x => {
			this.taskId = x.redemptionTransactionId
			if (this.taskId === undefined) {
				this.router.navigate(['worklist'])
			}
			this.isLoading = x.isLoading
		})

		this.formState$ = this.store.select(({ customerManagement }) => customerManagement.customerCatalogueRedemptionOrderViewResponse)
	}

	ngOnInit() {
	}

	ngOnDestroy() {
		this.subs.unsubscribe()
	}

	withdraw() {
		this.store.dispatch(CustomerManagementAction.GoCatalogueRedemptionDialog({ action: 'VIEW' }))
	}
}
