import {Action, createReducer, on} from '@ngrx/store'
import {formGroupReducer, setValue} from 'ngrx-forms'
import * as DashboardV2State from './dashboard-overview-v2.state'
import * as DashboardV2Action from './dashboard-overview-v2.actions'
import {CampaignTotalPointComparison} from 'src/app/models/dashboard/dashboard-overview'

export const reducer = createReducer(
	DashboardV2State.initialState,

	on(DashboardV2Action.Reset, (state) => {
		return {
			...DashboardV2State.initialState
		}
	}),

	on(DashboardV2Action.ViewTop5PerformanceCampaignSuccess, (state, { payload }) => {
		return {
			...state,
			topPerformanceCampaign: payload
		}
	}),

	on(DashboardV2Action.ViewTop5CampaignExpiringSuccess, (state, { payload }) => {
		return {
			...state,
			topCampaignExpiring: payload
		}
	}),

	on(DashboardV2Action.ViewTop5CampaignCappingUtilization, state => ({
		...state,
		cappingUtilizationIsLoading: true
	})),
	on(DashboardV2Action.ViewTop5CampaignCappingUtilizationSuccess, (state, { payload }) => {
		return {
			...state,
			cappingUtilizationIsLoading: false,
			campaignCappingUtilizationResponse: payload
		}
	}),

	on(DashboardV2Action.ViewCampaignChannelList, state => ({
		...state,
	})),

	on(DashboardV2Action.ViewCampaignChannelListSuccess, (state, { payload }) => ({
		...state,
		campaignChannelList: payload
	})),
	on(DashboardV2Action.ViewCampaignTotalPointComparison, state => ({
		...state,
		campaignComparison: {
			...state.campaignComparison,
			isLoading: true
		}
	})),
	on(DashboardV2Action.ViewCampaignTotalPointComparisonSuccess, (state, { payload }) => {
		const campaignId: string = payload.campaignId
		const totalPoints: CampaignTotalPointComparison[] = payload.totalPoints

		const newCampaignMap: Record<string, CampaignTotalPointComparison[]> = {
			...state.campaignComparison.campaignIdTotalPointMap,
			[campaignId]: totalPoints
		}
		return {
			...state,
			campaignComparison: {
				...state.campaignComparison,
				isLoading: false,
				campaignIdTotalPointMap: newCampaignMap
			}
		}
	}),

	on(DashboardV2Action.ViewCampaignChannelList, state => ({
		...state,
		campaignComparison: {
			...state.campaignComparison,
			isLoading: true
		}
	})),

	on(DashboardV2Action.ViewCampaignChannelListSuccess, (state, { payload }) => ({
		...state,
		campaignComparison: {
			...state.campaignComparison,
			isLoading: false,
			channelList: payload,
			selectedForm: DashboardV2State.initialDashboardRequestFormValue
		}
	})),

	on(DashboardV2Action.ViewPointRedeemUtilizationSuccess, (state, { payload }) => ({
		...state,
		pointRedeemUtilization: payload
	})),

	on(DashboardV2Action.setCampaignChannel, (state, { campaignChannel }) => ({
		...state,
		campaignComparison: {
			...state.campaignComparison,
			selectedForm: {
				...state.campaignComparison.selectedForm,
				campaignChannel
			}
		}
	})),

	on(DashboardV2Action.setCampaignId1, (state, { campaignId1 }) => {
		return {
			...state,
			campaignComparison: {
				...state.campaignComparison,
				selectedForm: {
					...state.campaignComparison.selectedForm,
					campaignId1
				}
			}
		}
	}),

	on(DashboardV2Action.setCampaignId2, (state, { campaignId2 }) => ({
		...state,
		campaignComparison: {
			...state.campaignComparison,
			selectedForm: {
				...state.campaignComparison.selectedForm,
				campaignId2

			}
		}
	})),

	on(DashboardV2Action.GetPublishedCampaignList, state => ({
		...state,
		campaignComparison: {
			...state.campaignComparison,
			isLoading: true
		}
	})),

	on(DashboardV2Action.GetPublishedCampaignListSuccess, (state, { payload }) => {
		return {
			...state,
			campaignComparison: {
				...state.campaignComparison,
				isLoading: false,
				campaignList: payload,
				selectedForm: {
					...state.campaignComparison.selectedForm,
					campaignId1: (payload[0] && payload[0].id) || 0,
					campaignId2: (payload[1] && payload[1].id) || 0,
				}
			}
		}
	}),

	on(DashboardV2Action.DurationOptionListSuccess, (state, { payload }) => {
		return {
			...state,
			durationOptionList: payload,
			durationOptionListWithoutCustom: payload.filter(o => o.key !== 'custom')
		}
	}),

	on(DashboardV2Action.GetDateRange, state => {
		return {
			...state,
			dateRange: {
				startDate: new Date(new Date().setDate(new Date().getDate() - Number(state.dashboardRequestForm.controls.range.value))).toISOString(),
				endDate: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString()
			},
			dashboardRequestForm: setValue(state.dashboardRequestForm, {
				...state.dashboardRequestForm.value,
				range: state.dashboardRequestForm.controls.range.value,
				startDate: new Date(new Date().setDate(new Date().getDate() - Number(state.dashboardRequestForm.controls.range.value))).toISOString(),
				endDate: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString(),
				maxMinVar: state.dashboardRequestForm.controls.maxMinVar.value
			} as DashboardV2State.DashboardViewRequestForm)
		}
	}),

	on(DashboardV2Action.ViewPendingApprovalTaskSuccess, (state, { payload }) => {
		return {
			...state,
			pendingApprovalTask: payload
		}
	}),

	on(DashboardV2Action.GetComparisonDaysListSuccess, (state, { payload }) => {
		return {
			...state,
			durationOptionListWithoutCustom: payload.filter(o => o.key !== 'custom')
		}
	}),
	on(DashboardV2Action.GetRedemptionTypeListSuccess, (state, { payload }) => {
		return {
			...state,
			redemptionTypeList: payload
		}
	}),
	on(DashboardV2Action.GetPointsRedemptionAnalysis, state => ({
		...state,
		pointRedemptionAnalysisIsLoading: true
	})),
	on(DashboardV2Action.GetPointsRedemptionAnalysisSuccess, (state, { payload }) => {
		return {
			...state,
			pointRedemptionAnalysisIsLoading: false,
			pointsRedemptionAnalysisResponse: payload
		}
	}),
	on(DashboardV2Action.GetPointsRedemptionAnalysisForComparisonSuccess, (state, { payload }) => {
		return {
			...state,
			pointsRedemptionAnalysisForComparisonResponse: payload
		}
	}),

	on(DashboardV2Action.GetMaxMinVarList, state => ({
		...state,
		mccTransaction: {
			...state.mccTransaction
		}
	})),

	on(DashboardV2Action.GetMaxMinVarListSuccess, (state, { payload }) => {
		return {
			...state,
			mccTransaction: {
				...state.mccTransaction,
				maxMinVarList: payload
			}
		}
	}),

	on(DashboardV2Action.setMaxMin, (state, { maxMinVar }) => {
		return {
			...state,
			dashboardRequestForm: setValue(state.dashboardRequestForm, {
				...state.dashboardRequestForm.value,
				maxMinVar
			} as DashboardV2State.DashboardViewRequestForm)
		}
	}),

	on(DashboardV2Action.ViewMccTransactionListSuccess, (state, { payload }) => {
		return {
			...state,
			isLoading: false,
			mccTransaction: {
				...state.mccTransaction,
				mccTransactionList: payload
			}
		}
	}),

	on(DashboardV2Action.ViewRewardPoolAnalysis, state => ({
		...state,
		rewardPoolAnalysisIsLoading: true,
	})),

	on(DashboardV2Action.ViewRewardPoolGroupAnalysis, state => ({
		...state,
		rewardPoolAnalysisIsLoading: true
	})),

	on(DashboardV2Action.ViewRewardPoolAnalysisOptionList, state => ({
		...state,
		rewardPoolAnalysisIsLoading: true
	})),

	on(DashboardV2Action.ViewRewardPoolAnalysisRewardPoolList, state => ({
		...state,
		rewardPoolAnalysisIsLoading: true
	})),

	on(DashboardV2Action.ViewRewardPoolAnalysisRewardPoolGroupList, state => ({
		...state,
		rewardPoolAnalysisIsLoading: true
	})),

	on(DashboardV2Action.ViewRewardPoolAnalysisSuccess, (state, { payload }) => {
		return {
			...state,
			rewardPoolAnalysisIsLoading: false,
			rewardPoolAnalysis: payload
		}
	}),

	on(DashboardV2Action.ViewRewardPoolGroupAnalysisSuccess, (state, { payload }) => {
		return {
			...state,
			rewardPoolAnalysisIsLoading: false,
			rewardPoolGroupAnalysis: payload
		}
	}),

	on(DashboardV2Action.ViewRewardPoolAnalysisOptionListSuccess, (state, { payload }) => {
		return {
			...state,
			rewardPoolAnalysisOptionList: payload
		}
	}),

	on(DashboardV2Action.ViewRewardPoolAnalysisRewardPoolListSuccess, (state, { payload }) => {
		return {
			...state,
			rewardPoolAnalysisRewardPoolList: payload,
			pointAccumulationPerformanceForm: setValue(state.pointAccumulationPerformanceForm, {
				...state.pointAccumulationPerformanceForm.value,
				rewardPool: payload.length ? payload[0].key : '0'
			})
		}
	}),

	on(DashboardV2Action.ViewRewardPoolAnalysisRewardPoolGroupListSuccess, (state, { payload }) => {
		return {
			...state,
			rewardPoolAnalysisRewardPoolGroupList: payload
		}
	})

)

export function Reducer(state: DashboardV2State.State = DashboardV2State.initialState, action: Action) {
	const dashboardRequestForm = DashboardV2State.validateDashboardViewRequestForm(state)(formGroupReducer(state.dashboardRequestForm, action))
	const pointAccumulationPerformanceForm = DashboardV2State.validatePointAccumulationForm(state)(formGroupReducer(state.pointAccumulationPerformanceForm, action))

	if (dashboardRequestForm !== state.dashboardRequestForm) {
		state = { ...state, dashboardRequestForm }
	}

	if (pointAccumulationPerformanceForm !== state.pointAccumulationPerformanceForm) {
		state = { ...state, pointAccumulationPerformanceForm }
	}
	return reducer(state, action)
}

