<form novalidate [ngrxFormState]="formState$ | async" fxLayout="column">
  <div *ngIf="isView" fxLayout="column">
    <div fxLayout="row" fxLayoutGap="5px">
      <mat-form-field fxFlex="30">
        <input matInput value="{{getComparisonValue((formState$ | async).controls.equation.value)}}" type="text"
          maxlength="255" readonly>
      </mat-form-field>

      <mat-form-field fxFlex="70">
        <input matInput value="{{(formState$ | async).controls.value.value}}" type="text" maxlength="255" readonly>
      </mat-form-field>
    </div>
  </div>

  <div *ngIf="!isView" fxLayout="column">
    <div fxLayout="row" fxLayoutGap="5px">
      <mat-form-field fxFlex="30">
        <mat-select [ngrxFormControlState]="(formState$ | async).controls.equation">
          <mat-option *ngFor="let i of comparisonList" [value]="i.key">
            {{i.value}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field fxFlex="70">
        <input matInput [matDatepicker]="picker" [ngrxFormControlState]="(formState$ | async).controls.value"
          [ngrxValueConverter]="dateValueConverter" (click)="picker.open()" readonly required>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-error *ngIf="(formState$ | async).errors._value">
          <app-error-validation [errors]="(formState$ | async).errors._value">
          </app-error-validation>
        </mat-error>
      </mat-form-field>
    </div>
  </div>
</form>