import { Component, Inject, OnDestroy, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material'
import { Store } from '@ngrx/store'
import { Subscription } from 'rxjs'
import * as AppStore from 'src/app/store'
import { FieldDetailForm } from 'src/app/store/param-settings/field-mapping/field-mapping.state'
import * as FieldMappingSelectors from 'src/app/store/param-settings/field-mapping/field-mapping.selectors'
import * as FieldMappingAction from 'src/app/store/param-settings/field-mapping/field-mapping.actions'
import { FieldMappingDialogUpdateParam } from 'src/app/models/param-settings/field-mapping/field-mapping-update'
import { AbstractControl, FormControl, Validators } from '@angular/forms'
export interface DialogData {
	id: number
	label: string
	value: string
}

function checkValidCharacters() {
	return(control: AbstractControl): { [key: string]: boolean } | null => {
		const isValid = control.value === null || control.value === '' || control.value.match(new RegExp(/^[\x20-\x7E]+$/g))

		return isValid ? null : { containInvalidCharacters: true }
	}
}
@Component({
	selector: 'app-field-mapping-edit',
	templateUrl: './field-mapping-edit.component.html',
	styleUrls: ['./field-mapping-edit.component.scss']
})

export class FieldMappingEditComponent implements OnInit, OnDestroy {

	subs: Subscription
	fieldDetailFormView: FieldDetailForm
	parameters: FieldMappingDialogUpdateParam
	formState$: FormControl

	constructor(
		private store: Store<AppStore.State>,
		private dialogRef: MatDialogRef<FieldMappingEditComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		this.subs = this.store.select(FieldMappingSelectors.getFieldMapping).subscribe(x => {
			this.fieldDetailFormView = x.fieldDetailFormView
		})

		this.parameters = {
			id: data.id,
			label: data.label,
			value: data.value
		}

		this.formState$ = new FormControl(this.parameters.value, [checkValidCharacters(), Validators.maxLength(40)])
	}

	ngOnInit() { }

	ngOnDestroy() {
		this.subs.unsubscribe()
	}

	cancel() {
		this.dialogRef.close()
	}

	save() {
		this.store.dispatch(FieldMappingAction.UpdateValue({id: this.parameters.id, value: this.formState$.value}))
	}

	isInvalidOrEqualToOldValue() {
		return this.formState$.invalid || (this.formState$.value === this.fieldDetailFormView.fieldValue)
	}
}
