import { KeyValue } from '@angular/common'
import {box, Boxed, createFormGroupState, FormGroupState, unbox, updateGroup, validate} from 'ngrx-forms'
import { maxLength, required } from 'ngrx-forms/validation'
import { Option } from 'src/app/models/option/option'
import { RedeemRules } from 'src/app/models/redemption-settings/redeem-rules/redeem-rules'
import { RedeemRulesDataResponse } from 'src/app/models/redemption-settings/redeem-rules/redeem-rules-data'
import { RedeemRulesViewResponse } from 'src/app/models/redemption-settings/redeem-rules/redeem-rules-view'
import { FieldValidator } from 'src/app/models/util/field.validator'
import {InformationDetailForm, Rule} from '../../campaign-management/campaign'

export interface State {
	isLoading: boolean
	id?: number
	filteredRulesKey?: string[]

	// Listing
	redeemRulesList: RedeemRules[]
	// View
	redeemRulesViewResponse: RedeemRulesViewResponse
	// Detail
	redeemRulesForm: FormGroupState<RedeemRuleForm>
	redeemRulesDetailForm: FormGroupState<RedeemRulesDetail>
	// option list
	redeemRulesData: RedeemRulesDataResponse

	redeemRulesView: RedeemRuleForm

	ruleList: Rule[]

	selectedRule: Rule

	selectedRuleList: Rule[]

	rewardPoolGroupRewardPoolList: Option[]
}

export interface RPGRule {
	rules: string[]
	keyValue: KeyValue<string, string>[]
	form: FormGroupState<RedeemRulesDetail>
}

export const initialRedeemRulesViewResponse: RedeemRulesViewResponse = {
	id: 0,
	code: '',
	name: '',
	rewardPoolType: '',
	rewardPool: '',
	cardholderTypes: '',
	channel: '',
	createdBy: '',
	createdDate: '',
	modifiedBy: '',
	modifiedDate: '',
	deletedBy: '',
	deletedDate: '',
	redeemRuleDetail: []
}

export interface RedeemRuleForm {
	code: string
	name: string
	rewardPoolType: string
	channel: string
	cardholderTypes: Boxed<string[]>
	rules: SelectedRules
	rewardPool: Boxed<string[]>
}

export interface SelectedRules {
	[key: string]: RPGRule
}

export interface RedeemRulesDetail {
	rewardPoolId: number
	customerBlockCodeFlag: string
	customerBlockCode: string
	customerStatusFlag: string
	customerStatus: string
	accountBlockCodeFlag: string
	accountBlockCode: string
	accountStatusFlag: string
	accountStatus: string
	cardBlockCodeFlag: string
	cardBlockCode: string
	cardStatusFlag: string
	cardStatus: string
}

export const initialRedeemRulesFormValue: RedeemRuleForm = {
	code: '',
	name: '',
	rewardPoolType: '',
	channel: '',
	cardholderTypes: box([]),
	rules: {},
	rewardPool:  box([]),
}

export const initialRedeemRulesDetailFormValue: RedeemRulesDetail = {
	rewardPoolId: 0,
	customerBlockCodeFlag: '',
	customerBlockCode: '',
	customerStatusFlag: '',
	customerStatus: '',
	accountBlockCodeFlag: '',
	accountBlockCode: '',
	accountStatusFlag: '',
	accountStatus: '',
	cardBlockCodeFlag: '',
	cardBlockCode: '',
	cardStatusFlag: '',
	cardStatus: ''
}

export const initialRedeemRulesForm = createFormGroupState('redeemRulesForm', initialRedeemRulesFormValue)
export const initialRedeemRulesDetailForm = createFormGroupState('redeemRulesDetailForm', initialRedeemRulesDetailFormValue)

export const initialRPGRule: RPGRule = {
	rules: [],
	keyValue: [],
	form: initialRedeemRulesDetailForm
}

export const validateRedeemRulesForm = (state: State) => {
	const channelCondition = state.redeemRulesForm.value.channel === 'Card'

	return updateGroup<RedeemRuleForm>({
		code: validate([required, FieldValidator.alphaNumericWithoutSpaces(), value => maxLength(10)(value)]),
		name: validate([required, maxLength(40), FieldValidator.checkValidCharacters()]),
		rewardPool: validate([value => required(unbox(value))]),
		rules: validate([value => FieldValidator.requiredIfTrue(unbox(value).toString() !== '')(Object.keys(value).toString())]),
		cardholderTypes: validate([value => FieldValidator.requiredIfTrue(channelCondition)(unbox(value).toString())])
	})
}

export const validateRedeemRulesDetailForm = (state: FormGroupState<RedeemRulesDetail>) => updateGroup<RedeemRulesDetail>({
		customerBlockCodeFlag: validate([FieldValidator.requiredIf(state.value.customerBlockCode)]),
		customerBlockCode: validate([FieldValidator.requiredIf(state.value.customerBlockCodeFlag),
			FieldValidator.checkAlphaNumeric(2, false, false, true), maxLength(255)]),
		customerStatusFlag: validate([FieldValidator.requiredIf(state.value.customerStatus)]),
		customerStatus: validate([FieldValidator.requiredIf(state.value.customerStatusFlag),
			FieldValidator.checkAlphaNumeric(2, false, false, true), maxLength(255)]),
		accountBlockCodeFlag: validate([FieldValidator.requiredIf(state.value.accountBlockCode)]),
		accountBlockCode: validate([FieldValidator.requiredIf(state.value.accountBlockCodeFlag),
			FieldValidator.checkAlphaNumeric(2, false, false, true), maxLength(255)]),
		accountStatusFlag: validate([FieldValidator.requiredIf(state.value.accountStatus)]),
		accountStatus: validate([FieldValidator.requiredIf(state.value.accountStatusFlag),
			FieldValidator.checkAlphaNumeric(2, false, false, true), maxLength(255)]),
		cardBlockCodeFlag: validate([FieldValidator.requiredIf(state.value.cardBlockCode)]),
		cardBlockCode: validate([FieldValidator.requiredIf(state.value.cardBlockCodeFlag),
			FieldValidator.checkAlphaNumeric(2, false, false, true), maxLength(255)]),
		cardStatusFlag: validate([FieldValidator.requiredIf(state.value.cardStatus)]),
		cardStatus: validate([FieldValidator.requiredIf(state.value.cardStatusFlag),
			FieldValidator.checkAlphaNumeric(2, false, false, true), maxLength(255)])
})

export const initialState: State = {
	isLoading: false,
	redeemRulesList: [],
	redeemRulesViewResponse: initialRedeemRulesViewResponse,
	redeemRulesForm: initialRedeemRulesForm,
	redeemRulesDetailForm: initialRedeemRulesDetailForm,
	redeemRulesData: {
		rewardPoolList: [],
		rewardPoolGroupList: [],
		cardHolderList: [],
		includeExcludeList: []
	},
	redeemRulesView: initialRedeemRulesFormValue,
	ruleList: [
		{keyValue: {key: 'ACCOUNT_BLOCK_CODE', value: 'Account Block Code'}, resourceCode: 'CARDLINK'},
		{keyValue: {key: 'ACCOUNT_STATUS', value: 'Account Status'}, resourceCode: 'CARDLINK'},
		{keyValue: {key: 'CARD_BLOCK_CODE', value: 'Card Block Code'}, resourceCode: 'CARDLINK'},
		{keyValue: {key: 'CARD_STATUS', value: 'Card Status'}, resourceCode: 'CARDLINK'},
		{keyValue: {key: 'CUSTOMER_BLOCK_CODE', value: 'Customer Block Code'}, resourceCode: 'BANKWIDE'},
		{keyValue: {key: 'CUSTOMER_STATUS', value: 'Customer Status'}, resourceCode: 'BANKWIDE'}
	],
	selectedRule: {keyValue: {key: '', value: ''}},
	selectedRuleList: [],
	rewardPoolGroupRewardPoolList: [],
}
