import { Injectable } from '@angular/core'
import { MatSnackBar, MatSnackBarConfig, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material'

@Injectable({
	providedIn: 'root'
})
export class SnackbarService {

	actionButtonLabel = 'Ok'
	action = false
	setAutoHide = true
	autoHide = 5000
	horizontalPosition: MatSnackBarHorizontalPosition = 'center'
	verticalPosition: MatSnackBarVerticalPosition = 'top'
	addExtraClass = false

	constructor(
		public snackBar: MatSnackBar
	) { }

	public open(message: string): void {
		const config = new MatSnackBarConfig()
		config.verticalPosition = this.verticalPosition
		config.horizontalPosition = this.horizontalPosition
		config.duration = this.setAutoHide ? this.autoHide : 0
		config.panelClass = this.addExtraClass ? ['snack-bar'] : undefined

		const snackBar = this.snackBar.open(message, this.action ? this.actionButtonLabel : undefined, config)
		snackBar.onAction().subscribe(() => { snackBar.dismiss() })
	}
}
