<span *ngIf="title" mat-dialog-title>{{ title | translate }}</span>

<mat-dialog-content *ngIf="content">
  <app-spinner *ngIf="tableIsLoading" [spinnerType]="'COMPONENT'" [isLoading]="tableIsLoading"></app-spinner>
  <p class="global-dialog-box-content">{{ content | translate: contentParam }}</p>
  <div class="table-container-without-hover" fxLayout="column" *ngIf="table">
    <table mat-table [dataSource]="dataSource" matSort matSortDisabled >
      <div *ngIf="table">
        <ng-container matColumnDef="payload">
          <th mat-header-cell *matHeaderCellDef>{{ tableColumnName | translate }}</th>
          <td mat-cell *matCellDef="let element">{{ element.payload }}</td>
        </ng-container>
      </div>

      <ng-container matColumnDef="success">
        <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
        <td mat-cell *matCellDef="let element">
          <mat-icon *ngIf="element.success === true" class="material-icons-outlined" style="color:#087f23;float:right">
            check_circle_outline
          </mat-icon>
          <mat-icon *ngIf="element.success === false" class="material-icons-outlined" matTooltip="{{ element.error }}"
            matTooltipPosition="left" style="color:#E53935;float:right">
            report_problem</mat-icon>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <div class="table-no-data" *ngIf="dataSource.filteredData.length === 0"> {{'ERROR.TABLE_NO_RECORD' | translate}} </div>
  </div>
</mat-dialog-content>

<div fxLayout="row" fxLayoutAlign="end">
  <div *ngIf="customVariable.showNo">
      <button class="mat-raised-button" (click)="cancel()">{{ cancelBtn | translate }}</button>
  </div>
  <div>
      <button class="mat-raised-button mat-primary" (click)="submit()"
        fxFlexOffset="8px">{{ submitBtn | translate }}</button>
  </div>
</div>