import { Action, createReducer, on } from '@ngrx/store'
import * as FulfilmentPartnerAction from './fulfilment-partner.actions'
import * as FulfilmentPartnerState from './fulfilment-partner.state'
import { formGroupReducer, setValue, reset } from 'ngrx-forms'

export const reducer = createReducer(
	FulfilmentPartnerState.initialState,
	on(FulfilmentPartnerAction.InitialState, () => FulfilmentPartnerState.initialState),
	on(FulfilmentPartnerAction.SetId, (state, { id }) => ({
		...state,
		id
	})),
	on(FulfilmentPartnerAction.RestError, state => ({
		...state,
		isLoading: false,
		isDuplicateLoading: false,
		isDuplicateDialog: false
	})),
	on(FulfilmentPartnerAction.GoCreate, state => ({
		...state,
		fulfilmentPartnerDetailForm: reset(setValue(state.fulfilmentPartnerDetailForm, FulfilmentPartnerState.initialFulfilmentPartnerDetailFormValue))
	})),
	on(FulfilmentPartnerAction.FileFrequencyList, state => ({
		...state,
		isLoading: true
	})),
	on(FulfilmentPartnerAction.FileFrequencyListSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		fileFrequencyList: payload.fileFrequencyList
	})),
	on(FulfilmentPartnerAction.List, state => ({
		...state,
		isLoading: true
	})),
	on(FulfilmentPartnerAction.ListSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		fulfilmentPartnerListResponse: payload
	})),
	on(FulfilmentPartnerAction.View, state => ({
		...state,
		isLoading: true,
		fulfilmentPartnerDetailForm: setValue(state.fulfilmentPartnerDetailForm, FulfilmentPartnerState.initialFulfilmentPartnerDetailFormValue)
	})),
	on(FulfilmentPartnerAction.ViewSuccess, (state, { payload }) => {
		const fulfilmentPartnerDetailFormView = {
			code: payload.code,
			name: payload.name,
			fileFrequency: payload.fileFrequency,
			createdBy: payload.createdBy,
			createdDate: payload.createdDate,
			modifiedBy: payload.modifiedBy,
			modifiedDate: payload.modifiedDate,
			allowDelete: payload.allowDelete
		}
		return ({
			...state,
			isLoading: false,
			id: payload.id,
			fulfilmentPartnerDetailForm: FulfilmentPartnerState.validateFulfilmentPartnerDetailForm(setValue(state.fulfilmentPartnerDetailForm, fulfilmentPartnerDetailFormView)),
			fulfilmentPartnerDetailFormView,
		})
	}),
	on(FulfilmentPartnerAction.Create, state => ({
		...state,
		isLoading: true
	})),
	on(FulfilmentPartnerAction.CreateSuccess, (state, { payload }) => ({
		...FulfilmentPartnerState.initialState
	})),
	on(FulfilmentPartnerAction.Update, state => ({
		...state,
		isLoading: true
	})),
	on(FulfilmentPartnerAction.UpdateSuccess, (state, { payload }) => ({
		...state
	})),
	on(FulfilmentPartnerAction.Delete, state => ({
		...state,
		isLoading: true
	})),
	on(FulfilmentPartnerAction.DeleteSuccess, () => FulfilmentPartnerState.initialState),
	on(FulfilmentPartnerAction.DuplicateDialog, state => ({
		...state,
		fulfilmentPartnerDuplicateForm: reset(setValue(state.fulfilmentPartnerDuplicateForm, FulfilmentPartnerState.initialFulfilmentPartnerDuplicateFormValue)),
		isDuplicateDialog: false
	})),
	on(FulfilmentPartnerAction.Duplicate, state => ({
		...state,
		isDuplicateLoading: true
	})),
	on(FulfilmentPartnerAction.DuplicateSuccess, state => ({
		...state,
		isDuplicateLoading: false,
		isDuplicateDialog: true
	}))
)

export function Reducer(state: FulfilmentPartnerState.State = FulfilmentPartnerState.initialState, action: Action) {
	const fulfilmentPartnerDetailForm = FulfilmentPartnerState.validateFulfilmentPartnerDetailForm(formGroupReducer(state.fulfilmentPartnerDetailForm, action))
	if (fulfilmentPartnerDetailForm !== state.fulfilmentPartnerDetailForm) {
		state = { ...state, fulfilmentPartnerDetailForm }
	}

	const fulfilmentPartnerDuplicateForm = FulfilmentPartnerState.validateFulfilmentPartnerDuplicateForm(formGroupReducer(state.fulfilmentPartnerDuplicateForm, action))
	if (fulfilmentPartnerDuplicateForm !== state.fulfilmentPartnerDuplicateForm) {
		state = {...state, fulfilmentPartnerDuplicateForm}
	}
	return reducer(state, action)
}
