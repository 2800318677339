import { Component, Inject, OnDestroy } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material'
import { Store } from '@ngrx/store'
import { FormGroupState } from 'ngrx-forms'
import { Observable, Subscription } from 'rxjs'
import * as AppStore from 'src/app/store'
import { GlProvisionDuplicateForm } from 'src/app/store/param-settings/gl-provision/gl-provision.state'
import * as GlProvisionAction from 'src/app/store/param-settings/gl-provision/gl-provision.actions'
import * as GlProvisionSelectors from 'src/app/store/param-settings/gl-provision/gl-provision.selectors'

@Component({
	selector: 'app-gl-provision-duplicate',
	templateUrl: './gl-provision-duplicate.component.html',
	styleUrls: ['./gl-provision-duplicate.component.scss']
})
export class GlProvisionDuplicateComponent implements OnDestroy {

	isLoading = false
	formState$: Observable<FormGroupState<GlProvisionDuplicateForm>>

	subs: Subscription

	constructor(
		private store: Store<AppStore.State>,
		private dialogRef: MatDialogRef<GlProvisionDuplicateComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		this.subs = this.store.select(GlProvisionSelectors.getGlProvision).subscribe(({ isDuplicateLoading, isDuplicateDialog }) => {
			this.isLoading = isDuplicateLoading

			if (isDuplicateDialog) {
				this.cancel()
			}
		})

		this.formState$ = this.store.select(({ glProvision }) => glProvision.glProvisionDuplicateForm)
	}

	ngOnDestroy() {
		this.subs.unsubscribe()
	}

	cancel() {
		this.dialogRef.close()
	}

	submit() {
		this.store.dispatch(GlProvisionAction.Duplicate())
	}
}
