import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'
import { FormGroupState } from 'ngrx-forms'
import { Observable, ReplaySubject, Subscription } from 'rxjs'
import { GlRedemption } from 'src/app/models/param-settings/gl-redemption/gl-redemption'
import * as AppStore from 'src/app/store'
import * as OnTheSpotRedemptionSelectors from 'src/app/store/redemption-settings/on-the-spot-redemption/on-the-spot-redemption.selectors'
import { OTSRewardPool, OTSRewardPoolForm, OTSRewardPools } from 'src/app/store/redemption-settings/on-the-spot-redemption/on-the-spot-redemption.state'
import { FormControl } from '@angular/forms'
import { FilterPredicate } from 'src/app/models/util/filter-predicate'

@Component({
	selector: 'app-on-the-spot-redemption-reward-pool',
	templateUrl: './on-the-spot-redemption-reward-pool.component.html',
	styleUrls: ['./on-the-spot-redemption-reward-pool.component.scss']
})
export class OnTheSpotRedemptionRewardPoolComponent implements OnInit, OnDestroy {

	@Input() otsRewardPool: OTSRewardPool

	action: string
	formState$: FormGroupState<OTSRewardPoolForm>
	glRedemptionViewList: GlRedemption[] = []
	subs: Subscription
	glRedemptionList: GlRedemption[] = []
	selectedValue: any = ''

	public filteredGlRedemption: ReplaySubject<GlRedemption[]> = new ReplaySubject<GlRedemption[]>(1)

	public dropDownCtrlGlRedemptionGroup: FormControl = new FormControl(this.selectedValue)

	constructor(
		private store: Store<AppStore.State>
	) {}

	get isView(): boolean { return this.action === 'VIEW' }

	ngOnInit() {
		this.subs = this.store.select(OnTheSpotRedemptionSelectors.getOnTheSpotRedemption).subscribe(x => {
			this.action = x.action
			this.glRedemptionViewList = x.glRedemptionList
			const glRedemptionList = x.glRedemptionList

			if (glRedemptionList) {
				this.glRedemptionList = glRedemptionList
			}

			this.filteredGlRedemption.next(this.glRedemptionList.slice())

			const otsRewardPools = x.onTheSpotRedemptionDetailForm.controls.otsRewardPools.value

			const form: OTSRewardPools = {
				byId: {
					...otsRewardPools.byId
				},
				allIds: {
					...otsRewardPools.allIds
				}
			}
			if (this.otsRewardPool != null) {
				this.formState$ = form && form.byId[this.otsRewardPool.id] && form.byId[this.otsRewardPool.id].form
			}
		})

		this.dropDownCtrlGlRedemptionGroup.valueChanges
			.subscribe(value => {
				const maxChar = 255
				if (value.length > maxChar) {
					const newValue = value.slice(0, maxChar)
					this.dropDownCtrlGlRedemptionGroup.reset('', { emitEvent: false })
					this.dropDownCtrlGlRedemptionGroup.setValue(newValue)

				} else {
					this.searchGlRedemption(value)
				}
			})

	}

	ngOnDestroy() {
		this.subs.unsubscribe()
	}

	getGlRedemption(value: number): string {
		const result = this.glRedemptionViewList.find(x => x.id === value)
		return result ? (result.code + ' - ' + result.name) : ''
	}

	searchGlRedemption(search: string) {
		if (!this.glRedemptionList) {
			return
		}
		if (!search) {
			this.filteredGlRedemption.next(this.glRedemptionList.slice())
			return
		} else {
			search = search.toLowerCase()
		}
		// filter the records
		this.filteredGlRedemption.next(
			this.glRedemptionList.filter(glRedemption => FilterPredicate.isIdenticalTo(search)([glRedemption.code.toLowerCase() + ' - ' + glRedemption.name.toLowerCase()]))
		)
	}
	clearSearchGlRedemption() {
		this.filteredGlRedemption.next(this.glRedemptionList.slice())
	}
}
