import { Component, OnInit, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material'
import { Store } from '@ngrx/store'
import * as AppStore from 'src/app/store'
import { Subscription, Observable } from 'rxjs'
import * as CustomerManagementSelector from 'src/app/store/customer-management/customer-management.selectors'
import { CustomerPartnerRedemptionOrderViewResponse } from 'src/app/models/customer-management/customer-partner-redemption-order'

@Component({
	selector: 'app-customer-management-cataloguepartner--redemption-view',
	templateUrl: './customer-management-catalogue-partner-redemption-view.component.html',
	styleUrls: ['./customer-management-catalogue-partner-redemption-view.component.scss']
})
export class CustomerManagementCataloguePartnerRedemptionViewComponent implements OnInit {
	redemptionType: string
	formState$: Observable<CustomerPartnerRedemptionOrderViewResponse>
	subs: Subscription
	constructor(
		private store: Store<AppStore.State>,
		private dialogRef: MatDialogRef<CustomerManagementCataloguePartnerRedemptionViewComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any) {

		this.subs = this.store.select(CustomerManagementSelector.getCustomerManagement).subscribe(x => {
			this.redemptionType = x.redemptionType
		})

		this.formState$ = this.store.select(({ customerManagement }) => customerManagement.customerPartnerRedemptionOrderViewResponse)
	}

	ngOnInit() {
	}

	onNoClick(): void {
		this.dialogRef.close()
	}

}
