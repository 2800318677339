import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Action, Store } from '@ngrx/store'
import { Observable, of } from 'rxjs'
import { catchError, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators'
import { Response } from 'src/app/models/common/http'
import { CustomerListResponse } from 'src/app/models/customer-management/customer-list'
import { CustomerManagementService } from 'src/app/services/customer-management/customer-management.service'
import * as AppStore from 'src/app/store/'
import * as CommonAction from '../common/common/common.actions'
import * as CustomerManagementAction from './customer-management.actions'
import { CustomerViewResponse } from 'src/app/models/customer-management/customer-detail'
import {
	CustomerCardViewResponse,
	CustomerCardViewTransactionResponse
} from 'src/app/models/customer-management/customer-card-detail'
import {
	AccumulatingTransactionListData,
	CustomerAccountViewResponse,
	CustomerAccountViewTransactionDetailResponse,
	CustomerAccountViewTransactionListResponse,
	PointBreakdownListData
} from 'src/app/models/customer-management/customer-account-detail'
import { MatDialog } from '@angular/material'
import { CustomerManagementAccountTransactionViewComponent } from 'src/app/components/customer-management/customer-management-account-transaction-view/customer-management-account-transaction-view.component'
import {
	CustomerRewardPoolViewResponse,
	RewardPoolInformationList
} from 'src/app/models/customer-management/customer-reward-pool-detail'
import { SelectDialogComponent } from 'src/app/components/common/dialog/select-dialog/select-dialog.component'
import { getDialogConfig } from 'src/app/models/common/dialog'
import {
	CardProductCatalogueListResponse,
	CustomerAddressListResponse,
	CustomerCardListResponse
} from 'src/app/models/customer-management/catalogue-redemption'
import { CustomerStatementTransactionListResponse } from 'src/app/models/customer-management/customer-statement-transaction-detail'
import { WaiverListDataResponse, WaiverTypeListDataResponse } from 'src/app/models/customer-management/waiver'
import { CustomerManagementWaiverComponent } from 'src/app/components/customer-management/customer-management-waiver/customer-management-waiver.component'
import { CustomerManagementWaiverConfirmationComponent } from 'src/app/components/customer-management/customer-management-waiver-confirmation/customer-management-waiver-confirmation.component'
import { AccumulationBucketList } from 'src/app/models/customer-management/customer-accumulation-bucket-detail'
import { CustomerRedemptionHistoryListResponse } from 'src/app/models/customer-management/customer-redemption-history'
import { CustomerCatalogueRedemptionOrderViewResponse } from 'src/app/models/customer-management/customer-catalogue-redemption-order'
import { CustomerManagementCatalogueRedemptionDialogViewComponent } from 'src/app/components/customer-management/customer-management-catalogue-redemption-dialog-view/customer-management-catalogue-redemption-dialog-view.component'
import { CustomerPartnerRedemptionListResponse } from 'src/app/models/customer-management/customer-partner-redemption'
import { CustomerManagementPartnerRedemptionComponent } from 'src/app/components/customer-management/customer-management-partner-redemption/customer-management-partner-redemption.component'
import { CustomerManagementPartnerRedemptionConfirmationComponent } from 'src/app/components/customer-management/customer-management-partner-redemption-confirmation/customer-management-partner-redemption-confirmation.component'
import { CustomerManagementStatementTransactionListViewComponent } from 'src/app/components/customer-management/customer-management-statement-transaction-list-view/customer-management-statement-transaction-list-view.component'
import { Option } from 'src/app/models/option/option'
import { Util } from 'src/app/models/util/util'
import { MessageDialogComponent } from 'src/app/components/common/dialog/message-dialog/message-dialog.component'
import { PointsAdjustmentService } from 'src/app/services/points-adjustment/points-adjustment.service'
import {
	AdjustmentTypeListResponse,
	PointsAdjustmentDetails,
	RewardPoolListResponse
} from 'src/app/models/customer-management/customer-points-adjustment'
import { ConditionalRewardViewResponse } from 'src/app/models/customer-management/customer-conditional-reward'
import { CustomerPartnerRedemptionOrderViewResponse } from 'src/app/models/customer-management/customer-partner-redemption-order'
import { CustomerManagementCataloguePartnerRedemptionViewComponent } from 'src/app/components/customer-management/customer-management-catalogue-partner-redemption-view/customer-management-catalogue-partner-redemption-view.component'
import { CustomerManagementCatalogueRedemptionConfirmationDialogComponent } from 'src/app/components/customer-management/customer-management-catalogue-redemption-confirmation-dialog/customer-management-catalogue-redemption-confirmation-dialog.component'
import { PointTransactionBreakdownTableComponent } from 'src/app/components/customer-management/components/point-transaction-breakdown-table/point-transaction-breakdown-table.component'
import { ParameterSettingsService } from 'src/app/services/param-settings/parameter-settings.service'

@Injectable()
export class Effects {

	constructor(
		private action$: Actions,
		private router: Router,
		private dialog: MatDialog,
		private customerManagementService: CustomerManagementService,
		private pointsAdjustmentService: PointsAdjustmentService,
		private parameterSettingsService: ParameterSettingsService,
		private store: Store<AppStore.State>
	) { }

	InitialState$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CustomerManagementAction.InitialState),
			tap(() => {
				this.router.navigate(['customer-management'])
			})
		), { dispatch: false }
	)

	GoList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CustomerManagementAction.GoList),
			tap(() => {
				this.router.navigate(['customer-management'])
			})
		), { dispatch: false }
	)

	GoView$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CustomerManagementAction.GoView),
			tap(() => {
				this.router.navigate(['customer-management/view'])
			})
		), { dispatch: false }
	)

	GoCardView$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CustomerManagementAction.GoCardView),
			tap(() => {
				this.router.navigate(['customer-management/view/card'])
			})
		), { dispatch: false }
	)

	GoAccountView$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CustomerManagementAction.GoAccountView),
			tap(() => {
				this.router.navigate(['customer-management/view/account'])
			})
		), { dispatch: false }
	)

	GoRewardPoolView$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CustomerManagementAction.GoRewardPoolView),
			tap(() => {
				this.router.navigate(['customer-management/view/reward-pool'])
			})
		), { dispatch: false }
	)

	GoRewardPoolGroupView$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CustomerManagementAction.GoRewardPoolGroupView),
			tap(() => {
				this.router.navigate(['customer-management/view/reward-pool-group'])
			})
		), { dispatch: false }
	)

	GoConditionalRewardView$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CustomerManagementAction.GoConditionalRewardView),
			tap(() => {
				this.router.navigate(['customer-management/view/conditional-reward'])
			})
		), { dispatch: false }
	)

	GoRewardPoolAccumulationBucket$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CustomerManagementAction.GoRewardPoolAccumulationBucket),
			tap(() => {
				this.router.navigate(['customer-management/view/rewardPool/accumulation-buckets'])
			})
		), { dispatch: false }
	)

	GoAccountAccumulationBucket$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CustomerManagementAction.GoAccountAccumulationBucket),
			tap(() => {
				this.router.navigate(['customer-management/view/account/accumulation-buckets'])
			})
		), { dispatch: false }
	)
	GoCardStatementView$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CustomerManagementAction.GoCardStatementView),
			tap(() => {
				this.router.navigate(['customer-management/view/card/statements'])
			})
		), { dispatch: false }
	)

	GoAccountStatementView$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CustomerManagementAction.GoAccountStatementView),
			tap(() => {
				this.router.navigate(['customer-management/view/account/statements'])
			})
		), { dispatch: false }
	)

	GoCardStatementDetailView$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CustomerManagementAction.GoCardStatementDetailView),
			tap(() => {
				this.router.navigate(['customer-management/view/card/statements/details'])
			})
		), { dispatch: false }
	)

	GoAccountStatementDetailView$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CustomerManagementAction.GoAccountStatementDetailView),
			tap(() => {
				this.router.navigate(['customer-management/view/account/statements/details'])
			})
		), { dispatch: false }
	)

	GoRewardPoolStatementView$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CustomerManagementAction.GoRewardPoolStatementView),
			tap(() => {
				this.router.navigate(['customer-management/view/reward-pool/statements'])
			})
		), { dispatch: false }
	)

	GoRedemptionHistoryView$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CustomerManagementAction.GoRedemptionHistoryView),
			tap(() => {
				this.router.navigate(['customer-management/view/redemption-history'])
			})
		), { dispatch: false }
	)

	GoPointsAdjustment$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CustomerManagementAction.GoPointsAdjustment),
			tap(() => {
				this.router.navigate(['customer-management/view/points-adjustment'])
			})
		), { dispatch: false }
	)

	GoPointsAdjustmentView$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CustomerManagementAction.GoPointsAdjustmentView),
			tap(() => {
				this.router.navigate(['customer-management/view/points-adjustment/details'])
			})
		), { dispatch: false }
	)

	GoCatalogueRedemptionDetail$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CustomerManagementAction.GoCatalogueRedemptionDetail),
			tap(() => {
				this.router.navigate(['customer-management/view/catalogue-redemption/details'])
			})
		), { dispatch: false }
	)

	GoCatalogueRedemptionGridList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CustomerManagementAction.GoCatalogueRedemptionGridList),
			tap(() => {
				this.router.navigate(['customer-management/view/catalogue-redemption'])
			})
		), { dispatch: false }
	)

	GoCatalogueRedemptionMyRequestView$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CustomerManagementAction.GoCatalogueRedemptionMyRequestView),
			tap(() => {
				this.router.navigate(['worklist/my-request/view/catalogue-redemption'])
			})
		), { dispatch: false }
	)

	GoCatalogueRedemptionTasklistView$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CustomerManagementAction.GoCatalogueRedemptionTasklistView),
			tap(() => {
				this.router.navigate(['worklist/tasklist/view/catalogue-redemption'])
			})
		), { dispatch: false }
	)

	GoTransactionView$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CustomerManagementAction.GoTransactionView),
			tap(() => {
				this.router.navigate(['customer-management/view/accounts/accumulating-transactions'])
			})
		), { dispatch: false }
	)

	RestError$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CustomerManagementAction.RestError),
			map(({ message, manualThrow }) => {
				return CommonAction.RestError({ message, manualThrow })
			})
		)
	)

	CloseDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CustomerManagementAction.CloseDialog),
			tap(() => {
				this.dialog.closeAll()
			})
		), { dispatch: false }
	)

	List$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CustomerManagementAction.List),
			withLatestFrom(this.store.select(state => state.customerManagement)),
			mergeMap(([x, customerManagement]) => {
				const form = customerManagement.customerManagementListForm.controls
				return this.customerManagementService.getCustomerList({
					icNumber: form.customerId.value,
					customerName: form.name.value,
					cardNumber: form.cardNumber.value,
					accountNumber: form.accountNumber.value,
					pageNumber: 0,
					pageSize: 5
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: CustomerListResponse = response.payload
					if (success) {
						return CustomerManagementAction.ListSuccess({ payload })
					} else { return CustomerManagementAction.RestError(response as any) }
				}), catchError(this.catcher))
			})
		)
	)

	ListPage$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CustomerManagementAction.ListPage),
			withLatestFrom(this.store.select(state => state.customerManagement)),
			mergeMap(([x, customerManagement]) => {
				const form = customerManagement.customerManagementListForm.controls
				return this.customerManagementService.getCustomerListPage({
					icNumber: customerManagement.searchIcNumber,
					customerName: customerManagement.searchCustomerName,
					cardNumber: customerManagement.searchCardNumber,
					accountNumber: customerManagement.searchAccountNumber,
					pageNumber: x.pageNumber,
					pageSize: x.pageSize
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: CustomerListResponse = response.payload
					if (success) {
						return CustomerManagementAction.ListSuccess({ payload })
					} else { return CustomerManagementAction.RestError(response as any) }
				}), catchError(this.catcher))
			})
		)
	)

	View$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CustomerManagementAction.View),
			withLatestFrom(this.store.select(state => state.customerManagement)),
			mergeMap(([, customerManagement]) => this.customerManagementService.viewCustomer({ id: customerManagement.id }).pipe(
				map((response: Response) => {
					const success: boolean = response.success
					const payload: CustomerViewResponse = response.payload

					if (success) {
						return CustomerManagementAction.ViewSuccess({ payload })
					} else { return CustomerManagementAction.RestError(response as any) }

				}), catchError(this.catcher)
			))
		)
	)

	CardView$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CustomerManagementAction.CardView),
			withLatestFrom(this.store.select(state => state.customerManagement)),
			mergeMap(([, customerManagement]) => this.customerManagementService.viewCardOfCustomer({ cardNumber: customerManagement.cardNumber }).pipe(
				map((response: Response) => {
					const success: boolean = response.success
					const payload: CustomerCardViewResponse = response.payload

					if (success) {
						return CustomerManagementAction.CardViewSuccess({ payload })
					} else { return CustomerManagementAction.RestError(response as any) }

				}), catchError(this.catcher)
			))
		)
	)

	CardViewSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CustomerManagementAction.CardViewSuccess),
			withLatestFrom(this.store.select(state => state.customerManagement)),
			map(([params, ]) => {
				const today = new Date()
				const endDate = Util.dateFormat(today.getDate(), today.getMonth(), today.getFullYear())
				return CustomerManagementAction.CardTransactionList({
					accountNumber: params.payload.accountNumber,
					cardNumber: params.payload.cardNumber,
					relationship: params.payload.primaryOrSupplementaryFlag.key,
					pageNumber: 0,
					pageSize: 5,
					startDate: params.payload.previousStartDate,
					endDate
				})
			})
		)
	)

	GetCardRewardPoolOptionList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CustomerManagementAction.CardViewSuccess),
			withLatestFrom(this.store.select(state => state.customerManagement)),
			map(() => {
				return CustomerManagementAction.GetCardStatementTransactionOptionList()
			})
		)
	)

	GetAccountRewardPoolOptionList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CustomerManagementAction.AccountViewSuccess),
			withLatestFrom(this.store.select(state => state.customerManagement)),
			map(() => {
				return CustomerManagementAction.GetCardStatementTransactionOptionList()
			})
		)
	)

	CardTransactionList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CustomerManagementAction.CardTransactionList),
			withLatestFrom(this.store.select(state => state.customerManagement)),
			mergeMap(([params, customerManagement]) => this.customerManagementService.getTransactionsOfCard({
				id: customerManagement.cardId,
				accountNumber: params.accountNumber,
				cardNumber: params.cardNumber,
				relationship: params.relationship,
				pageNumber: params.pageNumber,
				pageSize: params.pageSize,
				startDate: params.startDate,
				endDate: params.endDate
			}).pipe(map((response: Response) => {
				const success: boolean = response.success
				const payload: CustomerCardViewTransactionResponse = response.payload

				if (success) {
					return CustomerManagementAction.CardTransactionListSuccess({ payload })
				} else { return CustomerManagementAction.RestError(response as any) }

			}), catchError(this.catcher)))

		)
	)

	AccountView$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CustomerManagementAction.AccountView),
			withLatestFrom(this.store.select(state => state.customerManagement)),
			mergeMap(([, customerManagement]) => this.customerManagementService.viewAccountOfCustomer({ id: customerManagement.accountId }).pipe(
			map((response: Response) => {
				const success: boolean = response.success
				const payload: CustomerAccountViewResponse = response.payload

				if (success) {
					return CustomerManagementAction.AccountViewSuccess({ payload })
				} else { return CustomerManagementAction.RestError(response as any) }

			}), catchError(this.catcher)))
		)
	)

	InitialAccountTransactionListByCycle$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CustomerManagementAction.AccountViewSuccess),
			withLatestFrom(this.store.select(state => state.customerManagement)),
			mergeMap(([params, customerManagement]) => {
				const today = new Date()
				const endDate = Util.dateFormat(today.getDate(), today.getMonth(), today.getFullYear())

				return this.customerManagementService.getTransactionsOfAccount({
					id: customerManagement.accountId,
					startDate: customerManagement.customerAccountViewResponse.previousStartDate,
					endDate,
					pageNumber: 0,
					pageSize: 5
				}).pipe(
					map((response: Response) => {
						const success: boolean = response.success
						const payload: CustomerAccountViewTransactionListResponse = response.payload

						if (success) {
							return CustomerManagementAction.AccountTransactionListSuccess({ payload })
						} else { return CustomerManagementAction.RestError(response as any) }

					}), catchError(this.catcher))
			})
		)
	)

	AccountTransactionListByCycle$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CustomerManagementAction.AccountTransactionListByCycle),
			withLatestFrom(this.store.select(state => state.customerManagement)),
			mergeMap(([params, customerManagement]) => this.customerManagementService.getTransactionsOfAccount({
				id: customerManagement.accountId,
				startDate: params.startDate,
				endDate: params.endDate,
				rewardPoolId: params.rewardPoolId,
				pageNumber: params.pageNumber,
				pageSize: params.pageSize
			}).pipe(
				map((response: Response) => {
					const success: boolean = response.success
					const payload: CustomerAccountViewTransactionListResponse = response.payload

					if (success) {
						return CustomerManagementAction.AccountTransactionListSuccess({ payload })
					} else { return CustomerManagementAction.RestError(response as any) }

				}), catchError(this.catcher)))
		)
	)

	AccountTransactionView$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CustomerManagementAction.AccountTransactionView),
			withLatestFrom(this.store.select(state => state.customerManagement)),
			mergeMap(([params, customerManagement]) => this.customerManagementService.getTransactionDetailOfAccount({
				id: params.id
			}).pipe(
			map((response: Response) => {
				const success: boolean = response.success
				const payload: CustomerAccountViewTransactionDetailResponse = response.payload

				if (success) {
					return CustomerManagementAction.AccountTransactionViewSuccess({ payload })
				} else { return CustomerManagementAction.RestError(response as any) }

			}), catchError(this.catcher)))
		)
	)

	AccountTransactionViewSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CustomerManagementAction.AccountTransactionViewSuccess),
			withLatestFrom(this.store.select(state => state.fieldMapping)),
			mergeMap(([param, customerManagement]) => {
				const dialogRef = this.dialog.open(CustomerManagementAccountTransactionViewComponent, {
					data: {
						indicatorList: param.payload.indicatorList,
						descriptionList: param.payload.descriptionList,
						balanceList: param.payload.balanceList,
						dateList: param.payload.dateList,
						timeList: param.payload.timeList,
						amountList: param.payload.amountList
					},
					height: '80%',
					width: '80%'
				})
				return dialogRef.afterClosed()
			})
		), { dispatch: false }
	)

	RewardPoolView$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CustomerManagementAction.RewardPoolView),
			withLatestFrom(this.store.select(state => state.customerManagement)),
			mergeMap(([ , customerManagement]) => this.customerManagementService.getRewardPoolOfCustomer({ customerId: customerManagement.id, rewardPoolId: customerManagement.rewardPoolId }).pipe(
			map((response: Response) => {
				const success: boolean = response.success
				const payload: CustomerRewardPoolViewResponse = response.payload

				if (success) {
					this.store.dispatch(CustomerManagementAction.SetRewardPoolId({ rewardPoolId: payload.rewardPoolId }))
					this.store.dispatch(CustomerManagementAction.SetRewardPoolGroupId({ rewardPoolGroupId: payload.rewardPoolGroupId }))
					return CustomerManagementAction.RewardPoolViewSuccess({ payload })
				} else { return CustomerManagementAction.RestError(response as any) }

			}), catchError(this.catcher)))
		)
	)

	RewardPoolInformation$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CustomerManagementAction.RewardPoolInformation),
			withLatestFrom(this.store.select(state => state.customerManagement)),
			mergeMap(([, customerManagement]) => this.customerManagementService.getRewardPoolInformation({ customerId: customerManagement.id , rewardPoolId: customerManagement.rewardPoolId }).pipe(
				map((response: Response) => {
					const success: boolean = response.success
					const payload: RewardPoolInformationList [] = response.payload

					if (success) {
						return CustomerManagementAction.RewardPoolInformationSuccess({ payload })
					} else { return CustomerManagementAction.RestError(response as any) }

				}), catchError(this.catcher)
			))
		)
	)

	ConditionalRewardView$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CustomerManagementAction.ConditionalRewardView),
			withLatestFrom(this.store.select(state => state.customerManagement)),
			mergeMap(([, customerManagement]) => this.customerManagementService.getConditionalRewardOfCustomer(
				{ id: customerManagement.id },
				{
					conditionalRewardId: customerManagement.conditionalRewardId
				}).pipe(
					map((response: Response) => {
						const success: boolean = response.success
						const payload: ConditionalRewardViewResponse[] = response.payload

						if (success) {
							return CustomerManagementAction.ConditionalRewardViewSuccess({ payload })
						} else { return CustomerManagementAction.RestError(response as any) }

					}), catchError(this.catcher)))
		)
	)

	AccumulationBucket$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CustomerManagementAction.AccumulationBucket),
			withLatestFrom(this.store.select(state => state.customerManagement)),
			mergeMap(([, customerManagement]) => this.customerManagementService.getAccumulationBucket({ accountId: customerManagement.accountId, rewardPoolId: customerManagement.rewardPoolId }).pipe(
				map((response: Response) => {
					const success: boolean = response.success
					const payload: AccumulationBucketList = response.payload

					if (success) {
						return CustomerManagementAction.AccumulationBucketSuccess({ payload })
					} else { return CustomerManagementAction.RestError(response as any) }

				}), catchError(this.catcher)
			))
		)
	)

	GetProductCatalogueList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CustomerManagementAction.GetProductCatalogueList),
			withLatestFrom(this.store.select(state => state.customerManagement)),
			mergeMap(([, customerManagement]) => this.customerManagementService.getProductCatalogueListByRewardPool({rewardPoolId: customerManagement.rewardPoolId, rewardPoolGroupId: customerManagement.rewardPoolGroupId}).pipe(
			map((response: Response) => {
				const success: boolean = response.success
				const payload: CardProductCatalogueListResponse = response.payload
				if (success) {
					this.store.dispatch(CustomerManagementAction.GetPartnerRedemptionList({from: 'Catalogue'}))
					this.store.dispatch(CustomerManagementAction.GetProductCatalogueListSuccess({ payload }))
					return CustomerManagementAction.CatalogueRedemptionList()
				} else { return CustomerManagementAction.RestError(response as any) }

			}), catchError(this.catcher)))
		)
	)

	SelectProductCatalogueDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CustomerManagementAction.SelectProductCatalogueDialog),
			withLatestFrom(this.store.select(state => state.customerManagement)),
			mergeMap(([, customerManagement]) => {
				const dialogRef = this.dialog.open(SelectDialogComponent, getDialogConfig({
					title: 'CUSTOMER_MANAGEMENT.SELECT_PRODUCT',
					content: 'CUSTOMER_MANAGEMENT.PRODUCT',
					payload: customerManagement.productCatalogueList
				}))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return CustomerManagementAction.SelectProductCatalogue({ productCatalogue: result })
				} else {
					return CustomerManagementAction.closeDialog()
				}
			})
		)
	)

	SelectProductCatalogue$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CustomerManagementAction.SelectProductCatalogue),
			withLatestFrom(this.store.select(state => state.customerManagement)),
			map(([param, customerManagement]) => CustomerManagementAction.GetCustomerCardList())
		)
	)

	GetCustomerCardList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CustomerManagementAction.GetCustomerCardList),
			withLatestFrom(this.store.select(state => state.customerManagement)),
			mergeMap(([, customerManagement]) => this.customerManagementService.getListOfCardsOfCustomer({customerId: customerManagement.id}).pipe(
			map((response: Response) => {
				const success: boolean = response.success
				const payload: CustomerCardListResponse = response.payload
				if (success) {
					this.store.dispatch(CustomerManagementAction.GetCustomerCardListSuccess({ payload }))
					return CustomerManagementAction.GetCustomerAddressList()
				} else { return CustomerManagementAction.RestError(response as any) }

			}), catchError(this.catcher)))
		)
	)

	GetCustomerAddressList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CustomerManagementAction.GetCustomerAddressList),
			withLatestFrom(this.store.select(state => state.customerManagement)),
			mergeMap(([, customerManagement]) => this.customerManagementService.getListOfAddressOfCustomer({customerId: customerManagement.id}).pipe(
			map((response: Response) => {
				const success: boolean = response.success
				const payload: CustomerAddressListResponse = response.payload
				if (success) {
					this.store.dispatch(CustomerManagementAction.GetCustomerAddressListSuccess({ payload }))
					return CustomerManagementAction.GetPoBoxList()
				} else { return CustomerManagementAction.RestError(response as any) }

			}), catchError(this.catcher)))
		)
	)

	GetPoBoxList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CustomerManagementAction.GetPoBoxList),
			mergeMap(() => this.customerManagementService.getPoBoxList().pipe(
			map((response: Response) => {
				const success: boolean = response.success
				const payload: string[] = response.payload
				if (success) {
					this.store.dispatch(CustomerManagementAction.GetPoBoxListSuccess({ payload }))
					return CustomerManagementAction.GetPoBoxListSuccess({ payload })
				} else { return CustomerManagementAction.RestError(response as any) }

			}), catchError(this.catcher)))
		)
	)

	CatalogueRedemptionDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CustomerManagementAction.CatalogueRedemptionDialog),
			withLatestFrom(this.store.select(state => state.fieldMapping)),
			mergeMap(([, customerManagement]) => {
				const dialogRef = this.dialog.open(CustomerManagementCatalogueRedemptionConfirmationDialogComponent, {
				})
				return dialogRef.afterClosed()
			})
		), { dispatch: false }
	)

	CatalogueRedemptionList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CustomerManagementAction.CatalogueRedemptionList),
			tap(() => {
				this.router.navigate(['customer-management/view/catalogue-redemption'])
			})
		), { dispatch: false }
	)

	CreateCatalogueRedemption$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CustomerManagementAction.CreateCatalogueRedemption),
			withLatestFrom(this.store.select(state => state.customerManagement)),
			mergeMap(([, customerManagement]) => {
				let address = null

				if (customerManagement.productCatalogue.productCategory === 'G') {
					address = ''
					if (customerManagement.catalogueRedemptionForm.value.address1 !== '') {
						address += customerManagement.catalogueRedemptionForm.value.address1 + ' '
					}
					if (customerManagement.catalogueRedemptionForm.value.address2 !== '') {
						address += customerManagement.catalogueRedemptionForm.value.address2 + ' '
					}
					if (customerManagement.catalogueRedemptionForm.value.address3 !== '') {
						address += customerManagement.catalogueRedemptionForm.value.address3
					}
				}

				return this.customerManagementService.createCatalogueRedemption({
					cusId: customerManagement.customerViewResponse.customerInformationList[0].customerId,
					cardId: customerManagement.catalogueRedemptionForm.value.cardId,
					redemptionItemId: customerManagement.productCatalogue.id,
					productCategory: customerManagement.productCatalogue.productCategory,
					quantity: Number(customerManagement.catalogueRedemptionForm.value.quantity),
					address,
					city: customerManagement.productCatalogue.productCategory === 'G' ? customerManagement.catalogueRedemptionForm.value.city : null,
					state: customerManagement.productCatalogue.productCategory === 'G' ? customerManagement.catalogueRedemptionForm.value.state : null,
					postcode: customerManagement.productCatalogue.productCategory === 'G' ? customerManagement.catalogueRedemptionForm.value.postcode : null,
					emailAddress: customerManagement.productCatalogue.productCategory === 'V' ? customerManagement.catalogueRedemptionForm.value.emailAddress : null
				}).pipe(
					map((response: Response) => {
						const success: boolean = response.success
						const payload: string = response.payload
						if (success) {
							this.store.dispatch(CustomerManagementAction.closeDialog())
							return CustomerManagementAction.CreateCatalogueRedemptionSuccess({ payload })
						} else { return CustomerManagementAction.RestError(response as any) }

					}), catchError(this.catcher))
			})
		)
	)

	CardStatmentTransactionList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CustomerManagementAction.CardStatmentTransactionList),
			withLatestFrom(this.store.select(state => state.customerManagement)),
			mergeMap(([params, customerManagement]) => this.customerManagementService.getListOfStatementTransactions({
				accountId: params.accountId,
				customerId: params.customerId
			}).pipe(map((response: Response) => {
				const success: boolean = response.success
				const payload: CustomerStatementTransactionListResponse = response.payload

				if (success) {
					return CustomerManagementAction.StatementTransactionListSuccess({ payload })
				} else { return CustomerManagementAction.RestError(response as any) }

			}), catchError(this.catcher))
			)
		)
	)

	InitialStatmentTransactionList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CustomerManagementAction.InitialStatmentTransactionList),
			withLatestFrom(this.store.select(state => state.customerManagement)),
			mergeMap(([params, customerManagement]) => this.customerManagementService.getListOfStatementTransactions({
				accountId: customerManagement.accountId,
				customerId: customerManagement.id
			}).pipe(map((response: Response) => {
				const success: boolean = response.success
				const payload: CustomerStatementTransactionListResponse = response.payload

				if (success) {
					return CustomerManagementAction.StatementTransactionListSuccess({ payload })
				} else { return CustomerManagementAction.RestError(response as any) }

			}), catchError(this.catcher))
			)
		)
	)

	RewardPoolStatmentTransactionList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CustomerManagementAction.RewardPoolStatmentTransactionList),
			withLatestFrom(this.store.select(state => state.customerManagement)),
			mergeMap(([params, customerManagement]) => this.customerManagementService.getListOfStatementTransactions({
				accountId: params.accountId,
				customerId: params.customerId,
				rewardPoolId: params.rewardPoolId
			}).pipe(map((response: Response) => {
				const success: boolean = response.success
				const payload: CustomerStatementTransactionListResponse = response.payload

				if (success) {
					return CustomerManagementAction.StatementTransactionListSuccess({ payload })
				} else { return CustomerManagementAction.RestError(response as any) }

			}), catchError(this.catcher))
			)
		)
	)

	GetWaiverTypeList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CustomerManagementAction.GetWaiverTypeList),
			withLatestFrom(this.store.select(state => state.customerManagement)),
			mergeMap(([, customerManagement]) => this.customerManagementService.getListOfWaiverType({customerId: customerManagement.id, cardId: customerManagement.cardId}).pipe(
			map((response: Response) => {
				const success: boolean = response.success
				const payload: WaiverTypeListDataResponse = response.payload
				if (success) {
					this.store.dispatch(CustomerManagementAction.GetWaiverTypeListSuccess({ payload }))
					return CustomerManagementAction.SelectWaiverDialog()
				} else { return CustomerManagementAction.RestError(response as any) }

			}), catchError(this.catcher)))
		)
	)

	GetWaiverList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CustomerManagementAction.GetWaiverList),
			withLatestFrom(this.store.select(state => state.customerManagement)),
			mergeMap(([params, customerManagement]) => this.customerManagementService.getListOfWaiver({waiverTypeId: params.waiverTypeId, customerId: customerManagement.id, cardId: customerManagement.cardId}).pipe(
			map((response: Response) => {
				const success: boolean = response.success
				const payload: WaiverListDataResponse = response.payload
				if (success) {
					return CustomerManagementAction.GetWaiverListSuccess({ payload })
				} else { return CustomerManagementAction.RestError(response as any) }

			}), catchError(this.catcher)))
		)
	)

	SelectWaiverDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CustomerManagementAction.SelectWaiverDialog),
			withLatestFrom(this.store.select(state => state.fieldMapping)),
			mergeMap(([, customerManagement]) => {
				const dialogRef = this.dialog.open(CustomerManagementWaiverComponent)
				return dialogRef.afterClosed()
			})
		), { dispatch: false }
	)

	WaiverConfirmationDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CustomerManagementAction.WaiverConfirmationDialog),
			withLatestFrom(this.store.select(state => state.fieldMapping)),
			mergeMap(([, customerManagement]) => {
				const dialogRef = this.dialog.open(CustomerManagementWaiverConfirmationComponent)
				return dialogRef.afterClosed()
			})
		), { dispatch: false }
	)

	CreateWaiver$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CustomerManagementAction.CreateWaiver),
			withLatestFrom(this.store.select(state => state.customerManagement)),
			mergeMap(([, customerManagement]) => {

				return this.customerManagementService.createWaiver({
					waiverTypeId: customerManagement.waiverForm.value.waiverTypeId,
					waiverId: customerManagement.waiverForm.value.waiverId,
					cardId: customerManagement.cardId
				}).pipe(
					map((response: Response) => {
						const success: boolean = response.success
						const payload: string = response.payload
						if (success) {
							return CustomerManagementAction.CreateWaiverSuccess({ payload })
						} else { return CustomerManagementAction.RestError(response as any) }

					}), catchError(this.catcher))
			})
		)
	)

	GetRedemptionHistoryList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CustomerManagementAction.GetRedemptionHistoryList),
			withLatestFrom(this.store.select(state => state.customerManagement)),
			mergeMap(([, customerManagement]) => this.customerManagementService.getListOfRedemptionHistory({ customerId: customerManagement.id, rewardPoolId: customerManagement.rewardPoolId, rewardPoolGroupId: customerManagement.rewardPoolGroupId}).pipe(
				map((response: Response) => {
					const success: boolean = response.success
					const payload: CustomerRedemptionHistoryListResponse = response.payload

					if (success) {
						return CustomerManagementAction.GetRedemptionHistoryListSuccess({ payload })
					} else { return CustomerManagementAction.RestError(response as any) }

				}), catchError(this.catcher)
			))
		)
	)

	CatalogueRedemptionOrderView$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CustomerManagementAction.CatalogueRedemptionOrderView),
			withLatestFrom(this.store.select(state => state.customerManagement)),
			mergeMap(([, customerManagement]) => this.customerManagementService.viewCatalogueRedemptionOrderDetails({ redemptionTransactionId: customerManagement.redemptionTransactionId}).pipe(
				map((response: Response) => {
					const success: boolean = response.success
					const payload: CustomerCatalogueRedemptionOrderViewResponse = response.payload

					if (success) {
						return CustomerManagementAction.CatalogueRedemptionOrderViewSuccess({ payload })
					} else {
						return CustomerManagementAction.RestError(response as any) }

				}), catchError(this.catcher)))
		)
	)

	CatalogueRedemptionOrderDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CustomerManagementAction.CatalogueRedemptionOrderDialog),
			withLatestFrom(this.store.select(state => state.fieldMapping)),
			mergeMap(() => {
				const dialogRef = this.dialog.open(CustomerManagementCatalogueRedemptionDialogViewComponent, {
					width: '40%'
				})
				return dialogRef.afterClosed()
			})
		), { dispatch: false }
	)

	PartnerRedemptionOrderView$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CustomerManagementAction.PartnerRedemptionOrderView),
			withLatestFrom(this.store.select(state => state.customerManagement)),
			mergeMap(([, customerManagement]) => this.customerManagementService.viewPartnerRedemptionOrderDetails({ redemptionTransactionId: customerManagement.redemptionTransactionId }).pipe(
				map((response: Response) => {
					const success: boolean = response.success
					const payload: CustomerPartnerRedemptionOrderViewResponse = response.payload

					if (success) {
						return CustomerManagementAction.PartnerRedemptionOrderViewSuccess({ payload })
					} else {
						return CustomerManagementAction.RestError(response as any)
					}

				}), catchError(this.catcher)))
		)
	)

	PartnerRedemptionOrderDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CustomerManagementAction.PartnerRedemptionOrderDialog),
			withLatestFrom(this.store.select(state => state.fieldMapping)),
			mergeMap(([, customerManagement]) => {
				const dialogRef = this.dialog.open(CustomerManagementCataloguePartnerRedemptionViewComponent, {
					height: '50%',
					width: '40%'
				})
				return dialogRef.afterClosed()
			})
		), { dispatch: false }
	)


	GetPartnerRedemptionList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CustomerManagementAction.GetPartnerRedemptionList),
			withLatestFrom(this.store.select(state => state.customerManagement)),
			mergeMap(([x, customerManagement]) => this.customerManagementService.getPartnerRedemptionListByRewardPool({ rewardPoolId: customerManagement.rewardPoolId, rewardPoolGroupId: customerManagement.rewardPoolGroupId }).pipe(
				map((response: Response) => {
					const success: boolean = response.success
					const payload: CustomerPartnerRedemptionListResponse = response.payload
					if (success) {
						this.store.dispatch(CustomerManagementAction.GetPartnerRedemptionListSuccess({ payload }))
						if (x.from === 'Partner') {
							return CustomerManagementAction.PartnerRedemptionDialog()
						} else {
							{ return CustomerManagementAction.RestError(response as any) }
						}
					} else { return CustomerManagementAction.RestError(response as any) }

				}), catchError(this.catcher)))
		)
	)

	PartnerRedemptionDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CustomerManagementAction.PartnerRedemptionDialog),
			withLatestFrom(this.store.select(state => state.customerManagement)),
			mergeMap(([, customerManagement]) => {
				const dialogRef = this.dialog.open(CustomerManagementPartnerRedemptionComponent, {})
				return dialogRef.afterClosed()
			})
		), { dispatch: false }
	)

	PartnerRedemptionConfirmationDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CustomerManagementAction.PartnerRedemptionConfirmationDialog),
			withLatestFrom(this.store.select(state => state.fieldMapping)),
			mergeMap(([, customerManagement]) => {
				const dialogRef = this.dialog.open(CustomerManagementPartnerRedemptionConfirmationComponent)
				return dialogRef.afterClosed()
			})
		), { dispatch: false }
	)

	CreatePartnerRedemption$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CustomerManagementAction.CreatePartnerRedemption),
			withLatestFrom(this.store.select(state => state.customerManagement)),
			mergeMap(([, customerManagement]) => {

				return this.customerManagementService.createPartnerRedemption({
					customerId: customerManagement.id,
					rewardPoolGroupId: customerManagement.rewardPoolGroupId,
					rewardPoolId: customerManagement.rewardPoolId,
					point: customerManagement.partnerRedemptionForm.value.points,
					value: customerManagement.partnerRedemptionForm.value.values,
					airmileId: customerManagement.partnerRedemptionForm.value.airmileId,
					partnerCode: customerManagement.partnerRedemption.partnerCode,
					rewardPoolType: customerManagement.redemptionType
				}).pipe(
					map((response: Response) => {
						const success: boolean = response.success
						const payload: string = response.payload
						if (success) {
							this.store.dispatch(CustomerManagementAction.closeDialog())
							return CustomerManagementAction.CreatePartnerRedemptionSuccess({ payload })
						} else { return CustomerManagementAction.RestError(response as any) }
					}), catchError(this.catcher))
			})
		)
	)

	CardStatementTransactionDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CustomerManagementAction.CardStatementTransactionDialog),
			withLatestFrom(this.store.select(state => state.customerManagement)),
			mergeMap(([, customerManagement]) => {
				const dialogRef = this.dialog.open(CustomerManagementStatementTransactionListViewComponent, {
					maxHeight: '80vh',
					width: '80%'
				})
				return dialogRef.afterClosed()
			})
		), { dispatch: false }
	)

	GetCardStatementTransactionListByCycle$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CustomerManagementAction.GetCardStatementTransactionListByCycle),
			withLatestFrom(this.store.select(state => state.customerManagement)),
			mergeMap(([params, customerManagement]) => this.customerManagementService.getTransactionsOfCard({
				id: customerManagement.cardId,
				accountNumber: params.accountNumber,
				cardNumber: params.cardNumber,
				relationship: params.relationship,
				pageNumber: params.pageNumber,
				pageSize: params.pageSize,
				rewardPoolId: params.rewardPoolId,
				startDate: params.startDate,
				endDate: params.endDate
			}).pipe(map((response: Response) => {
				const success: boolean = response.success
				const payload: CustomerCardViewTransactionResponse = response.payload

				if (success) {
					return CustomerManagementAction.GetCardStatementTransactionListByCycleSuccess({ payload })
				} else { return CustomerManagementAction.RestError(response as any) }

			}), catchError(this.catcher)))

		)
	)

	GetCardStatementTransactionOptionList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CustomerManagementAction.GetCardStatementTransactionOptionList),
			withLatestFrom(this.store.select(state => state.customerManagement)),
			mergeMap(([params, customerManagement]) => this.customerManagementService.getCardTransactionsOptionList({
				accountId: customerManagement.isCreditCard ? customerManagement.customerCardViewResponse.accountId : customerManagement.accountId
			}).pipe(map((response: Response) => {
				const success: boolean = response.success
				const payload: Option[] = response.payload
				if (success) {
					return CustomerManagementAction.GetCardStatementTransactionOptionListSuccess({ payload })
				} else { return CustomerManagementAction.RestError(response as any) }

			}), catchError(this.catcher)))
			)
		)

	SubmitDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CustomerManagementAction.SubmitDialog),
			mergeMap(() => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.SUBMIT_POINTS_ADJUSTMENT' }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return CustomerManagementAction.Submit()
				} else {
					return CustomerManagementAction.CloseDialog()
				}
			})
		)
	)

	Submit$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CustomerManagementAction.Submit),
			withLatestFrom(this.store.select(state => state.customerManagement)),
			mergeMap(([, customerManagement]) => {
				const customerManagementPointsAdjustment = customerManagement.customerManagementPointsAdjustmentForm.controls
				let resourceCode: string
				let cardNumber: string
				let accountNumber: string
				if (customerManagement.isCreditCard) {
					resourceCode = customerManagement.customerCardViewResponse.resourceCode
					cardNumber = customerManagement.customerCardViewResponse.cardNumber
				} else {
					resourceCode = customerManagement.customerAccountViewResponse.resourceCode
					accountNumber = customerManagement.customerAccountViewResponse.accountNumber
				}
				return this.pointsAdjustmentService.createPointsAdjustment({
					cardNumber,
					accountNumber,
					rewardPoolId: Number(customerManagementPointsAdjustment.rewardPool.value),
					adjustmentType: customerManagementPointsAdjustment.adjustmentType.value,
					adjustmentPoints: customerManagementPointsAdjustment.pointsAdjustment.value,
					resourceCode
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: string = response.payload
					if (success) {
						return CustomerManagementAction.SubmitSuccess({ payload })
					} else {
						return CustomerManagementAction.RestError(response as any)
					}
				}), catchError(this.catcher))
			})
		)
	)

	SubmitSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CustomerManagementAction.SubmitSuccess),
			withLatestFrom(this.store.select(state => state.customerManagement)),
			mergeMap(([param, customerManagement]) => {
				if (customerManagement.isCreditCard) {
					return [CustomerManagementAction.GoCardView(), CommonAction.RestError({ message: param.payload })]
				} else {
					return [CustomerManagementAction.GoAccountView(), CommonAction.RestError({ message: param.payload })]
				}
			})
		)
	)

	GetPointsAdjustmentDetails$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CustomerManagementAction.GetPointsAdjustmentDetails),
			withLatestFrom(this.store.select(state => state.customerManagement)),
			mergeMap(([, customerManagement]) => this.pointsAdjustmentService.getPointsAdjustmentDetails({ id: customerManagement.taskId } as PointsAdjustmentDetails)
				.pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: PointsAdjustmentDetails = response.payload
					if (success) {
						return CustomerManagementAction.GetPointsAdjustmentDetailsSuccess({ payload })
					} else { return CustomerManagementAction.RestError(response as any) }
				}), catchError(this.catcher))
			)
		)
	)

	AdjustmentTypeList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CustomerManagementAction.AdjustmentTypeList),
			withLatestFrom(this.store.select(state => state.customerManagement)),
			mergeMap(() => {
				return this.pointsAdjustmentService.getAdjustmentTypeList().pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: AdjustmentTypeListResponse = response.payload
					if (success) {
						return CustomerManagementAction.AdjustmentTypeListSuccess({ payload })
					} else { return CustomerManagementAction.RestError(response as any) }
				}), catchError(this.catcher))
			})
		)
	)

	CreateCataloguePartnerRedemption$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CustomerManagementAction.CreateCataloguePartnerRedemption),
			withLatestFrom(this.store.select(state => state.customerManagement)),
			mergeMap(([, customerManagement]) => {
				let rewardPoolId = null
				let rewardPoolGroupId = null
				if (customerManagement.productCatalogue.rewardPoolType === 'G') {
					rewardPoolGroupId = customerManagement.productCatalogue.rewardPoolId
				} else {
					rewardPoolId = customerManagement.productCatalogue.rewardPoolId
				}
				const card = customerManagement.customerCardList.find(i => i.id === customerManagement.catalogueRedemptionForm.value.cardId)
				return this.customerManagementService.createProductPartnerRedemption({
					customerId: customerManagement.id,
					rewardPoolId,
					rewardPoolGroupId,
					rewardPoolType: customerManagement.productCatalogue.rewardPoolType,
					point: Number(customerManagement.catalogueRedemptionForm.value.totalPoint),
					value: Number(customerManagement.catalogueRedemptionForm.value.value),
					airmileId: customerManagement.catalogueRedemptionForm.value.airmileId,
					partnerCode: customerManagement.productCatalogue.partnerCode,
					kind: customerManagement.productCatalogue.kind,
					cardNo: card && card.cardNumber,
					catalogCode: customerManagement.productCatalogue.code,
					quantity: customerManagement.catalogueRedemptionForm.value.quantity
				}).pipe(
					map((response: Response) => {
						const success: boolean = response.success
						const payload: string = response.payload
						if (success) {
							this.store.dispatch(CustomerManagementAction.closeDialog())
							return CustomerManagementAction.CreateCataloguePartnerRedemptionSuccess({ payload })
						} else { return CustomerManagementAction.RestError(response as any) }
					}), catchError(this.catcher))
			})
		)
	)

	CatalogueRedemptionOrderWithdrawal$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CustomerManagementAction.CatalogueRedemptionOrderWithdrawal),
			withLatestFrom(this.store.select(state => state.customerManagement)),
			mergeMap(([, customerManagement]) => {
				this.store.dispatch(CustomerManagementAction.closeDialog())
				return this.customerManagementService.withdrawCatalogueRedemption({
					catalogueRedemptionOrderId: Number(customerManagement.redemptionTransactionId)
				}).pipe(
					map((response: Response) => {
						const success: boolean = response.success
						const payload: string = response.payload
						if (success) {
							this.store.dispatch(CustomerManagementAction.closeDialog())
							return CustomerManagementAction.CatalogueRedemptionOrderWithdrawalSuccess({ payload })
						} else { return CustomerManagementAction.RestError(response as any) }
					}), catchError(this.catcher))
			})
		)
	)

	GoCatalogueRedemptionDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CustomerManagementAction.GoCatalogueRedemptionDialog),
			mergeMap(({ action }) => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.WITHDRAW_REDEMPTION', payload: action }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return CustomerManagementAction.CatalogueRedemptionOrderWithdrawal()
				} else {
					return CustomerManagementAction.closeDialog()
				}
			})
		)
	)

	CardTransactionView$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CustomerManagementAction.CardTransactionView),
			withLatestFrom(this.store.select(state => state.customerManagement)),
			mergeMap(([params, customerManagement]) => this.customerManagementService
				.getTransactionOfPointsTransactions({
					redemptionTransactionId: params.id,
					cusId: customerManagement.id
				}).pipe(
					map((response: Response) => {
						const success: boolean = response.success
						const payload: PointBreakdownListData = response.payload
						if (success) {
							return CustomerManagementAction.CardTransactionViewSuccess({ payload })
						} else {
							return CustomerManagementAction.RestError(response as any)
						}
					}),
					catchError(this.catcher))
			)
		)
	)

	GoTransactionBreakdownDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CustomerManagementAction.GoTransactionBreakdownDialog),
			mergeMap(({ payload }) => {
				const dialogRef = this.dialog.open(PointTransactionBreakdownTableComponent, {
					maxHeight: '80vh',
					width: '80%'
				})
				this.store.dispatch(CustomerManagementAction.CardTransactionView({ id: payload }))
				return dialogRef.afterClosed()
			})
		), { dispatch: false }
	)

	RewardPoolList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CustomerManagementAction.RewardPoolList),
			withLatestFrom(this.store.select(state => state.rewardPool)),
			mergeMap(([x, rewardPool]) => {

				return this.parameterSettingsService.getRewardPoolList().pipe(map((response: Response) => {

					const success: boolean = response.success
					const payload: RewardPoolListResponse = response.payload
					if (success) {
						return CustomerManagementAction.RewardPoolListSuccess({ payload })
					} else { return CustomerManagementAction.RestError(response as any) }
				}),
					catchError((error) => {
						return (of(CustomerManagementAction.RestError({ message: error.message, manualThrow: true })))
					})
				)
			})
		)
	)

	GetAccumulatingTransactions$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(CustomerManagementAction.GetAccumulatingTransactions),
			withLatestFrom(this.store.select(state => state.customerManagement)),
			mergeMap(([, customerManagement]) => {

				return this.customerManagementService
					.getAccumulatingTransactions({ accountNumber: customerManagement.accountNumber, cusId: customerManagement.id })
					.pipe(map((response: Response) => {

							const success: boolean = response.success
							const payload: AccumulatingTransactionListData[] = response.payload
							if (success) {
								return CustomerManagementAction.GetAccumulatingTransactionsSuccess({payload})
							} else {
								return CustomerManagementAction.RestError(response as any)
							}
						}),
						catchError((error) => {
							return (of(CustomerManagementAction.RestError({message: error.message, manualThrow: true})))
						})
					)
			})
		)
	)

	catcher = (error) => {
		return (of(CustomerManagementAction.RestError({ message: error.message, manualThrow: error.manualThrow })))
	}
}
