import { Component, Inject, OnDestroy, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material'
import { Action, Store } from '@ngrx/store'
import { FormGroupState } from 'ngrx-forms'
import { Observable, ReplaySubject, Subject, Subscription } from 'rxjs'
import * as AppStore from 'src/app/store'
import { OnTheSpotRedemptionDuplicateForm } from 'src/app/store/redemption-settings/on-the-spot-redemption/on-the-spot-redemption.state'
import * as OnTheSpotRedemptionAction from 'src/app/store/redemption-settings/on-the-spot-redemption/on-the-spot-redemption.actions'
import * as OnTheSpotRedemptionSelectors from 'src/app/store/redemption-settings/on-the-spot-redemption/on-the-spot-redemption.selectors'
import { FormControl } from '@angular/forms'
import { KeyValue } from '@angular/common'
import { takeUntil } from 'rxjs/operators'
import { FilterPredicate } from 'src/app/models/util/filter-predicate'
import { ActivatedRoute } from '@angular/router'

@Component({
	selector: 'app-on-the-spot-redemption-duplicate',
	templateUrl: './on-the-spot-redemption-duplicate.component.html',
	styleUrls: ['./on-the-spot-redemption-duplicate.component.scss']
})
export class OnTheSpotRedemptionDuplicateComponent implements OnInit, OnDestroy {

	isLoading = false
	action: Action
	subs: Subscription
	formState$: Observable<FormGroupState<OnTheSpotRedemptionDuplicateForm>>

	merchantGroupList: KeyValue<string, string>[] = []
	public filteredMerchantGroups: ReplaySubject<KeyValue<string, string>[]> = new ReplaySubject<KeyValue<string, string>[]>(1)
	public dropDownCtrlMerchantGroup: FormControl = new FormControl()

	protected _onDestroy = new Subject<void>()

	constructor(
		public activatedRoute: ActivatedRoute,
		private store: Store<AppStore.State>,
		private dialogRef: MatDialogRef<OnTheSpotRedemptionDuplicateComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		this.action = activatedRoute.snapshot.data.action

		this.subs = this.store.select(OnTheSpotRedemptionSelectors.getOnTheSpotRedemption).subscribe((x) => {
			this.isLoading = x.isDuplicateLoading
			const merchantGroup = x.merchantGroupList

			if (x.isDuplicateDialog) {
				this.cancel()
			}

			if (merchantGroup) {
				this.merchantGroupList = merchantGroup
			}

			if (this.merchantGroupList.length > 0) {
				this.filteredMerchantGroups.next(this.merchantGroupList.slice())
			}


		})

		this.formState$ = this.store.select(({ onTheSpotRedemption }) => onTheSpotRedemption.onTheSpotRedemptionDuplicateForm)
	}

	ngOnInit() {
		this.store.dispatch(OnTheSpotRedemptionAction.MerchantGroupList())
		this.filteredMerchantGroups.next(this.merchantGroupList.slice())

		this.dropDownCtrlMerchantGroup.valueChanges
		.pipe(takeUntil(this._onDestroy))
		.subscribe(value => {
			const maxChar = 255
			if (value.length > maxChar) {
				const newValue = value.slice(0, maxChar)
				this.dropDownCtrlMerchantGroup.reset('', { emitEvent: false })
				this.dropDownCtrlMerchantGroup.setValue(newValue)

			} else {
				this.searchMerchantGroup(value)
			}
		})
	}

	ngOnDestroy() {
		this.subs.unsubscribe()
	}

	cancel() {
		this.dialogRef.close()
	}

	submit() {
		this.store.dispatch(OnTheSpotRedemptionAction.Duplicate())
	}

	searchMerchantGroup(search: string) {
		if (!this.merchantGroupList) {
			return
		}
		if (!search) {
			this.filteredMerchantGroups.next(this.merchantGroupList.slice())
			return
		} else {
			search = search.toLowerCase()
		}
		// filter the records
		this.filteredMerchantGroups.next(
			this.merchantGroupList.filter(merchantGroup => FilterPredicate.isIdenticalTo(search)([merchantGroup.value]))
		)
	}

	clearSearchMerchantGroup() {
		this.filteredMerchantGroups.next(this.merchantGroupList.slice())
	}
}