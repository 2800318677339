import { createAction, props } from '@ngrx/store'
import { RewardPoolGroupOverviewViewResponse, RewardPoolOverviewViewResponse, CampaignOverviewViewResponse, DashboardOptionListViewResponse, CampaignOptionListViewResponse, DashboardRangeOptionListViewResponse, CampaignsOverviewListResponse } from 'src/app/models/dashboard/dashboard-overview'
import { KeyValue } from '@angular/common'

export const InitialState = createAction('[Dashboard] InitialState')

export const Reset = createAction('[Dashboard] Reset')

export const RestError = createAction('[Dashboard] RestError', props<{ message: string, manualThrow: boolean }>())

export const ViewRewardPoolOverview = createAction('[Dashboard] ViewRewardPoolOverview')
export const ViewRewardPoolOverviewSuccess = createAction('[Dashboard] ViewRewardPoolOverviewSuccess', props<{ payload: RewardPoolOverviewViewResponse[] }>())

export const ViewRewardPoolGroupOverview = createAction('[Dashboard] ViewRewardPoolGroupOverview')
export const ViewRewardPoolGroupOverviewSuccess = createAction('[Dashboard] ViewRewardPoolGroupOverviewSuccess', props<{ payload: RewardPoolGroupOverviewViewResponse[] }>())

export const ViewCampaignOverview = createAction('[Dashboard] ViewCampaignOverview')
export const ViewCampaignOverviewSuccess = createAction('[Dashboard] ViewCampaignOverviewSuccess', props<{ payload: CampaignOverviewViewResponse }>())

export const ViewCampaignsListOverview = createAction('[Dashboard] ViewCampaignsListOverview')
export const ViewCampaignsListOverviewSuccess = createAction('[Dashboard] ViewCampaignsListOverviewSuccess', props<{ payload: CampaignsOverviewListResponse[] }>())

export const RewardPoolGroupOptionList = createAction('[Dashboard] RewardPoolGroupOptionList')
export const RewardPoolGroupOptionListSuccess = createAction('[Dashboard] RewardPoolGroupOptionListSuccess', props<{ payload: DashboardOptionListViewResponse }>())

export const RewardPoolGroupRangeOptionList = createAction('[Dashboard] RewardPoolGroupRangeOptionList')
export const RewardPoolGroupRangeOptionListSuccess = createAction('[Dashboard] RewardPoolGroupRangeOptionListSuccess', props<{ payload: DashboardRangeOptionListViewResponse }>())

export const RewardPoolOptionList = createAction('[Dashboard] RewardPoolOptionList')
export const RewardPoolOptionListSuccess = createAction('[Dashboard] RewardPoolOptionListSuccess', props<{ payload: DashboardOptionListViewResponse }>())

export const RewardPoolRangeOptionList = createAction('[Dashboard] RewardPoolRangeOptionList')
export const RewardPoolRangeOptionListSuccess = createAction('[Dashboard] RewardPoolRangeOptionListSuccess', props<{ payload: DashboardRangeOptionListViewResponse }>())

export const RewardPoolOnlyRangeOptionList = createAction('[Dashboard] RewardPoolOnlyRangeOptionList')
export const RewardPoolOnlyRangeOptionListSuccess = createAction('[Dashboard] RewardPoolOnlyRangeOptionListSuccess', props<{ payload: DashboardRangeOptionListViewResponse }>())

export const RewardPoolAndGroupRangeOptionList = createAction('[Dashboard] RewardPoolAndGroupRangeOptionList')
export const RewardPoolAndGroupRangeOptionListSuccess = createAction('[Dashboard] RewardPoolAndGroupRangeOptionListSuccess', props<{ payload: DashboardRangeOptionListViewResponse }>())

export const DashboardOptionList = createAction('[Dashboard] DashboardOptionList')
export const DashboardOptionListSuccess = createAction('[Dashboard] DashboardOptionListSuccess', props<{ payload: DashboardOptionListViewResponse }>())

export const DashboardRangeOptionList = createAction('[Dashboard] DashboardRangeOptionList')
export const DashboardRangeOptionListSuccess = createAction('[Dashboard] DashboardRangeOptionListSuccess', props<{ payload: DashboardRangeOptionListViewResponse }>())

export const CampaignEndDateOptionList = createAction('[Dashboard] CampaignEndDateOptionList')
export const CampaignEndDateOptionListSuccess = createAction('[Dashboard] CampaignEndDateOptionListSuccess', props<{ payload: DashboardOptionListViewResponse }>())

export const CampaignRangeOptionList = createAction('[Dashboard] CampaignRangeOptionList')
export const CampaignRangeOptionListSuccess = createAction('[Dashboard] CampaignRangeOptionListSuccess', props<{ payload: DashboardRangeOptionListViewResponse }>())

export const CampaignOptionList = createAction('[Dashboard] CampaignOptionList')
export const CampaignOptionListSuccess = createAction('[Dashboard] CampaignOptionListSuccess', props<{ payload: CampaignOptionListViewResponse }>())

export const InitialCampaignEndDateOptionList = createAction('[Dashboard] InitialCampaignEndDateOptionList')
export const InitialCampaignEndDateOptionListSuccess = createAction('[Dashboard] InitialCampaignEndDateOptionListSuccess', props<{ payload: DashboardOptionListViewResponse }>())

export const InitialCampaignRangeOptionList = createAction('[Dashboard] InitialCampaignRangeOptionList')
export const InitialCampaignRangeOptionListSuccess = createAction('[Dashboard] InitialCampaignRangeOptionListSuccess', props<{ payload: DashboardRangeOptionListViewResponse }>())

export const DashboardOverviewChannelOptionList = createAction('[Dashboard] DashboardOverviewChannelOptionList')
export const DashboardOverviewChannelOptionListSuccess = createAction('[Dashboard] DashboardOverviewChannelOptionListSuccess', props<{ payload: KeyValue<string, string>[] }>())

export const SetDashboardResource = createAction('[Dashboard] SetDashboardResource', props<{ resource: string }>())
