import { Component, Input, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'
import * as AppStore from '../../../../../store'
import { Option } from '../../../../../models/option/option'
import { Observable } from 'rxjs'
import { FormGroupState } from 'ngrx-forms'
import { RedeemRulesDetail } from '../../../../../store/redemption-settings/redeem-rules'
import * as RedeemRulesAction from 'src/app/store/redemption-settings/redeem-rules'
import * as RedeemRuleSelectors from '../../../../../store/redemption-settings/redeem-rules/redeem-rules.selectors'
import { map } from 'rxjs/internal/operators/map'
import * as R from 'ramda'

@Component({
	selector: 'app-redeem-rule-account-status',
	templateUrl: './redeem-rule-account-status.component.html',
	styleUrls: ['./redeem-rule-account-status.component.scss']
})
export class RedeemRuleAccountStatusComponent implements OnInit {

	@Input() rewardPool: Option
	@Input() isView: boolean

	formState$: Observable<FormGroupState<RedeemRulesDetail>>
	dataState$: Observable<{
		isLoading: boolean,
		accountStatus: string,
		accountStatusFlag: string
		includeExcludeList: Option[]
	}>

	constructor(
		private store: Store<AppStore.State>
	) {
	}

	ngOnInit() {
		this.dataState$ = this.store.select(RedeemRulesAction.getRedeemRules)
			.pipe(
				map(({
					isLoading,
					redeemRulesData,
					redeemRulesForm
				}) => {

					const [accountStatus, accountStatusFlag] = R.compose(
						R.paths([['accountStatus'], ['accountStatusFlag']]),
						R.path(['value', 'rules', this.rewardPool.value, 'form', 'value']),
					)(redeemRulesForm)

					const keyEqAccountStatusFlag = R.propEq('key', accountStatusFlag)

					const accountBlockCodeFlagName = R.compose(
						R.pathOr('', ['value']),
						R.find(keyEqAccountStatusFlag),
					)(redeemRulesData.includeExcludeList)

					return {
						isLoading,
						accountStatus,
						accountStatusFlag: accountBlockCodeFlagName,
						includeExcludeList: redeemRulesData.includeExcludeList
					}
				})
			)
		this.formState$ = this.store.select(RedeemRuleSelectors.getForm, this.rewardPool.value)
	}

	removeRule(payload: string, params: string) {
		this.store.dispatch(RedeemRulesAction.RemoveRule({ payload, params }))
	}
}
