import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { Request, Response } from 'src/app/models/common/http'
import { CustomerListPageRequest, CustomerListRequest } from 'src/app/models/customer-management/customer-list'
import { RestService } from '../common/rest-client/rest.service'
import { CustomerViewRequest } from 'src/app/models/customer-management/customer-detail'
import { CustomerCardViewRequest, CustomerCardViewTransactionResponse, CustomerCardTransactionViewRequest, CustomerCardTransactionOptionRequest, CustomerCardTransactionRequest } from 'src/app/models/customer-management/customer-card-detail'
import { AccumulatingTransactionListDataRequest, CustomerAccountViewRequest, CustomerAccountViewTransactionDetailRequest, CustomerAccountViewTransactionListRequest } from 'src/app/models/customer-management/customer-account-detail'
import { CustomerRewardPoolViewRequest } from 'src/app/models/customer-management/customer-reward-pool-detail'
import {
	CardProductCatalogueListRequest,
	CatalogueRedemptionCreateRequest,
	CatalogueRedemptionWithdrawRequest,
	CustomerAddressListRequest,
	CustomerCardListRequest
} from 'src/app/models/customer-management/catalogue-redemption'
import { CustomerStatementTransactionTransactionDetailRequest } from 'src/app/models/customer-management/customer-statement-transaction-detail'
import {
	CardWaiverCreateRequest,
	WaiverListDataRequest,
	WaiverListRequest
} from 'src/app/models/customer-management/waiver'
import { AccumulationBucketViewRequest } from 'src/app/models/customer-management/customer-accumulation-bucket-detail'
import {
	CustomerRedemptionHistoryListViewRequest,
	CustomerRedemptionHistoryViewRequest
} from 'src/app/models/customer-management/customer-redemption-history'
import { PartnerRedemptionCreateRequest } from 'src/app/models/customer-management/customer-partner-redemption'
import {
	ConditionalRewardParamsViewRequest,
	ConditionalRewardPathVariableViewRequest
} from 'src/app/models/customer-management/customer-conditional-reward'
import { ProductPartnerRedemptionCreateRequest } from 'src/app/models/customer-management/customer-product-partner-redemption'

@Injectable({
	providedIn: 'root'
})
export class CustomerManagementService {

	constructor(
		private rest: RestService
	) { }

	// Basic Information
	getCustomerList(params: CustomerListRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/customer-information/search' + this.rest.urlQueryString(params),
			mockURL: '/customer-management/basic-info/getBasicInfoList.json'
		}

		return this.rest.get(request)
	}

	getCustomerListPage(params: CustomerListPageRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/customer-information/search' + this.rest.urlQueryString(params),
			mockURL: '/customer-management/basic-info/getBasicInfoList.json'
		}

		return this.rest.get(request)
	}

	viewCustomer(params: CustomerViewRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/customer-information/view-basic/' + encodeURIComponent(params.id)
		}

		return this.rest.get(request)
	}

	viewCardOfCustomer(params: CustomerCardViewRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/customer-information/view-card/' + params.cardNumber
		}

		return this.rest.get(request)
	}

	getTransactionsOfCard(params: CustomerCardTransactionViewRequest): Observable<Response> {
		const { id, ...card } = params
		const request: Request = {
			liveURL: '/customer-information/view-card-transactions/' + params.id + this.rest.urlQueryString(card)
		}

		return this.rest.get(request)
	}

	getCardTransactionsOptionList(params: CustomerCardTransactionOptionRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/customer-information/transaction-option-list/' + params.accountId
		}

		return this.rest.get(request)
	}

	viewAccountOfCustomer(params: CustomerAccountViewRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/customer-information/view-account/' + params.id
		}

		return this.rest.get(request)
	}

	getTransactionsOfAccount(params: CustomerAccountViewTransactionListRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/customer-information/view-account-transactions/' + params.id + this.rest.urlQueryString(params)
		}

		return this.rest.get(request)
	}

	getTransactionDetailOfAccount(params: CustomerAccountViewTransactionDetailRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/customer-information/view-account-transaction-detail/' + params.id
		}

		return this.rest.get(request)
	}

	getRewardPoolOfCustomer(params: CustomerRewardPoolViewRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/customer-information/view-customer-reward-pool/' + this.rest.urlQueryString(params)
		}

		return this.rest.get(request)
	}

	getProductCatalogueListByRewardPool(params: CardProductCatalogueListRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/customer-information/product-catalogues/list' + this.rest.urlQueryString(params)
		}

		return this.rest.get(request)
	}

	getListOfCardsOfCustomer(params: CustomerCardListRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/customer-information/customer/card/list' + this.rest.urlQueryString(params)
		}

		return this.rest.get(request)
	}

	getListOfAddressOfCustomer(params: CustomerAddressListRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/customer-information/view-customer-address-list' + this.rest.urlQueryString(params)
		}

		return this.rest.get(request)
	}

	getPoBoxList(): Observable<Response> {
		const request: Request = {
			liveURL: '/customer-information/po-box'
		}

		return this.rest.get(request)
	}

	createCatalogueRedemption(params: CatalogueRedemptionCreateRequest): Observable<Response> {
		const request: Request = {
			body: {
				cusId: params.cusId,
				cardId: params.cardId,
				redemptionItemId: params.redemptionItemId,
				productCategory: params.productCategory,
				quantity: params.quantity,
				address: params.address,
				city: params.city,
				state: params.state,
				postcode: params.postcode,
				emailAddress: params.emailAddress
			},
			liveURL: '/customer-information/catalogue-redemption',
		}

		return this.rest.post(request)
	}

	withdrawCatalogueRedemption(params: CatalogueRedemptionWithdrawRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/customer-information/catalogue-redemption-order/withdraw/' + params.catalogueRedemptionOrderId
		}

		return this.rest.post(request)
	}

	getListOfStatementTransactions(params: CustomerStatementTransactionTransactionDetailRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/customer-information/view-account-statements' + this.rest.urlQueryString(params)
		}

		return this.rest.get(request)
	}

	getRewardPoolInformation(params: CustomerRewardPoolViewRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/customer-information/customer/accumulation-bucket/list' + this.rest.urlQueryString(params)
		}

		return this.rest.get(request)
	}

	getAccumulationBucket(params: AccumulationBucketViewRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/customer-information/accumulation-bucket/list' + this.rest.urlQueryString(params)
		}

		return this.rest.get(request)
	}

	getListOfWaiverType(params: WaiverListRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/customer-information/waiver-type/list' + this.rest.urlQueryString(params)
		}

		return this.rest.get(request)
	}

	getListOfWaiver(params: WaiverListDataRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/customer-information/waiver/list' + this.rest.urlQueryString(params)
		}

		return this.rest.get(request)
	}

	createWaiver(params: CardWaiverCreateRequest): Observable<Response> {
		const request: Request = {
			body: {
				waiverTypeId: params.waiverTypeId,
				waiverId: params.waiverId,
				cardId: params.cardId
			},
			liveURL: '/customer-information/card-waiver'
		}

		return this.rest.post(request)
	}

	getListOfRedemptionHistory(params: CustomerRedemptionHistoryListViewRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/customer-information/redemption-history/list' + this.rest.urlQueryString(params)
		}

		return this.rest.get(request)
	}

	viewCatalogueRedemptionOrderDetails(params: CustomerRedemptionHistoryViewRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/customer-information/catalogue-redemption-order/view' + this.rest.urlQueryString(params)
		}

		return this.rest.get(request)
	}

	viewPartnerRedemptionOrderDetails(params: CustomerRedemptionHistoryViewRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/customer-information/partner-redemption-order/view' + this.rest.urlQueryString(params)
		}

		return this.rest.get(request)
	}
	getPartnerRedemptionListByRewardPool(params: CardProductCatalogueListRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/customer-information/partner-redemption/list' + this.rest.urlQueryString(params)
		}

		return this.rest.get(request)
	}

	createPartnerRedemption(params: PartnerRedemptionCreateRequest): Observable<Response> {
		const request: Request = {
			body: {
				cusId: params.customerId,
				partnerCode: params.partnerCode,
				airmileId: params.airmileId,
				point: params.point,
				value: params.value,
				rewardPoolId: params.rewardPoolId,
				rewardPoolGroupId: params.rewardPoolGroupId,
				rewardPoolType: params.rewardPoolType
			},
			liveURL: '/customer-information/redeem',
		}

		return this.rest.post(request)
	}

	getConditionalRewardOfCustomer(pathVariable: ConditionalRewardPathVariableViewRequest, params: ConditionalRewardParamsViewRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/customer-information/conditional-reward-point-history-detail/' + pathVariable.id + this.rest.urlQueryString(params)
		}

		return this.rest.get(request)
	}

	createProductPartnerRedemption(params: ProductPartnerRedemptionCreateRequest): Observable<Response> {
		const request: Request = {
			body: {
				cusId: params.customerId,
				partnerCode: params.partnerCode,
				airmileId: params.airmileId,
				point: params.point,
				value: params.value,
				rewardPoolId: params.rewardPoolId,
				rewardPoolGroupId: params.rewardPoolGroupId,
				rewardPoolType: params.rewardPoolType,
				kind: params.kind,
				cardNo: params.cardNo,
				catalogCode: params.catalogCode,
				quantity: params.quantity
			},
			liveURL: '/customer-information/redeem',
		}

		return this.rest.post(request)
	}

	getTransactionOfPointsTransactions(params: CustomerCardTransactionRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/customer-information/point-transaction/' + params.redemptionTransactionId + '/transactions' + this.rest.urlQueryString({ cusId: params.cusId })
		}

		return this.rest.get(request)
	}

	getAccumulatingTransactions(params: AccumulatingTransactionListDataRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/customer-information/account/' + params.accountNumber + '/accumulating-transactions' + this.rest.urlQueryString({cusId: params.cusId})
		}

		return this.rest.get(request)
	}
}
