import { Action, createReducer, on } from '@ngrx/store'
import * as NgrxForms from 'ngrx-forms'
import { createFormGroupState, formGroupReducer, markAsTouched, MarkAsTouchedAction, reset, setValue, SetValueAction } from 'ngrx-forms'
import { Util } from 'src/app/models/util/util'
import { UUID } from 'src/app/models/util/uuid'
import * as ConditionalRewardAction from './conditional-reward.actions'
import * as ConditionalRewardState from './conditional-reward.state'

export const reducer = createReducer(
	ConditionalRewardState.initialState,
	NgrxForms.onNgrxFormsAction(SetValueAction, (state, action) => {
		return actionHandler(state, action)
	}),
	NgrxForms.onNgrxFormsAction(NgrxForms.MarkAsTouchedAction, (state, action) => {
		return actionHandler(state, action)
	}),
	on(ConditionalRewardAction.InitialState, () => ConditionalRewardState.initialState),
	on(ConditionalRewardAction.RestError, state => ({
		...state,
		isLoading: false
	})),
	on(ConditionalRewardAction.SetId, (state, { id }) => ({
		...state,
		id
	})),
	on(ConditionalRewardAction.SetTierId, (state, { tierId }) => ({
		...state,
		tierId
	})),
	on(ConditionalRewardAction.SetCheckerMakerId, (state, { checkerMakerId }) => ({
		...state,
		checkerMakerId
	})),
	on(ConditionalRewardAction.SetTierAction, (state, { tierAction }) => ({
		...state,
		tierAction
	})),
	on(ConditionalRewardAction.GoCreate, state => ({
		...state,
		tierAction: 'CREATE',
		conditionalRewardDetailForm: reset(setValue(state.conditionalRewardDetailForm, ConditionalRewardState.initialConditionalRewardDetailFormValue))
	})),
	on(ConditionalRewardAction.GoPublishedUpdate, (state, { action }) => ({
		...state,
		tierAction: action
	})),
	on(ConditionalRewardAction.List, state => ({
		...state,
		isLoading: true
	})),
	on(ConditionalRewardAction.ListSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		publisheds: payload.map(x => {
			return {
				...x,
				startDate: Util.fromISOStringToDateStr(x.startDate),
				endDate: Util.fromISOStringToDateStr(x.endDate)
			}
		})
	})),
	on(ConditionalRewardAction.DraftList, state => ({
		...state,
		isLoading: true
	})),
	on(ConditionalRewardAction.DraftListSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		drafts: payload
	})),
	on(ConditionalRewardAction.UnpublishedList, state => ({
		...state,
		isLoading: true
	})),
	on(ConditionalRewardAction.UnpublishedListSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		unpublisheds: payload.map(x => {
			return {
				...x,
				startDate: Util.fromISOStringToDateStr(x.startDate),
				endDate: Util.fromISOStringToDateStr(x.endDate)
			}
		})
	})),
	on(ConditionalRewardAction.View, state => ({
		...state,
		isLoading: true,
		conditionalRewardDetailForm: reset(setValue(state.conditionalRewardDetailForm, ConditionalRewardState.initialConditionalRewardDetailFormValue))
	})),
	on(ConditionalRewardAction.ViewSuccess, (state, { payload }) => {
		let tiers: ConditionalRewardState.Tiers = {
			byId: {},
			allIds: []
		}

		payload.tiers.forEach(x => {
			const id = UUID.getUUID()

			tiers = {
				byId: {
					...tiers.byId,
					[id]: {
						id,
						campaigns: [...x.campaigns],
						form: ConditionalRewardState.validateTierForm(id, state)(getForm(id, x.fromValue, x.toValue))
					}
				},
				allIds: [...tiers.allIds, id]
			}

			const conditionalRewardDetailForm = setValue(state.conditionalRewardDetailForm, {
				...state.conditionalRewardDetailForm.value,
				tiers: {
					byId: {
						...tiers.byId,
						[id]: {
							id,
							campaigns: [],
							form: ConditionalRewardState.validateTierForm(id, state)(getForm(id))
						}
					},
					allIds: [...tiers && tiers.allIds, id]
				}
			})

			state = {
				...state,
				conditionalRewardDetailForm
			}

			state = validateAllTiers(state, new MarkAsTouchedAction(id))
		})

		state = {
			...state,
			isLoading: false,
			allowDelete: payload.allowDelete,
			allowRepublish: payload.status === 'RPA',
			conditionalRewardDetailForm: ConditionalRewardState.validateConditionalRewardDetailForm(state)(markAsTouched(setValue(state.conditionalRewardDetailForm, {
				resourceCode: payload.resourceCode,
				code: payload.code,
				name: payload.name,
				startDate: payload.startDate,
				endDate: payload.endDate,
				accumulationCycleType: (
					payload.accumulationCycleType === 'FD'
					|| payload.accumulationCycleType === 'ID'
					|| payload.accumulationCycleType === 'OD') ? 'Y' : (
						payload.accumulationCycleType === 'OC'
						|| payload.accumulationCycleType === 'BC'
						|| payload.accumulationCycleType === 'AC'
					) ? 'B' : payload.accumulationCycleType,
				accumulationCycleMonth: payload.accumulationCycleMonth,
				accumulationCycleDay: payload.accumulationCycleDay,
				accumulationCycleTypeOption: (
					payload.accumulationCycleType === 'FD'
					|| payload.accumulationCycleType === 'ID'
					|| payload.accumulationCycleType === 'OD'
					|| payload.accumulationCycleType === 'OC'
					|| payload.accumulationCycleType === 'BC'
					|| payload.accumulationCycleType === 'AC') ? payload.accumulationCycleType : '',
				rewardCreditMethod: payload.rewardCreditMethod,
				tiers,
				createdBy: payload.createdBy,
				createdDate: payload.createdDate,
				modifiedBy: payload.modifiedBy,
				modifiedDate: payload.modifiedDate,
			}))),
			conditionalRewardDetailFormView: {
				resourceCode: payload.resourceCode,
				code: payload.code,
				name: payload.name,
				startDate: payload.startDate,
				endDate: payload.endDate,
				accumulationCycleType: (
					payload.accumulationCycleType === 'FD'
					|| payload.accumulationCycleType === 'ID'
					|| payload.accumulationCycleType === 'OD') ? 'Y' : (
						payload.accumulationCycleType === 'OC'
						|| payload.accumulationCycleType === 'BC'
						|| payload.accumulationCycleType === 'AC'
					) ? 'B' : payload.accumulationCycleType,
				accumulationCycleMonth: payload.accumulationCycleMonth,
				accumulationCycleDay: payload.accumulationCycleDay,
				accumulationCycleTypeOption: (
					payload.accumulationCycleType === 'FD'
					|| payload.accumulationCycleType === 'ID'
					|| payload.accumulationCycleType === 'OD'
					|| payload.accumulationCycleType === 'OC'
					|| payload.accumulationCycleType === 'BC'
					|| payload.accumulationCycleType === 'AC') ? payload.accumulationCycleType : '',
				rewardCreditMethod: payload.rewardCreditMethod,
				tiers,
				createdBy: payload.createdBy,
				createdDate: payload.createdDate,
				modifiedBy: payload.modifiedBy,
				modifiedDate: payload.modifiedDate,
			}
		}

		return state
	}),
	on(ConditionalRewardAction.Create, state => ({
		...state,
		isLoading: true
	})),
	on(ConditionalRewardAction.CreateSuccess, state => ({
		...state,
		isLoading: false
	})),
	on(ConditionalRewardAction.Update, state => ({
		...state,
		isLoading: true
	})),
	on(ConditionalRewardAction.UpdateSuccess, state => ({
		...state,
		isLoading: false
	})),
	on(ConditionalRewardAction.Delete, state => ({
		...state,
		isLoading: true
	})),
	on(ConditionalRewardAction.DeleteSuccess, () => ConditionalRewardState.initialState),
	on(ConditionalRewardAction.Publish, state => ({
		...state,
		isLoading: true
	})),
	on(ConditionalRewardAction.PublishSuccess, () => ConditionalRewardState.initialState),
	on(ConditionalRewardAction.Republish, state => ({
		...state,
		isLoading: true
	})),
	on(ConditionalRewardAction.RepublishSuccess, () => ConditionalRewardState.initialState),
	on(ConditionalRewardAction.GetConditionalRewardData, state => ({
		...state,
		isLoading: true
	})),
	on(ConditionalRewardAction.GetConditionalRewardDataSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		conditionalRewardDataResponse: payload
	})),
	on(ConditionalRewardAction.GetCampaignList, state => ({
		...state,
		isLoading: true
	})),
	on(ConditionalRewardAction.GetCampaignListSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		conditionalRewardCampaignResponse: payload
	})),
	on(ConditionalRewardAction.OnChangeAccumulationCycleType, state => ({
		...state,
		conditionalRewardDetailForm: setValue(state.conditionalRewardDetailForm, {
			...state.conditionalRewardDetailForm.value,
			accumulationCycleTypeOption: '',
			accumulationCycleDay: '',
			accumulationCycleMonth: state.conditionalRewardDetailForm.controls.accumulationCycleType.value === 'Y' ? '' : state.conditionalRewardDetailForm.controls.accumulationCycleMonth.value
		})
	})),
	on(ConditionalRewardAction.OnChangeAccumulationCycleMonth, state => ({
		...state,
		conditionalRewardDetailForm: setValue(state.conditionalRewardDetailForm, {
			...state.conditionalRewardDetailForm.value,
			accumulationCycleDay: ''
		})
	})),
	on(ConditionalRewardAction.OnChangeYearlyCycle, state => ({
		...state,
		conditionalRewardDetailForm: setValue(state.conditionalRewardDetailForm, {
			...state.conditionalRewardDetailForm.value,
			accumulationCycleDay: '',
			accumulationCycleMonth: ''
		})
	})),
	on(ConditionalRewardAction.OnChangeChannel, state => ({
		...state,
		conditionalRewardDetailForm: setValue(state.conditionalRewardDetailForm, {
			...state.conditionalRewardDetailForm.value,
			accumulationCycleDay: '',
			accumulationCycleMonth: '',
			accumulationCycleTypeOption: ''
		})
	})),
	on(ConditionalRewardAction.OnChangeBillingCycleType, state => ({
		...state,
		conditionalRewardDetailForm: setValue(state.conditionalRewardDetailForm, {
			...state.conditionalRewardDetailForm.value,
			accumulationCycleDay: ''
		})
	})),
	on(ConditionalRewardAction.OnChangeRewardCreditMethod, state => ({
		...state,
		conditionalRewardDetailForm: setValue(state.conditionalRewardDetailForm, {
			...state.conditionalRewardDetailForm.value,
			accumulationCycleType: '',
			accumulationCycleMonth: '',
			accumulationCycleDay: '',
			accumulationCycleTypeOption: ''
		})
	})),
	on(ConditionalRewardAction.ResetTierDialog, state => ({
		...state,
		conditionalRewardDetailForm: setValue(state.conditionalRewardDetailForm, {
			...state.conditionalRewardDetailForm.value,
			tiers: {
				byId: {},
				allIds: []
			}
		})
	})),
	on(ConditionalRewardAction.AddTier, state => {
		const id = UUID.getUUID()
		const formControls = state.conditionalRewardDetailForm.controls
		const tiersVal = formControls.tiers.value

		const conditionalRewardDetailForm = setValue(state.conditionalRewardDetailForm, {
			...state.conditionalRewardDetailForm.value,
			tiers: {
				byId: {
					...tiersVal.byId,
					[id]: {
						id,
						campaigns: [],
						form: ConditionalRewardState.validateTierForm(id, state)(getForm(id))
					}
				},
				allIds: [...tiersVal && tiersVal.allIds, id]
			}
		})

		state = {
			...state,
			conditionalRewardDetailForm
		}

		state = validateAllTiers(state, new MarkAsTouchedAction(id))

		return state
	}),
	on(ConditionalRewardAction.AddCampaign, (state, { campaignId }) => {
		const tierId = state.tierId
		const formControls = state.conditionalRewardDetailForm.controls
		const tiersVal = formControls.tiers.value
		const tier = tiersVal.byId[tierId]

		return ({
			...state,
			conditionalRewardDetailForm: setValue(state.conditionalRewardDetailForm, {
				...state.conditionalRewardDetailForm.value,
				tiers: {
					byId: {
						...tiersVal.byId,
						[tierId]: {
							...tier,
							campaigns: [...tier.campaigns, campaignId]
						}
					},
					allIds: [...tiersVal && tiersVal.allIds]
				}
			})
		})
	}),
	on(ConditionalRewardAction.DeleteTier, state => {
		const tierId = state.tierId
		const formControls = state.conditionalRewardDetailForm.controls
		const tiersVal = formControls.tiers.value

		const allIds = tiersVal.allIds.filter(x => x !== tierId)
		const byId = allIds.reduce((obj, key) => {
			if (tiersVal.byId.hasOwnProperty(key)) {
				obj[key] = tiersVal.byId[key]
			}
			return obj
		}, {})

		state = {
			...state,
			conditionalRewardDetailForm: setValue(state.conditionalRewardDetailForm, {
				...state.conditionalRewardDetailForm.value,
				tiers: {
					byId,
					allIds
				}
			})
		}

		state = validateAllTiers(state, new MarkAsTouchedAction(tierId))

		return state
	}),
	on(ConditionalRewardAction.DeleteCampaign, (state, { campaignId }) => {
		const tierId = state.tierId
		const formControls = state.conditionalRewardDetailForm.controls
		const tiersVal = formControls.tiers.value
		const tier = tiersVal.byId[tierId]

		return ({
			...state,
			conditionalRewardDetailForm: setValue(state.conditionalRewardDetailForm, {
				...state.conditionalRewardDetailForm.value,
				tiers: {
					byId: {
						...tiersVal.byId,
						[tierId]: {
							...tier,
							campaigns: [...tier.campaigns.filter(x => x !== campaignId)]
						}
					},
					allIds: [...tiersVal && tiersVal.allIds]
				}
			})
		})
	})
)

export function Reducer(state: ConditionalRewardState.State = ConditionalRewardState.initialState, action: Action) {
	const conditionalRewardDetailForm = ConditionalRewardState.validateConditionalRewardDetailForm(state)(formGroupReducer(state.conditionalRewardDetailForm, action))
	if (conditionalRewardDetailForm !== state.conditionalRewardDetailForm) {
		state = { ...state, conditionalRewardDetailForm }
	}

	return reducer(state, action)
}

function getForm(id: string, fromValue?: string, toValue?: string) {
	if (fromValue || toValue) {
		const formGroup = createFormGroupState<ConditionalRewardState.TierForm>(id, {
			fromValue,
			toValue
		})
		return validateForm(id, formGroup)
	}

	return createFormGroupState<ConditionalRewardState.TierForm>(id,
		ConditionalRewardState.initialTierFormValue)
}

function validateForm(id: string, form: any, state?: ConditionalRewardState.State) {
	return markAsTouched(ConditionalRewardState.validateTierForm(id, state)(form))
}

function validateAllTiers(state: ConditionalRewardState.State, action: SetValueAction<unknown> | NgrxForms.MarkAsTouchedAction) {
	const tiersVal = state.conditionalRewardDetailForm.controls.tiers.value

	let tiers = {
		byId: {},
		allIds: []
	}

	tiersVal.allIds.forEach(tierId => {
		if (tiersVal.byId.hasOwnProperty(tierId)) {
			const byId = tiersVal.byId[tierId]
			const tierForm = formGroupReducer(byId.form, action)

			tiers = {
				byId: {
					...tiers.byId,
					[tierId]: {
						...byId,
						form: validateForm(tierId, tierForm, state)
					}
				},
				allIds: [...tiersVal && tiersVal.allIds]
			}
		}
	})

	return {
		...state,
		conditionalRewardDetailForm: setValue(state.conditionalRewardDetailForm, {
			...state.conditionalRewardDetailForm.value,
			tiers
		})
	}
}

function actionHandler(state: ConditionalRewardState.State, action: SetValueAction<unknown> | NgrxForms.MarkAsTouchedAction) {
	const controlId = action.controlId.split('.')[0]

	const formControls = state.conditionalRewardDetailForm.controls
	let tiersVal = formControls.tiers.value

	const newTiers: ConditionalRewardState.Tiers = {
		byId: {
			...tiersVal.byId
		},
		allIds: {
			...tiersVal.allIds
		}
	}

	const tier = newTiers.byId[controlId]

	if (tier) {
		let form = formGroupReducer(tier.form, action)

		if (action.type === 'ngrx/forms/SET_VALUE') {
			form = formGroupReducer(tier.form, new SetValueAction(action.controlId, action.value))
		}

		tiersVal = {
			byId: {
				...tiersVal.byId,
				[controlId]: {
					...tiersVal.byId[controlId],
					form
				}
			},
			allIds: [...tiersVal.allIds]
		}

		state = {
			...state,
			conditionalRewardDetailForm: setValue(state.conditionalRewardDetailForm, {
				...state.conditionalRewardDetailForm.value,
				tiers: tiersVal
			})
		}

		state = validateAllTiers(state, action)
	}

	return state
}
