import { DashboardRedemptionRewardPoolViewResponse, DashboardRedemptionRewardPoolGroupViewResponse, DashboardRedemptionCatalogueListViewResponse, CodeOptionListViewResponse, DashboardRedemptionItemResponse, DashboardWaiverRedemptionItemOptionListResponse } from 'src/app/models/dashboard/dashboard-redemption'
import { createFormGroupState, FormGroupState, updateGroup } from 'ngrx-forms'
import { Util } from 'src/app/models/util/util'

export interface State {
	isLoading: boolean
	rewardPoolIsLoading: boolean
	rewardPoolGroupIsLoading: boolean
	redemptionItemIsLoading: boolean
	catalogueListIsLoading: boolean

	rewardPoolViewResponse: DashboardRedemptionRewardPoolViewResponse
	rewardPoolGroupViewResponse: DashboardRedemptionRewardPoolGroupViewResponse
	redemptionItemViewResponse: DashboardRedemptionItemResponse
	catalogueListViewResponse: DashboardRedemptionCatalogueListViewResponse[]

	rewardPoolOptionList: CodeOptionListViewResponse[]
	rewardPoolGroupOptionList: CodeOptionListViewResponse[]
	redemptionItemOptionList: CodeOptionListViewResponse[]
	waiverRedemptionItemOptionList: DashboardWaiverRedemptionItemOptionListResponse[]

	dashboardRedemptionRequestForm: FormGroupState<DashboardRedemptionRequestData>
}

export interface DashboardRedemptionRequestData {
	rewardPoolId: number
	customRewardPoolStartDate: string
	rewardPoolStartMonth: string
	rewardPoolEndMonth: string
	rewardPoolGroupId: number
	customRewardPoolGroupStartDate: string
	rewardPoolGroupStartMonth: string
	rewardPoolGroupEndMonth: string
	redemptionItemId: number
	customRedemptionItemStartDate: string
	redemptionItemStartMonth: string
	redemptionItemEndMonth: string
}

export const initialDashboardRedemptionRequestFormValue: DashboardRedemptionRequestData = {
	rewardPoolId: 0,
	customRewardPoolStartDate: Util.minusDayFromToday(31),
	rewardPoolStartMonth: Util.minusDayFromToday(31),
	rewardPoolEndMonth: Util.minusDayFromToday(1),
	rewardPoolGroupId: 0,
	customRewardPoolGroupStartDate: Util.minusDayFromToday(31),
	rewardPoolGroupStartMonth: Util.minusDayFromToday(31),
	rewardPoolGroupEndMonth: Util.minusDayFromToday(1),
	redemptionItemId: 0,
	customRedemptionItemStartDate: Util.minusDayFromToday(31),
	redemptionItemStartMonth: Util.minusDayFromToday(31),
	redemptionItemEndMonth: Util.minusDayFromToday(1)
}

export const initialDashboardRedemptionViewRequestForm = createFormGroupState('dashboardRedemptionRequestForm', initialDashboardRedemptionRequestFormValue)

export const validateDashboardRedemptionViewRequestForm = (state: State) => updateGroup<DashboardRedemptionRequestData>({
})

export const initialState: State = {
	isLoading: false,
	rewardPoolIsLoading: false,
	rewardPoolGroupIsLoading: false,
	redemptionItemIsLoading: false,
	catalogueListIsLoading: false,
	dashboardRedemptionRequestForm: initialDashboardRedemptionViewRequestForm,
	rewardPoolViewResponse: {
		rewardPoolId: 0,
		rewardPoolName: '',
		total: 0,
		data: []
	},
	rewardPoolGroupViewResponse: {
		rewardPoolGroupId: 0,
		rewardPoolGroupName: '',
		total: 0,
		data: []
	},
	redemptionItemViewResponse: {
		cashback: {
			id: 0,
			name: '',
			kind: '',
			total: 0,
			data: []
		},
		waiver: {
			id: 0,
			name: '',
			kind: '',
			total: 0,
			data: []
		},
		partner: {
			id: 0,
			name: '',
			kind: '',
			total: 0,
			data: []
		},
		onTheSpot: {
			id: 0,
			name: '',
			kind: '',
			total: 0,
			data: []
		}
	},
	catalogueListViewResponse: [],
	rewardPoolOptionList: [],
	rewardPoolGroupOptionList: [],
	redemptionItemOptionList: [],
	waiverRedemptionItemOptionList: []
}
