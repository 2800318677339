import { createFormGroupState, FormGroupState, updateGroup, validate } from 'ngrx-forms'
import { required } from 'ngrx-forms/validation'
import { SimulationResult, TicketSequence } from 'src/app/models/campaign-management/simulator/simulator'
import { SimulatorDataResponse, SimulatorKeyDataResponse } from 'src/app/models/campaign-management/simulator/simulator-data'
import { SimulatorViewResponse } from 'src/app/models/campaign-management/simulator/simulator-view'
import { FieldValidator } from 'src/app/models/util/field.validator'
import { Util } from 'src/app/models/util/util'

export interface State {
	isLoading: boolean
	isTicketLoading: boolean
	isTicketDialog: boolean
	id?: number
	isSimulationQueue?: boolean

	// Listing
	ticketSequences: TicketSequence[]
	simulationResults: SimulationResult[]
	// View
	simulatorViewResponse: SimulatorViewResponse
	// Detail
	simulatorDetailForm: FormGroupState<SimulatorDetailForm>
	// option list
	simulatorDataResponse: SimulatorDataResponse
	simulatorKeyDataResponse: SimulatorKeyDataResponse
}

export const initialSimulatorViewResponse: SimulatorViewResponse = {
	id: 0,
	ticketType: '',
	simulatorTarget: '',
	resourceCode: '',
	key: 0,
	startDate: '',
	endDate: '',
	result: {
		name: '',
		transactionHit: '',
		totalAmountHit: '',
		totalRewardedPoint: '',
		channel: '',
		priority: '',
		rulesetList: [],
		tierList: [],
		resultList: []
	},
	status: '',
	sequence: 0,
	createdBy: '',
	createdDate: '',
	modifiedBy: '',
	modifiedDate: ''
}

export interface SimulatorDetailForm {
	ticketType: string
	simulatorTarget: string
	resourceCode: string
	key: string
	startDate: string
	endDate: string
}

export const initialSimulatorDetailFormValue: SimulatorDetailForm = {
	ticketType: '',
	simulatorTarget: '',
	resourceCode: '',
	key: '',
	startDate: '',
	endDate: ''
}

export const initialSimulatorDetailForm = createFormGroupState('simulatorDetailForm', initialSimulatorDetailFormValue)

export const validateSimulatorDetailForm = (state: State) => {
	const resourceCodeCondition = state && (state.simulatorDetailForm.controls.simulatorTarget.value === 'C' ||
		state.simulatorDetailForm.controls.simulatorTarget.value === 'CR')
	let dateRangeCondition = false
	const startDate = state && state.simulatorDetailForm.value.startDate
	const endDate = state && state.simulatorDetailForm.value.endDate

	if (startDate && endDate) {
		dateRangeCondition = Util.diffInMonths(startDate, endDate) >= 3
	}

	return updateGroup<SimulatorDetailForm>({
		ticketType: validate([required]),
		simulatorTarget: validate([required]),
		resourceCode: validate([FieldValidator.requiredIfTrue(resourceCodeCondition)]),
		key: validate([required]),
		startDate: validate([FieldValidator.ifTrue(dateRangeCondition, 'dateBetween'), required]),
		endDate: validate([required])
	})
}

export const initialState: State = {
	isLoading: false,
	isTicketLoading: false,
	isTicketDialog: false,
	ticketSequences: [],
	simulationResults: [],
	simulatorViewResponse: initialSimulatorViewResponse,
	simulatorDetailForm: initialSimulatorDetailForm,
	simulatorDataResponse: {
		channelList: [],
		simulatorTargetList: [],
		conflictDataSimulatorTargetList: []
	},
	simulatorKeyDataResponse: {
		conflictDataSimulatorKeyData: {
			campaignList: [],
			conditionalRewardList: []
		},
		rawDataSimulatorKeyData: {
			campaignList: [],
			conditionalRewardList: [],
			productBundlingList: []
		}
	}
}


