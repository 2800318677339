import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'
import { FormGroupState } from 'ngrx-forms'
import { Observable, ReplaySubject, Subscription } from 'rxjs'
import * as AppStore from 'src/app/store'
import * as RedeemPartnerSelector from 'src/app/store/redemption-settings/redeem-partner/redeem-partner.selectors'
import { KeyValue } from '@angular/common'
import { RedeemPartnerRewardPool, RedeemPartnerRewardPoolForm } from 'src/app/store/redemption-settings/redeem-partner/redeem-partner.state'
import { FormControl } from '@angular/forms'
import { FilterPredicate } from 'src/app/models/util/filter-predicate'


@Component({
	selector: 'app-redeem-partner-reward-pool',
	templateUrl: './redeem-partner-reward-pool.component.html',
	styleUrls: ['./redeem-partner-reward-pool.component.scss']
})
export class RedeemPartnerRewardPoolComponent implements OnInit, OnDestroy {

	@Input() redeemPartnerRewardPool: RedeemPartnerRewardPool

	action: string
	formState$: Observable<FormGroupState<RedeemPartnerRewardPoolForm>>
	glRedemptionList: KeyValue<number, string>[] = []
	glOptionList$: Observable<KeyValue<number, string>[]>
	subs: Subscription
	selectedValue: any = ''

	public filteredGlRedemption: ReplaySubject<KeyValue<number, string>[]> = new ReplaySubject<KeyValue<number, string>[]>(1)
	public dropDownCtrlGlRedemption: FormControl = new FormControl(this.selectedValue)

	constructor(
		private store: Store<AppStore.State>
	) {
		this.subs = this.store.select(RedeemPartnerSelector.getRedeemPartner).subscribe(x => {
			this.action = x.action
			this.glRedemptionList = x.glRedemptions

			if (this.glRedemptionList) {
				this.filteredGlRedemption.next(this.glRedemptionList.slice())
			}
		})
		this.glOptionList$ = this.store.select(({ redeemPartner }) => redeemPartner.glRedemptions)
	}

	get isView(): boolean { return this.action === 'VIEW' }

	ngOnInit() {
		this.formState$ = this.store.select(RedeemPartnerSelector.getRedeemPartnerRewardPool, this.redeemPartnerRewardPool.id)
		this.dropDownCtrlGlRedemption.valueChanges
			.subscribe(value => {
				const maxChar = 255
				if (value.length > maxChar) {
					const newValue = value.slice(0, maxChar)
					this.dropDownCtrlGlRedemption.reset('', { emitEvent: false })
					this.dropDownCtrlGlRedemption.setValue(newValue)

				} else {
					this.searchGlRedemption(value)
				}
			})

	}

	ngOnDestroy() {
		this.subs.unsubscribe()
	}

	getGlRedemption(value: number): string {
		const result = this.glRedemptionList.find(x => x.key === value)
		return result ? result.value : ''
	}

	searchGlRedemption(search: string) {
		if (!this.glRedemptionList) {
			return
		}
		if (!search) {
			this.filteredGlRedemption.next(this.glRedemptionList.slice())
			return
		} else {
			search = search.toLowerCase()
		}
		// filter the records
		this.filteredGlRedemption.next(
			this.glRedemptionList.filter(glRedemption => FilterPredicate.isIdenticalTo(search)([glRedemption.value])))
	}

	clearSearchGlRedemption() {
		this.filteredGlRedemption.next(this.glRedemptionList.slice())
	}
}
