import { createFormGroupState, FormGroupState, updateGroup, validate } from 'ngrx-forms'
import { maxLength, required } from 'ngrx-forms/validation'
import { FieldValidator } from 'src/app/models/util/field.validator'
import { Option } from 'src/app/models/option/option'
import { FulfilmentPartnerListResponse } from 'src/app/models/catalogue-management/fulfilment-partner/fulfilment-partner-list'

export interface State {
	isLoading: boolean
	id?: number
	fulfilmentPartnerListResponse: FulfilmentPartnerListResponse
	fulfilmentPartnerDetailForm: FormGroupState<FulfilmentPartnerDetailForm>
	fileFrequencyList: Option[]
	fulfilmentPartnerDetailFormView: FulfilmentPartnerDetailForm
	fulfilmentPartnerDuplicateForm: FormGroupState<FulfilmentPartnerDuplicateForm>
	isDuplicateLoading: boolean
	isDuplicateDialog: boolean
}

export interface FulfilmentPartnerDetailForm {
	code: string
	name: string
	fileFrequency: string
	createdBy: string,
	createdDate: string,
	modifiedBy: string,
	modifiedDate: string
	allowDelete: boolean
}

export const initialFulfilmentPartnerDetailFormValue: FulfilmentPartnerDetailForm = {
	code: '',
	name: '',
	fileFrequency: '',
	createdBy: '',
	createdDate: '',
	modifiedBy: '',
	modifiedDate: '',
	allowDelete: true
}

export const initialFulfilmentPartnerDetailForm = createFormGroupState('fulfilmentPartnerDetailForm', initialFulfilmentPartnerDetailFormValue)

export const validateFulfilmentPartnerDetailForm = updateGroup<FulfilmentPartnerDetailForm>({
	code: validate([required, maxLength(10), FieldValidator.alphaNumericWithoutSpaces()]),
	name: validate([required, maxLength(40), FieldValidator.checkValidCharacters()]),
	fileFrequency: validate([required]),
})

export interface FulfilmentPartnerDuplicateForm {
	code: string,
	name: string
}

export const initialFulfilmentPartnerDuplicateFormValue: FulfilmentPartnerDuplicateForm = {
	code: '',
	name: ''
}

export const initialFulfilmentPartnerDuplicateForm = createFormGroupState('fulfilmentPartnerDuplicateForm', initialFulfilmentPartnerDuplicateFormValue)

export const validateFulfilmentPartnerDuplicateForm = updateGroup<FulfilmentPartnerDuplicateForm>({
	code: validate([required, maxLength(10), FieldValidator.alphaNumericWithoutSpaces()]),
	name: validate([required, maxLength(40), FieldValidator.checkValidCharacters()]),
})

export const initialState: State = {
	isLoading: false,
	fulfilmentPartnerListResponse: {
		fulfilmentPartners: []
	},
	fulfilmentPartnerDetailForm: initialFulfilmentPartnerDetailForm,
	fileFrequencyList: [],
	fulfilmentPartnerDetailFormView: initialFulfilmentPartnerDetailFormValue,
	fulfilmentPartnerDuplicateForm: initialFulfilmentPartnerDuplicateForm,
	isDuplicateLoading: false,
	isDuplicateDialog: false
}
