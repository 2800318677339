import { createFormGroupState, FormGroupState, updateGroup, validate } from 'ngrx-forms'
import { maxLength, required } from 'ngrx-forms/validation'
import { AdjustmentFile } from 'src/app/models/adjustment/adjustment-list'
import { KeyValue } from '@angular/common'
import { AdjustmentFileContent } from 'src/app/models/adjustment/adjustment'

export interface State {
	isLoading: boolean
	cachedResourceCode: string | null
	adjustmentFileList: AdjustmentFile[]
	resourceList: KeyValue<string, string>[]
	adjustmentFileContent: AdjustmentFileContent[]
	dataId: number
}

export interface PointAdjustmentFileUploadForm {
	fileName: string,
	file: string,
	resourceCode: string,
}

export const initialPointAdjustmentFileUploadFormValue: PointAdjustmentFileUploadForm = {
	fileName: '',
	file: null,
	resourceCode: '',
}

export const initialPointAdjustmentFileUploadForm = createFormGroupState('pointAdjustmentFileUploadForm', initialPointAdjustmentFileUploadFormValue)

export const validatePointAdjustmentFileUploadForm = updateGroup<PointAdjustmentFileUploadForm>({
	fileName: validate([required]),
	file: validate([required]),
	resourceCode: validate([required])
})

export const initialState: State = {
	isLoading: false,
	cachedResourceCode: null,
	adjustmentFileList: [],
	resourceList: [],
	adjustmentFileContent: [],
	dataId: 0
}
