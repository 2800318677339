import { Component, OnDestroy, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'
import { Subscription } from 'rxjs'
import * as AppStore from 'src/app/store'
import * as CommonAction from 'src/app/store/common/common'
import * as UserProfileAction from 'src/app/store/user-profile'
import * as ActionBarAction from 'src/app/store/common/action-bar/action-bar.actions'
import { Router } from '@angular/router'
import { environment } from 'src/environments/environment'

@Component({
	selector: 'app-navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnDestroy {

	orgName: string
	toggleFlag: boolean
	subs: Subscription
	version: string

	constructor(
		private router: Router,
		private store: Store<AppStore.State>
	) { }

	ngOnInit() {
		setInterval(() => { this.version = environment.build }, 1000)
		this.subs = this.store.select(CommonAction.getSideNavFlag).subscribe(x => {
			this.toggleFlag = x
		})
		window.onresize = () => this.sideNavBarController()
		this.sideNavBarController()
	}

	ngOnDestroy() {
		this.subs.unsubscribe()
	}

	toggle() {
		this.store.dispatch(CommonAction.ToggleSidenav({ flag: this.toggleFlag }))
	}

	goUserProfile() {
		if (this.router.url !== '/user-profile') {
			this.store.dispatch(UserProfileAction.InitialState())
			this.store.dispatch(ActionBarAction.UserProfileView())
			this.store.dispatch(UserProfileAction.View())
		}
	}

	logout() {
		this.store.dispatch(CommonAction.Logout())
	}

	sideNavBarController() {
		this.store.dispatch(CommonAction.ToggleSidenav({ flag: (window.outerWidth < 720) }))
	}
}
