<app-spinner [isLoading]="isLoading"></app-spinner>

<div class="title-div" fxLayout="row" fxLayoutAlign="space-between center">
  <span *ngIf="isCreate" class="title"> {{ 'CAMPAIGN.CREATE_RULESET' | translate }} </span>
  <span *ngIf="isUpdate" class="title"> {{ 'CAMPAIGN.UPDATE_RULESET' | translate }} </span>
  <span *ngIf="isView" class="title"> {{ 'CAMPAIGN.VIEW_RULESET' | translate }} </span>
  <button class="close-icon" mat-icon-button (click)="cancel()">
    <mat-icon>close</mat-icon>
  </button>
</div>

<div *ngIf="isView">
  <mat-dialog-content #container>
    <mat-horizontal-stepper #stepper [selectedIndex]="selectedIndex"
      (selectionChange)="selectionChange($event);container.scrollTop = 0;">

      <ng-template matStepperIcon="edit">
        <mat-icon>done</mat-icon>
      </ng-template>

      <ng-template matStepperIcon="error">
        <mat-icon>error</mat-icon>
      </ng-template>

      <mat-step #step completed>
        <ng-template matStepLabel>{{ 'CAMPAIGN.RULESET_CONDITIONS' | translate }}</ng-template>

        <form novalidate [ngrxFormState]="formState$ | async">
          <div fxLayout="row" fxLayoutGap="5px">
            <mat-form-field fxFlex="50">
              <mat-label>{{ 'CAMPAIGN.RULESET_CODE' | translate }}</mat-label>
              <input matInput value="{{(formState$ | async).controls.code.value}}" type="text" maxlength="10" readonly>
            </mat-form-field>

            <mat-form-field fxFlex="50">
              <mat-label>{{ 'CAMPAIGN.RULESET_NAME' | translate }}</mat-label>
              <input matInput value="{{(formState$ | async).controls.name.value}}" type="text" maxlength="40" readonly>
            </mat-form-field>
          </div>

          <div fxLayout="column">
            <mat-form-field fxFlex>
              <mat-label>{{ 'CAMPAIGN.CHANNEL' | translate }}</mat-label>
              <input matInput value="{{getChannelValue((formState$ | async).controls.channel.value)}}" type="text"
                maxlength="255" readonly>
            </mat-form-field>
          </div>
        </form>

        <div *ngIf="(formState$ | async).controls.channel.value" fxLayout="column">
          <div *ngFor="let ruleset of (rulesets$ | async);trackBy: trackByIndex;let i=index" fxFlexOffset="8px">
            <div [ngSwitch]="ruleset.ruleAddType">
              <app-rule-dynamic *ngSwitchCase="'RULE'" [ruleset]="ruleset"></app-rule-dynamic>
              <app-rule-group *ngSwitchCase="'CONDITIONAL_RULES'" [ruleset]="ruleset"></app-rule-group>
            </div>
          </div>
        </div>

      </mat-step>
      <mat-step completed>
        <ng-template matStepLabel>{{ 'CAMPAIGN.REWARD' | translate }}</ng-template>

        <form novalidate [ngrxFormState]="rewardFormState$ | async" fxLayout="column">
          <mat-form-field>
            <mat-label>{{ 'CAMPAIGN.REWARD_POOL' | translate }}</mat-label>
            <input matInput value="{{getRewardPoolValue((rewardFormState$ | async).controls.rewardPool.value)}}"
              type="text" maxlength="255" readonly>
          </mat-form-field>

          <div *ngIf="(rewardFormState$ | async).controls.rewardPool.value" fxLayout="column">
            <mat-form-field *ngIf="resourceType === 'BANK'">
              <mat-label>{{ 'CAMPAIGN.FIELD_MAPPING' | translate }}</mat-label>
              <input matInput value="{{getAmountValue((rewardFormState$ | async).controls.amountField.value)}}"
                type="text" maxlength="255" readonly>
            </mat-form-field>
            <br>
            <span class="content-title">{{ 'CAMPAIGN.REWARD_CONDITIONS' | translate }}</span>
            <br>
            <mat-form-field>
              <mat-label>{{ 'CAMPAIGN.TYPE' | translate }}</mat-label>
              <input matInput value="{{getRewardTypeValue((rewardFormState$ | async).controls.type.value)}}" type="text"
                maxlength="255" readonly>
            </mat-form-field>

            <div *ngIf="(rewardFormState$ | async).controls.type.value === 'X2X'" fxLayout="column">
              <mat-form-field>
                <mat-label>{{ 'CAMPAIGN.REWARD_CREDIT_METHOD' | translate }}</mat-label>
                <input matInput
                  value="{{getRewardCreditMethodValue((rewardFormState$ | async).controls.rewardCreditMethod.value)}}"
                  type="text" maxlength="255" readonly>
              </mat-form-field>

              <div *ngIf="(rewardFormState$ | async).controls.type.value === 'X2X' &&
            (rewardFormState$ | async).controls.rewardCreditMethod.value === 'CCE'" fxLayout="column">
                <mat-form-field>
                  <mat-label>{{ 'CAMPAIGN.CYCLE' | translate }}</mat-label>
                  <input matInput
                    value="{{getAccumulationCycleTypeValue((rewardFormState$ | async).controls.accumulationCycleType.value)}}"
                    type="text" maxlength="255" readonly>
                </mat-form-field>

                <div *ngIf="(rewardFormState$ | async).controls.accumulationCycleType.value === 'W'">
                  <mat-form-field fxFlex>
                    <input matInput
                      value="{{getDayValue((rewardFormState$ | async).controls.accumulationCycleDay.value)}}"
                      type="text" maxlength="255" readonly>
                  </mat-form-field>
                </div>

                <div *ngIf="(rewardFormState$ | async).controls.accumulationCycleType.value === 'M'">
                  <mat-form-field fxFlex>
                    <input matInput
                      value="{{getDayOfMonthValue((rewardFormState$ | async).controls.accumulationCycleDay.value)}}"
                      type="text" maxlength="255" readonly>
                  </mat-form-field>
                </div>

                <div *ngIf="(rewardFormState$ | async).controls.accumulationCycleType.value === 'Y' && (rewardFormState$ | async).controls.accumulationCycleTypeOption.value">
                  <mat-form-field fxFlex>
                    <input matInput
                      value="{{getYearlyCycleValue((rewardFormState$ | async).controls.accumulationCycleTypeOption.value)}}"
                      type="text" maxlength="255" readonly>
                  </mat-form-field>
                </div>

                <div *ngIf="(rewardFormState$ | async).controls.accumulationCycleType.value === 'Y' && ((rewardFormState$ | async).controls.accumulationCycleTypeOption.value === 'FD' || channelValue != 'CARDLINK')">
                  <mat-form-field fxFlex>
                    <input matInput
                      value="{{getMonthValue((rewardFormState$ | async).controls.accumulationCycleMonth.value)}}"
                      type="text" maxlength="255" readonly>
                  </mat-form-field>
                </div>

                <div *ngIf="(rewardFormState$ | async).controls.accumulationCycleType.value === 'Y' &&
                   (rewardFormState$ | async).controls.accumulationCycleMonth.value &&  ((rewardFormState$ | async).controls.accumulationCycleTypeOption.value === 'FD' || channelValue != 'CARDLINK')">
                  <mat-form-field fxFlex>
                    <input matInput
                      value="{{getDayOfMonthValue((rewardFormState$ | async).controls.accumulationCycleDay.value)}}"
                      type="text" maxlength="255" readonly>
                  </mat-form-field>
                </div>

                <div *ngIf="(rewardFormState$ | async).controls.accumulationCycleType.value === 'B'" fxLayout="row" fxLayout.lt-md="column"
                  fxLayoutGap="5px" fxLayoutGap.lt-md="0px">
                  <mat-form-field fxFlex>
                    <input matInput value="{{getBillingCycleType((rewardFormState$ | async).controls.billingCycleType.value)}}"
                      type="text" maxlength="255" readonly>
                  </mat-form-field>
                </div>

                <div
                  *ngIf="(rewardFormState$ | async).controls.billingCycleType.value === 'BC' || (rewardFormState$ | async).controls.billingCycleType.value === 'AC'">
                  <mat-form-field fxFlex>
                    <input matInput
                      value="{{getDayOfMonthValue((rewardFormState$ | async).controls.numberOfDays.value)}}"
                      type="text" maxlength="255" readonly>
                  </mat-form-field>
                </div>
              </div>

              <mat-form-field>
                <mat-label>{{ 'CAMPAIGN.TRANSACTION_METHOD' | translate }}</mat-label>
                <input matInput
                  value="{{getTransactionMethodValue((rewardFormState$ | async).controls.transactionMethod.value)}}"
                  type="text" maxlength="255" readonly>
              </mat-form-field>

              <div *ngIf="(rewardFormState$ | async).controls.transactionMethod.value === 'AMOUNT'" fxLayout="column">
                <mat-form-field fxFlex>
                  <mat-label>{{ 'CAMPAIGN.CALCULATION_METHOD' | translate }}</mat-label>
                  <input matInput value="Ratio" type="text" maxlength="255" readonly>
                </mat-form-field>

                <div fxLayout="row" fxFlex>
                  <mat-form-field fxFlex="48">
                    <span matPrefix>{{ 'CAMPAIGN.AMOUNT' | translate }} &nbsp;</span>
                    <input matInput value="{{(rewardFormState$ | async).controls.valueFactor.value}}" type="text"
                      maxlength="13" readonly>
                  </mat-form-field>
                  <h3 fxFlex="4" style="text-align: center;">=</h3>
                  <mat-form-field fxFlex="48">
                    <span matSuffix>&nbsp; {{ 'CAMPAIGN.POINTS' | translate }}</span>
                    <input matInput style="text-align:right"
                      value="{{(rewardFormState$ | async).controls.pointFactor.value}}" type="text" maxlength="15"
                      readonly>
                  </mat-form-field>
                </div>
              </div>

              <div *ngIf="(rewardFormState$ | async).controls.transactionMethod.value === 'SWIPE'" fxLayout="column">
                <mat-form-field fxFlex>
                  <mat-label>{{ 'CAMPAIGN.CALCULATION_METHOD' | translate }}</mat-label>
                  <input matInput value="Fixed Points" type="text" maxlength="10" readonly>
                </mat-form-field>

                <mat-form-field fxFlex>
                  <span matSuffix>&nbsp; {{ 'CAMPAIGN.POINTS' | translate }}</span>
                  <input matInput value="{{(rewardFormState$ | async).controls.pointFactor.value}}" type="text"
                    maxlength="15" readonly>
                </mat-form-field>
              </div>
            </div>

            <div *ngIf="(rewardFormState$ | async).controls.type.value === 'OX'" fxLayout="column">
              <mat-form-field>
                <mat-label>{{ 'CAMPAIGN.REWARD_CREDIT_METHOD' | translate }}</mat-label>
                <input matInput
                  value="{{getRewardCreditMethodValue((rewardFormState$ | async).controls.rewardCreditMethod.value)}}"
                  type="text" maxlength="255" readonly>
              </mat-form-field>

              <div *ngIf="(rewardFormState$ | async).controls.type.value === 'OX' &&
            ((rewardFormState$ | async).controls.rewardCreditMethod.value === 'I' ||
            (rewardFormState$ | async).controls.rewardCreditMethod.value === 'CCE')" fxLayout="column">
                <mat-form-field>
                  <mat-label>{{ 'CAMPAIGN.CYCLE' | translate }}</mat-label>
                  <input matInput
                    value="{{getAccumulationCycleTypeValue((rewardFormState$ | async).controls.accumulationCycleType.value)}}"
                    type="text" maxlength="255" readonly>
                </mat-form-field>

                <div *ngIf="(rewardFormState$ | async).controls.accumulationCycleType.value === 'W'">
                  <mat-form-field fxFlex>
                    <input matInput
                      value="{{getDayValue((rewardFormState$ | async).controls.accumulationCycleDay.value)}}"
                      type="text" maxlength="255" readonly>
                  </mat-form-field>
                </div>

                <div *ngIf="(rewardFormState$ | async).controls.accumulationCycleType.value === 'M'">
                  <mat-form-field fxFlex>
                    <input matInput
                      value="{{getDayOfMonthValue((rewardFormState$ | async).controls.accumulationCycleDay.value)}}"
                      type="text" maxlength="255" readonly>
                  </mat-form-field>
                </div>

                <div *ngIf="(rewardFormState$ | async).controls.accumulationCycleType.value === 'Y' && (rewardFormState$ | async).controls.accumulationCycleTypeOption.value">
                  <mat-form-field fxFlex>
                    <input matInput
                      value="{{getYearlyCycleValue((rewardFormState$ | async).controls.accumulationCycleTypeOption.value)}}"
                      type="text" maxlength="255" readonly>
                  </mat-form-field>
                </div>

                <div *ngIf="(rewardFormState$ | async).controls.accumulationCycleType.value === 'Y' && ((rewardFormState$ | async).controls.accumulationCycleTypeOption.value === 'FD' || channelValue != 'CARDLINK')">
                  <mat-form-field fxFlex>
                    <input matInput
                      value="{{getMonthValue((rewardFormState$ | async).controls.accumulationCycleMonth.value)}}"
                      type="text" maxlength="255" readonly>
                  </mat-form-field>
                </div>

                <div *ngIf="(rewardFormState$ | async).controls.accumulationCycleType.value === 'Y' &&
                   (rewardFormState$ | async).controls.accumulationCycleMonth.value && ((rewardFormState$ | async).controls.accumulationCycleTypeOption.value === 'FD' || channelValue != 'CARDLINK')">
                  <mat-form-field fxFlex>
                    <input matInput
                      value="{{getDayOfMonthValue((rewardFormState$ | async).controls.accumulationCycleDay.value)}}"
                      type="text" maxlength="255" readonly>
                  </mat-form-field>
                </div>

                <div *ngIf="(rewardFormState$ | async).controls.accumulationCycleType.value === 'B'" fxLayout="row" fxLayout.lt-md="column"
                  fxLayoutGap="5px" fxLayoutGap.lt-md="0px">
                  <mat-form-field fxFlex>
                    <input matInput value="{{getBillingCycleType((rewardFormState$ | async).controls.billingCycleType.value)}}"
                      type="text" maxlength="255" readonly>
                  </mat-form-field>
                </div>

                <div
                  *ngIf="(rewardFormState$ | async).controls.billingCycleType.value === 'BC' || (rewardFormState$ | async).controls.billingCycleType.value === 'AC'">
                  <mat-form-field fxFlex>
                    <input matInput
                      value="{{getDayOfMonthValue((rewardFormState$ | async).controls.numberOfDays.value)}}"
                      type="text" maxlength="255" readonly>
                  </mat-form-field>
                </div>
              </div>

              <mat-form-field>
                <mat-label>{{ 'CAMPAIGN.TRANSACTION_METHOD' | translate }}</mat-label>
                <input matInput
                  value="{{getTransactionsMethodValue((rewardFormState$ | async).controls.transactionMethod.value)}}"
                  type="text" maxlength="255" readonly>
              </mat-form-field>

              <div *ngIf="(rewardFormState$ | async).controls.transactionMethod.value === 'AMOUNT'" fxLayout="column">
                <div fxLayout="row" fxFlex>
                  <mat-form-field fxFlex="48">
                    <span matPrefix>{{ 'CAMPAIGN.AMOUNT' | translate }} &nbsp;</span>
                    <input matInput value="{{(rewardFormState$ | async).controls.conditionFactor.value}}" type="text"
                      maxlength="255" readonly>
                  </mat-form-field>
                  <h3 fxFlex="4" style="text-align: center;">=</h3>
                  <mat-form-field fxFlex="48">
                    <span matSuffix>&nbsp; {{ 'CAMPAIGN.POINTS' | translate }}</span>
                    <input matInput style="text-align:right"
                      value="{{(rewardFormState$ | async).controls.pointFactor.value}}" type="text" maxlength="255"
                      readonly>
                  </mat-form-field>
                </div>
              </div>

              <div *ngIf="(rewardFormState$ | async).controls.transactionMethod.value === 'SWIPE'" fxLayout="column">
                <div fxLayout="row" fxFlex>
                  <mat-form-field fxFlex="48">
                    <span matPrefix>{{ 'CAMPAIGN.NUMBER_OF_TRANSACTIONS' | translate }} &nbsp;</span>
                    <input matInput value="{{(rewardFormState$ | async).controls.conditionFactor.value}}" type="text"
                      maxlength="10" readonly>
                  </mat-form-field>
                  <h3 fxFlex="4" style="text-align: center;">=</h3>
                  <mat-form-field fxFlex="48">
                    <span matSuffix>&nbsp; {{ 'CAMPAIGN.POINTS' | translate }}</span>
                    <input matInput style="text-align:right"
                      value="{{(rewardFormState$ | async).controls.pointFactor.value}}" type="text" maxlength="15"
                      readonly>
                  </mat-form-field>
                </div>
              </div>
            </div>

            <div *ngIf="(rewardFormState$ | async).controls.type.value === 'PX'" fxLayout="column">
              <mat-form-field>
                <mat-label>{{ 'CAMPAIGN.REWARD_CREDIT_METHOD' | translate }}</mat-label>
                <input matInput
                  value="{{getRewardCreditMethodValue((rewardFormState$ | async).controls.rewardCreditMethod.value)}}"
                  type="text" maxlength="255" readonly>
              </mat-form-field>

              <div *ngIf="(rewardFormState$ | async).controls.type.value === 'PX' &&
            ((rewardFormState$ | async).controls.rewardCreditMethod.value === 'I' ||
            (rewardFormState$ | async).controls.rewardCreditMethod.value === 'CCE')" fxLayout="column">
                <mat-form-field>
                  <mat-label>{{ 'CAMPAIGN.CYCLE' | translate }}</mat-label>
                  <input matInput
                    value="{{getAccumulationCycleTypeValue((rewardFormState$ | async).controls.accumulationCycleType.value)}}"
                    type="text" maxlength="255" readonly>
                </mat-form-field>

                <div *ngIf="(rewardFormState$ | async).controls.accumulationCycleType.value === 'W'">
                  <mat-form-field fxFlex>
                    <input matInput
                      value="{{getDayValue((rewardFormState$ | async).controls.accumulationCycleDay.value)}}"
                      type="text" maxlength="255" readonly>
                  </mat-form-field>
                </div>

                <div *ngIf="(rewardFormState$ | async).controls.accumulationCycleType.value === 'M'">
                  <mat-form-field fxFlex>
                    <input matInput
                      value="{{getDayOfMonthValue((rewardFormState$ | async).controls.accumulationCycleDay.value)}}"
                      type="text" maxlength="255" readonly>
                  </mat-form-field>
                </div>

                <div *ngIf="(rewardFormState$ | async).controls.accumulationCycleType.value === 'Y' && (rewardFormState$ | async).controls.accumulationCycleTypeOption.value">
                  <mat-form-field fxFlex>
                    <input matInput
                      value="{{getYearlyCycleValue((rewardFormState$ | async).controls.accumulationCycleTypeOption.value)}}"
                      type="text" maxlength="255" readonly>
                  </mat-form-field>
                </div>

                <div *ngIf="(rewardFormState$ | async).controls.accumulationCycleType.value === 'Y' && ((rewardFormState$ | async).controls.accumulationCycleTypeOption.value === 'FD' || channelValue != 'CARDLINK')">
                  <mat-form-field fxFlex>
                    <input matInput
                      value="{{getMonthValue((rewardFormState$ | async).controls.accumulationCycleMonth.value)}}"
                      type="text" maxlength="255" readonly>
                  </mat-form-field>
                </div>

                <div *ngIf="(rewardFormState$ | async).controls.accumulationCycleType.value === 'Y' &&
                   (rewardFormState$ | async).controls.accumulationCycleMonth.value && ((rewardFormState$ | async).controls.accumulationCycleTypeOption.value === 'FD' || channelValue != 'CARDLINK')">
                  <mat-form-field fxFlex>
                    <input matInput
                      value="{{getDayOfMonthValue((rewardFormState$ | async).controls.accumulationCycleDay.value)}}"
                      type="text" maxlength="255" readonly>
                  </mat-form-field>
                </div>

                <div *ngIf="(rewardFormState$ | async).controls.accumulationCycleType.value === 'B'" fxLayout="row" fxLayout.lt-md="column"
                  fxLayoutGap="5px" fxLayoutGap.lt-md="0px">
                  <mat-form-field fxFlex>
                    <input matInput value="{{getBillingCycleType((rewardFormState$ | async).controls.billingCycleType.value)}}"
                      type="text" maxlength="255" readonly>
                  </mat-form-field>
                </div>

                <div
                  *ngIf="(rewardFormState$ | async).controls.billingCycleType.value === 'BC' || (rewardFormState$ | async).controls.billingCycleType.value === 'AC'">
                  <mat-form-field fxFlex>
                    <input matInput
                      value="{{getDayOfMonthValue((rewardFormState$ | async).controls.numberOfDays.value)}}"
                      type="text" maxlength="255" readonly>
                  </mat-form-field>
                </div>
              </div>

              <mat-form-field>
                <mat-label>{{ 'CAMPAIGN.TRANSACTION_METHOD' | translate }}</mat-label>
                <input matInput
                  value="{{getTransactionsMethodValue((rewardFormState$ | async).controls.transactionMethod.value)}}"
                  type="text" maxlength="255" readonly>
              </mat-form-field>

              <div *ngIf="(rewardFormState$ | async).controls.transactionMethod.value === 'AMOUNT'" fxLayout="column">
                <div fxLayout="row" fxFlex>
                  <mat-form-field fxFlex="48">
                    <span matPrefix>{{ 'CAMPAIGN.AMOUNT' | translate }} &nbsp;</span>
                    <input matInput value="{{(rewardFormState$ | async).controls.valueFactor.value}}" type="text"
                      maxlength="13" readonly>
                  </mat-form-field>
                  <h3 fxFlex="4" style="text-align: center;">=</h3>
                  <mat-form-field fxFlex="48">
                    <span matSuffix>&nbsp; {{ 'CAMPAIGN.POINTS' | translate }}</span>
                    <input matInput style="text-align:right"
                      value="{{(rewardFormState$ | async).controls.pointFactor.value}}" type="text" maxlength="15"
                      readonly>
                  </mat-form-field>
                </div>
              </div>

              <div *ngIf="(rewardFormState$ | async).controls.transactionMethod.value === 'SWIPE'" fxLayout="column">
                <div fxLayout="row" fxFlex>
                  <mat-form-field fxFlex="48">
                    <span matPrefix>{{ 'CAMPAIGN.NUMBER_OF_TRANSACTIONS' | translate }} &nbsp;</span>
                    <input matInput value="{{(rewardFormState$ | async).controls.valueFactor.value}}" type="text"
                      maxlength="10" readonly>
                  </mat-form-field>
                  <h3 fxFlex="4" style="text-align: center;">=</h3>
                  <mat-form-field fxFlex="48">
                    <span matSuffix>&nbsp; {{ 'CAMPAIGN.POINTS' | translate }}</span>
                    <input matInput style="text-align:right"
                      value="{{(rewardFormState$ | async).controls.pointFactor.value}}" type="text" maxlength="15"
                      readonly>
                  </mat-form-field>
                </div>
              </div>
            </div>

            <div *ngIf="(rewardFormState$ | async).controls.type.value === 'TPT'" fxLayout="column">
              <mat-form-field>
                <mat-label>{{ 'CAMPAIGN.REWARD_CREDIT_METHOD' | translate }}</mat-label>
                <input matInput
                  value="{{getRewardCreditMethodValue((rewardFormState$ | async).controls.rewardCreditMethod.value)}}"
                  type="text" maxlength="255" readonly>
              </mat-form-field>

              <mat-form-field>
                <mat-label>{{ 'CAMPAIGN.TRANSACTION_METHOD' | translate }}</mat-label>
                <input matInput
                  value="{{getTransactionsMethodValue((rewardFormState$ | async).controls.transactionMethod.value)}}"
                  type="text" maxlength="255" readonly>
              </mat-form-field>

              <div *ngIf="(rewardFormState$ | async).controls.transactionMethod.value === 'AMOUNT'" fxLayout="column">
                <mat-form-field>
                  <mat-label>{{ 'CAMPAIGN.CALCULATION_METHOD' | translate }}</mat-label>
                  <input matInput
                    value="{{getCalculationMethodValue((rewardFormState$ | async).controls.rewardMethod.value)}}"
                    type="text" maxlength="255" readonly>
                </mat-form-field>
              </div>
            </div>

            <div *ngIf="(rewardFormState$ | async).controls.type.value === 'TPC'" fxLayout="column">
              <mat-form-field>
                <mat-label>{{ 'CAMPAIGN.REWARD_CREDIT_METHOD' | translate }}</mat-label>
                <input matInput
                  value="{{getRewardCreditMethodValue((rewardFormState$ | async).controls.rewardCreditMethod.value)}}"
                  type="text" maxlength="255" readonly>
              </mat-form-field>

              <div *ngIf="(rewardFormState$ | async).controls.type.value === 'TPC' &&
            (rewardFormState$ | async).controls.rewardCreditMethod.value === 'CCE'" fxLayout="column">
                <mat-form-field>
                  <mat-label>{{ 'CAMPAIGN.CYCLE' | translate }}</mat-label>
                  <input matInput
                    value="{{getAccumulationCycleTypeValue((rewardFormState$ | async).controls.accumulationCycleType.value)}}"
                    type="text" maxlength="255" readonly>
                </mat-form-field>

                <div *ngIf="(rewardFormState$ | async).controls.accumulationCycleType.value === 'W'">
                  <mat-form-field fxFlex>
                    <input matInput
                      value="{{getDayValue((rewardFormState$ | async).controls.accumulationCycleDay.value)}}"
                      type="text" maxlength="255" readonly>
                  </mat-form-field>
                </div>

                <div *ngIf="(rewardFormState$ | async).controls.accumulationCycleType.value === 'M'">
                  <mat-form-field fxFlex>
                    <input matInput
                      value="{{getDayOfMonthValue((rewardFormState$ | async).controls.accumulationCycleDay.value)}}"
                      type="text" maxlength="255" readonly>
                  </mat-form-field>
                </div>

                <div *ngIf="(rewardFormState$ | async).controls.accumulationCycleType.value === 'Y' && (rewardFormState$ | async).controls.accumulationCycleTypeOption.value">
                  <mat-form-field fxFlex>
                    <input matInput
                      value="{{getYearlyCycleValue((rewardFormState$ | async).controls.accumulationCycleTypeOption.value)}}"
                      type="text" maxlength="255" readonly>
                  </mat-form-field>
                </div>

                <div *ngIf="(rewardFormState$ | async).controls.accumulationCycleType.value === 'Y' && ((rewardFormState$ | async).controls.accumulationCycleTypeOption.value === 'FD' || channelValue != 'CARDLINK')">
                  <mat-form-field fxFlex>
                    <input matInput
                      value="{{getMonthValue((rewardFormState$ | async).controls.accumulationCycleMonth.value)}}"
                      type="text" maxlength="255" readonly>
                  </mat-form-field>
                </div>

                <div *ngIf="(rewardFormState$ | async).controls.accumulationCycleType.value === 'Y' &&
                   (rewardFormState$ | async).controls.accumulationCycleMonth.value && ((rewardFormState$ | async).controls.accumulationCycleTypeOption.value === 'FD' || channelValue != 'CARDLINK')">
                  <mat-form-field fxFlex>
                    <input matInput
                      value="{{getDayOfMonthValue((rewardFormState$ | async).controls.accumulationCycleDay.value)}}"
                      type="text" maxlength="255" readonly>
                  </mat-form-field>
                </div>

                <div *ngIf="(rewardFormState$ | async).controls.accumulationCycleType.value === 'B'" fxLayout="row" fxLayout.lt-md="column"
                  fxLayoutGap="5px" fxLayoutGap.lt-md="0px">
                  <mat-form-field fxFlex>
                    <input matInput value="{{getBillingCycleType((rewardFormState$ | async).controls.billingCycleType.value)}}"
                      type="text" maxlength="255" readonly>
                  </mat-form-field>
                </div>

                <div
                  *ngIf="(rewardFormState$ | async).controls.billingCycleType.value === 'BC' || (rewardFormState$ | async).controls.billingCycleType.value === 'AC'">
                  <mat-form-field fxFlex>
                    <input matInput
                      value="{{getDayOfMonthValue((rewardFormState$ | async).controls.numberOfDays.value)}}"
                      type="text" maxlength="255" readonly>
                  </mat-form-field>
                </div>
              </div>

              <div *ngIf="(rewardFormState$ | async).controls.transactionMethod.value === 'AMOUNT'" fxLayout="column">
                <mat-form-field>
                  <mat-label>{{ 'CAMPAIGN.CALCULATION_METHOD' | translate }}</mat-label>
                  <input matInput
                    value="{{getCalculationMethodValue((rewardFormState$ | async).controls.rewardMethod.value)}}"
                    type="text" maxlength="255" readonly>
                </mat-form-field>
              </div>

              <div *ngIf="(rewardFormState$ | async).controls.transactionMethod.value === 'SWIPE'" fxLayout="column">
                <mat-form-field fxFlex>
                  <mat-label>{{ 'CAMPAIGN.CALCULATION_METHOD' | translate }}</mat-label>
                  <input matInput value="Fixed Points" type="text" maxlength="255" readonly>
                </mat-form-field>
              </div>
            </div>

            <div *ngIf="(rewardFormState$ | async).controls.type.value === 'INC'" fxLayout="column">

              <mat-form-field>
                <mat-label>{{ 'CAMPAIGN.REWARD_LEVEL' | translate }}</mat-label>
                <input matInput
                  value="{{getRewardLevelValue((rewardFormState$ | async).controls.rewardLevel.value)}}"
                  type="text" maxlength="255" readonly>
              </mat-form-field>
              <mat-form-field>
                <mat-label>{{ 'CAMPAIGN.REWARD_CREDIT_METHOD' | translate }}</mat-label>
                <input matInput
                  value="{{getRewardCreditMethodValue((rewardFormState$ | async).controls.rewardCreditMethod.value)}}"
                  type="text" maxlength="255" readonly>
              </mat-form-field>

              <div *ngIf="(rewardFormState$ | async).controls.type.value === 'INC' &&
            (rewardFormState$ | async).controls.rewardCreditMethod.value === 'CCE'" fxLayout="column">
                <mat-form-field>
                  <mat-label>{{ 'CAMPAIGN.CYCLE' | translate }}</mat-label>
                  <input matInput
                    value="{{getAccumulationCycleTypeValue((rewardFormState$ | async).controls.accumulationCycleType.value)}}"
                    type="text" maxlength="255" readonly>
                </mat-form-field>

                <div *ngIf="(rewardFormState$ | async).controls.accumulationCycleType.value === 'W'">
                  <mat-form-field fxFlex>
                    <input matInput
                      value="{{getDayValue((rewardFormState$ | async).controls.accumulationCycleDay.value)}}"
                      type="text" maxlength="255" readonly>
                  </mat-form-field>
                </div>

                <div *ngIf="(rewardFormState$ | async).controls.accumulationCycleType.value === 'M'">
                  <mat-form-field fxFlex>
                    <input matInput
                      value="{{getDayOfMonthValue((rewardFormState$ | async).controls.accumulationCycleDay.value)}}"
                      type="text" maxlength="255" readonly>
                  </mat-form-field>
                </div>

                <div *ngIf="(rewardFormState$ | async).controls.accumulationCycleType.value === 'Y' && (rewardFormState$ | async).controls.accumulationCycleTypeOption.value">
                  <mat-form-field fxFlex>
                    <input matInput
                      value="{{getYearlyCycleValue((rewardFormState$ | async).controls.accumulationCycleTypeOption.value)}}"
                      type="text" maxlength="255" readonly>
                  </mat-form-field>
                </div>

                <div *ngIf="(rewardFormState$ | async).controls.accumulationCycleType.value === 'Y' && ((rewardFormState$ | async).controls.accumulationCycleTypeOption.value === 'FD' || channelValue != 'CARDLINK')">
                  <mat-form-field fxFlex>
                    <input matInput
                      value="{{getMonthValue((rewardFormState$ | async).controls.accumulationCycleMonth.value)}}"
                      type="text" maxlength="255" readonly>
                  </mat-form-field>
                </div>

                <div *ngIf="(rewardFormState$ | async).controls.accumulationCycleType.value === 'Y' &&
                   (rewardFormState$ | async).controls.accumulationCycleMonth.value && ((rewardFormState$ | async).controls.accumulationCycleTypeOption.value === 'FD' || channelValue != 'CARDLINK')">
                  <mat-form-field fxFlex>
                    <input matInput
                      value="{{getDayOfMonthValue((rewardFormState$ | async).controls.accumulationCycleDay.value)}}"
                      type="text" maxlength="255" readonly>
                  </mat-form-field>
                </div>
              </div>

			<div *ngIf="(rewardFormState$ | async).controls.accumulationCycleType.value === 'B'" fxLayout="row"
				fxLayout.lt-md="column" fxLayoutGap="5px" fxLayoutGap.lt-md="0px">
				<mat-form-field fxFlex>
					<input matInput value="{{getBillingCycleType((rewardFormState$ | async).controls.billingCycleType.value)}}"
						type="text" maxlength="255" readonly>
				</mat-form-field>
			</div>

			<div
				*ngIf="(rewardFormState$ | async).controls.billingCycleType.value === 'BC' || (rewardFormState$ | async).controls.billingCycleType.value === 'AC'">
				<mat-form-field fxFlex>
					<input matInput value="{{getDayOfMonthValue((rewardFormState$ | async).controls.numberOfDays.value)}}"
						type="text" maxlength="255" readonly>
				</mat-form-field>
			</div>

              <mat-form-field>
                <mat-label>{{ 'CAMPAIGN.TRANSACTION_METHOD' | translate }}</mat-label>
                <input matInput
                  value="{{getTransactionMethodValue((rewardFormState$ | async).controls.transactionMethod.value)}}"
                  type="text" maxlength="255" readonly>
			  </mat-form-field>

			  <mat-form-field>
                <mat-label>{{ 'CAMPAIGN.INCREMENT_VALUE' | translate }}</mat-label>
				  <input matInput value="{{(rewardFormState$ | async).controls.conditionFactor.value}}" type="text"
				  maxlength="10" readonly>
			  </mat-form-field>

			  <mat-form-field>
				<mat-label>{{ 'CAMPAIGN.CALCULATION_METHOD' | translate }}</mat-label>
				<input matInput
				  value="{{getCalculationMethodValue((rewardFormState$ | async).controls.rewardMethod.value)}}"
				  type="text" maxlength="255" readonly>
			  </mat-form-field>

			<div *ngIf="(rewardFormState$ | async).controls.transactionMethod.value === 'AMOUNT'
						  && (rewardFormState$ | async).controls.rewardMethod.value === 'RATIO'" fxLayout="column">
                <div fxLayout="row" fxFlex>
                  <mat-form-field fxFlex="48">
                    <span matPrefix>{{ 'CAMPAIGN.AMOUNT' | translate }} &nbsp;</span>
                    <input matInput value="{{(rewardFormState$ | async).controls.valueFactor.value}}" type="text"
                      maxlength="13" readonly>
                  </mat-form-field>
                  <h3 fxFlex="4" style="text-align: center;">=</h3>
                  <mat-form-field fxFlex="48">
                    <span matSuffix>&nbsp; {{ 'CAMPAIGN.POINTS' | translate }}</span>
                    <input matInput style="text-align:right"
                      value="{{(rewardFormState$ | async).controls.pointFactor.value}}" type="text" maxlength="15"
                      readonly>
                  </mat-form-field>
                </div>
              </div>

			<div *ngIf="(rewardFormState$ | async).controls.transactionMethod.value === 'SWIPE'
						  && (rewardFormState$ | async).controls.rewardMethod.value === 'RATIO'" fxLayout="column">
                <mat-form-field fxFlex>
                  <mat-label>{{ 'CAMPAIGN.CALCULATION_METHOD' | translate }}</mat-label>
                  <input matInput value="Fixed Points" type="text" maxlength="10" readonly>
                </mat-form-field>

                <mat-form-field fxFlex>
                  <span matSuffix>&nbsp; {{ 'CAMPAIGN.POINTS' | translate }}</span>
                  <input matInput value="{{(rewardFormState$ | async).controls.pointFactor.value}}" type="text"
                    maxlength="15" readonly>
                </mat-form-field>
			  </div>

			<div *ngIf="(rewardFormState$ | async).controls.rewardMethod.value === 'FIX'
						&& ((rewardFormState$ | async).controls.transactionMethod.value === 'AMOUNT'|| (rewardFormState$ | async).controls.transactionMethod.value === 'SWIPE')
						" fxLayout="column">
				<mat-form-field fxFlex>
					<mat-label>{{ 'CAMPAIGN.POINTS' | translate }}</mat-label>
					<input matInput value="{{(rewardFormState$ | async).controls.pointFactor.value}}" type="text" maxlength="15"
						readonly>
				</mat-form-field>
			</div>
			</div>

            <div *ngIf="(rewardFormState$ | async).controls.rounding.value" fxLayout="column">
              <mat-checkbox [ngrxFormControlState]="(rewardFormState$ | async).controls.rounding" color="primary"
                (click)="$event.preventDefault()">
                {{ 'CAMPAIGN.ROUNDING' | translate }}
              </mat-checkbox>
            </div>

            <div *ngIf="(rewardFormState$ | async).controls.rounding.value" fxLayout="column">
              <mat-form-field fxFlex>
                <input matInput value="{{getRoundingValue((rewardFormState$ | async).controls.roundingValue.value)}}"
                  type="text" maxlength="255" readonly>
              </mat-form-field>

              <mat-form-field fxFlex>
                <mat-label>{{ 'CAMPAIGN.OPTION' | translate }}</mat-label>
                <input matInput
                  value="{{getNumberDecimalValue((rewardFormState$ | async).controls.numberDecimal.value)}}" type="text"
                  maxlength="255" readonly>
              </mat-form-field>

              <mat-form-field fxFlex>
                <input matInput value="{{(rewardFormState$ | async).controls.numberDecimalValue.value}}" type="text"
                  maxlength="1" readonly>
              </mat-form-field>
            </div>

            <div *ngIf="(rewardFormState$ | async).controls.type.value === 'TPT' ||
            (rewardFormState$ | async).controls.type.value === 'TPC'" fxLayout="column">
              <div *ngFor="let campaignTier of (campaignTiers$ | async);trackBy: trackByCampaignTierIndex;let i=index"
                fxFlexOffset="8px">
                <app-campaign-tier [campaignTier]="campaignTier" [index]="i"></app-campaign-tier>
              </div>
            </div>

          </div>

        </form>
      </mat-step>
    </mat-horizontal-stepper>
  </mat-dialog-content>

  <div class="action-button-div" fxLayout="row" fxLayoutAlign="end">
    <button *ngIf="selectedIndex === 0" class="mat-raised-button mat-primary next-button" (click)="stepper.next()"
      fxFlexOffset="8px">{{ 'ACTION.NEXT' | translate }}</button>

    <button *ngIf="selectedIndex === 1" class="mat-raised-button mat-primary next-button" (click)="cancel()"
      fxFlexOffset="8px">{{ 'ACTION.DONE' | translate }}</button>
  </div>
</div>

<div *ngIf="!isView && !isPublishedUpdate">
  <mat-dialog-content #container>

    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="5px" fxLayoutGap.lt-md="0px">
      <div fxFlex="70">
        <mat-horizontal-stepper linear #stepper [@.disabled]="false" [selectedIndex]="selectedIndex"
          (selectionChange)="selectionChange($event); container.scrollTop = 0;">

          <ng-template matStepperIcon="edit">
            <mat-icon>done</mat-icon>
          </ng-template>

          <ng-template matStepperIcon="error">
            <mat-icon>error</mat-icon>
          </ng-template>

          <mat-step [stepControl]="!isFirstStepValid ? null : rulesetConditionFormGroup"
            [completed]="!isFirstStepValid ? false : rulesetConditionFormGroup.valid" #step
            [hasError]="(formState$ | async).isTouched && (formState$ | async).isInvalid">
            <ng-template matStepLabel>{{ 'CAMPAIGN.SETUP_RULESET_CONDITIONS' | translate }}</ng-template>

            <form novalidate [ngrxFormState]="formState$ | async" [formGroup]="rulesetConditionFormGroup">
              <div fxLayout="row" fxLayoutGap="5px">
                <mat-form-field fxFlex="50">
                  <mat-label>{{ 'CAMPAIGN.RULESET_CODE' | translate }}</mat-label>
                  <input matInput [ngrxFormControlState]="(formState$ | async).controls.code" type="text" maxlength="10"
                    required>
                  <mat-error *ngIf="(formState$ | async).errors._code">
                    <app-error-validation [errors]="(formState$ | async).errors._code" [maxlength]="10">
                    </app-error-validation>
                  </mat-error>
                </mat-form-field>

                <mat-form-field fxFlex="50">
                  <mat-label>{{ 'CAMPAIGN.RULESET_NAME' | translate }}</mat-label>
                  <input matInput [ngrxFormControlState]="(formState$ | async).controls.name" type="text" maxlength="40"
                    required>
                  <mat-error *ngIf="(formState$ | async).errors._name">
                    <app-error-validation [errors]="(formState$ | async).errors._name" [maxlength]="40">
                    </app-error-validation>
                  </mat-error>
                </mat-form-field>
              </div>

              <div fxLayout="column">
				<mat-form-field fxFlex>
					<mat-label>{{ 'CAMPAIGN.CHANNEL' | translate }}</mat-label>
					<mat-select #channel [ngrxFormControlState]="(formState$ | async).controls.channel"
						(closed)="clearSearchResource()" (selectionChange)="onChangeChannel(channel.value)" required>
						<ngx-mat-select-search [formControl]="dropDownCtrlChannelList" [placeholderLabel]="'COMMON.SEARCH'| translate"
							[noEntriesFoundLabel]="'COMMON.NOT_MATCHING_FOUND' | translate">
							<mat-icon ngxMatSelectSearchClear (click)="clearSearchResource()">close</mat-icon>
						</ngx-mat-select-search>
						<mat-option *ngFor="let resource of filteredChannelList | async" [value]="resource.key">
							{{resource.value}}
						</mat-option>
					</mat-select>
					<mat-error *ngIf="(formState$ | async).errors._channel">
						<app-error-validation [errors]="(formState$ | async).errors._channel" [maxlength]="255">
						</app-error-validation>
					</mat-error>
				</mat-form-field>
              </div>
            </form>

            <div *ngIf="(formState$ | async).controls.channel.value" fxLayout="column">
              <div *ngFor="let ruleset of (rulesets$ | async);trackBy: trackByIndex;let i=index" fxFlexOffset="8px">
                <div [ngSwitch]="ruleset.ruleAddType">
                  <app-rule-dynamic *ngSwitchCase="'RULE'" [ruleset]="ruleset"></app-rule-dynamic>
                  <app-rule-group *ngSwitchCase="'CONDITIONAL_RULES'" [ruleset]="ruleset"></app-rule-group>
                </div>
              </div>

              <div fxLayout="column" fxFlexOffset="8px">
                <div>
                  <button mat-button [matMenuTriggerFor]="menu" color="primary">
                    <mat-icon class="material-icons-outlined">add</mat-icon>
                    {{ 'CAMPAIGN.ACTION.ADD_RULE' | translate }}
                  </button>
                  <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="addRuleset()"
                      [disabled]="(rulesets$ | async).length >= ruleListLength">{{ 'CAMPAIGN.ADD_RULE' | translate }}</button>
                    <button mat-menu-item
                      (click)="addGroup()">{{ 'CAMPAIGN.ADD_CONDITIONAL_RULES' | translate }}</button>
                  </mat-menu>
                </div>
              </div>
            </div>

          </mat-step>
          <mat-step [completed]="(rewardFormState$ | async).isValid && isValid"
            [hasError]="(rewardFormState$ | async).isTouched && ((rewardFormState$ | async).isInvalid || !isValid)">
            <ng-template matStepLabel>{{ 'CAMPAIGN.SETUP_REWARD' | translate }}</ng-template>

            <form novalidate [ngrxFormState]="rewardFormState$ | async" fxLayout="column">
              <mat-form-field>
                <mat-label>{{ 'CAMPAIGN.REWARD_POOL' | translate }}</mat-label>
				<mat-select [ngrxFormControlState]="(rewardFormState$ | async).controls.rewardPool" (closed)="clearSearchRewardPool()"
					(selectionChange)="onChangeRewardPool()" required>
					<ngx-mat-select-search [formControl]="dropDownCtrlRewardPool" [placeholderLabel]="'COMMON.SEARCH'| translate"
						[noEntriesFoundLabel]="'COMMON.NOT_MATCHING_FOUND' | translate">
						<mat-icon ngxMatSelectSearchClear (click)="clearSearchRewardPool()">close</mat-icon>
					</ngx-mat-select-search>
					<mat-option *ngFor="let rewardPool of filteredRewardPool | async" [value]="rewardPool.key">
						{{rewardPool.value}}
					</mat-option>
				</mat-select>
                <mat-error *ngIf="(rewardFormState$ | async).errors._rewardPool">
                  <app-error-validation [errors]="(rewardFormState$ | async).errors._rewardPool"></app-error-validation>
                </mat-error>
              </mat-form-field>

              <div *ngIf="(rewardFormState$ | async).controls.rewardPool.value" fxLayout="column">
                <mat-form-field *ngIf="resourceType === 'BANK'">
                  <mat-label>{{ 'CAMPAIGN.FIELD_MAPPING' | translate }}</mat-label>
                  <mat-icon class="material-icons-outlined" matSuffix
                    matTooltip="{{ 'CAMPAIGN.INFO.FIELD_MAPPING' | translate }}">
                    info</mat-icon>
                  <mat-select [ngrxFormControlState]="(rewardFormState$ | async).controls.amountField">
                    <mat-option>{{ 'COMMON.PLEASE_SELECT' | translate}}</mat-option>
                    <mat-option *ngFor="let i of amountList" [value]="i.key">
                      {{i.value}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <br>
                <span class="content-title">{{ 'CAMPAIGN.REWARD_CONDITIONS' | translate }}</span>
                <br>
                <mat-form-field>
                  <mat-label>{{ 'CAMPAIGN.TYPE' | translate }}</mat-label>
                  <mat-select [ngrxFormControlState]="(rewardFormState$ | async).controls.type"
                    (selectionChange)="onChangeType()">
                    <mat-option>{{ 'COMMON.PLEASE_SELECT' | translate}}</mat-option>
                    <mat-option *ngFor="let i of rewardTypeList" [value]="i.key">
                      {{i.value}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <div *ngIf="(rewardFormState$ | async).controls.type.value === 'X2X'" fxLayout="column">
                  <span class="radio-button-label">
                    {{ 'CAMPAIGN.REWARD_CREDIT_METHOD' | translate }}
                  </span>
                  <mat-radio-group [ngrxFormControlState]="(rewardFormState$ | async).controls.rewardCreditMethod"
                    color="primary" (change)="onChangeRewardCreditMethod()" fxLayoutGap="8px">
                    <mat-radio-button *ngFor="let i of rewardCreditMethodList" [value]="i.key">
                      {{i.value}}
                    </mat-radio-button>
                  </mat-radio-group>
                  <br>

                  <div *ngIf="(rewardFormState$ | async).controls.type.value === 'X2X' &&
                (rewardFormState$ | async).controls.rewardCreditMethod.value === 'CCE'" fxLayout="column">
                    <mat-form-field>
                      <mat-label>{{ 'CAMPAIGN.CYCLE' | translate }}</mat-label>
                      <mat-select [ngrxFormControlState]="(rewardFormState$ | async).controls.accumulationCycleType"
                        (selectionChange)="onChangeAccumulationCycleType()">
                        <mat-option>{{ 'COMMON.PLEASE_SELECT' | translate}}</mat-option>
                        <mat-option *ngFor="let i of accumulationCycleTypeList" [value]="i.key">
                          {{i.value}}</mat-option>
                      </mat-select>
                      <mat-error *ngIf="(rewardFormState$ | async).errors._accumulationCycleType">
                        <app-error-validation [errors]="(rewardFormState$ | async).errors._accumulationCycleType">
                        </app-error-validation>
                      </mat-error>
                    </mat-form-field>

                    <div *ngIf="(rewardFormState$ | async).controls.accumulationCycleType.value === 'W'">
                      <mat-form-field fxFlex>
                        <mat-select [ngrxFormControlState]="(rewardFormState$ | async).controls.accumulationCycleDay">
                          <mat-option>{{ 'COMMON.PLEASE_SELECT' | translate}}</mat-option>
                          <mat-option *ngFor="let i of dayList" [value]="i.key">
                            {{i.value}}
                          </mat-option>
                        </mat-select>
                        <mat-error *ngIf="(rewardFormState$ | async).errors._accumulationCycleDay">
                          <app-error-validation [errors]="(rewardFormState$ | async).errors._accumulationCycleDay">
                          </app-error-validation>
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div *ngIf="(rewardFormState$ | async).controls.accumulationCycleType.value === 'M'">
                      <mat-form-field fxFlex>
                        <mat-select [ngrxFormControlState]="(rewardFormState$ | async).controls.accumulationCycleDay">
                          <mat-option>{{ 'COMMON.PLEASE_SELECT' | translate}}</mat-option>
                          <mat-option *ngFor="let i of dayOfMonthList" [value]="i.key">
                            {{i.value}}
                          </mat-option>
                        </mat-select>
                        <mat-error *ngIf="(rewardFormState$ | async).errors._accumulationCycleDay">
                          <app-error-validation [errors]="(rewardFormState$ | async).errors._accumulationCycleDay">
                          </app-error-validation>
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div *ngIf="(rewardFormState$ | async).controls.accumulationCycleType.value === 'Y' && resourceType === 'CARD'">
                      <mat-radio-group [ngrxFormControlState]="(rewardFormState$ | async).controls.accumulationCycleTypeOption" color="primary"
                        fxLayoutGap="8px" required (change)="onChangeYearlyCycleType()">
                        <mat-radio-button *ngFor="let i of yearlyCycleTypeList" [value]="i.key">
                          {{i.value}}
                        </mat-radio-button>
                      </mat-radio-group>
                    </div>

                    <div *ngIf="(rewardFormState$ | async).controls.accumulationCycleTypeOption.value !== 'FD' && resourceType === 'CARD'" style="margin-bottom: 1.25em;"></div>

                    <div *ngIf="(rewardFormState$ | async).controls.accumulationCycleType.value === 'Y'
                      && ((rewardFormState$ | async).controls.accumulationCycleTypeOption.value === 'FD'
                      || resourceType !== 'CARD')">
                      <mat-form-field fxFlex>
                        <mat-select [ngrxFormControlState]="(rewardFormState$ | async).controls.accumulationCycleMonth"
                          (selectionChange)="clearCycleDay()">
                          <mat-option>{{ 'COMMON.PLEASE_SELECT' | translate}}</mat-option>
                          <mat-option *ngFor="let i of monthList" [value]="i.key">
                            {{i.value}}
                          </mat-option>
                        </mat-select>
                        <mat-error *ngIf="(rewardFormState$ | async).errors._accumulationCycleMonth">
                          <app-error-validation [errors]="(rewardFormState$ | async).errors._accumulationCycleMonth">
                          </app-error-validation>
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div *ngIf="(rewardFormState$ | async).controls.accumulationCycleType.value === 'Y'
                    && (rewardFormState$ | async).controls.accumulationCycleMonth.value
                    && ((rewardFormState$ | async).controls.accumulationCycleTypeOption.value === 'FD'
                    || resourceType !== 'CARD')">
                      <mat-form-field fxFlex>
                        <mat-select [ngrxFormControlState]="(rewardFormState$ | async).controls.accumulationCycleDay">
                          <mat-option>{{ 'COMMON.PLEASE_SELECT' | translate}}</mat-option>
                          <mat-option *ngFor="let i of dayOfMonthList" [value]="i.key">
                            {{i.value}}
                          </mat-option>
                        </mat-select>
                        <mat-error *ngIf="(rewardFormState$ | async).errors._accumulationCycleDay">
                          <app-error-validation [errors]="(rewardFormState$ | async).errors._accumulationCycleDay">
                          </app-error-validation>
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div *ngIf="(rewardFormState$ | async).controls.accumulationCycleType.value === 'B'" style="padding-bottom: 10px;">
                      <mat-radio-group [ngrxFormControlState]="(rewardFormState$ | async).controls.billingCycleType"
                        color="primary" fxLayoutGap="8px" required (change)="clearBillingCycleType()">
                        <mat-radio-button *ngFor="let i of billingCycleTypeList" [value]="i.key">
                          {{i.value}}
                        </mat-radio-button>
                      </mat-radio-group>
                    </div>

                    <div
                      *ngIf="(rewardFormState$ | async).controls.billingCycleType.value === 'BC' || (rewardFormState$ | async).controls.billingCycleType.value === 'AC'"
                      style="width: 100% !important;">
                      <mat-form-field fxFlex="100">
                        <mat-label>{{ 'AUTO_REDEMPTION.NUMBER_OF_DAYS' | translate }}</mat-label>
                        <mat-icon class="material-icons-outlined" matSuffix
                          matTooltip="{{ 'AUTO_REDEMPTION.NUMBER_OF_DAYS_HINT' | translate }}">info</mat-icon>
                        <input matInput [ngrxFormControlState]="(rewardFormState$ | async).controls.numberOfDays"
                          type="text" maxlength="1" required>
                        <mat-error *ngIf="(rewardFormState$ | async).errors._numberOfDays">
                          <app-error-validation [errors]="(rewardFormState$ | async).errors._numberOfDays">
                          </app-error-validation>
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>

                  <span class="radio-button-label">
                    {{ 'CAMPAIGN.TRANSACTION_METHOD' | translate }}
                  </span>
                  <mat-radio-group [ngrxFormControlState]="(rewardFormState$ | async).controls.transactionMethod"
                    color="primary" (change)="onChangeTransactionMethod()" fxLayoutGap="8px">
                    <mat-radio-button *ngFor="let i of transactionMethodList" [value]="i.key">
                      {{i.value}}
                    </mat-radio-button>
                  </mat-radio-group>
                  <br>

                  <div *ngIf="(rewardFormState$ | async).controls.transactionMethod.value === 'AMOUNT'"
                    fxLayout="column">
                    <mat-form-field fxFlex>
                      <mat-label>{{ 'CAMPAIGN.CALCULATION_METHOD' | translate }}</mat-label>
                      <input matInput value="Ratio" type="text" maxlength="255" readonly>
                    </mat-form-field>

                    <div fxLayout="row" fxFlex>
                      <mat-form-field fxFlex="48">
                        <span matPrefix>{{ 'CAMPAIGN.AMOUNT' | translate }} &nbsp;</span>
                        <input matInput [ngrxFormControlState]="(rewardFormState$ | async).controls.valueFactor"
                          onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57 || event.charCode == 46"
                          type="text" maxlength="13">
                        <mat-error *ngIf="(rewardFormState$ | async).errors._valueFactor">
                          <app-error-validation [errors]="(rewardFormState$ | async).errors._valueFactor">
                          </app-error-validation>
                        </mat-error>
                      </mat-form-field>
                      <h3 fxFlex="4" style="text-align: center;">=</h3>
                      <mat-form-field fxFlex="48">
                        <input matInput style="text-align:right" [ngrxFormControlState]="(rewardFormState$ | async).controls.pointFactor"
                          onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57 || event.charCode == 46"
                          type="text" maxlength="15">
                        <span matSuffix>&nbsp; {{ 'CAMPAIGN.POINTS' | translate }}</span>
                        <mat-error *ngIf="(rewardFormState$ | async).errors._pointFactor">
                          <app-error-validation [errors]="(rewardFormState$ | async).errors._pointFactor">
                          </app-error-validation>
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>

                  <div *ngIf="(rewardFormState$ | async).controls.transactionMethod.value === 'SWIPE'"
                    fxLayout="column">
                    <mat-form-field fxFlex>
                      <mat-label>{{ 'CAMPAIGN.CALCULATION_METHOD' | translate }}</mat-label>
                      <input matInput value="Fixed Points" type="text" maxlength="255" readonly>
                    </mat-form-field>

                    <mat-form-field fxFlex>
                      <mat-label>{{ 'CAMPAIGN.POINTS' | translate }}</mat-label>
                      <input matInput [ngrxFormControlState]="(rewardFormState$ | async).controls.pointFactor"
                        onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57"
                        type="text" maxlength="10">
                      <mat-error *ngIf="(rewardFormState$ | async).errors._pointFactor">
                        <app-error-validation [errors]="(rewardFormState$ | async).errors._pointFactor">
                        </app-error-validation>
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>

                <div *ngIf="(rewardFormState$ | async).controls.type.value === 'OX'" fxLayout="column">
                  <span class="radio-button-label">
                    {{ 'CAMPAIGN.REWARD_CREDIT_METHOD' | translate }}
                  </span>
                  <mat-radio-group [ngrxFormControlState]="(rewardFormState$ | async).controls.rewardCreditMethod"
                    color="primary" (change)="onChangeRewardCreditMethod()" fxLayoutGap="8px">
                    <mat-radio-button *ngFor="let i of rewardCreditMethodList" [value]="i.key">
                      {{i.value}}
                    </mat-radio-button>
                  </mat-radio-group>
                  <br>

                  <div *ngIf="(rewardFormState$ | async).controls.type.value === 'OX' &&
                ((rewardFormState$ | async).controls.rewardCreditMethod.value === 'I' ||
                (rewardFormState$ | async).controls.rewardCreditMethod.value === 'CCE')" fxLayout="column">
                    <mat-form-field>
                      <mat-label>{{ 'CAMPAIGN.CYCLE' | translate }}</mat-label>
                      <mat-select [ngrxFormControlState]="(rewardFormState$ | async).controls.accumulationCycleType"
                        (selectionChange)="onChangeAccumulationCycleType()">
                        <mat-option>{{ 'COMMON.PLEASE_SELECT' | translate}}</mat-option>
                        <mat-option *ngFor="let i of accumulationCycleTypeList" [value]="i.key">
                          {{i.value}}</mat-option>
                      </mat-select>
                      <mat-error *ngIf="(rewardFormState$ | async).errors._accumulationCycleType">
                        <app-error-validation [errors]="(rewardFormState$ | async).errors._accumulationCycleType">
                        </app-error-validation>
                      </mat-error>
                    </mat-form-field>

                    <div *ngIf="(rewardFormState$ | async).controls.accumulationCycleType.value === 'W'">
                      <mat-form-field fxFlex>
                        <mat-select [ngrxFormControlState]="(rewardFormState$ | async).controls.accumulationCycleDay">
                          <mat-option>{{ 'COMMON.PLEASE_SELECT' | translate}}</mat-option>
                          <mat-option *ngFor="let i of dayList" [value]="i.key">
                            {{i.value}}
                          </mat-option>
                        </mat-select>
                        <mat-error *ngIf="(rewardFormState$ | async).errors._accumulationCycleDay">
                          <app-error-validation [errors]="(rewardFormState$ | async).errors._accumulationCycleDay">
                          </app-error-validation>
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div *ngIf="(rewardFormState$ | async).controls.accumulationCycleType.value === 'M'">
                      <mat-form-field fxFlex>
                        <mat-select [ngrxFormControlState]="(rewardFormState$ | async).controls.accumulationCycleDay">
                          <mat-option>{{ 'COMMON.PLEASE_SELECT' | translate}}</mat-option>
                          <mat-option *ngFor="let i of dayOfMonthList" [value]="i.key">
                            {{i.value}}
                          </mat-option>
                        </mat-select>
                        <mat-error *ngIf="(rewardFormState$ | async).errors._accumulationCycleDay">
                          <app-error-validation [errors]="(rewardFormState$ | async).errors._accumulationCycleDay">
                          </app-error-validation>
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div *ngIf="(rewardFormState$ | async).controls.accumulationCycleType.value === 'Y' && resourceType === 'CARD'">
                      <mat-radio-group [ngrxFormControlState]="(rewardFormState$ | async).controls.accumulationCycleTypeOption" color="primary"
                        fxLayoutGap="8px" required (change)="onChangeYearlyCycleType()">
                        <mat-radio-button *ngFor="let i of yearlyCycleTypeList" [value]="i.key">
                          {{i.value}}
                        </mat-radio-button>
                      </mat-radio-group>
                    </div>

                    <div *ngIf="(rewardFormState$ | async).controls.accumulationCycleTypeOption.value !== 'FD' && resourceType === 'CARD'" style="margin-bottom: 1.25em;"></div>

                    <div *ngIf="(rewardFormState$ | async).controls.accumulationCycleType.value === 'Y'
                      && ((rewardFormState$ | async).controls.accumulationCycleTypeOption.value === 'FD'
                      || resourceType !== 'CARD')">
                      <mat-form-field fxFlex>
                        <mat-select [ngrxFormControlState]="(rewardFormState$ | async).controls.accumulationCycleMonth"
                          (selectionChange)="clearCycleDay()">
                          <mat-option>{{ 'COMMON.PLEASE_SELECT' | translate}}</mat-option>
                          <mat-option *ngFor="let i of monthList" [value]="i.key">
                            {{i.value}}
                          </mat-option>
                        </mat-select>
                        <mat-error *ngIf="(rewardFormState$ | async).errors._accumulationCycleMonth">
                          <app-error-validation [errors]="(rewardFormState$ | async).errors._accumulationCycleMonth">
                          </app-error-validation>
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div *ngIf="(rewardFormState$ | async).controls.accumulationCycleType.value === 'Y'
                    && (rewardFormState$ | async).controls.accumulationCycleMonth.value
                    && ((rewardFormState$ | async).controls.accumulationCycleTypeOption.value === 'FD'
                    || resourceType !== 'CARD')">
                      <mat-form-field fxFlex>
                        <mat-select [ngrxFormControlState]="(rewardFormState$ | async).controls.accumulationCycleDay">
                          <mat-option>{{ 'COMMON.PLEASE_SELECT' | translate}}</mat-option>
                          <mat-option *ngFor="let i of dayOfMonthList" [value]="i.key">
                            {{i.value}}
                          </mat-option>
                        </mat-select>
                        <mat-error *ngIf="(rewardFormState$ | async).errors._accumulationCycleDay">
                          <app-error-validation [errors]="(rewardFormState$ | async).errors._accumulationCycleDay">
                          </app-error-validation>
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div *ngIf="(rewardFormState$ | async).controls.accumulationCycleType.value === 'B'" style="padding-bottom: 10px;">
                      <mat-radio-group [ngrxFormControlState]="(rewardFormState$ | async).controls.billingCycleType"
                        color="primary" fxLayoutGap="8px" required (change)="clearBillingCycleType()">
                        <mat-radio-button *ngFor="let i of billingCycleTypeList" [value]="i.key">
                          {{i.value}}
                        </mat-radio-button>
                      </mat-radio-group>
                    </div>

                    <div
                      *ngIf="(rewardFormState$ | async).controls.billingCycleType.value === 'BC' || (rewardFormState$ | async).controls.billingCycleType.value === 'AC'"
                      style="width: 100% !important;">
                      <mat-form-field fxFlex="100">
                        <mat-label>{{ 'AUTO_REDEMPTION.NUMBER_OF_DAYS' | translate }}</mat-label>
                        <mat-icon class="material-icons-outlined" matSuffix
                          matTooltip="{{ 'AUTO_REDEMPTION.NUMBER_OF_DAYS_HINT' | translate }}">info</mat-icon>
                        <input matInput [ngrxFormControlState]="(rewardFormState$ | async).controls.numberOfDays" type="text" maxlength="1"
                          required>
                        <mat-error *ngIf="(rewardFormState$ | async).errors._numberOfDays">
                          <app-error-validation [errors]="(rewardFormState$ | async).errors._numberOfDays">
                          </app-error-validation>
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>

                  <span class="radio-button-label">
                    {{ 'CAMPAIGN.TRANSACTION_METHOD' | translate }}
                  </span>
                  <mat-radio-group [ngrxFormControlState]="(rewardFormState$ | async).controls.transactionMethod"
                    color="primary" (change)="onChangeTransactionMethod()" fxLayoutGap="8px">
                    <mat-radio-button *ngFor="let i of transactionMethodList" [value]="i.key">
                      {{i.value}}
                    </mat-radio-button>
                  </mat-radio-group>
                  <br>

                  <div *ngIf="(rewardFormState$ | async).controls.transactionMethod.value === 'AMOUNT'"
                    fxLayout="column">
                    <div fxLayout="row" fxFlex>
                      <mat-form-field fxFlex="48">
                        <span matPrefix>{{ 'CAMPAIGN.AMOUNT' | translate }} &nbsp;</span>
                        <input matInput [ngrxFormControlState]="(rewardFormState$ | async).controls.conditionFactor"
                          onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57 || event.charCode == 46"
                          type="text" maxlength="13">
                        <mat-error *ngIf="(rewardFormState$ | async).errors._conditionFactor">
                          <app-error-validation [errors]="(rewardFormState$ | async).errors._conditionFactor">
                          </app-error-validation>
                        </mat-error>
                      </mat-form-field>
                      <h3 fxFlex="4" style="text-align: center;">=</h3>
                      <mat-form-field fxFlex="48">
                        <input matInput style="text-align:right" [ngrxFormControlState]="(rewardFormState$ | async).controls.pointFactor"
                          onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57 || event.charCode == 46"
                          type="text" maxlength="15">
                        <span matSuffix>&nbsp; {{ 'CAMPAIGN.POINTS' | translate }}</span>
                        <mat-error *ngIf="(rewardFormState$ | async).errors._pointFactor">
                          <app-error-validation [errors]="(rewardFormState$ | async).errors._pointFactor">
                          </app-error-validation>
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>

                  <div *ngIf="(rewardFormState$ | async).controls.transactionMethod.value === 'SWIPE'"
                    fxLayout="column">
                    <div fxLayout="row" fxFlex>
                      <mat-form-field fxFlex="48">
                        <span matPrefix>{{ 'CAMPAIGN.NUMBER_OF_TRANSACTIONS' | translate }} &nbsp;</span>
                        <input matInput [ngrxFormControlState]="(rewardFormState$ | async).controls.conditionFactor"
                          onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57"
                          type="text" maxlength="10">
                        <mat-error *ngIf="(rewardFormState$ | async).errors._conditionFactor">
                          <app-error-validation [errors]="(rewardFormState$ | async).errors._conditionFactor">
                          </app-error-validation>
                        </mat-error>
                      </mat-form-field>
                      <h3 fxFlex="4" style="text-align: center;">=</h3>
                      <mat-form-field fxFlex="48">
                        <input matInput style="text-align:right" [ngrxFormControlState]="(rewardFormState$ | async).controls.pointFactor"
                          onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57 || event.charCode == 46"
                          type="text" maxlength="15">
                        <span matSuffix>&nbsp; {{ 'CAMPAIGN.POINTS' | translate }}</span>
                        <mat-error *ngIf="(rewardFormState$ | async).errors._pointFactor">
                          <app-error-validation [errors]="(rewardFormState$ | async).errors._pointFactor">
                          </app-error-validation>
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                </div>

                <div *ngIf="(rewardFormState$ | async).controls.type.value === 'PX'" fxLayout="column">
                  <span class="radio-button-label">
                    {{ 'CAMPAIGN.REWARD_CREDIT_METHOD' | translate }}
                  </span>
                  <mat-radio-group [ngrxFormControlState]="(rewardFormState$ | async).controls.rewardCreditMethod"
                    color="primary" (change)="onChangeRewardCreditMethod()" fxLayoutGap="8px">
                    <mat-radio-button *ngFor="let i of rewardCreditMethodList" [value]="i.key">
                      {{i.value}}
                    </mat-radio-button>
                  </mat-radio-group>
                  <br>

                  <div *ngIf="(rewardFormState$ | async).controls.type.value === 'PX' &&
                  ((rewardFormState$ | async).controls.rewardCreditMethod.value === 'I' ||
                  (rewardFormState$ | async).controls.rewardCreditMethod.value === 'CCE')" fxLayout="column">
                    <mat-form-field>
                      <mat-label>{{ 'CAMPAIGN.CYCLE' | translate }}</mat-label>
                      <mat-select [ngrxFormControlState]="(rewardFormState$ | async).controls.accumulationCycleType"
                        (selectionChange)="onChangeAccumulationCycleType()">
                        <mat-option>{{ 'COMMON.PLEASE_SELECT' | translate}}</mat-option>
                        <mat-option *ngFor="let i of accumulationCycleTypeList" [value]="i.key">
                          {{i.value}}</mat-option>
                      </mat-select>
                      <mat-error *ngIf="(rewardFormState$ | async).errors._accumulationCycleType">
                        <app-error-validation [errors]="(rewardFormState$ | async).errors._accumulationCycleType">
                        </app-error-validation>
                      </mat-error>
                    </mat-form-field>

                    <div *ngIf="(rewardFormState$ | async).controls.accumulationCycleType.value === 'W'">
                      <mat-form-field fxFlex>
                        <mat-select [ngrxFormControlState]="(rewardFormState$ | async).controls.accumulationCycleDay">
                          <mat-option>{{ 'COMMON.PLEASE_SELECT' | translate}}</mat-option>
                          <mat-option *ngFor="let i of dayList" [value]="i.key">
                            {{i.value}}
                          </mat-option>
                        </mat-select>
                        <mat-error *ngIf="(rewardFormState$ | async).errors._accumulationCycleDay">
                          <app-error-validation [errors]="(rewardFormState$ | async).errors._accumulationCycleDay">
                          </app-error-validation>
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div *ngIf="(rewardFormState$ | async).controls.accumulationCycleType.value === 'M'">
                      <mat-form-field fxFlex>
                        <mat-select [ngrxFormControlState]="(rewardFormState$ | async).controls.accumulationCycleDay">
                          <mat-option>{{ 'COMMON.PLEASE_SELECT' | translate}}</mat-option>
                          <mat-option *ngFor="let i of dayOfMonthList" [value]="i.key">
                            {{i.value}}
                          </mat-option>
                        </mat-select>
                        <mat-error *ngIf="(rewardFormState$ | async).errors._accumulationCycleDay">
                          <app-error-validation [errors]="(rewardFormState$ | async).errors._accumulationCycleDay">
                          </app-error-validation>
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div *ngIf="(rewardFormState$ | async).controls.accumulationCycleType.value === 'Y' && resourceType === 'CARD'">
                      <mat-radio-group [ngrxFormControlState]="(rewardFormState$ | async).controls.accumulationCycleTypeOption" color="primary"
                        fxLayoutGap="8px" required (change)="onChangeYearlyCycleType()">
                        <mat-radio-button *ngFor="let i of yearlyCycleTypeList" [value]="i.key">
                          {{i.value}}
                        </mat-radio-button>
                      </mat-radio-group>
                    </div>

                    <div *ngIf="(rewardFormState$ | async).controls.accumulationCycleTypeOption.value !== 'FD' && resourceType === 'CARD'" style="margin-bottom: 1.25em;"></div>

                    <div *ngIf="(rewardFormState$ | async).controls.accumulationCycleType.value === 'Y'
                      && ((rewardFormState$ | async).controls.accumulationCycleTypeOption.value === 'FD'
                      || resourceType !== 'CARD')">
                      <mat-form-field fxFlex>
                        <mat-select [ngrxFormControlState]="(rewardFormState$ | async).controls.accumulationCycleMonth"
                          (selectionChange)="clearCycleDay()">
                          <mat-option>{{ 'COMMON.PLEASE_SELECT' | translate}}</mat-option>
                          <mat-option *ngFor="let i of monthList" [value]="i.key">
                            {{i.value}}
                          </mat-option>
                        </mat-select>
                        <mat-error *ngIf="(rewardFormState$ | async).errors._accumulationCycleMonth">
                          <app-error-validation [errors]="(rewardFormState$ | async).errors._accumulationCycleMonth">
                          </app-error-validation>
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div *ngIf="(rewardFormState$ | async).controls.accumulationCycleType.value === 'Y'
                    && (rewardFormState$ | async).controls.accumulationCycleMonth.value
                    && ((rewardFormState$ | async).controls.accumulationCycleTypeOption.value === 'FD'
                    || resourceType !== 'CARD')">
                      <mat-form-field fxFlex>
                        <mat-select [ngrxFormControlState]="(rewardFormState$ | async).controls.accumulationCycleDay">
                          <mat-option>{{ 'COMMON.PLEASE_SELECT' | translate}}</mat-option>
                          <mat-option *ngFor="let i of dayOfMonthList" [value]="i.key">
                            {{i.value}}
                          </mat-option>
                        </mat-select>
                        <mat-error *ngIf="(rewardFormState$ | async).errors._accumulationCycleDay">
                          <app-error-validation [errors]="(rewardFormState$ | async).errors._accumulationCycleDay">
                          </app-error-validation>
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div *ngIf="(rewardFormState$ | async).controls.accumulationCycleType.value === 'B'" style="padding-bottom: 10px;">
                      <mat-radio-group [ngrxFormControlState]="(rewardFormState$ | async).controls.billingCycleType"
                        color="primary" fxLayoutGap="8px" required (change)="clearBillingCycleType()">
                        <mat-radio-button *ngFor="let i of billingCycleTypeList" [value]="i.key">
                          {{i.value}}
                        </mat-radio-button>
                      </mat-radio-group>
                    </div>

                    <div
                      *ngIf="(rewardFormState$ | async).controls.billingCycleType.value === 'BC' || (rewardFormState$ | async).controls.billingCycleType.value === 'AC'"
                      style="width: 100% !important;">
                      <mat-form-field fxFlex="100">
                        <mat-label>{{ 'AUTO_REDEMPTION.NUMBER_OF_DAYS' | translate }}</mat-label>
                        <mat-icon class="material-icons-outlined" matSuffix
                          matTooltip="{{ 'AUTO_REDEMPTION.NUMBER_OF_DAYS_HINT' | translate }}">info</mat-icon>
                        <input matInput [ngrxFormControlState]="(rewardFormState$ | async).controls.numberOfDays" type="text" maxlength="1"
                          required>
                        <mat-error *ngIf="(rewardFormState$ | async).errors._numberOfDays">
                          <app-error-validation [errors]="(rewardFormState$ | async).errors._numberOfDays">
                          </app-error-validation>
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>

                  <span class="radio-button-label">
                    {{ 'CAMPAIGN.TRANSACTION_METHOD' | translate }}
                  </span>
                  <mat-radio-group [ngrxFormControlState]="(rewardFormState$ | async).controls.transactionMethod"
                    color="primary" (change)="onChangeTransactionMethod()" fxLayoutGap="8px">
                    <mat-radio-button *ngFor="let i of transactionMethodList" [value]="i.key">
                      {{i.value}}
                    </mat-radio-button>
                  </mat-radio-group>
                  <br>

                  <div *ngIf="(rewardFormState$ | async).controls.transactionMethod.value === 'AMOUNT'"
                    fxLayout="column">
                    <div fxLayout="row" fxFlex>
                      <mat-form-field fxFlex="48">
                        <span matPrefix>{{ 'CAMPAIGN.AMOUNT' | translate }} &nbsp;</span>
                        <input matInput [ngrxFormControlState]="(rewardFormState$ | async).controls.valueFactor"
                          onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57 || event.charCode == 46"
                          type="text" maxlength="13">
                        <mat-error *ngIf="(rewardFormState$ | async).errors._valueFactor">
                          <app-error-validation [errors]="(rewardFormState$ | async).errors._valueFactor">
                          </app-error-validation>
                        </mat-error>
                      </mat-form-field>
                      <h3 fxFlex="4" style="text-align: center;">=</h3>
                      <mat-form-field fxFlex="48">
                        <input matInput style="text-align:right" [ngrxFormControlState]="(rewardFormState$ | async).controls.pointFactor"
                          onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57 || event.charCode == 46"
                          type="text" maxlength="15">
                        <span matSuffix>&nbsp; {{ 'CAMPAIGN.POINTS' | translate }}</span>
                        <mat-error *ngIf="(rewardFormState$ | async).errors._pointFactor">
                          <app-error-validation [errors]="(rewardFormState$ | async).errors._pointFactor">
                          </app-error-validation>
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>

                  <div *ngIf="(rewardFormState$ | async).controls.transactionMethod.value === 'SWIPE'"
                    fxLayout="column">
                    <div fxLayout="row" fxFlex>
                      <mat-form-field fxFlex="48">
                        <span matPrefix>{{ 'CAMPAIGN.NUMBER_OF_TRANSACTIONS' | translate }} &nbsp;</span>
                        <input matInput [ngrxFormControlState]="(rewardFormState$ | async).controls.valueFactor"
                          onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57"
                          type="text" maxlength="10">
                        <mat-error *ngIf="(rewardFormState$ | async).errors._valueFactor">
                          <app-error-validation [errors]="(rewardFormState$ | async).errors._valueFactor">
                          </app-error-validation>
                        </mat-error>
                      </mat-form-field>
                      <h3 fxFlex="4" style="text-align: center;">=</h3>
                      <mat-form-field fxFlex="48">
                        <input matInput style="text-align:right" [ngrxFormControlState]="(rewardFormState$ | async).controls.pointFactor"
                          onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57 || event.charCode == 46"
                          type="text" maxlength="15">
                        <span matSuffix>&nbsp; {{ 'CAMPAIGN.POINTS' | translate }}</span>
                        <mat-error *ngIf="(rewardFormState$ | async).errors._pointFactor">
                          <app-error-validation [errors]="(rewardFormState$ | async).errors._pointFactor">
                          </app-error-validation>
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                </div>

                <div *ngIf="(rewardFormState$ | async).controls.type.value === 'TPT'" fxLayout="column">
                  <span class="radio-button-label">
                    {{ 'CAMPAIGN.REWARD_CREDIT_METHOD' | translate }}
                  </span>
                  <mat-radio-group [ngrxFormControlState]="(rewardFormState$ | async).controls.rewardCreditMethod"
                    color="primary" (change)="onChangeRewardCreditMethod()" fxLayoutGap="8px">
                    <mat-radio-button *ngFor="let i of rewardCreditMethodList" [value]="i.key">
                      {{i.value}}
                    </mat-radio-button>
                  </mat-radio-group>
                  <br>

                  <span class="radio-button-label">
                    {{ 'CAMPAIGN.TRANSACTION_METHOD' | translate }}
                  </span>
                  <mat-radio-group [ngrxFormControlState]="(rewardFormState$ | async).controls.transactionMethod"
                    color="primary" (change)="onChangeTransactionMethod()" fxLayoutGap="8px">
                    <mat-radio-button *ngFor="let i of transactionMethodList" [value]="i.key">
                      {{i.value}}
                    </mat-radio-button>
                  </mat-radio-group>
                  <br>

                  <div *ngIf="(rewardFormState$ | async).controls.transactionMethod.value === 'AMOUNT'"
                    fxLayout="column">
                    <mat-form-field>
                      <mat-label>{{ 'CAMPAIGN.CALCULATION_METHOD' | translate }}</mat-label>
                      <mat-select [ngrxFormControlState]="(rewardFormState$ | async).controls.rewardMethod"
                        (selectionChange)="onChangeRewardMethod()">
                        <mat-option>{{ 'COMMON.PLEASE_SELECT' | translate}}</mat-option>
                        <mat-option *ngFor="let i of calculationMethodList" [value]="i.key">
                          {{i.value}}
                        </mat-option>
                      </mat-select>
                      <mat-error *ngIf="(rewardFormState$ | async).errors._rewardMethod">
                        <app-error-validation [errors]="(rewardFormState$ | async).errors._rewardMethod">
                        </app-error-validation>
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>

                <div *ngIf="(rewardFormState$ | async).controls.type.value === 'TPC'" fxLayout="column">
                  <span class="radio-button-label">
                    {{ 'CAMPAIGN.REWARD_CREDIT_METHOD' | translate }}
                  </span>
                  <mat-radio-group [ngrxFormControlState]="(rewardFormState$ | async).controls.rewardCreditMethod"
                    color="primary" (change)="onChangeRewardCreditMethod()" fxLayoutGap="8px">
                    <mat-radio-button *ngFor="let i of rewardCreditMethodList" [value]="i.key">
                      {{i.value}}
                    </mat-radio-button>
                  </mat-radio-group>
                  <br>

                  <div *ngIf="(rewardFormState$ | async).controls.type.value === 'TPC' &&
                (rewardFormState$ | async).controls.rewardCreditMethod.value === 'CCE'" fxLayout="column">
                    <mat-form-field>
                      <mat-label>{{ 'CAMPAIGN.CYCLE' | translate }}</mat-label>
                      <mat-select [ngrxFormControlState]="(rewardFormState$ | async).controls.accumulationCycleType"
                        (selectionChange)="onChangeAccumulationCycleType()">
                        <mat-option>{{ 'COMMON.PLEASE_SELECT' | translate}}</mat-option>
                        <mat-option *ngFor="let i of accumulationCycleTypeList" [value]="i.key">
                          {{i.value}}</mat-option>
                      </mat-select>
                      <mat-error *ngIf="(rewardFormState$ | async).errors._accumulationCycleType">
                        <app-error-validation [errors]="(rewardFormState$ | async).errors._accumulationCycleType">
                        </app-error-validation>
                      </mat-error>
                    </mat-form-field>

                    <div *ngIf="(rewardFormState$ | async).controls.accumulationCycleType.value === 'W'">
                      <mat-form-field fxFlex>
                        <mat-select [ngrxFormControlState]="(rewardFormState$ | async).controls.accumulationCycleDay">
                          <mat-option>{{ 'COMMON.PLEASE_SELECT' | translate}}</mat-option>
                          <mat-option *ngFor="let i of dayList" [value]="i.key">
                            {{i.value}}
                          </mat-option>
                        </mat-select>
                        <mat-error *ngIf="(rewardFormState$ | async).errors._accumulationCycleDay">
                          <app-error-validation [errors]="(rewardFormState$ | async).errors._accumulationCycleDay">
                          </app-error-validation>
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div *ngIf="(rewardFormState$ | async).controls.accumulationCycleType.value === 'M'">
                      <mat-form-field fxFlex>
                        <mat-select [ngrxFormControlState]="(rewardFormState$ | async).controls.accumulationCycleDay">
                          <mat-option>{{ 'COMMON.PLEASE_SELECT' | translate}}</mat-option>
                          <mat-option *ngFor="let i of dayOfMonthList" [value]="i.key">
                            {{i.value}}
                          </mat-option>
                        </mat-select>
                        <mat-error *ngIf="(rewardFormState$ | async).errors._accumulationCycleDay">
                          <app-error-validation [errors]="(rewardFormState$ | async).errors._accumulationCycleDay">
                          </app-error-validation>
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div *ngIf="(rewardFormState$ | async).controls.accumulationCycleType.value === 'Y' && resourceType === 'CARD'">
                      <mat-radio-group [ngrxFormControlState]="(rewardFormState$ | async).controls.accumulationCycleTypeOption" color="primary"
                        fxLayoutGap="8px" required (change)="onChangeYearlyCycleType()">
                        <mat-radio-button *ngFor="let i of yearlyCycleTypeList" [value]="i.key">
                          {{i.value}}
                        </mat-radio-button>
                      </mat-radio-group>
                    </div>

                    <div *ngIf="(rewardFormState$ | async).controls.accumulationCycleTypeOption.value !== 'FD' && resourceType === 'CARD'" style="margin-bottom: 1.25em;"></div>

                    <div *ngIf="(rewardFormState$ | async).controls.accumulationCycleType.value === 'Y'
                      && ((rewardFormState$ | async).controls.accumulationCycleTypeOption.value === 'FD'
                      || resourceType !== 'CARD')">
                      <mat-form-field fxFlex>
                        <mat-select [ngrxFormControlState]="(rewardFormState$ | async).controls.accumulationCycleMonth"
                          (selectionChange)="clearCycleDay()">
                          <mat-option>{{ 'COMMON.PLEASE_SELECT' | translate}}</mat-option>
                          <mat-option *ngFor="let i of monthList" [value]="i.key">
                            {{i.value}}
                          </mat-option>
                        </mat-select>
                        <mat-error *ngIf="(rewardFormState$ | async).errors._accumulationCycleMonth">
                          <app-error-validation [errors]="(rewardFormState$ | async).errors._accumulationCycleMonth">
                          </app-error-validation>
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div *ngIf="(rewardFormState$ | async).controls.accumulationCycleType.value === 'Y'
                    && (rewardFormState$ | async).controls.accumulationCycleMonth.value
                    && ((rewardFormState$ | async).controls.accumulationCycleTypeOption.value === 'FD'
                    || resourceType !== 'CARD')">
                      <mat-form-field fxFlex>
                        <mat-select [ngrxFormControlState]="(rewardFormState$ | async).controls.accumulationCycleDay">
                          <mat-option>{{ 'COMMON.PLEASE_SELECT' | translate}}</mat-option>
                          <mat-option *ngFor="let i of dayOfMonthList" [value]="i.key">
                            {{i.value}}
                          </mat-option>
                        </mat-select>
                        <mat-error *ngIf="(rewardFormState$ | async).errors._accumulationCycleDay">
                          <app-error-validation [errors]="(rewardFormState$ | async).errors._accumulationCycleDay">
                          </app-error-validation>
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div *ngIf="(rewardFormState$ | async).controls.accumulationCycleType.value === 'B'" style="padding-bottom: 10px;">
                      <mat-radio-group [ngrxFormControlState]="(rewardFormState$ | async).controls.billingCycleType"
                        color="primary" fxLayoutGap="8px" required (change)="clearBillingCycleType()">
                        <mat-radio-button *ngFor="let i of billingCycleTypeList" [value]="i.key">
                          {{i.value}}
                        </mat-radio-button>
                      </mat-radio-group>
                    </div>

                    <div
                      *ngIf="(rewardFormState$ | async).controls.billingCycleType.value === 'BC' || (rewardFormState$ | async).controls.billingCycleType.value === 'AC'"
                      style="width: 100% !important;">
                      <mat-form-field fxFlex="100">
                        <mat-label>{{ 'AUTO_REDEMPTION.NUMBER_OF_DAYS' | translate }}</mat-label>
                        <mat-icon class="material-icons-outlined" matSuffix
                          matTooltip="{{ 'AUTO_REDEMPTION.NUMBER_OF_DAYS_HINT' | translate }}">info</mat-icon>
                        <input matInput [ngrxFormControlState]="(rewardFormState$ | async).controls.numberOfDays" type="text" maxlength="1"
                          required>
                        <mat-error *ngIf="(rewardFormState$ | async).errors._numberOfDays">
                          <app-error-validation [errors]="(rewardFormState$ | async).errors._numberOfDays">
                          </app-error-validation>
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>

                  <span class="radio-button-label">
                    {{ 'CAMPAIGN.TRANSACTION_METHOD' | translate }}
                  </span>
                  <mat-radio-group [ngrxFormControlState]="(rewardFormState$ | async).controls.transactionMethod"
                    color="primary" (change)="onChangeTransactionMethod()" fxLayoutGap="8px">
                    <mat-radio-button *ngFor="let i of transactionMethodList" [value]="i.key">
                      {{i.value}}
                    </mat-radio-button>
                  </mat-radio-group>
                  <br>

                  <div *ngIf="(rewardFormState$ | async).controls.transactionMethod.value === 'AMOUNT'"
                    fxLayout="column">
                    <mat-form-field>
                      <mat-label>{{ 'CAMPAIGN.CALCULATION_METHOD' | translate }}</mat-label>
                      <mat-select [ngrxFormControlState]="(rewardFormState$ | async).controls.rewardMethod"
                        (selectionChange)="onChangeRewardMethod()">
                        <mat-option>{{ 'COMMON.PLEASE_SELECT' | translate}}</mat-option>
                        <mat-option *ngFor="let i of calculationMethodList" [value]="i.key">
                          {{i.value}}
                        </mat-option>
                      </mat-select>
                      <mat-error *ngIf="(rewardFormState$ | async).errors._rewardMethod">
                        <app-error-validation [errors]="(rewardFormState$ | async).errors._rewardMethod">
                        </app-error-validation>
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <div *ngIf="(rewardFormState$ | async).controls.transactionMethod.value === 'SWIPE'"
                    fxLayout="column">
                    <mat-form-field fxFlex>
                      <mat-label>{{ 'CAMPAIGN.CALCULATION_METHOD' | translate }}</mat-label>
                      <input matInput value="Fixed Points" type="text" maxlength="255" readonly>
                    </mat-form-field>
                  </div>
                </div>

                <div *ngIf="(rewardFormState$ | async).controls.type.value === 'INC'" fxLayout="column">
                  <span class="radio-button-label">
                    {{ 'CAMPAIGN.REWARD_LEVEL' | translate }}
                  </span>
                  <mat-radio-group [ngrxFormControlState]="(rewardFormState$ | async).controls.rewardLevel"
                  color="primary" (change)="onChangeRewardLevel()" fxLayoutGap="8px">
                  <mat-radio-button *ngFor="let i of rewardLevelList" [value]="i.key">
                    {{i.value}}
                  </mat-radio-button>
                </mat-radio-group>

                  <br>

                  <span class="radio-button-label">
                    {{ 'CAMPAIGN.REWARD_CREDIT_METHOD' | translate }}
                  </span>
                  <mat-radio-group [ngrxFormControlState]="(rewardFormState$ | async).controls.rewardCreditMethod"
                    color="primary" (change)="onChangeRewardCreditMethod()" fxLayoutGap="8px">
                    <mat-radio-button *ngFor="let i of rewardCreditMethodList" [value]="i.key">
                      {{i.value}}
                    </mat-radio-button>
                  </mat-radio-group>

                  <br>

                  <div *ngIf="(rewardFormState$ | async).controls.type.value === 'INC' &&
                (rewardFormState$ | async).controls.rewardCreditMethod.value === 'CCE'" fxLayout="column">
                    <mat-form-field>
                      <mat-label>{{ 'CAMPAIGN.CYCLE' | translate }}</mat-label>
                      <mat-select [ngrxFormControlState]="(rewardFormState$ | async).controls.accumulationCycleType"
                        (selectionChange)="onChangeAccumulationCycleType()">
                        <mat-option>{{ 'COMMON.PLEASE_SELECT' | translate}}</mat-option>
                        <mat-option *ngFor="let i of accumulationCycleTypeList" [value]="i.key">
                          {{i.value}}</mat-option>
                      </mat-select>
                      <mat-error *ngIf="(rewardFormState$ | async).errors._accumulationCycleType">
                        <app-error-validation [errors]="(rewardFormState$ | async).errors._accumulationCycleType">
                        </app-error-validation>
                      </mat-error>
                    </mat-form-field>

                    <div *ngIf="(rewardFormState$ | async).controls.accumulationCycleType.value === 'W'">
                      <mat-form-field fxFlex>
                        <mat-select [ngrxFormControlState]="(rewardFormState$ | async).controls.accumulationCycleDay">
                          <mat-option>{{ 'COMMON.PLEASE_SELECT' | translate}}</mat-option>
                          <mat-option *ngFor="let i of dayList" [value]="i.key">
                            {{i.value}}
                          </mat-option>
                        </mat-select>
                        <mat-error *ngIf="(rewardFormState$ | async).errors._accumulationCycleDay">
                          <app-error-validation [errors]="(rewardFormState$ | async).errors._accumulationCycleDay">
                          </app-error-validation>
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div *ngIf="(rewardFormState$ | async).controls.accumulationCycleType.value === 'M'">
                      <mat-form-field fxFlex>
                        <mat-select [ngrxFormControlState]="(rewardFormState$ | async).controls.accumulationCycleDay">
                          <mat-option>{{ 'COMMON.PLEASE_SELECT' | translate}}</mat-option>
                          <mat-option *ngFor="let i of dayOfMonthList" [value]="i.key">
                            {{i.value}}
                          </mat-option>
                        </mat-select>
                        <mat-error *ngIf="(rewardFormState$ | async).errors._accumulationCycleDay">
                          <app-error-validation [errors]="(rewardFormState$ | async).errors._accumulationCycleDay">
                          </app-error-validation>
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div *ngIf="(rewardFormState$ | async).controls.accumulationCycleType.value === 'Y' && resourceType === 'CARD'">
                      <mat-radio-group [ngrxFormControlState]="(rewardFormState$ | async).controls.accumulationCycleTypeOption" color="primary"
                        fxLayoutGap="8px" required (change)="onChangeYearlyCycleType()">
                        <mat-radio-button *ngFor="let i of yearlyCycleTypeList" [value]="i.key">
                          {{i.value}}
                        </mat-radio-button>
                      </mat-radio-group>
                    </div>

                    <div *ngIf="(rewardFormState$ | async).controls.accumulationCycleTypeOption.value !== 'FD' && resourceType === 'CARD'" style="margin-bottom: 1.25em;"></div>

                    <div *ngIf="(rewardFormState$ | async).controls.accumulationCycleType.value === 'Y'
                      && ((rewardFormState$ | async).controls.accumulationCycleTypeOption.value === 'FD'
                      || resourceType !== 'CARD')">
                      <mat-form-field fxFlex>
                        <mat-select [ngrxFormControlState]="(rewardFormState$ | async).controls.accumulationCycleMonth"
                          (selectionChange)="clearCycleDay()">
                          <mat-option>{{ 'COMMON.PLEASE_SELECT' | translate}}</mat-option>
                          <mat-option *ngFor="let i of monthList" [value]="i.key">
                            {{i.value}}
                          </mat-option>
                        </mat-select>
                        <mat-error *ngIf="(rewardFormState$ | async).errors._accumulationCycleMonth">
                          <app-error-validation [errors]="(rewardFormState$ | async).errors._accumulationCycleMonth">
                          </app-error-validation>
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div *ngIf="(rewardFormState$ | async).controls.accumulationCycleType.value === 'Y'
                    && (rewardFormState$ | async).controls.accumulationCycleMonth.value
                    && ((rewardFormState$ | async).controls.accumulationCycleTypeOption.value === 'FD'
                    || resourceType !== 'CARD')">
                      <mat-form-field fxFlex>
                        <mat-select [ngrxFormControlState]="(rewardFormState$ | async).controls.accumulationCycleDay">
                          <mat-option>{{ 'COMMON.PLEASE_SELECT' | translate}}</mat-option>
                          <mat-option *ngFor="let i of dayOfMonthList" [value]="i.key">
                            {{i.value}}
                          </mat-option>
                        </mat-select>
                        <mat-error *ngIf="(rewardFormState$ | async).errors._accumulationCycleDay">
                          <app-error-validation [errors]="(rewardFormState$ | async).errors._accumulationCycleDay">
                          </app-error-validation>
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div *ngIf="(rewardFormState$ | async).controls.accumulationCycleType.value === 'B'" style="padding-bottom: 10px;">
                      <mat-radio-group [ngrxFormControlState]="(rewardFormState$ | async).controls.billingCycleType"
                        color="primary" fxLayoutGap="8px" required (change)="clearBillingCycleType()">
                        <mat-radio-button *ngFor="let i of billingCycleTypeList" [value]="i.key">
                          {{i.value}}
                        </mat-radio-button>
                      </mat-radio-group>
                    </div>

                    <div
                      *ngIf="(rewardFormState$ | async).controls.billingCycleType.value === 'BC' || (rewardFormState$ | async).controls.billingCycleType.value === 'AC'"
                      style="width: 100% !important;">
                      <mat-form-field fxFlex="100">
                        <mat-label>{{ 'AUTO_REDEMPTION.NUMBER_OF_DAYS' | translate }}</mat-label>
                        <mat-icon class="material-icons-outlined" matSuffix
                          matTooltip="{{ 'AUTO_REDEMPTION.NUMBER_OF_DAYS_HINT' | translate }}">info</mat-icon>
                        <input matInput [ngrxFormControlState]="(rewardFormState$ | async).controls.numberOfDays"
                          type="text" maxlength="1" required>
                        <mat-error *ngIf="(rewardFormState$ | async).errors._numberOfDays">
                          <app-error-validation [errors]="(rewardFormState$ | async).errors._numberOfDays">
                          </app-error-validation>
                        </mat-error>
                      </mat-form-field>
                    </div>
				          </div>

                  <span class="radio-button-label">
                    {{ 'CAMPAIGN.TRANSACTION_METHOD' | translate }}
                  </span>
                  <mat-radio-group [ngrxFormControlState]="(rewardFormState$ | async).controls.transactionMethod"
                    color="primary" (change)="onChangeTransactionMethod()" fxLayoutGap="8px">
                    <mat-radio-button *ngFor="let i of transactionMethodList" [value]="i.key">
                      {{i.value}}
                    </mat-radio-button>
                  </mat-radio-group>
                  <br>

				<div fxLayout="row" fxFlex fxLayout="column">
					<mat-form-field fxFlex>
						<mat-label>{{ 'CAMPAIGN.INCREMENT_VALUE' | translate }}</mat-label>
            <input matInput [ngrxFormControlState]="(rewardFormState$ | async).controls.conditionFactor"
              onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57"
              type="text" maxlength="10" required>
						<mat-error *ngIf="(rewardFormState$ | async).errors._conditionFactor">
							<app-error-validation [errors]="(rewardFormState$ | async).errors._conditionFactor">
							</app-error-validation>
						</mat-error>
					</mat-form-field>
				</div>

				<mat-form-field>
					<mat-label>{{ 'CAMPAIGN.CALCULATION_METHOD' | translate }}</mat-label>
					<mat-select [ngrxFormControlState]="(rewardFormState$ | async).controls.rewardMethod"
						(selectionChange)="onChangeRewardMethod()">
						<mat-option>{{ 'COMMON.PLEASE_SELECT' | translate}}</mat-option>
						<mat-option *ngFor="let i of calculationMethodList" [value]="i.key">
							{{i.value}}
						</mat-option>
					</mat-select>
					<mat-error *ngIf="(rewardFormState$ | async).errors._rewardMethod">
						<app-error-validation [errors]="(rewardFormState$ | async).errors._rewardMethod">
						</app-error-validation>
					</mat-error>
				</mat-form-field>

				<div *ngIf="(rewardFormState$ | async).controls.transactionMethod.value === 'AMOUNT'
						 && (rewardFormState$ | async).controls.rewardMethod.value === 'RATIO'" fxLayout="column">
                    <div fxLayout="row" fxFlex>
                      <mat-form-field fxFlex="48">
                        <span matPrefix>{{ 'CAMPAIGN.AMOUNT' | translate }} &nbsp;</span>
                        <input matInput [ngrxFormControlState]="(rewardFormState$ | async).controls.valueFactor"
                          onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57 || event.charCode == 46"
                          type="text" maxlength="13">
                        <mat-error *ngIf="(rewardFormState$ | async).errors._valueFactor">
                          <app-error-validation [errors]="(rewardFormState$ | async).errors._valueFactor">
                          </app-error-validation>
                        </mat-error>
                      </mat-form-field>
                      <h3 fxFlex="4" style="text-align: center;">=</h3>
                      <mat-form-field fxFlex="48">
                        <input matInput style="text-align:right" [ngrxFormControlState]="(rewardFormState$ | async).controls.pointFactor"
                          onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57 || event.charCode == 46"
                          type="text" maxlength="15">
                        <span matSuffix>&nbsp; {{ 'CAMPAIGN.POINTS' | translate }}</span>
                        <mat-error *ngIf="(rewardFormState$ | async).errors._pointFactor">
                          <app-error-validation [errors]="(rewardFormState$ | async).errors._pointFactor">
                          </app-error-validation>
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>

				<div *ngIf="(rewardFormState$ | async).controls.transactionMethod.value === 'SWIPE'
						 && (rewardFormState$ | async).controls.rewardMethod.value === 'RATIO'" fxLayout="column">
                    <div fxLayout="row" fxFlex>
                      <mat-form-field fxFlex="48">
                        <span matPrefix>{{ 'CAMPAIGN.NUMBER_OF_TRANSACTIONS' | translate }} &nbsp;</span>
                        <input matInput [ngrxFormControlState]="(rewardFormState$ | async).controls.valueFactor"
                          onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57"
                          type="text" maxlength="10">
                        <mat-error *ngIf="(rewardFormState$ | async).errors._valueFactor">
                          <app-error-validation [errors]="(rewardFormState$ | async).errors._valueFactor">
                          </app-error-validation>
                        </mat-error>
                      </mat-form-field>
                      <h3 fxFlex="4" style="text-align: center;">=</h3>
                      <mat-form-field fxFlex="48">
                        <input matInput style="text-align:right" [ngrxFormControlState]="(rewardFormState$ | async).controls.pointFactor"
                          onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57 || event.charCode == 46"
                          type="text" maxlength="15">
                        <span matSuffix>&nbsp; {{ 'CAMPAIGN.POINTS' | translate }}</span>
                        <mat-error *ngIf="(rewardFormState$ | async).errors._pointFactor">
                          <app-error-validation [errors]="(rewardFormState$ | async).errors._pointFactor">
                          </app-error-validation>
                        </mat-error>
                      </mat-form-field>
                    </div>
				  </div>

				<div *ngIf="(rewardFormState$ | async).controls.rewardMethod.value === 'FIX'
						&& ((rewardFormState$ | async).controls.transactionMethod.value === 'AMOUNT'|| (rewardFormState$ | async).controls.transactionMethod.value === 'SWIPE')
						" fxLayout="column">
					<mat-form-field fxFlex>
						<mat-label>{{ 'CAMPAIGN.POINTS' | translate }}</mat-label>
            <input matInput [ngrxFormControlState]="(rewardFormState$ | async).controls.pointFactor"
              onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57"
              type="text" maxlength="10">
						<mat-error *ngIf="(rewardFormState$ | async).errors._pointFactor">
							<app-error-validation [errors]="(rewardFormState$ | async).errors._pointFactor">
							</app-error-validation>
						</mat-error>
					</mat-form-field>
				</div>
                </div>

                <div *ngIf="((rewardFormState$ | async).controls.type.value === 'X2X' &&
                (rewardFormState$ | async).controls.transactionMethod.value &&
                (rewardFormState$ | async).controls.transactionMethod.value === 'AMOUNT') ||

                ((rewardFormState$ | async).controls.type.value === 'TPT' &&
                (rewardFormState$ | async).controls.rewardMethod.value &&
                (rewardFormState$ | async).controls.rewardMethod.value === 'RATIO') ||

                ((rewardFormState$ | async).controls.type.value === 'TPC' &&
                (rewardFormState$ | async).controls.rewardMethod.value &&
                (rewardFormState$ | async).controls.rewardMethod.value === 'RATIO') ||

                ((rewardFormState$ | async).controls.type.value === 'INC' &&
                (rewardFormState$ | async).controls.transactionMethod.value &&
                (rewardFormState$ | async).controls.transactionMethod.value === 'AMOUNT')" fxLayout="column">
                  <mat-checkbox [ngrxFormControlState]="(rewardFormState$ | async).controls.rounding" color="primary"
                    (change)="onChangeRounding()">
                    {{ 'CAMPAIGN.ROUNDING' | translate }}
                  </mat-checkbox>
                </div>

                <div *ngIf="(rewardFormState$ | async).controls.rounding.value" fxLayout="column">
                  <br>
                  <mat-radio-group [ngrxFormControlState]="(rewardFormState$ | async).controls.roundingValue"
                    color="primary" fxLayoutGap="8px">
                    <mat-radio-button *ngFor="let i of roundingList" [value]="i.key">
                      {{i.value}}
                    </mat-radio-button>
                  </mat-radio-group>
                  <br>

                  <span class="radio-button-label">
                    {{ 'CAMPAIGN.OPTION' | translate }}
                  </span>
                  <mat-radio-group [ngrxFormControlState]="(rewardFormState$ | async).controls.numberDecimal"
                    color="primary" fxLayoutGap="8px">
                    <mat-radio-button *ngFor="let i of numberDecimalList" [value]="i.key">
                      {{i.value}}
                    </mat-radio-button>
                  </mat-radio-group>

                  <mat-form-field fxFlex>
                    <input matInput [ngrxFormControlState]="(rewardFormState$ | async).controls.numberDecimalValue"
                      onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 49 && event.charCode <= 57"
                      type="text" maxlength="1">
                    <mat-icon class="material-icons-outlined" matSuffix
                      matTooltip="{{ 'CAMPAIGN.INFO.OPTION' | translate }}">
                      info</mat-icon>
                    <mat-error *ngIf="(rewardFormState$ | async).errors._numberDecimalValue">
                      <app-error-validation [errors]="(rewardFormState$ | async).errors._numberDecimalValue"
                        [digitFrom]="1" [digitTo]="9" [maxlength]="1">
                      </app-error-validation>
                    </mat-error>
                  </mat-form-field>
                </div>

                <div *ngIf="((rewardFormState$ | async).controls.type.value === 'TPT' ||
                (rewardFormState$ | async).controls.type.value === 'TPC') &&
                (rewardFormState$ | async).controls.rewardMethod.value" fxLayout="column">
                  <div
                    *ngFor="let campaignTier of (campaignTiers$ | async);trackBy: trackByCampaignTierIndex;let i=index"
                    fxFlexOffset="8px">
                    <app-campaign-tier [campaignTier]="campaignTier" [index]="i"></app-campaign-tier>
                  </div>

                  <div fxLayout="row" fxLayout.lt-md="column" fxFlexOffset="8px">
                    <button [disabled]="campaignTiersLength >= 10" mat-button color="primary" style="padding:0px"
                      (click)="addCampaignTier()">
                      <mat-icon class="material-icons-outlined">add</mat-icon>
                      {{ 'CAMPAIGN.ACTION.ADD_TIER' | translate }}
                    </button>
                  </div>
                </div>

              </div>

            </form>
          </mat-step>
        </mat-horizontal-stepper>
      </div>
      <div class="guidelines-div" fxFlex="30">
        <div class="contain-div">
          <span class="mat-h3">{{ 'CAMPAIGN.GUIDELINES.GUIDELINES' | translate }}</span>
          <br>
          <br>
          {{ getGuidelines() | translate}}
        </div>
      </div>
    </div>

  </mat-dialog-content>

  <div class="action-button-div" fxLayout="row" fxLayoutAlign="end">
    <button *ngIf="selectedIndex === 0" class="mat-raised-button mat-primary next-button" (click)="stepper.next()"
      [disabled]="!((formState$ | async).controls.channel.value) ||
      !isFirstStepValid" fxFlexOffset="8px">{{ 'ACTION.NEXT' | translate }}</button>

    <button *ngIf="selectedIndex === 1" [disabled]="!(formState$ | async).isTouched ||
      (formState$ | async).isInvalid ||
      !(rewardFormState$ | async).isTouched ||
       (rewardFormState$ | async).isInvalid ||
       !isValid" class="mat-raised-button mat-primary next-button" (click)="submit()"
      fxFlexOffset="8px">{{ 'ACTION.DONE' | translate }}</button>
  </div>
</div>

<div
	*ngIf="!isView && isPublishedUpdate && (informationDetailFormState$ | async).controls.enableConditionalReward.value === true">
	<mat-dialog-content #container>

		<div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="5px" fxLayoutGap.lt-md="0px">
			<div fxFlex="70">
				<mat-horizontal-stepper linear #stepper [@.disabled]="false" [selectedIndex]="selectedIndex"
					(selectionChange)="selectionChange($event); container.scrollTop = 0;">

					<ng-template matStepperIcon="edit">
						<mat-icon>done</mat-icon>
					</ng-template>

					<ng-template matStepperIcon="error">
						<mat-icon>error</mat-icon>
					</ng-template>

					<mat-step [stepControl]="!isFirstStepValid ? null : rulesetConditionFormGroup"
						[completed]="!isFirstStepValid ? false : rulesetConditionFormGroup.valid" #step
						[hasError]="(formState$ | async).isTouched && (formState$ | async).isInvalid">
						<ng-template matStepLabel>{{ 'CAMPAIGN.SETUP_RULESET_CONDITIONS' | translate }}</ng-template>

						<form novalidate [ngrxFormState]="formState$ | async" [formGroup]="rulesetConditionFormGroup">
							<div fxLayout="row" fxLayoutGap="5px">
								<mat-form-field fxFlex="50">
									<mat-label>{{ 'CAMPAIGN.RULESET_CODE' | translate }}</mat-label>
									<input matInput value="{{(formState$ | async).controls.code.value}}" type="text"
										maxlength="10" readonly disabled>
								</mat-form-field>

								<mat-form-field fxFlex="50">
									<mat-label>{{ 'CAMPAIGN.RULESET_NAME' | translate }}</mat-label>
									<input matInput value="{{(formState$ | async).controls.name.value}}" type="text"
										maxlength="40" readonly disabled>
								</mat-form-field>
							</div>

							<div fxLayout="column">
								<mat-form-field fxFlex>
									<mat-label>{{ 'CAMPAIGN.CHANNEL' | translate }}</mat-label>
									<input matInput
										value="{{getChannelValue((formState$ | async).controls.channel.value)}}"
										type="text" maxlength="255" readonly disabled>
								</mat-form-field>
							</div>
						</form>

						<div *ngIf="(formState$ | async).controls.channel.value" fxLayout="column">
							<div *ngFor="let ruleset of (rulesets$ | async);trackBy: trackByIndex;let i=index"
								fxFlexOffset="8px">
								<div [ngSwitch]="ruleset.ruleAddType">
									<app-rule-dynamic *ngSwitchCase="'RULE'" [ruleset]="ruleset"></app-rule-dynamic>
									<app-rule-group *ngSwitchCase="'CONDITIONAL_RULES'" [ruleset]="ruleset">
									</app-rule-group>
								</div>
							</div>

							<div fxLayout="column" fxFlexOffset="8px">
								<div>
									<button mat-button [matMenuTriggerFor]="menu" color="primary">
										<mat-icon class="material-icons-outlined">add</mat-icon>
										{{ 'CAMPAIGN.ACTION.ADD_RULE' | translate }}
									</button>
									<mat-menu #menu="matMenu">
										<button mat-menu-item (click)="addRuleset()"
											[disabled]="(rulesets$ | async).length >= ruleListLength">{{
											'CAMPAIGN.ADD_RULE' | translate }}</button>
										<button mat-menu-item (click)="addGroup()">{{ 'CAMPAIGN.ADD_CONDITIONAL_RULES' |
											translate }}</button>
									</mat-menu>
								</div>
							</div>
						</div>
					</mat-step>

					<mat-step [completed]="(rewardFormState$ | async).isValid && isValid"
						[hasError]="(rewardFormState$ | async).isTouched && ((rewardFormState$ | async).isInvalid || !isValid)">
						<ng-template matStepLabel>{{ 'CAMPAIGN.SETUP_REWARD' | translate }}</ng-template>

						<form novalidate [ngrxFormState]="rewardFormState$ | async" fxLayout="column">
							<mat-form-field>
								<mat-label>{{ 'CAMPAIGN.REWARD_POOL' | translate }}</mat-label>
								<input matInput
									value="{{getRewardPoolValue((rewardFormState$ | async).controls.rewardPool.value)}}"
									type="text" maxlength="255" readonly disabled>
							</mat-form-field>

							<div *ngIf="(rewardFormState$ | async).controls.rewardPool.value" fxLayout="column">
								<mat-form-field *ngIf="resourceType === 'BANK'">
									<mat-label>{{ 'CAMPAIGN.FIELD_MAPPING' | translate }}</mat-label>
									<input matInput
										value="{{getAmountValue((rewardFormState$ | async).controls.amountField.value)}}"
										type="text" maxlength="255" readonly disabled>
								</mat-form-field>
								<br>
								<span class="content-title">{{ 'CAMPAIGN.REWARD_CONDITIONS' | translate }}</span>
								<br>
								<mat-form-field>
									<mat-label>{{ 'CAMPAIGN.TYPE' | translate }}</mat-label>
									<input matInput
										value="{{getRewardTypeValue((rewardFormState$ | async).controls.type.value)}}"
										type="text" maxlength="255" readonly disabled>
								</mat-form-field>

								<div *ngIf="(rewardFormState$ | async).controls.type.value === 'X2X'" fxLayout="column">
									<mat-form-field>
										<mat-label>{{ 'CAMPAIGN.REWARD_CREDIT_METHOD' | translate }}</mat-label>
										<input matInput
											value="{{getRewardCreditMethodValue((rewardFormState$ | async).controls.rewardCreditMethod.value)}}"
											type="text" maxlength="255" readonly disabled>
									</mat-form-field>

									<div *ngIf="(rewardFormState$ | async).controls.type.value === 'X2X' &&
							  (rewardFormState$ | async).controls.rewardCreditMethod.value === 'CCE'" fxLayout="column">
										<mat-form-field>
											<mat-label>{{ 'CAMPAIGN.CYCLE' | translate }}</mat-label>
											<input matInput
												value="{{getAccumulationCycleTypeValue((rewardFormState$ | async).controls.accumulationCycleType.value)}}"
												type="text" maxlength="255" readonly disabled>
										</mat-form-field>

										<div
											*ngIf="(rewardFormState$ | async).controls.accumulationCycleType.value === 'W'">
											<mat-form-field fxFlex>
												<input matInput
													value="{{getDayValue((rewardFormState$ | async).controls.accumulationCycleDay.value)}}"
													type="text" maxlength="255" readonly disabled>
											</mat-form-field>
										</div>

										<div
											*ngIf="(rewardFormState$ | async).controls.accumulationCycleType.value === 'M'">
											<mat-form-field fxFlex>
												<input matInput
													value="{{getDayOfMonthValue((rewardFormState$ | async).controls.accumulationCycleDay.value)}}"
													type="text" maxlength="255" readonly disabled>
											</mat-form-field>
										</div>

										<div
											*ngIf="(rewardFormState$ | async).controls.accumulationCycleType.value === 'Y' && (rewardFormState$ | async).controls.accumulationCycleTypeOption.value">
											<mat-form-field fxFlex>
												<input matInput
													value="{{getYearlyCycleValue((rewardFormState$ | async).controls.accumulationCycleTypeOption.value)}}"
													type="text" maxlength="255" readonly disabled>
											</mat-form-field>
										</div>

										<div
											*ngIf="(rewardFormState$ | async).controls.accumulationCycleType.value === 'Y' && ((rewardFormState$ | async).controls.accumulationCycleTypeOption.value === 'FD' || channelValue != 'CARDLINK')">
											<mat-form-field fxFlex>
												<input matInput
													value="{{getMonthValue((rewardFormState$ | async).controls.accumulationCycleMonth.value)}}"
													type="text" maxlength="255" readonly disabled>
											</mat-form-field>
										</div>

										<div
											*ngIf="(rewardFormState$ | async).controls.accumulationCycleType.value === 'Y' &&
									 (rewardFormState$ | async).controls.accumulationCycleMonth.value &&  ((rewardFormState$ | async).controls.accumulationCycleTypeOption.value === 'FD' || channelValue != 'CARDLINK')">
											<mat-form-field fxFlex>
												<input matInput
													value="{{getDayOfMonthValue((rewardFormState$ | async).controls.accumulationCycleDay.value)}}"
													type="text" maxlength="255" readonly disabled>
											</mat-form-field>
										</div>

										<div *ngIf="(rewardFormState$ | async).controls.accumulationCycleType.value === 'B'"
											fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="5px"
											fxLayoutGap.lt-md="0px">
											<mat-form-field fxFlex>
												<input matInput
													value="{{getBillingCycleType((rewardFormState$ | async).controls.billingCycleType.value)}}"
													type="text" maxlength="255" readonly disabled>
											</mat-form-field>
										</div>

										<div
											*ngIf="(rewardFormState$ | async).controls.billingCycleType.value === 'BC' || (rewardFormState$ | async).controls.billingCycleType.value === 'AC'">
											<mat-form-field fxFlex>
												<input matInput
													value="{{getDayOfMonthValue((rewardFormState$ | async).controls.numberOfDays.value)}}"
													type="text" maxlength="255" readonly disabled>
											</mat-form-field>
										</div>
									</div>

									<mat-form-field>
										<mat-label>{{ 'CAMPAIGN.TRANSACTION_METHOD' | translate }}</mat-label>
										<input matInput
											value="{{getTransactionMethodValue((rewardFormState$ | async).controls.transactionMethod.value)}}"
											type="text" maxlength="255" readonly disabled>
									</mat-form-field>

									<div *ngIf="(rewardFormState$ | async).controls.transactionMethod.value === 'AMOUNT'"
										fxLayout="column">
										<div fxLayout="row" fxFlex>
											<mat-form-field fxFlex="48">
												<span matPrefix>{{ 'CAMPAIGN.AMOUNT' | translate }} &nbsp;</span>
												<input matInput
													[ngrxFormControlState]="(rewardFormState$ | async).controls.valueFactor"
													onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57 || event.charCode == 46"
													type="text" maxlength="13">
												<mat-error *ngIf="(rewardFormState$ | async).errors._valueFactor">
													<app-error-validation
														[errors]="(rewardFormState$ | async).errors._valueFactor">
													</app-error-validation>
												</mat-error>
											</mat-form-field>
											<h3 fxFlex="4" style="text-align: center;">=</h3>
											<mat-form-field fxFlex="48">
												<input matInput style="text-align:right"
													[ngrxFormControlState]="(rewardFormState$ | async).controls.pointFactor"
													onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57 || event.charCode == 46"
													type="text" maxlength="15">
												<span matSuffix>&nbsp; {{ 'CAMPAIGN.POINTS' | translate }}</span>
												<mat-error *ngIf="(rewardFormState$ | async).errors._pointFactor">
													<app-error-validation
														[errors]="(rewardFormState$ | async).errors._pointFactor">
													</app-error-validation>
												</mat-error>
											</mat-form-field>
										</div>
									</div>

									<div *ngIf="(rewardFormState$ | async).controls.transactionMethod.value === 'SWIPE'"
										fxLayout="column">
										<mat-form-field fxFlex>
											<mat-label>{{ 'CAMPAIGN.CALCULATION_METHOD' | translate }}</mat-label>
											<input matInput value="Fixed Points" type="text" maxlength="255" readonly
												disabled>
										</mat-form-field>

										<mat-form-field fxFlex>
											<mat-label>{{ 'CAMPAIGN.POINTS' | translate }}</mat-label>
											<input matInput
												[ngrxFormControlState]="(rewardFormState$ | async).controls.pointFactor"
												onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57"
												type="text" maxlength="10">
											<mat-error *ngIf="(rewardFormState$ | async).errors._pointFactor">
												<app-error-validation
													[errors]="(rewardFormState$ | async).errors._pointFactor">
												</app-error-validation>
											</mat-error>
										</mat-form-field>
									</div>
								</div>

								<div *ngIf="(rewardFormState$ | async).controls.type.value === 'OX'" fxLayout="column">
									<mat-form-field>
										<mat-label>{{ 'CAMPAIGN.REWARD_CREDIT_METHOD' | translate }}</mat-label>
										<input matInput
											value="{{getRewardCreditMethodValue((rewardFormState$ | async).controls.rewardCreditMethod.value)}}"
											type="text" maxlength="255" readonly disabled>
									</mat-form-field>

									<div *ngIf="(rewardFormState$ | async).controls.type.value === 'OX' &&
							  ((rewardFormState$ | async).controls.rewardCreditMethod.value === 'I' ||
							  (rewardFormState$ | async).controls.rewardCreditMethod.value === 'CCE')" fxLayout="column">
										<mat-form-field>
											<mat-label>{{ 'CAMPAIGN.CYCLE' | translate }}</mat-label>
											<input matInput
												value="{{getAccumulationCycleTypeValue((rewardFormState$ | async).controls.accumulationCycleType.value)}}"
												type="text" maxlength="255" readonly disabled>
										</mat-form-field>

										<div
											*ngIf="(rewardFormState$ | async).controls.accumulationCycleType.value === 'W'">
											<mat-form-field fxFlex>
												<input matInput
													value="{{getDayValue((rewardFormState$ | async).controls.accumulationCycleDay.value)}}"
													type="text" maxlength="255" readonly disabled>
											</mat-form-field>
										</div>

										<div
											*ngIf="(rewardFormState$ | async).controls.accumulationCycleType.value === 'M'">
											<mat-form-field fxFlex>
												<input matInput
													value="{{getDayOfMonthValue((rewardFormState$ | async).controls.accumulationCycleDay.value)}}"
													type="text" maxlength="255" readonly disabled>
											</mat-form-field>
										</div>

										<div
											*ngIf="(rewardFormState$ | async).controls.accumulationCycleType.value === 'Y' && (rewardFormState$ | async).controls.accumulationCycleTypeOption.value">
											<mat-form-field fxFlex>
												<input matInput
													value="{{getYearlyCycleValue((rewardFormState$ | async).controls.accumulationCycleTypeOption.value)}}"
													type="text" maxlength="255" readonly disabled>
											</mat-form-field>
										</div>

										<div
											*ngIf="(rewardFormState$ | async).controls.accumulationCycleType.value === 'Y' && ((rewardFormState$ | async).controls.accumulationCycleTypeOption.value === 'FD' || channelValue != 'CARDLINK')">
											<mat-form-field fxFlex>
												<input matInput
													value="{{getMonthValue((rewardFormState$ | async).controls.accumulationCycleMonth.value)}}"
													type="text" maxlength="255" readonly disabled>
											</mat-form-field>
										</div>

										<div
											*ngIf="(rewardFormState$ | async).controls.accumulationCycleType.value === 'Y' &&
									 (rewardFormState$ | async).controls.accumulationCycleMonth.value && ((rewardFormState$ | async).controls.accumulationCycleTypeOption.value === 'FD' || channelValue != 'CARDLINK')">
											<mat-form-field fxFlex>
												<input matInput
													value="{{getDayOfMonthValue((rewardFormState$ | async).controls.accumulationCycleDay.value)}}"
													type="text" maxlength="255" readonly disabled>
											</mat-form-field>
										</div>

										<div *ngIf="(rewardFormState$ | async).controls.accumulationCycleType.value === 'B'"
											fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="5px"
											fxLayoutGap.lt-md="0px">
											<mat-form-field fxFlex>
												<input matInput
													value="{{getBillingCycleType((rewardFormState$ | async).controls.billingCycleType.value)}}"
													type="text" maxlength="255" readonly disabled>
											</mat-form-field>
										</div>

										<div
											*ngIf="(rewardFormState$ | async).controls.billingCycleType.value === 'BC' || (rewardFormState$ | async).controls.billingCycleType.value === 'AC'">
											<mat-form-field fxFlex>
												<input matInput
													value="{{getDayOfMonthValue((rewardFormState$ | async).controls.numberOfDays.value)}}"
													type="text" maxlength="255" readonly disabled>
											</mat-form-field>
										</div>
									</div>

									<mat-form-field>
										<mat-label>{{ 'CAMPAIGN.TRANSACTION_METHOD' | translate }}</mat-label>
										<input matInput
											value="{{getTransactionsMethodValue((rewardFormState$ | async).controls.transactionMethod.value)}}"
											type="text" maxlength="255" readonly disabled>
									</mat-form-field>

									<div *ngIf="(rewardFormState$ | async).controls.transactionMethod.value === 'AMOUNT'"
										fxLayout="column">
										<div fxLayout="row" fxFlex>
											<mat-form-field fxFlex="48">
												<span matPrefix>{{ 'CAMPAIGN.AMOUNT' | translate }} &nbsp;</span>
												<input matInput
													[ngrxFormControlState]="(rewardFormState$ | async).controls.conditionFactor"
													onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57 || event.charCode == 46"
													type="text" maxlength="13">
												<mat-error *ngIf="(rewardFormState$ | async).errors._conditionFactor">
													<app-error-validation
														[errors]="(rewardFormState$ | async).errors._conditionFactor">
													</app-error-validation>
												</mat-error>
											</mat-form-field>
											<h3 fxFlex="4" style="text-align: center;">=</h3>
											<mat-form-field fxFlex="48">
												<input matInput style="text-align:right"
													[ngrxFormControlState]="(rewardFormState$ | async).controls.pointFactor"
													onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57 || event.charCode == 46"
													type="text" maxlength="15">
												<span matSuffix>&nbsp; {{ 'CAMPAIGN.POINTS' | translate }}</span>
												<mat-error *ngIf="(rewardFormState$ | async).errors._pointFactor">
													<app-error-validation
														[errors]="(rewardFormState$ | async).errors._pointFactor">
													</app-error-validation>
												</mat-error>
											</mat-form-field>
										</div>
									</div>

									<div *ngIf="(rewardFormState$ | async).controls.transactionMethod.value === 'SWIPE'"
										fxLayout="column">
										<div fxLayout="row" fxFlex>
											<mat-form-field fxFlex="48">
												<span matPrefix>{{ 'CAMPAIGN.NUMBER_OF_TRANSACTIONS' | translate }}
													&nbsp;</span>
												<input matInput
													[ngrxFormControlState]="(rewardFormState$ | async).controls.conditionFactor"
													onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57"
													type="text" maxlength="10">
												<mat-error *ngIf="(rewardFormState$ | async).errors._conditionFactor">
													<app-error-validation
														[errors]="(rewardFormState$ | async).errors._conditionFactor">
													</app-error-validation>
												</mat-error>
											</mat-form-field>
											<h3 fxFlex="4" style="text-align: center;">=</h3>
											<mat-form-field fxFlex="48">
												<input matInput style="text-align:right"
													[ngrxFormControlState]="(rewardFormState$ | async).controls.pointFactor"
													onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57 || event.charCode == 46"
													type="text" maxlength="15">
												<span matSuffix>&nbsp; {{ 'CAMPAIGN.POINTS' | translate }}</span>
												<mat-error *ngIf="(rewardFormState$ | async).errors._pointFactor">
													<app-error-validation
														[errors]="(rewardFormState$ | async).errors._pointFactor">
													</app-error-validation>
												</mat-error>
											</mat-form-field>
										</div>
									</div>
								</div>

								<div *ngIf="(rewardFormState$ | async).controls.type.value === 'PX'" fxLayout="column">
									<mat-form-field>
										<mat-label>{{ 'CAMPAIGN.REWARD_CREDIT_METHOD' | translate }}</mat-label>
										<input matInput
											value="{{getRewardCreditMethodValue((rewardFormState$ | async).controls.rewardCreditMethod.value)}}"
											type="text" maxlength="255" readonly disabled>
									</mat-form-field>

									<div *ngIf="(rewardFormState$ | async).controls.type.value === 'PX' &&
							  ((rewardFormState$ | async).controls.rewardCreditMethod.value === 'I' ||
							  (rewardFormState$ | async).controls.rewardCreditMethod.value === 'CCE')" fxLayout="column">
										<mat-form-field>
											<mat-label>{{ 'CAMPAIGN.CYCLE' | translate }}</mat-label>
											<input matInput
												value="{{getAccumulationCycleTypeValue((rewardFormState$ | async).controls.accumulationCycleType.value)}}"
												type="text" maxlength="255" readonly disabled>
										</mat-form-field>

										<div
											*ngIf="(rewardFormState$ | async).controls.accumulationCycleType.value === 'W'">
											<mat-form-field fxFlex>
												<input matInput
													value="{{getDayValue((rewardFormState$ | async).controls.accumulationCycleDay.value)}}"
													type="text" maxlength="255" readonly disabled>
											</mat-form-field>
										</div>

										<div
											*ngIf="(rewardFormState$ | async).controls.accumulationCycleType.value === 'M'">
											<mat-form-field fxFlex>
												<input matInput
													value="{{getDayOfMonthValue((rewardFormState$ | async).controls.accumulationCycleDay.value)}}"
													type="text" maxlength="255" readonly disabled>
											</mat-form-field>
										</div>

										<div
											*ngIf="(rewardFormState$ | async).controls.accumulationCycleType.value === 'Y' && (rewardFormState$ | async).controls.accumulationCycleTypeOption.value">
											<mat-form-field fxFlex>
												<input matInput
													value="{{getYearlyCycleValue((rewardFormState$ | async).controls.accumulationCycleTypeOption.value)}}"
													type="text" maxlength="255" readonly disabled>
											</mat-form-field>
										</div>

										<div
											*ngIf="(rewardFormState$ | async).controls.accumulationCycleType.value === 'Y' && ((rewardFormState$ | async).controls.accumulationCycleTypeOption.value === 'FD' || channelValue != 'CARDLINK')">
											<mat-form-field fxFlex>
												<input matInput
													value="{{getMonthValue((rewardFormState$ | async).controls.accumulationCycleMonth.value)}}"
													type="text" maxlength="255" readonly disabled>
											</mat-form-field>
										</div>

										<div
											*ngIf="(rewardFormState$ | async).controls.accumulationCycleType.value === 'Y' &&
									 (rewardFormState$ | async).controls.accumulationCycleMonth.value && ((rewardFormState$ | async).controls.accumulationCycleTypeOption.value === 'FD' || channelValue != 'CARDLINK')">
											<mat-form-field fxFlex>
												<input matInput
													value="{{getDayOfMonthValue((rewardFormState$ | async).controls.accumulationCycleDay.value)}}"
													type="text" maxlength="255" readonly disabled>
											</mat-form-field>
										</div>

										<div *ngIf="(rewardFormState$ | async).controls.accumulationCycleType.value === 'B'"
											fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="5px"
											fxLayoutGap.lt-md="0px">
											<mat-form-field fxFlex>
												<input matInput
													value="{{getBillingCycleType((rewardFormState$ | async).controls.billingCycleType.value)}}"
													type="text" maxlength="255" readonly disabled>
											</mat-form-field>
										</div>

										<div
											*ngIf="(rewardFormState$ | async).controls.billingCycleType.value === 'BC' || (rewardFormState$ | async).controls.billingCycleType.value === 'AC'">
											<mat-form-field fxFlex>
												<input matInput
													value="{{getDayOfMonthValue((rewardFormState$ | async).controls.numberOfDays.value)}}"
													type="text" maxlength="255" readonly disabled>
											</mat-form-field>
										</div>
									</div>

									<mat-form-field>
										<mat-label>{{ 'CAMPAIGN.TRANSACTION_METHOD' | translate }}</mat-label>
										<input matInput
											value="{{getTransactionsMethodValue((rewardFormState$ | async).controls.transactionMethod.value)}}"
											type="text" maxlength="255" readonly disabled>
									</mat-form-field>

									<div *ngIf="(rewardFormState$ | async).controls.transactionMethod.value === 'AMOUNT'"
										fxLayout="column">
										<div fxLayout="row" fxFlex>
											<mat-form-field fxFlex="48">
												<span matPrefix>{{ 'CAMPAIGN.AMOUNT' | translate }} &nbsp;</span>
												<input matInput
													[ngrxFormControlState]="(rewardFormState$ | async).controls.valueFactor"
													onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57 || event.charCode == 46"
													type="text" maxlength="13">
												<mat-error *ngIf="(rewardFormState$ | async).errors._valueFactor">
													<app-error-validation
														[errors]="(rewardFormState$ | async).errors._valueFactor">
													</app-error-validation>
												</mat-error>
											</mat-form-field>
											<h3 fxFlex="4" style="text-align: center;">=</h3>
											<mat-form-field fxFlex="48">
												<input matInput style="text-align:right"
													[ngrxFormControlState]="(rewardFormState$ | async).controls.pointFactor"
													onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57 || event.charCode == 46"
													type="text" maxlength="15">
												<span matSuffix>&nbsp; {{ 'CAMPAIGN.POINTS' | translate }}</span>
												<mat-error *ngIf="(rewardFormState$ | async).errors._pointFactor">
													<app-error-validation
														[errors]="(rewardFormState$ | async).errors._pointFactor">
													</app-error-validation>
												</mat-error>
											</mat-form-field>
										</div>
									</div>

									<div *ngIf="(rewardFormState$ | async).controls.transactionMethod.value === 'SWIPE'"
										fxLayout="column">
										<div fxLayout="row" fxFlex>
											<mat-form-field fxFlex="48">
												<span matPrefix>{{ 'CAMPAIGN.NUMBER_OF_TRANSACTIONS' | translate }}
													&nbsp;</span>
												<input matInput
													[ngrxFormControlState]="(rewardFormState$ | async).controls.valueFactor"
													onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57"
													type="text" maxlength="10">
												<mat-error *ngIf="(rewardFormState$ | async).errors._valueFactor">
													<app-error-validation
														[errors]="(rewardFormState$ | async).errors._valueFactor">
													</app-error-validation>
												</mat-error>
											</mat-form-field>
											<h3 fxFlex="4" style="text-align: center;">=</h3>
											<mat-form-field fxFlex="48">
												<input matInput style="text-align:right"
													[ngrxFormControlState]="(rewardFormState$ | async).controls.pointFactor"
													onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57 || event.charCode == 46"
													type="text" maxlength="15">
												<span matSuffix>&nbsp; {{ 'CAMPAIGN.POINTS' | translate }}</span>
												<mat-error *ngIf="(rewardFormState$ | async).errors._pointFactor">
													<app-error-validation
														[errors]="(rewardFormState$ | async).errors._pointFactor">
													</app-error-validation>
												</mat-error>
											</mat-form-field>
										</div>
									</div>
								</div>

								<div *ngIf="(rewardFormState$ | async).controls.type.value === 'TPT'" fxLayout="column">
									<mat-form-field>
										<mat-label>{{ 'CAMPAIGN.REWARD_CREDIT_METHOD' | translate }}</mat-label>
										<input matInput
											value="{{getRewardCreditMethodValue((rewardFormState$ | async).controls.rewardCreditMethod.value)}}"
											type="text" maxlength="255" readonly disabled>
									</mat-form-field>

									<mat-form-field>
										<mat-label>{{ 'CAMPAIGN.TRANSACTION_METHOD' | translate }}</mat-label>
										<input matInput
											value="{{getTransactionsMethodValue((rewardFormState$ | async).controls.transactionMethod.value)}}"
											type="text" maxlength="255" readonly disabled>
									</mat-form-field>

									<div *ngIf="(rewardFormState$ | async).controls.transactionMethod.value === 'AMOUNT'"
										fxLayout="column">
										<mat-form-field>
											<mat-label>{{ 'CAMPAIGN.CALCULATION_METHOD' | translate }}</mat-label>
											<mat-select
												[ngrxFormControlState]="(rewardFormState$ | async).controls.rewardMethod"
												(selectionChange)="onChangeRewardMethod()">
												<mat-option>{{ 'COMMON.PLEASE_SELECT' | translate}}</mat-option>
												<mat-option *ngFor="let i of calculationMethodList" [value]="i.key">
													{{i.value}}
												</mat-option>
											</mat-select>
											<mat-error *ngIf="(rewardFormState$ | async).errors._rewardMethod">
												<app-error-validation
													[errors]="(rewardFormState$ | async).errors._rewardMethod">
												</app-error-validation>
											</mat-error>
										</mat-form-field>
									</div>
								</div>

								<div *ngIf="(rewardFormState$ | async).controls.type.value === 'TPC'" fxLayout="column">
									<mat-form-field>
										<mat-label>{{ 'CAMPAIGN.REWARD_CREDIT_METHOD' | translate }}</mat-label>
										<input matInput
											value="{{getRewardCreditMethodValue((rewardFormState$ | async).controls.rewardCreditMethod.value)}}"
											type="text" maxlength="255" readonly disabled>
									</mat-form-field>

									<div *ngIf="(rewardFormState$ | async).controls.type.value === 'TPC' &&
							  (rewardFormState$ | async).controls.rewardCreditMethod.value === 'CCE'" fxLayout="column">
										<mat-form-field>
											<mat-label>{{ 'CAMPAIGN.CYCLE' | translate }}</mat-label>
											<input matInput
												value="{{getAccumulationCycleTypeValue((rewardFormState$ | async).controls.accumulationCycleType.value)}}"
												type="text" maxlength="255" readonly disabled>
										</mat-form-field>

										<div
											*ngIf="(rewardFormState$ | async).controls.accumulationCycleType.value === 'W'">
											<mat-form-field fxFlex>
												<input matInput
													value="{{getDayValue((rewardFormState$ | async).controls.accumulationCycleDay.value)}}"
													type="text" maxlength="255" readonly disabled>
											</mat-form-field>
										</div>

										<div
											*ngIf="(rewardFormState$ | async).controls.accumulationCycleType.value === 'M'">
											<mat-form-field fxFlex>
												<input matInput
													value="{{getDayOfMonthValue((rewardFormState$ | async).controls.accumulationCycleDay.value)}}"
													type="text" maxlength="255" readonly disabled>
											</mat-form-field>
										</div>

										<div
											*ngIf="(rewardFormState$ | async).controls.accumulationCycleType.value === 'Y' && (rewardFormState$ | async).controls.accumulationCycleTypeOption.value">
											<mat-form-field fxFlex>
												<input matInput
													value="{{getYearlyCycleValue((rewardFormState$ | async).controls.accumulationCycleTypeOption.value)}}"
													type="text" maxlength="255" readonly disabled>
											</mat-form-field>
										</div>

										<div
											*ngIf="(rewardFormState$ | async).controls.accumulationCycleType.value === 'Y' && ((rewardFormState$ | async).controls.accumulationCycleTypeOption.value === 'FD' || channelValue != 'CARDLINK')">
											<mat-form-field fxFlex>
												<input matInput
													value="{{getMonthValue((rewardFormState$ | async).controls.accumulationCycleMonth.value)}}"
													type="text" maxlength="255" readonly disabled>
											</mat-form-field>
										</div>

										<div
											*ngIf="(rewardFormState$ | async).controls.accumulationCycleType.value === 'Y' &&
									 (rewardFormState$ | async).controls.accumulationCycleMonth.value && ((rewardFormState$ | async).controls.accumulationCycleTypeOption.value === 'FD' || channelValue != 'CARDLINK')">
											<mat-form-field fxFlex>
												<input matInput
													value="{{getDayOfMonthValue((rewardFormState$ | async).controls.accumulationCycleDay.value)}}"
													type="text" maxlength="255" readonly disabled>
											</mat-form-field>
										</div>

										<div *ngIf="(rewardFormState$ | async).controls.accumulationCycleType.value === 'B'"
											fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="5px"
											fxLayoutGap.lt-md="0px">
											<mat-form-field fxFlex>
												<input matInput
													value="{{getBillingCycleType((rewardFormState$ | async).controls.billingCycleType.value)}}"
													type="text" maxlength="255" readonly disabled>
											</mat-form-field>
										</div>

										<div
											*ngIf="(rewardFormState$ | async).controls.billingCycleType.value === 'BC' || (rewardFormState$ | async).controls.billingCycleType.value === 'AC'">
											<mat-form-field fxFlex>
												<input matInput
													value="{{getDayOfMonthValue((rewardFormState$ | async).controls.numberOfDays.value)}}"
													type="text" maxlength="255" readonly disabled>
											</mat-form-field>
										</div>
									</div>

									<div *ngIf="(rewardFormState$ | async).controls.transactionMethod.value === 'AMOUNT'"
										fxLayout="column">
										<mat-form-field>
											<mat-label>{{ 'CAMPAIGN.CALCULATION_METHOD' | translate }}</mat-label>
											<input matInput
												value="{{getCalculationMethodValue((rewardFormState$ | async).controls.rewardMethod.value)}}"
												type="text" maxlength="255" readonly disabled>
										</mat-form-field>
									</div>

									<div *ngIf="(rewardFormState$ | async).controls.transactionMethod.value === 'SWIPE'"
										fxLayout="column">
										<mat-form-field fxFlex>
											<mat-label>{{ 'CAMPAIGN.CALCULATION_METHOD' | translate }}</mat-label>
											<input matInput value="Fixed Points" type="text" maxlength="255" readonly>
										</mat-form-field>
									</div>
								</div>

								<div *ngIf="(rewardFormState$ | async).controls.type.value === 'INC'" fxLayout="column">

									<mat-form-field>
										<mat-label>{{ 'CAMPAIGN.REWARD_LEVEL' | translate }}</mat-label>
										<input matInput
											value="{{getRewardLevelValue((rewardFormState$ | async).controls.rewardLevel.value)}}"
											type="text" maxlength="255" readonly disabled>
									</mat-form-field>
									<mat-form-field>
										<mat-label>{{ 'CAMPAIGN.REWARD_CREDIT_METHOD' | translate }}</mat-label>
										<input matInput
											value="{{getRewardCreditMethodValue((rewardFormState$ | async).controls.rewardCreditMethod.value)}}"
											type="text" maxlength="255" readonly disabled>
									</mat-form-field>

									<div *ngIf="(rewardFormState$ | async).controls.type.value === 'INC' &&
							  						(rewardFormState$ | async).controls.rewardCreditMethod.value === 'CCE'" fxLayout="column">
										<mat-form-field>
											<mat-label>{{ 'CAMPAIGN.CYCLE' | translate }}</mat-label>
											<input matInput
												value="{{getAccumulationCycleTypeValue((rewardFormState$ | async).controls.accumulationCycleType.value)}}"
												type="text" maxlength="255" readonly disabled>
										</mat-form-field>

										<div
											*ngIf="(rewardFormState$ | async).controls.accumulationCycleType.value === 'W'">
											<mat-form-field fxFlex>
												<input matInput
													value="{{getDayValue((rewardFormState$ | async).controls.accumulationCycleDay.value)}}"
													type="text" maxlength="255" readonly disabled>
											</mat-form-field>
										</div>

										<div
											*ngIf="(rewardFormState$ | async).controls.accumulationCycleType.value === 'M'">
											<mat-form-field fxFlex>
												<input matInput
													value="{{getDayOfMonthValue((rewardFormState$ | async).controls.accumulationCycleDay.value)}}"
													type="text" maxlength="255" readonly disabled>
											</mat-form-field>
										</div>

										<div
											*ngIf="(rewardFormState$ | async).controls.accumulationCycleType.value === 'Y' && (rewardFormState$ | async).controls.accumulationCycleTypeOption.value">
											<mat-form-field fxFlex>
												<input matInput
													value="{{getYearlyCycleValue((rewardFormState$ | async).controls.accumulationCycleTypeOption.value)}}"
													type="text" maxlength="255" readonly disabled>
											</mat-form-field>
										</div>

										<div
											*ngIf="(rewardFormState$ | async).controls.accumulationCycleType.value === 'Y' && ((rewardFormState$ | async).controls.accumulationCycleTypeOption.value === 'FD' || channelValue != 'CARDLINK')">
											<mat-form-field fxFlex>
												<input matInput
													value="{{getMonthValue((rewardFormState$ | async).controls.accumulationCycleMonth.value)}}"
													type="text" maxlength="255" readonly disabled>
											</mat-form-field>
										</div>

										<div
											*ngIf="(rewardFormState$ | async).controls.accumulationCycleType.value === 'Y' &&
																			 (rewardFormState$ | async).controls.accumulationCycleMonth.value && ((rewardFormState$ | async).controls.accumulationCycleTypeOption.value === 'FD' || channelValue != 'CARDLINK')">
											<mat-form-field fxFlex>
												<input matInput value="{{getDayOfMonthValue((rewardFormState$ | async).controls.accumulationCycleDay.value)}}"
													type="text" maxlength="255" readonly disabled>
											</mat-form-field>
										</div>
									</div>

									<div *ngIf="(rewardFormState$ | async).controls.accumulationCycleType.value === 'B'"
										fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="5px"
										fxLayoutGap.lt-md="0px">
										<mat-form-field fxFlex>
											<input matInput
												value="{{getBillingCycleType((rewardFormState$ | async).controls.billingCycleType.value)}}"
												type="text" maxlength="255" readonly disabled>
										</mat-form-field>
									</div>

									<div
										*ngIf="(rewardFormState$ | async).controls.billingCycleType.value === 'BC' || (rewardFormState$ | async).controls.billingCycleType.value === 'AC'">
										<mat-form-field fxFlex>
											<input matInput
												value="{{getDayOfMonthValue((rewardFormState$ | async).controls.numberOfDays.value)}}"
												type="text" maxlength="255" readonly disabled>
										</mat-form-field>
									</div>

									<mat-form-field>
										<mat-label>{{ 'CAMPAIGN.TRANSACTION_METHOD' | translate }}</mat-label>
										<input matInput
											value="{{getTransactionMethodValue((rewardFormState$ | async).controls.transactionMethod.value)}}"
											type="text" maxlength="255" readonly disabled>
									</mat-form-field>

									<mat-form-field>
										<mat-label>{{ 'CAMPAIGN.INCREMENT_VALUE' | translate }}</mat-label>
										<input matInput
											value="{{(rewardFormState$ | async).controls.conditionFactor.value}}"
											type="text" maxlength="10" readonly disabled>
									</mat-form-field>

									<mat-form-field>
										<mat-label>{{ 'CAMPAIGN.CALCULATION_METHOD' | translate }}</mat-label>
										<input matInput
											value="{{getCalculationMethodValue((rewardFormState$ | async).controls.rewardMethod.value)}}"
											type="text" maxlength="255" readonly disabled>
									</mat-form-field>

									<div *ngIf="(rewardFormState$ | async).controls.transactionMethod.value === 'AMOUNT'
											&& (rewardFormState$ | async).controls.rewardMethod.value === 'RATIO'" fxLayout="column">
										<div fxLayout="row" fxFlex>
											<mat-form-field fxFlex="48">
												<span matPrefix>{{ 'CAMPAIGN.AMOUNT' | translate }} &nbsp;</span>
												<input matInput
													[ngrxFormControlState]="(rewardFormState$ | async).controls.valueFactor"
													onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57 || event.charCode == 46"
													type="text" maxlength="13">
												<mat-error *ngIf="(rewardFormState$ | async).errors._valueFactor">
													<app-error-validation
														[errors]="(rewardFormState$ | async).errors._valueFactor">
													</app-error-validation>
												</mat-error>
											</mat-form-field>
											<h3 fxFlex="4" style="text-align: center;">=</h3>
											<mat-form-field fxFlex="48">
												<input matInput style="text-align:right"
													[ngrxFormControlState]="(rewardFormState$ | async).controls.pointFactor"
													onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57 || event.charCode == 46"
													type="text" maxlength="15">
												<span matSuffix>&nbsp; {{ 'CAMPAIGN.POINTS' | translate }}</span>
												<mat-error *ngIf="(rewardFormState$ | async).errors._pointFactor">
													<app-error-validation
														[errors]="(rewardFormState$ | async).errors._pointFactor">
													</app-error-validation>
												</mat-error>
											</mat-form-field>
										</div>
									</div>

									<div *ngIf="(rewardFormState$ | async).controls.transactionMethod.value === 'SWIPE'
										&& (rewardFormState$ | async).controls.rewardMethod.value === 'RATIO'" fxLayout="column">
										<div fxLayout="row" fxFlex>
											<mat-form-field fxFlex="48">
												<span matPrefix>{{ 'CAMPAIGN.NUMBER_OF_TRANSACTIONS' | translate }}
													&nbsp;</span>
												<input matInput
													[ngrxFormControlState]="(rewardFormState$ | async).controls.valueFactor"
													onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57"
													type="text" maxlength="10">
												<mat-error *ngIf="(rewardFormState$ | async).errors._valueFactor">
													<app-error-validation
														[errors]="(rewardFormState$ | async).errors._valueFactor">
													</app-error-validation>
												</mat-error>
											</mat-form-field>
											<h3 fxFlex="4" style="text-align: center;">=</h3>
											<mat-form-field fxFlex="48">
												<input matInput style="text-align:right"
													[ngrxFormControlState]="(rewardFormState$ | async).controls.pointFactor"
													onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57 || event.charCode == 46"
													type="text" maxlength="15">
												<span matSuffix>&nbsp; {{ 'CAMPAIGN.POINTS' | translate }}</span>
												<mat-error *ngIf="(rewardFormState$ | async).errors._pointFactor">
													<app-error-validation
														[errors]="(rewardFormState$ | async).errors._pointFactor">
													</app-error-validation>
												</mat-error>
											</mat-form-field>
										</div>
									</div>

									<div *ngIf="(rewardFormState$ | async).controls.rewardMethod.value === 'FIX'
										&& ((rewardFormState$ | async).controls.transactionMethod.value === 'AMOUNT'|| (rewardFormState$ | async).controls.transactionMethod.value === 'SWIPE')
												" fxLayout="column">
										<mat-form-field fxFlex>
											<mat-label>{{ 'CAMPAIGN.POINTS' | translate }}</mat-label>
											<input matInput
												[ngrxFormControlState]="(rewardFormState$ | async).controls.pointFactor"
												onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57"
												type="text" maxlength="10">
											<mat-error *ngIf="(rewardFormState$ | async).errors._pointFactor">
												<app-error-validation
													[errors]="(rewardFormState$ | async).errors._pointFactor">
												</app-error-validation>
											</mat-error>
										</mat-form-field>
									</div>
								</div>

								<div *ngIf="(rewardFormState$ | async).controls.rounding.value" fxLayout="column">
									<mat-checkbox [ngrxFormControlState]="(rewardFormState$ | async).controls.rounding"
										color="primary" (click)="$event.preventDefault()">
										{{ 'CAMPAIGN.ROUNDING' | translate }}
									</mat-checkbox>
								</div>

								<div *ngIf="(rewardFormState$ | async).controls.rounding.value" fxLayout="column">
									<mat-form-field fxFlex>
										<input matInput
											value="{{getRoundingValue((rewardFormState$ | async).controls.roundingValue.value)}}"
											type="text" maxlength="255" readonly disabled>
									</mat-form-field>

									<mat-form-field fxFlex>
										<mat-label>{{ 'CAMPAIGN.OPTION' | translate }}</mat-label>
										<input matInput
											value="{{getNumberDecimalValue((rewardFormState$ | async).controls.numberDecimal.value)}}"
											type="text" maxlength="255" readonly disabled>
									</mat-form-field>

									<mat-form-field fxFlex>
										<input matInput
											value="{{(rewardFormState$ | async).controls.numberDecimalValue.value}}"
											type="text" maxlength="1" readonly disabled>
									</mat-form-field>
								</div>

								<div *ngIf="((rewardFormState$ | async).controls.type.value === 'TPT' ||
									(rewardFormState$ | async).controls.type.value === 'TPC') &&
										(rewardFormState$ | async).controls.rewardMethod.value" fxLayout="column">
									<div *ngFor="let campaignTier of (campaignTiers$ | async);trackBy: trackByCampaignTierIndex;let i=index"
										fxFlexOffset="8px">
										<app-campaign-tier [campaignTier]="campaignTier" [index]="i">
										</app-campaign-tier>
									</div>

									<div fxLayout="row" fxLayout.lt-md="column" fxFlexOffset="8px">
										<button [disabled]="campaignTiersLength >= 10" mat-button color="primary"
											style="padding:0px" (click)="addCampaignTier()">
											<mat-icon class="material-icons-outlined">add</mat-icon>
											{{ 'CAMPAIGN.ACTION.ADD_TIER' | translate }}
										</button>
									</div>
								</div>

							</div>

						</form>
					</mat-step>
				</mat-horizontal-stepper>
			</div>
			<div class="guidelines-div" fxFlex="30">
				<div class="contain-div">
					<span class="mat-h3">{{ 'CAMPAIGN.GUIDELINES.GUIDELINES' | translate }}</span>
					<br>
					<br>
					{{ getGuidelines() | translate}}
				</div>
			</div>
		</div>

	</mat-dialog-content>

	<div class="action-button-div" fxLayout="row" fxLayoutAlign="end">
		<button *ngIf="selectedIndex === 0" class="mat-raised-button mat-primary next-button" (click)="stepper.next()"
			[disabled]="!((formState$ | async).controls.channel.value) ||
		      !isFirstStepValid" fxFlexOffset="8px">{{ 'ACTION.NEXT' | translate }}</button>

		<button *ngIf="selectedIndex === 1" [disabled]="!(formState$ | async).isTouched ||
		      (formState$ | async).isInvalid ||
		      !(rewardFormState$ | async).isTouched ||
		       (rewardFormState$ | async).isInvalid ||
		       !isValid" class="mat-raised-button mat-primary next-button" (click)="submit()" fxFlexOffset="8px">{{'ACTION.DONE' | translate }}</button>
	</div>
</div>
