import { Action, createReducer, on } from '@ngrx/store'
import { formGroupReducer, reset, setValue, box } from 'ngrx-forms'
import * as ProductTypeGroupAction from './product-type-group.actions'
import * as ProductTypeGroupState from './product-type-group.state'

export const reducer = createReducer(
	ProductTypeGroupState.initialState,
	on(ProductTypeGroupAction.InitialState, () => ProductTypeGroupState.initialState),
	on(ProductTypeGroupAction.Reset, state => ({
		...state,
	})),
	on(ProductTypeGroupAction.SetId, (state, { code }) => ({
		...state,
		code
	})),
	on(ProductTypeGroupAction.GoCreate, state => ({
		...state,
		productTypeGroupDetailForm: reset(setValue(state.productTypeGroupDetailForm, ProductTypeGroupState.initialProductTypeGroupDetailFormValue)),
		productTypes: []
	})),
	on(ProductTypeGroupAction.RestError, state => ({
		...state,
		isLoading: false,
		isDuplicateLoading: false,
		isDuplicateDialog: false
	})),
	on(ProductTypeGroupAction.ResourceListCreate, state => ({
		...state,
		isLoading: true
	})),
	on(ProductTypeGroupAction.ResourceListCreateSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		resourceList: (payload || [])
	})),
	on(ProductTypeGroupAction.ResourceListUpdate, state => ({
		...state,
		isLoading: true
	})),
	on(ProductTypeGroupAction.ResourceListUpdateSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		resourceList: (payload || [])
	})),
	on(ProductTypeGroupAction.OptionList, state => ({
		...state,
		isLoading: true
	})),
	on(ProductTypeGroupAction.OptionListSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		productTypes: payload.cardProducts
	})),
	on(ProductTypeGroupAction.List, state => ({
		...state,
		isLoading: true
	})),
	on(ProductTypeGroupAction.ListSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		productTypeGroupListResponse: payload
	})),
	on(ProductTypeGroupAction.View, state => ({
		...state,
		isLoading: true,
		productTypeGroupDetailForm: setValue(state.productTypeGroupDetailForm, ProductTypeGroupState.initialProductTypeGroupDetailFormValue),
		allowDelete: true
	})),
	on(ProductTypeGroupAction.ViewSuccess, (state, { payload }) => {
		const productTypeGroupDetailFormView = {
			code: payload.code,
			name: payload.name,
			resourceCode: payload.resourceCode,
			resourceName: payload.resourceName,
			productTypeList: payload.productTypeList,
			productTypes: box(payload.productTypeList.map(productType => productType.productType)),
			description: payload.description,
			createdBy: payload.createdBy,
			createdDate: payload.createdDate,
			modifiedBy: payload.modifiedBy,
			modifiedDate: payload.modifiedDate
		}
		return ({
			...state,
			isLoading: false,
			productTypeGroupDetailForm: ProductTypeGroupState.validateProductTypeGroupDetailForm(setValue(state.productTypeGroupDetailForm, productTypeGroupDetailFormView as ProductTypeGroupState.ProductTypeGroupDetailForm)),
			allowDelete: payload.allowDelete,
			productTypeGroupDetailFormView
		})
	}),
	on(ProductTypeGroupAction.Create, state => ({
		...state,
		isLoading: true
	})),
	on(ProductTypeGroupAction.CreateSuccess, (state, { payload }) => ({
		...ProductTypeGroupState.initialState
	})),
	on(ProductTypeGroupAction.Update, state => ({
		...state,
		isLoading: true
	})),
	on(ProductTypeGroupAction.UpdateSuccess, (state, { payload }) => ({
		...state
	})),
	on(ProductTypeGroupAction.ClearType, state => ({
		...state,
		productTypeGroupDetailForm: ProductTypeGroupState.validateProductTypeGroupDetailForm(setValue(state.productTypeGroupDetailForm, {
			code: state.productTypeGroupDetailForm.value.code,
			name: state.productTypeGroupDetailForm.value.name,
			resourceCode: state.productTypeGroupDetailForm.value.resourceCode,
			resourceName: state.productTypeGroupDetailForm.value.resourceName,
			description: state.productTypeGroupDetailForm.value.description,
			productTypeList: state.productTypeGroupDetailForm.value.productTypeList,
			productTypes: box([]),
			createdBy: state.productTypeGroupDetailForm.value.createdBy,
			createdDate: state.productTypeGroupDetailForm.value.createdDate,
			modifiedBy: state.productTypeGroupDetailForm.value.modifiedBy,
			modifiedDate: state.productTypeGroupDetailForm.value.modifiedDate,
		}))
	})),
	on(ProductTypeGroupAction.Delete, state => ({
		...state,
		isLoading: true
	})),
	on(ProductTypeGroupAction.DeleteSuccess, () => ProductTypeGroupState.initialState),
	on(ProductTypeGroupAction.DuplicateDialog, state => ({
		...state,
		productTypeGroupDuplicateForm: reset(setValue(state.productTypeGroupDuplicateForm, ProductTypeGroupState.initialProductTypeGroupDuplicateFormValue)),
		isDuplicateDialog: false
	})),

	on(ProductTypeGroupAction.Duplicate, state => ({
		...state,
		isDuplicateLoading: true
	})),

	on(ProductTypeGroupAction.DuplicateSuccess, state => ({
		...state,
		isDuplicateLoading: false,
		isDuplicateDialog: true
	}))
)

export function Reducer(state: ProductTypeGroupState.State = ProductTypeGroupState.initialState, action: Action) {

	const productTypeGroupDetailForm = ProductTypeGroupState.validateProductTypeGroupDetailForm(formGroupReducer(state.productTypeGroupDetailForm, action))
	const productTypeGroupDuplicateForm = ProductTypeGroupState.validateProductTypeGroupDuplicateForm(formGroupReducer(state.productTypeGroupDuplicateForm, action))

	if (productTypeGroupDetailForm !== state.productTypeGroupDetailForm) {
		state = { ...state, productTypeGroupDetailForm }
	}

	if (productTypeGroupDuplicateForm !== state.productTypeGroupDuplicateForm) {
		state = { ...state, productTypeGroupDuplicateForm }
	}
	return reducer(state, action)
}
