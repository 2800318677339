import { Component, Inject, OnDestroy } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material'
import { Store } from '@ngrx/store'
import { FormGroupState } from 'ngrx-forms'
import { Observable, Subscription } from 'rxjs'
import * as AppStore from 'src/app/store'
import { ProductTypeGroupDuplicateForm } from 'src/app/store/param-settings/product-type-group'
import * as ProductTypeGroupAction from 'src/app/store/param-settings/product-type-group/product-type-group.actions'
import * as ProductTypeGroupSelectors from 'src/app/store/param-settings/product-type-group/product-type-group.selectors'

@Component({
	selector: 'app-product-type-group-duplicate',
	templateUrl: './product-type-group-duplicate.component.html',
	styleUrls: ['./product-type-group-duplicate.component.scss']
})
export class ProductTypeGroupDuplicateComponent implements OnDestroy {

	isLoading = false
	subs: Subscription
	formState$: Observable<FormGroupState<ProductTypeGroupDuplicateForm>>

	constructor(
		private store: Store<AppStore.State>,
		private dialogRef: MatDialogRef<ProductTypeGroupDuplicateComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		this.subs = this.store.select(ProductTypeGroupSelectors.getProductTypeGroup).subscribe(({ isDuplicateLoading, isDuplicateDialog }) => {
			this.isLoading = isDuplicateLoading

			if (isDuplicateDialog) {
				this.cancel()
			}
		})

		this.formState$ = this.store.select(({ productTypeGroup }) => productTypeGroup.productTypeGroupDuplicateForm)
	}

	ngOnDestroy() {
		this.subs.unsubscribe()
	}

	cancel() {
		this.dialogRef.close()
	}

	submit() {
		this.store.dispatch(ProductTypeGroupAction.Duplicate())
	}
}
