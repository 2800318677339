import { Action, createReducer, on } from '@ngrx/store'
import { formGroupReducer, setValue } from 'ngrx-forms'
import * as TargetSegmentAction from './target-segment.actions'
import * as TargetSegmentState from './target-segment.state'

export const reducer = createReducer(
	TargetSegmentState.initialState,
	on(TargetSegmentAction.InitialState, () => TargetSegmentState.initialState),
	on(TargetSegmentAction.GetAccountSegmentList, (state) => ({
		...state,
		isLoading: true,
	})),
	on(TargetSegmentAction.GetCardSegmentList, (state) => ({
		...state,
		isLoading: true,
	})),
	on(TargetSegmentAction.GetCustomerIdSegmentList, (state) => ({
		...state,
		isLoading: true,
	})),
	on(TargetSegmentAction.ViewAccountSegmentDetail, (state) => ({
		...state,
		isLoading: true,
	})),
	on(TargetSegmentAction.ViewCardSegmentDetail, (state) => ({
		...state,
		isLoading: true,
	})),
	on(TargetSegmentAction.ViewCustomerIdSegmentDetail, (state) => ({
		...state,
		isLoading: true,
	})),
	on(TargetSegmentAction.GetCardSegmentListSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		targetSegmentListResponse: {
			...state.targetSegmentListResponse,
			card: payload
		}
	})),
	on(TargetSegmentAction.GetAccountSegmentListSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		targetSegmentListResponse: {
			...state.targetSegmentListResponse,
			account: payload
		}
	})),
	on(TargetSegmentAction.GetCustomerIdSegmentListSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		targetSegmentListResponse: {
			...state.targetSegmentListResponse,
			customerId: payload
		}
	})),
	on(TargetSegmentAction.SetSegmentAndId, (state, { payload }) => ({
		...state,
		isLoading: false,
		id: payload.id,
		segment: payload.segment
	})),
	on(TargetSegmentAction.ViewCardSegmentDetailSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		targetSegmentDetails: payload
	})),
	on(TargetSegmentAction.ViewCustomerIdSegmentDetailSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		targetSegmentDetails: payload
	})),
	on(TargetSegmentAction.ViewAccountSegmentDetailSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		targetSegmentDetails: payload
	})),
	on(TargetSegmentAction.ViewTargetSegmentDetailEdit, (state) => ({
		...state,
		isLoading: true,
	})),
	on(TargetSegmentAction.ViewTargetSegmentDetailEditSuccess, (state, { payload }) => {
		const targetSegmentDetailFormView = {
			code: payload.code,
			name: payload.name,
			resourceCode: payload.resourceCode,
			description: payload.description
		}
		return ({
			...state,
			isLoading: false,
			targetSegmentDetails: payload,
			targetSegmentDetailForm: TargetSegmentState.validateTargetSegmentDetailForm(state)(setValue(state.targetSegmentDetailForm, {
				code: payload.code,
				name: payload.name,
				resourceCode: payload.resourceCode,
				description: payload.description})),
			targetSegmentDetailFormView,
			targetSegmentListView: payload.document.map(e => e)
		})
	}),
	on(TargetSegmentAction.GetResourceList, (state) => ({
		...state,
		isLoading: true,
	})),
	on(TargetSegmentAction.GetResourceListSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		resourceList: (payload || [])
	})),
	on(TargetSegmentAction.Delete, (state) => ({
		...state,
		isLoading: true,
	})),
	on(TargetSegmentAction.DeleteSuccess, (state) => ({
		...state,
		isLoading: false,
	})),
	on(TargetSegmentAction.UpdateSuccess, (state) => ({
		...state,
		isLoading: false,
	})),
	on(TargetSegmentAction.CreateSuccess, (state) => ({
		...state,
		isLoading: false,
	})),
	on(TargetSegmentAction.GoAccountCreate, (state) => ({
		...state,
		targetSegmentDetails: TargetSegmentState.initialTargetSegmentDetail,
		targetSegmentDetailForm: TargetSegmentState.initialTargetSegmentDetailForm
	})),
	on(TargetSegmentAction.GoCustomerCreate, (state) => ({
		...state,
		targetSegmentDetails: TargetSegmentState.initialTargetSegmentDetail,
		targetSegmentDetailForm: TargetSegmentState.initialTargetSegmentDetailForm
	})),
	on(TargetSegmentAction.GoCardCreate, (state) => ({
		...state,
		targetSegmentDetails: TargetSegmentState.initialTargetSegmentDetail,
		targetSegmentDetailForm: TargetSegmentState.initialTargetSegmentDetailForm
	})),
	on(TargetSegmentAction.RestError, (state) => ({
		...state,
		isLoading: false
	})),
	on(TargetSegmentAction.Create, (state) => ({
		...state,
		isLoading: true,
	})),
	on(TargetSegmentAction.Update, (state) => ({
		...state,
		isLoading: true,
	})),
)


export function Reducer(state: TargetSegmentState.State = TargetSegmentState.initialState, action: Action) {
	const targetSegmentDetailForm = TargetSegmentState.validateTargetSegmentDetailForm(state)(formGroupReducer(state.targetSegmentDetailForm, action))
	if (targetSegmentDetailForm !== state.targetSegmentDetailForm) {
		state = { ...state, targetSegmentDetailForm }
	}
	return reducer(state, action)
}
