import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Action, Store } from '@ngrx/store'
import { Observable, of } from 'rxjs'
import { catchError, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators'
import { Response } from 'src/app/models/common/http'
import * as AppStore from 'src/app/store/'
import * as CommonAction from '../../common/common/common.actions'
import * as DashboardAction from './dashboard-overview.actions'
import * as ActionBarWithSelectionAction from '../../common/action-bar-with-selection/action-bar-with-selection.actions'
import * as ActionBarAction from '../../common/action-bar/action-bar.actions'
import { DashboardService } from 'src/app/services/dashboard/dashboard.service'
import { DashboardOptionListViewResponse, CampaignOptionListViewResponse, DashboardRangeOptionListViewResponse } from 'src/app/models/dashboard/dashboard-overview'
import { KeyValue } from '@angular/common'
import { Util } from 'src/app/models/util/util'

@Injectable()
export class Effects {

	constructor(
		private action$: Actions,
		private router: Router,
		private dashboardService: DashboardService,
		private store: Store<AppStore.State>
	) { }

	InitialState$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(DashboardAction.InitialState),
			tap(() => {
				this.router.navigate(['dashboard/overview'])
			})
		), { dispatch: false }
	)

	RestError$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(DashboardAction.RestError),
			map(({ message, manualThrow }) => {
				return CommonAction.RestError({ message, manualThrow })
			})
		)
	)

	ViewRewardPoolOverview$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(DashboardAction.ViewRewardPoolOverview),
			withLatestFrom(this.store.select(state => state.dashboard)),
			mergeMap(([params, dashboard]) => {
				const dashboardRequestForm = dashboard.dashboardRequestForm.controls

				return this.dashboardService.getRewardPoolOverview({
					startMonth: dashboardRequestForm.rewardPoolStartDate.value.length === 10 ? dashboardRequestForm.rewardPoolStartDate.value : Util.fromISOStringToDynamicDateStr(dashboardRequestForm.customRewardPoolStartDate.value, 'YYYY-MM-DD'),
					endMonth: dashboardRequestForm.rewardPoolEndDate.value.length === 10 ? dashboardRequestForm.rewardPoolEndDate.value : Util.fromISOStringToDynamicDateStr(dashboardRequestForm.rewardPoolEndDate.value, 'YYYY-MM-DD'),
					resource: dashboard.resource
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload = response.payload
					if (success) {
						return DashboardAction.ViewRewardPoolOverviewSuccess({ payload })
					} else {
						return DashboardAction.RestError(response as any)
					}
				}), catchError(this.catcher))
			})
		)
	)

	ViewRewardPoolGroupOverview$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(DashboardAction.ViewRewardPoolGroupOverview),
			withLatestFrom(this.store.select(state => state.dashboard)),
			mergeMap(([params, dashboard]) => {
				const dashboardRequestForm = dashboard.dashboardRequestForm.controls

				return this.dashboardService.getRewardPoolGroupOverview({
					startMonth: dashboardRequestForm.startDate.value.length === 10 ? dashboardRequestForm.startDate.value : Util.fromISOStringToDynamicDateStr(dashboardRequestForm.customStartDate.value, 'YYYY-MM-DD'),
					endMonth: dashboardRequestForm.endDate.value.length === 10 ? dashboardRequestForm.endDate.value : Util.fromISOStringToDynamicDateStr(dashboardRequestForm.endDate.value, 'YYYY-MM-DD'),
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload = response.payload
					if (success) {
						return DashboardAction.ViewRewardPoolGroupOverviewSuccess({ payload })
					} else {
						return DashboardAction.RestError(response as any)
					}
				}), catchError(this.catcher))
			})
		)
	)

	ViewCampaignOverview$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(DashboardAction.ViewCampaignOverview),
			withLatestFrom(this.store.select(state => state.dashboard)),
			mergeMap(([params, dashboard]) => {
				const dashboardRequestForm = dashboard.dashboardRequestForm.controls

				return this.dashboardService.getCampaignOverview({
					campaignId: Number(dashboardRequestForm.campaignId.value),
					startMonth: dashboardRequestForm.campaignStartDate.value.length === 10 ? dashboardRequestForm.campaignStartDate.value : Util.fromISOStringToDynamicDateStr(dashboardRequestForm.customCampaignStartDate.value, 'YYYY-MM-DD'),
					endMonth: dashboardRequestForm.campaignEndDate.value.length === 10 ? dashboardRequestForm.campaignEndDate.value : Util.fromISOStringToDynamicDateStr(dashboardRequestForm.campaignEndDate.value, 'YYYY-MM-DD'),
					resource: dashboard.resource
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload = response.payload
					if (success) {
						return DashboardAction.ViewCampaignOverviewSuccess({ payload })
					} else {
						return DashboardAction.RestError(response as any)
					}
				}), catchError(this.catcher)
				)
			})
		)
	)

	ViewCampaignsListOverview$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(DashboardAction.ViewCampaignsListOverview),
			withLatestFrom(this.store.select(state => state.dashboard)),
			mergeMap(([params, dashboard]) => {

				return this.dashboardService.getCampaignListOverview({
					resource: dashboard.resource
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload = response.payload
					if (success) {
						return DashboardAction.ViewCampaignsListOverviewSuccess({ payload })
					} else {
						return DashboardAction.RestError(response as any)
					}
				}), catchError(this.catcher)
				)
			})
		)
	)

	ViewCampaignsListWithResourceOverview$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ActionBarWithSelectionAction.DashboardOverviewOptionList),
			withLatestFrom(this.store.select(state => state.dashboard)),
			mergeMap(([params, dashboard]) => {

				return this.dashboardService.getCampaignListOverview({
					resource: dashboard.resource
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload = response.payload
					if (success) {
						return DashboardAction.ViewCampaignsListOverviewSuccess({ payload })
					} else {
						return DashboardAction.RestError(response as any)
					}
				}), catchError(this.catcher)
				)
			})
		)
	)

	ViewRewardPoolCampaignsListWithResourceOverview$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ActionBarWithSelectionAction.DashboardRewardPoolOverviewOptionList),
			withLatestFrom(this.store.select(state => state.dashboard)),
			mergeMap(([params, dashboard]) => {

				return this.dashboardService.getCampaignListOverview({
					resource: dashboard.resource
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload = response.payload
					if (success) {
						return DashboardAction.ViewCampaignsListOverviewSuccess({ payload })
					} else {
						return DashboardAction.RestError(response as any)
					}
				}), catchError(this.catcher)
				)
			})
		)
	)

	InitialGetRewardPoolOverview$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(DashboardAction.DashboardRangeOptionListSuccess),
			withLatestFrom(this.store.select(state => state.dashboard)),
			mergeMap(([, dashboard]) => {
				const dashboardRequestForm = dashboard.dashboardRequestForm.controls

				return this.dashboardService.getRewardPoolOverview({
					startMonth: dashboardRequestForm.rewardPoolStartDate.value.length === 10 ? dashboardRequestForm.rewardPoolStartDate.value : Util.fromISOStringToDynamicDateStr(dashboardRequestForm.customRewardPoolStartDate.value, 'YYYY-MM-DD'),
					endMonth: dashboardRequestForm.rewardPoolEndDate.value.length === 10 ? dashboardRequestForm.rewardPoolEndDate.value : Util.fromISOStringToDynamicDateStr(dashboardRequestForm.rewardPoolEndDate.value, 'YYYY-MM-DD'),
					resource: dashboard.resource
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload = response.payload
					if (success) {
						return DashboardAction.ViewRewardPoolOverviewSuccess({ payload })
					} else {
						return DashboardAction.RestError(response as any)
					}
				}), catchError(this.catcher))
			})
		)
	)

	InitialGetCampaignOptionListOverview$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(DashboardAction.DashboardRangeOptionListSuccess),
			withLatestFrom(this.store.select(state => state.dashboard)),
			mergeMap(([, dashboard]) => {
				return this.dashboardService.getCampaignOptionList({
					resource: dashboard.resource
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: CampaignOptionListViewResponse = response.payload
					if (success) {
						return DashboardAction.CampaignOptionListSuccess({ payload })
					} else { return DashboardAction.RestError(response as any) }
				}), catchError(this.catcher))
			})
		)
	)

	InitialGetRewardPoolGroupOverview$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(DashboardAction.DashboardRangeOptionListSuccess),
			withLatestFrom(this.store.select(state => state.dashboard)),
			mergeMap(([, dashboard]) => {
				const dashboardRequestForm = dashboard.dashboardRequestForm.controls

				return this.dashboardService.getRewardPoolGroupOverview({
					startMonth: dashboardRequestForm.startDate.value.length === 10 ? dashboardRequestForm.startDate.value : Util.fromISOStringToDynamicDateStr(dashboardRequestForm.customStartDate.value, 'YYYY-MM-DD'),
					endMonth: dashboardRequestForm.endDate.value.length === 10 ? dashboardRequestForm.endDate.value : Util.fromISOStringToDynamicDateStr(dashboardRequestForm.endDate.value, 'YYYY-MM-DD'),
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload = response.payload
					if (success) {
						return DashboardAction.ViewRewardPoolGroupOverviewSuccess({ payload })
					} else {
						return DashboardAction.RestError(response as any)
					}
				}), catchError(this.catcher))
			})
		)
	)

	RewardPoolRangeOptionListSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(DashboardAction.RewardPoolRangeOptionListSuccess),
			withLatestFrom(this.store.select(state => state.dashboard)),
			mergeMap(([, dashboard]) => {
				const dashboardRequestForm = dashboard.dashboardRequestForm.controls

				return this.dashboardService.getRewardPoolOverview({
					startMonth: dashboardRequestForm.rewardPoolStartDate.value.length === 10 ? dashboardRequestForm.rewardPoolStartDate.value : Util.fromISOStringToDynamicDateStr(dashboardRequestForm.customRewardPoolStartDate.value, 'YYYY-MM-DD'),
					endMonth: dashboardRequestForm.rewardPoolEndDate.value.length === 10 ? dashboardRequestForm.rewardPoolEndDate.value : Util.fromISOStringToDynamicDateStr(dashboardRequestForm.rewardPoolEndDate.value, 'YYYY-MM-DD'),
					resource: dashboard.resource
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload = response.payload
					if (success) {
						return DashboardAction.ViewRewardPoolOverviewSuccess({ payload })
					} else {
						return DashboardAction.RestError(response as any)
					}
				}), catchError(this.catcher))
			})
		)
	)

	GetCampaignOverviewWithRewardPool$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(DashboardAction.RewardPoolRangeOptionListSuccess),
			withLatestFrom(this.store.select(state => state.dashboard)),
			mergeMap(([, dashboard]) => {
				return this.dashboardService.getCampaignOptionList({
					resource: dashboard.resource
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: CampaignOptionListViewResponse = response.payload
					if (success) {
						return DashboardAction.CampaignOptionListSuccess({ payload })
					} else { return DashboardAction.RestError(response as any) }
				}), catchError(this.catcher))
			})
		)
	)

	RewardPoolOnlyRangeOptionListSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(DashboardAction.RewardPoolOnlyRangeOptionListSuccess),
			withLatestFrom(this.store.select(state => state.dashboard)),
			mergeMap(([, dashboard]) => {
				const dashboardRequestForm = dashboard.dashboardRequestForm.controls

				return this.dashboardService.getRewardPoolOverview({
					startMonth: dashboardRequestForm.rewardPoolStartDate.value.length === 10 ? dashboardRequestForm.rewardPoolStartDate.value : Util.fromISOStringToDynamicDateStr(dashboardRequestForm.customRewardPoolStartDate.value, 'YYYY-MM-DD'),
					endMonth: dashboardRequestForm.rewardPoolEndDate.value.length === 10 ? dashboardRequestForm.rewardPoolEndDate.value : Util.fromISOStringToDynamicDateStr(dashboardRequestForm.rewardPoolEndDate.value, 'YYYY-MM-DD'),
					resource: dashboard.resource
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload = response.payload
					if (success) {
						return DashboardAction.ViewRewardPoolOverviewSuccess({ payload })
					} else {
						return DashboardAction.RestError(response as any)
					}
				}), catchError(this.catcher))
			})
		)
	)

	RewardPoolAndGroupRangeOptionListSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(DashboardAction.RewardPoolAndGroupRangeOptionListSuccess),
			withLatestFrom(this.store.select(state => state.dashboard)),
			mergeMap(([, dashboard]) => {
				const dashboardRequestForm = dashboard.dashboardRequestForm.controls

				return this.dashboardService.getRewardPoolOverview({
					startMonth: dashboardRequestForm.rewardPoolStartDate.value.length === 10 ? dashboardRequestForm.rewardPoolStartDate.value : Util.fromISOStringToDynamicDateStr(dashboardRequestForm.customRewardPoolStartDate.value, 'YYYY-MM-DD'),
					endMonth: dashboardRequestForm.rewardPoolEndDate.value.length === 10 ? dashboardRequestForm.rewardPoolEndDate.value : Util.fromISOStringToDynamicDateStr(dashboardRequestForm.rewardPoolEndDate.value, 'YYYY-MM-DD'),
					resource: dashboard.resource
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload = response.payload
					if (success) {
						return DashboardAction.ViewRewardPoolOverviewSuccess({ payload })
					} else {
						return DashboardAction.RestError(response as any)
					}
				}), catchError(this.catcher))
			})
		)
	)

	RewardPoolGroupRangeOptionListSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(DashboardAction.RewardPoolAndGroupRangeOptionListSuccess),
			withLatestFrom(this.store.select(state => state.dashboard)),
			mergeMap(([, dashboard]) => {
				const dashboardRequestForm = dashboard.dashboardRequestForm.controls

				return this.dashboardService.getRewardPoolGroupOverview({
					startMonth: dashboardRequestForm.startDate.value.length === 10 ? dashboardRequestForm.startDate.value : Util.fromISOStringToDynamicDateStr(dashboardRequestForm.customStartDate.value, 'YYYY-MM-DD'),
					endMonth: dashboardRequestForm.endDate.value.length === 10 ? dashboardRequestForm.endDate.value : Util.fromISOStringToDynamicDateStr(dashboardRequestForm.endDate.value, 'YYYY-MM-DD')
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload = response.payload
					if (success) {
						return DashboardAction.ViewRewardPoolGroupOverviewSuccess({ payload })
					} else {
						return DashboardAction.RestError(response as any)
					}
				}), catchError(this.catcher))
			})
		)
	)

	InitialGetRewardPoolGroupOverviewOnly$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(DashboardAction.RewardPoolGroupRangeOptionListSuccess),
			withLatestFrom(this.store.select(state => state.dashboard)),
			mergeMap(([, dashboard]) => {
				const dashboardRequestForm = dashboard.dashboardRequestForm.controls

				return this.dashboardService.getRewardPoolGroupOverview({
					startMonth: dashboardRequestForm.startDate.value.length === 10 ? dashboardRequestForm.startDate.value : Util.fromISOStringToDynamicDateStr(dashboardRequestForm.customStartDate.value, 'YYYY-MM-DD'),
					endMonth: dashboardRequestForm.endDate.value.length === 10 ? dashboardRequestForm.endDate.value : Util.fromISOStringToDynamicDateStr(dashboardRequestForm.endDate.value, 'YYYY-MM-DD')
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload = response.payload
					if (success) {
						return DashboardAction.ViewRewardPoolGroupOverviewSuccess({ payload })
					} else {
						return DashboardAction.RestError(response as any)
					}
				}), catchError(this.catcher))
			})
		)
	)

	CampaignRangeOptionListSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(DashboardAction.CampaignRangeOptionListSuccess),
			withLatestFrom(this.store.select(state => state.dashboard)),
			mergeMap(([, dashboard]) => {
				const dashboardRequestForm = dashboard.dashboardRequestForm.controls

				return this.dashboardService.getCampaignOverview({
					campaignId: Number(dashboardRequestForm.campaignId.value),
					startMonth: dashboardRequestForm.campaignStartDate.value.length === 10 ? dashboardRequestForm.campaignStartDate.value : Util.fromISOStringToDynamicDateStr(dashboardRequestForm.customCampaignStartDate.value, 'YYYY-MM-DD'),
					endMonth: dashboardRequestForm.campaignStartDate.value.length === 10 ?  dashboardRequestForm.campaignEndDate.value : Util.fromISOStringToDynamicDateStr(dashboardRequestForm.campaignEndDate.value, 'YYYY-MM-DD'),
					resource: dashboard.resource
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload = response.payload
					if (success) {
						return DashboardAction.ViewCampaignOverviewSuccess({ payload })
					} else {
						return DashboardAction.RestError(response as any)
					}
				}), catchError(this.catcher)
				)
			})
		)
	)

	InitialCampaignOptionList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(DashboardAction.InitialCampaignRangeOptionListSuccess),
			withLatestFrom(this.store.select(state => state.dashboard)),
			mergeMap(([, dashboard]) => {
				return this.dashboardService.getCampaignOptionList({
					resource: dashboard.resource
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: CampaignOptionListViewResponse = response.payload
					if (success) {
						return DashboardAction.CampaignOptionListSuccess({ payload })
					} else { return DashboardAction.RestError(response as any) }
				}), catchError(this.catcher))
			})
		)
	)

	CampaignOptionList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(DashboardAction.CampaignOptionList),
			withLatestFrom(this.store.select(state => state.dashboard)),
			mergeMap(([, dashboard]) => {
				return this.dashboardService.getCampaignOptionList({
					resource: dashboard.resource
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: CampaignOptionListViewResponse = response.payload
					if (success) {
						return DashboardAction.CampaignOptionListSuccess({ payload })
					} else { return DashboardAction.RestError(response as any) }
				}), catchError(this.catcher))
			})
		)
	)

	InitialCampaignOnlyOptionList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(DashboardAction.CampaignOptionListSuccess),
			withLatestFrom(this.store.select(state => state.dashboard)),
			mergeMap(([params, dashboard]) => {
				const dashboardRequestForm = dashboard.dashboardRequestForm.controls

				return this.dashboardService.getCampaignOverview({
					campaignId: Number(dashboardRequestForm.campaignId.value),
					startMonth: dashboardRequestForm.campaignStartDate.value.length === 10 ? dashboardRequestForm.campaignStartDate.value : Util.fromISOStringToDynamicDateStr(dashboardRequestForm.customCampaignStartDate.value, 'YYYY-MM-DD'),
					endMonth: dashboardRequestForm.campaignStartDate.value.length === 10 ? dashboardRequestForm.campaignEndDate.value : Util.fromISOStringToDynamicDateStr(dashboardRequestForm.campaignEndDate.value, 'YYYY-MM-DD'),
					resource: dashboard.resource
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload = response.payload
					if (success) {
						return DashboardAction.ViewCampaignOverviewSuccess({ payload })
					} else {
						return DashboardAction.RestError(response as any)
					}
				}), catchError(this.catcher)
				)
			})
		)
	)

	ActionBarDashboardOverviewChannelOptionList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ActionBarAction.DashboardOverview),
			withLatestFrom(this.store.select(state => state.actionBar)),
			mergeMap(([, dashboard]) => {
				return this.dashboardService.getDashboardChannelOptionList().pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: KeyValue<string, string>[] = response.payload
					if (success) {
						return ActionBarWithSelectionAction.DashboardOverviewOptionList({ payload })
					} else { return DashboardAction.RestError(response as any) }
				}), catchError(this.catcher))
			})
		)
	)

	ActionBarRewardPoolOverviewChannelOptionList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ActionBarAction.DashboardRewardPoolOverview),
			withLatestFrom(this.store.select(state => state.actionBar)),
			mergeMap(([, dashboard]) => {
				return this.dashboardService.getDashboardChannelOptionList().pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: KeyValue<string, string>[] = response.payload
					if (success) {
						return ActionBarWithSelectionAction.DashboardRewardPoolOverviewOptionList({ payload })
					} else { return DashboardAction.RestError(response as any) }
				}), catchError(this.catcher))
			})
		)
	)

	catcher = (error) => {
		return (of(DashboardAction.RestError({ message: error.message, manualThrow: error.manualThrow })))
	}
}
