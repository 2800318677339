<div>
    <mat-dialog-content>
        <app-spinner [isLoading]="isLoading"></app-spinner>

        <div class="layout-div">
            <div fxFlex>
                <form novalidate [ngrxFormState]="formState$ | async" fxLayout="column" fxFlex="100" fxFlex.lt-md="0">

                    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="30px" fxLayoutGap.lt-md="0px">
                        <span class="mat-h3">{{ 'CUSTOMER_MANAGEMENT.PARTNER_REDEMPTION' | translate }}</span>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="0px" fxLayoutGap.lt-md="0px">
                        <mat-form-field fxFlex="100">
                            <mat-label>{{ 'CUSTOMER_MANAGEMENT.PARTNER_NAME' | translate }}</mat-label>
                            <mat-select [ngrxFormControlState]="(formState$ | async).controls.name" required
                                (selectionChange)="selected($event)">
                                <mat-option>{{ 'COMMON.PLEASE_SELECT' | translate}}</mat-option>
                                <mat-option *ngFor="let i of partnerRedemptionKeyValueList" [value]="i.key">
                                    {{i.value}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="(formState$ | async).errors._name">
                                <app-error-validation [errors]="(formState$ | async).errors._name">
                                </app-error-validation>
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div *ngIf="(formState$ | async).controls.name.value === undefined || (formState$ | async).controls.name.value === '' "
                        fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="2px" fxLayoutGap.lt-md="0px">
                        <mat-form-field fxFlex="50">
                            <mat-label>{{ 'CUSTOMER_MANAGEMENT.REDEEM_POINTS' | translate }}</mat-label>
                            <input matInput value="{{point}}" readonly>
                            <span matSuffix>{{ 'CUSTOMER_MANAGEMENT.SUFFIX.POINTS' | translate }}</span>
                            <mat-error *ngIf="(formState$ | async).errors._point">
                                <app-error-validation [errors]="(formState$ | async).errors._point">
                                </app-error-validation>
                            </mat-error>
                        </mat-form-field>
                        <label class="equal">
                            =
                        </label>
                        <mat-form-field fxFlex="50">
                            <input matInput value="{{value}}" readonly>
                            <span matSuffix>{{ 'CUSTOMER_MANAGEMENT.SUFFIX.PARTNER_POINTS' | translate }}</span>
                            <mat-error *ngIf="(formState$ | async).errors._value">
                                <app-error-validation [errors]="(formState$ | async).errors._value">
                                </app-error-validation>
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div *ngIf="(formState$ | async).controls.name.value !== undefined && (formState$ | async).controls.name.value !== ''"
                        fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="2px" fxLayoutGap.lt-md="0px">
                        <mat-form-field fxFlex="50">
                            <mat-label>{{ 'CUSTOMER_MANAGEMENT.REDEEM_POINTS' | translate }}</mat-label>
							<input appTwoDecimalPlaces matInput
								   [ngrxFormControlState]="(formState$ | async).controls.points"
								   (keyup)="calculation()"
								   (paste)="keyboardValidation(($event.clipboardData).getData('text'))"
								   (keypress)="keyboardValidation($event.key)"
								   required maxlength="10">
                            <mat-error *ngIf="(formState$ | async).errors._points">
                                <app-error-validation [min]=point [errors]="(formState$ | async).errors._points">
                                </app-error-validation>
                            </mat-error>
                            <span matSuffix>{{ 'CUSTOMER_MANAGEMENT.SUFFIX.POINTS' | translate }}</span>
                        </mat-form-field>
                        <label class="equal">
                            =
                        </label>
                        <mat-form-field fxFlex="50" style="pointer-events:none;">
                            <input appTwoDecimalPlaces matInput [ngrxFormControlState]="(formState$ | async).controls.values" readonly>
                            <mat-error *ngIf="(formState$ | async).errors._values">
                                <app-error-validation [errors]="(formState$ | async).errors._values">
                                </app-error-validation>
                            </mat-error>
                            <span matSuffix>{{ 'CUSTOMER_MANAGEMENT.SUFFIX.PARTNER_POINTS' | translate }}</span>
                        </mat-form-field>
                    </div>

                    <div fxLayout="column" fxLayout.lt-md="column" fxLayoutGap="5px" fxLayoutGap.lt-md="0px">
                        <mat-form-field>
                            <mat-label>{{ 'CUSTOMER_MANAGEMENT.PARTNER_ID' | translate }}</mat-label>
							<input matInput [ngrxFormControlState]="(formState$ | async).controls.airmileId"
								   required maxlength="16">
                            <mat-error *ngIf="(formState$ | async).errors._airmileId">
                                <app-error-validation [errors]="(formState$ | async).errors._airmileId">
                                </app-error-validation>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </form>
            </div>
        </div>
    </mat-dialog-content>

    <div class="action-button-div" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="end">
        <button class="mat-raised-button" (click)="cancel()">{{ 'ACTION.CANCEL' | translate }}</button>
        <button type="button" class="mat-raised-button mat-primary w-80px"
            [disabled]="(formState$ | async).isInvalid" (click)="create()"
            color="primary">{{ 'ACTION.NEXT' | translate }}
        </button>
    </div>
</div>
