import { ProductPartnerListResponse } from 'src/app/models/catalogue-management/product-partner/product-partner-list'
import { createFormGroupState, FormGroupState, updateGroup, validate } from 'ngrx-forms'
import { maxLength, required } from 'ngrx-forms/validation'
import { FieldValidator } from 'src/app/models/util/field.validator'
import { Option } from 'src/app/models/option/option'
import { KeyValue } from '@angular/common'
import { RedeemPartner } from 'src/app/models/redemption-settings/redeem-partner/redeem-partner'
import { RewardPoolAndGroup } from 'src/app/models/catalogue-management/product-catalogue/product-catalogue-reward-list'

export interface State {
	action?: string
	isLoading: boolean
	id?: number
	redeemPartnerId?: string
	redeemPartner?: string
	productPartnerListResponse: ProductPartnerListResponse
	productPartnerDetailForm: FormGroupState<ProductPartnerDetailForm>
	statusList: Option[]
	redemptionTypeList: Option[]
	rewardPoolAndGroupList: KeyValue<string, string>[]
	redeemPartnerList: RedeemPartner[]
	productPartnerDetailFormView: ProductPartnerDetailForm
	rewardList: RewardPoolAndGroup[]
	productPartnerDuplicateForm: FormGroupState<ProductPartnerDuplicateForm>
	isDuplicateLoading: boolean
	isDuplicateDialog: boolean
}

export interface ProductPartnerDetailForm {
	code: string
	name: string
	description: string
	status: string
	rewardPoolAndGroup: string
	rewardPoolId: string
	rewardPoolType: string
	redeemPartnerId: string
	redeemPartner: string
	startDate: string
	endDate: string
	point: string
	image: string
	imageFileName: string
	createdBy: string
	createdDate: string
	modifiedBy: string
	modifiedDate: string
}

export const initialProductPartnerDetailFormValue: ProductPartnerDetailForm = {
	code: '',
	name: '',
	description: '',
	status: '',
	rewardPoolAndGroup: '',
	rewardPoolId: '',
	rewardPoolType: '',
	redeemPartnerId: '',
	redeemPartner: '',
	startDate: '',
	endDate: '',
	point: '',
	image: '',
	imageFileName: '',
	createdBy: '',
	createdDate: '',
	modifiedBy: '',
	modifiedDate: ''
}

export const initialProductPartnerDetailForm = createFormGroupState('productPartnerDetailForm', initialProductPartnerDetailFormValue)

export const validateProductPartnerDetailForm = (state: State) => updateGroup<ProductPartnerDetailForm>({
	code: validate([required, maxLength(10), FieldValidator.alphaNumericWithoutSpaces()]),
	name: validate([required, maxLength(40), FieldValidator.checkValidCharacters()]),
	description: validate([maxLength(100), FieldValidator.checkValidCharacters()]),
	status: validate([required]),
	redeemPartner: validate([required]),
	rewardPoolAndGroup: validate([required]),
	startDate: validate([required]),
	endDate: validate([required]),
	point: validate([required, maxLength(10), FieldValidator.numberWithoutDecimal()]),
	imageFileName: validate([])
})

export interface ProductPartnerDuplicateForm {
	code: string
	name: string
}

export const initialProductPartnerDuplicateFormValue: ProductPartnerDuplicateForm = {
	code: '',
	name: ''
}

export const initialProductPartnerDuplicateForm = createFormGroupState('productPartnerDuplicateForm', initialProductPartnerDuplicateFormValue)

export const validateProductPartnerDuplicateForm = updateGroup<ProductPartnerDuplicateForm>({
	code: validate([required, maxLength(10), FieldValidator.alphaNumericWithoutSpaces()]),
	name: validate([required, maxLength(40), FieldValidator.checkValidCharacters()]),
})

export const initialState: State = {
	isLoading: false,
	productPartnerListResponse: {
		productPartner: []
	},
	productPartnerDetailForm: initialProductPartnerDetailForm,
	statusList: [],
	redemptionTypeList: [],
	rewardPoolAndGroupList: [],
	redeemPartnerList: [],
	rewardList: [],
	productPartnerDetailFormView: initialProductPartnerDetailFormValue,
	productPartnerDuplicateForm: initialProductPartnerDuplicateForm,
	isDuplicateLoading: false,
	isDuplicateDialog: false
}
