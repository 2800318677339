import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { GroupCreateRequest } from 'src/app/models/access-management/group/group-create'
import { GroupDeleteRequest } from 'src/app/models/access-management/group/group-delete'
import { GroupEditRequest } from 'src/app/models/access-management/group/group-edit'
import { GroupPolicyAssignmentResponse } from 'src/app/models/access-management/group/group-policy-assignment'
import { GroupViewRequest } from 'src/app/models/access-management/group/group-view'
import { UserListRequest } from 'src/app/models/access-management/user/user-list'
import { UserRemoveGroupRequest } from 'src/app/models/access-management/user/user-remove-group'
import { UserViewRequest } from 'src/app/models/access-management/user/user-view'
import { Request, Response } from 'src/app/models/common/http'
import { RestService } from '../common/rest-client/rest.service'
import { GroupDuplicateRequest } from 'src/app/models/access-management/group/group-duplicate'

@Injectable({
	providedIn: 'root'
})
export class AccessManagementService {

	constructor(
		private rest: RestService,
	) { }

	// User
	getUserList(params: UserListRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/user/search' + this.rest.urlQueryString(params),
			mockURL: '/access-management/user/getUserList.json'
		}

		return this.rest.get(request)
	}

	viewUser(params: UserViewRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/user/view/' + params.userId,
			mockURL: '/access-management/user/viewUser.json'
		}

		return this.rest.get(request)
	}

	assignUserGroup(params: UserRemoveGroupRequest): Observable<Response> {
		const request: Request = {
			body: { groupId: params.groupId },
			liveURL: '/user/assignGroup/' + params.userId,
		}

		return this.rest.post(request)
	}

	removeUserGroup(params: UserRemoveGroupRequest): Observable<Response> {
		const request: Request = {
			body: { groupId: params.groupId },
			liveURL: '/user/removeGroup/' + params.userId,
		}

		return this.rest.post(request)
	}

	// Group
	getGroupList(params = { policyCheckIsEmpty: false }): Observable<Response> {
		const request: Request = {
			liveURL: '/group/list' + this.rest.urlQueryString(params),
			mockURL: '/access-management/group/getGroupList.json'
		}

		return this.rest.get(request)
	}

	createGroup(params: GroupCreateRequest): Observable<Response> {
		const request: Request = {
			body: params,
			liveURL: '/group/create',
			mockURL: '/access-management/group/createGroup.json'
		}

		return this.rest.post(request)
	}

	viewGroup(params: GroupViewRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/group/view/' + params.groupId,
			mockURL: '/access-management/group/viewGroup.json'
		}

		return this.rest.get(request)
	}

	editGroup(params: GroupEditRequest): Observable<Response> {
		const request: Request = {
			body: params,
			liveURL: '/group/edit/' + params.groupId,
			mockURL: '/access-management/group/updateGroup.json'
		}

		return this.rest.post(request)
	}

	deleteGroup(params: GroupDeleteRequest): Observable<Response> {
		const request: Request = {
			body: params,
			liveURL: '/group/delete/' + params.groupId,
			mockURL: '/access-management/group/deleteGroup.json'
		}

		return this.rest.post(request)
	}

	assignGroupPolicy(params: GroupPolicyAssignmentResponse): Observable<Response> {
		const request: Request = {
			body: { policyId: params.policyId },
			liveURL: '/group/assignPolicy/' + params.groupId,
		}

		return this.rest.post(request)
	}

	removeGroupPolicy(params: GroupPolicyAssignmentResponse): Observable<Response> {
		const request: Request = {
			body: { policyId: params.policyId },
			liveURL: '/group/removePolicy/' + params.groupId,
		}

		return this.rest.post(request)
	}

	// Policy
	getPolicyList(): Observable<Response> {
		const request: Request = {
			liveURL: '/policy/list',
			mockURL: '/access-management/policy/getPolicyList.json'
		}

		return this.rest.get(request)
	}

	duplicateGroup(groupId: number, params: GroupDuplicateRequest): Observable<Response> {
		const request: Request = {
			body: params,
			liveURL: '/group/duplicate/' + groupId,
		}

		return this.rest.post(request)
	}
}
