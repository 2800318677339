import { KeyValue } from '@angular/common'
import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef, MatSelectChange } from '@angular/material'
import { Store } from '@ngrx/store'
import { FormGroupState } from 'ngrx-forms'
import { Observable, ReplaySubject, Subscription } from 'rxjs'
import * as AppStore from 'src/app/store'
import { RedeemPartnerConversionForm, RedeemPartnerRewardPool } from 'src/app/store/redemption-settings/redeem-partner'
import * as RedeemPartnerAction from 'src/app/store/redemption-settings/redeem-partner/redeem-partner.actions'
import * as RedeemPartnerSelectors from 'src/app/store/redemption-settings/redeem-partner/redeem-partner.selectors'
import { FormControl } from '@angular/forms'
import { Util } from 'src/app/models/util/util'
import { FilterPredicate } from 'src/app/models/util/filter-predicate'

@Component({
	selector: 'app-point-conversion',
	templateUrl: './point-conversion.component.html',
	styleUrls: ['./point-conversion.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PointConversionComponent implements OnInit, OnDestroy {

	isLoading = false
	action: string
	formState$: Observable<FormGroupState<RedeemPartnerConversionForm>>
	glOptionList$: Observable<KeyValue<number, string>[]>

	rewardList: KeyValue<string, string>[] = []
	roundingList: KeyValue<string, string>[]
	numberDecimalList: KeyValue<string, string>[]
	subs: Subscription
	pointConversionId: string
	redeemPartnerRewardPools$: Observable<RedeemPartnerRewardPool[]>
	isRewardPool: boolean
	glRedemptionList: KeyValue<number, string>[] = []
	selectedValue: any = ''
	redeemPartnerConversionFormView: RedeemPartnerConversionForm
	rewardPoolType: string

	public filteredRewardPool: ReplaySubject<KeyValue<string, string>[]> = new ReplaySubject<KeyValue<string, string>[]>(1)
	public filteredGlRedemption: ReplaySubject<KeyValue<number, string>[]> = new ReplaySubject<KeyValue<number, string>[]>(1)

	public dropDownCtrlRewardPool: FormControl = new FormControl(this.selectedValue)
	public dropDownCtrlGlRedemption: FormControl = new FormControl(this.selectedValue)

	constructor(
		private store: Store<AppStore.State>,
		private dialogRef: MatDialogRef<PointConversionComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		this.subs = this.store.select(RedeemPartnerSelectors.getRedeemPartner).subscribe(({ isLoading, action, redeemPartnerDetail, rewardList, redeemPartnerDetailList, roundingList, numberDecimalList, redeemPartnerConversionForm, glRedemptions, redeemPartnerConversionFormView }) => {
			this.isLoading = isLoading
			this.action = action

			this.roundingList = roundingList
			this.numberDecimalList = numberDecimalList
			this.glRedemptionList = glRedemptions
			this.redeemPartnerConversionFormView = redeemPartnerConversionFormView


			if (this.glRedemptionList) {
				this.filteredGlRedemption.next(this.glRedemptionList.slice())
			}

			if (redeemPartnerConversionForm.controls.id.value.includes('I')) {
				this.isRewardPool = true
			} else {
				this.isRewardPool = false
			}
			if (action === 'ADD') {
				this.rewardList = rewardList.filter(x => {
					const redeemPartnerDetail = redeemPartnerDetailList.find(i => `${i.type}_${i.id}` === x.key)
					if (!redeemPartnerDetail) {
						return true
					}
				})
			} else if (action === 'UPDATE' && redeemPartnerDetail) {
				this.rewardList = rewardList.filter(x => {
					const id = `${redeemPartnerDetail.type}_${redeemPartnerDetail.id}`
					if (id === x.key) {
						this.pointConversionId = id
						return true
					}

					const redeemPartner = redeemPartnerDetailList.find(i => `${i.type}_${i.id}` === x.key)
					if (!redeemPartner) {
						return true
					}
				})
			} else {
				this.rewardList = rewardList
			}
			this.filteredRewardPool.next(this.rewardList.slice())
		})

		this.formState$ = this.store.select(({ redeemPartner }) => redeemPartner.redeemPartnerConversionForm)
		this.glOptionList$ = this.store.select(({ redeemPartner }) => redeemPartner.glRedemptions)
		this.redeemPartnerRewardPools$ = this.store.select(RedeemPartnerSelectors.getRedeemPartnerRewardPools)
	}

	ngOnInit() {

		this.dropDownCtrlRewardPool.valueChanges
			.subscribe(value => {
				const maxChar = 255
				if (value.length > maxChar) {
					const newValue = value.slice(0, maxChar)
					this.dropDownCtrlRewardPool.reset('', { emitEvent: false })
					this.dropDownCtrlRewardPool.setValue(newValue)

				} else {
					this.searchRewardPool(value)
				}
			})

		this.dropDownCtrlGlRedemption.valueChanges
			.subscribe(value => {
				const maxChar = 255
				if (value.length > maxChar) {
					const newValue = value.slice(0, maxChar)
					this.dropDownCtrlGlRedemption.reset('', { emitEvent: false })
					this.dropDownCtrlGlRedemption.setValue(newValue)

				} else {
					this.searchGlRedemption(value)
				}
			})

	}

	ngOnDestroy() {
		this.subs.unsubscribe()
	}

	get isView(): boolean { return this.action === 'VIEW' }

	cancel() {
		this.dialogRef.close()
	}

	submit() {
		this.store.dispatch(RedeemPartnerAction.SetPointConversionId({pointConversionId: this.pointConversionId}))
		this.store.dispatch(RedeemPartnerAction.SaveConversion())
		this.store.dispatch(RedeemPartnerAction.UpdatePointsConversion())
		this.cancel()
	}

	getRewardValue(value: string): string {
		const result = this.rewardList.find(x => x.key === value)
		return result ? result.value : ''
	}

	onChangeRounding() {
		this.store.dispatch(RedeemPartnerAction.OnChangeRounding())
	}

	getRoundingValue(value: string): string {
		const result = this.roundingList.find(x => x.key === value)
		return result ? result.value : ''
	}

	getNumberDecimalValue(value: string): string {
		const result = this.numberDecimalList.find(x => x.key === value)
		return result ? result.value : ''
	}

	trackByIndex(index: number) {
		return index
	}

	onChangeRewardPool(ev: MatSelectChange) {
		if (ev && ev.value) {
			const rewardPoolType = ev.value.split('_')[0]
			if (rewardPoolType === 'I') {
				this.store.dispatch(RedeemPartnerAction.OnChangeRewardPool())
			} else {
				this.store.dispatch(RedeemPartnerAction.OnChangeRewardPoolGroup())
			}
			this.rewardPoolType = rewardPoolType
		}
	}

	getGlRedemptionValue(value: number): string {
		const result = this.glRedemptionList.find(x => x.key === value)
		return result ? result.value : ''
	}

	searchRewardPool(search: string) {
		if (!this.rewardList) {
			return
		}
		if (!search) {
			this.filteredRewardPool.next(this.rewardList.slice())
			return
		} else {
			search = search.toLowerCase()
		}
		// filter the records
		this.filteredRewardPool.next(
			this.rewardList.filter(rewardPool => FilterPredicate.isIdenticalTo(search)([rewardPool.value]))
		)
	}

	clearSearchRewardPool() {
		this.filteredRewardPool.next(this.rewardList.slice())
	}

	searchGlRedemption(search: string) {
		if (!this.glRedemptionList) {
			return
		}
		if (!search) {
			this.filteredGlRedemption.next(this.glRedemptionList.slice())
			return
		} else {
			search = search.toLowerCase()
		}
		// filter the records
		this.filteredGlRedemption.next(
			this.glRedemptionList.filter(glRedemption => FilterPredicate.isIdenticalTo(search)([glRedemption.value]))
		)
	}

	clearSearchGlRedemption() {
		this.filteredGlRedemption.next(this.glRedemptionList.slice())
	}

	checkValidation() {
		let validation = true

		this.formState$.subscribe(x => {
			validation = Util.objectComparatorNLayer(x.value, this.redeemPartnerConversionFormView)
		})

		let rewardPoolFormStateValidation = false
		if (this.rewardPoolType === 'G') {
			this.redeemPartnerRewardPools$.subscribe(x => {
				rewardPoolFormStateValidation = x.some(v => v.form.isInvalid === true)
			})
		}

		return validation || rewardPoolFormStateValidation
	}
}
