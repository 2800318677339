import { KeyValue } from '@angular/common'
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { FormControl } from '@angular/forms'
import { MatSelect } from '@angular/material'
import { Store } from '@ngrx/store'
import { Boxed, FormGroupState } from 'ngrx-forms'
import { Observable, ReplaySubject, Subscription } from 'rxjs'
import { FilterPredicate } from 'src/app/models/util/filter-predicate'
import * as AppStore from 'src/app/store'
import * as CampaignSelectors from 'src/app/store/campaign-management/campaign/campaign.selectors'
import { ProductTypeForm, Ruleset } from 'src/app/store/campaign-management/campaign/campaign.state'

@Component({
	selector: 'app-product-type',
	templateUrl: './product-type.component.html',
	styleUrls: ['./product-type.component.scss']
})
export class ProductTypeComponent implements OnInit, OnDestroy {

	@Input() ruleset: Ruleset

	formState$: Observable<FormGroupState<ProductTypeForm>>
	action: string
	includeExcludeList: KeyValue<string, string>[] = []
	productTypes: KeyValue<string, string>[] = []
	productTypeGroups: KeyValue<string, string>[] = []
	subs: Subscription
	public dropDownCtrl: FormControl = new FormControl()
	public dropDownCtrlProductTypeGroups: FormControl = new FormControl()


	/** list of records filtered by search keyword */
	public filteredProductTypes: ReplaySubject<KeyValue<string, string>[]> = new ReplaySubject<KeyValue<string, string>[]>(1)
	public filteredProductTypeGroups: ReplaySubject<KeyValue<string, string>[]> = new ReplaySubject<KeyValue<string, string>[]>(1)

	@ViewChild('multipleSelect', { static: false }) multipleSelect: MatSelect

	constructor(
		private store: Store<AppStore.State>
	) {
		this.subs = this.store.select(CampaignSelectors.getCampaign).subscribe(({ campaignRulesetAction, rulesetData }) => {
			this.action = campaignRulesetAction
			this.includeExcludeList = rulesetData.includeExcludeList
			this.productTypes = rulesetData.productTypes
			this.productTypeGroups = rulesetData.productTypeGroups
		})
	}

	get isView(): boolean { return this.action === 'VIEW' }

	ngOnInit() {
		this.formState$ = this.store.select(CampaignSelectors.getForm, this.ruleset.id)
		// load the initial list
		this.filteredProductTypes.next(this.productTypes.slice())
		this.filteredProductTypeGroups.next(this.productTypeGroups.slice())

		this.dropDownCtrl.valueChanges
			.subscribe(value => {
				const maxChar = 255
				if (value.length > maxChar) {
					const newValue = value.slice(0, maxChar)
					this.dropDownCtrl.reset('', { emitEvent: false })
					this.dropDownCtrl.setValue(newValue)

				} else {
					this.search(value)
				}
			})


		this.dropDownCtrlProductTypeGroups.valueChanges
			.subscribe(value => {
				const maxChar = 255
				if (value.length > maxChar) {
					const newValue = value.slice(0, maxChar)
					this.dropDownCtrlProductTypeGroups.reset('', { emitEvent: false })
					this.dropDownCtrlProductTypeGroups.setValue(newValue)

				} else {
					this.searchProductTypeGroups(value)
				}
			})

	}

	search(search: string) {
		if (!this.productTypes) {
			return
		}
		if (!search) {
			this.filteredProductTypes.next(this.productTypes.slice())
			return
		} else {
			search = search.toLowerCase()
		}
		// filter the records
		this.filteredProductTypes.next(
			this.productTypes.filter(productType => FilterPredicate.isIdenticalTo(search)([productType.value]))
		)
	}

	clearSearch() {
		this.filteredProductTypes.next(this.productTypes.slice())
	}

	searchProductTypeGroups(search: string) {
		if (!this.productTypeGroups) {
			return
		}
		if (!search) {
			this.filteredProductTypeGroups.next(this.productTypeGroups.slice())
			return
		} else {
			search = search.toLowerCase()
		}
		// filter the records
		this.filteredProductTypeGroups.next(
			this.productTypeGroups.filter(productTypeGroup => FilterPredicate.isIdenticalTo(search)([productTypeGroup.value]))
		)
	}

	clearSearchProductTypeGroups() {
		this.filteredProductTypeGroups.next(this.productTypeGroups.slice())
	}

	ngOnDestroy() {
		this.subs.unsubscribe()
	}

	getIncludeExcludeValue(value: string): string {
		const result = this.includeExcludeList.find(x => x.key === value)
		return result ? result.value : ''
	}

	getProductTypeValue(value: Boxed<string[]>): string {
		let resp = ''

		if (value && value.value) {
			value.value.forEach(x => {
				const result = this.productTypes.find(y => x === y.key)
				if (result) {
					if (resp) { resp += ',' }
					resp += result.value
				}
			})
		}

		return resp
	}

	getProductTypeGroupsValue(value: Boxed<string[]>): string {
		let resp = ''

		if (value && value.value) {
			value.value.forEach(x => {
				const result = this.productTypeGroups.find(y => x === y.key)
				if (result) {
					if (resp) { resp += ',' }
					resp += result.value
				}
			})
		}

		return resp
	}
}
