<span *ngIf="title" mat-dialog-title>{{ title | translate }}</span>

<mat-dialog-content *ngIf="content">
  <p>{{ content | translate: contentParam }}</p>
</mat-dialog-content>

<div fxLayout="row" fxLayoutAlign="end">
  <button class="mat-raised-button" (click)="cancel()">{{ cancelBtn | translate }}</button>
  <button class="mat-raised-button mat-primary" (click)="submit()"
    fxFlexOffset="8px">{{ submitBtn | translate }}</button>
</div>