import { KeyValue } from '@angular/common'
import { createAction, props } from '@ngrx/store'
import { GlProvision } from 'src/app/models/param-settings/gl-provision/gl-provision'
import { GlProvisionListResponse } from 'src/app/models/param-settings/gl-provision/gl-provision-list'
import { RewardPoolListResponse } from 'src/app/models/param-settings/reward-pool/reward-pool-list'
import { RewardPoolCycleListOptionResponse, RewardPoolTypeListOptionResponse } from 'src/app/models/param-settings/reward-pool/reward-pool-option'
import { RewardPoolViewResponse } from 'src/app/models/param-settings/reward-pool/reward-pool-view'

export const InitialState = createAction('[RewardPool] InitialState')
export const Reset = createAction('[RewardPool] Reset')
export const SetId = createAction('[RewardPool] SetId', props<{ id: number }>())

export const GoList = createAction('[RewardPool] GoList')
export const GoView = createAction('[RewardPool] GoView')
export const GoCreate = createAction('[RewardPool] GoCreate')
export const GoUpdate = createAction('[RewardPool] GoUpdate')

export const GoBackDialog = createAction('[RewardPool] GoBackDialog', props<{ action: string }>())
export const CreateDialog = createAction('[RewardPool] CreateDialog')
export const UpdateDialog = createAction('[RewardPool] UpdateDialog')
export const DeleteDialog = createAction('[RewardPool] DeleteDialog')
export const CloseDialog = createAction('[RewardPool] CloseDialog')

export const RestError = createAction('[RewardPool] RestError', props<{ message: string, manualThrow?: boolean }>())

export const List = createAction('[RewardPool] List')
export const ListSuccess = createAction('[RewardPool] ListSuccess', props<{ payload: RewardPoolListResponse }>())

export const OptionList = createAction('[RewardPool] OptionList')
export const OptionListSuccess = createAction('[RewardPool] OptionListSuccess', props<{ payload: RewardPoolCycleListOptionResponse }>())

export const RewardPoolTypeOptionList = createAction('[RewardPool] RewardPoolTypeOptionList')
export const RewardPoolTypeOptionListSuccess = createAction('[RewardPool] RewardPoolTypeOptionListSuccess', props<{ payload: RewardPoolTypeListOptionResponse }>())

export const GLOptionListCreate = createAction('[RewardPool] GLOptionListCreate')
export const GLOptionListCreateSuccess = createAction('[RewardPool] GLOptionListCreateSuccess', props<{ payload: GlProvisionListResponse }>())

export const GLOptionListUpdate = createAction('[RewardPool] GLOptionListUpdate')
export const GLOptionListUpdateSuccess = createAction('[RewardPool] GLOptionListUpdateSuccess', props<{ payload: GlProvisionListResponse }>())

export const GLDetails = createAction('[RewardPool] GLDetails')
export const GLDetailsSuccess = createAction('[RewardPool] GLDetailsSuccess', props<{ payload: GlProvision }>())

export const View = createAction('[RewardPool] View')
export const ViewSuccess = createAction('[RewardPool] ViewSuccess', props<{ payload: RewardPoolViewResponse }>())

export const Create = createAction('[RewardPool] Create')
export const CreateSuccess = createAction('[RewardPool] CreateSuccess', props<{ payload: string }>())

export const Update = createAction('[RewardPool] Update')
export const UpdateSuccess = createAction('[RewardPool] UpdateSuccess', props<{ payload: string }>())

export const Delete = createAction('[RewardPool] Delete')
export const DeleteSuccess = createAction('[RewardPool] DeleteSuccess', props<{ payload: string }>())

export const ResourceListCreate = createAction('[RewardPool] ResourceListCreate')
export const ResourceListCreateSuccess = createAction('[RewardPool] ResourceListCreateSuccess', props<{ payload: KeyValue<string, string>[] }>())

export const ResourceListUpdate = createAction('[RewardPool] ResourceListUpdate')
export const ResourceListUpdateSuccess = createAction('[RewardPool] ResourceListUpdateSuccess', props<{ payload: KeyValue<string, string>[] }>())

export const ClearExpCycle = createAction('[RewardPool] ClearExpCycle')
export const ClearStatement = createAction('[RewardPool] ClearStatement')

export const OnChangeExpCycleChange = createAction('[RewardPool] OnChangeExpCycleChange', props<{ value: string }>())
export const OnChangeRewardPoolType = createAction('[RewardPool] OnChangeRewardPoolType')

export const OnChangeChannel = createAction('[RewardPool] OnChangeChannel', props<{ payload: string }>())

export const DuplicateDialog = createAction('[RewardPool] DuplicateDialog')
export const Duplicate = createAction('[RewardPool] Duplicate')
export const DuplicateSuccess = createAction('[RewardPool] DuplicateSuccess', props<{ payload: string }>())
