import { createAction, props } from '@ngrx/store'
import { RewardPoolGroupListResponse } from 'src/app/models/param-settings/reward-pool-group/reward-pool-group-list'
import { RewardPoolGroupViewResponse } from 'src/app/models/param-settings/reward-pool-group/reward-pool-group-view'
import { RewardPoolListResponse } from 'src/app/models/param-settings/reward-pool/reward-pool-list'
import { Resource, RewardPoolGroupGlRedemptionResponse } from 'src/app/models/param-settings/reward-pool-group/reward-pool-group'
import { GlRedemptionListResponse } from 'src/app/models/param-settings/gl-redemption/gl-redemption-list'
import { POLICY } from 'src/app/models/common/constant'

export const InitialState = createAction('[RewardPoolGroup] InitialState')
export const Reset = createAction('[RewardPoolGroup] Reset')
export const SetId = createAction('[RewardPoolGroup] SetId', props<{ id: number }>())
export const SetRewardPoolId = createAction('[RewardPoolGroup] SetRewardPoolId', props<{ rewardPoolId: number }>())
export const SetRedemptionItemId = createAction('[RewardPoolGroup] SetRedemptionItemId', props<{ redemptionItemId: number }>())
export const SetRedemptionItemKind = createAction('[RewardPoolGroup] SetRedemptionItemKind', props<{ redemptionItemKind: string }>())
export const SetAllGlMapCheckbox = createAction('[RewardPoolGroup] SetAllGlMapCheckbox', props<{ redemptionItemKind: string, updated: boolean }>())
export const SetGlMapCheckbox = createAction('[RewardPoolGroup] SetGlMapCheckbox', props<{ redemptionItemKind: string, redemptionItemId: number }>())

export const GoList = createAction('[RewardPoolGroup] GoList')
export const GoView = createAction('[RewardPoolGroup] GoView')
export const GoCreate = createAction('[RewardPoolGroup] GoCreate')
export const GoUpdate = createAction('[RewardPoolGroup] GoUpdate')
export const GoUpdateGlRedemption = createAction('[RewardPoolGroup] GoUpdateGlRedemption')
export const GoUpdateGlRedemptionView = createAction('[RewardPoolGroup] GoUpdateGlRedemptionView')

export const GoBackDialog = createAction('[RewardPoolGroup] GoBackDialog', props<{ action: string }>())
export const CreateDialog = createAction('[RewardPoolGroup] CreateDialog')
export const UpdateDialog = createAction('[RewardPoolGroup] UpdateDialog')
export const DeleteDialog = createAction('[RewardPoolGroup] DeleteDialog')
export const RemoveRewardPoolDialog = createAction('[RewardPoolGroup] RemoveRewardPoolDialog', props<{ rewardPoolId: number }>())
export const RemoveRewardPoolFromViewDialog = createAction('[RewardPoolGroup] RemoveRewardPoolFromViewDialog')
export const closeDialog = createAction('[RewardPoolGroup] closeDialog')
export const GlRedemptionDialog = createAction('[RewardPoolGroup] GlRedemptionDialog')
export const RevertRewardPoolDialog = createAction('[RewardPoolGroup] RevertRewardPoolDialog', props<{ rewardPoolId: number }>())

export const RestError = createAction('[RewardPoolGroup] RestError', props<{ message: string, manualThrow: boolean }>())

export const List = createAction('[RewardPoolGroup] List')
export const ListSuccess = createAction('[RewardPoolGroup] ListSuccess', props<{ payload: RewardPoolGroupListResponse }>())

export const View = createAction('[RewardPoolGroup] View')
export const ViewSuccess = createAction('[RewardPoolGroup] ViewSuccess', props<{ payload: RewardPoolGroupViewResponse }>())

export const Create = createAction('[RewardPoolGroup] Create')
export const CreateSuccess = createAction('[RewardPoolGroup] CreateSuccess', props<{ payload: string }>())

export const Update = createAction('[RewardPoolGroup] Update')
export const UpdateSuccess = createAction('[RewardPoolGroup] UpdateSuccess', props<{ payload: string }>())

export const UpdateRedemptionSequence = createAction('[RewardPoolGroup] UpdateRedemptionSequence', props<{ payload: Resource[] }>())
export const UpdateRedemptionSequenceSuccess = createAction('[RewardPoolGroup] UpdateRedemptionSequenceSuccess', props<{ payload: string }>())

export const Delete = createAction('[RewardPoolGroup] Delete')
export const DeleteSuccess = createAction('[RewardPoolGroup] DeleteSuccess', props<{ payload: string }>())

export const AssignRewardPoolDialog = createAction('[RewardPoolGroup] AssignRewardPoolDialog')
export const AssignRewardPoolList = createAction('[RewardPoolGroup] AssignRewardPoolList')
export const AssignRewardPoolListSuccess = createAction('[RewardPoolGroup] AssignRewardPoolListSuccess', props<{ payload: RewardPoolListResponse }>())

export const RemoveRewardPoolFromRewardPoolGroup = createAction('[RewardPoolGroup] RemoveRewardPoolFromRewardPoolGroup', props<{ rewardPoolId: number }>())
export const RemoveRewardPoolFromRewardPoolGroupSuccess = createAction('[RewardPoolGroup] RemoveRewardPoolFromRewardPoolGroupSuccess', props<{ message: string }>())
export const RevertRemovedRewardPoolFromRewardPoolGroup = createAction('[RewardPoolGroup] RevertRemovedRewardPoolFromRewardPoolGroup', props<{ rewardPoolId: number }>())
export const RevertRemovedRewardPoolFromRewardPoolGroupSuccess = createAction('[RewardPoolGroup] RevertRemovedRewardPoolFromRewardPoolGroupSuccess', props<{ message: string }>())
export const AssignRewardPoolToRewardPoolGroup = createAction('[RewardPoolGroup] AssignRewardPoolToRewardPoolGroup', props<{ rewardPoolId: number }>())
export const AssignRewardPoolToRewardPoolGroupSuccess = createAction('[RewardPoolGroup] AssignRewardPoolToRewardPoolGroupSuccess', props<{ message: string }>())
export const AssignRewardPoolToRewardGroup = createAction('[RewardPoolGroup] AssignRewardPoolToRewardPoolGroupSuccess', props<{ message: string }>())

export const GetRewardPoolGroupGlRedemptionMap = createAction('[RewardPoolGroup] GetRewardPoolGroupGlRedemptionMap', props<{ rewardPoolId: number }>())
export const GetRewardPoolGlRedemptionMap = createAction('[RewardPoolGroup] GetRewardPoolGlRedemptionMap', props<{ isViewPage: boolean }>())
export const GetRewardPoolGlRedemptionMapSuccess = createAction('[RewardPoolGroup] GetRewardPoolGlRedemptionMapSuccess', props<{ payload: RewardPoolGroupGlRedemptionResponse[] }>())
export const AssignGlRedemptionToRewardPool = createAction('[RewardPoolGroup] AssignGlRedemptionToRewardPool', props<{ glRedemptionId: number }>())
export const AssignMultiGlRedemptionToRewardPool = createAction('[RewardPoolGroup] AssignMultiGlRedemptionToRewardPool', props<{ glRedemptionList: any }>())
export const GetGlRedemptionList = createAction('[RewardPoolGroup] GetGlRedemptionList', props<{ payload: POLICY }>())
export const GetGlRedemptionListSuccess = createAction('[RewardPoolGroup] GetGlRedemptionListSuccess', props<{ payload: GlRedemptionListResponse }>())
export const AssignedGlRedemptionToRewardPool = createAction('[RewardPoolGroup] AssignedGlRedemptionToRewardPool')
export const PostMultiAssignedGlRedemptionToRewardPool = createAction('[RewardPoolGroup] PostMultiAssignedGlRedemptionToRewardPool', props<{ rewardPoolGlMapList: any }>())
export const PostAssignedGlRedemptionToRewardPool = createAction('[RewardPoolGroup] PostAssignedGlRedemptionToRewardPool', props<{ redemptionItemId: number, glRedemptionId: number }>())
export const SuccessGlMapToRewardPool = createAction('[RewardPoolGroup] SuccessGlMapToRewardPool', props<{ redemptionItemId: number }>())
export const FailedGlMapToRewardPool = createAction('[RewardPoolGroup] FailedGlMapToRewardPool', props<{ redemptionItemId: number, message: string }>())
export const RewardPoolGlMapList = createAction('[RewardPoolGroup] RewardPoolGlMapList')
export const GetRewardPoolGlRedemptionMapViewSuccess = createAction('[RewardPoolGroup] GetRewardPoolGlRedemptionMapViewSuccess', props<{ payload: RewardPoolGroupGlRedemptionResponse[] }>())
