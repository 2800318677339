import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material'
import { Router } from '@angular/router'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Action, Store } from '@ngrx/store'
import { Observable, of } from 'rxjs'
import { catchError, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators'
import { MessageDialogComponent } from 'src/app/components/common/dialog/message-dialog/message-dialog.component'
import { getDialogConfig } from 'src/app/models/common/dialog'
import { Response } from 'src/app/models/common/http'
import * as AppStore from 'src/app/store/'
import * as CommonAction from '../../common/common/common.actions'
import * as ProductPartnerAction from './product-partner.actions'
import { ProductPartnerListResponse } from 'src/app/models/catalogue-management/product-partner/product-partner-list'
import { CatalogueManagementService } from 'src/app/services/catalogue-management/catalogue-management.service'
import { StatusListResponse } from 'src/app/models/catalogue-management/product-catalogue/product-catalogue-status-list'
import { RewardPoolAndGroup } from 'src/app/models/catalogue-management/product-catalogue/product-catalogue-reward-list'
import { ProductPartnerViewResponse } from 'src/app/models/catalogue-management/product-partner/product-partner-view'
import { Util } from 'src/app/models/util/util'
import { ParameterSettingsService } from 'src/app/services/param-settings/parameter-settings.service'
import { POLICY } from 'src/app/models/common/constant'
import { RedeemPartnerListDataResponse } from 'src/app/models/catalogue-management/product-partner/product-partner-redeem-partner-list'
import { ProductPartnerImageUploadResponse } from 'src/app/models/catalogue-management/product-partner/product-partner-image-upload'
import { ProductPartnerDuplicateComponent } from '../../../components/catalogue-management/product-partner/product-partner-duplicate/product-partner-duplicate.component'

@Injectable()
export class Effects {

	constructor(
		private action$: Actions,
		private router: Router,
		private dialog: MatDialog,
		private catalogueManagementService: CatalogueManagementService,
		private parameterSettingsService: ParameterSettingsService,
		private store: Store<AppStore.State>
	) { }

	InitialState$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ProductPartnerAction.InitialState),
			tap(() => {
				this.router.navigate(['catalogue-management/product-catalogue'])
			})
		), { dispatch: false }
	)

	GoList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ProductPartnerAction.GoList),
			tap(() => {
				this.router.navigate(['catalogue-management/product-catalogue'])
			})
		), { dispatch: false }
	)

	GoView$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ProductPartnerAction.GoView),
			tap(() => {
				this.router.navigate(['catalogue-management/product-partner/view'])
			})
		), { dispatch: false }
	)

	GoCreate$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ProductPartnerAction.GoCreate),
			tap(() => {
				this.router.navigate(['catalogue-management/product-partner/create'])
			})
		), { dispatch: false }
	)

	GoEdit$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ProductPartnerAction.GoUpdate),
			tap(() => {
				this.router.navigate(['catalogue-management/product-partner/edit'])
			})
		), { dispatch: false }
	)

	GoBackDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ProductPartnerAction.GoBackDialog),
			mergeMap(({ action }) => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.BACK', payload: action }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					if (result.payload === 'LIST') {
						return ProductPartnerAction.GoList()
					}
					if (result.payload === 'VIEW') {
						return ProductPartnerAction.GoView()
					}
				} else {
					return ProductPartnerAction.closeDialog()
				}
			})
		)
	)

	RestError$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ProductPartnerAction.RestError),
			map(({ message, manualThrow }) => {
				return CommonAction.RestError({ message, manualThrow })
			})
		)
	)

	StatusList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ProductPartnerAction.StatusList),
			withLatestFrom(this.store.select(state => state.productPartner)),
			mergeMap(([x, productPartner]) => {
				return this.catalogueManagementService.getProductPartnerStatusList().pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: StatusListResponse = response.payload
					if (success) {
						return ProductPartnerAction.StatusListSuccess({ payload })
					} else { return ProductPartnerAction.RestError(response as any) }
				}), catchError(this.catcher))
			})
		)
	)

	OnChangeRedeemPartner$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ProductPartnerAction.OnChangeRedeemPartner),
			withLatestFrom(this.store.select(state => state.productPartner)),
			mergeMap(([x, productPartner]) => {
				return this.catalogueManagementService.getProductPartnerFilteredRewardPoolAndGroupList({policy: x.payload as POLICY}, x.redeemPartnerId).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: RewardPoolAndGroup[] = response.payload
					if (success) {
						if (x.clearRewardPool) {
							return ProductPartnerAction.OnChangeRedeemPartnerSuccess({ payload })
						} else {
							return ProductPartnerAction.RewardPoolAndGroupListSuccess({ payload })
						}
					} else { return ProductPartnerAction.RestError(response as any) }
				}), catchError(this.catcher))
			})
		)
	)

	RedeemPartnerList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ProductPartnerAction.RedeemParnterList),
			withLatestFrom(this.store.select(state => state.productPartner)),
			mergeMap(([x, productPartner]) => {
				return this.catalogueManagementService.getProductPartnerRedeemPartnerList({policy: x.payload as POLICY}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: RedeemPartnerListDataResponse = response.payload
					if (success) {
						return ProductPartnerAction.RedeemParnterListSuccess({ payload })
					} else { return ProductPartnerAction.RestError(response as any) }
				}), catchError(this.catcher))
			})
		)
	)

	RewardPoolAndGroupList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ProductPartnerAction.RewardPoolAndGroupList),
			withLatestFrom(this.store.select(state => state.productPartner)),
			mergeMap(([x, productPartner]) => {
				return this.catalogueManagementService.getProductPartnerRewardPoolAndGroupList({ policy: x.payload as POLICY }).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: RewardPoolAndGroup[] = response.payload
					if (success) {
						return ProductPartnerAction.RewardPoolAndGroupListSuccess({ payload })
					} else { return ProductPartnerAction.RestError(response as any) }
				}), catchError(this.catcher))
			})
		)
	)

	List$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ProductPartnerAction.List),
			withLatestFrom(this.store.select(state => state.productPartner)),
			mergeMap(([x, productPartner]) => {
				return this.catalogueManagementService.getProductPartnerList().pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: ProductPartnerListResponse = response.payload

					if (success) {
						return ProductPartnerAction.ListSuccess({ payload })
					} else { return ProductPartnerAction.RestError(response as any) }
				}), catchError(this.catcher)
				)
			})
		)
	)

	View$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ProductPartnerAction.View),
			withLatestFrom(this.store.select(state => state.productPartner)),
			mergeMap(([, productPartner]) => this.catalogueManagementService.getProductPartnerDetails({ id: productPartner.id })
				.pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: ProductPartnerViewResponse = response.payload
					if (success) {
						return ProductPartnerAction.ViewSuccess({ payload })
					} else { return ProductPartnerAction.RestError(response as any) }
				}), catchError(this.catcher)
				)
			)
		)
	)

	ViewSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ProductPartnerAction.ViewSuccess),
			withLatestFrom(this.store.select(state => state.productPartner)),
			mergeMap(([, productPartner ]) => {
				return [ProductPartnerAction.OnChangeRedeemPartner({payload: POLICY.PRODUCT_CATALOGUE_CREATE, redeemPartnerId: productPartner.redeemPartnerId, clearRewardPool: false})]
			})
		)
	)

	CreateDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ProductPartnerAction.CreateDialog),
			mergeMap(() => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.CREATE_PRODUCT_PARTNER' }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return ProductPartnerAction.Create()
				} else {
					return ProductPartnerAction.closeDialog()
				}
			})
		)
	)

	Create$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ProductPartnerAction.Create),
			withLatestFrom(this.store.select(state => state.productPartner)),
			mergeMap(([, productPartner]) => {
				const form = productPartner.productPartnerDetailForm.controls

				return this.catalogueManagementService.createProductPartner({
					code: form.code.value,
					name: form.name.value,
					description: form.description.value,
					status: form.status.value,
					rewardPoolId: form.rewardPoolAndGroup.value != null ? Number(form.rewardPoolAndGroup.value.split('_')[1]) : null,
					rewardPoolType: form.rewardPoolAndGroup.value != null ? form.rewardPoolAndGroup.value.split('_')[0] : null,
					redeemPartnerId: productPartner.redeemPartnerId,
					redeemPartner: form.redeemPartner.value,
					startDate: Util.fromISOStringToDateStr(form.startDate.value),
					endDate: Util.fromISOStringToDateStr(form.endDate.value),
					point: Number(form.point.value),
					image: form.imageFileName.value
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: string = response.payload
					if (success) {
						return ProductPartnerAction.CreateSuccess({ payload })
					} else { return ProductPartnerAction.RestError(response as any) }
				}), catchError(this.catcher))
			})
		)
	)

	CreateSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ProductPartnerAction.CreateSuccess),
			mergeMap(({ payload }) => {
				return [ProductPartnerAction.GoList(), CommonAction.RestError({ message: payload })]
			})
		)
	)

	UpdateDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ProductPartnerAction.UpdateDialog),
			mergeMap(() => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.UPDATE' }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return ProductPartnerAction.Update()
				} else {
					return ProductPartnerAction.closeDialog()
				}
			})
		)
	)

	Update$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ProductPartnerAction.Update),
			withLatestFrom(this.store.select(state => state.productPartner)),
			mergeMap(([, productPartner]) => {
				const form = productPartner.productPartnerDetailForm.controls

				return this.catalogueManagementService.editProductPartner({
					id: productPartner.id,
					name: form.name.value,
					description: form.description.value,
					status: form.status.value,
					rewardPoolId: form.rewardPoolAndGroup.value != null ? Number(form.rewardPoolAndGroup.value.split('_')[1]) : null,
					rewardPoolType: form.rewardPoolAndGroup.value != null ? form.rewardPoolAndGroup.value.split('_')[0] : null,
					redeemPartnerId: productPartner.redeemPartnerId,
					redeemPartner: form.redeemPartner.value,
					startDate: Util.fromISOStringToDateStr(form.startDate.value),
					endDate: Util.fromISOStringToDateStr(form.endDate.value),
					point: Number(form.point.value),
					image: form.imageFileName.value,
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: string = response.payload
					if (success) {
						return ProductPartnerAction.UpdateSuccess({ payload })
					} else { return ProductPartnerAction.RestError(response as any) }
				}), catchError(this.catcher))
			})
		)
	)

	UpdateSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ProductPartnerAction.UpdateSuccess),
			mergeMap(({ payload }) => {
				return [ProductPartnerAction.GoView(), CommonAction.RestError({ message: payload })]
			})
		)
	)

	DeleteDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ProductPartnerAction.DeleteDialog),
			mergeMap(() => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.DELETE_PRODUCT_PARTNER' }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return ProductPartnerAction.Delete()
				} else {
					return ProductPartnerAction.closeDialog()
				}
			})
		)
	)

	Delete$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ProductPartnerAction.Delete),
			withLatestFrom(this.store.select(state => state.productPartner)),
			mergeMap(([, productPartner]) => this.catalogueManagementService.deleteProductPartner({
				id: productPartner.id
			}).pipe(map((response: Response) => {
				const success: boolean = response.success
				const payload: string = response.payload

				if (success) {
					return ProductPartnerAction.DeleteSuccess({ payload })
				} else { return ProductPartnerAction.RestError(response as any) }
			}), catchError(this.catcher)
			))
		)
	)

	DeleteSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ProductPartnerAction.DeleteSuccess),
			mergeMap(({ payload }) => {
				return [ProductPartnerAction.GoList(), CommonAction.RestError({ message: payload })]
			})
		)
	)

	UploadProductPartnerImage$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ProductPartnerAction.UploadProductPartnerImage),
			withLatestFrom(this.store.select(state => state.productCatalogue)),
			mergeMap(([param, productCatalogue]) => {
				const form = productCatalogue.productCatalogueDetailForm.controls
				return this.catalogueManagementService.uploadProductPartnerImage(param.request)
					.pipe(map((response: Response) => {
						const success: boolean = response.success
						const payload: ProductPartnerImageUploadResponse = response.payload
						if (success) {
							return ProductPartnerAction.UploadProductPartnerImageSuccess({ payload })
						} else { return ProductPartnerAction.RestError(response as any) }
					}), catchError(this.catcher))
			})
		)
	)

	DuplicateDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ProductPartnerAction.DuplicateDialog),
			mergeMap(() => {
				const dialogRef = this.dialog.open(ProductPartnerDuplicateComponent, getDialogConfig({}))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return ProductPartnerAction.Duplicate()
				} else {
					return ProductPartnerAction.closeDialog()
				}
			})
		)
	)

	Duplicate$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ProductPartnerAction.Duplicate),
			withLatestFrom(this.store.select(state => state.productPartner)),
			mergeMap(([, productPartner]) => {
				const productPartnerDuplicateForm = productPartner.productPartnerDuplicateForm.value
				return this.catalogueManagementService.duplicateProductPartner(productPartner.id, {
					code: productPartnerDuplicateForm.code,
					name: productPartnerDuplicateForm.name
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload = response.payload

					if (success) {
						return ProductPartnerAction.DuplicateSuccess({payload})
					} else {
						return ProductPartnerAction.RestError(response as any)
					}
				}), catchError(this.catcher))
			})
		)
	)

	DuplicateSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ProductPartnerAction.DuplicateSuccess),
			mergeMap(({payload}) => {
				return [
					ProductPartnerAction.List(),
					CommonAction.RestError({message: payload})
				]
			})
		)
	)

	catcher = (error) => {
		return (of(ProductPartnerAction.RestError({ message: error.message, manualThrow: error.manualThrow })))
	}
}
