<form novalidate [ngrxFormState]="formState$ | async">
  <div *ngIf="isView" fxLayout="column">
    <mat-form-field fxFlex>
      <input matInput value="{{getEqualValue((formState$ | async).controls.equation.value)}}" type="text"
        maxlength="255" readonly>
    </mat-form-field>

    <mat-form-field fxFlex>
      <input matInput value="{{getAccountStatusValue((formState$ | async).controls.value.value)}}" type="text"
        maxlength="255" readonly>
    </mat-form-field>
  </div>

  <div *ngIf="!isView" fxLayout="column">
    <mat-radio-group [ngrxFormControlState]="(formState$ | async).controls.equation" color="primary" fxLayoutGap="8px">
      <mat-radio-button *ngFor="let i of equalList" [value]="i.key">
        {{i.value}}
      </mat-radio-button>
    </mat-radio-group>

    <mat-form-field fxFlex>
      <mat-select [ngrxFormControlState]="(formState$ | async).controls.value" multiple #multipleSelect
        (closed)="clearSearch()">
        <ngx-mat-select-search [formControl]="dropDownCtrl" [placeholderLabel]="'COMMON.SEARCH'| translate"
          [noEntriesFoundLabel]="'COMMON.NOT_MATCHING_FOUND' | translate">
          <mat-icon ngxMatSelectSearchClear (click)="clearSearch()">close</mat-icon>
        </ngx-mat-select-search>
        <mat-option *ngFor="let i of filteredAccountStatusList | async" [value]="i.key">
          {{i.value}}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="(formState$ | async).errors._value">
        <app-error-validation [errors]="(formState$ | async).errors._value" [maxlength]="255">
        </app-error-validation>
      </mat-error>
    </mat-form-field>
  </div>
</form>
