import { animate, state, style, transition, trigger } from '@angular/animations'
import { Component, HostBinding, Input, OnInit, OnDestroy } from '@angular/core'
import { Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { NavItem } from 'src/app/models/common/nav-item'
import * as CommonAction from 'src/app/store/common/common'
import * as AppStore from 'src/app/store'
import { Subscription } from 'rxjs'

@Component({
	selector: 'app-sidenav-list-item',
	templateUrl: './sidenav-list-item.component.html',
	styleUrls: ['./sidenav-list-item.component.scss'],
	animations: [
		trigger('indicatorRotate', [
			state('collapsed', style({ transform: 'rotate(360deg)' })),
			state('expanded', style({ transform: 'rotate(180deg)' })),
			transition('expanded <=> collapsed',
				animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
			),
		])
	]
})
export class SidenavListItemComponent implements OnInit, OnDestroy {

	expanded: boolean
	@HostBinding('attr.aria-expanded') ariaExpanded = this.expanded
	@Input() navItem: NavItem
	@Input() depth: number

	subsCurrentActiveNav: Subscription

	constructor(
		public router: Router,
		private store: Store<AppStore.State>
	) {
		if (this.depth === undefined) { this.depth = 0 }
	}

	ngOnInit() {
		this.subsCurrentActiveNav = this.store.select(CommonAction.getCurrentActiveNav).subscribe(x => {
			if (x !== this.navItem.displayName) {
				this.expanded = false
			}
		})
	}

	ngOnDestroy() {
		this.subsCurrentActiveNav.unsubscribe()
	}

	onItemSelected(navItem: NavItem) {
		if (!this.navItem.children || !this.navItem.children.length) {
			if (this.router.url !== this.navItem.route) {
				this.store.dispatch(this.navItem.action)
			}
		}

		if (this.navItem.children && this.navItem.children.length) {
			this.store.dispatch(CommonAction.SetCurrentActiveNav({ currentActiveNav: navItem.displayName }))
			this.expanded = !this.expanded
		}
	}
}
