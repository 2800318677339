import { Component, Inject, OnDestroy, OnInit } from '@angular/core'
import { Observable, ReplaySubject, Subscription } from 'rxjs'
import { FormGroupState } from 'ngrx-forms'
import { AutoRedemptionDuplicateForm } from '../../../../store/redemption-settings/auto-redemption'
import { Store } from '@ngrx/store'
import * as AppStore from '../../../../store'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material'
import * as AutoRedemptionSelector from 'src/app/store/redemption-settings/auto-redemption/auto-redemption.selectors'
import * as AutoRedemptionAction from 'src/app/store/redemption-settings/auto-redemption/auto-redemption.actions'
import { KeyValue } from '@angular/common'
import { FormControl } from '@angular/forms'
import { FilterPredicate } from '../../../../models/util/filter-predicate'
import { POLICY } from '../../../../models/common/constant'

@Component({
	selector: 'app-auto-redemption-duplicate',
	templateUrl: './auto-redemption-duplicate.component.html',
	styleUrls: ['./auto-redemption-duplicate.component.scss']
})
export class AutoRedemptionDuplicateComponent implements OnDestroy, OnInit {

	isLoading = false
	formState$: Observable<FormGroupState<AutoRedemptionDuplicateForm>>

	rewardPoolAndGroupList: Observable<KeyValue<string, string>[]>
	rewardPoolList: KeyValue<string, string>[] = []
	selectedValue: any = ''

	public filteredRewardPool: ReplaySubject<KeyValue<string, string>[]> = new ReplaySubject<KeyValue<string, string>[]>(1)
	public dropDownCtrlRewardPoolGroup: FormControl = new FormControl(this.selectedValue)


	subs: Subscription

	constructor(
		private store: Store<AppStore.State>,
		private dialogRef: MatDialogRef<AutoRedemptionDuplicateComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		this.subs = this.store.select(AutoRedemptionSelector.getAutoRedemption).subscribe(({isDuplicateLoading, isDuplicateDialog, rewardPoolAndGroupList}) => {
			this.isLoading = isDuplicateLoading
			if (isDuplicateDialog) {
				this.cancel()
			}

			const rewardPoolList = rewardPoolAndGroupList

			if (rewardPoolList) {
				this.rewardPoolList = rewardPoolList.filter(o => o.linkage === false).map(i => ({key: `I_${i.id}`, value: `${i.code} - ${i.name}`})) as KeyValue<string, string>[]
			}

			this.filteredRewardPool.next(this.rewardPoolList.slice())
		})

		this.formState$ = this.store.select(({autoRedemption}) => autoRedemption.autoRedemptionDuplicateForm)
		this.rewardPoolAndGroupList = this.store.select(({autoRedemption}) => autoRedemption.rewardPoolAndGroupList.filter(o => o.linkage === false).map(i => ({key: `I_${i.id}`, value: `${i.code} - ${i.name}`})) as KeyValue<string, string>[])

	}

	ngOnDestroy(): void {
		this.subs.unsubscribe()
	}

	ngOnInit(): void {
		this.store.dispatch(AutoRedemptionAction.DuplicateRewardPoolAndGroupList({payload: POLICY.AUTO_REDEMPTION_CREATE}))
		this.filteredRewardPool.next(this.rewardPoolList.slice())
		this.dropDownCtrlRewardPoolGroup.valueChanges
			.subscribe(value => {
				const maxChar = 255
				if (value.length > maxChar) {
					const newValue = value.slice(0, maxChar)
					this.dropDownCtrlRewardPoolGroup.reset('', {emitEvent: false})
					this.dropDownCtrlRewardPoolGroup.setValue(newValue)

				} else {
					this.searchRewardPool(value)
				}
			})
	}

	cancel() {
		this.dialogRef.close()
	}

	submit() {
		this.store.dispatch(AutoRedemptionAction.Duplicate())
	}

	searchRewardPool(search: string) {
		if (!this.rewardPoolList) {
			return
		}
		if (!search) {
			this.filteredRewardPool.next(this.rewardPoolList.slice())
			return
		} else {
			search = search.toLowerCase()
		}
		// filter the records
		this.filteredRewardPool.next(
			this.rewardPoolList.filter(rewardPool => FilterPredicate.isIdenticalTo(search)([rewardPool.value]))
		)
	}

	clearSearchRewardPool() {
		this.filteredRewardPool.next(this.rewardPoolList.slice())
	}
}
