import { createAction, props } from '@ngrx/store'
import { FileFrequencyListResponse } from 'src/app/models/catalogue-management/fulfilment-partner/fulfilment-partner-file-frequency-list'
import { FulfilmentPartnerListResponse } from 'src/app/models/catalogue-management/fulfilment-partner/fulfilment-partner-list'
import { FulfilmentPartnerViewResponse } from 'src/app/models/catalogue-management/fulfilment-partner/fulfilment-partner-view'

export const InitialState = createAction('[FulfilmentPartner] InitialState')
export const SetId = createAction('[FulfilmentPartner] SetId', props<{ id: number }>())

export const GoList = createAction('[FulfilmentPartner] GoList')
export const GoView = createAction('[FulfilmentPartner] GoView')
export const GoCreate = createAction('[FulfilmentPartner] GoCreate')
export const GoUpdate = createAction('[FulfilmentPartner] GoUpdate')

export const GoBackDialog = createAction('[FulfilmentPartner] GoBackDialog', props<{ action: string }>())
export const CreateDialog = createAction('[FulfilmentPartner] CreateDialog')
export const UpdateDialog = createAction('[FulfilmentPartner] UpdateDialog')
export const DeleteDialog = createAction('[FulfilmentPartner] DeleteDialog')
export const closeDialog = createAction('[FulfilmentPartner] closeDialog')

export const RestError = createAction('[FulfilmentPartner] RestError', props<{ message: string, manualThrow: boolean }>())

export const FileFrequencyList = createAction('[FulfilmentPartner] FileFrequencyList')
export const FileFrequencyListSuccess = createAction('[FulfilmentPartner] FileFrequencyListSuccess', props<{ payload: FileFrequencyListResponse }>())

export const List = createAction('[FulfilmentPartner] List')
export const ListSuccess = createAction('[FulfilmentPartner] ListSuccess', props<{ payload: FulfilmentPartnerListResponse }>())

export const View = createAction('[FulfilmentPartner] View')
export const ViewSuccess = createAction('[FulfilmentPartner] ViewSuccess', props<{ payload: FulfilmentPartnerViewResponse }>())

export const Create = createAction('[FulfilmentPartner] Create')
export const CreateSuccess = createAction('[FulfilmentPartner] CreateSuccess', props<{ payload: string }>())

export const Update = createAction('[FulfilmentPartner] Update')
export const UpdateSuccess = createAction('[FulfilmentPartner] UpdateSuccess', props<{ payload: string }>())

export const Delete = createAction('[FulfilmentPartner] Delete')
export const DeleteSuccess = createAction('[FulfilmentPartner] DeleteSuccess', props<{ payload: string }>())

export const DuplicateDialog = createAction('[FulfilmentPartner] DuplicateDialog')
export const Duplicate = createAction('[FulfilmentPartner] Duplicate')
export const DuplicateSuccess = createAction('[FulfilmentPartner] DuplicateSuccess', props<{ payload: string }>())
