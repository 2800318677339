import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store'
import { TypedAction } from '@ngrx/store/src/models'
import { environment } from 'src/environments/environment'
import * as Group from './access-management/group'
import * as Policy from './access-management/policy'
import * as Dashboard from './dashboard/dashboard-overview'
import * as DashboardV2 from './dashboard/dashboard-overview-v2'
import * as User from './access-management/user'
import * as PointsAdjustmentFileUpload from './adjustment-function/point-adjustment-file-upload'
import * as Login from './authentication/login'
import * as Campaign from './campaign-management/campaign'
import * as ProductCatalogue from './catalogue-management/product-catalogue'
import * as ActionBar from './common/action-bar'
import * as ActionBarWithSelection from './common/action-bar-with-selection'
import * as Common from './common/common'
import * as CustomerManagement from './customer-management'
import * as FulfilmentPartner from './catalogue-management/fulfilment-partner'
import * as FieldMapping from './param-settings/field-mapping'
import * as GlProvision from './param-settings/gl-provision'
import * as GlRedemption from './param-settings/gl-redemption'
import * as MccGroup from './param-settings/mcc-group'
import * as MerchantGroup from './param-settings/merchant-group'
import * as ProductTypeGroup from './param-settings/product-type-group'
import * as RewardPool from './param-settings/reward-pool'
import * as RewardPoolGroup from './param-settings/reward-pool-group'
import * as TcGroup from './param-settings/tc-group'
import * as AutoRedemption from './redemption-settings/auto-redemption'
import * as OnTheSpotRedemption from './redemption-settings/on-the-spot-redemption'
import * as RedeemPartner from './redemption-settings/redeem-partner'
import * as RedeemRules from './redemption-settings/redeem-rules'
import * as WaiverManagement from './redemption-settings/waiver-management'
import * as UserProfile from './user-profile'
import * as ConditionalReward from './campaign-management/conditional-reward'
import * as Tasklist from './worklist/tasklist'
import * as MyRequest from './worklist/my-request'
import * as WorklistTask from './worklist/checker-maker-function'
import * as TargetSegment from './param-settings/target-segment'
import * as DashboardRedemption from './dashboard/dashboard-redemption'
import * as Simulator from './campaign-management/simulator'
import * as ProductPartner from './catalogue-management/product-partner'
import * as FileDownload from './file-download'

export interface State {
	common: Common.State
	actionBar: ActionBar.State
	login: Login.State
	userProfile: UserProfile.State
	user: User.State
	group: Group.State
	policy: Policy.State
	dashboard: Dashboard.State
	dashboardV2: DashboardV2.State
	campaign: Campaign.State
	glProvision: GlProvision.State
	glRedemption: GlRedemption.State
	tcGroup: TcGroup.State
	mccGroup: MccGroup.State
	merchantGroup: MerchantGroup.State
	productTypeGroup: ProductTypeGroup.State
	rewardPool: RewardPool.State
	rewardPoolGroup: RewardPoolGroup.State
	fieldMapping: FieldMapping.State
	customerManagement: CustomerManagement.State
	pointAdjustmentFileUpload: PointsAdjustmentFileUpload.State
	redeemPartner: RedeemPartner.State
	autoRedemption: AutoRedemption.State
	productCatalogue: ProductCatalogue.State
	fulfilmentPartner: FulfilmentPartner.State
	waiverManagement: WaiverManagement.State
	onTheSpotRedemption: OnTheSpotRedemption.State
	redeemRules: RedeemRules.State
	conditionalReward: ConditionalReward.State
	tasklist: Tasklist.State
	myRequest: MyRequest.State
	worklistTask: WorklistTask.State
	targetSegment: TargetSegment.State
	dashboardRedemption: DashboardRedemption.State
	actionBarWithSelection: ActionBarWithSelection.State
	simulator: Simulator.State
	productPartner: ProductPartner.State
	fileDownload: FileDownload.State
}

export const reducers: ActionReducerMap<State> = {
	common: Common.Reducer,
	actionBar: ActionBar.Reducer,
	login: Login.Reducer,
	userProfile: UserProfile.Reducer,
	user: User.Reducer,
	group: Group.Reducer,
	policy: Policy.Reducer,
	dashboard: Dashboard.Reducer,
	dashboardV2: DashboardV2.Reducer,
	campaign: Campaign.Reducer,
	glProvision: GlProvision.Reducer,
	glRedemption: GlRedemption.Reducer,
	tcGroup: TcGroup.Reducer,
	mccGroup: MccGroup.Reducer,
	merchantGroup: MerchantGroup.Reducer,
	productTypeGroup: ProductTypeGroup.Reducer,
	rewardPool: RewardPool.Reducer,
	rewardPoolGroup: RewardPoolGroup.Reducer,
	fieldMapping: FieldMapping.Reducer,
	customerManagement: CustomerManagement.Reducer,
	pointAdjustmentFileUpload: PointsAdjustmentFileUpload.Reducer,
	redeemPartner: RedeemPartner.Reducer,
	autoRedemption: AutoRedemption.Reducer,
	productCatalogue: ProductCatalogue.Reducer,
	fulfilmentPartner: FulfilmentPartner.Reducer,
	waiverManagement: WaiverManagement.Reducer,
	onTheSpotRedemption: OnTheSpotRedemption.Reducer,
	redeemRules: RedeemRules.Reducer,
	conditionalReward: ConditionalReward.Reducer,
	tasklist: Tasklist.Reducer,
	myRequest: MyRequest.Reducer,
	worklistTask: WorklistTask.Reducer,
	targetSegment: TargetSegment.Reducer,
	dashboardRedemption: DashboardRedemption.Reducer,
	actionBarWithSelection: ActionBarWithSelection.Reducer,
	simulator: Simulator.Reducer,
	productPartner: ProductPartner.Reducer,
	fileDownload: FileDownload.Reducer
}

export const effects = [
	Common.Effects,
	ActionBar.Effects,
	Login.Effects,
	UserProfile.Effects,
	User.Effects,
	Group.Effects,
	Policy.Effects,
	Dashboard.Effects,
	DashboardV2.Effects,
	Campaign.Effects,
	GlProvision.Effects,
	GlRedemption.Effects,
	TcGroup.Effects,
	MccGroup.Effects,
	MerchantGroup.Effects,
	ProductTypeGroup.Effects,
	RewardPool.Effects,
	RewardPoolGroup.Effects,
	FieldMapping.Effects,
	CustomerManagement.Effects,
	PointsAdjustmentFileUpload.Effects,
	RedeemPartner.Effects,
	AutoRedemption.Effects,
	ProductCatalogue.Effects,
	FulfilmentPartner.Effects,
	WaiverManagement.Effects,
	OnTheSpotRedemption.Effects,
	RedeemRules.Effects,
	ConditionalReward.Effects,
	Tasklist.Effects,
	MyRequest.Effects,
	WorklistTask.Effects,
	TargetSegment.Effects,
	DashboardRedemption.Effects,
	ActionBarWithSelection.Effects,
	Simulator.Effects,
	ProductPartner.Effects,
	FileDownload.Effects
]

export function logger(reducer: ActionReducer<State>): ActionReducer<State> {
	return (state: State, action: TypedAction<string>): State => {
		console.log('state', state)
		console.log('action', action)

		return reducer(state, action)
	}
}

// Hide logger when production mode
// Hide logger when showStoreLogger is false, default is true
export const metaReducers: MetaReducer<State>[] = (!environment.production && environment.showStoreLogger) ? [logger] : []
