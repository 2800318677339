import { Component, OnInit, Inject, OnDestroy } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material'
import { Store } from '@ngrx/store'
import * as AppStore from 'src/app/store'
import { Subscription, Observable } from 'rxjs'
import * as CustomerManagementSelector from 'src/app/store/customer-management/customer-management.selectors'
import * as CustomerManagementAction from 'src/app/store/customer-management/customer-management.actions'
import { CustomerCatalogueRedemptionOrderViewResponse } from 'src/app/models/customer-management/customer-catalogue-redemption-order'
import { KeyValue } from '@angular/common'
import { LoginUser } from 'src/app/models/common/common'
import { getLoginUser } from 'src/app/store/common/common/common.selectors'
import { CatalogueRedemptionType } from 'src/app/models/common/constant'

@Component({
	selector: 'app-customer-management-catalogue-redemption-dialog-view',
	templateUrl: './customer-management-catalogue-redemption-dialog-view.component.html',
	styleUrls: ['./customer-management-catalogue-redemption-dialog-view.component.scss']
})
export class CustomerManagementCatalogueRedemptionDialogViewComponent implements OnInit, OnDestroy  {

	redemptionType: string
	formState$: Observable<CustomerCatalogueRedemptionOrderViewResponse>
	subs: Subscription
	subsLogin: Subscription
	rewardPoolAndGroupList: KeyValue<string, string>[]
	isLoading = false
	eCatalogueRedemptionType = CatalogueRedemptionType
	loginUser: LoginUser

	constructor(
		private store: Store<AppStore.State>,
		private dialogRef: MatDialogRef<CustomerManagementCatalogueRedemptionDialogViewComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {

		this.subsLogin = this.store.select(getLoginUser).subscribe(x => this.loginUser = x)

		this.subs = this.store.select(CustomerManagementSelector.getCustomerManagement).subscribe(x => {
			this.isLoading = x.isLoading
			this.redemptionType = x.redemptionType
		})

		this.formState$ = this.store.select(({ customerManagement }) => customerManagement.customerCatalogueRedemptionOrderViewResponse)
	}

	ngOnInit() {
	}

	ngOnDestroy() {
		this.subs.unsubscribe()
		this.subsLogin.unsubscribe()
	}

	onNoClick(): void {
		this.dialogRef.close()
	}

	getRewardPoolAndGroup(value: string): string {
		const result = this.rewardPoolAndGroupList.find(x => x.key === value)
		return result ? result.value : ''
	}

	withdraw() {
		this.store.dispatch(CustomerManagementAction.GoCatalogueRedemptionDialog({ action: 'VIEW' }))
		this.dialogRef.close()
	}
}
