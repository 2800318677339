import { KeyValue } from '@angular/common'
import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'
import { FormGroupState, MarkAsTouchedAction } from 'ngrx-forms'
import { Observable, Subject, Subscription } from 'rxjs'
import { take, takeUntil } from 'rxjs/operators'
import * as AppStore from 'src/app/store'
import * as CampaignSelectors from 'src/app/store/campaign-management/campaign/campaign.selectors'
import { ChooseDateForm, Ruleset, RulesetDetailForm } from 'src/app/store/campaign-management/campaign/campaign.state'

@Component({
	selector: 'app-choose-date',
	templateUrl: './choose-date.component.html',
	styleUrls: ['./choose-date.component.scss']
})
export class ChooseDateComponent implements OnInit, OnDestroy {

	@Input() ruleset: Ruleset

	formState$: Observable<FormGroupState<ChooseDateForm>>
	rulsesetFormState$: Observable<FormGroupState<RulesetDetailForm>>
	action: string
	chooseDateList: KeyValue<string, string>[] = []
	selectedDateTimeList: KeyValue<string, string>[] = []
	subs: Subscription
	protected _onDestroy = new Subject<void>()
	validate = false

	resourceType: string

	constructor(
		private store: Store<AppStore.State>
	) {
		this.subs = this.store.select(CampaignSelectors.getCampaign).subscribe(({ campaignRulesetAction, rulesetData, rulesetDetailForm, resourceType }) => {
			this.action = campaignRulesetAction
			this.chooseDateList = rulesetData.chooseDateList
			this.selectedDateTimeList = rulesetData.selectedDateTimeList
			this.resourceType = resourceType
			this.rulsesetFormState$ = this.store.select(({ campaign }) => campaign.rulesetDetailForm)

			this.rulsesetFormState$.pipe(takeUntil(this._onDestroy)).subscribe(o => {
				if (o.controls.code.isTouched && o.controls.name.isTouched && !this.validate) {
					this.validate = true
					if (this.formState$) {
						this.formState$.pipe(take(1)).subscribe(i => {
							if (i) {
								this.store.dispatch(new MarkAsTouchedAction(i.controls.value.id))
							}
						})
					}
				}
			})
		})
	}

	get isView(): boolean { return this.action === 'VIEW' }

	ngOnInit() {
		this.formState$ = this.store.select(CampaignSelectors.getForm, this.ruleset.id)
	}

	ngOnDestroy() {
		this.subs.unsubscribe()
	}

	getChooseDateValue(value: string): string {
		const result = this.chooseDateList.find(x => x.key === value)
		return result ? result.value : ''
	}

	getSelectedDateTimeValue(value: string): string {
		const result = this.selectedDateTimeList.find(x => x.key === value)
		return result ? result.value : ''
	}
}

