<form novalidate [ngrxFormState]="formState$ | async">
  <div *ngIf="isView" fxLayout="column">
    <mat-form-field fxFlex>
      <input matInput value="{{getChooseDateValue((formState$ | async).controls.value.value)}}" type="text"
        maxlength="255" readonly>
    </mat-form-field>

    <div *ngIf="resourceType === 'BANK'" fxLayout="column">
      <mat-form-field fxFlex>
        <input matInput value="{{getSelectedDateTimeValue((formState$ | async).controls.time.value)}}" type="text"
          maxlength="255" readonly>
      </mat-form-field>
    </div>
  </div>

  <div *ngIf="!isView" fxLayout="column">
    <div *ngIf="resourceType === 'CARD'" fxLayout="column">
      <mat-radio-group [ngrxFormControlState]="(formState$ | async).controls.value" color="primary" fxLayoutGap="8px">
        <mat-radio-button *ngFor="let i of chooseDateList" [value]="i.key">
          {{i.value}}
        </mat-radio-button>
      </mat-radio-group>
    </div>

    <div *ngIf="resourceType === 'BANK'" fxLayout="column">
      <mat-form-field fxFlex>
        <mat-select [ngrxFormControlState]="(formState$ | async).controls.value">
          <mat-option *ngFor="let i of chooseDateList" [value]="i.key">
            {{i.value}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="(formState$ | async).errors._value">
          <app-error-validation [errors]="(formState$ | async).errors._value">
          </app-error-validation>
        </mat-error>
      </mat-form-field>

      <mat-form-field fxFlex>
        <mat-select [ngrxFormControlState]="(formState$ | async).controls.time">
          <mat-option *ngFor=" let i of selectedDateTimeList" [value]="i.key">
            {{i.value}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="(formState$ | async).errors._time">
          <app-error-validation [errors]="(formState$ | async).errors._time">
          </app-error-validation>
        </mat-error>
      </mat-form-field>
    </div>
  </div>
</form>