import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { Request, Response } from 'src/app/models/common/http'
import { RestService } from '../common/rest-client/rest.service'
import { DownloadFileSampleRequest, GetFileDownloadListRequest } from 'src/app/models/file-download/file-download'

@Injectable({
	providedIn: 'root'
})
export class FileDownloadService {

	constructor(
		private rest: RestService
	) { }

	getFileDownloadList(params: GetFileDownloadListRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/file-download/list' + this.rest.urlQueryString(params),
		}
		return this.rest.get(request)
	}

	downloadFileSample(params: DownloadFileSampleRequest): Observable<Response> {
		const request: Request = {
			liveURL: '/file-download/download-file' + this.rest.urlQueryString(params),
		}
		return this.rest.get(request)
	}

}
