import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Action, Store } from '@ngrx/store'
import { Observable, of } from 'rxjs'
import { catchError, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators'
import * as AppStore from 'src/app/store/'
import * as CommonAction from '../../common/common/common.actions'
import * as TasklistAction from './tasklist.actions'
import { WorklistService } from 'src/app/services/worklist/worklist.service'
import { Response } from 'src/app/models/common/http'
import { WorklistDetailResponse, WorklistResponse } from 'src/app/models/worklist/worklist'

@Injectable()
export class Effects {

	constructor(
		private action$: Actions,
		private router: Router,
		private store: Store<AppStore.State>,
		private worklistService: WorklistService
	) { }

	InitialState$: Observable<Action> = createEffect(() => {
			return this.action$.pipe(ofType(TasklistAction.InitialState), tap(() => {
				this.router.navigate(['worklist/tasklist'])
			}))
		}, { dispatch: false }
	)

	GoList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(TasklistAction.GoList),
			tap(() => {
				this.router.navigate(['worklist/tasklist'])
			})
		), { dispatch: false }
	)

	List$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(TasklistAction.List),
			withLatestFrom(this.store.select(state => state.tasklist)),
			mergeMap(([params, ]) => this.worklistService.getTasklist({ pageNumber: params.pageNumber, pageSize: params.pageSize })
				.pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: WorklistResponse = response.payload

					if (success) {
						return TasklistAction.ListSuccess({ payload })
					} else { return TasklistAction.RestError(response as any) }
				}),
					catchError(this.catcher)
				)
			)
		)
	)

	ListByStatus$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(TasklistAction.ListByStatus),
			withLatestFrom(this.store.select(state => state.tasklist)),
			mergeMap(([params, ]) => this.worklistService.getTasklistByStatus({ status: params.status, pageNumber: params.pageNumber, pageSize: params.pageSize })
				.pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: WorklistDetailResponse = response.payload

					if (success) {
						return TasklistAction.ListByStatusSuccess({ status: params.status, payload })
					} else { return TasklistAction.RestError(response as any) }
				}),
					catchError(this.catcher)
				)
			)
		)
	)

	SearchListByKeyword$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(TasklistAction.SearchListByKeyword),
			withLatestFrom(this.store.select(state => state.tasklist)),
			mergeMap(([params, ]) => this.worklistService.searchTasklistByKeyword({ keyword: params.keyword, status: params.status, pageNumber: params.pageNumber, pageSize: params.pageSize })
				.pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: WorklistDetailResponse = response.payload

					if (success) {
						return TasklistAction.ListByStatusSuccess({ status: params.status, payload })
					} else { return TasklistAction.RestError(response as any) }
				}),
					catchError(this.catcher)
				)
			)
		)
	)


	RestError$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(TasklistAction.RestError),
			map(({ message, manualThrow }) => {
				return CommonAction.RestError({ message, manualThrow })
			})
		)
	)

	catcher = (error) => {
		return (of(TasklistAction.RestError({ message: error.message, manualThrow: error.manualThrow })))
	}
}
