import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'
import { FormGroupState } from 'ngrx-forms'
import { Observable, ReplaySubject, Subscription } from 'rxjs'
import * as AppStore from 'src/app/store'
import * as WaiverManagementSelector from 'src/app/store/redemption-settings/waiver-management/waiver-management.selectors'
import { WaiverRewardPool, WaiverRewardPoolForm } from 'src/app/store/redemption-settings/waiver-management/waiver-management.state'
import { KeyValue } from '@angular/common'
import { FormControl } from '@angular/forms'
import { FilterPredicate } from 'src/app/models/util/filter-predicate'

@Component({
	selector: 'app-assigned-waiver-reward-pool',
	templateUrl: './assigned-waiver-reward-pool.component.html',
	styleUrls: ['./assigned-waiver-reward-pool.component.scss']
})
export class AssignedWaiverRewardPoolComponent implements OnInit, OnDestroy {

	@Input() waiverRewardPool: WaiverRewardPool

	action: string
	formState$: Observable<FormGroupState<WaiverRewardPoolForm>>
	glRedemptionList: KeyValue<string, string>[] = []
	glRedemptionViewList: KeyValue<string, string>[] = []
	subs: Subscription
	selectedValue: any = ''

	public filteredGlRedemption: ReplaySubject<KeyValue<string, string>[]> = new ReplaySubject<KeyValue<string, string>[]>(1)

	public dropDownCtrlGlRedemptionGroup: FormControl = new FormControl(this.selectedValue)

	constructor(
		private store: Store<AppStore.State>
	) {
		this.subs = this.store.select(WaiverManagementSelector.getWaiverManagement).subscribe(x => {
			const waiverManagementData = x.waiverManagementData

			this.action = x.action
			this.glRedemptionList = waiverManagementData.glRedemptionList

			this.filteredGlRedemption.next(this.glRedemptionList.slice())

		})

	}

	get isView(): boolean { return this.action === 'VIEW' }

	ngOnInit() {
		this.formState$ = this.store.select(WaiverManagementSelector.getWaiverRewardPool, this.waiverRewardPool.id)
		this.dropDownCtrlGlRedemptionGroup.valueChanges
			.subscribe(value => {
				const maxChar = 255
				if (value.length > maxChar) {
					const newValue = value.slice(0, maxChar)
					this.dropDownCtrlGlRedemptionGroup.reset('', { emitEvent: false })
					this.dropDownCtrlGlRedemptionGroup.setValue(newValue)

				} else {
					this.searchGlRedemption(value)
				}
			})

	}

	ngOnDestroy() {
		this.subs.unsubscribe()
	}

	getGlRedemption(value: number): string {
		const result = this.glRedemptionList.find(x => x.key === value.toString())
		return result ? result.value : ''
	}

	searchGlRedemption(search: string) {
		if (!this.glRedemptionList) {
			return
		}
		if (!search) {
			this.filteredGlRedemption.next(this.glRedemptionList.slice())
			return
		} else {
			search = search.toLowerCase()
		}
		// filter the records
		this.filteredGlRedemption.next(
			this.glRedemptionList.filter(glRedemption => FilterPredicate.isIdenticalTo(search)([glRedemption.value]))
		)
	}

	clearSearchGlRedemption() {
		this.filteredGlRedemption.next(this.glRedemptionList.slice())
	}
}
