<span *ngIf="title" mat-dialog-title>
  {{ tableColumnName | translate  }}
  {{ title | translate }}
</span>

<mat-dialog-content>
  <div>
    <div class="table-content">
      <div class="pd-tbl-content">
        <label class="mat-header-cell">{{ tableColumnName | translate }}</label>
      </div>
      <div *ngIf="!!dataSource.length">
        <div class="pd-tbl-content flex-column bdr-top-1" *ngFor="let element of dataSource">
          <div class="grow-1">
            <label>{{ element.name }}</label>
          </div>
          <div class="align-center">
            <mat-icon *ngIf="element.exists === true" 
              class="material-icons-outlined" style="color:#087f23;">
              check_circle_outline
            </mat-icon>
            <mat-icon *ngIf="element.exists === false" 
              class="material-icons-outlined" [matTooltip]="errorMessage()"
              matTooltipPosition="left" style="color:#E53935;">
              report_problem
            </mat-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
<div><br /></div>
<div fxLayout="row" fxLayoutAlign="end">
  <button class="mat-raised-button mat-primary" (click)="submit()"
    fxFlexOffset="8px">{{ submitBtn | translate }}</button>
</div>