<mat-dialog-content>
  <!-- <form novalidate [ngrxFormState]="formState$"> -->
  <form novalidate>
    <mat-form-field>
      <mat-label>{{parameters.label}}</mat-label>
      <input matInput [formControl]="formState$" type="text" maxlength="40">
      <mat-error>{{ "ERROR.FIELD_CONTAIN_INVALID_CHARACTERS" | translate }}</mat-error>
    </mat-form-field>
  </form>
</mat-dialog-content>

<div fxLayout="row" fxLayoutAlign="end">
  <button class="mat-raised-button" (click)="cancel()">{{ 'ACTION.CANCEL' | translate }}</button>
  <button class="mat-raised-button mat-primary" fxFlexOffset="8px" [disabled]="isInvalidOrEqualToOldValue()" (click)="save()">{{ 'ACTION.SAVE' | translate }}</button>
</div>