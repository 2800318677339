import { createFeatureSelector, createSelector } from '@ngrx/store'
import * as RedeemRules from './redeem-rules.state'
import {SelectedRules} from './redeem-rules.state'

const redeemRules = createFeatureSelector<RedeemRules.State>('redeemRules')

export const getRedeemRules = createSelector(redeemRules, x => x)

export const getForm = createSelector(redeemRules, ({ redeemRulesForm }, id: string) => {
	const redeemRule: SelectedRules = redeemRulesForm.controls.rules.value
	return redeemRule && redeemRule[id] && redeemRule[id].form
})
