import { createAction, props } from '@ngrx/store'
import { ProductCatalogueListResponse } from 'src/app/models/catalogue-management/product-catalogue/product-catalogue-list'
import { ProductCatalogueViewResponse } from 'src/app/models/catalogue-management/product-catalogue/product-catalogue-view'
import { StatusListResponse } from 'src/app/models/catalogue-management/product-catalogue/product-catalogue-status-list'
import { RedemptionTypeListResponse } from 'src/app/models/catalogue-management/product-catalogue/product-catalogue-redemption-type-list'
import { RewardPoolAndGroup } from 'src/app/models/catalogue-management/product-catalogue/product-catalogue-reward-list'
import { GlRedemptionListResponse } from 'src/app/models/param-settings/gl-redemption/gl-redemption-list'
import { FulFilmentPartnerListDataResponse } from 'src/app/models/catalogue-management/product-catalogue/product-catalogue-fulfilment-partner-list'
import { ProductCatalogueImageUploadResponse, ProductCatalogueImageUploadRequest } from 'src/app/models/catalogue-management/product-catalogue/product-catalogue-image-upload'
import { ProductCategoryListResponse } from 'src/app/models/catalogue-management/product-catalogue/product-catalogue-product-category-list'
import { RewardPoolGroupViewResponse } from 'src/app/models/param-settings/reward-pool-group/reward-pool-group-view'
import { POLICY } from 'src/app/models/common/constant'

export const InitialState = createAction('[ProductCatalogue] InitialState')
export const SetId = createAction('[ProductCatalogue] SetId', props<{ id: number }>())

export const GoList = createAction('[ProductCatalogue] GoList')
export const GoView = createAction('[ProductCatalogue] GoView')
export const GoCreate = createAction('[ProductCatalogue] GoCreate')
export const GoUpdate = createAction('[ProductCatalogue] GoUpdate')

export const GoBackDialog = createAction('[ProductCatalogue] GoBackDialog', props<{ action: string }>())
export const CreateDialog = createAction('[ProductCatalogue] CreateDialog')
export const UpdateDialog = createAction('[ProductCatalogue] UpdateDialog')
export const DeleteDialog = createAction('[ProductCatalogue] DeleteDialog')
export const closeDialog = createAction('[ProductCatalogue] closeDialog')

export const RestError = createAction('[ProductCatalogue] RestError', props<{ message: string, manualThrow: boolean }>())

export const InitialLoadList = createAction('[ProductCatalogue] InitialLoadList', props<{ payload: POLICY }>())

export const StatusList = createAction('[ProductCatalogue] StatusList')
export const StatusListSuccess = createAction('[ProductCatalogue] StatusListSuccess', props<{ payload: StatusListResponse }>())

export const RedemptionTypeList = createAction('[ProductCatalogue] RedemptionTypeList')
export const RedemptionTypeListSuccess = createAction('[ProductCatalogue] RedemptionTypeListSuccess', props<{ payload: RedemptionTypeListResponse }>())

export const RewardPoolAndGroupList = createAction('[ProductCatalogue] RewardPoolAndGroupList', props<{ payload: POLICY }>())
export const RewardPoolAndGroupListSuccess = createAction('[ProductCatalogue] RewardPoolAndGroupListSuccess', props<{ payload: RewardPoolAndGroup[] }>())

export const GlRedemptionList = createAction('[ProductCatalogue] GlRedemptionList', props<{ payload: POLICY }>())
export const GlRedemptionListSuccess = createAction('[ProductCatalogue] GlRedemptionListSuccess', props<{ payload: GlRedemptionListResponse }>())

export const FulfilmentParnterList = createAction('[ProductCatalogue] FulfilmentParnterList', props<{ payload: POLICY }>())
export const FulfilmentParnterListSuccess = createAction('[ProductCatalogue] FulfilmentParnterListSuccess', props<{ payload: FulFilmentPartnerListDataResponse}>())

export const ProductCategoryList = createAction('[ProductCatalogue] ProductCategoryList')
export const ProductCategoryListSuccess = createAction('[ProductCatalogue] ProductCategoryListSuccess', props<{ payload: ProductCategoryListResponse}>())

export const List = createAction('[ProductCatalogue] List')
export const ListSuccess = createAction('[ProductCatalogue] ListSuccess', props<{ payload: ProductCatalogueListResponse }>())

export const View = createAction('[ProductCatalogue] View')
export const ViewSuccess = createAction('[ProductCatalogue] ViewSuccess', props<{ payload: ProductCatalogueViewResponse }>())

export const ResetPointsAndAmount = createAction('[ProductCatalogue] ResetPointsAndAmount')

export const Create = createAction('[ProductCatalogue] Create')
export const CreateSuccess = createAction('[ProductCatalogue] CreateSuccess', props<{ payload: string }>())

export const Update = createAction('[ProductCatalogue] Update')
export const UpdateSuccess = createAction('[ProductCatalogue] UpdateSuccess', props<{ payload: string }>())

export const Delete = createAction('[ProductCatalogue] Delete')
export const DeleteSuccess = createAction('[ProductCatalogue] DeleteSuccess', props<{ payload: string }>())

export const UploadProductCatalogueImage = createAction('[ProductCatalogue] UploadProductCatalogueImage', props<{ request: ProductCatalogueImageUploadRequest }>())
export const UploadProductCatalogueImageSuccess = createAction('[ProductCatalogue] UploadProductCatalogueImageSuccess', props<{ payload: ProductCatalogueImageUploadResponse }>())

export const OnChangeRewardPool = createAction('[ProductCatalogue] OnChangeRewardPool')
export const OnChangeRewardPoolGroup = createAction('[ProductCatalogue] OnChangeRewardPoolGroup')
export const OnChangeRewardPoolGroupSuccess = createAction('[ProductCatalogue] OnChangeRewardPoolGroupSuccess', props<{ payload: RewardPoolGroupViewResponse }>())

export const DuplicateDialog = createAction('[ProductCatalogue] DuplicateDialog')
export const Duplicate = createAction('[ProductCatalogue] Duplicate')
export const DuplicateSuccess = createAction('[ProductCatalogue] DuplicateSuccess', props<{ payload: string }>())
