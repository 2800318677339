import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material'
import { Router } from '@angular/router'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Action, Store } from '@ngrx/store'
import { Observable, of } from 'rxjs'
import { catchError, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators'
import { MessageDialogComponent } from 'src/app/components/common/dialog/message-dialog/message-dialog.component'
import { TextareaDialogComponent } from 'src/app/components/common/dialog/textarea-dialog/textarea-dialog.component'
import { getDialogConfig } from 'src/app/models/common/dialog'
import { Response } from 'src/app/models/common/http'
import { TaskCodeRequest, TaskCodeResponse, Worklist } from 'src/app/models/worklist/worklist'
import { WorklistService } from 'src/app/services/worklist/worklist.service'
import * as AppStore from 'src/app/store/'
import * as PointAdjustmentFileUploadAction from 'src/app/store/adjustment-function/point-adjustment-file-upload'
import * as CampaignAction from 'src/app/store/campaign-management/campaign'
import * as ConditionalRewardAction from 'src/app/store/campaign-management/conditional-reward'
import * as CommonAction from '../../common/common/common.actions'
import * as WorklistTask from './checker-maker-function.actions'
import * as CustomerManagementAction from 'src/app/store/customer-management'


@Injectable()
export class Effects {

	constructor(
		private action$: Actions,
		private router: Router,
		private dialog: MatDialog,
		private store: Store<AppStore.State>,
		private worklistService: WorklistService
	) { }

	InitialState$: Observable<Action> = createEffect(() => {
		return this.action$.pipe(ofType(WorklistTask.InitialState), tap(() => {
			this.router.navigate(['worklist/tasklist'])
		}))
	}, { dispatch: false }
	)

	GetWorklistTask$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(WorklistTask.GetWorklistTask),
			withLatestFrom(this.store.select(state => state.worklistTask)),
			mergeMap(([, s]) => this.worklistService.getTaskDetail({ id: s.task.id })
				.pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: Worklist = response.payload

					if (success) {
						return WorklistTask.GetWorklistTaskSuccess({ payload })
					} else { return WorklistTask.RestError(response as any) }
				}),
					catchError(this.catcher)
				)
			)
		)
	)

	ApproveWorklistTask$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(WorklistTask.ApproveWorklistTask),
			withLatestFrom(this.store.select(state => state.worklistTask)),
			mergeMap(([, y]) => this.worklistService.approveTask({ dataId: y.task.id })
				.pipe(map((response: Response) => {
					const success: boolean = response.success
					if (success) {
						return WorklistTask.ReturnToWhereItComeFrom()
					} else { return WorklistTask.RestError(response as any) }
				}),
					catchError(this.catcher)
				)
			)
		)
	)

	WithdrawWorklistTask$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(WorklistTask.WithdrawWorklistTask),
			withLatestFrom(this.store.select(state => state.worklistTask)),
			mergeMap(([, y]) => this.worklistService.withdrawTask({ dataId: y.task.id })
				.pipe(map((response: Response) => {
					const success: boolean = response.success
					if (success) {
						return WorklistTask.ReturnToWhereItComeFrom()
					} else { return WorklistTask.RestError(response as any) }
				}),
					catchError(this.catcher)
				)
			)
		)
	)

	RejectWorklistTask$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(WorklistTask.RejectWorklistTask),
			withLatestFrom(this.store.select(state => state.worklistTask)),
			mergeMap(([x, y]) => this.worklistService.rejectTask({ dataId: y.task.id, rejectReason: x.payload })
				.pipe(map((response: Response) => {
					const success: boolean = response.success
					if (success) {
						return WorklistTask.ReturnToWhereItComeFrom()
					} else { return WorklistTask.RestError(response as any) }
				}),
					catchError(this.catcher)
				)
			)
		)
	)

	GetCodeFromDataIdCampaign$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(WorklistTask.GetCodeFromDataIdCampaign),
			withLatestFrom(this.store.select(state => state.worklistTask)),
			mergeMap(([, s]) => {
				const params: TaskCodeRequest = { dataId: s.task.dataId, taskType: s.task.type }
				return this.worklistService.getDataCode(params)
					.pipe(map((response: Response) => {
						const success: boolean = response.success
						const payload: TaskCodeResponse = response.payload

						if (success) {
							return WorklistTask.GetCodeFromDataIdCampaignSuccess({ payload })
						} else { return WorklistTask.RestError(response as any) }
					}), catchError(this.catcher)
				)
			})
		)
	)

	GetCodeFromDataIdRepublishCampaign$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(WorklistTask.GetCodeFromDataIdRepublishCampaign),
			withLatestFrom(this.store.select(state => state.worklistTask)),
			mergeMap(([, s]) => {
				const params: TaskCodeRequest = { dataId: s.task.dataId, taskType: s.task.type }
				return this.worklistService.getDataCode(params)
					.pipe(map((response: Response) => {
						const success: boolean = response.success
						const payload: TaskCodeResponse = response.payload

						if (success) {
							return WorklistTask.GetCodeFromDataIdRepublishCampaignSuccess({ payload })
						} else { return WorklistTask.RestError(response as any) }
					}), catchError(this.catcher)
				)
			})
		)
	)

	GetCodeFromDataIdCampaignProductBundling$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(WorklistTask.GetCodeFromDataIdCampaignProductBundling),
			withLatestFrom(this.store.select(state => state.worklistTask)),
			mergeMap(([, s]) => {
				const params: TaskCodeRequest = { dataId: s.task.dataId, taskType: s.task.type }
				return this.worklistService.getDataCode(params)
					.pipe(map((response: Response) => {
						const success: boolean = response.success
						const payload: TaskCodeResponse = response.payload

						if (success) {
							return WorklistTask.GetCodeFromDataIdCampaignProductBundlingSuccess({ payload })
						} else { return WorklistTask.RestError(response as any) }
					}), catchError(this.catcher)
					)
			})
		)
	)

	GetCodeFromDataIdRepublishCampaignProductBundling$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(WorklistTask.GetCodeFromDataIdRepublishCampaignProductBundling),
			withLatestFrom(this.store.select(state => state.worklistTask)),
			mergeMap(([, s]) => {
				const params: TaskCodeRequest = { dataId: s.task.dataId, taskType: s.task.type }
				return this.worklistService.getDataCode(params)
					.pipe(map((response: Response) => {
						const success: boolean = response.success
						const payload: TaskCodeResponse = response.payload

						if (success) {
							return WorklistTask.GetCodeFromDataIdRepublishCampaignProductBundlingSuccess({ payload })
						} else { return WorklistTask.RestError(response as any) }
					}), catchError(this.catcher)
					)
			})
		)
	)

	GetCodeFromDataIdCampaignSuccess$: Observable<Action> = createEffect(() => {
		return this.action$.pipe(ofType(WorklistTask.GetCodeFromDataIdCampaignSuccess),
			withLatestFrom(this.store.select(state => state.worklistTask)),
			mergeMap(([, x]) => {
				return [CampaignAction.SetCode({ code: x.code }), CampaignAction.SetCheckerMakerId({ checkerMakerId: x.task.id}), CampaignAction.GoPublishedView({ action: 'VIEW' })]
			}))
	}, { dispatch: true }
	)

	GetCodeFromDataIdRepublishCampaignSuccess$: Observable<Action> = createEffect(() => {
		return this.action$.pipe(ofType(WorklistTask.GetCodeFromDataIdRepublishCampaignSuccess),
			withLatestFrom(this.store.select(state => state.worklistTask)),
			mergeMap(([, x]) => {
				return [CampaignAction.SetCode({ code: x.code }), CampaignAction.SetCheckerMakerId({ checkerMakerId: x.task.id}),
					x.from === 'my-request' ? CampaignAction.GoMyRequestCampaignRepublishedView({ action: 'VIEW' }) : CampaignAction.GoTasklistCampaignRepublishedView({ action: 'VIEW' })]
			}))
	}, { dispatch: true }
	)

	GetCodeFromDataIdCampaignProductBundlingSuccess$: Observable<Action> = createEffect(() => {
		return this.action$.pipe(ofType(WorklistTask.GetCodeFromDataIdCampaignProductBundlingSuccess),
			withLatestFrom(this.store.select(state => state.worklistTask)),
			mergeMap(([, x]) => {
				return [CampaignAction.SetCode({ code: x.code }), CampaignAction.SetCheckerMakerId({ checkerMakerId: x.task.id}), CampaignAction.GoPublishedViewProductBundle({ action: 'VIEW' })]
			}))
	}, { dispatch: true }
	)

	GetCodeFromDataIdRepublishCampaignProductBundlingSuccess$: Observable<Action> = createEffect(() => {
		return this.action$.pipe(ofType(WorklistTask.GetCodeFromDataIdRepublishCampaignProductBundlingSuccess),
			withLatestFrom(this.store.select(state => state.worklistTask)),
			mergeMap(([, x]) => {
				return [CampaignAction.SetCode({ code: x.code }), CampaignAction.SetCheckerMakerId({ checkerMakerId: x.task.id }),
					x.from === 'my-request' ? CampaignAction.GoMyRequestProductBundleRepublishedView({ action: 'VIEW' }) : CampaignAction.GoTasklistProductBundleRepublishedView({ action: 'VIEW' }) ]
			}))
	}, { dispatch: true }
	)

	SetIdAndGoToPointTransactionFileUpload$: Observable<Action> = createEffect(() => {
		return this.action$.pipe(ofType(WorklistTask.SetIdAndGoToPointTransactionFileUpload),
			withLatestFrom(this.store.select(state => state.worklistTask)),
			mergeMap(([, x]) => {
				return [
					PointAdjustmentFileUploadAction.SetId({ payload: x.task.dataId }),
					PointAdjustmentFileUploadAction.GoView()
				]
			}))
	}, { dispatch: true }
	)

	SetIdAndGoToConditionalReward$: Observable<Action> = createEffect(() => {
		return this.action$.pipe(ofType(WorklistTask.SetIdAndGoToConditionalReward),
			withLatestFrom(this.store.select(state => state.worklistTask)),
			mergeMap(([, x]) => {
				return [
					ConditionalRewardAction.SetId({ id: x.task.dataId }),
					ConditionalRewardAction.SetTierAction({ tierAction: 'VIEW' }),
					ConditionalRewardAction.GoPublishedView()
				]
			}))
	}, { dispatch: true }
	)

	SetIdAndGoToRepublishConditionalReward$: Observable<Action> = createEffect(() => {
		return this.action$.pipe(ofType(WorklistTask.SetIdAndGoToRepublishConditionalReward),
			withLatestFrom(this.store.select(state => state.worklistTask)),
			mergeMap(([, x]) => {
				return [
					ConditionalRewardAction.SetId({ id: x.task.dataId }),
					ConditionalRewardAction.SetCheckerMakerId({ checkerMakerId: x.task.id }),
					ConditionalRewardAction.SetTierAction({ tierAction: 'VIEW' }),
					ConditionalRewardAction.GoPublishedView()
				]
			}))
	}, { dispatch: true }
	)

	SetIdAndGoToPointsAdjustment$: Observable<Action> = createEffect(() => {
		return this.action$.pipe(ofType(WorklistTask.SetIdAndGoToPointsAdjustment),
			withLatestFrom(this.store.select(state => state.worklistTask)),
			mergeMap(([, x]) => {
				return [
					CustomerManagementAction.SetTaskId({ payload: x.task.dataId }),
					CustomerManagementAction.GoPointsAdjustmentView()
				]
			}))
	}, { dispatch: true }
	)

	SetIdAndGoToCatalogueRedemptionOrder$: Observable<Action> = createEffect(() => {
		return this.action$.pipe(ofType(WorklistTask.SetIdAndGoToCatalogueRedemptionOrder),
			withLatestFrom(this.store.select(state => state.worklistTask)),
			mergeMap(([, x]) => {
				return [
					CustomerManagementAction.SetRedemptionTransactionIdAndRedemptionType({ redemptionTransactionId: x.task.dataId }),
					CustomerManagementAction.SetTaskId({ payload: x.task.dataId }),
					CustomerManagementAction.CatalogueRedemptionOrderView(),
					x.from === 'my-request' ? CustomerManagementAction.GoCatalogueRedemptionMyRequestView() : CustomerManagementAction.GoCatalogueRedemptionTasklistView()
				]
			}))
	}, { dispatch: true }
	)

	ApproveDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(WorklistTask.ApproveDialog),
			mergeMap(() => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.APPROVE_TASK' }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return WorklistTask.ApproveWorklistTask()
				} else {
					return WorklistTask.CloseDialog()
				}
			})
		)
	)

	WithdrawDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(WorklistTask.WithdrawDialog),
			mergeMap(() => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.WITHDRAW_TASK' }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return WorklistTask.WithdrawWorklistTask()
				} else {
					return WorklistTask.CloseDialog()
				}
			})
		)
	)

	RejectReasonDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(WorklistTask.RejectReasonDialog),
			mergeMap(() => {
				const dialogRef = this.dialog.open(TextareaDialogComponent, getDialogConfig({ content: 'DIALOG.REJECT_REASON' }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return WorklistTask.RejectDialog({ payload: result.payload })
				} else {
					return WorklistTask.CloseDialog()
				}
			})
		)
	)

	RejectDialog$: Observable<Action> = createEffect(() => {
		return this.action$.pipe(
			ofType(WorklistTask.RejectDialog),
			mergeMap((x) => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.REJECT_TASK', payload: x.payload }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return WorklistTask.RejectWorklistTask({ payload: result.payload })
				} else {
					return WorklistTask.CloseDialog()
				}
			})
		)
	})

	ReturnToWhereItComeFrom$: Observable<Action> = createEffect(() => {
		return this.action$.pipe(ofType(WorklistTask.ReturnToWhereItComeFrom),
			withLatestFrom(this.store.select(state => state.worklistTask)),
			map(([, x]) => {
				this.router.navigate([`worklist/${x.from}`])
				return WorklistTask.WorklistTaskPerformSuccess()
			}))
	}, { dispatch: true }
	)

	CloseDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(WorklistTask.CloseDialog),
			tap(() => {
				this.dialog.closeAll()
			})
		), { dispatch: false }
	)

	RestError$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(WorklistTask.RestError),
			map(({ message, manualThrow }) => {
				return CommonAction.RestError({ message, manualThrow })
			})
		)
	)

	catcher = (error) => {
		return (of(WorklistTask.RestError({ message: error.message, manualThrow: error.manualThrow })))
	}
}
