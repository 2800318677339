import { POLICY } from './../../models/common/constant';
import { createAction, props } from '@ngrx/store'
import { FileDownload } from 'src/app/models/file-download/file-download'

export const InitialState = createAction('[FileDownload] InitialState')

export const Reset = createAction('[FileDownload] Reset')

export const RestError = createAction('[FileDownload] RestError', props<{ message: string, manualThrow: boolean }>())

export const List = createAction('[FileDownload] List', props<{ policyList: POLICY[] }>())
export const ListSuccess = createAction('[FileDownload] ListSuccess', props<{ payload: FileDownload[] }>())

export const DownloadFileSample = createAction('[FileDownload] DownloadFileSample', props<{ fileName: string }>())
export const DownloadFileSampleSuccess = createAction('[FileDownload] DownloadFileSampleSuccess')
