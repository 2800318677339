import { createFormGroupState, FormGroupState, updateGroup, validate, Boxed, box, unbox } from 'ngrx-forms'
import { maxLength, required } from 'ngrx-forms/validation'
import { FieldValidator } from 'src/app/models/util/field.validator'
import { WaiverManagementDataResponse } from 'src/app/models/redemption-settings/waiver-management/waiver-management-data'
import { WaiverManagementViewResponse } from 'src/app/models/redemption-settings/waiver-management/waiver-management-view'
import { WaiverManagement } from 'src/app/models/redemption-settings/waiver-management/waiver-management'
import { WaiverRewardPoolView } from 'src/app/models/redemption-settings/waiver-management/waiver-reward-pool'
export interface State {
	isLoading: boolean
	id?: number
	redemptionId?: string
	action?: string

	// Listing
	waiverManagementList: WaiverManagement[]
	// View
	waiverMangementViewResponse: WaiverManagementViewResponse
	assignedWaiverForm: FormGroupState<AssignedWaiverForm>
	// Detail
	waiverManagementDetailForm: FormGroupState<WaiverManagementDetailForm>
	// option list
	waiverManagementData: WaiverManagementDataResponse

	waiverManagementFormView: WaiverManagementDetailForm
	assignedWaiverFormView: AssignedWaiverForm
	assignedWaiverDuplicateForm: FormGroupState<AssignedWaiverDuplicateForm>
	isDuplicateLoading: boolean
	isDuplicateDialog: boolean
}

export const initialWaiverMangementViewResponse: WaiverManagementViewResponse = {
	id: 0,
	code: '',
	name: '',
	description: '',
	assignedWaiverList: [],
	createdBy: '',
	createdDate: '',
	modifiedBy: '',
	modifiedDate: ''
}

export interface WaiverManagementDetailForm {
	code: string
	name: string
	description: string
}

export const initialWaiverManagementDetailFormValue: WaiverManagementDetailForm = {
	code: '',
	name: '',
	description: ''
}

export const initialWaiverManagementDetailForm = createFormGroupState('waiverManagementDetailForm', initialWaiverManagementDetailFormValue)

export const validateWaiverManagementDetailForm = updateGroup<WaiverManagementDetailForm>({
	code: validate([required, FieldValidator.alphaNumericWithoutSpaces(), maxLength(10)]),
	name: validate([required, maxLength(40), FieldValidator.checkValidCharacters()]),
	description: validate([maxLength(100), FieldValidator.checkValidCharacters()])
})

export interface AssignedWaiverForm {
	id: string
	code: string
	name: string
	rewardPoolType: string
	rewardPoolId: string
	productTypeId: Boxed<string[]>
	productTypeGroupId: Boxed<string[]>
	cardHolder: string
	points: string
	glRedemptionId: number
	transactionCode: string
	transactionAmount: string
	transactionDescription: string
	rewardPoolAndGlRedemption: WaiverRewardPoolView[]
	waiverRewardPools: WaiverRewardPools
	createdBy: string
	createdDate: string
	modifiedBy: string
	modifiedDate: string
	feeTc: Boxed<string[]>
	feeRedemptionTc: Boxed<string[]>
}
export interface WaiverRewardPools {
	byId: { [key: string]: WaiverRewardPool }
	allIds: string[]
}

export interface WaiverRewardPool {
	id: string
	form: FormGroupState<WaiverRewardPoolForm>
}

export const initialAssignedWaiverFormValue: AssignedWaiverForm = {
	id: '',
	code: '',
	name: '',
	rewardPoolType: '',
	rewardPoolId: '',
	productTypeId: box([]),
	productTypeGroupId: box([]),
	cardHolder: '',
	points: '',
	glRedemptionId: null,
	transactionCode: '',
	transactionAmount: '',
	transactionDescription: '',
	rewardPoolAndGlRedemption: [],
	waiverRewardPools: {
		byId: {},
		allIds: []
	},
	createdBy: '',
	createdDate: '',
	modifiedBy: '',
	modifiedDate: '',
	feeTc: box([]),
	feeRedemptionTc: box([])
}

export const initialAssignedWaiverForm = createFormGroupState('assignedWaiverForm', initialAssignedWaiverFormValue)

export const validateAssignedWaiverForm = (state: State) => {
	let productTypeCondition = true

	if (state && (unbox(state.assignedWaiverForm.controls.productTypeId.value).toString() ||
		unbox(state.assignedWaiverForm.controls.productTypeGroupId.value).toString())) {
		productTypeCondition = false
	}

	return updateGroup<AssignedWaiverForm>({
		code: validate([required, FieldValidator.alphaNumericWithoutSpaces(), maxLength(10)]),
		name: validate([required, maxLength(40), FieldValidator.checkValidCharacters()]),
		rewardPoolId: validate([required]),
		productTypeId: validate([value => { if (productTypeCondition) { return required(unbox(value)) } }]),
		productTypeGroupId: validate([value => { if (productTypeCondition) { return required(unbox(value)) } }]),
		glRedemptionId: validate([FieldValidator.checkRewardPoolType(state.assignedWaiverForm.value.rewardPoolId && state.assignedWaiverForm.value.rewardPoolId.split('_')[0])]),
		cardHolder: validate([required]),
		points: validate([required, FieldValidator.digit(), maxLength(10)]),
		transactionCode: validate([required, FieldValidator.digit(), maxLength(4)]),
		transactionAmount: validate([required, FieldValidator.integerAndDecimalCheck(8, 2)]),
		transactionDescription: validate([required, maxLength(25), FieldValidator.checkValidCharacters()])
	})
}

export interface WaiverRewardPoolForm {
	rewardPoolId: string
	rewardPoolName: string
	glRedemptionId: number
}

export const initialWaiverRewardPoolFormValue: WaiverRewardPoolForm = {
	rewardPoolId: '',
	rewardPoolName: '',
	glRedemptionId: null
}

export const initialWaiverRewardPoolForm = createFormGroupState('waiverRewardPoolForm', initialWaiverRewardPoolFormValue)

export const validateWaiverRewardPoolForm = updateGroup<WaiverRewardPoolForm>({
	glRedemptionId: validate([required])
})

export interface AssignedWaiverDuplicateForm {
	code: string
	name: string
}

export const initialAssignedWaiverDuplicateFormValue: AssignedWaiverDuplicateForm = {
	code: '',
	name: ''
}

export const initialAssignedWaiverDuplicateForm = createFormGroupState('assignedWaiverDuplicateForm', initialAssignedWaiverDuplicateFormValue)

export const validateAssignedWaiverDuplicateForm = updateGroup<AssignedWaiverDuplicateForm>({
	code: validate([required, FieldValidator.alphaNumericWithoutSpaces(), maxLength(10)]),
	name: validate([required, maxLength(40), FieldValidator.checkValidCharacters()]),
})

export const initialState: State = {
	isLoading: false,
	waiverManagementList: [],
	waiverMangementViewResponse: initialWaiverMangementViewResponse,
	assignedWaiverForm: initialAssignedWaiverForm,
	waiverManagementDetailForm: initialWaiverManagementDetailForm,
	waiverManagementData: {
		rewardPoolList: [],
		productTypeList: [],
		productTypeGroupList: [],
		cardHolderList: [],
		glRedemptionList: [],
		tcGroupList: []
	},
	waiverManagementFormView: initialWaiverManagementDetailFormValue,
	assignedWaiverFormView: initialAssignedWaiverFormValue,
	assignedWaiverDuplicateForm: initialAssignedWaiverDuplicateForm,
	isDuplicateLoading: false,
	isDuplicateDialog: false
}
