<div *ngIf="isView">
	<mat-expansion-panel [ngClass]="'mat-box-container'" [expanded]="true" hideToggle="true" fxFlex>
		<mat-expansion-panel-header>
			<div fxLayout="row" fxLayoutAlign="space-between center" fxFlex>
				<span>{{ 'REDEEM_RULES.CUSTOMER_STATUS' | translate }}</span>
			</div>
		</mat-expansion-panel-header>

		<div fxLayout="column" fxLayoutGap="10px">
			<mat-form-field fxFlex>
				<input matInput
					   value="{{ (dataState$ | async).customerStatusFlag }}"
					   type="text" readonly>
			</mat-form-field>

			<mat-form-field fxFlex>
				<mat-label>{{ 'REDEEM_RULES.CUSTOMER_STATUS' | translate }}</mat-label>
				<input matInput value="{{ (dataState$ | async).customerStatus }}"
					   type="text" readonly>
			</mat-form-field>
		</div>
	</mat-expansion-panel>
</div>
<form novalidate [ngrxFormState]="(formState$ | async)" fxLayout="column" fxLayoutGap="10px">
	<div *ngIf="!isView">
		<mat-expansion-panel [ngClass]="'mat-box-container'" [expanded]="true" hideToggle="true" fxFlex>
			<mat-expansion-panel-header>
				<div fxLayout="row" fxLayoutAlign="space-between center" fxFlex>
					<span>{{ 'REDEEM_RULES.CUSTOMER_STATUS' | translate }}</span>
					<mat-icon class="mat-icon-pointer material-icons-outlined"
							  (click)="removeRule('CUSTOMER_STATUS', rewardPool.value)">close
					</mat-icon>
				</div>
			</mat-expansion-panel-header>

			<div fxLayout="column" fxLayoutGap="10px">
				<mat-radio-group fxLayout="row" fxLayout.lt-md="row" fxLayoutGap="20px" fxLayoutGap.lt-md="20px"
								 [ngrxFormControlState]="(formState$ | async).controls.customerStatusFlag">
					<mat-radio-button *ngFor="let i of (dataState$ | async).includeExcludeList"
									  [value]="i.key">{{i.value}}
					</mat-radio-button>
				</mat-radio-group>

				<mat-form-field fxFlex>
					<mat-label>{{ 'REDEEM_RULES.CUSTOMER_STATUS' | translate }}</mat-label>
					<mat-icon class="material-icons-outlined" matSuffix
							  matTooltip="{{ 'REDEEM_RULES.INFO.CUSTOMER_STATUS' | translate }}">
						info
					</mat-icon>
					<input matInput [ngrxFormControlState]="(formState$ | async).controls.customerStatus"
						   type="text"
						   maxlength="500">
					<mat-error *ngIf="(formState$ | async).errors._customerStatus">
						<app-error-validation [errors]="(formState$ | async).errors._customerStatus"
											  [maxlength]="500">
						</app-error-validation>
					</mat-error>
				</mat-form-field>
			</div>
		</mat-expansion-panel>
	</div>
</form>
