import { createAction, props } from '@ngrx/store'
import { PolicyListResponse } from 'src/app/models/access-management/policy/policy-list'

export const InitialState = createAction('[Policy] InitialState')
export const Reset = createAction('[Policy] Reset')
export const SetId = createAction('[Policy] SetId', props<{ id: string }>())

export const RestError = createAction('[Policy] RestError', props<{ message: string, manualThrow: boolean }>())

export const List = createAction('[Policy] List')
export const ListSuccess = createAction('[Policy] ListSuccess', props<{ payload: PolicyListResponse }>())
