import {Component, Inject, OnDestroy} from '@angular/core'
import {Observable, Subscription} from 'rxjs'
import {FormGroupState} from 'ngrx-forms'
import {FulfilmentPartnerDuplicateForm} from '../../../../store/catalogue-management/fulfilment-partner'
import {Store} from '@ngrx/store'
import * as AppStore from 'src/app/store'
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material'
import * as FulfilmentPartnerSelectors from 'src/app/store/catalogue-management/fulfilment-partner/fulfilment-partner.selectors'
import * as FulfilmentPartnerAction from 'src/app/store/catalogue-management/fulfilment-partner/fulfilment-partner.actions'

@Component({
	selector: 'app-fulfilment-partner-duplicate',
	templateUrl: './fulfilment-partner-duplicate.component.html',
	styleUrls: ['./fulfilment-partner-duplicate.component.scss']
})
export class FulfilmentPartnerDuplicateComponent implements OnDestroy {

	isLoading = false
	formState$: Observable<FormGroupState<FulfilmentPartnerDuplicateForm>>

	subs: Subscription

	constructor(
		private store: Store<AppStore.State>,
		private dialogRef: MatDialogRef<FulfilmentPartnerDuplicateComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		this.subs = this.store.select(FulfilmentPartnerSelectors.getFulfilmentPartner).subscribe(({isDuplicateLoading, isDuplicateDialog}) => {
			this.isLoading = isDuplicateLoading
			if (isDuplicateDialog) {
				this.cancel()
			}
		})

		this.formState$ = this.store.select(({fulfilmentPartner}) => fulfilmentPartner.fulfilmentPartnerDuplicateForm)
	}

	ngOnDestroy() {
		this.subs.unsubscribe()
	}

	cancel() {
		this.dialogRef.close()
	}

	submit() {
		this.store.dispatch(FulfilmentPartnerAction.Duplicate())
	}
}
