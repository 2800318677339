import { Action, createReducer, on } from '@ngrx/store'
import { formGroupReducer, formStateReducer, reset, setValue, SetValueAction } from 'ngrx-forms'
import * as UserAction from './user.actions'
import * as UserState from './user.state'

export const reducer = createReducer(
	UserState.initialState,
	on(UserAction.InitialState, () => UserState.initialState),
	on(UserAction.Reset, state => ({
		...state,
		userListForm: reset(setValue(state.userListForm, UserState.initialUserListFormValue))
	})),
	on(UserAction.SetUserId, (state, { userId }) => ({
		...state,
		userId
	})),
	on(UserAction.IsUserPage, (state, { isUserPage }) => ({
		...state,
		isUserPage
	})),
	on(UserAction.RestError, state => ({
		...state,
		isLoading: false
	})),
	on(UserAction.List, state => ({
		...state,
		isLoading: true,
	})),
	on(UserAction.ListSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		userListResponse: payload
	})),
	on(UserAction.View, state => ({
		...state,
		isLoading: true,
		groupListResult: [],
		userDetailForm: setValue(state.userDetailForm, UserState.initialUserDetailFormValue)
	})),
	on(UserAction.ViewSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		userViewResponse: {
			userId: payload.userId,
			username: payload.username,
			email: payload.email,
			modifiedBy: payload.modifiedBy,
			modifiedDate: payload.modifiedDate,
			groups: payload.groups
		}
	})),
	on(UserAction.RemoveGroupFromUser, state => ({
		...state,
		isLoading: true,
	})),
	on(UserAction.RemoveGroupFromUserSuccess, state => ({
		...state,
		isLoading: false,
	})),
	on(UserAction.AssignGroupList, state => ({
		...state,
		isLoading: true
	})),
	on(UserAction.AssignGroupListSuccess, (state, { payload }) => {
		return ({
			...state,
			isLoading: false,
			groups: payload.groups.map(x => ({
				...x,
				keyValue: { key: x.groupId.toString(), value: x.name }
			}))
		})
	}),
	on(UserAction.OpenConfirmationDialog, state => ({
		...state,
		isTableLoading: true
	})),
	on(UserAction.SuccessGroupToUser, (state, { groupId }) => {
		const groupResult = {
			success: true,
			payload: state.groups.find(item => groupId === item.groupId).name
		}
		return ({
			...state,
			isTableLoading: false,
			groupListResult: state.groupListResult.concat(groupResult)
		})
	}),
	on(UserAction.FailedGroupToUser, (state, { groupId, message }) => {
		const groupResult = {
				success: false,
				payload: state.groups.find(item => groupId === item.groupId).name,
				error: message
		}
		return ({
			...state,
			isTableLoading: false,
			groupListResult: state.groupListResult.concat(groupResult)
		})
	})
)


export function Reducer(state: UserState.State = UserState.initialState, action: Action) {
	const userListForm = UserState.validateUserListForm(formGroupReducer(state.userListForm, action))
	if (userListForm !== state.userListForm) {
		state = { ...state, userListForm }
	}

	const userDetailForm = UserState.validateUserDetailForm(formGroupReducer(state.userDetailForm, action))
	if (userDetailForm !== state.userDetailForm) {
		state = { ...state, userDetailForm }
	}
	return reducer(state, action)
}
