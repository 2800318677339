<app-spinner [spinnerType]="'COMPONENT'" [isLoading]="isLoading"></app-spinner>
<form novalidate [ngrxFormState]="formState$ | async" fxLayout="column" fxFlex>
    <div fxLayout="row" fxLayout.lt-md="outline" fxLayoutGap="20px" fxLayoutGap.lt-md="0">
        <span class="mat-h3" style="width: 100%;">{{ 'CUSTOMER_MANAGEMENT.REDEMPTION_HISTORY_DETAILS' | translate}}</span>

        <button mat-icon-button class="close-button" [mat-dialog-close]="true">
            <mat-icon class="close-icon">close</mat-icon>
        </button>
    </div>

    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0">
        <mat-form-field fxFlex="50" *ngIf="(formState$ | async).redemptionType !== eCatalogueRedemptionType.POINT">
            <mat-label>{{ 'CUSTOMER_MANAGEMENT.CARD_NUMBER' | translate }}</mat-label>
            <input matInput value="{{(formState$ | async).cardNumber}}" type="text" readonly>
        </mat-form-field>

        <mat-form-field fxFlex="50" *ngIf="(formState$ | async).redemptionType !== eCatalogueRedemptionType.POINT">
            <mat-label>{{ 'CUSTOMER_MANAGEMENT.REWARD_POOL_OR_REWARD_POOL_GROUP' | translate }}</mat-label>
            <input matInput value="{{ (formState$ | async).rewardPoolAndGroupValue }}" type="text"
                readonly>
        </mat-form-field>

        <mat-form-field *ngIf="(formState$ | async).redemptionType === eCatalogueRedemptionType.POINT" fxFlex>
            <mat-label>{{ 'CUSTOMER_MANAGEMENT.REWARD_POOL_OR_REWARD_POOL_GROUP' | translate }}</mat-label>
            <input matInput value="{{ (formState$ | async).rewardPoolAndGroupValue }}" type="text"
                readonly>
        </mat-form-field>
    </div>

    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0">
        <mat-form-field fxFlex="50">
            <mat-label>{{ 'CUSTOMER_MANAGEMENT.PRODUCT_CATALOGUE' | translate }}</mat-label>
            <input matInput value="{{(formState$ | async).productCodeAndName}}" type="text" readonly>
        </mat-form-field>

        <mat-form-field fxFlex="50">
            <mat-label>{{ 'CUSTOMER_MANAGEMENT.QUANTITY' | translate }}</mat-label>
            <input matInput value="{{(formState$ | async).quantity}}" type="text" readonly>
        </mat-form-field>
    </div>

    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0">
        <mat-form-field fxFlex="50">
            <mat-label>{{ 'CUSTOMER_MANAGEMENT.TOTAL_POINTS' | translate }}</mat-label>
            <input matInput value="{{(formState$ | async).totalPoints}}" type="text" readonly>
        </mat-form-field>

        <mat-form-field fxFlex="50">
            <mat-label>{{ 'CUSTOMER_MANAGEMENT.TOTAL_AMOUNT' | translate }}</mat-label>
            <input matInput value="{{(formState$ | async).totalAmount}}" type="text" readonly>
        </mat-form-field>
    </div>

    <mat-expansion-panel class="mat-box-container" [expanded]="true" [hideToggle]="true">
        <br />
        <div *ngIf="(formState$ | async).productCategory === 'G'">
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="5px" fxLayoutGap.lt-md="0px">
                <mat-form-field fxFlex="100">
                    <mat-label>{{ 'CUSTOMER_MANAGEMENT.ADDRESS' | translate }}</mat-label>
                    <input matInput value="{{ (formState$ | async).address }}" type="text" readonly>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="5px" fxLayoutGap.lt-md="0px">
                <mat-form-field fxFlex="50">
                    <mat-label>{{ 'CUSTOMER_MANAGEMENT.CITY' | translate }}</mat-label>
                    <input matInput value="{{ (formState$ | async).city }}" type="text" readonly>
                </mat-form-field>

                <mat-form-field fxFlex="50">
                    <mat-label>{{ 'CUSTOMER_MANAGEMENT.STATE' | translate }}</mat-label>
                    <input matInput value="{{ (formState$ | async).state }}" type="text" readonly>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="0px" fxLayoutGap.lt-md="0px">
                <mat-form-field fxFlex="50">
                    <mat-label>{{ 'CUSTOMER_MANAGEMENT.POSTCODE' | translate }}</mat-label>
                    <input matInput value="{{ (formState$ | async).postcode }}" type="text" readonly>
                </mat-form-field>
            </div>
        </div>

        <div *ngIf="(formState$ | async).productCategory === 'V'">
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="20px">
                <mat-form-field fxFlex="100">
                    <mat-label>{{ 'CUSTOMER_MANAGEMENT.EMAIL_ADDRESS' | translate }}</mat-label>
                    <input matInput value="{{ (formState$ | async).emailAddress }}" type="text" readonly>
                </mat-form-field>
            </div>
        </div>
    </mat-expansion-panel>

    <div *ngIf="(formState$ | async).allowWithdraw && loginUser.authorities.includes('REDEMPTION_CANCELLATION')" style="text-align: right; margin-top: 0.7em;">
        <button (click)="withdraw()" class="function-btn" [class.spinner]="isLoading" [disabled]="isLoading" mat-raised-button color="warn"
            type="button">
            {{ 'ACTION.WITHDRAW' | translate }}
        </button>
    </div>
</form>