import { Action, createReducer, on } from '@ngrx/store'
import {
	box,
	createFormGroupState,
	formGroupReducer,
	FormGroupState,
	reset,
	setValue,
	SetValueAction
} from 'ngrx-forms'
import * as RedeemRulesAction from './redeem-rules.actions'
import * as RedeemRulesState from './redeem-rules.state'
import { initialRPGRule, RedeemRuleForm, SelectedRules } from './redeem-rules.state'
import * as NgrxForms from 'ngrx-forms'
import { RedeemRuleEntity } from '../../../models/common/constant'
import { RedeemRulesEntity } from '../../../models/redemption-settings/redeem-rules/redeem-rules'
import { Option } from '../../../models/option/option'
import * as R from 'ramda'

export const reducer = createReducer(
	RedeemRulesState.initialState,
	NgrxForms.onNgrxFormsAction(SetValueAction, (state, action) => {
		return actionHandler(state, action)
	}),
	NgrxForms.onNgrxFormsAction(NgrxForms.MarkAsTouchedAction, (state, action) => {
		return actionHandler(state, action)
	}),
	on(RedeemRulesAction.InitialState, () => RedeemRulesState.initialState),
	on(RedeemRulesAction.RestError, state => ({
		...state,
		isLoading: false
	})),
	on(RedeemRulesAction.List, state => ({
		...state,
		isLoading: true
	})),
	on(RedeemRulesAction.ListSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		redeemRulesList: payload
	})),
	on(RedeemRulesAction.SetId, (state, { id }) => ({
		...state,
		id
	})),
	on(RedeemRulesAction.GoCreate, RedeemRulesAction.GoView, state => ({
		...state,
		redeemRulesForm: reset(setValue(state.redeemRulesForm, RedeemRulesState.initialRedeemRulesFormValue))
	})),
	on(RedeemRulesAction.View, state => ({
		...state,
		isLoading: true,
		redeemRulesViewResponse: RedeemRulesState.initialRedeemRulesViewResponse,
		redeemRulesView: RedeemRulesState.initialRedeemRulesFormValue
	})),
	on(RedeemRulesAction.ViewSuccess, (state, { payload }) => {
		let rules: SelectedRules = {}
		payload.redeemRuleDetail.forEach(o => {
			let ruleList: string[] = []

			const customerStatusRuleJson: RedeemRulesEntity = JSON.parse(o.customerStatus)
			const customerBlockCodeRuleJson: RedeemRulesEntity = JSON.parse(o.customerBlockCode)
			const accountStatusRuleJson: RedeemRulesEntity = JSON.parse(o.accountStatus)
			const accountBlockCodeRuleJson: RedeemRulesEntity = JSON.parse(o.accountBlockCode)
			const cardStatusRuleJson: RedeemRulesEntity = JSON.parse(o.cardStatus)
			const cardBlockCodeRuleJson: RedeemRulesEntity = JSON.parse(o.cardBlockCode)

			if (customerBlockCodeRuleJson.includeExcludeFlag !== '') {
				ruleList = ruleList.concat(RedeemRuleEntity.CUSTOMER_BLOCK_CODE)
			}
			if (customerStatusRuleJson.includeExcludeFlag !== '') {
				ruleList = ruleList.concat(RedeemRuleEntity.CUSTOMER_STATUS)
			}
			if (accountStatusRuleJson.includeExcludeFlag !== '') {
				ruleList = ruleList.concat(RedeemRuleEntity.ACCOUNT_STATUS)
			}
			if (accountBlockCodeRuleJson.includeExcludeFlag !== '') {
				ruleList = ruleList.concat(RedeemRuleEntity.ACCOUNT_BLOCK_CODE)
			}
			if (cardStatusRuleJson.includeExcludeFlag !== '') {
				ruleList = ruleList.concat(RedeemRuleEntity.CARD_STATUS)
			}
			if (cardBlockCodeRuleJson.includeExcludeFlag !== '') {
				ruleList = ruleList.concat(RedeemRuleEntity.CARD_BLOCK_CODE)
			}

			rules = {
				...rules,
				[o.rewardPool]: {
					keyValue: [],
					rules: ruleList,
					form: createFormGroupState<RedeemRulesState.RedeemRulesDetail>(o.rewardPool, {
						rewardPoolId: o.rewardPoolId,
						customerBlockCodeFlag: customerBlockCodeRuleJson.includeExcludeFlag,
						customerBlockCode: customerBlockCodeRuleJson.entity,
						customerStatusFlag: customerStatusRuleJson.includeExcludeFlag,
						customerStatus: customerStatusRuleJson.entity,
						accountBlockCodeFlag: accountBlockCodeRuleJson.includeExcludeFlag,
						accountBlockCode: accountBlockCodeRuleJson.entity,
						accountStatusFlag: accountStatusRuleJson.includeExcludeFlag,
						accountStatus: accountStatusRuleJson.entity,
						cardBlockCodeFlag: cardBlockCodeRuleJson.includeExcludeFlag,
						cardBlockCode: cardBlockCodeRuleJson.entity,
						cardStatusFlag: cardStatusRuleJson.includeExcludeFlag,
						cardStatus: cardStatusRuleJson.entity
					})
				}
			}
		})

		const redeemRulesView = {
			code: payload.code,
			name: payload.name,
			rewardPoolType: '',
			channel: payload.channel,
			cardholderTypes: payload.cardholderTypes ? box(payload.cardholderTypes.split(',')) : box([]),
			rules,
			rewardPool: box(payload.rewardPool.split(',')),
			rewardPoolGroup: '',
			customerBlockCodeFlag: '',
			customerBlockCode: '',
			customerStatusFlag: '',
			customerStatus: '',
			accountBlockCodeFlag: '',
			accountBlockCode: '',
			accountStatusFlag: '',
			accountStatus: '',
			cardBlockCodeFlag: '',
			cardBlockCode: '',
			cardStatusFlag: '',
			cardStatus: '',
		}

		return ({
			...state,
			isLoading: false,
			redeemRulesViewResponse: payload,
			redeemRulesView,
			redeemRulesForm: setValue(state.redeemRulesForm, redeemRulesView as RedeemRulesState.RedeemRuleForm)
		})
	}),
	on(RedeemRulesAction.Create, state => ({
		...state,
		isLoading: true
	})),
	on(RedeemRulesAction.CreateSuccess, state => ({
		...state,
		isLoading: false
	})),
	on(RedeemRulesAction.Update, state => ({
		...state,
		isLoading: true
	})),
	on(RedeemRulesAction.UpdateSuccess, state => ({
		...state,
		isLoading: false
	})),
	on(RedeemRulesAction.Delete, state => ({
		...state,
		isLoading: true
	})),
	on(RedeemRulesAction.DeleteSuccess, () => RedeemRulesState.initialState),
	on(RedeemRulesAction.GetRedeemRulesData, state => ({
		...state,
		isLoading: true
	})),
	on(RedeemRulesAction.GetRewardPoolGroupRewardPoolList, state => ({
		...state,
		isLoading: true
	})),
	on(RedeemRulesAction.GetRewardPoolGroupRewardPoolListSuccess, (state, { payload }) => {
		const rewardPoolList = payload.map(i => i.value)
		const existingRules: SelectedRules = state.redeemRulesForm.value.rules
		const rpList = Object.keys(existingRules)
		const rules = { ...existingRules }

		rpList.forEach(rp => {
			if (!rewardPoolList.includes(rp) && rp !== 'Reward Pool') {
				R.dissoc(rp, rules)
			}
		})

		const rewardPoolGroupRewardPoolList: Option[] = [...state.rewardPoolGroupRewardPoolList].filter(i => payload.map(o => o.key).includes(i.key))
			.concat(payload.filter(i => ![...state.rewardPoolGroupRewardPoolList].map(o => o.key).includes(i.key)))

		return {
			...state,
			isLoading: false,
			rewardPoolGroupRewardPoolList,
			redeemRulesForm: setValue(state.redeemRulesForm, {
				...state.redeemRulesForm.value,
				rules
			})
		}
	}),
	on(RedeemRulesAction.GetRedeemRulesSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		redeemRulesData: payload
	})),
	on(RedeemRulesAction.SelectRule, (state, { payload, params, filteredRulesKey }) => {
		const existingRules = state.redeemRulesForm.controls.rules.value[params.value] || initialRPGRule
		const rewardPoolId = params.key.split('_')

		return {
			...state,
			redeemRulesForm: setValue(state.redeemRulesForm, {
				...state.redeemRulesForm.value,
				rules: {
					...state.redeemRulesForm.value.rules,
					[params.value]: {
						...state.redeemRulesForm.controls.rules.value[params.value],
						rules: (existingRules.rules || []).concat(payload.key),
						keyValue: existingRules.keyValue,
						form: getForm(params.value, rewardPoolId.length > 0 ? Number(rewardPoolId[1]) : null, existingRules.form)
					}
				}
			}),
			filteredRulesKey
		}
	}),
	on(RedeemRulesAction.RemoveRule, (state, { payload, params }) => {
		const existingRules = state.redeemRulesForm.controls.rules.value[params] || initialRPGRule
		const existingRuleList = (existingRules.rules || []).filter(d => d !== payload)
		const updatedRedeemRuleForm = ruleChecker(state, payload, existingRules.form)
		const rules = ((redeemRuleForm: RedeemRuleForm) => {
			if (existingRuleList.length !== 0) {
				const form = setValue(redeemRuleForm.rules[params].form, updatedRedeemRuleForm)
				RedeemRulesState.validateRedeemRulesDetailForm(form)
				return {
					...redeemRuleForm.rules,
					[params]: {
						...redeemRuleForm.rules[params],
						rules: existingRuleList,
						keyValue: existingRules.keyValue,
						form
					}
				}
			} else {
				const { [params]: remove, ...rest } = redeemRuleForm.rules
				return rest
			}
		})(state.redeemRulesForm.value)

		return {
			...state,
			redeemRulesForm: RedeemRulesState.validateRedeemRulesForm(state)(setValue(state.redeemRulesForm, {
				...state.redeemRulesForm.value,
				rules
			}))
		}
	}),
	on(RedeemRulesAction.OnChangeRewardPoolTypeRadio, (state, { payload }) => ({
		...state,
		rewardPoolGroupRewardPoolList: [],
		redeemRulesForm: setValue(state.redeemRulesForm, {
			...state.redeemRulesForm.value,
			channel: '',
			rewardPool: box([]),
			cardholderTypes: box([]),
			rules: {},
			rewardPoolType: payload
		})
	})),
	on(RedeemRulesAction.OnChangeChannel, state => ({
		...state,
		rewardPoolGroupRewardPoolList: [],
		redeemRulesForm: setValue(state.redeemRulesForm, {
			...state.redeemRulesForm.value,
			cardholderTypes: box([]),
			rules: {}
		})
	}))
)

function getForm(key: string, id?: number, form?: FormGroupState<RedeemRulesState.RedeemRulesDetail>): FormGroupState<RedeemRulesState.RedeemRulesDetail> {
	return createFormGroupState<RedeemRulesState.RedeemRulesDetail>(key, {
		rewardPoolId: id,
		customerBlockCodeFlag: form ? form.value.customerBlockCodeFlag : '',
		customerBlockCode: form ? form.value.customerBlockCode : '',
		customerStatusFlag: form ? form.value.customerStatusFlag : '',
		customerStatus: form ? form.value.customerStatus : '',
		accountBlockCodeFlag: form ? form.value.accountBlockCodeFlag : '',
		accountBlockCode: form ? form.value.accountBlockCode : '',
		accountStatusFlag: form ? form.value.accountStatusFlag : '',
		accountStatus: form ? form.value.accountStatus : '',
		cardBlockCodeFlag: form ? form.value.cardBlockCodeFlag : '',
		cardBlockCode: form ? form.value.cardBlockCode : '',
		cardStatusFlag: form ? form.value.cardStatusFlag : '',
		cardStatus: form ? form.value.cardStatus : ''
	})
}

export function Reducer(state: RedeemRulesState.State, action: Action) {
	const existingState = state || RedeemRulesState.initialState
	let updatedState = state || RedeemRulesState.initialState
	const redeemRulesForm = RedeemRulesState.validateRedeemRulesForm(existingState)(formGroupReducer(existingState.redeemRulesForm, action))

	if (redeemRulesForm !== existingState.redeemRulesForm) {
		updatedState = { ...existingState, redeemRulesForm }
	}

	return reducer(updatedState, action)
}

function actionHandler(state: RedeemRulesState.State, action: SetValueAction<unknown> | NgrxForms.MarkAsTouchedAction) {
	const formControls = state.redeemRulesForm
	const controlId = action.controlId.split('.')[0]
	let updateState: RedeemRulesState.State = { ...state }
	let form = formGroupReducer(formControls, action)

	if (action.type === 'ngrx/forms/SET_VALUE') {
		form = formGroupReducer(formControls, new SetValueAction(action.controlId, action.value))
	}

	if (form) {
		updateState = {
			...state,
			redeemRulesForm: form
		}
	}

	let redeemPartnerRewardPoolsVal = formControls.value.rules

	const redeemRulesDetail = redeemPartnerRewardPoolsVal[controlId]

	if (redeemRulesDetail) {
		let redeemRulesDetailForm = formGroupReducer(redeemRulesDetail.form, action)

		if (action.type === 'ngrx/forms/SET_VALUE') {
			redeemRulesDetailForm = formGroupReducer(redeemRulesDetail.form, new SetValueAction(action.controlId, action.value))
		}

		redeemPartnerRewardPoolsVal = {
			...redeemPartnerRewardPoolsVal,
			[controlId]: {
				...redeemPartnerRewardPoolsVal[controlId],
				form: RedeemRulesState.validateRedeemRulesDetailForm(redeemRulesDetailForm)(redeemRulesDetailForm)
			}
		}

		updateState = {
			...state,
			redeemRulesForm: RedeemRulesState.validateRedeemRulesForm(state)(setValue(state.redeemRulesForm, {
				...state.redeemRulesForm.value,
				rules: redeemPartnerRewardPoolsVal
			}))
		}
	}
	return updateState
}

function ruleChecker(state: RedeemRulesState.State, rule: string, existingRedeemRuleForm: FormGroupState<RedeemRulesState.RedeemRulesDetail>): RedeemRulesState.RedeemRulesDetail {
	let updatedRedeemRuleForm = existingRedeemRuleForm.value

	switch (rule) {
		case RedeemRuleEntity.ACCOUNT_STATUS:
			updatedRedeemRuleForm = {
				...existingRedeemRuleForm.value,
				accountStatus: '',
				accountStatusFlag: ''
			}
			break
		case RedeemRuleEntity.ACCOUNT_BLOCK_CODE:
			updatedRedeemRuleForm = {
				...existingRedeemRuleForm.value,
				accountBlockCode: '',
				accountBlockCodeFlag: ''
			}
			break
		case RedeemRuleEntity.CUSTOMER_STATUS:
			updatedRedeemRuleForm = {
				...existingRedeemRuleForm.value,
				customerStatus: '',
				customerStatusFlag: ''
			}
			break
		case RedeemRuleEntity.CUSTOMER_BLOCK_CODE:
			updatedRedeemRuleForm = {
				...existingRedeemRuleForm.value,
				customerBlockCode: '',
				customerBlockCodeFlag: ''
			}
			break
		case RedeemRuleEntity.CARD_STATUS:
			updatedRedeemRuleForm = {
				...existingRedeemRuleForm.value,
				cardStatus: '',
				cardStatusFlag: ''
			}
			break
		case RedeemRuleEntity.CARD_BLOCK_CODE:
			updatedRedeemRuleForm = {
				...existingRedeemRuleForm.value,
				cardBlockCode: '',
				cardBlockCodeFlag: ''
			}
			break
	}

	return updatedRedeemRuleForm
}
