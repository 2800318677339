import { Component, Inject, OnDestroy, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material'
import { Store } from '@ngrx/store'
import { FormGroupState } from 'ngrx-forms'
import { Observable, Subscription } from 'rxjs'
import * as AppStore from 'src/app/store'
import { CampaignDuplicateForm } from 'src/app/store/campaign-management/campaign'
import * as CampaignAction from 'src/app/store/campaign-management/campaign/campaign.actions'
import * as CampaignSelectors from 'src/app/store/campaign-management/campaign/campaign.selectors'
import { POLICY } from 'src/app/models/common/constant'

@Component({
	selector: 'app-campaign-duplicate',
	templateUrl: './campaign-duplicate.component.html',
	styleUrls: ['./campaign-duplicate.component.scss']
})
export class CampaignDuplicateComponent implements OnInit, OnDestroy {

	isLoading = false
	formState$: Observable<FormGroupState<CampaignDuplicateForm>>

	subs: Subscription
	policy: POLICY

	constructor(
		private store: Store<AppStore.State>,
		private dialogRef: MatDialogRef<CampaignDuplicateComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		this.subs = this.store.select(CampaignSelectors.getCampaign).subscribe(({ isDuplicateLoading, isDuplicateDialog, policy }) => {
			this.isLoading = isDuplicateLoading

			if (isDuplicateDialog) {
				this.cancel()
			}
			this.policy = policy
		})

		this.formState$ = this.store.select(({ campaign }) => campaign.campaignDuplicateForm)
	}

	ngOnInit() { }

	ngOnDestroy() {
		this.subs.unsubscribe()
	}

	cancel() {
		this.dialogRef.close()
	}

	submit() {
		this.store.dispatch(CampaignAction.Duplicate({payload: this.policy}))
	}

}
