<div>
    <mat-dialog-content>
        <app-spinner [isLoading]="isLoading"></app-spinner>

        <div class="layout-div">
            <div fxFlex>
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="30px" fxLayoutGap.lt-md="0px">
                    <span class="mat-h3">{{ 'CUSTOMER_MANAGEMENT.CONFIRMATION' | translate }}</span>
                </div>
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="30px" fxLayoutGap.lt-md="0px">
                    <p>{{ 'CUSTOMER_MANAGEMENT.PARTNER_REDEMPTION_CONFIRMATION_MESSAGE' | translate:{'points': points, 'value': value} }}
                    </p>
                </div>
            </div>
        </div>
    </mat-dialog-content>

    <div class="action-button-div" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="end">
        <button class="mat-raised-button" (click)="cancel()">{{ 'ACTION.CANCEL' | translate }}</button>
        <button type="button" mat-raised-button (click)="create()" color="primary">{{ 'ACTION.CONFIRM' | translate }}
        </button>
    </div>
</div>
