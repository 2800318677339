import { NgModule } from '@angular/core'
import { MatNativeDateModule } from '@angular/material'
//
// Form Controls
//
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MatBadgeModule } from '@angular/material/badge'
//
// Popups & Modals
//
import { MatBottomSheetModule } from '@angular/material/bottom-sheet'
//
// Buttons & Indicators
//
import { MatButtonModule } from '@angular/material/button'
import { MatButtonToggleModule } from '@angular/material/button-toggle'
//
// Layout
//
import { MatCardModule } from '@angular/material/card'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatChipsModule } from '@angular/material/chips'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatDialogModule } from '@angular/material/dialog'
import { MatDividerModule } from '@angular/material/divider'
import { MatExpansionModule } from '@angular/material/expansion'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatGridListModule } from '@angular/material/grid-list'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatListModule } from '@angular/material/list'
//
// Navigation
//
import { MatMenuModule } from '@angular/material/menu'
//
// Data Table
//
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatRadioModule } from '@angular/material/radio'
import { MatSelectModule } from '@angular/material/select'
import { MatSidenavModule } from '@angular/material/sidenav'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { MatSliderModule } from '@angular/material/slider'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { MatSortModule } from '@angular/material/sort'
import { MatStepperModule } from '@angular/material/stepper'
import { MatTableModule } from '@angular/material/table'
import { MatTabsModule } from '@angular/material/tabs'
import { MatToolbarModule } from '@angular/material/toolbar'
import { MatTooltipModule } from '@angular/material/tooltip'
import { MatTreeModule } from '@angular/material/tree'
// Select with search
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search'
// Custom for ngrx
import { CustomErrorStateMatcherDirective } from './error-state-matcher'
import { MatListOptionFixDirective } from './mat-list-option-fix'
import { NgrxMatSelectViewAdapter } from './mat-select-view-adapter'

// Drag and drop module
import { DragDropModule } from '@angular/cdk/drag-drop'
// Custom SVG icon
import { HttpClientModule } from '@angular/common/http'


const MAT_LIST = [
	MatAutocompleteModule,
	MatCheckboxModule,
	MatDatepickerModule,
	MatFormFieldModule,
	MatInputModule,
	MatRadioModule,
	MatSelectModule,
	MatSliderModule,
	MatSlideToggleModule,

	MatNativeDateModule,

	MatMenuModule,
	MatSidenavModule,
	MatToolbarModule,

	MatCardModule,
	MatDividerModule,
	MatExpansionModule,
	MatGridListModule,
	MatListModule,
	MatStepperModule,
	MatTabsModule,
	MatTreeModule,

	MatButtonModule,
	MatButtonToggleModule,
	MatBadgeModule,
	MatChipsModule,
	MatIconModule,
	MatProgressSpinnerModule,
	MatProgressBarModule,

	MatBottomSheetModule,
	MatDialogModule,
	MatSnackBarModule,
	MatTooltipModule,

	MatPaginatorModule,
	MatSortModule,
	MatTableModule,

	NgxMatSelectSearchModule,

	DragDropModule,

	HttpClientModule,
]

@NgModule({
	imports: [
		MAT_LIST
	],
	declarations: [
		NgrxMatSelectViewAdapter,
		CustomErrorStateMatcherDirective,
		MatListOptionFixDirective,
	],
	exports: [
		MAT_LIST,
		NgrxMatSelectViewAdapter,
		CustomErrorStateMatcherDirective,
		MatListOptionFixDirective
	]

})
export class MaterialModule { }
