import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Action, Store } from '@ngrx/store'
import { Observable, of } from 'rxjs'
import { catchError, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators'
import * as AppStore from 'src/app/store/'
import * as CommonAction from '../../common/common/common.actions'
import * as DashboardV2Action from './dashboard-overview-v2.actions'
import { DashboardService } from 'src/app/services/dashboard/dashboard.service'
import { Response } from '../../../models/common/http'
import { KeyValue } from '@angular/common'
import { CampaignTotalPointComparison, PublishedCampaign } from 'src/app/models/dashboard/dashboard-overview'
import * as moment from 'moment'

@Injectable()
export class Effects {
	constructor(
		private action$: Actions,
		private router: Router,
		private dashboardService: DashboardService,
		private store: Store<AppStore.State>
	) {
	}

	InitialState$: Observable<Action> = createEffect(
		() =>
			this.action$.pipe(
				ofType(DashboardV2Action.InitialState),
				tap(() => this.router.navigate(['dashboard/overview-v2']))
			),
		{ dispatch: false }
	)

	RestError$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(DashboardV2Action.RestError),
			map(({ message, manualThrow }) => {
				return CommonAction.RestError({ message, manualThrow })
			})
		)
	)

	ViewTop5PerformanceCampaign$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(DashboardV2Action.ViewTop5PerformanceCampaign),
			mergeMap(() => {
				return this.dashboardService.getTop5PerformanceCampaign().pipe(
					map((response: Response) => {
						const success: boolean = response.success
						const payload = response.payload
						if (success) {
							return DashboardV2Action.ViewTop5PerformanceCampaignSuccess({ payload })
						} else {
							return DashboardV2Action.RestError(response as any)
						}
					}),
					catchError(this.catcher)
				)
			})
		)
	)

	ViewCampaignTotalPoint$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(DashboardV2Action.ViewCampaignTotalPointComparison),
			mergeMap((action) => {
				return this.dashboardService.getCampaignTotalPointComparison(action.payload)
					.pipe(
						map((response: Response) => {
							const success: boolean = response.success
							const payload: CampaignTotalPointComparison[] = response.payload

							if (success) {
								return DashboardV2Action.ViewCampaignTotalPointComparisonSuccess({
									payload: {
										campaignId: action.payload.toString(),
										totalPoints: payload
									}
								})
							} else {
								return DashboardV2Action.RestError(response as any)
							}
						}),
						catchError(this.catcher)
					)
			})
		)
	)


	ViewCampaignChannelList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(DashboardV2Action.ViewCampaignChannelList),
			mergeMap(() => {
				return this.dashboardService.getDashboardChannelOptionList().pipe(
					map((response: Response) => {
						const success: boolean = response.success
						const payload: KeyValue<string, string>[] = response.payload
						if (success) {
							return DashboardV2Action.ViewCampaignChannelListSuccess({ payload })
						} else {
							return DashboardV2Action.RestError(response as any)
						}
					}),
					catchError(this.catcher)
				)
			})
		)
	)

	ViewPointRedeemUtilization$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(DashboardV2Action.ViewPointRedeemUtilization),
			withLatestFrom(this.store.select(state => state.dashboardV2)),
			mergeMap(([params, dashboardV2]) => {
				const dashboardRequestForm = dashboardV2.dashboardRequestForm.controls

				return this.dashboardService.getPointRedeemUtilization({
					startDate: moment(dashboardRequestForm.startDate.value).format('YYYY-MM-DD'),
					endDate: moment(dashboardRequestForm.endDate.value).format('YYYY-MM-DD'),
				}).pipe(
					map((response: Response) => {
						const success: boolean = response.success
						const payload = response.payload

						if (success) {
							return DashboardV2Action.ViewPointRedeemUtilizationSuccess({ payload })
						} else {
							return DashboardV2Action.RestError(response as any)
						}
					}),
					catchError(this.catcher)
				)
			})
		)
	)

	ViewTop5CampaignCappingUtilization$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(DashboardV2Action.ViewTop5CampaignCappingUtilization),
			withLatestFrom(this.store.select(state => state.dashboardV2)),
			mergeMap(([params, dashboard]) => {
				return this.dashboardService.getCampaignCappingUtilizationList({
					channel: params.payload
				}).pipe(
					map((response: Response) => {
						const success: boolean = response.success
						const payload = response.payload
						if (success) {
							return DashboardV2Action.ViewTop5CampaignCappingUtilizationSuccess({ payload })
						} else {
							return DashboardV2Action.RestError(response as any)
						}
					}),
					catchError(this.catcher)
				)
			})
		)
	)

	ViewCampaignChannelListSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(DashboardV2Action.ViewCampaignChannelListSuccess),
			mergeMap((o) => {
				return [
					DashboardV2Action.setCampaignChannel({ campaignChannel: o.payload[0].key }),
					DashboardV2Action.GetPublishedCampaignList({ payload: o.payload[0].key })
				]
			})
		)
	)

	ViewTop5CampaignExpiring$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(DashboardV2Action.ViewTop5CampaignExpiring),
			mergeMap(() => {
				return this.dashboardService.getCampaignExpiringList().pipe(
					map((response: Response) => {
						const success: boolean = response.success
						const payload = response.payload
						if (success) {
							return DashboardV2Action.ViewTop5CampaignExpiringSuccess({ payload })
						} else {
							return DashboardV2Action.RestError(response as any)
						}
					}),
					catchError(this.catcher)
				)
			})
		)
	)

	GetPublishedCampaignList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(DashboardV2Action.GetPublishedCampaignList),
			mergeMap((action) => {
				return this.dashboardService.getPublishedCampaignList(action.payload)
					.pipe(
						map((response: Response) => {
							const success: boolean = response.success
							const payload: PublishedCampaign[] = response.payload
							if (success) {
								return DashboardV2Action.GetPublishedCampaignListSuccess({ payload })
							} else {
								return DashboardV2Action.RestError(response as any)
							}
						}),
						catchError(this.catcher)
					)
			})
		)
	)

	GetPublishedCampaignListSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(DashboardV2Action.GetPublishedCampaignListSuccess),
			mergeMap((action) => {
				const { payload } = action
				const actionsToCall = []

				if (payload[0] && payload[0].id) {
					actionsToCall.push(
						DashboardV2Action.ViewCampaignTotalPointComparison({ payload: payload[0].id })
					)
				}

				if (payload[1] && payload[1].id) {

					actionsToCall.push(
						DashboardV2Action.ViewCampaignTotalPointComparison({ payload: payload[1].id })
					)
				}

				return actionsToCall
			})
		)
	)

	SetCampaignId1$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(DashboardV2Action.setCampaignId1),
			map((o) => {
				return DashboardV2Action.ViewCampaignTotalPointComparison({ payload: o.campaignId1 })
			})
		)
	)

	SetCampaignId2$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(DashboardV2Action.setCampaignId2),
			map((o) => {
				return DashboardV2Action.ViewCampaignTotalPointComparison({ payload: o.campaignId2 })
			})
		)
	)

	ViewRewardPoolAnalysis$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(DashboardV2Action.ViewRewardPoolAnalysis),
			withLatestFrom(this.store.select((o) =>
				o.dashboardV2
			)),
			mergeMap(([params, dashboardV2]) => {
				const dashboardRequestForm = dashboardV2.dashboardRequestForm.controls
				const pointAccumulationPerformanceForm = dashboardV2.pointAccumulationPerformanceForm.controls
				return this.dashboardService.getRewardPoolAnalysis({
					startDate: moment(dashboardRequestForm.startDate.value).format('YYYY-MM-DD'),
					endDate: moment(dashboardRequestForm.endDate.value).format('YYYY-MM-DD'),
					rewardPool: pointAccumulationPerformanceForm.rewardPool.value
				}).pipe(
					map((response: Response) => {
						const success: boolean = response.success
						const payload = response.payload
						if (success) {
							return DashboardV2Action.ViewRewardPoolAnalysisSuccess({ payload })
						} else {
							return DashboardV2Action.RestError(response as any)
						}
					}),
					catchError(this.catcher)
				)
			})
		)
	)

	ViewRewardPoolGroupAnalysis$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(DashboardV2Action.ViewRewardPoolGroupAnalysis),
			withLatestFrom(this.store.select((o) =>
				o.dashboardV2
			)),
			mergeMap(([params, dashboardV2]) => {
				const dashboardRequestForm = dashboardV2.dashboardRequestForm.controls
				const pointAccumulationPerformanceForm = dashboardV2.pointAccumulationPerformanceForm.controls
				return this.dashboardService.getRewardPoolGroupAnalysis({
					startDate: moment(dashboardRequestForm.startDate.value).format('YYYY-MM-DD'),
					endDate: moment(dashboardRequestForm.endDate.value).format('YYYY-MM-DD'),
					rewardPoolGroup: pointAccumulationPerformanceForm.rewardPoolGroup.value
				}).pipe(
					map((response: Response) => {
						const success: boolean = response.success
						const payload = response.payload
						if (success) {
							return DashboardV2Action.ViewRewardPoolGroupAnalysisSuccess({ payload })
						} else {
							return DashboardV2Action.RestError(response as any)
						}
					}),
					catchError(this.catcher)
				)
			})
		)
	)

	ViewRewardPoolAnalysisOptionList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(DashboardV2Action.ViewRewardPoolAnalysisOptionList),
			mergeMap(() => {
				return this.dashboardService.getRewardPoolAnalysisOptionList().pipe(
					map((response: Response) => {
						const success: boolean = response.success
						const payload = response.payload
						if (success) {
							return DashboardV2Action.ViewRewardPoolAnalysisOptionListSuccess({ payload })
						} else {
							return DashboardV2Action.RestError(response as any)
						}
					}),
					catchError(this.catcher)
				)
			})
		)
	)

	ViewRewardPoolAnalysisRewardPoolList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(DashboardV2Action.ViewRewardPoolAnalysisRewardPoolList),
			withLatestFrom(this.store.select((o) =>
				o.dashboardV2
			)),
			mergeMap(([params, dashboardV2]) => {
				const dashboardRequestForm = dashboardV2.dashboardRequestForm.controls
				return this.dashboardService.getRewardPoolAnalysisRewardPoolList({
					startDate: moment(dashboardRequestForm.startDate.value).format('YYYY-MM-DD'),
					endDate: moment(dashboardRequestForm.endDate.value).format('YYYY-MM-DD'),
				}).pipe(
					map((response: Response) => {
						const success: boolean = response.success
						const payload = response.payload
						if (success) {
							return DashboardV2Action.ViewRewardPoolAnalysisRewardPoolListSuccess({ payload })
						} else {
							return DashboardV2Action.RestError(response as any)
						}
					}),
					catchError(this.catcher)
				)
			})
		)
	)

	ViewRewardPoolAnalysisRewardPoolGroupList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(DashboardV2Action.ViewRewardPoolAnalysisRewardPoolGroupList),
			withLatestFrom(this.store.select((o) =>
				o.dashboardV2
			)),
			mergeMap(([params, dashboardV2]) => {
				const dashboardRequestForm = dashboardV2.dashboardRequestForm.controls
				return this.dashboardService.getRewardPoolAnalysisRewardPoolGroupList({
					startDate: moment(dashboardRequestForm.startDate.value).format('YYYY-MM-DD'),
					endDate: moment(dashboardRequestForm.endDate.value).format('YYYY-MM-DD'),
				}).pipe(
					map((response: Response) => {
						const success: boolean = response.success
						const payload = response.payload
						if (success) {
							return DashboardV2Action.ViewRewardPoolAnalysisRewardPoolGroupListSuccess({ payload })
						} else {
							return DashboardV2Action.RestError(response as any)
						}
					}),
					catchError(this.catcher)
				)
			})
		)
	)

	ViewRewardPoolAnalysisRewardPoolGroupListSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(DashboardV2Action.ViewRewardPoolAnalysisRewardPoolGroupListSuccess),
			map( () => DashboardV2Action.ViewRewardPoolGroupAnalysis())
		)
	)

	ViewRewardPoolAnalysisRewardPoolListSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(DashboardV2Action.ViewRewardPoolAnalysisRewardPoolListSuccess),
			map( () => DashboardV2Action.ViewRewardPoolAnalysis())
		)
	)

	DurationOptionList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(DashboardV2Action.DurationOptionList),
			mergeMap(() => {
				return this.dashboardService.getDurationOptionList().pipe(
					map((response: Response) => {
						const success: boolean = response.success
						const payload = response.payload
						if (success) {
							return DashboardV2Action.DurationOptionListSuccess({ payload })
						} else {
							return DashboardV2Action.RestError(response as any)
						}
					}),
					catchError(this.catcher)
				)
			})
		)
	)

	ViewPendingApprovalTask$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(DashboardV2Action.ViewPendingApprovalTask),
			mergeMap(() => {
				return this.dashboardService.getPendingApprovalTask().pipe(
					map((response: Response) => {
						const success: boolean = response.success
						const payload = response.payload
						if (success) {
							return DashboardV2Action.ViewPendingApprovalTaskSuccess({ payload })
						} else {
							return DashboardV2Action.RestError(response as any)
						}
					}),
					catchError(this.catcher)
				)
			})
		)
	)

	GetComparisonDaysList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(DashboardV2Action.GetComparisonDaysList),
			mergeMap(() => {
				return this.dashboardService.getDurationOptionList().pipe(
					map((response: Response) => {
						const success: boolean = response.success
						const payload = response.payload
						if (success) {
							return DashboardV2Action.GetComparisonDaysListSuccess({ payload })
						} else {
							return DashboardV2Action.RestError(response as any)
						}
					}),
					catchError(this.catcher)
				)
			})
		)
	)

	GetMaxMinVarList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(DashboardV2Action.GetMaxMinVarList),
			withLatestFrom(this.store.select(state => state.dashboardV2)),
			mergeMap(([params, dashboardV2]) => {
				return this.dashboardService.getMaxMinVarList().pipe(
					map((response: Response) => {
						const success: boolean = response.success
						const payload = response.payload
						if (success) {
							return DashboardV2Action.GetMaxMinVarListSuccess({ payload })
						} else {
							return DashboardV2Action.RestError(response as any)
						}
					}),
					catchError(this.catcher)
				)
			})
		)
	)

	GetRedemptionTypeList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(DashboardV2Action.GetRedemptionTypeList),
			mergeMap(() => {
				return this.dashboardService.getRedemptionTypeList().pipe(
					map((response: Response) => {
						const success: boolean = response.success
						const payload = response.payload
						if (success) {
							return DashboardV2Action.GetRedemptionTypeListSuccess({ payload })
						} else {
							return DashboardV2Action.RestError(response as any)
						}
					}),
					catchError(this.catcher)
				)
			})
		)
	)

	GetMaxMinVarListSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(DashboardV2Action.GetMaxMinVarListSuccess),
			withLatestFrom(this.store.select(state => state.dashboardV2)),
			mergeMap(([params, dashboardV2]) => {
				return [
					DashboardV2Action.ViewMccTransactionList()
				]
			})
		)
	)

	GetPointsRedemptionAnalysis$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(DashboardV2Action.GetPointsRedemptionAnalysis),
			withLatestFrom(this.store.select(state => state.dashboardV2)),
			mergeMap(([params, dashboardV2]) => {
				const dashboardRequestForm = dashboardV2.dashboardRequestForm.controls

				return this.dashboardService.getPointsRedemptionAnalysis({
					type: dashboardRequestForm.redemptionType.value,
					startDate: moment(dashboardRequestForm.startDate.value).format('YYYY-MM-DD'),
					endDate: moment(dashboardRequestForm.endDate.value).format('YYYY-MM-DD'),
				}).pipe(
					map((response: Response) => {
						const success: boolean = response.success
						const payload = response.payload
						if (success) {
							return DashboardV2Action.GetPointsRedemptionAnalysisSuccess({ payload })
						} else {
							return DashboardV2Action.RestError(response as any)
						}
					}),
					catchError(this.catcher)
				)
			})
		)
	)

	SetMaxMin$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(DashboardV2Action.setMaxMin),
			map((o) => {
				return DashboardV2Action.ViewMccTransactionList()
			})
		)
	)

	GetPointsRedemptionAnalysisForComparison$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(DashboardV2Action.GetPointsRedemptionAnalysisForComparison),
			withLatestFrom(this.store.select(state => state.dashboardV2)),
			mergeMap(([params, dashboardV2]) => {
				const dashboardRequestForm = dashboardV2.dashboardRequestForm.controls

				return this.dashboardService.getPointsRedemptionAnalysis({
					type: dashboardRequestForm.redemptionType.value,
					startDate: moment(new Date(dashboardRequestForm.startDate.value).setDate(new Date(dashboardRequestForm.startDate.value).getDate() - Number(dashboardRequestForm.daysForComparison.value))).format('YYYY-MM-DD'),
					endDate: moment(new Date(dashboardRequestForm.endDate.value).setDate(new Date(dashboardRequestForm.endDate.value).getDate() - Number(dashboardRequestForm.daysForComparison.value))).format('YYYY-MM-DD')
				}).pipe(
					map((response: Response) => {
						const success: boolean = response.success
						const payload = response.payload
						if (success) {
							return DashboardV2Action.GetPointsRedemptionAnalysisForComparisonSuccess({ payload })
						} else {
							return DashboardV2Action.RestError(response as any)
						}
					}),
					catchError(this.catcher)
				)
			})
		)
	)

	ViewMccTransactionList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(DashboardV2Action.ViewMccTransactionList),
			withLatestFrom(this.store.select(state => state.dashboardV2)),
			mergeMap(([params, dashboardV2]) => {
				const dashboardRequestForm = dashboardV2.dashboardRequestForm.controls
				return this.dashboardService.getMccTransactionList({
					startDate: moment(dashboardRequestForm.startDate.value).format('YYYY-MM-DD'),
					endDate: moment(dashboardRequestForm.endDate.value).format('YYYY-MM-DD'),
					maxMinVar: dashboardRequestForm.maxMinVar.value
				}).pipe(
					map((response: Response) => {
						const success: boolean = response.success
						const payload = response.payload
						if (success) {
							return DashboardV2Action.ViewMccTransactionListSuccess({ payload })
						} else {
							return DashboardV2Action.RestError(response as any)
						}
					}),
					catchError(this.catcher)
				)
			})
		)
	)

	catcher = error => {
		return of(DashboardV2Action.RestError({ message: error.message, manualThrow: error.manualThrow }))
	}

}
