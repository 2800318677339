import { Component, Inject, OnDestroy, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material'
import { Store } from '@ngrx/store'
import { Subscription } from 'rxjs'
import * as AppStore from 'src/app/store'
import * as CustomerManagementAction from 'src/app/store/customer-management/customer-management.actions'
import * as CustomerManagementSelector from 'src/app/store/customer-management/customer-management.selectors'


@Component({
	selector: 'app-customer-management-waiver-confirmation',
	templateUrl: './customer-management-waiver-confirmation.component.html',
	styleUrls: ['./customer-management-waiver-confirmation.component.scss']
})
export class CustomerManagementWaiverConfirmationComponent implements OnInit, OnDestroy {

	isLoading = false
	points = ''
	waiver = ''
	subs: Subscription

	constructor(
		private store: Store<AppStore.State>,
		private dialogRef: MatDialogRef<CustomerManagementWaiverConfirmationComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any) {

		this.subs = this.store.select(CustomerManagementSelector.getCustomerManagement).subscribe(x => {
			this.isLoading = x.isLoading
			const waiver = x.waiverList.find(waiver => waiver.id === x.waiverForm.value.waiverId)
			this.points = waiver.points
			this.waiver = waiver.name
		})
	}

	ngOnInit() {
	}

	ngOnDestroy() {
		this.subs.unsubscribe()
	}

	cancel() {
		this.dialogRef.close()
	}

	create() {
		this.dialogRef.close()
		this.store.dispatch(CustomerManagementAction.CreateWaiver())
	}
}
