import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material'
import { Router } from '@angular/router'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Action, Store } from '@ngrx/store'
import { unbox } from 'ngrx-forms'
import { Observable, of, forkJoin } from 'rxjs'
import { catchError, map, mergeMap, take, tap, withLatestFrom } from 'rxjs/operators'
import { MessageDialogComponent } from 'src/app/components/common/dialog/message-dialog/message-dialog.component'
import { AssignedWaiverComponent } from 'src/app/components/redemption-settings/waiver-management/assigned-waiver/assigned-waiver.component'
import { getDialogConfig } from 'src/app/models/common/dialog'
import { Response } from 'src/app/models/common/http'
import { WaiverManagement } from 'src/app/models/redemption-settings/waiver-management/waiver-management'
import { WaiverManagementDataResponse } from 'src/app/models/redemption-settings/waiver-management/waiver-management-data'
import { WaiverManagementViewResponse } from 'src/app/models/redemption-settings/waiver-management/waiver-management-view'
import { RedemptionSettingsService } from 'src/app/services/redemption-settings/redemption-settings.service'
import { ParameterSettingsService } from 'src/app/services/param-settings/parameter-settings.service'
import * as AppStore from 'src/app/store/'
import * as CommonAction from '../../common/common/common.actions'
import * as WaiverManagementAction from './waiver-management.actions'
import { RewardPoolGroupViewResponse } from 'src/app/models/param-settings/reward-pool-group/reward-pool-group-view'
import { AssignedWaiverDuplicateComponent } from '../../../components/redemption-settings/waiver-management/assigned-waiver-duplicate/assigned-waiver-duplicate.component'

@Injectable()
export class Effects {

	constructor(
		private action$: Actions,
		private router: Router,
		private dialog: MatDialog,
		private redemptionSettingsService: RedemptionSettingsService,
		private store: Store<AppStore.State>,
		private parameterSettingsService: ParameterSettingsService
	) { }


	InitialState$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(WaiverManagementAction.InitialState),
			tap(() => {
				this.router.navigate(['redemption-settings/waiver-management'])
			})
		), { dispatch: false }
	)

	RestError$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(WaiverManagementAction.RestError),
			map(({ message, manualThrow }) => {
				return CommonAction.RestError({ message, manualThrow })
			})
		)
	)

	CloseDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(WaiverManagementAction.CloseDialog),
			tap(() => {
				this.dialog.closeAll()
			})
		), { dispatch: false }
	)

	GoList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(WaiverManagementAction.GoList),
			tap(() => {
				this.router.navigate(['redemption-settings/waiver-management'])
			})
		), { dispatch: false }
	)

	GoView$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(WaiverManagementAction.GoView),
			tap(() => {
				this.router.navigate(['redemption-settings/waiver-management/view'])
			})
		), { dispatch: false }
	)

	GoCreate$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(WaiverManagementAction.GoCreate),
			tap(() => {
				this.router.navigate(['redemption-settings/waiver-management/create'])
			})
		), { dispatch: false }
	)

	GoUpdate$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(WaiverManagementAction.GoUpdate),
			tap(() => {
				this.router.navigate(['redemption-settings/waiver-management/edit'])
			})
		), { dispatch: false }
	)

	List$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(WaiverManagementAction.List),
			withLatestFrom(this.store.select(state => state.waiverManagement)),
			mergeMap(() => {
				return this.redemptionSettingsService.getWaiverManagementList().pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: WaiverManagement[] = response.payload

					if (success) {
						return WaiverManagementAction.ListSuccess({ payload })
					} else {
						return WaiverManagementAction.RestError(response as any)
					}
				}),
					catchError((error) => {
						return (of(WaiverManagementAction.RestError({ message: error.message, manualThrow: true })))
					})
				)
			})
		)
	)

	View$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(WaiverManagementAction.View),
			withLatestFrom(this.store.select(state => state.waiverManagement)),
			mergeMap(([, waiverManagement]) => this.redemptionSettingsService.viewWaiverManagement({ id: waiverManagement.id } as WaiverManagementViewResponse)
				.pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: WaiverManagementViewResponse = response.payload
					if (success) {
						return WaiverManagementAction.ViewSuccess({ payload })
					} else {
						return WaiverManagementAction.RestError(response as any)
					}
				}), catchError(this.catcher))
			)
		)
	)

	CreateDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(WaiverManagementAction.CreateDialog),
			mergeMap(() => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.CREATE_WAIVER_MANAGEMENT' }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return WaiverManagementAction.Create()
				} else {
					return WaiverManagementAction.CloseDialog()
				}
			})
		)
	)

	Create$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(WaiverManagementAction.Create),
			withLatestFrom(this.store.select(state => state.waiverManagement)),
			mergeMap(([, waiverManagement]) => {
				const waiverManagementDetailForm = waiverManagement.waiverManagementDetailForm.value
				return this.redemptionSettingsService.createWaiverManagement({
					code: waiverManagementDetailForm.code,
					name: waiverManagementDetailForm.name,
					description: waiverManagementDetailForm.description
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload = response.payload
					if (success) {
						return WaiverManagementAction.CreateSuccess({ payload })
					} else {
						return WaiverManagementAction.RestError(response as any)
					}
				}), catchError(this.catcher))
			})
		)
	)

	CreateSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(WaiverManagementAction.CreateSuccess),
			mergeMap(({ payload }) => {
				return [WaiverManagementAction.GoList(), CommonAction.RestError({ message: payload })]
			})
		)
	)
	GoBackDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(WaiverManagementAction.GoBackDialog),
			mergeMap(({ action }) => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.BACK', payload: action }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					if (result.payload === 'LIST') {
						return WaiverManagementAction.GoList()
					}
					if (result.payload === 'VIEW') {
						return WaiverManagementAction.GoView()
					}
				} else {
					return WaiverManagementAction.CloseDialog()
				}
			})
		)
	)

	UpdateDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(WaiverManagementAction.UpdateDialog),
			mergeMap(() => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.UPDATE' }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return WaiverManagementAction.Update()
				} else {
					return WaiverManagementAction.CloseDialog()
				}
			})
		)
	)

	Update$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(WaiverManagementAction.Update),
			withLatestFrom(this.store.select(state => state.waiverManagement)),
			mergeMap(([, waiverManagement]) => {
				const waiverManagementDetailForm = waiverManagement.waiverManagementDetailForm.value
				return this.redemptionSettingsService.editWaiverManagement(waiverManagement.id, {
					name: waiverManagementDetailForm.name,
					description: waiverManagementDetailForm.description
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: string = response.payload
					if (success) {
						return WaiverManagementAction.UpdateSuccess({ payload })
					} else {
						return WaiverManagementAction.RestError(response as any)
					}
				}), catchError(this.catcher))
			})
		)
	)

	UpdateSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(WaiverManagementAction.UpdateSuccess),
			mergeMap(({ payload }) => {
				return [WaiverManagementAction.GoView(), CommonAction.RestError({ message: payload })]
			})
		)
	)

	DeleteDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(WaiverManagementAction.DeleteDialog),
			mergeMap(() => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.DELETE_WAIVER_MANAGEMENT' }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return WaiverManagementAction.Delete()
				} else {
					return WaiverManagementAction.CloseDialog()
				}
			})
		)
	)

	Delete$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(WaiverManagementAction.Delete),
			withLatestFrom(this.store.select(state => state.waiverManagement)),
			mergeMap(([, waiverManagement]) => {
				return this.redemptionSettingsService.deleteWaiverManagement({
					id: waiverManagement.id
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					if (success) {
						return WaiverManagementAction.DeleteSuccess()
					} else {
						return WaiverManagementAction.RestError(response as any)
					}
				}), catchError(this.catcher))
			})
		)
	)

	DeleteSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(WaiverManagementAction.DeleteSuccess),
			map(() => {
				return WaiverManagementAction.GoList()
			})
		)
	)

	GetWaiverManagementData$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(WaiverManagementAction.GetWaiverManagementData),
			mergeMap(() => {
				return this.redemptionSettingsService.getWaiverManagementData().pipe(
					map((response: Response) => {
						const success: boolean = response.success
						const payload: WaiverManagementDataResponse = response.payload
						if (success) {
							return WaiverManagementAction.GetWaiverManagementSuccess({ payload })
						} else {
							return WaiverManagementAction.RestError(response as any)
						}

					}), catchError(this.catcher)
				)
			})
		)
	)

	WaiverDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(WaiverManagementAction.WaiverDialog),
			mergeMap(() => {
				const dialogRef = this.dialog.open(AssignedWaiverComponent, getDialogConfig({}))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (!result) {
					return WaiverManagementAction.CloseDialog()
				}
			})
		)
	)

	CreateWaiver$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(WaiverManagementAction.CreateWaiver),
			withLatestFrom(this.store.select(state => state.waiverManagement)),
			mergeMap(([, waiverManagement]) => {
				const assignedWaiverForm = waiverManagement.assignedWaiverForm.value
				const rewardPool = assignedWaiverForm.rewardPoolId
				const waiverRewardPoolsVal = assignedWaiverForm.waiverRewardPools
				const rewardPoolAndGlRedemption = []

				waiverRewardPoolsVal.allIds.forEach(id => {
					if (waiverRewardPoolsVal.byId.hasOwnProperty(id)) {
						const byId = waiverRewardPoolsVal.byId[id]
						const formVal = byId.form.value

						rewardPoolAndGlRedemption.push({
							rewardPoolId: formVal.rewardPoolId,
							glRedemptionId: formVal.glRedemptionId
						})
					}
				})
				let rewardPoolType = ''
				let rewardPoolId = ''
				if (rewardPool) {
					rewardPoolType = rewardPool.split('_')[0]
					rewardPoolId = rewardPool.split('_')[1]
				}
				if (rewardPoolType === 'I') {
					rewardPoolAndGlRedemption.push({
						rewardPoolId,
						glRedemptionId: assignedWaiverForm.glRedemptionId
					})
				}
				return this.redemptionSettingsService.createWaiver(waiverManagement.id, {
					code: assignedWaiverForm.code,
					name: assignedWaiverForm.name,
					rewardPoolType,
					rewardPoolId,
					productTypeId: unbox(assignedWaiverForm.productTypeId) && unbox(assignedWaiverForm.productTypeId).toString(),
					productTypeGroupId: unbox(assignedWaiverForm.productTypeGroupId) && unbox(assignedWaiverForm.productTypeGroupId).toString(),
					cardHolder: assignedWaiverForm.cardHolder,
					points: assignedWaiverForm.points,
					glRedemptionId: Number(assignedWaiverForm.glRedemptionId),
					rewardPoolAndGlRedemption,
					transactionCode: assignedWaiverForm.transactionCode,
					transactionAmount: Number(assignedWaiverForm.transactionAmount),
					transactionDescription: assignedWaiverForm.transactionDescription,
					feeTc: unbox(assignedWaiverForm.feeTc) && unbox(assignedWaiverForm.feeTc).toString(),
					feeRedemptionTc: unbox(assignedWaiverForm.feeRedemptionTc) && unbox(assignedWaiverForm.feeRedemptionTc).toString()
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload = response.payload
					if (success) {
						return WaiverManagementAction.CreateWaiverSuccess({ payload })
					} else {
						return WaiverManagementAction.RestError(response as any)
					}
				}), catchError(this.catcher))
			})
		)
	)

	CreateWaiverSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(WaiverManagementAction.CreateWaiverSuccess),
			mergeMap(() => {
				return [WaiverManagementAction.CloseDialog(), WaiverManagementAction.View()]
			})
		)
	)

	UpdateWaiver$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(WaiverManagementAction.UpdateWaiver),
			withLatestFrom(this.store.select(state => state.waiverManagement)),
			mergeMap(([, waiverManagement]) => {
				const assignedWaiverForm = waiverManagement.assignedWaiverForm.value
				const rewardPool = assignedWaiverForm.rewardPoolId
				const waiverRewardPoolsVal = assignedWaiverForm.waiverRewardPools
				const rewardPoolAndGlRedemption = []

				waiverRewardPoolsVal.allIds.forEach(id => {
					if (waiverRewardPoolsVal.byId.hasOwnProperty(id)) {
						const byId = waiverRewardPoolsVal.byId[id]
						const formVal = byId.form.value

						rewardPoolAndGlRedemption.push({
							rewardPoolId: formVal.rewardPoolId,
							glRedemptionId: formVal.glRedemptionId
						})
					}
				})
				let rewardPoolType = ''
				let rewardPoolId = ''
				if (rewardPool) {
					rewardPoolType = rewardPool.split('_')[0]
					rewardPoolId = rewardPool.split('_')[1]
				}
				if (rewardPoolType === 'I') {
					rewardPoolAndGlRedemption.push({
						rewardPoolId,
						glRedemptionId: assignedWaiverForm.glRedemptionId
					})
				}
				return this.redemptionSettingsService.updateWaiver(waiverManagement.id, {
					id: assignedWaiverForm.id,
					code: assignedWaiverForm.code,
					name: assignedWaiverForm.name,
					rewardPoolType,
					rewardPoolId,
					productTypeId: unbox(assignedWaiverForm.productTypeId) && unbox(assignedWaiverForm.productTypeId).toString(),
					productTypeGroupId: unbox(assignedWaiverForm.productTypeGroupId) && unbox(assignedWaiverForm.productTypeGroupId).toString(),
					cardHolder: assignedWaiverForm.cardHolder,
					points: assignedWaiverForm.points,
					glRedemptionId: Number(assignedWaiverForm.glRedemptionId),
					rewardPoolAndGlRedemption,
					transactionCode: assignedWaiverForm.transactionCode,
					transactionAmount: Number(assignedWaiverForm.transactionAmount),
					transactionDescription: assignedWaiverForm.transactionDescription,
					feeTc: unbox(assignedWaiverForm.feeTc) && unbox(assignedWaiverForm.feeTc).toString(),
					feeRedemptionTc: unbox(assignedWaiverForm.feeRedemptionTc) && unbox(assignedWaiverForm.feeRedemptionTc).toString()
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload = response.payload
					if (success) {
						return WaiverManagementAction.UpdateWaiverSuccess({ payload })
					} else {
						return WaiverManagementAction.RestError(response as any)
					}
				}), catchError(this.catcher))
			})
		)
	)

	UpdateWaiverSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(WaiverManagementAction.UpdateWaiverSuccess),
			mergeMap(() => {
				return [WaiverManagementAction.CloseDialog(), WaiverManagementAction.View()]
			})
		)
	)

	DeleteWaiverDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(WaiverManagementAction.DeleteWaiverDialog),
			mergeMap(() => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({
					content: 'DIALOG.DELETE_ASSIGNED_WAIVER'
				}))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return WaiverManagementAction.DeleteWaiver()
				} else {
					return WaiverManagementAction.CloseDialog()
				}
			})
		)
	)

	DeleteWaiver$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(WaiverManagementAction.DeleteWaiver),
			withLatestFrom(this.store.select(state => state.waiverManagement)),
			mergeMap(([, waiverManagement]) => this.redemptionSettingsService.deleteWaiver(waiverManagement.id, {
				id: waiverManagement.redemptionId
			}).pipe(map((response: Response) => {
				const success: boolean = response.success
				const payload = response.payload
				if (success) {
					return WaiverManagementAction.UpdateWaiverSuccess({ payload })
				} else {
					return WaiverManagementAction.RestError(response as any)
				}
			}), catchError(this.catcher)))
		)
	)

	DeleteWaiverSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(WaiverManagementAction.DeleteWaiverSuccess),
			mergeMap(() => {
				return [WaiverManagementAction.CloseDialog(), WaiverManagementAction.View()]
			})
		)
	)

	OnChangeRewardPoolGroup$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(WaiverManagementAction.OnChangeRewardPoolGroup),
			withLatestFrom(this.store.select(state => state.waiverManagement)),
			mergeMap(([x, waiverManagement]) => {
				const rewardPoolId = waiverManagement.assignedWaiverForm.value.rewardPoolId.split('_')[1]
				return this.parameterSettingsService.viewRewardPoolGroup({ id: rewardPoolId }).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: RewardPoolGroupViewResponse = response.payload
					if (success) {
						return WaiverManagementAction.OnChangeRewardPoolGroupSuccess({ payload })
					} else { return WaiverManagementAction.RestError(response as any) }
				}), catchError(this.catcher))
			})
		)
	)

	InitialLoadList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(WaiverManagementAction.InitialLoadList),
			withLatestFrom(this.store.select(state => state.waiverManagement)),
			mergeMap(([, waiverManagement]) => forkJoin([
				this.redemptionSettingsService
					.viewWaiverManagement({ id: waiverManagement.id })
					.pipe(take(1)),
				this.redemptionSettingsService.getWaiverManagementData()
					.pipe(take(1)),
			]).pipe(
				mergeMap((value) => {
					const [waiverManagementResp, redemptionSettingResp]: Response[] = value
					const responses: Action[] = []
					if (waiverManagementResp.success) {
						responses.push(WaiverManagementAction.ViewSuccess({ payload: waiverManagementResp.payload }))
					}
					if (redemptionSettingResp.success) {
						responses.push(WaiverManagementAction.GetWaiverManagementSuccess({ payload: redemptionSettingResp.payload }))
					}
					const fail = value
						.filter(v => !v.success)
						.map(v => WaiverManagementAction.RestError(v as any))

					return [...responses, ...fail]
				}
				),
				catchError(this.catcher))
			)
		),
	)

	DuplicateDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(WaiverManagementAction.DuplicateDialog),
			mergeMap(() => {
				const dialogRef = this.dialog.open(AssignedWaiverDuplicateComponent, getDialogConfig({}))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return WaiverManagementAction.DuplicateWaiverDetail()
				} else {
					return WaiverManagementAction.CloseDialog()
				}
			})
		)
	)

	DuplicateWaiverDetail$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(WaiverManagementAction.DuplicateWaiverDetail),
			withLatestFrom(this.store.select(state => state.waiverManagement)),
			mergeMap(([, waivedManagement]) => {
				const assignedWaiverDetailDuplicateForm = waivedManagement.assignedWaiverDuplicateForm.value
				return this.redemptionSettingsService.duplicateWaiver(waivedManagement.id, {
					id: waivedManagement.redemptionId,
					code: assignedWaiverDetailDuplicateForm.code,
					name: assignedWaiverDetailDuplicateForm.name
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload = response.payload

					if (success) {
						return WaiverManagementAction.DuplicateSuccess({payload})
					} else {
						return WaiverManagementAction.RestError(response as any)
					}
				}), catchError(this.catcher))
			})
		)
	)

	DuplicateSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(WaiverManagementAction.DuplicateSuccess),
			mergeMap(({payload}) => {
				return [
					WaiverManagementAction.View(),
					CommonAction.RestError({message: payload})
				]
			})
		)
	)

	catcher = (error) => {
		return (of(WaiverManagementAction.RestError({ message: error.message, manualThrow: error.manualThrow })))
	}

}
