import { Action, createReducer, on } from '@ngrx/store'
import * as ProductCatalogueAction from './product-catalogue.actions'
import * as ProductCatalogueState from './product-catalogue.state'
import * as NgrxForms from 'ngrx-forms'
import { formGroupReducer, setValue, reset, markAsTouched, createFormGroupState, SetValueAction } from 'ngrx-forms'
import { KeyValue } from '@angular/common'
import { Util } from 'src/app/models/util/util'

export const reducer = createReducer(
	ProductCatalogueState.initialState,
	NgrxForms.onNgrxFormsAction(SetValueAction, (state, action) => {
		return actionHandler(state, action)
	}),
	NgrxForms.onNgrxFormsAction(NgrxForms.MarkAsTouchedAction, (state, action) => {
		return actionHandler(state, action)
	}),
	on(ProductCatalogueAction.InitialState, () => ProductCatalogueState.initialState),
	on(ProductCatalogueAction.SetId, (state, { id }) => ({
		...state,
		id
	})),
	on(ProductCatalogueAction.RestError, state => ({
		...state,
		isLoading: false,
		isDuplicateDialog: false,
		isDuplicateLoading: false
	})),
	on(ProductCatalogueAction.GoCreate, state => ({
		...state,
		productCatalogueDetailForm: reset(setValue(state.productCatalogueDetailForm, ProductCatalogueState.initialProductCatalogueDetailFormValue)),
		action: 'CREATE'
	})),
	on(ProductCatalogueAction.GoUpdate, state => ({
		...state,
		action: 'UPDATE'
	})),
	on(ProductCatalogueAction.GoView, state => ({
		...state,
		action: 'VIEW'
	})),
	on(ProductCatalogueAction.StatusList, state => ({
		...state,
		isLoading: true
	})),
	on(ProductCatalogueAction.StatusListSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		statusList: payload.statusList
	})),
	on(ProductCatalogueAction.RedemptionTypeList, state => ({
		...state,
		isLoading: true
	})),
	on(ProductCatalogueAction.RedemptionTypeListSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		redemptionTypeList: payload.redemptionTypeList
	})),
	on(ProductCatalogueAction.ProductCategoryList, state => ({
		...state,
		isLoading: true
	})),
	on(ProductCatalogueAction.ProductCategoryListSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		productCategoryList: payload.productCategoryList
	})),
	on(ProductCatalogueAction.RewardPoolAndGroupList, state => ({
		...state,
		isLoading: true
	})),
	on(ProductCatalogueAction.RewardPoolAndGroupListSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		rewardPoolAndGroupList: (payload || []).map(i => ({ key: `${i.type}_${i.id}`, value: `${i.code} - ${i.name}` })) as KeyValue<string, string>[]
	})),
	on(ProductCatalogueAction.GlRedemptionList, state => ({
		...state,
		isLoading: true
	})),
	on(ProductCatalogueAction.GlRedemptionListSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		glRedemptionList: payload.glRedemptions
	})),
	on(ProductCatalogueAction.FulfilmentParnterList, state => ({
		...state,
		isLoading: true
	})),
	on(ProductCatalogueAction.FulfilmentParnterListSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		fulfilmentPartnerList: payload.fulfilmentPartners
	})),
	on(ProductCatalogueAction.List, state => ({
		...state,
		isLoading: true
	})),
	on(ProductCatalogueAction.ListSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		productCatalogueListResponse: payload
	})),
	on(ProductCatalogueAction.View, state => ({
		...state,
		isLoading: true,
		productCatalogueDetailForm: setValue(state.productCatalogueDetailForm, ProductCatalogueState.initialProductCatalogueDetailFormValue)
	})),
	on(ProductCatalogueAction.ViewSuccess, (state, { payload }) => {
		const rewardPoolAndGlRedemption = payload.rewardPool
		let productCatalogueRewardPools: ProductCatalogueState.ProductCatalogueRewardPools = {
			byId: {},
			allIds: []
		}

		if (payload.rewardPoolType === 'G') {
			rewardPoolAndGlRedemption.forEach(x => {
				const id = x.rewardPoolId
				productCatalogueRewardPools = {
					byId: {
						...productCatalogueRewardPools.byId,
						[id]: {
							id,
							form: ProductCatalogueState.validateProductCatalogueRewardPoolForm(getProductCatalogueRewardPoolForm(id.toString(), id, x.rewardPoolName, x.glRedemptionId))
						}
					},
					allIds: [...productCatalogueRewardPools.allIds, id.toString()]
				}
			})
		}

		const productCatalogueDetailFormView: ProductCatalogueState.ProductCatalogueDetailForm = {
			code: payload.code,
			name: payload.name,
			description: payload.description,
			merchantId: payload.merchantId,
			status: payload.status,
			rewardPoolAndGroup: payload.rewardPoolType + '_' + payload.rewardPoolId,
			rewardPoolId: payload.rewardPoolId.toString(),
			rewardPoolType: payload.rewardPoolType,
			glRedemptionId: payload.rewardPoolType === 'I' ? payload.rewardPool.find(x => x.rewardPoolId === payload.rewardPoolId).glRedemptionId : null,
			fulfilmentPartner: payload.fulfilmentPartner,
			startDate: payload.startDate,
			endDate: payload.endDate,
			redemptionType: payload.redemptionType,
			point: payload.point.toString(),
			amount: payload.amount.toFixed(2),
			transactionCode: payload.transactionCode,
			redemptionRate: state.action === 'UPDATE' ? payload.redemptionRate.toString() : payload.redemptionRate.toFixed(4),
			image: payload.image,
			imageFileName: payload.imageFileName,
			glProductCode: payload.glProductCode,
			productCategory: payload.productCategory,
			productCatalogueRewardPools,
			createdBy: payload.createdBy,
			createdDate: payload.createdDate,
			modifiedBy: payload.modifiedBy,
			modifiedDate: payload.modifiedDate
		}
		return ({
			...state,
			id: payload.id,
			productCatalogueDetailForm: ProductCatalogueState.validateProductCatalogueDetailForm(state)(setValue(state.productCatalogueDetailForm, productCatalogueDetailFormView)),
			productCatalogueDetailFormView
		})
	}),
	on(ProductCatalogueAction.ResetPointsAndAmount, state => ({
		...state,
		productCatalogueDetailForm: ProductCatalogueState.validateProductCatalogueDetailForm(state)(setValue(state.productCatalogueDetailForm, {
			code: state.productCatalogueDetailForm.value.code,
			name: state.productCatalogueDetailForm.value.name,
			description: state.productCatalogueDetailForm.value.description,
			merchantId: state.productCatalogueDetailForm.value.merchantId,
			status: state.productCatalogueDetailForm.value.status,
			rewardPoolId: state.productCatalogueDetailForm.value.rewardPoolId,
			glRedemptionId: state.productCatalogueDetailForm.value.glRedemptionId,
			fulfilmentPartner: state.productCatalogueDetailForm.value.fulfilmentPartner,
			startDate: state.productCatalogueDetailForm.value.startDate,
			endDate: state.productCatalogueDetailForm.value.endDate,
			redemptionType: state.productCatalogueDetailForm.value.redemptionType,
			point: '',
			amount: '',
			transactionCode: state.productCatalogueDetailForm.value.transactionCode,
			redemptionRate: state.productCatalogueDetailForm.value.redemptionRate,
			image: state.productCatalogueDetailForm.value.image,
			imageFileName: state.productCatalogueDetailForm.value.imageFileName,
			glProductCode: state.productCatalogueDetailForm.value.glProductCode,
			productCategory: state.productCatalogueDetailForm.value.productCategory,
			rewardPoolAndGroup: state.productCatalogueDetailForm.value.rewardPoolAndGroup,
			rewardPoolType: state.productCatalogueDetailForm.value.rewardPoolType,
			productCatalogueRewardPools: state.productCatalogueDetailForm.value.productCatalogueRewardPools,
			createdBy: state.productCatalogueDetailForm.value.createdBy,
			createdDate: state.productCatalogueDetailForm.value.createdDate,
			modifiedBy: state.productCatalogueDetailForm.value.modifiedBy,
			modifiedDate: state.productCatalogueDetailForm.value.modifiedDate
		}))
	})),
	on(ProductCatalogueAction.UploadProductCatalogueImage, state => ({
		...state,
		isLoading: true
	})),
	on(ProductCatalogueAction.UploadProductCatalogueImageSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		productCatalogueDetailForm: ProductCatalogueState.validateProductCatalogueDetailForm(state)(setValue(state.productCatalogueDetailForm, {
			code: state.productCatalogueDetailForm.value.code,
			name: state.productCatalogueDetailForm.value.name,
			description: state.productCatalogueDetailForm.value.description,
			merchantId: state.productCatalogueDetailForm.value.merchantId,
			status: state.productCatalogueDetailForm.value.status,
			rewardPoolId: state.productCatalogueDetailForm.value.rewardPoolId,
			glRedemptionId: state.productCatalogueDetailForm.value.glRedemptionId,
			fulfilmentPartner: state.productCatalogueDetailForm.value.fulfilmentPartner,
			startDate: state.productCatalogueDetailForm.value.startDate,
			endDate: state.productCatalogueDetailForm.value.endDate,
			redemptionType: state.productCatalogueDetailForm.value.redemptionType,
			point: state.productCatalogueDetailForm.value.point,
			amount: state.productCatalogueDetailForm.value.amount,
			transactionCode: state.productCatalogueDetailForm.value.transactionCode,
			redemptionRate: state.productCatalogueDetailForm.value.redemptionRate,
			image: payload.image,
			imageFileName: payload.imageFileName,
			glProductCode: state.productCatalogueDetailForm.value.glProductCode,
			productCategory: state.productCatalogueDetailForm.value.productCategory,
			rewardPoolAndGroup: state.productCatalogueDetailForm.value.rewardPoolAndGroup,
			rewardPoolType: state.productCatalogueDetailForm.value.rewardPoolType,
			productCatalogueRewardPools: state.productCatalogueDetailForm.value.productCatalogueRewardPools,
			createdBy: state.productCatalogueDetailForm.value.createdBy,
			createdDate: state.productCatalogueDetailForm.value.createdDate,
			modifiedBy: state.productCatalogueDetailForm.value.modifiedBy,
			modifiedDate: state.productCatalogueDetailForm.value.modifiedDate
		}))
	})),
	on(ProductCatalogueAction.InitialLoadList, state => ({
		...state,
		isLoading: true
	})),
	on(ProductCatalogueAction.Create, state => ({
		...state,
		isLoading: true
	})),
	on(ProductCatalogueAction.CreateSuccess, (state, { payload }) => ({
		...ProductCatalogueState.initialState
	})),
	on(ProductCatalogueAction.Update, state => ({
		...state,
		isLoading: true
	})),
	on(ProductCatalogueAction.UpdateSuccess, (state, { payload }) => ({
		...state
	})),
	on(ProductCatalogueAction.Delete, state => ({
		...state,
		isLoading: true
	})),
	on(ProductCatalogueAction.DeleteSuccess, () => ProductCatalogueState.initialState),
	on(ProductCatalogueAction.OnChangeRewardPool, state => {
		const rewardPoolAndGroup = state.productCatalogueDetailForm.controls.rewardPoolAndGroup.value
		const rewardPoolId = rewardPoolAndGroup && rewardPoolAndGroup.split('_')[1]
		const rewardPoolType = rewardPoolAndGroup && rewardPoolAndGroup.split('_')[0]
		const productCatalogueRewardPools: ProductCatalogueState.ProductCatalogueRewardPools = {
			byId: {},
			allIds: []
		}

		return ({
			...state,
			productCatalogueDetailForm: ProductCatalogueState.validateProductCatalogueDetailForm(state)(setValue(state.productCatalogueDetailForm, {
				...state.productCatalogueDetailForm.value,
				rewardPoolId,
				rewardPoolType,
				glRedemptionId: null,
				productCatalogueRewardPools
			}))
		})
	}),
	on(ProductCatalogueAction.OnChangeRewardPoolGroup, state => {
		const rewardPoolAndGroup = state.productCatalogueDetailForm.controls.rewardPoolAndGroup.value
		const rewardPoolId = rewardPoolAndGroup && rewardPoolAndGroup.split('_')[1]
		const rewardPoolType = rewardPoolAndGroup && rewardPoolAndGroup.split('_')[0]
		const productCatalogueRewardPools: ProductCatalogueState.ProductCatalogueRewardPools = {
			byId: {},
			allIds: []
		}

		return ({
			...state,
			productCatalogueDetailForm: ProductCatalogueState.validateProductCatalogueDetailForm(state)(setValue(state.productCatalogueDetailForm, {
				...state.productCatalogueDetailForm.value,
				rewardPoolId,
				rewardPoolType,
				glRedemptionId: null,
				productCatalogueRewardPools
			}))
		})
	}),
	on(ProductCatalogueAction.OnChangeRewardPoolGroupSuccess, (state, { payload }) => {
		const rewardPoolList = payload.rewardPools

		let productCatalogueRewardPools = {
			byId: {},
			allIds: []
		}

		if (rewardPoolList) {
			rewardPoolList.forEach(x => {
				const id = x.id

				productCatalogueRewardPools = {
					byId: {
						...productCatalogueRewardPools.byId,
						[id]: {
							id,
							form: ProductCatalogueState.validateProductCatalogueRewardPoolForm(getProductCatalogueRewardPoolForm(id.toString(), id, `${x.code} - ${x.name}`, null))
						}
					},
					allIds: [...productCatalogueRewardPools.allIds, id.toString()]
				}
			})
		}

		return {
			...state,
			productCatalogueDetailForm: setValue(state.productCatalogueDetailForm, {
				...state.productCatalogueDetailForm.value,
				productCatalogueRewardPools
			})
		}
	}),
	on(ProductCatalogueAction.DuplicateDialog, state => ({
		...state,
		productCatalogueDuplicateForm: reset(setValue(state.productCatalogueDuplicateForm, ProductCatalogueState.initialProductCatalogueDuplicateFormValue)),
		isDuplicateDialog: false
	})),
	on(ProductCatalogueAction.Duplicate, state => ({
		...state,
		isDuplicateLoading: true
	})),
	on(ProductCatalogueAction.DuplicateSuccess, state => ({
		...state,
		isDuplicateLoading: false,
		isDuplicateDialog: true
	})),
)

export function Reducer(state: ProductCatalogueState.State = ProductCatalogueState.initialState, action: Action) {
	const productCatalogueDetailForm = ProductCatalogueState.validateProductCatalogueDetailForm(state)(formGroupReducer(state.productCatalogueDetailForm, action))
	if (productCatalogueDetailForm !== state.productCatalogueDetailForm) {
		state = { ...state, productCatalogueDetailForm }
	}

	const productCatalogueDuplicateForm = ProductCatalogueState.validateProductCatalogueDuplicateForm(formGroupReducer(state.productCatalogueDuplicateForm, action))
	if (productCatalogueDuplicateForm !== state.productCatalogueDuplicateForm) {
		state = {...state, productCatalogueDuplicateForm}
	}
	return reducer(state, action)
}

function validateForm(form: any) {
	form = markAsTouched(form)

	return ProductCatalogueState.validateProductCatalogueDetailForm(form)
}

function getProductCatalogueRewardPoolForm(id: string, rewardPoolId?: number, rewardPoolName?: string, glRedemptionId?: number) {
	if (rewardPoolId || glRedemptionId) {
		const formGroup = createFormGroupState<ProductCatalogueState.ProductCatalogueRewardPoolForm>(id, {
			rewardPoolId,
			rewardPoolName,
			glRedemptionId
		})
		return validateProductCatalogueRewardPoolForm(formGroup)
	}

	return createFormGroupState<ProductCatalogueState.ProductCatalogueRewardPoolForm>(id, ProductCatalogueState.initialProductCatalogueRewardPoolFormValue)
}

function validateProductCatalogueRewardPoolForm(form: any, state?: ProductCatalogueState.State) {
	return markAsTouched(ProductCatalogueState.validateProductCatalogueRewardPoolForm(form))
}

function actionHandler(state: ProductCatalogueState.State, action: SetValueAction<unknown> | NgrxForms.MarkAsTouchedAction) {
	const formControls = state.productCatalogueDetailForm

	let form = formGroupReducer(formControls, action)

	if (action.type === 'ngrx/forms/SET_VALUE') {
		form = formGroupReducer(formControls, new SetValueAction(action.controlId, action.value))
	}

	state = {
		...state,
		productCatalogueDetailForm: setValue(state.productCatalogueDetailForm, {
			...state.productCatalogueDetailForm.value,
			endDate: Util.ISOStringFormat(form.value.endDate),
			startDate: Util.ISOStringFormat(form.value.startDate),
		})
	}

	form = validateForm(state)(form)

	state = {
		...state,
		productCatalogueDetailForm: setValue(state.productCatalogueDetailForm, {
			...state.productCatalogueDetailForm.value,
			endDate: Util.ISOStringFormat(form.value.endDate),
			startDate: Util.ISOStringFormat(form.value.startDate),
		})
	}

	const controlId = action.controlId.split('.')[0]

	let productCatalogueRewardPoolsVal = formControls.value.productCatalogueRewardPools

	const newProductCatalogueRewardPools: ProductCatalogueState.ProductCatalogueRewardPools = {
		byId: {
			...productCatalogueRewardPoolsVal.byId
		},
		allIds: {
			...productCatalogueRewardPoolsVal.allIds
		}
	}

	const productCatalogueRewardPools = newProductCatalogueRewardPools.byId[controlId]

	if (productCatalogueRewardPools) {
		let form = formGroupReducer(productCatalogueRewardPools.form, action)

		if (action.type === 'ngrx/forms/SET_VALUE') {
			form = formGroupReducer(productCatalogueRewardPools.form, new SetValueAction(action.controlId, action.value))
		}

		productCatalogueRewardPoolsVal = {
			byId: {
				...productCatalogueRewardPoolsVal.byId,
				[controlId]: {
					...productCatalogueRewardPoolsVal.byId[controlId],
					form: validateProductCatalogueRewardPoolForm(form)
				}
			},
			allIds: [...productCatalogueRewardPoolsVal.allIds]
		}

		state = {
			...state,
			productCatalogueDetailForm: setValue(state.productCatalogueDetailForm, {
				...state.productCatalogueDetailForm.value,
				productCatalogueRewardPools: productCatalogueRewardPoolsVal
			})
		}
	}

	return state
}
