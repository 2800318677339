import { Action, createReducer, on } from '@ngrx/store'
import * as DashboardRedemptionAction from './dashboard-redemption.actions'
import * as DashboardRedemptionState from './dashboard-redemption.state'
import { setValue, formGroupReducer } from 'ngrx-forms'

export const reducer = createReducer(
	DashboardRedemptionState.initialState,
	on(DashboardRedemptionAction.InitialState, () => DashboardRedemptionState.initialState),
	on(DashboardRedemptionAction.RestError, state => ({
		...state,
		isLoading: false
	})),
	on(DashboardRedemptionAction.RewardPoolOptionList, state => ({
		...state,
		rewardPoolIsLoading: true
	})),
	on(DashboardRedemptionAction.RewardPoolOptionListSuccess, (state, { payload }) => ({
		...state,
		rewardPoolIsLoading: false,
		rewardPoolOptionList: payload,
		dashboardRedemptionRequestForm: setValue(state.dashboardRedemptionRequestForm, {
			rewardPoolId: !!payload.length ? payload[0].id : 0,
			customRewardPoolStartDate: state.dashboardRedemptionRequestForm.controls.customRewardPoolStartDate.value,
			rewardPoolStartMonth: state.dashboardRedemptionRequestForm.controls.rewardPoolStartMonth.value,
			rewardPoolEndMonth: state.dashboardRedemptionRequestForm.controls.rewardPoolEndMonth.value,
			rewardPoolGroupId: state.dashboardRedemptionRequestForm.controls.rewardPoolGroupId.value,
			customRewardPoolGroupStartDate: state.dashboardRedemptionRequestForm.controls.customRewardPoolGroupStartDate.value,
			rewardPoolGroupStartMonth: state.dashboardRedemptionRequestForm.controls.rewardPoolGroupStartMonth.value,
			rewardPoolGroupEndMonth: state.dashboardRedemptionRequestForm.controls.rewardPoolGroupEndMonth.value,
			redemptionItemId: state.dashboardRedemptionRequestForm.controls.redemptionItemId.value,
			customRedemptionItemStartDate: state.dashboardRedemptionRequestForm.controls.customRedemptionItemStartDate.value,
			redemptionItemStartMonth: state.dashboardRedemptionRequestForm.controls.redemptionItemStartMonth.value,
			redemptionItemEndMonth: state.dashboardRedemptionRequestForm.controls.redemptionItemEndMonth.value
		} as DashboardRedemptionState.DashboardRedemptionRequestData)
	})),
	on(DashboardRedemptionAction.ViewRewardPoolRedemption, state => ({
		...state,
		rewardPoolIsLoading: true
	})),
	on(DashboardRedemptionAction.ViewRewardPoolRedemptionSuccess, (state, { payload }) => ({
		...state,
		rewardPoolIsLoading: false,
		rewardPoolViewResponse: payload
	})),
	on(DashboardRedemptionAction.RewardPoolGroupOptionList, state => ({
		...state,
		rewardPoolGroupIsLoading: true
	})),
	on(DashboardRedemptionAction.RewardPoolGroupOptionListSuccess, (state, { payload }) => ({
		...state,
		rewardPoolGroupIsLoading: false,
		rewardPoolGroupOptionList: payload,
		dashboardRedemptionRequestForm: setValue(state.dashboardRedemptionRequestForm, {
			rewardPoolId: state.dashboardRedemptionRequestForm.controls.rewardPoolId.value,
			customRewardPoolStartDate: state.dashboardRedemptionRequestForm.controls.customRewardPoolStartDate.value,
			rewardPoolStartMonth: state.dashboardRedemptionRequestForm.controls.rewardPoolStartMonth.value,
			rewardPoolEndMonth: state.dashboardRedemptionRequestForm.controls.rewardPoolEndMonth.value,
			rewardPoolGroupId: !!payload.length ? payload[0].id : 0,
			customRewardPoolGroupStartDate: state.dashboardRedemptionRequestForm.controls.customRewardPoolGroupStartDate.value,
			rewardPoolGroupStartMonth: state.dashboardRedemptionRequestForm.controls.rewardPoolGroupStartMonth.value,
			rewardPoolGroupEndMonth: state.dashboardRedemptionRequestForm.controls.rewardPoolGroupEndMonth.value,
			redemptionItemId: state.dashboardRedemptionRequestForm.controls.redemptionItemId.value,
			customRedemptionItemStartDate: state.dashboardRedemptionRequestForm.controls.customRedemptionItemStartDate.value,
			redemptionItemStartMonth: state.dashboardRedemptionRequestForm.controls.redemptionItemStartMonth.value,
			redemptionItemEndMonth: state.dashboardRedemptionRequestForm.controls.redemptionItemEndMonth.value
		} as DashboardRedemptionState.DashboardRedemptionRequestData)
	})),
	on(DashboardRedemptionAction.ViewRewardPoolGroupRedemption, state => ({
		...state,
		rewardPoolGroupIsLoading: true
	})),
	on(DashboardRedemptionAction.ViewRewardPoolGroupRedemptionSuccess, (state, { payload }) => ({
		...state,
		rewardPoolGroupIsLoading: false,
		rewardPoolGroupViewResponse: payload
	})),
	on(DashboardRedemptionAction.InitialRedemptionItemOptionList, state => ({
		...state,
		redemptionItemIsLoading: true
	})),
	on(DashboardRedemptionAction.InitialRedemptionItemOptionListSuccess, (state, { payload }) => ({
		...state,
		redemptionItemIsLoading: false,
		redemptionItemOptionList: payload,
		dashboardRedemptionRequestForm: setValue(state.dashboardRedemptionRequestForm, {
			rewardPoolId: state.dashboardRedemptionRequestForm.controls.rewardPoolId.value,
			customRewardPoolStartDate: state.dashboardRedemptionRequestForm.controls.customRewardPoolStartDate.value,
			rewardPoolStartMonth: state.dashboardRedemptionRequestForm.controls.rewardPoolStartMonth.value,
			rewardPoolEndMonth: state.dashboardRedemptionRequestForm.controls.rewardPoolEndMonth.value,
			rewardPoolGroupId: state.dashboardRedemptionRequestForm.controls.rewardPoolGroupId.value,
			customRewardPoolGroupStartDate: state.dashboardRedemptionRequestForm.controls.customRewardPoolGroupStartDate.value,
			rewardPoolGroupStartMonth: state.dashboardRedemptionRequestForm.controls.rewardPoolGroupStartMonth.value,
			rewardPoolGroupEndMonth: state.dashboardRedemptionRequestForm.controls.rewardPoolGroupEndMonth.value,
			redemptionItemId: !!payload.length ? payload[0].id : 0,
			customRedemptionItemStartDate: state.dashboardRedemptionRequestForm.controls.customRedemptionItemStartDate.value,
			redemptionItemStartMonth: state.dashboardRedemptionRequestForm.controls.redemptionItemStartMonth.value,
			redemptionItemEndMonth: state.dashboardRedemptionRequestForm.controls.redemptionItemEndMonth.value
		} as DashboardRedemptionState.DashboardRedemptionRequestData)
	})),
	on(DashboardRedemptionAction.RedemptionItemCashbackOptionList, state => ({
		...state,
		redemptionItemIsLoading: true
	})),
	on(DashboardRedemptionAction.RedemptionItemCashbackOptionListSuccess, (state, { payload }) => ({
		...state,
		redemptionItemIsLoading: false,
		redemptionItemOptionList: payload,
		dashboardRedemptionRequestForm: setValue(state.dashboardRedemptionRequestForm, {
			rewardPoolId: state.dashboardRedemptionRequestForm.controls.rewardPoolId.value,
			customRewardPoolStartDate: state.dashboardRedemptionRequestForm.controls.customRewardPoolStartDate.value,
			rewardPoolStartMonth: state.dashboardRedemptionRequestForm.controls.rewardPoolStartMonth.value,
			rewardPoolEndMonth: state.dashboardRedemptionRequestForm.controls.rewardPoolEndMonth.value,
			rewardPoolGroupId: state.dashboardRedemptionRequestForm.controls.rewardPoolGroupId.value,
			customRewardPoolGroupStartDate: state.dashboardRedemptionRequestForm.controls.customRewardPoolGroupStartDate.value,
			rewardPoolGroupStartMonth: state.dashboardRedemptionRequestForm.controls.rewardPoolGroupStartMonth.value,
			rewardPoolGroupEndMonth: state.dashboardRedemptionRequestForm.controls.rewardPoolGroupEndMonth.value,
			redemptionItemId: !!payload.length ? payload[0].id : 0,
			customRedemptionItemStartDate: state.dashboardRedemptionRequestForm.controls.customRedemptionItemStartDate.value,
			redemptionItemStartMonth: state.dashboardRedemptionRequestForm.controls.redemptionItemStartMonth.value,
			redemptionItemEndMonth: state.dashboardRedemptionRequestForm.controls.redemptionItemEndMonth.value
		} as DashboardRedemptionState.DashboardRedemptionRequestData)
	})),
	on(DashboardRedemptionAction.ViewRedemptionItemCashback, state => ({
		...state,
		redemptionItemIsLoading: true
	})),
	on(DashboardRedemptionAction.ViewRedemptionItemCashbackSuccess, (state, { payload }) => ({
		...state,
		redemptionItemIsLoading: false,
		redemptionItemViewResponse: {
			...state.redemptionItemViewResponse,
			cashback: payload
		},
		totalPoints: payload.total
	})),
	on(DashboardRedemptionAction.ViewRedemptionItemPartner, state => ({
		...state,
		redemptionItemIsLoading: true
	})),
	on(DashboardRedemptionAction.ViewRedemptionItemPartnerSuccess, (state, { payload }) => ({
		...state,
		redemptionItemIsLoading: false,
		redemptionItemViewResponse: {
			...state.redemptionItemViewResponse,
			partner: payload
		},
	})),
	on(DashboardRedemptionAction.ViewRedemptionItemWaiver, state => ({
		...state,
		redemptionItemIsLoading: true
	})),
	on(DashboardRedemptionAction.ViewRedemptionItemWaiverSuccess, (state, { payload }) => ({
		...state,
		redemptionItemIsLoading: false,
		redemptionItemViewResponse: {
			...state.redemptionItemViewResponse,
			waiver: payload
		},
	})),
	on(DashboardRedemptionAction.ViewRedemptionItemOnTheSpot, state => ({
		...state,
		redemptionItemIsLoading: true
	})),
	on(DashboardRedemptionAction.ViewRedemptionItemOnTheSpotSuccess, (state, { payload }) => ({
		...state,
		redemptionItemIsLoading: false,
		redemptionItemViewResponse: {
			...state.redemptionItemViewResponse,
			onTheSpot: payload
		},
	})),
	on(DashboardRedemptionAction.ViewCatalogueList, state => ({
		...state,
		catalogueListIsLoading: true
	})),
	on(DashboardRedemptionAction.ViewCatalogueListSuccess, (state, { payload }) => ({
		...state,
		catalogueListIsLoading: false,
		catalogueListViewResponse: payload
	})),
	on(DashboardRedemptionAction.RedemptionItemPartnerOptionList, state => ({
		...state,
		redemptionItemIsLoading: true
	})),
	on(DashboardRedemptionAction.RedemptionItemPartnerOptionListSuccess, (state, { payload }) => ({
		...state,
		redemptionItemIsLoading: false,
		redemptionItemOptionList: payload,
		dashboardRedemptionRequestForm: setValue(state.dashboardRedemptionRequestForm, {
			rewardPoolId: state.dashboardRedemptionRequestForm.controls.rewardPoolId.value,
			customRewardPoolStartDate: state.dashboardRedemptionRequestForm.controls.customRewardPoolStartDate.value,
			rewardPoolStartMonth: state.dashboardRedemptionRequestForm.controls.rewardPoolStartMonth.value,
			rewardPoolEndMonth: state.dashboardRedemptionRequestForm.controls.rewardPoolEndMonth.value,
			rewardPoolGroupId: state.dashboardRedemptionRequestForm.controls.rewardPoolGroupId.value,
			customRewardPoolGroupStartDate: state.dashboardRedemptionRequestForm.controls.customRewardPoolGroupStartDate.value,
			rewardPoolGroupStartMonth: state.dashboardRedemptionRequestForm.controls.rewardPoolGroupStartMonth.value,
			rewardPoolGroupEndMonth: state.dashboardRedemptionRequestForm.controls.rewardPoolGroupEndMonth.value,
			redemptionItemId: !!payload.length ? payload[0].id : 0,
			customRedemptionItemStartDate: state.dashboardRedemptionRequestForm.controls.customRedemptionItemStartDate.value,
			redemptionItemStartMonth: state.dashboardRedemptionRequestForm.controls.redemptionItemStartMonth.value,
			redemptionItemEndMonth: state.dashboardRedemptionRequestForm.controls.redemptionItemEndMonth.value
		} as DashboardRedemptionState.DashboardRedemptionRequestData)
	})),
	on(DashboardRedemptionAction.RedemptionItemWaiverOptionList, state => ({
		...state,
		redemptionItemIsLoading: true
	})),
	on(DashboardRedemptionAction.RedemptionItemWaiverOptionListSuccess, (state, { payload }) => ({
		...state,
		redemptionItemIsLoading: false,
		waiverRedemptionItemOptionList: payload,
		dashboardRedemptionRequestForm: setValue(state.dashboardRedemptionRequestForm, {
			rewardPoolId: state.dashboardRedemptionRequestForm.controls.rewardPoolId.value,
			customRewardPoolStartDate: state.dashboardRedemptionRequestForm.controls.customRewardPoolStartDate.value,
			rewardPoolStartMonth: state.dashboardRedemptionRequestForm.controls.rewardPoolStartMonth.value,
			rewardPoolEndMonth: state.dashboardRedemptionRequestForm.controls.rewardPoolEndMonth.value,
			rewardPoolGroupId: state.dashboardRedemptionRequestForm.controls.rewardPoolGroupId.value,
			customRewardPoolGroupStartDate: state.dashboardRedemptionRequestForm.controls.customRewardPoolGroupStartDate.value,
			rewardPoolGroupStartMonth: state.dashboardRedemptionRequestForm.controls.rewardPoolGroupStartMonth.value,
			rewardPoolGroupEndMonth: state.dashboardRedemptionRequestForm.controls.rewardPoolGroupEndMonth.value,
			redemptionItemId: !!payload.length ? payload[0].waiverId : 0,
			customRedemptionItemStartDate: state.dashboardRedemptionRequestForm.controls.customRedemptionItemStartDate.value,
			redemptionItemStartMonth: state.dashboardRedemptionRequestForm.controls.redemptionItemStartMonth.value,
			redemptionItemEndMonth: state.dashboardRedemptionRequestForm.controls.redemptionItemEndMonth.value
		} as DashboardRedemptionState.DashboardRedemptionRequestData)
	})),
	on(DashboardRedemptionAction.RedemptionItemOnTheSpotOptionList, state => ({
		...state,
		redemptionItemIsLoading: true
	})),
	on(DashboardRedemptionAction.RedemptionItemOnTheSpotOptionListSuccess, (state, { payload }) => ({
		...state,
		redemptionItemIsLoading: false,
		redemptionItemOptionList: payload,
		dashboardRedemptionRequestForm: setValue(state.dashboardRedemptionRequestForm, {
			rewardPoolId: state.dashboardRedemptionRequestForm.controls.rewardPoolId.value,
			customRewardPoolStartDate: state.dashboardRedemptionRequestForm.controls.customRewardPoolStartDate.value,
			rewardPoolStartMonth: state.dashboardRedemptionRequestForm.controls.rewardPoolStartMonth.value,
			rewardPoolEndMonth: state.dashboardRedemptionRequestForm.controls.rewardPoolEndMonth.value,
			rewardPoolGroupId: state.dashboardRedemptionRequestForm.controls.rewardPoolGroupId.value,
			customRewardPoolGroupStartDate: state.dashboardRedemptionRequestForm.controls.customRewardPoolGroupStartDate.value,
			rewardPoolGroupStartMonth: state.dashboardRedemptionRequestForm.controls.rewardPoolGroupStartMonth.value,
			rewardPoolGroupEndMonth: state.dashboardRedemptionRequestForm.controls.rewardPoolGroupEndMonth.value,
			redemptionItemId: !!payload.length ? payload[0].id : 0,
			customRedemptionItemStartDate: state.dashboardRedemptionRequestForm.controls.customRedemptionItemStartDate.value,
			redemptionItemStartMonth: state.dashboardRedemptionRequestForm.controls.redemptionItemStartMonth.value,
			redemptionItemEndMonth: state.dashboardRedemptionRequestForm.controls.redemptionItemEndMonth.value
		} as DashboardRedemptionState.DashboardRedemptionRequestData)
	}))
)

export function Reducer(state: DashboardRedemptionState.State = DashboardRedemptionState.initialState, action: Action) {
	const dashboardRedemptionRequestForm = DashboardRedemptionState.validateDashboardRedemptionViewRequestForm(state)(formGroupReducer(state.dashboardRedemptionRequestForm, action))
	if (dashboardRedemptionRequestForm !== state.dashboardRedemptionRequestForm) {
		state = { ...state, dashboardRedemptionRequestForm }
	}
	return reducer(state, action)
}
