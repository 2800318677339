<mat-dialog-content *ngIf="content">
	<div class="dialog-container">
		<label class="pd-btm-10px block">{{ content | translate }}</label>
		<div>
			<div>
				<textarea class="txtArea" [(ngModel)]="txtAreaInput" rows="8" required maxlength="100"></textarea>
			</div>
			<label class="txtSize-10pt block right pd-btm-10px counter">Max {{ txtAreaInput.length }} / 100</label>
		</div>
		<div fxLayout="row" fxLayoutAlign="end">
			<div>
				<button class="mat-raised-button mat-primary" (click)="cancel()"
					fxFlexOffset="8px">{{ 'ACTION.CANCEL' | translate }}</button>
				<button class="mat-raised-button mat-primary" (click)="submit()" [disabled]="!txtAreaInput.length"
					fxFlexOffset="8px">{{ 'ACTION.SUBMIT' | translate }}</button>
			</div>
		</div>
	</div>
</mat-dialog-content>