import { Inject, OnDestroy, ViewChild } from '@angular/core'
import { Component, OnInit } from '@angular/core'
import { MatTableDataSource, MAT_DIALOG_DATA, MatDialogRef, MatPaginator } from '@angular/material'
import { Store } from '@ngrx/store'
import { Subscription } from 'rxjs'
import { CustomerCardViewResponse, CardTransactionListDataColumns, CustomerCardTransactionListData } from 'src/app/models/customer-management/customer-card-detail'
import * as AppStore from 'src/app/store'
import * as CustomerManagementAction from 'src/app/store/customer-management/customer-management.actions'
import * as CustomerManagementSelector from 'src/app/store/customer-management/customer-management.selectors'
import * as ActionBarAction from 'src/app/store/common/action-bar/action-bar.actions'
import { Option } from 'src/app/models/option/option'
import { CustomerStatementTransactionDataColumns, CustomerStatementTransactionViewResponse } from 'src/app/models/customer-management/customer-statement-transaction-detail'
import { AccountTransactionListDataColumns, CustomerAccountTransactionListData } from 'src/app/models/customer-management/customer-account-detail'
import { ActivatedRoute, Router } from '@angular/router'

@Component({
	selector: 'app-customer-management-statement-transaction-list-view',
	templateUrl: './customer-management-statement-transaction-list-view.component.html',
	styleUrls: ['./customer-management-statement-transaction-list-view.component.scss']
})
export class CustomerManagementStatementTransactionListViewComponent implements OnInit, OnDestroy {

	isLoading = false
	isTableLoading = false
	subs: Subscription

	pageNumber = 0
	pageSize = 5
	totalItem = 0

	cardInformation: CustomerCardViewResponse
	startDate: string
	endDate: string
	rewardPoolId: number
	initialRewardPoolId = '0'
	selectionList: Option[]
	rewardPage = false
	accountNo: string
	isCreditCardPage = false
	cardNo: string
	productType: string

	cardDisplayedColumns: CardTransactionListDataColumns[] = ['postingDate', 'description', 'transactionDate', 'campaignName', 'transactionCode', 'amount', 'points', 'kind']
	cardDataSource: MatTableDataSource<CustomerCardTransactionListData>

	accountDisplayedColumns: AccountTransactionListDataColumns[] = ['description', 'transactionDate', 'points']
	accountDataSource: MatTableDataSource<CustomerAccountTransactionListData>

	statementDisplayedColumns: CustomerStatementTransactionDataColumns[] = ['cycle', 'accumulation', 'creditAdjustment', 'debitAdjustment', 'redeem', 'currentBalance']
	statementDataSource: MatTableDataSource<CustomerStatementTransactionViewResponse>

	@ViewChild(MatPaginator, { static: false }) paginator: MatPaginator

	constructor(
		public activedRoute: ActivatedRoute,
		private router: Router,
		private store: Store<AppStore.State>
	) {
		this.cardDataSource = new MatTableDataSource()
		this.accountDataSource = new MatTableDataSource()
		this.statementDataSource = new MatTableDataSource()

		this.subs = this.store.select(CustomerManagementSelector.getCustomerManagement).subscribe(x => {
			if (x.id === undefined) {
				this.router.navigate(['customer-management'])
			}
			this.isLoading = x.isLoading
			this.isTableLoading = x.isTableLoading
			this.rewardPage = x.rewardPage
			this.startDate = x.startDate
			this.endDate = x.endDate
			this.cardInformation = x.customerCardViewResponse
			this.selectionList = x.cardTransactionOptionList
			this.rewardPoolId = x.rewardPoolId
			this.accountNo = x.accountNumber
			this.isCreditCardPage = x.isCreditCard
			this.cardNo = x.cardNumber
			this.productType = this.isCreditCardPage === true ? x.customerCardViewResponse.cardType : x.customerAccountViewResponse.productType
			const cardResponse = x.customerCardViewTransactionResponse
			const accountResponse = x.customerAccountViewTransactionListResponse
			const statementList = x.customerStatementTransactionListResponse
			if (cardResponse && this.isCreditCardPage) {
				this.cardDataSource.data = cardResponse.transactionList
				this.pageNumber = cardResponse.pageHeader.pageNum
				this.pageSize = cardResponse.pageHeader.pageSize
				this.totalItem = cardResponse.pageHeader.totalItem
			}

			if (accountResponse && !this.isCreditCardPage) {
				this.accountDataSource.data = accountResponse.transactionList
				this.pageNumber = accountResponse.pageHeader.pageNum
				this.pageSize = accountResponse.pageHeader.pageSize
				this.totalItem = accountResponse.pageHeader.totalItem
			}

			if (statementList) {
				this.statementDataSource.data = statementList.statementList.filter( o => o.statementEndDate === this.endDate && o.statementStartDate === this.startDate)
			}
		})

	}

	ngOnInit() {
		if (this.isCreditCardPage) {
			this.store.dispatch(ActionBarAction.CustomerManagementTransactionDetailView())
		} else {
			this.store.dispatch(ActionBarAction.CustomerManagementAccountTransactionDetailView())
		}
		this.cardDataSource.paginator = this.paginator
		this.accountDataSource.paginator = this.paginator
		this.getTransaction()
	}

	ngOnDestroy() {
		this.subs.unsubscribe()
	}

	getTransaction() {
		if (this.isCreditCardPage) {
			if (this.rewardPoolId !== 0) {
				this.store.dispatch(CustomerManagementAction.GetCardStatementTransactionListByCycle({
					startDate: this.startDate,
					endDate: this.endDate,
					accountNumber: this.accountNo,
					cardNumber: this.cardInformation.cardNumber,
					relationship: this.cardInformation.primaryOrSupplementaryFlag.key,
					pageNumber: this.paginator && this.paginator.pageIndex ? this.paginator.pageIndex : 0,
					pageSize: this.paginator && this.paginator.pageSize ? this.paginator.pageSize : 5,
					rewardPoolId: this.rewardPoolId
				}))
			} else {
				this.store.dispatch(CustomerManagementAction.CardTransactionList({
					startDate: this.startDate,
					endDate: this.endDate,
					accountNumber: this.accountNo,
					cardNumber: this.cardInformation.cardNumber,
					relationship: this.cardInformation.primaryOrSupplementaryFlag.key,
					pageNumber: this.paginator && this.paginator.pageIndex ? this.paginator.pageIndex : 0,
					pageSize: this.paginator && this.paginator.pageSize ? this.paginator.pageSize : 5,
				}))
			}
		} else {
			this.store.dispatch(CustomerManagementAction.AccountTransactionListByCycle({
				rewardPoolId: this.rewardPoolId === 0 ? null : this.rewardPoolId,
				startDate: this.startDate,
				endDate: this.endDate,
				pageNumber: this.paginator && this.paginator.pageIndex ? this.paginator.pageIndex : 0,
				pageSize: this.paginator && this.paginator.pageSize ? this.paginator.pageSize : 5,
			}))
		}
	}

	onRowClicked(row: CustomerAccountTransactionListData) {
		if (row.transactionId !== 0) {
			this.store.dispatch(CustomerManagementAction.AccountTransactionView({ id: row.transactionId }))
		}
	}

	onCreditCardRowClicked(row: CustomerCardTransactionListData) {
		if (row.kind === 'CB') {
			this.store.dispatch(CustomerManagementAction.GoTransactionBreakdownDialog({ payload: row.pointTransactionId }))
		}
	}
}
