import {Component, Inject, OnDestroy, OnInit} from '@angular/core'
import {Observable, Subscription} from 'rxjs'
import {FormGroupState} from 'ngrx-forms'
import {Store} from '@ngrx/store'
import * as AppStore from 'src/app/store'
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material'
import {TcGroupDuplicateForm} from 'src/app/store/param-settings/tc-group'
import * as TCGroupSelectors from 'src/app/store/param-settings/tc-group/tc-group.selectors'
import * as TCGroupAction from 'src/app/store/param-settings/tc-group/tc-group.actions'

@Component({
	selector: 'app-tc-group-duplicate',
	templateUrl: './tc-group-duplicate.component.html',
	styleUrls: ['./tc-group-duplicate.component.scss']
})
export class TcGroupDuplicateComponent implements OnDestroy {

	isLoading = false
	formState$: Observable<FormGroupState<TcGroupDuplicateForm>>

	subs: Subscription

	constructor(
		private store: Store<AppStore.State>,
		private dialogRef: MatDialogRef<TcGroupDuplicateComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		this.subs = this.store.select(TCGroupSelectors.getTcGroup).subscribe(({isDuplicateLoading, isDuplicateDialog}) => {
			this.isLoading = isDuplicateLoading

			if (isDuplicateDialog) {
				this.cancel()
			}
		})

		this.formState$ = this.store.select(({tcGroup}) => tcGroup.tcGroupDuplicateForm)
	}

	ngOnDestroy() {
		this.subs.unsubscribe()
	}

	cancel() {
		this.dialogRef.close()
	}

	submit() {
		this.store.dispatch(TCGroupAction.Duplicate())
	}
}
