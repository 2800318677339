import { KeyValue } from '@angular/common'
import { createAction, props } from '@ngrx/store'
import { TopCampaignExpiring, PointRedeemUtilization, CampaignCappingUtilizationResponse, CampaignCappingUtlizationRequest, TopPerformanceCampaign, CampaignTotalPointComparison, PublishedCampaign, PointsRedemptionAnalysisResponse, PointsRedemptionAnalysisForComparisonResponse } from '../../../models/dashboard/dashboard-overview'
import { MccTransactionList, MccTransactionTotalAmount } from '../../../models/dashboard/dashboard-overview'
import { Option } from 'src/app/models/option/option'
import { RewardPoolGroupAnalysis } from '../../../models/dashboard/dashboard-overview'
import { PendingApprovalTask, RewardPoolAnalysis } from '../../../models/dashboard/dashboard-overview'

export const InitialState = createAction('[DashboardV2] InitialState')

export const Reset = createAction('[DashboardV2] Reset')

export const RestError = createAction('[DashboardV2] RestError', props<{ message: string; manualThrow: boolean }>())

export const ViewTop5PerformanceCampaign = createAction('[DashboardV2] Get Top 5 Performance Campaign')

export const ViewTop5PerformanceCampaignSuccess = createAction(
	'[DashboardV2] Get Top 5 Performance Campaign Success',
	props<{ payload: TopPerformanceCampaign[] }>()
)

export const ViewPointRedeemUtilization = createAction('[Dashboardv2] Get Point Redeem Utilization')

export const ViewPointRedeemUtilizationSuccess = createAction(
	'[Dashboard2] Get Point Redeem Utilization Success',
	props<{ payload: PointRedeemUtilization[] }>()
)

export const ViewTop5CampaignExpiring = createAction('[DashboardV2] Get Top 5 Campaign Expiring')

export const ViewTop5CampaignExpiringSuccess = createAction(
	'[DashboardV2] Get Top 5 Campaign Expiring Success',
	props<{ payload: TopCampaignExpiring[] }>()
)

export const ViewTop5CampaignCappingUtilization = createAction('[DashboardV2] Get Top 5 Campaign Capping Utilization',
	props<{ payload: string }>()
)

export const ViewTop5CampaignCappingUtilizationSuccess = createAction(
	'[DashboardV2] Get Top 5 Campaign Capping Utilization Success',
	props<{ payload: CampaignCappingUtilizationResponse[] }>()
)

export const ViewCampaignTotalPointComparison = createAction('[DashboardV2] Get Campaign Comparison', props<{ payload: number }>())

export const ViewCampaignTotalPointComparisonSuccess = createAction(
	'[DashboardV2] Get Campaign Comparison Success',
	props<{
		payload: {
			campaignId: string,
			totalPoints: CampaignTotalPointComparison[]
		}
	}>()
)

export const ViewCampaignChannelList = createAction('[DashboardV2] Get Campaign Channel List')

export const ViewCampaignChannelListSuccess = createAction(
	'[DashboardV2] Get Campaign Channel List Success',
	props<{ payload: KeyValue<string, string>[] }>()
)

export const GetPublishedCampaignList = createAction('[DashboardV2] Get Published Campaign Detail', props<{ payload: string }>())

export const GetPublishedCampaignListSuccess = createAction(
	'[DashboardV2] Get Published Campaign Detail Success',
	props<{ payload: PublishedCampaign[] }>())


export const setCampaignId1 = createAction(
	'[DashboardV2] Set first Campaign ID',
	props<{ campaignId1: number }>())

export const setCampaignId2 = createAction(
	'[DashboardV2] Set Second Campaign ID',
	props<{ campaignId2: number }>())

export const setCampaignChannel = createAction(
	'[DashboardV2] Set Campaign Channel',
	props<{ campaignChannel: string }>())

export const DurationOptionList = createAction('[DashboardV2] List Duration Options')

export const DurationOptionListSuccess = createAction(
	'[DashboardV2] List Duration Options Success', props<{ payload: Option[] }>()
)

export const GetDateRange = createAction('[DashboardV2] Get Date Range')

export const ViewPendingApprovalTask = createAction('[DashboardV2] Pending Appproval Task List')

export const ViewPendingApprovalTaskSuccess = createAction(
	'[DashboardV2] Pending Appproval Task List Success',
	props<{ payload: PendingApprovalTask[] }>()
)

export const GetComparisonDaysList = createAction('[DashboardV2] List Days For Comparison')

export const GetComparisonDaysListSuccess = createAction(
	'[DashboardV2] List Days For Comparison Success', props<{ payload: Option[] }>()
)

export const GetRedemptionTypeList = createAction('[DashboardV2] List Redemption Types')

export const GetRedemptionTypeListSuccess = createAction(
	'[DashboardV2] List Redemption Types Success', props<{ payload: Option[]}>()
)

export const GetPointsRedemptionAnalysis = createAction('[DashboardV2] Get Points Redemption Analysis')

export const GetPointsRedemptionAnalysisSuccess = createAction(
	'[DashboardV2] Get Points Redemption Analysis Success',
	props<{ payload: PointsRedemptionAnalysisResponse[] }>()
)

export const GetPointsRedemptionAnalysisForComparison = createAction('[DashboardV2] Get Points Redemption Analysis For Comparison')

export const GetPointsRedemptionAnalysisForComparisonSuccess = createAction(
	'[DashboardV2] Get Points Redemption Analysis For Comparison Success',
	props<{ payload: PointsRedemptionAnalysisForComparisonResponse[] }>()
)

export const GetMaxMinVarList = createAction('[DashboaerdV2] Get List Highest Lowest Option')

export const GetMaxMinVarListSuccess = createAction(
	'[DashboardV2] Get List Highest Lowest Success', props<{ payload: Option[] }>()
)

export const setMaxMin = createAction(
	'[DashboardV2] Set Value of Highest Lowest',
	props<{ maxMinVar: string }>()
)

export const ViewMccTransactionList = createAction('[DaxhboardV2] Get Mcc Transaction List')

export const ViewMccTransactionListSuccess = createAction(
	'[DashboardV2] Get Mcc Transaction List Success', props<{ payload: MccTransactionTotalAmount }>()
)

export const ViewRewardPoolAnalysis = createAction('[DashboardV2] Reward Pool Analysis')

export const ViewRewardPoolAnalysisSuccess = createAction(
	'[DashboardV2] Reward Pool Analysis Success',
	props<{ payload: RewardPoolAnalysis[] }>()
)

export const ViewRewardPoolGroupAnalysis = createAction('[DashboardV2] Reward Pool Group Analysis')

export const ViewRewardPoolGroupAnalysisSuccess = createAction(
	'[DashboardV2] Reward Pool Group Analysis Success',
	props<{ payload: RewardPoolGroupAnalysis[] }>()
)

export const ViewRewardPoolAnalysisOptionList = createAction('[DashboardV2] Reward Pool Analysis Option List')

export const ViewRewardPoolAnalysisOptionListSuccess = createAction(
	'[DashboardV2] Reward Pool Analysis Option List Success',
	props<{ payload: Option[] }>()
)

export const ViewRewardPoolAnalysisRewardPoolList = createAction('[DashboardV2] Reward Pool Analysis Reward Pool List')

export const ViewRewardPoolAnalysisRewardPoolListSuccess = createAction(
	'[DashboardV2] Reward Pool Analysis Reward Pool List Success',
	props<{ payload: Option[] }>()
)

export const ViewRewardPoolAnalysisRewardPoolGroupList = createAction('[DashboardV2] Reward Pool Analysis Reward Pool Group List')

export const ViewRewardPoolAnalysisRewardPoolGroupListSuccess = createAction(
	'[DashboardV2] Reward Pool Analysis Reward Pool Group List Success',
	props<{ payload: Option[] }>()
)
