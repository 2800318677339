import { KeyValue } from '@angular/common'
import { Type } from '@angular/core'
import { AccountBlockCodeComponent } from 'src/app/components/campaign-management/campaign/rule/rule/account-block-code/account-block-code.component'
import { AccountStatusComponent } from 'src/app/components/campaign-management/campaign/rule/rule/account-status/account-status.component'
import { ApprovalCodeComponent } from 'src/app/components/campaign-management/campaign/rule/rule/approval-code/approval-code.component'
import { BirthdayComponent } from 'src/app/components/campaign-management/campaign/rule/rule/birthday/birthday.component'
import { CardBlockCodeComponent } from 'src/app/components/campaign-management/campaign/rule/rule/card-block-code/card-block-code.component'
import { CardStatusComponent } from 'src/app/components/campaign-management/campaign/rule/rule/card-status/card-status.component'
import { ChooseDateComponent } from 'src/app/components/campaign-management/campaign/rule/rule/choose-date/choose-date.component'
import { ComparisonDateComponent } from 'src/app/components/campaign-management/campaign/rule/rule/comparison-date/comparison-date.component'
import { CountryCodeComponent } from 'src/app/components/campaign-management/campaign/rule/rule/country-code/country-code.component'
import { CustomerBlockCodeComponent } from 'src/app/components/campaign-management/campaign/rule/rule/customer-block-code/customer-block-code.component'
import { CustomerStatusComponent } from 'src/app/components/campaign-management/campaign/rule/rule/customer-status/customer-status.component'
import { DynamicAmountComponent } from 'src/app/components/campaign-management/campaign/rule/rule/dynamic-amount/dynamic-amount.component'
import { DynamicBalanceComponent } from 'src/app/components/campaign-management/campaign/rule/rule/dynamic-balance/dynamic-balance.component'
import { DynamicDateComponent } from 'src/app/components/campaign-management/campaign/rule/rule/dynamic-date/dynamic-date.component'
import { DynamicDescriptionComponent } from 'src/app/components/campaign-management/campaign/rule/rule/dynamic-description/dynamic-description.component'
import { DynamicIndicatorComponent } from 'src/app/components/campaign-management/campaign/rule/rule/dynamic-indicator/dynamic-indicator.component'
import { ECommerceIndComponent } from 'src/app/components/campaign-management/campaign/rule/rule/e-commerce-ind/e-commerce-ind.component'
import { MccComponent } from 'src/app/components/campaign-management/campaign/rule/rule/mcc/mcc.component'
import { MemberSinceComponent } from 'src/app/components/campaign-management/campaign/rule/rule/member-since/member-since.component'
import { MerchantComponent } from 'src/app/components/campaign-management/campaign/rule/rule/merchant/merchant.component'
import { PosEntryModeComponent } from 'src/app/components/campaign-management/campaign/rule/rule/pos-entry-mode/pos-entry-mode.component'
import { PostingCurrencyCodeComponent } from 'src/app/components/campaign-management/campaign/rule/rule/posting-currency-code/posting-currency-code.component'
import { ProductTypeComponent } from 'src/app/components/campaign-management/campaign/rule/rule/product-type/product-type.component'
import { RecurringIndComponent } from 'src/app/components/campaign-management/campaign/rule/rule/recurring-ind/recurring-ind.component'
import { SegmentComponent } from 'src/app/components/campaign-management/campaign/rule/rule/segment/segment.component'
import { SelectedDayComponent } from 'src/app/components/campaign-management/campaign/rule/rule/selected-day/selected-day.component'
import { StaffIndAccComponent } from 'src/app/components/campaign-management/campaign/rule/rule/staff-ind-acc/staff-ind-acc.component'
import { StaffIndComponent } from 'src/app/components/campaign-management/campaign/rule/rule/staff-ind/staff-ind.component'
import { TargetAccountComponent } from 'src/app/components/campaign-management/campaign/rule/rule/target-account/target-account.component'
import { TargetCardComponent } from 'src/app/components/campaign-management/campaign/rule/rule/target-card/target-card.component'
import { TargetCustomerComponent } from 'src/app/components/campaign-management/campaign/rule/rule/target-customer/target-customer.component'
import { TransactionAmountComponent } from 'src/app/components/campaign-management/campaign/rule/rule/transaction-amount/transaction-amount.component'
import { TransactionCodeComponent } from 'src/app/components/campaign-management/campaign/rule/rule/transaction-code/transaction-code.component'
import { TransactionCurrencyCodeComponent } from 'src/app/components/campaign-management/campaign/rule/rule/transaction-currency-code/transaction-currency-code.component'
import { TransactionDescriptionComponent } from 'src/app/components/campaign-management/campaign/rule/rule/transaction-description/transaction-description.component'
import { TransactionModeComponent } from 'src/app/components/campaign-management/campaign/rule/rule/transaction-mode/transaction-mode.component'
import { VipIndComponent } from 'src/app/components/campaign-management/campaign/rule/rule/vip-ind/vip-ind.component'
import { POLICY } from 'src/app/models/common/constant'

export type RuleAddType = 'RULE' | 'CONDITIONAL_RULES'
export type PublishedDataColumns = 'code' | 'name' | 'startDate' | 'endDate' | 'channel' | 'rewardPool' | 'action'
export type DraftDataColumns = 'code' | 'name' | 'status' | 'channel' | 'modifiedDate' | 'action'
export type UnpublishedDataColumns = 'code' | 'name' | 'startDate' | 'endDate' | 'status' | 'channel' | 'action'
export type PbPublishedDataColumns = 'code' | 'name' | 'startDate' | 'endDate' | 'rewardPool' | 'action'
export type PbDraftDataColumns = 'code' | 'name' | 'status' | 'modifiedDate' | 'action'
export type PbUnpublishedDataColumns = 'code' | 'name' | 'startDate' | 'endDate' | 'status' | 'action'
export type RulesetDataColumns = 'code' | 'name' | 'channel' | 'modifiedDate' | 'rewardPool' | 'id'
export type CampaignInfoDataColumns = 'columnName' | 'from' | 'to'
export type CappingInfoDataColumns = 'cappingName' | 'from' | 'to'
export type CappingDetailDataColumns = 'columnName' | 'from' | 'to'
export type RulesetDetailDataColumns = 'columnName' | 'from' | 'to'

export const RuleType: KeyValue<string, Type<any>>[] = [
	{ key: 'CHOOSE_DATE', value: ChooseDateComponent },
	{ key: 'TRANSACTION_AMOUNT', value: TransactionAmountComponent },
	{ key: 'PRODUCT_TYPE', value: ProductTypeComponent },
	{ key: 'MCC', value: MccComponent },
	{ key: 'MERCHANT', value: MerchantComponent },
	{ key: 'SELECTED_DAY', value: SelectedDayComponent },
	{ key: 'TRANSACTION_CODE', value: TransactionCodeComponent },
	{ key: 'APPROVAL_CODE', value: ApprovalCodeComponent },
	{ key: 'TRANSACTION_DESCRIPTION', value: TransactionDescriptionComponent },
	{ key: 'POS_ENTRY_MODE', value: PosEntryModeComponent },
	{ key: 'TRANSACTION_MODE', value: TransactionModeComponent },
	{ key: 'POSTING_CURRENCY_CODE', value: PostingCurrencyCodeComponent },
	{ key: 'TRANSACTION_CURRENCY_CODE', value: TransactionCurrencyCodeComponent },
	{ key: 'CUSTOMER_BLOCK_CODE', value: CustomerBlockCodeComponent },
	{ key: 'ACCOUNT_BLOCK_CODE', value: AccountBlockCodeComponent },
	{ key: 'CARD_BLOCK_CODE', value: CardBlockCodeComponent },
	{ key: 'BIRTHDAY', value: BirthdayComponent },
	{ key: 'CUSTOMER_STATUS', value: CustomerStatusComponent },
	{ key: 'ACCOUNT_STATUS', value: AccountStatusComponent },
	{ key: 'CARD_STATUS', value: CardStatusComponent },
	{ key: 'MEMBER_SINCE', value: MemberSinceComponent },
	{ key: 'COMPARISON_DATE', value: ComparisonDateComponent },
	{ key: 'VIP_IND', value: VipIndComponent },
	{ key: 'STAFF_IND', value: StaffIndComponent },
	{ key: 'RECURRING_IND', value: RecurringIndComponent },
	{ key: 'E-COMMERCE_IND', value: ECommerceIndComponent },
	{ key: 'TARGET_CUSTOMER', value: TargetCustomerComponent },
	{ key: 'TARGET_ACCOUNT', value: TargetAccountComponent },
	{ key: 'TARGET_CARD', value: TargetCardComponent },
	{ key: 'SEGMENT', value: SegmentComponent },
	{ key: 'STAFF_IND_ACC', value: StaffIndAccComponent },
	{ key: 'COUNTRY_CODE', value: CountryCodeComponent },
	{ key: 'INDICATOR_1', value: DynamicIndicatorComponent },
	{ key: 'INDICATOR_2', value: DynamicIndicatorComponent },
	{ key: 'INDICATOR_3', value: DynamicIndicatorComponent },
	{ key: 'INDICATOR_4', value: DynamicIndicatorComponent },
	{ key: 'INDICATOR_5', value: DynamicIndicatorComponent },
	{ key: 'INDICATOR_6', value: DynamicIndicatorComponent },
	{ key: 'INDICATOR_7', value: DynamicIndicatorComponent },
	{ key: 'DESCRIPTION_1', value: DynamicDescriptionComponent },
	{ key: 'DESCRIPTION_2', value: DynamicDescriptionComponent },
	{ key: 'DESCRIPTION_3', value: DynamicDescriptionComponent },
	{ key: 'DESCRIPTION_4', value: DynamicDescriptionComponent },
	{ key: 'DESCRIPTION_5', value: DynamicDescriptionComponent },
	{ key: 'DESCRIPTION_6', value: DynamicDescriptionComponent },
	{ key: 'DESCRIPTION_7', value: DynamicDescriptionComponent },
	{ key: 'BALANCE_1', value: DynamicBalanceComponent },
	{ key: 'BALANCE_2', value: DynamicBalanceComponent },
	{ key: 'BALANCE_3', value: DynamicBalanceComponent },
	{ key: 'BALANCE_4', value: DynamicBalanceComponent },
	{ key: 'BALANCE_5', value: DynamicBalanceComponent },
	{ key: 'BALANCE_6', value: DynamicBalanceComponent },
	{ key: 'BALANCE_7', value: DynamicBalanceComponent },
	{ key: 'AMOUNT_1', value: DynamicAmountComponent },
	{ key: 'AMOUNT_2', value: DynamicAmountComponent },
	{ key: 'AMOUNT_3', value: DynamicAmountComponent },
	{ key: 'AMOUNT_4', value: DynamicAmountComponent },
	{ key: 'AMOUNT_5', value: DynamicAmountComponent },
	{ key: 'AMOUNT_6', value: DynamicAmountComponent },
	{ key: 'AMOUNT_7', value: DynamicAmountComponent },
	{ key: 'DATE_1', value: DynamicDateComponent },
	{ key: 'DATE_2', value: DynamicDateComponent },
	{ key: 'DATE_3', value: DynamicDateComponent },
	{ key: 'DATE_4', value: DynamicDateComponent },
	{ key: 'DATE_5', value: DynamicDateComponent },
	{ key: 'DATE_6', value: DynamicDateComponent },
	{ key: 'DATE_7', value: DynamicDateComponent }
]

export interface Published {
	kind: string
	code: string
	name: string
	startDate: string
	endDate: string
	channel: string
	rewardPool: string
}

export interface Draft {
	kind: string
	code: string
	name: string
	status: string
	channel: string
	modifiedDate: string
}

export interface Unpublished {
	kind: string
	code: string
	name: string
	startDate: string
	endDate: string
	status: string
	channel: string
}

export interface PbPublished {
	kind: string
	code: string
	name: string
	startDate: string
	endDate: string
	rewardPool: string
}

export interface PbDraft {
	kind: string
	code: string
	name: string
	status: string
	modifiedDate: string
}

export interface PbUnpublished {
	kind: string
	code: string
	name: string
	startDate: string
	endDate: string
	channel: string
}

export interface RulesetDataSource {
	id: string
	code: string
	name: string
	channel: string
	rewardPool: string
}

export interface InformationDetailForm {
	channel: string
	code: string
	name: string
	priority: string
	startDate: string
	endDate: string
	enableConditionalReward: boolean
}

export interface CampaignRuleset {
	id: string
	rule: RulesetDetailForm
	reward: RulesetRewardForm
}

export interface RulesetDetailForm {
	code: string
	name: string
	channel: string
	modifiedDate: string
	rewardPool: string
	conditions: Ruleset[]
}

export interface Ruleset {
	id: string
	ruleAddType: RuleAddType
	rule: string
	conditionGroups?: RuleCondition[]
	equation?: string
	value?: string
	values?: string[]

}

export interface RuleCondition {
	id: string
	conditions: Ruleset[]
}

export interface RulesetRewardForm {
	rewardPool: string
	amountField: string
	type: string
	rewardCreditMethod: string
	transactionMethod: string
	rewardMethod: string
	conditionFactor: string
	valueFactor: string
	pointFactor: string
	accumulationCycleType: string
	accumulationCycleDay: string
	accumulationCycleMonth: string
	rounding: boolean
	roundingValue: string
	numberDecimal: string
	numberDecimalValue: string
	tierRewardSetting: CampaignTier[]
	rewardLevel: string
}

export const initialRulesetRewardValue: RulesetRewardForm = {
	rewardPool: '',
	amountField: '',
	type: '',
	rewardCreditMethod: '',
	transactionMethod: '',
	rewardMethod: '',
	conditionFactor: '',
	valueFactor: '',
	pointFactor: '',
	accumulationCycleType: '',
	accumulationCycleDay: '',
	accumulationCycleMonth: '',
	rounding: false,
	roundingValue: '',
	numberDecimal: '',
	numberDecimalValue: '',
	tierRewardSetting: [],
	rewardLevel: ''
}

export interface CampaignTier {
	conditionFactorFrom: string
	conditionFactorTo: string
	valueFactor: string
	pointFactor: string
}

export interface CappingForm {
	customerCapType: string
	customerCap: string
	campaignCapType: string
	campaignCap: string
	capCycleType: string
	capCycleDay: string
	capCycleMonth: string
}

export interface CampaignPolicy {
	policy: POLICY
}

export interface CampaignTasklist {
	taskName: string
	code: string
	name: string
	modifiedBy: string
	modifiedDate: string
	campaignInformation: ColumnField[]
	capping: CappingTasklist[]
	ruleset: RulesetTasklist[]
}

export interface ColumnField {
	columnName: string
	from: string
	to: string
	expand?: boolean
}

export interface CappingTasklist {
	cappingName: string
	value: ColumnField[]
}

export interface RuleDetails {
	columnName: string
	from: string
	to: string
	ruleDetails?: ColumnField[]
	expand?: boolean
}

const InitialRuleDetails: ColumnField = {
	columnName: '',
	from: '',
	to: '',
	expand: false
}

const InitialRulesetRuleDetails: RuleDetails = {
	columnName: '',
	from: '',
	to: '',
	ruleDetails: [],
	expand: false
}

export interface ExpandedRuleDetails {
	columnName: string
	from: string
	to: string
	ruleDetails?: ColumnField[]
	expand: boolean
}

export interface ExpandedRuleDetails {
	columnName: string
	from: string
	to: string
	ruleDetails?: ColumnField[]
	expand: boolean
}

export interface RulesetTasklist {
	rulesetName: string
	rules: RuleDetails[]
	conditionalRules: RuleDetails[][]
	oldReward?: RulesetRewardForm
	newReward?: RulesetRewardForm
}
