<form novalidate [ngrxFormState]="formState$ | async">

	<div *ngIf="isView" fxLayout="column">
		<div fxLayout="row" fxLayoutGap="5px" fxFlex>
			<mat-form-field fxFlex>
				<mat-label>{{ 'REDEEM_PARTNER.REWARD_POOL' | translate }}</mat-label>
				<input matInput value="{{(formState$ | async).controls.rewardPoolName.value}}" type="text" readonly>
			</mat-form-field>

			<mat-form-field fxFlex>
				<mat-label>{{ 'REDEEM_PARTNER.GL_REDEMPTION' | translate }}</mat-label>
				<input matInput value="{{getGlRedemption((formState$ | async).controls.glRedemptionId.value)}}"
					type="text" maxlength="255" readonly>
			</mat-form-field>
		</div>
	</div>

	<div *ngIf="!isView" fxLayout="column">
		<div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="5px">
			<mat-form-field fxFlex="50">
				<mat-label>{{ 'REDEEM_PARTNER.REWARD_POOL' | translate }}</mat-label>
				<input matInput value="{{(formState$ | async).controls.rewardPoolName.value}}" type="text" readonly>
			</mat-form-field>
			<mat-form-field fxFlex="50">
				<mat-label>{{ 'REDEEM_PARTNER.GL_REDEMPTION' | translate }}</mat-label>
				<mat-select [ngrxFormControlState]="(formState$ | async).controls.glRedemptionId" (closed)="clearSearchGlRedemption()">
					<ngx-mat-select-search [formControl]="dropDownCtrlGlRedemption" [placeholderLabel]="'COMMON.SEARCH'| translate"
						[noEntriesFoundLabel]="'COMMON.NOT_MATCHING_FOUND' | translate"
						>
						<mat-icon ngxMatSelectSearchClear (click)="clearSearchGlRedemption()">close</mat-icon>
					</ngx-mat-select-search>
					<mat-option *ngFor="let glRedemption of filteredGlRedemption | async" [value]="glRedemption.key">
						{{glRedemption.value}}
					</mat-option>
				</mat-select>
				<mat-error *ngIf="(formState$ | async).errors._glRedemptionId">
					<app-error-validation [errors]="(formState$ | async).errors._glRedemptionId">
					</app-error-validation>
				</mat-error>
			</mat-form-field>
		</div>

	</div>
</form>
