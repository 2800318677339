import { Component, Inject, OnDestroy } from '@angular/core'
import { Observable, Subscription } from 'rxjs'
import { FormGroupState } from 'ngrx-forms'
import { ProductCatalogueDuplicateForm } from '../../../../store/catalogue-management/product-catalogue'
import { Store } from '@ngrx/store'
import * as AppStore from '../../../../store'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material'
import * as ProductCatalogueSelectors from 'src/app/store/catalogue-management/product-catalogue/product-catalogue.selectors'
import * as ProductCatalogueAction from 'src/app/store/catalogue-management/product-catalogue/product-catalogue.actions'

@Component({
	selector: 'app-product-catalogue-duplicate',
	templateUrl: './product-catalogue-duplicate.component.html',
	styleUrls: ['./product-catalogue-duplicate.component.scss']
})
export class ProductCatalogueDuplicateComponent implements OnDestroy {

	isLoading = false
	formState$: Observable<FormGroupState<ProductCatalogueDuplicateForm>>

	subs: Subscription

	constructor(
		private store: Store<AppStore.State>,
		private dialogRef: MatDialogRef<ProductCatalogueDuplicateComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		this.subs = this.store.select(ProductCatalogueSelectors.getProductCatalogue).subscribe(({isDuplicateLoading, isDuplicateDialog}) => {
			this.isLoading = isDuplicateLoading
			if (isDuplicateDialog) {
				this.cancel()
			}
		})

		this.formState$ = this.store.select(({productCatalogue}) => productCatalogue.productCatalogueDuplicateForm)
	}

	ngOnDestroy(): void {
		this.subs.unsubscribe()
	}

	cancel() {
		this.dialogRef.close()
	}

	submit() {
		this.store.dispatch(ProductCatalogueAction.Duplicate())
	}
}
