import { KeyValue } from '@angular/common'
import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'
import { FormGroupState } from 'ngrx-forms'
import { Observable, Subscription } from 'rxjs'
import * as AppStore from 'src/app/store'
import * as CampaignSelectors from 'src/app/store/campaign-management/campaign/campaign.selectors'
import { ComparisonDateForm, Ruleset } from 'src/app/store/campaign-management/campaign/campaign.state'

@Component({
	selector: 'app-comparison-date',
	templateUrl: './comparison-date.component.html',
	styleUrls: ['./comparison-date.component.scss']
})
export class ComparisonDateComponent implements OnInit, OnDestroy {

	@Input() ruleset: Ruleset

	formState$: Observable<FormGroupState<ComparisonDateForm>>
	action: string
	comparisonDateList: KeyValue<string, string>[] = []
	comparisonList: KeyValue<string, string>[] = []
	subs: Subscription

	constructor(
		private store: Store<AppStore.State>
	) {
		this.subs = this.store.select(CampaignSelectors.getCampaign).subscribe(({ campaignRulesetAction, rulesetData }) => {
			this.action = campaignRulesetAction
			this.comparisonDateList = rulesetData.comparisonDateList
			this.comparisonList = rulesetData.comparisonList
		})
	}

	get isView(): boolean { return this.action === 'VIEW' }

	ngOnInit() {
		this.formState$ = this.store.select(CampaignSelectors.getForm, this.ruleset.id)
	}

	ngOnDestroy() {
		this.subs.unsubscribe()
	}

	getComparisonDateValue(value: string): string {
		const result = this.comparisonDateList.find(x => x.key === value)
		return result ? result.value : ''
	}

	getComparisonValue(value: string): string {
		const result = this.comparisonList.find(x => x.key === value)
		return result ? result.value : ''
	}
}
