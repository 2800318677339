import { KeyValue } from '@angular/common'
import { createFormGroupState, FormGroupState, updateGroup, validate } from 'ngrx-forms'
import { maxLength, required } from 'ngrx-forms/validation'
import { Option } from 'src/app/models/option/option'
import { GlProvision } from 'src/app/models/param-settings/gl-provision/gl-provision'
import { RewardPoolListResponse } from 'src/app/models/param-settings/reward-pool/reward-pool-list'
import { FieldValidator } from 'src/app/models/util/field.validator'

export interface State {
	isLoading: boolean
	id?: number,
	channel?: string
	rewardPoolDetailForm: FormGroupState<RewardPoolDetailForm>
	rewardPoolListResponse: RewardPoolListResponse
	cycleList: Option[]
	rewardPoolTypeList: Option[]
	glProvisions: KeyValue<number, string>[]
	glProvision: GlProvision
	resourceList: KeyValue<string, string>[]
	allowDelete: boolean
	allowEditType: boolean
	rewardPoolDetailFormView: RewardPoolDetailForm
	rewardPoolDuplicateForm: FormGroupState<RewardPoolDuplicateForm>
	isDuplicateLoading: boolean
	isDuplicateDialog: boolean
}

export interface RewardPoolDetailForm {
	id: number
	code: string
	name: string
	glId: number
	resourceCode: string
	resourceName: string
	expCycleType: string
	expCycle: string
	cycleType: string
	cycleMonth: number
	cycleDay: number
	customerCycleCap: number
	cycleCap: number
	customerMaxCap: number
	maxCap: number
	rewardPoolType: string
	glProductCode: string
	statement: string
	createdBy: string
	createdDate: string
	modifiedBy: string
	modifiedDate: string
}

export const initialRewardPoolDetailFormValue: RewardPoolDetailForm = {
	id: 0,
	code: '',
	name: '',
	glId: null,
	resourceCode: '',
	resourceName: '',
	expCycleType: '',
	expCycle: '0',
	cycleType: '-',
	cycleMonth: 0,
	cycleDay: 0,
	customerCycleCap: 0,
	cycleCap: 0,
	customerMaxCap: 0,
	maxCap: 0,
	rewardPoolType: '',
	glProductCode: '',
	statement: null,
	createdBy: '',
	createdDate: '',
	modifiedBy: '',
	modifiedDate: '',
}

export const initialRewardPoolDetailForm = createFormGroupState('rewardPoolDetailForm', initialRewardPoolDetailFormValue)

export const validateRewardPoolDetailForm = (state: State) => {
	const rewardPoolType = state && state.rewardPoolDetailForm.value.rewardPoolType
	const rewardPoolTypeCondition = rewardPoolType === 'P'

	return updateGroup<RewardPoolDetailForm>({
		id: validate([required]),
		code: validate([required, maxLength(10), FieldValidator.alphaNumericWithoutSpaces()]),
		name: validate([required, maxLength(40), FieldValidator.checkValidCharacters()]),
		glId: validate([FieldValidator.requiredIfTrueNumber(rewardPoolTypeCondition)]),
		resourceCode: validate([required, maxLength(10)]),
		expCycleType: validate([required]),
		expCycle: validate([maxLength(3),
		FieldValidator.numberWithoutDecimal(),
		FieldValidator.notEvergreenValidator(state.rewardPoolDetailForm),
		]),
		rewardPoolType: validate([required]),
		glProductCode: validate([FieldValidator.requiredIfTrue(rewardPoolTypeCondition), maxLength(6), FieldValidator.checkValidCharacters()]),
		statement: validate([maxLength(210), FieldValidator.checkValidCharacters()]),
		createdBy: validate([]),
		createdDate: validate([]),
		modifiedBy: validate([]),
		modifiedDate: validate([]),
	})
}

export interface RewardPoolDuplicateForm {
	code: string
	name: string
}

export const initialRewardPoolDuplicateFormValue: RewardPoolDuplicateForm = {
	code: '',
	name: ''
}

export const initialRewardPoolDuplicateForm = createFormGroupState('rewardPoolDuplicateForm', initialRewardPoolDuplicateFormValue)

export const validateRewardPoolDuplicateForm = updateGroup<RewardPoolDuplicateForm>({
	code: validate([required, maxLength(10), FieldValidator.alphaNumericWithoutSpaces()]),
	name: validate([required, maxLength(40), FieldValidator.checkValidCharacters()])
})

export const initialState: State = {
	isLoading: false,
	rewardPoolDetailForm: initialRewardPoolDetailForm,
	rewardPoolListResponse: {
		rewardPools: []
	},
	cycleList: [],
	rewardPoolTypeList: [],
	glProvisions: [],
	glProvision: {
		id: 0, code: '', name: ''
	},
	resourceList: [],
	allowDelete: true,
	allowEditType: true,
	rewardPoolDetailFormView: initialRewardPoolDetailFormValue,
	rewardPoolDuplicateForm: initialRewardPoolDuplicateForm,
	isDuplicateLoading: false,
	isDuplicateDialog: false
}
