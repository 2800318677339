import { CommonModule } from '@angular/common'
import { HttpClientModule } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { ErrorStateMatcher } from '@angular/material'
import { RouterModule } from '@angular/router'
import { TranslateModule } from '@ngx-translate/core'
import { GoogleChartsModule } from 'angular-google-charts'
import { NgrxFormsModule } from 'ngrx-forms'
import { CampaignDuplicateComponent } from '../components/campaign-management/campaign/campaign-duplicate/campaign-duplicate.component'
import { CampaignTierComponent } from '../components/campaign-management/campaign/rule/campaign-tier/campaign-tier.component'
import { RuleConditionComponent } from '../components/campaign-management/campaign/rule/rule-condition/rule-condition.component'
import { RuleDynamicComponent } from '../components/campaign-management/campaign/rule/rule-dynamic/rule-dynamic.component'
import { RuleGroupComponent } from '../components/campaign-management/campaign/rule/rule-group/rule-group.component'
import { AccountBlockCodeComponent } from '../components/campaign-management/campaign/rule/rule/account-block-code/account-block-code.component'
import { AccountStatusComponent } from '../components/campaign-management/campaign/rule/rule/account-status/account-status.component'
import { ApprovalCodeComponent } from '../components/campaign-management/campaign/rule/rule/approval-code/approval-code.component'
import { BirthdayComponent } from '../components/campaign-management/campaign/rule/rule/birthday/birthday.component'
import { CardBlockCodeComponent } from '../components/campaign-management/campaign/rule/rule/card-block-code/card-block-code.component'
import { CardStatusComponent } from '../components/campaign-management/campaign/rule/rule/card-status/card-status.component'
import { ChooseDateComponent } from '../components/campaign-management/campaign/rule/rule/choose-date/choose-date.component'
import { ComparisonDateComponent } from '../components/campaign-management/campaign/rule/rule/comparison-date/comparison-date.component'
import { CountryCodeComponent } from '../components/campaign-management/campaign/rule/rule/country-code/country-code.component'
import { CustomerBlockCodeComponent } from '../components/campaign-management/campaign/rule/rule/customer-block-code/customer-block-code.component'
import { CustomerStatusComponent } from '../components/campaign-management/campaign/rule/rule/customer-status/customer-status.component'
import { DynamicAmountComponent } from '../components/campaign-management/campaign/rule/rule/dynamic-amount/dynamic-amount.component'
import { DynamicBalanceComponent } from '../components/campaign-management/campaign/rule/rule/dynamic-balance/dynamic-balance.component'
import { DynamicDateComponent } from '../components/campaign-management/campaign/rule/rule/dynamic-date/dynamic-date.component'
import { DynamicDescriptionComponent } from '../components/campaign-management/campaign/rule/rule/dynamic-description/dynamic-description.component'
import { DynamicIndicatorComponent } from '../components/campaign-management/campaign/rule/rule/dynamic-indicator/dynamic-indicator.component'
import { ECommerceIndComponent } from '../components/campaign-management/campaign/rule/rule/e-commerce-ind/e-commerce-ind.component'
import { MccComponent } from '../components/campaign-management/campaign/rule/rule/mcc/mcc.component'
import { MemberSinceComponent } from '../components/campaign-management/campaign/rule/rule/member-since/member-since.component'
import { MerchantComponent } from '../components/campaign-management/campaign/rule/rule/merchant/merchant.component'
import { PosEntryModeComponent } from '../components/campaign-management/campaign/rule/rule/pos-entry-mode/pos-entry-mode.component'
import { PostingCurrencyCodeComponent } from '../components/campaign-management/campaign/rule/rule/posting-currency-code/posting-currency-code.component'
import { ProductTypeComponent } from '../components/campaign-management/campaign/rule/rule/product-type/product-type.component'
import { RecurringIndComponent } from '../components/campaign-management/campaign/rule/rule/recurring-ind/recurring-ind.component'
import { SegmentComponent } from '../components/campaign-management/campaign/rule/rule/segment/segment.component'
import { SelectedDayComponent } from '../components/campaign-management/campaign/rule/rule/selected-day/selected-day.component'
import { StaffIndAccComponent } from '../components/campaign-management/campaign/rule/rule/staff-ind-acc/staff-ind-acc.component'
import { StaffIndComponent } from '../components/campaign-management/campaign/rule/rule/staff-ind/staff-ind.component'
import { TargetAccountComponent } from '../components/campaign-management/campaign/rule/rule/target-account/target-account.component'
import { TargetCardComponent } from '../components/campaign-management/campaign/rule/rule/target-card/target-card.component'
import { TargetCustomerComponent } from '../components/campaign-management/campaign/rule/rule/target-customer/target-customer.component'
import { TransactionAmountComponent } from '../components/campaign-management/campaign/rule/rule/transaction-amount/transaction-amount.component'
import { TransactionCodeComponent } from '../components/campaign-management/campaign/rule/rule/transaction-code/transaction-code.component'
import { TransactionCurrencyCodeComponent } from '../components/campaign-management/campaign/rule/rule/transaction-currency-code/transaction-currency-code.component'
import { TransactionDescriptionComponent } from '../components/campaign-management/campaign/rule/rule/transaction-description/transaction-description.component'
import { TransactionModeComponent } from '../components/campaign-management/campaign/rule/rule/transaction-mode/transaction-mode.component'
import { VipIndComponent } from '../components/campaign-management/campaign/rule/rule/vip-ind/vip-ind.component'
import { RulesetComponent } from '../components/campaign-management/campaign/rule/ruleset/ruleset.component'
import { TierComponent } from '../components/campaign-management/conditional-reward/conditional-reward-tier/tier.component'
import { SimulatorCreateComponent } from '../components/campaign-management/simulator/simulator-create/simulator-create.component'
import { SimulatorTierComponent } from '../components/campaign-management/simulator/simulator-tier/simulator-tier.component'
import { SimulatorViewComponent } from '../components/campaign-management/simulator/simulator-view/simulator-view.component'
import { ActionBarWithSelectionComponent } from '../components/common/action-bar-with-selection/action-bar-with-selection.component'
import { ActionBarComponent } from '../components/common/action-bar/action-bar.component'
import { ErrorMessageDialogComponent } from '../components/common/dialog/error-message-dialog/error-message-dialog.component'
import { GlobalMessageDialogComponent } from '../components/common/dialog/global-message-dialog/global-message-dialog.component'
import { MessageDialogComponent } from '../components/common/dialog/message-dialog/message-dialog.component'
import { MultiSelectDialogComponent } from '../components/common/dialog/multi-select-dialog/multi-select-dialog.component'
import { SelectDialogComponent } from '../components/common/dialog/select-dialog/select-dialog.component'
import { TextareaDialogComponent } from '../components/common/dialog/textarea-dialog/textarea-dialog.component'
import { ErrorValidationComponent } from '../components/common/error-validation/error-validation.component'
import { NavbarComponent } from '../components/common/navbar/navbar.component'
import { PaginatedResultTableComponent } from '../components/common/paginated-result-table/paginated-result-table.component'
import { SidenavListItemComponent } from '../components/common/sidenav/sidenav-list-item/sidenav-list-item.component'
import { SidenavComponent } from '../components/common/sidenav/sidenav.component'
import { SpinnerComponent } from '../components/common/spinner/spinner.component'
import { AccountCreditCardTableComponent } from '../components/customer-management/components/account-credit-card-table/account-credit-card-table.component'
import { AccountDepositTableComponent } from '../components/customer-management/components/account-deposit-table/account-deposit-table.component'
import { CustomerManagementAccountTransactionViewComponent } from '../components/customer-management/customer-management-account-transaction-view/customer-management-account-transaction-view.component'
import { CustomerManagementCatalogueRedemptionViewComponent } from '../components/customer-management/customer-management-catalogue-redemption-view/customer-management-catalogue-redemption-view.component'
import { CustomerManagementWaiverConfirmationComponent } from '../components/customer-management/customer-management-waiver-confirmation/customer-management-waiver-confirmation.component'
import { CustomerManagementWaiverComponent } from '../components/customer-management/customer-management-waiver/customer-management-waiver.component'
import { CustomerManagementPartnerRedemptionComponent } from '../components/customer-management/customer-management-partner-redemption/customer-management-partner-redemption.component'
import { CustomerManagementPartnerRedemptionConfirmationComponent } from '../components/customer-management/customer-management-partner-redemption-confirmation/customer-management-partner-redemption-confirmation.component'
import { FieldMappingEditComponent } from '../components/param-settings/field-mapping/field-mapping-edit/field-mapping-edit.component'
import { TargetSegmentDialogComponent } from '../components/param-settings/target-segment/target-segment-dialog/target-segment-dialog.component'
import { OnTheSpotRedemptionRewardPoolComponent } from '../components/redemption-settings/on-the-spot-redemption/on-the-spot-redemption-reward-pool/on-the-spot-redemption-reward-pool.component'
import { PointConversionComponent } from '../components/redemption-settings/redeem-partner/point-conversion/point-conversion.component'
import { AssignedWaiverComponent } from '../components/redemption-settings/waiver-management/assigned-waiver/assigned-waiver.component'
import { CheckerMakerFunctionComponent } from '../components/worklist/checker-maker-function/checker-maker-function.component'
import { CanAccessDirective } from '../directives/can-access.directive'
import { CheckerMakerModuleDirective } from '../directives/checker-maker-module.directive'
import { TwoDecimalPlacesDirective } from '../directives/two-decimal-places.directive'
import { MaterialModule } from '../material/material.module'
import { CustomErrorStateMatcher } from '../models/util/error-state-matcher'
import { GroupDuplicateComponent } from '../components/access-management/group/group-duplicate/group-duplicate.component'
import { ProductCatalogueRewardPoolComponent } from '../components/catalogue-management/product-catalogue/product-catalogue-reward-pool/product-catalogue-reward-pool.component'
import { AssignedWaiverRewardPoolComponent } from '../components/redemption-settings/waiver-management/assigned-waiver-reward-pool/assigned-waiver-reward-pool.component'
import { RedeemPartnerRewardPoolComponent } from '../components/redemption-settings/redeem-partner/redeem-partner-reward-pool/redeem-partner-reward-pool.component'
import { RulesetDuplicateComponent } from '../components/campaign-management/campaign/rule/ruleset-duplicate/ruleset-duplicate.component'
import { TargetSegmentResultDialogComponent } from '../components/param-settings/target-segment/target-segment-result-dialog/target-segment-result-dialog.component'
import { AccountLoyaltyAccumulationBucketComponent } from '../components/customer-management/components/account-loyalty-accumulation-bucket/account-loyalty-accumulation-bucket.component'
import { CustomerManagementCataloguePartnerRedemptionViewComponent } from '../components/customer-management/customer-management-catalogue-partner-redemption-view/customer-management-catalogue-partner-redemption-view.component'
import { CampaignWorklistConditionalRulesDetailComponent } from '../components/campaign-management/campaign/component/campaign-worklist-conditional-rules-detail/campaign-worklist-conditional-rules-detail.component'
import { CampaignWorklistRewardTiersComponent } from '../components/campaign-management/campaign/component/campaign-worklist-reward-tiers/campaign-worklist-reward-tiers.component'
import { CampaignWorklistRulesetDetailComponent } from '../components/campaign-management/campaign/component/campaign-worklist-ruleset-detail/campaign-worklist-ruleset-detail.component'
import { CustomerManagementCatalogueRedemptionConfirmationDialogComponent } from '../components/customer-management/customer-management-catalogue-redemption-confirmation-dialog/customer-management-catalogue-redemption-confirmation-dialog.component'
import { CustomerManagementCatalogueRedemptionDialogViewComponent } from '../components/customer-management/customer-management-catalogue-redemption-dialog-view/customer-management-catalogue-redemption-dialog-view.component'
import { PointTransactionBreakdownTableComponent } from '../components/customer-management/components/point-transaction-breakdown-table/point-transaction-breakdown-table.component'
import { RedeemRuleAccountBlockCodeComponent } from '../components/redemption-settings/redeem-rules/rule-components/account-block-code/redeem-rule-account-block-code.component'
import { RedeemRuleAccountStatusComponent } from '../components/redemption-settings/redeem-rules/rule-components/account-status/redeem-rule-account-status.component'
import { RedeemRuleCustomerBlockCodeComponent } from '../components/redemption-settings/redeem-rules/rule-components/customer-block-code/redeem-rule-customer-block-code.component'
import { RedeemRuleCustomerStatusComponent } from '../components/redemption-settings/redeem-rules/rule-components/customer-status/redeem-rule-customer-status.component'
import { RedeemRuleCardBlockCodeComponent } from '../components/redemption-settings/redeem-rules/rule-components/card-block-code/redeem-rule-card-block-code.component'
import { RedeemRuleCardStatusComponent } from '../components/redemption-settings/redeem-rules/rule-components/card-status/redeem-rule-card-status.component'
import { AccumulatingTransactionsTableComponent } from '../components/customer-management/components/accumulating-transactions-table/accumulating-transactions-table.component'
import { MccGroupDuplicateComponent } from '../components/param-settings/mcc-group/mcc-group-duplicate/mcc-group-duplicate.component'
import { FulfilmentPartnerDuplicateComponent } from '../components/catalogue-management/fulfilment-partner/fulfilment-partner-duplicate/fulfilment-partner-duplicate.component'
import { RewardPoolDuplicateComponent } from '../components/param-settings/reward-pool/reward-pool-duplicate/reward-pool-duplicate.component'
import { MccGroupDialogComponent } from '../components/param-settings/mcc-group/mcc-group-dialog/mcc-group-dialog.component'
import { TcGroupDuplicateComponent } from '../components/param-settings/tc-group/tc-group-duplicate/tc-group-duplicate.component'
import { GlProvisionDuplicateComponent } from '../components/param-settings/gl-management/gl-provision/gl-provision-duplicate/gl-provision-duplicate.component'
import { GlRedemptionDuplicateComponent } from '../components/param-settings/gl-management/gl-redemption/gl-redemption-duplicate/gl-redemption-duplicate.component'
import { MerchantGroupDuplicateComponent } from '../components/param-settings/merchant-group/merchant-group-duplicate/merchant-group-duplicate.component'
import { ProductTypeGroupDuplicateComponent } from '../components/param-settings/product-type-group/product-type-group-duplicate/product-type-group-duplicate.component'
import { MerchantGroupDialogComponent } from '../components/param-settings/merchant-group/merchant-group-dialog/merchant-group-dialog.component'
import { TcGroupDialogComponent } from '../components/param-settings/tc-group/tc-group-dialog/tc-group-dialog.component'
import { ProductCatalogueDuplicateComponent } from '../components/catalogue-management/product-catalogue/product-catalogue-duplicate/product-catalogue-duplicate.component'
import { ProductPartnerDuplicateComponent } from '../components/catalogue-management/product-partner/product-partner-duplicate/product-partner-duplicate.component'
import { AssignedWaiverDuplicateComponent } from '../components/redemption-settings/waiver-management/assigned-waiver-duplicate/assigned-waiver-duplicate.component'
import { AutoRedemptionDuplicateComponent } from '../components/redemption-settings/auto-redemption/auto-redemption-duplicate/auto-redemption-duplicate.component'
import { OnTheSpotRedemptionDuplicateComponent } from '../components/redemption-settings/on-the-spot-redemption/on-the-spot-redemption-duplicate/on-the-spot-redemption-duplicate.component'

const CommonUI = [
	NavbarComponent,
	ActionBarComponent,
	SidenavComponent,
	SidenavListItemComponent,
	SpinnerComponent,
	ErrorValidationComponent,
	RuleDynamicComponent,
	RuleGroupComponent,
	RuleConditionComponent,
	PaginatedResultTableComponent,
	AccountCreditCardTableComponent,
	AccountDepositTableComponent,
	CheckerMakerFunctionComponent,
	CampaignTierComponent,
	TierComponent,
	ActionBarWithSelectionComponent,
	SimulatorTierComponent,
	ProductCatalogueRewardPoolComponent,
	OnTheSpotRedemptionRewardPoolComponent,
	AssignedWaiverRewardPoolComponent,
	RedeemPartnerRewardPoolComponent,
	AccountLoyaltyAccumulationBucketComponent,
	CampaignWorklistRewardTiersComponent,
	CampaignWorklistRulesetDetailComponent,
	CampaignWorklistConditionalRulesDetailComponent,
	AccumulatingTransactionsTableComponent
]
const Pipes = [
]
const Directives = [
	CanAccessDirective,
	CheckerMakerModuleDirective,
	TwoDecimalPlacesDirective
]

const EntryComponent = [
	MessageDialogComponent,
	GlobalMessageDialogComponent,
	ErrorMessageDialogComponent,
	SelectDialogComponent,
	MultiSelectDialogComponent,
	TextareaDialogComponent,
	TargetSegmentDialogComponent,
	TcGroupDialogComponent,
	CampaignDuplicateComponent,
	RulesetComponent,
	PointConversionComponent,
	AssignedWaiverComponent,
	AccountStatusComponent,
	ApprovalCodeComponent,
	BirthdayComponent,
	CustomerBlockCodeComponent,
	AccountBlockCodeComponent,
	CardBlockCodeComponent,
	CardStatusComponent,
	ChooseDateComponent,
	ComparisonDateComponent,
	CustomerStatusComponent,
	ECommerceIndComponent,
	MccComponent,
	MerchantComponent,
	PosEntryModeComponent,
	PostingCurrencyCodeComponent,
	ProductTypeComponent,
	RecurringIndComponent,
	SelectedDayComponent,
	StaffIndComponent,
	TransactionAmountComponent,
	TransactionCodeComponent,
	TransactionCurrencyCodeComponent,
	CountryCodeComponent,
	TransactionDescriptionComponent,
	TransactionModeComponent,
	VipIndComponent,
	TargetCustomerComponent,
	TargetAccountComponent,
	TargetCardComponent,
	MemberSinceComponent,
	SegmentComponent,
	StaffIndAccComponent,
	DynamicIndicatorComponent,
	DynamicDescriptionComponent,
	DynamicBalanceComponent,
	DynamicAmountComponent,
	DynamicDateComponent,
	FieldMappingEditComponent,
	CustomerManagementAccountTransactionViewComponent,
	CustomerManagementWaiverComponent,
	CustomerManagementWaiverConfirmationComponent,
	CustomerManagementCatalogueRedemptionViewComponent,
	SimulatorCreateComponent,
	SimulatorViewComponent,
	CustomerManagementPartnerRedemptionComponent,
	CustomerManagementPartnerRedemptionConfirmationComponent,
	GroupDuplicateComponent,
	RulesetDuplicateComponent,
	TargetSegmentResultDialogComponent,
	CustomerManagementCataloguePartnerRedemptionViewComponent,
	CustomerManagementCatalogueRedemptionConfirmationDialogComponent,
	CustomerManagementCatalogueRedemptionDialogViewComponent,
	PointTransactionBreakdownTableComponent,
	RedeemRuleCustomerBlockCodeComponent,
	RedeemRuleCustomerStatusComponent,
	RedeemRuleAccountBlockCodeComponent,
	RedeemRuleAccountStatusComponent,
	RedeemRuleCardBlockCodeComponent,
	RedeemRuleCardStatusComponent,
	MccGroupDuplicateComponent,
	FulfilmentPartnerDuplicateComponent,
	RewardPoolDuplicateComponent,
	MccGroupDialogComponent,
	TcGroupDuplicateComponent,
	GlProvisionDuplicateComponent,
	GlRedemptionDuplicateComponent,
	MerchantGroupDuplicateComponent,
	ProductTypeGroupDuplicateComponent,
	MerchantGroupDialogComponent,
	ProductCatalogueDuplicateComponent,
	ProductPartnerDuplicateComponent,
	AssignedWaiverDuplicateComponent,
	AutoRedemptionDuplicateComponent,
	OnTheSpotRedemptionDuplicateComponent
]

@NgModule({
	declarations: [
		CommonUI,
		Pipes,
		Directives,
		EntryComponent
	],
	exports: [
		CommonModule,
		RouterModule,
		HttpClientModule,
		FormsModule,
		ReactiveFormsModule,
		CommonUI,
		Pipes,
		Directives,
		MaterialModule,
		TranslateModule,
		FlexLayoutModule,
		NgrxFormsModule,
		GoogleChartsModule
	],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		RouterModule,
		MaterialModule,
		TranslateModule,
		FlexLayoutModule,
		NgrxFormsModule,
		GoogleChartsModule
	],
	providers: [
		{ provide: ErrorStateMatcher, useClass: CustomErrorStateMatcher }
	],
	entryComponents: [
		EntryComponent
	]
})
export class SharedModule { }
