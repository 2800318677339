import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { FormBuilder, FormControl, FormGroup } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef, MatSelect } from '@angular/material'
import { Store } from '@ngrx/store'
import { FormGroupState } from 'ngrx-forms'
import { Observable, ReplaySubject, Subject, Subscription } from 'rxjs'
import { take, takeUntil } from 'rxjs/operators'
import { SelectDialogData } from 'src/app/models/common/dialog'
import { FilterPredicate } from 'src/app/models/util/filter-predicate'
import * as AppStore from 'src/app/store'
import * as CommonAction from 'src/app/store/common/common/common.actions'
import * as CommonSelector from 'src/app/store/common/common/common.selectors'
import { SelectForm } from 'src/app/store/common/common/common.state'

@Component({
	selector: 'app-select-dialog',
	templateUrl: './select-dialog.component.html',
	styleUrls: ['./select-dialog.component.scss']
})
export class SelectDialogComponent implements OnInit, AfterViewInit, OnDestroy {

	title: string
	content: string
	payload: any = []
	form: FormGroup
	value: string
	temp: string

	show = true

	formState$: Observable<FormGroupState<SelectForm>>
	subs: Subscription
	public dropDownCtrl: FormControl = new FormControl()

	/** list of records filtered by search keyword */
	public filteredRecords: ReplaySubject<any[]> = new ReplaySubject<any[]>(1)

	@ViewChild('singleSelect', { static: false }) singleSelect: MatSelect

	/** Subject that emits when the component has been destroyed. */
	protected _onDestroy = new Subject<void>()

	constructor(
		private fb: FormBuilder,
		private store: Store<AppStore.State>,
		private dialogRef: MatDialogRef<SelectDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: SelectDialogData
	) {
		this.title = data.title
		this.content = data.content
		this.payload = data.payload

		this.store.dispatch(CommonAction.SelectInitial()
		)
		this.subs = this.store.select(CommonSelector.getSelectForm).subscribe(x => {
			this.value = x.controls.value.value
		})

		this.formState$ = this.store.select(({ common }) => common.selectForm)
	}

	ngOnInit() {
		// load the initial list
		this.filteredRecords.next(this.payload.slice())

		this.dropDownCtrl.valueChanges
			.subscribe(value => {
				const maxChar = 255
				if (value.length > maxChar) {
					const newValue = value.slice(0, maxChar)
					this.dropDownCtrl.reset('', { emitEvent: false })
					this.dropDownCtrl.setValue(newValue)

				} else {
					this.search(value)
				}
			})

	}

	ngAfterViewInit() {
		this.setInitialValue()
	}

	/**
	 * Sets the initial value after the filteredRecords are loaded initially
	 */
	setInitialValue() {
		this.filteredRecords
			.pipe(take(1), takeUntil(this._onDestroy))
			.subscribe(() => {
				// setting the compareWith property to a comparison function
				// triggers initializing the selection according to the initial value of
				// the form control (i.e. _initializeSelection())
				// this needs to be done after the filteredRecords are loaded initially
				// and after the mat-option elements are available
				this.singleSelect.compareWith = (a: any, b: any) => a && b && a.id === b.id
			})
	}

	search(search: string) {
		if (!this.payload) {
			return
		}
		if (!search) {
			this.filteredRecords.next(this.payload.slice())
			return
		} else {
			search = search.toLowerCase()
		}
		// filter the records
		this.filteredRecords.next(
			this.payload.filter(payload => FilterPredicate.isIdenticalTo(search)([payload.keyValue.value]))
		)
	}

	clearSearch() {
		this.dropDownCtrl.setValue('')
		this.filteredRecords.next(this.payload.slice())
		this.reRender()
	}

	clearSearchAndReRender() {
		this.dropDownCtrl.setValue('')
		this.filteredRecords.next(this.payload.slice())

		// Temporary Fix
		// DO NOT REMOVE, WILL CAUSE BUG (unless better approach implemented)
		this.reRender()
	}

	ngOnDestroy() {
		this.subs.unsubscribe()
	}

	add() {
		const payload = this.payload.find(({ keyValue }) => keyValue.key === this.value)
		this.dialogRef.close(payload)
	}

	cancel() {
		this.dialogRef.close()
	}

	reRender() {
		this.show = false
		setTimeout(() => this.show = true, 10)
	}
}
