import { Inject, Injectable } from '@angular/core'
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service'
import { StorageKey, StorageData } from 'src/app/models/common/storage.model'

@Injectable({
	providedIn: 'root'
})
export class LocalStorageService {

	constructor(
		@Inject(LOCAL_STORAGE) private localStorageService: StorageService
	) { }

	set(storageKey: StorageKey, storageData: StorageData) {
		this.localStorageService.set(storageKey, storageData)
	}

	get(storageKey: StorageKey): StorageData {
		return this.localStorageService.get(storageKey)
	}

	remove(storageKey: StorageKey) {
		this.localStorageService.remove(storageKey)
	}

	clear() {
		this.localStorageService.clear()
	}
}
