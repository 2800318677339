import { createFormGroupState, FormGroupState, updateGroup, validate } from 'ngrx-forms'
import { maxLength, required } from 'ngrx-forms/validation'
import { TcGroupListResponse } from 'src/app/models/param-settings/tc-group/tc-group-list'
import { FieldValidator } from 'src/app/models/util/field.validator'
import { TcGroupDetails } from 'src/app/models/param-settings/tc-group/tc-group'

export interface State {
	isLoading: boolean
	code?: string
	tcGroupListForm: FormGroupState<TcGroupListForm>
	tcGroupDetailForm: FormGroupState<TcGroupDetailForm>
	tcGroupListResponse: TcGroupListResponse
	allowDelete: boolean
	tcGroupDetailFormView: TcGroupDetailForm
	tcGroupDuplicateForm: FormGroupState<TcGroupDuplicateForm>
	isDuplicateLoading: boolean
	isDuplicateDialog: boolean
	tcGroupDetails: TcGroupDetails
	tcGroupTableListView: string[]
}

export interface TcGroupListForm {
	groupCode: string
	groupName: string
	fromDate: string
	toDate: string
	pageNum: number
	pageSize: number
}

export const initialTcGroupListFormValue: TcGroupListForm = {
	groupCode: '',
	groupName: '',
	fromDate: '',
	toDate: '',
	pageNum: 0,
	pageSize: 0
}

export const initialTcGroupListForm = createFormGroupState('tcGroupListForm', initialTcGroupListFormValue)

export const validateTcGroupListForm = updateGroup<TcGroupListForm>({
	groupCode: validate([maxLength(255)]),
	groupName: validate([maxLength(255)]),
	fromDate: validate([maxLength(255)]),
	toDate: validate([maxLength(255)])
})

export interface TcGroupDetailForm {
	code: string
	name: string
	description: string
	range: string
	createdBy: string
	createdDate: string
	modifiedBy: string
	modifiedDate: string
}

export const initialTcGroupDetailFormValue: TcGroupDetailForm = {
	code: '',
	name: '',
	description: null,
	range: '',
	createdBy: '',
	createdDate: '',
	modifiedBy: '',
	modifiedDate: ''
}

export const initialTcGroupDetailForm = createFormGroupState('tcGroupDetailForm', initialTcGroupDetailFormValue)

export const validateTcGroupDetailForm = updateGroup<TcGroupDetailForm>({
	code: validate([required, FieldValidator.alphaNumericWithoutSpaces()]),
	name: validate([required, maxLength(40), FieldValidator.checkValidCharacters()]),
	description: validate([maxLength(100), FieldValidator.checkValidCharacters()])
})

export interface TcGroupDuplicateForm {
	code: string
	name: string
}

export const initialTcGroupDuplicateFormValue: TcGroupDuplicateForm = {
	code: '',
	name: ''
}

export const initialTcGroupDuplicateForm = createFormGroupState('tcGroupDuplicateForm', initialTcGroupDuplicateFormValue)

export const validateTcGroupDuplicateForm = updateGroup<TcGroupDuplicateForm>({
	code: validate([required, FieldValidator.alphaNumericWithoutSpaces()]),
	name: validate([required, maxLength(40), FieldValidator.checkValidCharacters()])
})

export function digit() {
	return (value: string) => {
		const re = new RegExp(/^\d+$/)
		const condition = value && !value.match(re)
		return condition ? { digit: true } : null
	}
}

export function range() {
	return (value: string) => {
		const allowedCharactersRegex = new RegExp(/^([0-9-,])+$/)
		const digitRegex = new RegExp(/^\d+$/)
		let check = true
		value.split(',').forEach(splitValue => {
			const individualValues = splitValue.split('-')
			individualValues.forEach(element => {
				if (!(element.match(digitRegex))) {
					check = false
				}
			})
		})
		const condition = value && !value.match(allowedCharactersRegex) && !(check)
		return condition ? { range: true } : null
	}
}

export const initialTcGroupDetails = {
	code: '',
	name: '',
	range: [],
	description: ''
}

export const initialState: State = {
	isLoading: false,
	tcGroupListForm: initialTcGroupListForm,
	tcGroupDetailForm: initialTcGroupDetailForm,
	tcGroupListResponse: {
		tcGroups: []
	},
	allowDelete: true,
	tcGroupDetailFormView: initialTcGroupDetailFormValue,
	tcGroupDuplicateForm: initialTcGroupDuplicateForm,
	isDuplicateLoading: false,
	isDuplicateDialog: false,
	tcGroupDetails: initialTcGroupDetails,
	tcGroupTableListView: []
}
