<app-spinner [isLoading]="isLoading"></app-spinner>

<div class="title-div" fxLayout="row" fxLayoutAlign="space-between center">
	<span class="title">{{'REWARD_POOL.REWARD_POOL_DUPLICATE' | translate }}</span>
	<button class="close-icon" mat-icon-button (click)="cancel()">
		<mat-icon>close</mat-icon>
	</button>
</div>

<mat-dialog-content>
	<form novalidate [ngrxFormState]="formState$ | async" fxFlex fxLayout="column">
		<mat-form-field class="prefix">
			<mat-label>{{ 'REWARD_POOL.CODE' | translate }}</mat-label>
			<input matInput [ngrxFormControlState]="(formState$ | async).controls.code" type="text" maxlength="10" required>
			<mat-error *ngIf="(formState$ | async).errors._code">
				<app-error-validation [errors]="(formState$ | async).errors._code" [maxlength]="10"></app-error-validation>
			</mat-error>
		</mat-form-field>

		<mat-form-field>
			<mat-label>{{ 'REWARD_POOL.NAME' | translate }}</mat-label>
			<input matInput [ngrxFormControlState]="(formState$ | async).controls.name" type="text" maxlength="40" required />
			<mat-error *ngIf="(formState$ | async).errors._name">
				<app-error-validation [errors]="(formState$ | async).errors._name" [maxlength]="40"></app-error-validation>
			</mat-error>
		</mat-form-field>
	</form>
</mat-dialog-content>

<div class="action-button-div" fxLayout="row" fxLayoutAlign="end">
	<button [disabled]="(formState$ | async).isInvalid" class="mat-raised-button mat-primary next-button"
				(click)="submit()" fxFlexOffset="8px">{{ 'ACTION.SAVE' | translate }}
	</button>
</div>
