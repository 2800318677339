import { createFormGroupState, FormGroupState, updateGroup, validate } from 'ngrx-forms'
import { maxLength } from 'ngrx-forms/validation'
import { PolicyListResponse } from 'src/app/models/access-management/policy/policy-list'

export interface State {
	isLoading: boolean
	id?: string
	policyListForm: FormGroupState<PolicyListForm>
	policyListReponse: PolicyListResponse
}

export interface PolicyListForm {
	name: string,
	pageNum: number
	pageSize: number
}

export const initialPolicyListFormValue: PolicyListForm = {
	name: '',
	pageNum: 0,
	pageSize: 0
}

export const initialPolicyListForm = createFormGroupState('policyListForm', initialPolicyListFormValue)

export const validatePolicyListForm = updateGroup<PolicyListForm>({
	name: validate([maxLength(255)])
})

export const initialState: State = {
	isLoading: false,
	policyListForm: initialPolicyListForm,
	policyListReponse: {
		policies: []
	}
}
