import { createFormGroupState, FormGroupState, updateGroup, validate } from 'ngrx-forms'
import { maxLength, required } from 'ngrx-forms/validation'
import { GlProvisionListResponse } from 'src/app/models/param-settings/gl-provision/gl-provision-list'
import { FieldValidator } from 'src/app/models/util/field.validator'
import { KeyValue } from '@angular/common'

export interface State {
	isLoading: boolean
	id?: number
	glProvisionDetailForm: FormGroupState<GlProvisionDetailForm>
	glProvisionDuplicateForm: FormGroupState<GlProvisionDuplicateForm>
	glProvisionListResponse: GlProvisionListResponse
	allowDelete: boolean
	glProvisionDetailFormView: GlProvisionDetailForm
	entityTypeList: KeyValue<string, string>[]
	isDuplicateDialog: boolean
	isDuplicateLoading: boolean
}

export interface GlProvisionDetailForm {
	code: string
	name: string
	debitGlCode: string
	debitBranch: string
	debitBranchCostCenter: string
	debitEntityKey: string
	debitEntityValue: string
	creditGlCode: string
	creditBranch: string
	creditBranchCostCenter: string
	creditEntityKey: string
	creditEntityValue: string
	expCreditGlCode: string
	expCreditBranch: string
	expCreditBranchCostCenter: string
	expEntityKey: string
	expEntityValue: string
	subDebitGlCode: string
	subDebitBranch: string
	subDebitBranchCostCenter: string
	subEntityKey: string
	subEntityValue: string
	point: string
	cashValue: string
	createdBy: string
	createdDate: string
	modifiedBy: string
	modifiedDate: string
}

export const initialGlProvisionDetailFormValue: GlProvisionDetailForm = {
	code: '',
	name: '',
	debitGlCode: '',
	debitBranch: '',
	debitBranchCostCenter: '',
	debitEntityKey: '',
	debitEntityValue: '',
	creditGlCode: '',
	creditBranch: '',
	creditBranchCostCenter: '',
	creditEntityKey: '',
	creditEntityValue: '',
	expCreditGlCode: '',
	expCreditBranch: '',
	expCreditBranchCostCenter: '',
	expEntityKey: '',
	expEntityValue: '',
	subDebitGlCode: '',
	subDebitBranch: '',
	subDebitBranchCostCenter: '',
	subEntityKey: '',
	subEntityValue: '',
	point: '',
	cashValue: '',
	createdBy: '',
	createdDate: '',
	modifiedBy: '',
	modifiedDate: ''
}

export const initialGlProvisionDetailForm = createFormGroupState('glProvisionDetailForm', initialGlProvisionDetailFormValue)

export interface GlProvisionDuplicateForm {
	code: string
	name: string
}

export const initialGlProvisionDuplicateFormValue: GlProvisionDuplicateForm = {
	code: '',
	name: ''
}

export const initialGlProvisionDuplicateForm = createFormGroupState('glProvisionDuplicateForm', initialGlProvisionDuplicateFormValue)

export const validateGlProvisionDetailForm = (state: State) => updateGroup<GlProvisionDetailForm>({
	code: validate([required, maxLength(10), FieldValidator.alphaNumericWithoutSpaces()]),
	name: validate([required, maxLength(40), FieldValidator.checkValidCharacters()]),
	debitGlCode: validate([required, FieldValidator.checkNumericMatchLength(8), FieldValidator.checkSameString(state.glProvisionDetailForm.controls.creditGlCode.value, 'Credit GL Code')]),
	debitBranch: validate([required, FieldValidator.alphaNumericWithoutSpaces(), FieldValidator.checkAlphaNumericMatchLength(3)]),
	debitBranchCostCenter: validate([required, FieldValidator.alphaNumericWithoutSpaces(), FieldValidator.checkAlphaNumericMatchLength(8)]),
	debitEntityKey: validate([required, maxLength(10), FieldValidator.numberWithoutDecimal()]),
	creditGlCode: validate([required, FieldValidator.checkNumericMatchLength(8), FieldValidator.checkSameString(state.glProvisionDetailForm.controls.debitGlCode.value, 'Debit GL Code')]),
	creditBranch: validate([required, FieldValidator.alphaNumericWithoutSpaces(), FieldValidator.checkAlphaNumericMatchLength(3)]),
	creditBranchCostCenter: validate([required, FieldValidator.alphaNumericWithoutSpaces(), FieldValidator.checkAlphaNumericMatchLength(8)]),
	creditEntityKey: validate([required, maxLength(10), FieldValidator.numberWithoutDecimal()]),
	expCreditGlCode: validate([required, FieldValidator.checkNumericMatchLength(8), FieldValidator.checkSameString(state.glProvisionDetailForm.controls.creditGlCode.value, 'Expired Points Debit GL Code')]),
	expCreditBranch: validate([required, FieldValidator.alphaNumericWithoutSpaces(), FieldValidator.checkAlphaNumericMatchLength(3)]),
	expCreditBranchCostCenter: validate([required, FieldValidator.alphaNumericWithoutSpaces(), FieldValidator.checkAlphaNumericMatchLength(8)]),
	expEntityKey: validate([required, maxLength(10), FieldValidator.numberWithoutDecimal()]),
	subDebitGlCode: validate([required, FieldValidator.checkNumericMatchLength(8)]),
	subDebitBranch: validate([required, FieldValidator.alphaNumericWithoutSpaces(), FieldValidator.checkAlphaNumericMatchLength(3)]),
	subDebitBranchCostCenter: validate([required, FieldValidator.alphaNumericWithoutSpaces(), FieldValidator.checkAlphaNumericMatchLength(8)]),
	subEntityKey: validate([required, maxLength(10), FieldValidator.numberWithoutDecimal()]),
	point: validate([required, FieldValidator.numberWithoutDecimal()]),
	cashValue: validate([required, FieldValidator.integerAndDecimalCheck(4, 4)])
})

export const validateGlProvisionDuplicateForm = () => updateGroup<GlProvisionDuplicateForm>({
	code: validate([required, maxLength(10), FieldValidator.alphaNumericWithoutSpaces()]),
	name: validate([required, maxLength(40), FieldValidator.checkValidCharacters()])
})

export const initialState: State = {
	isLoading: false,
	glProvisionDetailForm: initialGlProvisionDetailForm,
	glProvisionDuplicateForm: initialGlProvisionDuplicateForm,
	glProvisionListResponse: {
		glProvisions: []
	},
	allowDelete: true,
	glProvisionDetailFormView: initialGlProvisionDetailFormValue,
	entityTypeList: [],
	isDuplicateDialog: false,
	isDuplicateLoading: false
}
