import { KeyValue } from '@angular/common'
import {
	createFormGroupState,
	FormGroupState,
	updateGroup,
	validate,
} from 'ngrx-forms'
import { maxLength, required } from 'ngrx-forms/validation'
import { CatalogueRedemptionType } from 'src/app/models/common/constant'
import {
	CardProductCatalogueListData,
	CustomerAddressListData,
	CustomerCardListData,
} from 'src/app/models/customer-management/catalogue-redemption'
import {
	AccumulatingTransactionListData,
	CustomerAccountViewResponse,
	CustomerAccountViewTransactionDetailResponse,
	CustomerAccountViewTransactionListResponse,
	PointBreakdownListData,
} from 'src/app/models/customer-management/customer-account-detail'
import { AccumulationBucketList } from 'src/app/models/customer-management/customer-accumulation-bucket-detail'
import {
	CustomerCardViewResponse,
	CustomerCardViewTransactionResponse,
} from 'src/app/models/customer-management/customer-card-detail'
import { CustomerCatalogueRedemptionOrderViewResponse } from 'src/app/models/customer-management/customer-catalogue-redemption-order'
import { ConditionalRewardViewResponse } from 'src/app/models/customer-management/customer-conditional-reward'
import { CustomerViewResponse } from 'src/app/models/customer-management/customer-detail'
import { CustomerListResponse } from 'src/app/models/customer-management/customer-list'
import { CustomerPartnerRedemptionListData } from 'src/app/models/customer-management/customer-partner-redemption'
import { CustomerPartnerRedemptionOrderViewResponse } from 'src/app/models/customer-management/customer-partner-redemption-order'
import { RewardPoolListResponse } from 'src/app/models/customer-management/customer-points-adjustment'
import { CustomerRedemptionHistoryListResponse } from 'src/app/models/customer-management/customer-redemption-history'
import {
	CustomerRewardPoolViewResponse,
	RewardPoolInformationList,
} from 'src/app/models/customer-management/customer-reward-pool-detail'
import { CustomerStatementTransactionListResponse } from 'src/app/models/customer-management/customer-statement-transaction-detail'
import {
	WaiverListData,
	WaiverTypeListData,
} from 'src/app/models/customer-management/waiver'
import { Option } from 'src/app/models/option/option'
import { FieldValidator } from 'src/app/models/util/field.validator'

export interface State {
	isLoading: boolean
	isTableLoading: boolean
	pointBalance?: number
	accountNo?: string
	id?: string
	cardId?: number
	accountId?: number
	rewardPoolId?: number
	rewardPoolGroupId?: number
	conditionalRewardId?: number
	redemptionTransactionId?: number
	catalogueRedemptionId?: number
	redemptionType?: string
	rewardPage: boolean
	accountPage: boolean
	isCreditCard: boolean
	taskId?: number
	point?: number
	value?: number
	rewardId?: number
	icNumber?: string
	customerName?: string
	accountNumber?: string
	cardNumber?: string
	startDate?: string
	endDate?: string
	searchCustomerName?: string
	searchIcNumber?: string
	searchAccountNumber?: string
	searchCardNumber?: string
	totalPoints?: string
	totalAmount?: string
	resource?: string
	customerManagementListForm: FormGroupState<CustomerManagementListForm>
	customerListResponse: CustomerListResponse
	customerViewResponse: CustomerViewResponse
	customerCardViewResponse: CustomerCardViewResponse
	customerCardViewTransactionResponse: CustomerCardViewTransactionResponse
	customerAccountViewResponse: CustomerAccountViewResponse
	customerAccountViewTransactionListResponse: CustomerAccountViewTransactionListResponse
	customerAccountViewTransactionDetailResponse: CustomerAccountViewTransactionDetailResponse
	customerRewardPoolViewResponse: CustomerRewardPoolViewResponse
	customerConditionalRewardViewResponse: ConditionalRewardViewResponse[]
	rewardPoolInformationList: RewardPoolInformationList[]
	accumulationBucketList: AccumulationBucketList
	productCatalogueList: CardProductCatalogueListData[]
	customerCardList: CustomerCardListData[]
	customerAddressList: CustomerAddressListData[]
	productCatalogue?: CardProductCatalogueListData
	catalogueRedemptionForm: FormGroupState<CatalogueRedemptionForm>
	customerStatementTransactionListResponse: CustomerStatementTransactionListResponse
	waiverTypeList: WaiverTypeListData[]
	waiverList: WaiverListData[]
	waiverForm: FormGroupState<WaiverForm>
	customerRedemptionHistoryListResponse: CustomerRedemptionHistoryListResponse
	customerCatalogueRedemptionOrderViewResponse: CustomerCatalogueRedemptionOrderViewResponse
	customerPartnerRedemptionOrderViewResponse: CustomerPartnerRedemptionOrderViewResponse
	partnerRedemptionForm: FormGroupState<PartnerRedemptionForm>
	partnerRedemptionList: CustomerPartnerRedemptionListData[]
	partnerRedemption?: CustomerPartnerRedemptionListData
	partnerRedemptionKeyValueList: KeyValue<string, string>[]
	poboxList: string[]
	cardTransactionOptionList: Option[]
	customerManagementPointsAdjustmentForm: FormGroupState<CustomerManagementPointsAdjustmentForm>,
	adjustmentTypeList: Option[]
	customerTransactionList: PointBreakdownListData,
	rewardPoolListResponse: RewardPoolListResponse
	accumulatingTransactionList: AccumulatingTransactionListData[]
	accumulatingTransactionOptionList: Option[]
}

export interface CustomerManagementListForm {
	name: string
	customerId: string
	accountNumber: string
	cardNumber: string
	id: number
}

export const initialCustomerManagementListFormValue: CustomerManagementListForm = {
	name: '',
	customerId: '',
	accountNumber: '',
	cardNumber: '',
	id: 0
}

export interface CatalogueRedemptionForm {
	cardId: number
	accountType: string
	quantity: string
	address1: string
	address2: string
	address3: string
	city: string
	state: string
	postcode: string
	emailAddress: string
	airmileId: string
	totalPoint: string
	value: string
}

export interface PartnerRedemptionForm {
	name: string
	airmileId: string
	points: number
	values: number
}

export const initialCatalogueRedemptionFormValue: CatalogueRedemptionForm = {
	cardId: undefined,
	accountType: undefined,
	quantity: '',
	address1: '',
	address2: '',
	address3: '',
	city: '',
	state: '',
	postcode: '',
	emailAddress: '',
	airmileId: '',
	totalPoint: '',
	value: '',
}

export const initialCatalogueRedemptionOrderFormValue: CustomerCatalogueRedemptionOrderViewResponse = {
	productCodeAndName: '',
	quantity: 0,
	totalPoints: 0,
	totalAmount: 0,
	productCategory: '',
	address: '',
	city: '',
	state: '',
	postcode: '',
	emailAddress: '',
	catalogueRedemptionOrderId: '',
	cardNumber: '',
	rewardPoolAndGroupValue: '',
	redemptionType: '',
	allowWithdraw: false
}

export interface WaiverForm {
	waiverTypeId: number,
	waiverId: number
}

export const initialWaiverFormValue: WaiverForm = {
	waiverTypeId: undefined,
	waiverId: undefined
}

export const initialPartnerRedemptionFormValue: PartnerRedemptionForm = {
	name: '',
	airmileId: '',
	points: undefined,
	values: undefined
}

export interface CustomerManagementPointsAdjustmentForm {
	cardNumber: string
	accountNumber: string
	rewardPool: string
	adjustmentType: string
	pointsAdjustment: string
}

export const initialCustomerManagementPointsAdjustmentFormValue: CustomerManagementPointsAdjustmentForm = {
	cardNumber: '',
	accountNumber: '',
	rewardPool: '',
	adjustmentType: '',
	pointsAdjustment: ''
}

export const initialCustomerManagementListForm = createFormGroupState('customerManagementListForm', initialCustomerManagementListFormValue)

export const initialCatalogueRedemptionForm = createFormGroupState('catalogueRedemptionForm', initialCatalogueRedemptionFormValue)

export const initialWaiverForm = createFormGroupState('waiverForm', initialWaiverFormValue)

export const initialPartnerRedemptionForm = createFormGroupState('partnerRedemptionForm', initialPartnerRedemptionFormValue)

export const initialCustomerManagementPointsAdjustmentForm = createFormGroupState('customerManagementPointsAdjustmentForm', initialCustomerManagementPointsAdjustmentFormValue)

export const validateBasicInfoListForm = updateGroup<CustomerManagementListForm>({
	name: validate([maxLength(60)]),
	customerId: validate([maxLength(20), FieldValidator.checkAlphaNumeric()]),
	accountNumber: validate([maxLength(20), FieldValidator.numberWithoutDecimal()]),
	cardNumber: validate([maxLength(20), FieldValidator.numberWithoutDecimal()])
})

export const validateCatalogueRedemptionForm = (state: State) => {
	let addressCondition = false
	const poboxList = state && state.poboxList
	const catalogueRedemptionForm = state && state.catalogueRedemptionForm.value
	const address = catalogueRedemptionForm && `${catalogueRedemptionForm.address1} ${catalogueRedemptionForm.address2} ${catalogueRedemptionForm.address3}`
	const productCatalogue =  state && state.productCatalogue ? state.productCatalogue : null
	const productCategory = productCatalogue && productCatalogue.productCategory
	const redemptionType = productCatalogue && productCatalogue.redemptionType
	const kind = productCatalogue && productCatalogue.kind
	poboxList.forEach(pobox => {
		if (address.toUpperCase().indexOf(pobox.toUpperCase()) > -1) {
			addressCondition = true
		}
	})

	let isCataloguePartner = false
	let isCatalogue = false
	let airmileValidator = []
	let requiredEmailChecking = []
	let requiredCard = []
	if (kind === 'CP') {
		isCataloguePartner = true
		airmileValidator = [FieldValidator.alphaNumericWithoutSpaces()]
		requiredCard = [FieldValidator.requiredIfTrue(true)]
	} else {
		if (productCategory === 'V') {
			requiredEmailChecking = [FieldValidator.requiredIfMatch(productCategory, 'V')]
		} else {
			isCatalogue = true
		}
	}

	if (redemptionType === CatalogueRedemptionType.POINT_AMOUNT) {
		requiredCard = [FieldValidator.requiredIfMatch(redemptionType, CatalogueRedemptionType.POINT_AMOUNT)]
	}

	return updateGroup<CatalogueRedemptionForm>({
		cardId: validate([...requiredCard]),
		accountType: validate([FieldValidator.requiredIfTrue(isCatalogue)]),
		quantity: validate([required, maxLength(10), FieldValidator.numberWithoutDecimal()]),
		address1: validate([FieldValidator.requiredIfTrue(isCatalogue) , FieldValidator.requiredIfMatch(productCategory, 'G'), FieldValidator.ifTrue(addressCondition, 'pobox'), maxLength(40)]),
		address2: validate([maxLength(40)]),
		address3: validate([maxLength(40)]),
		city: validate([FieldValidator.requiredIfTrue(isCatalogue), FieldValidator.requiredIfMatch(productCategory, 'G'), maxLength(30)]),
		state: validate([FieldValidator.requiredIfTrue(isCatalogue), FieldValidator.requiredIfMatch(productCategory, 'G'), maxLength(30)]),
		postcode: validate([FieldValidator.requiredIfTrue(isCatalogue), FieldValidator.requiredIfMatch(productCategory, 'G'), maxLength(40)]),
		emailAddress: validate([...requiredEmailChecking]),
		airmileId: validate([FieldValidator.requiredIfTrue(isCataloguePartner), maxLength(16), ...airmileValidator]),
	})
}

export const validateWaiverForm = updateGroup<WaiverForm>({
	waiverTypeId: validate([required]),
	waiverId: validate([required])
})

export const validatePartnerRedemptionForm = (state: State) => {

	const points = state.partnerRedemptionForm.value.points
	const minimumPoints = state.point

	return updateGroup<PartnerRedemptionForm>({
		name: validate([required]),
		airmileId: validate([required, maxLength(16), FieldValidator.alphaNumericWithoutSpaces()]),
		points: validate([required, FieldValidator.checkMiniminPoint(points, minimumPoints)]),
		values: validate([required, FieldValidator.checkStartWithZeroNumber()])
	})
}

export const validateCustomerManagementPointsAdjustmentForm = updateGroup<CustomerManagementPointsAdjustmentForm>({
	rewardPool: validate([required]),
	adjustmentType: validate([required]),
	pointsAdjustment: validate([required, FieldValidator.integerAndDecimalCheck(10, 2)])
})

export const initialState: State = {
	isLoading: false,
	isTableLoading: false,
	rewardPage: false,
	accountPage: false,
	isCreditCard: false,
	customerManagementListForm: initialCustomerManagementListForm,
	catalogueRedemptionForm: initialCatalogueRedemptionForm,
	waiverForm: initialWaiverForm,
	partnerRedemptionForm: initialPartnerRedemptionForm,
	customerManagementPointsAdjustmentForm: initialCustomerManagementPointsAdjustmentForm,
	customerListResponse: {
		pageHeader: {
			itemCount: 0,
			pageNum: 0,
			pageSize: 0,
			totalItem: 0,
			totalPage: 0
		},
		customerList: []
	},
	customerViewResponse: {
		customerInformationList: [],
		creditCardList: [],
		depositList: [],
		rewardPoolGroupList: [],
		rewardPoolList: [],
		conditionalRewardList: []
	},
	customerCardViewResponse: {
		accountId: 0,
		accountNumber: '',
		accountStatus: '',
		accountBlockCode: '',
		cardNumber: '',
		cardName: '',
		cardType: '',
		cardExpiry: '',
		blockCode: '',
		billingCycle: 0,
		cardStatus: '',
		primaryOrSupplementaryFlag: {
			key: '',
			value: ''
		},
		cardOpenDate: '',
		cardJoinDate: '',
		cardIssueDate: '',
		cardActivationDate: '',
		resourceCode: '',
		previousStartDate: ''
	},
	customerCardViewTransactionResponse: {
		pageHeader: {
			itemCount: 0,
			pageNum: 0,
			pageSize: 0,
			totalItem: 0,
			totalPage: 0
		},
		transactionList: []
	},
	customerAccountViewResponse: {
		accountNumber: '',
		accountHolder: '',
		status: '',
		accountOpenDate: '',
		source: '',
		blockCode: '',
		productType: '',
		resourceCode: '',
		previousStartDate: ''
	},
	customerAccountViewTransactionListResponse: {
		pageHeader: {
			itemCount: 0,
			pageNum: 0,
			pageSize: 0,
			totalItem: 0,
			totalPage: 0
		},
		transactionList: []
	},
	customerAccountViewTransactionDetailResponse: {
		indicatorList: [],
		descriptionList: [],
		balanceList: [],
		dateList: [],
		timeList: [],
		amountList: []
	},
	customerRewardPoolViewResponse: {
		rewardPoolId: 0,
		rewardPoolName: '',
		rewardPoolGroupId: 0,
		rewardPoolGroupName: '',
		totalPoints: ''
	},
	productCatalogueList: [],
	customerCardList: [],
	customerAddressList: [],
	customerStatementTransactionListResponse: {
		statementList: []
	},
	waiverTypeList: [],
	waiverList: [],
	rewardPoolInformationList: [],
	accumulationBucketList: {
		accountNo: '',
		rewardPool: [],
		overallPoints: 0
	},
	customerRedemptionHistoryListResponse: {
		redemptionTransactionList: []
	},
	customerCatalogueRedemptionOrderViewResponse: {
		productCodeAndName: '',
		quantity: 0,
		totalPoints: 0,
		totalAmount: 0,
		productCategory: '',
		address: '',
		city: '',
		state: '',
		postcode: '',
		emailAddress: '',
		catalogueRedemptionOrderId: '',
		cardNumber: '',
		rewardPoolAndGroupValue: '',
		redemptionType: '',
		allowWithdraw: false
	},
	customerPartnerRedemptionOrderViewResponse: {
		productCodeAndName: '',
		quantity: 0,
		totalPoint: '',
		value: '',
		airmileId: ''
	},
	customerConditionalRewardViewResponse: [],
	partnerRedemptionList: [],
	partnerRedemptionKeyValueList: [],
	poboxList: [],
	cardTransactionOptionList: [],
	adjustmentTypeList: [],
	customerTransactionList: {
		spendAmountList: [],
		transactionList: []
	},
	rewardPoolListResponse: {
		rewardPools: []
	},
	accumulatingTransactionList: [],
	accumulatingTransactionOptionList: []
}
