import { createAction, props } from '@ngrx/store'
import { WorklistDetailResponse, WorklistResponse } from 'src/app/models/worklist/worklist'

export const InitialState = createAction('[My Request] InitialState')
export const RestError = createAction('[My Request] RestError', props<{ message: string, manualThrow: boolean }>())

export const GoList = createAction('[My Request] GoList')

export const List = createAction('[My Request] List', props<{ pageNumber: number, pageSize: number }>())
export const ListSuccess = createAction('[My Request] ListSuccess', props<{ payload: WorklistResponse }>())

export const ListByStatus = createAction('[My Request] ListByStatus', props<{ status: string, pageNumber: number, pageSize: number }>())
export const ListByStatusSuccess = createAction('[My Request] ListByStatusSuccess', props<{ status: string, payload: WorklistDetailResponse }>())

export const SearchListByKeyword = createAction('[My Request] SearchListByKeyword', props<{ keyword: string, status: string, pageNumber: number, pageSize: number }>())
