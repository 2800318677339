import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material'
import { Router } from '@angular/router'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Action, Store } from '@ngrx/store'
import { Observable, of } from 'rxjs'
import { catchError, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators'
import { Response } from 'src/app/models/common/http'
import { TargetSegmentOverview, TargetSegmentDetails, TargetSegmentValidationResponse } from 'src/app/models/target-segment/target-segment'
import { TargetSegmentService } from 'src/app/services/target-segment/target-segment.service'
import * as AppStore from 'src/app/store/'
import * as TargetSegmentAction from './target-segment.actions'
import { MessageDialogComponent } from 'src/app/components/common/dialog/message-dialog/message-dialog.component'
import { getDialogConfig, getGlobalDialogConfig } from 'src/app/models/common/dialog'
import { KeyValue } from '@angular/common'
import * as CommonAction from '../../common/common/common.actions'
import { TargetSegmentResultDialogComponent } from 'src/app/components/param-settings/target-segment/target-segment-result-dialog/target-segment-result-dialog.component'


@Injectable()
export class Effects {

	constructor(
		private action$: Actions,
		private router: Router,
		private dialog: MatDialog,
		private targetSegmentService: TargetSegmentService,
		private store: Store<AppStore.State>
	) { }

	InitialState$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(TargetSegmentAction.InitialState),
			tap(() => {
				this.router.navigate(['parameter-settings/target-segment'])
			})
		), { dispatch: false }
	)

	GetCardSegmentList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(TargetSegmentAction.GetCardSegmentList),
			mergeMap(() => {
				return this.targetSegmentService.getCardSegmentList().pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: TargetSegmentOverview[] = response.payload.cardSegmentList
					if (success) {
						return TargetSegmentAction.GetCardSegmentListSuccess({ payload })
					} else { return TargetSegmentAction.RestError(response as any) }

				}), catchError(this.catcher))
			})
		)
	)

	GetAccountSegmentList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(TargetSegmentAction.GetAccountSegmentList),
			mergeMap(() => {
				return this.targetSegmentService.getAccountSegmentList().pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: TargetSegmentOverview[] = response.payload.accountSegmentList
					if (success) {
						return TargetSegmentAction.GetAccountSegmentListSuccess({ payload })
					} else { return TargetSegmentAction.RestError(response as any) }

				}), catchError(this.catcher))
			})
		)
	)

	GetCustomerIdSegmentList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(TargetSegmentAction.GetCustomerIdSegmentList),
			mergeMap(() => {
				return this.targetSegmentService.getCustomerIdSegmentList().pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: TargetSegmentOverview[] = response.payload.customerIdSegmentList
					if (success) {
						return TargetSegmentAction.GetCustomerIdSegmentListSuccess({ payload })
					} else { return TargetSegmentAction.RestError(response as any) }

				}), catchError(this.catcher))
			})
		)
	)

	ViewAccountSegmentDetail$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(TargetSegmentAction.ViewAccountSegmentDetail),
			withLatestFrom(this.store.select(state => state.targetSegment)),
			mergeMap(([x, targetSegment]) => {
				return this.targetSegmentService.getTargetSegmentDetails({
					segment: targetSegment.segment, id: targetSegment.id
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: TargetSegmentDetails = response.payload
					if (success) {
						return TargetSegmentAction.ViewAccountSegmentDetailSuccess({ payload })
					} else { return TargetSegmentAction.RestError(response as any) }
				}), catchError(this.catcher))
			})
		)
	)

	ViewCardSegmentDetail$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(TargetSegmentAction.ViewCardSegmentDetail),
			withLatestFrom(this.store.select(state => state.targetSegment)),
			mergeMap(([x, targetSegment]) => {
				return this.targetSegmentService.getTargetSegmentDetails({
					segment: targetSegment.segment, id: targetSegment.id
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: TargetSegmentDetails = response.payload
					if (success) {
						return TargetSegmentAction.ViewCardSegmentDetailSuccess({ payload })
					} else { return TargetSegmentAction.RestError(response as any) }
				}), catchError(this.catcher))
			})
		)
	)

	ViewCustomerIdSegmentDetail$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(TargetSegmentAction.ViewCustomerIdSegmentDetail),
			withLatestFrom(this.store.select(state => state.targetSegment)),
			mergeMap(([x, targetSegment]) => {
				return this.targetSegmentService.getTargetSegmentDetails({
					segment: targetSegment.segment, id: targetSegment.id
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: TargetSegmentDetails = response.payload
					if (success) {
						return TargetSegmentAction.ViewCustomerIdSegmentDetailSuccess({ payload })
					} else { return TargetSegmentAction.RestError(response as any) }
				}), catchError(this.catcher))
			})
		)
	)

	GoViewCardSegmentDetail$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(TargetSegmentAction.GoViewCardSegmentDetail),
			tap(() => {
				this.router.navigate(['parameter-settings/target-segment/card-segment/view'])
			})
		), { dispatch: false }
	)

	GoViewAccountSegmentDetail$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(TargetSegmentAction.GoViewAccountSegmentDetail),
			tap(() => {
				this.router.navigate(['parameter-settings/target-segment/account-segment/view'])
			})
		), { dispatch: false }
	)

	GoViewCustomerIdSegmentDetail$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(TargetSegmentAction.GoViewCustomerIdSegmentDetail),
			tap(() => {
				this.router.navigate(['parameter-settings/target-segment/customer-id-segment/view'])
			})
		), { dispatch: false }
	)

	GoCustomerCreate$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(TargetSegmentAction.GoCustomerCreate),
			tap(() => {
				this.router.navigate(['parameter-settings/target-segment/customer-id-segment/create'])
			})
		), { dispatch: false }
	)

	GoCardCreate$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(TargetSegmentAction.GoCardCreate),
			tap(() => {
				this.router.navigate(['parameter-settings/target-segment/card-segment/create'])
			})
		), { dispatch: false }
	)

	GoAccountCreate$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(TargetSegmentAction.GoAccountCreate),
			tap(() => {
				this.router.navigate(['parameter-settings/target-segment/account-segment/create'])
			})
		), { dispatch: false }
	)

	GoCustomerEdit$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(TargetSegmentAction.GoCustomerEdit),
			tap(() => {
				this.router.navigate(['parameter-settings/target-segment/customer-id-segment/update'])
			})
		), { dispatch: false }
	)

	GoCardEdit$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(TargetSegmentAction.GoCardEdit),
			tap(() => {
				this.router.navigate(['parameter-settings/target-segment/card-segment/update'])
			})
		), { dispatch: false }
	)

	GoAccountEdit$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(TargetSegmentAction.GoAccountEdit),
			tap(() => {
				this.router.navigate(['parameter-settings/target-segment/account-segment/update'])
			})
		), { dispatch: false }
	)

	GoList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(TargetSegmentAction.GoList),
			tap(() => {
				this.router.navigate(['parameter-settings/target-segment'])
			})
		), { dispatch: false }
	)

	GoBackDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(TargetSegmentAction.GoBackDialog),
			mergeMap(({ action }) => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.BACK', payload: action }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					if (result.payload === 'LIST') {
						return TargetSegmentAction.GoList()
					}
					if (result.payload === 'VIEW_CUSTOMER') {
						return TargetSegmentAction.GoViewCustomerIdSegmentDetail()
					}
					if (result.payload === 'VIEW_ACCOUNT') {
						return TargetSegmentAction.GoViewAccountSegmentDetail()
					}
					if (result.payload === 'VIEW_CARD') {
						return TargetSegmentAction.GoViewCardSegmentDetail()
					}
				} else {
					return TargetSegmentAction.CloseDialog()
				}
			})
		)
	)

	ViewTargetSegmentDetailEdit$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(TargetSegmentAction.ViewTargetSegmentDetailEdit),
			withLatestFrom(this.store.select(state => state.targetSegment)),
			mergeMap(([x, targetSegment]) => {
				return this.targetSegmentService.getTargetSegmentDetails({
					segment: targetSegment.segment, id: targetSegment.id
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: TargetSegmentDetails = response.payload
					if (success) {
						return TargetSegmentAction.ViewTargetSegmentDetailEditSuccess({ payload })
					} else { return TargetSegmentAction.RestError(response as any) }
				}), catchError(this.catcher))
			})
		)
	)

	GetResourceList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(TargetSegmentAction.GetResourceList),
			withLatestFrom(this.store.select(state => state.targetSegment)),
			mergeMap(([x, targetSegment]) => {
				return this.targetSegmentService.getTargetSegmentResourceList({
					segment: targetSegment.segment, action: x.action
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: KeyValue<string, string>[] = response.payload
					if (success) {
						return TargetSegmentAction.GetResourceListSuccess({ payload })
					} else { return TargetSegmentAction.RestError(response as any) }
				}), catchError(this.catcher))
			})
		)
	)

	DeleteDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(TargetSegmentAction.DeleteDialog),
			mergeMap((x) => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: x.payload }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return TargetSegmentAction.Delete()
				} else {
					return TargetSegmentAction.CloseDialog()
				}
			})
		)
	)

	Delete$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(TargetSegmentAction.Delete),
			withLatestFrom(this.store.select(state => state.targetSegment)),
			mergeMap(([x, targetSegment]) => this.targetSegmentService.deleteTargetSegment({
				segment: targetSegment.segment, id: targetSegment.id
			}).pipe(map((response: Response) => {
				const success: boolean = response.success
				const payload: string = response.payload

				if (success) {
					return TargetSegmentAction.DeleteSuccess({ payload })
				} else { return TargetSegmentAction.RestError(response as any) }

			}, catchError(this.catcher))
			)
			)
		)
	)

	DeleteSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(TargetSegmentAction.DeleteSuccess),
			mergeMap(({ payload }) => {
				return [TargetSegmentAction.GoList(), CommonAction.RestError({ message: payload })]
			})
		)
	)

	UpdateDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(TargetSegmentAction.UpdateDialog),
			mergeMap((x) => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.UPDATE', payload: x.payload }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return TargetSegmentAction.Update({ payload: result.payload })
				} else {
					return TargetSegmentAction.CloseDialog()
				}
			})
		)
	)

	Update$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(TargetSegmentAction.Update),
			withLatestFrom(this.store.select(state => state.targetSegment)),
			mergeMap(([x, targetSegment]) => {
				const form = targetSegment.targetSegmentDetailForm.controls
				return this.targetSegmentService.updateTargetSegment({
					direction: { segment: targetSegment.segment, id: targetSegment.id },
					body: {
						name: form.name.value,
						resourceCode: form.resourceCode.value,
						description: form.description.value,
						[`${targetSegment.segment}s` as string]: x.payload
					}
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: TargetSegmentValidationResponse = response.payload

					if (success) {
						return TargetSegmentAction.OpenSuccessValidationDialogUpdate({ segment: targetSegment.segment, payload })
					} else { return TargetSegmentAction.RestError(response as any) }

				}), catchError(this.catcher)
				)
			})
		)
	)

	OpenSuccessValidationDialogUpdate$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(TargetSegmentAction.OpenSuccessValidationDialogUpdate),
			mergeMap(({ segment, payload }) => {
				const dialogRef = this.dialog.open(TargetSegmentResultDialogComponent, getGlobalDialogConfig({
					title: 'TARGET_SEGMENT.CONFIRMATION_LIST',
					payload: { segment, ...payload }
				}))
				return dialogRef.afterClosed()
			}),
			map(({ payload }) => TargetSegmentAction.UpdateSuccess(payload.message))
		)
	)

	UpdateSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(TargetSegmentAction.UpdateSuccess),
			withLatestFrom(this.store.select(state => state.targetSegment)),
			mergeMap(([x, targetSegment]) => {
				if (targetSegment.segment) {
					if (targetSegment.segment === 'customerId') {
						return [TargetSegmentAction.GoViewCustomerIdSegmentDetail()]
					}
					if (targetSegment.segment === 'account') {
						return [TargetSegmentAction.GoViewAccountSegmentDetail()]
					}
					if (targetSegment.segment === 'card') {
						return [TargetSegmentAction.GoViewCardSegmentDetail()]
					}
				} else {
					return [TargetSegmentAction.GoList(), CommonAction.RestError({ message: x.payload.message })]
				}
			})
		)
	)

	CreateDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(TargetSegmentAction.CreateDialog),
			mergeMap((x) => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: x.content, payload: x.payload }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return TargetSegmentAction.Create({ payload: result.payload })
				} else {
					return TargetSegmentAction.CloseDialog()
				}
			})
		)
	)

	Create$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(TargetSegmentAction.Create),
			withLatestFrom(this.store.select(state => state.targetSegment)),
			mergeMap(([x, targetSegment]) => {
				const form = targetSegment.targetSegmentDetailForm.controls
				return this.targetSegmentService.createTargetSegment({
					segment: targetSegment.segment,
					body: {
						code: x.payload.prefix + form.code.value,
						name: form.name.value,
						resourceCode: form.resourceCode.value,
						description: form.description.value,
						[`${targetSegment.segment}s` as string]: x.payload.list.map(o => o.replace('\r', ''))
					}
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: TargetSegmentValidationResponse = response.payload

					if (success) {
						return TargetSegmentAction.CreateSuccess({ segment: targetSegment.segment, payload })
					} else { return TargetSegmentAction.RestError(response as any) }

				}), catchError(this.catcher)
				)
			})
		)
	)

	CreateSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(TargetSegmentAction.CreateSuccess),
			mergeMap(({ segment, payload }) => {
				return [TargetSegmentAction.OpenSuccessValidationDialogCreate({ segment, payload }), CommonAction.RestError({ message: payload.message })]
			})
		)
	)

	OpenSuccessValidationDialogCreate$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(TargetSegmentAction.OpenSuccessValidationDialogCreate),
			mergeMap(({ segment, payload }) => {
				const dialogRef = this.dialog.open(TargetSegmentResultDialogComponent, getGlobalDialogConfig({
					title: 'TARGET_SEGMENT.CONFIRMATION_LIST',
					payload: { segment, ...payload }
				}))
				return dialogRef.afterClosed()
			}),
			map(() => TargetSegmentAction.GoList())
		)
	)

	catcher = (error) => {
		return (of(TargetSegmentAction.RestError({ message: error.message, manualThrow: error.manualThrow })))
	}
}
