import { Action, createReducer, on } from '@ngrx/store'
import { formGroupReducer, reset, setValue } from 'ngrx-forms'
import * as PolicyAction from './policy.actions'
import * as PolicyState from './policy.state'

export const reducer = createReducer(
	PolicyState.initialState,
	on(PolicyAction.InitialState, () => PolicyState.initialState),
	on(PolicyAction.Reset, state => ({
		...state,
		policyListForm: reset(setValue(state.policyListForm, PolicyState.initialPolicyListFormValue))
	})),
	on(PolicyAction.SetId, (state, { id }) => ({
		...state,
		id
	})),
	on(PolicyAction.RestError, state => ({
		...state,
		isLoading: false
	})),
	on(PolicyAction.List, state => ({
		...state,
		isLoading: true
	})),
	on(PolicyAction.ListSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		policyListReponse: payload
	}))
)

export function Reducer(state: PolicyState.State = PolicyState.initialState, action: Action) {
	const policyListForm = PolicyState.validatePolicyListForm(formGroupReducer(state.policyListForm, action))
	if (policyListForm !== state.policyListForm) {
		state = { ...state, policyListForm }
	}
	return reducer(state, action)
}
