import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core'
import { MatTableDataSource } from '@angular/material'
import {
	AccumulatingTransactionListData,
	CustomerTransactionListDataTable
} from '../../../../models/customer-management/customer-account-detail'
import { CardTransactionListDataColumns } from '../../../../models/customer-management/customer-card-detail'

@Component({
	selector: 'app-accumulating-transactions-table',
	templateUrl: './accumulating-transactions-table.component.html',
	styleUrls: ['./accumulating-transactions-table.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class AccumulatingTransactionsTableComponent implements OnInit {

	@Input() accumulatingTransactionList: AccumulatingTransactionListData
	accumulatingTransactionsDataSource: MatTableDataSource<CustomerTransactionListDataTable>
	pendingTableDisplayedColumns: CardTransactionListDataColumns[] = ['postingDate', 'description', 'transactionDate', 'campaignName', 'transactionCode', 'amount', 'point']
	cardSpendAmount: string
	totalSpendAmount: string

	@ViewChild('pendingPaginator', { static: true }) pendingPaginator

	formatTo2Decimal = new Intl.NumberFormat('en-US', {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2
	})

	constructor() { }

	ngOnInit() {
		this.accumulatingTransactionsDataSource = new MatTableDataSource()
		this.accumulatingTransactionsDataSource.paginator = this.pendingPaginator
		if (this.accumulatingTransactionList) {
			this.accumulatingTransactionsDataSource.data = this.accumulatingTransactionList.transactionList
				.map(o => ({
					...o,
					amount: this.formatTo2Decimal.format(o.amount || 0),
					point: this.formatTo2Decimal.format(o.point || 0)
				}))

			this.totalSpendAmount = this.formatTo2Decimal.format(this.accumulatingTransactionList.spendAmountList
				.map(i => i.spendAmount)
				.reduce((a, b) => a + b, 0) || 0)

			this.cardSpendAmount = this.accumulatingTransactionList.spendAmountList
				.map(i => `${i.cardNumber}: ${this.formatTo2Decimal.format(i.spendAmount || 0)}`)
				.join('\n')

			console.warn(this.cardSpendAmount)
		}
	}
}
