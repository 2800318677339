<form novalidate [ngrxFormState]="formState$ | async" fxLayout="column">
  <div *ngIf="isView" fxLayout="column">
    <mat-form-field fxFlex>
      <input matInput value="{{getIncludeExcludeValue((formState$ | async).controls.equation.value)}}" type="text"
        maxlength="255" readonly>
    </mat-form-field>

    <mat-form-field fxFlex>
      <input matInput value="{{getProductTypeValue((formState$ | async).controls.value.value)}}" type="text"
        maxlength="255" readonly>
    </mat-form-field>

    <mat-form-field fxFlex>
      <mat-label>{{ 'CAMPAIGN.PRODUCT_TYPE_GROUP' | translate }}</mat-label>
      <input matInput value="{{getProductTypeGroupsValue((formState$ | async).controls.values.value)}}" type="text"
        maxlength="255" readonly>
    </mat-form-field>
  </div>

  <div *ngIf="!isView" fxLayout="column">
    <mat-radio-group [ngrxFormControlState]="(formState$ | async).controls.equation" color="primary" fxLayoutGap="8px">
      <mat-radio-button *ngFor="let i of includeExcludeList" [value]="i.key">
        {{i.value}}
      </mat-radio-button>
    </mat-radio-group>

    <mat-form-field fxFlex>
      <mat-select [ngrxFormControlState]="(formState$ | async).controls.value" multiple #multipleSelect
        (closed)="clearSearch()">
        <ngx-mat-select-search [formControl]="dropDownCtrl" [placeholderLabel]="'COMMON.SEARCH'| translate"
          [noEntriesFoundLabel]="'COMMON.NOT_MATCHING_FOUND' | translate">
          <mat-icon ngxMatSelectSearchClear (click)="clearSearch()">close</mat-icon>
        </ngx-mat-select-search>
        <mat-option *ngFor="let productType of filteredProductTypes | async" [value]="productType.key">
          {{productType.value}}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="(formState$ | async).errors._value">
        <app-error-validation [errors]="(formState$ | async).errors._value">
        </app-error-validation>
      </mat-error>
    </mat-form-field>

    <mat-form-field fxFlex>
      <mat-label>{{ 'CAMPAIGN.PRODUCT_TYPE_GROUP' | translate }}</mat-label>
			<mat-select [ngrxFormControlState]="(formState$ | async).controls.values" multiple (closed)="clearSearchProductTypeGroups()">
				<ngx-mat-select-search [formControl]="dropDownCtrlProductTypeGroups" [placeholderLabel]="'COMMON.SEARCH'| translate"
					[noEntriesFoundLabel]="'COMMON.NOT_MATCHING_FOUND' | translate">
					<mat-icon ngxMatSelectSearchClear (click)="clearSearchProductTypeGroups()">close</mat-icon>
				</ngx-mat-select-search>
				<mat-option *ngFor="let productTypeGroup of filteredProductTypeGroups | async" [value]="productTypeGroup.key">
					{{productTypeGroup.value}}
				</mat-option>
			</mat-select>
      <mat-error *ngIf="(formState$ | async).errors._values">
        <app-error-validation [errors]="(formState$ | async).errors._values">
        </app-error-validation>
      </mat-error>
    </mat-form-field>
  </div>
</form>
