import { Action, createReducer, on } from '@ngrx/store'
import { formGroupReducer, reset, setValue } from 'ngrx-forms'
import * as MerchantGroupAction from './merchant-group.actions'
import * as MerchantGroupState from './merchant-group.state'

export const reducer = createReducer(
	MerchantGroupState.initialState,
	on(MerchantGroupAction.InitialState, () => MerchantGroupState.initialState),
	on(MerchantGroupAction.Reset, state => ({
		...state,
	})),
	on(MerchantGroupAction.SetId, (state, { code }) => ({
		...state,
		code
	})),
	on(MerchantGroupAction.GoCreate, state => ({
		...state,
		merchantGroupDetailForm: reset(setValue(state.merchantGroupDetailForm, MerchantGroupState.initialMerchantGroupDetailFormValue))
	})),
	on(MerchantGroupAction.RestError, state => ({
		...state,
		isLoading: false,
		isDuplicateLoading: false,
		isDuplicateDialog: false
	})),
	on(MerchantGroupAction.List, state => ({
		...state,
		isLoading: true
	})),
	on(MerchantGroupAction.ListSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		merchantGroupListResponse: payload
	})),
	on(MerchantGroupAction.ResourceListCreate, state => ({
		...state,
		isLoading: true
	})),
	on(MerchantGroupAction.ResourceListCreateSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		resourceList: (payload || [])
	})),
	on(MerchantGroupAction.ResourceListUpdate, state => ({
		...state,
		isLoading: true
	})),
	on(MerchantGroupAction.ResourceListUpdateSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		resourceList: (payload || [])
	})),
	on(MerchantGroupAction.View, state => ({
		...state,
		isLoading: true,
		merchantGroupDetailForm: setValue(state.merchantGroupDetailForm, MerchantGroupState.initialMerchantGroupDetailFormValue),
		allowDelete: true
	})),
	on(MerchantGroupAction.ViewSuccess, (state, { payload }) => {
		const merchantGroupDetailFormView = {
			code: payload.code,
			name: payload.name,
			description: payload.description,
			merchantId: payload.merchantId,
			createdBy: payload.createdBy,
			createdDate: payload.createdDate,
			modifiedBy: payload.modifiedBy,
			modifiedDate: payload.modifiedDate
		}
		return ({
			...state,
			isLoading: false,
			merchantGroupDetailForm: MerchantGroupState.validateMerchantGroupDetailForm(setValue(state.merchantGroupDetailForm, merchantGroupDetailFormView as MerchantGroupState.MerchantGroupDetailForm)),
			allowDelete: payload.allowDelete,
			merchantGroupDetailFormView
		})
	}),
	on(MerchantGroupAction.Create, state => ({
		...state,
		isLoading: true
	})),
	on(MerchantGroupAction.CreateSuccess, (state, { payload }) => ({
		...MerchantGroupState.initialState
	})),
	on(MerchantGroupAction.Update, state => ({
		...state,
		isLoading: true
	})),
	on(MerchantGroupAction.UpdateSuccess, (state, { payload }) => ({
		...state
	})),
	on(MerchantGroupAction.Delete, state => ({
		...state,
		isLoading: true
	})),
	on(MerchantGroupAction.DeleteSuccess, () => MerchantGroupState.initialState),
	on(MerchantGroupAction.DuplicateDialog, state => ({
		...state,
		merchantGroupDuplicateForm: reset(setValue(state.merchantGroupDuplicateForm, MerchantGroupState.initialMerchantGroupDuplicateFormValue)),
		isDuplicateDialog: false
	})),

	on(MerchantGroupAction.Duplicate, state => ({
		...state,
		isDuplicateLoading: true
	})),

	on(MerchantGroupAction.DuplicateSuccess, state => ({
		...state,
		isDuplicateLoading: false,
		isDuplicateDialog: true
	}))
)

export function Reducer(state: MerchantGroupState.State = MerchantGroupState.initialState, action: Action) {

	const merchantGroupDetailForm = MerchantGroupState.validateMerchantGroupDetailForm(formGroupReducer(state.merchantGroupDetailForm, action))
	const merchantGroupDuplicateForm = MerchantGroupState.validateMerchantGroupDuplicateForm(formGroupReducer(state.merchantGroupDuplicateForm, action))

	if (merchantGroupDetailForm !== state.merchantGroupDetailForm) {
		state = { ...state, merchantGroupDetailForm }
	}

	if (merchantGroupDuplicateForm !== state.merchantGroupDuplicateForm) {
		state = { ...state, merchantGroupDuplicateForm }
	}

	return reducer(state, action)
}
