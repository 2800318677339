import { Component, Input, OnInit } from '@angular/core'
import { Observable } from 'rxjs'
import { Option } from '../../../../../models/option/option'
import { FormGroupState } from 'ngrx-forms'
import { RedeemRulesDetail } from '../../../../../store/redemption-settings/redeem-rules'
import { Store } from '@ngrx/store'
import * as AppStore from '../../../../../store'
import * as RedeemRulesAction from 'src/app/store/redemption-settings/redeem-rules'
import * as RedeemRuleSelectors from '../../../../../store/redemption-settings/redeem-rules/redeem-rules.selectors'
import { map } from 'rxjs/internal/operators/map'
import * as R from 'ramda'

@Component({
	selector: 'app-redeem-rule-card-status',
	templateUrl: './redeem-rule-card-status.component.html',
	styleUrls: ['./redeem-rule-card-status.component.scss']
})
export class RedeemRuleCardStatusComponent implements OnInit {

	@Input() rewardPool: Option
	@Input() isView: boolean

	formState$: Observable<FormGroupState<RedeemRulesDetail>>
	dataState$: Observable<{
		isLoading: boolean,
		cardStatus: string,
		cardStatusFlag: string
		includeExcludeList: Option[]
	}>

	constructor(
		private store: Store<AppStore.State>
	) {
	}

	ngOnInit() {
		this.dataState$ = this.store.select(RedeemRulesAction.getRedeemRules)
			.pipe(
				map(({
					isLoading,
					redeemRulesData,
					redeemRulesForm
				}) => {

					const [cardStatus, cardStatusFlag] = R.compose(
						R.paths([['cardStatus'], ['cardStatusFlag']]),
						R.path(['value', 'rules', this.rewardPool.value, 'form', 'value']),
					)(redeemRulesForm)

					const keyEqCardStatusFlag = R.propEq('key', cardStatusFlag)

					const cardStatusFlagName = R.compose(
						R.pathOr('', ['value']),
						R.find(keyEqCardStatusFlag),
					)(redeemRulesData.includeExcludeList)

					return {
						isLoading,
						cardStatus,
						cardStatusFlag: cardStatusFlagName,
						includeExcludeList: redeemRulesData.includeExcludeList
					}
				})
			)
		this.formState$ = this.store.select(RedeemRuleSelectors.getForm, this.rewardPool.value)
	}

	removeRule(payload: string, params: string) {
		this.store.dispatch(RedeemRulesAction.RemoveRule({ payload, params }))
	}
}
