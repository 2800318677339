import { Component, Inject, OnInit } from '@angular/core'
import { AbstractControl, FormControl, Validators } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material'
import { Subscription } from 'rxjs'
import { MessageDialogData } from 'src/app/models/common/dialog'
import { Store } from '@ngrx/store'
import * as AppStore from 'src/app/store'
import { MccGroupUpdateInput } from 'src/app/models/param-settings/mcc-group/mcc-group-update'
import * as MccGroupAction from 'src/app/store/param-settings/mcc-group'

function inputValidation() {
	return (control: AbstractControl): { [key: string]: boolean } | null => {
		let textInput: boolean
		if (control.value) {
			if (control.value.length === 9) {
				const splitArray = control.value.split('-')
				textInput = control.value === null || control.value === '' || splitArray[0].match(/^\d+$/) && splitArray[1].match(/^\d+$/) && splitArray[1] > splitArray[0]
			} else if (control.value.length === 4) {
				textInput = control.value === null || control.value === '' || control.value.match(/^\d+$/)
			} else {
				textInput = control.value === null || control.value === ''
			}
		}

		return textInput ? null : {containInvalidCharacters: true}
	}
}

@Component({
	selector: 'app-mcc-group-dialog',
	templateUrl: './mcc-group-dialog.component.html',
	styleUrls: ['./mcc-group-dialog.component.scss']
})
export class MccGroupDialogComponent implements OnInit {

	parameters: MccGroupUpdateInput
	formState$: FormControl

	type = ''
	txtInput = ''
	title = ''
	content = ''
	pendingList: string[] = []
	subs: Subscription
	isLoading = false

	constructor(
		private dialogRef: MatDialogRef<MccGroupDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: MessageDialogData,
		private store: Store<AppStore.State>
	) {
		this.subs = this.store.select(MccGroupAction.getMccGroup).subscribe(x => {
			this.isLoading = x.isLoading
		})

		this.title = data.title
		this.content = data.content
		this.parameters = {
			rangeInput: data.payload.rangeInput
		}
		this.type = data.payload.type
		this.pendingList = (data.payload.pendingList || [])

		this.formState$ = new FormControl(this.parameters.rangeInput, [inputValidation(), Validators.maxLength(9)])

	}

	ngOnInit() {
	}

	cancel() {
		this.dialogRef.close()
	}

	submit(action) {
		switch (this.type) {
			case 'ADD_EDIT':
				this.data.payload = this.formState$.value
				break
			case 'APPEND_REPLACE':
				this.data.payload = {action, pendingList: this.pendingList}
				break
		}
		this.dialogRef.close(this.data)
	}

	isInvalidOrEqualToOldValue() {
		return this.formState$.invalid || (this.formState$.value === this.data.payload.txtInput)
	}
}
