import { createAction, props } from '@ngrx/store'
import { LoginUser } from 'src/app/models/common/common'

export const Logout = createAction('[Navbar] Logout')
export const LogoutSuccess = createAction('[Navbar] LogoutSuccess')
export const RestError = createAction('[Rest] RestError', props<{ message?: string, manualThrow?: boolean }>())
export const SuccessMessage = createAction('[Success] SuccessMessage', props<{ message?: string}>())
export const ToggleSidenav = createAction('[Sidenav] ToggleSidenav', props<{ flag: boolean }>())
export const SetLoginUser = createAction('[Common] LoginUser', props<{ payload: LoginUser }>())
export const SetPreviousRoute = createAction('[Common] SetPreviousRoute', props<{ previousRoute: string }>())
export const SetCurrentActiveNav = createAction('[Common] SetCurrentActiveNav', props<{ currentActiveNav: string }>())
export const SelectInitial = createAction('[Select] SelectInitial')
