import { createAction, props } from '@ngrx/store'
import { Worklist, TaskCodeResponse } from 'src/app/models/worklist/worklist'

export const InitialState = createAction('[WorklistTask] InitialState')
export const RestError = createAction('[WorklistTask] RestError', props<{ message: string, manualThrow: boolean }>())

export const SetWorklistTask = createAction('[WorklistTask] SetWorklistTask', props<{ payload: Worklist }>())

export const SetModuleFrom = createAction('[WorklistTask] SetModuleFrom', props<{ payload: 'tasklist' | 'my-request' }>())

export const ShowWorklistFunction = createAction('[WorklistTask] ShowWorklistFunction')
export const ResetWorklistTask = createAction('[WorklistTask] ResetWorklistTask')

export const GetWorklistTask = createAction('[WorklistTask] GetWorklistTask')
export const GetWorklistTaskSuccess = createAction('[WorklistTask] GetWorklistTaskSuccess', props<{ payload: Worklist }>())

export const GetCodeFromDataIdCampaign = createAction('[WorklistTask] GetCodeFromDataIdCampaign')
export const GetCodeFromDataIdCampaignSuccess = createAction('[WorklistTask] GetCodeFromDataIdSuccess', props<{ payload: TaskCodeResponse }>())

export const GetCodeFromDataIdRepublishCampaign = createAction('[WorklistTask] GetCodeFromDataIdRepublishCampaign')
export const GetCodeFromDataIdRepublishCampaignSuccess = createAction('[WorklistTask] GetCodeFromDataIdRepublishCampaignSuccess', props<{ payload: TaskCodeResponse }>())

export const GetCodeFromDataIdCampaignProductBundling = createAction('[WorklistTask] GetCodeFromDataIdProductBundling')
export const GetCodeFromDataIdCampaignProductBundlingSuccess = createAction('[WorklistTask] GetCodeFromDataIdProductBundlingSuccess', props<{ payload: TaskCodeResponse }>())

export const GetCodeFromDataIdRepublishCampaignProductBundling = createAction('[WorklistTask] GetCodeFromDataIdRepublishCampaignProductBundling')
export const GetCodeFromDataIdRepublishCampaignProductBundlingSuccess = createAction('[WorklistTask] GetCodeFromDataIdRepublishCampaignProductBundlingSuccess', props<{ payload: TaskCodeResponse }>())

export const GetCodeFromDataIdRepublishConditionalReward = createAction('[WorklistTask] GetCodeFromDataIdRepublishConditionalReward')
export const GetCodeFromDataIdRepublishConditionalRewardSuccess = createAction('[WorklistTask] GetCodeFromDataIdRepublishConditionalRewardSuccess', props<{ payload: TaskCodeResponse }>())

export const SetCodeAndGoToCampaign = createAction('[WorklistTask] SetCodeAndGoToCampaign')
export const SetIdAndGoToPointTransactionFileUpload = createAction('[WorklistTask] SetIdAndGoToPointTransactionFileUpload')
export const SetIdAndGoToConditionalReward = createAction('[WorklistTask] SetIdAndGoToConditionalReward')
export const SetIdAndGoToPointsAdjustment = createAction('[WorklistTask] SetIdAndGoToPointsAdjustment')
export const SetIdAndGoToRepublishConditionalReward = createAction('[WorklistTask] SetIdAndGoToRepublishConditionalReward')
export const SetIdAndGoToCatalogueRedemptionOrder = createAction('[WorklistTask] SetIdAndGoToCatalogueRedemptionOrder')

export const ApproveWorklistTask = createAction('[WorklistTask] ApproveWorklistTask')
export const ApproveDialog = createAction('[WorklistTask] ApproveDialog')

export const RejectWorklistTask = createAction('[WorklistTask] RejectWorklistTask', props<{ payload: string }>())
export const RejectReasonDialog = createAction('[WorklistTask] RejectReasonDialog')
export const RejectDialog = createAction('[WorklistTask] RejectDialog', props<{ payload: string }>())

export const WithdrawWorklistTask = createAction('[WorklistTask] WithdrawWorklistTask')
export const WithdrawDialog = createAction('[WorklistTask] WithdrawDialog')

export const CloseDialog = createAction('[WorklistTask] CloseDialog')
export const WorklistTaskPerformSuccess = createAction('[WorklistTask] WorklistTaskPerformSuccess')
export const ReturnToWhereItComeFrom = createAction('[WorklistTask] ReturnToWhereItComeFrom')
