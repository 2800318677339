import { Component, Inject, OnInit, OnDestroy } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA, MatTableDataSource } from '@angular/material'
import { MessageDialogData } from 'src/app/models/common/dialog'
import { GlobalDialogBox } from 'src/app/models/util/global-dialog'
import { Store } from '@ngrx/store'
import * as AppStore from 'src/app/store'
import { Subscription } from 'rxjs'
import * as GroupSelectors from 'src/app/store/access-management/group/group.selectors'
import * as UserSelectors from 'src/app/store/access-management/user/user.selectors'
import * as RewardPoolSelectors from 'src/app/store/param-settings/reward-pool-group/reward-pool-group.selectors'

@Component({
	selector: 'app-global-message-dialog',
	templateUrl: './global-message-dialog.component.html',
	styleUrls: ['./global-message-dialog.component.scss']
})
export class GlobalMessageDialogComponent implements OnInit, OnDestroy {

	title: string
	content: string
	contentParam: string
	cancelBtn: string
	submitBtn: string
	customVariable: any
	groupSubs: Subscription
	rewardPoolGroupSubs: Subscription
	dataSource: any
	error: string
	tableIsLoading: any
	tableColumnName: string
	multipleColumnTableName: string
	module: string
	table = []
	multipleColumnTable = []
	displayedColumns = []

	constructor(
		private dialogRef: MatDialogRef<GlobalMessageDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: MessageDialogData,
		private store: Store<AppStore.State>
	) {
		this.title = data.title
		this.content = data.content
		this.contentParam = data.contentParam
		this.table = data.table
		this.tableColumnName = data.tableColumnName
		this.module = data.module
		if (this.table) {
			this.displayedColumns = ['payload', 'success']
			this.dataSource = new MatTableDataSource()
			if (this.module === 'GROUP') {
				this.groupSubs = this.store.select(GroupSelectors.getGroup).subscribe(x => {
					this.tableIsLoading = x.isTableLoading
					if (x.policyListResult) {
						this.dataSource.data = x.policyListResult
					}
				})
			}
			if (this.module === 'USER') {
				this.groupSubs = this.store.select(UserSelectors.getUser).subscribe(x => {
					this.tableIsLoading = x.isTableLoading
					if (x.groupListResult) {
						this.dataSource.data = x.groupListResult
					}
				})
			}
			if (this.module === 'REWARD_POOL_GROUP') {
				this.rewardPoolGroupSubs = this.store.select(RewardPoolSelectors.getRewardPoolGroup).subscribe(x => {
					this.tableIsLoading = x.isTableLoading
					if (x.glMapListResult) {
						this.dataSource.data = x.glMapListResult
					}
				})
			}
		}

		this.cancelBtn = data.cancelBtn || 'ACTION.NO'
		this.submitBtn = data.submitBtn || 'ACTION.YES'
	}

	ngOnInit() {
		this.customVariable = { ...GlobalDialogBox.customVariable }
	}

	ngOnDestroy() {
		GlobalDialogBox.customVariable = {}
		if (this.groupSubs) { this.groupSubs.unsubscribe() }
		if (this.rewardPoolGroupSubs) { this.rewardPoolGroupSubs.unsubscribe() }
	}

	cancel() {
		this.dialogRef.close()
	}

	submit() {
		if (typeof (this.customVariable.onOk) === 'function') {
			this.customVariable.onOk()
		}
		this.dialogRef.close(this.data)
	}

}
