import { Component, OnInit, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material'
import { MessageDialogData } from 'src/app/models/common/dialog'
import { Store } from '@ngrx/store'
import * as TcGroupAction from 'src/app/store/param-settings/tc-group'
import * as AppStore from 'src/app/store'
import { Subscription } from 'rxjs'
import { TcGroupUpdateInput } from 'src/app/models/param-settings/tc-group/tc-group-update'
import { AbstractControl, FormControl, Validators } from '@angular/forms'

function tcGroupInputValidation() {
	return (control: AbstractControl): { [key: string]: boolean } | null => {
		let textInput: boolean

		if (control.value) {
			if (control.value.length === 9) {
				const rangeArray = control.value.split("-")
				textInput = control.value === null || control.value === '' || rangeArray[0].match(/^\d+$/) && rangeArray[1].match(/^\d+$/) && rangeArray[1] > rangeArray[0]
			}

			else if (control.value.length === 4) {
				textInput = control.value === null || control.value === '' || control.value.match(/^\d+$/)
			}
		}

		return textInput ? null : { containInvalidCharacters: true }
	}
}

@Component({
	selector: 'app-tc-group-dialog',
	templateUrl: './tc-group-dialog.component.html',
	styleUrls: ['./tc-group-dialog.component.scss']
})
export class TcGroupDialogComponent implements OnInit {
	parameters: TcGroupUpdateInput
	formState$: FormControl

	type = ''
	txtInput = ''
	title = ''
	content = ''
	pendingList: string[] = []
	subs: Subscription
	isLoading = false

	constructor(
		private dialogRef: MatDialogRef<TcGroupDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: MessageDialogData,
		private store: Store<AppStore.State>
	) {
		this.subs = this.store.select(TcGroupAction.getTcGroup).subscribe(x => {
			this.isLoading = x.isLoading
		})

		this.title = data.title
		this.content = data.content
		this.parameters = {
			txtInput: data.payload.txtInput
		}
		this.type = data.payload.type
		this.pendingList = (data.payload.pendingList || [])
		this.txtInput = data.payload.txtInput

		this.formState$ = new FormControl(this.parameters.txtInput, [tcGroupInputValidation(), Validators.maxLength(9)])
	}

	ngOnInit() {
	}

	cancel() {
		this.dialogRef.close()
	}

	submit(action) {
		switch (this.type) {
			case 'ADD_EDIT':
				this.data.payload = this.formState$.value
				break
			case 'APPEND_REPLACE':
				this.data.payload = { action, pendingList: this.pendingList }
				break
		}
		this.dialogRef.close(this.data)
	}

	isInvalidOrEqualToOldValue() {
		return this.formState$.invalid || (this.formState$.value === this.data.payload.txtInput)
	}
}
