import { ChangeDetectionStrategy, Component, ComponentFactoryResolver, Input, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core'
import { Store } from '@ngrx/store'
import { Subscription } from 'rxjs'
import { RuleType } from 'src/app/models/campaign-management/campaign/campaign'
import * as AppStore from 'src/app/store'
import * as CampaignAction from 'src/app/store/campaign-management/campaign/campaign.actions'
import * as CampaignSelectors from 'src/app/store/campaign-management/campaign/campaign.selectors'
import { Ruleset } from 'src/app/store/campaign-management/campaign/campaign.state'

@Component({
	selector: 'app-rule-dynamic',
	templateUrl: './rule-dynamic.component.html',
	styleUrls: ['./rule-dynamic.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class RuleDynamicComponent implements OnInit, OnDestroy {

	@Input() ruleset: Ruleset
	@ViewChild('dynamicContainer', { static: true, read: ViewContainerRef }) entry: ViewContainerRef

	title: string
	action: string
	subs: Subscription

	constructor(
		private componentFactoryResolver: ComponentFactoryResolver,
		private store: Store<AppStore.State>
	) {
		this.subs = this.store.select(CampaignSelectors.getCampaign).subscribe(({ campaignRulesetAction }) => {
			this.action = campaignRulesetAction
		})
	}

	get isView(): boolean { return this.action === 'VIEW' }

	ngOnInit() {
		this.title = this.ruleset && this.ruleset.rule && this.ruleset.rule.keyValue && this.ruleset.rule.keyValue.value

		this.loadComponent()
	}

	ngOnDestroy() {
		this.subs.unsubscribe()
	}

	loadComponent() {
		const keyValue = RuleType.find(x =>
			x.key === (this.ruleset && this.ruleset.rule && this.ruleset.rule.keyValue && this.ruleset.rule.keyValue.key)
		)

		this.entry.clear()
		if (keyValue) {
			const componentFactory = this.componentFactoryResolver.resolveComponentFactory(keyValue.value)
			const componentRef = this.entry.createComponent(componentFactory)
			const instance = componentRef.instance
			instance.ruleset = this.ruleset
		}
	}

	delete() {
		this.store.dispatch(CampaignAction.DeleteRuleset({ ruleset: this.ruleset }))
	}
}
