import { Action, createReducer, on } from '@ngrx/store'
import * as PointAdjustmentFileUploadAction from './point-adjustment-file-upload.actions'
import * as PointAdjustmentFileUploadState from './point-adjustment-file-upload.state'

export const reducer = createReducer(
	PointAdjustmentFileUploadState.initialState,
	on(PointAdjustmentFileUploadAction.InitialState, () => PointAdjustmentFileUploadState.initialState),
	on(PointAdjustmentFileUploadAction.RestError, state => ({
		...state,
		isLoading: false
	})),
	on(PointAdjustmentFileUploadAction.SetId, (state, { payload }) => ({
		...state,
		dataId: payload
	})),
	on(PointAdjustmentFileUploadAction.SetResourceCode, (state, { payload }) => ({
		...state,
		cachedResourceCode: payload
	})),
	on(PointAdjustmentFileUploadAction.FileList, (state, { payload }) => ({
		...state,
		isLoading: true
	})),
	on(PointAdjustmentFileUploadAction.FileListSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		adjustmentFileList: payload
	})),
	on(PointAdjustmentFileUploadAction.FileContent, (state) => ({
		...state,
		isLoading: true
	})),
	on(PointAdjustmentFileUploadAction.FileContentSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		adjustmentFileContent: payload
	})),
	on(PointAdjustmentFileUploadAction.ResourceListCreate, state => ({
		...state,
		isLoading: true
	})),
	on(PointAdjustmentFileUploadAction.ResourceListCreateSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		resourceList: (payload || [])
	})),
	on(PointAdjustmentFileUploadAction.CreatePointAdjustmentFileUpload, (state) => ({
		...state,
		isLoading: true,
	})),
	on(PointAdjustmentFileUploadAction.CreatePointAdjustmentFileUploadSuccess, (state) => ({
		...state,
		isLoading: false,
	})),
)

export function Reducer(state: PointAdjustmentFileUploadState.State = PointAdjustmentFileUploadState.initialState, action: Action) {

	return reducer(state, action)
}
