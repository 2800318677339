<mat-accordion>
  <mat-expansion-panel class="mat-box-container" [expanded]="!isView" [hideToggle]="!isView">
    <mat-expansion-panel-header>
      <div fxLayout="row" fxLayoutAlign="space-between center" fxFlex>
        <span class="content-title">{{'CAMPAIGN.CONDITION_RULES' | translate}}</span>
        <mat-icon *ngIf="!isView" class="mat-icon-pointer material-icons-outlined" (click)="delete()">close</mat-icon>
      </div>
    </mat-expansion-panel-header>
    <div fxLayout="column">
      <div *ngFor="let ruleCondition of (ruleConditions$ | async);trackBy: trackByIndex;let i=index" fxLayout="column">
        <div *ngIf="ruleCondition" fxFlexOffset="8px">
          <app-rule-condition [ruleCondition]="ruleCondition"></app-rule-condition>
        </div>
      </div>

      <div *ngIf="!isView" fxLayout="column" fxFlexOffset="8px">
        <div>
          <button mat-button color="primary" (click)="add()" fxLayout="row" fxLayoutAlign="center">
            <mat-icon class="material-icons-outlined">add</mat-icon>
            {{ 'CAMPAIGN.ACTION.ADD_CONDITION' | translate }}
          </button>
        </div>
      </div>
    </div>
  </mat-expansion-panel>
</mat-accordion>