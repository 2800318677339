import { createFormGroupState, FormGroupState, updateGroup, validate } from 'ngrx-forms'
import { maxLength, required } from 'ngrx-forms/validation'
import { MerchantGroupListResponse } from 'src/app/models/param-settings/mer-group/merchant-group-list'
import { FieldValidator } from 'src/app/models/util/field.validator'
import { MerchantGroupDetails } from 'src/app/models/param-settings/mer-group/merchant-group'

export interface State {
	isLoading: boolean
	code?: string
	merchantGroupDetailForm: FormGroupState<MerchantGroupDetailForm>
	merchantGroupDuplicateForm: FormGroupState<MerchantGroupDuplicateForm>
	merchantGroupListResponse: MerchantGroupListResponse
	allowDelete: boolean
	merchantGroupDetailFormView: MerchantGroupDetailForm
	isDuplicateLoading: boolean
	isDuplicateDialog: boolean
	merchantGroupDetails: MerchantGroupDetails
	merchantGroupTableListView: string[]
}

export interface MerchantGroupDetailForm {
	code: string
	name: string
	description: string
	merchantId: string
	createdBy: string
	createdDate: string
	modifiedBy: string
	modifiedDate: string
}

export const initialMerchantGroupDetailFormValue: MerchantGroupDetailForm = {
	code: '',
	name: '',
	description: '',
	merchantId: '',
	createdBy: '',
	createdDate: '',
	modifiedBy: '',
	modifiedDate: ''
}

export const initialMerchantGroupDetailForm = createFormGroupState('merchantGroupDetailForm', initialMerchantGroupDetailFormValue)

export const validateMerchantGroupDetailForm = updateGroup<MerchantGroupDetailForm>({
	code: validate([required, FieldValidator.alphaNumericWithoutSpaces()]),
	name: validate([required, maxLength(40), FieldValidator.checkValidCharacters()]),
	description: validate([maxLength(100), FieldValidator.checkValidCharacters()])
})

export const initialMerchantGroupDetails = {
	code: '',
	name: '',
	description: '',
	merchantId: []
}

export interface MerchantGroupDuplicateForm {
	code: string,
	name: string
}

export const initialMerchantGroupDuplicateFormValue: MerchantGroupDuplicateForm = {
	code: '',
	name: ''
}

export const initialMerchantGroupDuplicateForm = createFormGroupState('merchantGroupDuplicateForm', initialMerchantGroupDuplicateFormValue)

export const validateMerchantGroupDuplicateForm = updateGroup<MerchantGroupDuplicateForm>({
	code: validate([required, FieldValidator.alphaNumericWithoutSpaces()]),
	name: validate([required, maxLength(40), FieldValidator.checkValidCharacters()])
})

export const initialState: State = {
	isLoading: false,
	merchantGroupDetailForm: initialMerchantGroupDetailForm,
	merchantGroupDuplicateForm: initialMerchantGroupDuplicateForm,
	merchantGroupListResponse: {
		merchantGroups: []
	},
	allowDelete: true,
	merchantGroupDetailFormView: initialMerchantGroupDetailFormValue,
	isDuplicateDialog: false,
	isDuplicateLoading: false,
	merchantGroupDetails: initialMerchantGroupDetails,
	merchantGroupTableListView: []
}
