import { KeyValue } from '@angular/common'
import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'
import { Boxed, FormGroupState } from 'ngrx-forms'
import { Observable, ReplaySubject, Subscription } from 'rxjs'
import * as AppStore from 'src/app/store'
import * as CampaignSelectors from 'src/app/store/campaign-management/campaign/campaign.selectors'
import { MerchantForm, Ruleset } from 'src/app/store/campaign-management/campaign/campaign.state'
import { FormControl } from '@angular/forms'
import { FilterPredicate } from 'src/app/models/util/filter-predicate'

@Component({
	selector: 'app-merchant',
	templateUrl: './merchant.component.html',
	styleUrls: ['./merchant.component.scss']
})
export class MerchantComponent implements OnInit, OnDestroy {

	@Input() ruleset: Ruleset

	formState$: Observable<FormGroupState<MerchantForm>>
	action: string
	includeExcludeList: KeyValue<string, string>[] = []
	merchants: KeyValue<string, string>[] = []
	subs: Subscription

	public filteredMerchantGroups: ReplaySubject<KeyValue<string, string>[]> = new ReplaySubject<KeyValue<string, string>[]>(1)

	public dropDownCtrlMerchantGroups: FormControl = new FormControl()

	constructor(
		private store: Store<AppStore.State>
	) {
		this.subs = this.store.select(CampaignSelectors.getCampaign).subscribe(({ campaignRulesetAction, rulesetData }) => {
			this.action = campaignRulesetAction
			this.includeExcludeList = rulesetData.includeExcludeList
			this.merchants = rulesetData.merchantGroups
		})
	}

	get isView(): boolean { return this.action === 'VIEW' }

	ngOnInit() {
		this.formState$ = this.store.select(CampaignSelectors.getForm, this.ruleset.id)
		this.filteredMerchantGroups.next(this.merchants.slice())

		this.dropDownCtrlMerchantGroups.valueChanges
			.subscribe(value => {
				const maxChar = 255
				if (value.length > maxChar) {
					const newValue = value.slice(0, maxChar)
					this.dropDownCtrlMerchantGroups.reset('', { emitEvent: false })
					this.dropDownCtrlMerchantGroups.setValue(newValue)

				} else {
					this.searchMerchantGroups(value)
				}
			})

	}

	ngOnDestroy() {
		this.subs.unsubscribe()
	}

	getIncludeExcludeValue(value: string): string {
		const result = this.includeExcludeList.find(x => x.key === value)
		return result ? result.value : ''
	}

	getMerchantsValue(value: Boxed<string[]>): string {
		let resp = ''

		if (value && value.value) {
			value.value.forEach(x => {
				const result = this.merchants.find(y => x === y.key)
				if (result) {
					if (resp) { resp += ',' }
					resp += result.value
				}
			})
		}

		return resp
	}

	searchMerchantGroups(search: string) {
		if (!this.merchants) {
			return
		}
		if (!search) {
			this.filteredMerchantGroups.next(this.merchants.slice())
			return
		} else {
			search = search.toLowerCase()
		}
		// filter the records
		this.filteredMerchantGroups.next(
			this.merchants.filter(merchant => FilterPredicate.isIdenticalTo(search)([merchant.key.toLowerCase() + ' - ' + merchant.value.toLowerCase()]))
		)
	}

	clearSearchMerchantGroups() {
		this.filteredMerchantGroups.next(this.merchants.slice())
	}
}
