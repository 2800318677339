import { Component, Inject, OnDestroy } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material'
import { Store } from '@ngrx/store'
import { FormGroupState } from 'ngrx-forms'
import { Observable, Subscription } from 'rxjs'
import * as AppStore from 'src/app/store'
import { MerchantGroupDuplicateForm } from 'src/app/store/param-settings/merchant-group'
import * as MerchantGroupSelectors from 'src/app/store/param-settings/merchant-group/merchant-group.selectors'
import * as MerchantGroupAction from 'src/app/store/param-settings/merchant-group/merchant-group.actions'

@Component({
	selector: 'app-merchant-group-duplicate',
	templateUrl: './merchant-group-duplicate.component.html',
	styleUrls: ['./merchant-group-duplicate.component.scss']
})
export class MerchantGroupDuplicateComponent implements OnDestroy {

	isLoading = false
	formState$: Observable<FormGroupState<MerchantGroupDuplicateForm>>

	subs: Subscription

	constructor(
		private store: Store<AppStore.State>,
		private dialogRef: MatDialogRef<MerchantGroupDuplicateComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		this.subs = this.store.select(MerchantGroupSelectors.getMerchantGroup).subscribe(({ isDuplicateLoading, isDuplicateDialog }) => {
			this.isLoading = isDuplicateLoading

			if (isDuplicateDialog) {
				this.cancel()
			}
		})

		this.formState$ = this.store.select(({ merchantGroup }) => merchantGroup.merchantGroupDuplicateForm)
	}

	ngOnDestroy() {
		this.subs.unsubscribe()
	}

	cancel() {
		this.dialogRef.close()
	}

	submit() {
		this.store.dispatch(MerchantGroupAction.Duplicate())
	}
}
