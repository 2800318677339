import { DatePipe, KeyValue } from '@angular/common'
import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material'
import { MomentDateAdapter } from '@angular/material-moment-adapter'
import { Store } from '@ngrx/store'
import { FormGroupState, NgrxValueConverters } from 'ngrx-forms'
import { Observable, Subscription } from 'rxjs'
import { CUSTOM_DATE_FORMATS } from 'src/app/models/util/date.adapter'
import * as AppStore from 'src/app/store'
import * as CampaignSelectors from 'src/app/store/campaign-management/campaign/campaign.selectors'
import { MemberSinceForm, Ruleset } from 'src/app/store/campaign-management/campaign/campaign.state'

@Component({
	selector: 'app-member-since',
	templateUrl: './member-since.component.html',
	styleUrls: ['./member-since.component.scss'],
	providers: [
		DatePipe,
		{
			provide: DateAdapter, useClass: MomentDateAdapter
		},
		{
			provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS
		}
	]
})
export class MemberSinceComponent implements OnInit, OnDestroy {

	@Input() ruleset: Ruleset

	formState$: Observable<FormGroupState<MemberSinceForm>>
	action: string
	comparisonList: KeyValue<string, string>[] = []
	subs: Subscription

	dateValueConverter = NgrxValueConverters.dateToISOString

	constructor(
		private store: Store<AppStore.State>
	) {
		this.subs = this.store.select(CampaignSelectors.getCampaign).subscribe(({ campaignRulesetAction, rulesetData }) => {
			this.action = campaignRulesetAction
			this.comparisonList = rulesetData.comparisonList
		})
	}

	get isView(): boolean { return this.action === 'VIEW' }

	ngOnInit() {
		this.formState$ = this.store.select(CampaignSelectors.getForm, this.ruleset.id)
	}

	ngOnDestroy() {
		this.subs.unsubscribe()
	}

	getComparisonValue(value: string): string {
		const result = this.comparisonList.find(x => x.key === value)
		return result ? result.value : ''
	}
}
