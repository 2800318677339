<span *ngIf="title" mat-dialog-title>{{ title | translate }}</span>

<mat-dialog-content>
  <form novalidate [ngrxFormState]="formState$ | async">
    <div *ngIf="!show" class="dummy-block">&nbsp;</div>
    <mat-form-field *ngIf="show">
      <mat-label>{{ 'COMMON.SELECT' | translate }} {{ content | translate }}</mat-label>
      <mat-select [ngrxFormControlState]="(formState$ | async).controls.value" required #singleSelect
        (closed)="clearSearchAndReRender()">
        <ngx-mat-select-search [formControl]="dropDownCtrl" [placeholderLabel]="'COMMON.SEARCH'| translate"
          [noEntriesFoundLabel]="'COMMON.NOT_MATCHING_FOUND' | translate">
          <mat-icon ngxMatSelectSearchClear (click)="clearSearch()">close</mat-icon>
        </ngx-mat-select-search>
        <mat-option *ngFor="let i of filteredRecords | async" [value]="i.keyValue.key">
          <div [title]="i.keyValue.value" >{{i.keyValue.value}}</div>
        </mat-option>
      </mat-select>
      <mat-error *ngIf="(formState$ | async).errors._value">
        <app-error-validation [errors]="(formState$ | async).errors._value"></app-error-validation>
      </mat-error>
    </mat-form-field>
  </form>
</mat-dialog-content>

<div fxLayout="row" fxLayoutAlign="end">
  <button class="mat-raised-button" (click)="cancel()">{{ 'ACTION.CANCEL' | translate }}</button>
  <button class="mat-raised-button mat-primary" [disabled]="(formState$ | async).controls.value.isInvalid" (click)="add()"
    fxFlexOffset="8px">{{ 'ACTION.ADD' | translate }}</button>
</div>
