import { createAction, props } from '@ngrx/store'
import { Draft, Published, Unpublished } from 'src/app/models/campaign-management/conditional-reward/conditional-reward'
import { ConditionalRewardCampaignResponse } from 'src/app/models/campaign-management/conditional-reward/conditional-reward-campaign'
import { ConditionalRewardDataResponse } from 'src/app/models/campaign-management/conditional-reward/conditional-reward-data'
import { ConditionalRewardViewResponse } from 'src/app/models/campaign-management/conditional-reward/conditional-reward-view'
import { POLICY } from 'src/app/models/common/constant'

export const InitialState = createAction('[ConditionalReward] InitialState')
export const SetId = createAction('[ConditionalReward] SetId', props<{ id: number }>())
export const SetTierId = createAction('[ConditionalReward] SetTierId', props<{ tierId: string }>())
export const SetTierAction = createAction('[ConditionalReward] SetTierAction', props<{ tierAction: string }>())
export const SetCheckerMakerId = createAction('[ConditionalReward] SetCheckerMakerId', props<{ checkerMakerId: number }>())

export const GoList = createAction('[ConditionalReward] GoList')
export const GoCreate = createAction('[ConditionalReward] GoCreate')
export const GoUpdate = createAction('[ConditionalReward] GoUpdate')
export const GoPublishedView = createAction('[ConditionalReward] GoPublishedView')
export const GoUnpublishedView = createAction('[ConditionalReward] GoUnpublishedView')
export const GoPublishedUpdate = createAction('[ConditionalReward] GoPublishedUpdate', props<{ action: string }>())

export const GoBackDialog = createAction('[ConditionalReward] GoBackDialog', props<{ action: string }>())
export const CreateDialog = createAction('[ConditionalReward] CreateDialog')
export const UpdateDialog = createAction('[ConditionalReward] UpdateDialog')
export const DeleteDialog = createAction('[ConditionalReward] DeleteDialog')
export const PublishDialog = createAction('[ConditionalReward] PublishDialog')
export const TerminateDialog = createAction('[ConditionalReward] TerminateDialog')
export const AddCampaignDialog = createAction('[ConditionalReward] AddCampaignDialog')
export const DeleteTierDialog = createAction('[ConditionalReward] DeleteTierDialog')
export const ResetTierDialog = createAction('[ConditionalReward] ResetTierDialog')
export const CloseDialog = createAction('[ConditionalReward] CloseDialog')
export const RepublishDialog = createAction('[ConditionalReward] RepublishDialog')

export const RestError = createAction('[ConditionalReward] RestError', props<{ message: string, manualThrow: boolean }>())

export const List = createAction('[ConditionalReward] List')
export const ListSuccess = createAction('[ConditionalReward] ListSuccess', props<{ payload: Published[] }>())

export const DraftList = createAction('[ConditionalReward] DraftList')
export const DraftListSuccess = createAction('[ConditionalReward] DraftListSuccess', props<{ payload: Draft[] }>())

export const UnpublishedList = createAction('[ConditionalReward] UnpublishedList')
export const UnpublishedListSuccess = createAction('[ConditionalReward] UnpublishedListSuccess', props<{ payload: Unpublished[] }>())

export const View = createAction('[ConditionalReward] View', props<{ payload: POLICY }>())
export const ViewSuccess = createAction('[ConditionalReward] ViewSuccess', props<{ payload: ConditionalRewardViewResponse }>())

export const Create = createAction('[ConditionalReward] Create')
export const CreateSuccess = createAction('[ConditionalReward] CreateSuccess', props<{ payload: string }>())

export const Update = createAction('[ConditionalReward] Update')
export const UpdateSuccess = createAction('[ConditionalReward] UpdateSuccess', props<{ payload: string }>())

export const Delete = createAction('[ConditionalReward] Delete')
export const DeleteSuccess = createAction('[ConditionalReward] DeleteSuccess')

export const Publish = createAction('[ConditionalReward] Publish')
export const PublishSuccess = createAction('[ConditionalReward] PublishSuccess')

export const Republish = createAction('[ConditionalReward] Republish')
export const RepublishSuccess = createAction('[ConditionalReward] RepublishSuccess')

export const Terminate = createAction('[ConditionalReward] Terminate')
export const TerminateSuccess = createAction('[ConditionalReward] TerminateSuccess')

export const GetConditionalRewardData = createAction('[ConditionalReward] GetConditionalRewardData', props<{ payload: POLICY }>())
export const GetConditionalRewardDataSuccess = createAction('[ConditionalReward] GetConditionalRewardDataSuccess', props<{ payload: ConditionalRewardDataResponse }>())

export const GetCampaignList = createAction('[ConditionalReward] GetCampaignList')
export const GetCampaignListSuccess = createAction('[ConditionalReward] GetCampaignListSuccess', props<{ payload: ConditionalRewardCampaignResponse[] }>())

export const OnChangeAccumulationCycleType = createAction('[ConditionalReward] OnChangeAccumulationCycleType')
export const OnChangeAccumulationCycleMonth = createAction('[ConditionalReward] OnChangeAccumulationCycleMonth')
export const OnChangeYearlyCycle = createAction('[ConditionalReward] OnChangeYearlyCycle')
export const OnChangeChannel = createAction('[ConditionalReward] OnChangeChannel')
export const OnChangeBillingCycleType = createAction('[ConditionalReward] OnChangeBillingCycleType')
export const OnChangeRewardCreditMethod = createAction('[ConditionalReward] OnChangeRewardCreditMethod')

export const AddTier = createAction('[ConditionalReward] AddTier')
export const AddCampaign = createAction('[ConditionalReward] AddCampaign', props<{ campaignId: number }>())

export const DeleteTier = createAction('[ConditionalReward] DeleteTier')
export const DeleteCampaign = createAction('[ConditionalReward] DeleteCampaign', props<{ campaignId: number }>())
