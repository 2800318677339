import { CommonModule } from '@angular/common'
import { HttpClient, HttpClientModule } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { EffectsModule } from '@ngrx/effects'
import { StoreModule } from '@ngrx/store'
import { TranslateLoader, TranslateModule } from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { NgrxFormsModule } from 'ngrx-forms'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { SharedModule } from './shared/shared.module'
import { effects, metaReducers, reducers } from './store'
import { GoogleChartsModule } from 'angular-google-charts'
import { StoreDevtoolsModule } from '@ngrx/store-devtools'
import { environment } from '../environments/environment'

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient): TranslateHttpLoader {
	return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json')
}

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule.withServerTransition({ appId: 'serverApp' }),
		BrowserAnimationsModule,
		AppRoutingModule,
		CommonModule,
		HttpClientModule,
		SharedModule,
		FlexLayoutModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient]
			}
		}),
		NgrxFormsModule,
		StoreModule.forRoot(reducers,
			{
				metaReducers,
				runtimeChecks: {
					strictActionImmutability: true,
					strictActionSerializability: true,
					strictStateImmutability: true,
					strictStateSerializability: true
				}
			}
		),
		StoreDevtoolsModule.instrument({
			logOnly: environment.production, // Restrict extension to log-only mode
		}),
		EffectsModule.forRoot(effects),
		GoogleChartsModule
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
