import { Component, OnInit,  HostBinding } from '@angular/core'
import { Store } from '@ngrx/store'
import { TypedAction } from '@ngrx/store/src/models'
import { delay } from 'rxjs/operators'
import { ActionButton } from 'src/app/models/common/action-bar'
import * as AppStore from 'src/app/store'
import * as ActionBarAction from 'src/app/store/common/action-bar'
import { animate, state, style, transition, trigger } from '@angular/animations'

@Component({
	selector: 'app-action-bar',
	templateUrl: './action-bar.component.html',
	styleUrls: ['./action-bar.component.scss'],
	animations: [
		trigger('indicatorRotate', [
			state('collapsed', style({ transform: 'rotate(360deg)' })),
			state('expanded', style({ transform: 'rotate(180deg)' })),
			transition('expanded <=> collapsed',
				animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
			),
		])
	]
})
export class ActionBarComponent implements OnInit {

	icon: string
	title: string
	actionButtons: ActionButton[]
	dashboardActionBar: ActionButton[]
	expanded: boolean
	@HostBinding('attr.aria-expanded') ariaExpanded = this.expanded

	constructor(
		private store: Store<AppStore.State>
	) { }

	ngOnInit() {
		this.store.select(ActionBarAction.getActionBar).subscribe(x => {
			this.icon = x.icon
			this.title = x.title
			this.actionButtons = x.actionButtons
			this.dashboardActionBar = x.dashboardActionBar
		})
	}

	doAction(action: TypedAction<any>) {
		this.store.dispatch(action)
	}

	click() {
		this.expanded = !this.expanded
	}
}
