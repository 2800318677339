import { createFeatureSelector, createSelector } from '@ngrx/store'
import * as OnTheSpotRedemption from './on-the-spot-redemption.state'

const onTheSpotRedemption = createFeatureSelector<OnTheSpotRedemption.State>('onTheSpotRedemption')

export const getOnTheSpotRedemption = createSelector(onTheSpotRedemption, x => x)

export const getOTSRewardPools = createSelector(onTheSpotRedemption, ({ onTheSpotRedemptionDetailForm }): OnTheSpotRedemption.OTSRewardPool[] => {
	let result = []
	const list = onTheSpotRedemptionDetailForm.controls.otsRewardPools.value

	if (list && list.allIds) {
		result = list.allIds.map(id => list.byId[id])
	}

	return result
})

export const getOTSRewardPool = createSelector(onTheSpotRedemption, ({ onTheSpotRedemptionDetailForm }, id: string) => {
	const otsRewardPools = onTheSpotRedemptionDetailForm.controls.otsRewardPools.value

	const form: OnTheSpotRedemption.OTSRewardPools = {
		byId: {
			...otsRewardPools.byId
		},
		allIds: {
			...otsRewardPools.allIds
		}
	}

	return form && form.byId[id] && form.byId[id].form
})
