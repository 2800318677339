import { Component, Inject, OnDestroy, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material'
import { Store } from '@ngrx/store'
import { FormGroupState } from 'ngrx-forms'
import { Observable, Subscription } from 'rxjs'
import * as AppStore from 'src/app/store'
import { GroupDetailForm } from 'src/app/store/access-management/group'
import * as GroupAction from 'src/app/store/access-management/group/group.actions'
import * as GroupSelectors from 'src/app/store/access-management/group/group.selectors'

@Component({
	selector: 'app-group-duplicate',
	templateUrl: './group-duplicate.component.html',
	styleUrls: ['./group-duplicate.component.scss']
})
export class GroupDuplicateComponent implements OnInit, OnDestroy {

	isLoading = false
	formState$: Observable<FormGroupState<GroupDetailForm>>

	subs: Subscription

	constructor(
		private store: Store<AppStore.State>,
		private dialogRef: MatDialogRef<GroupDuplicateComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		this.subs = this.store.select(GroupSelectors.getGroup).subscribe(({ isDuplicateLoading, isDuplicateDialog }) => {
			this.isLoading = isDuplicateLoading

			if (isDuplicateDialog) {
				this.cancel()
			}
		})

		this.formState$ = this.store.select(({ group }) => group.groupDetailForm)
	}

	ngOnInit() { }

	ngOnDestroy() {
		this.subs.unsubscribe()
	}

	cancel() {
		this.dialogRef.close()
	}

	submit() {
		this.store.dispatch(GroupAction.Duplicate())
	}
}
