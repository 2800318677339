import { MatDialogConfig } from '@angular/material'

export interface MessageDialogData {
	title?: string
	content: string
	contentParam?: string
	cancelBtn?: string
	submitBtn?: string
	payload?: any
	table?: any
	tableColumnName?: any
	module?: any
}

export interface SelectDialogData {
	title?: string
	content?: string
	payload?: any
}

export function getDialogConfig(data: SelectDialogData | MessageDialogData) {
	const dialogConfig = new MatDialogConfig()
	dialogConfig.autoFocus = true
	dialogConfig.data = data
	return dialogConfig
}

export function getGlobalDialogConfig(data: SelectDialogData | MessageDialogData) {
	const dialogConfig = new MatDialogConfig()
	dialogConfig.autoFocus = true
	dialogConfig.disableClose = true
	dialogConfig.data = data
	return dialogConfig
}
