<form novalidate [ngrxFormState]="formState$ | async" fxLayout="column">
  <div *ngIf="isView" fxLayout="column">
    <mat-form-field fxFlex>
      <input matInput value="{{getComparisonDateValue((formState$ | async).controls.comparisonDate.value)}}" type="text"
        maxlength="255" readonly>
    </mat-form-field>

    <div fxLayout="row" fxLayoutGap="5px">
      <mat-form-field fxFlex="30">
        <input matInput value="{{getComparisonValue((formState$ | async).controls.equation.value)}}" type="text"
          maxlength="255" readonly>
      </mat-form-field>

      <mat-form-field fxFlex="70">
        <input matInput value="{{(formState$ | async).controls.value.value}}" type="text" maxlength="255" readonly>
      </mat-form-field>
    </div>
  </div>

  <div *ngIf="!isView" fxLayout="column">
    <mat-form-field fxFlex>
      <mat-select [ngrxFormControlState]="(formState$ | async).controls.comparisonDate">
        <mat-option *ngFor="let i of comparisonDateList" [value]="i.key">
          {{i.value}}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="(formState$ | async).errors._comparisonDate">
        <app-error-validation [errors]="(formState$ | async).errors._comparisonDate">
        </app-error-validation>
      </mat-error>
    </mat-form-field>

    <div fxLayout="row" fxLayoutGap="5px">
      <mat-form-field fxFlex="30">
        <mat-select [ngrxFormControlState]="(formState$ | async).controls.equation">
          <mat-option *ngFor="let i of comparisonList" [value]="i.key">
            {{i.value}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field fxFlex="70">
        <input matInput [ngrxFormControlState]="(formState$ | async)?.controls.value"
          onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57"
          type="text" maxlength="255" required>
        <mat-icon class="material-icons-outlined" matSuffix
          matTooltip="{{ 'CAMPAIGN.INFO.COMPARISON_DATE' | translate }}">info
        </mat-icon>
        <mat-error *ngIf="(formState$ | async).errors._value">
          <app-error-validation [errors]="(formState$ | async).errors._value" [maxlength]="255">
          </app-error-validation>
        </mat-error>
      </mat-form-field>
    </div>
  </div>
</form>