<app-spinner [spinnerType]="'COMPONENT'" [isLoading]="isLoading"></app-spinner>
<div fxLayout="column" fxLayout.lt-md="column">
  <div fxLayout="row" fxLayout.lt-md="column">
    <span class="mat-h3">{{ 'CUSTOMER_MANAGEMENT.CASHBACK_TRANSACTION_DETAILS' | translate }}</span>
  </div>
	<div fxLayout="row" fxLayout.lt-md="column">
		<span class="mat-h3"
			  style="display: flex;
			  align-items: center;"
		>{{ 'CUSTOMER_MANAGEMENT.SPEND_AMOUNT' | translate }} : {{ totalSpendAmount }}
			<mat-icon class="material-icons-outlined" matSuffix
					  [matTooltip]="cardSpendAmount"
					  matTooltipClass="custom-tooltip">info
			</mat-icon>
		</span>
	</div>
  <div class="table-container-without-hover" fxFlex="100" style="width: 100%;">
    <app-paginated-result-table [headerColumns]="headerColumns" mat-table [dataSource]="dataSource"
      [displayedColumns]="displayedColumns">
    </app-paginated-result-table>
    <div class="table-no-data" *ngIf="dataSource.data.length === 0"> {{'ERROR.TABLE_NO_RECORD' | translate}} </div>
    <mat-paginator class="bdr-radius-btm-lr" #matPaginatorPointTransaction [pageSizeOptions]="[5, 10, 25, 100]">
    </mat-paginator>
  </div>
</div>
