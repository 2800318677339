import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material'
import { Router } from '@angular/router'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Action, Store } from '@ngrx/store'
import { Observable, of, from } from 'rxjs'
import { catchError, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators'
import { MessageDialogComponent } from 'src/app/components/common/dialog/message-dialog/message-dialog.component'
import { GroupListResponse } from 'src/app/models/access-management/group/group-list'
import { GroupViewResponse, ErrorMessage } from 'src/app/models/access-management/group/group-view'
import { PolicyListResponse } from 'src/app/models/access-management/policy/policy-list'
import { getDialogConfig } from 'src/app/models/common/dialog'
import { Response } from 'src/app/models/common/http'
import { AccessManagementService } from 'src/app/services/access-management/access-management.service'
import * as AppStore from 'src/app/store/'
import * as CommonAction from '../../common/common/common.actions'
import * as GroupAction from './group.actions'
import { MultiSelectDialogComponent } from 'src/app/components/common/dialog/multi-select-dialog/multi-select-dialog.component'
import { unbox } from 'ngrx-forms'
import { GroupDuplicateComponent } from 'src/app/components/access-management/group/group-duplicate/group-duplicate.component'
import { GlobalMessageDialogComponent } from 'src/app/components/common/dialog/global-message-dialog/global-message-dialog.component'

@Injectable()
export class Effects {

	constructor(
		private action$: Actions,
		private router: Router,
		private dialog: MatDialog,
		private accessManagementService: AccessManagementService,
		private store: Store<AppStore.State>
	) { }

	InitialState$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(GroupAction.InitialState),
			tap(() => {
				this.router.navigate(['access-management/group'])
			})
		), { dispatch: false }
	)

	GoList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(GroupAction.GoList),
			tap(() => {
				this.router.navigate(['access-management/group'])
			})
		), { dispatch: false }
	)

	GoView$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(GroupAction.GoView),
			tap(() => {
				this.router.navigate(['access-management/group/view'])
			})
		), { dispatch: false }
	)

	GoCreate$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(GroupAction.GoCreate),
			tap(() => {
				this.router.navigate(['access-management/group/create'])
			})
		), { dispatch: false }
	)

	GoEdit$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(GroupAction.GoEdit),
			tap(() => {
				this.router.navigate(['access-management/group/edit'])
			})
		), { dispatch: false }
	)

	GoBackDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(GroupAction.GoBackDialog),
			mergeMap(({ action }) => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.BACK', payload: action }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					if (result.payload === 'LIST') {
						return GroupAction.GoList()
					}
					if (result.payload === 'VIEW') {
						return GroupAction.GoView()
					}
				} else {
					return GroupAction.closeDialog()
				}
			})
		)
	)

	RestError$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(GroupAction.RestError),
			map(({ message, manualThrow }) => {
				return CommonAction.RestError({ message, manualThrow })
			})
		)
	)

	List$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(GroupAction.List),
			withLatestFrom(this.store.select(state => state.group)),
			mergeMap(([x, group]) => {
				const form = group.groupListForm.controls
				const fromDate: Date = form.fromDate.value && new Date(form.fromDate.value)
				const toDate: Date = form.toDate.value && new Date(form.toDate.value)

				return this.accessManagementService.getGroupList().pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: GroupListResponse = response.payload

					if (success) {
						return GroupAction.ListSuccess({ payload })
					} else { return GroupAction.RestError(response as any) }
				}), catchError(this.catcher)
				)
			})
		)
	)

	View$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(GroupAction.View),
			withLatestFrom(this.store.select(state => state.group)),
			mergeMap(([, group]) => this.accessManagementService.viewGroup({ groupId: group.groupId }).pipe(
				map((response: Response) => {
					const success: boolean = response.success
					const payload: GroupViewResponse = response.payload

					if (success) {
						return GroupAction.ViewSuccess({ payload })
					} else { return GroupAction.RestError(response as any) }

				}), catchError(this.catcher)
			))
		)
	)

	CreateDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(GroupAction.CreateDialog),
			mergeMap(() => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.CREATE_GROUP' }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return GroupAction.Create()
				} else {
					return GroupAction.closeDialog()
				}
			})
		)
	)

	Create$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(GroupAction.Create),
			withLatestFrom(this.store.select(state => state.group)),
			mergeMap(([, group]) => {
				const form = group.groupDetailForm.controls
				return this.accessManagementService.createGroup({
					name: form.name.value,
					description: form.description.value
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: string = response.payload
					if (success) {
						return GroupAction.CreateSuccess({ payload })
					} else { return GroupAction.RestError(response as any) }

				})
					, catchError(this.catcher)
				)
			})
		)
	)

	CreateSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(GroupAction.CreateSuccess),
			mergeMap(({payload}) => {
				return [GroupAction.GoList(), CommonAction.RestError({ message: payload })]
			})
		)
	)

	Edit$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(GroupAction.Edit),
			withLatestFrom(this.store.select(state => state.group)),
			mergeMap(([, group]) => {
				const form = group.groupDetailForm.controls
				return this.accessManagementService.editGroup({
					groupId: group.groupId,
					name: form.name.value,
					description: form.description.value
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: string = response.payload
					if (success) {
						return GroupAction.EditSuccess({ payload })
					} else { return GroupAction.RestError(response as any) }

				}), catchError(this.catcher))
			})
		)
	)

	EditSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(GroupAction.EditSuccess),
			mergeMap(({payload}) => {
				return [GroupAction.GoList(), CommonAction.RestError({ message: payload })]
			})
		)
	)

	DeleteDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(GroupAction.DeleteDialog),
			mergeMap(() => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.DELETE_GROUP' }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return GroupAction.Delete()
				} else {
					return GroupAction.closeDialog()
				}
			})
		)
	)

	Delete$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(GroupAction.Delete),
			withLatestFrom(this.store.select(state => state.group)),
			mergeMap(([, group]) => {
				return this.accessManagementService.deleteGroup({
					groupId: group.groupId
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					if (success) {
						return GroupAction.DeleteSuccess()
					} else { return GroupAction.RestError(response as any) }

				})
					, catchError(this.catcher)
				)
			})
		)
	)

	DeleteSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(GroupAction.DeleteSuccess),
			map(() => {
				return GroupAction.GoList()
			})
		)
	)

	AssignPolicyList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(GroupAction.AssignPolicyList),
			mergeMap(() => {
				return this.accessManagementService.getPolicyList()
					.pipe(map((response: Response) => {
						const success: boolean = response.success
						const payload: PolicyListResponse = response.payload
						if (success) {
							this.store.dispatch(GroupAction.AssignPolicyListSuccess({ payload }))
							return GroupAction.AssignPolicyDialog()
						} else { return GroupAction.RestError(response as any) }

					}), catchError(this.catcher))
			})
		)
	)

	AssignPolicyDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(GroupAction.AssignPolicyDialog),
			withLatestFrom(this.store.select(state => state.group)),
			mergeMap(([, group]) => {
				const dialogRef = this.dialog.open(MultiSelectDialogComponent, getDialogConfig({
					title: 'GROUP.ASSIGN_FUNCTION',
					content: 'GROUP.FUNCTION',
					payload: group.assignPolicyDataList.filter(x => {
						const data = group.groupViewResponse.policies.find(
							({ policyId }) => policyId === x.policyId
						)
						if (!data) { return x }
					})
				}))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return GroupAction.AssignMultiPolicyToGroup({ policyIdList: unbox(result) })
				} else {
					return GroupAction.closeDialog()
				}
			}),
		)
	)

	AssignMultiPolicyToGroup$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(GroupAction.AssignMultiPolicyToGroup),
			mergeMap((param) => {
				const service = (id) => GroupAction.AssignPolicyToGroup({ policyId: Number(id) })
				const box = param.policyIdList.map(o => service(o))
				return [ ...box]
			})
		)
	)

	AssignPolicyToGroup$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(GroupAction.AssignPolicyToGroup),
			withLatestFrom(this.store.select(state => state.group)),
			mergeMap(([param, group]) => {
				return this.accessManagementService.assignGroupPolicy({ groupId: group.groupId, policyId: param.policyId }).pipe(
					map((response: Response) => {
						const success: boolean = response.success
						const payload: string = response.payload

						if (success) {
							return GroupAction.SuccessPolicyToGroup({ policyId: Number(payload) })
						} else {
							const errorMessage: ErrorMessage = response.error
							return GroupAction.FailedPolicyToGroup({ policyId: Number(payload), message: errorMessage.message})
						}
					})
				)
			})
		)
	)

	OpenPolicyResult$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(GroupAction.OpenConfirmationDialog),
			withLatestFrom(this.store.select(state => state)),
			mergeMap(([, state]) => {
				const dialogRef = this.dialog.open(GlobalMessageDialogComponent, getDialogConfig({
					content: 'GROUP.ASSIGN_FUNCTION_CONFIRMATION_LIST',
					table: state.group.policyListResult != null ? state.group.policyListResult : null,
					submitBtn: 'ACTION.OK',
					tableColumnName: 'GROUP.ASSIGNED_FUNCTION',
					module: 'GROUP'
				}))
				return dialogRef.afterClosed()
			}),
			map(() => {
				return GroupAction.View()
			})
		)
	)

	AssignPolicyToGroupSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(GroupAction.AssignPolicyToGroupSuccess),
			mergeMap(() => {
				return [GroupAction.View()]
			})
		)
	)

	RemovePolicyFromGroup$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(GroupAction.RemovePolicyFromGroup),
			withLatestFrom(this.store.select(state => state.group)),
			mergeMap(([param]) => {
				return this.accessManagementService.removeGroupPolicy({ groupId: param.groupId, policyId: param.policyId }).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: string = response.payload
					if (success) {
						return GroupAction.RemovePolicyFromGroupSuccess({ message: payload })
					} else { return GroupAction.RestError(response as any) }

				}), catchError(this.catcher))
			})
		)
	)

	RemovePolicyFromGroupSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(GroupAction.RemovePolicyFromGroupSuccess),
			mergeMap(({ message }) => {
				return [CommonAction.RestError({ message }), GroupAction.View()]
			})
		)
	)

	DuplicateDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(GroupAction.DuplicateDialog),
			mergeMap(() => {
				const dialogRef = this.dialog.open(GroupDuplicateComponent, getDialogConfig({}))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return GroupAction.Duplicate()
				} else {
					return GroupAction.CloseDialog()
				}
			})
		)
	)

	Duplicate$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(GroupAction.Duplicate),
			withLatestFrom(this.store.select(state => state.group)),
			mergeMap(([, group]) => {
				const groupDetailForm = group.groupDetailForm.value

				return this.accessManagementService.duplicateGroup(group.groupId, {
					name: groupDetailForm.name,
					description: groupDetailForm.description
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload = response.payload
					if (success) {
						return GroupAction.DuplicateSuccess({ payload })
					} else {
						return GroupAction.RestError(response as any)
					}
				}), catchError(this.catcher))
			})
		)
	)

	DuplicateSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(GroupAction.DuplicateSuccess),
			mergeMap(({ payload }) => {
				return [
					GroupAction.List(),
					CommonAction.RestError({ message: payload })
				]
			})
		)
	)

	catcher = (error) => {
		return (of(GroupAction.RestError({ message: error.message, manualThrow: error.manualThrow })))
	}
}
