import { Component, Input, OnInit } from '@angular/core'
import { CampaignTier } from 'src/app/models/campaign-management/campaign/campaign'

@Component({
	selector: 'app-campaign-worklist-reward-tiers',
	templateUrl: './campaign-worklist-reward-tiers.component.html',
	styleUrls: ['./campaign-worklist-reward-tiers.component.scss']
})
export class CampaignWorklistRewardTiersComponent implements OnInit {

	@Input() campaignTier: CampaignTier
	@Input() rewardMethod: string
	@Input() type: string
	@Input() index: string

	constructor() { }

	ngOnInit() {
	}

}
