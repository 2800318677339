import { createFormGroupState, FormGroupState, updateGroup } from 'ngrx-forms'
import { RewardPoolOverviewViewResponse, RewardPoolGroupOverviewViewResponse, CampaignOverviewViewResponse, RangeOption, CampaignsOverviewListResponse } from 'src/app/models/dashboard/dashboard-overview'
import { Option } from 'src/app/models/option/option'

export interface State {
	isLoading: boolean
	rewardPoolIsLoading: boolean
	rewardPoolGroupIsLoading: boolean
	campaignIsLoading: boolean
	campaignListIsLoading: boolean
	totalPoints?: number
	resource: string
	rewardPoolOverviewViewResponse: RewardPoolOverviewViewResponse[]
	rewardPoolGroupOverviewViewResponse: RewardPoolGroupOverviewViewResponse[]
	campaignOverviewViewResponse: CampaignOverviewViewResponse
	campaignsOverviewListResponse: CampaignsOverviewListResponse[]
	dashboardRequestForm: FormGroupState<DashboardViewRequestForm>
	monthList: Option[]
	rangeList: RangeOption[]
	campaignRangeList: RangeOption[]
	campaignList: Option[]
}

export interface DashboardViewRequestForm {
	customRewardPoolStartDate: string
	rewardPoolStartDate: string
	rewardPoolEndDate: string
	customStartDate: string
	startDate: string
	endDate: string
	customCampaignStartDate: string
	campaignStartDate: string
	campaignEndDate: string
	campaignId: string
}

export const initialDashboardRequestFormValue: DashboardViewRequestForm = {
	customRewardPoolStartDate: '',
	rewardPoolStartDate: '',
	rewardPoolEndDate: '',
	customStartDate: '',
	startDate: '',
	endDate: '',
	customCampaignStartDate: '',
	campaignStartDate: '',
	campaignEndDate: '',
	campaignId: ''
}

export const initialDashboardViewRequestForm = createFormGroupState('dashboardRequestForm', initialDashboardRequestFormValue)

export const validateDashboardViewRequestForm = (state: State) => updateGroup<DashboardViewRequestForm>({
})

export const initialState: State = {
	isLoading: false,
	rewardPoolIsLoading: false,
	rewardPoolGroupIsLoading: false,
	campaignIsLoading: false,
	campaignListIsLoading: false,
	dashboardRequestForm: initialDashboardViewRequestForm,
	rewardPoolGroupOverviewViewResponse: [],
	rewardPoolOverviewViewResponse: [],
	campaignOverviewViewResponse: {
		campaignId: 0,
		campaignCode: '',
		campaignName: '',
		pointsDetails: [],
		totalPoints: 0
	},
	campaignsOverviewListResponse: [],
	rangeList: [],
	campaignRangeList: [],
	monthList: [],
	campaignList: [],
	resource: ''
}
