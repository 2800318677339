import { createFeatureSelector, createSelector } from '@ngrx/store'
import * as Campaign from './campaign.state'
import { CampaignRulesets, CampaignTier, CampaignTiers, RuleCondition, Ruleset, Rulesets } from './campaign.state'

const campaign = createFeatureSelector<Campaign.State>('campaign')

export const getCampaign = createSelector(campaign, x => x)

export const getRulesets = createSelector(campaign, ({ rulesetDetailForm }): Ruleset[] => {
	let list = []
	const rulesets = rulesetDetailForm.controls.rulesets.value

	if (rulesets && rulesets.allIds) {
		list = rulesets.allIds.map(id => rulesets.byId[id])
	}

	return list
})

export const getGroups = createSelector(campaign, ({ rulesetDetailForm }, id: string): RuleCondition[] => {
	let list = []
	const rulesets = rulesetDetailForm.controls.rulesets.value

	if (rulesets && rulesets.byId && rulesets.byId[id] && rulesets.byId[id].ruleConditionIds) {
		list = [...rulesets.byId[id].ruleConditionIds.map(key => rulesetDetailForm.controls.ruleConditions.value.byId[key])]
	}

	return list
})

export const getConditions = createSelector(campaign, ({ rulesetDetailForm }, id: string): Ruleset[] => {
	let list = []
	const ruleConditions = rulesetDetailForm.controls.ruleConditions.value

	if (ruleConditions && ruleConditions.byId && ruleConditions.byId[id] && ruleConditions.byId[id].rulesetChildIds) {
		list = [...ruleConditions.byId[id].rulesetChildIds.map(key => rulesetDetailForm.controls.rulesetChilds.value.byId[key])]
	}

	return list
})


export const getForm = createSelector(campaign, ({ rulesetDetailForm }, id: string) => {
	const rulesets = rulesetDetailForm.controls.rulesets.value
	const rulesetChilds = rulesetDetailForm.controls.rulesetChilds.value

	const form: Rulesets = {
		byId: {
			...rulesets.byId,
			...rulesetChilds.byId
		},
		allIds: {
			...rulesets.allIds,
			...rulesetChilds.allIds
		}
	}

	return form && form.byId[id] && form.byId[id].form
})

export const getCampaignRulesets = createSelector(campaign, ({ rulesetListForm }): CampaignRulesets => {
	const list = [...rulesetListForm.controls.campaignRulesetList.value]

	let campaignRulesets: CampaignRulesets = {
		byId: {},
		allIds: []
	}

	list.forEach(x => {
		campaignRulesets = {
			byId: {
				...campaignRulesets.byId,
				[x.id]: {
					id: x.id,
					rulesetDetailForm: x.rulesetDetailForm,
					rulesetRewardForm: x.rulesetRewardForm
				}
			},
			allIds: [...campaignRulesets.allIds, x.id]
		}
	})

	return campaignRulesets
})

export const getCampaignTiers = createSelector(campaign, ({ rulesetRewardForm }): CampaignTier[] => {
	let result = []
	const list = rulesetRewardForm.controls.campaignTiers.value

	if (list && list.allIds) {
		result = list.allIds.map(id => list.byId[id])
	}

	return result
})

export const getCampaignTier = createSelector(campaign, ({ rulesetRewardForm }, id: string) => {
	const campaignTiers = rulesetRewardForm.controls.campaignTiers.value

	const form: CampaignTiers = {
		byId: {
			...campaignTiers.byId
		},
		allIds: {
			...campaignTiers.allIds
		}
	}

	return form && form.byId[id] && form.byId[id].form
})
