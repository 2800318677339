import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Action, Store } from '@ngrx/store'
import { Observable, of } from 'rxjs'
import { catchError, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators'
import { PolicyListResponse } from 'src/app/models/access-management/policy/policy-list'
import { Response } from 'src/app/models/common/http'
import { AccessManagementService } from 'src/app/services/access-management/access-management.service'
import * as AppStore from 'src/app/store/'
import * as CommonAction from '../../common/common/common.actions'
import * as PolicyAction from './policy.actions'

@Injectable()
export class Effects {

	constructor(
		private action$: Actions,
		private router: Router,
		private accessManagementService: AccessManagementService,
		private store: Store<AppStore.State>
	) { }

	InitialState$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(PolicyAction.InitialState),
			tap(() => {
				this.router.navigate(['access-management/function'])
			})
		), { dispatch: false }
	)

	RestError$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(PolicyAction.RestError),
			map(({ message, manualThrow }) => {
				return CommonAction.RestError({ message, manualThrow })
			})
		)
	)

	List$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(PolicyAction.List),
			withLatestFrom(this.store.select(state => state.policy)),
			mergeMap(([x, policy]) => {
				const form = policy.policyListForm.controls
				return this.accessManagementService.getPolicyList().pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: PolicyListResponse = response.payload

					if (success) {
						return PolicyAction.ListSuccess({ payload })
					} else { return PolicyAction.RestError(response as any) }
				}), catchError(this.catcher)
				)
			})
		)
	)

	catcher = (error) => {
		return (of(PolicyAction.RestError({ message: error.message, manualThrow: error.manualThrow })))
	}
}
