import { KeyValue } from '@angular/common'
import { Action, createReducer, on } from '@ngrx/store'
import { formGroupReducer, markAsTouched, reset, setValue, SetValueAction, createFormGroupState } from 'ngrx-forms'
import * as RedeemPartnerAction from './redeem-partner.actions'
import * as RedeemPartnerState from './redeem-partner.state'
import * as NgrxForms from 'ngrx-forms'

export const reducer = createReducer(
	RedeemPartnerState.initialState,
	NgrxForms.onNgrxFormsAction(SetValueAction, (state, action) => {
		return actionHandler(state, action)
	}),
	NgrxForms.onNgrxFormsAction(NgrxForms.MarkAsTouchedAction, (state, action) => {
		return actionHandler(state, action)
	}),
	on(RedeemPartnerAction.InitialState, () => RedeemPartnerState.initialState),
	on(RedeemPartnerAction.List, state => ({
		...state,
		isLoading: true
	})),
	on(RedeemPartnerAction.ListSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		redeemPartnerList: payload
	})),
	on(RedeemPartnerAction.SetId, (state, { id }) => ({
		...state,
		id
	})),
	on(RedeemPartnerAction.SetConversion, (state, { redeemPartnerDetail }) => {

		let redeemPartnerRewardPools: RedeemPartnerState.RedeemPartnerRewardPools = {
			byId: {},
			allIds: []
		}

		if (redeemPartnerDetail) {
			if (redeemPartnerDetail.type === 'G') {
				redeemPartnerDetail.rewardPoolAndGlRedemption.forEach(x => {
					const id = x.rewardPoolId
					redeemPartnerRewardPools = {
						byId: {
							...redeemPartnerRewardPools.byId,
							[id]: {
								id,
								form: RedeemPartnerState.validateRedeemPartnerRewardPoolForm(getRedeemPartnerRewardPoolForm(id.toString(), id.toString(), x.rewardPoolName, Number(x.glRedemptionId)))
							}
						},
						allIds: [...redeemPartnerRewardPools.allIds, id.toString()]
					}
				})
			}
		}
		const newRedeemPartnerConversionForm = {
			id: `${redeemPartnerDetail.type}_${redeemPartnerDetail.id}`,
			point: redeemPartnerDetail.point,
			value: redeemPartnerDetail.value,
			type: redeemPartnerDetail.type,
			name: redeemPartnerDetail.name,
			glRedemptionId: Number(redeemPartnerDetail.type === 'I' ? redeemPartnerDetail.rewardPoolAndGlRedemption.find(x => x.rewardPoolId === redeemPartnerDetail.id)
				&& redeemPartnerDetail.rewardPoolAndGlRedemption.find(x => x.rewardPoolId === redeemPartnerDetail.id).glRedemptionId : null),
			redemptionRate: redeemPartnerDetail.redemptionRate,
			glProductCode: redeemPartnerDetail.glProductCode,
			rounding: redeemPartnerDetail.rounding,
			roundingValue: redeemPartnerDetail.roundingValue,
			numberDecimal: redeemPartnerDetail.numberDecimal,
			numberDecimalValue: redeemPartnerDetail.numberDecimalValue,
			rewardPoolAndGlRedemption: [],
			redeemPartnerRewardPools
		}

		const redeemPartnerConversionFormView = {
			id: `${redeemPartnerDetail.type}_${redeemPartnerDetail.id}`,
			point: redeemPartnerDetail.point,
			value: redeemPartnerDetail.value,
			type: redeemPartnerDetail.type,
			name: redeemPartnerDetail.name,
			glRedemptionId: Number(redeemPartnerDetail.type === 'I' ? redeemPartnerDetail.rewardPoolAndGlRedemption.find(x => x.rewardPoolId === redeemPartnerDetail.id)
				&& redeemPartnerDetail.rewardPoolAndGlRedemption.find(x => x.rewardPoolId === redeemPartnerDetail.id).glRedemptionId : null),
			redemptionRate: redeemPartnerDetail.redemptionRate,
			glProductCode: redeemPartnerDetail.glProductCode,
			rounding: redeemPartnerDetail.rounding,
			roundingValue: redeemPartnerDetail.roundingValue,
			numberDecimal: redeemPartnerDetail.numberDecimal,
			numberDecimalValue: redeemPartnerDetail.numberDecimalValue,
			rewardPoolAndGlRedemption: [],
			redeemPartnerRewardPools
		}

		const redeemPartnerConversionForm = RedeemPartnerState.validateRedeemPartnerConversionForm(state)(
			markAsTouched(
				setValue(state.redeemPartnerConversionForm, newRedeemPartnerConversionForm)
			)
		)

		return ({
			...state,
			redeemPartnerDetail,
			redeemPartnerConversionForm,
			redeemPartnerConversionFormView
		})
	}),
	on(RedeemPartnerAction.GoCreate, state => ({
		...state,
		redeemPartnerDetailForm: reset(setValue(state.redeemPartnerDetailForm, RedeemPartnerState.initialRedeemPartnerDetail)),
		redeemPartnerDetailList: []
	})),
	on(RedeemPartnerAction.View, state => ({
		...state,
		isLoading: true,
		redeemPartnerView: RedeemPartnerState.initialRedeemPartnerViewResponse,
		redeemPartnerDetailList: []
	})),
	on(RedeemPartnerAction.ViewSuccess, (state, { payload }) => {

		const rewardPoolAndGlRedemption = payload.document ? payload.document : []
		let redeemPartnerRewardPools: RedeemPartnerState.RedeemPartnerRewardPools = {
			byId: {},
			allIds: []
		}

		rewardPoolAndGlRedemption.forEach(e => {
			if (e.type === 'G') {
				e.rewardPoolAndGlRedemption.forEach(x => {
					const id = x.rewardPoolId
					redeemPartnerRewardPools = {
						byId: {
							...redeemPartnerRewardPools.byId,
							[id]: {
								id,
								form: RedeemPartnerState.validateRedeemPartnerRewardPoolForm(getRedeemPartnerRewardPoolForm(id.toString(), id, x.rewardPoolName, x.glRedemptionId))
							}
						},
						allIds: [...redeemPartnerRewardPools.allIds, id.toString()]
					}
				})
			}
		})

		const redeemPartnerFormView = {
			id: payload.id,
			code: payload.code,
			name: payload.name,
			partnerAuthorizationUrl: payload.detail ? payload.detail.partnerAuthorizationUrl : '',
			clientId: payload.detail ? payload.detail.clientId : '',
			clientSecret: payload.detail ? payload.detail.clientSecret : '',
			createdBy: payload.createdBy,
			createdDate: payload.createdDate,
			modifiedBy: payload.modifiedBy,
			modifiedDate: payload.modifiedDate
		}

		return ({
			...state,
			isLoading: false,
			redeemPartnerView: payload,
			redeemPartnerDetailList: payload.document ? [...payload.document.map(x => ({
				id: x.id.toString(),
				name: x.name,
				type: x.type,
				point: x.point.toString(),
				value: x.value.toString(),
				redemptionRate: x.redemptionRate.toFixed(4).toString(),
				glProductCode: x.glProductCode,
				rounding: x.rounding,
				roundingValue: x.roundingValue,
				numberDecimal: x.numberDecimal,
				numberDecimalValue: x.numberDecimalValue,
				rewardPoolAndGlRedemption: x.rewardPoolAndGlRedemption,
				used: x.used
			}))] : [],
			redeemPartnerFormView,
			redeemPartnerConversionDetailView: payload.document ? [...payload.document.map(x => ({
				id: x.id.toString(),
				name: x.name,
				type: x.type,
				point: x.point.toString(),
				value: x.value.toString(),
				redemptionRate: x.redemptionRate.toFixed(4).toString(),
				glProductCode: x.glProductCode,
				rounding: x.rounding,
				roundingValue: x.roundingValue,
				numberDecimal: x.numberDecimal,
				numberDecimalValue: x.numberDecimalValue,
				rewardPoolAndGlRedemption: x.rewardPoolAndGlRedemption,
				used: x.used
			}))] : []
		})
	}),
	on(RedeemPartnerAction.Update, state => ({
		...state,
		isLoading: true
	})),
	on(RedeemPartnerAction.UpdateSuccess, state => ({
		...state,
		isLoading: false
	})),
	on(RedeemPartnerAction.UpdateView, state => ({
		...state,
		isLoading: true
	})),
	on(RedeemPartnerAction.UpdateViewSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		redeemPartnerDetailList: payload.document ? [...payload.document.map(x => ({
			id: x.id.toString(),
			name: x.name,
			type: x.type,
			point: x.point.toString(),
			value: x.value.toString(),
			redemptionRate: x.redemptionRate.toFixed(4),
			glProductCode: x.glProductCode,
			rounding: x.rounding,
			roundingValue: x.roundingValue,
			numberDecimal: x.numberDecimal,
			numberDecimalValue: x.numberDecimalValue,
			rewardPoolAndGlRedemption: x.rewardPoolAndGlRedemption,
			used: x.used
		}))] : [],
		redeemPartnerDetailForm: RedeemPartnerState.validateRedeemPartnerDetailForm(setValue(state.redeemPartnerDetailForm, {
			id: payload.id,
			code: payload.code,
			name: payload.name,
			partnerAuthorizationUrl: payload.detail ? payload.detail.partnerAuthorizationUrl : '',
			clientId: payload.detail ? payload.detail.clientId : '',
			clientSecret: payload.detail ? payload.detail.clientSecret : '',
			createdBy: payload.createdBy,
			createdDate: payload.createdDate,
			modifiedBy: payload.modifiedBy,
			modifiedDate: payload.modifiedDate
		} as RedeemPartnerState.RedeemPartnerDetailForm))
	})),
	on(RedeemPartnerAction.GLOptionListView, state => ({
		...state,
		isLoading: true
	})),
	on(RedeemPartnerAction.GLOptionListViewSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		glRedemptions: (payload.glRedemptions || []).map(i => ({ key: i.id, value: `${i.code} - ${i.name}` }))
	})),
	on(RedeemPartnerAction.GLOptionListUpdate, state => ({
		...state,
		isLoading: true
	})),
	on(RedeemPartnerAction.GLOptionListUpdateSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		glRedemptions: (payload.glRedemptions || []).map(i => ({ key: i.id, value: `${i.code} - ${i.name}` }))
	})),
	on(RedeemPartnerAction.RewardOptionListView, state => ({
		...state,
		isLoading: true
	})),
	on(RedeemPartnerAction.RewardOptionListViewSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		rewardList: (payload || []).map(i => ({ key: `${i.type}_${i.id}`, value: `${i.code} - ${i.name}` })) as KeyValue<string, string>[]
	})),
	on(RedeemPartnerAction.RewardOptionListUpdate, state => ({
		...state,
		isLoading: true
	})),
	on(RedeemPartnerAction.RewardOptionListUpdateSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		rewardList: (payload || []).map(i => ({ key: `${i.type}_${i.id}`, value: `${i.code} - ${i.name}` })) as KeyValue<string, string>[]
	})),
	on(RedeemPartnerAction.ConversionDialog, (state, { action }) => ({
		...state,
		action,
		redeemPartnerDetail: undefined,
		redeemPartnerConversionForm: reset(setValue(state.redeemPartnerConversionForm, RedeemPartnerState.initialRedeemPartnerConversionFormValue))
	})),
	on(RedeemPartnerAction.SetPointConversionId, (state, { pointConversionId }) => ({
		...state,
		pointConversionId
	})),
	on(RedeemPartnerAction.SaveConversion, state => {
		const redeemPartnerConversionForm = state.redeemPartnerConversionForm.controls
		let redeemPartnerDetailList = []
		let id = ''
		let name = ''
		let type = ''
		const point = redeemPartnerConversionForm.point.value.toString()
		const value = redeemPartnerConversionForm.value.value.toString()
		const pointConversionId = state.pointConversionId
		const redemptionRate = redeemPartnerConversionForm.redemptionRate.value.toString()
		const glProductCode = redeemPartnerConversionForm.glProductCode.value
		const rounding = redeemPartnerConversionForm.rounding.value
		const roundingValue = redeemPartnerConversionForm.roundingValue.value
		const numberDecimal = redeemPartnerConversionForm.numberDecimal.value
		const numberDecimalValue = redeemPartnerConversionForm.numberDecimalValue.value
		const rewardPoolAndGlRedemption = []
		const rewardPool = redeemPartnerConversionForm.id.value
		const pointConversionForm = state.redeemPartnerConversionForm.controls
		const redeemPartnerRewardPoolsVal = pointConversionForm.redeemPartnerRewardPools.value
		const used = null

		redeemPartnerRewardPoolsVal.allIds.forEach(id => {
			if (redeemPartnerRewardPoolsVal.byId.hasOwnProperty(id)) {
				const byId = redeemPartnerRewardPoolsVal.byId[id]
				const formVal = byId.form.value

				rewardPoolAndGlRedemption.push({
					rewardPoolId: formVal.rewardPoolId,
					glRedemptionId: formVal.glRedemptionId,
					rewardPoolName: formVal.rewardPoolName
				})
			}
		})

		let rewardPoolType = ''
		let rewardPoolId = ''
		if (rewardPool) {
			rewardPoolType = rewardPool.split('_')[0]
			rewardPoolId = rewardPool.split('_')[1]
		}
		if (rewardPoolType === 'I') {
			rewardPoolAndGlRedemption.push({
				rewardPoolId,
				rewardPoolName: null,
				glRedemptionId: redeemPartnerConversionForm.glRedemptionId.value.toString()
			})
		}

		if (redeemPartnerConversionForm && redeemPartnerConversionForm.id) {
			const redeemPartnerDetailIndex = state.redeemPartnerDetailList.findIndex(x => `${x.type}_${x.id}` === pointConversionId)
			id = redeemPartnerConversionForm.id.value.split('_')[1]
			type = redeemPartnerConversionForm.id.value.split('_')[0]

			const reward = state.rewardList.find(x => x.key === redeemPartnerConversionForm.id.value)
			if (reward) {
				name = reward.value
			}

			const redeemPartnerDetail = {
				id,
				name,
				type,
				point,
				value,
				redemptionRate,
				glProductCode,
				rounding,
				roundingValue,
				numberDecimal,
				numberDecimalValue,
				rewardPoolAndGlRedemption,
				used
			}

			if (redeemPartnerDetailIndex !== -1) {
				const newList = [...state.redeemPartnerDetailList]
				newList[redeemPartnerDetailIndex] = redeemPartnerDetail
				redeemPartnerDetailList = [...newList]
				const item = redeemPartnerDetailList[redeemPartnerDetailIndex]
				redeemPartnerDetailList.splice(redeemPartnerDetailIndex, 1) // remove the item from list
				redeemPartnerDetailList.unshift(item) // append the item to the first position of list
			} else {
				redeemPartnerDetailList = [...state.redeemPartnerDetailList]
				redeemPartnerDetailList.unshift(redeemPartnerDetail)
			}
		}

		return ({
			...state,
			redeemPartnerDetailList
		})
	}),
	on(RedeemPartnerAction.DeleteConversion, state => ({
		...state,
		redeemPartnerDetailList: [...state.redeemPartnerDetailList.filter(x => {
			if (x.id !== state.redeemPartnerDetail.id || x.type !== state.redeemPartnerDetail.type) {
				return true
			}
		})]
	})),
	on(RedeemPartnerAction.OnChangeRounding, state => ({
		...state,
		redeemPartnerConversionForm: setValue(state.redeemPartnerConversionForm, {
			...state.redeemPartnerConversionForm.value,
			roundingValue: state.redeemPartnerConversionForm.controls.rounding.value ? state.redeemPartnerConversionForm.controls.roundingValue.value : '',
			numberDecimal: state.redeemPartnerConversionForm.controls.rounding.value ? state.redeemPartnerConversionForm.controls.numberDecimal.value : '',
			numberDecimalValue: state.redeemPartnerConversionForm.controls.rounding.value ? state.redeemPartnerConversionForm.controls.numberDecimalValue.value : ''
		})
	})),
	on(RedeemPartnerAction.OnChangeRewardPool, state => {
		const rewardPoolAndGroup = state.redeemPartnerConversionForm.controls
		const id = rewardPoolAndGroup.id.value
		const type = rewardPoolAndGroup.type.value
		const redeemPartnerRewardPools: RedeemPartnerState.RedeemPartnerRewardPools = {
			byId: {},
			allIds: []
		}

		return ({
			...state,
			redeemPartnerConversionForm: RedeemPartnerState.validateRedeemPartnerConversionForm(state)(setValue(state.redeemPartnerConversionForm, {
				...state.redeemPartnerConversionForm.value,
				id,
				type,
				glRedemptionId: null,
				redeemPartnerRewardPools
			}))
		})
	}),
	on(RedeemPartnerAction.OnChangeRewardPoolGroup, state => {
		const rewardPoolAndGroup = state.redeemPartnerConversionForm.controls
		const id = rewardPoolAndGroup.id.value
		const type = rewardPoolAndGroup.type.value
		const redeemPartnerRewardPools: RedeemPartnerState.RedeemPartnerRewardPools = {
			byId: {},
			allIds: []
		}

		return ({
			...state,
			redeemPartnerConversionForm: RedeemPartnerState.validateRedeemPartnerConversionForm(state)(setValue(state.redeemPartnerConversionForm, {
				...state.redeemPartnerConversionForm.value,
				id,
				type,
				glRedemptionId: null,
				redeemPartnerRewardPools
			}))
		})
	}),
	on(RedeemPartnerAction.OnChangeRewardPoolGroupSuccess, (state, { payload }) => {
		const rewardPoolList = payload.rewardPools

		let redeemPartnerRewardPools = {
			byId: {},
			allIds: []
		}

		if (rewardPoolList) {
			rewardPoolList.forEach(x => {
				const id = x.id.toString()

				redeemPartnerRewardPools = {
					byId: {
						...redeemPartnerRewardPools.byId,
						[id]: {
							id,
							form: RedeemPartnerState.validateRedeemPartnerRewardPoolForm(getRedeemPartnerRewardPoolForm(id, id, `${x.code} - ${x.name}`, null))
						}
					},
					allIds: [...redeemPartnerRewardPools.allIds, id]
				}
			})
		}
		return {
			...state,
			redeemPartnerConversionForm: setValue(state.redeemPartnerConversionForm, {
				...state.redeemPartnerConversionForm.value,
				redeemPartnerRewardPools
			})
		}
	}),
	on(RedeemPartnerAction.RoundingList, state => ({
		...state,
		isLoading: true
	})),
	on(RedeemPartnerAction.RoundingListSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		roundingList: payload.roundingList
	})),
	on(RedeemPartnerAction.NumberDecimalList, state => ({
		...state,
		isLoading: true
	})),
	on(RedeemPartnerAction.NumberDecimalListSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		numberDecimalList: payload.numberDecimalList
	})),
	on(RedeemPartnerAction.UpdatePointsConversion, state => ({
		...state,
		isLoading: true
	})),
	on(RedeemPartnerAction.UpdatePointsConversionSuccess, (state, { payload }) => ({
		...state,
		isLoading: false
	})),
)

export function Reducer(state: RedeemPartnerState.State = RedeemPartnerState.initialState, action: Action) {
	const redeemPartnerDetailForm = RedeemPartnerState.validateRedeemPartnerDetailForm(formGroupReducer(state.redeemPartnerDetailForm, action))
	if (redeemPartnerDetailForm !== state.redeemPartnerDetailForm) {
		state = { ...state, redeemPartnerDetailForm }
	}

	const redeemPartnerConversionForm = RedeemPartnerState.validateRedeemPartnerConversionForm(state)(formGroupReducer(state.redeemPartnerConversionForm, action))
	if (redeemPartnerConversionForm !== state.redeemPartnerConversionForm) {
		state = { ...state, redeemPartnerConversionForm }
	}
	return reducer(state, action)
}

function getRedeemPartnerRewardPoolForm(id: string, rewardPoolId?: string, rewardPoolName?: string, glRedemptionId?: number) {
	if (rewardPoolId || glRedemptionId) {
		const id = rewardPoolId
		const formGroup = createFormGroupState<RedeemPartnerState.RedeemPartnerRewardPoolForm>(id, {
			rewardPoolId,
			rewardPoolName,
			glRedemptionId
		})
		return validateRedeemPartnerRewardPoolForm(formGroup)
	}

	return createFormGroupState<RedeemPartnerState.RedeemPartnerRewardPoolForm>(id,
		RedeemPartnerState.initialRedeemPartnerRewardPoolFormValue)
}

function validateRedeemPartnerRewardPoolForm(form: any, state?: RedeemPartnerState.State) {
	return markAsTouched(RedeemPartnerState.validateRedeemPartnerRewardPoolForm(form))
}

function validateForm(form: any) {
	form = markAsTouched(form)

	return RedeemPartnerState.validateRedeemPartnerConversionForm(form)
}

function actionHandler(state: RedeemPartnerState.State, action: SetValueAction<unknown> | NgrxForms.MarkAsTouchedAction) {
	const formControls = state.redeemPartnerConversionForm

	let form = formGroupReducer(formControls, action)

	if (action.type === 'ngrx/forms/SET_VALUE') {
		form = formGroupReducer(formControls, new SetValueAction(action.controlId, action.value))
	}

	if (form) {
		form = validateForm(state)(form)

		state = {
			...state,
			redeemPartnerConversionForm: form
		}
	}

	const controlId = action.controlId.split('.')[0]

	let redeemPartnerRewardPoolsVal = formControls.value.redeemPartnerRewardPools

	const newRedeemPartnerRewardPools: RedeemPartnerState.RedeemPartnerRewardPools = {
		byId: {
			...redeemPartnerRewardPoolsVal.byId
		},
		allIds: {
			...redeemPartnerRewardPoolsVal.allIds
		}
	}

	const redeemPartnerRewardPools = newRedeemPartnerRewardPools.byId[controlId]

	if (redeemPartnerRewardPools) {
		let form = formGroupReducer(redeemPartnerRewardPools.form, action)

		if (action.type === 'ngrx/forms/SET_VALUE') {
			form = formGroupReducer(redeemPartnerRewardPools.form, new SetValueAction(action.controlId, action.value))
		}

		redeemPartnerRewardPoolsVal = {
			byId: {
				...redeemPartnerRewardPoolsVal.byId,
				[controlId]: {
					...redeemPartnerRewardPoolsVal.byId[controlId],
					form: validateRedeemPartnerRewardPoolForm(form)
				}
			},
			allIds: [...redeemPartnerRewardPoolsVal.allIds]
		}

		state = {
			...state,
			redeemPartnerConversionForm: setValue(state.redeemPartnerConversionForm, {
				...state.redeemPartnerConversionForm.value,
				redeemPartnerRewardPools: redeemPartnerRewardPoolsVal
			})
		}
	}
	return state
}
