import { createAction, props } from '@ngrx/store'
import { SimulationResult, TicketSequence } from 'src/app/models/campaign-management/simulator/simulator'
import { SimulatorDataResponse, SimulatorKeyDataResponse } from 'src/app/models/campaign-management/simulator/simulator-data'
import { SimulatorViewResponse } from 'src/app/models/campaign-management/simulator/simulator-view'

export const InitialState = createAction('[Simulator] InitialState')
export const SetId = createAction('[Simulator] SetId', props<{ id: number }>())
export const SetSimulationQueue = createAction('[Simulator] SetSimulationQueue', props<{ isSimulationQueue: boolean }>())

export const GoList = createAction('[Simulator] GoList')

export const GoBackDialog = createAction('[Simulator] GoBackDialog', props<{ action: string }>())
export const RawDataSimulatorDialog = createAction('[Simulator] RawDataSimulatorDialog')
export const ConflictDataSimulatorDialog = createAction('[Simulator] ConflictDataSimulatorDialog')
export const CreateDialog = createAction('[Simulator] CreateDialog')
export const ViewDialog = createAction('[Simulator] ViewDialog')
export const DeleteDialog = createAction('[Simulator] DeleteDialog')
export const CancelDialog = createAction('[Simulator] PublishDialog')
export const CloseDialog = createAction('[Simulator] CloseDialog')

export const RestError = createAction('[Simulator] RestError', props<{ message: string, manualThrow: boolean }>())

export const List = createAction('[Simulator] List')
export const ListSuccess = createAction('[Simulator] ListSuccess', props<{ payload: TicketSequence[] }>())

export const SimulationResultList = createAction('[Simulator] SimulationResultList')
export const SimulationResultListSuccess = createAction('[Simulator] SimulationResultListSuccess', props<{ payload: SimulationResult[] }>())

export const View = createAction('[Simulator] View')
export const ViewSuccess = createAction('[Simulator] ViewSuccess', props<{ payload: SimulatorViewResponse }>())

export const Create = createAction('[Simulator] Create')
export const CreateSuccess = createAction('[Simulator] CreateSuccess', props<{ payload: string }>())

export const Delete = createAction('[Simulator] Delete')
export const DeleteSuccess = createAction('[Simulator] DeleteSuccess')

export const Cancel = createAction('[Simulator] Cancel')
export const CancelSuccess = createAction('[Simulator] CancelSuccess')

export const GetSimulatorData = createAction('[Simulator] GetSimulatorData')
export const GetSimulatorDataSuccess = createAction('[Simulator] GetSimulatorDataSuccess', props<{ payload: SimulatorDataResponse }>())

export const GetSimulatorKeyData = createAction('[Simulator] GetSimulatorKeyData')
export const GetSimulatorKeyDataSuccess = createAction('[Simulator] GetSimulatorKeyDataSuccess', props<{ payload: SimulatorKeyDataResponse }>())

export const OnChangeResourceCode = createAction('[Simulator] OnChangeResourceCode')
export const OnChangeSimulatorTarget = createAction('[Simulator] OnChangeSimulatorTarget')
