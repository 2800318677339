import { FormGroupState } from 'ngrx-forms'
import { ProductCatalogueDetailForm } from 'src/app/store/catalogue-management/product-catalogue'
import { RewardPoolDetailForm } from 'src/app/store/param-settings/reward-pool'
import { ValidatorFn, AbstractControl } from '@angular/forms'

export interface Field {
	variable: string
	label: string
}

export class FieldValidator {

	// example: 0-9
	static digit() {
		return (value: string) => {
			if (/\s/.test(value)) {
				return { notAllowedSpace: true }
			}
			const regExp = new RegExp(/^\d+$/)
			const isMatch = value && !value.toString().match(regExp)
			return isMatch ? { digit: true } : null
		}
	}

	// example: 0.00
	static check2Decimal() {
		return (value: string) => {
			const regExp = new RegExp(/^\d*\.?\d{0,2}$/g)
			const isMatch = value && !value.toString().match(regExp)
			return isMatch ? { digit: true } : null
		}
	}

	// example: split (,) then check length and 0-9
	static checkNumeric(
		length: number = 255,
		isExactLength: boolean = false,
		isRange: boolean = false
	) {
		return (value: string) => {
			const regExp = new RegExp(/^\d+$/)
			let isMatch = null

			if (!value) { return isMatch }

			if (/\s/.test(value)) {
				return { notAllowedSpace: true }
			}

			value.split(',')
				.map(x => x = x.trim())
				.forEach(x => {
					if (isRange) {
						const range = x.split('-')
						if (range && range.length > 0) {
							const from = range[0]
							if (range.length > 2) {
								isMatch = { digit: true }
							}

							if (range.length === 2) {
								const to = range[1]

								if (isExactLength) {
									if ((from.length !== length || !from.match(regExp)) ||
										(to.length !== length || !to.match(regExp))) {
										isMatch = { digit: true }
									}
								} else {
									if ((from.length > length || !from.match(regExp)) ||
										(to.length > length || !to.match(regExp))) {
										isMatch = { digit: true }
									}
								}
							} else {
								if (isExactLength) {
									if (from.length !== length || !from.match(regExp)) {
										isMatch = { digit: true }
									}
								} else {
									if (from.length > length || !from.match(regExp)) {
										isMatch = { digit: true }
									}
								}
							}
						}
					} else {
						if (isExactLength) {
							if (x.length !== length || !x.match(regExp)) {
								isMatch = { digit: true }
							}
						} else {
							if (x.length > length || !x.match(regExp)) {
								isMatch = { digit: true }
							}
						}
					}
				})

			return isMatch
		}
	}

	// example: split (,) then check length and 0-9,A-Z,a-z
	static checkAlphaNumeric(
		length: number = 255,
		isExactLength: boolean = false,
		isRange: boolean = false,
		allowSpace: boolean = false,
		isNumeric: boolean = false
	) {
		return (value: string) => {
			const regExp = isNumeric ? new RegExp(/^([0-9])+$/) : new RegExp(/^([a-zA-Z0-9])+$/)
			const spaceRegExp = isNumeric ? new RegExp(/^([0-9\s])+$/) : new RegExp(/^([a-zA-Z0-9\s])+$/)

			let isMatch = null

			if (!value) { return isMatch }

			value.split(',')
				.forEach(x => {
					if (isRange) {
						const range = x.split('-')
						if (range && range.length > 0) {
							const from = range[0]
							if (range.length > 2) {
								isMatch = { digit: true }
							}

							if (range.length === 2) {
								const to = range[1]
								if (!allowSpace && (!from.match(regExp) || !to.match(regExp))) {
									if (!from.match(spaceRegExp) || !to.match(spaceRegExp)) {
										isMatch = { digit: true }
									} else {
										isMatch = { notAllowedSpace: true }
									}
								} else if (isExactLength) {
									if ((from.length !== length || !from.match(allowSpace ? spaceRegExp : regExp)) ||
										(to.length !== length || !to.match(allowSpace ? spaceRegExp : regExp))) {
										isMatch = { digit: true }
									}
									if (isNumeric) {
										if ((Number(from) >= Number(to) || !from.match(allowSpace ? spaceRegExp : regExp))) {
											isMatch = { invalidInputRange: true, to, from }
										}
									}
								} else if (isNumeric) {
									if ((Number(from) >= Number(to) || !from.match(allowSpace ? spaceRegExp : regExp))) {
										isMatch = { invalidInputRange: true, to, from }
									}
								} else {
									if ((from.length > length || !from.match(allowSpace ? spaceRegExp : regExp)) ||
										(to.length > length || !to.match(allowSpace ? spaceRegExp : regExp))) {
										isMatch = { digit: true }
									}
								}

							} else {
								if (!allowSpace && !from.match(regExp)) {
									if (!from.match(spaceRegExp)) {
										isMatch = { digit: true }
									} else {
										isMatch = { notAllowedSpace: true }
									}
								} else if (isExactLength) {
									if (from.length !== length || !from.match(allowSpace ? spaceRegExp : regExp)) {
										isMatch = { digit: true }
									}
								} else {
									if (from.length > length || !from.match(allowSpace ? spaceRegExp : regExp)) {
										isMatch = { digit: true }
									}
								}
							}
						}
					} else {
						if (!allowSpace && !x.match(regExp)) {
							if (!x.match(spaceRegExp)) {
								isMatch = { digit: true }
							} else {
								isMatch = { notAllowedSpace: true }
							}
						} else if (isExactLength) {
							if (x.length !== length || !x.match(allowSpace ? spaceRegExp : regExp)) {
								isMatch = { digit: true }
							}
						} else {
							if (x.length > length || !x.match(allowSpace ? spaceRegExp : regExp)) {
								isMatch = { digit: true }
							}
						}
					}
				})

			return isMatch
		}
	}

	// example: 1-999
	static digitRange(from: number = 0, to: number = 0) {
		return (value: string) => {
			if (!value) { return null }
			const isMatch = +value >= from && +value <= to
			return isMatch ? null : { digitRange: true }
		}
	}

	// duplicate code
	static duplicateCode(codes: string[]) {
		return (value: string) => {
			if (!value) { return null }
			const isMatch = !codes.includes(value)
			return isMatch ? null : { duplicate: true }
		}
	}

	static alphaNumericWithSpaces() {
		return (value: string) => {
			const isMatch = value.match(new RegExp(/^([a-zA-Z0-9 ])+$/))

			return isMatch ? null : { alphaNumericWithSpaces: true }
		}
	}

	static alphaNumericWithoutSpaces() {
		return (value: string) => {
			if (value.match(new RegExp(/^([a-zA-Z0-9])+$/))) {
				return null
			} else if (value.match(new RegExp(/^([a-zA-Z0-9\s])+$/))) {
				return { notAllowedSpace: true }
			} else {
				return { alphaNumericWithoutSpaces: true }
			}
		}
	}

	static numberWithoutDecimal() {
		return (value: string) => {
			if (value !== null && value !== undefined && value !== '') {
				if (value.match(new RegExp(/^[0-9]+$/))) {
					return null
				} else if (value.match(new RegExp(/^[0-9\s]+$/))) {
					return { notAllowedSpace: true }
				} else {
					return { numberWithoutDecimal: true }
				}
			}
		}
	}

	static numberWithDecimal(decimalPoints: number) {
		return (value: string) => {
			const isMatch = (value !== null && value !== undefined && value !== '') ? new RegExp('^(\\d+\\.\\d{0,' + decimalPoints + '}|\\d+)$', 'g').test(value) : true
			return isMatch ? null : { numberWithDecimal: true, decimal: decimalPoints }
		}
	}

	static integerAndDecimalCheck(integerPoints: number, decimalPoints: number, checkIsZero: boolean = false) {
		return (value: string) => {
			if (value !== null && value !== undefined && value !== '') {
				if (checkIsZero && +(value.replace(/\s/g, '')) <= 0) {
					return { NotStartWithZero: true }
				}
				if (new RegExp(`^(\\d{1,${integerPoints}}\\.\\d{0,${decimalPoints}}|\\d{1,${integerPoints}})$`, 'g').test(value)) {
					return null
				} else if (value.includes(' ')) {
					return { notAllowedSpace: true }
				} else {
					return { integerAndDecimal: true, integer: integerPoints, decimal: decimalPoints }
				}
			}
		}
	}

	static fieldValueCheck(state: FormGroupState<any>, fieldA: Field, fieldB: Field, needMatch: boolean) {
		return () => {
			if (needMatch) {
				if (state.value[fieldA.variable] !== state.value[fieldB.variable]) {
					return { fieldValueCheck: true, fieldA: fieldA.label, fieldB: fieldB.label, match: '' }
				}
			} else {
				if (state.value[fieldA.variable] === state.value[fieldB.variable]) {
					return { fieldValueCheck: true, fieldA: fieldA.label, fieldB: fieldB.label, match: 'not' }
				}
			}
			return null
		}
	}

	static notEvergreenValidator(state: FormGroupState<RewardPoolDetailForm>) {
		const selectedValue = { expCycleType: state.value.expCycleType }
		return (value: string) => {
			if (selectedValue.expCycleType !== 'E' && Number(value) <= 0) { return { notEvergreenValidator: true } }
			return null
		}
	}

	static checkProductCatalogueAmount(state: FormGroupState<ProductCatalogueDetailForm>) {
		const redemptionType = state.value.redemptionType
		return (value: string) => {
			if (redemptionType === 'PA') {
				if (value === null || value === undefined || value === '') {
					return { requiredIf: true }
				}
				const isMatch = value !== undefined ? new RegExp('^(\\d+\.\\d{0,' + 2 + '}|\\d+)$', 'g').test(value) : null
				return isMatch ? null : { numberWithDecimal: true, decimal: 2 }
			}
			return null
		}
	}

	static checkProductCatalogueTransactionCode(state: FormGroupState<ProductCatalogueDetailForm>) {
		const redemptionType = state.value.redemptionType
		return (value: string) => {
			if (redemptionType === 'PA') {
				if (value === null || value === undefined || value === '') {
					return { requiredIf: true }
				}
				const isMatch = value !== undefined ? new RegExp(/^[0-9]+$/).test(value) : null
				return isMatch ? null : { numberWithoutDecimal: true }
			}
			return null
		}
	}

	static requiredIf(data: string) {
		return (value: string) => {
			const isMatch = (value === null || value === undefined || value === '') && data
			return isMatch ? { requiredIf: true } : null
		}
	}

	static requiredIfTrue(data: boolean) {
		return (value: string) => {
			const isMatch = (value === null || value === undefined || value === '') && data
			return isMatch ? { requiredIf: true } : null
		}
	}

	static requiredIfTrueNumber(data: boolean) {
		return (value: number) => {
			const isMatch = (value === null || value === undefined) && data
			return isMatch ? { requiredIf: true } : null
		}
	}

	static checkCycleMonth(data: string) {
		return (value: string) => {
			const isMatch = (data === 'Y') && (value === null || value === undefined || value === '')
			return isMatch ? { requiredIf: true } : null
		}
	}

	static checkCycleDay(data: string) {
		return (value: string) => {
			const isMatch = (data === 'W' || data === 'M') && (value === null || value === undefined || value === '')
			return isMatch ? { requiredIf: true } : null
		}
	}

	static checkCapCycleDay(data: string) {
		return (value: string) => {
			const isMatch = (data === 'W' || data === 'M'  || data === 'Y') && (value === null || value === undefined || value === '')
			return isMatch ? { requiredIf: true } : null
		}
	}

	static checkBillingCycle(data: string) {
		return (value: string) => {
			const isMatch = (data === 'B') && (value === null || value === undefined || value === '')
			return isMatch ? { requiredIf: true } : null
		}
	}

	static checkCycleTypeOption(data: string) {
		return (value: string) => {
			const isMatch = (data === 'B' || data === 'Y') && (value === null || value === undefined || value === '')
			return isMatch ? { requiredIf: true } : null
		}
	}

	static checkBillingCycleType(data: string) {
		return (value: string) => {
			const isMatch = (data === 'BC' || data === 'AC') && (value === null || value === undefined || value === '')
			return isMatch ? { requiredIf: true } : null
		}
	}

	static checkYearlyCycleType(data: string) {
		return (value: string) => {
			const isMatch = (data === 'FD') && (value === null || value === undefined || value === '')
			return isMatch ? { requiredIf: true } : null
		}
	}

	static checkRewardPoolType(data: string) {
		return (value: number) => {
			const isMatch = (data === 'I') && (value === null || value === undefined || value === 0)
			return isMatch ? { requiredIf: true } : null
		}
	}

	static requiredIfMatch(data: string, targetMatch: string) {
		return (value: string) => {
			if (targetMatch === 'V') {
				const regExp = new RegExp(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
				const isMatch = (data === targetMatch) && (value === null || value === undefined || value === '' || !value.match(regExp))
				return isMatch ? { invalidEmail: true } : null
			} else {
				const isMatch = (data === targetMatch) && (value === null || value === undefined || value === '')
				return isMatch ? { requiredIf: true } : null
			}
		}
	}

	static percentageRange() {
		return (value: string) => {
			if (!value) { return null }
			if (value !== null && value !== undefined && value !== '') {
				if (+value >= 0 && +value <= 100) {
					return null
				} else if (value.includes(' ')) {
					return { notAllowedSpace: true }
				} else {
					return { percentageRange: true }
				}
			}
		}
	}

	static NotStartWithZero() {
		return (value: string) => {
			if (!value) { return null }
			if (value !== null && value !== undefined && value !== '') {
				if (+value > 0) {
					return null
				} else if (value.includes(' ')) {
					return { notAllowedSpace: true }
				} else {
					return { NotStartWithZero: true }
				}
			}
		}
	}

	static lessThan(data: string) {
		return (value: string): { [key: string]: any } => {
			const isMatch = (value !== null && value !== undefined && value !== '') &&
				(data !== null && data !== undefined && data !== '') &&
				+value >= +data
			return isMatch ? { lessThan: true } : null
		}
	}

	static lessThanOrEqualTo(data: string) {
		return (value: string): { [key: string]: any } => {
			const isMatch = (value !== null && value !== undefined && value !== '') &&
				(data !== null && data !== undefined && data !== '') &&
				+value > +data
			return isMatch ? { lessThanOrEqualTo: true } : null
		}
	}

	static moreThan(data: string) {
		return (value: string): { [key: string]: any } => {
			const isMatch = (value !== null && value !== undefined && value !== '') &&
				(data !== null && data !== undefined && data !== '') &&
				+value <= +data
			return isMatch ? { moreThan: true } : null
		}
	}

	static moreThanOrEqualTo(data: string) {
		return (value: string): { [key: string]: any } => {
			const isMatch = (value !== null && value !== undefined && value !== '') &&
				(data !== null && data !== undefined && data !== '') &&
				+value < +data
			return isMatch ? { moreThanOrEqualTo: true } : null
		}
	}

	static ifTrue(data: boolean, error: string) {
		return () => {
			return data ? { [error]: true } : null
		}
	}

	// angular form without ngrx validator
	static formAlphaNumericWithoutSpaces(): ValidatorFn {
		return (control: AbstractControl): { [key: string]: any } | null => {
			const isMatch = control.value.match(new RegExp(/^([a-zA-Z0-9])+$/))
			return isMatch ? null : { alphaNumericWithoutSpaces: true }
		}
	}

	static checkNumericMatchLength(length: number) {
		return (value: any) => {
			if (!value) { return null }
			if (value.match(new RegExp(/^([0-9])+$/)) && value.length === length) {
				return null
			} else if (value.match(new RegExp(/^([0-9\s])+$/)) && value.length === length) {
				return { notAllowedSpace: true }
			} else {
				return { numericMatchLength: true, numericLength: length }
			}
		}
	}

	static checkNumericLengthUpTo(length: number) {
		return (value: any) => {
			if (!value) { return null }
			const lengthMatch = value.match(new RegExp(/^[0-9]+$/)) && value.toString().length <= length
			return lengthMatch ? null : { numericUpToLength: true, numericLength: length }
		}
	}

	static checkAlphaNumericMatchLength(length: number) {
		return (value: string) => {
			if (!value) { return null }
			if (value.match(new RegExp(/^([a-zA-Z0-9])+$/)) && value.length === length) {
				return null
			} else if (value.match(new RegExp(/^([a-zA-Z0-9\s])+$/)) && value.length === length) {
				return { notAllowedSpace: true }
			} else {
				return { alphaNumericMatchLength: true, alphaNumericLength: length }
			}
		}
	}

	static checkMiniminPoint(points, minimumPoints: number) {
		return (value: number) => {
			const isMatch = (points < minimumPoints)
			return isMatch ? { min: true } : null
		}
	}

	static checkStartWithZeroNumber() {
		return (value: number) => {
			const isMatch = value > 0
			return isMatch ? null : { NotStartWithZero: true }
		}
	}

	static checkSameString(data: string, sameStringLabel: string) {
		return (value: string) => {
			return value === data ? { sameString: true, sameStringLabel } : null
		}
	}

	static checkFirstPositionContainSpace() {
		return (value: string) => {
			const isMatch = !value.startsWith(' ', 0)
			return isMatch ? null : { firstPositionContainSpace: true }
		}
	}

	// Allow ASCII characters code 32 to 126
	static checkValidCharacters() {
		return (value: string) => {
			const isValid = value === null || value === '' || value.match(new RegExp(/^[\x20-\x7E]+$/g))

			return isValid ? null : { containInvalidCharacters: true }
		}
	}
}
