import { state } from '@angular/animations'
import { KeyValue } from '@angular/common'
import { Action, createReducer, on } from '@ngrx/store'
import * as NgrxForms from 'ngrx-forms'
import { formGroupReducer, markAsTouched, reset, setValue, SetValueAction } from 'ngrx-forms'
import { CycleMonthlyList } from '../redemption-settings/auto-redemption'
import * as CustomerManagementAction from './customer-management.actions'
import * as CustomerManagementState from './customer-management.state'

export const reducer = createReducer(
	CustomerManagementState.initialState,
	NgrxForms.onNgrxFormsAction(SetValueAction, (state, action) => {
		return actionHandler(state, action)
	}),
	NgrxForms.onNgrxFormsAction(NgrxForms.MarkAsTouchedAction, (state, action) => {
		return actionHandler(state, action)
	}),
	on(CustomerManagementAction.InitialState, () => CustomerManagementState.initialState),

	on(CustomerManagementAction.Reset, state => ({
		...state,
		customerManagementListForm: reset(setValue(state.customerManagementListForm, CustomerManagementState.initialCustomerManagementListFormValue)),
		catalogueRedemptionForm: reset(setValue(state.catalogueRedemptionForm, CustomerManagementState.initialCatalogueRedemptionFormValue)),
		waiverForm: reset(setValue(state.waiverForm, CustomerManagementState.initialWaiverFormValue)),
		partnerRedemptionForm: reset(setValue(state.partnerRedemptionForm, CustomerManagementState.initialPartnerRedemptionFormValue)),
		customerManagementPointsAdjustmentForm: reset(setValue(state.customerManagementPointsAdjustmentForm, CustomerManagementState.initialCustomerManagementPointsAdjustmentFormValue))
	})),
	on(CustomerManagementAction.SetId, (state, { id }) => ({
		...state,
		id
	})),
	on(CustomerManagementAction.SetTaskId, (state, { payload }) => ({
		...state,
		taskId: payload
	})),
	on(CustomerManagementAction.SetCardId, (state, { cardId }) => ({
		...state,
		cardId
	})),
	on(CustomerManagementAction.SetAccountId, (state, { accountId }) => ({
		...state,
		accountId
	})),
	on(CustomerManagementAction.SetRewardPoolId, (state, { rewardPoolId }) => ({
		...state,
		rewardPoolId
	})),
	on(CustomerManagementAction.SetRewardPoolGroupId, (state, { rewardPoolGroupId }) => ({
		...state,
		rewardPoolGroupId
	})),
	on(CustomerManagementAction.SetCustomerName, (state, { customerName }) => ({
		...state,
		customerName
	})),
	on(CustomerManagementAction.SetIcNumber, (state, { icNumber }) => ({
		...state,
		icNumber
	})),
	on(CustomerManagementAction.SetCardNumber, (state, { cardNumber }) => ({
		...state,
		cardNumber
	})),
	on(CustomerManagementAction.SetAccountNumber, (state, { accountNumber }) => ({
		...state,
		accountNumber
	})),
	on(CustomerManagementAction.SetSearchCustomerName, (state, { customerName }) => ({
		...state,
		searchCustomerName: customerName
	})),
	on(CustomerManagementAction.SetSearchIcNumber, (state, { icNumber }) => ({
		...state,
		searchIcNumber: icNumber
	})),
	on(CustomerManagementAction.SetSearchCardNumber, (state, { cardNumber }) => ({
		...state,
		searchCardNumber: cardNumber
	})),
	on(CustomerManagementAction.SetSearchAccountNumber, (state, { accountNumber }) => ({
		...state,
		searchAccountNumber: accountNumber
	})),
	on(CustomerManagementAction.SetRedemptionTransactionIdAndRedemptionType, (state, { redemptionTransactionId, redemptionType }) => ({
		...state,
		redemptionTransactionId,
		redemptionType
	})),
	on(CustomerManagementAction.SetConditionalRewardId, (state, { conditionalRewardId }) => ({
		...state,
		conditionalRewardId
	})),
	on(CustomerManagementAction.SetTotalPointsAndTotalAmount, (state, { totalPoints, totalAmount }) => ({
		...state,
		totalPoints,
		totalAmount
	})),
	on(CustomerManagementAction.SetResource, (state, { resource }) => ({
		...state,
		resource
	})),
	on(CustomerManagementAction.GoAccountAccumulationBucket, state => ({
		...state,
		rewardPage: false,
		accountPage: true
	})),
	on(CustomerManagementAction.GoRewardPoolGroupView, state => ({
		...state,
		rewardPoolId: null
	})),
	on(CustomerManagementAction.GoRewardPoolAccumulationBucket, state => ({
		...state,
		rewardPage: true,
		accountPage: false
	})),
	on(CustomerManagementAction.GoRewardPoolStatementView, state => ({
		...state,
		rewardPage: true,
		accountPage: false
	})),
	on(CustomerManagementAction.GoCardStatementView, state => ({
		...state,
		rewardPage: false,
		accountPage: false
	})),
	on(CustomerManagementAction.GoAccountStatementView, state => ({
		...state,
		rewardPage: false,
		accountPage: false
	})),
	on(CustomerManagementAction.GoCardView, state => ({
		...state,
		isCreditCard: true
	})),
	on(CustomerManagementAction.GoAccountView, state => ({
		...state,
		isCreditCard: false
	})),
	on(CustomerManagementAction.RestError, state => ({
		...state,
		isLoading: false,
		isTableLoading: false
	})),
	on(CustomerManagementAction.List, state => ({
		...state,
		isLoading: true
	})),
	on(CustomerManagementAction.ListSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		customerListResponse: payload
	})),
	on(CustomerManagementAction.ListPage, state => ({
		...state,
		isLoading: true
	})),
	on(CustomerManagementAction.View, state => ({
		...state,
		isLoading: true,
		customerViewResponse: {
			customerInformationList: [],
			creditCardList: [],
			depositList: [],
			rewardPoolGroupList: [],
			rewardPoolList: [],
			conditionalRewardList: []
		}
	})),
	on(CustomerManagementAction.ViewSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		customerViewResponse: payload
	})),
	on(CustomerManagementAction.CardView, state => ({
		...state,
		isLoading: true,
		customerCardViewResponse: {
			accountId: 0,
			accountNumber: '',
			accountStatus: '',
			accountBlockCode: '',
			cardNumber: '',
			cardName: '',
			cardType: '',
			cardExpiry: '',
			blockCode: '',
			billingCycle: 0,
			cardStatus: '',
			primaryOrSupplementaryFlag: {
				key: '',
				value: ''
			},
			cardOpenDate: '',
			cardJoinDate: '',
			cardIssueDate: '',
			cardActivationDate: '',
			resourceCode: '',
			previousStartDate: ''
		}
	})),
	on(CustomerManagementAction.CardViewSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		customerCardViewResponse: payload
	})),
	on(CustomerManagementAction.CardTransactionList, state => ({
		...state,
		isTableLoading: true
	})),
	on(CustomerManagementAction.CardTransactionListSuccess, (state, { payload }) => ({
		...state,
		isTableLoading: false,
		customerCardViewTransactionResponse: payload
	})),
	on(CustomerManagementAction.AccountViewSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		customerAccountViewResponse: payload
	})),
	on(CustomerManagementAction.InitialStatmentTransactionList, state => ({
		...state,
		isLoading: true
	})),
	on(CustomerManagementAction.AccountTransactionListByCycle, state => ({
		...state,
		isTableLoading: true
	})),
	on(CustomerManagementAction.AccountTransactionListSuccess, (state, { payload }) => ({
		...state,
		isTableLoading: false,
		customerAccountViewTransactionListResponse: payload
	})),
	on(CustomerManagementAction.AccountTransactionView, state => ({
		...state,
		isLoading: true
	})),
	on(CustomerManagementAction.AccountTransactionViewSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		customerAccountViewTransactionDetailResponse: payload
	})),
	on(CustomerManagementAction.RewardPoolView, state => ({
		...state,
		isLoading: true
	})),
	on(CustomerManagementAction.RewardPoolViewSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		customerRewardPoolViewResponse: payload
	})),
	on(CustomerManagementAction.RewardPoolInformation, state => ({
		...state,
		isLoading: true
	})),
	on(CustomerManagementAction.RewardPoolInformationSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		rewardPoolInformationList: payload
	})),
	on(CustomerManagementAction.AccumulationBucket, state => ({
		...state,
		isLoading: true
	})),
	on(CustomerManagementAction.AccumulationBucketSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		accumulationBucketList: payload
	})),
	on(CustomerManagementAction.GetProductCatalogueList, state => ({
		...state,
		isLoading: true,
		catalogueRedemptionForm: reset(setValue(state.catalogueRedemptionForm, CustomerManagementState.initialCatalogueRedemptionFormValue))
	})),
	on(CustomerManagementAction.GetProductCatalogueListSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		productCatalogueList: payload.productCatalogues.map(productCatalogue => (({
			keyValue: { key: productCatalogue.id.toString(), value: productCatalogue.name },
			id: productCatalogue.id,
			code: productCatalogue.code,
			name: productCatalogue.name,
			point: productCatalogue.point,
			amount: productCatalogue.amount,
			rewardPoolId: productCatalogue.rewardPoolId,
			rewardPoolType: productCatalogue.rewardPoolType,
			productCategory: productCatalogue.productCategory,
			kind: productCatalogue.kind,
			partnerCode: productCatalogue.partnerCode,
			redemptionType: productCatalogue.redemptionType,
			image: productCatalogue.image,
			imageFileName: productCatalogue.imageFileName,
			createdDate: productCatalogue.createdDate,
			modifiedDate: productCatalogue.modifiedDate
		})))
	})),
	on(CustomerManagementAction.SelectProductCatalogue, (state, { productCatalogue }) => ({
		...state,
		productCatalogue
	})),
	on(CustomerManagementAction.GetCustomerCardList, state => ({
		...state,
		isLoading: true
	})),
	on(CustomerManagementAction.GetCustomerCardListSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		customerCardList: payload.cardList
	})),
	on(CustomerManagementAction.GetCustomerAddressList, state => ({
		...state,
		isLoading: true
	})),
	on(CustomerManagementAction.GetCustomerAddressListSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		customerAddressList: [ ...payload.addressList, {accountType: 'Others', address1: '', address2: '', address3: '', city: '', state: '', postcode: '', emailAddress: ''} ]
	})),
	on(CustomerManagementAction.SetCatalogueRedemptionAddress, (state, { address1, address2, address3, city, addressState, postcode, emailAddress}) => ({
		...state,
		catalogueRedemptionForm: CustomerManagementState.validateCatalogueRedemptionForm(state)(setValue(state.catalogueRedemptionForm, {
			cardId: state.catalogueRedemptionForm.value.cardId,
			accountType: state.catalogueRedemptionForm.value.accountType,
			quantity: state.catalogueRedemptionForm.value.quantity,
			address1,
			address2,
			address3,
			city,
			state: addressState,
			postcode,
			emailAddress,
			airmileId: state.catalogueRedemptionForm.value.airmileId,
			totalPoint: state.catalogueRedemptionForm.value.totalPoint,
			value: state.catalogueRedemptionForm.value.value,
		})),
	})),
	on(CustomerManagementAction.CreateCatalogueRedemption, state => ({
		...state,
		isLoading: true
	})),
	on(CustomerManagementAction.CreateCatalogueRedemptionSuccess, state => ({
		...state,
		isLoading: false
	})),
	on(CustomerManagementAction.CardStatmentTransactionList, state => ({
		...state,
		isLoading: true
	})),
	on(CustomerManagementAction.RewardPoolStatmentTransactionList, state => ({
		...state,
		isLoading: true
	})),
	on(CustomerManagementAction.StatementTransactionListSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		customerStatementTransactionListResponse: payload
	})),
	on(CustomerManagementAction.GetWaiverTypeList, state => ({
		...state,
		isLoading: true,
		waiverTypeList: [],
		waiverList: [],
		waiverForm: reset(setValue(state.waiverForm, CustomerManagementState.initialWaiverFormValue))
	})),
	on(CustomerManagementAction.GetWaiverTypeListSuccess, (state, {payload }) => ({
		...state,
		isLoading: false,
		waiverTypeList: payload.waiverTypes.map(waiverType => (({
			keyValue: { key: waiverType.id.toString(), value: waiverType.name },
			id: waiverType.id,
			code: waiverType.code,
			name: waiverType.name
		})))
	})),
	on(CustomerManagementAction.ClearWaiverList, state => ({
		...state,
		waiverList: []
	})),
	on(CustomerManagementAction.GetWaiverList, state => ({
		...state,
		isLoading: true,
		waiverList: [],
		waiverForm: CustomerManagementState.validateWaiverForm(setValue(state.waiverForm, {
			waiverTypeId: state.waiverForm.value.waiverTypeId,
			waiverId: undefined
		})),
	})),
	on(CustomerManagementAction.GetWaiverListSuccess, (state, {payload }) => ({
		...state,
		isLoading: false,
		waiverList: payload.waivers.map(waiver => (({
			keyValue: { key: waiver.id.toString(), value: waiver.name },
			id: waiver.id,
			code: waiver.code,
			name: waiver.name,
			points: waiver.points
		})))
	})),
	on(CustomerManagementAction.CreateWaiver, state => ({
		...state,
		isLoading: true
	})),
	on(CustomerManagementAction.CreateWaiverSuccess, state => ({
		...state,
		isLoading: false
	})),
	on(CustomerManagementAction.GetRedemptionHistoryList, state => ({
		...state,
		isLoading: true
	})),
	on(CustomerManagementAction.GetRedemptionHistoryListSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		customerRedemptionHistoryListResponse: payload
	})),
	on(CustomerManagementAction.CatalogueRedemptionOrderView, state => ({
		...state,
		isLoading: true,
		customerCatalogueRedemptionOrderViewResponse: CustomerManagementState.initialCatalogueRedemptionOrderFormValue
	})),
	on(CustomerManagementAction.CatalogueRedemptionOrderViewSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		customerCatalogueRedemptionOrderViewResponse: payload
	})),
	on(CustomerManagementAction.CatalogueRedemptionOrderWithdrawal, state => ({
		...state,
		isLoading: true,
	})),
	on(CustomerManagementAction.CatalogueRedemptionOrderWithdrawalSuccess, state => ({
		...state,
		isLoading: false,
	})),
	on(CustomerManagementAction.SetCatalogueRedemptionId, (state, { catalogueRedemptionId }) => ({
		...state,
		catalogueRedemptionId
	})),
	on(CustomerManagementAction.PartnerRedemptionOrderView, state => ({
		...state,
		isLoading: true
	})),
	on(CustomerManagementAction.PartnerRedemptionOrderViewSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		customerPartnerRedemptionOrderViewResponse: payload
	})),
	on(CustomerManagementAction.GetPartnerRedemptionList, state => ({
		...state,
		isLoading: true,
		partnerRedemptionForm: reset(setValue(state.partnerRedemptionForm, CustomerManagementState.initialPartnerRedemptionFormValue))

	})),
	on(CustomerManagementAction.GetPartnerRedemptionListSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		partnerRedemptionList: payload.partnerRedemptionList.map(partnerRedemption => (({
			name: partnerRedemption.name,
			point: partnerRedemption.point,
			value: partnerRedemption.value,
			type: partnerRedemption.type,
			id: partnerRedemption.id,
			partnerCode: partnerRedemption.partnerCode,
			rounding: partnerRedemption.rounding,
			roundingValue: partnerRedemption.roundingValue,
			numberDecimal: partnerRedemption.numberDecimal,
			numberDecimalValue: partnerRedemption.numberDecimalValue,
			rewardId: partnerRedemption.rewardId
		}))),
		partnerRedemptionKeyValueList: (payload.partnerRedemptionList || []).map(i => ({ key: `${i.id}`, value: `${i.name}` })) as KeyValue<string, string>[]

	})),
	on(CustomerManagementAction.SetPointAndValueAndTypeAndRewardId, (state, { point, value, redemptionType, rewardId}) => ({
		...state,
		point,
		value,
		redemptionType,
		rewardId,
		partnerRedemptionForm: CustomerManagementState.validatePartnerRedemptionForm(state)(state.partnerRedemptionForm)
	})),
	on(CustomerManagementAction.SetPointResetValueAndAirmileId, (state, { point }) => ({
		...state,
		point,
		value: undefined,
		partnerRedemptionForm: setValue(state.partnerRedemptionForm, {
			airmileId: '',
			name: state.partnerRedemptionForm.value.name,
			points: undefined,
			values: undefined
		}),
	})),
	on(CustomerManagementAction.ResetPointAndValueAndNameAndAirmileId, state => ({
		...state,
		point: undefined,
		value: undefined,
		partnerRedemptionForm: setValue(state.partnerRedemptionForm, {
			airmileId: '',
			name: '',
			points: undefined,
			values: undefined
		}),
	})),
	on(CustomerManagementAction.CreatePartnerRedemption, state => ({
		...state,
		isLoading: true
	})),
	on(CustomerManagementAction.CreatePartnerRedemptionSuccess, state => ({
		...state,
		isLoading: false
	})),
	on(CustomerManagementAction.CreateCataloguePartnerRedemption, state => ({
		...state,
		isLoading: true
	})),
	on(CustomerManagementAction.CreateCataloguePartnerRedemptionSuccess, state => ({
		...state,
		isLoading: false
	})),
	on(CustomerManagementAction.GetPoBoxList, state => ({
		...state,
		isLoading: true
	})),
	on(CustomerManagementAction.GetPoBoxListSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		poboxList: payload
	})),
	on(CustomerManagementAction.SetCustomerPartnerRdemptionListData, (state, { customerPartnerRedemptionListData }) => ({
		...state,
		isLoading: false,
		partnerRedemption: customerPartnerRedemptionListData,
		partnerRedemptionForm: setValue(state.partnerRedemptionForm, {
			airmileId: '',
			name: state.partnerRedemptionForm.value.name,
			points: undefined,
			values: undefined
		}),
	})),
	on(CustomerManagementAction.SetStatementStartDateAndEndDate, (state, { startDate, endDate }) => ({
		...state,
		startDate,
		endDate
	})),
	on(CustomerManagementAction.GetCardStatementTransactionListByCycle, state => ({
		...state,
		isTableLoading: true
	})),
	on(CustomerManagementAction.GetCardStatementTransactionListByCycleSuccess, (state, { payload }) => ({
		...state,
		isTableLoading: false,
		customerCardViewTransactionResponse: payload
	})),
	on(CustomerManagementAction.GetCardStatementTransactionOptionList, state => ({
		...state,
		isTableLoading: true
	})),
	on(CustomerManagementAction.GetCardStatementTransactionOptionListSuccess, (state, { payload }) => ({
		...state,
		isTableLoading: false,
		cardTransactionOptionList: payload
	})),
	on(CustomerManagementAction.Submit, state => ({
		...state,
		isLoading: true
	})),
	on(CustomerManagementAction.SubmitSuccess, (state, { payload }) => ({
		...state,
		isLoading: false
	})),
	on(CustomerManagementAction.GoPointsAdjustment, state => ({
		...state,
		customerManagementPointsAdjustmentForm: reset(setValue(state.customerManagementPointsAdjustmentForm, CustomerManagementState.initialCustomerManagementPointsAdjustmentFormValue))
	})),
	on(CustomerManagementAction.GoPointsAdjustmentView, state => ({
		...state,
		customerManagementPointsAdjustmentForm: reset(setValue(state.customerManagementPointsAdjustmentForm, CustomerManagementState.initialCustomerManagementPointsAdjustmentFormValue))
	})),
	on(CustomerManagementAction.GoCatalogueRedemptionDetail, state => ({
		...state,
		catalogueRedemptionForm: reset(setValue(state.catalogueRedemptionForm, CustomerManagementState.initialCatalogueRedemptionFormValue))
	})),
	on(CustomerManagementAction.GetPointsAdjustmentDetails, state => ({
		...state,
		isLoading: true,
		customerManagementPointsAdjustmentForm: setValue(state.customerManagementPointsAdjustmentForm, CustomerManagementState.initialCustomerManagementPointsAdjustmentFormValue),
	})),
	on(CustomerManagementAction.GetPointsAdjustmentDetailsSuccess, (state, { payload }) => ({
			...state,
			isLoading: false,
			customerManagementPointsAdjustmentForm: setValue(state.customerManagementPointsAdjustmentForm, {
				cardNumber: payload.cardNumber,
				accountNumber: payload.accountNumber,
				rewardPool: payload.rewardPoolName,
				adjustmentType: payload.adjustmentType,
				pointsAdjustment: payload.adjustmentPoints
			} as CustomerManagementState.CustomerManagementPointsAdjustmentForm)

	})),
	on(CustomerManagementAction.AdjustmentTypeList, state => ({
		...state,
		isLoading: true
	})),
	on(CustomerManagementAction.AdjustmentTypeListSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		adjustmentTypeList: payload.adjustmentTypeList
	})),
	on(CustomerManagementAction.ConditionalRewardView, state => ({
		...state,
		isLoading: true
	})),
	on(CustomerManagementAction.ConditionalRewardViewSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		customerConditionalRewardViewResponse: payload
	})),
	on(CustomerManagementAction.CardTransactionView, state => ({
		...state,
		isLoading: true
	})),
	on(CustomerManagementAction.CardTransactionViewSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		customerTransactionList: payload
	})),
	on(CustomerManagementAction.RewardPoolList, state => ({
		...state,
		isLoading: true
	})),
	on(CustomerManagementAction.RewardPoolListSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		rewardPoolListResponse: payload
	})),
	on(CustomerManagementAction.GetAccumulatingTransactions, state => ({
		...state,
		isLoading: true
	})),
	on(CustomerManagementAction.GetAccumulatingTransactionsSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		accumulatingTransactionList: payload,
		accumulatingTransactionOptionList: payload
			.reduce((acc, x) => [...new Set([...acc, ...x.transactionList
				.map(o => o.campaignName)])], ['All'])
			.map(k => ({ key: k, value: k }))
	}))
)

export function Reducer(state: CustomerManagementState.State = CustomerManagementState.initialState, action: Action) {
	const customerManagementListForm = CustomerManagementState.validateBasicInfoListForm(formGroupReducer(state.customerManagementListForm, action))
	if (customerManagementListForm !== state.customerManagementListForm) {
		state = { ...state, customerManagementListForm }
	}

	const catalogueRedemptionForm = CustomerManagementState.validateCatalogueRedemptionForm(state)(formGroupReducer(state.catalogueRedemptionForm, action))
	if (catalogueRedemptionForm !== state.catalogueRedemptionForm) {
		state = { ...state, catalogueRedemptionForm }
	}

	const waiverForm = CustomerManagementState.validateWaiverForm(formGroupReducer(state.waiverForm, action))
	if (waiverForm !== state.waiverForm) {
		state = { ...state, waiverForm }
	}

	const partnerRedemptionForm = CustomerManagementState.validatePartnerRedemptionForm(state)(formGroupReducer(state.partnerRedemptionForm, action))
	if (partnerRedemptionForm !== state.partnerRedemptionForm) {
		state = { ...state, partnerRedemptionForm }
	}

	return reducer(state, action)
}

function validateForm(form: any) {
	form = markAsTouched(form)

	return CustomerManagementState.validateCatalogueRedemptionForm(form)
}

function validatePartnerRedemptionForm(form: any) {
	form = markAsTouched(form)

	return CustomerManagementState.validatePartnerRedemptionForm(form)
}

function actionHandler(state: CustomerManagementState.State, action: SetValueAction<unknown> | NgrxForms.MarkAsTouchedAction) {
	const formControls = state.catalogueRedemptionForm
	const formControlsForPartnerRedemption = state.partnerRedemptionForm
	const formControlsForPointsAdjustment = state.customerManagementPointsAdjustmentForm

	let form = formGroupReducer(formControls, action)
	let formForPartnerRedemption = formGroupReducer(formControlsForPartnerRedemption, action)
	let pointsAdjustmentForm = formGroupReducer(formControlsForPointsAdjustment, action)

	if (action.type === 'ngrx/forms/SET_VALUE') {
		form = formGroupReducer(formControls, new SetValueAction(action.controlId, action.value))
		formForPartnerRedemption = formGroupReducer(formControlsForPartnerRedemption, new SetValueAction(action.controlId, action.value))
		pointsAdjustmentForm = formGroupReducer(formControlsForPointsAdjustment, new SetValueAction(action.controlId, action.value))

	}

	if (form) {
		form = validateForm(state)(form)
		formForPartnerRedemption = markAsTouched(validatePartnerRedemptionForm(state)(formForPartnerRedemption))
		pointsAdjustmentForm = CustomerManagementState.validateCustomerManagementPointsAdjustmentForm(pointsAdjustmentForm)

		state = {
			...state,
			catalogueRedemptionForm: form,
			partnerRedemptionForm: formForPartnerRedemption,
			customerManagementPointsAdjustmentForm: pointsAdjustmentForm
		}
	}
	return state
}
