import { Action, createReducer, on } from '@ngrx/store'
import { formGroupReducer, reset, setValue, SetValueAction } from 'ngrx-forms'
import * as DashboardAction from './dashboard-overview.actions'
import * as DashboardState from './dashboard-overview.state'
import * as NgrxForms from 'ngrx-forms'
import * as ActionbarWithSelectionAction from 'src/app/store/common/action-bar-with-selection/action-bar-with-selection.actions'
import { Util } from 'src/app/models/util/util'

export const reducer = createReducer(
	DashboardState.initialState,
	on(DashboardAction.InitialState, () => DashboardState.initialState),
	on(DashboardAction.RestError, state => ({
		...state,
		isLoading: false,
		isTableLoading: false
	})),
	on(DashboardAction.ViewRewardPoolGroupOverview, state => ({
		...state,
		rewardPoolGroupIsLoading: true
	})),
	on(DashboardAction.ViewRewardPoolGroupOverviewSuccess, (state, { payload }) => ({
		...state,
		rewardPoolGroupIsLoading: false,
		rewardPoolGroupOverviewViewResponse: payload
	})),
	on(DashboardAction.ViewRewardPoolOverview, state => ({
		...state,
		rewardPoolIsLoading: true
	})),
	on(DashboardAction.ViewRewardPoolOverviewSuccess, (state, { payload }) => ({
		...state,
		rewardPoolIsLoading: false,
		rewardPoolOverviewViewResponse: payload
	})),
	on(DashboardAction.ViewCampaignOverview, state => ({
		...state,
		campaignIsLoading: true
	})),
	on(DashboardAction.ViewCampaignOverviewSuccess, (state, { payload }) => ({
		...state,
		campaignIsLoading: false,
		campaignOverviewViewResponse: payload,
		totalPoints: payload.totalPoints
	})),
	on(DashboardAction.ViewCampaignsListOverview, state => ({
		...state,
		campaignListIsLoading: true
	})),
	on(DashboardAction.ViewCampaignsListOverviewSuccess, (state, { payload }) => ({
		...state,
		campaignListIsLoading: false,
		campaignsOverviewListResponse: payload
	})),
	on(DashboardAction.DashboardOptionList, state => ({
		...state,
		rewardPoolIsLoading: true,
		rewardPoolGroupIsLoading: true
	})),
	on(DashboardAction.DashboardOptionListSuccess, (state, { payload }) => ({
		...state,
		rewardPoolIsLoading: false,
		rewardPoolGroupIsLoading: false,
		monthList: payload.monthList,
		dashboardRequestForm: setValue(state.dashboardRequestForm, {
			customRewardPoolStartDate: state.dashboardRequestForm.controls.customRewardPoolStartDate.value,
			rewardPoolStartDate: state.dashboardRequestForm.controls.rewardPoolStartDate.value,
			rewardPoolEndDate: payload.monthList[payload.monthList.length - 1].key,
			customStartDate: state.dashboardRequestForm.controls.customStartDate.value,
			startDate: state.dashboardRequestForm.controls.startDate.value,
			endDate: payload.monthList[payload.monthList.length - 1].key,
			campaignId: state.dashboardRequestForm.controls.campaignId.value,
			customCampaignStartDate: state.dashboardRequestForm.controls.campaignStartDate.value,
			campaignStartDate: state.dashboardRequestForm.controls.campaignStartDate.value,
			campaignEndDate: payload.monthList[payload.monthList.length - 1].key
		} as DashboardState.DashboardViewRequestForm)
	})),
	on(DashboardAction.DashboardRangeOptionList, state => ({
		...state,
		rewardPoolIsLoading: true,
		rewardPoolGroupIsLoading: true
	})),
	on(DashboardAction.DashboardRangeOptionListSuccess, (state, { payload }) => ({
		...state,
		rewardPoolIsLoading: false,
		rewardPoolGroupIsLoading: false,
		rangeList: payload.rangeList,
		campaignRangeList: payload.rangeList,
		dashboardRequestForm: setValue(state.dashboardRequestForm, {
			customRewardPoolStartDate: payload.rangeList[0].key,
			rewardPoolStartDate: payload.rangeList[0].key,
			rewardPoolEndDate: state.dashboardRequestForm.controls.rewardPoolEndDate.value,
			customStartDate: payload.rangeList[0].key,
			startDate: payload.rangeList[0].key,
			endDate: state.dashboardRequestForm.controls.endDate.value,
			campaignId: state.dashboardRequestForm.controls.campaignId.value,
			customCampaignStartDate: payload.rangeList[0].key,
			campaignStartDate: payload.rangeList[0].key,
			campaignEndDate: state.dashboardRequestForm.controls.campaignEndDate.value
		} as DashboardState.DashboardViewRequestForm)
	})),
	on(DashboardAction.CampaignRangeOptionList, state => ({
		...state,
		campaignIsLoading: true
	})),
	on(DashboardAction.CampaignRangeOptionListSuccess, (state, { payload }) => ({
		...state,
		campaignIsLoading: false,
		campaignRangeList: payload.rangeList,
		dashboardRequestForm: setValue(state.dashboardRequestForm, {
			customRewardPoolStartDate: payload.rangeList.find(o => o.key === state.dashboardRequestForm.controls.customRewardPoolStartDate.value || state.dashboardRequestForm.controls.rewardPoolStartDate.value === 'custom') ?
				state.dashboardRequestForm.controls.customRewardPoolStartDate.value : payload.rangeList[0].key,
			rewardPoolStartDate: payload.rangeList.find(o => o.key === state.dashboardRequestForm.controls.rewardPoolStartDate.value || state.dashboardRequestForm.controls.rewardPoolStartDate.value === 'custom') ?
				state.dashboardRequestForm.controls.rewardPoolStartDate.value : payload.rangeList[0].key,
			rewardPoolEndDate: state.dashboardRequestForm.controls.rewardPoolEndDate.value,
			customStartDate: payload.rangeList.find(o => o.key === state.dashboardRequestForm.controls.customStartDate.value || state.dashboardRequestForm.controls.startDate.value === 'custom') ?
				state.dashboardRequestForm.controls.customStartDate.value : payload.rangeList[0].key,
			startDate: payload.rangeList.find(o => o.key === state.dashboardRequestForm.controls.startDate.value || state.dashboardRequestForm.controls.startDate.value === 'custom') ?
				state.dashboardRequestForm.controls.startDate.value : payload.rangeList[0].key,
			endDate: state.dashboardRequestForm.controls.endDate.value,
			campaignId: state.dashboardRequestForm.controls.campaignId.value,
			customCampaignStartDate: payload.rangeList.find(o => o.key === state.dashboardRequestForm.controls.customCampaignStartDate.value || state.dashboardRequestForm.controls.campaignStartDate.value === 'custom') ?
				state.dashboardRequestForm.controls.customCampaignStartDate.value : payload.rangeList[0].key,
			campaignStartDate: payload.rangeList.find(o => o.key === state.dashboardRequestForm.controls.campaignStartDate.value || state.dashboardRequestForm.controls.campaignStartDate.value === 'custom') ?
				state.dashboardRequestForm.controls.campaignStartDate.value : payload.rangeList[0].key,
			campaignEndDate: state.dashboardRequestForm.controls.campaignEndDate.value
		} as DashboardState.DashboardViewRequestForm)
	})),
	on(DashboardAction.CampaignOptionList, state => ({
		...state,
		campaignIsLoading: true
	})),
	on(DashboardAction.CampaignOptionListSuccess, (state, { payload }) => ({
		...state,
		campaignIsLoading: false,
		campaignList: payload.campaignList,
		dashboardRequestForm: setValue(state.dashboardRequestForm, {
			customRewardPoolStartDate: state.dashboardRequestForm.controls.customRewardPoolStartDate.value,
			rewardPoolStartDate: state.dashboardRequestForm.controls.rewardPoolStartDate.value,
			rewardPoolEndDate: state.dashboardRequestForm.controls.rewardPoolEndDate.value,
			customStartDate: state.dashboardRequestForm.controls.customStartDate.value,
			startDate: state.dashboardRequestForm.controls.startDate.value,
			endDate: state.dashboardRequestForm.controls.endDate.value,
			campaignId: !!payload.campaignList.length ? payload.campaignList[0].key : 0,
			customCampaignStartDate: state.dashboardRequestForm.controls.customCampaignStartDate.value,
			campaignStartDate: state.dashboardRequestForm.controls.campaignStartDate.value,
			campaignEndDate: state.dashboardRequestForm.controls.campaignEndDate.value
		} as DashboardState.DashboardViewRequestForm)
	})),
	on(DashboardAction.DashboardOverviewChannelOptionList, state => ({
		...state,
		isLoading: true
	})),
	on(ActionbarWithSelectionAction.DashboardOverviewOptionList, (state, { payload }) => ({
		...state,
		isLoading: false,
		resource: payload[0].key
	})),
	on(ActionbarWithSelectionAction.DashboardRewardPoolOverviewOptionList, (state, { payload }) => ({
		...state,
		isLoading: false,
		resource: payload[0].key
	})),
	on(DashboardAction.SetDashboardResource, (state, { resource }) => ({
		...state,
		resource
	})),
	on(DashboardAction.RewardPoolGroupOptionList, state => ({
		...state,
		rewardPoolGroupIsLoading: true
	})),
	on(DashboardAction.RewardPoolGroupOptionListSuccess, (state, { payload }) => ({
		...state,
		rewardPoolGroupIsLoading: false,
		monthList: payload.monthList,
		dashboardRequestForm: setValue(state.dashboardRequestForm, {
			customRewardPoolStartDate: state.dashboardRequestForm.controls.customRewardPoolStartDate.value,
			rewardPoolStartDate: state.dashboardRequestForm.controls.rewardPoolStartDate.value,
			rewardPoolEndDate: state.dashboardRequestForm.controls.rewardPoolEndDate.value,
			customStartDate: state.dashboardRequestForm.controls.customStartDate.value,
			startDate: state.dashboardRequestForm.controls.startDate.value,
			endDate: payload.monthList[payload.monthList.length - 1].key,
			campaignId: state.dashboardRequestForm.controls.campaignId.value,
			campaignStartDate: state.dashboardRequestForm.controls.campaignStartDate.value,
			campaignEndDate: state.dashboardRequestForm.controls.campaignEndDate.value
		} as DashboardState.DashboardViewRequestForm)
	})),
	on(DashboardAction.RewardPoolGroupRangeOptionList, state => ({
		...state,
		rewardPoolGroupIsLoading: true
	})),
	on(DashboardAction.RewardPoolGroupRangeOptionListSuccess, (state, { payload }) => ({
		...state,
		rewardPoolGroupIsLoading: false,
		rangeList: payload.rangeList,
		dashboardRequestForm: setValue(state.dashboardRequestForm, {
			customRewardPoolStartDate: payload.rangeList.find(o => o.key === state.dashboardRequestForm.controls.customRewardPoolStartDate.value || state.dashboardRequestForm.controls.rewardPoolStartDate.value === 'custom') ?
				state.dashboardRequestForm.controls.customRewardPoolStartDate.value : payload.rangeList[0].key,
			rewardPoolStartDate: payload.rangeList.find(o => o.key === state.dashboardRequestForm.controls.rewardPoolStartDate.value || state.dashboardRequestForm.controls.rewardPoolStartDate.value === 'custom') ?
				state.dashboardRequestForm.controls.rewardPoolStartDate.value : payload.rangeList[0].key,
			rewardPoolEndDate: state.dashboardRequestForm.controls.rewardPoolEndDate.value,
			customStartDate: payload.rangeList.find(o => o.key === state.dashboardRequestForm.controls.customStartDate.value || state.dashboardRequestForm.controls.startDate.value === 'custom') ?
				state.dashboardRequestForm.controls.customStartDate.value : payload.rangeList[0].key,
			startDate: payload.rangeList.find(o => o.key === state.dashboardRequestForm.controls.startDate.value || state.dashboardRequestForm.controls.startDate.value === 'custom') ?
				state.dashboardRequestForm.controls.startDate.value : payload.rangeList[0].key,
			endDate: state.dashboardRequestForm.controls.endDate.value,
			campaignId: state.dashboardRequestForm.controls.campaignId.value,
			campaignStartDate: state.dashboardRequestForm.controls.campaignStartDate.value,
			campaignEndDate: state.dashboardRequestForm.controls.campaignEndDate.value
		} as DashboardState.DashboardViewRequestForm)
	})),
	on(DashboardAction.RewardPoolAndGroupRangeOptionList, state => ({
		...state,
		rewardPoolGroupIsLoading: true,
		rewardPoolIsLoading: true
	})),
	on(DashboardAction.RewardPoolAndGroupRangeOptionListSuccess, (state, { payload }) => ({
		...state,
		rewardPoolGroupIsLoading: false,
		rewardPoolIsLoading: true,
		rangeList: payload.rangeList,
		dashboardRequestForm: setValue(state.dashboardRequestForm, {
			startDate: payload.rangeList.find(o => o.key === state.dashboardRequestForm.controls.startDate.value) ? state.dashboardRequestForm.controls.startDate.value : payload.rangeList[0].key,
			endDate: state.dashboardRequestForm.controls.endDate.value,
			campaignId: state.dashboardRequestForm.controls.campaignId.value,
			campaignStartDate: state.dashboardRequestForm.controls.campaignStartDate.value,
			campaignEndDate: state.dashboardRequestForm.controls.campaignEndDate.value
		} as DashboardState.DashboardViewRequestForm)
	})),
	on(DashboardAction.RewardPoolOptionList, state => ({
		...state,
		rewardPoolIsLoading: true
	})),
	on(DashboardAction.RewardPoolOptionListSuccess, (state, { payload }) => ({
		...state,
		rewardPoolIsLoading: false,
		monthList: payload.monthList,
		dashboardRequestForm: setValue(state.dashboardRequestForm, {
			customRewardPoolStartDate: state.dashboardRequestForm.controls.customRewardPoolStartDate.value,
			rewardPoolStartDate: state.dashboardRequestForm.controls.rewardPoolStartDate.value,
			rewardPoolEndDate: payload.monthList[payload.monthList.length - 1].key,
			customStartDate: state.dashboardRequestForm.controls.customStartDate.value,
			startDate: state.dashboardRequestForm.controls.startDate.value,
			endDate: payload.monthList[payload.monthList.length - 1].key,
			campaignId: state.dashboardRequestForm.controls.campaignId.value,
			customCampaignStartDate: state.dashboardRequestForm.controls.customCampaignStartDate.value,
			campaignStartDate: state.dashboardRequestForm.controls.campaignStartDate.value,
			campaignEndDate: payload.monthList[payload.monthList.length - 1].key
		} as DashboardState.DashboardViewRequestForm)
	})),
	on(DashboardAction.RewardPoolRangeOptionList, state => ({
		...state,
		rewardPoolGroupIsLoading: true
	})),
	on(DashboardAction.RewardPoolRangeOptionListSuccess, (state, { payload }) => ({
		...state,
		rewardPoolGroupIsLoading: false,
		rangeList: payload.rangeList,
		campaignRangeList: payload.rangeList,
		dashboardRequestForm: setValue(state.dashboardRequestForm, {
			customRewardPoolStartDate: payload.rangeList.find(o => o.key === state.dashboardRequestForm.controls.customRewardPoolStartDate.value || state.dashboardRequestForm.controls.rewardPoolStartDate.value === 'custom') ?
				state.dashboardRequestForm.controls.customRewardPoolStartDate.value : payload.rangeList[0].key,
			rewardPoolStartDate: payload.rangeList.find(o => o.key === state.dashboardRequestForm.controls.rewardPoolStartDate.value || state.dashboardRequestForm.controls.rewardPoolStartDate.value === 'custom') ?
				state.dashboardRequestForm.controls.rewardPoolStartDate.value : payload.rangeList[0].key,
			rewardPoolEndDate: state.dashboardRequestForm.controls.rewardPoolEndDate.value,
			customStartDate: payload.rangeList.find(o => o.key === state.dashboardRequestForm.controls.customStartDate.value || state.dashboardRequestForm.controls.startDate.value === 'custom') ?
				state.dashboardRequestForm.controls.customStartDate.value : payload.rangeList[0].key,
			startDate: payload.rangeList.find(o => o.key === state.dashboardRequestForm.controls.startDate.value || state.dashboardRequestForm.controls.startDate.value === 'custom') ?
				state.dashboardRequestForm.controls.startDate.value : payload.rangeList[0].key,
			endDate: state.dashboardRequestForm.controls.endDate.value,
			campaignId: state.dashboardRequestForm.controls.campaignId.value,
			customCampaignStartDate: payload.rangeList.find(o => o.key === state.dashboardRequestForm.controls.customCampaignStartDate.value || state.dashboardRequestForm.controls.campaignStartDate.value === 'custom') ?
				state.dashboardRequestForm.controls.customCampaignStartDate.value : payload.rangeList[0].key,
			campaignStartDate: payload.rangeList.find(o => o.key === state.dashboardRequestForm.controls.campaignStartDate.value || state.dashboardRequestForm.controls.campaignStartDate.value === 'custom') ?
				state.dashboardRequestForm.controls.campaignStartDate.value : payload.rangeList[0].key,
			campaignEndDate: state.dashboardRequestForm.controls.campaignEndDate.value
		} as DashboardState.DashboardViewRequestForm)
	})),
	on(DashboardAction.RewardPoolOnlyRangeOptionList, state => ({
		...state,
		rewardPoolIsLoading: true
	})),
	on(DashboardAction.RewardPoolOnlyRangeOptionListSuccess, (state, { payload }) => ({
		...state,
		rewardPoolIsLoading: false,
		rangeList: payload.rangeList,
		dashboardRequestForm: setValue(state.dashboardRequestForm, {
			customRewardPoolStartDate: payload.rangeList.find(o => o.key === state.dashboardRequestForm.controls.customRewardPoolStartDate.value || state.dashboardRequestForm.controls.rewardPoolStartDate.value === 'custom') ?
				state.dashboardRequestForm.controls.customRewardPoolStartDate.value : payload.rangeList[0].key,
			rewardPoolStartDate: payload.rangeList.find(o => o.key === state.dashboardRequestForm.controls.rewardPoolStartDate.value || state.dashboardRequestForm.controls.rewardPoolStartDate.value === 'custom') ?
				state.dashboardRequestForm.controls.rewardPoolStartDate.value : payload.rangeList[0].key,
			rewardPoolEndDate: state.dashboardRequestForm.controls.rewardPoolEndDate.value,
			customStartDate: state.dashboardRequestForm.controls.customStartDate.value,
			startDate: state.dashboardRequestForm.controls.startDate.value,
			endDate: state.dashboardRequestForm.controls.endDate.value,
			campaignId: state.dashboardRequestForm.controls.campaignId.value,
			customCampaignStartDate: state.dashboardRequestForm.controls.customCampaignStartDate.value,
			campaignStartDate: state.dashboardRequestForm.controls.campaignStartDate.value,
			campaignEndDate: state.dashboardRequestForm.controls.campaignEndDate.value
		} as DashboardState.DashboardViewRequestForm)
	})),
	on(DashboardAction.InitialCampaignEndDateOptionList, state => ({
		...state,
		campaignIsLoading: true
	})),
	on(DashboardAction.InitialCampaignEndDateOptionListSuccess, (state, { payload }) => ({
		...state,
		campaignIsLoading: false,
		monthList: payload.monthList,
		dashboardRequestForm: setValue(state.dashboardRequestForm, {
			startDate: state.dashboardRequestForm.controls.startDate.value,
			endDate: state.dashboardRequestForm.controls.endDate.value,
			campaignId: state.dashboardRequestForm.controls.campaignId.value,
			campaignStartDate: state.dashboardRequestForm.controls.campaignStartDate.value,
			campaignEndDate: payload.monthList[payload.monthList.length - 1].key
		} as DashboardState.DashboardViewRequestForm)
	})),
	on(DashboardAction.InitialCampaignRangeOptionList, state => ({
		...state,
		campaignIsLoading: true
	})),
	on(DashboardAction.InitialCampaignRangeOptionListSuccess, (state, { payload }) => ({
		...state,
		campaignIsLoading: false,
		campaignRangeList: payload.rangeList,
		dashboardRequestForm: setValue(state.dashboardRequestForm, {
			startDate: state.dashboardRequestForm.controls.startDate.value,
			endDate: state.dashboardRequestForm.controls.endDate.value,
			campaignId: state.dashboardRequestForm.controls.campaignId.value,
			customCampaignStartDate: payload.rangeList[0].key,
			campaignStartDate: payload.rangeList[0].key,
			campaignEndDate: state.dashboardRequestForm.controls.campaignEndDate.value
		} as DashboardState.DashboardViewRequestForm)
	}))
)

export function Reducer(state: DashboardState.State = DashboardState.initialState, action: Action) {
	const dashboardRequestForm = DashboardState.validateDashboardViewRequestForm(state)(formGroupReducer(state.dashboardRequestForm, action))
	if (dashboardRequestForm !== state.dashboardRequestForm) {
		state = { ...state, dashboardRequestForm }
	}
	return reducer(state, action)
}
