import { trigger, state, style, transition, animate } from '@angular/animations'
import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { MatPaginator, MatTableDataSource } from '@angular/material'
import { Subscription } from 'rxjs'
import { CampaignInfoDataColumns, ColumnField, RuleDetails, RulesetDetailDataColumns } from 'src/app/models/campaign-management/campaign/campaign'
import * as CampaignSelectors from 'src/app/store/campaign-management/campaign/campaign.selectors'
import * as AppStore from 'src/app/store'
import { Store } from '@ngrx/store'
import { FilterPredicate } from 'src/app/models/util/filter-predicate'
import { KeyValue } from '@angular/common'

@Component({
	selector: 'app-campaign-worklist-conditional-rules-detail',
	templateUrl: './campaign-worklist-conditional-rules-detail.component.html',
	styleUrls: ['./campaign-worklist-conditional-rules-detail.component.scss'],
	animations: [
		trigger('detailExpand', [
			state('collapsed', style({ height: '0px', minHeight: '0' })),
			state('expanded', style({ height: '*' })),
			transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
			state('collapsed1', style({ transform: 'rotate(360deg)' })),
			state('expanded1', style({ transform: 'rotate(180deg)' })),
			transition('expanded1 <=> collapsed1',
				animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
			)
		])
	],
})
export class CampaignWorklistConditionalRulesDetailComponent implements OnInit {

	@Input() rulesetName: string
	@Input() conditionalRules: RuleDetails[]
	@Input() index: number

	subs: Subscription

	displayedColumns: CampaignInfoDataColumns[] = ['columnName', 'from', 'to']
	rulesetInfoDisplayedColumns: RulesetDetailDataColumns[] = ['columnName', 'from', 'to']
	conditionalRulesDataSource: MatTableDataSource<RuleDetails>

	expandedRule: RuleDetails = null
	rewardPoolList: KeyValue<string, string>[] = []

	@ViewChild('matPaginatorConditionalRules', { static: false }) paginator: MatPaginator

	constructor(
		private store: Store<AppStore.State>
	) {
		this.conditionalRulesDataSource = new MatTableDataSource()
		this.subs = this.store.select(CampaignSelectors.getCampaign).subscribe(x => {

			// set option list
			const campaignData = x.campaignData
			if (this.conditionalRules && campaignData) {
				this.rewardPoolList = campaignData.rewardPools
			}

			this.conditionalRulesDataSource.data = this.conditionalRules
			this.conditionalRulesDataSource.paginator = this.paginator
			this.conditionalRulesDataSource.filterPredicate = ({ columnName }: ColumnField, filter: string): boolean => {
				return FilterPredicate.isIdenticalTo(filter)([columnName])
			}
		})
		this.conditionalRulesDataSource.data = this.conditionalRules && this.conditionalRules.map((o: RuleDetails) => o.ruleDetails ? ({ ...o, expand: false, ruleDetails: o.ruleDetails.map((o: ColumnField) => ({ ...o, expand: false })) }) : o)
	}

	ngOnInit() {
		this.conditionalRulesDataSource.data = this.conditionalRules
		this.conditionalRulesDataSource.paginator = this.paginator
	}

	searchRulesetCondition(filterValue: string) {
		this.conditionalRulesDataSource.filter = filterValue.trim().toLowerCase()
	}

	onExpandClick($event: Event, columnName: string, columnName1: string) {
		$event.stopPropagation()
		this.conditionalRulesDataSource.data = this.conditionalRulesDataSource.data.map((o: RuleDetails) => o.columnName === columnName1 ? (({ ...o, ruleDetails: o.ruleDetails.map((i: ColumnField) => i.columnName === columnName ? ({ ...i, expand: !i.expand }) : i) })) : o)
	}

	onRulesetExpandClick($event: Event, columnName: string) {
		$event.stopPropagation()
		this.conditionalRulesDataSource.data = this.conditionalRulesDataSource.data.map((o: RuleDetails) => o.columnName === columnName ? (({ ...o, expand: !o.expand })) : o)
	}

	getRewardPoolValue(value: string): string {
		const result = this.rewardPoolList.find(x => x.key === value)
		return result ? result.value : '-'
	}
}
