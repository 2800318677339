import { KeyValue } from '@angular/common'
import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'
import { FormGroupState } from 'ngrx-forms'
import { Observable, Subscription } from 'rxjs'
import { ConditionalRewardCampaignResponse } from 'src/app/models/campaign-management/conditional-reward/conditional-reward-campaign'
import * as AppStore from 'src/app/store'
import * as ConditionalRewardAction from 'src/app/store/campaign-management/conditional-reward/conditional-reward.actions'
import * as ConditionalRewardSelectors from 'src/app/store/campaign-management/conditional-reward/conditional-reward.selectors'
import { Tier, TierForm } from 'src/app/store/campaign-management/conditional-reward/conditional-reward.state'

@Component({
	selector: 'app-tier',
	templateUrl: './tier.component.html',
	styleUrls: ['./tier.component.scss']
})
export class TierComponent implements OnInit, OnDestroy {

	@Input() tier: Tier
	@Input() index: number

	action: string
	formState$: Observable<FormGroupState<TierForm>>
	campaigns$: Observable<number[]>
	campaignList: ConditionalRewardCampaignResponse[] = []
	filterCampaign: ConditionalRewardCampaignResponse[] = []
	subs: Subscription

	isFirst = false
	isLast: number

	constructor(
		private store: Store<AppStore.State>
	) {
		this.subs = this.store.select(ConditionalRewardSelectors.getConditionalReward).subscribe(x => {
			this.action = x.tierAction
			this.campaignList = x.conditionalRewardCampaignResponse
			this.filterCampaign = x.conditionalRewardCampaignResponse.filter(x => x.status !== 'Terminated')
			this.isLast = x.conditionalRewardDetailForm.value.tiers.allIds.length - 1
		})
	}

	get isView(): boolean { return this.action === 'VIEW' }

	ngOnInit() {
		this.isFirst = this.index === 0

		this.formState$ = this.store.select(ConditionalRewardSelectors.getTier, this.tier.id)
		this.campaigns$ = this.store.select(ConditionalRewardSelectors.getTierCampaign, this.tier.id)
	}

	ngOnDestroy() {
		this.subs.unsubscribe()
	}

	trackByIndex(index: number, tier: Tier) {
		return tier.id
	}

	delete() {
		this.store.dispatch(ConditionalRewardAction.SetTierId({ tierId: this.tier.id }))
		this.store.dispatch(ConditionalRewardAction.DeleteTier())
	}

	addCampaign() {
		this.store.dispatch(ConditionalRewardAction.SetTierId({ tierId: this.tier.id }))
		this.store.dispatch(ConditionalRewardAction.AddCampaignDialog())
	}

	deleteCampaign(campaignId: number) {
		this.store.dispatch(ConditionalRewardAction.SetTierId({ tierId: this.tier.id }))
		this.store.dispatch(ConditionalRewardAction.DeleteCampaign({ campaignId }))
	}

	getCampaign(value: number): string {
		const result = this.campaignList.find(x => x.id === value)
		return result ? result.name : ''
	}

	getCampaignStatus(value: number): string {
		const result = this.campaignList.find(x => x.id === value)
		return result ? result.status : ''
	}
}
