import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material'
import { Router } from '@angular/router'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Action, Store } from '@ngrx/store'
import { Observable, of } from 'rxjs'
import { catchError, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators'
import { MessageDialogComponent } from 'src/app/components/common/dialog/message-dialog/message-dialog.component'
import { Draft, Published, Unpublished } from 'src/app/models/campaign-management/conditional-reward/conditional-reward'
import { ConditionalRewardCampaignResponse } from 'src/app/models/campaign-management/conditional-reward/conditional-reward-campaign'
import { ConditionalRewardDataResponse } from 'src/app/models/campaign-management/conditional-reward/conditional-reward-data'
import { ConditionalRewardViewResponse } from 'src/app/models/campaign-management/conditional-reward/conditional-reward-view'
import { getDialogConfig } from 'src/app/models/common/dialog'
import { Response } from 'src/app/models/common/http'
import { Util } from 'src/app/models/util/util'
import { CampaignManagementService } from 'src/app/services/campaign-management/campaign-management.service'
import * as AppStore from 'src/app/store/'
import * as CommonAction from '../../common/common/common.actions'
import * as ConditionalRewardAction from './conditional-reward.actions'
import { SelectDialogComponent } from 'src/app/components/common/dialog/select-dialog/select-dialog.component'
import { Policy } from 'src/app/models/access-management/policy/policy'
import { POLICY } from 'src/app/models/common/constant'

@Injectable()
export class Effects {

	constructor(
		private action$: Actions,
		private router: Router,
		private dialog: MatDialog,
		private campaignManagementService: CampaignManagementService,
		private store: Store<AppStore.State>
	) { }

	InitialState$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ConditionalRewardAction.InitialState),
			tap(() => {
				this.router.navigate(['campaign-management/conditional-reward'])
			})
		), { dispatch: false }
	)

	GoList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ConditionalRewardAction.GoList),
			tap(() => {
				this.router.navigate(['campaign-management/conditional-reward'])
			})
		), { dispatch: false }
	)

	GoCreate$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ConditionalRewardAction.GoCreate),
			tap(() => {
				this.router.navigate(['campaign-management/conditional-reward/create'])
			})
		), { dispatch: false }
	)

	GoUpdate$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ConditionalRewardAction.GoUpdate),
			tap(() => {
				this.router.navigate(['campaign-management/conditional-reward/update'])
			})
		), { dispatch: false }
	)

	GoPublishedView$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ConditionalRewardAction.GoPublishedView),
			tap(() => {
				this.router.navigate(['campaign-management/conditional-reward/published-view'])
			})
		), { dispatch: false }
	)

	GoUnpublishedView$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ConditionalRewardAction.GoUnpublishedView),
			tap(() => {
				this.router.navigate(['campaign-management/conditional-reward/unpublished-view'])
			})
		), { dispatch: false }
	)

	GoPublishedUpdate$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ConditionalRewardAction.GoPublishedUpdate),
			tap(() => {
				this.router.navigate(['campaign-management/conditional-reward/published-update'])
			})
		), { dispatch: false }
	)

	ResetTierDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ConditionalRewardAction.ResetTierDialog),
			tap(() => {
				this.store.dispatch(ConditionalRewardAction.RestError({ message: 'Tiering has been reset.', manualThrow: true }))
			})
		), { dispatch: false }
	)

	GoBackDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ConditionalRewardAction.GoBackDialog),
			mergeMap(({ action }) => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.BACK', payload: action }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					if (result.payload === 'LIST') {
						return ConditionalRewardAction.GoList()
					}
				} else {
					return ConditionalRewardAction.CloseDialog()
				}
			})
		)
	)

	RepublishDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ConditionalRewardAction.RepublishDialog),
			mergeMap(() => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.UPDATE' }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return ConditionalRewardAction.Republish()
				} else {
					return ConditionalRewardAction.CloseDialog()
				}
			})
		)
	)

	RestError$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ConditionalRewardAction.RestError),
			map(({ message, manualThrow }) => {
				return CommonAction.RestError({ message, manualThrow })
			})
		)
	)

	List$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ConditionalRewardAction.List),
			mergeMap(() => {
				return this.campaignManagementService.getConditionalRewardPublishedList().pipe(
					map((response: Response) => {
						const success: boolean = response.success
						const payload: Published[] = response.payload

						if (success) {
							return ConditionalRewardAction.ListSuccess({ payload })
						} else { return ConditionalRewardAction.RestError(response as any) }
					}), catchError(this.catcher)
				)
			})
		)
	)

	DraftList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ConditionalRewardAction.DraftList),
			mergeMap(() => {
				return this.campaignManagementService.getConditionalRewardDraftList().pipe(
					map((response: Response) => {
						const success: boolean = response.success
						const payload: Draft[] = response.payload
						if (success) {
							return ConditionalRewardAction.DraftListSuccess({ payload })
						} else { return ConditionalRewardAction.RestError(response as any) }

					}), catchError(this.catcher)
				)
			})
		)
	)

	UnpublishedList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ConditionalRewardAction.UnpublishedList),
			mergeMap(() => {
				return this.campaignManagementService.getConditionalRewardUnpublishedList().pipe(
					map((response: Response) => {
						const success: boolean = response.success
						const payload: Unpublished[] = response.payload

						if (success) {
							return ConditionalRewardAction.UnpublishedListSuccess({ payload })
						} else { return ConditionalRewardAction.RestError(response as any) }
					}), catchError(this.catcher)
				)
			})
		)
	)

	View$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ConditionalRewardAction.View),
			withLatestFrom(this.store.select(state => state.conditionalReward)),
			mergeMap(([x, conditionalReward]) => this.campaignManagementService.viewConditionalReward({
				id: conditionalReward.id,
				checkerMakerId: conditionalReward.checkerMakerId
			}, {policy: x.payload as POLICY}).pipe(map((response: Response) => {
				const success: boolean = response.success
				const payload: ConditionalRewardViewResponse = response.payload

				if (success) {
					return ConditionalRewardAction.ViewSuccess({ payload })
				} else { return ConditionalRewardAction.RestError(response as any) }

			}), catchError(this.catcher)
			))
		)
	)

	ViewSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ConditionalRewardAction.ViewSuccess),
			mergeMap(() => {
				return [ConditionalRewardAction.GetCampaignList()]
			})
		)
	)


	CreateDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ConditionalRewardAction.CreateDialog),
			mergeMap(() => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.CREATE_CONDITIONAL_REWARD' }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return ConditionalRewardAction.Create()
				} else {
					return ConditionalRewardAction.CloseDialog()
				}
			})
		)
	)

	Create$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ConditionalRewardAction.Create),
			withLatestFrom(this.store.select(state => state.conditionalReward)),
			mergeMap(([, conditionalReward]) => {
				const conditionalRewardDetailForm = conditionalReward.conditionalRewardDetailForm.value
				const tiersVal = conditionalRewardDetailForm.tiers
				const tier = []

				tiersVal.allIds.forEach(tierId => {
					if (tiersVal.byId.hasOwnProperty(tierId)) {
						const byId = tiersVal.byId[tierId]
						const form = byId.form.value

						tier.push({
							fromValue: form.fromValue.length === 0 ? '0' : form.fromValue,
							toValue: form.toValue,
							campaigns: byId.campaigns
						})
					}
				})

				return this.campaignManagementService.createConditionalReward({
					resourceCode: conditionalRewardDetailForm.resourceCode,
					code: conditionalRewardDetailForm.code,
					name: conditionalRewardDetailForm.name,
					startDate: Util.fromISOStringToDateStr(conditionalRewardDetailForm.startDate),
					endDate: Util.fromISOStringToDateStr(conditionalRewardDetailForm.endDate),
					accumulationCycleType: (conditionalRewardDetailForm.accumulationCycleType === 'Y' && conditionalRewardDetailForm.resourceCode === 'CARDLINK')
						|| conditionalRewardDetailForm.accumulationCycleType === 'B' ? conditionalRewardDetailForm.accumulationCycleTypeOption : conditionalRewardDetailForm.accumulationCycleType,
					accumulationCycleMonth: conditionalRewardDetailForm.accumulationCycleMonth,
					accumulationCycleDay: conditionalRewardDetailForm.accumulationCycleDay,
					rewardCreditMethod: conditionalRewardDetailForm.rewardCreditMethod,
					tier
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload = response.payload
					if (success) {
						return ConditionalRewardAction.CreateSuccess({ payload })
					} else {
						return ConditionalRewardAction.RestError(response as any)
					}
				}), catchError(this.catcher))
			})
		)
	)

	CreateSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ConditionalRewardAction.CreateSuccess),
			mergeMap(({ payload }) => {
				return [ConditionalRewardAction.GoList(), CommonAction.RestError({ message: payload })]
			})
		)
	)

	UpdateDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ConditionalRewardAction.UpdateDialog),
			mergeMap(() => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.UPDATE' }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return ConditionalRewardAction.Update()
				} else {
					return ConditionalRewardAction.CloseDialog()
				}
			})
		)
	)

	Update$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ConditionalRewardAction.Update),
			withLatestFrom(this.store.select(state => state.conditionalReward)),
			mergeMap(([, conditionalReward]) => {
				const conditionalRewardDetailForm = conditionalReward.conditionalRewardDetailForm.value
				const tiersVal = conditionalRewardDetailForm.tiers
				const tier = []

				tiersVal.allIds.forEach(tierId => {
					if (tiersVal.byId.hasOwnProperty(tierId)) {
						const byId = tiersVal.byId[tierId]
						const form = byId.form.value

						tier.push({
							fromValue: form.fromValue.length === 0 ? '0' : form.fromValue,
							toValue: form.toValue,
							campaigns: byId.campaigns
						})
					}
				})

				return this.campaignManagementService.updateConditionalReward(conditionalReward.id, {
					resourceCode: conditionalRewardDetailForm.resourceCode,
					code: conditionalRewardDetailForm.code,
					name: conditionalRewardDetailForm.name,
					startDate: Util.fromISOStringToDateStr(conditionalRewardDetailForm.startDate),
					endDate: Util.fromISOStringToDateStr(conditionalRewardDetailForm.endDate),
					accumulationCycleType: (conditionalRewardDetailForm.accumulationCycleType === 'Y' && conditionalRewardDetailForm.resourceCode === 'CARDLINK')
						|| conditionalRewardDetailForm.accumulationCycleType === 'B' ? conditionalRewardDetailForm.accumulationCycleTypeOption : conditionalRewardDetailForm.accumulationCycleType,
					accumulationCycleMonth: conditionalRewardDetailForm.accumulationCycleMonth,
					accumulationCycleDay: conditionalRewardDetailForm.accumulationCycleDay,
					rewardCreditMethod: conditionalRewardDetailForm.rewardCreditMethod,
					tier
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload = response.payload
					if (success) {
						return ConditionalRewardAction.UpdateSuccess({ payload })
					} else {
						return ConditionalRewardAction.RestError(response as any)
					}
				}), catchError(this.catcher))
			})
		)
	)

	UpdateSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ConditionalRewardAction.UpdateSuccess),
			mergeMap(({ payload }) => {
				return [ConditionalRewardAction.View({ payload: POLICY.CONDITIONAL_REWARD_CREATE}), CommonAction.RestError({ message: payload })]
			})
		)
	)

	DeleteDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ConditionalRewardAction.DeleteDialog),
			mergeMap(() => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.DELETE_CONDITIONAL_REWARD' }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return ConditionalRewardAction.Delete()
				} else {
					return ConditionalRewardAction.CloseDialog()
				}
			})
		)
	)

	Delete$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ConditionalRewardAction.Delete),
			withLatestFrom(this.store.select(state => state.conditionalReward)),
			mergeMap(([, conditionalReward]) => {
				return this.campaignManagementService.deleteConditionalReward({
					id: conditionalReward.id
				}).pipe(map((response: Response) => {
					const success: boolean = response.success

					if (success) {
						return ConditionalRewardAction.DeleteSuccess()
					} else { return ConditionalRewardAction.RestError(response as any) }

				}, catchError(this.catcher))
				)
			})
		)
	)

	DeleteSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ConditionalRewardAction.DeleteSuccess),
			map(() => {
				return ConditionalRewardAction.GoList()
			})
		)
	)

	PublishDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ConditionalRewardAction.PublishDialog),
			mergeMap(() => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.PUBLISH_CONDITIONAL_REWARD' }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return ConditionalRewardAction.Publish()
				} else {
					return ConditionalRewardAction.CloseDialog()
				}
			})
		)
	)

	Publish$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ConditionalRewardAction.Publish),
			withLatestFrom(this.store.select(state => state.conditionalReward)),
			mergeMap(([, conditionalReward]) => {
				return this.campaignManagementService.publishConditionalReward({
					id: conditionalReward.id
				}).pipe(map((response: Response) => {
					const success: boolean = response.success

					if (success) {
						return ConditionalRewardAction.PublishSuccess()
					} else { return ConditionalRewardAction.RestError(response as any) }

				}), catchError(this.catcher))
			})
		)
	)

	PublishSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ConditionalRewardAction.PublishSuccess),
			mergeMap(() => {
				return [
					ConditionalRewardAction.List(),
					ConditionalRewardAction.DraftList(),
					ConditionalRewardAction.UnpublishedList()
				]
			})
		)
	)

	Republish$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ConditionalRewardAction.Republish),
			withLatestFrom(this.store.select(state => state.conditionalReward)),
			mergeMap(([, conditionalReward]) => {
				const conditionalRewardDetailForm = conditionalReward.conditionalRewardDetailForm.value
				const tiersVal = conditionalRewardDetailForm.tiers
				const tier = []

				tiersVal.allIds.forEach(tierId => {
					if (tiersVal.byId.hasOwnProperty(tierId)) {
						const byId = tiersVal.byId[tierId]
						const form = byId.form.value

						tier.push({
							fromValue: form.fromValue.length === 0 ? '0' : form.fromValue,
							toValue: form.toValue,
							campaigns: byId.campaigns
						})
					}
				})

				return this.campaignManagementService.republishConditionalReward(conditionalReward.id, {
					resourceCode: conditionalRewardDetailForm.resourceCode,
					code: conditionalRewardDetailForm.code,
					name: conditionalRewardDetailForm.name,
					startDate: Util.fromISOStringToDateStr(conditionalRewardDetailForm.startDate),
					endDate: Util.fromISOStringToDateStr(conditionalRewardDetailForm.endDate),
					accumulationCycleType: (conditionalRewardDetailForm.accumulationCycleType === 'Y' && conditionalRewardDetailForm.resourceCode === 'CARDLINK')
						|| conditionalRewardDetailForm.accumulationCycleType === 'B' ? conditionalRewardDetailForm.accumulationCycleTypeOption : conditionalRewardDetailForm.accumulationCycleType,
					accumulationCycleMonth: conditionalRewardDetailForm.accumulationCycleMonth,
					accumulationCycleDay: conditionalRewardDetailForm.accumulationCycleDay,
					rewardCreditMethod: conditionalRewardDetailForm.rewardCreditMethod,
					tier
				}).pipe(map((response: Response) => {
					const success: boolean = response.success

					if (success) {
						return ConditionalRewardAction.RepublishSuccess()
					} else { return ConditionalRewardAction.RestError(response as any) }

				}), catchError(this.catcher))
			})
		)
	)

	RepublishSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ConditionalRewardAction.RepublishSuccess),
			mergeMap(() => {
				return [
					ConditionalRewardAction.GoPublishedView()
				]
			})
		)
	)

	TerminateDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ConditionalRewardAction.TerminateDialog),
			mergeMap(() => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.TERMINATE_CONDITIONAL_REWARD' }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return ConditionalRewardAction.Terminate()
				} else {
					return ConditionalRewardAction.CloseDialog()
				}
			})
		)
	)

	Terminate$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ConditionalRewardAction.Terminate),
			withLatestFrom(this.store.select(state => state.conditionalReward)),
			mergeMap(([, conditionalReward]) => {
				return this.campaignManagementService.terminateConditionalReward({
					id: conditionalReward.id
				}).pipe(map((response: Response) => {
					const success: boolean = response.success

					if (success) {
						return ConditionalRewardAction.TerminateSuccess()
					} else { return ConditionalRewardAction.RestError(response as any) }

				}), catchError(this.catcher))
			})
		)
	)

	TerminateSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ConditionalRewardAction.TerminateSuccess),
			mergeMap(() => {
				return [
					ConditionalRewardAction.List(),
					ConditionalRewardAction.DraftList(),
					ConditionalRewardAction.UnpublishedList()
				]
			})
		)
	)

	GetConditionalRewardData$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ConditionalRewardAction.GetConditionalRewardData),
			mergeMap(({ payload }) => {
				return this.campaignManagementService.getConditionalRewardData({ policy: payload as POLICY }).pipe(
					map((response: Response) => {
						const success: boolean = response.success
						const payload: ConditionalRewardDataResponse = response.payload

						if (success) {
							return ConditionalRewardAction.GetConditionalRewardDataSuccess({ payload })
						} else {
							return ConditionalRewardAction.RestError(response as any)
						}

					}), catchError(this.catcher)
				)
			})
		)
	)

	GetCampaignList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ConditionalRewardAction.GetCampaignList),
			withLatestFrom(this.store.select(state => state.conditionalReward)),
			mergeMap(([, conditionalReward]) => {
				const conditionalRewardDetailForm = conditionalReward.conditionalRewardDetailForm.value
				return this.campaignManagementService.getCampaignList({
					resourceCode: conditionalRewardDetailForm.resourceCode,
					startDate: Util.fromISOStringToDateStr(conditionalRewardDetailForm.startDate),
					endDate: Util.fromISOStringToDateStr(conditionalRewardDetailForm.endDate),
					accumulationCycleType: (conditionalRewardDetailForm.accumulationCycleType === 'Y' && conditionalRewardDetailForm.resourceCode === 'CARDLINK')
						|| (conditionalRewardDetailForm.accumulationCycleType === 'B') ? conditionalRewardDetailForm.accumulationCycleTypeOption : conditionalRewardDetailForm.accumulationCycleType,
					accumulationCycleMonth: conditionalRewardDetailForm.accumulationCycleMonth,
					accumulationCycleDay: conditionalRewardDetailForm.accumulationCycleDay,
					rewardCreditMethod: conditionalRewardDetailForm.rewardCreditMethod
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: ConditionalRewardCampaignResponse[] = response.payload

					if (success) {
						return ConditionalRewardAction.GetCampaignListSuccess({ payload })
					} else {
						return ConditionalRewardAction.RestError(response as any)
					}

				}), catchError(this.catcher))
			})
		)
	)

	AddCampaignDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(ConditionalRewardAction.AddCampaignDialog),
			withLatestFrom(this.store.select(state => state.conditionalReward)),
			mergeMap(([, conditionalReward]) => {
				const tier = conditionalReward.conditionalRewardDetailForm.value.tiers.byId[conditionalReward.tierId]

				const dialogRef = this.dialog.open(SelectDialogComponent, getDialogConfig({
					title: 'CONDITIONAL_REWARD.ADD_CAMPAIGN',
					content: 'CONDITIONAL_REWARD.CAMPAIGN',
					payload: conditionalReward.conditionalRewardCampaignResponse
						.filter(x => {
							const data = tier.campaigns.find(
								key => key === x.id
							)
							if (!data) { return x }
						})
						.filter(o => { // republish filter only published campaign
							if (conditionalReward.tierAction === 'PUBLISHED_UPDATE') {
								return o.status === 'Publish'
							} else {
								return o.status !== 'Terminated'
							}
						}).map(({ id, name }) => (({
							keyValue: { key: id.toString(), value: name }
						})))
				}))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return ConditionalRewardAction.AddCampaign({ campaignId: +result.keyValue.key })
				} else {
					return ConditionalRewardAction.CloseDialog()
				}
			})
		)
	)
	catcher = (error) => {
		return (of(ConditionalRewardAction.RestError({ message: error.message, manualThrow: error.manualThrow })))
	}
}
