import { Component, Inject, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material'
import { TranslateService } from '@ngx-translate/core'
import { MessageDialogData } from 'src/app/models/common/dialog'

@Component({
	selector: 'app-target-segment-result-dialog',
	templateUrl: './target-segment-result-dialog.component.html',
	styleUrls: ['./target-segment-result-dialog.component.scss']
})
export class TargetSegmentResultDialogComponent implements OnInit {

	title: string
	content: any
	submitBtn: string
	tableColumnName: string
	dataSource = []

	constructor(
		private translateService: TranslateService,
		private dialogRef: MatDialogRef<TargetSegmentResultDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: MessageDialogData
	) {
		this.title = data.title
		this.content = data.content
		this.submitBtn = data.submitBtn || 'ACTION.OK'

		const objectColumnName = (value) => {
			switch (value) {
				case 'customerId': return 'customerId'
				case 'account': return 'accountNo'
				case 'card': return 'cardNo'
			}
		}

		const displayColumnName = (value) => {
			switch (value) {
				case 'customerId': return 'TARGET_SEGMENT.DIALOG.CUSTOMER_ID'
				case 'account': return 'TARGET_SEGMENT.DIALOG.ACCOUNT_NO'
				case 'card': return 'TARGET_SEGMENT.DIALOG.CARD_NO'
			}
		}

		if (data.payload) {
			this.tableColumnName = displayColumnName(data.payload.segment)
			this.dataSource = (data.payload.targetList || []).map((o: { [key: string]: string | boolean }) => ({
				name: o[objectColumnName(data.payload.segment)],
				exists: o.exists
			}))
		}
	}

	ngOnInit() { }

	errorMessage() {
		const segment = this.translateService.instant(this.tableColumnName)
		const notMatch = this.translateService.instant('TARGET_SEGMENT.DIALOG.THIS_XX_NOT_MATCH_WITH_DATABASE', { segment })
		return notMatch
	}

	cancel() {
		this.dialogRef.close(this.data)
	}

	submit() {
		this.dialogRef.close(this.data)
	}

}
