import { ProductCatalogueListResponse } from 'src/app/models/catalogue-management/product-catalogue/product-catalogue-list'
import { createFormGroupState, FormGroupState, updateGroup, validate, Boxed, box } from 'ngrx-forms'
import { maxLength, required } from 'ngrx-forms/validation'
import { FieldValidator } from 'src/app/models/util/field.validator'
import { Option } from 'src/app/models/option/option'
import { GlRedemption } from 'src/app/models/param-settings/gl-redemption/gl-redemption'
import { KeyValue } from '@angular/common'
import { FulfilmentPartnerListData } from 'src/app/models/catalogue-management/fulfilment-partner/fulfilment-partner-list'

export interface State {
	action?: string
	isLoading: boolean
	id?: number
	productCatalogueListResponse: ProductCatalogueListResponse
	productCatalogueDetailForm: FormGroupState<ProductCatalogueDetailForm>
	productCatalogueRewardPoolForm: FormGroupState<ProductCatalogueRewardPoolForm>
	statusList: Option[]
	redemptionTypeList: Option[]
	productCategoryList: Option[]
	rewardPoolAndGroupList: KeyValue<string, string>[]
	glRedemptionList: GlRedemption[]
	fulfilmentPartnerList: FulfilmentPartnerListData[]
	productCatalogueDetailFormView: ProductCatalogueDetailForm
	productCatalogueDuplicateForm: FormGroupState<ProductCatalogueDuplicateForm>
	isDuplicateLoading: boolean
	isDuplicateDialog: boolean
}

export interface ProductCatalogueDetailForm {
	code: string
	name: string
	description: string
	merchantId: string
	status: string
	rewardPoolAndGroup: string
	rewardPoolId: string
	rewardPoolType: string
	glRedemptionId: number
	fulfilmentPartner: string
	startDate: string
	endDate: string
	redemptionType: string
	point: string
	amount: string
	transactionCode: string,
	redemptionRate: string,
	image: string,
	imageFileName: string,
	glProductCode: string,
	productCategory: string,
	productCatalogueRewardPools: ProductCatalogueRewardPools,
	createdBy: string,
	createdDate: string,
	modifiedBy: string,
	modifiedDate: string
}

export const initialProductCatalogueDetailFormValue: ProductCatalogueDetailForm = {
	code: '',
	name: '',
	description: '',
	merchantId: '',
	status: '',
	rewardPoolAndGroup: '',
	rewardPoolId: '',
	rewardPoolType: '',
	glRedemptionId: null,
	fulfilmentPartner: '',
	startDate: '',
	endDate: '',
	redemptionType: '',
	point: '',
	amount: '',
	transactionCode: '',
	redemptionRate: '',
	image: '',
	imageFileName: '',
	glProductCode: '',
	productCategory: '',
	productCatalogueRewardPools: {
		byId: {},
		allIds: []
	},
	createdBy: '',
	createdDate: '',
	modifiedBy: '',
	modifiedDate: ''
}

export interface ProductCatalogueRewardPoolForm {
	rewardPoolId: number
	rewardPoolName: string
	glRedemptionId: number
}

export const initialProductCatalogueRewardPoolFormValue: ProductCatalogueRewardPoolForm = {
	rewardPoolId: null,
	rewardPoolName: '',
	glRedemptionId: null
}

export const initialProductCatalogueRewardPoolForm = createFormGroupState('productCatalogueRewardPoolForm', initialProductCatalogueRewardPoolFormValue)

export const validateProductCatalogueRewardPoolForm = updateGroup<ProductCatalogueRewardPoolForm>({
	glRedemptionId: validate([required])
})

export const initialProductCatalogueDetailForm = createFormGroupState('productCatalogueDetailForm', initialProductCatalogueDetailFormValue)

export const validateProductCatalogueDetailForm = (state: State) => updateGroup<ProductCatalogueDetailForm>({
	code: validate([required, maxLength(10), FieldValidator.alphaNumericWithoutSpaces()]),
	name: validate([required, maxLength(40), FieldValidator.checkValidCharacters()]),
	description: validate([maxLength(100), FieldValidator.checkValidCharacters()]),
	merchantId: validate([maxLength(15), FieldValidator.checkNumericMatchLength(15)]),
	status: validate([required]),
	rewardPoolAndGroup: validate([required]),
	glRedemptionId: validate([FieldValidator.checkRewardPoolType(state.productCatalogueDetailForm.value.rewardPoolAndGroup && state.productCatalogueDetailForm.value.rewardPoolAndGroup.split('_')[0])]),
	fulfilmentPartner: validate([required]),
	startDate: validate([required]),
	endDate: validate([required]),
	redemptionType: validate([required]),
	point: validate([required, maxLength(10), FieldValidator.numberWithoutDecimal(), FieldValidator.NotStartWithZero()]),
	amount: validate([maxLength(10), FieldValidator.checkProductCatalogueAmount(state.productCatalogueDetailForm)]),
	transactionCode: validate([maxLength(4), FieldValidator.checkProductCatalogueTransactionCode(state.productCatalogueDetailForm)]),
	redemptionRate: validate([FieldValidator.integerAndDecimalCheck(2, 4), required]),
	glProductCode: validate([required, maxLength(6), FieldValidator.alphaNumericWithoutSpaces()]),
	productCategory: validate([required]),
	imageFileName: validate([])
})

export interface ProductCatalogueDuplicateForm {
	code: string
	name: string
}

export const initialProductCatalogueDuplicateFormValue: ProductCatalogueDuplicateForm = {
	code: '',
	name: ''
}

export const initialProductCatalogueDuplicateForm = createFormGroupState('productCatalogueDuplicateForm', initialProductCatalogueDuplicateFormValue)

export const validateProductCatalogueDuplicateForm = updateGroup<ProductCatalogueDuplicateForm>({
	code: validate([required, maxLength(10), FieldValidator.alphaNumericWithoutSpaces()]),
	name: validate([required, maxLength(40), FieldValidator.checkValidCharacters()]),
})

export const initialState: State = {
	isLoading: false,
	productCatalogueListResponse: {
		productCatalogues: []
	},
	productCatalogueDetailForm: initialProductCatalogueDetailForm,
	productCatalogueRewardPoolForm: initialProductCatalogueRewardPoolForm,
	statusList: [],
	redemptionTypeList: [],
	productCategoryList: [],
	rewardPoolAndGroupList: [],
	glRedemptionList: [],
	fulfilmentPartnerList: [],
	productCatalogueDetailFormView: initialProductCatalogueDetailFormValue,
	productCatalogueDuplicateForm: initialProductCatalogueDuplicateForm,
	isDuplicateLoading: false,
	isDuplicateDialog: false
}

export interface ProductCatalogueRewardPools {
	byId: { [key: string]: ProductCatalogueRewardPool }
	allIds: string[]
}

export interface ProductCatalogueRewardPool {
	id: string
	form: FormGroupState<ProductCatalogueRewardPoolForm>
}
