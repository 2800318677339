import { Action, createReducer, on } from '@ngrx/store'
import { formGroupReducer, reset, setValue } from 'ngrx-forms'
import * as MccGroupAction from './mcc-group.actions'
import * as MccGroupState from './mcc-group.state'

export const reducer = createReducer(
	MccGroupState.initialState,
	on(MccGroupAction.InitialState, () => MccGroupState.initialState),
	on(MccGroupAction.Reset, state => ({
		...state,
		mccGroupListForm: reset(setValue(state.mccGroupListForm, MccGroupState.initialMccGroupListFormValue))
	})),
	on(MccGroupAction.SetId, (state, { code }) => ({
		...state,
		code
	})),
	on(MccGroupAction.GoCreate, state => ({
		...state,
		mccGroupDetailForm: reset(setValue(state.mccGroupDetailForm, MccGroupState.initialMccGroupDetailFormValue))
	})),
	on(MccGroupAction.RestError, state => ({
		...state,
		isLoading: false,
		isDuplicateLoading: false,
		isDuplicateDialog: false
	})),
	on(MccGroupAction.List, state => ({
		...state,
		isLoading: true
	})),
	on(MccGroupAction.ListSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		mccGroupListResponse: payload
	})),
	on(MccGroupAction.ResourceListCreate, state => ({
		...state,
		isLoading: true
	})),
	on(MccGroupAction.ResourceListCreateSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		resourceList: (payload || [])
	})),
	on(MccGroupAction.ResourceListUpdate, state => ({
		...state,
		isLoading: true
	})),
	on(MccGroupAction.ResourceListUpdateSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		resourceList: (payload || [])
	})),
	on(MccGroupAction.View, state => ({
		...state,
		isLoading: true,
		mccGroupDetailForm: setValue(state.mccGroupDetailForm, MccGroupState.initialMccGroupDetailFormValue),
		allowDelete: true
	})),
	on(MccGroupAction.ViewSuccess, (state, { payload }) => {
		const mccGroupDetailFormView = {
			code: payload.code,
			name: payload.name,
			description: payload.description,
			range: payload.range,
			createdBy: payload.createdBy,
			createdDate: payload.createdDate,
			modifiedBy: payload.modifiedBy,
			modifiedDate: payload.modifiedDate
		}
		return ({
			...state,
			isLoading: false,
			mccGroupDetailForm: MccGroupState.validateMccGroupDetailForm(setValue(state.mccGroupDetailForm, mccGroupDetailFormView as MccGroupState.MccGroupDetailForm)),
			allowDelete: payload.allowDelete,
			mccGroupDetailFormView
		})
	}),
	on(MccGroupAction.Create, state => ({
		...state,
		isLoading: true
	})),
	on(MccGroupAction.CreateSuccess, (state, { payload }) => ({
		...MccGroupState.initialState
	})),
	on(MccGroupAction.Update, state => ({
		...state,
		isLoading: true
	})),
	on(MccGroupAction.UpdateSuccess, (state, { payload }) => ({
		...state
	})),
	on(MccGroupAction.Delete, state => ({
		...state,
		isLoading: true
	})),
	on(MccGroupAction.DeleteSuccess, () => MccGroupState.initialState),
	on(MccGroupAction.DuplicateDialog, state => ({
		...state,
		mccGroupDuplicateForm: reset(setValue(state.mccGroupDuplicateForm, MccGroupState.initialMccGroupDuplicateFormValue)),
		isDuplicateDialog: false
	})),
	on(MccGroupAction.Duplicate, state => ({
		...state,
		isDuplicateLoading: true
	})),
	on(MccGroupAction.DuplicateSuccess, state => ({
		...state,
		isDuplicateLoading: false,
		isDuplicateDialog: true
	}))
)

export function Reducer(state: MccGroupState.State = MccGroupState.initialState, action: Action) {
	const mccGroupListForm = MccGroupState.validateMccGroupListForm(formGroupReducer(state.mccGroupListForm, action))
	if (mccGroupListForm !== state.mccGroupListForm) {
		state = { ...state, mccGroupListForm }
	}

	const mccGroupDetailForm = MccGroupState.validateMccGroupDetailForm(formGroupReducer(state.mccGroupDetailForm, action))
	if (mccGroupDetailForm !== state.mccGroupDetailForm) {
		state = { ...state, mccGroupDetailForm }
	}

	const mccGroupDuplicateForm = MccGroupState.validateMccGroupDuplicateForm(formGroupReducer(state.mccGroupDuplicateForm, action))
	if (mccGroupDuplicateForm !== state.mccGroupDuplicateForm) {
		state = {...state, mccGroupDuplicateForm}
	}

	return reducer(state, action)
}
