export const Downloader = {
	isIE: () => {
		return !!((window.document as any).documentMode)
	},
	isEdge: () => {
		return !!(/Edge\/\d./i.test(navigator.userAgent))
	},
	anchorDownloader: (fileName: string, fileBody: any, onFinish = () => { }) => {
		const a = document.createElement('a')
		a.id = `client_downloader_${Date.now()}`
		a.setAttribute('style', 'display:none')
		if (!!Downloader.isIE()) {
			// For IE
			switch (typeof (fileBody)) {
				case 'object':
					const content = new Blob([fileBody])
					window.navigator.msSaveOrOpenBlob(content, fileName)
					break
				case 'string':
					const [, body] = fileBody.split(';base64,')
					const byteArray = Uint8Array.from(
						atob(body).split('').map(char => char.charCodeAt(0))
					)
					const blob = new Blob([byteArray])
					window.navigator.msSaveOrOpenBlob(blob, fileName)
					break
			}
		} else {
			// Chrome Portion
			const dllink = (body) => {
				switch (typeof (body)) {
					case 'object':
						return window.URL.createObjectURL(new Blob([body]))
					case 'string':
						if (Downloader.isEdge()) {
							if (body.includes(';base64,')) {
								const [, b64] = body.split(';base64,')
								const byteArray = Uint8Array.from(
									atob(b64).split('').map(char => char.charCodeAt(0))
								)
								const content = new Blob([byteArray])
								return window.URL.createObjectURL(new Blob([content]))
							}
						} else { return body }
				}
				return ''
			}

			a.href = dllink(fileBody)
			a.download = fileName

			// For firefox
			document.body.appendChild(a)

			// Final Steps
			a.click()
			const target2Remove = document.getElementById(a.id)
			if (!!target2Remove && target2Remove.parentNode != null) {
				target2Remove.parentNode.removeChild(a)
			}
		}
		onFinish()
	},
}
