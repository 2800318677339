import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Action, Store } from '@ngrx/store'
import { Observable, of } from 'rxjs'
import { catchError, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators'
import * as AppStore from 'src/app/store/'
import * as CommonAction from '../common/common/common.actions'
import * as UserProfileAction from './user-profile.actions'
import { UserProfileService } from 'src/app/services/user-profile/user-profile.service'
import { Response } from 'src/app/models/common/http'
import { UserProfile } from 'src/app/models/user-profile/user-profile'

@Injectable()
export class Effects {

	constructor(
		private action$: Actions,
		private router: Router,
		private store: Store<AppStore.State>,
		private userProfileService: UserProfileService
	) { }

	InitialState$: Observable<Action> = createEffect(() => {
			return this.action$.pipe(ofType(UserProfileAction.InitialState), tap(() => {
				this.router.navigate(['user-profile'])
			}))
		}, { dispatch: false }
	)

	View$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(UserProfileAction.View),
			withLatestFrom(this.store.select(state => state.userProfile)),
			mergeMap(([, userProfile]) => this.userProfileService.getUserProfile()
				.pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: UserProfile = response.payload

					if (success) {
						return UserProfileAction.ViewSuccess({ payload })
					} else { return UserProfileAction.RestError(response as any) }
				}),
					catchError(this.catcher)
				)
			)
		)
	)

	RestError$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(UserProfileAction.RestError),
			map(({ message, manualThrow }) => {
				return CommonAction.RestError({ message, manualThrow })
			})
		)
	)

	catcher = (error) => {
		return (of(UserProfileAction.RestError({ message: error.message, manualThrow: error.manualThrow })))
	}
}
