import { Component, Inject, OnDestroy, OnInit } from '@angular/core'
import { MatDialogRef, MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material'
import { Store } from '@ngrx/store'
import { Observable, Subscription } from 'rxjs'
import { ConflictDataCampaignColumns, ConflictDataColumns, RawDataCampaignColumns, SimulatorResultBody, SimulatorResultCampaignBody, SimulatorRulesetBody, SimulatorTierBody, TicketSequence } from 'src/app/models/campaign-management/simulator/simulator'
import { SimulatorViewResponse } from 'src/app/models/campaign-management/simulator/simulator-view'
import * as AppStore from 'src/app/store'
import * as SimulatorSelector from 'src/app/store/campaign-management/simulator/simulator.selectors'

@Component({
	selector: 'app-simulator-view',
	templateUrl: './simulator-view.component.html',
	styleUrls: ['./simulator-view.component.scss']
})
export class SimulatorViewComponent implements OnInit, OnDestroy {

	isLoading = false
	formState$: Observable<SimulatorViewResponse>
	subs: Subscription
	isSimulationQueue: boolean

	rawDataCampaignDisplayedColumns: RawDataCampaignColumns[] = ['name', 'rewardPool', 'reward', 'transactionHit', 'transactionAmount', 'rewardPoint']
	rawDataCampaignDataSource: MatTableDataSource<SimulatorRulesetBody>

	conflictDataCampaignDisplayedColumns: ConflictDataCampaignColumns[] = ['name', 'channel', 'priority']
	conflictDataCampaignDataSource: MatTableDataSource<SimulatorResultCampaignBody>

	conflictDataDisplayedColumns: ConflictDataColumns[] = ['name', 'rule', 'rewardUnitType', 'amount']
	conflictDataDataSource: MatTableDataSource<SimulatorResultBody>

	ticketSequence: TicketSequence

	constructor(
		private store: Store<AppStore.State>,
		private dialogRef: MatDialogRef<SimulatorViewComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		this.rawDataCampaignDataSource = new MatTableDataSource()
		this.conflictDataCampaignDataSource = new MatTableDataSource()
		this.conflictDataDataSource = new MatTableDataSource()

		this.subs = this.store.select(SimulatorSelector.getSimulator).subscribe(x => {
			this.isLoading = x.isLoading
			if (!x.id || x.id === 0) {
				this.cancel()
			}
			this.isSimulationQueue = x.isSimulationQueue
			const ticketSequenceResultList = x.ticketSequences
			const result = x.simulatorViewResponse.result
			if (result && !this.isSimulationQueue) {
				this.rawDataCampaignDataSource.data = result.rulesetList
				this.conflictDataCampaignDataSource.data = [{ name: result.name, channel: result.channel, priority: result.priority }]
				this.conflictDataDataSource.data = result.resultList
			}

			if (ticketSequenceResultList && this.isSimulationQueue) {
				this.ticketSequence = ticketSequenceResultList.find(e => e.id === x.id)
			}
		})

		this.formState$ = this.store.select(({ simulator }) => simulator.simulatorViewResponse)
	}

	ngOnInit() { }

	trackByIndex(index: number, tier: SimulatorTierBody) {
		return tier
	}

	ngOnDestroy() {
		this.subs.unsubscribe()
	}

	cancel() {
		this.dialogRef.close()
	}
}
