import { createFeatureSelector, createSelector } from '@ngrx/store'
import * as ProductCatalogue from './product-catalogue.state'

const productCatalogue = createFeatureSelector<ProductCatalogue.State>('productCatalogue')

export const getProductCatalogue = createSelector(productCatalogue, x => x)

export const getProductCatalogueRewardPools = createSelector(productCatalogue, ({ productCatalogueDetailForm }): ProductCatalogue.ProductCatalogueRewardPool[] => {
	let result = []
	const list = productCatalogueDetailForm.controls.productCatalogueRewardPools.value

	if (list && list.allIds) {
		result = list.allIds.map(id => list.byId[id])
	}

	return result
})

export const getProductCatalogueRewardPool = createSelector(productCatalogue, ({ productCatalogueDetailForm }, id: string) => {
	const productCatalogueRewardPools = productCatalogueDetailForm.controls.productCatalogueRewardPools.value

	const form: ProductCatalogue.ProductCatalogueRewardPools = {
		byId: {
			...productCatalogueRewardPools.byId
		},
		allIds: {
			...productCatalogueRewardPools.allIds
		}
	}
	return form && form.byId[id] && form.byId[id].form
})
