import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material'
import { Router } from '@angular/router'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Action, Store } from '@ngrx/store'
import { Observable, of } from 'rxjs'
import { catchError, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators'
import { SimulatorCreateComponent } from 'src/app/components/campaign-management/simulator/simulator-create/simulator-create.component'
import { MessageDialogComponent } from 'src/app/components/common/dialog/message-dialog/message-dialog.component'
import { SimulationResult, TicketSequence } from 'src/app/models/campaign-management/simulator/simulator'
import { SimulatorDataResponse, SimulatorKeyDataResponse } from 'src/app/models/campaign-management/simulator/simulator-data'
import { getDialogConfig } from 'src/app/models/common/dialog'
import { Response } from 'src/app/models/common/http'
import { Util } from 'src/app/models/util/util'
import { CampaignManagementService } from 'src/app/services/campaign-management/campaign-management.service'
import * as AppStore from 'src/app/store/'
import * as CommonAction from '../../common/common/common.actions'
import * as SimulatorAction from './simulator.actions'
import { SimulatorViewResponse } from 'src/app/models/campaign-management/simulator/simulator-view'
import { SimulatorViewComponent } from 'src/app/components/campaign-management/simulator/simulator-view/simulator-view.component'

@Injectable()
export class Effects {

	constructor(
		private action$: Actions,
		private router: Router,
		private dialog: MatDialog,
		private campaignManagementService: CampaignManagementService,
		private store: Store<AppStore.State>
	) { }

	InitialState$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(SimulatorAction.InitialState),
			tap(() => {
				this.router.navigate(['campaign-management/simulator'])
			})
		), { dispatch: false }
	)

	GoList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(SimulatorAction.GoList),
			tap(() => {
				this.router.navigate(['campaign-management/simulator'])
			})
		), { dispatch: false }
	)

	RawDataSimulatorDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(SimulatorAction.RawDataSimulatorDialog),
			mergeMap(() => {
				const dialogRef = this.dialog.open(SimulatorCreateComponent, getDialogConfig({}))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (!result) {
					return SimulatorAction.CloseDialog()
				}
			})
		)
	)

	ConflictDataSimulatorDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(SimulatorAction.ConflictDataSimulatorDialog),
			mergeMap(() => {
				const dialogRef = this.dialog.open(SimulatorCreateComponent, getDialogConfig({}))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (!result) {
					return SimulatorAction.CloseDialog()
				}
			})
		)
	)

	GoBackDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(SimulatorAction.GoBackDialog),
			mergeMap(({ action }) => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.BACK', payload: action }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					if (result.payload === 'LIST') {
						return SimulatorAction.GoList()
					}
				} else {
					return SimulatorAction.CloseDialog()
				}
			})
		)
	)

	RestError$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(SimulatorAction.RestError),
			map(({ message, manualThrow }) => {
				return CommonAction.RestError({ message, manualThrow })
			})
		)
	)

	List$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(SimulatorAction.List),
			mergeMap(() => {
				return this.campaignManagementService.getSimulatorTicketSequenceList().pipe(
					map((response: Response) => {
						const success: boolean = response.success
						const payload: TicketSequence[] = response.payload

						if (success) {
							return SimulatorAction.ListSuccess({ payload })
						} else { return SimulatorAction.RestError(response as any) }
					}), catchError(this.catcher)
				)
			})
		)
	)

	SimulationResultList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(SimulatorAction.SimulationResultList),
			mergeMap(() => {
				return this.campaignManagementService.getSimulatorSimulationResultList().pipe(
					map((response: Response) => {
						const success: boolean = response.success
						const payload: SimulationResult[] = response.payload
						if (success) {
							return SimulatorAction.SimulationResultListSuccess({ payload })
						} else { return SimulatorAction.RestError(response as any) }

					}), catchError(this.catcher)
				)
			})
		)
	)

	ViewDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(SimulatorAction.ViewDialog),
			mergeMap(() => {
				const dialogRef = this.dialog.open(SimulatorViewComponent, getDialogConfig({}))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (!result) {
					return SimulatorAction.CloseDialog()
				}
			})
		)
	)

	View$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(SimulatorAction.View),
			withLatestFrom(this.store.select(state => state.simulator)),
			mergeMap(([, simulator]) => this.campaignManagementService.viewSimulator({
				id: simulator.id
			}).pipe(map((response: Response) => {
				const success: boolean = response.success
				const payload: SimulatorViewResponse = response.payload

				if (success) {
					return SimulatorAction.ViewSuccess({ payload })
				} else { return SimulatorAction.RestError(response as any) }

			}), catchError(this.catcher)
			))
		)
	)

	CreateDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(SimulatorAction.CreateDialog),
			mergeMap(() => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.CREATE_SIMULATOR' }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return SimulatorAction.Create()
				} else {
					return SimulatorAction.CloseDialog()
				}
			})
		)
	)

	Create$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(SimulatorAction.Create),
			withLatestFrom(this.store.select(state => state.simulator)),
			mergeMap(([, simulator]) => {
				const simulatorDetailForm = simulator.simulatorDetailForm.value

				return this.campaignManagementService.createSimulator({
					resourceCode: simulatorDetailForm.resourceCode,
					ticketType: simulatorDetailForm.ticketType,
					simulatorTarget: simulatorDetailForm.simulatorTarget,
					key: +simulatorDetailForm.key,
					startDate: Util.fromISOStringToDateStr(simulatorDetailForm.startDate),
					endDate: Util.fromISOStringToDateStr(simulatorDetailForm.endDate)
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload = response.payload
					if (success) {
						return SimulatorAction.CreateSuccess({ payload })
					} else {
						return SimulatorAction.RestError(response as any)
					}
				}), catchError(this.catcher))
			})
		)
	)

	CreateSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(SimulatorAction.CreateSuccess),
			mergeMap(({ payload }) => {
				return [
					SimulatorAction.List(),
					SimulatorAction.SimulationResultList(),
					CommonAction.RestError({ message: payload })
				]
			})
		)
	)

	DeleteDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(SimulatorAction.DeleteDialog),
			mergeMap(() => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.DELETE_SIMULATOR' }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return SimulatorAction.Delete()
				} else {
					return SimulatorAction.CloseDialog()
				}
			})
		)
	)

	Delete$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(SimulatorAction.Delete),
			withLatestFrom(this.store.select(state => state.simulator)),
			mergeMap(([, simulator]) => {
				return this.campaignManagementService.deleteSimulator({
					id: simulator.id
				}).pipe(map((response: Response) => {
					const success: boolean = response.success

					if (success) {
						return SimulatorAction.DeleteSuccess()
					} else { return SimulatorAction.RestError(response as any) }

				}, catchError(this.catcher))
				)
			})
		)
	)

	DeleteSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(SimulatorAction.DeleteSuccess),
			mergeMap(() => {
				return [
					SimulatorAction.List(),
					SimulatorAction.SimulationResultList()
				]
			})
		)
	)

	CancelDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(SimulatorAction.CancelDialog),
			mergeMap(() => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.CANCEL_SIMULATOR' }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return SimulatorAction.Cancel()
				} else {
					return SimulatorAction.CloseDialog()
				}
			})
		)
	)

	Cancel$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(SimulatorAction.Cancel),
			withLatestFrom(this.store.select(state => state.simulator)),
			mergeMap(([, simulator]) => {
				return this.campaignManagementService.cancelSimulator({
					id: simulator.id
				}).pipe(map((response: Response) => {
					const success: boolean = response.success

					if (success) {
						return SimulatorAction.CancelSuccess()
					} else { return SimulatorAction.RestError(response as any) }

				}), catchError(this.catcher))
			})
		)
	)

	CancelSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(SimulatorAction.CancelSuccess),
			mergeMap(() => {
				return [
					SimulatorAction.List(),
					SimulatorAction.SimulationResultList()
				]
			})
		)
	)

	GetSimulatorData$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(SimulatorAction.GetSimulatorData),
			mergeMap(() => {
				return this.campaignManagementService.getSimulatorData().pipe(
					map((response: Response) => {
						const success: boolean = response.success
						const payload: SimulatorDataResponse = response.payload

						if (success) {
							return SimulatorAction.GetSimulatorDataSuccess({ payload })
						} else {
							return SimulatorAction.RestError(response as any)
						}

					}), catchError(this.catcher)
				)
			})
		)
	)

	GetSimulatorKeyData$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(SimulatorAction.GetSimulatorKeyData),
			withLatestFrom(this.store.select(state => state.simulator)),
			mergeMap(([, simulator]) => {
				const simulatorDetailForm = simulator.simulatorDetailForm.value

				return this.campaignManagementService.getSimulatorKeyData({
					resourceCode: simulatorDetailForm.resourceCode,
					simulatorTarget: simulatorDetailForm.simulatorTarget
				}).pipe(
					map((response: Response) => {
						const success: boolean = response.success
						const payload: SimulatorKeyDataResponse = response.payload

						if (success) {
							return SimulatorAction.GetSimulatorKeyDataSuccess({ payload })
						} else {
							return SimulatorAction.RestError(response as any)
						}

					}), catchError(this.catcher)
				)
			})
		)
	)

	catcher = (error) => {
		return (of(SimulatorAction.RestError({ message: error.message, manualThrow: error.manualThrow })))
	}
}
