import { Component, OnInit, OnDestroy } from '@angular/core'
import * as AppStore from 'src/app/store'
import { Store } from '@ngrx/store'
import * as WorklistTaskAction from 'src/app/store/worklist/checker-maker-function'


@Component({
	selector: 'app-checker-maker-function',
	templateUrl: './checker-maker-function.component.html',
	styleUrls: ['./checker-maker-function.component.scss']
})
export class CheckerMakerFunctionComponent implements OnInit, OnDestroy {

	fromWhere = ''			// used in html
	isPending = false		// used in html
	show = false 			// used in html
	taskId: number
	isLoading = false

	constructor(
		private store: Store<AppStore.State>
	) { }

	ngOnInit() {
		this.store.select(WorklistTaskAction.getWorklistTask).subscribe(x => {
			this.fromWhere = x.from
			this.isPending = (x.task.status === 'Pending')
			this.taskId = x.task.id
			this.show = x.show
			this.isLoading = x.isLoading
		})
	}

	ngOnDestroy() {
		this.store.dispatch(WorklistTaskAction.ResetWorklistTask())
	}

	approve() {
		if (!!this.taskId) {
			this.store.dispatch(WorklistTaskAction.ApproveDialog())
		}
	}

	reject() {
		if (!!this.taskId) {
			this.store.dispatch(WorklistTaskAction.RejectReasonDialog())
		}
	}

	withdraw() {
		if (!!this.taskId) {
			this.store.dispatch(WorklistTaskAction.WithdrawDialog())
		}
	}
}
