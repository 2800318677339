import { box, Boxed, createFormGroupState, FormGroupState, updateGroup, validate } from 'ngrx-forms'
import { maxLength, required } from 'ngrx-forms/validation'
import { LoginUser } from 'src/app/models/common/common'
import { NavItem } from 'src/app/models/common/nav-item'
import * as GroupAction from '../../access-management/group'
import * as PolicyAction from '../../access-management/policy'
import * as UserAction from '../../access-management/user'
import * as PointAdjustmentFileUploadAction from '../../adjustment-function//point-adjustment-file-upload'
import * as CampaignAction from '../../campaign-management/campaign'
import * as ConditionalRewardAction from '../../campaign-management/conditional-reward'
import * as SimulatorAction from '../../campaign-management/simulator'
import * as FulfilmentPartnerAction from '../../catalogue-management/fulfilment-partner'
import * as ProductCatalogueAction from '../../catalogue-management/product-catalogue'
import * as CustomerManagementAction from '../../customer-management'
import * as DashboardAction from '../../dashboard/dashboard-overview'
import * as DashboardV2Action from '../../dashboard/dashboard-overview-v2'
import * as DashboardRedemptionAction from '../../dashboard/dashboard-redemption'
import * as FieldMappingAction from '../../param-settings/field-mapping'
import * as GlManagementAction from '../../param-settings/gl-provision'
import * as MccGroupAction from '../../param-settings/mcc-group'
import * as MerchantGroupAction from '../../param-settings/merchant-group'
import * as ProductTypeGroupAction from '../../param-settings/product-type-group'
import * as RewardPoolAction from '../../param-settings/reward-pool'
import * as TargetSegmentAction from '../../param-settings/target-segment'
import * as TcGroupAction from '../../param-settings/tc-group'
import * as AutoRedemptionAction from '../../redemption-settings/auto-redemption'
import * as OnTheSpotRedemptionAction from '../../redemption-settings/on-the-spot-redemption'
import * as RedeemPartnerAction from '../../redemption-settings/redeem-partner'
import * as RedeemRulesAction from '../../redemption-settings/redeem-rules'
import * as WaiverManagementAction from '../../redemption-settings/waiver-management'
import * as MyRequestAction from '../../worklist/my-request'
import * as TasklistAction from '../../worklist/tasklist'
import * as FileDownloadAction from '../../file-download/'

export interface State {
	loginUser: LoginUser
	previousRoute: string
	sideNavFlag: boolean
	sidenavItemList: NavItem[]
	message?: string
	selectForm: FormGroupState<SelectForm>
	currentActiveNav: string
}

export interface SelectForm {
	value: string
	selectionList: Boxed<string[]>
}

export const initialSelectFormValue: SelectForm = {
	value: '',
	selectionList: box([])
}

export const initialSelectForm = createFormGroupState('selectForm', initialSelectFormValue)

export const validateSelectForm = updateGroup<SelectForm>({
	value: validate([required, maxLength(255)]),
	selectionList: validate([required])
})

export const initialState: State = {
	loginUser: {
		accessToken: '',
		refreshToken: '',
		authorities: [],
		message: ''
	},
	previousRoute: 'auth',
	sideNavFlag: true,
	sidenavItemList: [
		{
			displayName: 'SIDE_NAV.DASHBOARD.DASHBOARD',
			functions: [
				'DASHBOARD_REWARD_POOL_GROUP_OVERVIEW',
				'DASHBOARD_PRODUCT_BUNDLING_OVERVIEW',
				'DASHBOARD_REWARD_POOL_CAMPAIGN_OVERVIEW',
				'DASHBOARD_REDEMPTION',
				'DASHBOARD_OVERVIEW'
			],
			iconName: 'home',
			route: '',
			children: [
				{
					displayName: 'SIDE_NAV.DASHBOARD.OVERVIEW', 
					functions: ['DASHBOARD_OVERVIEW'],
					iconName: '',
					route: '/dashboard/overview-v2',
					action: DashboardV2Action.InitialState()
				},
				{
					displayName: 'SIDE_NAV.DASHBOARD.REDEMPTION',
					functions: ['DASHBOARD_REDEMPTION'],
					iconName: '',
					route: '/dashboard/redemption',
					action: DashboardRedemptionAction.InitialState()
				}
			]
		},
		{
			displayName: 'SIDE_NAV.ACCESS_MANAGEMENT.ACCESS_MANAGEMENT',
			functions: ['USER_VIEW', 'GROUP_VIEW', 'FUNCTION_VIEW'],
			iconName: 'people',
			route: '',
			children: [
				{
					displayName: 'SIDE_NAV.ACCESS_MANAGEMENT.USER',
					functions: ['USER_VIEW'],
					iconName: '',
					route: '/access-management/user',
					action: UserAction.InitialState()
				},
				{
					displayName: 'SIDE_NAV.ACCESS_MANAGEMENT.GROUP',
					functions: ['GROUP_VIEW'],
					iconName: '',
					route: '/access-management/group',
					action: GroupAction.InitialState()
				},
				{
					displayName: 'SIDE_NAV.ACCESS_MANAGEMENT.FUNCTION',
					functions: ['FUNCTION_VIEW'],
					iconName: '',
					route: '/access-management/function',
					action: PolicyAction.InitialState()
				}
			]
		},
		{
			displayName: 'SIDE_NAV.WORKLIST.WORKLIST',
			functions: [
				'CAMPAIGN_APPROVE',
				'ADJUSTMENT_APPROVE',
				'CAMPAIGN_BUNDLING_APPROVE',
				'CONDITIONAL_REWARD_APPROVE',
				'CAMPAIGN_CREATE',
				'ADJUSTMENT_ACTION',
				'CAMPAIGN_BUNDLING_CREATE',
				'CONDITIONAL_REWARD_CREATE',
				'ACCOUNT_ADJUSTMENT_APPROVE',
				'ACCOUNT_ADJUSTMENT',
				'REDEMPTION_CANCELLATION_APPROVE',
				'REDEMPTION_CANCELLATION',
				'CAMPAIGN_UPDATE',
				'CAMPAIGN_BUNDLING_UPDATE',
				'CONDITIONAL_REWARD_UPDATE'
			],
			iconName: 'list_alt',
			route: '',
			children: [
				{
					displayName: 'SIDE_NAV.WORKLIST.TASKLIST',
					functions: [
						'CAMPAIGN_APPROVE',
						'ADJUSTMENT_APPROVE',
						'CAMPAIGN_BUNDLING_APPROVE',
						'CONDITIONAL_REWARD_APPROVE',
						'ACCOUNT_ADJUSTMENT_APPROVE',
						'REDEMPTION_CANCELLATION_APPROVE'
					],
					iconName: '',
					route: '/worklist/tasklist',
					action: TasklistAction.InitialState()
				},
				{
					displayName: 'SIDE_NAV.WORKLIST.MYREQUEST',
					functions: [
						'CAMPAIGN_APPROVE',
						'ADJUSTMENT_APPROVE',
						'CAMPAIGN_BUNDLING_APPROVE',
						'CONDITIONAL_REWARD_APPROVE',
						'CAMPAIGN_CREATE',
						'ADJUSTMENT_ACTION',
						'CAMPAIGN_BUNDLING_CREATE',
						'CONDITIONAL_REWARD_CREATE',
						'ACCOUNT_ADJUSTMENT_APPROVE',
						'ACCOUNT_ADJUSTMENT',
						'REDEMPTION_CANCELLATION_APPROVE',
						'REDEMPTION_CANCELLATION',
						'CAMPAIGN_UPDATE',
						'CAMPAIGN_BUNDLING_UPDATE',
						'CONDITIONAL_REWARD_UPDATE'
					],
					iconName: '',
					route: '/worklist/my-request',
					action: MyRequestAction.InitialState()
				}
			]
		},
		{
			displayName: 'SIDE_NAV.PARAMETER_SETTINGS.PARAMETER_SETTINGS',
			functions: ['TC_GROUP_VIEW',
				'MCC_GROUP_VIEW',
				'MERCHANT_GROUP_VIEW',
				'PRODUCT_TYPE_GROUP_VIEW',
				'GL_PROVISION_VIEW',
				'GL_REDEMPTION_VIEW',
				'REWARD_POOL_VIEW',
				'REWARD_POOL_GROUP_VIEW',
				'FIELD_MAPPING_VIEW',
				'TARGET_SEGMENT_CUSTOMER_VIEW',
				'TARGET_SEGMENT_CARD_VIEW',
				'TARGET_SEGMENT_ACCOUNT_VIEW'
			],
			iconName: 'assignment',
			route: '',
			children: [
				{
					displayName: 'SIDE_NAV.PARAMETER_SETTINGS.TC_GROUP',
					functions: ['TC_GROUP_VIEW'],
					iconName: '',
					route: '/parameter-settings/tc-group',
					action: TcGroupAction.InitialState()
				},
				{
					displayName: 'SIDE_NAV.PARAMETER_SETTINGS.MCC_GROUP',
					functions: ['MCC_GROUP_VIEW'],
					iconName: '',
					route: '/parameter-settings/mcc-group',
					action: MccGroupAction.InitialState()
				},
				{
					displayName: 'SIDE_NAV.PARAMETER_SETTINGS.MERCHANT_GROUP',
					functions: ['MERCHANT_GROUP_VIEW'],
					iconName: '',
					route: '/parameter-settings/merchant-group',
					action: MerchantGroupAction.InitialState()
				},
				{
					displayName: 'SIDE_NAV.PARAMETER_SETTINGS.PRODUCT_TYPE_GROUP',
					functions: ['PRODUCT_TYPE_GROUP_VIEW'],
					iconName: '',
					route: '/parameter-settings/product-type-group',
					action: ProductTypeGroupAction.InitialState()
				},
				{
					displayName: 'SIDE_NAV.PARAMETER_SETTINGS.GL_MANAGEMENT',
					functions: ['GL_PROVISION_VIEW', 'GL_REDEMPTION_VIEW'],
					iconName: '',
					route: '/parameter-settings/gl-management',
					action: GlManagementAction.InitialState()
				},
				{
					displayName: 'SIDE_NAV.PARAMETER_SETTINGS.REWARD_POOL',
					functions: ['REWARD_POOL_VIEW', 'REWARD_POOL_GROUP_VIEW'],
					iconName: '',
					route: '/parameter-settings/reward-pool',
					action: RewardPoolAction.InitialState()
				},
				{
					displayName: 'SIDE_NAV.PARAMETER_SETTINGS.FIELD_MAPPING',
					functions: ['FIELD_MAPPING_VIEW'],
					iconName: '',
					route: '/parameter-settings/field-mapping',
					action: FieldMappingAction.InitialState()
				},
				{
					displayName: 'SIDE_NAV.TARGET_SEGMENT.TARGET_SEGMENT',
					functions: ['TARGET_SEGMENT_CUSTOMER_VIEW', 'TARGET_SEGMENT_CARD_VIEW', 'TARGET_SEGMENT_ACCOUNT_VIEW'],
					iconName: '',
					route: '/parameter-settings/target-segment',
					action: TargetSegmentAction.InitialState()
				}
			]
		},
		{
			displayName: 'SIDE_NAV.CAMPAIGN_MANAGEMENT.CAMPAIGN_MANAGEMENT',
			functions: [
				'CAMPAIGN_PUBLISHED_VIEW', 'CAMPAIGN_ARCHIVED_VIEW', 'CAMPAIGN_CREATE',
				'CAMPAIGN_BUNDLING_PUBLISHED_VIEW', 'CAMPAIGN_BUNDLING_ARCHIVED_VIEW', 'CAMPAIGN_BUNDLING_CREATE',
				'CONDITIONAL_REWARD_PUBLISHED_VIEW', 'CONDITIONAL_REWARD_ARCHIVED_VIEW', 'CONDITIONAL_REWARD_CREATE',
				'SIMULATOR_VIEW', 'CONDITIONAL_REWARD_UPDATE'
			],
			iconName: 'dashboard',
			route: '',
			children: [
				{
					displayName: 'SIDE_NAV.CAMPAIGN_MANAGEMENT.CAMPAIGN',
					functions: [
						'CAMPAIGN_PUBLISHED_VIEW', 'CAMPAIGN_ARCHIVED_VIEW', 'CAMPAIGN_CREATE',
						'CAMPAIGN_BUNDLING_PUBLISHED_VIEW', 'CAMPAIGN_BUNDLING_ARCHIVED_VIEW', 'CAMPAIGN_BUNDLING_CREATE'
					],
					iconName: '',
					route: '/campaign-management/campaign',
					action: CampaignAction.InitialState()
				},
				{
					displayName: 'SIDE_NAV.CAMPAIGN_MANAGEMENT.CONDITIONAL_REWARD',
					functions: ['CONDITIONAL_REWARD_PUBLISHED_VIEW', 'CONDITIONAL_REWARD_ARCHIVED_VIEW', 'CONDITIONAL_REWARD_CREATE', 'CONDITIONAL_REWARD_UPDATE'],
					iconName: '',
					route: '/campaign-management/conditional-reward',
					action: ConditionalRewardAction.InitialState()
				},
				{
					displayName: 'SIDE_NAV.CAMPAIGN_MANAGEMENT.SIMULATOR',
					functions: ['SIMULATOR_VIEW'],
					iconName: '',
					route: '/campaign-management/simulator',
					action: SimulatorAction.InitialState()
				}
			]
		},
		{
			displayName: 'SIDE_NAV.REDEMPTION_SETTINGS.REDEMPTION_SETTINGS',
			functions: ['REDEEM_PARTNER_VIEW', 'REDEEM_RULES_VIEW', 'AUTO_REDEMPTION_VIEW', 'OTS_PARAMETER_VIEW', 'WAIVER_MANAGEMENT_VIEW'],
			iconName: 'loyalty',
			route: '',
			children: [
				{
					displayName: 'SIDE_NAV.REDEMPTION_SETTINGS.REDEEM_PARTNER',
					functions: ['REDEEM_PARTNER_VIEW'],
					iconName: '',
					route: '/redemption-settings/redeem-partner',
					action: RedeemPartnerAction.InitialState()
				},
				{
					displayName: 'SIDE_NAV.REDEMPTION_SETTINGS.REDEEM_RULES',
					functions: ['REDEEM_RULES_VIEW'],
					iconName: '',
					route: '/redemption-settings/redeem-rules',
					action: RedeemRulesAction.InitialState()
				},
				{
					displayName: 'SIDE_NAV.REDEMPTION_SETTINGS.WAIVER_MANAGEMENT',
					functions: ['WAIVER_MANAGEMENT_VIEW'],
					iconName: '',
					route: '/redemption-settings/waiver-management',
					action: WaiverManagementAction.InitialState()
				},
				{
					displayName: 'SIDE_NAV.REDEMPTION_SETTINGS.AUTO_REDEMPTION',
					functions: ['AUTO_REDEMPTION_VIEW'],
					iconName: '',
					route: '/redemption-settings/auto-redemption',
					action: AutoRedemptionAction.InitialState()
				},
				{
					displayName: 'SIDE_NAV.REDEMPTION_SETTINGS.ON_THE_SPOT_REDEMPTION',
					functions: ['OTS_PARAMETER_VIEW'],
					iconName: '',
					route: '/redemption-settings/on-the-spot-redemption',
					action: OnTheSpotRedemptionAction.InitialState()
				}
			]
		},
		{
			displayName: 'SIDE_NAV.CATALOGUE_MANAGEMENT.CATALOGUE_MANAGEMENT',
			functions: ['PRODUCT_CATALOGUE_VIEW', 'FULFILMENT_PARTNER_VIEW'],
			iconName: 'local_mall',
			route: '',
			children: [
				{
					displayName: 'SIDE_NAV.CATALOGUE_MANAGEMENT.PRODUCT_CATALOGUE',
					functions: ['PRODUCT_CATALOGUE_VIEW'],
					iconName: '',
					route: '/catalogue-management/product-catalogue',
					action: ProductCatalogueAction.InitialState()
				},
				{
					displayName: 'SIDE_NAV.CATALOGUE_MANAGEMENT.FULFILMENT_PARTNER',
					functions: ['FULFILMENT_PARTNER_VIEW'],
					iconName: '',
					route: '/catalogue-management/fulfilment-partner',
					action: FulfilmentPartnerAction.InitialState()
				}
			]
		},
		{
			displayName: 'SIDE_NAV.CUSTOMER_ACCOUNT_INFORMATION',
			functions: ['BASIC_INFO_VIEW'],
			iconName: 'contact_support',
			route: '/customer-management',
			action: CustomerManagementAction.InitialState()
		},
		{
			displayName: 'SIDE_NAV.ADJUSTMENT.ADJUSTMENT',
			functions: ['ADJUSTMENT_ACTION'],
			iconName: 'tune',
			route: '',
			children: [
				{
					displayName: 'SIDE_NAV.ADJUSTMENT.POINT_ADJUSTMENT_FILE_UPLOAD',
					functions: ['ADJUSTMENT_ACTION'],
					iconName: '',
					route: '/adjustment-function/point-adjustment-file-upload',
					action: PointAdjustmentFileUploadAction.InitialState()
				}
			]
		},
		{
			displayName: 'SIDE_NAV.FILE_DOWNLOAD.FILE_DOWNLOAD',
			functions: ['TARGET_SEGMENT_CUSTOMER_CREATE', 'TARGET_SEGMENT_CUSTOMER_UPDATE', 'TARGET_SEGMENT_CUSTOMER_DELETE',
				'TARGET_SEGMENT_ACCOUNT_CREATE', 'TARGET_SEGMENT_ACCOUNT_UPDATE', 'TARGET_SEGMENT_ACCOUNT_DELETE',
				'TARGET_SEGMENT_CARD_CREATE', 'TARGET_SEGMENT_CARD_UPDATE', 'TARGET_SEGMENT_CARD_DELETE',
				'ADJUSTMENT_ACTION', 'ACCOUNT_ADJUSTMENT'],
			iconName: 'file',
			iconType: 'svg',
			route: '/file-download',
			action: FileDownloadAction.InitialState()
		}
	],
	currentActiveNav: '',
	selectForm: initialSelectForm
}

/*
	Icon Reference : https://material.io/resources/icons/?icon=local_mall&style=outline

	Dashboard - Home
	Access Management - People
	Worklist - List alt
	Parameter Settings - Assignment
	Campaign - Dashboard
	Redemption Settings - Loyalty
	Catalogue Management - Local mall
	Customer/ Account Information - Contact support
	Adjustment - Tune
*/
