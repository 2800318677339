import { Action, createReducer, on } from '@ngrx/store'
import * as FieldMappingAction from './field-mapping.actions'
import * as FieldMappingState from './field-mapping.state'
import { reset, setValue } from 'ngrx-forms'

export const reducer = createReducer(
	FieldMappingState.initialState,
	on(FieldMappingAction.InitialState, () => FieldMappingState.initialState),
	on(FieldMappingAction.Reset, state => ({
		...state
	})),
	on(FieldMappingAction.SetId, (state, { id }) => ({
		...state,
		id
	})),
	on(FieldMappingAction.RestError, state => ({
		...state,
		isLoading: false
	})),
	on(FieldMappingAction.List, state => ({
		...state,
		isLoading: true,
	})),
	on(FieldMappingAction.ListSuccess, (state, { payload }) => ({
		...state,
		isLoading: false,
		fieldMappingListResponse: payload
	})),
	on(FieldMappingAction.View, state => ({
		...state,
		isLoading: true
	})),
	on(FieldMappingAction.ViewSuccess, (state, { payload }) => ({
			...state,
			isLoading: false,
			fieldMappingViewResponse: payload,
			fieldDetailForm: reset(setValue(state.fieldDetailForm, FieldMappingState.initialFieldDetailFormValue))
	})),
	on(FieldMappingAction.UpdateValueDialog, (state, { id, label, value }) => {
		const fieldDetailFormView = {
			fieldValue: value
		}

		return ({
			...state,
			isLoading: false,
			fieldDetailForm: FieldMappingState.validateFieldDetailForm(setValue(state.fieldDetailForm, {
				fieldValue: value
			})),
			fieldDetailFormView
		})
	}),
	on(FieldMappingAction.UpdateValue, (state, {id, value}) => ({
			...state,
			isLoading: true
	})),
	on(FieldMappingAction.UpdateValueSuccess, (state) => ({
			...state,
			isLoading: false
	})),
	on(FieldMappingAction.UpdateMasking, (state, {id, masking}) => ({
			...state,
			isLoading: true
	})),
	on(FieldMappingAction.UpdateMaskingSuccess, (state) => ({
			...state,
			isLoading: false
	}))
)

export function Reducer(state: FieldMappingState.State = FieldMappingState.initialState, action: Action) {
	return reducer(state, action)
}
