import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { UrlRoutes } from './models/common/route'

const routes: UrlRoutes[] = [
	{
		path: 'auth',
		loadChildren: () => import('./components/authentication/authentication.module').then(mod => mod.AuthenticationModule)
	},
	{
		path: '',
		loadChildren: () => import('./components/navigation/navigation.module').then(mod => mod.NavigationModule),
	},
	{
		path: '**', redirectTo: ''
	}
]

@NgModule({
	imports: [RouterModule.forRoot(routes, { useHash: true })],
	exports: [RouterModule]
})
export class AppRoutingModule { }
