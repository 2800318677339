import { createAction, props } from '@ngrx/store'
import { KeyValue } from '@angular/common'
import { MerchantGroupListResponse } from 'src/app/models/param-settings/mer-group/merchant-group-list'
import { MerchantGroupViewResponse } from 'src/app/models/param-settings/mer-group/merchant-group-view'
import { MerchantGroupPrefixAndList } from 'src/app/models/param-settings/mer-group/merchant-group-create'

export const InitialState = createAction('[MerchantGroup] InitialState')
export const Reset = createAction('[MerchantGroup] Reset')
export const SetId = createAction('[MerchantGroup] SetId', props<{ code: string }>())

export const GoList = createAction('[MerchantGroup] GoList')
export const GoView = createAction('[MerchantGroup] GoView')
export const GoCreate = createAction('[MerchantGroup] GoCreate')
export const GoUpdate = createAction('[MerchantGroup] GoUpdate')

export const GoBackDialog = createAction('[MerchantGroup] GoBackDialog', props<{ action: string }>())
export const CreateDialog = createAction('[MerchantGroup] CreateDialog', props<{ content: string, payload: MerchantGroupPrefixAndList }>())
export const UpdateDialog = createAction('[MerchantGroup] UpdateDialog', props<{ payload: string[] }>())
export const DeleteDialog = createAction('[MerchantGroup] DeleteDialog')
export const CloseDialog = createAction('[MerchantGroup] CloseDialog')

export const RestError = createAction('[MerchantGroup] RestError', props<{ message: string, manualThrow: boolean }>())

export const List = createAction('[MerchantGroup] List')
export const ListSuccess = createAction('[MerchantGroup] ListSuccess', props<{ payload: MerchantGroupListResponse }>())

export const View = createAction('[MerchantGroup] View')
export const ViewSuccess = createAction('[MerchantGroup] ViewSuccess', props<{ payload: MerchantGroupViewResponse }>())

export const Create = createAction('[MerchantGroup] Create', props<{ payload: MerchantGroupPrefixAndList }>())
export const CreateSuccess = createAction('[MerchantGroup] CreateSuccess', props<{ payload: string }>())

export const Update = createAction('[MerchantGroup] Update', props<{ payload: string[] }>())
export const UpdateSuccess = createAction('[MerchantGroup] UpdateSuccess', props<{ payload: string }>())

export const Delete = createAction('[MerchantGroup] Delete')
export const DeleteSuccess = createAction('[MerchantGroup] DeleteSuccess', props<{ payload: string }>())

export const ResourceListCreate = createAction('[MerchantGroup] ResourceListCreate')
export const ResourceListCreateSuccess = createAction('[MerchantGroup] ResourceListCreateSuccess', props<{ payload: KeyValue<string, string>[] }>())

export const ResourceListUpdate = createAction('[MerchantGroup] ResourceListUpdate')
export const ResourceListUpdateSuccess = createAction('[MerchantGroup] ResourceListUpdateSuccess', props<{ payload: KeyValue<string, string>[] }>())

export const DuplicateDialog = createAction('[MerchantGroup] DuplicateDialog')
export const Duplicate = createAction('[MerchantGroup] Duplicate')
export const DuplicateSuccess = createAction('[MerchantGroup] DuplicateSuccess', props<{ payload: string }>())
