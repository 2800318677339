import { KeyValue } from '@angular/common'
import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { FormControl } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef, MatSelect, MatSelectChange } from '@angular/material'
import { Store } from '@ngrx/store'
import { Boxed, FormGroupState } from 'ngrx-forms'
import { Observable, ReplaySubject, Subject, Subscription } from 'rxjs'
import * as AppStore from 'src/app/store'
import { AssignedWaiverForm, WaiverRewardPool } from 'src/app/store/redemption-settings/waiver-management'
import * as WaiverManagementAction from 'src/app/store/redemption-settings/waiver-management/waiver-management.actions'
import * as WaiverManagementSelector from 'src/app/store/redemption-settings/waiver-management/waiver-management.selectors'
import { Util } from 'src/app/models/util/util'
import { FilterPredicate } from 'src/app/models/util/filter-predicate'

@Component({
	selector: 'app-assigned-waiver',
	templateUrl: './assigned-waiver.component.html',
	styleUrls: ['./assigned-waiver.component.scss']
})
export class AssignedWaiverComponent implements OnInit, OnDestroy {

	isLoading = false
	action: string
	formState$: Observable<FormGroupState<AssignedWaiverForm>>

	rewardPoolList: KeyValue<string, string>[] = []
	productTypeList: KeyValue<string, string>[] = []
	productTypeGroupList: KeyValue<string, string>[] = []
	cardHolderList: KeyValue<string, string>[] = []
	glRedemptionList: KeyValue<string, string>[] = []
	tcGroupList: KeyValue<string, string>[] = []
	subs: Subscription
	assignedWaiverFormView: AssignedWaiverForm
	isRewardPool: boolean
	waiverRewardPools$: Observable<WaiverRewardPool[]>
	rewardPoolType: string
	selectedValue: any = ''

	public dropDownCtrl: FormControl = new FormControl()
	public dropDownCtrlRewardPoolGroup: FormControl = new FormControl(this.selectedValue)
	public dropDownCtrlProductTypeGroup: FormControl = new FormControl(this.selectedValue)
	public dropDownCtrlCardHolder: FormControl = new FormControl(this.selectedValue)
	public dropDownCtrlGlRedemption: FormControl = new FormControl(this.selectedValue)
	public dropDownCtrlAnnualFee: FormControl = new FormControl(this.selectedValue)
	public dropDownCtrlAnnualFeeRedemption: FormControl = new FormControl(this.selectedValue)


	/** list of records filtered by search keyword */
	public filteredProductTypes: ReplaySubject<KeyValue<string, string>[]> = new ReplaySubject<KeyValue<string, string>[]>(1)
	public filteredRewardPool: ReplaySubject<KeyValue<string, string>[]> = new ReplaySubject<KeyValue<string, string>[]>(1)
	public filteredProductTypeGroup: ReplaySubject<KeyValue<string, string>[]> = new ReplaySubject<KeyValue<string, string>[]>(1)
	public filteredCardHolder: ReplaySubject<KeyValue<string, string>[]> = new ReplaySubject<KeyValue<string, string>[]>(1)
	public filteredGlRedemption: ReplaySubject<KeyValue<string, string>[]> = new ReplaySubject<KeyValue<string, string>[]>(1)
	public filteredAnnualFee: ReplaySubject<KeyValue<string, string>[]> = new ReplaySubject<KeyValue<string, string>[]>(1)
	public filteredAnnualFeeRedemption: ReplaySubject<KeyValue<string, string>[]> = new ReplaySubject<KeyValue<string, string>[]>(1)

	filteredAnnualFeeSelected: string[]
	filteredAnnualFeeRedemptionSelected: string[]

	@ViewChild('multipleSelect', { static: true }) multipleSelect: MatSelect

	/** Subject that emits when the component has been destroyed. */
	protected _onDestroy = new Subject<void>()

	constructor(
		private store: Store<AppStore.State>,
		private dialogRef: MatDialogRef<AssignedWaiverComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		this.subs = this.store.select(WaiverManagementSelector.getWaiverManagement).subscribe(x => {
			this.isLoading = x.isLoading
			this.action = x.action

			const waiverManagementData = x.waiverManagementData
			if (waiverManagementData) {
				this.rewardPoolList = waiverManagementData.rewardPoolList
				this.productTypeList = waiverManagementData.productTypeList
				this.productTypeGroupList = waiverManagementData.productTypeGroupList
				this.cardHolderList = waiverManagementData.cardHolderList
				this.glRedemptionList = waiverManagementData.glRedemptionList
				this.tcGroupList = waiverManagementData.tcGroupList
			}
			this.assignedWaiverFormView = x.assignedWaiverFormView
			if (x.assignedWaiverForm.controls.rewardPoolId.value.includes('I')) {
				this.isRewardPool = true
			} else {
				this.isRewardPool = false
			}
		})

		this.formState$ = this.store.select(({ waiverManagement }) => waiverManagement.assignedWaiverForm)
		this.waiverRewardPools$ = this.store.select(WaiverManagementSelector.getWaiverRewardPools)
	}

	ngOnInit() {
		// load the initial list
		this.filteredProductTypes.next(this.productTypeList.slice())
		this.filteredProductTypeGroup.next(this.productTypeGroupList.slice())
		this.filteredRewardPool.next(this.rewardPoolList.slice())
		this.filteredCardHolder.next(this.cardHolderList.slice())
		this.filteredGlRedemption.next(this.glRedemptionList.slice())
		this.filteredAnnualFee.next(this.tcGroupList.slice())
		this.filteredAnnualFeeRedemption.next(this.tcGroupList.slice())

		this.formState$.subscribe(o => {
			if (o) {
				this.filteredAnnualFeeSelected = o.controls.feeTc.value.value
				this.filteredAnnualFeeRedemptionSelected = o.controls.feeRedemptionTc.value.value
				this.filteredAnnualFee.next(this.tcGroupList.filter(o => !this.filteredAnnualFeeRedemptionSelected.includes(o.key)))
				this.filteredAnnualFeeRedemption.next(this.tcGroupList.filter(o => !this.filteredAnnualFeeSelected.includes(o.key)))
			}
		})

		this.dropDownCtrlRewardPoolGroup.valueChanges
			.subscribe(value => {
				const maxChar = 255
				if (value.length > maxChar) {
					const newValue = value.slice(0, maxChar)
					this.dropDownCtrlRewardPoolGroup.reset('', { emitEvent: false })
					this.dropDownCtrlRewardPoolGroup.setValue(newValue)

				} else {
					this.searchRewardPool(value)
				}
			})

		this.dropDownCtrlGlRedemption.valueChanges
			.subscribe(value => {
				const maxChar = 255
				if (value.length > maxChar) {
					const newValue = value.slice(0, maxChar)
					this.dropDownCtrlGlRedemption.reset('', { emitEvent: false })
					this.dropDownCtrlGlRedemption.setValue(newValue)

				} else {
					this.searchGlRedemption(value)
				}
			})

		this.dropDownCtrl.valueChanges
			.subscribe(value => {
				const maxChar = 255
				if (value.length > maxChar) {
					const newValue = value.slice(0, maxChar)
					this.dropDownCtrl.reset('', { emitEvent: false })
					this.dropDownCtrl.setValue(newValue)

				} else {
					this.search(value)
				}
			})

		this.dropDownCtrlProductTypeGroup.valueChanges
			.subscribe(value => {
				const maxChar = 255
				if (value.length > maxChar) {
					const newValue = value.slice(0, maxChar)
					this.dropDownCtrlProductTypeGroup.reset('', { emitEvent: false })
					this.dropDownCtrlProductTypeGroup.setValue(newValue)

				} else {
					this.searchProductTypeGroup(value)
				}
			})

		this.dropDownCtrlCardHolder.valueChanges
			.subscribe(value => {
				const maxChar = 255
				if (value.length > maxChar) {
					const newValue = value.slice(0, maxChar)
					this.dropDownCtrlCardHolder.reset('', { emitEvent: false })
					this.dropDownCtrlCardHolder.setValue(newValue)

				} else {
					this.searchCardHolder(value)
				}
			})

		this.dropDownCtrlAnnualFee.valueChanges
		.subscribe(value => {
			const maxChar = 255
			if (value.length > maxChar) {
				const newValue = value.slice(0, maxChar)
				this.dropDownCtrlCardHolder.reset('', { emitEvent: false })
				this.dropDownCtrlCardHolder.setValue(newValue)

			} else {
				this.searchAnnualFee(value)
			}
		})

		this.dropDownCtrlAnnualFeeRedemption.valueChanges
		.subscribe(value => {
			const maxChar = 255
			if (value.length > maxChar) {
				const newValue = value.slice(0, maxChar)
				this.dropDownCtrlCardHolder.reset('', { emitEvent: false })
				this.dropDownCtrlCardHolder.setValue(newValue)

			} else {
				this.searchAnnualFeeRedemption(value)
			}
		})
	}

	ngOnDestroy() {
		this.subs.unsubscribe()
	}

	trackByIndex(index: number) {
		return index
	}

	search(search: string) {
		if (!this.productTypeList) {
			return
		}
		if (!search) {
			this.filteredProductTypes.next(this.productTypeList.slice())
			return
		} else {
			search = search.toLowerCase()
		}
		// filter the records
		this.filteredProductTypes.next(
			this.productTypeList.filter(productType => FilterPredicate.isIdenticalTo(search)([productType.value]))
		)
	}

	clearSearch() {
		this.filteredProductTypes.next(this.productTypeList.slice())
	}

	searchRewardPool(search: string) {
		if (!this.rewardPoolList) {
			return
		}
		if (!search) {
			this.filteredRewardPool.next(this.rewardPoolList.slice())
			return
		} else {
			search = search.toLowerCase()
		}
		// filter the records
		this.filteredRewardPool.next(
			this.rewardPoolList.filter(rewardPool => FilterPredicate.isIdenticalTo(search)([rewardPool.value]))
		)
	}
	clearSearchRewardPool() {
		this.filteredRewardPool.next(this.rewardPoolList.slice())
	}

	searchProductTypeGroup(search: string) {
		if (!this.productTypeGroupList) {
			return
		}
		if (!search) {
			this.filteredProductTypeGroup.next(this.productTypeGroupList.slice())
			return
		} else {
			search = search.toLowerCase()
		}
		// filter the records
		this.filteredProductTypeGroup.next(
			this.productTypeGroupList.filter(productTypeGroup => FilterPredicate.isIdenticalTo(search)([productTypeGroup.value]))
		)
	}

	clearSearchProductTypeGroup() {
		this.filteredProductTypeGroup.next(this.productTypeGroupList.slice())
	}

	searchCardHolder(search: string) {
		if (!this.cardHolderList) {
			return
		}
		if (!search) {
			this.filteredCardHolder.next(this.cardHolderList.slice())
			return
		} else {
			search = search.toLowerCase()
		}
		// filter the records
		this.filteredCardHolder.next(
			this.cardHolderList.filter(cardHolder => FilterPredicate.isIdenticalTo(search)([cardHolder.value]))
		)
	}

	clearSearchCardHolder() {
		this.filteredCardHolder.next(this.cardHolderList.slice())
	}

	searchGlRedemption(search: string) {
		if (!this.glRedemptionList) {
			return
		}
		if (!search) {
			this.filteredGlRedemption.next(this.glRedemptionList.slice())
			return
		} else {
			search = search.toLowerCase()
		}
		// filter the records
		this.filteredGlRedemption.next(
			this.glRedemptionList.filter(glRedemption => FilterPredicate.isIdenticalTo(search)([glRedemption.value]))
		)
	}

	clearSearchGlRedemption() {
		this.filteredGlRedemption.next(this.glRedemptionList.slice())
	}

	searchAnnualFee(search: string) {
		if (!this.tcGroupList) {
			return
		}
		if (!search) {
			this.filteredAnnualFee.next(this.tcGroupList.slice())
			return
		} else {
			search = search.toLowerCase()
		}
		// filter the records
		this.filteredAnnualFee.next(
			this.tcGroupList.filter(tcGroupList => !this.filteredAnnualFeeRedemptionSelected.includes(tcGroupList.key) && FilterPredicate.isIdenticalTo(search)([tcGroupList.value]))
		)
	}

	searchAnnualFeeRedemption(search: string) {
		if (!this.tcGroupList) {
			return
		}
		if (!search) {
			this.filteredAnnualFeeRedemption.next(this.tcGroupList.slice())
			return
		} else {
			search = search.toLowerCase()
		}
		// filter the records
		this.filteredAnnualFeeRedemption.next(
			this.tcGroupList.filter(tcGroupList => !this.filteredAnnualFeeSelected.includes(tcGroupList.key) && FilterPredicate.isIdenticalTo(search)([tcGroupList.value]))
		)
	}

	clearSearchAnnualFee() {
		this.filteredAnnualFee.next(this.tcGroupList.filter(o => !this.filteredAnnualFeeRedemptionSelected.includes(o.key)).slice())
	}

	clearSearchAnnualFeeRedemption() {
		this.filteredAnnualFeeRedemption.next(this.tcGroupList.filter(o => !this.filteredAnnualFeeSelected.includes(o.key)).slice())
	}

	get isView(): boolean { return this.action === 'VIEW' }
	get isCreate(): boolean { return this.action === 'CREATE' }
	get isUpdate(): boolean { return this.action === 'UPDATE' }

	cancel() {
		this.dialogRef.close()
	}

	submit() {
		this.store.dispatch(WaiverManagementAction.CreateWaiver())
	}

	update() {
		this.store.dispatch(WaiverManagementAction.UpdateWaiver())
	}

	getRewardPoolValue(value: string): string {
		const result = this.rewardPoolList.find(x => x.key === value)
		return result ? result.value : ''
	}

	getProductTypeValue(value: Boxed<string[]>): string {
		let resp = ''

		if (value && value.value) {
			value.value.forEach(x => {
				const result = this.productTypeList.find(y => x === y.key)
				if (result) {
					if (resp) { resp += ', \n' }
					resp += result.value
				}
			})
		}

		return resp
	}

	getProductTypeGroupValue(value: Boxed<string[]>): string {
		let resp = ''

		if (value && value.value) {
			value.value.forEach(x => {
				const result = this.productTypeGroupList.find(y => x === y.key)
				if (result) {
					if (resp) { resp += ', \n' }
					resp += result.value
				}
			})
		}

		return resp
	}

	getCardHolderValue(value: string): string {
		const result = this.cardHolderList.find(x => x.key === value)
		return result ? result.value : ''
	}

	getGlRedemptionValue(value: number): string {
		const result = this.glRedemptionList.find(x => x.key === value.toString())
		return result ? result.value : ''
	}

	getAnnualFeeValue(value: Boxed<string[]>): string {
		let resp = ''

		if (value && value.value) {
			value.value.forEach(x => {
				const result = this.tcGroupList.find(y => x === y.key)
				if (result) {
					if (resp) { resp += ', \n' }
					resp += result.value
				} else {
					return ''
				}
			})
		}

		return resp
	}

	getAnnualFeeRedemptionValue(value: Boxed<string[]>): string {
		let resp = ''

		if (value && value.value) {
			value.value.forEach(x => {
				const result = this.tcGroupList.find(y => x === y.key)
				if (result) {
					if (resp) { resp += ', \n' }
					resp += result.value
				} else {
					return ''
				}
			})
		}

		return resp
	}

	checkValidation() {
		let validation = true
		let rewardPoolFormStateValidation = false

		this.formState$.subscribe(x => {
			validation = Util.objectComparatorNLayer(x.value, this.assignedWaiverFormView)
		})
		if (!this.isRewardPool) {
			this.waiverRewardPools$.subscribe(x => {
				rewardPoolFormStateValidation = x.some(v => v.form.isInvalid === true)
			})
		}
		return validation || rewardPoolFormStateValidation
	}

	onChangeRewardPool(ev: MatSelectChange) {
		if (ev && ev.value) {
			const rewardPoolType = ev.value.split('_')[0]
			if (rewardPoolType === 'I') {
				this.store.dispatch(WaiverManagementAction.OnChangeRewardPool())
			} else {
				this.store.dispatch(WaiverManagementAction.OnChangeRewardPoolGroup())
			}
		}
	}
}
