<form novalidate [ngrxFormState]="formState$ | async" fxLayout="column">
  <div *ngIf="isView" fxLayout="column">
    <mat-form-field fxFlex>
      <input matInput value="{{getBirthdayValue((formState$ | async).controls.value.value)}}" type="text"
        maxlength="255" readonly>
    </mat-form-field>

    <div *ngIf="(formState$ | async).controls.value.value === 'X_DAYS_BEFORE_X_DAYS_AFTER'" fxLayout="column">
      <div fxLayout="row" fxLayoutGap="5px" fxFlex>
        <mat-form-field fxFlex="50">
          <span matSuffix>&nbsp; {{ 'CAMPAIGN.DAYS_BEFORE' | translate }}</span>
          <input matInput style="text-align:right" value="{{(formState$ | async).controls.xDaysBefore.value}}"
            type="text" maxlength="255" readonly>
        </mat-form-field>
        <mat-form-field fxFlex="50">
          <span matSuffix>&nbsp; {{ 'CAMPAIGN.DAYS_AFTER' | translate }}</span>
          <input matInput style="text-align:right" value="{{(formState$ | async).controls.xDaysAfter.value}}"
            type="text" maxlength="255" readonly>
        </mat-form-field>
      </div>
    </div>
  </div>

  <div *ngIf="!isView" fxLayout="column">
    <mat-form-field fxFlex>
      <mat-select [ngrxFormControlState]="(formState$ | async).controls.value"
        (selectionChange)="onChangeBirthday($event)">
        <mat-option *ngFor="let i of birthdayList" [value]="i.key">
          {{i.value}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div *ngIf="(formState$ | async).controls.value.value === 'X_DAYS_BEFORE_X_DAYS_AFTER'" fxLayout="column">
      <div fxLayout="row" fxLayoutGap="5px" fxFlex>
        <mat-form-field fxFlex="50">
          <span matSuffix>&nbsp; {{ 'CAMPAIGN.DAYS_BEFORE' | translate }}</span>
          <input matInput #xDaysBefore style="text-align:right" [ngrxFormControlState]="(formState$ | async).controls.xDaysBefore"
            onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57"
            type="text" (input)="xDaysBeforeChange(xDaysBefore.value)" maxlength="3">
          <mat-error *ngIf="(formState$ | async).errors._xDaysBefore">
            <app-error-validation [errors]="(formState$ | async).errors._xDaysBefore">
            </app-error-validation>
          </mat-error>
        </mat-form-field>
        <mat-form-field fxFlex="50">
          <input matInput #xDaysAfter style="text-align:right" [ngrxFormControlState]="(formState$ | async).controls.xDaysAfter"
            onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57"
            type="text" (input)="xDaysAfterChange(xDaysAfter.value)" maxlength="3">
          <span matSuffix>&nbsp; {{ 'CAMPAIGN.DAYS_AFTER' | translate }}</span>
          <mat-error *ngIf="(formState$ | async).errors._xDaysAfter">
            <app-error-validation [errors]="(formState$ | async).errors._xDaysAfter">
            </app-error-validation>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>
</form>