import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { MatPaginator, MatTableDataSource } from '@angular/material'
import { BucketDetails } from 'src/app/models/customer-management/customer-accumulation-bucket-detail'

@Component({
	selector: 'app-account-loyalty-accumulation-bucket',
	templateUrl: './account-loyalty-accumulation-bucket.component.html',
	styleUrls: ['./account-loyalty-accumulation-bucket.component.scss']
})
export class AccountLoyaltyAccumulationBucketComponent implements OnInit {

	@Input() isLoading: boolean
	@Input() headerColumns: Array<string>
	@Input() bucketList: BucketDetails[]
	@Input() displayedColumns: Array<any>
	@Input() rewardPoolName: string

	dataSource: MatTableDataSource<BucketDetails>
	@ViewChild('matPaginatorAccumulationBucket', { static: true }) paginator: MatPaginator

	constructor() {
		this.dataSource = new MatTableDataSource()
	}

	ngOnInit() {
		this.dataSource.data = this.bucketList
		this.dataSource.paginator = this.paginator
	}

}
