import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Action, Store } from '@ngrx/store'
import { Observable, of } from 'rxjs'
import { catchError, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators'
import { Response } from 'src/app/models/common/http'
import { AdjustmentFunctionService } from 'src/app/services/adjustment-function/adjustment-function.service'
import * as AppStore from 'src/app/store/'
import * as CommonAction from '../../common/common/common.actions'
import * as PointAdjustmentFileUploadAction from './point-adjustment-file-upload.actions'
import { AdjustmentFile } from 'src/app/models/adjustment/adjustment-list'
import { KeyValue } from '@angular/common'
import { AdjustmentFileInfo, AdjustmentFileContent } from 'src/app/models/adjustment/adjustment'
import { Downloader } from 'src/app/models/util/downloader'
import * as WorklistTask from 'src/app/store/worklist/checker-maker-function/checker-maker-function.state'
import * as PointsAdjustmentFileUpload from 'src/app/store/adjustment-function/point-adjustment-file-upload/point-adjustment-file-upload.state'
import { POLICY } from 'src/app/models/common/constant'

@Injectable()
export class Effects {

	constructor(
		private action$: Actions,
		private router: Router,
		private adjustmentFunctionService: AdjustmentFunctionService,
		private store: Store<AppStore.State>
	) { }

	InitialState$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(PointAdjustmentFileUploadAction.InitialState),
			tap(() => {
				this.router.navigate(['adjustment-function/point-adjustment-file-upload'])
			})
		), { dispatch: false }
	)

	GoView$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(PointAdjustmentFileUploadAction.GoView),
			tap(() => {
				this.router.navigate(['adjustment-function/point-adjustment-file-view'])
			})
		), { dispatch: false }
	)

	GoList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(PointAdjustmentFileUploadAction.GoList),
			tap(() => {
				this.router.navigate(['adjustment-function/point-adjustment-file-upload'])
			})
		), { dispatch: false }
	)

	RestError$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(PointAdjustmentFileUploadAction.RestError),
			map(({ message, manualThrow }) => {
				return CommonAction.RestError({ message, manualThrow })
			})
		)
	)

	CreatePointAdjustmentFileUpload$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(PointAdjustmentFileUploadAction.CreatePointAdjustmentFileUpload),
			mergeMap(({ payload }) => this.adjustmentFunctionService.pointAdjustmentFileUpload(payload)
				.pipe(map((response: Response) => {

					const success: boolean = response.success
					if (success) {
						return PointAdjustmentFileUploadAction.CreatePointAdjustmentFileUploadSuccess()
					} else { return PointAdjustmentFileUploadAction.RestError(response as any) }

				}), catchError(this.catcher)
				)
			)
		)
	)

	List$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(PointAdjustmentFileUploadAction.FileList),
			mergeMap(({ payload }) => {
				return this.adjustmentFunctionService.getAdjustmentFunctionFileList({ resource: payload }).pipe(map((response: Response) => {
					const success: boolean = response.success
					const respPayload: AdjustmentFile[] = response.payload

					if (success) {
						return PointAdjustmentFileUploadAction.FileListSuccess({ payload: respPayload })
					} else { return PointAdjustmentFileUploadAction.RestError(response as any) }
				}), catchError(this.catcher)
				)
			})
		)
	)

	FileContent$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(PointAdjustmentFileUploadAction.FileContent),
			withLatestFrom(this.store.select(state => [ state.pointAdjustmentFileUpload, state.worklistTask ])),
			mergeMap(([x, [ p, w ]]) => {
				return this.adjustmentFunctionService.getAdjustmentFunctionFileContent({ id: (p as PointsAdjustmentFileUpload.State).dataId, policyCode: x.payload as POLICY})
				.pipe(map((response: Response) => {
					const success: boolean = response.success
					const respPayload: AdjustmentFileContent[] = response.payload

					if (success) {
						return PointAdjustmentFileUploadAction.FileContentSuccess({ payload: respPayload })
					} else { return PointAdjustmentFileUploadAction.RestError(response as any) }
				}), catchError(this.catcher)
				)
			})
		)
	)

	ResourceListCreate$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(PointAdjustmentFileUploadAction.ResourceListCreate),
			mergeMap(() => {
				return this.adjustmentFunctionService.getResourceList().pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: KeyValue<string, string>[] = response.payload
					if (success) {
						return PointAdjustmentFileUploadAction.ResourceListCreateSuccess({ payload })
					} else { return PointAdjustmentFileUploadAction.RestError(response as any) }
				}), catchError(this.catcher))
			})
		)
	)

	CreatePointAdjustmentFileUploadSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(PointAdjustmentFileUploadAction.CreatePointAdjustmentFileUploadSuccess),
			mergeMap(() => {
				const btnRefresh = document.getElementById('btnRefresh')
				if (!!btnRefresh) {
					btnRefresh.click()
				}
				return []
			})
		)
	)

	DownloadFile$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(PointAdjustmentFileUploadAction.DownloadFile),
			mergeMap(({ payload }) => {
				return this.adjustmentFunctionService.getAdjustmentFunctionFile({ filename: payload }).pipe(map((response: Response) => {
					const success: boolean = response.success
					const respPayload: AdjustmentFileInfo = response.payload
					if (success) {
						return PointAdjustmentFileUploadAction.DownloadFileSuccess({ payload: respPayload })
					} else { return PointAdjustmentFileUploadAction.RestError(response as any) }
				}), catchError(this.catcher)
				)
			})
		)
	)

	DownloadFileSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(PointAdjustmentFileUploadAction.DownloadFileSuccess),
			mergeMap(({ payload }) => {
				Downloader.anchorDownloader(payload.filename, payload.file)
				return []
			})
		)
	)

	catcher = (error) => {
		return (of(PointAdjustmentFileUploadAction.RestError({ message: error.message, manualThrow: error.manualThrow })))
	}
}
