import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'
import { FormGroupState } from 'ngrx-forms'
import { InferredStringFormState } from 'ngrx-forms/src/state'
import { Observable, Subscription } from 'rxjs'
import * as AppStore from 'src/app/store'
import * as CampaignAction from 'src/app/store/campaign-management/campaign/campaign.actions'
import * as CampaignSelectors from 'src/app/store/campaign-management/campaign/campaign.selectors'
import { CampaignTier, CampaignTierForm } from 'src/app/store/campaign-management/campaign/campaign.state'

@Component({
	selector: 'app-campaign-tier',
	templateUrl: './campaign-tier.component.html',
	styleUrls: ['./campaign-tier.component.scss']
})
export class CampaignTierComponent implements OnInit, OnDestroy {

	@Input() campaignTier: CampaignTier
	@Input() index: string

	action: string
	type: string
	rewardMethod: string
	transactionMethod: string
	formState$: Observable<FormGroupState<CampaignTierForm>>
	subs: Subscription

	constructor(
		private store: Store<AppStore.State>
	) {
		this.subs = this.store.select(CampaignSelectors.getCampaign).subscribe(x => {
			this.action = x.campaignRulesetAction
			this.type = x.rulesetRewardForm.value.type
			this.rewardMethod = x.rulesetRewardForm.value.rewardMethod
			this.transactionMethod = x.rulesetRewardForm.value.transactionMethod
		})
	}

	get isView(): boolean { return this.action === 'VIEW' }

	ngOnInit() {
		this.formState$ = this.store.select(CampaignSelectors.getCampaignTier, this.campaignTier.id)
	}

	ngOnDestroy() {
		this.subs.unsubscribe()
	}

	delete() {
		this.store.dispatch(CampaignAction.SetCampaignTierId({ campaignTierId: this.campaignTier.id }))
		this.store.dispatch(CampaignAction.DeleteCampaignTier())
	}

}
