import { KeyValue } from '@angular/common'
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { Store } from '@ngrx/store'
import { Boxed, FormGroupState } from 'ngrx-forms'
import { Observable, ReplaySubject, Subscription } from 'rxjs'
import * as AppStore from 'src/app/store'
import * as CampaignSelectors from 'src/app/store/campaign-management/campaign/campaign.selectors'
import { AccountStatusForm, Ruleset } from 'src/app/store/campaign-management/campaign/campaign.state'
import { MatSelect } from '@angular/material'
import { FormControl } from '@angular/forms'
import { FilterPredicate } from 'src/app/models/util/filter-predicate'

@Component({
	selector: 'app-account-status',
	templateUrl: './account-status.component.html',
	styleUrls: ['./account-status.component.scss']
})
export class AccountStatusComponent implements OnInit, OnDestroy {

	@Input() ruleset: Ruleset

	formState$: Observable<FormGroupState<AccountStatusForm>>
	action: string
	equalList: KeyValue<string, string>[] = []
	accountStatusList: KeyValue<string, string>[] = []
	subs: Subscription
	dropDownCtrl: FormControl = new FormControl()

	/** list of records filtered by search keyword */
	filteredAccountStatusList: ReplaySubject<KeyValue<string, string>[]> = new ReplaySubject<KeyValue<string, string>[]>(1)

	@ViewChild('multipleSelect', { static: false }) multipleSelect: MatSelect

	constructor(
		private store: Store<AppStore.State>
	) {
		this.subs = this.store.select(CampaignSelectors.getCampaign).subscribe(({ campaignRulesetAction, rulesetData }) => {
			this.action = campaignRulesetAction
			this.equalList = rulesetData.equalList
			this.accountStatusList = rulesetData.accountStatusList
		})
	}

	get isView(): boolean { return this.action === 'VIEW' }

	ngOnInit() {
		this.formState$ = this.store.select(CampaignSelectors.getForm, this.ruleset.id)
		// load the initial list
		this.filteredAccountStatusList.next(this.accountStatusList.slice())

		this.dropDownCtrl.valueChanges
			.subscribe(value => {
				const maxChar = 255
				if (value.length > maxChar) {
					const newValue = value.slice(0, maxChar)
					this.dropDownCtrl.reset('', { emitEvent: false })
					this.dropDownCtrl.setValue(newValue)

				} else {
					this.search(value)
				}
			})

	}

	ngOnDestroy() {
		this.subs.unsubscribe()
	}

	search(search: string) {
		if (!this.accountStatusList) {
			return
		}
		if (!search) {
			this.filteredAccountStatusList.next(this.accountStatusList.slice())
			return
		} else {
			search = search.toLowerCase()
		}
		// filter the records
		this.filteredAccountStatusList.next(
			this.accountStatusList.filter(x => FilterPredicate.isIdenticalTo(search)([x.value]))
		)
	}

	clearSearch() {
		this.filteredAccountStatusList.next(this.accountStatusList.slice())
	}

	getEqualValue(value: string): string {
		const result = this.equalList.find(x => x.key === value)
		return result ? result.value : ''
	}

	getAccountStatusValue(value: Boxed<string[]>): string {
		let resp = ''

		if (value && value.value) {
			value.value.forEach(x => {
				const result = this.accountStatusList.find(y => x === y.key)
				if (result) {
					if (resp) { resp += ',' }
					resp += result.value
				}
			})
		}

		return resp
	}
}
