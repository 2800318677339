import { Component, Inject, OnDestroy, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA, MatSelectChange } from '@angular/material'
import { Store } from '@ngrx/store'
import { FormGroupState } from 'ngrx-forms'
import { Observable, Subscription } from 'rxjs'
import { WaiverListData, WaiverTypeListData } from 'src/app/models/customer-management/waiver'
import * as AppStore from 'src/app/store'
import { WaiverForm } from 'src/app/store/customer-management'
import * as CustomerManagementAction from 'src/app/store/customer-management/customer-management.actions'
import * as CustomerManagementSelector from 'src/app/store/customer-management/customer-management.selectors'


@Component({
	selector: 'app-customer-management-waiver',
	templateUrl: './customer-management-waiver.component.html',
	styleUrls: ['./customer-management-waiver.component.scss']
})
export class CustomerManagementWaiverComponent implements OnInit, OnDestroy {

	isLoading = false
	formState$: Observable<FormGroupState<WaiverForm>>
	waiverTypeList: WaiverTypeListData[]
	waiverList: WaiverListData[]
	subs: Subscription

	constructor(
		private store: Store<AppStore.State>,
		private dialogRef: MatDialogRef<CustomerManagementWaiverComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any) {

		this.subs = this.store.select(CustomerManagementSelector.getCustomerManagement).subscribe(x => {
			this.isLoading = x.isLoading
			this.waiverTypeList = x.waiverTypeList
			this.waiverList = x.waiverList
		})

		this.formState$ = this.store.select(({ customerManagement }) => customerManagement.waiverForm)
	}

	ngOnInit() {
	}

	ngOnDestroy() {
		this.subs.unsubscribe()
	}

	cancel() {
		this.dialogRef.close()
	}

	create() {
		this.dialogRef.close()
		this.store.dispatch(CustomerManagementAction.WaiverConfirmationDialog())
	}

	onWaiverTypeChange(event: MatSelectChange) {
		if (event.value === undefined) {
			this.store.dispatch(CustomerManagementAction.ClearWaiverList())
		} else {
			this.store.dispatch(CustomerManagementAction.GetWaiverList({ waiverTypeId: event.value }))
		}
	}
}
