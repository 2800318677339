import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material'
import { Router } from '@angular/router'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Action, Store } from '@ngrx/store'
import { Observable, of } from 'rxjs'
import { catchError, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators'
import { MessageDialogComponent } from 'src/app/components/common/dialog/message-dialog/message-dialog.component'
import { MultiSelectDialogComponent } from 'src/app/components/common/dialog/multi-select-dialog/multi-select-dialog.component'
import { GroupListResponse } from 'src/app/models/access-management/group/group-list'
import { UserListResponse } from 'src/app/models/access-management/user/user-list'
import { UserViewResponse, ErrorMessage } from 'src/app/models/access-management/user/user-view'
import { getDialogConfig } from 'src/app/models/common/dialog'
import { Response } from 'src/app/models/common/http'
import { AccessManagementService } from 'src/app/services/access-management/access-management.service'
import { unbox } from 'ngrx-forms'
import * as AppStore from 'src/app/store/'
import * as CommonAction from '../../common/common/common.actions'
import * as UserAction from './user.actions'
import { GlobalMessageDialogComponent } from 'src/app/components/common/dialog/global-message-dialog/global-message-dialog.component'

@Injectable()
export class Effects {

	constructor(
		private action$: Actions,
		private router: Router,
		private dialog: MatDialog,
		private accessManagementService: AccessManagementService,
		private store: Store<AppStore.State>
	) { }

	InitialState$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(UserAction.InitialState),
			tap(() => {
				this.router.navigate(['access-management/user'])
			})
		), { dispatch: false }
	)

	GoList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(UserAction.GoList),
			tap(() => {
				this.router.navigate(['access-management/user'])
			})
		), { dispatch: false }
	)

	GoView$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(UserAction.GoView),
			tap(() => {
				this.router.navigate(['access-management/user/view'])
			})
		), { dispatch: false }
	)

	GoUpdate$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(UserAction.GoUpdate),
			tap(() => {
				this.router.navigate(['access-management/user/update'])
			})
		), { dispatch: false }
	)

	GoBackDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(UserAction.GoBackDialog),
			mergeMap(({ action }) => {
				const dialogRef = this.dialog.open(MessageDialogComponent, getDialogConfig({ content: 'DIALOG.BACK', payload: action }))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					if (result.payload === 'LIST') {
						return UserAction.GoList()
					}
					if (result.payload === 'VIEW') {
						return UserAction.GoView()
					}
				} else {
					return UserAction.closeDialog()
				}
			})
		)
	)

	RestError$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(UserAction.RestError),
			map(({ message, manualThrow }) => {
				return CommonAction.RestError({ message, manualThrow })
			})
		)
	)

	List$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(UserAction.List),
			withLatestFrom(this.store.select(state => state.user)),
			mergeMap(([x, user]) => {
				const form = user.userListForm.controls
				return this.accessManagementService.getUserList({
					username: form.username.value,
					email: form.email.value
				}).pipe(map((response: Response) => {
					const success: boolean = response.success
					const payload: UserListResponse = response.payload
					if (success) {
						return UserAction.ListSuccess({ payload })
					} else { return UserAction.RestError(response as any) }
				}), catchError(this.catcher))
			})
		)
	)

	View$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(UserAction.View),
			withLatestFrom(this.store.select(state => state.user)),
			mergeMap(([, user]) => this.accessManagementService.viewUser({ userId: user.userId }).pipe(
				map((response: Response) => {
					const success: boolean = response.success
					const payload: UserViewResponse = response.payload

					if (success) {
						return UserAction.ViewSuccess({ payload })
					} else { return UserAction.RestError(response as any) }

				}), catchError(this.catcher)
			))
		)
	)

	RemoveGroupFromUser$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(UserAction.RemoveGroupFromUser),
			withLatestFrom(this.store.select(state => state.user)),
			mergeMap(([group, user]) => this.accessManagementService.removeUserGroup({ groupId: group.groupId, userId: user.userId }).pipe(
				map((response: Response) => {
					const success: boolean = response.success
					const payload: string = response.payload

					if (success) {
						return UserAction.RemoveGroupFromUserSuccess({ message: payload })
					} else { return UserAction.RestError(response as any) }

				}), catchError(this.catcher)
			))
		)
	)

	RemoveGroupFromUserSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(UserAction.RemoveGroupFromUserSuccess),
			mergeMap(({ message }) => {
				return [CommonAction.RestError({ message }), UserAction.View()]
			})
		)
	)

	AssignGroupList$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(UserAction.AssignGroupList),
			mergeMap(() => {
				return this.accessManagementService.getGroupList({ policyCheckIsEmpty: true })
					.pipe(map((response: Response) => {
						const success: boolean = response.success
						const payload: GroupListResponse = response.payload
						if (success) {
							this.store.dispatch(UserAction.AssignGroupListSuccess({ payload }))
							return UserAction.AssignGroupDialog()
						} else { return UserAction.RestError(response as any) }

					}), catchError(this.catcher))
			})
		)
	)

	AssignGroupDialog$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(UserAction.AssignGroupDialog),
			withLatestFrom(this.store.select(state => state.user)),
			mergeMap(([, user]) => {
				const dialogRef = this.dialog.open(MultiSelectDialogComponent, getDialogConfig({
					title: 'USER.ASSIGN_GROUP',
					content: 'USER.GROUP',
					payload: user.groups.filter(x => {
						const data = user.userViewResponse.groups.find(
							({ groupId }) => groupId === x.groupId
						)
						if (!data) { return x }
					})
				}))
				return dialogRef.afterClosed()
			}),
			map(result => {
				if (result) {
					return UserAction.AssignMultiGroupToUser({ groupIdList: unbox(result) })
				} else {
					return UserAction.closeDialog()
				}
			})
		)
	)

	AssignMultiGroupToUser$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(UserAction.AssignMultiGroupToUser),
			mergeMap((param) => {
				const service = (id) => UserAction.AssignGroupToUser({ groupId: Number(id) })
				const box = param.groupIdList.map(o => service(o))
				return [ ...box]
			})
		)
	)

	AssignGroupToUser$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(UserAction.AssignGroupToUser),
			withLatestFrom(this.store.select(state => state.user)),
			mergeMap(([group, user]) => this.accessManagementService.assignUserGroup({ groupId: group.groupId, userId: user.userId }).pipe(
				map((response: Response) => {
					const success: boolean = response.success
					const payload: string = response.payload
					if (success) {
						return UserAction.SuccessGroupToUser({ groupId: Number(payload) })
					} else {
						const errorMessage: ErrorMessage = response.error
						return UserAction.FailedGroupToUser({ groupId: Number(payload), message: errorMessage.message})
					}

				})
			))
		)
	)

	OpenGroupResult$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(UserAction.OpenConfirmationDialog),
			withLatestFrom(this.store.select(state => state)),
			mergeMap(([, state]) => {
				const dialogRef = this.dialog.open(GlobalMessageDialogComponent, getDialogConfig({
					content: 'USER.ASSIGN_GROUP_CONFIRMATION_LIST',
					table: state.user.groupListResult != null ? state.user.groupListResult : null,
					submitBtn: 'ACTION.OK',
					tableColumnName: 'USER.ASSIGN_GROUP',
					module: 'USER'
				}))
				return dialogRef.afterClosed()
			}),
			map(() => {
				return UserAction.View()
			})
		)
	)


	AssignGroupToUserSuccess$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(UserAction.AssignGroupToUserSuccess),
			mergeMap(({ message }) => {
				return [CommonAction.RestError({ message }), UserAction.View()]
			})
		)
	)

	catcher = (error) => {
		return (of(UserAction.RestError({ message: error.message, manualThrow: error.manualThrow })))
	}
}
