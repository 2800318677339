import { Component, OnInit, OnDestroy, Inject } from '@angular/core'
import { Subscription } from 'rxjs'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material'
import { Store } from '@ngrx/store'
import * as AppStore from 'src/app/store'
import * as CustomerManagementAction from 'src/app/store/customer-management/customer-management.selectors'

@Component({
	selector: 'app-customer-management-account-transaction-view',
	templateUrl: './customer-management-account-transaction-view.component.html',
	styleUrls: ['./customer-management-account-transaction-view.component.scss']
})
export class CustomerManagementAccountTransactionViewComponent implements OnInit, OnDestroy {

	subs: Subscription

	constructor(
		private store: Store<AppStore.State>,
		private dialogRef: MatDialogRef<CustomerManagementAccountTransactionViewComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		this.subs = this.store.select(CustomerManagementAction.getCustomerManagement).subscribe(x => {
		})
	}

	ngOnInit() { }

	ngOnDestroy() {
		this.subs.unsubscribe()
	}

	cancel() {
		this.dialogRef.close()
	}

}
