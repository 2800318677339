<div fxLayout="column">
  <div fxLayout="row" fxFlex>
    <span class="simulator-label" fxFlex="24">{{ 'SIMULATOR.TIER' | translate }} {{index+1}}</span>
    <h3 class="simulator-symbol" fxFlex="4">:</h3>
    <span class="simulator-value" fxFlex="64">{{ tier.name }}</span>
  </div>

  <div fxLayout="row" fxFlex>
    <span class="simulator-label" fxFlex="24">{{ 'SIMULATOR.TRANSACTION_HIT' | translate }}</span>
    <h3 class="simulator-symbol" fxFlex="4">:</h3>
    <span class="simulator-value" fxFlex="64">{{ tier.transactionHit }}</span>
  </div>

  <div fxLayout="row" fxFlex>
    <span class="simulator-label" fxFlex="24">{{ 'SIMULATOR.TOTAL_AMOUNT_HIT' | translate }}</span>
    <h3 class="simulator-symbol" fxFlex="4">:</h3>
    <span class="simulator-value" fxFlex="64">{{ tier.totalAmountHit }}</span>
  </div>

  <div fxLayout="row" fxFlex>
    <span class="simulator-label" fxFlex="24">{{ 'SIMULATOR.TOTAL_REWARDED_POINT' | translate }}</span>
    <h3 class="simulator-symbol" fxFlex="4">:</h3>
    <span class="simulator-value" fxFlex="64">{{ tier.totalRewardedPoint }}</span>
  </div>
  <br>
  <div class="table-container">
    <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort matSortDisabled>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'SIMULATOR.CAMPAIGN' | translate }}
        </th>
        <td mat-cell *matCellDef="let row">{{row.name}}</td>
      </ng-container>

      <ng-container matColumnDef="transactionHit">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'SIMULATOR.TXN_HIT' | translate }}
        </th>
        <td mat-cell *matCellDef="let row">{{row.transactionHit}}</td>
      </ng-container>

      <ng-container matColumnDef="totalAmountHit">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'SIMULATOR.TOTAL_AMOUNT_HIT' | translate }}
        </th>
        <td mat-cell *matCellDef="let row">{{row.totalAmountHit}}</td>
      </ng-container>

      <ng-container matColumnDef="totalRewardedPoint">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'SIMULATOR.TOTAL_REWARD_POINT' | translate }}
        </th>
        <td mat-cell *matCellDef="let row">{{row.totalRewardedPoint}}</td>
      </ng-container>

      <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let row" [attr.colspan]="displayedColumns.length">
          <div class="element-detail" [@detailExpand]="row == expandedElement ? 'expanded' : 'collapsed'">

            <table class="simulator-ruleset-table">
              <tr>
                <th>{{ 'SIMULATOR.RULESET' | translate }}</th>
                <th>{{ 'SIMULATOR.REWARD_POOL' | translate }}</th>
                <th>{{ 'SIMULATOR.REWARD' | translate }}</th>
                <th>{{ 'SIMULATOR.TXN_HIT' | translate }}</th>
                <th>{{ 'SIMULATOR.TXN_AMOUNT' | translate }}</th>
                <th>{{ 'SIMULATOR.REWARD_POINT' | translate }}</th>
              </tr>
              <tr *ngFor="let item of row.rulesetList">
                <td>{{ item.name }}</td>
                <td>{{ item.rewardPool }}</td>
                <td>{{ item.reward }}</td>
                <td>{{ item.transactionHit }}</td>
                <td>{{ item.transactionAmount }}</td>
                <td>{{ item.rewardPoint }}</td>
              </tr>
            </table>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="element-row"
        [class.expanded-row]="expandedElement === row" (click)="expandedElement = expandedElement === row ? null : row">
      </tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
    </table>
    <div class="table-no-data" *ngIf="dataSource.filteredData.length === 0"> {{'ERROR.TABLE_NO_RECORD' | translate}} </div>
  </div>
</div>
<hr>