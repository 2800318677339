<app-spinner [isLoading]="isLoading"></app-spinner>

<div *ngIf="isCreate" class="title-div" fxLayout="row" fxLayoutAlign="space-between center">
  <span class="title"> {{ 'WAIVER_MANAGEMENT.CREATE_WAIVER' | translate }} </span>
  <button class="close-icon" mat-icon-button (click)="cancel()">
    <mat-icon>close</mat-icon>
  </button>
</div>

<div *ngIf="!isCreate" class="title-div" fxLayout="row" fxLayoutAlign="space-between center">
  <span class="title"> {{ 'WAIVER_MANAGEMENT.WAIVER_DETAILS' | translate }} </span>
  <button class="close-icon" mat-icon-button (click)="cancel()">
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-dialog-content>

  <div *ngIf="isView">
    <form novalidate [ngrxFormState]="formState$ | async" fxLayout="column" fxFlex>
      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="5px" fxLayoutGap.lt-md="0px">
        <mat-form-field fxFlex="50">
          <mat-label>{{ 'WAIVER_MANAGEMENT.CODE' | translate }}</mat-label>
          <input matInput value="{{(formState$ | async).controls.code.value}}" type="text" maxlength="10" readonly>
        </mat-form-field>

        <mat-form-field fxFlex="50">
          <mat-label>{{ 'WAIVER_MANAGEMENT.NAME' | translate }}</mat-label>
          <input matInput value="{{(formState$ | async).controls.name.value}}" type="text" maxlength="40" readonly>
        </mat-form-field>
      </div>
        <mat-form-field fxFlex>
          <mat-label>{{ 'WAIVER_MANAGEMENT.REWARD_POOL_OR_GROUP' | translate }}</mat-label>
          <input matInput value="{{getRewardPoolValue((formState$ | async).controls.rewardPoolId.value)}}" type="text"
            maxlength="255" readonly>
        </mat-form-field>
        <div *ngIf="isRewardPool == true" >
          <mat-form-field fxFlex>
            <mat-label>{{ 'WAIVER_MANAGEMENT.GL_REDEMPTION' | translate }}</mat-label>
            <input matInput value="{{getGlRedemptionValue((formState$ | async).controls.glRedemptionId.value)}}" type="text"
              maxlength="255" readonly>
          </mat-form-field>
        </div>
        <div *ngIf="isRewardPool == false">
          <div *ngFor="let waiverRewardPool of (waiverRewardPools$ | async);trackBy: trackByIndex;let i=index">
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="5px" fxLayoutGap.lt-md="0px">
              <app-assigned-waiver-reward-pool [waiverRewardPool]="waiverRewardPool" fxFlex>
              </app-assigned-waiver-reward-pool>
            </div>
          </div>
        </div>
      <mat-form-field fxFlex>
        <mat-label>{{ 'WAIVER_MANAGEMENT.PRODUCT_TYPE' | translate }}</mat-label>
        <textarea matInput value="{{getProductTypeValue((formState$ | async).controls.productTypeId.value)}}"
          cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" readonly></textarea>
      </mat-form-field>

      <mat-form-field fxFlex>
        <mat-label>{{ 'WAIVER_MANAGEMENT.PRODUCT_TYPE_GROUP' | translate }}</mat-label>
        <textarea matInput value="{{getProductTypeGroupValue((formState$ | async).controls.productTypeGroupId.value)}}"
          cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" readonly></textarea>
      </mat-form-field>

      <mat-form-field fxFlex>
        <mat-label>{{ 'WAIVER_MANAGEMENT.FEE_TC' | translate }}</mat-label>
        <textarea matInput value="{{getAnnualFeeValue((formState$ | async).controls.feeTc.value)}}"
          cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" readonly></textarea>
      </mat-form-field>

      <mat-form-field fxFlex>
        <mat-label>{{ 'WAIVER_MANAGEMENT.FEE_REDEMPTION_TC' | translate }}</mat-label>
        <textarea matInput value="{{getAnnualFeeRedemptionValue((formState$ | async).controls.feeRedemptionTc.value)}}"
          cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" readonly></textarea>
      </mat-form-field>

      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="5px" fxLayoutGap.lt-md="0px">
        <mat-form-field fxFlex="50">
          <mat-label>{{ 'WAIVER_MANAGEMENT.POINTS' | translate }}</mat-label>
          <input matInput value="{{(formState$ | async).controls.points.value}}" type="text" maxlength="10" readonly>
        </mat-form-field>

        <mat-form-field fxFlex="50">
          <mat-label>{{ 'WAIVER_MANAGEMENT.RELATION' | translate }}</mat-label>
          <input matInput value="{{getCardHolderValue((formState$ | async).controls.cardHolder.value)}}" type="text"
            maxlength="255" readonly>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="5px" fxLayoutGap.lt-md="0px">
        <mat-form-field fxFlex="50">
          <mat-label>{{ 'COMMON.CREATED_BY' | translate }}</mat-label>
          <input matInput value="{{(formState$ | async).controls.createdBy.value}}" type="text" readonly>
        </mat-form-field>

        <mat-form-field fxFlex="50">
          <mat-label>{{ 'COMMON.CREATED_DATE' | translate }}</mat-label>
          <input matInput value="{{(formState$ | async).controls.createdDate.value}}" type="text" readonly>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="5px" fxLayoutGap.lt-md="0px">
        <mat-form-field fxFlex="50">
          <mat-label>{{ 'COMMON.MODIFIED_BY' | translate }}</mat-label>
          <input matInput value="{{(formState$ | async).controls.modifiedBy.value}}" type="text" readonly>
        </mat-form-field>

        <mat-form-field fxFlex="50">
          <mat-label>{{ 'COMMON.MODIFIED_DATE' | translate }}</mat-label>
          <input matInput value="{{(formState$ | async).controls.modifiedDate.value}}" type="text" readonly>
        </mat-form-field>
      </div>

      <mat-expansion-panel [ngClass]="'mat-box-container'" [expanded]="true">
        <mat-expansion-panel-header>
          <div fxLayout="row" fxLayoutAlign="space-between center" fxFlex>
            <span>{{ 'WAIVER_MANAGEMENT.TRANSACTION_POSTING' | translate }}</span>
          </div>
        </mat-expansion-panel-header>

        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="5px" fxLayoutGap.lt-md="0px">
          <mat-form-field>
            <mat-label>{{ 'WAIVER_MANAGEMENT.TRANSACTION_CODE' | translate }}</mat-label>
            <input matInput value="{{(formState$ | async).controls.transactionCode.value}}" type="text" maxlength="10"
              readonly>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{ 'WAIVER_MANAGEMENT.TRANSACTION_AMOUNT' | translate }}</mat-label>
            <input matInput value="{{(formState$ | async).controls.transactionAmount.value}}" type="text" maxlength="11"
              readonly>
          </mat-form-field>
        </div>

        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="5px" fxLayoutGap.lt-md="0px">
          <mat-form-field fxFlex>
            <mat-label>{{ 'WAIVER_MANAGEMENT.TRANSACTION_DESCRIPTION' | translate }}</mat-label>
            <textarea matInput value="{{(formState$ | async).controls.transactionDescription.value}}"
              cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" cdkAutosizeMaxRows="10"
              readonly></textarea>
          </mat-form-field>
        </div>
      </mat-expansion-panel>
      <br>
    </form>
  </div>

  <div *ngIf="!isView">
    <form novalidate [ngrxFormState]="formState$ | async" fxLayout="column" fxFlex>
      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="5px" fxLayoutGap.lt-md="0px">
        <mat-form-field fxFlex="50">
          <mat-label>{{ 'WAIVER_MANAGEMENT.CODE' | translate }}</mat-label>
          <input matInput [ngrxFormControlState]="(formState$ | async).controls.code" type="text" maxlength="10"
            [required]="isCreate" [readonly]="isUpdate">
          <mat-error *ngIf="(formState$ | async).errors._code">
            <app-error-validation [errors]="(formState$ | async).errors._code" [maxlength]="10">
            </app-error-validation>
          </mat-error>
        </mat-form-field>

        <mat-form-field fxFlex="50">
          <mat-label>{{ 'WAIVER_MANAGEMENT.NAME' | translate }}</mat-label>
          <input matInput [ngrxFormControlState]="(formState$ | async).controls.name" type="text" maxlength="40"
            required>
          <mat-error *ngIf="(formState$ | async).errors._name">
            <app-error-validation [errors]="(formState$ | async).errors._name" [maxlength]="40">
            </app-error-validation>
          </mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="5px" fxLayoutGap.lt-md="0px">
        <mat-form-field class="minw-365px" fxFlex="100">
          <mat-label>{{ 'WAIVER_MANAGEMENT.REWARD_POOL_OR_GROUP' | translate }}</mat-label>
		<mat-select [ngrxFormControlState]="(formState$ | async).controls.rewardPoolId" (closed)="clearSearchRewardPool()"
			(selectionChange)="onChangeRewardPool($event)" required>
			<ngx-mat-select-search [formControl]="dropDownCtrlRewardPoolGroup" [placeholderLabel]="'COMMON.SEARCH'| translate"
				[noEntriesFoundLabel]="'COMMON.NOT_MATCHING_FOUND' | translate">
				<mat-icon ngxMatSelectSearchClear (click)="clearSearchRewardPool()">close</mat-icon>
			</ngx-mat-select-search>
			<mat-option *ngFor="let rewardPool of filteredRewardPool | async" [value]="rewardPool.key">
				{{rewardPool.value}}
			</mat-option>
		</mat-select>
          <mat-error *ngIf="(formState$ | async).errors._rewardPoolId">
            <app-error-validation [errors]="(formState$ | async).errors._rewardPoolId">
            </app-error-validation>
          </mat-error>
        </mat-form-field>
      </div>

      <div *ngIf="isRewardPool == true">
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="5px" fxLayoutGap.lt-md="0px">
          <mat-form-field class="minw-365px" fxFlex="100">
            <mat-label>{{ 'WAIVER_MANAGEMENT.GL_REDEMPTION' | translate }}</mat-label>
			<mat-select [ngrxFormControlState]="(formState$ | async).controls.glRedemptionId" (closed)="clearSearchGlRedemption()"
				required>
				<ngx-mat-select-search [formControl]="dropDownCtrlGlRedemption" [placeholderLabel]="'COMMON.SEARCH'| translate"
					[noEntriesFoundLabel]="'COMMON.NOT_MATCHING_FOUND' | translate"
					>
					<mat-icon ngxMatSelectSearchClear (click)="clearSearchGlRedemption()">close</mat-icon>
				</ngx-mat-select-search>
				<mat-option *ngFor="let glRedemption of filteredGlRedemption | async" [value]="glRedemption.key">
					{{glRedemption.value}}
				</mat-option>
			</mat-select>
            <mat-error *ngIf="(formState$ | async).errors._glRedemptionId">
              <app-error-validation [errors]="(formState$ | async).errors._glRedemptionId">
              </app-error-validation>
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div *ngIf="isRewardPool == false">
          <div *ngFor="let waiverRewardPool of (waiverRewardPools$ | async);trackBy: trackByIndex;let i=index">
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="5px" fxLayoutGap.lt-md="0px">
              <app-assigned-waiver-reward-pool [waiverRewardPool]="waiverRewardPool" fxFlex>
              </app-assigned-waiver-reward-pool>
            </div>
          </div>
      </div>

      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="5px" fxLayoutGap.lt-md="0px">
        <mat-form-field class="minw-365px" fxFlex="100">
          <mat-label>{{ 'WAIVER_MANAGEMENT.PRODUCT_TYPE' | translate }}</mat-label>
          <mat-select [ngrxFormControlState]="(formState$ | async).controls.productTypeId" multiple #multipleSelect
            (closed)="clearSearch()">
            <ngx-mat-select-search [formControl]="dropDownCtrl" [placeholderLabel]="'COMMON.SEARCH'| translate"
              [noEntriesFoundLabel]="'COMMON.NOT_MATCHING_FOUND' | translate">
              <mat-icon ngxMatSelectSearchClear (click)="clearSearch()">close</mat-icon>
            </ngx-mat-select-search>
            <mat-option *ngFor="let i of filteredProductTypes | async" [value]="i.key">
              {{i.value}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="(formState$ | async).errors._productTypeId">
            <app-error-validation [errors]="(formState$ | async).errors._productTypeId">
            </app-error-validation>
          </mat-error>
        </mat-form-field>
      </div>

    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="5px" fxLayoutGap.lt-md="0px">
      <mat-form-field class="minw-365px" fxFlex="100">
        <mat-label>{{ 'WAIVER_MANAGEMENT.PRODUCT_TYPE_GROUP' | translate }}</mat-label>
        <mat-select [ngrxFormControlState]="(formState$ | async).controls.productTypeGroupId" multiple
          (closed)="clearSearchProductTypeGroup()">
          <ngx-mat-select-search [formControl]="dropDownCtrlProductTypeGroup" [placeholderLabel]="'COMMON.SEARCH'| translate"
            [noEntriesFoundLabel]="'COMMON.NOT_MATCHING_FOUND' | translate"
            >
            <mat-icon ngxMatSelectSearchClear (click)="clearSearchProductTypeGroup()">close</mat-icon>
          </ngx-mat-select-search>
          <mat-option *ngFor="let i of filteredProductTypeGroup | async" [value]="i.key">
            {{i.value}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="(formState$ | async).errors._productTypeGroupId">
          <app-error-validation [errors]="(formState$ | async).errors._productTypeGroupId">
          </app-error-validation>
        </mat-error>
      </mat-form-field>
    </div>

    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="5px" fxLayoutGap.lt-md="0px">
      <mat-form-field class="minw-365px" fxFlex="100">
        <mat-label>{{ 'WAIVER_MANAGEMENT.FEE_TC' | translate }}</mat-label>
        <mat-select [ngrxFormControlState]="(formState$ | async).controls.feeTc" multiple
          (closed)="clearSearchAnnualFee()">
          <ngx-mat-select-search [formControl]="dropDownCtrlAnnualFee" [placeholderLabel]="'COMMON.SEARCH'| translate"
            [noEntriesFoundLabel]="'COMMON.NOT_MATCHING_FOUND' | translate"
            >
            <mat-icon ngxMatSelectSearchClear (click)="clearSearchAnnualFee()">close</mat-icon>
          </ngx-mat-select-search>
          <mat-option *ngFor="let i of filteredAnnualFee | async" [value]="i.key">
            {{i.value}}
          </mat-option>
        </mat-select>
        <!-- <mat-error *ngIf="(formState$ | async).errors._productTypeGroupId">
          <app-error-validation [errors]="(formState$ | async).errors._productTypeGroupId">
          </app-error-validation>
        </mat-error> -->
      </mat-form-field>
    </div>

    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="5px" fxLayoutGap.lt-md="0px">
      <mat-form-field class="minw-365px" fxFlex="100">
        <mat-label>{{ 'WAIVER_MANAGEMENT.FEE_REDEMPTION_TC' | translate }}</mat-label>
        <mat-select [ngrxFormControlState]="(formState$ | async).controls.feeRedemptionTc" multiple
          (closed)="clearSearchAnnualFeeRedemption()">
          <ngx-mat-select-search [formControl]="dropDownCtrlAnnualFeeRedemption" [placeholderLabel]="'COMMON.SEARCH'| translate"
            [noEntriesFoundLabel]="'COMMON.NOT_MATCHING_FOUND' | translate"
            >
            <mat-icon ngxMatSelectSearchClear (click)="clearSearchAnnualFeeRedemption()">close</mat-icon>
          </ngx-mat-select-search>
          <mat-option *ngFor="let i of filteredAnnualFeeRedemption | async" [value]="i.key">
            {{i.value}}
          </mat-option>
        </mat-select>
        <!-- <mat-error *ngIf="(formState$ | async).errors._productTypeGroupId">
          <app-error-validation [errors]="(formState$ | async).errors._productTypeGroupId">
          </app-error-validation>
        </mat-error> -->
      </mat-form-field>
    </div>

      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="5px" fxLayoutGap.lt-md="0px">
        <mat-form-field fxFlex="50">
          <mat-label>{{ 'WAIVER_MANAGEMENT.POINTS' | translate }}</mat-label>
          <input matInput [ngrxFormControlState]="(formState$ | async).controls.points"
            onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57"
            type="text" maxlength="10" required>
          <mat-error *ngIf="(formState$ | async).errors._points">
            <app-error-validation [errors]="(formState$ | async).errors._points" [maxlength]="10">
            </app-error-validation>
          </mat-error>
        </mat-form-field>

        <mat-form-field fxFlex="50">
          <mat-label>{{ 'WAIVER_MANAGEMENT.RELATION' | translate }}</mat-label>
		<mat-select [ngrxFormControlState]="(formState$ | async).controls.cardHolder" (closed)="clearSearchCardHolder()"
			required>
			<ngx-mat-select-search [formControl]="dropDownCtrlCardHolder" [placeholderLabel]="'COMMON.SEARCH'| translate"
				[noEntriesFoundLabel]="'COMMON.NOT_MATCHING_FOUND' | translate">
				<mat-icon ngxMatSelectSearchClear (click)="clearSearchCardHolder()">close</mat-icon>
			</ngx-mat-select-search>
			<mat-option *ngFor="let cardHolder of filteredCardHolder | async" [value]="cardHolder.key">
				{{cardHolder.value}}
			</mat-option>
		</mat-select>
          <mat-error *ngIf="(formState$ | async).errors._cardHolder">
            <app-error-validation [errors]="(formState$ | async).errors._cardHolder">
            </app-error-validation>
          </mat-error>
        </mat-form-field>
      </div>

      <mat-expansion-panel [ngClass]="'mat-box-container'" [expanded]="true">
        <mat-expansion-panel-header>
          <div fxLayout="row" fxLayoutAlign="space-between center" fxFlex>
            <span>{{ 'WAIVER_MANAGEMENT.TRANSACTION_POSTING' | translate }}</span>
          </div>
        </mat-expansion-panel-header>

        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="5px" fxLayoutGap.lt-md="0px">
          <mat-form-field>
            <mat-label>{{ 'WAIVER_MANAGEMENT.TRANSACTION_CODE' | translate }}</mat-label>
            <input matInput [ngrxFormControlState]="(formState$ | async).controls.transactionCode" type="text"
              onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57"
              maxlength="4" required>
            <mat-error *ngIf="(formState$ | async).errors._transactionCode">
              <app-error-validation [errors]="(formState$ | async).errors._transactionCode" [maxlength]="4">
              </app-error-validation>
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{ 'WAIVER_MANAGEMENT.TRANSACTION_AMOUNT' | translate }}</mat-label>
            <input matInput [ngrxFormControlState]="(formState$ | async).controls.transactionAmount" type="text"
              onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57 || event.charCode == 46"
              maxlength="11" required>
            <mat-error *ngIf="(formState$ | async).errors._transactionAmount">
              <app-error-validation [errors]="(formState$ | async).errors._transactionAmount" [maxlength]="11">
              </app-error-validation>
            </mat-error>
          </mat-form-field>
        </div>

        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="5px" fxLayoutGap.lt-md="0px">
          <mat-form-field fxFlex>
            <mat-label>{{ 'WAIVER_MANAGEMENT.TRANSACTION_DESCRIPTION' | translate }}</mat-label>
            <textarea matInput [ngrxFormControlState]="(formState$ | async).controls.transactionDescription"
              cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" cdkAutosizeMaxRows="10"
              maxlength="25" required></textarea>
            <mat-error *ngIf="(formState$ | async).errors._transactionDescription">
              <app-error-validation [errors]="(formState$ | async).errors._transactionDescription" [maxlength]="25">
              </app-error-validation>
            </mat-error>
          </mat-form-field>
        </div>
      </mat-expansion-panel>

    </form>
  </div>

</mat-dialog-content>

<br>
<div *ngIf="isUpdate" class="action-button-div" fxLayout="row" fxLayoutAlign="end">
  <button [disabled]="!(formState$ | async).isTouched || (formState$ | async).isInvalid || checkValidation()"
    class="mat-raised-button mat-primary next-button" (click)="update()"
    fxFlexOffset="8px">{{ 'ACTION.SAVE' | translate }}</button>
</div>

<div *ngIf="isCreate" class="action-button-div" fxLayout="row" fxLayoutAlign="end">
  <button [disabled]="!(formState$ | async).isTouched || (formState$ | async).isInvalid"
    class="mat-raised-button mat-primary next-button" (click)="submit()"
    fxFlexOffset="8px">{{ 'ACTION.CREATE' | translate }}</button>
</div>
