import { Component, Inject, OnDestroy } from '@angular/core'
import { Observable, Subscription } from 'rxjs'
import { FormGroupState } from 'ngrx-forms'
import { Store } from '@ngrx/store'
import * as AppStore from '../../../../store'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material'
import * as ProductPartnerSelectors from 'src/app/store/catalogue-management/product-partner/product-partner.selectors'
import * as ProductPartnerAction from 'src/app/store/catalogue-management/product-partner/product-partner.actions'
import { ProductPartnerDuplicateForm } from '../../../../store/catalogue-management/product-partner'

@Component({
	selector: 'app-product-partner-duplicate',
	templateUrl: './product-partner-duplicate.component.html',
	styleUrls: ['./product-partner-duplicate.component.scss']
})
export class ProductPartnerDuplicateComponent implements OnDestroy {

	isLoading = false
	formState$: Observable<FormGroupState<ProductPartnerDuplicateForm>>

	subs: Subscription

	constructor(
		private store: Store<AppStore.State>,
		private dialogRef: MatDialogRef<ProductPartnerDuplicateComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		this.subs = this.store.select(ProductPartnerSelectors.getProductPartner).subscribe(({isDuplicateLoading, isDuplicateDialog}) => {
			this.isLoading = isDuplicateLoading
			if (isDuplicateDialog) {
				this.cancel()
			}
		})

		this.formState$ = this.store.select(({productPartner}) => productPartner.productPartnerDuplicateForm)
	}

	ngOnDestroy(): void {
		this.subs.unsubscribe()
	}

	cancel() {
		this.dialogRef.close()
	}

	submit() {
		this.store.dispatch(ProductPartnerAction.Duplicate())
	}
}
