import { KeyValue } from '@angular/common'
import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'
import { Boxed, FormGroupState } from 'ngrx-forms'
import { Observable, ReplaySubject, Subscription } from 'rxjs'
import * as AppStore from 'src/app/store'
import * as CampaignAction from 'src/app/store/campaign-management/campaign/campaign.actions'
import * as CampaignSelectors from 'src/app/store/campaign-management/campaign/campaign.selectors'
import { Ruleset, TransactionCodeForm } from 'src/app/store/campaign-management/campaign/campaign.state'
import { FormControl } from '@angular/forms'
import { FilterPredicate } from 'src/app/models/util/filter-predicate'

@Component({
	selector: 'app-transaction-code',
	templateUrl: './transaction-code.component.html',
	styleUrls: ['./transaction-code.component.scss']
})
export class TransactionCodeComponent implements OnInit, OnDestroy {

	@Input() ruleset: Ruleset

	formState$: Observable<FormGroupState<TransactionCodeForm>>
	action: string
	includeExcludeList: KeyValue<string, string>[] = []
	tcGroups: KeyValue<string, string>[] = []
	subs: Subscription

	public filteredTCGroups: ReplaySubject<KeyValue<string, string>[]> = new ReplaySubject<KeyValue<string, string>[]>(1)
	public filteredTCGroupsReversal: ReplaySubject<KeyValue<string, string>[]> = new ReplaySubject<KeyValue<string, string>[]>(1)

	filteredTCGroupsSelected: string[]
	filteredTCGroupsReversalSelected: string[]

	public dropDownCtrlTCGroups: FormControl = new FormControl()
	public dropDownCtrlTCGroupsReversal: FormControl = new FormControl()

	constructor(
		private store: Store<AppStore.State>
	) {
		this.subs = this.store.select(CampaignSelectors.getCampaign).subscribe(({ campaignRulesetAction, rulesetData }) => {
			this.action = campaignRulesetAction
			this.includeExcludeList = rulesetData.includeExcludeList
			this.tcGroups = rulesetData.tcGroups
		})
	}

	get isView(): boolean { return this.action === 'VIEW' }

	ngOnInit() {
		this.formState$ = this.store.select(CampaignSelectors.getForm, this.ruleset.id)
		this.filteredTCGroups.next(this.tcGroups.slice())
		this.filteredTCGroupsReversal.next(this.tcGroups.slice())
		this.formState$.subscribe(o => {
			if (o) {
				this.filteredTCGroupsSelected = o.controls.values.value.value
				this.filteredTCGroupsReversalSelected = o.controls.reversalValues.value.value
				this.filteredTCGroups.next(this.tcGroups.filter(o => !this.filteredTCGroupsReversalSelected.includes(o.key)))
				this.filteredTCGroupsReversal.next(this.tcGroups.filter(o => !this.filteredTCGroupsSelected.includes(o.key)))
			}
		})


		this.dropDownCtrlTCGroups.valueChanges
			.subscribe(value => {
				const maxChar = 255
				if (value.length > maxChar) {
					const newValue = value.slice(0, maxChar)
					this.dropDownCtrlTCGroups.reset('', { emitEvent: false })
					this.dropDownCtrlTCGroups.setValue(newValue)

				} else {
					this.searchTCGroups(value)
				}
			})

		this.dropDownCtrlTCGroupsReversal.valueChanges
			.subscribe(value => {
				const maxChar = 255
				if (value.length > maxChar) {
					const newValue = value.slice(0, maxChar)
					this.dropDownCtrlTCGroupsReversal.reset('', { emitEvent: false })
					this.dropDownCtrlTCGroupsReversal.setValue(newValue)

				} else {
					this.searchTCGroupsReversal(value)
				}
			})


	}

	ngOnDestroy() {
		this.subs.unsubscribe()
	}

	onChangeReversalTCGroup(isCheck: boolean): void {
		this.store.dispatch(CampaignAction.OnChangeReversalTCGroup({ ruleset: this.ruleset, isCheck }))
	}

	getIncludeExcludeValue(value: string): string {
		const result = this.includeExcludeList.find(x => x.key === value)
		return result ? result.value : ''
	}

	getTcGroupsValue(value: Boxed<string[]>): string {
		let resp = ''

		if (value && value.value) {
			value.value.forEach(x => {
				const result = this.tcGroups.find(y => x === y.key)
				if (result) {
					if (resp) { resp += ',' }
					resp += result.key + '-' + result.value
				}
			})
		}
		return resp
	}

	searchTCGroups(search: string) {
		if (!this.tcGroups) {
			return
		}
		if (!search) {
			this.filteredTCGroups.next(this.tcGroups.filter(o => !this.filteredTCGroupsReversalSelected.includes(o.key)).slice())
			return
		} else {
			search = search.toLowerCase()
		}
		// filter the records
		this.filteredTCGroups.next(
			this.tcGroups.filter(tc => !this.filteredTCGroupsReversalSelected.includes(tc.key) && FilterPredicate.isIdenticalTo(search)([tc.key.toLowerCase() + ' - ' + tc.value.toLowerCase()]))
		)
	}

	searchTCGroupsReversal(search: string) {
		if (!this.tcGroups) {
			return
		}
		if (!search) {
			this.filteredTCGroupsReversal.next(this.tcGroups.filter(o => !this.filteredTCGroupsSelected.includes(o.key)).slice())
			return
		} else {
			search = search.toLowerCase()
		}
		// filter the records
		this.filteredTCGroupsReversal.next(
			this.tcGroups.filter(tc => !this.filteredTCGroupsSelected.includes(tc.key) && FilterPredicate.isIdenticalTo(search)([tc.key.toLowerCase() + ' - ' + tc.value.toLowerCase()]))
		)
	}

	clearSearchTCGroups() {
		this.filteredTCGroups.next(this.tcGroups.filter(o => !this.filteredTCGroupsReversalSelected.includes(o.key)).slice())
	}

	clearSearchTCGroupsReversal() {
		this.filteredTCGroupsReversal.next(this.tcGroups.filter(o => !this.filteredTCGroupsSelected.includes(o.key)).slice())
	}
}
