import { createFormGroupState, FormGroupState, updateGroup, validate } from 'ngrx-forms'
import { maxLength, required, pattern } from 'ngrx-forms/validation'
import { GroupListResponse } from 'src/app/models/access-management/group/group-list'
import { AssignPolicy } from 'src/app/models/access-management/group/group'
import { GroupViewResponse, PolicyListResult } from 'src/app/models/access-management/group/group-view'
import { FieldValidator } from 'src/app/models/util/field.validator'

export interface State {
	isLoading: boolean
	groupId?: number
	groupListForm: FormGroupState<GroupListForm>
	groupDetailForm: FormGroupState<GroupDetailForm>
	groupListReponse: GroupListResponse
	groupViewResponse: GroupViewResponse
	assignPolicyDataList: AssignPolicy[]
	isDuplicateLoading: boolean
	isDuplicateDialog: boolean
	isTableLoading: boolean
	policyListResult: PolicyListResult[]
	policyResult: PolicyListResult
	groupDetailFormView: GroupDetailForm
}

export interface GroupListForm {
	name: string
	fromDate: string
	toDate: string
	pageNum: number
	pageSize: number
}

export const initialGroupListFormValue: GroupListForm = {
	name: '',
	fromDate: '',
	toDate: '',
	pageNum: 0,
	pageSize: 0
}

export const initialGroupListForm = createFormGroupState('groupListForm', initialGroupListFormValue)

export const validateGroupListForm = updateGroup<GroupListForm>({
	name: validate([maxLength(255)]),
	fromDate: validate([maxLength(255)]),
	toDate: validate([maxLength(255)])
})

export interface GroupDetailForm {
	name: string
	description: string
}

export const initialGroupDetailFormValue: GroupDetailForm = {
	name: '',
	description: ''
}

export const initialGroupDetailForm = createFormGroupState('groupDetailForm', initialGroupDetailFormValue)

export const validateGroupDetailForm = updateGroup<GroupDetailForm>({
	name: validate([required, maxLength(40), FieldValidator.alphaNumericWithSpaces()]),
	description: validate([maxLength(100), FieldValidator.checkValidCharacters()])
})

export const initialState: State = {
	isLoading: false,
	groupListForm: initialGroupListForm,
	groupDetailForm: initialGroupDetailForm,
	groupListReponse: {
		groups: []
	},
	groupViewResponse: {
		groupId: 0,
		name: '',
		numberOfUsers: '',
		description: '',
		policies: [],
		assignedUsers: [],
		createdBy: '',
		createdDate: '',
		modifiedBy: '',
		modifiedDate: ''
	},
	assignPolicyDataList: [],
	isDuplicateLoading: false,
	isDuplicateDialog: false,
	policyListResult: [],
	isTableLoading: false,
	policyResult: {
		success: false,
		payload: ''
	},
	groupDetailFormView: initialGroupDetailFormValue
}
