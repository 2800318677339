<form novalidate [ngrxFormState]="formState$ | async">
  <div *ngIf="isView">
    <div fxLayout="row" fxLayoutGap="5px">
      <mat-form-field fxFlex="30">
        <input matInput value="{{getCriteriaValue((formState$ | async).controls.equation.value)}}" type="text"
          maxlength="255" readonly>
      </mat-form-field>

      <mat-form-field fxFlex="70">
        <input matInput value="{{(formState$ | async).controls.value.value}}" type="text" maxlength="255" readonly>
      </mat-form-field>
    </div>
  </div>

  <div *ngIf="!isView">
    <div fxLayout="row" fxLayoutGap="5px">
      <mat-form-field fxFlex="30">
        <mat-select [ngrxFormControlState]="(formState$ | async).controls.equation">
          <mat-option *ngFor="let i of criteriaList" [value]="i.key">
            {{i.value}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field fxFlex="70">
        <input matInput [ngrxFormControlState]="(formState$ | async)?.controls.value" type="text" maxlength="255">
        <mat-error *ngIf="(formState$ | async).errors._value">
          <app-error-validation [errors]="(formState$ | async).errors._value" [maxlength]="255">
          </app-error-validation>
        </mat-error>
      </mat-form-field>
    </div>
  </div>
</form>