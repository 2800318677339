import { KeyValue } from '@angular/common'
import { createFormGroupState, FormGroupState, updateGroup, validate } from 'ngrx-forms'
import { maxLength, required } from 'ngrx-forms/validation'
import { RedeemPartner, RedeemPartnerDetail } from 'src/app/models/redemption-settings/redeem-partner/redeem-partner'
import { RedeemPartnerViewResponse } from 'src/app/models/redemption-settings/redeem-partner/redeem-partner-view'
import { FieldValidator } from 'src/app/models/util/field.validator'
import { RedeemPartnerRewardPoolView } from 'src/app/models/redemption-settings/redeem-partner/redeem-partner-reward-pool'

export interface State {
	isLoading: boolean
	id?: string
	action?: string
	redeemPartnerDetail?: RedeemPartnerDetail
	// listing
	redeemPartnerList: RedeemPartner[]
	// view
	redeemPartnerView: RedeemPartnerViewResponse
	// edit
	redeemPartnerDetailList: RedeemPartnerDetail[]
	redeemPartnerConversionForm: FormGroupState<RedeemPartnerConversionForm>
	redeemPartnerDetailForm: FormGroupState<RedeemPartnerDetailForm>
	// option list
	rewardList: KeyValue<string, string>[]
	glRedemptions: KeyValue<number, string>[]
	roundingList: KeyValue<string, string>[]
	numberDecimalList: KeyValue<string, string>[]

	redeemPartnerConversionDetailView: RedeemPartnerDetail[]
	redeemPartnerFormView: RedeemPartnerDetailForm
	redeemPartnerConversionFormView: RedeemPartnerConversionForm

	pointConversionId?: string
}

export interface RedeemPartnerDetailForm {
	id: string
	code: string
	name: string
	partnerAuthorizationUrl: string
	clientId: string
	clientSecret: string
	createdBy: string
	createdDate: string
	modifiedBy: string
	modifiedDate: string
}

export const initialRedeemPartnerDetail: RedeemPartnerDetailForm = {
	id: '',
	code: '',
	name: '',
	partnerAuthorizationUrl: '',
	clientId: '',
	clientSecret: '',
	createdBy: '',
	createdDate: '',
	modifiedBy: '',
	modifiedDate: '',
}

export const initialRedeemPartnerViewResponse: RedeemPartnerViewResponse = {
	id: '',
	code: '',
	name: '',
	document: [],
	detail: {
		partnerAuthorizationUrl: '',
		clientId: '',
		clientSecret: ''
	},
	createdBy: '',
	createdDate: '',
	modifiedBy: '',
	modifiedDate: '',
	rewardPoolAndGlRedemption: []
}

export const initialRedeemPartnerDetailForm = createFormGroupState('redeemPartnerDetailForm', initialRedeemPartnerDetail)

export const validateRedeemPartnerDetailForm = updateGroup<RedeemPartnerDetailForm>({
	code: validate([required, maxLength(10), FieldValidator.alphaNumericWithoutSpaces()]),
	name: validate([required, maxLength(40), FieldValidator.checkValidCharacters()]),
	partnerAuthorizationUrl: validate([maxLength(255)])
})

export interface RedeemPartnerConversionForm {
	id: string
	point: string
	value: string
	name: string
	type: string
	glRedemptionId: number
	redemptionRate: string
	glProductCode: string
	rounding: boolean
	roundingValue: string
	numberDecimal: string
	numberDecimalValue: string
	rewardPoolAndGlRedemption: RedeemPartnerRewardPoolView[]
	redeemPartnerRewardPools: RedeemPartnerRewardPools
}

export interface RedeemPartnerRewardPools {
	byId: { [key: string]: RedeemPartnerRewardPool }
	allIds: string[]
}

export interface RedeemPartnerRewardPool {
	id: string
	form: FormGroupState<RedeemPartnerRewardPoolForm>
}

export const initialRedeemPartnerConversionFormValue: RedeemPartnerConversionForm = {
	id: '',
	point: '',
	value: '',
	type: '',
	name: '',
	glRedemptionId: 0,
	redemptionRate: '',
	glProductCode: '',
	rounding: false,
	roundingValue: '',
	numberDecimal: '',
	numberDecimalValue: '',
	rewardPoolAndGlRedemption: [],
	redeemPartnerRewardPools: {
		byId: {},
		allIds: []
	},
}

export const initialRedeemPartnerConversionForm = createFormGroupState('redeemPartnerConversionForm', initialRedeemPartnerConversionFormValue)

export const validateRedeemPartnerConversionForm = (state: State) => {
	const rounding = state.redeemPartnerConversionForm.controls.rounding.value

	return updateGroup<RedeemPartnerConversionForm>({
		id: validate([required]),
		point: validate([FieldValidator.check2Decimal(), required]),
		value: validate([FieldValidator.check2Decimal(), required]),
		glRedemptionId: validate([FieldValidator.checkRewardPoolType(state.redeemPartnerConversionForm.value.id && state.redeemPartnerConversionForm.value.id.split('_')[0])]),
		redemptionRate: validate([FieldValidator.integerAndDecimalCheck(2, 4), required]),
		glProductCode: validate([required, maxLength(6), FieldValidator.checkValidCharacters()]),
		roundingValue: validate([FieldValidator.requiredIfTrue(rounding)]),
		numberDecimal: validate([FieldValidator.requiredIfTrue(rounding)]),
		numberDecimalValue: validate([FieldValidator.requiredIfTrue(rounding), FieldValidator.digit(), FieldValidator.digitRange(1, 9)])
	})
}

export const initialState: State = {
	isLoading: false,
	redeemPartnerList: [],
	redeemPartnerView: initialRedeemPartnerViewResponse,
	redeemPartnerDetailList: [],
	redeemPartnerConversionForm: initialRedeemPartnerConversionForm,
	redeemPartnerDetailForm: initialRedeemPartnerDetailForm,
	rewardList: [],
	glRedemptions: [],
	roundingList: [],
	numberDecimalList: [],
	redeemPartnerConversionDetailView: [],
	redeemPartnerFormView: initialRedeemPartnerDetail,
	redeemPartnerConversionFormView: initialRedeemPartnerConversionFormValue
}

export interface RedeemPartnerRewardPoolForm {
	rewardPoolId: string
	rewardPoolName: string
	glRedemptionId: number
}

export const initialRedeemPartnerRewardPoolFormValue: RedeemPartnerRewardPoolForm = {
	rewardPoolId: '',
	rewardPoolName: '',
	glRedemptionId: null
}

export const initialRedeemPartnerRewardPoolForm = createFormGroupState('redeemPartnerRewardPoolForm', initialRedeemPartnerRewardPoolFormValue)

export const validateRedeemPartnerRewardPoolForm = updateGroup<RedeemPartnerRewardPoolForm>({
	glRedemptionId: validate([required])
})
