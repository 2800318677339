import { createFormGroupState, FormGroupState, updateGroup, validate } from 'ngrx-forms'
import { maxLength, required } from 'ngrx-forms/validation'
import { MccGroupDetails } from 'src/app/models/param-settings/mcc-group/mcc-group'
import { MccGroupListResponse } from 'src/app/models/param-settings/mcc-group/mcc-group-list'
import { FieldValidator } from 'src/app/models/util/field.validator'

export interface State {
	isLoading: boolean
	code?: string
	mccGroupListForm: FormGroupState<MccGroupListForm>
	mccGroupDetailForm: FormGroupState<MccGroupDetailForm>
	mccGroupListResponse: MccGroupListResponse
	allowDelete: boolean
	mccGroupDetailFormView: MccGroupDetailForm
	mccGroupDuplicateForm: FormGroupState<MccGroupDuplicateForm>
	isDuplicateLoading: boolean
	isDuplicateDialog: boolean
	mccGroupDetails: MccGroupDetails
	mccGroupRangeListView: string[]
}

export interface MccGroupListForm {
	code: string
	name: string
}

export const initialMccGroupListFormValue: MccGroupListForm = {
	code: '',
	name: '',
}

export const initialMccGroupListForm = createFormGroupState('mccGroupListForm', initialMccGroupListFormValue)

export const validateMccGroupListForm = updateGroup<MccGroupListForm>({
	code: validate([maxLength(255)]),
	name: validate([maxLength(255)]),
})
export interface MccGroupDetailForm {
	code: string
	name: string
	description: string
	range: string
	createdBy: string
	createdDate: string
	modifiedBy: string
	modifiedDate: string
}

export const initialMccGroupDetailFormValue: MccGroupDetailForm = {
	code: '',
	name: '',
	description: '',
	range: '',
	createdBy: '',
	createdDate: '',
	modifiedBy: '',
	modifiedDate: ''
}

export const initialMccGroupDetailForm = createFormGroupState('mccGroupDetailForm', initialMccGroupDetailFormValue)

export const validateMccGroupDetailForm = updateGroup<MccGroupDetailForm>({
	code: validate([required, FieldValidator.alphaNumericWithoutSpaces()]),
	name: validate([required, maxLength(40), FieldValidator.checkValidCharacters()]),
	description: validate([maxLength(100), FieldValidator.checkValidCharacters()])
})

export interface MccGroupDuplicateForm {
	code: string
	name: string
}

export const initialMccGroupDuplicateFormValue: MccGroupDuplicateForm = {
	code: '',
	name: ''
}

export const initialMccGroupDuplicateForm = createFormGroupState('mccGroupDuplicateForm', initialMccGroupDuplicateFormValue)

export const validateMccGroupDuplicateForm = updateGroup<MccGroupDuplicateForm>({
	code: validate([required, FieldValidator.alphaNumericWithoutSpaces()]),
	name: validate([required, maxLength(40), FieldValidator.checkValidCharacters()])
})

export function digit() {
	return (value: string) => {
		const re = new RegExp(/^\d+$/)
		const condition = value && !value.match(re)
		return condition ? { digit: true } : null
	}
}

export function range() {
	return (value: string) => {
		const allowedCharactersRegex = new RegExp(/^([0-9-,])+$/)
		const digitRegex = new RegExp(/^\d+$/)
		let check = true
		value.split(',').forEach(splitValue => {
			const individualValues = splitValue.split('-')
			individualValues.forEach(element => {
				if (!(element.match(digitRegex))) {
					check = false
				}
			})
		})
		const condition = value && !value.match(allowedCharactersRegex) && !(check)
		return condition ? { range: true } : null
	}
}

export const initialMccGroupDetails = {
	code: '',
	name: '',
	range: [],
	description: ''
}

export const initialState: State = {
	isLoading: false,
	mccGroupListForm: initialMccGroupListForm,
	mccGroupDetailForm: initialMccGroupDetailForm,
	mccGroupListResponse: {
		mccGroups: []
	},
	allowDelete: true,
	mccGroupDetailFormView: initialMccGroupDetailFormValue,
	mccGroupDuplicateForm: initialMccGroupDuplicateForm,
	isDuplicateDialog: false,
	isDuplicateLoading: false,
	mccGroupDetails: initialMccGroupDetails,
	mccGroupRangeListView: []
}
