import { animate, state, style, transition, trigger } from '@angular/animations'
import { Component, Input, OnInit } from '@angular/core'
import { MatTableDataSource } from '@angular/material'
import { RawDataConditionalRewardColumns, SimulatorCampaignBody, SimulatorRulesetBody, SimulatorTierBody } from 'src/app/models/campaign-management/simulator/simulator'

@Component({
	selector: 'app-simulator-tier',
	templateUrl: './simulator-tier.component.html',
	styleUrls: ['./simulator-tier.component.scss'],
	animations: [
		trigger('detailExpand', [
			state('collapsed', style({ height: '0px', minHeight: '0' })),
			state('expanded', style({ height: '*' })),
			transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
		])
	]
})
export class SimulatorTierComponent implements OnInit {

	@Input() tier: SimulatorTierBody
	@Input() index: string

	displayedColumns: RawDataConditionalRewardColumns[] = ['name', 'transactionHit', 'totalAmountHit', 'totalRewardedPoint']
	dataSource: MatTableDataSource<SimulatorCampaignBody>
	expandedElement: SimulatorRulesetBody[]

	constructor() {
		this.dataSource = new MatTableDataSource()
	}

	ngOnInit() {
		this.dataSource.data = this.tier.campaignList
	}

}
