import { createAction, props } from '@ngrx/store'
import { GlRedemptionListResponse } from 'src/app/models/param-settings/gl-redemption/gl-redemption-list'
import { RedeemPartner, RedeemPartnerDetail } from 'src/app/models/redemption-settings/redeem-partner/redeem-partner'
import { RedeemPartnerReward } from 'src/app/models/redemption-settings/redeem-partner/redeem-partner-list'
import { RedeemPartnerUpdateView } from 'src/app/models/redemption-settings/redeem-partner/redeem-partner-update'
import { RedeemPartnerViewResponse } from 'src/app/models/redemption-settings/redeem-partner/redeem-partner-view'
import { RewardPoolGroupViewResponse } from 'src/app/models/param-settings/reward-pool-group/reward-pool-group-view'
import { Rounding } from 'src/app/models/redemption-settings/redeem-partner/redeem-partner-rounding-list'
import { NumberDecimal } from 'src/app/models/redemption-settings/redeem-partner/redeem-partner-number-decimal-list'

export const InitialState = createAction('[RedeemPartner] InitialState')
export const RestError = createAction('[RedeemPartner] RestError', props<{ message: string, manualThrow: boolean }>())

export const GoList = createAction('[RedeemPartner] GoList')

export const SetId = createAction('[RedeemPartner] SetId', props<{ id: string }>())
export const SetConversion = createAction('[RedeemPartner] SetConversion', props<{ redeemPartnerDetail: RedeemPartnerDetail }>())
export const GoCreate = createAction('[RedeemPartner] GoCreate')
export const GoView = createAction('[RedeemPartner] GoView')
export const GoUpdate = createAction('[RedeemPartner] GoUpdate')

export const GoBackDialog = createAction('[RedeemPartner] GoBackDialog', props<{ action: string }>())
export const closeDialog = createAction('[RedeemPartner] closeDialog')
export const ConversionDialog = createAction('[RedeemPartner] ConversionDialog', props<{ action: string }>())
export const CreateDialog = createAction('[RedeemPartner] CreateDialog')
export const UpdateDialog = createAction('[RedeemPartner] UpdateDialog')
export const DeleteDialog =  createAction('[RedeemPartner] DeleteDialog')
export const DeleteConversionDialog = createAction('[RedeemPartner] DeleteConversionDialog')

export const List = createAction('[RedeemPartner] List')
export const ListSuccess = createAction('[RedeemPartner] ListSuccess', props<{ payload: RedeemPartner[] }>())

export const GLOptionListView = createAction('[RedeemPartner] GLOptionListView')
export const GLOptionListViewSuccess = createAction('[RedeemPartner] GLOptionListViewSuccess', props<{ payload: GlRedemptionListResponse }>())

export const GLOptionListUpdate = createAction('[RedeemPartner] GLOptionListUpdate')
export const GLOptionListUpdateSuccess = createAction('[RedeemPartner] GLOptionListUpdateSuccess', props<{ payload: GlRedemptionListResponse }>())

export const RewardOptionListView = createAction('[RedeemPartner] RewardOptionListView')
export const RewardOptionListViewSuccess = createAction('[RedeemPartner] RewardOptionListViewSuccess', props<{ payload: RedeemPartnerReward[] }>())

export const RewardOptionListUpdate = createAction('[RedeemPartner] RewardOptionListUpdate')
export const RewardOptionListUpdateSuccess = createAction('[RedeemPartner] RewardOptionListUpdateSuccess', props<{ payload: RedeemPartnerReward[] }>())

export const Create = createAction('[RedeemPartner] Create')
export const CreateSuccess = createAction('[RedeemPartner] CreateSuccess', props<{ payload: string }>())

export const View = createAction('[RedeemPartner] View')
export const ViewSuccess = createAction('[RedeemPartner] ViewSuccess', props<{ payload: RedeemPartnerViewResponse }>())

export const Update = createAction('[RedeemPartner] Update')
export const UpdateSuccess = createAction('[RedeemPartner] UpdateSuccess', props<{ payload: string }>())

export const UpdateView = createAction('[RedeemPartner] UpdateView')
export const UpdateViewSuccess = createAction('[RedeemPartner] UpdateViewSuccess', props<{ payload: RedeemPartnerUpdateView }>())

export const Delete = createAction('[RedeemPartner] Delete')
export const DeleteSuccess = createAction('[RedeemPartner] DeleteSuccess', props<{ payload: string }>())

export const UpdatePointsConversionDialog = createAction('[RedeemPartner] UpdatePointsConversionDialog')
export const UpdatePointsConversion = createAction('[RedeemPartner] UpdatePointsConversion')
export const UpdatePointsConversionSuccess = createAction('[RedeemPartner] UpdatePointsConversionSuccess', props<{ payload: string }>())

export const SaveConversion = createAction('[RedeemPartner] SaveConversion')
export const DeleteConversion = createAction('[RedeemPartner] DeleteConversion')

export const SetPointConversionId = createAction('[RedeemPartner] SetPointConversionId', props<{ pointConversionId: string }>())

export const OnChangeRounding = createAction('[RedeemPartner] OnChangeRounding')
export const OnChangeRewardPool = createAction('[RedeemPartner] OnChangeRewardPool')
export const OnChangeRewardPoolGroup = createAction('[RedeemPartner] OnChangeRewardPoolGroup')
export const OnChangeRewardPoolGroupSuccess = createAction('[RedeemPartner] OnChangeRewardPoolGroupSuccess', props<{ payload: RewardPoolGroupViewResponse }>())

export const RoundingList = createAction('[RedeemPartner] RoundingList')
export const RoundingListSuccess = createAction('[RedeemPartner] RoundingListSuccess', props<{ payload: Rounding }>())

export const NumberDecimalList = createAction('[RedeemPartner] NumberDecimalList')
export const NumberDecimalListSuccess = createAction('[RedeemPartner] NumberDecimalListSuccess', props<{ payload: NumberDecimal }>())
